//dựa theo enum RegisterSource trong Backend.
export const registerSource = {
  /// <summary>
  /// Tại quầy tiếp tân
  /// </summary>
  RECEPTION: 0,
  /// <summary>
  /// Lịch hẹn
  /// </summary>
  APPOINMENT: 1,
  /// <summary>
  /// Khám sức khỏe công ty
  /// </summary>
  COMPANY_EXAM: 2,
  /// <summary>
  /// Từ phần mềm Tnet của phòng khám Ivy
  /// </summary>
  TNET: 3,
}