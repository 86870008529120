import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { Popover, Tooltip } from 'antd';
import { connect } from 'react-redux';
import * as Action from '../../../libs/actions';
import * as InvoiceService from '../../../services/invoiceService';
import SeparatePayment from '../payment/components/SeparatePayment';
import { USER_AUTH } from '../../../configureHIS/common/constants';

/** Component này dùng để hiển thị thành tiền, thanh toán của hóa đơn/bill thanh toán
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\invoice\component\smallshowsummaryamount.js
    - src\screens\invoice\trahoadon\preview.js
 ** Các props nhận vào:
* @param {boolean} props.isEdit: //true: khi hiện ở màn hình xem chi tiết | false: khi hiện ở màn hình hover.
* @param {object} props.billNumber: số bill. Này là chim mồi. Nếu props.data không có thì call api dựa vào billNumber này. Có thể là số bill thanh toán hoặc hoàn trả
* @param {object} props.data: dữ liệu. lấy theo response data của api "api/Invoice/GetBillDetail?billNumber=..."
    props.data = {
        * @param {Array} paymentTypeName: array, //Hình thức thanh toán.
        * @param {Date} paidDate: date, //Ngày thanh toán / hoàn trả.
        * @param {string} cashier: text, //Thu ngân.
        * @param {string} remark: text, //Ghi chú.
        * @param {string} voucher: text, //voucher code nếu có áp dụng CTKM
        * @param {string} managementId: mã quản lý.
        * @param {string} patientId: mã bệnh nhân.
        * @param {string} billNumber: số bill. Có thể là số bill thanh toán hoặc hoàn trả
        * @param {boolean} showBtnPrint: hiện / ẩn button in
        * @param {boolean} showBtnRefund: hiện / ẩn button in
        
        * @param {number} total: number, //Tổng tiền: tổng tiền các dịch vụ (sau khi đã giảm dịch vụ).
        * @param {number} discount: number, //Miễn giảm: giảm giá trên bill (giảm tay trên bill).
        * @param {number} loyalty: number, //CTKM / Thẻ: giảm giá trên bill theo CTKM / thẻ.
        * @param {number} paid: number, //Thành tiền = total - discount - loyalty.
        * @param {number} refund: number, //Hoàn trả: số tiền phải hoàn trả nếu là bill hoàn trả.
        * @param {number} revenue: number, //Thanh toán: số tiền phải thanh toán nếu là bill thanh toán.
    }
* @param {Function} props.onChangePayment: //change payment
 */
//tương ứng enum ReceiptType
const ReceiptType = {
    THANHTOAN: 0,
    TAMUNG: 2,
    HOANTRA: 3,
    VIENPHI_NOITRU: 4,
    HOANTRA_TAMUNG: 5
}
const SummaryAmount = forwardRef((props, ref) => {
    const notiRef = useRef(null);

    //theo props
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [billNumber, setBillNumber] = useState(props.billNumber);
    const [billNo, setBillNo] = useState(props.billNo);
    const [data, setData] = useState(props.data);
    //riêng
    const [isBillRefund, setIsBillRefund] = useState(props.data?.billType == ReceiptType.HOANTRA);
    const [visibleChangePaymentType, setVisibleChangePaymentType] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setIsBillRefund(data?.billType == ReceiptType.HOANTRA);
        forceUpdate();
    }, [data?.billType])

    useEffect(() => {
        if (props.data === null || props.data === undefined) {
            getBillDetail();
        }
        else { 
            setBillNumber(props.data.billNumber);
            setBillNo(props.data.billNo);
            setData(props.data);
        }
        setIsEdit(props.isEdit);
    }, [props])
    
    // useEffect(() => {
    //     setIsBillRefund(data?.billType == ReceiptType.HOANTRA);
    // }, [props.data?.billType])

    // useEffect(() => {
    //     //Nếu không có truyền data vào thì gọi api để lấy.
    //     if (props.data === null || props.data === undefined) {
    //         getBillDetail();
    //     }
    //     else { setBillNumber(props.data.billNumber); }
    // }, [])

    function getBillDetail() {
        Action.setLoading(true);
        InvoiceService.getBillDetail(billNumber).then(res => {
            if (res.isSuccess) {
                setData(res.data);
                setIsBillRefund(res.data?.billType == ReceiptType.HOANTRA);
                forceUpdate();
            }
        }).catch(err => {

        }).finally(() => {
            Action.setLoading(false);
        })
    }

    function onChangePaymentType() {
        setVisibleChangePaymentType(false);
        props.onChangePayment && props.onChangePayment();
    }

    return (
        <Fragment>
        {isBillRefund ? 
            <div className='d-flex justify-content-between justify-content-end'>
                <span className='mita-title' style={{ width : '40%'}}>HOÀN TRẢ</span>
                <span style={{ width : '10%', textAlign : 'end'}}><u>đ</u></span>
                <span className='mita-title text-danger' style={{ width : '35%', textAlign : 'end'}}>{Number(data?.refund ?? 0).toLocaleString("en-US")}</span>
                <span style={{ width : '15%', textAlign : 'end'}}>&nbsp;</span>
            </div>
        :
            <div className='d-flex flex-column justify-content-end'>
                {(data?.total != data?.paid) &&
                <div className='d-flex'>
                    <span className='mita-title' style={{ width : '40%'}}>THÀNH TIỀN</span>
                    <span style={{ width : '10%', textAlign : 'end'}}><u>đ</u></span>
                    <span className='mita-title' style={{ width : '35%', textAlign : 'end'}}>{Number(data?.total ?? 0).toLocaleString("en-US")}</span>
                    <span style={{ width : '15%', textAlign : 'end'}}>&nbsp;</span>
                </div>
                }
                {Number(data?.discount ?? 0) > 0 &&
                <div className='d-flex'>
                    <span className='mita-title' style={{ width : '40%'}}>MIỄN GIẢM</span>
                    <span style={{ width : '10%', textAlign : 'end'}}><u>đ</u></span>
                    <span className='mita-title text-warning' style={{ width : '35%', textAlign : 'end'}}>{Number(data?.discount ?? 0).toLocaleString("en-US")}</span>
                    <span style={{ width : '15%', textAlign : 'end'}}>&nbsp;</span>
                </div>
                }
                {Number(data?.loyalty ?? 0) > 0 &&
                <div className='d-flex'>
                    <span className='mita-title' style={{ width : '40%'}}>CTKM
                    <Popover trigger="hover" placement="top"
                        content = {
                            <div>
                                <label className='mita-value'>Mã CTKM đã sử dụng:&nbsp;</label>
                                <label className='mita-title'>{data?.voucher}</label>
                            </div>
                        }
                    >
                        <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>
                    </Popover>
                    </span>
                    <span style={{ width : '10%', textAlign : 'end'}}><u>đ</u></span>
                    <span className='mita-title text-success' style={{ width : '35%', textAlign : 'end'}}>{Number(data?.loyalty ?? 0).toLocaleString("en-US")}</span>
                    <span style={{ width : '15%', textAlign : 'end'}}>&nbsp;</span>
                </div>
                }
                <div className='d-flex'>
                    <span className='mita-title' style={{ width : '40%'}}>THANH TOÁN</span>
                    <span style={{ width : '10%', textAlign : 'end'}}><u>đ</u></span>
                    <span className='mita-title text-info' style={{ width : '35%', textAlign : 'end'}}>{Number(data?.paid ?? 0).toLocaleString("en-US")}</span>
                    <span style={{ width : '15%', textAlign : 'center'}}>
                        {isEdit && 
                            <Popover
                                content={
                                    <div style={{ width: 400, padding: 10 }}>
                                    <SeparatePayment
                                        data = {{
                                            billNumber : billNumber,
                                            billNo: billNo,
                                            totalBill : Number(data?.paid ?? 0)
                                        }}
                                        onSuccessChange = {() => { onChangePaymentType()}}
                                        onClose={() => setVisibleChangePaymentType(false)}
                                        isPayment = {true}
                                        isChangePayment = {true}
                                        showCancel={false} />
                                    </div>}
                                trigger="click"
                                placement="top"
                                visible={visibleChangePaymentType}
                                onVisibleChange={(e) => setVisibleChangePaymentType(e)}
                            >
                                {
                                    props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.ALLOCATION] &&
                                    <Tooltip title="Phân bổ hình thức thanh toán">
                                        <div className="anchor-color" onClick={() => setVisibleChangePaymentType(true)}><i className="fas fa-sitemap"></i></div>
                                    </Tooltip>
                                }
                            </Popover>
                        }
                    </span>
                </div>
                {/* {data?.refund > 0 && 
                <>
                <div style={{ textAlign: "end", borderTop: "2px dashed" }}>
                    <label className='mita-title' style={{ textTransform: "uppercase" }}>Hoàn trả&nbsp;</label><u>đ</u>&nbsp;
                    <label className='mita-title text-danger' style={{ width: 150 }}>{Number(data?.refund ?? 0).toLocaleString("en-US")}</label>&nbsp;
                    <label style={{ width: 20 }}>&nbsp;</label>
                </div>
                <div style={{ textAlign: "end" }}>
                    <label className='mita-title' style={{ textTransform: "uppercase" }}>Doanh thu&nbsp;</label><u>đ</u>&nbsp;
                    <label className='mita-title text-info' style={{ width: 150 }}>{(Number(data?.paid ?? 0)-Number(data?.refund ?? 0)).toLocaleString("en-US")}</label>&nbsp;
                    <label style={{ width: 20 }}>&nbsp;</label>
                </div>
                </>} */}
            </div>
        }
        </Fragment>
    )
});

const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
        userScreenAuthorization: state.global.userScreenAuthorization,
    })
};
export default connect(mapStateToProps)(SummaryAmount)