import React, { forwardRef, useImperativeHandle, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import NoContent from '../../../components/loading/noContent';
import { Table, Tooltip } from 'antd';
import { isnotnull, isNullOrEmpty } from '../../../libs/util';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import { TableVirtuoso } from 'react-virtuoso'
const SQLResult = forwardRef((props, ref) => {
    const [totalRecords,] = useState(props.totalRecords ?? 0);
    const [dataItems, setDataItems] = useState([]);
    const [header, setHeader] = useState([]);
    const [message, setMessage] = useState();
    const [isSelectQuery, setIsSelectQuery] = useState(false);
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        const data = props.data;
        setHeader(data.listHeader ?? []);
        setIsSelectQuery(data.isSelectQuery ?? false);
        setDataItems(data.items ?? []);
        setMessage(data.message);
    }, [props.data]);

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{index + 1}</span>;
            }
        },
        ...header?.map((col, index) => {
            return {
                title: col,
                dataIndex: col,
                key: col,
                align: 'left',
                width: 120,
                render: (val) => <MitaDisplayLongText
                    content={val}
                    maxLength={60}
                    showMoreText=" ...Xem thêm"
                    showLessText=" Rút gọn"
                />
            }
        })
    ]

    const onDoubleClickHeader = (val) => {
        props.onDoubleClickHeader && props.onDoubleClickHeader(val);
    }

    return (
        <Fragment>
            <div>Kết quả:</div>
            {
                (isSelectQuery && isnotnull(header)) ?
                    isnotnull(dataItems) ?
                        <TableVirtuoso
                            style={{ height: "45vh", width: "100%", borderRadius: 10 }}
                            data={dataItems}
                            fixedHeaderContent={() => (
                                <tr className='backgroundColorThemes'>
                                    <th key="thIndex" style={{ width: 35, textAlign: "center", padding: "5px 20px", fontWeight: "bold" }}>#</th>
                                    {header.map((head, hInd) => {
                                        return (
                                            <Tooltip title="Nhấp đôi chuột để thêm tên cột tại con trỏ chuột trong ô lệnh">
                                                <th style={{ textAlign: "center", padding: "5px 20px", fontWeight: "bold" }} key={hInd}
                                                    onDoubleClick={() => { onDoubleClickHeader(head) }}>{head}</th>
                                            </Tooltip>
                                        )
                                    })}
                                </tr>
                            )}
                            components={{
                                TableRow: ({ context, item, ...restProps }) => {
                                    return (
                                        <tr className='rowCustom border-bottom' {...restProps}>
                                            <td style={{ width: 35, textAlign: "center", padding: "5px 20px" }}>{restProps['data-index'] + 1}</td>
                                            {header.map((head, ind) => {
                                                return (
                                                    <td style={{ textAlign: "left", padding: "5px 20px" }} key={ind}>
                                                        <MitaDisplayLongText
                                                            content={item[head]}
                                                            maxLength={32}
                                                            showMoreText=" ...Xem thêm"
                                                            showLessText=" Rút gọn"
                                                        />
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                },
                                TableBody: forwardRef((props, ref) => (
                                    <tbody {...props} ref={ref} />
                                ))
                            }}
                        />
                        : <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" />
                    :
                    <div className='text-danger'>{message}</div>
            }
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SQLResult)