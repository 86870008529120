import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'config';

export function getListEquipments(key, exp = false, depr = false, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/equipments-list?key=${key}&exp=${exp}&depr=${depr}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createEquipment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/add-equipment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateEquipment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/update-equipment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteEquipment(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete-equipment?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportEquipmentsList(exp, depr) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/export-equipments-list?&exp=${exp}&depr=${depr}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function loadUsers(active=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/users-list?&active=${active}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createHistory(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/add-history`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListHistories(key, status, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/histories-list?&key=${key}&status=${status}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function historyAction(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/history-action`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportHistoryEquipmentsList() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/export-history-equipments-list`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printHandOverRetrieval(id, type) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/printHandOverRetrieval?&id=${id}&type=${type}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function getEquipmentsInfo(filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/equipment-info?&filter=${filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

//Bảo trì tài sản
export function createProgress(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/add-maintenace-schedule`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getMaintenanceList(key, status, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/maintenace-list?&key=${key}&status=${status}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateProgress(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/edit-maintenace-schedule`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportMaintenance(status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/export-maintenance-list?&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printMaintenanceReport(id, type) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/print-maintenance-report?&id=${id}&type=${type}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function templateTaiSanCoDinh() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/template-tai-san-co-dinh`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function importDanhSach(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/import-tai-san-co-dinh`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListTypes(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loai-tai-san-co-dinh?&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createTypeOfEquipment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/add-type-of-equipment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateTypeOfEquipment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/edit-type-of-equipment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteTypeOfEquipment(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete-type-of-equipment?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateHide(id, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/update-hide-type?&ma=${id}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function loadTypes() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/equipments-type-list`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function generateCode(pref) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/generate-equipment-code?&pref=${pref}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function loadListKiemKe(key, type, date, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/kiem-ke-tai-san?&key=${key}&sortby=${type}&date=${date}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getCodeKiemKe() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/get-code-kiem-ke`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAllEquipment(bydate) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/get-all-equipment?&sort=${bydate}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createKiemKeTaiSan(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/tao-phieu-kiem-ke-tai-san`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateKiemKeTaiSan(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/update-phieu-kiem-ke`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deletePhieuKiemKe(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete-phieu-kiem-ke?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSummary(id, sort) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/tai-san-chua-kiem-ke?&id=${id}&sort=${sort}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportKiemKeTaiSan(maphieu, sort) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/export-kiem-ke-tai-san?&id=${maphieu}&sort=${sort}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListLocation(key, page, size, getAll = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetLocation?&key=${key}&page=${page}&size=${size}&getAll=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createLocation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AddLocation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateLocation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/EditLocation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteLocation(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteLocation/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};