import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Button, Input, Select, Table, Tooltip, notification, Alert, Modal, Steps } from 'antd';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import moment from 'moment';
import { FORMAT_DATE, FORMAT_DATE_TIME, FORMAT_TIME_DATE } from '../../configure/systemConfig';
import NguonKhachHang from "../recept/register/nguonkhachhang";
import * as ConfigService from '../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as AppointmentService from '../../services/appointmentService';
import Notify, { NotifyStatus } from '../../components/notify';
import ModalCLS from '../../components/MDCLS';
import { isNullOrEmpty, isnotnull } from '../../libs/util';
import * as PatientService from '../../services/patientService';
import DateTimePicker from 'react-datetime-picker';
import AddOrder from '../lichhenlaymau/addOrder';
import { ALLOW_COMBO } from "../../configureHIS/common/constants";
import * as HotlineServices from "./hotlineservices";
import * as ChiDinhService from '../../services/chiDinhService';
import SelectConsultant from '../../components/MDCLS/selectConsultant';
import ConsultantWarning from '../recept/register/consultantWarning';

const { Step } = Steps;
const { TextArea } = Input;
var taskDelay = null;
const username = localStorage.getItem("userID");

const TaoCapNhatLichHenLayMauHotline = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const _nguonKhachHang = useRef();
    const _orderRef = useRef();
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [stepInfo, setStepInfo] = useState({});
    const [patientInfo, setPatientInfo] = useState(props.patientInfo ?? {});
    const [ngayHenGanNhat, setNgayHenGanNhat] = useState({});
    const [bangGia, setBangGia] = useState("");
    const [bangGiaTheoSite, setBangGiaTheoSite] = useState("");
    const [bangGiaTheoNguonKH, setBangGiaTheoNguonKH] = useState("");
    const [dataOrder, setDataOrder] = useState(props.dataOrder ?? []);
    const [caseInfo, setCaseInfo] = useState(props.caseInfo);
    const [site, setSite] = useState(props.site);
    const [patientId, setPatientId] = useState("");
    const [listSite, setListSite] = useState([]);
    const [detail, setDetail] = useState();
    const [initDetail, setInitDetail] = useState();
    const [ngayHen, setNgayHen] = useState(new Date());
    const [loadingTable, setLoadingTable] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [caseId, setCaseId] = useState(props.caseId);
    const [modalSelectConsultant, setModalSelectConsultant] = useState(false);
    const [managementId, setManagementId] = useState(props.managementId);
    const [listHTTKQ, setListHTTKQ] = useState([]);
    const [sendBy, setSendBy] = useState([]);

    useEffect(() => {
        getListSite();
        getListHTTKQ();
    }, []);

    useEffect(() => {
        if (caseInfo.mabn) getNgayHenGanNhat(caseInfo.mabn);
        setNewAddress(caseInfo.diachihen);
        setNgayHen(moment(caseInfo.ngayhen).year() !== 1 ? moment(caseInfo.ngayhen).toDate() : new Date());
        setDefaultAddress(isNullOrEmpty(caseInfo.diachihen) ? true : false);
        caseInfo.ghichu && setInitDetail(caseInfo.ghichu);
        loadChiDinhInfo();
    }, [props.caseInfo])

    useEffect(() => {
        let s = listSite.find(x => x.id == site);
        if (s) setBangGiaTheoSite(s.banggia);
    }, [site])

    const onCloseModal = () => {
        props.onClose && props.onClose();
        resetState();
        _notify && _notify.current && _notify.current.Close();
    }

    const resetState = () => {
        setPatientId("");
        setSite();
    }

    const xoaTatCaChiDinh = () => {
        setDataOrder([]);
    }

    const checkBangGia = (info) => {
        let banggia = bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia;
        let listOrder = [...dataOrder];
        let itemFound = listOrder && listOrder.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        if (itemFound !== null && itemFound !== undefined) banggia = itemFound.banggia; //nếu đã lưu chỉ định có banggia thì lấy banggia của chidinh
        if (itemFound !== null && itemFound !== undefined && info.banggia !== banggia) {
            //nếu banggia của nguonKH khác bangia hiện tại thì vào thay đổi
            //nếu đổi banggia mà có chidinh thì xóa chidinh cũ
            if (listOrder.length > 0) {
                xoaTatCaChiDinh();
                if (_orderRef && _orderRef.current) {
                    _orderRef.current.UpdateSelectedFromProps([]);
                }
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`);
                if (itemFound.maql) {
                    cancelExam(itemFound.maql, listOrder);
                }
                banggia = info.banggia;
            }
            setBangGia(banggia);
        } else setBangGia(info.banggia);
    }

    const cancelExam = (managementId) => {
        var loadDetail = false;
        Actions.setLoading(true);
        xoaTatCaChiDinh();
        if (_orderRef && _orderRef.current) {
            _orderRef.current.UpdateSelectedFromProps([]);
        }
        let clrCopporator = false;
        PatientService.cancelExam(managementId, clrCopporator).then((respone) => {
            if (respone.isSuccess) {
                loadChiDinhInfo(loadDetail);
            } else {
                Actions.openMessageDialog("Lỗi", respone.err.msgString);
            }
            Actions.setLoading(false);
        })
    }

    const loadChiDinhInfo = (loadDetail = true) => {
        if (Number(caseInfo.idlichhen) > 0) {
            getListOrderById(caseInfo.idlichhen);
            loadDetail && getAppointmentDetail(caseInfo.idlichhen);
        }
    }

    const getListOrderById = (id) => {
        Actions.setLoading(true);
        setLoadingTable(true);
        AppointmentService.GetListOrderById(id, site).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                for (let item of data) {
                    item.listChild = item.listChildCd;
                }
                setDataOrder(data);
                _orderRef && _orderRef.current && _orderRef.current.UpdateSelectedFromProps(data);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
                onCloseModal();
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        }).finally(() => {
            Actions.setLoading(false)
            setLoadingTable(false);
        });
    }

    const getAppointmentDetail = (idlichhen) => {
        AppointmentService.ScheduleGetSampleByPatientID(idlichhen, site).then(res => {
            if (res.isSuccess) {
                const data = res.item;
                const info = {
                    hoantat: data.dalaymau === 1, // 0: chưa, 1: rồi
                    isCheckin: data.giocheckin && moment(data.giocheckin).year() !== 1,
                    isLaymau: data.gionhanmau && moment(data.gionhanmau).year() !== 1,
                    mactv: data.mactv,
                    tenctv: data.tenctv,
                    giocheckin: data.giocheckin && moment(data.giocheckin).year() !== 1 && moment(data.giocheckin).format(FORMAT_DATE_TIME),
                    gionhanmau: data.gionhanmau && moment(data.gionhanmau).year() !== 1 && moment(data.gionhanmau).format(FORMAT_DATE_TIME),
                }
                setStepInfo(info);
                setSendBy(data.sendBy);
            }
        }).catch(() => { })
    }

    const getListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) setListSite(result.data);
            else setListSite([]);
        }).catch(err => { })
    }

    const onChangeDetail = () => {
        if (detail !== undefined && detail !== null && detail !== "" && detail != " ") {
            let data = initDetail ? `${initDetail}\n[${username}]: ${detail}` : `[${username}]: ${detail}`;
            setInitDetail(data);
            setDetail("");
        }
    }

    let dataSource = (dataOrder ?? []).map(function (e, index) {
        let o = Object.assign({}, e);
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    });

    let regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title:
                <div>
                    <span className={props.consultantRequired && "required-field"}>NV tư vấn</span>
                    <Tooltip title='Cập nhật nhân viên tư vấn'>
                        <Button type='link' style={{ color: 'white' }}
                            className='pl-2'
                            onClick={() => { setModalSelectConsultant(true) }}
                        ><i className='fa fa-pen' />
                        </Button>
                    </Tooltip>
                </div>,
            dataIndex: 'consultantName',
            key: 'consultantName',
            width: 130,
            render: (text, record, index) => {
                return record.consultantName
            },
        },
        {
            title: 'Giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        // {
        //     title: 'Thao tác',
        //     align: 'center',
        //     width: '10%',
        //     render: (text, row, index) => {
        //         return (
        //             (Number(caseInfo.idlichhen) > 0 || row.isChild) ? "" : (
        //                 <div style={{ cursor: 'pointer' }}
        //                     className="text-danger"
        //                     onClick={() => {
        //                         xoaChiDinh(row);
        //                         if (_orderRef && _orderRef.current) {
        //                             _orderRef.current.XoaChiDinh(row);
        //                         }
        //                     }} >
        //                     <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
        //                 </div>
        //             )
        //         )
        //     }
        // }
    ];

    const xoaChiDinh = (row) => {
        let data = [...dataOrder];
        data = data.filter(x => x.id !== row.id)
        setDataOrder(data.map(x => ({ ...x })));
    }

    const getNgayHenGanNhat = (mabn, laymau = true) => {
        AppointmentService.NgayHenGanNhat(mabn, laymau).then(result => {
            if (result.isSuccess) setNgayHenGanNhat(result.item);
            else setNgayHenGanNhat({});
        })
    }

    const saveLichHen = () => {
        if (isNullOrEmpty(site)) {
            _notify.current.Show("Cần chọn chi nhánh đặt lịch", NotifyStatus.Warning);
            return;
        }
        if (!defaultAddress && isNullOrEmpty(newAddress)) {
            _notify.current.Show("Cần nhập địa chỉ mới", NotifyStatus.Warning);
            return;
        }
        let checkNgayHen = moment(ngayHen);
        if (!checkNgayHen.isValid()) {
            _notify.current.Show("Ngày hẹn sai định dạng", NotifyStatus.Warning);
            return;
        }
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            _notify.current.Show("Bắt buộc chọn nguồn khách hàng", NotifyStatus.Warning);
            return;
        }
		let isNullConsultant = dataOrder.some(x => isNullOrEmpty(x.consultantId));
		if (props.consultantRequired && isNullConsultant) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        Actions.setLoading(true);
        const data = {
            mabn: caseInfo.mabn.toString(),
            ngayhen: checkNgayHen.format("YYYY-MM-DD HH:mm"),
            newAddress: newAddress,
            site: site.toString(),
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
            idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
            lstChiDinh: dataOrder
        }
        HotlineServices.saveLichHen(data).then(result => {
            if (result.isSuccess) {
                updateIdLichHen(result.returnObject.id, result.returnObject.chinhanh);
                updateGhiChu(caseInfo.idhotline);
                if (updateChidinh(result.returnObject.id, result.returnObject.chinhanh))
                {
                    _notify.current.Show("Lưu thành công", NotifyStatus.Success);
                    notification.success({ message: 'Lưu thành công' });
                    resetState();
                    reload();
                }
            } else Actions.openMessageDialog(result.err.msgCode ?? "Lỗi", result.err.msgString);
        }).catch(() => {
            _notify.current.Show('Xảy ra lỗi khi lưu, vui lòng thử lại', NotifyStatus.Error);
        }).finally(() => Actions.setLoading(false))
    }

    const updateIdLichHen = (idhen, destSite) => {
        updateArrangerment(caseId, 'lenlich', idhen, caseInfo.chinhanh ? caseInfo.chinhanh : '', destSite);
    }

    const updateArrangerment = (id = caseId, status, idhen, chinhanh, destSite) => {
        HotlineServices.updateArrangerment(id, status, idhen, chinhanh, destSite).then(res => {
            if (res.isSuccess) {
                reload();
            }
        }).catch((err) => { })
    }

    const updateGhiChu = (hotlineId) => {
        HotlineServices.UpdateDetailArrangerment(hotlineId, { ghichu: initDetail }, props.activeSite).then(() => { })
    }

    const updateChidinh = (orderid) => {
        Actions.setLoading(true);
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        let lstSelect = [...dataOrder];
        if (!lstSelect || lstSelect.length === 0)
            return;
        let listChiDinh = [];
        for (let item of lstSelect) {
            item.ma = "0";
            item.madoituong = "2";
            item.mavp = item.id.toString();
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment(ngayHen).format("YYYY-MM-DD HH:mm");
            if (listChiDinh.filter(x => x.id == item.id).length > 0) {
                notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                return;
            }
            listChiDinh.push(item);
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = xChild;
                    itemChild.mavp = xChild.id.toString();
                    itemChild.giaaTH = xChild.dongia.toString();
                    itemChild.dongia = xChild.dongia;
                    itemChild.soluong = xChild.soluong;
                    itemChild.ngay = moment(ngayHen).format("YYYY-MM-DD HH:mm");
                    itemChild.vienPhiIdOfCombo = item.id;
                    if (listChiDinh.filter(x => x.id == itemChild.id).length > 0) {
                        notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                        return;
                    }
                    listChiDinh.push(itemChild);
                });
        }
        const data = {
            orderid: orderid,
            listChiDinh: listChiDinh,
            patient: {
                code: caseInfo.mabn.toString(),
                subjectCode: '2',
                sendby: sendBy,
                dateInHospital: moment(ngayHen).format("YYYY-MM-DD HH:mm"),
            },
            idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
            chinhanh: !isNullOrEmpty(site) ? site.toString() : "",
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu chỉ định thành công!' });
                reload();
                return true;
            } else {
                notification.warning({ message: result.err.msgString });
                Actions.openMessageDialog(result.err.msgCode, result.err.msgString);
                return false;
            }
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    const reload = () => {
        props.onReload && props.onReload();
    }

    const updateLichHen = () => {
        let checkNgayHen = moment(ngayHen);
        if (!checkNgayHen.isValid() == true) {
            _notify.current.Show("Thời gian hẹn khám không hợp lệ", NotifyStatus.Warning);
            onScrollToTop();
            return;
        }
        if (!defaultAddress && isNullOrEmpty(newAddress)) {
            _notify.current.Show("Cần nhập địa chỉ mới", NotifyStatus.Warning);
            return;
        }
        Actions.setLoading(true);
        updateGhiChu(caseInfo.id);
        if (isNullOrEmpty(site)) {
            _notify.current.Show("Cần chọn chi nhánh đặt lịch", NotifyStatus.Warning);
            onScrollToTop();
            return;
        }
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            _notify.current.Show("Bắt buộc chọn nguồn khách hàng", NotifyStatus.Warning);
            return;
        }
        const data = {
            mabn: caseInfo.mabn,
            ngayhen: ngayHen,
            newAddress: newAddress,
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            lstChiDinh: dataOrder 
        }
        HotlineServices.updateLichHen(Number(caseInfo.idlichhen), data, site).then(result => {
            if (result.isSuccess) {
                _nguonKhachHang && _nguonKhachHang.current.updateInfo();
                _notify.current.Show("Cập nhật thành công", NotifyStatus.Success);
                notification.success({ message: 'Cập nhật thành công' });
                resetState();
                reload();
            } else {
                _notify.current.Show(result.err?.msgString ?? "Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
                Actions.openMessageDialog( result.err.msgCode ?? "Lỗi", result.err.msgString);
                onScrollToTop();
            }
        }).catch(err => {
            _notify.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
            onScrollToTop();
        }).finally(() => Actions.setLoading(false))
    }

    const onScrollToTop = () => {
        var containerToScroll = document.getElementById('container');
        containerToScroll && containerToScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const onUpdateConsultant = (newData) => {
        let listSelectedServices = [...dataOrder];
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });
        if (props.isCreate) setDataOrder(listSelectedServices);
        else updateConsultant(listSelectedServices);
    }
    
    const updateConsultant = (listSelectedServices) => {
        let model = listSelectedServices.map(x => {
            return { siteId: parseInt(x.chinhanh), NhanVienId: x.consultantId, idChiDinh: x.idChiDinh }
        });
        Actions.setLoading(true);
        ChiDinhService.updateConsultantIndication(model).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công." });
                setDataOrder(listSelectedServices);
                setModalSelectConsultant(false);
            }
            else notification.warning({ message: result.err.msgString });
        }).catch(err => {
            if (err.stack) {
                notification.error({ message: "Lỗi javascript sau khi lưu. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
                return;
            }
            notification.error({ message: "Lưu không thành công. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
        }).finally(() => Actions.setLoading(false))
    }

    const getListHTTKQ = (key = "", page = 1, size = 10) => {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                setListHTTKQ(res.data);
            } else {
                setListHTTKQ([]);
            }
        })
    }

    return (
        <Fragment>
            <HeaderModal title={`${props.isCreate ? "Tạo lịch hẹn lấy mẫu tại nhà" : "Cập nhật lịch hẹn lấy mẫu tại nhà"}`} onClose={() => { onCloseModal() }} />
            <Notify ref={_notify} />
            <div style={{ overflowY: "auto", padding: "0px 10px" }} id="container">
                {!props.isCreate &&
                    <Steps className="mt-2" progressDot>
                        <Step status={stepInfo.mactv ? "finish" : "process"} title="Chỉ định CTV" description={stepInfo.tenctv}></Step>
                        <Step status={stepInfo.mactv ? stepInfo.isCheckin ? "finish" : "process" : "wait"} title="Check-in" description={stepInfo.giocheckin}></Step>
                        <Step status={stepInfo.isCheckin ? stepInfo.isLaymau ? "finish" : "process" : "wait"} title="Lấy mẫu" description={stepInfo.gionhanmau}></Step>
                        <Step status={stepInfo.isLaymau ? stepInfo.hoantat ? "finish" : "process" : "wait"} title="Bàn giao"></Step>
                    </Steps>
                }
                <div className="row mt-2">
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Mã BN:</span><strong className="ml-2">{patientInfo.mabn}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Họ tên:</span><strong className="ml-2">{patientInfo.hoten}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>{patientInfo.onlyyear ? "Năm sinh" : "Ngày sinh"}:</span>
                        <strong className="ml-2">{patientInfo.onlyyear ? moment(patientInfo.ngaysinh, "YYYY/MM/DD").format("YYYY") : moment(patientInfo.ngaysinh, "YYYY/MM/DD").format(FORMAT_DATE)}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Giới tính:</span><strong className="ml-2">{patientInfo.gioitinh}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Điện thoại:</span><strong className="ml-2">{patientInfo.dienthoai}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Email:</span><strong className="ml-2">{patientInfo.email}</strong>
                    </div>
                    <div className="col-md-12 my-1 d-flex">
                        <span style={{ width: '100px' }}>Địa chỉ:</span><strong className="ml-2">{patientInfo.sonha}</strong>
                    </div>
                </div>
                {isnotnull(ngayHenGanNhat) &&
                    <div className='row mt-2'>
                        <div className="col-md-12">
                            <div className='backgroundColorThemes rounded w-100 p-1'>Lịch hẹn đã tạo gần nhất:
                                <strong className="ml-2">{ngayHenGanNhat === null ? '' : moment(ngayHenGanNhat.ngayhen).format(FORMAT_TIME_DATE)}</strong>
                            </div>
                        </div>
                    </div>}
                <Alert type='warning' className='mt-2'
                    message={
                        <div className='row'>
                            {/* <div className="col-md-12"><i>* Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div> */}
                            <div className="col-md-12"><i className='text-danger'>* Việc thay đổi nguồn khách hàng có thể hủy toàn bộ chỉ định ngay lập tức mà <b>không thể hoàn tác.</b></i></div>
                            <div className='col-md-12'><i>* Cần xác nhận <b>nơi khám, nguồn khách hàng, hình thức trả kết quả</b>. Không cho phép điều chỉnh thông tin này khi hoàn tất thao tác tạo mới.</i></div>
                        </div>
                    }
                ></Alert>
                <div className='row mt-2'>
                    <NguonKhachHang ref={_nguonKhachHang}
                        managermentId={managementId}
                        chiNhanhDatLich={site}
                        updateInfoBangGia={(info) => {
                            let bgia = info.banggia;
                            if (bgia == null || bgia == '' || bgia == undefined) {
                                bgia = bangGiaTheoSite
                                if (bgia == null || bgia == '' || bgia == undefined)
                                    bgia = 'default';
                            }
                            setBangGiaTheoNguonKH(info.banggia);
                            checkBangGia({ banggia: bgia });
                        }}
                        setBangGiaDefault={false}
                        disable={!props.isCreate}
                    />
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                        <Select
                            placeholder="Chọn chi nhánh"
                            style={{ width: '100%' }}
                            value={site}
                            onChange={(value, item) => {
                                //cần xét trường hợp load để edit (có sẵn banggiaTheoNguonKH theo nguonKH đã lưu), thì set banggiaTheoNguonKH từ lúc mới load
                                if (bangGiaTheoNguonKH == null || bangGiaTheoNguonKH == '' || bangGiaTheoNguonKH == undefined) {
                                    let bgia = item.item.banggia;
                                    if (bgia == null || bgia == '' || bgia == undefined)
                                        bgia = 'default';
                                    checkBangGia({ banggia: bgia });
                                }
                                setBangGiaTheoSite(item.item.banggia);
                                setSite(value);
                            }}
                            disabled={!props.isCreate}
                        >
                            {listSite && listSite.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} item={item}>{item.ten}</option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <label>Hình thức trả kết quả<Tooltip title={`Chỉ áp dụng cho lần đăng ký này, chỉ lưu được khi có chỉ định.${!props.isCreate ? " Nếu muốn cập nhật, vui lòng chuyển đến màn hình đăng ký." : ""}`}>&nbsp;<i className='far fa-question-circle' /></Tooltip></label>
                        <Select value={sendBy} placeholder="Chọn hình thức trả kết quả" className='w-100'
                            onChange={(value) => {
                                setSendBy(value);
                            }}
                            showSearch
                            disabled={!props.isCreate}
                            onSearch={(e) => {
                                if (taskDelay) clearTimeout(taskDelay);
                                taskDelay = setTimeout(() => {
                                    getListHTTKQ(e);
                                }, 400);
                            }}
                            mode='multiple'
                            filterOption={(input, option) => {
                                return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                    option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }}>
                            {
                                listHTTKQ && listHTTKQ.map((x, index) => {
                                    return (
                                        <Select.Option key={x.id} value={x.keyValue}>{x.keyName}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="row mt-2" >
                    <div className='col-12'>
                        <label>Ghi chú</label>
                        <TextArea rows={5} value={initDetail} readOnly={true}
                        ></TextArea>
                    </div>
                    <div className="col-12 d-flex mt-2" >
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }}
                            onChange={(e) => {
                                setDetail(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && e.ctrlKey) {
                                    onChangeDetail();
                                }
                            }}
                            value={detail}
                            placeholder="Nhập ghi chú và bấm thêm hoặc bấm Ctrl+Enter">
                        </TextArea>
                        <button style={{ width: 90 }} className={`btn ${!isNullOrEmpty(detail) ? "btn-warning-sparkle" : "btn-color"} ml-2`}
                            onClick={() => { onChangeDetail() }}><i className="fa fa-plus mr-1"></i>Thêm</button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <span style={{ width: '80px' }}>Ngày hẹn: </span>
                        <DateTimePicker
                            className="w-100"
                            locale="vi"
                            calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                            clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                            format={'dd-MM-yyyy HH:mm'}
                            isClockOpen={false}
                            isCalendarOpen={false}
                            onChange={setNgayHen}
                            value={ngayHen ? ngayHen : new Date()}
                            minDate={moment(ngayHen).year() > 1 && moment(ngayHen) < moment() ? moment(ngayHen).toDate() : moment().toDate()}
                        />
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{defaultAddress ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                        <Input
                            placeholder={defaultAddress ? "" : "Nhập địa chỉ lấy mẫu"}
                            value={defaultAddress ? patientInfo.sonha : newAddress}
                            disabled={defaultAddress}
                            prefix={<button className={defaultAddress ? "btn btn-secondary btn-sm mr-2" : "btn btn-color btn-sm mr-2"}
                                onClick={(e) => { e.preventDefault(); setDefaultAddress(!defaultAddress); setNewAddress("") }}>{defaultAddress ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                            onChange={(e) => setNewAddress(e.target.value)}
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <div className="border-register">
                            <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                <div className="h4 font-weight-bold">Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) =>
                                    tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>
                                <Button className="btn btn-secondary ml-auto" onClick={e => {
                                    e.stopPropagation();
                                    setVisibleOrder(true);
                                }}>Thêm/Sửa chỉ định</Button>
                            </div>
                            <div className="collapse mt-2" id="chidinh">
                                <div className="table-responsive">
                                    <Table dataSource={dataSource}
                                        rowKey={(e) => e.id}
                                        columns={regiterServiceColumn}
                                        pagination={false}
                                        loading={loadingTable}
                                        scroll={{ y: 0 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-hr'></div>
                <ConsultantWarning visible={dataOrder.length > 0 && dataOrder.some(x => isNullOrEmpty(x.consultantId))} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn btn-color" onClick={() => {
                        props.isCreate ? saveLichHen() : updateLichHen()
                    }}> <i className="fa fa-save mr-1" />{props.isCreate ? "Thêm" : "Cập nhật"}</button>
                    <button className="btn btn-danger ml-2" onClick={() => {
                        onCloseModal();
                    }}><i className="fas fa-sign-out-alt mr-1" />Đóng</button>
                </div>
                <Modal
                    width={1400}
                    visible={visibleOrder}
                    onCancel={() => setVisibleOrder(false)}>
                    {
                        (Number(caseInfo.idlichhen) > 0) ?
                            <AddOrder
                                chinhanh={site}
                                ref={_orderRef}
                                selectedPatient={{
                                    mabn: caseInfo.mabn,
                                    maql: caseInfo.maql,
                                    ngayhen: caseInfo.ngayhen,
                                    id: caseInfo.idlichhen,
                                }}
                                reLoad={() => { getListOrderById(caseInfo.idlichhen) }}
                                onClose={() => {
                                    setVisibleOrder(false);
                                }}
                                banggia={bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia}
                                disableSelectBangGia={!props.allowToChangeServicePriceList} />
                            :
                            <ModalCLS
                                showGoiKham={false}
                                showCombo={ALLOW_COMBO}
                                maskClosable={false}
                                ref={_orderRef}
                                onClose={(data) => {
                                    setVisibleOrder(false);
                                    setDataOrder(data);
                                }}
                                banggia={bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia}
                                disableSelectBangGia={!props.allowToChangeServicePriceList}
                                editAfterRegister={true}
                            />
                    }
                </Modal>
                <Modal
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={modalSelectConsultant}
                    destroyOnClose={true}
                    width={1000}>
                    <SelectConsultant
                        isDialog={true}
                        dataSource={dataOrder.map(x => ({ ...x }))}
                        onClose={() => { setModalSelectConsultant(false) }}
                        onSubmit={(values) => {
                            onUpdateConsultant(values);
                            setModalSelectConsultant(false);
                        }}
                    />
                </Modal>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
		coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
		allowToChangeServicePriceList: state.global.sysConfig.allowToChangeServicePriceList ? state.global.sysConfig.allowToChangeServicePriceList : false,
        consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaoCapNhatLichHenLayMauHotline)