import { EditOutlined } from '@ant-design/icons';
import {
    Button, DatePicker, Drawer, Form, Input, Modal, notification, Pagination, Select, Popconfirm,
    Table, Tag, Dropdown, Menu, Upload, Tooltip, Checkbox
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as AdministrativeService from '../../services/administrativeService';
import * as PatientService from '../../services/patientService';
import * as RegisMediService from '../../services/regisMediService';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as ConfigService from '../../services/configService';
import History from '../../components/history';
import * as Actions from '../../libs/actions';
import ModalDST from './modal/dausinhton';
import ModalLienHe from './modal/lienhe';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { ValidationCustom } from '../../commons/validationCustom';
import DetailHoSo from '../exam/hoso/chitiet'
import Administrative from '../recept/components/administrative';
import { GetListBangGia } from '../../configureHIS/update_vp/services/VPService';
import { GetList } from '../corporation/CorporationServices';
import * as WorldfoneService from '../../services/worldfoneService';
import LoginWF from '../hotline/loginWF';
import * as SystemConfig from '../../configure/systemConfig';

const regEx = new RegExp('(^[a-zA-Z])');
const { Option } = Select;
const { Dragger } = Upload;
const initialState = {
    key: '',
    coporatorFilter: null,
    listCoporatorsFilter: [],
    sodienthoai: '',
    mabn: '',
    hoten: '',
    gender: '',
    phone: '',
    email: '',
    cmnd: '',
    ngaysinh: moment(),
    ward: '',
    district: '',
    city: '',
    loadingZalo: false,
    txtZalo: '',
    totalRecords: 0,
    visibleLienHe: false,
    visibleDST: false,
    currentNumber: 1,
    diachi: '',
    coporator: "",
    listCoporators: [],
    onlyyear: false,
    // hinhthucthanhtoan: null,
    hinhthucthanhtoan: 1,
}
const lstMarketing = [
    {key : 'vanglai', value: 'Vãng Lai'},
    {key : 'facebook', value: 'Facebook'},
    {key : 'zalo', value: 'Zalo'},
    {key : 'website', value: 'Website'}
]
const LIST_PATIENT_MODE = {
    NORMAL: 'Normal', 
    READ: 'Read'
}
export default class QuanLyBenhNhan extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            //state for List Patient Dialog
            isDlg: this.props.isDlg,
            mode: this.props.mode ?? LIST_PATIENT_MODE.NORMAL,
            indeterminateOfChkAllForPopup: false,
            checkedOfChkAllForPopup: false,
            listPatientForPopup: [],

            lstBN: [],
            lstCity: [],
            lstDistrict: [],
            lstWard: [],
            lstDoctor: [],
            visible: false,
            visiableHoSo: false,
            visibleZalo: false,
            HistoryDrawer: false,
            mabs: '',
            isEdit: false,
            idLichhen: '',
            ngayhen: moment(),
            patient: {},
            ngayhengannhat: {},
            selectedId: '',
            fileList: [],
            uploading: false,
            insertRow: 0,
            errorRow: 0,
            errorList: "",
            rejectRow: 0,
            rejectList: "",
            updateRow: 0,
            updateList: "",
            successInfo: "",
            showImportResult: false,
            visibleImportInfo: false,
            listBangGia: [],
            banggia: "default",
            mactv: "",
            lstCTV: [],
            isLayMau: false,
            msgCode: "",
            isLoginWF: false,
            titleWF: '',
            lstMarketing : [],
            marketing : [],
        }
    }
    componentDidMount() {
        this.loadDanhSach();
        this.loadCity();
        this.DanhSachBacSi();
        this.loadCoporation();
        this.loadCoporationFilter();
        this.loadMarketing();
    }
    loadDanhSach(key = '', page = 1, size = 10) {
        this.setState({ key: key }, () => {
            PatientService.GetListBN(this.state.key, this.state.coporatorFilter ?? '', page, size).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstBN: result.data,

                    });
                    if (result.data.length > 0) {
                        this.setState({ totalRecords: result.data[0].totalRecords })
                    }
                }
                this.setStatusOfChkAllForPopup();
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu danh sách bệnh nhân bị lỗi' });
            })
        })
    }
    loadCity(pageNumber, pageSize) {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data,

                });
                this.form.setFieldsValue({
                    district: null,
                    ward: null
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    loadDistrict(id, pageNumber, pageSize) {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDistrict: result.data,
                    lstWard: []
                });
            }
            else {
                this.form.setFieldsValue({
                    district: null,
                    ward: null
                });
                this.setState({
                    lstDistrict: [],
                    lstWard: [],
                    district: null,
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ quận huyện thị bị lỗi' })
        })
    }
    loadWard(id, pageNumber, pageSize) {

        AdministrativeService.GetWardByDistrictId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstWard: result.data,
                });
            }
            else {
                this.form.setFieldsValue({
                    ward: null
                });
                this.setState({
                    loadWard: [],
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }
    DanhSachBacSi() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }
    searchBN() {
        if (this.state.key === null || this.state.key === '') {
            this.loadDanhSach();
            return;
        }
        PatientService.SearchBenhNhan(this.state.key).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        lstBN: result.data,
                    });
                }
                else {
                    this.setState({
                        lstBN: [],
                    });
                }
            }
            this.setStatusOfChkAllForPopup();
        }).catch(err => {
        })
    }

    async updateBN() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                patientID: this.state.mabn,
                PatientName: this.state.hoten,
                birthDay: this.state.ngaysinh,
                gender: this.state.gender,
                phone: this.state.sodienthoai,
                email: this.state.email,
                cardId: this.state.cmnd,
                ward: this.state.ward !== null ? this.state.ward : 0,
                district: this.state.district !== null ? this.state.district : 0,
                city: this.state.city,
                patientName: this.state.hoten,
                address: this.state.diachi,
                coporator: this.state.coporator,
                onlyyear: this.state.onlyyear,
                marketing : this.state.marketing.join(';'),
            }
            PatientService.Update(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        //disabledUpdate: true,
                        // editState: false,

                        currentNumber: 1
                    })
                    this.loadDanhSach();
                    notification.success({ message: 'Cập nhật thành công!' })
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
                notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
            })
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    func(row) {
        if(row.marketing !== '' && row.marketing !== null){
            this.setState({
                marketing : row.marketing.split(';')
            })
        }
        this.setState({
            diachi: row.address,
            mabn: row.patientID, 
            hoten: row.patientName,
            gender: row.gender, 
            ngaysinh: row.birthDay,
            ward: row.ward, 
            district: row.district,
            city: row.city, 
            sodienthoai: row.phone,
            email: row.email, 
            cmnd: row.cardId,
            coporator: row.coporator, 
            onlyyear: row.onlyyear,
        }, () => {
            this.loadDistrict(row.city);
            this.loadWard(row.district);
            this.form.setFieldsValue({
                diachi: row.address,
                district: this.state.district,
                ward: this.state.ward,
                email: this.state.email,
                sodienthoai: row.phone,
                cmnd: this.state.cmnd,
                ngaysinh: moment(this.state.ngaysinh),
                coporator: row.coporator
            });
            this.showModal();
        })

    }
    closeModal() {
        this.setState({ visibleDST: false, visibleLienHe: false })
    }
    showModal = () => {

        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.form.setFieldsValue({
            city: null,
            district: null,
            ward: null
        });
        this.setState({
            visible: false,
            //disabledUpdate: true,
            visibledUpdate: false,
            visibledSave: true,
            visibleLichHen: false
        });
    };
    updateCheckZalo(mabn, check) {
        this.setState({ loadingZalo: true })
        PatientService.updateCheckZalo(mabn, check).then(result => {
            let data = this.state.lstBN;
            let item = data.find(x => x.patientID === mabn);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.zalo;
                    item.zalo = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.zalo;
                    item.zalo = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstBN: data,
                loadingZalo: false,
            })
        }).catch(err => {
            let data = this.state.lstBN;
            let item = data.find(x => x.patientID === mabn);
            if (item !== undefined) {
                const newcheck = item.zalo;
                item.zalo = newcheck;
            }
            this.setState({ loadingZalo: false, lstBN: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    updateUserZalo(mabn) {
        PatientService.updateUserZalo(mabn, this.state.txtZalo).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                this.loadDanhSach();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    disabledDate(current) {
        return current && (current > moment().endOf('day') || current < moment("1800-01-01"));
    }
    async CreatePatient() {
        let data = this.administrative.getState();
        data.city = data.city ? data.city.toString() : '';
        data.district = data.district ? data.district.toString() : '';
        data.ward = data.ward ? data.ward.toString() : '';
        data.subjectCode = '2';
        data.people = '25';
        let numberError = 0;
        await this.administrative.getForm().validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length

        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        if (this.administrative.validator.allValid()) {
            if (numberError === 0) {
                Actions.setLoading(true);
                RegisMediService.CreatePatient(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        this.setState({ visiableCreate: false });
                        this.loadDanhSach();
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString)
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", err);
                    Actions.setLoading(false);
                })
            }
        }
    }
    NgayHenGanNhat(isLayMau = false) {
        AppointmentService.NgayHenGanNhat(this.state.patient.patientID, isLayMau).then(result => {
            if (result.isSuccess) {
                this.setState({ ngayhengannhat: result.item })
            }
            else {
                this.setState({ ngayhengannhat: {} })
            }
        })
    }
    SubmitLichHen() {
        if (this.state.isLayMau) {
            if (this.validator.fieldValid("Cộng tác viên")) {
                const data = {
                    mabn: this.state.patient.patientID,
                    ngayhen: this.state.ngayhen,
                    mactv: this.state.mactv
                }
                AppointmentService.LuuLichHenLayMau(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                    } else {
                        notification.warning({ message: result.err.msgString });
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
                    Actions.setLoading(false);
                })
            } else {
                this.validator.showMessageFor("Cộng tác viên");
                this.forceUpdate();
            }
        } else {
            const data = {
                mabs: this.state.mabs,
                mabn: this.state.patient.patientID,
                ngayhen: this.state.ngayhen.format("YYYY/MM/DD HH:mm"),
            }
            Actions.setLoading(true);
            if (this.state.isEdit === true) {
                AppointmentService.updateAppointment(this.state.idLichhen, data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Cập nhật thành công!' });
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString)
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", err);
                    Actions.setLoading(false);
                })
            }
            else {
                AppointmentService.LuuLichHen(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        this.setState({ isEdit: true, idLichhen: result.id });
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString)
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", err);
                    Actions.setLoading(false);
                })
            }
        }
    }
    resetPassword(row) {
        CommonService.resetMatKhau(row.patientID).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Đã reset mật khẩu cho " + row.patientName })
            }
            else {
                notification.error({ message: "Thao tác lỗi" })
            }
        })
    }
    getImportTemplate = () => {
        Actions.setLoading(true);
        PatientService.mauImportDanhSachBenhNhan(this.state.banggia).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Mau-Import-Danh-Sach-Benh-Nhan";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    handleUpload = () => {
        // if (this.state.hinhthucthanhtoan !== null && this.state.hinhthucthanhtoan !== undefined) {
            Actions.setLoading(true);
            const { fileList } = this.state;
            const formData = new FormData();
            formData.append('file', fileList[0]);
            this.setState({
                uploading: true
            });
            PatientService.importDanhSachBenhNhan(formData, this.state.hinhthucthanhtoan).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        uploading: false,
                        fileList: [],
                        insertRow: res.returnObject.insertRow,
                        errorRow: res.returnObject.errorRow,
                        errorList: res.returnObject.errorList,
                        rejectRow: res.returnObject.rejectRow,
                        rejectList: res.returnObject.rejectList,
                        updateRow: res.returnObject.updateRow,
                        updateList: res.returnObject.updateList,
                        successInfo: res.returnObject.successInfo,
                        showImportResult: true,
                        // hinhthucthanhtoan: null,
                        hinhthucthanhtoan: 1,
                    });
                    this.loadDanhSach();
                    Actions.setLoading(false);
                }
                else {
                    notification.warning({ message: res.err.msgString });
                    this.setState({
                        uploading: false,
                        showImportResult: true,
                        visibleImportInfo: true,
                        errorRow: res.returnObject.errorRow,
                        errorList: res.returnObject.errorList,
                        rejectRow: res.returnObject.rejectRow,
                        rejectList: res.returnObject.rejectList,
                        updateRow: res.returnObject.updateRow,
                        updateList: res.returnObject.updateList,
                        msgCode: res.err.msgCode,
                        successInfo: res.returnObject.successInfo,
                        fileList: [],
                    });
                    Actions.setLoading(false);
                }
            }).catch(err => {
                this.setState({
                    uploading: false
                });
                Actions.setLoading(false);
            })
        // } else {
        //     notification.warning({ message: "Chưa chọn hình thức thanh toán" })
        // }
    }
    GetListBangGia = () => {
        GetListBangGia().then(result => {
            if (result.isSuccess) {
                this.setState({
                    listBangGia: result.data
                });
            }
        }).catch(err => {
        })
    }

    //Danh sách cộng tác viên
    DanhSachCTV(page = 1, size = 10, getAll = false) {
        CommonService.GetListCollaborators(page, size, getAll).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCTV: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cộng tác viên bị lỗi!' })
        })
    }

    loadCoporation(key = "", page = 1, size = 10) {
        GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporators: res.data,
                })
            }
        })
    }

    loadCoporationFilter(key = "", page = 1, size = 10) {
        GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporatorsFilter: res.data,
                    coporatorFilter: null
                })
            }
        })
    }

    loadMarketing(){
        ConfigService.GetKenhKhachHang(1,100,false).then(result => {
            if(result.isSuccess){
                this.setState({
                    lstMarketing : result.data
                })
            }else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {

        })
    }

    MakeCall(row) {
        if (row.phone === '' || row.phone.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            this.setState({ isLoginWF: true });
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.phone, row.patientName, tokenWF).then(result => {
            if (result.status !== 1) {
                this.setState({ titleWF: 'Vui lòng đăng nhập Worldfone', isLoginWF: true });
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true,'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }
    checkPassport = (value) => {
        return regEx.test(value);
    }
    addMarkerting = (e) => {
        let lst = this.state.marketing;
        if(e.target.checked){
            lst.push(e.target.value.toString());
        } else { 
            lst = lst.filter((item) => item !== e.target.value.toString())
        }
        this.setState({ marketing : lst });
    }
    //check SL các patient đang chọn (trong trang hiện tại) có bằng SL this.state.lstBN hay không
    //lấy trị tuyệt đối Sl listSelectedPatientId (theo trang) trừ SL listPatientId (này theo trang sẵn rồi)
    //- nếu = 0 => checkAll được check. indeterminateOfChkAllForPopup = false
    //- nếu = this.state.lstBN.length => checkAll unchecked. indeterminateOfChkAllForPopup = false
    //- nếu < this.state.lstBN.length => indeterminateOfChkAllForPopup = true
    isSelectedPatientEqualListPatient = () => {
        let listPatientId = this.state.lstBN.map(x => x.patientID);
        let listSelectedPatientId = this.state.listPatientForPopup.map(x => x.patientID);
        listSelectedPatientId = listSelectedPatientId.filter(x => listPatientId.indexOf(x) > -1);
        return Math.abs(listSelectedPatientId.length - listPatientId.length);
    }
    setStatusOfChkAllForPopup() {
        let t = this.isSelectedPatientEqualListPatient();
        switch (t) {
            case 0:
                this.setState({ indeterminateOfChkAllForPopup: false });
                this.setState({ checkedOfChkAllForPopup: true });
                break;
            case this.state.lstBN.length:
                this.setState({ indeterminateOfChkAllForPopup: false });
                this.setState({ checkedOfChkAllForPopup: false });
                break;
            default:
                this.setState({ indeterminateOfChkAllForPopup: true });
                break;
        }
    }
    updateListpatientForPopup(text, record, index) {
        let indexOfList = this.state.listPatientForPopup.map(x => x.patientID).indexOf(record.patientID);
        if (indexOfList >= 0)
            this.state.listPatientForPopup.splice(indexOfList, 1);
        else
            this.state.listPatientForPopup.push(record);
        this.setStatusOfChkAllForPopup();
    }
    checkAll(e) {
        let t = this.isSelectedPatientEqualListPatient();
        //trường hợp đang checkAll (t=0) -> uncheckAll
        if (t == 0) {
            this.state.lstBN.forEach(item => {
                let indexOfList = this.state.listPatientForPopup.map(x => x.patientID).indexOf(item.patientID);
                this.state.listPatientForPopup.splice(indexOfList, 1);
            });
            this.setState({ checkedOfChkAllForPopup: false });
        }
        else {
            this.state.lstBN.forEach(item => {
                let indexOfList = this.state.listPatientForPopup.map(x => x.patientID).indexOf(item.patientID);
                if (indexOfList < 0)
                    this.state.listPatientForPopup.push(item);
            });
            this.setState({ checkedOfChkAllForPopup: true });
        }
        this.setState({indeterminateOfChkAllForPopup:false});
    }
    onCloseListPatientPoptupHandle() {
        this.setState({ 
            isDlg: this.props.isDlg,
            mode: this.props.mode ?? LIST_PATIENT_MODE.NORMAL,
            indeterminateOfChkAllForPopup: false,
            checkedOfChkAllForPopup: false,
            listPatientForPopup: [],
        });
    }
    render() {
        var { msgCode } = this.state;
        // let is = this.props.mode == LIST_PATIENT_VIEW_MODE.READ;
        let columns = [
            {
                title: () => { return <Checkbox onChange={(e) => this.checkAll(e)} id='chkAllForPopup' 
                        indeterminate={this.state.indeterminateOfChkAllForPopup}
                        checked={this.state.checkedOfChkAllForPopup}
                    ></Checkbox>
                },
                // dataIndex: 'dataIndexPatientID',
                key: 'keyPatientID',
                isHideColumn: !this.state.isDlg,
                width: this.state.isDlg ? 54 : 0,
                width: 54,
                align: 'center',
                render: (text, record, index) => {
                    if (this.state.isDlg) {
                        return <Checkbox checked={this.state.listPatientForPopup.map(x => x.patientID).indexOf(record.patientID) > -1}
                            onChange={() => this.updateListpatientForPopup(text, record, index)}></Checkbox>;
                    }
                    return;
                },
                
            },
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
                width: 150,
            },
            {
                title: 'Họ tên',
                dataIndex: 'patientName',
                key: 'patientName',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Giới tính',
                dataIndex: 'gender',
                key: 'gender',
                width: 120,
                render: (value) => {
                    return <span>{value === 0 ? "Nam" : "Nữ"}</span>;
                },
            },
            {
                title: 'Số CMND',
                dataIndex: 'cardId',
                key: 'cardId',
                width: 120
            },
            {
                title: 'SĐT',
                dataIndex: 'phone',
                key: 'phone',
                width: 120
            },

            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                align: 'left'
            },
            {
                title: 'Action',
                align: 'center',
                isHideColumn: this.state.mode !== LIST_PATIENT_MODE.NORMAL,
                render: (row) => {
                    if (this.state.mode !== LIST_PATIENT_MODE.NORMAL) return;
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <Tooltip title="Gọi điện">
                                    <Tag className="btn btn-success"
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        onClick={() => {
                                            this.setState({ patient: row })
                                            this.MakeCall(row);
                                        }} >
                                        <i className='fas fa-phone'></i>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Xem hồ sơ">
                                    <Tag className="btn btn-info"
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        onClick={() => {
                                            this.setState({ patient: row, visiableHoSo: true })
                                        }} >
                                        <i className='fas fa-file-medical'></i>
                                    </Tag>
                                </Tooltip>
                                {/* <Tooltip title="Tạo lịch hẹn khám">
                                    <Tag className="btn btn-color"
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        onClick={() => {
                                            this.setState({ patient: row, visibleLichHen: true }, () => { this.NgayHenGanNhat(); this.DanhSachCTV() })
                                        }} >
                                        <i className='fas fa-calendar-check'></i>
                                    </Tag>
                                </Tooltip>
                                <Tooltip title="Tạo lịch hẹn lấy máu">
                                    <Tag className="btn btn-color"
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        onClick={() => {
                                            this.setState({ patient: row, visibleLichHen: true, isLayMau: true }, () => { this.NgayHenGanNhat(true); this.DanhSachCTV() })
                                        }} >
                                        <i className='fas fa-vial'></i>
                                    </Tag>
                                </Tooltip> */}
                                <Tooltip title="Chỉnh sửa">
                                    <Tag
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        className="btn btn-warning"
                                        title="Thông tin hành chánh"
                                        onClick={() => this.func(row)} >
                                        <i className='fas fa-cogs'></i>
                                    </Tag>
                                </Tooltip>
                                {/* <Tooltip title="Reset mật khẩu">
                                    <Tag
                                        style={{ marginRight: 5, marginTop: 5 }}
                                        className="btn btn-danger"
                                        title="Đặt mật khẩu về mặc định"
                                        onClick={() => this.resetPassword(row)} >
                                        <i className='fas fa-key'></i>
                                    </Tag>
                                </Tooltip> */}
                            </div>
                        </div>
                    )
                }
            }
        ];
        columns = columns.filter(x => !(x.isHideColumn ?? false));
        let { fileList } = this.state;
        const props = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                    successInfo: "",
                }))
                return false;
            },
            fileList
        };
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.setState({ vsbSelectBangGia: true }, this.GetListBangGia())}>Tải file excel mẫu</button>
                </Menu.Item>
                <Modal
                    width={600}
                    maskClosable={true}
                    visible={this.state.vsbSelectBangGia}
                    onCancel={() => this.setState({ vsbSelectBangGia: false, banggia: "default" })}>
                    <Form.Item label="Bảng giá áp dụng" className="mt-2 d-flex justify-content-center align-items-center">
                        <Select value={this.state.banggia}
                            style={{ width: "100%" }}
                            onChange={e => this.setState({ banggia: e })}>
                            {/* <Option key={-1} value="default">Mặc định</Option> */}
                            {this.state.listBangGia.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <button className="btn btn-color mt-4" onClick={() => this.getImportTemplate()}>
                        Tải về mẫu import với danh mục bảng giá: <b>{this.state.banggia === "default" ? "Mặc định" : this.state.banggia}</b></button>
                </Modal>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ visibleImport: true })}>Import dữ liệu</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={600}
                    visible={this.state.visibleImport}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className='text-danger text-center'><i>Theo yêu cầu, chức năng tự động thanh toán sẽ ngừng hoạt động kể từ ngày 17/05/2022.<br/>Sau khi hoàn tất import anh/chị vui lòng thanh toán bằng tay!</i></div>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        {/* <div className="form-group w-100">
                            <label>Hình thức thanh toán</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label><Tooltip title="Áp dụng hình thức thanh toán bên dưới cho tất cả bệnh nhân trong danh sách nếu có đăng ký dịch vụ">&nbsp;<i className='far fa-question-circle' /></Tooltip>
                            <Select className='w-100 text-center'
                                placeholder="Chọn hình thức thanh toán"
                                onChange={(e) => this.setState({ hinhthucthanhtoan: e })}
                                value={this.state.hinhthucthanhtoan}>
                                <Option key={1} value={1}>Tiền mặt</Option>
                                <Option key={2} value={2}>Chuyển khoản</Option>
                                <Option key={3} value={3}>Quẹt thẻ</Option>
                                <Option key={4} value={4}>Công nợ</Option>
                            </Select>
                        </div> */}
                        <div style={{ width: "100%" }}>
                            <Dragger
                                {...props}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}>
                                <div>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                            <p style={{ textTransform: "uppercase", textAlign: "center", display: this.state.showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                            <p style={{ display: this.state.insertRow > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="green">{`${this.state.insertRow}`}</Tag> bệnh nhân.</p>
                            <p style={{ display: this.state.rejectRow > 0 ? 'flex' : 'none' }}>
                                <Tooltip title="Thông tin bắt buộc nhập: Tên, ngày sinh, giới tính, số điện thoại">Thiếu (sai) thông tin:</Tooltip>{'\u00A0'}<Tag color="red">{`${this.state.rejectRow}`}</Tag> bệnh nhân.</p>
                            <p style={{ display: this.state.rejectRow > 0 ? 'initial' : 'none', color: "red" }}>Bao gồm:{'\u00A0'}<br />{`${this.state.rejectList}`}</p>
                            <p style={{ display: this.state.errorRow > 0 ? 'flex' : 'none' }}>
                                Trùng mã xét nghiệm (SID):{'\u00A0'}<Tag color="red">{`${this.state.errorRow}`}</Tag> bệnh nhân.</p>
                            <p style={{ display: this.state.errorRow > 0 ? 'initial' : 'none', color: "red" }}>Bao gồm:{'\u00A0'}<br />{`${this.state.errorList}`}</p>
                            <p style={{ display: this.state.errorRow === 0 && this.state.errorList ? 'initial' : 'none', color: "red" }}>{`${this.state.errorList}`}</p>
                            <p style={{ display: this.state.updateRow > 0 ? 'flex' : 'none' }}>
                                <Tooltip title="Bệnh nhân trùng tên, giới tính và số điện thoại">Cập nhật thông tin:</Tooltip>{'\u00A0'}<Tag color="orange">{`${this.state.updateRow}`}</Tag> bệnh nhân.</p>
                            <p style={{ display: this.state.updateRow > 0 ? 'initial' : 'none', color: "#e77a1c" }}>Bao gồm:{'\u00A0'}<br />{`${this.state.updateList}`}</p>
                            <p style={{ display: this.state.successInfo ? 'initial' : 'none', color: "red" }}>{this.state.successInfo}</p>
                            <div style={{ display: (this.state.rejectRow > 0 || this.state.errorList) ? 'unset' : 'none', background: "aliceblue", border: "solid 1px #bbb", borderRadius: 15, padding: 9, cursor: "pointer" }}>
                                <Tooltip title={!this.state.visibleImportInfo ? "Tìm hiểu nguyên nhân và khắc phục" : "Đóng"}>
                                    <i className={!this.state.visibleImportInfo ? "far fa-question-circle" : "far fa-times-circle"}
                                        style={{ fontSize: 18 }}
                                        onClick={() => this.setState({ visibleImportInfo: !this.state.visibleImportInfo })}></i>
                                </Tooltip><br />
                                <div style={{
                                    visibility: this.state.visibleImportInfo ? "visible" : "hidden", display: this.state.visibleImportInfo ? "unset" : "none"
                                }}>
                                    <ol style={{ textAlign: "justify" }}>
                                        {this.state.rejectRow > 0 &&
                                            <span>
                                                <li>Kiểm tra các thông tin bắt buộc (tên, ngày sinh, giới tính, số điện thoại, tỉnh-quận-huyện) đã được nhập hay chưa.</li>
                                                <li>Cần đảm bảo chọn đúng quận/huyện, phường/xã của tỉnh/thành phố tương ứng. Đảm bảo chọn đơn vị hành chính trong danh sách xổ ra từ file excel</li>
                                                <li>Các thông tin cá nhân (điện thoại, CMND/CCCD, email) cần được nhập đúng định dạng.</li>
                                            </span>}
                                    </ol>
                                    <ol style={{ textAlign: "justify" }}>
                                        {msgCode &&
                                            <span>
                                                <h5 className='text-uppercase font-bold text-center'>Các thông tin cần kiểm tra lại</h5>
                                                {msgCode.includes("P001") && <li><b>Thông tin tỉnh/thành, quận/huyện, phường/xã phải chọn từ file excel. Không copy từ nguồn khác.</b></li>}
                                                {msgCode.includes("P002") && <li><b>Số điện thoại phải theo chuẩn số điện thoại Việt Nam (10 số nếu là điện thoại di động). Chỉ có thể dùng dấu (.) hoặc (-) để ngăn cách nhóm 3-4 số.</b></li>}
                                                {msgCode.includes("P003") && <li><b>Số CMND/CCCD phải đúng chuẩn.</b></li>}
                                                {msgCode.includes("P004") && <li><b>Email phải đúng chuẩn.</b></li>}
                                                {msgCode.includes("P005") && <li><b>Giới tính không hợp lệ.</b></li>}
                                                {msgCode.includes("P006") && <li><b>Ngày sinh không hợp lệ.</b></li>}
                                            </span>}
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.handleUpload()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        visibleImport: false,
                                        fileList: [],
                                        insertRow: 0,
                                        rejectRow: 0,
                                        rejectList: "",
                                        errorRow: 0,
                                        errorList: "",
                                        updateRow: 0,
                                        updateList: "",
                                        showImportResult: false,
                                        uploading: false,
                                        successInfo: "",
                                    });
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        );
        return (
            <div className={'containerChilderWrapper'}>
                <Form ref={(c) => this.form = c} style={{ padding: 5 }}>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>

                    <div className="d-flex" style={{ paddingTop: 0 }}>
                        <Input
                            style={{ width: 250, maxWidth: '90vw' }}
                            placeholder="Nhập mã hoặc tên bệnh nhân..."
                            onChange={(e) => {
                                this.setState({ key: e.target.value, currentNumber: 1, });
                                setTimeout(() => { this.loadDanhSach(e.target.value) }, 200);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.setState({ currentNumber: 1, }, () => { this.loadDanhSach(e.target.value); })

                                }
                            }}
                        />

                        <Select 
                            style={{ width: 250, maxWidth: '90vw' }}
                            className="ml-2"
                            value={this.state.coporatorFilter} 
                            placeholder="Chọn nguồn khách hàng"
                            onChange={(e) => {
                                this.setState({ coporatorFilter: e, currentNumber: 1, });
                                this.loadDanhSach(this.state.key);
                            }}
                            showSearch
                            allowClear
                            onSearch={(value) => this.loadCoporationFilter(value)}>
                            {this.state.listCoporatorsFilter && this.state.listCoporatorsFilter.map((copr, index) => {
                                return (
                                    <Option key={index} value={copr.ma}>{copr.ten}</Option>
                                )
                            })}
                        </Select>
                        <Button className="btn btn-color" onClick={() => {
                            this.setState({ currentNumber: 1, }, () => { this.loadDanhSach(this.state.key); })

                        }}><i className="fa fa-search"></i></Button>
                        
                        <Button hidden={!this.state.isDlg} className="btn btn-color ml-2 mr-auto" onClick={() => { this.props.onCloseListPatientPoptup(this.state.listPatientForPopup); this.onCloseListPatientPoptupHandle(); }}>Chọn</Button>
                        <Button hidden={this.state.mode != LIST_PATIENT_MODE.NORMAL} 
                            className="btn btn-success ml-auto" 
                            onClick={() => { this.setState({ visiableCreate: true }) }}><i className="fa fa-plus mr-1"></i>Tạo mới
                        </Button>
                        <Dropdown
                            hidden={this.state.mode != LIST_PATIENT_MODE.NORMAL} 
                            overlay={menu} trigger={['click']}
                            onClick={e => e.preventDefault()}>
                            <Button className="btn btn-outline-primary ml-2"
                                hidden={this.state.mode != LIST_PATIENT_MODE.NORMAL} 
                                style={{ marginLeft: 10 }}>
                                <i className="fa fa-upload" style={{ marginRight: '10px' }} />
                                {this.state.uploading ? "Đang tải lên" : "Nhập danh sách"}
                            </Button>
                        </Dropdown>
                        
                    </div>
                    <div style={{ height: 'calc(100% - 60px)', padding: 10 }}>
                        <Table scroll={{ y: 0 }} pagination={false} dataSource={this.state.lstBN}
                            columns={columns} rowKey={(e, index) => index}
                            onRow={(record) => {
                                return {
                                    onClick: e => {
                                        this.setState({ selectedId: record.patientID })
                                    }
                                };
                            }}
                            rowClassName={(record) => {
                                return `rowCustomSelect ${(this.state.selectedId === record.patientID) ? 'rowCustomSelectActive' : ''}`
                            }}
                        />
                    </div>
                    <Pagination
                        position={['bottomCenter']}
                        current={this.state.currentNumber}
                        style={{ textAlignLast: "center" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentNumber: page }); this.loadDanhSach(this.state.key, page, size) }}
                        onShowSizeChange={(page, size) => this.setState({ currentNumber: 1 }, () => { this.loadDanhSach(this.state.key, size) })}
                        showSizeChanger={true}
                    />
                    <ModalLienHe
                        visible={this.state.visibleLienHe}
                        closeModal={this.closeModal.bind(this)}
                        mabn={this.state.mabn}

                    />
                    {this.state.visibleDST &&
                        <div>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Dấu sinh tồn</h3>
                            </div>
                            <ModalDST
                                visible={this.state.visibleDST}
                                closeModal={this.closeModal.bind(this)}
                                mabn={this.state.mabn}
                            />
                        </div>
                    }
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.visible}
                        closeModal={this.closeModal.bind(this)}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Cập nhật thông tin bệnh nhân</h3>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">
                                <label>Mã bệnh nhân</label>
                                <Form.Item>
                                    <Input disabled
                                        value={this.state.mabn} />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <label>Họ tên</label>
                                <Form.Item>
                                    <Input
                                        // disabled={this.state.disabledUpdate}
                                        maxLength={50}
                                        onChange={(e) => this.setState({ hoten: e.target.value })}
                                        value={this.state.hoten} />
                                    {this.validator.message("Họ và tên", this.state.hoten, "required|maxLen:50")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Tooltip className='mr-2' title="Chỉ lưu năm sinh">
                                    <Checkbox checked={this.state.onlyyear} onChange={() => this.setState({ onlyyear: !this.state.onlyyear })}></Checkbox>
                                </Tooltip>
                                <label>{this.state.onlyyear ? "Năm sinh" : "Ngày sinh"}</label>
                                <Form.Item name="ngaysinh" rules={[{ required: true, message: 'Vui lòng nhập ngày sinh!' }]} >
                                    <DatePicker
                                        // disabled={this.state.disabledUpdate}
                                        allowClear={false}
                                        className="w-100"
                                        locale={viVN}
                                        picker={this.state.onlyyear && "year"}
                                        format={this.state.onlyyear ? "YYYY" : "DD-MM-YYYY"} disabledDate={this.disabledDate}
                                        onChange={(e) => this.setState({ ngaysinh: e })}
                                        value={moment(this.state.ngaysinh)} placeholder="dd-MM-YYYY" />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="form-group row mt-2">
                            <div className="col-md-4">
                                <label>Giới tính</label>
                                <Form.Item>
                                    <Select
                                        // disabled={this.state.disabledUpdate}
                                        onChange={(e) => this.setState({ gender: e })}
                                        value={this.state.gender}>
                                        <Option value={0}>Nam</Option>
                                        <Option value={1}>Nữ</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <label>Điện thoại</label>
                                <Form.Item name="sodienthoai">
                                    <Input
                                        // disabled={this.state.disabledUpdate}
                                        maxLength={10}
                                        placeholder="Nhập số điện thoại"
                                        onKeyPress={(e) => {
                                            if (e.key === " " || isNaN(e.key) === true)
                                                e.preventDefault();
                                        }}
                                        onChange={(e) => {
                                            this.setState({ sodienthoai: e.target.value });
                                            this.validator.showMessages();
                                        }}
                                        value={this.state.sodienthoai} />
                                    {this.validator.message("Số điện thoại", this.state.sodienthoai, 'phonenumber')}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <label>Email</label>
                                <Form.Item name="email">
                                    <Input type="email"
                                        // disabled={this.state.disabledUpdate}
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value });
                                            this.validator.showMessages();
                                        }}
                                        placeholder="Nhập email" />
                                    {this.validator.message("Địa chỉ email", this.state.email, "email")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="form-group row mt-2">
                            <div className="col-md-4">
                                <label>Tỉnh/TP</label>
                                <Form.Item>
                                    <Select onChange={(value) => {
                                        if (value === 0) {
                                            this.validator.purgeFields();
                                        }
                                        this.setState({ city: value, district: null, ward: null });
                                        this.form.setFieldsValue({ district: "", ward: "" });
                                        this.loadDistrict(value)
                                        // let address = '';

                                        // for (let city of this.state.lstCity) {
                                        //     if (value === city.value.toString()) {
                                        //         address += city.label;
                                        //     }
                                        // }
                                        // this.setState({ diachi: address });
                                        // this.form.setFieldsValue({
                                        //     diachi: address
                                        // });
                                    }}
                                        showSearch
                                        // disabled={this.state.disabledUpdate}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={this.state.city}
                                    >

                                        {this.state.lstCity.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Tỉnh/Thành phố", this.state.city, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <label>Quận/Huyện</label>
                                <Form.Item name="district">
                                    <Select
                                        // disabled={this.state.disabledUpdate}
                                        value={this.state.district}
                                        onChange={(value) => {
                                            this.setState({ district: value, ward: null }); this.loadWard(value, 1, 100);
                                            this.form.setFieldsValue({ ward: "" });
                                            // let address = '';
                                            // for (let district of this.state.lstDistrict) {
                                            //     if (value === district.value.toString()) {
                                            //         address += district.label + ', ';
                                            //     }
                                            // }
                                            // if (this.state.lstCity)
                                            //     for (let city of this.state.lstCity) {
                                            //         if (this.state.city.toString() === city.value.toString()) {
                                            //             address += city.label;
                                            //         }
                                            //     }
                                            // this.setState({ diachi: address });
                                            // this.form.setFieldsValue({
                                            //     diachi: address
                                            // });
                                        }}>
                                        {this.state.lstDistrict.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.state.city !== 0 && this.validator.message("Quận/huyện", this.state.district, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <label>Phường/Xã</label>
                                <Form.Item name="ward">
                                    <Select
                                        // disabled={this.state.disabledUpdate}
                                        value={this.state.ward}
                                        onChange={(value) => {
                                            this.setState({ ward: value });
                                            // let address = '';
                                            // for (let ward of this.state.lstWard) {
                                            //     if (value === ward.value.toString()) {
                                            //         address += ward.label + ', ';
                                            //     }
                                            // }
                                            // if (this.state.lstDistrict)
                                            //     for (let dis of this.state.lstDistrict) {
                                            //         if (this.state.district.toString() === dis.value.toString()) {
                                            //             address += dis.label + ', ';
                                            //         }
                                            //     }
                                            // if (this.state.lstCity)
                                            //     for (let city of this.state.lstCity) {
                                            //         if (this.state.city.toString() === city.value.toString()) {
                                            //             address += city.label;
                                            //         }
                                            //     }
                                            // this.setState({ diachi: address });
                                            // this.form.setFieldsValue({
                                            //     diachi: address
                                            // });
                                        }}>
                                        {this.state.lstWard.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.state.city !== 0 && this.validator.message("Phường/xã", this.state.ward, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="form-group row mt-2">
                            <div className="col-md-4">
                                <label>{this.checkPassport(this.state.cmnd) ? "Hộ chiếu" : "Căn cước công dân"}</label>
                                <Form.Item name="cmnd">
                                    <Input
                                        // disabled={this.state.disabledUpdate}
                                        // maxLength={12}
                                        placeholder="Nhập căn cước công dân/hộ chiếu"
                                        // onKeyPress={(e) => {
                                        //     if (e.key === " " || isNaN(e.key) === true)
                                        //         e.preventDefault();
                                        // }}
                                        onChange={(e) => {
                                            this.setState({ cmnd: e.target.value });
                                            this.validator.showMessages();
                                        }}
                                        value={this.state.cmnd} />
                                    {this.state.cmnd && this.checkPassport(this.state.cmnd) ?
                                        this.validator.message("Hộ chiếu", this.state.cmnd, "passport|maxLen:11,num")
                                        : this.validator.message("CMND/CCCD", this.state.cmnd, "cmnd|maxLen:20,num")}
                                </Form.Item>
                            </div>
                            <div className="col-md-8">
                                <label>Địa chỉ</label>
                                <Form.Item name="diachi">
                                    <Input
                                        // disabled={this.state.disabledUpdate}
                                        placeholder="Nhập địa chỉ"
                                        onChange={(e) => this.setState({ diachi: e.target.value })}
                                        value={this.state.diachi} />
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 mt-1">
                                <label>Nguồn khách hàng</label>
                                <Form.Item name="coporator">
                                    <Select value={this.state.coporator} placeholder="Chọn nguồn khách hàng"
                                        onChange={(e) => {
                                            this.setState({ coporator: e })
                                        }}
                                        showSearch
                                        allowClear
                                        onSearch={(value) => this.loadCoporation(value)}>
                                        {this.state.listCoporators && this.state.listCoporators.map((copr, index) => {
                                            return (
                                                <Option key={index} value={copr.ma}>{copr.ten}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-lg-6 mt-1">
                                <label>Kênh khách hàng</label>
                                <Form.Item name="marketing" >
                                {this.state.lstMarketing && this.state.lstMarketing.map((item, index)=> {
                                    return(
                                        <Checkbox key={index} onChange={(e) => this.addMarkerting(e)} value={item.id} checked={this.state.marketing.includes(item.id.toString())}>{item.ten}</Checkbox>
                                    )})
                                }
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                            {/* {
                                this.state.editState === true ? */}
                            <button
                                className="btn btn-color"
                                onClick={() => this.updateBN()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu
                            </button>
                            {/* 
                                :
                                <Button type="primary"
                                    onClick={() => this.setState({
                                        disabledUpdate: false,
                                        editState: true })}>Cập nhật
                                </Button>
                            }
                             */}
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => { this.setState({ visible: false }) }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng
                            </button>
                        </div>
                    </Modal>
                </Form>
                {this.state.visiableHoSo &&
                    <Modal
                        visible={this.state.visiableHoSo}
                        onCancel={() => { this.setState({ visiableHoSo: false }) }}
                        width={1000}
                    >
                        <DetailHoSo patient={this.state.patient} onClose={() => this.setState({ visiableHoSo: false })} />
                    </Modal>
                }
                {this.state.visibleLichHen &&
                    <Modal
                        visible={this.state.visibleLichHen}
                        onCancel={() => this.setState({ visibleLichHen: false, ngayhengannhat: {}, mabs: '', ngayhen: moment(), isEdit: false })}
                        width={1000}
                    >
                        <div className="h4 font-weight-bold text-center text-uppercase">Tạo lịch hẹn {this.state.isLayMau && " lấy máu tại nhà"}</div>
                        <div className="row">
                            <div className="col-md-6 my-1">
                                Mã BN: <strong className="ml-2">{this.state.patient.patientID}</strong>
                            </div>
                            <div className="col-md-6 my-1">
                                Họ tên:<strong className="ml-2">{this.state.patient.patientName}</strong>
                            </div>
                            <div className="col-md-6 my-1">
                                Ngày sinh:<strong className="ml-2">{this.state.patient.onlyyear ? moment(this.state.patient.birthDay).format("YYYY") : moment(this.state.patient.birthDay).format(SystemConfig.FORMAT_DATE)}</strong>
                            </div>
                            <div className="col-md-6 my-1">
                                Giới tính:<strong className="ml-2">{this.state.patient.gender === 0 ? 'Nam' : 'Nữ'}</strong>
                            </div>
                            <div className="col-md-6 my-1">
                                Điện thoại:<strong className="ml-2">{this.state.patient.phone}</strong>
                            </div>
                            <div className="col-md-6 my-1">
                                Email:<strong className="ml-2">{this.state.patient.email}</strong>
                            </div>
                            <div className="col-md-12 my-1">
                                Lịch hẹn hiện tại:<strong className="ml-2">{this.state.ngayhengannhat === null ? '' : moment(this.state.ngayhengannhat.ngayhen).format(SystemConfig.FORMAT_DATE_TIME)}</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span style={{ width: 120 }}>{this.state.isLayMau ? "Cộng tác viên:" : "Bác sĩ:"}</span>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder={this.state.isLayMau ? 'Chọn cộng tác viên' : "Chọn bác sĩ"}
                                    allowClear={true}
                                    onChange={(e) => { this.state.isLayMau ? this.setState({ mactv: e }) : this.setState({ mabs: e }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.state.isLayMau ?
                                        this.state.lstCTV.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })
                                        :
                                        this.state.lstDoctor.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                {this.state.isLayMau &&
                                    this.validator.message("Cộng tác viên", this.state.mactv, "selection")
                                }
                            </div>
                            <div className="col-md-6">
                                <span style={{ width: '80px' }}>
                                    Ngày hẹn: </span>
                                <DatePicker
                                    locale={viVN}
                                    showTime
                                    format={"DD/MM/YYYY HH:mm"}
                                    className="w-100"
                                    allowClear={false}
                                    disabledDate={(current) => { return current && current.valueOf() < Date.now() }}
                                    onChange={(e) => this.setState({ ngayhen: e })}
                                    value={moment(this.state.ngayhen)} placeholder="dd/MM/YYYY" />
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            <Popconfirm
                                title="Xác nhận lưu lịch hẹn"
                                onConfirm={() => { this.SubmitLichHen() }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className="btn btn-color mr-2"
                                    onClick={() => { }}>{this.state.isEdit === true ? 'Cập nhật' : 'Lưu'}</Button>
                            </Popconfirm>
                            <Button className="btn btn-outline-secondary"
                                onClick={() => {
                                    this.setState({ visibleLichHen: false, ngayhengannhat: {}, mactv: '', mabs: '', ngayhen: moment(), isEdit: false, isLayMau: false });
                                    this.state.isLayMau && this.validator.hideMessageFor("Cộng tác viên");
                                }}>Đóng</Button>
                        </div>
                    </Modal>
                }
                {this.state.visiableCreate &&
                    <Modal
                        visible={this.state.visiableCreate}
                        onCancel={() => this.setState({ visiableCreate: false })}
                        width={1400}
                    >
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Thêm thông tin bệnh nhân</h3>
                        </div>
                        <Administrative ref={((c) => this.administrative = c)}
                            newPatient={true}
                            nguoigioithieu={true}
                            isnotRegister={true}
                        />
                        <div className="text-center mt-2">
                            <Button className="btn btn-color mr-2" onClick={() => { this.CreatePatient() }}>Lưu</Button>
                            <Button className="btn btn-outline-secondary" onClick={() => { this.setState({ visiableCreate: false }) }}>Đóng</Button>
                        </div>
                    </Modal>
                }
                <Drawer
                    maskClosable={true}
                    title="Lịch sử khám bệnh"
                    placement="right"
                    width={400}
                    closable={true}
                    onClose={() => this.setState({ HistoryDrawer: false, selectedPatientID: null })}
                    visible={this.state.HistoryDrawer}
                >
                    {this.state.selectedPatientID &&
                        <History iShowResult={true} patientID={this.state.selectedPatientID}></History>
                    }
                </Drawer>
                <Modal
                    visible={this.state.isLoginWF}
                    onCancel={() => { this.setState({ isLoginWF: false }) }}
                > <LoginWF title={this.state.titleWF}
                    onClose={() => this.setState({ isLoginWF: false })}
                    /></Modal>
            </div>
        )
    }
}