import React, { useEffect, useRef, useState } from 'react';
import HeaderModal from '../../common/headerModal';
import * as VPService from '../services/VPService';
import { setLoading } from '../../../libs/actions';
import { BUTTON } from '../../common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import { Alert, Table } from 'antd';
import NoContent from '../../../components/loading/noContent';

function CoporationLinked(props) {
    const _notify = useRef();
    const [bangGia, setBangGia] = useState(props.bangGia);
    const [listCoporationLinked, setListCoporationLinked] = useState();
    useEffect(() => {
        setBangGia(props.bangGia);
    }, [props.bangGia])
    useEffect(() => {
        getListCoporationLinkedQuotation();
    }, [])
    const onClose = () => {
        props.onClose();
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{index + 1}</span>;
            }
        },
        {
            title: 'Mã',
            dataIndex: 'coporationId',
            key: 'coporationId',
            width: 90,
            align: 'center',
        },
        {
            title: 'Tên',
            dataIndex: 'coporationName',
            key: 'coporationName',
            width: 150,
            align: 'left',
        },
        {
            title: 'Chiết khấu',
            key: 'discount',
            align: 'right',
            width: 90,
            render: (value, cop) => {
                return cop.coporationDiscount.type == "percent" ? `${cop.coporationDiscount.discount}%` : cop.coporationDiscount.discount?.toLocaleString('en-US')
            },
        },
    ];
    const getListCoporationLinkedQuotation = (bg = bangGia) => {
        setLoading(true);
        VPService.GetListCoporationLinkedQuotation(bg).then(result => {
            if (result.isSuccess) {
                setListCoporationLinked(result.data);
            } else {
                showError(result.err.msgString, NotifyStatus.Warning);
            }
        }).catch(err => {
            showError(err.err.msgString);
        }).finally(() => setLoading(false))
    }
    const showError = (msg, type = NotifyStatus.Error) => {
        _notify && _notify.current && _notify.current.Show(msg, type);
    }
    return (
        <div className='list-page'>
            <HeaderModal title={`Danh sách nguồn khách hàng đang sử dụng bảng giá: ${props.bangGia == "default" ? "Mặc định" : props.bangGia}`} onClose={() => { onClose() }} />
            <Notify ref={_notify} />
            <div className='list-page-body'>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listCoporationLinked}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        pagination={false}
                        rowClassName="rowCustom"
                    />
                </div>
            </div>
            <div className='list-page-footer'>
                <Alert type='warning' showIcon message="Lưu ý"
                    description="Các dịch vụ trong bảng giá sẽ được chiết khấu theo cấu hình tại màn hình cấu hình chiết khấu,
                    KHÔNG ĐƯỢC chiết khấu theo danh sách nguồn khách hàng được liệt kê bên trên."
                />
                <div className='d-flex justify-content-end mt-2'>
                    <button className={`${BUTTON.DANGER}`} onClick={() => onClose()}><i className='fas fa-sign-out-alt mr-1' />Đã rõ</button>
                </div>
            </div>
        </div>
    );
}

export default CoporationLinked;