import * as ApiCaller from '../libs/httpRequests';


const prefix = 'SuperAdmin';

export function ExecuteQuery(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/psqlquery`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function QueryHistory(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/psqlquery-history`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};