import React, { useEffect, useState, useRef } from 'react'
import { notification, DatePicker } from 'antd';
import * as Actions from '../../../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as InHospitalService from '../../../../services/inPatientService';
import moment from 'moment';
import CapThuocNoiTru from './capthuoc';
import * as SystemConfig from "../../../../configure/systemConfig";
const { RangePicker } = DatePicker;
function SaoChepThuoc(props) {
    const [makho, setMakho] = useState(props.makho ? props.makho : '');
    const [mabs, setMabs] = useState(props.mabs ? props.mabs : '');
    const [tenkho, setTenkho] = useState('');
    const [ngayCopy, setNgayCopy] = useState(props.ngayCopy ?[moment(props.ngayCopy).add(1, 'days'),moment(props.ngayCopy).add(2, 'days')]:[moment(),moment().add(1, 'days')]);
    const [idbenhtrinh, setIdbenhtrinh] = useState(props.idbenhtrinh ? props.idbenhtrinh : idbenhtrinh);
    const [patient, setPatient] = useState(props.patient ? props.patient : {});
    const _capthuoc = useRef();
    const [ngayTaoDonGoc, setNgayTaoDonGoc] = useState(props.ngayCopy ? moment(props.ngayCopy) : moment());
    useEffect(() => {
        setPatient(props.patient);
    }, [props.patient]);

    useEffect(() => {
        setIdbenhtrinh(props.idbenhtrinh);
        setNgayCopy(props.ngayCopy ?[moment(props.ngayCopy).add(1, 'days'),moment(props.ngayCopy).add(2, 'days')]:[moment(),moment().add(1, 'days')]);
        setNgayTaoDonGoc(props.ngayCopy ? moment(props.ngayCopy) : moment());
        setMabs(props.mabs);
        setMakho(props.makho);
        _capthuoc.current.DanhSachThuocNoiTruTheoBenhTrinh();
    }, [props.idbenhtrinh]);

    const SubmitCopy = () => {
        const dataCapthuoc = _capthuoc.current.getData();
        const patientSubmit = {
            'code': patient.code,
            'cardNumber': patient.cardNumber,
            'department': patient.department,
            'subjectCode': patient.subjectCode,
            'managerId': patient.managerId,
            'doctorReceive': mabs,
            'diagnostic': patient.diagnostic === undefined ? '' : patient.diagnostic,
            'diagnosticName': patient.diagnosticName === undefined ? '' : patient.diagnosticName,
            'enterCode': patient.enterCode === undefined ? '' : patient.enterCode,
        }
        const data = {
            id: '',
            mavaovien: patient.enterCode,
            maql: patient.managerId,
            mabn: patient.code,
            dateFrom: ngayCopy[0].format("YYYY/MM/DD"),
            dateTo: ngayCopy[1].format("YYYY/MM/DD"),
            mabs: mabs,
            patient: patientSubmit,
            listCapThuoc: dataCapthuoc.listCapThuoc,
        }
        Actions.setLoading(true);
        InHospitalService.CopyThuocNoiTruTheoNgay(data).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Sao chép đơn thuốc thành công!' });
                if (props.onClose) {
                    props.onClose();
                }
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }
    return (
        <React.Fragment>
            <div className="font-weight-bold">Sao chép đơn thuốc từ ngày {ngayTaoDonGoc.format(SystemConfig.FORMAT_DATE)}</div>
            <div className="row">
                <div className="col-auto d-flex align-items-center">
                    <div className="mr-1">Chọn kho</div>
                    <select
                        className="form-control"
                        style={{ width: '200px' }}
                        showSearch
                        value={makho}
                        onChange={(e) => { setMakho(e.target.value); setTenkho(e.target.options[e.target.selectedIndex].text) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {props.lstKho.map((row, index) => {
                            return (
                                <option key={index} value={row.value}>{row.label}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-auto d-flex align-items-center">
                    <div className="mr-1">Bác sĩ</div>
                    <select className="form-control"
                        value={mabs}
                        style={{ width: '200px' }}
                        onChange={(value) => { setMabs(value.target.value) }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {props.lstDoctor.map((row, index) => {
                            return (
                                <option key={index} value={row.value}>{row.label}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-auto d-flex align-items-center">
                    <div className="mr-1">Ngày</div>
                    <RangePicker className="fontSize12" format="DD-MM-YYYY" value={ngayCopy} allowClear={false}
                        locale={viVN} onChange={(value => setNgayCopy(value))}
                        //disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                    />
                </div>
            </div>
            <CapThuocNoiTru
                ref={_capthuoc}
                makho={makho}
                tenkho={tenkho}
                showHeader={true}
                scrollY='255px'
                idbenhtrinh={idbenhtrinh}
                managerId={patient.managerId}
                cardNumber={patient.cardNumber}
                subjectCode={patient.subjectCode}
                gender={patient.gender}
                department={patient.department}
                code={patient.code}
                enterCode={patient.enterCode}
                dateIn={ngayCopy}
                noitru={true}
            />
            <div className="text-center">
                <button type="button" className="btn btn-color mr-2"
                    onClick={() => { SubmitCopy() }}
                ><i className="fa fa-save"></i> Xác nhận sao chép</button>
                <button type="button" className="btn btn-outline-secondary"
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}
                ><i className="fa fa-save"></i> Đóng</button>
            </div>
        </React.Fragment>
    )
}

export default SaoChepThuoc;