import moment from 'moment';
import { notification, Modal } from "antd";
import React, { Fragment, useState, useEffect, useRef } from "react";
import MitaCameraViewer from './Component/mitaCameraViewer';
import ButtonInputCamera from "./Component/mitaCameraButton";

export default (props) => {
    // Mã tính năng (xn, cdha, patient)
    const [featureName, setFeatureName] = useState(props.featureName ? props.featureName : '')
    // Mã component sử dụng (chẩn đoán hình ảnh, xét nghiệm)
    const [componentKey, setComponentKey] = useState('')
    // Id tham chiếu
    const [refId, setRefId] = useState('')
    // Modal
    const [visibleCameraViewer, setVisibleCameraViewer] = useState(false);
    
    useEffect(() => {
        setComponentKey(props.componentKey);
        setRefId(props.refId);
    }, [props])   

    const onSelectedDevice = (device) => {
        props.onSelectedDevice(device);
    }

    const onClose = () => {
        props.onClose();
        setVisibleCameraViewer(false)
    }

    return (
        <Fragment>
            <div>
                <ButtonInputCamera 
                    onSelected = {(device) => { onSelectedDevice(device); setVisibleCameraViewer(true);}}
                    takePhoto = {false}
                    onClose={() => onClose()}
                />
            </div>
            {/* <Modal
                width={'90%'}
                visible={visibleCameraViewer}
                destroyOnClose = {true}
            >
                <MitaCameraViewer 
                    featureName="cdha"
                    componentKey="CDHA-Multimedia"
                    refId={"230215085617532001"} 
                    device={selectedDevice} 
                    onClose={() => onClose() }
                />
            </Modal> */}
        </Fragment>
    )
};


