import { DatePicker, Input, Modal, notification, Tabs, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import TaiCho from './taicho';
import TaiNha from './tainha';
import * as ConfirmOrderService from "../../services/confirmOrderService";
import { connect } from 'react-redux';
import HeaderModal from '../../configureHIS/common/headerModal';
import SIDCard from './SIDCard';
import Notify, { NotifyStatus } from '../../components/notify';
import { IsActive_FeatureLH } from '../../configure/featureConfig';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs

const XacNhanLayMau = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [key, setKey] = useState('');
    const [activeKey, setActiveKey] = useState('1');
    const taichoRef = useRef();
    const tainhaRef = useRef();
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const inputTextKey = useRef();
    const [SID, setSID] = useState("");
    const _SID = useRef();
    const [visibleMutiSID, setVisibleMutiSID] = useState(false);
    const [listSIDInfo, setListSIDInfo] = useState([]);
    const _notify = useRef();

    useEffect(() => {
        if (activeKey === '1') {
            if (taichoRef && taichoRef.current)
                taichoRef.current.GetList()
        } else {
            if (tainhaRef && tainhaRef.current)
                tainhaRef.current.GetList()
        }
    }, [chinhanh])
    useEffect(() => {
        GetListSite();
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
        });
        _SID.current.focus();
        _SID.current.select();
    }, [])

    useEffect(() => {
        setChinhanh(props.siteid);
    }, [props.siteid])

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    
    const notifyUpdate = () => {
        const isLoading = false;
        if (activeKey === '1') {
            if (taichoRef && taichoRef.current)
                taichoRef.current.GetList(isLoading)
        } else {
            if (tainhaRef && tainhaRef.current)
                tainhaRef.current.GetList(isLoading)
        }
    }

    const searchSID = () => {
        ConfirmOrderService.getManagermentIDBySID(SID).then(res => {
            if (res.isSuccess && res.data && res.data.length > 0) {
                const dataLength = res.data.length;
                if (dataLength === 1) {
                    let item = res.data[0];
                    renderConfirmSID(item);
                } else {
                    setVisibleMutiSID(true);
                    setListSIDInfo(res.data);
                    const randomIndex = Math.floor(Math.random() * dataLength);
                    let pickRandom = res.data[randomIndex]
                    let example = `Ví dụ: để chọn khách hàng ${pickRandom.patientName} hãy bấm số ${randomIndex + 1} trên bàn phím máy tính.`;
                    _notify && _notify.current && _notify.current.Show(
                        `Anh/chị vui lòng chọn đúng SID cần xác nhận. Có thể click chuột trái lên thẻ tương ứng hoặc bấm số trên bàn phím được gán tương ứng để chọn. ${example}`
                        , NotifyStatus.Warning);
                }
            } else {
                if (res.err.msgString) notification.warning({ message: res.err.msgString })
                else notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
            }
        }).catch(() => { })
        autoFocusSIDInput();
    }

    const autoFocusSIDInput = () => {
        _SID.current.focus();
        _SID.current.select();
    }
    const clearSIDInput = () => {
        setSID('')
    }
    const onCloseSelectSID = () => {
        setVisibleMutiSID(false);
        _notify && _notify.current && _notify.current.Close();
    }
    const renderConfirmSID = (item) => {
        activeKey === "1" ?
        taichoRef && taichoRef.current.confirmSID(item.managermentID, item.siteID)
        : tainhaRef && tainhaRef.current.confirmSID(item.managermentID, item.siteID);
        setVisibleMutiSID(false);
    }

    return (
        <React.Fragment>
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <div className="h4 text-center font-weight-bold my-2">XÁC NHẬN LẤY MẪU</div>
                    <div className="row justify-content-center align-content-between">
                        {/* {isMultisite === true &&
                            <div className="col-auto mb-2">
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={chinhanh}
                                    onChange={(e) => {
                                        setChinhanh(e)
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        } */}
                        <div className="col-auto mb-2">
                            <RangePicker format="DD-MM-YYYY" value={ngay} allowClear={false}
                                locale={viVN} onChange={value => setNgay(value)}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <input className="form-control" autoFocus style={{ width: "200px" }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (activeKey === '1') {
                                            if (taichoRef && taichoRef.current)
                                                taichoRef.current.GetList()
                                        } else {
                                            if (tainhaRef && tainhaRef.current)
                                                tainhaRef.current.GetList()
                                        }
                                        setKey('');
                                    }
                                }}
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                placeholder="Nhập mã hoặc tên bệnh nhân"
                                ref={inputTextKey}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <button type="button" className="btn btn-color"
                                onClick={() => {
                                    if (activeKey === '1') {
                                        if (taichoRef && taichoRef.current)
                                            taichoRef.current.GetList()
                                    } else {
                                        if (tainhaRef && tainhaRef.current)
                                            tainhaRef.current.GetList()
                                    }
                                    inputTextKey.current.focus();
                                }}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </button>
                        </div>
                        <div className="col-auto mb-2">
                            <Tooltip title="Cần cài đặt Barcode Auto-Enter nếu sử dụng máy quét barcode">
                                <Input value={SID} onChange={(e) => setSID(e.target.value)}
                                    ref={_SID} placeholder="Nhập SID hoặc quét barcode"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            searchSID(e.target.value);
                                        }
                                    }}>
                                </Input>
                            </Tooltip>
                        </div>
                    </div>
                    {!IsActive_FeatureLH() &&
                    <div style={{ height: 'calc(100% - 60px)' }}>
                        <div style={{ backgroundColor: '#fff', height: '100%' }}>
                            <TaiCho ref={taichoRef}
                                keySearch={key}
                                dateFrom={ngay[0]}
                                dateTo={ngay[1]}
                                chinhanh={chinhanh}
                                // parentFocus={(value) => { if (value) { inputTextKey.current.focus() }}}
                                parentFocusSID={(value) => { if (value) { 
                                    autoFocusSIDInput()
                                    }}}

                                clearSID={(value) => { if (value) { 
                                    clearSIDInput()
                                    }}}
                            />
                        </div>
                    </div>
                    }
                    {IsActive_FeatureLH() &&
                    <div style={{ height: 'calc(100% - 80px)' }}>
                        <Tabs className='tab-header-services' centered={true} onChange={e => {
                            setActiveKey(e);
                            autoFocusSIDInput();
                        }}>
                            <TabPane tab={"LẤY MẪU TẠI CHỖ"} key="1">
                                <div style={{ backgroundColor: '#fff', height: '100%' }}>
                                    <TaiCho ref={taichoRef}
                                        keySearch={key}
                                        dateFrom={ngay[0]}
                                        dateTo={ngay[1]}
                                        chinhanh={chinhanh}
                                        // parentFocus={(value) => { if (value) { inputTextKey.current.focus() }}}
                                        parentFocusSID={(value) => { if (value) { 
                                            autoFocusSIDInput()
                                            }}}

                                        clearSID={(value) => { if (value) { 
                                            clearSIDInput()
                                            }}}
                                    />
                                </div>
                            </TabPane>
                            
                            <TabPane tab={"LẤY MẪU TẠI NHÀ"} key="2">
                                <div style={{ backgroundColor: '#fff', height: '100%' }}>
                                    <TaiNha ref={tainhaRef}
                                        keySearch={key}
                                        dateFrom={ngay[0]}
                                        dateTo={ngay[1]}
                                        chinhanh={chinhanh}
                                        // parentFocus={(value) => { if (value) { inputTextKey.current.focus() }}}
                                        parentFocusSID={(value) => { if (value) { 
                                            autoFocusSIDInput() 
                                            }}}
                                        clearSID={(value) => { if (value) { 
                                            clearSIDInput()
                                            }}}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    }
                </div>
            </div>
            <Modal
                visible={visibleMutiSID}
                onCancel={() => onCloseSelectSID()}
                destroyOnClose
                width={800}>
                <HeaderModal title="Chọn SID" onClose={() => onCloseSelectSID()} />
                <Notify ref={_notify}/>
                <div className="d-flex flex-wrap">
                    <SIDCard data={listSIDInfo} onSelectSIDCard={(info) => renderConfirmSID(info)}/>
                </div>
            </Modal>
        </React.Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true})(XacNhanLayMau)
