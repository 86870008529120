import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { BUTTON } from '../../../configureHIS/common/constants';
import { Button, Input, notification } from 'antd';
import SqlResult from './sqlResult';
import * as SuperAdminService from "../../../services/superadminService";
import { setLoading } from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';

const { TextArea } = Input;

const SQLQuery = forwardRef((props, ref) => {
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const [query, setQuery] = useState();
    const [tabKey, setTabKey] = useState(props.tabKey);
    const _query = useRef();

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        },
        setReuseQuery: (statement, activeTab) => {
            reuseQuery(statement, activeTab);
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setTabKey(props.tabKey);
    }, [props.tabKey])

    const executeQuery = (q = query) => {
        if (isNullOrEmpty(q)) {
            showError("Hãy nhập câu lệnh cần thực thi.");
            return;
        }
        let selectText = getSelectedQuery();
        const data = {
            query: !isNullOrEmpty(selectText) ? selectText : q,
        }
        setLoading(true);
        SuperAdminService.ExecuteQuery(data).then(result => {
            if (result.isSuccess) {
                setTotalRecords(result.data.totalRecords ?? 0);
                setData(result.data);
                closeNotify();
            } else {
                showError(result.error);
            }
        }).catch(err => {
            showError(err.error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const showError = (err) => {
        if (props.showError) {
            props.showError(err);
        } else {
            notification.error({ message: "Không tìm thấy hàm thực thi." });
        }
    }

    const closeNotify = () => {
        if (props.closeNotify) {
            props.closeNotify();
        } else {
            notification.error({ message: "Không tìm thấy hàm thực thi." });
        }
    }

    const showHistory = () => {
        if (props.showHistory) {
            props.showHistory();
        } else {
            notification.error({ message: "Không tìm thấy hàm thực thi." });
        }
    }

    const getSelectedQuery = () => {
        let textArea = document.getElementById('sqlQuery');
        let selectedText = textArea.value;
        let selStart = textArea.selectionStart;
        let selEnd = textArea.selectionEnd;
        return selectedText.substring(selStart, selEnd);
    }

    const reuseQuery = (statement, activeTab) => {
        // console.log(statement, activeTab, tabKey);
        // if (activeTab == tabKey) {
            setQuery(statement);
            executeQuery(statement);
        // }
    }

    const onDoubleClickHeader = (val) => {
        if (/[A-Z]/.test(val)) {
            val = `"${val}"`;
        }
        insertAtCursor(val);
    }

    const  insertAtCursor = (val) => {
        let currentInput = _query.current?.resizableTextArea.textArea;
        let cursorPos = currentInput.selectionStart;
        let v = currentInput.value;
        let textBefore = v.substring(0, cursorPos);
        let textAfter = v.substring(cursorPos, v.length);
        if (textBefore.length > 0) val = ` ${val}`;
        if (textAfter.length > 0) val = `${val} `;
        cursorPos += val.length;
        const res = textBefore.trim() + val + textAfter.trim();
        setQuery(res.trim());
        currentInput.focus();
        setTimeout(() => {
            currentInput.selectionEnd = cursorPos - 1;
        }, 200);
    }

    return (
        <div className="list-page">
            <div className="list-page-body">
                <div className="list-page-table">
                    <div className='d-flex justify-content-between'>
                        <div>
                            <Button className={`${BUTTON.THEME}`} onClick={() => executeQuery()}><i className='fas fa-play mr-1' />Thực thi <span className='font-weight-bold text-danger ml-1'>F2</span></Button>
                            <span className='ml-2 text-primary'>Bạn có thể bôi đen câu lệnh để chọn và thực thi đoạn đã được bôi đen</span>
                        </div>
                        <Button className={`${BUTTON.INFO}`} onClick={() => { showHistory() }}><i className='fas fa-history mr-1' />Lịch sử</Button>
                    </div>
                    <div className='mt-2'>
                        <TextArea
                            ref={_query}
                            id="sqlQuery"
                            style={{ fontFamily: "Consolas", }}
                            rows={8}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key == "F2") {
                                    executeQuery(e.target.value);
                                }
                            }}/>
                    </div>
                    <div className='mt-2'>
                        <SqlResult data={data} totalRecords={totalRecords} onDoubleClickHeader={(val) => { onDoubleClickHeader(val) }} />
                    </div>
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SQLQuery)