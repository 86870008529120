import { Button, DatePicker, Form, Input, Modal, notification, Popconfirm, Table, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as InvoiceService from '../../../services/invoiceService';
import * as PrintService from '../../../services/printService';

const { RangePicker } = DatePicker;
const initialState = {

}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            ngay: [moment(), moment()],
            txtmabn: '',
            lstTrucTiep: [],
            lstTrucTiepCT: [],
            lstTamUng: [],
            lstTamUngCT: [],
            isCT: false,
            detail: {},
            ghichu: '',
            tonghoantra: 0,
            tonghoantraTU: 0,
        };
    }
    componentDidMount() {
        this.loadDanhSachHoanTraTrucTiep()
    }

    loadDanhSachHoanTraTrucTiep() {
        Actions.setLoading(true);
        InvoiceService.DanhSachCanHoanTra(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.txtmabn).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTrucTiep: result.data,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu hoàn trả trực tiếp bị lỗi' })
        })
    }
    notifyUpdate() {
        this.loadDanhSachHoanTraTrucTiep()
    }
    loadDanhSachCanHoanTraTamUng() {
        Actions.setLoading(true);
        InvoiceService.DanhSachCanHoanTraTamUng(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.txtmabn).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTamUng: result.data,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu hoàn trả tạm ứng bị lỗi' })
        })
    }

    XemChiTiet(row) {
        this.setState({ detail: row });
        Actions.setLoading(true);
        InvoiceService.DanhSachCanHoanTraChiTiet(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), row.mabn, row.maql).then(result => {
            if (result.isSuccess) {
                let tonghoantra = 0;
                result.data.map(x => x.hoantra === 'Đã hoàn trả' ? tonghoantra += x.sotien : tonghoantra += 0);
                this.setState({
                    isCT: true,
                    lstTrucTiepCT: result.data,
                    tonghoantra: tonghoantra,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu hoàn trả chi tiết bị lỗi' })
        })
    }

    XemChiTietTU(row) {
        this.setState({ detail: row });
        Actions.setLoading(true);
        InvoiceService.DanhSachCanHoanTraChiTietTamUng(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), row.mabn, row.maql).then(result => {
            if (result.isSuccess) {
                let tonghoantra = 0;
                result.data.map(x => x.hoantra === 'Đã hoàn trả' ? tonghoantra += x.sotien : tonghoantra += 0);
                this.setState({
                    isCTTU: true,
                    lstTamUngCT: result.data,
                    tonghoantraTU: tonghoantra,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu hoàn trả chi tiết tạm ứng bị lỗi' })
        })
    }

    closeModal() {
        this.setState({ isCT: false, isCTTU: false });
    }
    HoanTraTrucTiep(row) {
        const data = {
            mabn: row.mabn,
            maql: row.maql,
            mavp: row.mavp,
            loaikham: row.loaikham,
            ghichu: this.state.ghichu,
            idchidinh: row.idchidinh,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTrucTiep(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTiet(row);
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }

    HoanTraTamUng(row) {
        const data = {
            mabn: row.mabn,
            maql: row.maql,
            idhoantra: row.idtamung,
            ghichu: this.state.ghichu,
            idchidinh: row.idchidinh,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTamUng(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTietTU(row);
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }

    HoanTraTatCaTrucTiep() {
        const data = {
            mabn: this.state.detail.mabn,
            maql: this.state.detail.maql,
            dateFrom: this.state.ngay[0].format('YYYY-MM-DD'),
            dateTo: this.state.ngay[1].format('YYYY-MM-DD'),
            ghichu: this.state.ghichu,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTatCaTrucTiep(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTiet({ mabn: data.mabn, maql: data.maql });
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }

    HoanTraTatCaTamUng() {
        const data = {
            mabn: this.state.detail.mabn,
            maql: this.state.detail.maql,
            dateFrom: this.state.ngay[0].format('YYYY-MM-DD'),
            dateTo: this.state.ngay[1].format('YYYY-MM-DD'),
            ghichu: this.state.ghichu,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTatCaTamUng(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTietTU({ mabn: data.mabn, maql: data.maql });
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }
    PrintRefundTrucTiep() {
        this.setState({ isCT: false, isCTTU: false });
        PrintService.PrintRefundTrucTiep(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.detail.mabn, this.state.detail.maql).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintRefundTamUng() {
        this.setState({ isCT: false, isCTTU: false });
        PrintService.PrintRefundTamUng(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.detail.mabn, this.state.detail.maql).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintSummary(data) {
        PrintService.PrintSummary(data.maql, data.mabn, moment(data.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Họ tên',
                dataIndex: 'tenbn',
                key: 'tenbn',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            // {
            //     title: 'maql',
            //     dataIndex: 'maql',
            //     key: 'maql',
            // },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
            },
            // {
            //     title: 'Số nhà',
            //     dataIndex: 'diachi',
            //     key: 'diachi',
            // },
            {
                title: 'Ngày vào',
                dataIndex: 'ngayvv',
                key: 'ngayvv',
            },
            {
                title: 'Loại',
                dataIndex: 'tenloaikham',
                key: 'tenloaikham',
            },
            {
                //title: '',
                render: (row) => {
                    return (
                        <div>
                            {
                                (row.loaikham) === '1' ?
                                    <button className="btn btn-warning" title="Xem chi tiết" onClick={() => { this.XemChiTiet(row) }}>Hoàn trả</button>
                                    :
                                    <button className="btn btn-warning" title="Xem chi tiết" onClick={() => { this.XemChiTietTU(row) }}>Hoàn trả</button>
                            }
                            <button className="btn btn-primary" style={{ marginLeft: '5px' }} title="Quyết toán" onClick={() => { this.PrintSummary(row) }}>In bảng kê</button>
                        </div>
                    )
                }
            }
        ];
        const columndetail = [
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Tên',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giảm giá',
                dataIndex: 'tylegiamgia',
                key: 'tylegiamgia',
                align: 'right',
                render: (value, row) => {
                    return <span>{(row.sotien - row.sotien * ((100 - value) / 100)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Thành tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                align: 'right',
                render: (value, row) => {
                    return <span>{(value * ((100 - row.tylegiamgia) / 100)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD-MM-YYYY HH:mm')}</span>;
                },
            },
            {
                title: 'Trạng thái',
                dataIndex: 'hoantra',
                key: 'hoantra',
                width: 100,
                render: (value) => {
                    return (value === 'Đã hoàn trả' ? <div style={{ color: 'red' }}>{value}</div> : <div>{value}</div>)
                },
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả hóa đơn này không?"
                                onConfirm={() => { this.HoanTraTrucTiep(row) }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả hóa đơn">
                                    <button className="btn btn-warning"><i className="fa fa-undo"></i></button>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        const columndetailTU = [
            {
                title: 'Tổng tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
            },
            {
                title: 'Trạng thái',
                dataIndex: 'hoantra',
                key: 'hoantra',
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả hóa đơn tạm ứng này không?"
                                onConfirm={() => { this.HoanTraTamUng(row) }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả hóa đơn tạm ứng">
                                    <button className="btn btn-warning"><i className="fa fa-undo"></i></button>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }}>download</a>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <Form.Item>
                            <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                                locale={viVN} onChange={(value => this.setState({ ngay: value }, () => this.loadDanhSachHoanTraTrucTiep()))}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input onChange={(e) => this.setState({ txtmabn: e.target.value })} placeholder="Nhập mã hoặc tên bệnh nhân"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.loadDanhSachHoanTraTrucTiep();
                                    }
                                }} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                    <button className="btn btn-success"
                        onClick={() => { this.loadDanhSachHoanTraTrucTiep() }}>
                        <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </button>
                        </Form.Item>
                </div>
                <div style={{ fontSize: "large", textAlign: 'center' }}>Danh sách đã đóng tiền</div>
                <div style={{ padding: "5px 20px" }}>
                    <Table dataSource={this.state.lstTrucTiep} columns={columns} rowKey={(row, index) => index}></Table>
                </div>
                {this.state.isCT && <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.isCT}
                    onCancel={() => this.closeModal()}
                    footer={
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả tất cả hóa đơn này không?"
                                onConfirm={() => { this.HoanTraTatCaTrucTiep() }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả tất cả hóa đơn">
                                    <Button type="primary" className="btn btn-outline">Hoàn trả tất cả</Button>
                                </Tooltip>
                            </Popconfirm>
                            <Button type="primary" onClick={() => this.closeModal()}>Đóng</Button>
                        </div>
                    }
                >
                    <div>
                        Tổng tiền hoàn trả: <strong style={{ color: "red" }}>{this.state.tonghoantra.toLocaleString('en-US')}</strong> VNĐ
                    </div>
                    <Table dataSource={this.state.lstTrucTiepCT} columns={columndetail} rowKey={(row, index) => index}></Table>
                    <div style={{ textAlign: 'center', paddingTop:'15px' }}>
                        <Tooltip placement="top" title="In hóa đơn hoàn trả">
                            <button className="btn btn-primary" onClick={() => this.PrintRefundTrucTiep()}><i style={{ marginRight: 5 }} className="fa fa-print" />In hóa đơn hoàn trả</button>
                        </Tooltip>
                        <button className="btn btn-danger" style={{ marginLeft: '10px' }} onClick={() => this.closeModal()}>Đóng</button>
                    </div>
                </Modal>}
                {this.state.isCTTU && <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.isCTTU}
                    onCancel={() => this.closeModal()}
                    footer={
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả tất cả hóa đơn này không?"
                                onConfirm={() => { this.HoanTraTatCaTamUng() }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả tất cả hóa đơn">
                                    <Button type="primary" className="btn btn-outline">Hoàn trả tất cả</Button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip placement="top" title="In hóa đơn hoàn trả">
                                <button className="btn btn-primary" onClick={() => this.PrintRefundTamUng()}><i style={{ marginRight: 5 }} className="fa fa-print" />In</button>
                            </Tooltip>
                            <button className="btn btn-danger" onClick={() => this.closeModal()}>Đóng</button>
                        </div>
                    }
                >
                    <div>
                        Tổng tiền hoàn trả: {this.state.tonghoantraTU.toLocaleString('en-US')} VNĐ
                    </div>
                    <Table dataSource={this.state.lstTamUngCT} columns={columndetailTU} rowKey={(row, index) => index}></Table>
                    <div style={{ textAlign: 'center' }}>
                        <button className="btn btn-danger" onClick={() => this.closeModal()}>Đóng</button>
                    </div>
                </Modal>}
            </React.Fragment>
        )
    }
}
