import { DatePicker, Input, Select } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify'
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as InvoiceServices from '../../../services/invoiceService';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import * as StatisticService from '../../../services/statisticService';
import * as LisService from '../../../services/lisService';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as PrintService from '../../../services/printService';

const { TextArea } = Input;
const { Option } = Select;

export default forwardRef((props, ref) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const [datecreate, setDatecreate] = useState(moment())
    const [billSelected, setBillSelected] = useState('');
    const [thamChieu, setThamChieu] = useState('');
    const [ghichu, setGhichu] = useState('');
    const [, updateState] = React.useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [dateRange,] = useState(props.dateRange);
    const [listPaymentType, setListPaymentType] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [idTheTraTruoc, setIdTheTraTruoc] = useState(5);

    useEffect(() => {
        IdConfigTheTraTruoc();
    }, []);

    useEffect(() => {
        if (props.billSelected && props.billSelected !== null && props.billSelected !== '') {
            setBillSelected(props.billSelected.toString())
        } else {
            setBillSelected('')
        }
        notiRef && notiRef.current && notiRef.current.Show("Thanh toán hàng loạt không bao gồm giảm giá, áp dụng voucher và các CTKM khác. Để sử dụng các chức năng đã nêu, vui lòng chuyển đến màn hình thanh toán!", NotifyStatus.Warning);
    }, [props])

    const resetState = () => {
        setBillSelected('')
        setThamChieu('')
        setGhichu('')
        setPaymentType(null);
        notiRef.current.Close()
    }

    const Submit = (doPrint = false) => {
        if (validator.current.allValid()) {
            setDisableBtn(true);
            Actions.setLoading(true);
            const data = {
                billNumberList: billSelected,
                dateFrom: dateRange[0],
                dateTo: dateRange[1],
                paymentType: paymentType,
            }
            InvoiceServices.SaveMassPayment(data).then(res => {
                if (res.isSuccess) {
                    if (notiRef && notiRef.current) 
                    notiRef.current.Show("Thực hiện thanh toán thành công", NotifyStatus.Success)
                    SentDataToLis(billSelected);
                    setTimeout(() => {
                        if (props.onSubmit)
                            props.onSubmit();
                        if (!doPrint) resetState();
                    }, 1000);
                    doPrint && printSIDBarcode();
                } else {
                    notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
                }
            }).catch((err) => {
                notiRef.current.Show(err.error.messageText ?? "Đã xảy ra lỗi. Vui lòng thử lại sau.", NotifyStatus.Error);
            }).finally(() => {
                setDisableBtn(false);
                Actions.setLoading(false);
            })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const GetPaymentMethod = () => {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let lst = result.data;
                let data = lst.filter(item => item.ma !== Number(idTheTraTruoc));
                setListPaymentType(data)
            }
        }).catch(err => {
        })
    }

    const IdConfigTheTraTruoc = () => {
        StatisticService.IdConfigTheTraTruoc().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                setIdTheTraTruoc(res);
                GetPaymentMethod();
            }
        }).catch(err => {
        })
    }

    const totalSelected = () => {
        let total = billSelected.split(",").length;
        if (billSelected && total > 1)
            return (
                <b>({total})</b>
            )
        else return "";
    }

    const printSIDBarcode = () => {
        let printAll = true;
        let soLuong = 1;
        PrintService.PrintOrderSIDInManagermentList(billSelected, soLuong, printAll).then((result) => {
            Actions.DoPrint(result.item);
            resetState();
        })
    }

    const SentDataToLis = (managementIds) => {
        let data = { 'managementIds' : managementIds }
        LisService.SentDataToLis(data).then(result => {
            if (!result.isSuccess) {
                Actions.openMessageDialog(result.error.messageText)
            }
        }).catch(err => {
            Actions.openMessageDialog(err.error.messageText)
        })
    }

    return (
        <>
            <HeaderModal title="Thanh toán" onClose={() => {
                props.onCancel();
                resetState();
            }} />
            <Notify ref={notiRef} />
            <div className='py-1'>
                <label>Đã chọn {totalSelected()}</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} disabled={true}
                    value={billSelected}
                />
            </div>
            <div className='py-1'>
                <label>Ngày giờ thanh toán</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <DatePicker locale={viVN} format="DD-MM-YYYY HH:mm" style={{ width: '100%' }}
                    showTime={true}
                    disabled={true}
                    allowClear={false}
                    value={moment()} />
            </div>
            <div className='py-1'>
                <label>Hình thức thanh toán</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <Select className="w-100" id="tthl-slt-paytype"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e)} >
                    {listPaymentType && listPaymentType.map((type, index) => {
                        return (
                            <Option key={index} value={type.ma}>{type.ten}</Option>
                        )
                    })}
                </Select>
                {validator.current.message("Hình thức thanh toán", paymentType, "selection")}
            </div>
            <div className='py-1 text-right'>
                <button className='btn btn-color mr-1'
                    onClick={() => {
                        Submit()
                    }}
                    disabled={disableBtn}
                ><i className="fas fa-save" style={{ marginRight: 5 }} />Thanh toán</button>
                <button className='btn btn-color '
                    onClick={() => {
                        let doPrint = true;
                        Submit(doPrint);
                    }}
                    disabled={disableBtn}
                ><i className="fas fa-barcode" style={{ marginRight: 5 }} />Thanh toán và in barcode</button>
            </div>
        </>
    )
});
