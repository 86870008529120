import { Button, Input, notification, Upload, Select, Tooltip, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import * as ConfigService from '../../../../services/configService';
import * as InvoiceService from '../../../../services/invoiceService';
import * as KhoService from '../../../../services/KhoService';
import * as Actions from '../../../../libs/actions';
import { InboxOutlined } from '@ant-design/icons';
import { FEATURE } from '../../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../../configure/featureConfig';
import HeaderModal from '../../../../configureHIS/common/headerModal';
import { Fragment } from 'react';
import { IsSuperAdmin } from '../../../../configure/superAdminConfig';
import * as CorporationServices from '../../../corporation/CorporationServices';
import { CheckBank, isNullOrEmpty, isnotnull } from '../../../../libs/util';

let taskDelay = null;
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default (props) => {

    const [edit, setEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [ten, setTen] = useState('');
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');
    const [logo, setLogo] = useState('');
    const [codebn, setCodebn] = useState('');
    const [fileList, setfileList] = useState([]);
    const [ma, setMa] = useState('');
    const [banggia, setBanggia] = useState('');
    const [dataBG, setDataBG] = useState([]);
    const [listKho, setListKho] = useState([]);
    const [kho, setKho] = useState('');
    const [dms, setDms] = useState('');
    const [banking, setBanking] = useState('');
    const [tax, setTax] = useState('');
    const [billPrefix, setBillPrefix] = useState('');
    const [clientRegisterSource, setClientRegisterSource] = useState();
    const [listClientRegisterSource, setListClientRegisterSource] = useState([]);
    const [clientCoporationId, setClientCoporationId] = useState();
    const [listClientCoporationId, setListClientCoporationId] = useState([]);
    const [clientCoporationName, setClientCoporationName] = useState();
    const [clientUrlApi, setClientUrlApi] = useState();

    const config = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        onChange(info) {
            getBase64(info.fileList[0].originFileObj).then(result => {
                setLogo(result);
            }).catch(err => {
                setLogo('');
                console.log(err);
            })
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        GetListBangGia();
        DanhSachKho();
        if (!edit && IsSuperAdmin()) {
            getListThirdParty();
            loadListCoporation();
        }
    }, [])
    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])
    useEffect(() => {
        setDetail(props.detail)
    }, [props.detail])
    useEffect(() => {
        if (detail.id) {
            setDiachi(detail.diachi);
            setTen(detail.ten);
            setDienthoai(detail.dienthoai);
            setLogo(detail.logo);
            setMa(detail.ma);
            setCodebn(detail.codebn);
            setBanggia(detail.banggia);
            setDms(detail.hostDMS);
            setKho(detail.khothuoc);
            setBanking(detail.thongtinck);
            setTax(detail.tax);
            setBillPrefix(detail.billPrefix);
            setClientRegisterSource(detail.clientRegisterSource);
            setClientCoporationId(detail.clientCoporationId);
            setClientCoporationName(detail.clientCoporationName);
            setClientUrlApi(detail.clientUrlApi);
        }
        if (isnotnull(detail) && IsSuperAdmin()) {
            getListThirdParty();
            loadListCoporation();
        }
    }, [detail])
    const GetListBangGia = () => {
        InvoiceService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setDataBG(result.data);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    const DanhSachKho = () => {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                setListKho(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }
    const Submit = () => {
        let data = {
            diachi, ten, dienthoai, logo, ma, codebn, banggia, hostDMS: dms, khothuoc: kho ? kho.toString() : '',
            thongtinck: banking, tax, billPrefix, clientRegisterSource, clientCoporationId, clientUrlApi
        }
        if (ten.trim() === '' || ma.trim() === '') {
            notification.warning({ message: "Vui lòng nhập tên và mã!" });
            return;
        }
        if (!edit) {
            Actions.setLoading(true);
            ConfigService.CreateSite(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Tạo mới site thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                    else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                    else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
                }
            }).catch(err => {
                Actions.openMessageDialog('Lỗi', err)
            }).finally(() => {
                Actions.setLoading(false);
            })
        }
        else {
            data.id = detail.id;
            Actions.setLoading(true);
            ConfigService.UpdateSite(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                    else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                    else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err)
            })
        }
    }

    const getListThirdParty = () => {
        Actions.setLoading(true);
        setTimeout(() => {
            setListClientRegisterSource([
                {
                    label: "TNET",
                    value: 3
                },
            ]);
            Actions.setLoading(false);
        }, 200);
    }

    const loadListCoporation = (key = "", page = 1, size = 10) => {
        CorporationServices.GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                let data = res.data;
                if (!isNullOrEmpty(detail.clientCoporationId)) {
                    if (data.find(x => x.ma == detail.clientCoporationId) == undefined)
                        data.push({ ma: detail.clientCoporationId, ten: detail.clientCoporationName })
                }
                setListClientCoporationId(data);
            }
        })
    }

    return (
        <React.Fragment>
            <HeaderModal title={edit ? detail.ten : 'Tạo mới site'} onClose={() => props.onClose()}/>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Mã</label>
                    <Input value={ma} className="w-100"
                        placeholder="Nhập mã" 
                        onChange={(e) => {
                            setMa(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tên</label>
                    <Input value={ten} className="w-100"
                        placeholder="Nhập tên"
                        onChange={(e) => {
                            setTen(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Điện thoại</label>
                    <Input value={dienthoai} className="w-100"
                        placeholder="Nhập điện thoại"
                        onChange={(e) => {
                            setDienthoai(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Code BN</label>
                    <Input value={codebn} className="w-100" maxLength={3}
                        placeholder="Nhập mã hiển thị trước số mã bệnh nhân"
                        onChange={(e) => {
                            setCodebn(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>HostDMS</label>
                    <Input value={dms} className="w-100"
                        placeholder="Nhập địa chỉ kết nối DMS"
                        onChange={(e) => {
                            setDms(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tiếp đầu ngữ hóa đơn</label>
                    <Input value={billPrefix.toUpperCase()} className="w-100" maxLength={4}
                        placeholder="Nhập mã hiển thị trước số biên lai"
                        onChange={(e) => { setBillPrefix(e.target.value.toUpperCase()); }}
                        onKeyDown={(e) => {if (e.code === 'Space') e.preventDefault()}} />
                </div>
                {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                    <div className="col-md-6 mb-2">
                        <label>Bảng giá mặc định</label>
                        <Select
                            value={banggia}
                            showSearch
                            className="w-100"
                            placeholder="Chọn bảng giá mặc định"
                            onChange={(e) => { setBanggia(e) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                        >
                            {dataBG.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>}
                <div className="col-md-6 mb-2">
                    <label>Kho bán thuốc</label>
                    <Select
                        showSearch
                        className="w-100"
                        value={kho}
                        allowClear={true}
                        onChange={(e) => { setKho(e) }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listKho.map((row, index) => {
                            return (
                                <Option key={index} value={row.value.toString()}>{row.label}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className='col-md-6 mb-2'>
                    <label>Địa chỉ</label>
                    <TextArea rows={4}
                        onChange={e => {
                            setDiachi(e.target.value)
                        }}
                        value={diachi}
                    ></TextArea>
                </div>
                <div className='col-md-6 mb-2'>
                    <label>Mã số thuế</label>
                    <TextArea rows={1}
                        onChange={e => {
                            setTax(e.target.value)
                        }}
                        value={tax}
                    ></TextArea>
                </div>
                <div className='col-md-6 mb-2'>
                    <label>Thông tin tài khoản ngân hàng</label>&nbsp;
                    {!isNullOrEmpty(banking) && /\d{8,18}/g.test(banking) ?
                        <Tooltip title="Đây là thông tin tự động nhận diện, chỉ hiển thị để xem, hệ thống không lưu trữ. Nếu thông tin hiển thị sai khác, vui lòng bỏ qua hoặc thông báo đến bộ phận phát triển phần mềm"><Tag color='blue'>{CheckBank(banking)}</Tag></Tooltip> : ""}
                    <TextArea rows={3}
                        onChange={e => {
                            setBanking(e.target.value)
                        }}
                        value={banking}
                    ></TextArea>
                </div>
                {IsSuperAdmin() && <Fragment>
                    <div className='col-md-6 mb-2'>
                        <label>Nguồn kết nối</label>
                        <Select
                            getPopupContainer={(p) => p.parentNode}
                            showSearch
                            className="w-100"
                            value={clientRegisterSource}
                            allowClear={true}
                            onChange={(e) => { setClientRegisterSource(e) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {listClientRegisterSource && listClientRegisterSource.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-6 mb-2'>
                        <label>Nguồn khách hàng mặc định</label>
                        <Select
                            getPopupContainer={(p) => p.parentNode}
                            showSearch
                            className="w-100"
                            value={clientCoporationId}
                            allowClear={true}
                            onChange={(e) => { setClientCoporationId(e) }}
                            filterOption={false}
                            onSearch={(val) => {
                                if (taskDelay) clearTimeout(taskDelay);
                                taskDelay = setTimeout(() => {
                                    loadListCoporation(val);
                                }, 500);
                            }}
                        >
                            {listClientCoporationId && listClientCoporationId.map((row, index) => {
                                return (
                                    <Option key={index} value={row.ma}>{row.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-md-6 mb-2">
                        <Tooltip title='Api này gọi đến sevice kết nối với đối tác. VD phòng khám IVY'><label>Api Url connector</label></Tooltip>
                        <Input value={clientUrlApi} className="w-100"
                            placeholder="Api Url connector"
                            onChange={(e) => { setClientUrlApi(e.target.value); }}
                            onKeyDown={(e) => {if (e.code === 'Space') e.preventDefault()}} />
                    </div>
                </Fragment>}
                <div className="col-md-6 mb-2">
                    <label>Logo</label>
                    <div>
                        {logo !== '' &&
                            <div style={{ width: '100%' }}>
                                <img style={{ width: '100%' }} src={logo} />
                            </div>
                        }
                        <Dragger {...config} beforeUpload={() => false} fileList={fileList} itemRender={null} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                        </Dragger>
                    </div>
                </div>
            </div>
            <div className="d-flex align-self-center">
                <Button className="btn btn-color mr-1"
                    onClick={() => {
                        Submit()
                    }}>
                    Lưu</Button>
                <Button
                    className="ml-1"
                    onClick={() => {
                        if (props.onClose)
                            props.onClose()
                    }}>
                    Đóng</Button>
            </div>
        </React.Fragment>
    )
}
