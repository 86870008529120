import { Button, Form, Input, Pagination, DatePicker, Table, Select } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import * as Actions from '../../libs/actions';
import * as WorldfoneService from '../../services/worldfoneService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as SystemConfig from '../../configure/systemConfig';
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function ListController() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [status, setStatus] = useState('');
    const [date, setDate] = useState([moment(), moment()]);
    useEffect(() => {
        GetListCalls();
    }, []);

    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListCalls()
    }, [currentPage, size, status]);


    const GetListCalls = () => {
        Actions.setLoading(true);
        WorldfoneService.GetListCalls(key, currentPage, size, date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD"), status).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const columns = [
        {
            title: 'Giờ bắt đầu',
            dataIndex: 'starttime',
            key: 'starttime',
            width: 120,
            // render: (value) => (moment(value).format(SystemConfig.FORMAT_DATE_TIME)),
        },
        {
            title: 'Giờ kết thúc',
            dataIndex: 'endtime',
            key: 'endtime',
            width: 120,
            // render: (value) => (moment(value).format(SystemConfig.FORMAT_DATE_TIME)),
        },
        {
            title: 'Thời gian (s)',
            dataIndex: 'billduration',
            key: 'billduration',
            width: 100
        },
        {
            title: 'Người gọi',
            dataIndex: 'callernumber',
            key: 'callernumber',
            width: 120
        },
        {
            title: 'Người nhận',
            dataIndex: 'destinationnumber',
            key: 'destinationnumber',
            width: 120,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'disposition',
            key: 'disposition',
            width: 100,
        },
        {
            title: 'Loại',
            dataIndex: 'direction',
            key: 'direction',
            width: 100,
            render: (value) => (value === 'outbound' ? 'Cuộc gọi đi' : 'Cuộc gọi đến'),
        },
        {
            title: 'Loại',
            width: 40,
            align: 'center',
            render: (row) => (
                <div className='btn' onClick={() => {
                    getcdr2(row.calluuid, row.secret)
                }}
                ><i className='fas fa-sync'></i></div>
            ),
        }
    ];
    const getcdr2 = (calluuid, secrect) => {
        Actions.setLoading(true);
        WorldfoneService.getcdr2(calluuid, secrect).then(result => {
            if (result.status === '200') {
                let data = result.data;
                WorldfoneService.UpdateWorldfone(data).then(response => {
                    Actions.setLoading(false);
                }).catch(err => Actions.setLoading(false))
            }
            else {
                Actions.setLoading(false);
            }
        }).catch(err => {
            Actions.setLoading(false);
            console.log(err);
        })
    }
    return (
        <div className='containerChilderWrapper'>
            <div className='h4 text-center mb-2 font-bold text-uppercase'>QUẢN LÝ CUỘC GỌI</div>
            <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item>
                        <Select
                            style={{ width: '120px' }}
                            allowClear={false}
                            value={status}
                            onChange={e => { setStatus(e) }}
                        >
                            <Option key={0} value={''}>Loại</Option>
                            <Option key={1} value={'outbound'}>Cuộc gọi đi</Option>
                            <Option key={2} value={'inbound'}>Cuộc gọi đến</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={date}
                            style={{ width: '200px' }}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(e) => { setDate(e) }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Input
                            value={key}
                            placeholder="Nhập người gọi hoặc nhận"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
            </div>
            <div style={{ height: 'calc(100% - 110px)' }}>
                <Table dataSource={dataSource}
                    scroll={{ y: 0, x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index}
                />
            </div>
            <Pagination
                current={currentPage}
                size={size}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page) }}
                onShowSizeChange={(page, size) => { setSize(size) }}
                showSizeChanger={true}
            />
        </div>
    )

}