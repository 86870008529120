import React, { Component } from 'react'
import { Form, Select, Table, notification, Button, Modal, Input, Pagination } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
import * as MedicineService from '../../../services/medicineService';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as DepartmentConfigService from '../../../services/departmentConfigureService'
import Autocomplete from '../../../components/autocomplete';
import * as Actions from '../../../libs/actions';
const { Option } = Select;

const initialState = {
    makhoa: '',
    mavp: '',
    tenvp: '',
    idvp: '',
}
export default class CauHinhGiaKhoa extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstdepartment: [],
            lstgiavp: [],
            lstCauHinh: [],
            currentPage: 1,
            pageSize: 10,
            expandableRow: [],
            detail: {},
            openModal: false,
            dataUpdate: [],
            totalRecords: 0,
            key: "",
        };
    }
    componentWillMount() {
        this.LoadDepartment();
        this.loadDanhSachCauHinh();
    }
    LoadDepartment() {
        CommonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    loadDanhSachCauHinh(key = this.state.key, page = this.state.currentPage, size = this.state.pageSize) {
        DepartmentConfigService.DanhSachVienPhiMacDinhTheoKhoa(key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCauHinh: result.data,
                    totalRecords: result.totalRecords,
                });
            }
        })
    }
    DanhSachGiaVienPhi(key) {
        ChiDinhService.searchSerivce(8, 0,
            'default', key, this.state.currentPage, this.state.pageSize).then(result => {
                if (result.isSuccess) {
                    this.setState({ lstgiavp: result.data.map((x) => ({ label: x.ten, value: x.id, mavp: x.mavp })) })
                }

            }).catch(err => {

                Actions.openMessageDialog('Error 3', err)
            })
        // MedicineService.DanhSachGiaVP(key).then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             lstgiavp: result.data
        //         });
        //     }
        // })
    }
    async CapNhat() {
        const data = {
            makp: this.state.detail.makp,
            listVp: this.state.dataUpdate
        }
        DepartmentConfigService.CapNhatDanhSachVienPhiMacDinhTheoKhoa(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' });
                this.loadDanhSachCauHinh();
                Actions.setLoading(false);
            } else {
                notification.error({ message: result.err.msgString });
                Actions.setLoading(false);
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật bị lỗi. Vui lòng thử lại!' });
            Actions.setLoading(false);
        })

    }

    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            this.setState({ expandableRow: [index] })
        }
        else {
            this.setState({ expandableRow: [] })
        }
    }

    setDataUpdate() {
        if (this.state.idvp && this.state.idvp != '') {
            let data = this.state.dataUpdate;
            if (data.findIndex(x => `${x.idvp}` === `${this.state.idvp}`) < 0) {
                data.push({ idvp: this.state.idvp, tenvp: this.state.tenvp, mavp: this.state.lstgiavp.find(x => `${x.value}` === `${this.state.idvp}`).mavp })
                this.setState({ dataUpdate: data.map(x => ({ ...x })) })
            }
        }
    }

    render() {
        const columns = [
            {
                title: 'Mã khoa',
                dataIndex: 'makp',
                key: 'makp',
                width: 120
            },
            {
                title: 'Tên khoa',
                dataIndex: 'tenkp',
                key: 'tenkp',
            },
            {
                title: 'Action',
                width: 120,
                render: (row) => {
                    return (
                        <Button className="btn btn-warning"
                            title="Cập nhật!"
                            onClick={(e) => {
                                this.setState({ detail: row, openModal: true, dataUpdate: row.listVp || [] });
                                e.stopPropagation();
                            }} >
                            <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                        </Button>
                    )
                }
            }
        ];
        const columnChild = [
            {
                title: 'Mã VP',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Tên VP',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
        ]
        const columnUpdate = [
            {
                title: 'Mã VP',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 100,
            },
            {
                title: 'Tên VP',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
            {
                title: 'Action',
                width: 100,
                render: (row) => {
                    return (
                        <Button className="btn btn-danger"
                            title="Cập nhật!"
                            onClick={(e) => {
                                this.setState({ dataUpdate: this.state.dataUpdate.filter(x => `${x.idvp}` !== `${row.idvp}`) })
                            }} >
                            <i className="fa fa-trash" style={{ marginRight: 5 }} />Xóa
                        </Button>
                    )
                }
            }
        ]
        const expandable = {
            expandedRowRender: record => (
                <div style={{ padding: 10, width: '100%' }}>
                    <Table dataSource={record.listVp} pagination={false} columns={columnChild} scroll={{ x: 'max-content' }} />
                </div>),
            rowExpandable: record => record.listVp && record.listVp.length > 0,
        };
        let dataSource = this.state.lstCauHinh.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <>
                <Form ref={(c) => this.form = c} style={{ height: '100%' }}>

                    <div className="row" style={{ display: 'flex' }}>
                        <div className="col-md-6">
                            <Input
                                className="form-control mb-2 w-100"
                                value={this.state.key}
                                placeholder="Nhập mã hoặc tên khoa"
                                onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({
                                            currentPage: 1,
                                        }, () => this.loadDanhSachCauHinh());
                                    }
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success mb-2" onClick={() => this.setState({
                                currentPage: 1,
                            }, () => this.loadDanhSachCauHinh())}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </button>
                        </div>
                    </div>
                    <div style={{ height: 'calc(100% - 100px)' }}>
                        <Table dataSource={dataSource} scroll={{ y: 0 }}
                            columns={columns} rowKey={(e, index) => index}
                            expandable={expandable}
                            expandIconColumnIndex={-1}
                            expandRowByClick={true}
                            // expandedRowKeys={this.state.expandableRow}
                            onRow={(record, index) => {
                                return {
                                    onClick: e => {
                                        this.setExpandable(index);
                                    }
                                };
                            }}
                            rowClassName={(row, index) => {
                                const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                return xx
                            }}
                            pagination={false}
                        />
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size,
                                }, () => this.loadDanhSachCauHinh());
                            }}
                            onShowSizeChange={(page, size) => {
                                this.setState({
                                    currentPage: 1,
                                    pageSize: size,
                                }, () => this.loadDanhSachCauHinh())
                            }}
                            showSizeChanger={true}>
                        </Pagination>
                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={1000}
                    cancelText="Đóng"
                    okText="Lưu"
                    onCancel={() => { this.setState({ openModal: false }) }}
                    visible={this.state.openModal}
                    onOk={() => {

                    }}
                >
                    <div style={{ padding: 20 }}>
                        <h3 className='text-center pb-2'>Cập nhật viện phí mặc định</h3>
                        <div className="row" style={{ marginBottom: 10 }}>
                            <div className="col-md-6">
                                <Form.Item label="Khoa:">
                                    <Input value={this.state.detail.tenkp} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Viện phí:">
                                    <Autocomplete width={400}
                                        ref={((c) => this.autocomplete = c)}
                                        dataSource={this.state.lstgiavp}
                                        handleSearch={this.DanhSachGiaVienPhi.bind(this)}
                                        getItem={(key, value) => { this.setState({ idvp: key, tenvp: value }) }}
                                        value={this.state.idvp}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='text-center'>
                            <Button className='btn btn-success'
                                onClick={() => { this.setDataUpdate() }}
                            >Thêm</Button>
                        </div>
                        <div style={{ padding: 10, width: '100%' }}>
                            <Table dataSource={this.state.dataUpdate} pagination={false} columns={columnUpdate} scroll={{ x: 'max-content' }} />
                        </div>
                        <div className='text-center'>
                            <Button className='btn btn-secondary mr-1'
                                onClick={() => { this.setState({ openModal: false }) }}
                            >Đóng</Button>
                            <Button className='btn btn-primary ml-1'
                                onClick={() => { this.CapNhat() }}
                            >Lưu</Button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}