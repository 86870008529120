import * as React from 'react';
import { connect } from 'react-redux';
import * as AuthServices from '../../services/authServices';
import history from '../../stores/history';
import { loadSysConfig } from "../../services/configService";
import * as actions from '../../libs/actions';
class Authenticated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    AuthServices.getCurrentSite().then((res) => {
      if (res.isSuccess) {
        let item = res.item;
        if (this.props.siteid != null) {
          if (item.id != this.props.siteid) {
            this.props.clearLogin()
            history.push('login');
          }
        } else {
          this.props.setCurrentSite({ siteid: item.id, siteCode: item.ma, siteName: item.ten });
          this.loadSysConfig();
        }
      } else {
        this.props.clearLogin()
        history.push('login');
      }
    }).catch((ex) => {
        this.props.clearLogin()
        history.push('login');
    })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
   
    try {
      if (nextProps.global !== undefined && (nextProps.global.token === '' || nextProps.global.token === undefined)) {
        if(this)
        this.props.clearLogin()
        history.push('login');
      }
    } catch (ex) {
      if(this)
      this.props.clearLogin()
      history.push('login');
    }
    let token = localStorage.getItem('token') || '';
    if (token === '') {
      history.push('login');
    } else {
      AuthServices.checkAuth(token);
    }
    return null
  }
  loadSysConfig() {
    loadSysConfig().then(res => {
      if (res.isSuccess) {
        actions.setConfigure(res.item);
        if (res.item.dateFormat) localStorage.setItem("dateFormat", res.item.dateFormat); 
        if (res.item.timeFormat) localStorage.setItem("timeFormat", res.item.timeFormat); 
      }
    })
  }
  render() {
    const Comp = this.props.component;
    let token = localStorage.getItem('token') || '';
    if (token === '') {
      return (
        <React.Fragment>
        </React.Fragment>);
    } else {
      if (Comp !== undefined) {
        return (
          <React.Fragment>
            <Comp />
          </React.Fragment>);
      } else {
        return (
          <React.Fragment>
          </React.Fragment>);
      }
    }
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentSite: (siteInfo) => dispatch({ type: 'SET_ACTIVE_SITE', siteInfo: siteInfo }),
    clearLogin: () => dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authenticated);