import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import * as SystemConfig from "../../configure/systemConfig";

function PatientInfo(props) {
    //Thông tin bệnh nhân
    const [patient, setPatient] = useState(props.patientInfo ? props.patientInfo
        :
        [
            {
                label: "Nhập label",
                value: "Không có thông tin",
                column: 12,
            }
        ]
    );
    //Thông tin khác (nếu cần)
    const [additionalInfo, setAdditionalInfo] = useState(props.additionalInfo ? props.additionalInfo
        :
        [
            {
                label: "Nhập label",
                value: "Không có thông tin",
                column: 12,
            }
        ]
    );
    // Hiển thị thông tin khác ngoài thông tin cá nhân
    const [showAdditionalInfo,] = useState(props.showAdditionalInfo ? props.showAdditionalInfo : false);

    useEffect(() => {
        if (props.patientInfo !== null && props.patientInfo !== undefined) {
            setPatient(props.patientInfo);
        }
    }, [props.patientInfo]);

    useEffect(() => {
        if (props.additionalInfo !== null && props.additionalInfo !== undefined) {
            setAdditionalInfo(props.additionalInfo);
        }
    }, [props.additionalInfo]);

    return (
        <Fragment>
            <div className="warrperStyleGroup mt-4 mb-2">
                <div className="grouplabel">
                    Thông tin khách hàng
                </div>
                <div className="row">
                    {patient && patient.length > 0 &&
                        patient.map((info, index) => {
                            return (
                                <div className={`col-md-${info.column} d-flex`} key={index}>
                                    <span style={{ width: `${info.labelWidth ? `${info.labelWidth}px` : "100px"}` }}>{info.label}:</span>
                                    <strong className="ml-2">
                                        {info.value !== null && info.value !== undefined && info.value !== ""
                                        ? info.value
                                        : "Không có thông tin"
                                    }</strong>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {showAdditionalInfo && additionalInfo && additionalInfo.length > 0 &&
                <div className="warrperStyleGroup mt-2 mb-2">
                    <div className="row">
                        {additionalInfo.map((info, index) => {
                            return (
                                <div className={`col-md-${info.column} d-flex`} key={index}>
                                    <span style={{ width: `${info.labelWidth ? `${info.labelWidth}px` : "100px"}`}}>{info.label}:</span>
                                    <strong className={`ml-2 ${info.className && info.className}`}>
                                        {info.value !== null && info.value !== undefined && info.value !== ""
                                        ? info.value
                                        : "Không có thông tin"
                                    }</strong>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default PatientInfo;