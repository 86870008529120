import React, { useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Select, List, Input, Button, Checkbox, Tooltip, Switch, Drawer, Popconfirm, Popover  } from 'antd';
import * as Actions from '../../../libs/actions';
import * as PackageService from '../../../services/packageService';
import { isNullOrEmpty } from '../../../libs/util';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from "../../../configureHIS/common/headerModal";
import Modify from "./Modify";

const { Option } = Select;
export default forwardRef((props, ref) => {
  // Ref
  const _notiRef = useRef();    
  const firstRender = useRef(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);
  const listPageSize = [10, 20, 50, 100];
  const [sortFields, setSortFields] = useState([]);
  const [sortTypes, setSortTypes] = useState([]);
  const [sortingType, setSortingType] = useState('');
  const [sortingField, setSortingField] = useState('');
  const [keyword, setKeyword] = useState('');
  // Table
  const [listResult, setListResult] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  //
  const [selectedPackageId, setSelectedPackageId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  // Modal
  const [visibleModify, setVisibleModify] = useState(false);

  useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return
      };
      GetList();
  }, [skipCount])

  useEffect(() => {
      resetToFirstPage();
  }, [sortingType, sortingField, maxResultCount]);

  const GetList = () => {
    Actions.setLoading(true);
    let filterModel = {
        keyword : keyword,
        skipCount : skipCount,
        maxResultCount : maxResultCount,
        sorting: ((sortingField ?? '').length < 1 ? 'code' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
    }
    PackageService.ListServicePackage(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecords(result.data.totalCount);
        setSortFields(result.data.sortFields);
        setSortTypes(result.data.sortTypes);
        if (skipCount === 0) {
            setListResult(result.data.items);
        }
        else {
            setListResult([...listResult, ...result.data.items]);
        }
      }
      else {
        _notiRef && _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        _notiRef && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else _notiRef && _notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else GetList();
  }

  const handleEdit = (packageId) => {
    setSelectedPackageId(packageId);
    setIsEdit(true);
    setVisibleModify(true);
  }

  const handleClose = (isReload) => {
    setSelectedPackageId('');
    setIsEdit(false);
    setVisibleModify(false);
    if(isReload) resetToFirstPage();
  }

  const DeleteServicePackage = (packageId) => {
      PackageService.DeleteServicePackage(packageId).then(result => {
          if (result.isSuccess) {
              resetToFirstPage();
              _notiRef && _notiRef.current.Show('Xóa gói thành công!', NotifyStatus.Success);
          } else {
              _notiRef && _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
          }
      }).catch(err => {
        if (err.error && err.error.messageText)
          _notiRef && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
        else _notiRef && _notiRef.current.Show(err, NotifyStatus.Warning);
      }).finally(() => {
          Actions.setLoading(false);
          forceUpdate();
      })
  }

  const RenderDetail = (data) => {   
    console.log(data)
      
    // (data && data.map(x => (
    //   <div className="d-flex flex-column w-100" key={`pk-${x.groupCode}`}>{/* 1 item */}
    //       <div className="d-flex justify-content-between w-100">{/* 1 row in item */}
    //           <div className=''>{/* column 1 */}
    //               <label className='mita-title'>{x.groupName}</label>
    //           </div>
    //       </div>
    //       {data.filter(c => c.groupCode === x.groupCode).map((record, index) => {
    //           <div className='ml-2'>{/* column 1 */}
    //               <label className='mita-value'>{record.name}</label>
    //           </div>
    //       })}
    //   </div>
    // )))
  }

  return (
    <React.Fragment>
      <div className="list-page containerChilderWrapper">
          <HeaderModal title="Danh sách gói dịch vụ"/>
          <Notify ref={_notiRef} />
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="list-page-search">{/* search/filter */}
              <div className="row mt-2 ">{/* button tạo mới */}
                  <div className='col-md-12 d-flex justify-content-between align-items-end'>
                    <Button className="mr-2" type="link"
                      onClick={() => {
                        setVisibleModify(true)
                      }}
                    ><i style={{ marginRight: 5 }} className="fa fa-plus-circle" />Tạo mới</Button>
                    <Input
                      prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                      className="mr-2 w-50"
                      placeholder="Nhập mã, tên gói"
                      value={keyword}
                      onChange={(e) => { setKeyword(e.target.value); }}
                      onPressEnter={(e) => { resetToFirstPage() }}
                    ></Input>
                    <label>Sắp xếp theo</label>
                    <Select className="ml-1" style={{ width: '140px' }}
                        placeholder="Ngày đăng ký, tên"
                        value={sortFields.find(x => x.selected)?.key}
                        onChange={(value) => { setSortingField(value ?? ''); }}
                    >
                        {(sortFields ?? []).map((record, index) => {
                            return (
                                <Option key={record.key} value={record.key}>{record.text}</Option>
                            )
                        })}
                    </Select>
                    <Select className="mx-1" style={{ width: '100px' }}
                        placeholder="Tăng dần/giảm dần"
                        maxTagCount='responsive'
                        value={sortTypes.find(x => x.selected)?.key}
                        onChange={(value) => { setSortingType(value ?? ''); }}
                    >
                        {(sortTypes ?? []).map((record, index) => {
                            return (
                                <Option key={record.key} value={record.key}>{record.text}</Option>
                            )
                        })}
                    </Select>
                    <label className="mr-2">Hiển thị</label>
                    <Select 
                      placeholder="10, 20, 50, 100"
                      value={maxResultCount}
                      onChange={(value) => { setMaxResultCount(value); }}
                    >
                      {listPageSize.map((record, index) => {
                        return (
                          <Option key={record} value={record}>{record}</Option>
                        )
                      })}
                    </Select>
                  </div>
              </div>
            </div>
            <div className="list-page-table mt-2">
                  <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                      bordered={false}
                      dataSource={listResult}
                      renderItem={(record) => {
                          let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                          return (
                              <List.Item
                                  // onClick={(e) => { onClickFunction(e, record) }}
                                  key={`${record.id}`}
                                  className={className}>
                                  <div className="d-flex justify-content-between w-100">
                                      <div className="d-flex flex-column justify-content-start h-100" style={{ width: '2%' }}>
                                          <Checkbox className="mr-2"
                                              checked={selectedRecords.map(x => x.id).indexOf(record.id) > -1}
                                              onChange={(e) => {
                                                  if (e.target.checked){
                                                      setSelectedRecords(c => [...c, record])
                                                  } else{
                                                      setSelectedRecords(selectedRecords.filter(x => x.Id !== record.Id))
                                                  } 
                                              }}
                                              onClick={(e) => {e.stopPropagation();}}
                                          ></Checkbox>
                                      </div>
                                      <div className="d-flex flex-column justify-content-start h-100" style={{ width: '33%' }}>
                                          <div className="">
                                              <label className="mita-title mr-2">{record.name}</label>
                                              { !isNullOrEmpty(record.description) && 
                                                <Popover placement="right" content={<span style={{whiteSpace: "pre-line"}}>{record.description}</span>}>
                                                  <label><i className="fas fa-info-circle"></i></label>
                                                </Popover>}
                                          </div>
                                          <div>
                                            <label className="mita-value mr-2">Mã gói: {record.code}</label>
                                          </div>
                                      </div>
                                      <div className="d-flex flex-column justify-content-start h-100" style={{ width: '40%' }}>
                                          <div className="">
                                            {record.idVp && <label><i className="fas fa-link mr-2"></i>{record.serviceName}</label> }
                                          </div>
                                      </div>
                                      <div className="d-flex flex-column justify-content-start h-100" style={{ width: '15%' }}>
                                          <div className="">
                                            <Popover placement="top" content={ 
                                              record.groupNameShortcut.map(x => (
                                                <div className="d-flex flex-column w-100" key={`pk-${x.value}`}>{/* 1 item */}
                                                  <div className="d-flex justify-content-between w-100">{/* 1 row in item */}
                                                      <div className=''>{/* column 1 */}
                                                          <label className='mita-title'>{x.label}</label>
                                                      </div>
                                                  </div>
                                                  {record.listServicePackageItem.filter(c => c.groupCode === x.value).map((item, index) => (
                                                      <div className='ml-2'>{/* column 1 */}
                                                          <label className='mita-value'>{item.name}</label>
                                                      </div>
                                                  ))}
                                                </div>
                                              ))
                                            }>
                                              <label className='mita-title'>Số lượng dịch vụ: </label>
                                              <label className="mita-value">{record.quantityService}</label>
                                            </Popover>
                                          </div>
                                      </div>
                                      <div className="d-flex flex-column justify-content-end h-100" style={{ width: '5%' }}>
                                        <div>
                                          <Switch disabled={true} checked={record.isActive} onChange={() => {}} />
                                        </div>
                                      </div>
                                      <div className="d-flex flex-row justify-content-end h-100" style={{ width: '5%' }}>
                                          <div className='itemButton' onClick={() => { handleEdit(record.id) }}><i className="fas fa-pencil-alt mr-3"></i></div>
                                          <Popconfirm
                                            title="Bạn chắc chắn muốn xóa gói?"
                                            onConfirm={() => { DeleteServicePackage(record.id)}}
                                            okText="Đồng ý"
                                            cancelText="Đóng"
                                          >
                                            <div className='itemButton'><i className="fas fa-trash-alt mr-2"></i></div>
                                          </Popconfirm>
                                      </div>
                                  </div>
                              </List.Item>
                          )
                      }}
                  >
                    <List.Item key="showMore">
                        <div className="w-100 d-flex justify-content-center">
                            <button
                                className='btn btn-secondary'
                                hidden={totalRecords <= listResult.length}
                                onClick={() => {
                                    setSkipCount(skipCount + maxResultCount);
                                }}
                            >Hiển thị thêm {totalRecords - listResult.length}</button>
                        </div>
                    </List.Item>
                  </List>
              </div>
          </div>
        </div>
        <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"} >
          {/* footer (nếu có) */}
          <div> </div>
        </div>


    <Drawer className='custom-ant-drawer'
      maskClosable={false}
      mask={true}
      closable={false}
      width={700}
      placement="right"
      onClose={(isReload) => handleClose(isReload)}
      visible={visibleModify}
      destroyOnClose={true}
    >
      <Modify 
        isEdit={isEdit} 
        selectedPackageId={selectedPackageId} 
        onClose={(isReload) => handleClose(isReload)} />
    </Drawer>
    </React.Fragment>
  );
})