import { Form, Input, notification, Select } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
const { Option } = Select;
const initialState = {
    matp: '',
    tentp: '',
    mavung: ''
}
export default class createcity extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstVung: [],
            newCity: true
        };
    }

    componentDidMount() {
        this.loadVung();
    }
    searchCity(key = "", page = 1, size = 1) {
        CommonService.DanhMucThanhPho(key, page, size).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    newCity: false
                });
            } else {
                this.setState({
                    newCity: true
                });
            }
        })
    }
    loadVung() {
        CommonService.DanhMucVung().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstVung: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu vùng bị lỗi' })
        })
    }
    async createCity() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                tentt: this.state.tentp,
                mavung: this.state.mavung,
                loai: 'thanhthi',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.loadCity();
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    resetState() {
        this.form.setFieldsValue({
            matp: null,
            tentp: null,
            mavung: null
        });
        this.setState({
            matp: '',
            tentp: '',
            mavung: ''
        });
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: "5px 20px" }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                            <h3>Thêm dữ liệu thành phố</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item label="Tên thành phố:" name="tentp"
                                    rules={[{ required: true, message: 'Yêu cầu nhập tên thành phố!' }]}>
                                    <Input
                                        value={this.state.tentp}
                                        onChange={(e) => this.setState({ tentp: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Vùng:" name="mavung"
                                    rules={[{ required: true, message: 'Yêu cầu chọn vùng!' }]}>
                                    <Select
                                        showSearch
                                        className="w-100"
                                        onChange={(e) => { this.setState({ mavung: e }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstVung.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.mavung}>{row.tenvung}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" disabled={!this.state.newCity} onClick={() => this.createCity()}
                            ><i className="fa fa-save" style={{ marginRight: 5 }} />Lưu</button>
                            <button className="btn btn-warning"
                                style={{ marginLeft: 10 }} onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }} type="button"
                                onClick={() => {
                                    this.props.onCancel();
                                    this.form.resetFields();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng

                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
