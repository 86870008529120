
import { Switch, Form, Input, Modal, notification, Pagination, Table, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import CreateDepartment from './createDepartment';
import * as PhongKhamService from '../services/PhongKhamService';
import * as CommonService from '../../../services/commonService';
import * as Actions from '../../../libs/actions';

const initialState = {
    visible: false,
    visibleCreate: false,
    mapk: 0,
    totalRecords: 0,
    currentPage: 1,
    key: '',
    tenphongkham: ''
}
export default class Department extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            departments: [],
            key: '',
            loadingSwitch: false,
            pageSize: 10,
        };
    }

    componentDidMount() {
        this.loadDepartment();
    }
    onReload = () => {
        this.setState({
            currentPage: 1
        });
    }
    loadDepartment() {
        PhongKhamService.getListDepartment(this.state.key, this.state.currentPage, this.state.pageSize).then(result => {
            if (result.isSuccess) {
                this.setState({
                    departments: result.data,
                    totalRecords: result.totalRecords,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi' });
        })
    }

    async updateDepartment() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                tenkp: this.state.tenkp,
                makp: this.state.makp,
            }
            PhongKhamService.updateDepartent(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
        this.loadDepartment();
    };
    updateCheckSwitch(row, check) {
        this.setState({ loadingSwitch: true });
        CommonService.UpdateHienThiKhoa(row.makp, check).then(result => {
            let data = this.state.departments;
            let item = data.find(x => x.makp === row.makp);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                departments: data.map(x => ({ ...x })),
                loadingSwitch: false,
            })
        }).catch(err => {
            let data = this.state.departments;
            let item = data.find(x => x.makp === row.makp);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingSwitch: false, departments: data.map(x => ({ ...x })) })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    
    XoaKhoa(makp) {
        PhongKhamService.deleteDepartment(makp).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thao tác thành công' });
                this.loadDepartment();
            }
            else{
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.warning({ message: err });
        })
    }

    render() {
        const columns = [
            {
                title: 'Mã Khoa',
                dataIndex: 'makp',
                key: 'makp',
                with: 100,
            },
            {
                title: 'Tên Khoa',
                dataIndex: 'tenkp',
                key: 'tenkp',
            },
            {
                title: 'Hiển thị',
                with: 100,
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingSwitch} checkedChildren="Có" unCheckedChildren="Không" checked={row.hienthi === 1 || row.hienthi === true}
                            onChange={(e) => { this.updateCheckSwitch(row, e) }} />
                    )
                }
            },
            {
                title: 'Action',
                width: 200,
                render: (row) => {
                    return (
                        <>
                            <button className="btn btn-warning"
                                title="Cập nhật phòng khám!"
                                onClick={() => {
                                    this.setState({ makp: row.makp, tenkp: row.tenkp, visible: true });
                                    this.form.setFieldsValue({
                                        tenkp: row.tenkp
                                    })
                                }} >
                                <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { 
                                    this.XoaKhoa(row.makp)
                                }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </>
                    )
                }
            }
        ];
        let dataSource = this.state.departments.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{height:'100%'}}>

                    <div className="row" style={{ display: 'flex' }}>
                        <div className="col-md-6">
                            <input
                                className="form-control mb-2"
                                value={this.state.key}
                                placeholder="Nhập mã hoặc tên khoa cần tìm"
                                onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({
                                            currentPage: 1,
                                        }, () => { this.loadDepartment() });
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <button className="btn btn-success"
                                onClick={() => this.setState({
                                    currentPage: 1,
                                }, () => { this.loadDepartment() })}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </button>
                            <button className="btn btn-success" style={{ marginLeft: 10 }}
                                onClick={this.showModalCreate}>
                                <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Modal maskClosable={false}
                            width={600}
                            visible={this.state.visible}

                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Cập nhật thông tin khoa khám bệnh</h3>
                            </div>
                            <div className="container-fluid row" style={{ display: 'flex', padding: 'inherit' }}>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Tên Khoa:" style={{ flex: 1 }} name="tenkp" rules={[{ required: true, whitespace: true, message: 'Tên Khoa không được để trống!' }]}>
                                        <Input
                                            value={this.state.tenkp}
                                            onChange={(e) => {
                                                this.setState({ tenkp: e.target.value })
                                            }} />
                                    </Form.Item>
                                </div>

                            </div>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={() => this.updateDepartment()}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }} /> Cập nhật</button>
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-danger"
                                        onClick={() => {
                                            this.handleCancel();
                                            this.onReload();
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                        /> Đóng
                                    </button>
                                </Form.Item>
                            </div>
                        </Modal>
                        <Modal maskClosable={false}
                            width={800}
                            visible={this.state.visibleCreate}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <CreateDepartment onCancel={this.handleCancel}
                                onReload={this.onReload} />
                        </Modal>

                    </div>
                    <div style={{height:'calc(100% - 100px)'}}>
                        <Table dataSource={dataSource} scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={this.state.currentPage}
                        onChange={(page, size) => {
                            this.setState({
                                currentPage: page,
                                pageSize: size
                            }, () => { this.loadDepartment() });
                        }}
                        onShowSizeChange={(page, size) =>
                            this.setState({
                                currentPage: page,
                                pageSize: size
                            }, () => { this.loadDepartment() })
                        }
                        showSizeChanger={true}
                    />

                </Form>
            </React.Fragment>
        )
    }
}