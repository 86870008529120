import { Button, DatePicker, Form, Modal, notification, Select, TimePicker } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';
import * as EnterInfo from '../../../services/enterInfoService';
import * as RegisterService from '../../../services/registerService';
import Administrative from '../components/administrative';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const { Option } = Select;
const initialState = {
    dateIn: moment(),
    timeIn: moment().format('HH:mm'),
    cardNumber: '',
    dateExpired: '',
    routeCode: '',
    routeName: '',
    route: false,
    relative: false,
    relativeName: '',
    relativeAddress: '',
    relativePhone: '',
    popupList: false,
    totalRecords: 0,
    subject: '',
    managementID:'',
    showBHYT:false,
};
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        
        this.state = {
            ...initialState,
            patientObject: [],
            subject: '',
            lstcardAddress: [],
            lstSubject: [],
            lstdepartment: [],
            lstPatient: [],
            lstColums: [],
            subjectName: '',
            subjectCode: '',
            clinic: 0,
            clinicName: ''
        };
    }

    onLoaded(_administrative) {
        this.administrative = _administrative;
        this.administrative.handleLoadPatientBarCode(this.props.patientObject)
        this.setState({
            managementID:this.props.patientObject.managementID,
        });

    }
    componentWillMount() {
        this.loadNhomDoiTuong();
        //this.loadKCB('');
        this.loadPhongKham();
    }


    loadNhomDoiTuong(pageNumber, pageSize) {

        EnterInfo.GetNhomDoiTuong(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstSubject: result.data,
                    subject: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm đối tượng bị lỗi' })
        })
    }
    loadPhongKham(pageNumber, pageSize) {
        EnterInfo.GetPhongKham(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstdepartment: result.data,
                    department: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm đối tượng bị lỗi' })
        })
    }

    loadKCB(key) {
        EnterInfo.GetDSKCB(key).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstcardAddress: result.data,
                    cardAddress: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu KCB bị lỗi' })
        })
    }
    getBHYT(value) {
        if (this.validator.fieldValid('số thẻ')) {

        }
    }

    async submitUpdate() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length


        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length

        })
        await this.administrative.getForm().validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length

        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        if (numberError === 0) {
            Actions.setLoading(true);
            let data = new FormData();
            let dataAdministrative = this.administrative.getState();
            let obj = this.form.getFieldsValue();
            data.append('barCode', this.props.chinhanh);
            data.append('barCode', dataAdministrative.code ? dataAdministrative.code : '');
            data.append('code', dataAdministrative.code ? dataAdministrative.code : '');
            data.append('managerId', this.state.managementID);
            data.append('name', dataAdministrative.name);
            data.append('dob', dataAdministrative.dob.format('YYYY-MM-DD'));
            data.append('age', dataAdministrative.age);
            data.append('gender', dataAdministrative.gender);
            data.append('people', dataAdministrative.people);
            data.append('address', dataAdministrative.address);
            data.append('cmnd', dataAdministrative.cmnd);
            data.append('phone', dataAdministrative.phone);
            data.append('email', dataAdministrative.email);
            data.append('city', dataAdministrative.city);
            data.append('district', dataAdministrative.district);
            data.append('ward', dataAdministrative.ward);
            // data.append('jobAddress', dataAdministrative.jobAddress);
            // data.append('village', dataAdministrative.village!== undefined ? dataAdministrative.village : '');
            data.append('country', dataAdministrative.nation);
            data.append('dateInHospital', obj.dateIn.format("YYYY/MM/DD"));
            data.append('timeInHospital', obj.dateIn.format('HH:mm'));
            data.append('department', obj.department);
            data.append('subjectCode', obj.subject);
            // data.append('cardCode', obj.cardNumber);
            // data.append('cardNumber', obj.cardNumber);
            // data.append('cardFrom', (obj.dateExpired.length > 1 && obj.dateExpired[0] !== '') ? obj.dateExpired[0].format('YYYY-MM-DD') : '0001-01-01');
            // data.append('cardTo', (obj.dateExpired.length > 1 && obj.dateExpired[1] !== '') ? obj.dateExpired[1].format('YYYY-MM-DD') : '0001-01-01');
            // data.append('route', this.state.route);
            // data.append('routeCode', this.state.routeName);
            // data.append('routeName', this.state.routeName);
            // data.append('relative', this.state.relative);
            // data.append('relativeName', obj.relativeName);
            // data.append('relativeAddress', obj.relativeAddress);
            // data.append('relativePhone', obj.relativePhone);
            RegisterService.SavePatient(data).then(result => {
                if (result.isSuccess) {
                    this.props.onCancel();
                    notification.success({ message: "Sửa thành công" });
                    this.setState({ canPrint: true, maql: result.id, relative:false })
                    this.loadListPatient();
                    if (this.props.reloadListPatient) {
                        this.props.reloadListPatient();
                    }
                }
                else {
                    notification.success({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {

        this.setState({
            visible: false,
        });
    };
    formRef =  (c) =>{
        if(this.form === null && c !=null && this.props.patientObject !== null){
            this.form = c;
            this.setState({
                showBHYT:Number(this.props.patientObject.subjectCode)===1,
                route:this.props.patientObject.route,
                relative:this.props.patientObject.nguoithan,
                routeName:this.props.patientObject.hospitalID,
            })
            this.form.setFieldsValue({
                dateIn: moment(this.props.patientObject.applyDate),
                timeIn: moment(this.props.patientObject.applyDate),
                cardNumber: this.props.patientObject.cardNumber,
                relativeName: this.props.patientObject.tennguoithan,
                relativeAddress: this.props.patientObject.diachinguoithan,
                relativePhone: this.props.patientObject.dienthoainguoithan,
                subject: Number(this.props.patientObject.subjectCode),
                code: this.props.patientObject.patientID,
                department:this.props.patientObject.department,
                dateExpired:[moment(this.props.patientObject.cardFrom).year()===1?'':moment(this.props.patientObject.cardFrom),moment(this.props.patientObject.cardTo).year()===1?'':moment(this.props.patientObject.cardTo)],
                enterCode:this.props.patientObject.enterCode,
                enterNumber:this.props.patientObject.enterNumber,
                routeCode:this.props.patientObject.hospitalID === 0?'':this.props.patientObject.hospitalID,
                routeName:this.props.patientObject.hospitalID === 0?'':this.props.patientObject.hospitalName,
            })
        }
    }

    BuildForm = () => {
        return (
            <Form ref={(c) => this.formRef(c)}>
                <div className="form-group" style={{ display: 'flex' }} >
                    <Form.Item label="Ngày vào viện"
                        name="dateIn" rules={[{ required: true }]}
                    >
                        <DatePicker locale={viVN} format={"DD-MM-YYYY"} placeholder="DD-MM-YYYY" onChange={(date, dateString) => { }} />
                    </Form.Item>
                    <Form.Item label="Giờ vào viện" name="timeIn" rules={[{ required: true }]}
                    >
                        <TimePicker

                            format={'HH:mm'}
                            onChange={(date, dateString) => { }}
                        />
                    </Form.Item>
                </div>
                <div className="form-group"  >
                    <Form.Item label="Phòng khám:" name="department" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {this.state.lstdepartment.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className="form-group"  >
                    <Form.Item label="Đối tượng:" name="subject" rules={[{ required: true }]}>
                        <Select onChange={(value) => {
                            if (value === 1) {
                                this.setState({ showBHYT: true })
                            } else {
                                if (this.state.showBHYT) {
                                    this.setState({ showBHYT: false })
                                }
                            }
                        }}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {this.state.lstSubject.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
                {/*
                <div className="form-group">
                    <Form.Item label="Số thẻ" name="cardNumber" rules={[{ required: this.state.showBHYT, min: 20, message: 'Vui lòng nhập từ 12 ký tự thẻ !'  }]}>
                        <Input id="cardNumber" disabled={!this.state.showBHYT} maxLength="20" placeholder="Nhập số thẻ"
                            onBlur={(e) => this.getBHYT(e.target.value)}
                        />

                    </Form.Item>
                </div>
                <div className="form-group">
                    <Form.Item label="NNC - HH" name="dateExpired" rules={[{ required: this.state.showBHYT, message: 'Vui lòng chọn ngày hết hạn !' }]}>
                        <RangePicker format={"DD-MM-YYYY"} placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']} disabled={!this.state.showBHYT}
                            onChange={(date, dateString) => { }} />
                    </Form.Item>
                </div>
                <div className="form-group" >
                    <Form.Item label="ĐKKCB:" name="routeName" rules={[{ required: this.state.showBHYT, message: 'Vui lòng chọn bệnh viện !' }]} style={{ flex: 2 }}>
                        <Autocomplete
                            disabled={!this.state.showBHYT}
                            ref={((c) => this.autocomplete = c)}
                            dataSource={this.state.lstcardAddress}
                            handleSearch={this.loadKCB.bind(this)}
                            getItem={(key, value) => {
                                this.form.setFieldsValue({
                                    routeName: value,
                                });
                                this.setState({ routeName: key })
                            }}
                            value={this.state.cardAddress}
                        />
                    </Form.Item>
                </div>
                <div className="form-group">
                    <div className="form-group" style={{ display: 'flex' }} >
                        <Form.Item label="Người thân" name="relative" style={{ flex: 1 }}>
                            <Checkbox checked={this.state.relative} onChange={(e) => {this.setState({relative:e.target.checked})}} />
                        </Form.Item>
                        <Form.Item label="Trái tuyến" name="route" style={{ flex: 1 }}>
                            <Checkbox checked={this.state.route} onChange={(e) => {this.setState({route:e.target.checked})}} disabled={!this.state.showBHYT} ></Checkbox>
                        </Form.Item>
                    </div>
                    <div className={`form-group ${this.state.relative===false?'relative-close':'relative-open'}`}>
                        <Form.Item label="Họ tên người thân" name="relativeName">
                            <Input placeholder="Họ tên người thân" />
                        </Form.Item>
                        <Form.Item label="Địa chỉ người thân" name="relativeAddress">
                            <Input placeholder="Địa chỉ người thân" />
                        </Form.Item>

                        <Form.Item label="Điện thoại:" name="relativePhone">
                            <Input placeholder="Điện thoại người thân" />
                        </Form.Item>
                    </div>
                </div>
                */}
            </Form>
        )
    }
    render() {
        return (
            <React.Fragment>
                <Modal maskClosable={false}
                    onCancel={() => this.props.onCancel()}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={this.props.patientObject !== null && this.props.patientObject !== undefined}
                    title="Chỉnh sửa bệnh nhân"
                    width="95%"
                    can
                    footer={[
                        <Button  type="primary" key="back" onClick={() => this.props.onCancel()}>
                            Hủy
                        </Button>,
                        <Button disabled={this.props.patientObject.done.toLowerCase()==='x'} key="submit" type="primary" onClick={() => this.submitUpdate()}>
                            {this.props.patientObject.done.toLowerCase()==='x'?'Đã khám':'Xác nhận'}
                        </Button>,
                    ]}
                >
                    <div >
                        <div className="col-lg-6 col-sm-12">
                            <Administrative isNotShowBarCode={true} onLoaded={this.onLoaded.bind(this)}

                            />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="h6 text-center" style={{ marginTop: 10, marginBottom: 30 }}>II. THÔNG TIN VÀO VIỆN</div>
                            {this.BuildForm()}
                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        )
    }
}
