import React, { useRef, Fragment, useState, useEffect, forwardRef, useCallback, useImperativeHandle } from "react";
import { List, Drawer, Button, Tooltip, Checkbox, Popconfirm, Radio, Popover, Tag , notification } from 'antd';
import moment from "moment";
import * as Actions from '../../../libs/actions';
import Notify, { NotifyStatus } from '../../../components/notify';
import SelectProduct from '../../../components/MDCLS/selectProduct';
import SelectProductAutocomplete from '../../../components/MDCLS/selectProductAutocomplete';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import AppliedVoucher from '../../invoice/payment/appliedVoucher';
import { LOYALTY_TYPE } from "../../../configureHIS/common/constants";
import * as ChiDinhService from '../../../services/chiDinhService';
import * as PackageService from "../../../services/packageService";
import * as PrintService from '../../../services/printService';
import * as NhanVienSerivce from '../../../services/employeeService';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { groupBy, isNullOrEmpty, renumber, uppercaseFirstLetter } from "../../../libs/util";
import Handlebars from "handlebars";
import {default as num2words} from 'vn-num2words';
import handlebarHelper from "../../../libs/handlebarHelper";
import { connect } from 'react-redux';

/** Hướng dẫn sử dụng component
 ** Màn hình đăng ký chỉ định
 ** Các component đang sử dụng:
    - src\screens\exam\examination\detail-components\clinical-exam.js (màn hình Khám bệnh)
    - src\screens\register\register-products\registerproduct.js (màn hình Đăng ký)
 ** Các props nhận vào:
 * @param {string} props.parentComponent: string. màn hình/component cha sửa dụng component này.
 * @param {string} props.data: [require] object. 
 * properties của props.data
 * {
     patientCode: [require] string. Mã bệnh nhân
     priceListName: [require] string. Tên bảng giá
     priceListNameText: string. Bảng giá của...
     managermentId: [require] string. Mã quản lý
     siteId: [require] string. Chi nhánh theo mã quản lý
     registedDate: datetime. Ngày đăng ký.
     examinationId: string. id chỉ định, từ màn hình trước. Dành cho module "Khám bệnh".
     serviceName: string. Tên dịch vụ từ màn hình trước. Dành cho module "Khám bệnh".
     serviceCode: number. id viện phí, từ màn hình trước. Dành cho module "Khám bệnh".
 * }
*/
const PARENTCOMPONENT = {
    DANGKY: 'DANGKY',
    KHAMBENH: 'KHAMBENH',
};
const SHOWDELETEINDICATION = {
    ACCEPTALL: "ACCEPTALL",
    BYUSER: "BYUSER",
}
const RegisterProductIndication = React.forwardRef((props, ref) => {
// export default forwardRef ((props, ref) => {
    
    useImperativeHandle(ref, () => ({
        getListService: () => {
            return listResultRoot;
        }
    }));

    const hiddenLink = useRef();
    const notiRef = useRef();
    const selectProductRef = useRef();
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [cancel, setCancel] = useState(); // list chidinh để view
    const [listResult, setListResult] = useState([]); // list chidinh để view
    const [listResultLoai, setListResultLoai] = useState([]); // list loại

    const [listResultRoot, setListResultRoot] = useState([]); // list chidinh full. Cái listResult trên là lọc lại để hiển thị.
    const [listResultOfCurrentUser, setListResultOfCurrentUser] = useState([]); // list chidinh của current user và tạo trong dịch vụ khám bệnh hiện tại.

    const [totalAmount, setTotalAmount] = useState(0);//tổng tiền chưa thanh toán
    const [totalAmountPaid, setTotalAmountPaid] = useState(0); //tổng tiền đã thanh toán
    const [totalRecord, setTotalRecord] = useState(0);
    //này là list dịch vụ từ db hoặc đang được chọn. Theo model của component Product
    const [selectedListServices, setSelectedListServices] = useState([]);
    const [data, setData] = useState(props.data);
    const [checkPrintAll, setCheckPrintAll] = useState(false);// này là check/uncheck all các dịch vụ
    const [indeterminateCheckPrintAll, setIndeterminateCheckPrintAll] = useState(false);
    const [countResultOfCurrentUser, setCountResultOfCurrentUser] = useState(0);
    // Giá trị của checkbox hiện tất cả chỉ định
    const [checkViewIndicationAll, setCheckViewIndicationAll] = useState();
    // idChiDinhs này dùng cho mục đích tick chọn để in chỉ định. Không dùng cho mục đích khác nhé. 
    // 2023.07.26: Ngoài việc check để in thì còn check để update nhân viên tư vấn nữa. Nên cái idChiDinhs này nói chung là lưu id các chỉ định được check.
    const [idChiDinhs, setIdChiDinhs] = useState([]); 
    //checkedListServices ngày lưu lại  các service được check (dựa vào idChiDinhs)
    const [checkedListServices, setCheckedListServices] = useState([]); 
    const [visibleSelectProduct, setVisibleSelectProduct] = useState(false);
    const [keywordProducts, setKeywordProducts] = useState('');
    const [currentUserId, setCurrentUserId] = useState(props.idUser);
    
    // const [listBangGia, setListBangGia] = useState();
    const [listConsultant, setListConsultant] = useState([]);
    const [consultantId, setConsultantId] = useState();
    const [consultantName, setConsultantName] = useState();
    const [radDVKM, setRadDVKM] = useState(1);
    const [visibleConsultantPopover, setVisibleConsultantPopover] = useState(false);

    const [parentComponent, setParentComponent] = useState('');
    const [showLoyalty, setShowLoyalty] = useState(false); //Dùng để hiện lựa chọn CTKM. 
    const [showPrintRegister, setShowPrintRegister] = useState(false); //Dùng để hiện button link "In phiếu đăng ký".
    const [showPrintIndication, setShowPrintIndication] = useState(false); //Dùng để hiện button link "In chỉ định".
    const [showConsultant, setShowConsultant] = useState(false); //Dùng để hiện/update NV tư vấn.
    const [showBangGia, setShowBangGia] = useState(false); //Dùng để hiện/update bảng giá.
    const [showCheckShowAll, setShowCheckShowAll] = useState(false); //Dùng để hiện/update checkbow show all.
    // - xóa dịch vụ: dựa vào trạng thái và dựa vào màn hình: màn hình đăng ký cho phép xóa tự do nếu trạng thái được xóa, màn hình khám bệnh tùy user ra chỉ định (user tạo).
    // state showDeleteIndication dùng để hiện button xóa chỉ định. state này là dựa vào màn hình, phải kết hợp với trạng thái nữa.
    // state này nhận 1 trong 2 giá trị: ACCEPTALL | BYUSER tương ứng là hiện hết | hiện bởi user. Nếu không có giá trị (null/empty/undefined) thì ẩn hết.
    const [showDeleteIndication, setShowDeleteIndication] = useState(); 

    useEffect(() => {
        setShowHideFuntions();
        setParentComponent(props.parentComponent);
    },[props.parentComponent])

    useEffect(() => {
    },[props.data])

    useEffect(() => {
        if (!firstRender.current) search(checkViewIndicationAll);
    },[checkViewIndicationAll])

    useEffect(() => {
        setIndeterminateCheckPrintAll(idChiDinhs.length > 0 && idChiDinhs.length < countResultOfCurrentUser);
        if (!firstRender.current) {
            let lCheckedListServices = (listResultRoot ?? []).filter(x => idChiDinhs.indexOf(x.idChiDinh) > -1);
            setCheckPrintAll(idChiDinhs && idChiDinhs.length > 0 && idChiDinhs.length == countResultOfCurrentUser);
            setCheckedListServices(lCheckedListServices);
        }
    }, [idChiDinhs, countResultOfCurrentUser]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, []);

    // function getListBangGia() {
    //     VPService.GetListBangGia().then(result => {
    //         if (result.isSuccess) {
    //             setListBangGia(result.data);
    //         }
    //     }).catch(err => {
    //     }).finally(() => {
    //         this.forceUpdate();
    //     })
    // }

    const setShowHideFuntions = () => {
        switch ((props.parentComponent ?? "").toUpperCase()) { // cái (?? "") là phòng trường hợp người code không truyền props.parentComponent thì mặc định = "". tại nếu null/undefined mà toUpperCase là coi chừng lỗi
            case PARENTCOMPONENT.DANGKY:
                setShowLoyalty(true);
                setShowPrintRegister(true);
                setShowConsultant(true);
                setShowBangGia(true);
                setCheckViewIndicationAll(true);
                setShowDeleteIndication(SHOWDELETEINDICATION.ACCEPTALL);
                break;
            case PARENTCOMPONENT.KHAMBENH:
                setShowPrintIndication(true);
                setShowCheckShowAll(true);
                setCheckViewIndicationAll(false);
                setShowDeleteIndication(SHOWDELETEINDICATION.BYUSER);
                setCancel(0);
                break;
        }
        forceUpdate();
    }

    function setListIndicationWithUser(items) {
        let listResultOfCurrentUser = items;
        let count = 0;
        switch ((props.parentComponent ?? "").toUpperCase()) { // cái (?? "") là phòng trường hợp người code không truyền props.parentComponent thì mặc định = "". tại nếu null/undefined mà toUpperCase là coi chừng lỗi
            case PARENTCOMPONENT.DANGKY:
                // màn hình Đăng ký thì cho check hết, để có thể cập nhật
                count = items.reduce((accumulator, currentItem)  => accumulator + (currentItem.listChild ?? []).length, items.length);
                break;
            case PARENTCOMPONENT.KHAMBENH:
                listResultOfCurrentUser = items.filter(x => x.userCreate == currentUserId && x.parentId == data.examinationId);
                setListResultOfCurrentUser(listResultOfCurrentUser);
                count = listResultOfCurrentUser.reduce((accumulator, currentItem)  => accumulator + (currentItem.listChild ?? []).length, listResultOfCurrentUser.length);
                setCountResultOfCurrentUser(count);
                break;
        }
        setListResultOfCurrentUser(listResultOfCurrentUser);
        setCountResultOfCurrentUser(count);
    }

    const search = (chkViewIndicationAll = checkViewIndicationAll) => {
        Actions.setLoading(true);
        let filterModel = {
          //common: paging & sorting
          skipCount: 0,
          maxResultCount: 0,
        //   sorting: sorting,
          //riêng theo module
          keyword: '',
          maQL: data.managermentId,
          orderId: '',
          sid: '',
          cancel: (props.parentComponent == PARENTCOMPONENT.KHAMBENH ? 0 : null),
        }
        ChiDinhService.getList(filterModel).then(result => {
          if (result.isSuccess) {
            setListIndicationWithUser(result.data.items);
            setTotalRecord(result.data.totalCount);
            setListResultRoot(result.data.items);
            setIndicationView(chkViewIndicationAll, result.data.items);
            setSelectedListServices(convertProductToSelectComponent(result.data.items));
            notiRef.current && notiRef.current.Close();
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
            Actions.setLoading(false);
          }
        }).catch(err => {
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
          else notiRef.current.Show(err, NotifyStatus.Warning);
          Actions.setLoading(false);
        }).finally(() => {
            forceUpdate();
        })
    }
    
    function xoaChiDinhByVienPhiId(vienPhiId) {
        let row = listResult.find(x=>x.vienPhiId == vienPhiId);
        Actions.setLoading(true);
        return new Promise((resolve, reject) => {
            ChiDinhService.XoaChiDinh(row.idChiDinh, row.chiNhanh).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Xóa thành công" })
                    search();
                    return resolve(true);
                } else {
                    if (result.err)
                        return reject(result.err.msgString);
                    else if (result.error) {
                        return reject(result.error.messageText);
                    }
                    else return reject("Lỗi server. Liên hệ IT check Exception")
                }
            }).catch(err => {
                return reject("Lỗi server. Liên hệ IT check Exception");
            }).finally(() => {Actions.setLoading(false);})
        });


        // if (row) return xoaChiDinh(row);
        // return false;
    }

    function xoaChiDinh(row) {
        Actions.setLoading(true);
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chiNhanh).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                search();
                return true;
                // this.GetListOrderById(this.state.appointmentid, this.state.selectedPatient.chinhanh)
            } else {
                notiRef.current.Show(result.err.msgString, NotifyStatus.Warning);
                return false;
            }
        }).catch(err => {
            notiRef.current.Show(err, NotifyStatus.Warning);
            Actions.setLoading(false);
            return false;
        })
    }

    const convertProductToSelectComponent = (arrProduct) => {
        return arrProduct?.map(x => {
            let tenKhoaPhong = ((x.nhomId == 8 && (x.tenkp || x.tenPhongKham)) ? `${x.tenkp } - ${x.tenPhongKham }` : '');
            // let tenKhoaPhong = `${x.tenkp } - ${x.tenPhongKham }`;
            let listChild = [];
            if (x.listChild && x.listChild.length > 0) {
                listChild = convertProductToSelectComponent(x.listChild);
            }
            return {
                siteId: x.siteId,
                id: x.vienPhiId,
                ma: x.vienPhiMa,
                ten: x.vienPhiTen,
                allowDelete: x.allowDelete,
                idChiDinh: x.idChiDinh,
                uiStatus: 'selected',
                uiDeleted: false,
                gia_Th: x.donGiaGoc,
                donGia: x.donGia,
                tenKhoaPhong: tenKhoaPhong,
                statusText: x.status_Text,
                paid: x.paid,
                listChild: listChild,
            }
        })
    }

    const searchLinkClickHandle = () => {
        setVisibleSelectProduct(true); 
        setSelectedListServices(convertProductToSelectComponent(listResultRoot));
    }

    const onConfirmSelectProductHandle = (arrayResult = []) => {
        let newChiDinh = arrayResult.filter(x=>x.uiStatus == 'new');
        let listChiDinh = [];
        let newVienPhiIds = [];
        newChiDinh.forEach(x => {
            newVienPhiIds.push(x.id);
            listChiDinh.push(convertToModelSaveNew(x));
            if (x.isCombo)
                x.listChild.forEach(xChild => {
                    newVienPhiIds.push(xChild.id);
                    let itemChild = convertToModelSaveNew(xChild);
                    itemChild.vienPhiIdOfCombo = x.id;
                    listChiDinh.push(itemChild);
                });
        });
        return saveNewManyProduct(listChiDinh);
    }

    //áp dụng cho component SelectProductAutocomplete
    const onSelectIndications = (products = []) => {
        let listChiDinh = [];
        products.filter(x => x.isInBangGia).forEach(item => {
            listChiDinh.push(convertToModelSaveNew(item));
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = convertToModelSaveNew(xChild);
                    itemChild.vienPhiIdOfCombo = item.id;
                    listChiDinh.push(itemChild);
                });
        })
        saveNewManyProduct(listChiDinh);
    }
    
    const saveNewManyProduct = (listChiDinh = []) => {
        if (!listChiDinh || listChiDinh.length ===0) return;
        let model = {
            maQL: data.managermentId,
            listIndicationExams: listChiDinh,
        }
        ChiDinhService.createMany(model).then(result => {
            if (result.isSuccess) {
                setVisibleSelectProduct(false);
                if (listChiDinh.length > 1)
                    notification.success({ message: "Lưu thành công" });
                else notification.success({ message: `Lưu thành công [${listChiDinh[0].ten}]` });
                search(checkViewIndicationAll);
                if (result.data)
                    setIdChiDinhs([...idChiDinhs,...result.data]);
                notiRef.current && notiRef.current.Close();
            }
            else {
                // notification.error({ message: result.error.messageText });
                notification.error({ message: "Lưu không thành công" });
                notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                selectProductRef.current && selectProductRef.current.showNotiRef(result.error.messageText);
            }
            Actions.setLoading(false);
        }).catch(err => {
            if (err.stack) {
                notification.error({ message: "Lỗi javascript sau khi lưu" });
                return;
            }
            notification.error({ message: "Lưu không thành công" });
            notiRef.current.Show(err, NotifyStatus.Warning);
            selectProductRef.current && selectProductRef.current.showNotiRef(err);
            Actions.setLoading(false);
        })
    }
    
    const updateConsultants = () => {
        if ((checkedListServices ?? []).length == 0) return;
        let model = {
            siteId: checkedListServices[0].siteId,
            nhanVienId: consultantId,
            idChiDinhs: checkedListServices.map(x => x.idChiDinh),
        };
        Actions.setLoading(true);
        setVisibleConsultantPopover(false);
        // return;
        ChiDinhService.updateConsultants(model).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                search(checkViewIndicationAll);
                setCheckPrintAll(false);
                setIdChiDinhs([]);
                setConsultantName('');
                notiRef.current && notiRef.current.Close();
            }
            else {
                // notification.error({ message: result.error.messageText });
                notification.error({ message: "Lưu không thành công" });
                notiRef.current && notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                // selectProductRef.current && selectProductRef.current.showNotiRef(result.error.messageText);
            }
            Actions.setLoading(false);
        }).catch(err => {
            if (err.stack) {
                notification.error({ message: "Lỗi javascript sau khi lưu" });
                return;
            }
            notification.error({ message: "Lưu không thành công" });
            notiRef.current && notiRef.current.Show(err, NotifyStatus.Warning);
            Actions.setLoading(false);
        })
    }
    
    const onChangeCheckPrintAll = (e) => {
        setCheckPrintAll(e.target.checked);
        console.log(listResultOfCurrentUser);
        if (e.target.checked) {
            let idCDs = listResultOfCurrentUser.map(x => x.idChiDinh);
            console.log(idCDs);
            listResultOfCurrentUser.forEach(x=>{
                idCDs = [...idCDs,  ...(x.listChild ?? []).map(c => c.idChiDinh)];
            })
            console.log(idCDs);
            setIdChiDinhs(idCDs);
        }
        else { setIdChiDinhs([]); }
    }

    function setIndicationView(isViewAll = false, listFull = []) {
        if (listFull && listFull.length > 0) {
            let lsit = isViewAll ? listFull : listFull.filter(x => x.userCreate == currentUserId && x.parentId == data.examinationId);

            let listLoai=[];
            let amount = 0;
            let amountPaid = 0;
            lsit.forEach(x => {
                if (x.paid == 1) amountPaid += x.donGia;
                else amount += x.donGia;
                let itemLoai = listLoai.find(c=>c.loaiId == x.loaiId);
                if (!itemLoai) {
                    itemLoai={ loaiId: x.loaiId, loaiTen: x.loaiTen, listChiDinh: [] };
                    listLoai.push(itemLoai);
                }
            });
            setTotalAmount(amount);
            setTotalAmountPaid(amountPaid);
            setListResultLoai(listLoai);
            setListResult(lsit);
        }
    }

    function onChangeCheckViewIndicationAll(e) {
        setCheckViewIndicationAll(e.target.checked);
    }

    //áp dụng cho component SelectProductAutocomplete và SelectProduct. Nhưng cho api lưu chỉ định mới
    const convertToModelSaveNew = (x) => {
        return {
            bangGia: data.priceListName,
            donGia: x.donGia,
            donGiaGoc: x.gia_Th, // chỗ này lưu ý ở Đăng ký. donGiaGoc là giá dịch vụ, donGia là sau khi trừ khuyến mại nhé.
            maKp: x.maKp, // hiện tại chưa có, sau này có thể cho chọn khoa phòng khi tạo chỉ định
            maBs: parentComponent == PARENTCOMPONENT.KHAMBENH ? currentUserId : null, // 2023.06.22: màn hình khám bệnh mới lưu maBs (BS chỉ định). Sau này có màn hình nào khác lưu BS chỉ định thì sửa ở đây
            PhongKham: x.phongKham, // hiện tại chưa có, sau này có thể cho chọn kho phòng khi tạo chỉ định
            parentId: data.examinationId,
            vienPhiId: x.id,
            vienPhiMa: x.ma,
            vienPhiTen: x.ten,
            nhomId: x.nhomId,
            ngay: moment().format('YYYY/MM/DD HH:mm'),
        };
    }

    const PrintIsPaid = () => {
        if (idChiDinhs.length > 0)
            PrintService.printIndication(data.patientCode, data.managermentId, moment(data.registedDate).format('YYYY-MM-DD'), idChiDinhs.join(","), data.serviceCode)
                .then((result) => {
                    if (isNullOrEmpty(result.item.template)) {
                        Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                        return;
                    } else {
                        let templateMaster = "";
                        var finalData = result.item.data;
                        const listPacs = finalData.listPacs; // Danh sách chỉ định CĐHA (Chưa phân loại)
                        const listSurgery = finalData.listSurgery; // Danh sách chỉ định thủ thuật (Chưa phân loại)
                        const listTest = finalData.listTest; // Danh sách chỉ định thủ thuật (Chưa phân theo orderId)
                        const listOrderId = result.item.listOrderId; // Danh sách orderId
                        if (listPacs.listIndication) {
                            var listPacsGroup = groupBy(listPacs.listIndication, "tenloai"); // Danh sách chỉ định CĐHA đã phân theo loại
                            Object.keys(listPacsGroup).forEach((groupName, index) => {
                                let listPacsBelongToGroup = listPacsGroup[groupName];
                                let totalMoney = listPacsBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: listPacsBelongToGroup,
                                    title: `${listPacs.title?.toUpperCase()} ${groupName.toUpperCase()}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `pacs${index}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplateSecond.replaceAll("data.listService", `data.${keyName}`); // Thêm template chỉ định CĐHA theo loại
                            });
                        }
                        if (listSurgery.listIndication) {
                            var listSurgeryGroup = groupBy(listSurgery.listIndication, "tenloai"); // Danh sách chỉ định thủ thuật đã phân theo loại
                            Object.keys(listSurgeryGroup).forEach((groupName, index) => {
                                let listSurgeryBelongToGroup = listSurgeryGroup[groupName];
                                let totalMoney = listSurgeryBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: listSurgeryBelongToGroup,
                                    title: `${listSurgery.title?.toUpperCase()} ${groupName.toUpperCase()}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `surgery${index}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplateSecond.replaceAll("data.listService", `data.${keyName}`); // Thêm template chỉ định thủ thuật theo loại
                            });
                        }
                        if (listTest.listIndication) {
                            var listTestGroupByOrderId = groupBy(finalData.listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo loại
                            Object.keys(listTestGroupByOrderId).forEach(orderId => {
                                let listTestBelongToGroup = listTestGroupByOrderId[orderId];
                                const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
                                const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
                                const resultGroupArrRenumber = renumber(resultGroupArr);
                                let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: resultGroupArrRenumber,
                                    sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
                                    title: `${finalData.listTest.title?.toUpperCase() ?? "PHIẾU CHỈ ĐỊNH XÉT NGHIỆM"}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `test${orderId}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
                            });
                        }
                        const template = Handlebars.compile(result.item.template.replace("#listMasterData", templateMaster));
                        result.item.data = finalData;
                        Handlebars.registerHelper(handlebarHelper);
                        Actions.DoPrint(template(result.item));
                    }
                })
        else notification.warning({ message: "Vui lòng chọn chỉ định để in" })
    }

	function loadConsultant(data) {
        // if (this.props.onType) {
        //     this.props.onType(data);
        // }
        // this.setState({ key: data });
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'26');
    }

    function selectConsultant(data) {
        setConsultantId(data.id);
        setConsultantName(data.hoten);
    }

    const renderDv = (listDv) => {
      if (!listDv || listDv.length == 0) return "";
      let className = "p-1 border-2 border-bottom py-2 d-flex flex-column ant-list-item-hover";
      return (
        listDv.map((dvCha, index) => {
            let tenKhoaPhong = ((dvCha.nhomId == 8 && (dvCha.tenkp || dvCha.tenPhongKham)) ? `Khoa - phòng: ${dvCha.tenkp } - ${dvCha.tenPhongKham }` : '');
            let enableCheckPrint = false;//(dvCha.userCreate == currentUserId && dvCha.parentId == data.examinationId);
            switch (props.parentComponent) {
                case PARENTCOMPONENT.DANGKY:
                    enableCheckPrint = true;
                    break;
                case PARENTCOMPONENT.KHAMBENH:
                    enableCheckPrint = (dvCha.userCreate == currentUserId && dvCha.parentId == data.examinationId);
                    break;
            }
            let classNameDv = dvCha.cancel == 1 ? className + ' disable' : className;
            return (
                <List.Item key={`dvCha-${dvCha.idChiDinh}`}
                    className={classNameDv}
                >
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between w-100">
                            <div className="d-flex" style={{width: 'calc(70% - 106px)'}}>
                                <Tooltip title={enableCheckPrint ? '' : `Chỉ định này không phải do bạn thêm trong dịch vụ [${data.serviceName}]. Nên bạn không thể chọn để in.`}>
                                    <Checkbox className="mr-2"
                                        disabled={!enableCheckPrint}
                                        checked={idChiDinhs.indexOf(dvCha.idChiDinh) > -1}
                                        onChange={(e) => {
                                            let idChilds = (dvCha.listChild ?? []).map(x=>x.idChiDinh);
                                            if (e.target.checked) setIdChiDinhs([...idChiDinhs, dvCha.idChiDinh, ...idChilds]);
                                            else setIdChiDinhs(idChiDinhs.filter(x => x != dvCha.idChiDinh && idChilds.indexOf(x)<0));
                                        }}
                                        // onClick={(e) => {e.stopPropagation();}}
                                    ></Checkbox>
                                </Tooltip>
                                <label className="mita-value h-100">
                                    <Tooltip title={`Người đăng ký: ${dvCha.userCreateName}`}>
                                        <label>
                                            <i className= {`fa fa-user-md mr-1 
                                                ${(dvCha.userCreate == currentUserId && (dvCha.parentId == data.examinationId || props.parentComponent == PARENTCOMPONENT.DANGKY)) 
                                                    ? "text-color" : ""}`
                                            } />
                                        </label>
                                    </Tooltip>
                                    {dvCha.vienPhiTen} 
                                    {/* - {dvCha.vienPhiMa} */}
                                </label>
                            </div>
                            <div style={{width: 106}}>
                                <label className="mita-value">{dvCha.cancel == 1 ? '' : dvCha.status_Text}</label>
                            </div>
                            <div className="d-flex justify-content-end align-items-start" style={{width: '30%'}}>
                                {dvCha.cancel != 1 && <>
                                    {dvCha.paid == 1 ?
                                        // <Tooltip title="Đã thanh toán">
                                        //     <label className="mita-value"><i class="fas fa-dollar-sign"></i></label>
                                        // </Tooltip>
                                    <label className="mita-value">Đã thanh toán</label>
                                    :
                                    <>
                                        <label className="mita-value mr-2">{dvCha.donGiaGoc.toLocaleString('en-US')}</label>
                                        {dvCha.discountValue > 0 &&
                                        <Tag color="volcano" className="mr-1">
                                            Miễn giảm: {Number(dvCha.donGiaGoc - dvCha.donGia).toLocaleString('en-US')}
                                        </Tag>
                                        }
                                    </>
                                    }
                                    {(showDeleteIndication == SHOWDELETEINDICATION.ACCEPTALL 
                                        || (showDeleteIndication == SHOWDELETEINDICATION.BYUSER && dvCha.maBs == currentUserId)
                                        ) && dvCha.allowDelete && 
                                    <Popconfirm
                                        title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{dvCha.vienPhiTen}</b>?</span>)}}
                                        onConfirm={() => xoaChiDinh(dvCha)}
                                        onCancel={() => {}}
                                        okText="Đồng ý"
                                        cancelText="Hủy"
                                    >
                                        <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0, width: 16 }}
                                            hidden={!(dvCha.allowDelete ?? true)}
                                            // onClick={() => {xoaChiDinh(dvCha);}}
                                        ><i className="fa fa-trash-alt text-danger"></i></Button>
                                    </Popconfirm>
                                    }
                                </>}
                                {dvCha.cancel == 1 && <label className="mita-value">Đã hoàn trả</label>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9" style={{ paddingLeft:20}}>
                            {(tenKhoaPhong && tenKhoaPhong.length > 0) &&
                                <label className="mita-value">{tenKhoaPhong}</label>
                            }
                            </div>
                            {showConsultant && 
                            <div className="col-md-3 d-flex justify-content-start">
                                <label className="mita-value">Nhân viên tư vấn:</label>
                                <label className="mita-value">{dvCha.consultantName ?? '--'}</label>
                            </div>
                            }
                        </div>
                        {(dvCha.listChild && dvCha.listChild.length > 0) &&
                        <div className="d-flex flex-column justify-content-stretch pl-5">
                            {dvCha.listChild.map((dvConCombo, index) => {
                                let tenKhoaPhongChild = ((dvConCombo.nhomId == 8 && (dvConCombo.tenkp || dvConCombo.tenPhongKham)) ? `Khoa - phòng: ${dvConCombo.tenkp } - ${dvConCombo.tenPhongKham }` : '');
                                return (<>
                                    <div className="w-100 d-flex justify-content-between">
                                        <div className="d-flex" style={{width: 'calc(70% - 106px)'}}>
                                            <label className="mita-value h-auto">{dvConCombo.vienPhiTen} 
                                                {/* - {dvConCombo.vienPhiMa} */}
                                            </label>
                                        </div>
                                        <div style={{width: '30%'}}>
                                            <label className="mita-value">{dvCha.cancel == 1 ? '' : dvConCombo.status_Text}</label>
                                        </div>
                                        <div className="text-right" style={{width: 106}}>
                                        </div>
                                    </div>
                                    {(tenKhoaPhongChild && tenKhoaPhongChild.length > 0 && dvCha.cancel != 1 ) &&
                                    <div className="pl-3" style={{width: '55%'}}>
                                        <label className="mita-value h-auto">{tenKhoaPhongChild}</label>
                                    </div>
                                    }
                                </>
                                )
                            })}
                        </div>
                        }
                    </div>
                </List.Item>
            )
        })
      )
    }

    const renderConsultant = () => {
        return (
            <div style={{ minWidth: 600 }}>
                <h4 style={{position:'absolute', top: -8, right: -8, cursor: 'pointer'}}
                    onClick={()=>setVisibleConsultantPopover(false)}
                >
                    <i className='far fa-times-circle stopRowClick' 
                    style={{ backgroundColor: "#ccc", borderRadius: "50%", border: "1px solid #ccc" }} />
                </h4>
                <div className="row d-flex justify-content-between">
                    <div className="col-md-4"><label className="mita-title">Nhân viên tư vấn</label></div>
                    <div className="col-md-8">
                        <AutocompletetableNoGroup 
                            // ref={(c) => this.AutocompletePatient = c}
                            placeholder={"Nhập thông tin nhân viên tư vấn"}
                            width='100%'
                            tablewidth={500}
                            onSearch={loadConsultant.bind(this)}
                            onSelect={(item) => {selectConsultant(item);}}
                            headers={columnConsultant}
                            keyValue={'id'}
                            value={consultantName}
                            // showRemove={this.state.consultantName !== '' && this.state.consultantName !== undefined && this.state.consultantName !== null && !this.state.disableCopor && !isnotEdit} 
                            CleanText={() => {
                                // this.setState({ idconsultant: '', consultantName: '' });
                            }}
                            // disabled={this.state.disableCopor || isnotEdit}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6"><label className="mita-title mr-2">Dịch vụ tư vấn</label></div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {checkedListServices.map(x => {
                            return (
                                <div className="row mt-1" key={x.idChiDinh}>
                                    <div className="col-md-6"><label>{x.vienPhiTen}</label></div>
                                    <div className="col-md-6 d-flex">
                                        <label className='mita-title'>Nhân viên tư vấn: </label>
                                        <label className='mita-value'>{x.consultantName ?? "--"}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-end' >
                    <button className='btn btn-color' 
                        onClick={updateConsultants}
                    >
                        <i className='fas fa-save mr-1'></i>
                        Lưu
                    </button>
                </div>
            </div>
        )
    }

    const columnConsultant = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
        },
    ];

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
            <div className="w-100 h-100 d-flex flex-column">
                <div className="row">{/* notification */}
                    <div className="col-md-12"><Notify ref={notiRef} /></div>
                </div>
                <div className="row">
                    {/* <div className="col-md-3 d-flex">
                        <label className="mita-title">Bảng giá: </label>
                        <Tooltip title={data.priceListNameText}>
                            <label className="mita-value">{data.priceListName}</label>
                        </Tooltip>
                    </div> */}
                    <div className="col-md-12 d-flex flex-column">
                        <div className="w-100 d-flex justify-content-between">
                            <Radio.Group value={radDVKM} onChange={(e) => {setRadDVKM(e.target.value);}}>
                                <Radio value={1}><label className="mita-title">Chọn dịch vụ / gói dịch vụ</label></Radio>
                                {showLoyalty &&
                                <Radio value={2}><label className="mita-title">Nhập mã voucher có CTKM dịch vụ</label></Radio>
                                }
                            </Radio.Group>
                            <Button className="pl-0 ml-1" type="link" 
                                onClick={() => {searchLinkClickHandle();}}
                            ><i className='fa fa-search-plus mr-1'/>Tìm kiếm và chọn</Button>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="w-100">
                                {radDVKM == 1 &&
                                <SelectProductAutocomplete 
                                    showBangGia={true}
                                    bangGia={data.priceListName}
                                    selectedListServices={[
                                    ...(selectedListServices ?? []),
                                    ]}
                                    width='100%'
                                    tablewidth={500}
                                    listHeight={"50vh"}
                                    onSelect={(items) => onSelectIndications(items)}
                                    value={keywordProducts}
                                    // headers={columnsSearhProducts}
                                    keyValue={'id'}
                                />
                                }
                                {radDVKM == 2 && showLoyalty &&
                                <AutocompletetableNoGroup
                                    // ref={(c) => this.AutocompletePatient = c}
                                    placeholder={"Nhập mã voucher"}
                                    width='100%'
                                    tablewidth={500}
                                    onSearch={loadConsultant.bind(this)}
                                    onSelect={(item) => {selectConsultant(item);}}
                                    headers={columnConsultant}
                                    keyValue={'id'}
                                    value={consultantName}
                                    // showRemove={this.state.consultantName !== '' && this.state.consultantName !== undefined && this.state.consultantName !== null && !this.state.disableCopor && !isnotEdit} 
                                    CleanText={() => {
                                        // this.setState({ idconsultant: '', consultantName: '' });
                                    }}
                                    // disabled={this.state.disableCopor || isnotEdit}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            <div className="pl-1">
                                <Checkbox  className="mr-2"
                                    checked={checkPrintAll}
                                    indeterminate={indeterminateCheckPrintAll}
                                    onChange={(e) => {
                                        onChangeCheckPrintAll(e);
                                    }}
                                ></Checkbox>
                                {showPrintRegister &&
                                <Button className="pl-0" type="link" 
                                    onClick={() => {PrintIsPaid();}}
                                ><i className='fa fa-print mr-1'/>In phiếu đăng ký</Button>
                                }
                                {/* <Checkbox  className="pl-2 mr-2"
                                    checked={checkPrintAll}
                                    indeterminate={indeterminateCheckPrintAll}
                                    onChange={(e) => {
                                        onChangeCheckPrintAll(e);
                                    }}
                                ></Checkbox> */}
                                {showPrintIndication &&
                                <Button className="pl-0" type="link" 
                                    onClick={() => {PrintIsPaid();}}
                                ><i className='fa fa-print mr-2'/>In chỉ định</Button>
                                }
                                {showConsultant &&
                                <Popover
                                    content = {renderConsultant}
                                    trigger="click"
                                    visible={visibleConsultantPopover}
                                    onVisibleChange={(e) => {setVisibleConsultantPopover(e); }}
                                >
                                    <Button className="pl-2" type="link" 
                                        onClick={() => {setVisibleConsultantPopover(!visibleConsultantPopover);}}
                                    ><i className='fa fa-pencil-alt mr-1'/>Cập nhật nhân viên tư vấn</Button>
                                </Popover>
                                }
                            </div>
                            <div className="pl-1">
                                {showCheckShowAll && 
                                <Checkbox  className="mr-2 m-auto" style={{verticalAlign: 'middle'}}
                                    checked={checkViewIndicationAll}
                                    // indeterminate={indeterminateCheckPrintAll}
                                    onChange={(e) => {
                                        onChangeCheckViewIndicationAll(e);
                                    }}
                                ><span className="pl-0" >Tất cả chỉ định</span></Checkbox>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-page-table overflow-auto">{/* list */}
                    <List className="none-border-bottom none-border-bottom-item"
                        bordered={false}
                        dataSource={listResultLoai}
                        renderItem={(record) => {
                            let className = "p-1 border-2 border-top";
                            return (
                                <>
                                    <List.Item key={`loai-${record.loaiId.toString()}`}
                                        className={className}
                                    >
                                        <div className="mita-title d-flex justify-content-between w-100 text-uppercase" style={{ fontSize: 11 }}>{/* 1 item */}
                                        {record.loaiTen}
                                        </div>
                                    </List.Item>
                                    {renderDv(listResult.filter(x => x.loaiId == record.loaiId))}
                                </>
                            )
                        }}
                    >
                        <List.Item key={"total-amount"} className='p-0' style={{borderTop: '1px solid'}}>
                            <div className="d-flex flex-column w-100 p-1">
                                <div className="d-flex justify-content-end" style={{ fontSize: 16 }}>
                                    <div className=""><label className="mita-value">Chưa thanh toán</label></div>
                                    <div className="text-right ml-4">
                                        <label className="mita-title"><u>đ</u> {totalAmount.toLocaleString('en-US')}</label>
                                    </div>
                                    {/* <Tag className="d-flex flex-row justify-content-between align-items-center m-0 p-1" color="blue" style={{width: 'min-content'}}>
                                        <span>CHƯA THANH TOÁN</span>
                                        <span><h4 className="m-0 ml-3">đ {totalAmount.toLocaleString('en-US')}</h4></span>
                                    </Tag> */}
                                </div>
                            </div>
                        </List.Item>
                    </List>
                </div>
                {/* footer (nếu có) */}
                {/* <div className="d-flex justify-content-between mt-2">
                    <div className='d-flex justify-content-end' >
                        <button 
                            className='btn btn-color mr-2' 
                            // onClick={print}
                        >
                            <i className='fas fa-print mr-2'></i>
                            In
                        </button>
                        <button
                            className='btn btn-danger'
                            // onClick={() => props.onCancel()}
                        >
                            <i className='fa fa-sign-out-alt mr-2'></i>
                            Đóng
                        </button>
                    </div>
                </div> */}
            </div>
            <Drawer className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"80%"}
                placement="right"
                visible={visibleSelectProduct}
                destroyOnClose={true}
            >
                <SelectProduct ref={selectProductRef}
                    isDialog={ true } 
                    // selectedListServices={selectedListServices}s
                    selectedListServices={[
                    ...(selectedListServices ?? []),
                    // ...[
                    //   {id: 867, ten: 'Khám cấp cứu', ma: 'KCC01', allowDelete: false},
                    //   {id: 1516, ten: 'MIỄN DỊCH TEST NHANH', ma: '2-1516', allowDelete: false},
                    // ]
                    ]}
                    onCancel={() => {setVisibleSelectProduct(false)}}
                    onConfirm={(arrayResult) => { onConfirmSelectProductHandle(arrayResult); }}
                    onDeleteItem={(record) => { // record này là theo model product của component selectProduct.
                        return xoaChiDinhByVienPhiId(record.id);
                    }}
                    btnConfirmText="Lưu"
                    bangGia={data.priceListName}
                    showBangGia={true}
                    disableBangGia={true}
                />
            </Drawer>
        </Fragment>
    )
})

const mapStateToProps = (state) => {
    return ({
        idUser: state.global.idUser,
      //   siteCode: state.global.siteCode,
        // siteName: state.global.siteName
    })
  };
  
  export default connect(mapStateToProps, null, null, { forwardRef: true })(RegisterProductIndication);