/*
Component này là thông tin Kết quả xét nghiệm. 
Right panel theo màn hình 6/10 của mockup "HIS-phan he xet nghiem.pdf": thông tin phía trên (Chẩn đoán ban đầu, trạng thái)
*/
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button, Modal, Input, Upload, Popconfirm, Tooltip, Checkbox, notification } from 'antd';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ExamResultService from '../../../services/ExamResultServices';
import * as TestService from '../../../services/lisService';
import { connect } from 'react-redux';

const { TextArea } = Input;
const { Dragger } = Upload;
const TestResultBottom= React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    comment: () => {
      return comment;
    },
    suggestion: () => {
      return suggestion;
    }
  }));
  const notiRef = useRef(null);
  const notiRefFile = useRef(null);
  const _hiddenLink = useRef();
  const upload = useRef();
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [comment, setComment] = useState();
  const [suggestion, setSuggestion] = useState();
  const [fileUrlBlob, setFileUrlBlob] = useState('');
  const [file, setFile] = useState();
  const [selectedFileUuid, setSelectedFileUuid] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [listFile, setListFile] = useState([]);
  const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
  const [ghiDeKetQua, setGhiDeKetQua] = useState(false);
  const [copied, setCopied] = useState(false);
  //#region useEffect
  // useEffect(() => {
  //   if (file) submitUploadFile([file]);
  // }, [file]);
  useEffect(() => {
    getById(props.sid);
  }, []);
  //#endregion 
  //#region useEffect
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };
  const handleFileChange = (e) => {
    if (e.file.percent == 100) submitUploadFile(e.fileList);
    // upload.current.fileList = [];
    for(var i = 0; i < e.fileList.length  - 1; i++) {
      upload.current.fileList.shift();
    }
    // setFile(upload.current.fileList[0]);
    // if (upload.current.fileList[0]) setErrorFileString('');
  }
  const handleFileRemove = (e) => {
  }
  //#endregion

  const setData = (data) => {
    data.comment && setComment(data.comment);
    data.suggestion && setSuggestion(data.suggestion);
    data.lisFiles && setListFile(data.lisFiles);
  }

  const getById = (sid) => {
    ExamResultService.getExamResultInfoBottom(sid).then(result => {if (result.isSuccess) {
        notiRef && notiRef.current.Close();
        setData(result.data);
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const viewResultPdf = (data) => {
    Actions.setLoading(true);
    TestService.DownLoadLisFile(data.uuId)
        .then((response) => {
            let res = response.clone();
            res.json().then((t) => {
                if (!t.isSuccess) 
                    notification.error({ message: t.error.messageText })
            }).catch(err => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    setFileUrlBlob(url);
                    setVisiblePreviewFile(true);
                })
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })

    // TestService.DownLoadLisFile(data.uuId) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
    //     .then((response) => {
    //         response.blob().then((blob) => {
    //             let url = window.URL.createObjectURL(blob);
    //             setFileUrlBlob(url);
    //             setVisiblePreviewFile(true);
    //         })
    //         Actions.setLoading(false);
    //     }).catch(err => {
    //         Actions.setLoading(false);
    //     })
  }

  const downloadFile = () => {
      TestService.DownLoadLisFile(selectedFileUuid).then(result => {
          result.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              _hiddenLink.current.href = url;
              _hiddenLink.current.download = selectedFile ? selectedFile.title : "KetQuaXetNgiem.pdf";
              _hiddenLink.current.click();
          })
      }).catch(err => {
      })
  }

  function copyToClipboard(e) {
    setCopied(true);
    notification.success({ message: 'Sao chép thành công' });
    setCopied(false);   
  }

  const submitUploadFile = (listFile) => {
    if (listFile == null || listFile==undefined || listFile.length==0) return;
    Actions.setLoading(true);
    let formdata = new FormData();
    let title = '';
    listFile.forEach(element => {
        formdata.append('listFile', element.originFileObj);
        title = element.name;
    });
    // return;
    TestService.UploadFileResult(formdata, props.sid, props.siteId, '', ghiDeKetQua).then(res => {
        if (res.isSuccess) {
            notification.success({ message: "Upload file thành công!" });
            Actions.setLoading(false);
            getById(props.sid);
        } else {notification.warning({ message: res.err.msgString });
        }
    }).catch(err => {
        Actions.setLoading(false);
    })
  }
  const onDeleteFileClickHandle = (record) => {
    Actions.setLoading(true);
    ExamResultService.DeleteExamResultFile(record.uuId).then(res => {
        if (res.isSuccess) {
            notification.success({ message: "Xóa file thành công"});
            notiRefFile && notiRefFile.current.Close();
            getById(props.sid);
            Actions.setLoading(false);
        } else {
            notification.error({ message: "Không thể xóa file, vui lòng thử lại sau"});
            notiRefFile.current.Show(res.error.messageText, NotifyStatus.Error);
            Actions.setLoading(false);
        }
    }).catch((err) => {
      if (err.error && err.error.messageText)
      notiRefFile.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRefFile.current.Show(err, NotifyStatus.Error);
        Actions.setLoading(false);
    })
  }
  return (
    <React.Fragment>
      <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
      <div className="row">{/* notification */}
        <div className="col-md-12"><Notify ref={notiRef} /></div>
      </div>
      <div className="row mt-2">{/* Nhận xét chỉ số */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Nhận xét chỉ số</label>
          <TextArea 
            rows={4}
            value={comment}
            onChange={(e) => { setComment(e.target.value); }}
          ></TextArea >
        </div>
      </div>
      <div className="row mt-2">{/* Đề nghị */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Đề nghị</label>
          <TextArea 
            rows={4}
            value={suggestion}
            onChange={(e) => { setSuggestion(e.target.value); }}
          ></TextArea >
        </div>
      </div>
      <div className="row mt-2">{/* notification */}
        <div className="col-md-12"><Notify ref={notiRefFile}/></div>
      </div>
      <div className="row">{/* Đính kèm tệp tin */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Đính kèm tệp tin (xóa hoặc upload file là sẽ cập nhật file ngay mà không cần click Lưu)</label>
          { listFile.map((row, index) => 
            <div
            >
                <i className='fas fa-file-pdf mr-2'></i>
                <a href="#" className='mr-2' style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedFileUuid(row.uuId);
                    setSelectedFile(row);
                    viewResultPdf(row);
                  }}
                >{row.title}</a>
                <label className='mr-2'>{moment(row.dateCreate).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                <CopyToClipboard text={row.fileUrl}onCopy={() => copyToClipboard()}>
                  <i className="far fa-clone mr-2" style={{ cursor: 'pointer' }}></i>
                  {/* <a className="ant-dropdown-link" onClick={() => {}}><i className="fas fa-copy"/></a> */}
                </CopyToClipboard>
                
                <Popconfirm
                  title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{row.title}</b>?</span>)}}
                  onConfirm={() => onDeleteFileClickHandle(row)}
                  onCancel={() => {}}
                  okText="Đồng ý"
                  cancelText="Hủy"
                >
                  <i className="fas fa-trash-alt mr-2" style={{ cursor: 'pointer' }}></i>
                </Popconfirm>
            </div>
          )}
        </div>
      </div>
      <div className="row">{/* import file */}
        <div className={"col-md-12 d-flex flex-column"}>
          <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
          </div>
          <Dragger
            ref={upload}
            customRequest={dummyRequest}
            // listType="picture-card"
            // multiple={true}
            // fileList={files}
            // onPreview={handlePreview.bind(this)}
            onChange={handleFileChange.bind(this)}
            // onChange={(e) => { handleChange(e); }}
            onRemove={handleFileRemove.bind(this)}
            accept=".pdf"
            itemRender={(originNode, file, fileList) => {
              return;
              // return <div className="handle-move-upload">
              //   {originNode}
              // </div>
            }}
          >
            <p className="ant-upload-drag-icon">
              <i className="fas fa-cloud-upload-alt text-color" style={{ fontSize:"2.5rem" }}></i>
            </p>
            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây</p>
          </Dragger>
          <div className='d-flex justify-content-end '>
            <Tooltip title={`${ghiDeKetQua ? "Ghi đè" : "Không ghi đè"} file nếu trùng tên file`}>
              <Checkbox className='w-100' checked={ghiDeKetQua} onChange={() => {setGhiDeKetQua(!ghiDeKetQua);}}>Cho phép ghi đè</Checkbox>
            </Tooltip>
          </div>
        </div>
      </div>
      {visiblePreviewFile &&
          <Modal maskClosable={true}
              width={1000}
              visible={visiblePreviewFile}
              onCancel={() => {setVisiblePreviewFile(false);}}
              footer={null}>
              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <div className="list-button-preview">
                      <Button className="mr-2" onClick={() => {
                          downloadFile()
                      }}><i className="fa fa-download"></i></Button>
                      <Button onClick={() =>{setVisiblePreviewFile(false);}}>Đóng</Button>
                  </div>
                  <embed src={`${fileUrlBlob}#toolbar=0`}style={{ height: '100%', width: '100%' }}
                  />
              </div>
          </Modal>
      }
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TestResultBottom);