import React, { useEffect, useState, } from 'react'
import { Popconfirm, DatePicker, Modal, notification } from 'antd';
import { useSelector } from 'react-redux';
import * as Actions from '../../../../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as InHospitalService from '../../../../../services/inPatientService';
import moment from 'moment';
import PhieuLinhThuoc from './phieulanhthuoc'
import * as PrintService from '../../../../../services/printService';

const { RangePicker } = DatePicker;
function DanhSachPhieuLanhThuoc(props) {
    const [isKhoaDuoc, setIsKhoaDuoc] = useState(props.isKhoaDuoc === true);
    const [ngayTao, setNgayTao] = useState([moment(), moment()]);
    const [trangthai, setTrangthai] = useState(0);
    const [keySearch, setKeySearch] = useState('');
    const [lstTrangthai, setLstTrangthai] = useState([]);
    const [lstDataSource, setLstDataSource] = useState([]);
    const [checkAllSelect, setCheckAllSelect] = useState(true);
    const [visiblePhieuLinhThuoc, setVisiblePhieuLinhThuoc] = useState(false);
    const [idphieulinh, setIdphieulinh] = useState(0);
    const [lstNgay, setLstNgay] = useState([]);
    const isReload = useSelector(state => state.global.resetListPhieuLinhThuoc);
    const [tentrangthai, setTenTrangthai] = useState('');
    useEffect(() => {
        setIsKhoaDuoc(props.isKhoaDuoc);
    }, [props.isKhoaDuoc]);
    useEffect(() => {
        if (isReload === true)
            GetListPhieuLinhThuoc();
        Actions.ResetListPhieuLinhThuoc(false);
    }, [isReload]);
    useEffect(() => {
        GetListTrangThaiPhieuThuoc();
        GetListPhieuLinhThuoc();
    }, []);
    useEffect(() => {
        GetListPhieuLinhThuoc();
    }, [ngayTao, trangthai]);
    const GetListTrangThaiPhieuThuoc = () => {
        Actions.setLoading(true);
        InHospitalService.GetListTrangThaiPhieuThuoc(isKhoaDuoc).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                setLstTrangthai(result.data)
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const GetListPhieuLinhThuoc = () => {
        Actions.setLoading(true);
        InHospitalService.GetListPhieuLinhThuoc(keySearch, trangthai, ngayTao[0].format("YYYY/MM/DD"), ngayTao[1].format("YYYY/MM/DD"), isKhoaDuoc).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                let data = result.data.map(x => ({
                    ...x,
                    check: true,
                }));
                let listType = data.map(x => moment(x.ngay).format('DD/MM/YYYY'));
                let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                setLstNgay(ListTypeDistinct);
                setLstDataSource(data)
                if (data.length > 0) {
                    setCheckAllSelect(true);
                } else {
                    setCheckAllSelect(false);
                }
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const DeletePhieuLanhThuoc = (id) => {
        Actions.setLoading(true);
        InHospitalService.DeletePhieuLanhThuoc(id).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                GetListPhieuLinhThuoc();
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const UpdateCheckSelect = (data, ischeck) => {
        let dbSource = lstDataSource;
        let index = dbSource.findIndex(x => x.maphieu === data.maphieu && x.sophieu === data.sophieu);
        if (index >= 0) {
            dbSource[index].check = ischeck;
        }
        if (dbSource.findIndex(x => x.check !== true) >= 0) {
            setCheckAllSelect(false);
        }
        else {
            setCheckAllSelect(true);
        }
        setLstDataSource(dbSource.map(x => ({ ...x })));
    }
    const UpdateCheckAllSelect = (ischeck) => {
        setCheckAllSelect(ischeck);
        let dbSource = lstDataSource;
        if (ischeck === true) {
            setLstDataSource(dbSource.map(x => ({ ...x, check: true })));
        }
        else {
            setLstDataSource(dbSource.map(x => ({ ...x, check: false })));
        }
    }
    const PrintListPhieuLinhThuoc = () => {
        let lstId = lstDataSource.filter(x => x.check === true).map(x => x.id);
        if (lstId.length === 0)
            return;
        let arrId = lstId.join(',');
        PrintService.PrintListPhieuLinhThuoc(arrId).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    const XacNhanPhieuThuoc = () => {
        if (idphieulinh === 0) {
            return;
        }
        Actions.setLoading(true);
        InHospitalService.XacNhanPhieuLanhThuoc(idphieulinh).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Xác nhận thành công' });
                Actions.ResetListPhieuLinhThuoc(true);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const UpdateTrangThai = (status) => {
        if (idphieulinh === 0) {
            return;
        }
        Actions.setLoading(true);
        InHospitalService.UpdateTrangThaiPhieuLanhThuoc(idphieulinh, status).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công' });
                Actions.ResetListPhieuLinhThuoc(true);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6 my-1">
                    <RangePicker className="fontSize12 w-100" format="DD-MM-YYYY" value={ngayTao} allowClear={false}
                        locale={viVN} onChange={(value => setNgayTao(value))}
                    />
                </div>
                <div className="col-md-6 my-1">
                    <select className="form-control"
                        value={trangthai}
                        style={{ width: '100%' }}
                        onChange={(value) => { setTrangthai(value.target.value) }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option key={-1} value={0}>Trạng thái</option>
                        {lstTrangthai.map((row, index) => {
                            return (
                                <option key={index} value={row.value}>{row.label}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-6 my-1 text-right">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <button className="btn btn-color" type="button" onClick={() => { GetListPhieuLinhThuoc() }}><i className="fa fa-search" /></button>
                        </div>
                        <input type="text" className="form-control" placeholder="Nhập số phiếu"
                            onChange={(e) => { setKeySearch(e.target.value) }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    GetListPhieuLinhThuoc()
                                }
                            }} />
                    </div>
                </div>
                <div className="col-md-6 my-1">
                    <button className="btn btn-outline-secondary" onClick={() => {
                        PrintListPhieuLinhThuoc()
                    }}><i className="fa fa-print mr-2"></i>In phiếu thuốc</button>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-inverse table-hover">
                    <thead>
                        <tr>
                            {isKhoaDuoc !== true &&
                                <th style={{ minWidth: '50px', verticalAlign: 'middle' }}>
                                    <input checked={checkAllSelect} type="checkbox" className="form-check"
                                        onChange={(e) => {
                                            UpdateCheckAllSelect(e.target.checked);
                                        }}
                                    />
                                </th>
                            }
                            <th style={{ minWidth: '100px' }}>Lần lãnh</th>
                            <th style={{ minWidth: '120px' }}>Số phiếu</th>
                            <th style={{ minWidth: '120px' }}>Trạng thái</th>
                            <th style={{ minWidth: '50px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lstNgay.map((ngay, i) => {
                            return (
                                lstDataSource.filter(x => moment(x.ngay).format('DD/MM/YYYY') === ngay).map((x, index) => (
                                    <React.Fragment>
                                        {index === 0 &&
                                            <tr key={'ngay' + i}>
                                                <td className="font-weight-bold" colSpan={isKhoaDuoc !== true ? 5 : 4}>{ngay}</td>
                                            </tr>
                                        }
                                        <tr key={'ngay' + i + index} style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                setIdphieulinh(x.id);
                                                setTenTrangthai(x.tentrangthai);
                                                setVisiblePhieuLinhThuoc(true);
                                            }}>
                                            {isKhoaDuoc !== true &&
                                                <td style={{ verticalAlign: 'middle' }} onClick={(e) => { e.stopPropagation(); }}>
                                                    <input checked={x.check === true} type="checkbox" className="form-check"
                                                        onChange={(e) => {
                                                            UpdateCheckSelect(x, e.target.checked);
                                                        }}
                                                    />
                                                </td>
                                            }
                                            <td>{x.solan}</td>
                                            <td>{x.maphieu}{x.sophieu}</td>
                                            <td>{x.tentrangthai}</td>
                                            <td>
                                                {isKhoaDuoc !== true ?
                                                    <div onClick={(e) => { e.stopPropagation(); }}>
                                                        <Popconfirm
                                                            title="Bạn có chắc muốn xóa?"
                                                            onConfirm={(e) => { DeletePhieuLanhThuoc(x.id) }}
                                                            okText="Đồng ý" cancelText="Hủy">
                                                            <div className="text-danger" style={{ cursor: 'pointer' }} >
                                                                <i className="fa fa-trash"></i>
                                                            </div>
                                                        </Popconfirm>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        <div className="d-flex" onClick={(e) => {
                                                            setIdphieulinh(x.id);
                                                            setTenTrangthai(x.tentrangthai);
                                                            e.stopPropagation();
                                                        }}>
                                                            {x.matrangthai === 2 &&
                                                                <Popconfirm
                                                                    title="Xác nhận phiếu thuốc?"
                                                                    onConfirm={(e) => { XacNhanPhieuThuoc() }}
                                                                    okText="Đồng ý" cancelText="Hủy">
                                                                    <button style={{ height: '30px', width: '110px' }} type="button" className="btn btn-color mr-2 py-1">
                                                                        Xác nhận
                                                                    </button>
                                                                </Popconfirm>
                                                            }
                                                            {x.matrangthai === 3 &&
                                                                <Popconfirm
                                                                    title="Xác nhận phiếu đã có thuốc?"
                                                                    onConfirm={(e) => { UpdateTrangThai(4) }}
                                                                    okText="Đồng ý" cancelText="Hủy">
                                                                    <button style={{ height: '30px', width: '110px' }} type="button" className="btn btn-outline-success mr-2 py-1">
                                                                        Đã có thuốc
                                                                    </button>
                                                                </Popconfirm>
                                                            }
                                                            {x.matrangthai === 4 &&
                                                                <Popconfirm
                                                                    title="Xác nhận phiếu đã cấp thuốc?"
                                                                    onConfirm={(e) => { UpdateTrangThai(5) }}
                                                                    okText="Đồng ý" cancelText="Hủy">
                                                                    <button style={{ height: '30px', width: '110px' }} type="button" className="btn btn-outline-info mr-2 py-1">
                                                                        Đã cấp thuốc
                                                                    </button>
                                                                </Popconfirm>
                                                            }
                                                            {x.matrangthai === 2 &&
                                                                <Popconfirm
                                                                    title="Xác nhận từ chối?"
                                                                    onConfirm={(e) => { UpdateTrangThai(6) }}
                                                                    okText="Đồng ý" cancelText="Hủy">
                                                                    <button style={{ height: '30px', width: '110px' }} type="button" className="btn btn-danger py-1">
                                                                        Từ chối
                                                                    </button>
                                                                </Popconfirm>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))

                            )
                        })}
                    </tbody>
                </table>
            </div>
            {visiblePhieuLinhThuoc &&
                <Modal
                    width={1000}
                    visible={visiblePhieuLinhThuoc}
                    onCancel={() => { setVisiblePhieuLinhThuoc(false); setIdphieulinh(0) }}
                >
                    <PhieuLinhThuoc
                        onClose={(isreset) => {
                            setVisiblePhieuLinhThuoc(false);
                            if (isreset === true)
                                GetListPhieuLinhThuoc();
                            setIdphieulinh(0);
                        }}
                        isKhoaDuoc={isKhoaDuoc}
                        idphieulinh={idphieulinh}
                        tentrangthai={tentrangthai}
                    />
                </Modal>
            }
        </React.Fragment>
    );
}

export default DanhSachPhieuLanhThuoc;