import { DatePicker, InputNumber, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Autocomplete from '../../../../../components/autocomplete';
import * as Actions from '../../../../../libs/actions';
import * as CommonService from '../../../../../services/commonService';
import * as InPatientService from '../../../../../services/inPatientService';
import * as SystemConfig from "../../../../../configure/systemConfig";
const { RangePicker } = DatePicker;
function ChonThuoc(props) {
    const [listType, SetListType] = useState([]);
    const [dataSearch, SetDataSearch] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [searchType, setSearchType] = useState('1');
    const [field, setField] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [searchId, setSearchId] = useState(null);
    const [ngaydung, setNgaydung] = useState([moment(), moment()]);
    useEffect(() => {
        if (props.reloadSearch === true)
            Search();
    }, [props.reloadSearch])
    const LoadSearchData = (data) => {
        if (searchType === '1')
            InPatientService.GetListMedic(data).then(result => {
                if (result.isSuccess) {
                    SetDataSearch(result.data);
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu thuốc bị lỗi' })
            })
        else
            CommonService.searchPatient(data, 1, 20).then(result => {
                if (result.isSuccess) {
                    let data = result.data.map(x => ({
                        value: x.patientID,
                        label: `${x.patientID} | ${x.patientName} | ${moment(x.birthYear).year()} | ${x.gender === "1" ? "Nữ" : "Nam"}`
                    }))
                    SetDataSearch(data);
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu bệnh nhân bị lỗi' })
            })
    }
    const Search = () => {
        Actions.setLoading(true);
        InPatientService.DanhSachCanTraThuoc(searchType, searchId, ngaydung[0].format("YYYY/MM/DD"), ngaydung[1].format("YYYY/MM/DD"))
            .then(result => {
                Actions.setLoading(false);
                if (result.isSuccess) {
                    FilterData(result.data);
                    setDataSource(result.data);
                } else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err)
                Actions.setLoading(false);
            })
    }
    const GetListDistinct = (data, field) => {
        if (data.length === 0)
            return [];
        let lst = data.map(x => x[field]);
        let ListTypeDistinct = lst.filter((x, index) => !lst.includes(x, index + 1));
        return ListTypeDistinct;
    }
    const FilterData = (data) => {
        if (searchType === "1") {
            setField('mabn')
            let types = GetListDistinct(data, 'mabn');
            SetListType(types);
        }
        else {
            setField('ngaydung')
            let types = GetListDistinct(data, 'ngaydung');
            SetListType(types);
        }
    }
    const editAmount = (row, soluong) => {
        let data = dataSource;
        let index = data.findIndex(record => record.mabn === row.mabn && record.idphieuthuoc === row.idphieuthuoc && record.mabd === row.mabd
            && record.idphieulanh === row.idphieulanh);
        if (index >= 0)
            data[index].soluongtra = soluong;
        setDataSource(
            data.map(x => ({ ...x }))
        );
    }
    const SendData = () => {
        let msg = "";
        dataSource.forEach(row => {
            if (row.soluongnhan - row.soluongtra < 0) {
                msg += `Số lượng thuốc [${row.tenthuoc}] không hợp lệ!<br />`;
            }
        })
        if (msg !== "") {
            Actions.openMessageDialog("Lỗi", msg);
            return;
        }
        if (props.ReceiveData) {
            props.ReceiveData(dataSource.filter(x => x.soluongtra > 0));
        }
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-auto my-1">
                    <label>Tìm kiếm theo</label><br />
                    <select className="form-control"
                        value={searchType}
                        style={{ width: '130px' }}
                        onChange={(value) => {
                            setDataSource([]);
                            SetDataSearch([]);
                            setSearchKey('');
                            setSearchId(null);
                            setSearchType(value.target.value)
                        }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option key={1} value={'1'}>Tên thuốc</option>
                        <option key={2} value={'2'}>Bệnh nhân</option>
                    </select>
                </div>
                {searchType === '1' ?
                    <React.Fragment>
                        <div className="col-auto my-1">
                            <label>Thuốc</label><br />
                            <Autocomplete
                                width="300px"
                                placeholder="Nhập mã, tên thuốc"
                                dataSource={dataSearch}
                                handleSearch={LoadSearchData.bind(this)}
                                value={searchId}
                                getItem={(key) => { setSearchId(key) }} />
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="col-auto my-1">
                            <label>Bệnh nhân</label><br />
                            <Autocomplete
                                width="250px"
                                placeholder="Nhập mã, tên bệnh nhân"
                                dataSource={dataSearch}
                                handleSearch={LoadSearchData.bind(this)}
                                value={searchId}
                                getItem={(key) => { setSearchId(key) }} />
                        </div>
                    </React.Fragment>}
                <div className="col-auto my-1">
                    <label>Ngày dùng thuốc</label>
                    <div style={{ width: '200px' }}>
                        <RangePicker className="fontSize12" format="DD-MM-YYYY" value={ngaydung} allowClear={false}
                            locale={viVN} onChange={(value => setNgaydung(value))}
                        />
                    </div>
                </div>
                <div className="col-auto my-1 align-self-end">
                    <button className="btn btn-color" onClick={() => {
                        Search()
                    }}><i className="fa fa-search mr-2"></i>Tìm</button>
                </div>
            </div>
            <div className="table-responsive" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                <table className="table table-striped table-inverse table-hover">
                    <thead>
                        <tr className="tableHeaderFixed">
                            <th>Mã thuốc</th>
                            <th>Tên thuốc</th>
                            <th>Dạng</th>
                            <th>Đơn vị</th>
                            <th>SL nhận</th>
                            <th>SL trả</th>
                            <th>Ghi chú</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchType === '1' ?
                            listType.map((type, i) => {
                                let tmpFilter = dataSource.filter(x => x[field] === type);
                                let lstNgay = GetListDistinct(tmpFilter, 'ngaydung');
                                return (
                                    <React.Fragment>

                                        {lstNgay.map((ngay, ngayindex) => {
                                            let tmp = tmpFilter.filter(x => x.ngaydung === ngay);
                                            return (
                                                <React.Fragment>
                                                    {tmp.length > 0 &&
                                                        <tr>
                                                            <td colSpan="7">{`${tmp[0].mabn} ${tmp[0].tenbn} ${moment(ngay).format(SystemConfig.FORMAT_DATE)}`}</td>
                                                        </tr>
                                                    }
                                                    {tmp.map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.mathuoc}</td>
                                                            <td>{row.tenthuoc}</td>
                                                            <td>{row.dangthuoc}</td>
                                                            <td>{row.donvi}</td>
                                                            <td>{row.soluongnhan}</td>
                                                            <td>
                                                                <InputNumber style={{ width: '60px' }} value={row.soluongtra} min={0} max={9999} maxLength={6}
                                                                    onChange={(e) => { editAmount(row, e) }} /></td>
                                                            <td>{row.ghichu}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            )

                                        }
                                        )}
                                    </React.Fragment>
                                )
                            })
                            :
                            listType.map((type, i) => {
                                let tmp = dataSource.filter(x => x[field] === type);
                                return (
                                    <React.Fragment>
                                        {tmp.length > 0 &&
                                            <tr>
                                                <td colSpan="7">{`${moment(type).format(SystemConfig.FORMAT_DATE)}`}</td>
                                            </tr>
                                        }
                                        {tmp.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.mathuoc}</td>
                                                <td>{row.tenthuoc}</td>
                                                <td>{row.dangthuoc}</td>
                                                <td>{row.donvi}</td>
                                                <td>{row.soluongnhan}</td>
                                                <td>
                                                    <InputNumber style={{ width: '60px' }} value={row.soluongtra} min={0} max={9999} maxLength={6}
                                                        onChange={(e) => { editAmount(row, e) }} /></td>
                                                <td>{row.ghichu}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-center">
                <button type="button" className="btn btn-color"
                    onClick={(e) => {
                        SendData()
                    }}>Thêm vào danh sách</button>
            </div>
        </React.Fragment>
    )
}

export default ChonThuoc;