import { DatePicker, Input, Pagination } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../../../libs/actions';
import * as StatisticService from '../../../../../services/statisticService';
import * as SystemConfig from "../../../../../configure/systemConfig";

const { RangePicker } = DatePicker;
function TheoNgay(props) {
    const _hiddenLink = useRef();
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [key, setKey] = useState('');
    const [currentSize, setCurrentSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (currentPage === 0)
            return;
        LoadDataSource();
    }, [currentPage, currentSize])
    useEffect(() => {
        LoadDataSource();
    }, [])
    const LoadDataSource = () => {
        Actions.setLoading(true);
        StatisticService.GetStatisticInPatientByDay(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), key, false, currentPage, currentSize).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu bị lỗi');
        })
    }
    const Export = () => {
        Actions.setLoading(true);
        StatisticService.ExportStatisticInPatientByDay(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), key)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeDoanhSoNoiTruTheoHoaDon";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    return (
        <React.Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <div className="row" style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                <div className="col-auto py-1" style={{ width: '250px' }}>
                    <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                        allowClear={false} format="DD-MM-YYYY" value={ngay}
                        placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                        onChange={(value => setNgay(value))}
                        renderExtraFooter={() =>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                    onClick={() => {
                                        setNgay([moment().subtract(7, 'd'), moment(new Date())])
                                    }}>
                                    7  Ngày
                                </div>
                                <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                    onClick={() => {
                                        setNgay([moment().startOf('month'), moment(new Date())])
                                    }}>
                                    Tháng hiện tại
                                </div>
                                <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                    onClick={() => {
                                        setNgay([moment().startOf('quarter'), moment(new Date())])
                                    }}>
                                    Quý hiện tại
                                </div>
                                <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                    onClick={() => {
                                        setNgay([moment().startOf('year'), moment(new Date())])
                                    }}>
                                    Năm hiện tại
                                </div>
                            </div>
                        }
                    />
                </div>
                <div className="col-auto py-1">
                    <Input className="form-control" placeholder="Nhập tên, mã bệnh nhân"
                        type="text" style={{ width: '250px' }} value={key} onChange={(e) => { setKey(e.target.value) }} />
                </div>
                <div className="col-auto py-1 d-flex">
                    <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                        setCurrentPage(0);
                        setTimeout(() => {
                            setCurrentPage(1);
                        }, 100);
                    }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    <button className="btn btn-color" onClick={() => {
                        Export()
                    }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                </div>
            </div>
            <div className="table-responsive" >
                <table className="table" style={{ background: '#fff' }}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '100px' }}>Ngày</th>
                            <th className="text-right" style={{ minWidth: '100px' }}>Viện phí</th>
                            <th className="text-right" style={{ minWidth: '100px' }}>Miễn giảm</th>
                            <th className="text-right" style={{ minWidth: '100px' }}>Tạm ứng</th>
                            <th className="text-right" style={{ minWidth: '120px' }}>Hoàn trả</th>
                            <th className="text-right" style={{ minWidth: '100px' }}>Cần trả</th>
                            <th className="text-right" style={{ minWidth: '100px' }}>Khách nợ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataSource.map((row, index) =>
                            <tr key={index}>
                                <td>{moment(row.ngay).format(SystemConfig.FORMAT_DATE)}</td>
                                <td className="text-right">{row.vienphi.toLocaleString('en-US')}</td>
                                <td className="text-right">{row.miengiam.toLocaleString('en-US')}</td>
                                <td className="text-right">{row.tamung.toLocaleString('en-US')}</td>
                                <td className="text-right">{row.hoantra.toLocaleString('en-US')}</td>
                                <td className="text-right">{row.cantra.toLocaleString('en-US')}</td>
                                <td className="text-right">{row.khachno.toLocaleString('en-US')}</td>
                            </tr>)}
                    </tbody>
                </table>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10 }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    current={currentPage}
                    size={currentSize}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setCurrentSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setCurrentPage(1);
                        setCurrentSize(size);
                    }
                    }
                    showSizeChanger={true}
                />
            </div>
        </React.Fragment>
    )
}

export default TheoNgay;