import { Input, Pagination, Select, Tag, Table, Modal, DatePicker, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Action from '../../libs/actions';
import * as EquipmentService from './equipmentService';
import { FORMAT_DATE } from '../../configure/systemConfig';

const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
    visibleCreate: false,
    dateIn: moment(),
    create: true
};
let task = null;
const { Option } = Select;

export default class Maintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            key: "",
            status: '',
            listMaintenance: [],
            lstUsers: [],
            listEquipment: [],

            ID: 0,
            equipmentID: '',
            equipmentName: '',
            dateIn: moment(),
            infomation: '',
            location: '',
            statusUpdate: 0,
            username: '',
            userID: -1,
            dateinuse: '',
            type: 0,
            returnDate: moment(),
            history: 'inprogress'
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.getMaintenance();
        this.loadListEquipment(false);
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    getMaintenance(page = this.state.currentPage, size = this.state.pageSize) {
        EquipmentService.getMaintenanceList(
            this.state.key, this.state.status, page, size).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        listMaintenance: res.data,
                        currentPage: page,
                        pageSize: size,
                        totalRecords: res.totalRecords,
                    });
                }
            }).catch(err => {
            })
    }
    loadListEquipment(filter) {
        EquipmentService.getEquipmentsInfo(filter).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listEquipment: res.data
                });
            }
        }).catch(err => {
        })
    }
    progressAction(create) {
        if (this.validator.allValid()) {
            var {
                ID, equipmentID, dateIn, type, userID,
                history, infomation, returnDate
            } = this.state;
            if (create) {
                const data = {
                    equipmentID, dateIn, type, userID,
                    history, infomation, returnDate: null
                };
                EquipmentService.createProgress(data).then((response) => {
                    if (response.isSuccess) {
                        notification.success({ message: "Đã tạo bảo trì tài sản" });
                        this.resetState();
                        this.getMaintenance(1, this.state.pageSize);
                        this.loadListEquipment(true);
                    } else {
                        notification.error({ message: "Tạo bảo trì tài sản không thành công" })
                    }
                }).catch(() => { })
            } else {
                const data = {
                    ID, equipmentID, type,
                    history, infomation, returnDate
                };
                EquipmentService.updateProgress(data).then((response) => {
                    if (response.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" });
                        this.resetState();
                        this.loadListEquipment(false);
                        this.setState({
                            visibleCreate: false
                        });
                        this.getMaintenance(1, this.state.pageSize);
                    } else {
                        notification.error({ message: response.err.msgString })
                    }
                }).catch(() => { })
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState = () => {
        this.validator.hideMessages();
        this.setState({
            equipmentID: '',
            equipmentName: '',
            dateIn: moment(),
            infomation: '',
            location: '',
            statusUpdate: 0,
            username: '',
            dateinuse: '',
            type: 0,
            returnDate: moment(),
            history: 'inprogress'
        });
    }
    export = () => {
        Action.setLoading(true);
        EquipmentService.exportMaintenance(this.state.status).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Lich-Su-Bao-Tri-Tai-San";
                this._hiddenLink.click();
            })
            Action.setLoading(false);
        }).catch(err => {
            Action.setLoading(false);
        })
    }
    printMaintenanceReport(id, type) {
        EquipmentService.printMaintenanceReport(id, type).then((result) => {
            Action.DoPrint(result.item)
        })
    }
    render() {
        let { currentPage, pageSize } = this.state;
        const columns = [
            {
                title: "STT",
                align: "center",
                render: (text, record, index) => (currentPage - 1) * pageSize + Number(index) + 1,
                width: 70
            },
            {
                title: "Mã tài sản",
                dataIndex: "equipmentID",
                key: "equipmentID",
                align: "center",
                width: 100,
            },
            {
                title: "Tên tài sản",
                dataIndex: "equipmentName",
                key: "equipmentName",
                align: "left",
                width: 160,
            },
            {
                title: "Người sử dụng",
                dataIndex: "username",
                key: "username",
                width: 170,
            },
            {
                title: "Trạng thái",
                dataIndex: "history",
                key: "history",
                align: "center",
                width: 160,
                render: state => {
                    switch (state) {
                        case 'inprogress':
                            return <Tag color="blue" style={{ width: "100%" }}>Đang bảo trì</Tag>
                        case 'complete':
                            return <Tag color="green" style={{ width: "100%" }}>Hoàn thành</Tag>
                        case 'error':
                            return <Tag color="orange" style={{ width: "100%" }}>Thất bại</Tag>
                        default:
                            break;
                    }
                }
            },
            {
                title: "Hình thức",
                dataIndex: "type",
                key: "type",
                align: 'center',
                width: 100,
                render: type => {
                    switch (type) {
                        case 0:
                            return 'Miễn phí'
                        case 1:
                            return 'Tính phí'
                        default:
                            return ""
                    }
                }
            },
            {
                title: "Ngày tiếp nhận",
                dataIndex: "dateIn",
                key: "dateIn",
                width: 120,
                render: (day) => {
                    if (day) return moment(day).format(FORMAT_DATE)
                }
            },
            {
                title: "Ngày hoàn trả",
                dataIndex: "returnDate",
                key: "returnDate",
                width: 120,
                render: (day) => {
                    if (day) return moment(day).format(FORMAT_DATE)
                }
            },
            {
                title: "Nội dung",
                dataIndex: "infomation",
                key: "infomation",
                width: 240
            },
            {
                title: "Thao tác",
                align: "center",
                width: 240,
                render: (row) => {
                    return (
                        <div>
                            {row.history === 'inprogress' &&
                                <div>
                                    <button className={row.useable ? "btn btn-secondary" : "btn btn-primary"}
                                        style={{ width: "16%" }}
                                        title="In biên bản tiếp nhận"
                                        disabled={row.useable}
                                        onClick={() => this.printMaintenanceReport(Number(row.id), 'inprogress')}>
                                        <i className="fas fa-print" />
                                    </button>
                                    <button className={row.useable ? "btn btn-secondary" : "btn btn-warning"}
                                        style={{ marginLeft: 10, width: "40%" }}
                                        disabled={row.useable}
                                        onClick={() => {
                                            this.setState({
                                                visibleCreate: true,
                                                create: false,
                                                ID: row.id,
                                                equipmentID: row.equipmentID,
                                                type: row.type,
                                                infomation: row.infomation,
                                                history: 'complete',
                                                returnDate: row.returnDate ? row.returnDate : moment(),
                                                dateIn: row.dateIn && moment(row.dateIn)
                                            });
                                            let eqinfo = this.state.listEquipment.filter(x => x.equipmentID === row.equipmentID)[0];
                                            eqinfo && this.setState({
                                                equipmentName: eqinfo.equipmentName ? eqinfo.equipmentName : null,
                                                location: eqinfo.location ? eqinfo.location : null,
                                                statusUpdate: eqinfo.status ? eqinfo.status : 0,
                                                username: eqinfo.username ? eqinfo.username : null,
                                                dateinuse: eqinfo.dateinuse ? moment(eqinfo.dateinuse).format(FORMAT_DATE) : null
                                            });
                                        }}>
                                        Cập nhật
                                    </button>
                                </div>}
                            {row.history === 'complete' &&
                                <button className="btn btn-primary"
                                    style={{ width: "60%" }}
                                    title="In biên bản hoàn trả"
                                    onClick={() => this.printMaintenanceReport(Number(row.id), 'complete')}>
                                    <i className="fas fa-print" style={{ marginRight: 5 }} />In phiếu hoàn
                                </button>}
                            {/* {row.history === 'error' &&
                                <button className="btn btn-primary"
                                    style={{ width: "60%" }}
                                    title="In biên bản hủy bỏ"
                                    onClick={() => this.printRetrieval(row.id)}>
                                    <i className="fas fa-print" style={{ marginRight: 5 }} />In phiếu hủy
                                </button>} */}
                        </div>
                    )
                }
            }
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <div className="h4 font-bold text-uppercase text-center">Lịch sử bảo trì tài sản</div>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <div className="col-md-4 col-sm-6 mt-2">
                            <Input placeholder="Nhập mã, tên tài sản hoặc tên người dùng để tìm kiếm" value={this.state.key}
                                onChange={e => {
                                    this.setState({
                                        key: e.target.value
                                    });
                                    task = setTimeout(() => {
                                        this.getMaintenance(1, this.state.pageSize);
                                    }, 700);
                                }}>
                            </Input>
                        </div>
                        <div className="col-md-2 col-sm-6 mt-2">
                            <Select value={this.state.status}
                                className='w-100'
                                onChange={(e) => {
                                    this.setState({
                                        status: e
                                    });
                                    task = setTimeout(() => {
                                        this.getMaintenance(1, this.state.pageSize);
                                    }, 700);
                                }}>
                                <Option value=''>Tất cả trạng thái</Option>
                                <Option value='inprogress'>Đang bảo trì</Option>
                                <Option value='complete'>Hoàn thành</Option>
                                <Option value='error'>Thất bại</Option>
                            </Select>
                        </div>
                        <div className="mt-2">
                            {/* <button className="btn btn-success" title="Cập nhật dữ liệu"
                            onClick={() => this.getMaintenance(1, this.state.pageSize)}>
                            <i className="fas fa-sync-alt" />
                        </button> */}
                            <button className="btn btn-success" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.validator.purgeFields();
                                    this.setState({
                                        visibleCreate: true,
                                        create: true,
                                        type: 0
                                    });
                                    this.loadListEquipment(true);
                                }}>
                                <i className="fa fa-plus" style={{ marginRight: 5 }} />Tạo mới
                            </button>
                            <button className="btn btn-primary" style={{ marginLeft: 10 }}
                                onClick={() => this.export()}>
                                <i className="fas fa-download" style={{ marginRight: 5 }} />Xuất danh sách
                            </button>
                        </div>
                    </div>
                    <div style={{ width:"100%", height:'calc(100% - 120px)' , marginTop:20 }}>
                        <Table
                            dataSource={this.state.listMaintenance}
                            columns={columns}

                            rowKey={row => "maintenance-tbl-" + row.id}
                            pagination={false}
                            scroll={{ y: 0 }} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.getMaintenance(page, size); }}
                        onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.getMaintenance(1, size); }}
                        showSizeChanger={true}
                    />

                </div>
                <Modal
                    visible={this.state.visibleCreate}
                    width={1000}
                    onCancel={this.cancelAction}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>{this.state.create ? "Thêm tài sản bảo trì" : "Cập nhật thông tin bảo trì"}</h3>
                    </div>
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        <hr className="btn-color" style={{ width: "100%" }} />
                        <p style={{ textTransform: 'uppercase', transform: "translateY(-115%)", backgroundColor: "#fff", width: "40%" }}>Thông tin tài sản</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Mã tài sản</label>
                            <Select showSearch className="w-100" value={this.state.equipmentID}
                                disabled={!this.state.create}
                                onChange={(e) => {
                                    this.setState({ equipmentID: e });
                                    let eqinfo = this.state.listEquipment.filter(x => x.equipmentID === e)[0];
                                    eqinfo && this.setState({
                                        equipmentName: eqinfo.equipmentName ? eqinfo.equipmentName : null,
                                        location: eqinfo.location ? eqinfo.location : null,
                                        statusUpdate: eqinfo.status ? eqinfo.status : 0,
                                        username: eqinfo.username ? eqinfo.username : null,
                                        userID: eqinfo.userid ? eqinfo.userid : -1,
                                        dateinuse: eqinfo.dateinuse ? moment(eqinfo.dateinuse).format(FORMAT_DATE) : null
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.state.listEquipment.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.equipmentID}>{row.equipmentID}</Option>
                                    )
                                })}
                            </Select>
                            {this.validator.message("Mã tài sản", this.state.equipmentID, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Tên tài sản</label>
                            <Input value={this.state.equipmentName} maxLength={100} disabled></Input>
                        </div>
                        <div className="col-md-4">
                            <label>Trạng thái</label>
                            <Select className="w-100" value={this.state.statusUpdate} disabled >
                                <Option value={0}>Trong kho</Option>
                                <Option value={1}>Đang sử dụng</Option>
                                <Option value={2}>Thanh lý</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Ngày cấp</label>
                            <Input disabled value={this.state.dateinuse}></Input>
                        </div>
                        <div className="col-md-4">
                            <label>Đơn vị sở hữu</label>
                            <Input value={this.state.location} disabled></Input>
                        </div>
                        <div className="col-md-4">
                            <label>Tên người dùng</label>
                            <Input value={this.state.username} disabled></Input>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>
                        <hr className="btn-color" style={{ width: "100%" }} />
                        <p style={{ textTransform: 'uppercase', transform: "translateY(-115%)", backgroundColor: "#fff", width: "40%" }}>Thông tin bảo trì</p>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Ngày tiếp nhận</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.dateIn}
                                disabled={!this.state.create}
                                onChange={(e) => this.setState({ dateIn: e })}
                                allowClear={false}
                                disabledDate={(current) => { return current && (current < moment().subtract(3, 'months')) }} />
                            {this.validator.message("Thời gian tiếp nhận", this.state.dateIn, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Hình thức</label>
                            <Select value={this.state.type} className="w-100" onChange={(e) => this.setState({ type: e })}>
                                <Option value={0}>Miễn phí</Option>
                                <Option value={1}>Tính phí</Option>
                            </Select>
                        </div>
                        <div className="col-md-4">
                            <label>Trạng thái</label>
                            <Select value={this.state.history} className="w-100" onChange={(e) => this.setState({ history: e })}>
                                {this.state.create && <Option value='inprogress'>Đang bảo trì</Option>}
                                {!this.state.create && <Option value='complete'>Hoàn thành</Option>}
                                {!this.state.create && <Option value='error'>Thất bại</Option>}
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className={this.state.history === 'inprogress' ? "col-md-12" : "col-md-8"}>
                            <label>Nội dung</label>
                            <Input value={this.state.infomation} maxLength={150} style={{ height: 32 }}
                                onChange={(e) => this.setState({ infomation: e.target.value })}></Input>
                        </div>
                        <div className="col-md-4" style={{ visibility: this.state.history === 'inprogress' && "hidden" }}>
                            <label>Ngày hoàn trả</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.returnDate}
                                onChange={(e) => this.setState({ returnDate: e })}
                                allowClear={false}
                                disabledDate={(current) => { return current && (current < moment().subtract(1, 'days') || current > moment().add(3, 'months')) }} />
                            {this.state.history !== 'inprogress' && this.validator.message("Thời gian trả tài sản", this.state.returnDate, "required")}
                        </div>
                    </div>
                    <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        {this.state.create ?
                            <button className="btn btn-success"
                                onClick={() => {
                                    this.progressAction(true);
                                }}><i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                            :
                            <button className="btn btn-success"
                                onClick={() => {
                                    this.progressAction(false);
                                }}><i className="fa fa-save" style={{ marginRight: 5 }} />Lưu</button>}
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({
                                    visibleCreate: false
                                });
                                this.loadListEquipment(false);
                                this.state.create === false && this.resetState();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
            </>
        );
    }
}