import React, { Fragment, useState, useEffect, useRef } from "react";
import HeaderModal from "../../../configureHIS/common/headerModal";
import * as PackageService from '../../../services/packageService';
import * as Actions from '../../../libs/actions';
import Notify, { NotifyStatus } from "../../../components/notify";
import { Input, Table } from "antd";

export default (props) => {
    const _notiRef = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [loading, setLoading] = useState(false);
    const [detail , setDetail] = useState({});
    const [packageId, setPackageId] = useState(props.packageId);
    const [listSelectedService, setListSelectedService] = useState([]);

    useEffect(() => {
        GetPackageDetail()
    }, [])

    const GetPackageDetail = () => {
        PackageService.ServicePackageDetail(packageId).then(result => {
            if (result.isSuccess) {
                setDetail(result.data);
                let data = result.data.listServicePackageItem;
                let dataSource = data.map(function (e, index) {
                    let o = Object.assign({}, e);
                    o.key = o.id
                    return o;
                })
                setListSelectedService(dataSource);
            } else {
                Actions.openMessageDialog('Lỗi', result.error.messageText);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => {
            setLoading(false);
            forceUpdate();
        })
    }

    const onClose = () => {
        props.onClose();
    }

    const columns = [
        {
            title: "Mã Dịch vụ",
            dataIndex: "maVP",
            key: "maVP",
            width: 70,
        },
        {
            title: "Tên dịch vụ",
            dataIndex: "name",
            key: "name",
            width: 300,
        },
        {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
            align: 'Center',
            width: 70,
            format: "number",
        }
    ]
    return (
        <Fragment>
        <HeaderModal title={"Xem gói dịch vụ" } onClose={() => {onClose()}}/>
            <div className="row">
                <div className="col-md-12"><Notify ref={_notiRef}/></div>
            </div>
            <div className="row">
                <div className="col-12 my-1">
                    <label>Mã gói</label>
                    <Input className="w-100" value={detail.code} readonly={true} />
                </div>
                <div className="col-12 my-1">
                    <label>Tên gói</label>
                    <Input className="w-100" value={detail.name} readonly={true} />
                </div>
                <div className="col-12 my-1">
                    <label>Mô tả</label>
                    <Input className="w-100" value={ detail.description } readonly={true} />
                </div>
            </div>
            <div className="row my-2" style={{ height:'calc(100% - 210px)' }}>
                <div className="col-12" >
                    <Table 
                        dataSource={listSelectedService}
                        scroll={{ y: 0 }}
                        pagination={false}
                        columns={columns} 
                        rowKey={(e, index) => index} 
                    />
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-danger mr-2" onClick={() => {onClose()}}><i className="fas fa-sign-out-alt mr-2"/>Đóng</button>
                </div>
            </div>
        </Fragment>
    )
};
