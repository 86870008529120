import React, { Fragment, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { Table, Pagination, Modal } from 'antd';
import moment from 'moment';
import * as SystemConfig from "../../../configure/systemConfig";
import * as PromotionalCampagin from '../../../../src/services/PromotionServices';
import * as InvoiceService from '../../../services/invoiceService';
import BillDetail from './billdetail';
import * as Actions from '../../../libs/actions';
import PatientDetail from '../../../screens/patient/thongtinbenhnhan';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reload: () => {
            setCurrentPage(1);
        },
        getList: () => {
            setCurrentPage(1);
            loadListVouchers(1);
        }
    }));
    const _hiddenLink = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listVouchers, setListVouchers] = useState([]);
    const [lstPaymentDetail, setLstPaymentDetail] = useState([]);
    const [visiblePay, setVisiblePay] = useState(false);
    const [selectedBillId, setSelectedBillId] = useState('');
    const [visiblePatient, setVisiblePatient] = useState(false);
    const [patientID, setPatientID] = useState('');
    const [sorting, setSorting] = useState('');
    const tableColumns = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (currentPage - 1) * currentSize + Number(index) + 1,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 120,
            render: (patientName, record) => {
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        loadPatientDetail(record.patientId);
                    }}>{patientName}</a>
            },
            sorter: (a, b) => a.patientName.localeCompare(b.patientName),
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'billId',
            key: 'billId',
            width: 80,
            sorter: () => {},
            render: (billId, record) => {
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        loadPaymentDetail(record);
                    }}>{record.billNo ?? billId}</a>
            }
        },
        {
            title: 'Chương trình khuyến mại',
            dataIndex: 'promotionName',
            key: 'promotionName',
            width: 120,
        },
        {
            title: 'Mã voucher',
            dataIndex: 'voucherCode',
            key: 'voucherCode',
            width: 90,
        },
        {
            title: 'Ngày áp dụng',
            dataIndex: 'usedDate',
            key: 'usedDate',
            width: 100,
            render: (date) => (date && moment(date).format(SystemConfig.FORMAT_DATE_TIME)),
            sorter: (a, b) => moment(a.usedDate) - moment(b.usedDate),
        },
        // {
        //     title: 'Số tiền KM',
        //     dataIndex: 'discountValue',
        //     key: 'discountValue',
        //     width: 80,
        //     align: "right",
        //     render: (totalDiscount) => (totalDiscount && totalDiscount.toLocaleString("en-US"))
        // },
        {
            title: 'Thực thu',
            dataIndex: 'paid',
            key: 'paid',
            width: 80,
            align: "right",
            render: (paid) => (paid && paid.toLocaleString("en-US"))
        },
    ]

    useEffect(() => {
        loadListVouchers();
    }, [currentPage, currentSize, sorting]);

    const loadListVouchers = (current = currentPage, size = currentSize) => {
        Actions.setLoading(true);
        let dateSelected = props.dateSelected;
        PromotionalCampagin.getListVoucherHistory(
            props.type, props.maximumPromotion, props.listPromotion, props.page, props.site, props.allSite, props.priority,
            dateSelected && dateSelected[0] && dateSelected[0].format(SystemConfig.FORMAT_YYYYMMDD),
            dateSelected && dateSelected[1] && dateSelected[1].format(SystemConfig.FORMAT_YYYYMMDD), current, size, sorting).then(res => {
                if (res.isSuccess) {
                    setTotalRecords(res.data.totalCount);
                    setListVouchers(res.data.items);
                }
                Actions.setLoading(false);
            }).catch(() => {
                Actions.setLoading(false);
            })
    }

    const exportListVoucherHistory = () => {
        Actions.setLoading(true);
        let dateSelected = props.dateSelected;
        PromotionalCampagin.exportListVoucherHistory(
            props.type, props.maximumPromotion, props.listPromotion, props.page, props.site, props.allSite, props.priority,
            dateSelected && dateSelected[0] && dateSelected[0].format(SystemConfig.FORMAT_YYYYMMDD),
            dateSelected && dateSelected[1] && dateSelected[1].format(SystemConfig.FORMAT_YYYYMMDD))
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = `DanhSachVoucherDaSuDung-${props.maximumPromotion}-ChuongTrinh`;
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    const loadPaymentDetail = (data) => {
        Actions.setLoading(true);
        InvoiceService.PatientPaymentInvoiceDetail(data.billId, moment(data.ngaylap).format('YYYY-MM-DD'), false).then(result => {
            if (result.isSuccess) {
                setSelectedBillId(data.billId);
                setLstPaymentDetail(result.data)
                setVisiblePay(true);
                Actions.setLoading(false);
            }
            else {
                Actions.setLoading(false);
            }
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const loadPatientDetail = (patientID) => {
        setPatientID(patientID);
        setVisiblePatient(true);
    }

    const onTableFilter = (pagination, filters, sorter, extra) => {
        if (sorter.column && sorter.order) {
            let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
            setSorting(sort);
        }
        else {
            setSorting('');
        }
    };

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink}></a>
            <div className="text-right mt-2">
                <button className='btn btn-secondary' id="vchrhis-btn-exl" onClick={() => exportListVoucherHistory()}><i className='fa fa-file-excel mr-1'></i>Xuất danh sách</button>
            </div>
            <div className='mt-2' style={{ height: "100%" }}>
                <Table
                    columns={tableColumns}
                    dataSource={listVouchers}
                    pagination={false}
                    rowKey={(row, index) => index}
                    scroll={{ x: "max-content", y: 0 }}
                    locale={{
                        triggerDesc: "Bấm để sắp xếp giảm dần",
                        triggerAsc: "Bấm để sắp xếp tăng dần",
                        cancelSort: "Bấm để hủy bỏ sắp xếp",
                    }}
                    onChange={onTableFilter} 
                />
            </div>
            <Pagination
                className="text-center mt-2"
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                size={currentSize}
                current={currentPage}
                onChange={(page, size) => {
                    setCurrentPage(page);
                    setCurrentSize(size);
                    loadListVouchers(page, size);
                }}
                onShowSizeChange={(page, size) => {
                    setCurrentPage(1);
                    setCurrentSize(size);
                    loadListVouchers(1, size)
                }}
                showSizeChanger={true}
            />
            <Modal
                width={1000}
                visible={visiblePay}
                maskClosable={true}
                onClose={() => setVisiblePay(false)}>
                <BillDetail lstPaymentDetail={lstPaymentDetail} billId={selectedBillId} onClose={() => setVisiblePay(false)} />
            </Modal>
            <Modal
                width={1000}
                visible={visiblePatient}
                maskClosable={true}
                onClose={() => setVisiblePatient(false)}>
                <PatientDetail patientID={patientID} isPreview={true} onCancel={() => setVisiblePatient(false)} />
            </Modal>
        </Fragment >
    );
})