

import * as ApiCaller from '../libs/httpRequests';
import { store } from '../stores';
import * as WorldfoneService from '../services/worldfoneService';
import * as Configure from '../configure/urlConfig';
import { notification } from 'antd';
import * as Actions from '../libs/actions';
import jwt_decode from "jwt-decode";
import moment from 'moment';

function loginWF() {
    let userWF = localStorage.getItem('userWF');
    // let tokenWF = localStorage.getItem('tokenWF');
    const data = new FormData();
    data.append("secret_key", Configure.SECRETKEY_WORLDWFONE);
    data.append("userextension", userWF.split('@')[0]);
    WorldfoneService.GetToken(data, userWF).then(result => {
        if (result.status === true) {
            notification.success({ message: 'Đăng nhập worldfone thành công!' });
            WorldfoneService.login(userWF, result.data.token).then(response => {
            }).catch(err => Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone và đăng nhập lại."))
        } else {
            notification.error({ message: 'Đăng nhập worldfone thất bại!' });
        }
    })
}
export function login(username, password) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost('Auth/signin', {
            userName: username,
            password: password,
        }, false)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                    let token = '';
                    let name = '';
                    let userID = '';
                    let groupid = '';
                    let title = '';
                    let userWF = '';
                    if (objRespone.token) {
                        token = objRespone.token
                        name = objRespone.name || ''
                        userID = objRespone.userID || ''
                        groupid = objRespone.groupid || ''
                        title = objRespone.title || ''
                        userWF = objRespone.userWF || ''
                    }
                    localStorage.setItem('token', token);
                    localStorage.setItem('name', name);
                    localStorage.setItem('userID', userID);
                    localStorage.setItem('groupid', groupid);
                    localStorage.setItem('title', title);
                    localStorage.setItem('userWF', userWF);
                    store.dispatch({
                        type: 'USER_SET_DATA', data: {
                            token: objRespone.token, name: name, userID: userID, userWF: userWF,
                            groupid: objRespone.groupid
                        }
                    });
                } else {
                    store.dispatch({
                        type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                        errContent: objRespone.err.msgString
                    });

                }
                return resolve(objRespone)

            }).catch(err => {
                store.dispatch({
                    type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                    errContent: JSON.stringify(err)
                });
                return reject(err)
            });
    });

}


export function loginWithSite(username, password, site) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost('Auth/SigninWithSite', {
            userName: username,
            password: password,
            site: site
        }, false)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                    let token = '';
                    let name = '';
                    let userID = '';
                    let groupid = '';
                    let title = '';
                    let userWF = '';
                    if (objRespone.token) {
                        token = objRespone.token
                        name = objRespone.name || ''
                        userID = objRespone.userID || ''
                        groupid = objRespone.groupid || ''
                        title = objRespone.title || ''
                        userWF = objRespone.userWF || ''
                    }
                    localStorage.setItem('token', token);
                    localStorage.setItem('name', name);
                    localStorage.setItem('userID', userID);
                    localStorage.setItem('groupid', groupid);
                    localStorage.setItem('title', title);
                    localStorage.setItem('userWF', userWF);
                    store.dispatch({
                        type: 'USER_SET_DATA', data: {
                            token: objRespone.token, name: name, userID: userID, userWF: userWF,
                            groupid: objRespone.groupid
                        }
                    });
                } else {
                    store.dispatch({
                        type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                        errContent: objRespone.err.msgString
                    });

                }
                return resolve(objRespone)

            }).catch(err => {
                store.dispatch({
                    type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "Đăng nhập thất bại",
                    errContent: JSON.stringify(err)
                });
                return reject(err)
            });
    });

}
export function logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('name', '');
    localStorage.setItem('groupid', '');
    localStorage.setItem('title', '');
    localStorage.setItem('userID', '');
    localStorage.setItem('id', '');
    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } });
}
export function checkAuth(token) {
    // if (token) {
    //     const expireTime = jwt_decode(token).exp;
    //     const currentTime = Math.round(moment().valueOf() / 1000);
    //     const nextMonthTime = Math.round(moment().add(1, 'months').valueOf() / 1000);
    //     if (expireTime < currentTime || expireTime > nextMonthTime) {
    //         logout();
    //     } else {
            return new Promise((resolve, reject) => {
                return ApiCaller.httpGet('Auth/check', true)
                    .then(objRespone => {
                        if (objRespone.isSuccess === true) {
                        } else {
                            store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } });
                        }
                        return resolve(objRespone)
                    }).catch(err => {
                        store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } });
                        return reject(err)
                    });
            });
    //     }
    // }
}


export function getCurrentSite() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet('Auth/getCurrentSite', true)
            .then(objRespone => {
                if (objRespone.isSuccess === true) {
                } else {
                    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } });
                }
                return resolve(objRespone)
            }).catch(err => {
                store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '', role: '', groupid: '', title: '' } });
                return reject(err)
            });
    });
}

export function getAvailableSites() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet('Auth/GetAvailableSites', true)
            .then(objRespone => {
                return resolve(objRespone)
            }).catch(err => {
                return reject(err)
            });
    });
}
export function SwitchCurrentSite(site) {
    var data = { siteid: site }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost('Auth/SwitchCurrentSite', data)
            .then(objRespone => {
                return resolve(objRespone)
            }).catch(err => {
                return reject(err)
            });
    });
}
export function IsAdmin() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Auth/IsAdmin`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            Actions.openMessageDialog("Error", err.toString());
            return reject(err)
        })
    });
};

