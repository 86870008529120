import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Input, Button, DatePicker, Spin, Table, Pagination } from 'antd';
import HeaderModal from "../../../configureHIS/common/headerModal";
import moment from "moment";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as LogPaymentService from '../../../services/LogPaymentService';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';

const { RangePicker } = DatePicker;
const showMoreText='...Xem thêm';
const showLessText=' Rút gọn';
export default (props) => {
    const notiRef = useRef(null);
    const firstRender = useRef(true);
    const _hiddenLink = useRef();
    const [date, setDate] = useState([moment(), moment()]);
    const [keyword, setKeyword] = useState('');

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //state paging và sorting
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    //
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    if (firstRender.current) {
        firstRender.current = false;
        return;
        }
        getData();
    }, [pageIndex]);

    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [pageSize])

    const getData = () => {
        setLoading(true);
        let filterModel = {
            keyword : keyword,
            dateFrom: date[0],
            dateTo: date[1],
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll : false
        }
        LogPaymentService.GetList(filterModel).then(result => {
        if (result.isSuccess) {
            setListResult(result.data.items);
            setTotalRecord(result.data.totalCount);
            notiRef && notiRef.current.Close();
        }
        else {
            notiRef.current && notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
        }
        }).catch(err => {
        if (err.error && err.error.messageText)
            notiRef.current && notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        else notiRef.current && notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
        if (pageIndex != 1) setPageIndex(1);
        else getData();
    }

    const onExportExcelClinkHandle = () => {
        Actions.setLoading(true);
        let filterModel = {
            keyword : keyword,
            dateFrom: date[0],
            dateTo: date[1],
            IsGetAll : true
        }
        LogPaymentService.ExportLogPayment(filterModel).then(result => {
        result.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            _hiddenLink.current.href = url;
            let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_LichSuThaoTacThanhToan`; 
            _hiddenLink.current.download = fileName;
            _hiddenLink.current.click();
        })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 30,
            render: (text, record, index) => {
                return <span>{ (pageIndex-1) * pageSize + (index + 1) }</span>;
            }
        },
        { 
            title: 'Ngày lập phiếu',
            dataIndex: 'billDate',
            key: 'billDate',
            align: 'left',
            width: 80,
            render : (value, record) => {
                return (
                    value && <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME_SECOND)}</span>
                )
            }
        },
        {
            title: 'Số hóa đơn',
            dataIndex: 'billNumber',
            key: 'billNumber',
            align: 'center',
            width: 70,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 120,
        },
        {
            title: 'Ngày giờ điều chỉnh',
            dataIndex: 'createdDate',
            key: 'createdDate',
            align: 'left',
            width: 100,
            render : (value, record) => {
                return (
                    value && <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME_SECOND)}</span>
                )
            }
        },
        {
            title: 'Người điều chỉnh',
            dataIndex: 'userCreated',
            key: 'userCreated',
            align: 'left',
            width: 100,
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            align: 'left',
            width: 100,
        },
        {
            title: 'Nội dung',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: 170,
            render : (value) => {
                return (
                    <MitaDisplayLongText
                        content={value}
                        maxLength={64}
                        showMoreText={showMoreText}
                        showLessText={showLessText}
                        displayMode='popup'
                    />
                )
            }
        },
    ]
    
    return (
        <Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <div className="list-page containerChilderWrapper">
                <div className="list-page-body overflow-auto">{/* body */}
                    <HeaderModal title='Lịch sử thao tác thanh toán'/>
                    <div className="list-page-search">{/* search/filter */}
                        <div className="row">
                            <div className="col-md-12"><Notify ref={notiRef} /></div>
                            <div className='col-md-4 d-flex flex-column'>{/* Khoảng thời gian */}
                                <label>Khoảng thời gian</label>
                                <RangePicker 
                                    allowClear={false}
                                    locale={viVN}
                                    showNow={false}
                                    className="w-100"
                                    value={date}
                                    onChange={(e) => { setDate(e) }}
                                    format="DD-MM-YYYY"
                                />
                            </div>
                            <div className='col-md-8 d-flex flex-column'>{/* Từ khóa */}
                                <label>Từ khóa</label>
                                <Input
                                    placeholder="Tìm kiếm theo số hóa đơn, tên bệnh nhân, hành động hoặc nội dung."
                                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                                    value={keyword} 
                                    onChange={(e) => { setKeyword(e.target.value); }}
                                    onPressEnter={(e) => { resetToFirstPage() }}
                                ></Input>
                            </div>
                        </div>
                        <div className="row mt-2">{/* button Thống kê */}
                            <div className='col-md-12 d-flex justify-content-between'>
                                <Button className='btn btn-color' 
                                    onClick={() => {
                                        onExportExcelClinkHandle()
                                    }}
                                >
                                    <i className='fas fa-file-excel mr-2'></i> Xuất excel
                                </Button>
                                <Button className='btn btn-color ml-2' 
                                    onClick={() => {
                                        resetToFirstPage()
                                    }}
                                >
                                    <i className='fa fa-search mr-2'></i> Tìm kiếm
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Spin spinning={loading} className='spin-color'>
                                <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                                    <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                                        className="table-min-h-0 table-header-1row"
                                        dataSource={listResult} 
                                        columns={columns}
                                        locale={{ emptyText: 'Không có dữ liệu' }} 
                                        scroll={{ x: 1200, y: 0 }}
                                        rowKey={(e) => e.billNo + e.patientCode}
                                        rowClassName={(record, rowIndex) => {
                                        }}
                                        pagination={false}
                                    />
                                    <Pagination
                                        current={pageIndex}
                                        size={pageSize}
                                        style={{ textAlignLast: "center", marginTop: "10px" }}
                                        total={totalRecord}
                                        showTotal={total => `Tổng: ${total}`}
                                        onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                                        onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                                        showSizeChanger={true}
                                    />
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
