import { notification, Popconfirm, Statistic, InputNumber, Input, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import EditableTable from '../../../../commons/editableTable';
import { ValidationCustom } from '../../../../commons/validationCustom';
import Administrative from '../confirm/patientdetails';
import AutocompleteTable from '../../../../components/autocompletetable';
import * as PrintService from '../../../../services/printService';

import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as CommonService from '../../../../services/commonService';
import * as MedicineService from '../../../../services/medicineService';
import { isNullOrEmpty } from '../../../../libs/util';
import HeaderModal from '../../../../configureHIS/common/headerModal';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            activeThuoc: 0,
            thuoc: '',
            lstKho: [],
            lstNguon: [],
            maNguon: '',
            maKho: 502,
            lstDetail: [],
            lstDetailNew: [],
            lstColums: [],
            giaban: 0,
            soluong: 0,
            tongtien: 0,
            tongtienNew: 0,
            disible: true,
            hidden: true,
            tinhtien: true,
            listThuoc: [],
            drawShow: false,
            render: '',
            loai: '',
            sophieu: 0,
            AdministrativePatientName: 'Hành Chánh',
            numberAdd: 1,
            tenKho: '',
            tenNguon: '',
            detailDefault: {},
            ghichu: '',
            btnEdit: true,
            btnEditText: 'Chỉnh sửa',
        };
    }

    componentWillMount() {

        this.loadDetailToa(this.props.ngay, this.props.id)
        this.SearchMedicine();
        this.loadKho();
        this.loadNguon()
        CommonService.GetPatientDetail(this.props.mabn).then(result => {
            if (result.isSuccess) {
                let selectedPatient = result.item;
                let temp = 'Hành Chánh';
                if (selectedPatient) {
                    temp += '    BN: ' + selectedPatient.patientName + '  -   mã BN:' + selectedPatient.patientID;
                }
                this.setState({ AdministrativePatientName: temp })
                this.setState({ selectedPatient })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })

    }

    loadDetailToa(ngay, id) {
        MedicineService.GetListChiTietHoaDon(ngay, id).then(result => {
            if (result.isSuccess) {
                let data = [];
                if (this.props.detail.done === 0) {
                    data = result.data.filter(x => x.soluong > 0);
                }
                else {
                    data = result.data.map(x => ({ ...x, soluong: x.soluongthuc })).filter(x => x.soluongthuc > 0);
                }
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.giathucthu;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                }
                let tongtienNew = tongtien
                this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data[0].ghichu
                })
                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
        })
            .catch(err => {
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
            })
    }

    loadKho() {
        MedicineService.GetListKho().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        //maKho: result.data[0].value,
                        tenKho: result.data[0].label,
                    });
                }
                this.setState({
                    lstKho: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    loadNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        maNguon: result.data[0].value,
                        tenNguon: result.data[0].label,
                    });
                }
                this.setState({
                    lstNguon: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }



    LuuHoaDon() {
        let medicines = this.newTable.getDataSource()
        for (let item of medicines) {
            item.soluong = +item.soluong;
            item.ngay = item.ngay === null ? '0001-01-01' : item.ngay;
        }

        let data = {
            ngay: this.props.ngay === null ? '0001-01-01' : this.props.ngay,
            id: this.props.id,
            medicines,
            ghichu: this.state.ghichu
        }

        Actions.setLoading(true);
        MedicineService.LuuXacNhanHoaDon(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' })
                this.ChangeButton();
                this.setState({ isPrint: true })
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.warning({ message: 'Lưu bị lỗi' });
            Actions.setLoading(false);
        })
    }


    onSelectPatient(item) {
        this.setState({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })

        this.form.setFieldsValue({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })

        this.AutocompletePatient.setValue(item.patientName);
    }

    xoathuoc(row) {
        let lstDetailNew = this.state.lstDetailNew;
        let lst = this.state.lstDetailNew.filter(x => x.id !== row.id);
        lstDetailNew = lst;
        this.newTable.setDataSource(lstDetailNew);
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }
        this.setState({ tongtienNew, lstDetailNew })
    }

    handleChangeNewMedicine(data) {
        try {
            let tongtienNew = 0;
            for (let item of data) {
                if (item.soluong && item.giathucthu)
                    tongtienNew += item.soluong * item.giathucthu;
            }
            let temp = data.map(function (e, index) {
                let o = Object.assign({}, e);
                return o;
            })
            this.newTable.setDataSource(temp);
            this.setState({ tongtienNew: tongtienNew, lstDetailNew: temp });
        } catch (error) {

        }



    }
    keyFunction = (e, row, col) => {
        if (col === 'cachdung') {
            e.preventDefault();

            if (e.key === 'Tab') {
                if (row < this.state.lstDetailNew.length) {
                    this.newTable.focusNewRow(row + 1,
                        'soluong');
                } else {
                    if (this.Autocomplete)
                        this.Autocomplete.focus();
                }

            }
            if (e.key === 'Enter') {
                if (this.ButtonSave)
                    this.ButtonSave.focus();
            }
        }

    }

    AddNewMedicine(item) {
        let { lstDetailNew } = this.state
        item.id = Number(item.value);
        item.makho = Number(item.makho);
        item.manguon = Number(item.manguon);
        let olditems = lstDetailNew.filter(record => record.ma === item.ma);
        if (olditems.length > 0) {
            return
        } else {
            item.tenthuoc = item.ten;
            item.dang = item.donvi;
            item.isNew = true
            item.key = item.ma
            item.soluong = null
            item.giathucthu = item.dongia
            item.countNumber = lstDetailNew.length + 1;
            item.songay = isNullOrEmpty(item.songay) ? 1 : item.songay;
            item.slsang = isNullOrEmpty(item.slsang) ? 0 : item.slsang;
            item.sltrua = isNullOrEmpty(item.sltrua) ? 0 : item.sltrua;
            item.slchieu = isNullOrEmpty(item.slchieu) ? 0 : item.slchieu;
            item.sltoi = isNullOrEmpty(item.sltoi) ? 0 : item.sltoi;
            lstDetailNew.push(item);
        }
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }

        this.newTable.setDataSource(lstDetailNew);
        setTimeout(() => {
            this.newTable.focusNewRow(lstDetailNew[lstDetailNew.length - 1].countNumber,
                'soluong');
        }, 200)

        this.setState({ tongtienNew: tongtienNew, lstDetailNew: lstDetailNew })

    }

    SearchMedicine(key) {
        return MedicineService.DanhSachThuocBanle(2, key, 11, 502)
    }
    SearchGoiThuoc(key) {
        return MedicineService.GetListGoiThuoc(key, 1, 20);
    }
    onSelect(item) {
        if (item.slton === 0) {
            notification.warning({ message: `Thuốc ${item.ten} không còn trong kho !!!` })
        } else {
            this.AddNewMedicine(item)
        }
    }
    onSelectGoiThuoc(item) {
        MedicineService.DanhSachThuocBanLeTrongGoiThuoc(item.id, 11, 502, false).then(result => {
            if (result.isSuccess === true) {
                if (result.data.length > 0) {
                    let data = this.state.lstDetailNew;
                    result.data.forEach(item => {
                        item.id = Number(item.value);
                        item.makho = Number(item.makho);
                        item.manguon = Number(item.manguon);
                        let olditems = data.filter(record => record.ma === item.ma);
                        if (olditems.length > 0) {
                            // continue;
                        } else {
                            item.tenthuoc = item.ten;
                            item.dang = item.donvi;
                            item.isNew = true
                            item.key = item.ma
                            item.giathucthu = item.dongia
                            item.countNumber = data.length + 1;
                            data.push(item);
                        }
                    });
                    let tongtienNew = 0;
                    for (let item of data) {
                        if (item.soluong && item.giathucthu)
                            tongtienNew += item.soluong * item.giathucthu;
                    }

                    this.newTable.setDataSource(data);
                    setTimeout(() => {
                        this.newTable.focusNewRow(data[data.length - 1].countNumber,
                            'soluong');
                    }, 200)

                    this.setState({ tongtienNew: tongtienNew, lstDetailNew: data })
                }
                else {
                    notification.warning({ message: 'Không có thuốc nào trong kho.' })
                }

            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    editPrice(row, gia) {
        let { lstDetailNew } = this.state;
        let index = lstDetailNew.findIndex(record => record.id === row.id && record.makho === row.makho && record.manguon === row.manguon && record.tenhang === row.tenhang);
        lstDetailNew[index].giathucthu = gia;
        let tongtien = 0;
        for (let item of lstDetailNew) {
            tongtien += item.soluong * item.giathucthu;
        }
        let tongtienNew = tongtien;
        this.newTable.setDataSource(lstDetailNew);
        this.setState({ lstDetailNew, tongtienNew })
    }
    PrintUse(id, ngay, maql) {
        PrintService.PrintUse(id, ngay, maql).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintMedicine(id, ngay) {
        PrintService.PrintMedicine(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintConclusion(mabn, maql, ngay, isDuoc = false, makp = "", loaiKham = "") {
        PrintService.PrintConclusion(this.props.id, mabn, maql, ngay, makp, loaiKham, isDuoc).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ChangeButton() {
        this.setState({ btnEdit: !this.state.btnEdit }, () => {
            let txt = 'Lưu';
            if (this.state.btnEdit === true) {
                txt = 'Chỉnh sửa';
            }
            this.setState({ btnEditText: txt });
        })
    }
    XoaPhieu() {
        MedicineService.XoaToaTheoDon(this.props.id, moment(this.props.ngay).format('YYYY-MM-DD'),this.props.maql).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.props.onClose();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    render() {
        const columns = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 35,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                width: 150,
            },

            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 50,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 100,
            },
            {
                title: 'Hãng sản xuất',
                dataIndex: 'hangsanxuat',
                key: 'hangsanxuat',
                width: 110,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                type: 'number',
                width: 100,
                editable: true,
                isrequire: true,
                min: 1,
                maxLength: 7,
                //    CustomKey: 'ma'
            },
            {
                title: 'Cách dùng',
                key: 'cachdung',
                dataIndex: 'cachdung',
                editable: true,
                width: 100,
                //  CustomKey: 'ma'
            },

            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 100,
                render: (value, row) => (<InputNumber style={{ width: '90px' }} value={value} min={0} max={999999999} onChange={(e) => { this.editPrice(row, e) }} />)
            },

            {
                title: '',
                width: 30,
                render: (row, index) => {

                    return (
                        <Popconfirm
                            title="Bạn có chắc muốn xóa thuốc này không?"
                            onConfirm={() => { this.xoathuoc(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <div style={{ cursor: 'pointer' }}>
                                <span style={{ width: '100%', height: '100%', marginTop: 3, color: 'red' }}> <i className="fa fa-trash"></i>  </span>
                            </div>
                        </Popconfirm>
                    )
                }
            }
        ];
        const columnsEdit = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 35,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                width: 150,
            },

            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 50,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 100,
            },
            {
                title: 'Hãng sản xuất',
                dataIndex: 'hangsanxuat',
                key: 'hangsanxuat',
                width: 110,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 110,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                type: 'number',
                width: 100,
                editable: true,
                isrequire: true,
                maxLength: 7,
                min: 1,
                //    CustomKey: 'ma'
            },
            {
                title: 'Cách dùng',
                key: 'cachdung',
                dataIndex: 'cachdung',
                editable: true,
                width: 100,
                //  CustomKey: 'ma'
            },

            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : '0'}</span>;
                },
            },
        ];
        const columnsSearh = [
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 200,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 350,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 100,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 80,
                format: 'number'
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,

            },
            {
                title: 'Tồn kho',
                dataIndex: 'slton',
                key: 'slton',
                width: 80,
                format: 'number'
            },
        ];
        const columnsGoiThuoc = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 100,
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: 350,
            },
            {
                title: 'Số ngày',
                dataIndex: 'songay',
                key: 'songay',
                width: 100,
            },
            {
                title: 'Mô tả',
                dataIndex: 'mota',
                key: 'mota',
                width: 150,
            }
        ];
        let dataSourceNew = this.state.lstDetailNew.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.ma
            return o;
        })



        return (

            <React.Fragment>
                <HeaderModal title="Phiếu xuất thuốc" onClose={() => this.props.onClose()} />
                <div style={{ height: 'calc(100% - 50px)' }}>
                    <div>
                        <Administrative loai={Number(!isNullOrEmpty(this.props.detail.loai) ? this.props.detail.loai : 2)} thanhtoan={this.props.done === 1} item={this.state.selectedPatient}></Administrative>
                    </div>
                    <div style={{
                        height: 'calc(100% - 150px)', overflow: 'auto', marginTop: 10
                    }}>
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ marginTop: 20 }}>
                                    <AutocompleteTable
                                        disabled={this.state.btnEdit && this.props.done !== 0}
                                        placeholder={"Tìm theo tên hay hoạt chất"}
                                        width={300}
                                        tablewidth={1000}
                                        onSearch={this.SearchMedicine}
                                        onSelect={this.onSelect.bind(this)}
                                        value={this.state.thuoc} headers={columnsSearh}
                                        keyValue={'ma'}
                                        ref={(c) => { this.Autocomplete = c }}
                                    />
                                    <AutocompleteTable
                                        disabled={this.state.btnEdit && this.props.done !== 0}
                                        placeholder={"Tìm theo mã, tên gói thuốc"}
                                        width={300}
                                        tablewidth={800}
                                        listHeight={"50vh"}
                                        onSearch={this.SearchGoiThuoc}
                                        onSelect={this.onSelectGoiThuoc.bind(this)}
                                        value={this.state.goithuoc} headers={columnsGoiThuoc}
                                        hideGroupHeader={true}
                                        keyValue={'id'}
                                    />
                                </div>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Statistic title="Tổng Tiền" value={this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}
                                        precision={2} />
                                </div>
                            </div>
                            <div style={{ display: `${this.state.btnEdit === true && this.props.done !== 0 ? '' : 'none'}`, height:'calc(100% - 130px)' }}>
                                <Table
                                    scroll={{ x: 'max-content', y: 0 }}
                                    dataSource={dataSourceNew} columns={columnsEdit} pagination={false}
                                    rowClassName={(record) => {

                                        return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                                    }}

                                ></Table>
                            </div>
                            <div style={{ display: `${this.state.btnEdit === false || this.props.done === 0 ? '' : 'none'}`,height:'calc(100% - 125px)' }}>
                                <EditableTable
                                    ref={(c) => this.newTable = c}
                                    onchange={(data) => { this.handleChangeNewMedicine(data) }}
                                    scroll={{ x: 'max-content', y: 0 }}
                                    height='100%'
                                    dataSource={dataSourceNew} columns={columns} pagination={false}
                                    keyFunction={this.keyFunction}
                                    rowClassName={(record) => {

                                        return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                                    }}

                                ></EditableTable>
                            </div>

                            <div style={{ marginTop: 10 }}>Ghi chú: <br></br>
                                <Input style={{ marginTop: 7 }}
                                    disabled={this.state.btnEdit && this.props.done !== 0}
                                    placeholder="Nhập lý do điều chỉnh giá thực thu"
                                    value={this.state.ghichu}
                                    onChange={(e) => { this.setState({ ghichu: e.target.value }) }} />
                            </div>
                        </React.Fragment>
                    </div>
                    <div className='custom-hr'/>
                    <div className="form-group" style={{
                        marginTop: 15, display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div>
                            <Popconfirm
                                title="Xác nhận xóa phiếu!"
                                onConfirm={() => { this.XoaPhieu() }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger">Xóa phiếu</button>
                            </Popconfirm>
                        </div>
                        {(this.props.done === 2 || this.state.isPrint === true) ?
                            < React.Fragment >
                                <button className="btn btn-outline-secondary" key="1" style={{ marginLeft: 5 }} onClick={() => {
                                    this.PrintConclusion(this.props.mabn, this.props.detail.maql, this.props.ngay, true)
                                }} >
                                    In phiếu thuốc </button>
                                <button className="btn btn-outline-secondary" key="2" style={{ marginLeft: 5 }} onClick={() => { this.PrintUse(this.props.id, this.props.ngay,this.props.detail.maql) }} >
                                    In cách dùng </button>
                            </React.Fragment>
                            :
                            <div>
                                <button className="btn btn-outline-secondary" key="1" style={{ marginLeft: 5 }} onClick={() => {
                                    this.PrintConclusion(this.props.mabn, this.props.detail.maql, this.props.ngay)
                                }}>
                                    In đơn thuốc </button>
                            </div>
                        }
                        {this.props.done === 0 ?
                            <Popconfirm
                                title="Bạn có chắc muốn xác nhận đơn thuốc này không?"
                                onConfirm={() => { this.LuuHoaDon() }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-color" type="primary" style={{ marginLeft: 5 }}>
                                    <i className="fa fa-save" > Xác nhận đơn thuốc</i>
                                </button>
                            </Popconfirm>
                            :
                            <div>{
                                this.state.btnEdit === true ?
                                    <button className="btn btn-color" onClick={() => { this.ChangeButton() }} style={{ marginLeft: 5 }}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>{this.state.btnEditText}
                                    </button> :
                                    <Popconfirm
                                        title="Bạn có chắc muốn xác nhận đơn thuốc này không?"
                                        onConfirm={() => { this.LuuHoaDon() }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-color" type="primary" style={{ marginLeft: 5 }}>
                                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>{this.state.btnEditText}
                                        </button>
                                    </Popconfirm>
                            }</div>
                        }

                        <button className="btn btn-danger" style={{ marginLeft: 5 }}
                            onClick={() => this.props.onClose()}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            />  Đóng
                        </button>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

