import { Button, Form, Input, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import * as CommonService from '../../services/commonService';
import * as DepartmentConfigService from '../../services/departmentConfigureService'
const { Option } = Select;
export default (props) => {

    const [dataSource, setDataSource] = useState([]);
    const [dataDepartment, setDataDepartment] = useState([]);
    const [makhoa, setMakhoa] = useState(props.item.makp || '');
    const [selectedItem, setSelectedItem] = useState({});
    const [detail, setDetail] = useState(props.item);
    useEffect(() => {
        LoadDepartment();
    }, [])

    useEffect(() => {
        DanhSachSoLuongBenhNhanKhamBenh(makhoa);
    }, [makhoa])

    const LoadDepartment = () => {
        CommonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess) {
                setDataDepartment(result.data)
            }
        })
    }

    const DanhSachSoLuongBenhNhanKhamBenh = (makhoa) => {
        DepartmentConfigService.DanhSachSoLuongBenhNhanKhamBenh(makhoa).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
            }
        })
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (Number(index) + 1),
            align: 'center',
        },
        {
            title: 'Tên khoa',
            dataIndex: 'tenkhoa',
            key: 'tenkhoa',
        },
        {
            title: 'Tên phòng',
            dataIndex: 'tenphong',
            key: 'tenphong',
        },
        {
            title: 'Đang khám',
            dataIndex: 'dangkham',
            key: 'dangkham',
        },
        {
            title: 'Chưa khám',
            dataIndex: 'chuakham',
            key: 'chuakham',
        },
        {
            title: '',
            render: (text, record, index) => {
                return (
                    <Button
                        onClick={() => {
                            if (props.onSubmit) props.onSubmit(record)
                        }}
                    >Chọn</Button>
                )
            },
            align: 'center',
        }
    ];
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-12 mb-2'>
                    <div>
                        <Form.Item label="Khoa">
                            <Select value={makhoa} style={{ width: '200px' }} onChange={(e) => {
                                setMakhoa(e);
                            }}>
                                {dataDepartment.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className='col-12 mb-2'>
                    <Table dataSource={dataSource}
                        rowKey={(e, index) => index} columns={columns} scroll={{ y: 0 }}
                        pagination={false}
                        rowClassName={(record) => {
                            return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : record && record.voucher && record.voucher.length > 0 ? 'text-danger' : ''}`

                        }}
                    />
                </div>
                <div className='col-12 mb-2 text-center'>
                    <Button className='btn btn-secondary' onClick={() => {
                        if (props.onClose) props.onClose()
                    }}
                    >Đóng</Button>
                </div>
            </div>
        </React.Fragment>
    )
}
