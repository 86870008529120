import React, { Fragment, useEffect, useCallback, useState, forwardRef, useRef } from 'react';
import { Input } from 'antd';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../../../libs/suneditor-vi-lang';
import MitaCameraViewer from "../../../../components/MitaComponents/Camera/Component/mitaCameraViewer";

export default (props) => {
    // Device
    const [selectedDevice, setSelectedDevice] = useState(null);
        // Id tham chiếu
    const [refId, setRefId] = useState('')
    // PACS  Infomation
    const [moTa, setMoTa] = useState();
    const [ketLuan, setKetLuan] = useState();
    const [deNghi, setDeNghi] = useState();

    useEffect(() => {
        setSelectedDevice(props.device)
        setRefId(props.refId);
        if(props.data){
            setMoTa(props.data.mota);
            setKetLuan(props.data.ketluan);
            setDeNghi(props.data.denghi);
        }
    }, [props]) 

    const onClose = () => {
        let data = {
            'mota' : moTa,
            'denghi' : deNghi,
            'ketluan' : ketLuan
        }
        props.onClose(data);
    }


    return (
        <div className="row">
            <div className='col-6' >
                <MitaCameraViewer 
                    featureName="cdha"
                    componentKey="CDHA-Multimedia"
                    refId={refId} 
                    device={selectedDevice} 
                    onClose={() => onClose() }
                />
            </div>
            <div className='col-6'>
                <div className='col-12 mt-2'>
                    <label className='font-weight-bold'>Mô tả</label>
                    <SunEditor
                        lang={suneditorViLang}
                        onBlur={(e, value) => {
                            setMoTa(value);
                        }}
                        onChange={(e) => setMoTa(e)}
                        setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                        setContents={moTa}
                        setOptions={{
                            height: 550,
                            buttonList: [
                                ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                                ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                            ]
                        }}
                        height='100%'
                    />
                </div>
                <div className='col-12 mt-2'>
                    <label className='font-weight-bold'>Kết luận</label>
                    <SunEditor
                        lang={suneditorViLang}
                        onBlur={(e, value) => {
                            setKetLuan(value);
                        }}
                        onChange={(e) => setKetLuan(e)}
                        setDefaultStyle="font-size: 13px; paragraphStyles: normal; font-family: Arial;"
                        setContents={ketLuan}
                        setOptions={{
                            height: 300,
                            buttonList: [
                                ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'lineHeight', 'horizontalRule', 'list', 'table', 'link'],
                            ]
                        }}
                        height='100%'
                    />
                </div>
                <div className='col-12 mt-2'> 
                    <label className='font-weight-bold'>Đề nghị</label>
                    <Input.TextArea
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        value={deNghi}
                        placeholder="Nhập đề nghị"
                        onChange={(e) => setDeNghi(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
};
