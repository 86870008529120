import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Button, Checkbox, Input, Popover, Select, Slider, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import * as NotificationMessageService from "../../services/notificationMessage";
import { setLoading } from '../../libs/actions';
import { isNullOrEmpty, isnotnull } from '../../libs/util';

let task = null;

const UserNotificationSelect = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        },
        resetListUserSelected: () => {
            resetListUserSelected();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListUser();
    }, [props.groupId, props.feature]);

    const _notify = useRef();
    const [keyword, setKeyword] = useState("");
    const [listUser, setListUser] = useState([]);
    const [listUserSelected, setListUserSelected] = useState([]);
    const checkAll = listUserSelected.length === listUser.length;
    const indeterminate = listUserSelected.length > 0 && listUserSelected.length < listUser.length;
    const [gender, setGender] = useState();
    const [ageRange, setAgeRange] = useState([]);
    const [listUserId, setListUserId] = useState();

    const columns = [
        {
            title: 'Chọn',
            dataIndex: '',
            key: '',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return (
                    <Checkbox
                        checked={listUserSelected.find(x => x.username == record.username) != undefined}
                        onChange={(e) => {
                            handleChangeSelect(record);
                        }}
                    ></Checkbox>
                )
            }
        },
        {
            title: 'Họ và tên',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 150,
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            width: 100,
        },
    ];

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        } else showError();
    }

    const getListUser = (key = keyword) => {
        setLoading(true);
        const data = {
            keyword: key,
            groupId: props.groupId,
            feature: props.feature ?? "",
        }
        NotificationMessageService.getListUser(data).then(result => {
            if (result.isSuccess)
                setListUser(result.data);
            else setListUser([]);
        }).catch(() => {
            showError('Lỗi tải danh sách người dùng của nhóm')
        }).finally(() => setLoading(false))
    }

    const onDelaySearch = (e) => {
        if (task) clearTimeout(task)
        task = setTimeout(() => {
            getListUser(e.target.value)
        }, 500)
    }

    const handleSaveSelectUser = () => {
        if (props.onSave) {
            props.onSave(listUserSelected);
        } else if (props.onClose) {
            props.onClose();
        } else showError();
    }

    const showError = (err) => {
        _notify && _notify.current && _notify.current.Show(err ?? "Không tìm thấy function", NotifyStatus.Error);
    }

    const handleChangeCheckAll = (checked) => {
        if (!checked) {
            setListUserSelected([]);
        } else {
            let listUserSelectedNew = [...listUserSelected];
            (listUser ?? []).forEach(record => {
                let isSelected = listUserSelectedNew.find(x => x.username == record.username) != undefined;
                if (!isSelected) {
                    listUserSelectedNew.push(record);
                }
            });
            setListUserSelected(listUserSelectedNew);
        }
    }

    const handleChangeSelect = (record) => {
        let isSelected = listUserSelected.find(x => x.username == record.username) != undefined;
        let listUserSelectedNew = [...listUserSelected];
        if (!isSelected) {
            listUserSelectedNew.push(record);
            setListUserSelected(listUserSelectedNew);
        } else {
            listUserSelectedNew = listUserSelectedNew.filter(x => x.username != record.username);
            setListUserSelected(listUserSelectedNew);
        }
    }

    const resetListUserSelected = () => {
        setListUserSelected([]);
        setListUserId();
    }

    const onApplyListUserId = async () => {
        const listId = listUserId.replace(/ /g, "").split(",");
        let list = [];
        const data = {
            keyword: "",
            groupId: props.groupId,
            feature: props.feature ?? "",
        }
        const listUser = await NotificationMessageService.getListUser(data).then(result => { return result.data ?? [] }).catch(() => { return [] });
        if (isnotnull(listId)) {
            listId.forEach((Id) => {
                let found = listUser.find(x => x.username == Id);
                if (!isNullOrEmpty(Id) && found != undefined) list.push(found);
            })
        }
        setListUserSelected(list);
    }

    return (
        <div className="list-page">
            <HeaderModal title="Chọn người dùng" onClose={() => { onClose() }} />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <Input
                                prefix={<i className='fas fa-search' />}
                                placeholder='Nhập tên hoặc tài khoản người dùng'
                                className="w-100"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={(e) => {
                                    onDelaySearch(e);
                                }}
                            ></Input>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 d-flex flex-row justify-content-end'>
                            <Popover trigger="click" title="Nhập nhanh danh sách người dùng" placement='left' destroyTooltipOnHide
                                content={
                                    <div>
                                        <Input.TextArea
                                            autoSize={{ maxRows: 10, minRows: 5 }}
                                            placeholder='Nhập nhanh danh sách bằng ID người dùng (tài khoản đăng nhập), các ID cách nhau bằng dấu phẩy ","'
                                            className="w-100"
                                            value={listUserId}
                                            onChange={(e) => setListUserId(e.target.value)}
                                        ></Input.TextArea>
                                        <Button className={`${BUTTON.CUSTOM} mt-2`} onClick={() => { onApplyListUserId() }}>Nhập ngay</Button>
                                    </div>
                                }>
                                <Button className={`${BUTTON.CUSTOM}`}>Nhập nhanh danh sách người dùng</Button>
                            </Popover>
                        </div>
                    </div>
                    <div className='row d-none'>
                        <div className='col-12 mt-2'>
                            <label>Giới tính</label>
                            <Select className='w-100' value={gender} onChange={(e) => setGender(e)} allowClear>
                                <Select.Option key={0} value={0}>Nam</Select.Option>
                                <Select.Option key={1} value={1}>Nữ</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div className='row d-none'>
                        <div className='col-12 mt-2'>
                            <label>Chọn khoảng tuổi {ageRange.length > 0 && <span>({ageRange[0]} - {ageRange[1]})</span>}</label>
                            <Slider range value={ageRange} onChange={(e) => setAgeRange(e)} />
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                    <Checkbox checked={checkAll} indeterminate={indeterminate} onChange={(e) => {
                        handleChangeCheckAll(e.target.checked);
                    }}>Tất cả</Checkbox>
                    <Popover title="Danh sách đã chọn"
                        placement='left'
                        content={
                            listUserSelected.length > 0 ?
                                <ol type='1'>
                                    {listUserSelected && listUserSelected.map((x, index) => {
                                        return (
                                            <li key={index}>{x.username} - {x.name}</li>
                                        )
                                    })}
                                </ol>
                                : <label>Chưa chọn người dùng nào</label>
                        }>
                        <div className='text-color'>{`Đã chọn${listUserSelected && listUserSelected.length > 0 ? ` ${listUserSelected.length}` : ""}`}</div>
                    </Popover>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 20px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listUser}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.id}
                        rowClassName="rowCustom"
                        onRow={(record) => {
                            return {
                                onClick: () => { handleChangeSelect(record); }
                            };
                        }}
                        pagination={false}
                    />
                </div>
            </div>
            <div className="list-page-footer">
                <div className='d-flex justify-content-end'>
                    <button className={`${BUTTON.THEME}`} onClick={() => {
                        handleSaveSelectUser();
                    }}><i className="mr-2 fas fa-save" />Lưu</button>
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(UserNotificationSelect)