import React, { forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import HeaderModal from '../common/headerModal';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../components/notify';
import MultiMedia from '../../components/MitaComponents/UploadFile/mitaUploadFile';
import { Alert } from 'antd';

const AppOrderTest = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
    }, []);

    const _notify = useRef();

    return (
        <div className="containerChilderWrapper">
            <HeaderModal title="App lấy mẫu tại nhà" />
            <Notify ref={_notify} />
            <Alert message={<span><a target='_blank' href={`${window.location.origin}/app`}>Trang tải xuống app lẫy mẫu</a> sẽ lấy file mới nhất.<br />Quy tắc đặt tên: <span className='font-weight-bold'>{`{tên file}-{version}.apk`}</span>. Ví dụ: MTCOrderApp-1.2.0.apk</span>} />
            <div className='mt-2'>
                <MultiMedia
                    featureName="APPMOBILE"
                    componentKey="AppOrderTest"
                    isEdit={true}
                    layout="Details"
                    extention=".apk"
                    refId={"AppOrderTest"}
                    showPreview={true}
                >
                </MultiMedia>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AppOrderTest)