/*
Component này là thông tin Kết quả xét nghiệm. 
Right panel theo màn hình 6/10 của mockup "HIS-phan he xet nghiem.pdf": thông tin phía trên (Chẩn đoán ban đầu, trạng thái)
*/
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button, Modal, notification } from 'antd';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ExamResultService from '../../../services/ExamResultServices';
import * as TestService from '../../../services/lisService';
import { connect } from 'react-redux';

const TestResultBottom= React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    reload: () => {
      getById(props.sid);
    },
  }));
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [comment, setComment] = useState();
  const [suggestion, setSuggestion] = useState();
  const [fileUrlBlob, setFileUrlBlob] = useState('');
  const [selectedFileUuid, setSelectedFileUuid] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [listFile, setListFile] = useState([]);
  const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
  const [copied, setCopied] = useState(false);
  //#region useEffect
  useEffect(() => {
    getById(props.sid);
  }, []);
  //#endregion 

  const setData = (data) => {
    setComment(data.comment);
    setSuggestion(data.suggestion);
    setListFile(data.lisFiles);
  }

  const getById = (sid) => {
    ExamResultService.getExamResultInfoBottom(sid).then(result => {
      if (result.isSuccess) {
        notiRef && notiRef.current.Close();
        setData(result.data);
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const viewResultPdf = (data) => {
    Actions.setLoading(true);
    TestService.DownLoadLisFile(data.uuId)
        .then((response) => {
            let res = response.clone();
            res.json().then((t) => {
                if (!t.isSuccess) 
                    notification.error({ message: t.error.messageText })
            }).catch(err => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    setFileUrlBlob(url);
                    setVisiblePreviewFile(true);
                })
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })

    // TestService.DownLoadLisFile(data.uuId) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
    //     .then((response) => {
    //         response.blob().then((blob) => {
    //             let url = window.URL.createObjectURL(blob);
    //             setFileUrlBlob(url);
    //             setVisiblePreviewFile(true);
    //         })
    //         Actions.setLoading(false);
    //     }).catch(err => {
    //         Actions.setLoading(false);
    //     })
  }
  
  const downloadFile = () => {
      TestService.DownLoadLisFile(selectedFileUuid).then(result => {
          result.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              _hiddenLink.current.href = url;
              _hiddenLink.current.download = selectedFile ? selectedFile.title : "KetQuaXetNgiem.pdf";
              _hiddenLink.current.click();
          })
      }).catch(err => {
      })
  }

  const printFile = () => {
    TestService.DownLoadLisFile(selectedFileUuid).then(result => {
        result.blob().then((blob) => {
            Actions.DoPrint(blob)
        })
    }).catch(err => {
    })
  }

  function copyToClipboard(e) {
    setCopied(true);
    notification.success({ message: 'Sao chép thành công' });
    setCopied(false);   
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
      <div className="row">{/* notification */}
        <div className="col-md-12"><Notify ref={notiRef} /></div>
      </div>
      <div className="row mt-2">{/* Nhận xét chỉ số */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Nhận xét chỉ số</label>
          <label className="mita-value" style={{ whiteSpace: "pre-line", height: "fit-content", }}>{comment}</label>
        </div>
      </div>
      <div className="row mt-2">{/* Đề nghị */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Đề nghị</label>
          <label className="mita-value" style={{ whiteSpace: "pre-line", height: "fit-content", }}>{suggestion}</label>
        </div>
      </div>
      <div className="row mt-2">{/* Đính kèm tệp tin */}
        <div className="col-md-12 d-flex flex-column">
          <label className="mita-title">Đính kèm tệp tin</label>
          { listFile.map((row, index) => 
            <div
            >
                <i className='fas fa-file-pdf mr-2'></i>
                <a href="#" className='mr-2' style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedFileUuid(row.uuId);
                    setSelectedFile(row);
                    viewResultPdf(row);
                  }}
                >{row.title}</a><label className='mr-2'>{moment(row.dateCreate).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                <CopyToClipboard text={row.fileUrl}
                  onCopy={() => copyToClipboard()}>
                  <i className="far fa-clone mr-2" style={{ cursor: 'pointer' }}></i>
                </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
      {visiblePreviewFile &&
          <Modal maskClosable={true}
              width={1000}
              visible={visiblePreviewFile}
              onCancel={() => {setVisiblePreviewFile(false);}}
              footer={null}>
              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <div className="list-button-preview">
                      <Button className="mr-2" onClick={() => {
                          printFile()
                      }}><i className="fas fa-print"></i></Button>
                      <Button className="mr-2" onClick={() => {
                          downloadFile()
                      }}><i className="fa fa-download"></i></Button>
                      <Button onClick={() =>{setVisiblePreviewFile(false);}}>Đóng</Button>
                  </div>
                  <embed src={`${fileUrlBlob}#toolbar=0`}
                      style={{ height: '100%', width: '100%' }}
                  />
              </div>
          </Modal>
      }
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TestResultBottom);