import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Product';

//#region giá vp
export function getList(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getListVPAndPackage(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list-vpandpackage`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion
//#region nhóm vp
export function nhomGetListShortcut() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/nhom-getlist-shortcut`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion
