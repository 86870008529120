import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { DatePicker, Input, Pagination, Popconfirm, Select, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import moment from 'moment';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';
import * as NotificationMessageService from "../../services/notificationMessage";
import { setLoading } from '../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { isnotnull } from '../../libs/util';

const CreateNotificationMessage = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListUser();
    }, []);

    useEffect(() => {
        if (props.activeTab == "sumerize")
            handleSearch();
    }, [props.activeTab]);

    const _notify = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [userReceive, setUserReceive] = useState();
    const [screen, setScreen] = useState();
    const [keyword, setKeyword] = useState();
    const [showExtendFilter, setShowExtendFilter] = useState(false);
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [read, setRead] = useState();
    const [userSend, setUserSend] = useState();

    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Họ và tên',
            dataIndex: 'userReceiveName',
            key: 'userReceiveName',
            align: 'left',
            width: 230,
        },
        {
            title: 'Tài khoản',
            dataIndex: 'userReceive',
            key: 'userReceive',
            align: 'center',
            width: 230,
        },
        {
            title: 'Nội dung',
            dataIndex: 'detail',
            key: 'detail',
            align: 'left',
            width: 400,
            render: (detail) => {
                return <div dangerouslySetInnerHTML={{ __html: `${detail}` }} />
            }
        },
        {
            title: 'Ngày gửi',
            dataIndex: 'dateCreate',
            key: 'dateCreate',
            align: 'right',
            width: 200,
            render: (val) => {
                return (
                    <label>{moment(val).format(FORMAT_DATE_TIME)}</label>
                )
            }
        },
        {
            title: "Account gửi",
            dataIndex: 'userSend',
            key: 'userSend',
            align: 'center',
            width: 100,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'read',
            key: 'read',
            align: 'center',
            width: 90,
            render: (val) => {
                if (val == true) return <Tag color='blue'>Đã đọc</Tag>
                else if (val == false) return <Tag color='magenta'>Đã gửi</Tag>
            }
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (val, record) => {
                return (
                    <div>
                        <Popconfirm title="Bạn có muốn xóa thông báo này?"
                            okText="Đồng ý" cancelText="Hủy bỏ"
                            onConfirm={() => { onDeleteNotificationMessage(record.uid); }}
                        >
                            <button className={`${BUTTON.DANGER}`}><i className='fas fa-trash mr-1' />Xóa</button>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    const onDeleteNotificationMessage = (id) => {
        setLoading(true);
        NotificationMessageService.DeleteSentNotificationMessage(id).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Xóa thành công.", NotifyStatus.Success);
                handleSearch(1);
            }
            else showError("Đã xảy ra lỗi khi xóa.")
        }).catch(() => {
            showError("Đã xảy ra lỗi.")
        }).finally(() => setLoading(false));
    }

    const getListUser = () => {
        setLoading(true);
        const data = {
            keyword: "",
            feature: "",
        }
        NotificationMessageService.getListUser(data).then(result => {
            if (result.isSuccess) {
                setListUser(result.data);
                _notify && _notify.current && _notify.current.Close();
            }
            else {
                setListUser([]);
                showError("Đã xảy ra lỗi tải danh sách người dùng.")
            }
        }).catch(() => {
            showError('Lỗi tải danh sách người dùng.')
        }).finally(() => setLoading(false))
    }

    const showError = (err) => {
        _notify && _notify.current && _notify.current.Show(err ?? "Không tìm thấy function.", NotifyStatus.Error);
    }

    const renderScreenList = () => {
        let listOpts = [];
        props.menuData && props.menuData.length > 0 && props.menuData.map((x) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc) => {
                            listOpts.push(getOption(nc));
                        })
                    } else listOpts.push(getOption(n));
                })
            } else listOpts.push(getOption(x));
        })
        return listOpts.filter(x => (props.permission ?? []).includes("@@@") ? x : (props.permission ?? []).includes(x.props.value));
    }

    const getOption = (opt) => {
        return <Select.Option key={`${opt.menuKey}`} value={opt.code}>{opt.label}</Select.Option>
    }

    const onChangeShowMore = () => {
        const newVal = !showExtendFilter;
        setShowExtendFilter(!showExtendFilter);
        if (!newVal) {
            setDateFrom();
            setDateTo();
            setUserSend();
            setRead();
        }
    }

    const handleSearch = (sPage = page, sSize = size) => {
        const data = {
            userSend,
            userReceive,
            screen,
            keyword,
            dateFrom,
            dateTo,
            read,
            skipCount: (sPage - 1) * sSize,
            maxResultCount: sSize,
        }
        if (isnotnull(dateFrom) && isnotnull(dateTo)) {
            if (dateFrom > dateTo) {
                showError('"Từ ngày" phải nhỏ hơn hoặc bằng "Đến ngày"');
                return;
            }
        }
        setLoading(true);
        NotificationMessageService.GetSentNotificationMessage(data).then(res => {
            if (res.isSuccess) {
                setListData(res.data.items);
                setTotalRecords(res.data.totalCount);
                _notify && _notify.current && _notify.current.Close();
            } else {
                setListData([]);
                showError("Đã xảy ra lỗi tải danh sách thông báo.")
            }
        }).catch(() => {
            showError("Đã xảy ra lỗi tải danh sách thông báo.")
        }).finally(() => setLoading(false));
    }

    const handleDeleteAll = () => {
        const data = {
            userSend,
            userReceive,
            screen,
            keyword,
            dateFrom,
            dateTo,
            read,
        }
        if (isnotnull(dateFrom) && isnotnull(dateTo)) {
            if (dateFrom > dateTo) {
                showError('"Từ ngày" phải nhỏ hơn hoặc bằng "Đến ngày"');
                return;
            }
        }
        setLoading(true);
        NotificationMessageService.DeleteSentNotificationMessageFilter(data).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Xóa thành công.", NotifyStatus.Success);
                handleSearch(1);
            }
            else showError("Đã xảy ra lỗi khi xóa.")
        }).catch(() => {
            showError("Đã xảy ra lỗi.")
        }).finally(() => setLoading(false));
    }

    return (
        <div className="list-page">
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='d-flex align-items-end flex-wrap' style={{ flexGrow: 1, flexBasis: 400, gap: 10 }}>
                        <div style={{ flex: 1 }}>
                            <label>Người nhận thông báo</label>
                            <Select className='w-100' placeholder='Chọn người dùng'
                                value={userReceive} onChange={(e) => { setUserReceive(e); }}
                                showSearch allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {listUser && listUser.map((x, index) => {
                                    return (
                                        <Select.Option key={index} value={x.username}>{x.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div style={{ flex: 1 }}>
                            <label>Màn hình</label>
                            <Select className='w-100' placeholder='Chọn màn hình'
                                value={screen} onChange={(e) => { setScreen(e); }}
                                showSearch allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderScreenList()}
                            </Select>
                        </div>
                        <div style={{ flex: 2 }}>
                            <label>Từ khóa</label>
                            <Input className='w-100' placeholder='Nhập nội dung hoặc tiêu đề'
                                value={keyword} onChange={(e) => { setKeyword(e.target.value); }}
                            ></Input>
                        </div>
                        <div className='mt-2'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { handleSearch(1) }}><i className='fas fa-search mr-1' />Tìm</button>
                        </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-between align-items-center'>
                        <span className='anchor-color' onClick={() => onChangeShowMore()}>Tìm kiếm mở rộng</span>
                        <Popconfirm title={<span>Bạn có muốn xóa tất cả thông báo <b>được tìm thấy theo bộ lọc đã tạo</b>?</span>}
                            okText="Đồng ý" cancelText="Hủy bỏ"
                            onConfirm={() => { handleDeleteAll(); }}
                        >
                            <button className={`${BUTTON.DANGER}`}>Xóa tất cả thông báo</button>
                        </Popconfirm>
                    </div>
                    {showExtendFilter && <div className='d-flex align-items-end flex-wrap' style={{ flexGrow: 1, flexBasis: 400, gap: 10 }}>
                        <div className="mt-2" style={{ flex: 1 }}>
                            <label>Gửi đi bởi</label>
                            <Select className='w-100' placeholder='Chọn người gửi'
                                value={userSend} onChange={(e) => { setUserSend(e); }}
                                showSearch allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {listUser && listUser.map((x, index) => {
                                    return (
                                        <Select.Option key={index} value={x.username}>{x.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="mt-2" style={{ flex: 1 }}>
                            <label>Gửi từ ngày</label>
                            <DatePicker className='w-100' locale={viVN} value={dateFrom} onChange={(e) => setDateFrom(e)} allowClear placeholder='Gửi đi từ ngày' />
                        </div>
                        <div className="mt-2" style={{ flex: 1 }}>
                            <label>Đến ngày</label>
                            <DatePicker className='w-100' locale={viVN} value={dateTo} onChange={(e) => setDateTo(e)} allowClear placeholder='Đến ngày' />
                        </div>
                        <div className="mt-2" style={{ flex: 1 }}>
                            <label>Trạng thái</label>
                            <Select className='w-100' placeholder='Trạng thái'
                                value={read} onChange={(e) => { setRead(e); }}
                                allowClear
                            >
                                <Select.Option key={false} value={false}>Đã gửi</Select.Option>
                                <Select.Option key={true} value={true}>Đã đọc</Select.Option>
                            </Select>
                        </div>
                    </div>}
                </div>
                <div className="list-page-table mt-2">
                    <Table
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: "max-content" }}
                        rowKey={(e) => e.uid}
                        pagination={false}
                    />
                </div>
                <Pagination
                    current={page}
                    size={size}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { setPage(page); setSize(size); handleSearch(page, size); }}
                    onShowSizeChange={(page, size) => { setPage(1); setSize(size); handleSearch(1, size); }}
                    showSizeChanger={true}
                    locale={{ items_per_page: "kết quả / trang" }}
                />
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
        permission: state.global.permission,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CreateNotificationMessage)