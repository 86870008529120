import React, { Component } from 'react'
import { notification, DatePicker, Popconfirm, Modal } from 'antd';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as NhapkhoaService from '../../../services/nhapkhoaService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from "../../../configure/systemConfig";
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: moment(),
            key: '',
            lstBN: [],
            lstDatCom: [],
            lstSuatAn: [],
            makp: '',
            loai: '1',
            suatan: '',
            soluong: 1,
            sang: 0,
            trua: 0,
            chieu: 0,
            toi: 0,
            huongdan: '',
            ghichu: '',
            mabn: '',
            maql: '',
            mavaovien: '',
            detailCT: {},
            lstSuatAnEdit: [],
        };
    }
    componentDidMount() {
        this.LoadListPatient();
        this.LoadListSuatAn();
        this.ListDatCom();
    }
    LoadListPatient() {
        NhapkhoaService.DanhSachDaNhap('', this.state.key).then(response => {
            if (response.isSuccess === true) {
                let data = response.data.map(x => ({
                    enterCode: x.enterCode,
                    name: x.name,
                    code: x.code,
                    managerId: x.managerId,
                }))
                this.setState({ lstBN: data });
            }
        })
    }
    LoadListSuatAn(isEdit = false) {
        let loai = this.state.loai;
        if (isEdit) {
            loai = this.state.detailCT.loai
        }
        NhapkhoaService.DanhSachSuatAn(loai).then(response => {
            if (response.isSuccess === true) {
                if (isEdit) {
                    this.setState({ lstSuatAnEdit: response.data });
                    if (response.data.length > 0) {
                        let detail = this.state.detailCT;
                        detail.idSuatAn = response.data[0].id;
                        this.setState({ detailCT: { ...detail } })
                    }
                }
                else {
                    this.setState({ lstSuatAn: response.data });
                    if (response.data.length > 0) {
                        this.setState({ suatan: response.data[0].id });
                    }
                }
            }
        })
    }
    ListDatCom() {
        NhapkhoaService.ListDatCom(this.state.ngay.format('YYYY/MM/DD')).then(response => {
            if (response.isSuccess === true) {
                this.setState({ lstDatCom: response.data });
            }
        })
    }
    UpdateDatComCT(data, isUpdate = false) {
        let isdeleted = isUpdate === false ? 1 : 0;
        let tmp = {
            id: data.id,
            idDatCom: data.idDatCom,
            idSuatAn: data.idSuatAn,
            dongia: data.dongia,
            ngay: data.ngay,
            ghichu: data.ghichu,
            huongdan: data.huongdan,
            soluong: data.soluong,
            sang: data.sang,
            trua: data.trua,
            chieu: data.chieu,
            toi: data.toi,
            isdeleted: isdeleted,
        }
        NhapkhoaService.UpdateDatComCT(tmp).then(response => {
            if (response.isSuccess === true) {
                notification.success({ message: 'Thao tác thành công' });
                this.setState({ visible: false })
                this.ListDatCom();
            }
            else {
                Actions.openMessageDialog("", response.err.msgString)
            }
        })
    }
    submit() {
        if (this.state.mabn === '') {
            notification.error({ message: 'Chưa chọn bệnh nhân!' });
            return;
        }
        let data = {
            maql: this.state.maql,
            mabn: this.state.mabn,
            mavaovien: this.state.mavaovien,
            giuong: '',
            listDetail: [
                {
                    idDatCom: '',
                    idSuatAn: this.state.suatan,
                    dongia: this.state.lstSuatAn.filter(x => x.id === this.state.suatan.toString())[0].dongia,
                    ngay: this.state.ngay,
                    ghichu: this.state.ghichu,
                    huongdan: this.state.huongdan,
                    soluong: this.state.soluong,
                    sang: this.state.sang,
                    trua: this.state.trua,
                    chieu: this.state.chieu,
                    toi: this.state.toi,
                }
            ]
        }
        NhapkhoaService.UpdateDatCom(data).then(response => {
            if (response.isSuccess === true) {
                notification.success({ message: 'Thao tác thành công' });
                this.ListDatCom();
            }
            else {
                Actions.openMessageDialog("", response.err.msgString)
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className="text-center h5 text-uppercase">ĐẶT CƠM - CHÁO - SỮA - BỘT</div>
                <div className="d-flex justify-content-center">
                    <DatePicker locale={viVN} className="mr-1" allowClear={false} value={this.state.ngay} format={'DD/MM/YYYY'}
                        onChange={(e) => this.setState({ ngay: e }, () => {
                            this.ListDatCom()
                        })}
                    />
                    <button className="btn btn-outline-secondary mr-1 d-none" onClick={() => { }}>Xem tổng kết</button>
                    <button className="btn btn-outline-secondary mr-1 d-none" onClick={() => { }}>In cơm</button>
                    <button className="btn btn-outline-secondary mr-1 d-none" onClick={() => { }}>In sữa bột</button>
                </div>
                <div className="row equal" style={{ padding: '10px' }}>
                    <div className="col-md-4 mb-2">
                        <div style={{ backgroundColor: '#fff', padding: '10px', height: 'calc(100vh - 195px)', overflow: 'auto' }}>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <button className="btn btn-color" type="button" onClick={() => { this.LoadListPatient() }}><i className="fa fa-search" /></button>
                                </div>
                                <input type="text" className="form-control" placeholder="Nhập mã nhập viện hoặc tên bệnh nhân" onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.LoadListPatient();
                                        }
                                    }} />
                            </div>
                            <table className="table table-striped table-inverse table-hover">
                                <tbody>
                                    {this.state.lstBN.map((row, index) => (
                                        <tr className={`${this.state.mavaovien === row.enterCode ? 'bg-select-table' : ''}`} key={index} style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.setState({
                                                    mavaovien: row.enterCode,
                                                    mabn: row.code,
                                                    maql: row.managerId,
                                                })
                                            }}>
                                            <td>{row.enterCode}</td>
                                            <td>{row.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-8 mb-2">
                        <div style={{ backgroundColor: '#fff', padding: '10px', height: '100%' }}>
                            <div className="row">
                                <div className="col-md-3 mb-1">
                                    <label>Loại</label>
                                    <select value={this.state.loai} className="form-control" onChange={(e) => { this.setState({ loai: e.target.value }, () => { this.LoadListSuatAn() }) }}>
                                        <option value="1">Cơm - Cháo</option>
                                        <option value="2">Sữa - Bột</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mb-1">
                                    <label>Tên món</label>
                                    <select value={this.state.suatan} className="form-control w-100" onChange={(e) => { this.setState({ suatan: e.target.value }) }}>
                                        {this.state.lstSuatAn.map((row, index) => (
                                            <option key={index} value={row.id}>{row.ten}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3 mb-1">
                                    <label>Số lượng</label>
                                    <input value={this.state.soluong.toLocaleString('en-US')} type="number" min={0} maxLength={2} className="form-control"
                                        onChange={(e) => {
                                            if (e.target.value.length > 3 || e.target.value < 0)
                                                return;
                                            this.setState({
                                                soluong: Number(e.target.value)
                                            })
                                        }} />
                                </div>
                                <div className="col-md-3 mb-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Sáng</span>
                                        </div>
                                        <input value={this.state.sang.toLocaleString('en-US')} placeholder="Sáng" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                            if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                                return;
                                            this.setState({ sang: Number(e.target.value) })
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Trưa</span>
                                        </div>
                                        <input value={this.state.trua.toLocaleString('en-US')} placeholder="Trưa" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                            if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                                return;
                                            this.setState({ trua: Number(e.target.value) })
                                        }} />

                                    </div>
                                </div>
                                <div className="col-md-3 mb-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Chiều</span>
                                        </div>
                                        <input value={this.state.chieu.toLocaleString('en-US')} placeholder="Chiều" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                            if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                                return;
                                            this.setState({ chieu: Number(e.target.value) })
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Tối</span>
                                        </div>
                                        <input value={this.state.toi.toLocaleString('en-US')} placeholder="Tối" type="number" min={0} max={1} className="form-control" onChange={(e) => {
                                            if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                                return;
                                            this.setState({ toi: Number(e.target.value) })
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-5 mb-1">
                                    <input value={this.state.ghichu} type="text" placeholder="Nhập ghi chú" className="form-control" onChange={(e) => { this.setState({ ghichu: e.target.value }) }} />
                                </div>
                                <div className="col-md-5 mb-1">
                                    <input value={this.state.huongdan} placeholder="Nhận hướng dẫn" type="text" className="form-control" onChange={(e) => { this.setState({ huongdan: e.target.value }) }} />
                                </div>

                                <div className="col-md-2 mb-1">
                                    <button className="btn btn-color w-100" onClick={(e) => {
                                        this.submit();
                                    }}>Thêm</button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-inverse table-hover">
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: '200px' }}>Tên dinh dưỡng</th>
                                            <th style={{ minWidth: '200px' }}>Bệnh nhân</th>
                                            <th style={{ minWidth: '50px' }}>SL</th>
                                            <th style={{ minWidth: '80px' }}>Sáng</th>
                                            <th style={{ minWidth: '80px' }}>Trưa</th>
                                            <th style={{ minWidth: '80px' }}>Chiều</th>
                                            <th style={{ minWidth: '80px' }}>Tối</th>
                                            <th style={{ minWidth: '100px' }}>Ngày</th>
                                            <th style={{ minWidth: '100px' }}>Ghi chú</th>
                                            <th style={{ minWidth: '100px' }}>Hướng dẫn</th>
                                            <th style={{ minWidth: '100px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.lstDatCom.map((row, index) => (
                                            <tr key={index} style={{ cursor: 'pointer' }} onClick={() => {

                                            }}>
                                                <td>{row.tenSA}</td>
                                                <td>{row.tenbn}</td>
                                                <td>{row.soluong}</td>
                                                <td>{row.sang}</td>
                                                <td>{row.trua}</td>
                                                <td>{row.chieu}</td>
                                                <td>{row.toi}</td>
                                                <td>{moment(row.ngay).format(SystemConfig.FORMAT_DATE)}</td>
                                                <td>{row.ghichu}</td>
                                                <td>{row.huongdan}</td>
                                                <td className="d-flex">
                                                    <button onClick={() => {
                                                        this.setState({ visible: true, detailCT: row }, () => {
                                                            this.LoadListSuatAn(true);
                                                        })
                                                    }} className="btn btn-outline-warning mr-1"><i className="fa fa-edit"></i></button>
                                                    <Popconfirm
                                                        title="Bạn có chắc muốn xóa?"
                                                        onConfirm={() => { this.UpdateDatComCT(row) }}
                                                        okText="Đồng ý" cancelText="Hủy">
                                                        <button className="btn btn-outline-danger"><i className="fa fa-trash"></i></button>
                                                    </Popconfirm>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visible &&
                    <Modal maskClosable={false}
                        visible={this.state.visible}
                        width={1000}
                    >
                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <div>Mã nhập viện:<strong>{this.state.detailCT.mavaovien}</strong></div>
                            </div>
                            <div className="col-md-6 mb-1">
                                <div>Tên BN:<strong>{this.state.detailCT.tenbn}</strong></div>
                            </div>
                            <div className="col-md-3 mb-1">
                                <label>Loại</label>
                                <select value={this.state.detailCT.loai} className="form-control" onChange={(e) => {
                                    let detail = this.state.detailCT;
                                    detail.loai = e.target.value;
                                    this.setState({ detailCT: { ...detail } }, () => { this.LoadListSuatAn(true) })
                                }}>
                                    <option value="1">Cơm - Cháo</option>
                                    <option value="2">Sữa - Bột</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-1">
                                <label>Tên món</label>
                                <select value={this.state.detailCT.suatan} className="form-control w-100" onChange={(e) => {
                                    let detail = this.state.detailCT;
                                    detail.idSuatAn = e.target.value;
                                    this.setState({ detailCT: { ...detail } })
                                }}>
                                    {this.state.lstSuatAnEdit.map((row, index) => (
                                        <option key={index} value={row.id}>{row.ten}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3 mb-1">
                                <label>Số lượng</label>
                                <input value={this.state.detailCT.soluong} type="number" min={0} maxLength={2} className="form-control" onChange={(e) => {
                                    if (e.target.value.length > 3 || e.target.value < 0)
                                        return;
                                    let detail = this.state.detailCT;
                                    detail.soluong = Number(e.target.value)
                                    this.setState({ detailCT: { ...detail } })
                                }} />
                            </div>
                            <div className="col-md-3 mb-1">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Sáng</span>
                                    </div>
                                    <input value={this.state.detailCT.sang.toLocaleString('en-US')} placeholder="Sáng" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                        let detail = this.state.detailCT;
                                        if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                            return;
                                        detail.sang = Number(e.target.value)
                                        this.setState({ detailCT: { ...detail } })
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3 mb-1">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Trưa</span>
                                    </div>
                                    <input value={this.state.detailCT.trua.toLocaleString('en-US')} placeholder="Trưa" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                        let detail = this.state.detailCT;
                                        if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                            return;
                                        detail.trua = Number(e.target.value)
                                        this.setState({ detailCT: { ...detail } })
                                    }} />

                                </div>
                            </div>
                            <div className="col-md-3 mb-1">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Chiều</span>
                                    </div>
                                    <input value={this.state.detailCT.chieu.toLocaleString('en-US')} placeholder="Chiều" type="number" className="form-control" min={0} max={1} onChange={(e) => {
                                        let detail = this.state.detailCT;
                                        if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                            return;
                                        detail.chieu = Number(e.target.value)
                                        this.setState({ detailCT: { ...detail } })
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3 mb-1">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Tối</span>
                                    </div>
                                    <input value={this.state.detailCT.toi.toLocaleString('en-US')} placeholder="Tối" type="number" min={0} max={1} className="form-control" onChange={(e) => {
                                        let detail = this.state.detailCT;
                                        if (Number(e.target.value) > 1 || Number(e.target.value) < 0)
                                            return;
                                        detail.toi = Number(e.target.value)
                                        this.setState({ detailCT: { ...detail } })
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-6 mb-1">
                                <input value={this.state.detailCT.ghichu} type="text" placeholder="Nhập ghi chú" className="form-control" onChange={(e) => {
                                    let detail = this.state.detailCT;
                                    detail.ghichu = e.target.value
                                    this.setState({ detailCT: { ...detail } })
                                }} />
                            </div>
                            <div className="col-md-6 mb-1">
                                <input value={this.state.detailCT.huongdan} placeholder="Nhận hướng dẫn" type="text" className="form-control" onChange={(e) => {
                                    let detail = this.state.detailCT;
                                    detail.huongdan = e.target.value
                                    this.setState({ detailCT: { ...detail } })
                                }} />
                            </div>
                        </div>
                        <div className="text-center">
                            <button className='btn btn-color mr-2' onClick={() => this.UpdateDatComCT(this.state.detailCT, true)}>Cập nhật</button>
                            <button className='btn btn-outline-secondary' onClick={() => this.setState({ visible: false })}>Đóng</button>
                        </div>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}
