import { Collapse, DatePicker, Form, notification, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import SimpleReactValidator from 'simple-react-validator';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
export default class ThongKeNhapKhoQuayThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            tenloai: 'Tất cả',
            tenkho: '',
            lstKho: []
        };
    }

    componentWillMount() {
        this.LoadThongKeNhapKho();
    }

    LoadThongKeNhapKho() {
        Actions.setLoading(true);
        StatisticService.ThongKeNhapKho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), 502).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeNhapKho() {
        if (this.validator.allValid()) {
            Actions.setLoading(true);
            StatisticService.ExportNhapkho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), 502)
                .then((response) => {
                    response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        this._hiddenLink.href = url;
                        this._hiddenLink.download = "ThongKeNhapKhoQuay";
                        this._hiddenLink.click();
                    })
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
        }
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center'
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left'
            },
            {
                title: 'Hãng',
                dataIndex: 'tenhang',
                key: 'tenhang',
                align: 'left'
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'center'
            },
            {
                title: 'Loại',
                dataIndex: 'tenloai',
                key: 'tenloai',
                align: 'left'
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'center',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'center',
                width: 120,
            },
            {
                title: 'Chi tiết',
                dataIndex: 'lstData',
                key: 'lstData',
                align: 'center',
                render: (row) => {

                    return (
                        <Collapse className="remove-space-statistic">
                            <Panel header="Xem chi tiết">
                                <Table
                                    style={{ margin: '0 !important' }}
                                    pagination={false}
                                    // pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                    dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
                            </Panel>
                        </Collapse>
                    );
                },
            },
        ]
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ padding: '20px' }}>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <div className="row no-gutters" style={{ paddingBottom: '20px', justifyContent: 'center' }}>
                            <div className="col-auto">
                                <Form.Item label="Từ ngày:">
                                    <RangePicker allowClear={false} disabledDate={this.disabledDate}
                                        format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value }, () => this.LoadThongKeNhapKho()))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto" style={{display:'flex'}}>
                                <Form.Item>
                                    <button className="btn btn-success" onClick={() => { this.LoadThongKeNhapKho() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-primary" onClick={() => { this.ExportThongKeNhapKho() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <div className="table-responsive">
                                <Table
                                    scroll={{ x: 'max-content', y: 0 }}
                                    pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                    dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                            </div>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
