
import React, { useEffect, useState, forwardRef } from 'react';
import { Popover } from 'antd';

export default forwardRef((props, ref) => {
    // const createRef = useRef();
    /*Các props cần truyền vào:
    - content: nội dung/text cần hiển thị
    - maxLength: Độ dài ký tự tối đa được hiển thị
    - showMoreText: text hiển thị cho button show more
    - showLessText: text hiển thị cho button show less
    - displayMode:
      + 'popup': hiển thị popup
      + 'inner': hiện trong tag hiện tại
    */
    //state hứng props
    const [content, setContent] = useState(""); //nội dung/text cần hiển thị (được truyền từ props vào)
    const [maxLength, setMaxLength] = useState(30); //Độ dài tối đa
    const [showMoreText, setShowMoreText] = useState('...show more');
    const [showLessText, setShowLessText] = useState('...show less');
    const [displayMode, setDisplayMode] = useState('inner'); //"inner" | "popup": chế độ hiển thị full text

    //state cho internal component
    // const [contentDisplay, setContentDisplay] = useState(""); //nội dung/text cần hiển thị
    const [contentLess, setContentLess] = useState(""); //nội dung/text ngắn
    // const [visibleShowMoreLess, setVisibleShowMoreLess] = useState(""); //hiển thị button showMore/showLess
    const [isShowMore, setIsShowMore] = useState(false); //trạng thái đang show more hay less
    
    useEffect(() => {
        // console.log('long text -----');
        // console.log(typeof(props.content));
        changeProps();
    }, [props]);

    // useEffect(() => {
    //     reset();
    // }, [props.content, props.maxLength]);

    const changeProps = () => {
        let cont = props.content ?? "";
        let max = props.maxLength ?? 64;
        setContent(cont);
        setMaxLength(max);
        setShowMoreText(props.showMoreText ?? "...show more");
        setShowLessText(props.showLessText ?? "...show less");
        updateDisplayMode();
        updateContentLess(cont, max);
    }
    const updateDisplayMode = () => {
        //mặc dù có 2 giá trị là popup | popup nhưng dùng switch case thay vì if else vì có thể sau này mở rộng thêm, có các mode khác như modal hay là gì gì gì đó
        switch (props.displayMode) {
            case "popup":
                setDisplayMode("popup");
                break;
            default:
                setDisplayMode("inner");
                break;
        }
    }

    const updateContentLess = (cont = content, max = maxLength) => {
        if (cont.length > max) {
            setContentLess(cont.substring(0, max));
        }
        else {
            setContentLess(cont);
        }
    }

    return (
        <div>
            <span style={{whiteSpace: "pre-line"}}>{ (isShowMore && displayMode == 'inner') ? content : contentLess }
            </span>
            <span //type="link"
                className='ant-btn-link'
                style={{ padding: "0", cursor: "pointer" }}
                hidden={!(content.length > maxLength && displayMode === 'inner')}
                onClick={(e) => { e.stopPropagation(); setIsShowMore(!isShowMore) }}
            >{ isShowMore ? showLessText : showMoreText }
            </span>
            <Popover
                content={() => { 
                    return (
                        <span style={{whiteSpace: "pre-line"}}>{content}</span>
                    ); 
                }}
                trigger="click"
            >
                <span // type="link"
                    className='ant-btn-link'
                    style={{ padding: "0", cursor: "pointer" }}
                    // style={{ padding: "0 0 0 2px", display: (isShowMore ? "block" : "") }}
                    hidden={!(content.length > maxLength && displayMode === 'popup')}
                >{ isShowMore ? showLessText : showMoreText }
                </span>
            </Popover>
        </div>
    );
})