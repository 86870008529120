import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import { DatePicker, Pagination, Select, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import { BUTTON } from '../../../configureHIS/common/constants';
import HeaderModal from '../../../configureHIS/common/headerModal';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { FORMAT_DATE, FORMAT_PREFIX_EXCEL } from '../../../configure/systemConfig';
import moment from 'moment';
import * as StatisticService from '../../../services/statisticService';
import { openMessageDialog, setLoading } from '../../../libs/actions';
import * as AppointmentService from '../../../services/appointmentService';
import SiteFilter from "../../../configureHIS/common/siteFilter";
import * as CoporationServices from "../../../services/CorporationServices";

const defaultPageSize = 20;

const BaoCaoNhanVienDatLich = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const _hiddenLink = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(defaultPageSize);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [dateFrom, setDateFrom] = useState(moment().subtract(3, "months"));
    const [dateTo, setDateTo] = useState(moment());
    const [userCreate, setUserCreate] = useState();
    const [listUserCreate, setListUserCreate] = useState([]);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [siteIds, setSiteIds] = useState([]);
    const [coporationIds, setCoporationIds] = useState([]);
    const [listCoporations, setListCoporations] = useState([]);

    useEffect(() => {
        getListEmployee();
    }, []);

    useEffect(() => {
        getList();
    }, [page, size]);

    useEffect(() => {
        if (advancedSearch) getListCoporations();
        else {
            setCoporationIds([]);
            setSiteIds([]);
        }
    }, [advancedSearch]);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        ...(siteIds && siteIds.length == 1 ? [] : [{
            title: 'Chi nhánh',
            dataIndex: 'siteName',
            key: 'siteName',
            align: 'left',
            width: 150,
        }]),
        {
            title: 'Tên KH',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 200,
        },
        {
            title: 'Nguồn KH',
            dataIndex: 'coporationName',
            key: 'coporationName',
            align: 'left',
            width: 180,
        },
        {
            title: 'Nhân viên đặt hẹn',
            dataIndex: 'userCreate',
            key: 'userCreate',
            align: 'left',
            width: 200,
        },
        {
            title: 'Nhân viên tư vấn',
            dataIndex: 'consultant',
            key: 'consultant',
            align: 'left',
            width: 160,
        },
        {
            title: 'Biên lai',
            dataIndex: 'billNo',
            key: 'billNo',
            align: 'center',
            width: 110,
        },
        {
            title: 'Ngày lập',
            dataIndex: 'billDate',
            key: 'billDate',
            align: 'right',
            width: 110,
            render: (val) => {
                return moment(val).format(FORMAT_DATE);
            }
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'paymentType',
            key: 'paymentType',
            align: 'left',
            width: 120,
        },
        {
            title: 'Dịch vụ',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'left',
            width: 150,
            render: (val, record) => {
                return <span>{record.comboName && record.comboName != "" ? <Fragment>{val}<Tag className='ml-1' color='green'>{record.comboName}</Tag></Fragment> : val}</span>
            }
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 80,
        },
        {
            title: 'Đơn giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            width: 80,
            render: (val) => {
                return val.toLocaleString("en-US");
            }
        },
        {
            title: 'Thành tiền',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 80,
            render: (val) => {
                return val.toLocaleString("en-US");
            }
        },
        {
            title: 'Miễn giảm',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            width: 80,
            render: (val) => {
                return val.toLocaleString("en-US");
            }
        },
        {
            title: 'Thực thu',
            dataIndex: 'revenue',
            key: 'revenue',
            align: 'right',
            width: 80,
            render: (val) => {
                return val.toLocaleString("en-US");
            }
        },
    ];

    const getListEmployee = () => {
        AppointmentService.ListUserCreate().then(result => {
            if (result.isSuccess) {
                setListUserCreate(result.data);
            }
        }).catch(err => {
            _notify.current && _notify.current.Show('Đã xảy ra lỗi tải dữ liệu nhân viên.', NotifyStatus.Error);
        })
    }

    const getList = (p = page) => {
        if (dateFrom > dateTo) {
            _notify.current && _notify.current.Show('Ngày lập phiếu từ phải nhỏ hơn ngày lập phiếu đến', NotifyStatus.Warning);
            return;
        }
        else _notify.current && _notify.current.Close();
        if (p != page) {
            setPage(p);
            return;
        }
        let filterModal = {
            skipCount: (p - 1) * size,
            maxResultCount: size,
            dateFrom,
            dateTo,
            userCreate,
            siteIds,
            coporationIds
        }
        setLoading(true);
        StatisticService.GetListBaoCaoNhanVienDatLich(filterModal)
            .then(res => {
                if (res.isSuccess) {
                    setListData(res.data.items);
                    setTotalRecords(res.data.totalCount);
                } else {
                    _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
                }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
                else _notify.current && _notify.current.Show(err, NotifyStatus.Error);
            }).finally(() => {
                setLoading(false);
            })
    }

    const getDownload = () => {
        _notify.current && _notify.current.Close();
        let filterModal = {
            dateFrom,
            dateTo,
            userCreate,
            getAll: true,
            siteIds,
            coporationIds
        }
        setLoading(true);
        StatisticService.ExportBaoCaoNhanVienDatLich(filterModal)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BaoCaoNhanVienDatLich`;
                    _hiddenLink.current.download = fileName;
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                openMessageDialog("Lỗi", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(err => {
                setLoading(false);
            })
    }

    const getListCoporations = () => {
        CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
            if (result.isSuccess) {
                setListCoporations(result.data);
            }
        }).catch(err => { })
    }

    return (
        <div className="list-page containerChilderWrapper">
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink}></a>
            <HeaderModal title="Báo cáo nhân viên đặt lịch" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='row d-flex justify-content-between align-items-end'>
                        <div className='mt-2 col-md-4 col-sm-6 d-flex flex-column'>
                            <label className='mita-title'>Thời gian lập biên lai</label>
                            <div className="d-flex">
                                <DatePicker
                                    allowClear={false}
                                    locale={viVN}
                                    showNow={false}
                                    className="w-100"
                                    placeholder={"Từ ngày"}
                                    value={dateFrom}
                                    format={FORMAT_DATE}
                                    onChange={(e) => {
                                        setDateFrom(e ? moment(e) : null);
                                    }}
                                />
                                <div className="m-auto">&#160;-&#160;</div>
                                <DatePicker
                                    allowClear={false}
                                    locale={viVN}
                                    showNow={false}
                                    className="w-100"
                                    placeholder={"Đến ngày"}
                                    value={dateTo}
                                    format={FORMAT_DATE}
                                    onChange={(e) => {
                                        setDateTo(e ? moment(e) : null);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mt-2 col-md-4 col-sm-6 d-flex flex-column'>
                            <label className='mita-title'>Nhân viên đặt hẹn</label>
                            <Select
                                allowClear
                                className="w-100"
                                showSearch
                                placeholder="Chọn nhân viên đặt hẹn"
                                value={userCreate}
                                onChange={(value) => { setUserCreate(value); }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {(listUserCreate ?? []).map((record, index) => {
                                    return (
                                        <Select.Option key={`${index}-${record.value}`} value={Number(record.value)}>{record.label}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className='mt-2 col-md-4 col-sm-6 d-flex justify-content-end'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { getList(1) }}><i className='fas fa-search mr-1' />Tìm</button>
                            <button className={`${BUTTON.THEME} ml-2`} onClick={() => { getDownload() }}>Xuất excel</button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2'>
                            <a href='#' onClick={() => setAdvancedSearch(!advancedSearch)}>{advancedSearch ? "Ẩn tìm kiếm mở rộng" : "Tìm kiếm mở rộng"}</a>
                        </div>
                    </div>
                    {advancedSearch &&
                        <div className='row'>
                            <SiteFilter col={6} updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
                            <div className='col-md-6 col-sm-12 mt-2'>
                                <label className='mita-title'>Nguồn khách hàng</label>
                                <Select
                                    className='w-100'
                                    allowClear
                                    showSearch mode='multiple'
                                    placeholder="Chọn nguồn khách hàng"
                                    value={coporationIds}
                                    onChange={(e) => { setCoporationIds(e) }}
                                    onClear={(e) => setCoporationIds(null)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {(listCoporations ?? []).map((record, index) => {
                                        return (
                                            <Select.Option key={record.ma} value={record.ma}>{record.ten}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                    }
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.id}
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size) }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size) }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                        pageSizeOptions={[10, 20, 30, 50, 100, 200, 300, 500]}
                        defaultPageSize={defaultPageSize}
                    />
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(BaoCaoNhanVienDatLich)