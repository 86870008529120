import { Button, Form, Input, Pagination, Switch, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as RoleService from '../../services/roleService';
export default function ListController() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListController()
    }, [currentPage, size]);


    const GetListController = () => {
        Actions.setLoading(true);
        RoleService.GetListController(key, currentPage, size, false).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const UpdateNewController = () => {
        Actions.setLoading(true);
        RoleService.UpdateNewController().then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhập thành công' })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const updateAlways = (id, check) => {
        RoleService.UpdateAlwaysController(id, check).then(result => {
            let data = dataSource;
            let item = data.find(x => x.id === id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.always === true ? false : true;
                    item.always = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.always;
                    item.always = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            setDataSource(data.map(x => ({ ...x })));
        }).catch(err => {
            let data = dataSource;
            let item = data.find(x => x.id === id);
            if (item !== undefined) {
                const newcheck = item.always;
                item.always = newcheck;
            }
            setDataSource(data.map(x => ({ ...x })));
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    const columns = [
        {
            title: 'Controller',
            dataIndex: 'controller',
            key: 'Controller',
            width: 120
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'Action',
            width: 180
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'Method',
            width: 80,
        },
        {
            title: 'Dùng chung',
            align: 'center',
            width: 80,
            render: (row) => {
                return (
                    <Switch checkedChildren="Có" unCheckedChildren="Không" checked={row.always === true}
                        onChange={(e) => {
                            updateAlways(row.id, e)
                        }} />
                )
            }
        }
    ];
    return (
        <>
            <div className='h4 text-center mb-2 font-bold text-uppercase'>Danh sách Controller</div>
            <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item label="Tìm theo tên:">
                        <Input
                            value={key}
                            placeholder="Nhập từ khóa"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-success"
                            onClick={() => { UpdateNewController() }}
                        >Update controller</Button>
                    </Form.Item>
                </div>
            </div>
            <div className="table-responsive">
                <Table dataSource={dataSource}
                    scroll={{ y: '65vh', x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index} />
            </div>
            <Pagination
                current={currentPage}
                size={size}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page) }}
                onShowSizeChange={(page, size) => { setSize(size) }}
                showSizeChanger={true}
            />
        </>
    )

}