import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, useCallback } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Button, DatePicker, Input, Radio, Checkbox, Select, Table, Tooltip, notification, Alert, Modal, Popconfirm, Divider, Upload } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../configureHIS/common/constants';
import * as Actions from '../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';
import * as DistributeServices from '../dieuphoi/distributeservices';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as SystemConfig from "../../configure/systemConfig";
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import * as CollaboratorsServices from '../nhomctv/nhomctvservices';
import * as UrlConfig from '../../configure/urlConfig';
import * as ChiDinhService from '../../services/chiDinhService';
import AddOrder from './addOrder';
import SelectConsultant from '../../components/MDCLS/selectConsultant';
import * as PatientService from '../../services/patientService';
import { isNullOrEmpty } from '../../libs/util';
import ConsultantWarning from '../recept/register/consultantWarning';

const { Option } = Select;
const { TextArea } = Input;

const ChiTietLichLayMauTaiNha = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        getListCollaborators();
        getListCollaboratorsGroup();
    }, []);

    useEffect(() => {
        getDetail(props.selectedAppointment);
    }, [props.selectedAppointment]);

    const _nguonKhachHang = useRef();
    const _orderRef = useRef();

    const [groupCTV, setGroupCTV] = useState(props.groupCTV);
    const [inEditMode, setInEditMode] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(props.selectedAppointment);
    const [fileListA, setFileListA] = useState([]);
    const [fileListB, setFileListB] = useState([]);
    const [maCTV, setMaCTV] = useState("");
    const [newAddress, setNewAddress] = useState("");
    const [dataOrder, setDataOrder] = useState([]);
    const [maBN, setMaBN] = useState("");
    const [ngayHen, setNgayHen] = useState(moment());
    const [listCTV, setListCTV] = useState([]);
    const [getType, setGetType] = useState(1);
    const [nhomCTV, setNhomCTV] = useState("all");
    const [listNhomCTV, setListNhomCTV] = useState([]);
    const [chiNhanh, setChiNhanh] = useState(props.chiNhanh);
    const [vatInvoice, setVatInvoice] = useState(false);
    const [ghiChu, setGhiChu] = useState("");
    const [managementID, setManagementID] = useState("");
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [idGiaLayMau, setIdGiaLayMau] = useState(0);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [bangGia, setBangGia] = useState('default');
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedPatient, setSelectedPatient] = useState({});
    const [isCheckIn, setIsCheckIn] = useState(false);
    const [isGetSample, setIsGetSample] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [appointmentId, setAppointmentId] = useState();
    const [gioCheckIn, setGioCheckIn] = useState(moment());
    const [gioNhanMau, setGioNhanMau] = useState(moment());
    const [diaDiem, setDiaDiem] = useState("");
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [payConfirm, setPayConfirm] = useState(false);
    const [createBy, setCreateBy] = useState();
    const [createAt, setCreateAt] = useState();
    const [createFrom, setCreateFrom] = useState();
    const [disableCoporation, setDisableCoporation] = useState(false);

    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    const [modalSelectConsultant, setModalSelectConsultant] = useState(false);

    const onCloseModal = () => {
        _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.resetState();
        setInEditMode(false);
        setMaCTV(null);
        setGetType(1);
        setNhomCTV('all');
        setCreateBy(null);
        setCreateAt(null);
        setCreateFrom(null);
        props.onCancel && props.onCancel();
    }

    const getDetail = (appointment) => {
        AppointmentService.ScheduleGetSampleByPatientID(appointment.id, appointment.chinhanh).then(result => {
            if (result.isSuccess) {
                const data = result.item;
                setSelectedDate(data.ngayhen);
                setSelectedPatient(data);
                setSelectedAppointment(appointment);
                setMaCTV((data.mactv || data.mactv !== "0") ? data.mactv : '');
                setIsCheckIn(moment(data.giocheckin).year() !== 1);
                setIsGetSample(moment(data.gionhanmau).year() !== 1);
                setIsCompleted(data.dalaymau === 1);
                setAppointmentId(appointment.id);
                setGhiChu(data.ghichu);
                setMaBN(data.mabn);
                setGioCheckIn(moment(data.giocheckin).year() !== 1 ? moment(data.giocheckin).format(SystemConfig.FORMAT_TIME_DATE) : "");
                setGioNhanMau(moment(data.gionhanmau).year() !== 1 ? moment(data.gionhanmau).format(SystemConfig.FORMAT_TIME_DATE) : "");
                setDiaDiem(data.diachicheckin ? data.diachicheckin : "");
                setLat(data.lat);
                setLng(data.lng);
                setIdGiaLayMau(data.idGiaLayMau);
                setNewAddress(data.diachi);
                setVatInvoice(data.vatinvoice);
                setPayConfirm(data.payconfirm);
                setCreateBy(data.createBy);
                setCreateAt(data.createAt);
                setCreateFrom(data.createFrom);
                setDisableCoporation(data.dalaymau === 1);
                setManagementID(data.maql);
                let imageList = [];
                (data.image !== null && data.image.length > 0) && data.image.forEach(image => {
                    imageList.push({
                        uid: image.id,
                        url: getUrlPreview(image.filepath),
                        name: image.filename,
                        order: image.sort
                    });
                });
                setFileListA(imageList.filter(x => x.order === 1)); // Hình ảnh trước khi lấy mẫu
                setFileListB(imageList.filter(x => x.order === 2));  // Hình ảnh sau khi lấy mẫu
            } else Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
        getListOrderById(appointment.id, appointment.chinhanh);
    }

    const getListOrderById = (id, chiNhanh) => {
        Actions.setLoading(true);
        AppointmentService.GetListOrderById(id, chiNhanh).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                for (let item of data)
                    item.listChild = item.listChildCd;
                setDataOrder(data);
            } else Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        }).finally(() => { Actions.setLoading(false) })
    }

    const getUrlPreview = (path) => {
        let url = UrlConfig.BASE_URL + path;
        url = url.replace("\\", "/").replace("/api/wwwroot", "");
        return url;
    }

    const checkBangGia = (info) => {
        let banggia = bangGia;
        let itemFound = dataOrder.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && info.banggia !== banggia) {
            if (dataOrder && dataOrder.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                cancelExam(itemFound.maql);
                setDataOrder([]);
                _orderRef && _orderRef.current && _orderRef.current.UpdateSelectedFromProps([]);
                banggia = info.banggia;
            }
            setBangGia(banggia);
        } else setBangGia(info.banggia);
    }

    const disabledDate = (current) => {
        return current.valueOf() < Date.now();
    }

    const getListCollaborators = (type = getType, group = nhomCTV, chiNhanh) => {
        var getAll = type === 1 ? true : false;
        if (!getAll) {
            var date = moment(ngayHen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, true, group, chiNhanh).then(res => {
                if (res.isSuccess) {
                    setListCTV(res.data);
                }
            }).catch(() => { })
        }
        else {
            CommonService.GetListCollaborators(1, 10, false, group, chiNhanh).then(res => {
                if (res.isSuccess) {
                    setListCTV(res.data);
                }
            }).catch(() => { })
        }
    }
    const getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                setListNhomCTV(res.data);
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => { })
    }

    let dataSource = dataOrder.map(function (e, index) {
        let o = Object.assign({}, e);
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    })

    const regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title:
                <div>
                    <span className={props.consultantRequired && "required-field"}>NV tư vấn</span>
                    {inEditMode &&
                        <Tooltip title='Cập nhật nhân viên tư vấn'>
                            <Button type='link' style={{ color: 'white' }}
                                className='pl-2'
                                onClick={() => { setModalSelectConsultant(true) }}
                            ><i className='fa fa-pen' />
                            </Button>
                        </Tooltip>}
                </div>,
            dataIndex: 'consultantName',
            key: 'consultantName',
            width: 130,
            render: (text, record, index) => {
                return record.consultantName
            },
        },
        {
            title: 'Giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        ...(!inEditMode ? [] : [{
            title: '',
            align: 'center',
            width: '10%',
            render: (text, row, index) => {
                return (
                    row.isChild ? "" : (
                        <div style={{ cursor: 'pointer' }}
                            className="text-danger"
                            disabled={row.status === 'Đã đóng tiền'}
                            onClick={() => {
                                xoaChiDinh(row, index)
                            }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </div>
                    )
                )
            }
        }])
    ];

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handleChangeA = ({ fileList }) => {
        setFileListA(fileList);
    }

    const handleChangeB = ({ fileList }) => {
        setFileListB(fileList);
    }

    const updateLichHenLayMau = (event = "") => {
        //event:
        //1. checkin: cập nhật thời gian check in.
        //2. getsample: cập nhật thời gian lấy mẫu (Hình ảnh chụp mẫu máu sẽ lưu ở bước này).
        //3. sendsample: cập nhật thời gian giao mẫu.
        var data = {};
        const id = appointmentId;
        if (id === null || id === undefined)
            notification.warning({ message: "Chưa chọn lịch hẹn" })
        if (event === "") {
            data = {
                ngayhen: selectedDate,
                mactv: maCTV
            };
        } else {
            data = {
                ghichu: ghiChu,
                vatinvoice: vatInvoice,
                payconfirm: payConfirm
            }
        }
        AppointmentService.updateLichHenLayMau(id, event, data, selectedAppointment.chinhanh).then((result) => {
            if (result.isSuccess) {
                submitUploadFiles();
                _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.updateInfo();
                getDetail(selectedAppointment);
                setInEditMode(false);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi ", err);
        })
    }

    const submitUploadFiles = () => {
        let formdata = new FormData();
        fileListA.forEach(element => {
            formdata.append('listFileA', element.originFileObj);
        });
        fileListB.forEach(element => {
            formdata.append('listFileB', element.originFileObj);
        });
        AppointmentService.uploadHinhAnhMau(formdata, appointmentId).then(() => {
            notification.success({ message: "Đã cập nhật!" });
            getDetail(selectedAppointment);
        }).catch(() => {
            notification.error({ message: "Không thể upload ảnh!" });
        })
    }

    const deleteLichHenLayMau = () => {
        const id = appointmentId;
        AppointmentService.deleteLichHenLayMau(id, selectedAppointment.chinhanh).then((result) => {
            if (result.isSuccess) {
                notification.success({ message: "Đã xóa lịch hẹn thành công" });
                reloadDataProps();
            } else Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const reloadDataProps = () => {
        props.reload && props.reload();
        setInEditMode(false);
    }

    const xoaChiDinh = (row) => {
        Actions.setLoading(true);
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                getListOrderById(appointmentId, selectedPatient.chinhanh)
            } else Actions.openMessageDialog('Lỗi', result.err.msgString);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        }).finally(() => Actions.setLoading(false))
    }

    const handleRemove = (id) => {
        groupCTV &&
            AppointmentService.deleteHinhAnhMau(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Xóa thành công' });
                }
            }).catch(err => { })
    }

    const capNhatChiTietLichHenLayMau = () => {
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            Actions.openMessageDialog("Thông báo", "Bắt buộc chọn nguồn khách hàng");
            return;
        }
        let isNullConsultant = dataOrder.some(x => isNullOrEmpty(x.consultantId));
        if (props.consultantRequired && isNullConsultant) {
            Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
            return;
        }
        if (validator.current.allValid()) {
            Actions.setLoading(true);
            const id = appointmentId;
            if (id === null || id === undefined)
                notification.warning({ message: "Chưa chọn lịch hẹn" })
            const data = {
                ngayhen: selectedDate,
                mactv: maCTV,
                mabn: maBN,
                newAddress: newAddress,
                idGiaLayMau: idGiaLayMau,
                ghichu: ghiChu,
                vatinvoice: vatInvoice,
                payconfirm: payConfirm,
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            };
            AppointmentService.CapNhatChiTietLichHenLayMau(id, data, selectedAppointment.chinhanh).then((result) => {
                if (result.isSuccess) {
                    reloadDataProps();
                    _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.updateInfo();
                    notification.success({ message: "Cập nhật thành công" })
                } else Actions.openMessageDialog(result.err.msgCode ?? "Lỗi", result.err.msgString);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            }).finally(() => Actions.setLoading(false))
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const onUpdateConsultant = (newData) => {
        let listSelectedServices = [...dataOrder];
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });

        let model = listSelectedServices.map(x => {
            return { siteId: parseInt(x.chinhanh), NhanVienId: x.consultantId, idChiDinh: x.idChiDinh }
        });

        Actions.setLoading(true);
        ChiDinhService.updateConsultantIndication(model).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công." });
                setDataOrder(listSelectedServices);
                setModalSelectConsultant(false);
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            if (err.stack) {
                notification.error({ message: "Lỗi javascript sau khi lưu. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
                return;
            }
            notification.error({ message: "Lưu không thành công. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
            Actions.setLoading(false);
        })
    }

    const cancelExam = (managementID) => {
        let clrCopporator = false;
        Actions.setLoading(true);
        PatientService.cancelExam(managementID, clrCopporator).then((respone) => {
            if (respone.isSuccess) {
                getListOrderById(appointmentId, selectedPatient.chinhanh)
            } else Actions.openMessageDialog("Lỗi", respone.err.msgString);
        }).finally(() => Actions.setLoading(false))
    }

    return (
        <div>
            <HeaderModal title={`${inEditMode ? "Cập nhật " : "Chi tiết "} lịch hẹn lấy mẫu tại nhà`}
                onClose={() => { onCloseModal() }} />
            {createFrom && createBy && createAt &&
                <Alert type='info'
                    message={
                        <div className='text-danger text-center'>Được tạo từ <b className='text-uppercase'>{createFrom}</b> bởi <b className='text-uppercase'>{createBy}</b> vào ngày <b>{createAt}</b></div>}>
                </Alert>}
            <div className="row mt-2">
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:&nbsp;</span><b>{selectedAppointment.tenbn}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:&nbsp;</span><b>{selectedAppointment.mabn}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:&nbsp;</span><b>{selectedPatient.onlyyear ? "Không ghi nhận" : moment(selectedPatient.ngaysinh).format("DD-MM")}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:&nbsp;</span><b>{moment(selectedPatient.ngaysinh).format("YYYY")}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:&nbsp;</span><b>{selectedPatient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND/CCCD:&nbsp;</span> <b>{selectedPatient.cmnd}</b></div>
                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:&nbsp;</span><b>{selectedPatient.dienthoai}</b></div>
            </div>
            <Alert type='warning'
                message={
                    <div className='row'>
                        {/* <div className="col-md-12"><i>* Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div> */}
                        <div className="col-md-12"><i className='text-danger'>* Việc thay đổi nguồn khách hàng <strong>sẽ hủy toàn bộ chỉ định</strong> ngay lập tức mà <b>không thể hoàn tác</b></i></div>
                    </div>
                }
            ></Alert>
            <div className='row'>
                <Nguonkhachhang ref={_nguonKhachHang} managermentId={managementID} updateInfoBangGia={(info) => { checkBangGia(info) }} disable={disableCoporation} />
            </div>
            <div className='row'>
                {selectedPatient.ghichuHotline && <div className="col-md-12 mb-2">
                    <label>Ghi chú <i>(Từ hotline)</i></label>
                    <TextArea rows={3} value={selectedPatient.ghichuHotline} readOnly={true}></TextArea>
                </div>}
                <div className="col-md-12 mb-2"><label>Địa chỉ lấy mẫu</label>
                    <Input className='w-100' disabled={!inEditMode} value={newAddress} onChange={(e) => setNewAddress(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Thời gian</label>
                    <DatePicker locale={viVN} className='w-100' allowClear={false} showTime format={FORMAT_DATE_TIME}
                        onChange={(e) => { setSelectedDate(e) }} value={moment(selectedDate)}
                        disabledDate={disabledDate} disabled={!inEditMode} placeholder={FORMAT_DATE_TIME} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Nhóm cộng tác viên</label>
                    <Select
                        className='w-100'
                        value={nhomCTV}
                        placeholder="Chọn nhóm cộng tác viên" disabled={!inEditMode}
                        onChange={(e) => { setNhomCTV(e); setMaCTV(null); getListCollaborators(getType, e); }}>
                        <Option value="all">Tất cả nhóm</Option>
                        {listNhomCTV && listNhomCTV.map((row, index) => {
                            return (
                                <Option key={index} value={row.label}>{row.label}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-12 mb-2">
                    <label>
                        <Radio.Group disabled={!inEditMode} onChange={(e) => { setGetType(e.target.value); setMaCTV(null); getListCollaborators(e.target.value, nhomCTV); }} value={getType}>
                            <Radio value={1}>Tất cả CTV</Radio>
                            {IsActive_FeatureLCTV() &&
                                <Radio value={2}>CTV đã đăng ký lịch</Radio>
                            }
                        </Radio.Group>
                    </label>
                    <Select
                        style={{ width: '100%' }}
                        disabled={!inEditMode}
                        value={maCTV}
                        placeholder="Chọn cộng tác viên"
                        allowClear={false}
                        onChange={(e) => { setMaCTV(e) }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {listCTV && listCTV.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                    {validator.current.message("Cộng tác viên", maCTV, "selection")}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <Checkbox
                        disabled={!inEditMode}
                        checked={vatInvoice}
                        onChange={() => setVatInvoice(!vatInvoice)}
                    >Xuất hóa đơn đỏ</Checkbox>
                </div>
                <div className="col-md-6 mb-2">
                    <Checkbox
                        disabled={!inEditMode}
                        checked={payConfirm}
                        onChange={() => setPayConfirm(!payConfirm)}
                    >CTV xác nhận đã thanh toán</Checkbox>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-2">
                    <label>Ghi chú</label>
                    <Input disabled={isCompleted || !inEditMode} placeholder="Nhập ghi chú" value={ghiChu}
                        onChange={(e) => setGhiChu(e.target.value)} />
                </div>
                <div className="col-12 mb-2">
                    <div className="border-register">
                        <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                            <div className="h4 font-weight-bold">Chỉ định
                                ({dataOrder ? dataOrder.reduce((tmp, cur) =>
                                    tmp + Number(cur.dongia), 0).toLocaleString('en-US') : '0'})đ</div>
                            <Button className="btn btn-color ml-auto"
                                disabled={!inEditMode} onClick={e => {
                                    e.stopPropagation();
                                    setVisibleOrder(true);
                                }}>Thêm chỉ định</Button>
                        </div>
                        <div class="collapse" id="chidinh">
                            <div className="table-responsive">
                                <Table dataSource={dataSource}
                                    rowKey={(e) => e.id}
                                    columns={regiterServiceColumn}
                                    pagination={false}
                                    scroll={{ y: 0 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {diaDiem &&
                    <React.Fragment>
                        <div className="col-md-12 d-flex mb-2"><span>Địa chỉ check-in:&nbsp;</span><b>{diaDiem}</b></div>
                        <div className="col-12 mb-2">
                            <Button className="btn btn-color" type="button" data-toggle="collapse" data-target="#collapseExample">Xem bản đồ</Button>
                            <div class="collapse" id="collapseExample">
                                <div className="container-iframe-adsress">
                                    <iframe className="responsive-iframe-adsress" title="Vị trí" src={`https://maps.google.com?q=${lat},${lng}&output=embed`} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
            {isCheckIn &&
                <div>
                    <Divider orientation="center">Hình ảnh</Divider>
                    {!isCompleted || (isCompleted && (fileListA.length > 0 || fileListA.length > 0)) ?
                        <div className="row" style={{ display: "flex", flexDirection: "row" }}>
                            <div className="col-md-12">
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    multiple={true}
                                    fileList={fileListA}
                                    onPreview={handlePreview}
                                    onChange={handleChangeA}
                                    disabled={isCompleted || !groupCTV}
                                    onRemove={img => handleRemove(img.uid)}
                                    beforeUpload={() => false}
                                >
                                    {isCompleted || !groupCTV ? null :
                                        <div>
                                            <i className="fa fa-upload" />
                                            <div className='mt-2'>Chọn ảnh</div>
                                        </div>}
                                </Upload>
                            </div>
                        </div>
                        : <p>Chưa thêm dữ liệu hình ảnh</p>}
                </div>}
            <div className='custom-hr'></div>
            <ConsultantWarning visible={dataOrder.length > 0 && dataOrder.some(x => isNullOrEmpty(x.consultantId))} />
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                {!isCompleted &&
                    <div>
                        {!isGetSample && groupCTV ?
                            <Popconfirm
                                title={isCheckIn ? "Xác nhận đã lấy mẫu máu?" : "Xác nhận đến nơi lấy mẫu?"}
                                onConfirm={() => { isCheckIn ? updateLichHenLayMau("getsample") : updateLichHenLayMau("checkin") }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Tooltip title={moment(selectedDate) > moment() && "Bạn đang thực thực hiện thao tác sớm hơn thời gian hẹn"}>
                                    <button className="btn btn-color" style={{ marginRight: 10 }}><i style={{ marginRight: 5 }} className={`fa fa-${isCheckIn ? "save" : "map-marker-alt"}`}>
                                    </i>{isCheckIn ? "Hoàn tất lấy mẫu" : "Check-in"}
                                    </button>
                                </Tooltip>
                            </Popconfirm>
                            :
                            !groupCTV ?
                                isGetSample &&
                                <Popconfirm
                                    title="Đây là lần chỉnh sửa cuối cùng, bạn xác nhận CTV đã gửi mẫu?"
                                    onConfirm={() => { updateLichHenLayMau("sendsample") }}
                                    okText="Đồng ý" cancelText="Hủy" disabled={!isGetSample}>
                                    <button style={{ display: isCompleted ? "none" : "initial", marginRight: 10 }} className="btn btn-color">
                                        <i className="mr-1 fa fa-save" />Tiếp nhận mẫu
                                    </button>
                                </Popconfirm>
                                :
                                <button style={{ display: isCompleted ? "none" : "initial", marginRight: 10 }}
                                    className="btn btn-color" onClick={() => { updateLichHenLayMau("getsample") }}>
                                    <i className="mr-1 fa fa-save"></i>Lưu thay đổi
                                </button>
                        }
                    </div>
                }
                {!groupCTV && !isCompleted && isCheckIn === false &&
                    <div>
                        {inEditMode === false ?
                            <button className="btn btn-color" style={{ marginRight: 10 }} onClick={() => setInEditMode(true)}><i className="mr-1 fa fa-edit"></i>Cập nhật</button>
                            :
                            <button className="btn btn-color" style={{ marginRight: 10 }} onClick={() => capNhatChiTietLichHenLayMau()}><i className="mr-1 fa fa-save"></i>Lưu</button>
                        }
                        <Popconfirm
                            title="Bạn có chắc muốn xóa lịch hẹn này không?"
                            onConfirm={() => { deleteLichHenLayMau() }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginRight: 10 }}>
                                <i className="mr-1 fa fa-trash" />Xóa</button>
                        </Popconfirm>
                    </div>}
                <button className={BUTTON.DANGER}
                    onClick={() => {
                        onCloseModal();
                    }}>
                    <i className="mr-1 fas fa-sign-out-alt" />Đóng
                </button>
            </div>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>{previewTitle}</span>
                    <span onClick={() => setPreviewVisible(false)}><i className="fas fa-times" style={{ fontSize: 24, color: "brown" }} /></span>
                </div>
                <br />
                <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {visibleOrder &&
                <Modal
                    width={1400}
                    visible={visibleOrder}
                    open={visibleOrder}
                    onCancel={() => setVisibleOrder(false)}>
                    <AddOrder selectedPatient={selectedPatient} chinhanh={chiNhanh}
                        reLoad={() => {
                            getListOrderById(appointmentId, selectedPatient.chinhanh)
                        }}
                        onClose={() => { setVisibleOrder(false); }}
                        banggia={bangGia} />
                </Modal>
            }
            <Modal
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                visible={modalSelectConsultant}
                destroyOnClose={true}
                width={1000}>
                <SelectConsultant
                    isDialog={true}
                    dataSource={dataOrder.map(x => ({ ...x }))}
                    onClose={() => { setModalSelectConsultant(false) }}
                    onSubmit={(values) => {
                        onUpdateConsultant(values);
                        setModalSelectConsultant(false);
                    }}
                />
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
		coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
		allowToChangeServicePriceList: state.global.sysConfig.allowToChangeServicePriceList ? state.global.sysConfig.allowToChangeServicePriceList : false,
        consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ChiTietLichLayMauTaiNha)