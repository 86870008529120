import * as ApiCaller from '../libs/httpRequests';

const prefix = 'InHospital';

export function DanhSachNoiTru(makp, key, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachNoiTru?makp=${makp}&key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListDateBenhTrinh(mabn, mavaovien, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListDateBenhTrinh?mabn=${mabn}&mavaovien=${mavaovien}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetBenhTrinh(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetBenhTrinh?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateBenhTrinh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateBenhTrinh`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuocNoiTruTheoBenhTrinh(idbenhtrinh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuocNoiTruTheoBenhTrinh?idbenhtrinh=${idbenhtrinh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuocNoiTruTheoNgay(mabn, mavaovien, dateFrom, dateTo, isOld = false, keySearch = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuocNoiTruTheoNgay?mabn=${mabn}&mavaovien=${mavaovien}&dateFrom=${dateFrom}&dateTo=${dateTo}&isOld=${isOld}&keySearch=${keySearch}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CopyBenhTrinh(idbenhtrinh, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CopyBenhTrinh?idbenhtrinh=${idbenhtrinh}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateSoChamSoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateSoChamSoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListSoChamSocByEnterCode(mavaovien) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSoChamSocByEnterCode?mavaovien=${mavaovien}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateGiuongBenh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateGiuongBenh`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListGiuongBenhByEnterCode(mavaovien) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListGiuongBenhByEnterCode?mavaovien=${mavaovien}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateDeleteGiuongBenh(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateDeleteGiuongBenh?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListXetNghiem(mavaovien, mabn, dateFrom, dateTo, key = '', isOld = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListXetNghiem?mavaovien=${mavaovien}&mabn=${mabn}&dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&isOld=${isOld}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinCDHATheoBenhTrinh(mavaovien, mabn, dateFrom, dateTo, key = '', isOld = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinCDHATheoBenhTrinh?mavaovien=${mavaovien}&mabn=${mabn}&dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&isOld=${isOld}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinDongYBenhNhanTheoBenhTrinh(mavaovien, mabn, dateFrom, dateTo,thuthuat, key = '', isOld = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinDongYBenhNhanTheoBenhTrinh?mavaovien=${mavaovien}&mabn=${mabn}&dateFrom=${dateFrom}&dateTo=${dateTo}&thuthuat=${thuthuat}&key=${key}&isOld=${isOld}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CopyThuocNoiTruTheoNgay(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CopyThuocNoiTruTheoNgay`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function EditThuocNoiTruTheoNgay(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/EditThuocNoiTruTheoNgay`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteThuocNoiTruTheoNgay(idcapthuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DeleteThuocNoiTruTheoNgay?idcapthuoc=${idcapthuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListTongHopLanhThuoc(makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTongHopLanhThuoc?makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListTrangThaiPhieuThuoc(isKhoaDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTrangThaiPhieuThuoc?isKhoaDuoc=${isKhoaDuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListTrangThaiPhieuTraThuoc(isKhoaDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTrangThaiPhieuTraThuoc?isKhoaDuoc=${isKhoaDuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreatePhieuThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreatePhieuThuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListPhieuLinhThuoc(key, status, dateFrom, dateTo, isKhoaDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPhieuLinhThuoc?key=${key}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&isKhoaDuoc=${isKhoaDuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function GetListPhieuTraThuoc(key, status, dateFrom, dateTo, isKhoaDuoc = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPhieuTraThuoc?key=${key}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&isKhoaDuoc=${isKhoaDuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailPhieuLinhThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDetailPhieuLinhThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailPhieuTraThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDetailPhieuTraThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function UpdateTrangThaiPhieuLanhThuoc(id, trangthai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateTrangThaiPhieuLanhThuoc?id=${id}&trangthai=${trangthai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function UpdateTrangThaiPhieuTraThuoc(id, trangthai) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateTrangThaiPhieuTraThuoc?id=${id}&trangthai=${trangthai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateNgayPhieuLanhThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateNgayPhieuLanhThuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeletePhieuLanhThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DeletePhieuLanhThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeletePhieuTraThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DeletePhieuTraThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XacNhanPhieuLanhThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/XacNhanPhieuLanhThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function GetListMedic(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListMedic?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function DanhSachCanTraThuoc(type, key, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanTraThuoc?type=${type}&key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreatePhieuTraThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreatePhieuTraThuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdatePhieuTraThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdatePhieuTraThuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XacNhanPhieuTraThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/XacNhanPhieuTraThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListPaymentInPatient(key = '', page = 1, size = 20) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPaymentInPatient?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailPaymentInPatient(mabn, mavaovien, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDetailPaymentInPatient?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListThanhToanNoiTru(mabn, mavaovien, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListThanhToanNoiTru?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuThanhToanNoiTru(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuThanhToanNoiTru`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailPaymentInPatientPaid(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDetailPaymentInPatientPaid?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoanTraTamUng(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuHoanTraTamUng`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListHoanTraTamUng(mabn, mavaovien, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListHoanTraTamUng?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinThucHienVienPhi(mabn, mavaovien, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinThucHienVienPhi?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteThanhToanNoiTru(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteThanhToanNoiTru?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteHoanTraTamUng(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteHoanTraTamUng?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListThanhToanNoiTruByDate(dateFrom, dateTo, key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListThanhToanNoiTruByDate?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListHoanTraTamUngByDate(dateFrom, dateTo, key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListHoanTraTamUngByDate?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinPTTTbyIdBenhTrinh(idbenhtrinh,thuthuat) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinPTTTbyIdBenhTrinh?idbenhtrinh=${idbenhtrinh}&thuthuat=${thuthuat}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};