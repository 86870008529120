import { Select } from 'antd';
import React, { Component } from 'react';
const { Option } = Select;
let timeout;


export default class AutocompleteMulti extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            value: undefined,
            text: ''
        };
    }
    Search(value) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => {
            if (this.props.handleSearch) {
                this.props.handleSearch(value, this.props.title);
            }
        }, 500)
    }
    setValue(item) {
        if (this.props.getItem) {
            this.props.getItem(item)
        }
    }

    render() {
        let options = [];
        let values = (this.props.value !== undefined && this.props.value.length > 0)?this.props.value.map(x=>x.value):[];
        if (this.props.dataSource && this.props.dataSource.length > 0) {
            options = this.props.dataSource.map(d => <Option key={d.value}>{d.label}</Option>);
        }
        return (
            <Select
                // className='autocompleteMulti'
                placeholder={this.props.placeholder ?? ''}
                className={ 'autocompleteMulti ' + (this.props.className !== undefined ? this.props.className : '')}
                style={{ maxWidth: `${this.props.maxWidth !== undefined  ?  this.props.maxWidth : '100%'}` }}
                showSearch
                mode="multiple"
                filterOption={false}
                onSearch={(e) => { this.Search(e) }}
                onChange={(e, item) => { this.setValue(item) }}
                value={values}
                listHeight={500}
                getPopupContainer={trigger => trigger.parentNode}
                disabled={this.props.disabled ?? false}
            >
                {
                    this.props.dataSource && this.props.dataSource.length > 0 &&
                    this.props.dataSource.map(d => <Option key={d.value}>{d.label}</Option>)
                }
            </Select>
        );
    }

}