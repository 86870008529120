import React, { Component } from 'react';
import { DatePicker, Form, Pagination, InputNumber, Select, notification, Button, Table, Modal, Input, Popconfirm } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as KhoService from '../../services/KhoService';
import * as MedicineService from '../../services/medicineService';
import * as Actions from '../../libs/actions';
import * as PrintService from '../../services/printService';
import BanLeThuoc from './components/banledetails';
import BanLeEdit from './reciptbanle';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { RangePicker } = DatePicker;
const { Option } = Select;
const initialState = {
    makho: '',
    tungay: moment(),
    ngay: [moment(), moment()],
    denngay: moment(),
    ngaytao: moment(),
    visible: false,
    totalRecords: 0,
    totalRecords1: 0,
    key: '',
    sotoa: 0,
    mabn: '',
    hoten: ''
}
export default class ThuocBanLe extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKho: [],
            lstBanle: [],
            lstDetail: [],
            currentPage: 1,
            trangthai: '1',
            visibleEdit: false,
            tongtien: 0,
        };
    }
    componentWillMount() {
        this.loadKho();
        this.loadDanhSachBanLe();
    }
    notifyUpdate = () => {
        this.loadDanhSachBanLe();
    }
    loadKho() {
        KhoService.GetListKho().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value
                });
            }
            this.form.setFieldsValue({
                makho: result.data[0].value
            })
        }).catch(err => {
        })
    }
    loadDanhSachBanLe(page = 1, size = 10) {
        if (this.state.tungay === null || this.state.tungay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian từ ngày!' });
            return;
        }
        if (this.state.denngay === null || this.state.denngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian đến ngày!' });
            return;
        }
        MedicineService.DanhSachToaBanLe(this.state.key, Number(this.state.sotoa), this.state.tungay.format('YYYY-MM-DD'), this.state.denngay.format('YYYY-MM-DD'), page, size, this.state.trangthai).then(result => {
            if (result.isSuccess) {
                let tong = 0;
                result.data.map(x => tong += x.tongtien)
                this.setState({
                    lstBanle: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page,
                    tongtien: tong,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách bán lẻ bị lỗi' });
        })
    }
    thongtinHD(ngay, id, page = 1, size = 10) {
        MedicineService.ThongTinHoaDon(ngay, id, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.data,
                    totalRecords1: result.totalRecords
                });
            }
            else {
                this.setState({
                    lstNhap: []
                })
            }
        }).catch(err => {
        })
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalDetail = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visibleDetail: false
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleDetail: false,
        });
        this.loadDanhSachBanLe();
    };
    onClose = () => {
        this.setState({ visibleEdit: false })
    }
    PrintConclusion(mabn, id, sotoa, ngay) {
        PrintService.PrintConclusionBanLe(mabn, id, Number(sotoa), ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUse(id, ngay) {
        PrintService.PrintUseRetail(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    xoathuoc(row) {
        MedicineService.XoaToaBanLe(row.id, moment(row.ngaylapphieu).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.loadDanhSachBanLe();
                notification.success({ message: 'Xóa thành công!' });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    render() {
        let { id } = this.state;
        const columns = [
            {
                title: 'Ngày bán',
                dataIndex: 'ngaylapphieu',
                key: 'ngaylapphieu',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số toa',
                dataIndex: 'sotoa',
                key: 'sotoa',
                align: 'center',

            },
            {
                title: 'Mã bệnh nhân',
                dataIndex: 'mabn',
                key: 'mabn',
                align: 'center',
            },
            {
                title: 'Họ tên',
                dataIndex: 'hoten',
                key: 'hoten',
                align: 'left',

            },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                width: 50,
                render: (row, index) => {
                    return (
                        <Popconfirm
                            disabled={row.trangthai === 'Đã thanh toán'}
                            onClick={(e) => e.stopPropagation()}
                            title="Bạn có chắc muốn xóa đơn thuốc này?"
                            onConfirm={(e) => { e.stopPropagation(); this.xoathuoc(row) }}
                            onCancel={(e) => e.stopPropagation()}
                            okText="Đồng ý" cancelText="Hủy">
                            <Button disabled={row.trangthai === 'Đã thanh toán'} danger>Xóa</Button>
                        </Popconfirm>
                    )
                }
            }
        ];
        let dataSource = this.state.lstBanle.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columnsDetail1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'datennuocng',
                align: 'center',
            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
                align: 'left',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        let dataSource2 = this.state.lstDetail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: '20px' }}>
                        <div className="row no-gutters">
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <Form.Item>
                                    <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value, tungay: value[0], denngay: value[1] }, () => this.loadDanhSachBanLe()))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Kho:</label>
                                <Form.Item name="makho" rules={[{ required: true, message: 'Yêu cầu chọn kho!' }]}>
                                    <Select
                                        value={this.state.makho}
                                        showSearch
                                        onChange={(value) => { this.setState({ makho: value }) }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstKho.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Trạng thái:</label>
                                <Form.Item>
                                    <Select
                                        showSearch style={{ width: "150px" }}
                                        defaultValue='-----'
                                        value={this.state.trangthai}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(key) => { this.setState({ trangthai: key, visibleEdit: false, visibleDetail: false }, () => this.loadDanhSachBanLe()) }}>
                                        <Option key={0} value='1'>Mới</Option>
                                        <Option key={3} value='3'>Đã thanh toán</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Số toa:</label>
                                <Form.Item>
                                    <InputNumber placeholder="Nhập số toa"
                                        style={{ width: "150px" }}
                                        min={1}
                                        maxLength={5}
                                        onChange={(e) => this.setState({ sotoa: e })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.loadDanhSachBanLe();
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <Form.Item style={{ width: 230, marginRight: 20 }}>
                                    <Input placeholder="Nhập mã hoặc tên bệnh nhân..."
                                        onChange={(e) => this.setState({ key: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.loadDanhSachBanLe();
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <button className="btn btn-success" style={{ margin: '-5px 5px 0px 5px' }}
                                    onClick={() => this.loadDanhSachBanLe()}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                <button style={{ margin: '-5px 0px 0px 0px' }} className="btn btn-success"
                                    onClick={this.showModal}
                                > <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </div>
                        </div>
                        <div style={{ marginTop: 15 }} >
                            <div style={{ textAlign: 'right' }}>
                                <strong>
                                    Tổng tiền: {this.state.tongtien.toLocaleString('en-US')}
                                </strong>
                            </div>
                            <div className="table-responsive">
                                <Table dataSource={dataSource}
                                    pagination={false}
                                    scroll={{ x: 'max-content', y: 0 }}
                                    columns={columns} rowKey={(e, index) => index}
                                    onRow={(row, rowIndex) => {
                                        return {
                                            onClick: (e) => {
                                                this.setState({
                                                    id: row.id, detail: row, visibleDetail: true
                                                    , visibleEdit: true, ngaytao: row.ngaylapphieu,
                                                    mabn: row.mabn, hoten: row.hoten
                                                }); this.thongtinHD(row.ngaylapphieu, row.id);
                                            },
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        return "rowCustom"
                                    }}
                                />
                            </div>
                            <Pagination
                                current={this.state.currentPage}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.loadDanhSachBanLe(page, size) }}
                                onShowSizeChange={(page, size) => this.loadDanhSachBanLe(1, size)}
                                showSizeChanger={true}
                            />

                        </div>
                    </div>
                </Form>
                {this.state.visible === true && <Modal maskClosable={false}
                    //destroyOnClose={true}
                    width={1400}
                    visible={this.state.visible}
                    placement="top"
                    closable={false}
                    onClose={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    {this.state.visible === true &&
                        <BanLeThuoc
                            onClose={this.handleCancel}>
                        </BanLeThuoc>
                    }
                </Modal>}
                { this.state.visibleDetail && this.state.trangthai === 3 &&
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.visibleDetail}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}

                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ disabled: false }}
                        footer={[
                            (this.state.trangthai === '3') &&
                            <Button type="primary" style={{ marginLeft: "5px" }}
                                onClick={() => { this.PrintConclusion(this.state.detail.mabn, this.state.detail.id, this.state.detail.sotoa, (this.state.detail.ngaylapphieu)) }}>
                                <i className="fa fa-print"></i></Button>,
                            (this.state.trangthai === '3') &&
                            <Button type="primary" style={{ marginLeft: "5px" }}
                                onClick={() => { this.PrintUse(this.state.detail.id, (this.state.detail.ngaylapphieu)) }}>
                                In cách dùng</Button>,
                            <Button style={{ marginLeft: "5px" }} onClick={this.handleCancel}>Đóng</Button>,
                        ]}
                    >
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <Form.Item label="ID phiếu:">
                                <Input
                                    disabled
                                    value={this.state.id} />
                            </Form.Item>
                        </div>
                        <Table dataSource={dataSource2}
                            pagination={false}
                            columns={columnsDetail1} rowKey={(e, index) => index} />
                        <div>
                            Ghi chú: {dataSource2.length > 0 ? dataSource2[0].ghichu : ''}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                            {(this.state.trangthai === '3') &&
                                <div>
                                    <Button style={{ marginLeft: "5px" }}
                                        onClick={() => { this.PrintConclusion(this.state.detail.mabn, this.state.detail.id, this.state.detail.sotoa, (this.state.detail.ngaylapphieu)) }}>
                                        In toa thuốc</Button>
                                    <Button style={{ marginLeft: "5px" }}
                                        onClick={() => { this.PrintUse(this.state.detail.id, (this.state.detail.ngaylapphieu)) }}>
                                        In cách dùng</Button>
                                </div>
                            }
                            <Button danger type="primary" style={{ marginLeft: "5px" }} onClick={this.handleCancel}>Đóng</Button>
                        </div>
                    </Modal>
                }
                { this.state.visibleEdit && this.state.trangthai === 1 &&
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.visibleEdit}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}

                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ disabled: false }}
                        footer={[null]}
                    >
                        <BanLeEdit ngay={this.state.ngaytao} id={id} mabn={this.state.mabn} hoten={this.state.hoten} sotoa={this.state.detail.sotoa}
                            onClose={this.onClose.bind(this)}
                        >
                        </BanLeEdit>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}


