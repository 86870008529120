import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, InputNumber, Button, Radio, Select, Tag, Switch, Popover, Tooltip, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ThirdPartyConfigService from '../../../services/thirdPartyConfigService';

const { TextArea } = Input;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [selectedListServices, setSelectedListServices] = useState();
  //validate
  //result model
  const [id, setId] = useState(props.id);
  const [description, setDescription] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [smsApiKey, setSmsApiKey] = useState();
  const [smsSecretKey, setSmsSecretKey] = useState();
  const [smsOAID, setSmsOAID] = useState();
  //#endregion 
  //gửi test
  const [smsTo, setSmsTo] = useState();
  const [smsContent, setSmsContent] = useState();
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    getById(props.id);
  }, [props.id]);

  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
      if (props.detailScreenMode == detailScreenModeEnum.create)
        setHeaderText('Tạo mới');
  }, [props.detailScreenMode]);

  useEffect(() => {
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setDescription(data.description);
    setIsDefault(data.isDefault);

    setSmsApiKey(data.config.smsApiKey);
    setSmsSecretKey(data.config.smsSecretKey);
    setSmsOAID(data.config.smsOAID);
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - ' + data.config.smsOAID);
    else if (props.detailScreenMode == detailScreenModeEnum.update)
      setHeaderText('Cập nhật - ' + data.config.smsOAID);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    ThirdPartyConfigService.smsGetById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const model = {
    id : null,
    description: '',
    isDefault : '',
    config: {
      smsApiKey : '',
      smsSecretKey : '',
      smsOAID : '',
    }
  };

  const setModel = () => {
    model.id = id;
    model.description = description;
    model.isDefault = isDefault;
    model.config={
      smsApiKey : smsApiKey,
      smsSecretKey : smsSecretKey,
      smsOAID : smsOAID,
    };
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.smsCreate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.smsUpdate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const validate = () => {
    let isValid = true;
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && id) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const sendSmsTest = () => {
    if (!smsTo || smsTo.length == 0 || !smsContent || smsContent.length == 0 ) {
      notification.error({ message: "Phải nhập Số điện thoại nhận và Nội dung" });
      return;
    }
    Actions.setLoading(true);
    let data = {id: id, smsTo: smsTo, smsContent: smsContent};
    ThirdPartyConfigService.sendSmsTest(data).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mô tả / Mặc định*/}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mô tả</label>
                  </div>
                  <TextArea
                    value={description}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextArea>
                  {validator.current.message("Mô tả", description, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label className="mita-title">Mặc định</label>
                  <div>
                    <Tooltip title={'Chỉ 1 tài khoản được bật mặc định. Nếu bạn bật mặc định cho tài khoản này thì các tài khoản khác sẽ bị tắt.'}>
                      <Switch 
                        checked={isDefault} 
                        onChange={(checked, e) => { 
                          if (detailScreenMode == detailScreenModeEnum.detail) {
                            props.onIsDefaultClick(id);
                          }
                          setIsDefault(checked); 
                        }} 
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Api key */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Api key</label>
                  </div>
                  <Input
                    value={smsApiKey}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setSmsApiKey(e.target.value)}
                  ></Input>
                  {validator.current.message("Api key", smsApiKey, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Secret key*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Secret key</label>
                  </div>
                  <Input
                    value={smsSecretKey}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setSmsSecretKey(e.target.value)}
                  ></Input>
                  {validator.current.message("Secret key", smsSecretKey, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Secret key*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>OA ID</label>
                  </div>
                  <Input
                    value={smsOAID}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setSmsOAID(e.target.value)}
                  ></Input>
                  {validator.current.message("oa id", smsOAID, "required")}
                </div>
              </div>
              <div hidden={detailScreenMode!=detailScreenModeEnum.detail}>
              <div className="row mt-2 border border-top"> {/* */}
                <div className="col-md-12">
                  <label className="mita-title">Gửi sms test</label>
                </div>
              </div>
              <div className="row mt-2"> {/* smsTo | smsContent */}
                <div className="col-md-5 d-flex flex-column">
                  <div>
                    <label className="mita-title">Số điện thoại nhận</label>
                  </div>
                  <Input
                    value={smsTo}
                    readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                    onChange={(e) => setSmsTo(e.target.value)}
                  ></Input>
                </div>
                <div className="col-md-5 d-flex flex-column">
                  <div>
                    <label className="mita-title">Nội dung</label>
                  </div>
                  <Input
                    value={smsContent}
                    readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                    onChange={(e) => setSmsContent(e.target.value)}
                  ></Input>
                </div>
                <div className="col-md-2 d-flex flex-column justify-content-end">
                  <button 
                    className='btn btn-color mr-2' 
                    onClick={sendSmsTest}
                  >
                    <i className='fas fa-paper-plane  mr-2'></i>
                    Gửi
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})