import React, { Component } from 'react';
import { Form, Input, Modal, Pagination, Table, Switch, Popconfirm, notification } from 'antd';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as Action from '../../libs/actions';
import * as EquipmentService from './equipmentService';

let task = null;
const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
    visible: false,
    create: true,
    loadingHide: false
}

export default class Type extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            key: "",
            typeName: "",
            typeCode: "",
            note: "",
            lstTypes: [],
            id: 0,
            _typeCode: ""
        }
    }
    componentDidMount() {
        this.loadListTypes();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadListTypes(page = this.state.currentPage, size = this.state.pageSize) {
        EquipmentService.getListTypes(this.state.key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstTypes: res.data,
                    currentPage: page,
                    pageSize: size,
                    totalRecords: res.totalRecords,
                });
            }
        }).catch(err => {
        })
    }
    add() {
        Action.setLoading(true);
        var {
            typeName, typeCode, note
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                typeName, typeCode, note
            }
            EquipmentService.createTypeOfEquipment(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Thêm thành công!' });
                    this.resetState();
                    this.validator.purgeFields();
                    this.loadListTypes();
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    update() {
        Action.setLoading(true);
        var {
            id, typeName, typeCode, note, _typeCode
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                id, typeName, typeCode, note, _typeCode
            }
            EquipmentService.updateTypeOfEquipment(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.resetState();
                    this.setState({
                        visible: false
                    })
                    this.validator.purgeFields();
                    this.loadListTypes();
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    delete(id) {
        EquipmentService.deleteTypeOfEquipment(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadListTypes();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }
    resetState() {
        this.validator.hideMessages();
        this.setState({
            typeName: null,
            typeCode: null,
            note: ""
        });
    }
    updateHide(ma, check) {
        this.setState({ loadingHide: true })
        let status = false;
        if (check === false) {
            status = true;
        }
        EquipmentService.UpdateHide(ma, status).then(result => {
            let data = this.state.lstTypes;
            let item = data.find(x => x.id === ma);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hide === true ? false : true;
                    item.hide = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hide;
                    item.hide = newcheck;
                }
                notification.error({ message: result.err.msgString })
            }
            this.setState({
                loadingHide: false,
            });
            this.loadListTypes();
        }).catch(err => {
            let data = this.state.lstTypes;
            let item = data.find(x => x.id === ma);
            if (item !== undefined) {
                const newcheck = item.hide;
                item.hide = newcheck;
            }
            this.setState({ loadingHide: false });
            this.loadListTypes();
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    render() {
        const columns = [
            {
                title: "STT",
                align: "center",
                render: (text, record, index) => (this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1,
                width: 70
            },
            {
                title: "Tên loại",
                align: "left",
                dataIndex: "typeName",
                key: "typeName"
            },
            {
                title: "Mã loại",
                align: "left",
                dataIndex: "typeCode",
                key: "typeCode"
            },
            {
                title: "Chú thích",
                align: "left",
                dataIndex: "note",
                key: "note"
            },
            {
                title: 'Hiển thị',
                align: 'center',
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingHide} checkedChildren="Hiện" unCheckedChildren="Ẩn" checked={row.hide === false}
                            onChange={(e) => { this.updateHide(row.id, e); }} />
                    )
                }
            },
            {
                title: 'Thao tác',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật loại tài sản!"
                                onClick={() => {
                                    this.setState({
                                        visible: true, create: false,
                                        id: row.id, typeName: row.typeName, typeCode: row.typeCode,
                                        note: row.note, _typeCode: row.typeCode
                                    });
                                }} >
                                <i style={{ marginRight: 5 }} className="fa fa-edit"></i>Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.delete(row.id) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        return (
            <>
                <div className='containerChilderWrapper'>
                    <div className='h4 text-center mt-2 mb-3 font-bold text-uppercase'>Cấu hình danh mục loại tài sản</div>
                    <div
                        className="row no-gutters"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="col-md-4 mt-2">
                            <Input
                                placeholder="Nhập mã hoặc tên loại để tìm kiếm"
                                onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={() => {
                                    task = setTimeout(() => {
                                        this.loadListTypes(1, this.state.pageSize)
                                    }, 300);
                                }} />
                        </div>
                        <button
                            style={{ marginLeft: 10 }}
                            className="btn btn-success mt-2"
                            onClick={() => this.setState({ visible: true, create: true })} >
                            <i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới
                        </button>
                    </div>
                    <div style={{ width:"100%", height:'calc(100% - 130px)' , marginTop:20 }}>
                        <Table
                            columns={columns}
                            dataSource={this.state.lstTypes}
                            scroll={{ y: 0 }}
                            pagination={false}
                            rowKey={row => "type-of-eq-tbl-" + row.id} />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={this.state.currentPage}
                        onChange={(page, size) => {
                            this.setState({
                                currentPage: page,
                                pageSize: size
                            });
                            this.loadListTypes(page, size);
                        }}
                        onShowSizeChange={(page, size) => {
                            this.setState({
                                currentPage: 1,
                                pageSize: size
                            });
                            this.loadListTypes(1, size)
                        }}
                        showSizeChanger={true}
                    />

                </div>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>{this.state.create ? " Thêm " : "Cập nhật thông tin "} loại tài sản</h3>
                    </div>
                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10, justifyContent: 'center' }}>
                        <div className="col-md-6">
                            <Form.Item label={<span className="ant-form-item-required">Tên loại </span>}>
                                <Input
                                    placeholder="Tên loại tài sản"
                                    value={this.state.typeName}
                                    onChange={(e) => {
                                        this.setState({ typeName: e.target.value })
                                    }} />
                                {this.validator.message('Tên loại tài sản', this.state.typeName, 'required')}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label={<span className="ant-form-item-required">Mã loại </span>}>
                                <Input
                                    maxLength={3}
                                    placeholder="Mã loại tài sản"
                                    value={this.state.typeCode}
                                    onChange={(e) => {
                                        this.setState({ typeCode: e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "") })
                                    }} />
                                {this.validator.message('Mã loại tài sản', this.state.typeCode, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-12">
                            <Form.Item label="Chú thích">
                                <Input
                                    placeholder="Ghi chú thêm"
                                    value={this.state.note}
                                    onChange={(e) => this.setState({ note: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                        {this.state.create ?
                            <div>
                                <button
                                    className="btn btn-success"
                                    onClick={() => this.add()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }} />
                                    Thêm
                                </button>
                                <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.resetState()
                                    }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Nhập lại
                                </button>
                            </div>
                            :
                            <button
                                className="btn btn-success"
                                onClick={() => this.update()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }} />
                                Cập nhật
                            </button>
                        }
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visible: false });
                                this.resetState();
                                this.validator.purgeFields();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
            </>
        );
    }
}