import React from 'react';
import { Component } from 'react';
import {
    DatePicker, Input,
    Modal, Checkbox, InputNumber,
    Select, notification
} from 'antd';
import viVN from 'antd/lib/date-picker/locale/vi_VN';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Action from '../../libs/actions';
import * as EquipmentService from './equipmentService';
import TaoNhanVien from '../HR/taonhanvien';
const { Option } = Select;
export default class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createID: '',
            equipmentID: '',
            equipmentName: '',
            brand: '',
            type: '',
            price: 0,
            createTime: moment(),
            expiration: null,
            depreciation: null,
            note: '',

            serial: '',
            infomation: '',
            location: null,
            status: 0,
            userid: null,
            dateinuse: moment(),
            lstUsers: [],

            vsbCreateUser: false,
            isChecked: false,
            lstTypes: [],
            renewTypes: true,
            listLocation: [],
            isOwner: 0,
            _equipmentID: "",
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.getListUsers();
        this.getListTypes();
        this.getListLocation();
    }
    addEquipment() {
        Action.setLoading(true);
        var {
            createTime, equipmentID, equipmentName,
            brand, type, price, expiration, depreciation,
            note, serial, infomation,
            location, status, userid, dateinuse
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                createTime, equipmentID, equipmentName,
                brand, type, dongia: price, expiration, depreciation,
                note, serial, infomation,
                location, status, userid: userid ? userid : -1, dateinuse
            }
            EquipmentService.createEquipment(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Thêm thành công!' });
                    status === 1 && this.addHistory({ equipmentID, note, userid, dateinuse });
                    this.resetState();
                    this.props.onReload();
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    addHistory(data) {
        EquipmentService.createHistory(data).then(res => {
            if (res.isSuccess) {
            } else {
                notification.warning({ message: "Lỗi khi thêm thông tin bàn giao" });
            }
        }).catch(() => { })
    }
    getListUsers(active = true) {
        EquipmentService.loadUsers(active).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstUsers: res.data
                });
            }
        }).catch(() => { })
    }
    getListTypes() {
        EquipmentService.loadTypes().then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstTypes: res.data
                });
            }
        }).catch(() => { })
    }
    generateCode(prefix) {
        EquipmentService.generateCode(prefix).then(res => {
            if (res.isSuccess) {
                this.setState({
                    equipmentID: res.returnObject.code,
                    _equipmentID: res.returnObject.code,
                });
                if (this.state.location !== undefined && this.state.location !== null & this.state.location !== "") {
                    this.setState({
                        equipmentID: this.state.isOwner.toString() + this.state.location + res.returnObject.code
                    })
                }
            } else {
                notification.error({ message: "Đã xảy ra lỗi khi tạo mã" })
            }
        }).catch(() => { })
    }
    resetState = () => {
        this.validator.hideMessages();
        this.setState({
            createID: '',
            equipmentID: '',
            equipmentName: '',
            brand: '',
            type: '',
            price: 0,
            createTime: moment(),
            expiration: null,
            depreciation: null,
            note: '',

            serial: '',
            infomation: '',
            location: null,
            status: 0,
            userid: null,
            dateinuse: moment(),
        })
    }
    handleCancel = () => {
        this.setState({
            vsbCreateUser: false
        });
        this.getListUsers();
        this.getListTypes();
    };

    getListLocation = (page = 1, size = 10) => {
        var getAll = true;
        var key = "";
        EquipmentService.getListLocation(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                this.setState({ listLocation: res.data })
            }
        }).catch(() => { })
    }
    setEquipmentIDWithLocation(loc = this.state.location, owner = this.state.isOwner) {
        if (this.state.equipmentID !== undefined && this.state.equipmentID !== "" && loc !== null && loc !== "") {
            this.setState({
                equipmentID: owner.toString() + loc + this.state._equipmentID
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>{this.state.status !== 1 ? "Thêm " : "Bàn giao "} tài sản cố định</h3>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <label>Ngày nhập</label>
                        <DatePicker locale={viVN} allowClear={false} className="w-100" format={"DD-MM-YYYY"} value={this.state.createTime}
                            onChange={(e) => this.setState({ createTime: e })}
                            disabledDate={(current) => { return current && (current < moment().subtract(3, 'months')) }} />
                        {this.validator.message("Thời gian nhập liệu", this.state.createTime, "required")}
                    </div>
                    <div className="col-md-4">
                        <label>Loại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Input style={{ display: "none" }} value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}></Input>
                        <Select
                            className="w-100"
                            value={this.state.type}
                            showSearch
                            onChange={(e) => {
                                this.setState({ type: e });
                                this.generateCode(e);
                            }}
                            onClick={() => {
                                if (this.state.renewTypes) {
                                    this.getListTypes();
                                    this.setState({
                                        renewTypes: false
                                    });
                                }
                            }
                            }
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {this.state.lstTypes.map((row, index) => {
                                return (
                                    <Option key={index} value={row.typeCode}>{row.typeName}</Option>
                                )
                            })}
                        </Select>
                        {this.validator.message("Loại tài sản", this.state.type, "selection")}
                    </div>
                    <div className="col-md-4">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <label>Bộ phận</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            </div>
                            <Checkbox checked={this.state.isOwner === 1}
                                onChange={(e) => this.setState({
                                    isOwner: e.target.checked ? 1 : 0
                                }, this.setEquipmentIDWithLocation(this.state.location, e.target.checked ? 1 : 0)
                                )}>Chủ sở hữu</Checkbox>
                        </div>
                        <Select className="w-100" value={this.state.location} placeholder="Chọn bộ phận"
                            value={this.state.location}
                            allowClear={true}
                            onChange={(e) => {
                                this.setState({ location: e }, this.setEquipmentIDWithLocation(e, this.state.isOwner));
                                if (e !== 1) {
                                    this.setState({
                                        userid: null,
                                        dateinuse: null
                                    });
                                }
                            }}>
                            {this.state.listLocation && this.state.listLocation.map((loc, index) => {
                                return (
                                    <Option value={loc.code} key={index}>{loc.location}</Option>
                                )
                            })}
                        </Select>
                        {this.validator.message("Bộ phận", this.state.location, "selection")}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <label>Mã tài sản</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            </div>
                            <Checkbox checked={this.state.isChecked} onChange={(e) => this.setState({ isChecked: e.target.checked })}>Tùy chỉnh</Checkbox>
                        </div>
                        <Input disabled={!this.state.isChecked} value={this.state.equipmentID} onChange={(e) => this.setState({ equipmentID: e.target.value })} maxLength={10}></Input>
                        {this.validator.message("Mã tài sản", this.state.equipmentID, "required")}
                        <button disabled={this.state.type === null || this.state.type === ""} className="btn btn-success" style={{ position: "absolute", top: "30px", right: "10px", transform: "scale(0.8)", borderRadius: 8 }}
                            onClick={() => { this.generateCode(this.state.type); this.setState({ isChecked: false }) }}><i className="fa fa-sync" /></button>
                    </div>
                    <div className="col-md-4">
                        <label>Tên tài sản</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Input value={this.state.equipmentName} maxLength={100} onChange={(e) => this.setState({ equipmentName: e.target.value })}></Input>
                        {this.validator.message("Tên tài sản", this.state.equipmentName, "required")}
                    </div>
                    <div className="col-md-4">
                        <label>Hãng</label>
                        <Input value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })}></Input>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Giá</label>
                        <InputNumber className="w-100" value={this.state.price} min={0} max={999999999} maxLength={11}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(e) => this.setState({ price: e })} />
                        {this.validator.message("Giá", this.state.price, "required")}
                    </div>
                    <div className="col-md-4">
                        <label>Hạn bảo hành</label>
                        <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"}
                            value={this.state.expiration} onChange={(e) => this.setState({ expiration: e })}
                            disabledDate={(current) => { return current && (current < moment("1990-01-01")) }} />
                    </div>
                    <div className="col-md-4">
                        <label>Hạn khấu hao</label>
                        <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.depreciation}
                            disabledDate={current => { return current <= this.state.expiration }}
                            onChange={(e) => this.setState({ depreciation: e })} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Ghi chú</label>
                        <Input value={this.state.note} maxLength={255} onChange={(e) => this.setState({ note: e.target.value })}></Input>
                    </div>
                    <div className="col-md-4">
                        <label>Serial</label>
                        <Input value={this.state.serial} maxLength={50} onChange={(e) => this.setState({ serial: e.target.value })}></Input>
                    </div>
                    <div className="col-md-4">
                        <label>Cấu hình</label>
                        <Input value={this.state.infomation} maxLength={100} onChange={(e) => this.setState({ infomation: e.target.value })}></Input>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label>Trạng thái</label>
                        <Select className="w-100" value={this.state.status} placeholder="Chọn trạng thái"
                            onChange={(e) => {
                                this.setState({ status: e });
                                this.validator.purgeFields();
                                if (e !== 1) {
                                    this.setState({
                                        userid: null,
                                        dateinuse: null
                                    });
                                }
                            }}>
                            <Option value={0}>Trong kho</Option>
                            <Option value={1}>Đang sử dụng</Option>
                            <Option value={2}>Thanh lý</Option>
                        </Select>
                    </div>
                    <div className="col-md-4" style={{ visibility: this.state.status === 1 ? "visible" : "hidden" }}>
                        <label>Cấp cho</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <div>
                            <Select className="w-100" value={this.state.userid} showSearch
                                onChange={(e) => this.setState({ userid: e })}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.state.lstUsers.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.hoten}</Option>
                                    )
                                })}
                            </Select>
                            {this.state.status === 1 && this.validator.message("Người sử dụng", this.state.userid, "required")}
                            <button className="btn btn-success" style={{ position: "absolute", top: "29px", right: "8px", transform: "scale(0.7)" }}
                                onClick={() => this.setState({ vsbCreateUser: true })}><i className="fa fa-plus" /></button>
                        </div>
                    </div>
                    <div className="col-md-4" style={{ visibility: this.state.status === 1 ? "visible" : "hidden" }}>
                        <label>Ngày cấp</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.dateinuse}
                            disabledDate={current => { return current <= moment().subtract(5, 'years') || current >= moment().add(3, 'months') }}
                            onChange={(e) => this.setState({ dateinuse: e })}></DatePicker>
                        {this.state.status === 1 && this.validator.message("Ngày cấp", this.state.dateinuse, 'required')}
                    </div>
                </div>
                <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button className="btn btn-success"
                        onClick={() => {
                            this.addEquipment();
                        }}>
                        <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu
                    </button>
                    <button className="btn btn-warning" style={{ marginLeft: 10 }}
                        onClick={() => {
                            this.resetState()
                        }}>
                        <i className="fa fa-eraser" style={{ marginRight: 5 }} />Nhập lại
                    </button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                        onClick={() => {
                            this.props.onCancel();
                            this.setState({
                                renewTypes: true
                            })
                        }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                    </button>
                </div>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.vsbCreateUser}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <TaoNhanVien onCancel={this.handleCancel} />
                </Modal>
            </React.Fragment>
        )
    }
}