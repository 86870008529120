import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, useCallback, Fragment } from 'react';
import { Button, Card, Drawer, Input, Popover, Select, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import * as ManagegerService from '../../services/ManageService';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import { isNullOrEmpty, isnotnull } from '../../libs/util';
import UserNotificationSelect from './userNotificationSelect';
import * as NotificationMessageService from "../../services/notificationMessage";
import _ from 'lodash';
import { setLoading } from '../../libs/actions';
import { IsSuperAdmin } from '../../commons/utils';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../libs/suneditor-vi-lang';
import { GoogleGenerativeAI } from "@google/generative-ai";
import * as Configure from '../../configure/urlConfig';
import "../../configureHIS/common//notificationMessageStyle.scss";

let task = null;
var newLine = "\r\n";

const SentMessageNotification = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getUserGroup();
        getListConfigInfo();
    }, []);

    const genAI = new GoogleGenerativeAI(Configure.GEMINI_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const _notify = useRef();
    const _userNotificationSelect = useRef();
    const [userGroup, setUserGroup] = useState();
    const [listUserGroup, setListUserGroup] = useState([]);
    const [type, setType] = useState();
    const [listType,] = useState([
        {
            key: "regular",
            name: "Thông báo chung (dạng pop-up)"
        },
        {
            key: "target",
            name: "Mở màn hình cụ thể"
        },
    ]);
    const [target, setTarget] = useState();
    const [listIcon,] = useState([
        {
            title: "Thông tin",
            key: "info",
            value: "fas fa-info-circle",
            style: {
                color: "#7373dd"
            }
        },
        {
            title: "Thành công",
            key: "success",
            value: "far fa-check-circle",
            style: {
                color: "#87db87"
            }
        },
        {
            title: "Cảnh báo",
            key: "warning",
            value: "fas fa-exclamation-circle",
            style: {
                color: "#fdc867"
            }
        },
        {
            title: "Thắc mắc",
            key: "question",
            value: "fas fa-question-circle",
            style: {
                color: "#f88181"
            }
        },
    ]);
    const [title, setTitle] = useState();
    const [icon, setIcon] = useState();
    const [detail, setDetail] = useState();
    const [listUserSelected, setListUserSelected] = useState([]);
    const [vsbSelectUser, setVsbSelectUser] = useState(false);
    const [listExample,] = useState([
        {
            title: "Thông báo cập nhật phần mềm",
            detail: "Bản cập nhật phần mềm phiên bản mới đã được cập nhật thành công."
                + newLine + "<br/>Cảm ơn quý khách đã sử dụng dịch vụ của Viettinlab.",
            type: "regular",
            icon: "fas fa-info-circle"
        },
        {
            title: "Thông báo hết hạn phần mềm",
            detail: "Kính gửi quý khách hàng,"
                + newLine + "<br/>Viettin xin thông báo, phần mềm sẽ hết hạn vào lúc."
                + newLine + "<br/>Quý khách vui lòng gia hạn trước thời điểm này để tránh gián đoạn trong quá trình sử dụng."
                + newLine + "<br/>Cảm ơn quý khách đã sử dụng dịch vụ của Viettinlab.",
            type: "regular",
            icon: "fas fa-exclamation-circle"
        },
        {
            title: "Bạn vừa được phân quyền màn hình mới",
            detail: "Bấm để chuyển đến màn hình.",
            type: "target",
            icon: "fas fa-info-circle"
        },
    ]);
    const [recently, setRecently] = useState(JSON.parse(localStorage.recentlyNotificationTemplate ?? "[]") ?? []);
    const [geminiKeyword, setGeminiKeyword] = useState();
    const _detailField = useRef();
    const [listSpecialInfo, setListSpecialInfo] = useState([]);

    const renderScreenList = () => {
        let listOpts = [];
        props.menuData && props.menuData.length > 0 && props.menuData.map((x) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc) => {
                            listOpts.push(getOption(nc));
                        })
                    } else listOpts.push(getOption(n));
                })
            } else listOpts.push(getOption(x));
        })
        return listOpts.filter(x => (props.permission ?? []).includes("@@@") ? x : (props.permission ?? []).includes(x.props.value));
    }

    const getOption = (opt) => {
        return <Select.Option key={`${opt.menuKey}`} value={opt.code}>{opt.label}</Select.Option>
    }

    const onDelaySearchUserGroup = (key) => {
        if (task) clearTimeout(task)
        task = setTimeout(() => {
            getUserGroup(key);
        }, 500)
    }

    const getUserGroup = (key = "") => {
        ManagegerService.GetListGroupUser(key).then(result => {
            if (result.isSuccess) {
                setListUserGroup(result.data);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show('Lỗi tải danh sách nhóm người dùng', NotifyStatus.Error);
        })
    }

    const handleSendImmediately = () => {
        if (!validator.current.allValid()) {
            _notify && _notify.current && _notify.current.Show("Cần nhập thông tin bắt buộc", NotifyStatus.Warning);
            validator.current.showMessages();
            forceUpdate();
            return;
        }
        if (!isnotnull(listUserSelected)) {
            _notify && _notify.current && _notify.current.Show("Vui lòng chọn người nhận thông báo", NotifyStatus.Warning);
            return;
        }
        _notify && _notify.current && _notify.current.Close();
        const data = {
            type,
            title,
            icon: {
                value: icon,
                style: listIcon.find(x => x.value == icon)?.style
            },
            detail,
            listUser: listUserSelected,
            ...(type != "regular" ? { target } : {}),
        };
        setLoading(true);
        NotificationMessageService.CreateNotificationMessage(data).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Gửi thành công.", NotifyStatus.Success);
                addRecentlyNotificartionMessage(data);
                onResetListUserSelected();
                onResetData();
            } else {
                _notify && _notify.current && _notify.current.Show("Gửi lỗi, vui lòng thử lại sau.", NotifyStatus.Error);
            }
        }).catch(() => {
            _notify && _notify.current && _notify.current.Show("Đã xảy ra lỗi.", NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
            validator && validator.current && validator.current.hideMessages();
            validator && validator.current && validator.current.purgeFields();
        })
    }

    const handleCloseSelectUser = () => {
        setVsbSelectUser(false);
    }

    const handleSaveSelectUser = (userSelected) => {
        setVsbSelectUser(false);
        setListUserSelected(userSelected);
    }

    const handleVsbSelectUser = () => {
        setVsbSelectUser(true);
    }

    const onResetListUserSelected = () => {
        setListUserSelected([]);
        _userNotificationSelect && _userNotificationSelect.current && _userNotificationSelect.current.resetListUserSelected && _userNotificationSelect.current.resetListUserSelected();
    }

    const onResetData = () => {
        setTitle();
        setDetail();
        setTarget();
        setType();
        setGeminiKeyword();
        validator && validator.current && validator.current.hideMessages();
        validator && validator.current && validator.current.purgeFields();
    }

    const setDataFromExample = (data) => {
        setTitle(data.title);
        setDetail(data.detail);
        setType(data.type);
        setTarget(data.target);
        setIcon(data.icon);
    }

    async function geminiRequest(key = geminiKeyword) {
        if (isNullOrEmpty(key)) {
            _notify && _notify.current && _notify.current.Show("Vui lòng nhập câu hỏi", NotifyStatus.Warning);
            return;
        }
        setType("regular");
        setLoading(true);
        const result = await model.generateContent(key);
        const response = await result.response;
        setLoading(false);
        const text = response.text();
        setDetail(text);
    }

    const renderTemplate = (list, showClear = false) => {
        return (
            <div className='col-12 d-flex flex-row justify-content-start align-items-center w-100 mt-2' style={{ overflowX: "auto", height: 240, gap: "20px" }}>
                {
                    list && list.map((e, index) => {
                        return (
                            <div>
                                <Card className="rowCustom"
                                    title={
                                        <div className='d-flex justify-content-between'>
                                            <label>{!isNullOrEmpty(e.icon) ? <i className={`${e.icon} mr-1`} style={listIcon.find(x => x.value == e.icon)?.style} /> : ""}{e.title}</label>
                                            {showClear &&
                                                <Tooltip title="Xóa" color='volcano' placement='left'>
                                                    <label style={{ cursor: "pointer", position: "absolute", right: 14 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                        clearTemplate(index);
                                                    }}>❌</label>
                                                </Tooltip>}
                                        </div>
                                    } bordered style={{ width: 400, height: 200, backgroundColor: "#f9f0ff54", cursor: "pointer", borderRadius: 8 }} key={index}
                                    onClick={() => { setDataFromExample(e) }}
                                >
                                    <div style={{ height: "95px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: `${e.detail}` }}></div>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const clearTemplate = (index) => {
        const dataLocalStorage = localStorage.recentlyNotificationTemplate;
        if (isnotnull(dataLocalStorage)) {
            const data = JSON.parse(dataLocalStorage);
            if (data.length > 0) {
                data.splice(index, 1);
                setRecentlyNotificationTemplate(data);
            }
        }
    }

    const addRecentlyNotificartionMessage = (data) => {
        let currentConfig = [...recently];
        data.icon = data.icon.value;
        if (currentConfig.find(x => x.type == data.type && x.title == data.title && x.icon == data.icon && x.detail == data.detail) == undefined) {
            currentConfig.push(data);
        }
        setRecentlyNotificationTemplate(currentConfig);
    }

    const setRecentlyNotificationTemplate = (data) => {
        setRecently(data);
        localStorage.setItem("recentlyNotificationTemplate", JSON.stringify(data));
    }

    const getListConfigInfo = () => {
        NotificationMessageService.getDefaultConfigInfo().then(res => {
            if (res.isSuccess) {
                setListSpecialInfo(res.data);
            }
        })
    }

    const addInfo = (field) => {
        let info = detail ?? "";
        info = addStyledText(info, `{{${field}}}`);
        setDetail(info);
    }

    const addStyledText = (pureText, text) => {
        if (type == "regular") return pureText.concat(`<span style="font-weight: bold;">${text}</span>`);
        return insertAtCursor(text);
    }

    function insertAtCursor(val) {
        let currentInput = _detailField.current?.resizableTextArea.textArea;
        let cursorPos = currentInput.selectionStart;
        let v = currentInput.value;
        let textBefore = v.substring(0, cursorPos);
        let textAfter = v.substring(cursorPos, v.length);
        if (textBefore.length > 0) val = ` ${val}`;
        if (textAfter.length > 0) val = `${val} `;
        cursorPos += val.length;
        currentInput.focus();
        currentInput.setSelectionRange(cursorPos, cursorPos);
        const res = textBefore + val + textAfter;
        return res.trim();
    }

    return (
        <Fragment>
            <div className="list-page">
                <div className="list-page-body">
                    <Notify ref={_notify} />
                    <div className="list-page-search">
                        <div className='row'>
                            <div className='col-md-4 mt-2'>
                                <label>Nhóm người dùng</label>
                                <Select className='w-100' placeholder='Chọn nhóm người dùng'
                                    value={userGroup} onChange={(e) => { setUserGroup(e); onResetListUserSelected(); }} allowClear
                                    showSearch onSearch={(e) => { onDelaySearchUserGroup(e); }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {listUserGroup && listUserGroup.map((x, index) => {
                                        return (
                                            <Select.Option key={index} value={x.value}>{x.label}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className='col-md-4 mt-2'>
                                <label className='required-field'>Loại thông báo</label>
                                <Select className='w-100' placeholder='Chọn loại thông báo'
                                    value={type} onChange={(e) => {
                                        setType(e);
                                        if (e != "target") {
                                            setTarget();
                                            validator && validator.current && validator.current.hideMessages();
                                            validator && validator.current && validator.current.purgeFields();
                                        }
                                    }}>
                                    {listType && listType.map((x, index) => {
                                        return (
                                            <Select.Option key={index} value={x.key}>{x.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                                {validator.current.message("Loại thông báo", type, "selection")}
                            </div>
                            {type == "target" && <div className='col-md-4 mt-2'>
                                <label className={type == "target" ? "required-field" : ""}>Màn hình đích</label>
                                <Select className='w-100' placeholder='Màn hình đích'
                                    target={target} onChange={(e) => { setTarget(e); onResetListUserSelected(); }}
                                    showSearch defaultActiveFirstOption
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {renderScreenList()}
                                </Select>
                                {type == "target" ? validator.current.message("Màn hình đích", target, "selection") : ""}
                            </div>}
                        </div>
                        <div className='row'>
                            <div className='col-md-12 mt-2'>
                                <Popover title="Danh sách đã chọn"
                                    placement='right'
                                    content={
                                        listUserSelected.length > 0 ?
                                            <ol type='1'>
                                                {listUserSelected && listUserSelected.map((x, index) => {
                                                    return (
                                                        <li key={index}>{x.username} - {x.name}</li>
                                                    )
                                                })}
                                            </ol>
                                            : <label>Chưa chọn người dùng nào</label>
                                    }>
                                    <button className={`${BUTTON.THEME}`} onClick={() => { handleVsbSelectUser(); }}>{`Chọn người dùng${listUserSelected && listUserSelected.length > 0 ? ` (đã chọn: ${listUserSelected.length})` : ""}`}</button>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table mt-2" style={{ overflowY: "auto" }}>
                        <div className='row'>
                            <div className='col-lg-8 col-md-12 mt-2'>
                                <label className='required-field'>Tiêu đề</label>
                                <Input
                                    placeholder='Nhập tiêu đề'
                                    value={title}
                                    onChange={(e) => { setTitle(e.target.value) }}
                                ></Input>
                                {validator.current.message("Tiêu đề", title, "required")}
                            </div>
                            <div className='col-lg-4 col-md-12 mt-2'>
                                <label>Icon</label>
                                <Select
                                    className='w-100'
                                    placeholder='Chọn icon'
                                    value={icon}
                                    onChange={(e) => { setIcon(e) }}
                                >
                                    {listIcon && listIcon.map((x) => {
                                        return (
                                            <Select.Option key={x.key} value={x.value}><i className={`mr-2 ${x.value ?? ""}`} style={x.style} />{x.title}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mt-2 d-flex flex-row justify-content-between align-items-center'>
                                <Input
                                    prefix={<img style={{ height: 25, padding: "0px 6px 6px" }} src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Google_Gemini_logo.svg/344px-Google_Gemini_logo.svg.png' alt="gemini-logo" />}
                                    value={geminiKeyword}
                                    onChange={(e) => setGeminiKeyword(e.target.value)}
                                    placeholder='AI có thể hỗ trợ bạn tạo một nội dung...'
                                    onKeyDown={(e) => { if (e.key === "Enter") geminiRequest(e.target.key) }}
                                    suffix={<button style={{ height: 35 }} className={`${BUTTON.CUSTOM} ml-2`} onClick={() => geminiRequest()}>Giúp tôi</button>}></Input>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mt-2'>
                                <div className='d-flex flex-row'>
                                    <label className='required-field'>Nhập nội dung thông báo</label>
                                </div>
                                {type && type == "regular" ?
                                    <div ref={_detailField}>
                                        <SunEditor
                                            onBlur={(e, value) => {
                                                setDetail(value)
                                            }}
                                            onChange={(e) => setDetail(e)}
                                            lang={suneditorViLang}
                                            setDefaultStyle="font-size: 16px; paragraphStyles: normal; font-family: Arial;"
                                            setContents={detail}
                                            setOptions={{
                                                height: 300,
                                                buttonList: [
                                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                    ['fontColor', 'hiliteColor'],
                                                    ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                                    ['outdent', 'indent', 'align', 'horizontalRule', 'list', 'table', 'lineHeight'],
                                                    ['link', 'image', 'codeView']
                                                ]
                                            }}
                                            height='100%'
                                        />
                                    </div>
                                    :
                                    <Input.TextArea
                                        ref={_detailField}
                                        autoSize={{ minRows: 5, maxRows: 20 }}
                                        placeholder='Nội dung thông báo'
                                        value={detail} onChange={(e) => {
                                            setDetail(e.target.value);
                                        }}
                                    ></Input.TextArea>}
                                {validator.current.message("Nội dung thông báo", detail?.replace("<p><br></p>", ""), "required")}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-between mt-2'>
                                <div>
                                    <Popover
                                        placement='right'
                                        title="Bấm chọn thông tin"
                                        content={
                                            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                                                {
                                                    listSpecialInfo && listSpecialInfo.map((inf, ind) => {
                                                        return <li className='w-100 rowCustom' kye={ind}><a onClick={() => { addInfo(inf.key) }} className='text-color'>{inf.title}</a></li>
                                                    })
                                                }
                                            </ul>
                                        }
                                    >
                                        <Button className={`${BUTTON.CUSTOM}`}>Thêm thông tin cấu hình sẵn vào nội dung</Button>
                                    </Popover>
                                </div>
                                <div className='d-flex flex-row'>
                                    {type == "regular" && detail && title &&
                                        <div className=''>
                                            <Popover placement='left' content={
                                                <div className="notificationMessageModal">
                                                    <div>
                                                        <h3>{title}</h3>
                                                        <div style={{
                                                            lineHeight: "30px", wordBreak: "break-word"
                                                        }} dangerouslySetInnerHTML={{ __html: `${detail}` }}></div>
                                                    </div>
                                                </div>
                                            }>
                                                <button className={`${BUTTON.CUSTOM}`}><i className='fas fa-eye mr-1' />Xem trước</button>
                                            </Popover>
                                        </div>}
                                    <div>
                                        <button className={`${BUTTON.THEME} ml-2`}
                                            onClick={() => {
                                                handleSendImmediately()
                                            }}
                                        >Gửi ngay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {recently && recently.length > 0 && <div className='row'>
                            <div className='col-12 mt-2 mita-title'>Gần đây (Click để chọn)</div>
                            {renderTemplate(recently, true)}
                        </div>}
                        {IsSuperAdmin() && <div className='row'>
                            <div className='col-12 mt-2 mita-title'>Mẫu (Click để chọn)</div>
                            {renderTemplate(listExample)}
                        </div>}
                    </div>
                </div>
            </div>
            <Drawer
                open={vsbSelectUser}
                destroyOnClose={false}
                closable={false}
                width={600}
                className='custom-ant-drawer'
            >
                <UserNotificationSelect
                    ref={_userNotificationSelect}
                    groupId={userGroup} feature={target}
                    onClose={() => handleCloseSelectUser()}
                    onSave={(userSelected) => handleSaveSelectUser(userSelected)} />
            </Drawer>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
        permission: state.global.permission,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SentMessageNotification)