import * as ApiCaller from '../libs/httpRequests';

const prefix = 'introVideo';

export function getListIntroVideo(screenCode) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListIntroVideo?s=${screenCode}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}

export function createIntroVideo(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateIntroVideo`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateIntroVideo(data) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateIntroVideo`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteIntroVideo(id) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteIntroVideo?id=${id}`).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};