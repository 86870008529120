import React, { Fragment } from 'react';

const messageStyle = {
    left: "40px",
    position: "absolute",
    textTransform: "uppercase",
    top: "42px",
    fontSize: "70%",
    color: "white",
    fontWeight: "bold",
    width: "34%",
    height: "15px",
    overflow: "hidden",
    textAlign: "center",
}

function NoContent(props) {
    return (
        <Fragment>
            <div className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: '100%', width: '100%' }}>
                <div style={{ overflow: "hidden", width: 300, position: "relative" }}>
                    <div style={messageStyle}>{props.message ?? "Không có dữ liệu"}</div> {/* Tối đa 15 kí tự */}
                    <img style={{ padding: '15px' }} width={300} alt={"Không có dữ liệu"} src="./images/NoContent.png"></img>
                </div>
                <h4 className="mt-1">{props.title ?? "Không có dữ liệu"}</h4>
            </div>
        </Fragment>
    );
}

export default NoContent;