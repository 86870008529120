import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Switch, Button, Select, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import MitaDisplayListElement from '../../components/MitaComponents/mitaDisplayListElement';
import Notify, { NotifyStatus } from '../../components/notify';
import * as PrintTemplateService from '../../services/printTemplateService';

const { Option } = Select;
const { TextArea } = Input;
const fieldProductEnum = {
  cardTypeProducts: 'cardTypeProducts', cardTypeProductFrees: 'cardTypeProductFrees', cardTypeProductExcludes: 'cardTypeProductExcludes'
};
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState('Chi tiết - ' + props.record.printTemplateName); 
  //result model
  const [printTemplateName, setPrintTemplateName] = useState('');
  const [printTemplateDescription, setPrintTemplateDescription] = useState('');
  const [printTemplateContent, setPrintTemplateContent] = useState('');
  const [componentId, setComponentId] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  //#endregion 
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 

  useEffect(() => {
    getById(props.record.id);
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setPrintTemplateName(data.printTemplateName);
    setPrintTemplateDescription(data.printTemplateDescription);
    setComponentId(data.componentId);
    setPrintTemplateContent(data.printTemplateContent);
    setIsDefault(data.isDefault);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    PrintTemplateService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mã loại thẻ / Hoạt động*/}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên template</label>
                  </div>
                  <Input
                    value={printTemplateName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setPrintTemplateName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên template", printTemplateName, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>ComponentId</label>
                  </div>
                  <Input
                    value={componentId}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setComponentId(e.target.value)}
                  ></Input>
                  {validator.current.message("ComponentId", componentId, "required")}
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <div>
                    <label>Mặc định</label>
                  </div>
                  <div>
                    <Switch 
                      checked={isDefault} 
                      // onChange={(checked, e) => { return; }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Mô tả */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Mô tả</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={printTemplateDescription ?? ""}
                    onChange={(e) => setPrintTemplateDescription(e.target.value)}
                  ></TextArea >
                </div>
              </div>
              <div className="row mt-2" style={{ }}>
                <div className="col-12">
                  <label>File template html</label>
                  <div className='w-100 p-2 border border-1' dangerouslySetInnerHTML={{ __html: `${printTemplateContent !== null ? printTemplateContent : ''}` }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
              <button 
                className='btn btn-secondary mr-2' 
                onClick={props.onDownloadClick}
              >
                <i className='fas fa-download mr-2'></i>
                Tải về
              </button>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  onClick={props.onModifiedClick}
                >
                  <i className='fas fa-pencil-alt mr-2'></i>
                  Chỉnh sửa
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})