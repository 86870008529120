import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../common/headerModal';
import { Button, Drawer, Dropdown, Input, Menu, Modal, Pagination, Select, Table, notification } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import * as VPService from '../services/VPService';
import { setLoading } from '../../../libs/actions';
import UpdateDiscount from '../components/updateDiscount';
import DiscountConfigureLog from './discountConfigureLog';
import { isNullOrEmpty } from '../../../libs/util';
import CoporationLinked from './coporationLinked';
import ImportCoporationDiscount from '../components/importCoporationDiscount';
import { FORMAT_PREFIX_EXCEL } from '../../../configure/systemConfig';
import moment from 'moment';

const DiscountConfigure = forwardRef((props, ref) => {
    const _notify = useRef();
    const _hiddenLink = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [bangGia, setBangGia] = useState();
    const [listBangGia, setListBangGia] = useState([]);
    const [listVP, setListVP] = useState([]);
    const [keyword, setKeyword] = useState();
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [selected, setSelected] = useState({})
    const [visibleLog, setVisibleLog] = useState(false);
    const [showCoporationLinked, setShowCoporationLinked] = useState(false);
    const [updateMultiple, setUpdateMultiple] = useState(false);
    const [visibleImport, setVisibleImport] = useState(false);

    const menuItems = (
        <Menu>
            <Menu.Item key={0}>
                <button className="btn" onClick={() => getTemplate()}>Tải danh sách dịch vụ của bảng giá</button>
            </Menu.Item>
            <Menu.Item key={1}>
                <button className="btn" onClick={() => getTemplate(true)}>Tải danh sách dịch vụ chưa cấu hình</button>
            </Menu.Item>
            <Menu.Item key={2}>
                <button className="btn" onClick={() => setVisibleImport(true)}>Import dữ liệu cập nhật</button>
            </Menu.Item>
        </Menu>
    )

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListBangGia();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '20%',
            align: 'left',
        },
        {
            title: 'Giá bán',
            dataIndex: 'gia_th',
            key: 'gia_th',
            align: 'right',
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Hình thức chiết khấu',
            dataIndex: 'discounttype',
            key: 'discounttype',
            align: 'right',
            render: (value) => {
                return value == "percent" ? "Phần trăm" : value == "amount" ? "Số tiền" : "";
            },
        },
        {
            title: 'Chiết khấu',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            render: (value) => {
                return <span>{value ? value.toLocaleString('en-US') : ''}</span>;
            },
        },
        {
            title: 'Giá sau chiết khấu',
            align: 'right',
            render: (data) => {
                return (
                    data.gia_th && data.discount ?
                        data.discounttype == "percent" ? (data.gia_th * (1 - data.discount / 100)).toLocaleString('en-US')
                            : (data.gia_th - data.discount).toLocaleString('en-US')
                        : ""
                )
            }
        },
        {
            title: 'Thao tác',
            align: 'center',
            render: (row) => {
                return (
                    <div>
                        <Button className={`${BUTTON.WARNING}`} onClick={() => { setSelected(row); setUpdateMultiple(false); setVisibleUpdate(true); }}><i className='fas fa-edit mr-1' />Cập nhật</Button>
                        <Button className={`${BUTTON.INFO} ml-2`} onClick={() => { setSelected(row); setVisibleLog(true) }}><i className="fas fa-list-ul" /></Button>
                    </div>
                )
            }
        }
    ];

    const getListBangGia = () => {
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                let lstBangGia = result.data.filter(x => x.value.toLowerCase() != 'default');
                setListBangGia(lstBangGia);
            }
        }).catch(err => {
        })
    }

    const loadDanhSachVP = (p = page, s = size, bg = bangGia) => {
        setLoading(true)
        const getHide = false;
        VPService.GetDanhSachVP(false, keyword ?? "", bg, 0, 0, p, s, getHide).then(result => {
            if (result.isSuccess) {
                setListVP(result.data);
                setTotalRecords(result.totalRecords);
            }
        }).catch(err => {
        }).finally(() => setLoading(false))
    }

    const onCloseUpdate = () => {
        setSelected({});
        setVisibleUpdate(false);
    }

    const getTemplate = (getNullOnly) => {
        if (isNullOrEmpty(bangGia)) {
            notification.info({ message: "Vui lòng chọn bảng giá." });
            return;
        }
        setLoading(true);
        VPService.TemplateImportCoporationDiscount(bangGia, getNullOnly).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = `${moment().format(FORMAT_PREFIX_EXCEL)}_MauImportChietKhau_${bangGia == "default" ? "Mặc định" : bangGia}`;
                _hiddenLink.current.click();
            })
        }).catch(err => {
            notification.error({ message: err.err.msgString });
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="list-page containerChilderWrapper">
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <HeaderModal title="Cấu hình chiết khấu" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='d-flex flex-wrap align-items-end' style={{ gap: 10 }}>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 300 }}>
                            <label className='mita-title'>Bảng giá</label>
                            <Select
                                placeholder="Chọn bảng giá"
                                className='w-100'
                                allowClear
                                value={bangGia}
                                onChange={(e) => {
                                    setBangGia(e);
                                    setPage(1);
                                    loadDanhSachVP(1, size, e);
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option key={-1} value="default">Mặc định</Select.Option>
                                {
                                    listBangGia.map((bg, index) => {
                                        return <Select.Option key={index} value={bg.value}>{bg.label}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 300 }}>
                            <label className='mita-title'>Tìm kiếm dịch vụ</label>
                            <Input className="w-100" value={keyword} onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={(e) => { if (e.key == "Enter") { setPage(1); loadDanhSachVP(1, size, bangGia, e.target.value) } }}
                                placeholder='Nhập tên hoặc mã dịch vụ' />
                        </div>
                        <div className='mt-2'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { setPage(1); loadDanhSachVP(); }}><i className='fas fa-search mr-1' />Tìm</button>
                        </div>
                    </div>
                    <div className={`d-flex justify-content-${!isNullOrEmpty(bangGia) ? "between" : "end"}`}>
                        {!isNullOrEmpty(bangGia) &&
                            <div className='mt-2'>
                                <button className={`${BUTTON.SECONDARY}`} onClick={() => setShowCoporationLinked(true)}>Nguồn khách hàng đang sử dụng bảng giá</button>
                            </div>
                        }
                        <div className='mt-2'>
                            <Dropdown
                                overlay={menuItems} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <button className={`${BUTTON.PRIMARY}`}><i className="fas fa-upload mr-1" />Import</button>
                                </a>
                            </Dropdown>
                            {!isNullOrEmpty(bangGia) &&
                                <button className={`${BUTTON.WARNING} ml-2`} onClick={() => { setSelected({}); setUpdateMultiple(true); setVisibleUpdate(true) }}>Cập nhật đồng loạt</button>}
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listVP}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size); loadDanhSachVP(page, size) }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size); loadDanhSachVP(1, size) }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
            <Drawer
                open={visibleUpdate}
                maskClosable={false}
                closable={false}
                width={500}
                destroyOnClose
                className='custom-ant-drawer'
            >
                <UpdateDiscount
                    updateMultiple={updateMultiple}
                    data={updateMultiple ? {} : selected} bangGia={bangGia}
                    onClose={() => { onCloseUpdate() }}
                    onSuccess={() => loadDanhSachVP()}
                />
            </Drawer>
            <Drawer
                open={visibleLog}
                maskClosable={false}
                closable={false}
                width={500}
                destroyOnClose
                className='custom-ant-drawer'
            >
                <DiscountConfigureLog
                    data={selected}
                    bangGia={bangGia}
                    onClose={() => setVisibleLog(false)}
                />
            </Drawer>
            <Modal open={showCoporationLinked}
                closable={false}
                destroyOnClose
                width={800}>
                <CoporationLinked
                    bangGia={bangGia}
                    onClose={() => { setShowCoporationLinked(false) }} />
            </Modal>
            <Modal
                maskClosable={true}
                width={500}
                open={visibleImport}
                onCancel={() => setVisibleImport(false)}
                destroyOnClose
            >
                <ImportCoporationDiscount
                    bangGia={bangGia} onSuccess={() => loadDanhSachVP()}
                    onClose={() => setVisibleImport(false)} />
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DiscountConfigure)