import { Collapse, DatePicker, Form, notification, Select, Table, Pagination} from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as KhoService from '../../../../services/KhoService';
import HeaderModal from '../../../../configureHIS/common/headerModal';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;

export default class ThongKeXuatKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            lstKho: [],
            makho: 0,
            tenkho: '',
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
        };
    }
    componentWillMount() {
        this.loadKho();
        this.LoadThongKeChuyenKho();
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstKho: result.data,
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi!' })
        })
    }
    LoadThongKeChuyenKho(page = this.state.currentPage, size = this.state.pageSize) {
        Actions.setLoading(true);
        StatisticService.ThongKeChuyenKho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.makho, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    totalRecords: result.totalRecords,
                    dataSource: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeChuyenKho() {
        Actions.setLoading(true);
        StatisticService.ExportChuyenKho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.makho, this.state.tenkho)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeChuyenKho";
                    this._hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'left',
                width: 100,
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
                width: 150,
            },
            {
                title: 'Hãng',
                dataIndex: 'tenhang',
                key: 'tenhang',
                align: 'left',
                width: 150,
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
                width: 100,
            },
            {
                title: 'Loại',
                dataIndex: 'tenloai',
                key: 'tenloai',
                align: 'left',
                width: 100,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'left',
                width: 100,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'left',
                width: 80,
            },
            {
                title: 'Người chuyển',
                dataIndex: 'nguoinhap',
                key: 'nguoinhap',
                align: 'left',
                width: 120,
            },
            {
                title: 'Lý do chuyển',
                dataIndex: 'lydo',
                key: 'lydo',
                align: 'left',
                width: 400,
            },
            {
                title: 'Chi tiết',
                dataIndex: 'lstData',
                key: 'lstData',
                align: 'center',
                width: 700,
                render: (row) => {
                    return (
                        <Collapse className="remove-space-statistic">
                            <Panel header="Xem chi tiết">
                                <Table
                                    style={{ margin: '0 !important' }}
                                    pagination={false}
                                    dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
                            </Panel>
                        </Collapse>
                    );
                },
            },
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} ></a>
                    <HeaderModal title="Thống kê chuyển kho" />
                    <Form ref={(c) => this.form = c} style={{ height: "calc(100% - 110px)" }}>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="col-md-3 mt-2">
                                <Form.Item label="Từ ngày:" style={{ display: "flex", alignItems: "center" }}>
                                    <RangePicker allowClear={false} disabledDate={this.disabledDate}
                                        format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value }, () => { this.LoadThongKeChuyenKho() }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2">
                                <Form.Item>
                                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                        <label style={{ width: 100 }}>Kho chuyển</label>
                                        <Select
                                            style={{ overflow: "hidden" }}
                                            value={this.state.makho}
                                            onChange={(e) => { this.setState({ makho: e }, () => { this.LoadThongKeChuyenKho() }) }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option key={null} value={0}>TẤT CẢ</Option>
                                            {this.state.lstKho.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2" style={{ display: 'flex' }}>
                                <Form.Item>
                                    <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => { this.LoadThongKeChuyenKho() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-color" onClick={() => { this.ExportThongKeChuyenKho() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='mt-2' style={{ height: 'calc(100% - 40px)' }}>
                            <Table
                                scroll={{ x: 'max-content', y: 0 }}
                                alignItems='center'
                                pagination={false}
                                dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                        </div>
                        <Pagination
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            size={this.state.currentSize}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({ currentPage: page, currentSize: size }, () => {
                                    this.LoadThongKeChuyenKho();
                                })
                            }}
                            onShowSizeChange={(page, size) => this.setState({ currentPage: 1, currentSize: size }, () => {
                                this.LoadThongKeChuyenKho();
                            })}
                            showSizeChanger={true}

                        />
                    </Form>
                </div>
            </ >
        )
    }
}
