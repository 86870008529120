import React, { useState, useEffect } from 'react'
import { notification, Switch, Pagination, Table, Modal, Dropdown, Menu, Input } from 'antd';
import * as Actions from '../../libs/actions';
import * as PatientService from '../../services/patientService';
import Create from './create';
import moment from 'moment';
import { FORMAT_DATE } from '../../configure/systemConfig';
import HeaderModal from '../../configureHIS/common/headerModal'

function NguoiGioiThieu(props) {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [totalRecord, settotalRecords] = useState(0);

    const [key, setKey] = useState('');
    const [loadingSwitch, setloadingSwitch] = useState(false);
    const [detail, setdetail] = useState({});
    const [Edit, setEdit] = useState(false);
    const [enableCreate, setenableCreate] = useState(false);
    const [sorting, setSorting] = useState(null)

    useEffect(() => {
        GetListNguoiGioiThieu();
    }, [page, size])

    function GetListNguoiGioiThieu() {
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            sorting: sorting,
            //riêng theo module
            keyword: key,
        }
        PatientService.GetReferentList(filterModel).then(results => {
            if (results.isSuccess) {
                setDataSource(results.data.items);
                settotalRecords(results.data.totalCount);
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    
    // useEffect(() => {
    //     if (page === 0)
    //         return;
    //     GetListNguoiGioiThieu();
    // }, [page, size]);
    function updateCheckSwitch(row, check) {
        setloadingSwitch(true);
        PatientService.UpdateHienTiNguoiGioiThieu(row.id, check).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                GetListNguoiGioiThieu()
                if(result.returnObject){
                    const args = {
                        message: 'Lưu ý',
                        description:
                        'Người giới thiệu này đã được liên kết với nguồn khách hàng. Vui lòng kiểm tra lại thông tin nguồn khách hàng.',
                        duration: 0,
                    };
                    notification.open(args);
                }
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            setloadingSwitch(false);
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    
    function deleteNguoiGioiThieu(row){
        PatientService.XoaNguoiGioiThieu(row.id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                GetListNguoiGioiThieu();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            
        }).catch(err => {
            notification.warning({ message: 'Xóa bị lỗi!' })
        })
    }

    const rowOptions = (record) => {
        return (
        <Menu
            items={[
                {
                    label: 
                    <a href="#" onClick={() => {
                        setdetail(record);
                        setenableCreate(true);
                        setEdit(true);
                    }}><i className="fa fa-edit"/> Cập nhật </a>,
                    key: '0',
                },
                {
                    label: 
                    <a href="#" onClick={() => {
                        deleteNguoiGioiThieu(record)
                    }}><i className="fa fa-trash"/> Xóa </a>,
                    key: '1',
                },
            ]}
        />
        )
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
        let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
            setSorting(sort);
        }
        else {
            setSorting('');
        }
    };
    
    const resetState = () => {
        setKey('')
    }

    const columnData = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: 'Mã người giới thiệu',
            dataIndex: 'code',
            align : 'center',
            key: 'code',
            width: 100,
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 100,
            render: (row, record) => {
                return (
                    <>
                    <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                        <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                    </Dropdown>
                    </>
                )          
            }
        },
        {
            title: 'Họ tên',
            dataIndex: 'hoTen',
            key: 'hoTen',
            sorter: () => {},
            width: 120,
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaySinh',
            key: 'ngaySinh',
            align: 'center',
            width: 120,
            render: (value) => {
                return <span>{value ? moment(value).format("DD/MM/YYYY") : ''}</span>;
            },
        },
        {
            title: 'CMND',
            dataIndex: 'cccd',
            key: 'cccd',
            align: 'center',
            width: 120,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienThoai',
            key: 'dienThoai',
            align: 'left',
            width: 120,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            width: 120,
        },
        {
            title: 'Hiển thị',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            align: 'center',
            width: 120,
            render : (row, record) => {
                return (
                    <Switch 
                        loading={loadingSwitch}
                        checked={row === 0}
                        onChange={(e) => { updateCheckSwitch(record, e) }} />
                )
            }
        },
        {
            title: 'Người quản lý',
            dataIndex: 'parentName',
            key: 'parentName',
            align: 'left',
            width: 120,
        },
    ]

    return <React.Fragment>
        <div className='containerChilderWrapper'>
            <HeaderModal title={'Quản lý người giới thiệu'} />
            <div className="row mb-2">
                <div className="col-md-12 col-sm-12">
                    <label>Từ khóa</label>
                    <Input
                        placeholder="Nhập mã, tên, cccd, điện thoại người giới thiệu"
                        value={key}
                        onChange={(e) => {setKey(e.target.value)}}
                        onPressEnter={() => { setPage(1); GetListNguoiGioiThieu(); }}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-primary mr-1" onClick={() => {setPage(1); GetListNguoiGioiThieu();}}><i className="fa fa-search mr-1"></i> Tìm kiếm</button>
                    <button className="btn btn-secondary" onClick={() => {resetState()}}><i className="fa fa-eraser mr-1"></i> Xóa dữ liệu nhập</button>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 d-flex justify-content-start">
                    <button className="btn btn-color mr-1" onClick={() => {setenableCreate(true); setEdit(false);}}><i className="fa fa-plus mr-1"></i> Tạo mới</button>
                </div>
            </div>
            <div style={{ height: 'calc(100% - 260px)' }} >
                <Table
                    scroll={{ x: 'max-content', y: 0 }}
                    columns={columnData}
                    dataSource={dataSource}
                    pagination={false}
                    rowKey={(row, index) => index}
                    onChange={onTableChange}
                    locale={{
                        triggerDesc: "Bấm để sắp xếp giảm dần",
                        triggerAsc: "Bấm để sắp xếp tăng dần",
                        cancelSort: "Bấm để hủy bỏ sắp xếp",
                    }}
                />
            </div>
            <div className='row'>
                <div className='col-12 py-1'>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                        total={totalRecord}
                        showTotal={total => `Tổng: ${total}`}
                        size={size}
                        current={page}
                        onChange={(page, size) => {
                            setPage(page);
                            setSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setPage(page);
                            setSize(size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
        </div>

        <Modal
            width={800}
            visible={enableCreate}
            destroyOnClose={true}
            onCancel={() => {
                setenableCreate(false)
            }}
        >
            <Create reload={() => { GetListNguoiGioiThieu() }} detail={detail} edit={Edit} onClose={() => {setenableCreate(false); setdetail(null);}} />
        </Modal>
    </React.Fragment>
}

export default NguoiGioiThieu;