import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import HeaderModal from '../../configureHIS/common/headerModal';
import PatientInfo from "../../screens/patient/component/patientInfo";
import VitalSignInfo from "../../screens/patient/component/vitalSign";
import History from '../../components/history/indexNew';
import * as TestReceivedServices from '../../services/testReceivedService';
import TestReceiving from "./testReceiving";
import { Collapse } from 'antd';
import { isNullOrEmpty } from '../../libs/util';
import { HISTORYFEATURE } from '../../configureHIS/common/constants';

const { Panel } = Collapse;

function Detail(props) {
    const historyRef = useRef();
    const _notiRef = useRef();
    const [data, setData] = useState(props.data);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    useEffect(() => {
        getDetail();
    }, []);

    const getDetail = () => {
        TestReceivedServices.getTestReceivedByOrderId(data.orderId).then(result => {
            if (result.isSuccess) {
                _notiRef && _notiRef.current.Close();
                if (result.data)
                    setData(result.data);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }
    const onSubmit = () => {
        getDetail();
        props.handleFocusSID && props.handleFocusSID();
    }
    const onCancel = () => {
        props.onCancel && props.onCancel();
    }
    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={`Nhận mẫu - ${data.hoTen}${!isNullOrEmpty(data.sid) ? " - " + data.sid : ""}`} onClose={() => { props.onCancel() }} />
                <div className="list-page-body">
                    <div className="w-100"><Notify ref={_notiRef} /></div>
                    <div className="w-100 h-100 d-flex justify-content-between">
                        <div className='left-panel'>
                            <Collapse defaultActiveKey={['pif', 'vts', 'htr']} expandIconPosition="right">
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={data.maBN} col={12}></PatientInfo>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>} key="vts">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <VitalSignInfo maql={data.maQL} col={12}></VitalSignInfo>
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="htr">
                                    <div className="w-100 my-2" style={{ lineHeight: "20px" }}>
                                        <History 
                                            ref={historyRef} 
                                            iShowResult={true} 
                                            patientID={data.maBN}
                                            feature= {[HISTORYFEATURE.XETNGHIEM]} />  
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="right-panel">
                            <div className='h-100 mx-2 border position-relative'>
                                <Collapse defaultActiveKey={['xnlm']} expandIconPosition="right">
                                    <Panel header={<label className='mita-header-title'>Xác nhận nhận mẫu</label>} key="xnlm">
                                        <TestReceiving 
                                            data={data} 
                                            reload={() => { getDetail() }} 
                                            onCancel={() => { onCancel() }} 
                                            onSubmit={() => { onSubmit()}}>
                                        </TestReceiving>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Detail;