import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Button, DatePicker, Pagination, Table, Select } from 'antd';
import * as Actions from '../../../libs/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Statistic from '../../../services/statisticService';
import * as SystemConfig from "../../../configure/systemConfig";
import HeaderModal from '../../../configureHIS/common/headerModal';
import SiteFilter from '../../../configureHIS/common/siteFilter';
import { isnotnull } from '../../../libs/util';

const { RangePicker } = DatePicker;
const { Option } = Select;

function ThongKeLayMauTaiNha(props) {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment().subtract(7, "days"), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [userCreate, setUserCreate] = useState(null);
    const [listUserCreate, setListUserCreate] = useState([]);
    const [status, setStatus] = useState(null);
    const [listStatus,] = useState([
        {
            value: 1,
            label: "Hoàn tất",
        },
        {
            value: 2,
            label: "Kỹ thuật viên đã từ chối",
        },
        {
            value: 3,
            label: "Kỹ thuật viên đã lấy mẫu",
        },
        {
            value: 4,
            label: "Kỹ thuật viên đã check-in",
        },
        {
            value: 5,
            label: "Kỹ thuật viên đã xác nhận lịch",
        },
        {
            value: 6,
            label: "Quá giờ chờ xác nhận/chỉ định KTV",
        },
        {
            value: 7,
            label: "Đã khởi tạo lịch",
        },
        {
            value: 8,
            label: "Quá giờ hẹn (KTV chưa thực hiện)",
        }
    ]);
    const [site, setSite] = useState([]);

    const ThongKeLayMauTaiNha = (pag = page, siz = size) => {
        Actions.setLoading(true);
        Statistic.ThongKeLayMauTaiNha(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), status, userCreate, pag, siz, site.toString()).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecord(res.totalRecords);
            } else {
                setDataSource([]);
                setTotalRecord(0);
            }
        }).catch(() => {
            setDataSource([]);
            setTotalRecord(0);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKeLichLayMauTaiNha = () => {
        Actions.setLoading(true);
        Statistic.ExportThongKeLichLayMauTaiNha(date[0].format("yyyy/MM/DD"), date[1].format("yyyy/MM/DD"), status, userCreate, site.toString()).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "DanhSachLichHenLayMau";
                _hiddenLink.current.click();
            })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    useEffect(() => {
        if (isnotnull(site)) {
            ThongKeLayMauTaiNha();
            GetDanhSachNguoiTao();
        }
    }, [site])

    const GetDanhSachNguoiTao = () => {
        Statistic.GetCreateUserList(site.toString()).then(res => {
            if (res.isSuccess) setListUserCreate(res.data);
            else setListUserCreate([]);
        }).catch(() => {})
    }

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 70
        },
        {
            title: "Ngày tạo",
            dataIndex: "dateCreate",
            key: "dateCreate",
            align: "center",
            width: 100,
            render: (day) => {
                return <span>{day && moment(day).format(SystemConfig.FORMAT_TIME_DATE)}</span>
            }
        },
        {
            title: "Người tạo",
            dataIndex: "userCreate",
            key: "userCreate",
            align: "left",
            width: 110,
        },
        {
            title: "Mã khách hàng",
            dataIndex: "patientID",
            key: "patientID",
            align: "center",
            width: 100,
        },
        {
            title: "Tên khách hàng",
            dataIndex: "patientName",
            key: "patientName",
            align: "left",
            width: 120,
        },
        // {
        //     title: "Địa chỉ khách hàng",
        //     dataIndex: "patientAddress",
        //     key: "patientAddress",
        //     align: "left",
        //     width: 120,
        //     // render: (val) => {
        //     //     return (
        //     //         <MitaDisplayLongText
        //     //             content={val}
        //     //             maxLength={50}
        //     //             showMoreText={'...xem thêm'}
        //     //             showLessText={' rút gọn'}
        //     //         />
        //     //     )
        //     // }
        // },
        {
            title: "Ngày hẹn",
            dataIndex: "appointmentDate",
            key: "appointmentDate",
            align: "center",
            width: 100,
            render: (day) => {
                return <span>{day && moment(day).format(SystemConfig.FORMAT_TIME_DATE)}</span>
            }
        },
        {
            title: "Địa chỉ lấy mẫu",
            dataIndex: "location",
            key: "location",
            align: "left",
            width: 120,
            // render: (val) => {
            //     return (
            //         <MitaDisplayLongText
            //             content={val}
            //             maxLength={50}
            //             showMoreText={'...xem thêm'}
            //             showLessText={' rút gọn'}
            //         />
            //     )
            // }
        },
        {
            title: "Kỹ thuật viên",
            dataIndex: "userGetName",
            key: "userGetName",
            align: "center",
            width: 100,
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 120,
        },
        {
            title: "Chi nhánh",
            dataIndex: "siteName",
            key: "siteName",
            align: "left",
            width: 120,
        }
    ];
    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <HeaderModal title="Thống kê lịch hẹn lấy mẫu" />
                <div className='row'>
                    <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <div className="mr-2">
                        <RangePicker allowClear={false} format="DD-MM-YYYY" value={date} locale={viVN}
                            onChange={(value => setDate(value))}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Select onChange={(e) => setUserCreate(e)}
                            showSearch value={userCreate} className="w-100" placeholder="Chọn người tạo lịch"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                                <Option ley="user-all" value={0}>Mọi người tạo lịch</Option>
                            {listUserCreate && listUserCreate.map((item, index) => {
                                return (
                                    <Option key={`user-${index}`} value={item.donvi}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-3'>
                        <Select className="w-100" placeholder="Chọn trạng thái" onChange={(e) => setStatus(e)} value={status}>
                            <Option ley="status-all" value={0}>Mọi trạng thái</Option>
                            {listStatus && listStatus.map((item, index) => {
                                return (
                                    <Option key={`status-${index}`} value={item.value}>{item.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="d-flex">
                        <Button className="btn btn-color"
                            onClick={() => { ThongKeLayMauTaiNha(1) }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                        <Button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { ExportThongKeLichLayMauTaiNha() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </div>
                </div>
                <div className="mt-2" style={{ width: "100%", height: 'calc(100% - 210px)' }}>
                    <Table
                        scroll={{ y: 0, x: 'max-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(row, index) => index}
                        rowClassName={(row) => {
                            if (!row.complete && row.status !== "Đã khởi tạo lịch") return "bg-warning"
                        }}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                        total={totalRecord}
                        showTotal={total => `Tổng: ${total}`}
                        size={size}
                        current={page}
                        onChange={(page, size) => {
                            setPage(page);
                            setSize(size);
                            ThongKeLayMauTaiNha(page, size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setPage(1);
                            setSize(size);
                            // ThongKeLayMauTaiNha(1, size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeLayMauTaiNha)