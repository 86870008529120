import React, { Component } from 'react';
import { Tabs } from 'antd';
import DanhSachLoaiPhong from './loaiphong';
import DanhSachPhong from './phong';
import DanhSachGiuong from './giuong';
const { TabPane } = Tabs;

export default class ConfigPhongnoiTru extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{ padding: 20 }}>
                    <div className="h4 text-center mt-2">
                        <Tabs centered={true} defaultActiveKey="1">
                            <TabPane tab={"Danh sách loại phòng"} key="1">
                                <DanhSachLoaiPhong/>
                            </TabPane>
                            <TabPane tab={"Danh sách phòng"} key="2" >
                                <DanhSachPhong/>
                            </TabPane>
                            <TabPane tab={"Danh sách giường"} key="3">
                                <DanhSachGiuong/>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}