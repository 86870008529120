import moment from 'moment';
import React from 'react';
import { FORMAT_DATE } from "../configure/systemConfig";

export const ValidationCustom = {
    messages: {
        required: "Vui lòng nhập :attribute",
        numeric: "Vui lòng nhập số cho :attribute",
        max: "Vui lòng nhập :attribute tối đa :max",
        min: "Vui lòng nhập :attribute tối thiểu :min",
        between: "Vui lòng nhập trong khoảng :min-:max"
    },
    element: message => <div style={{ fontSize: "14px", color: "#FF4D4F" }}>{message}</div>,

    validators: {
        //Validator số điện thoại di động Việt Nam bắt đầu là +84 hoặc 0, sau đó là đầu số nhà mạng 3,5,7,8,9, sau cùng là 8 số còn lại
        //Số điện thoại bàn 11 số: bắt đầu là +84 hoặc 02, tiếp theo là mã tỉnh và cuối cùng là 8 số còn lại
        phonenumber: {
            message: 'Vui lòng nhập đúng định dạng số điện thoại',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^(\+84|02[0-9]|0[3|5|7|8|9])+([0-9]{8})\b/g) && params.indexOf(val) === -1
            },
            required: false
        },
        //Chứng minh thư từ 9-13 số
        cmnd: {
            message: 'Nhập sai định dạng căn cước công dân',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^([0-9]{9,13})\b/g) && params.indexOf(val) === -1
            },
            required: false
        },
        //Passport bắt đầu bằng 1 chữ và từ 7 đến tối đa 11 số (chữ)
        passport: {
            message: 'Nhập sai định dạng hộ chiếu',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[a-zA-Z]+[0-9a-zA-Z]{7,11}\b/g) && params.indexOf(val) === -1
            },
            required: false
        },
        //Địa chỉ email
        email: {
            message: 'Nhập sai định dạng email',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) && params.indexOf(val) === -1
            },
            required: false
        },
        maxLen: {
            message: "Nhập :attribute tối đa :max :type",
            rule: (val, params) => val.length <= parseFloat(params[0]),
            messageReplace: (message, params) => message.replace(':max', params[0]).replace(':type', params[1] === 'num' ? "số" : "kí tự")
        },
        minLen: {
            message: "Nhập :attribute tối thiểu :min :type",
            rule: (val, params) => val.length >= parseFloat(params[0]),
            messageReplace: (message, params) => message.replace(':min', params[0]).replace(':type', params[1] === 'num' ? "số" : "kí tự")
        },
        //Thẻ bảo hiểm y tế
        bhyt: {
            message: 'Vui lòng nhập đúng định dạng số BHYT',
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^[\w]{2}[0-9]{1}[0-9]{2}[0-9]{10}$|^[0-9]{10}$/g) && params.indexOf(val) === -1
            },
        },
        //Hết hạn thẻ BHYT
        expired: {
            message: 'Cảnh báo: :attribute đã hết',
            rule: val => val && moment(val) > moment()
        },
        expiredRange: {
            message: "Vui lòng nhập :attribute sau :start",
            rule: (val, params) => val >= params[0],
            messageReplace: (message, params) => {
                let start = parseFloat(params[0]);
                return message.replace(':start', moment(start).format(params[1] ?? FORMAT_DATE));
            },
        },
        selection: {
            required: true,
            message: "Vui lòng chọn :attribute",
            rule: (val) => val !== "" && val.toString().trim() !== "",
        },
        url: {
            required: true,
            message: "Vui lòng nhập :attribute đúng định dạng",
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) && params.indexOf(val) === -1
            },
        }
    }
}