import React from "react";
import './main.css';
// import TreeView from './treeView'
import NewTree from './newTree/indexByApi';
//import NewTree from './newTree/indexNew';

export default class NavLeftSlideBar extends React.Component {

    render() {
        return (
            <nav className="sidebar-nav">
                {this.props.isOpen === true &&
                    <NewTree />
                }
            </nav>
        );
    }
}
