import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import ReactDOM from "react-dom";
import { Input, InputNumber, Button, Table, notification, Modal, Popconfirm, Pagination, Dropdown, Menu, Select, Tag, Drawer } from 'antd';
import * as Actions from '../../../libs/actions';
import * as CardTypeService from '../../../services/CardTypeService';
import * as CardAccountService from '../../../services/CardAccountService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import CardAccountDetail from './detail';
import CardList from '../card/index';
import CardDetail from '../card/detail';
import CardHistory from "../cardhistory/index";

const { Option } = Select;
export default forwardRef((props, ref) => {
  //biến cục bộ
  const showMoreText='...Xem thêm';
  const showLessText=' Rút gọn';
  const notiRef = useRef(null);
  const detailRef = useRef();
  const deletePopupRef = useRef([]);
  const firstRender = useRef(true);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [listCardType, setListCardType] = useState([]);
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [cardTypeIds, setCardTypeIds] = useState(props.cardTypeId ? [props.cardTypeId] : []);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [selectedId, setSelectedId] = useState(); // set visible modal create/update/detail
  const [visibleCardList, setVisibleCardList] = useState(false); 
  const [visibleCardDetail, setVisibleCardDetail] = useState(false);
  const [visibleCardHistory, setVisibleCardHistory] = useState(false); 
  const [cardAccountSelected, setCardAccountSelected] = useState();
  //table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (props.cardTypeId)
      setCardTypeIds([props.cardTypeId]);
  }, [props.cardTypeId])

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  // useEffect(() => {
  //   if (firstRender) return;
  //   resetToFirstPage();
  // }, [cardTypeIds])

  useEffect(() => {
    getListCardType();
    resetToFirstPage();
    firstRender.current = false;
  }, []);
  //#endregion 
  //#region search & filter
  const getListCardType = () => {
    // Actions.setLoading(true);
    let filterModel = {
      skipCount: 0,
      maxResultCount: 1000,
      sorting: 'cardTypeName',
      keyword: '',
      // isActives: [true],
      getInclude: false
    }
    CardTypeService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setListCardType(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        // Actions.setLoading(false);
    })
  }

  const search = (isResetSelectedRow = false) => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardTypeIds: cardTypeIds
    }
    CardAccountService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        if (isResetSelectedRow) setSelectedRowKeys([]);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    else setSelectedId(null) ;
    setDetailScreenMode(detailScrMode);
    setVisibleDetail(true);
  }

  const loadCardCreate = (row) => {//đăng ký card
    if (row) setSelectedId(row.id);
    setCardAccountSelected(row);
    setVisibleCardDetail(true);
  }

  const loadCardList= (row) => {//list card
    if (row) setSelectedId(row.id);
    setCardAccountSelected(row);
    setVisibleCardList(true);
  }

  const loadCardHistory= (row) => {//list card
    if (row) setSelectedId(row.id);
    setCardAccountSelected(row);
    setVisibleCardHistory(true);
  }

  const onDeleteClickHandle = (id) => {
    Actions.setLoading(true);
    CardAccountService.deleteById(id).then(result => {
      if (result.isSuccess) {
        search();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };
  
  const onExportListClickHandle = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: 0,
      maxResultCount: 0,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardTypeIds: cardTypeIds
    }
    CardAccountService.getListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "DanhSachTaiKhoanThe_"+moment(new Date()).format('YYMMDDhhmmss');
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  useEffect(() => {
    // console.log(`selectedRowKeys useEffect: ${selectedRowKeys}`);
  }, [selectedRowKeys])

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let newSelecteds = selectedRowKeys.filter(x => x != record.id);
      if (selected) {
        newSelecteds.push(record.id);
      }
      setSelectedRowKeys(newSelecteds);
      return true;
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      let selectedRowIds = changeRows.map(c => c.id);
      let newSelecteds = selectedRowKeys.filter(x => selectedRowIds.indexOf(x) < 0);
      if (selected) {
        newSelecteds = [...newSelecteds,...changeRows.map(x => x.id)];
        newSelecteds = Array.from(new Set(newSelecteds.map((item) => item)));
      }
      setSelectedRowKeys(newSelecteds);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) search();
  };

  //các options cho row
  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                loadCardCreate(record);
              }}><i className='far fa-address-card mr-2'/> Mở thẻ</a>,
            key: '0',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadDetail(record, detailScreenModeEnum.update);
              }}><i className='fas fa-pencil-alt mr-2'/> Chỉnh sửa</a>,
            key: 'edit',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadCardList(record);
              }}><i className='far fa-address-book mr-2'/> Danh sách mở thẻ</a>,
            key: '1',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadCardHistory(record);
              }}><i className='fa fa-history mr-2'/> Lịch sử áp dụng</a>,
            key: '2',
          },
          {
            label: 
              <a href="#" onClick={() => { deletePopupRef.current[record.id].click() }}
              ><i className='fas fa-trash-alt mr-2'/> Xóa tài khoản</a>
              ,
            key: '3',
          },
        ]}
      />
    )
  }

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 30,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      // Table.SELECTION_COLUMN,
      {
          title: 'Số tài khoản',
          dataIndex: 'cardAccountCode',
          key: 'cardAccountCode',
          align: 'left',
          width: 120,
          sorter: () => {},
          render: (value, record) => {
            return (
              <>
                <a href="#"
                  onClick={() => {
                    loadDetail(record, detailScreenModeEnum.detail);
                  }}
                >{value}
                </a>
                <Popconfirm
                    title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{value}</b>?</span>)}}
                    onConfirm={() => onDeleteClickHandle(record.id)}
                    onCancel={() => {}}
                    okText="Đồng ý"
                    cancelText="Hủy"
                  >
                    <a ref={e => deletePopupRef.current[record.id] = e}></a>
                  </Popconfirm>
                </>
            )
          }
      },
      {
          title: '',
          align: 'left',
          width: 25,
          render: (value, record) => {
              return (
                <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                  <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                </Dropdown>
              )      
          }
      },
      {
          title: 'Tên chủ tài khoản',
          dataIndex: 'accountName',
          key: 'accountName',
          align: 'left',
          // width: 180,
          sorter: () => {},
          render: (value, record) => {
              return (
                <span>{value}</span>
              )      
          }
      },
      {
          title: 'Số hợp đồng',
          dataIndex: 'contractNo',
          key: 'contractNo',
          align: 'left',
          width: 140,
          sorter: () => {},
          render: (value, record) => {
              return (
                <span>{value}</span>
              )      
          }
      },
      {
          title: 'Tên loại thẻ',
          dataIndex: 'cardTypeName',
          key: 'cardTypeName',
          align: 'left',
          width: 160,
          sorter: () => {},
          render: (value, record) => {
              return (
                // <span>{value}</span>
                <MitaDisplayLongText
                  // content={() => geneRemark(record.remarkBonus, record.remarkOther, record.remarkPreferential, record.remarkServiceConcat) }
                  content={value}
                  maxLength={30} // mặc định 64
                  showMoreText={showMoreText}
                  showLessText={showLessText}
                  // displayMode="popup"
                />
              )      
          }
      },
      {
          title: 'Điện thoại',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
          align: 'left',
          width: 100,
          render: (value, record) => {
              return (
                <span>{value}</span>
              )
          }
      },
      {
          title: 'Giá trị',
          dataIndex: 'debitLimit',
          key: 'debitLimit',
          align: 'right',
          width: 100,
          render: (value, record, index) => {
              return <span>{ value.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Sử dụng',
          dataIndex: 'valueUsed',
          key: 'valueUsed',
          align: 'center',
          width: 100,
          render: (value) => {
            return <span>{ value?.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Còn lại',
          dataIndex: 'valueUsed',
          key: 'valueUsed',
          align: 'center',
          width: 100,
          render: (value, record) => {
            return <span>{(record.debitLimit - record.valueUsed).toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Hoạt động',
          dataIndex: 'isActive',
          key: 'isActive',
          align: 'center',
          width: 70,
          render: (value, record) => {
              return (
                <span>{value ? "Có": "Không" }</span>
              )
          }
      },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách Tài khoản thẻ trả trước</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                <div className="col-md-6 d-flex">
                  <Input
                    placeholder="Tìm theo số tài khoản, tên chủ tài khoản, số hợp đồng"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
                <div className="col-md-6">
                  <Select
                    mode="multiple"
                    allowClear
                    className="w-100"
                    placeholder="Chọn loại thẻ"
                    maxTagCount= 'responsive'
                    value={ cardTypeIds }
                    onChange={(value) => {setCardTypeIds(value);}}
                  >
                  {(listCardType ?? []).map((record, index) => {
                      return (
                          <Option key={record.id} value={record.id}>{record.cardTypeName}</Option>
                      )
                  })}
                  </Select>
                </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-12 d-flex justify-content-end'>
                    <button className="btn btn-color"
                        onClick={() => { resetToFirstPage(); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm</button>
                    <Button className="btn btn-light ml-2"
                        onClick={() => { setCardTypeIds([]); setKeyword(null); }}
                    > <i style={{ marginRight: 5 }} className="fa fa-eraser" />Xóa dữ liệu nhập</Button>
                  </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          loadDetail(null, detailScreenModeEnum.create);
                        }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
                  <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  </div>
              </div>
            </div>
            <div className="list-page-table">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        closable={false}
        mask={false}
        width={700}
        placement="right"
        // onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <CardAccountDetail ref={detailRef}
          isDialog={ true } 
          cardAccountId={selectedId}
          detailScreenMode = {detailScreenMode}
          onCancel={(reload = false) => {handleCancel(reload)}}/>
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={700}
        placement="right"
        visible={visibleCardDetail}
        destroyOnClose={true}
      >
        <CardDetail
          isDialog={ true } 
          cardAccountId={selectedId}
          detailScreenMode = {detailScreenModeEnum.create}
          // createMode = {createCardModeEnum.}
          cardAccountSelected = {cardAccountSelected}

          onCancel={() => {setVisibleCardDetail(false)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={1200}
        placement="right"
        visible={visibleCardList}
        destroyOnClose={true}
      >
        <CardList
          isDialog={ true } 
          accountName={cardAccountSelected?.accountName}
          cardAccountId={selectedId}
          onCancel={() => {setVisibleCardList(false)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        closable={false}
        width={1200}
        placement="right"
        visible={visibleCardHistory}
        destroyOnClose={true}
      >
        <CardHistory
          isDialog={ true } 
          cardAccountId={selectedId}
          onCancel={() => {setVisibleCardHistory(false)}}
        />
      </Drawer>
    </React.Fragment>
  );
})