import { Form, Input, Modal, notification, Popconfirm, Select, Table } from 'antd';
import React, { Component } from 'react'
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService'
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';

const { Option } = Select;
export default class lienhe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            relativeName: '',
            relativeAddress: '',
            relativePhone: '',
            relative: '',
            mabn: '',
            lstQuanhe: [],
            change: false,
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    static getDerivedStateFromProps(props, state) {
        if (props.visible !== undefined) {
            return {
                visible: props.visible,
                mabn: props.mabn,
                change: true,
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.state.visible) {
                this.DanhSachLienHe();
            }
        }
    }

    DanhSachLienHe() {
        PatientService.DanhSachQuanHe(this.state.mabn).then(result => {
            if (result.isSuccess) {
                this.setState({ lstQuanhe: result.data });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    ThemQuanHe() {
        Actions.setLoading(true);
        if (this.validator.allValid()) {
            const data = {
                'relative': this.state.relative,
                'relativeName': this.state.relativeName,
                'relativeAddress': this.state.relativeAddress,
                'relativePhone': this.state.relativePhone,
                'code': this.state.mabn,
            }
            PatientService.ThemQuanHe(data).then(result => {
                Actions.setLoading(false);
                if (result.isSuccess) {
                    notification.success({ message: 'Thêm thành công' });
                    this.DanhSachLienHe();
                    this.resetState();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })
        } else {
            Actions.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    XoaQuanHe(mabn, ngay) {
        const data = {
            'code': mabn,
            'dateUpdate': ngay,
        }
        PatientService.XoaQuanHe(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                this.DanhSachLienHe();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    resetState() {
        this.validator.hideMessages();
        this.setState({
            relativeName: null,
            relativeAddress: null,
            relativePhone: null,
            relative: null
        });
    }

    render() {
        const columns = [
            {
                title: 'Quan hệ',
                dataIndex: 'relative',
                key: 'relative',
            },
            {
                title: 'Tên',
                dataIndex: 'relativeName',
                key: 'relativeName',
            },
            {
                title: 'Điện thoại',
                dataIndex: 'relativePhone',
                key: 'relativePhone',
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'relativeAddress',
                key: 'relativeAddress',
            },
            {
                title: 'Ngày',
                dataIndex: 'dateUpdate',
                key: 'dateUpdate',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm:ss')}</span>;
                }
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <Popconfirm title="Bạn có chắc muốn xóa người này?"
                            onConfirm={() => this.XoaQuanHe(this.state.mabn, row.dateUpdate)}
                            okText="Đồng ý"
                            cancelText="Hủy">
                            <button className="btn btn-danger" title="Xóa người liên hệ">
                                <i className="fa fa-trash" style={{ marginRight: 5 }}></i>Xóa
                            </button>
                        </Popconfirm>
                    )
                }
            },
        ]
        return (
            <React.Fragment>
                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="row no-gutters" style={{ display: 'flex' }}>
                        <div className="col-md-6 mt-2">
                            <Form.Item label="Họ tên">
                                <Input value={this.state.relativeName} placeholder="Họ tên"
                                    onChange={(e) => { this.setState({ relativeName: e.target.value }) }}
                                />
                                {this.validator.message('Tên người liên hệ', this.state.relativeName, 'required')}
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mt-2">
                            <Form.Item label="Địa chỉ">
                                <Input value={this.state.relativeAddress} placeholder="Địa chỉ"
                                    onChange={(e) => { this.setState({ relativeAddress: e.target.value }) }}
                                />
                                {this.validator.message('Địa chỉ', this.state.relativeAddress, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ display: 'flex' }}>
                        <div className="col-md-6 mt-2">
                            <Form.Item label="Điện Thoại" >
                                <Input
                                    name='phone'
                                    placeholder="Số điện thoại người liên hệ"
                                    minLength={10}
                                    maxLength={10}
                                    value={this.state.relativePhone}
                                    onChange={(e) => {
                                        this.setState({ relativePhone: e.target.value });
                                    }}
                                />
                                {this.validator.message('Số điện thoại', this.state.relativePhone, 'required|phonenumber')}
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mt-2">
                            <Form.Item label="Quan hệ">
                                <Select placeholder="Chọn quan hệ" value={this.state.relative} onChange={(value) => { this.setState({ relative: value }) }}>
                                    <Option key={"cha"} value={"Cha"}>Cha</Option>
                                    <Option key={"mẹ"} value={"Mẹ"}>Mẹ</Option>
                                    <Option key={"vợ"} value={"Vợ"}>Vợ</Option>
                                    <Option key={"chồng"} value={"Vhồng"}>Chồng</Option>
                                    <Option key={"anh/chị"} value={"Anh/Chị"}>Anh/Chị</Option>
                                    <Option key={"khác"} value={"Khác"}>Khác</Option>
                                </Select>
                                {this.validator.message('Quan hệ', this.state.relative, 'required')}
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <button className="btn btn-success" onClick={() => { this.ThemQuanHe() }}> <i className="fa fa-plus" style={{ marginRight: 5 }} />Tạo mới</button>
                    </div>
                    <div className="table-responsive" style={{ paddingTop: 10 }}>
                        <Table pagination={false} scroll={{ x: 'max-content', y: 0 }}
                            dataSource={this.state.lstQuanhe} columns={columns} rowKey={(row, index) => index} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                        <button className="btn btn-danger" style={{ marginLeft: 5 }}
                            onClick={() => {
                                this.props.closeModal();
                                this.resetState();
                                this.validator.hideMessageFor('Số điện thoại');
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
