import React, { Component } from 'react';
import { Form, Input, Table, Switch, Popconfirm, Modal, notification, Select, Pagination, InputNumber } from 'antd';
import * as cfgService from '../../../services/configService';
import CreateSuatAn from '../components/createNewSuatAn';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
let task = null;
const initialState = {
    visible: false,
    visibleCreate: false,
    totalRecords: 0
}
const { Option } = Select;
export default class DanhSachSuatAn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            currentPage: 1,
            pageSize: 10,
            lstSuatAn: [],
            key: "",
            id: 0,
            tensuatan: "",
            dongia: 0.0,
            loai: 1,
            tenloai: "",
            loadingHide: 0
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadSuatAn();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        })
    }

    reset() {
        this.state({
            lstSuatAn: [],
            key: "",
            id: 0,
            tensuatan: "",
            dongia: 0.0,
            loai: 1,
            tenloai: "",
            loadingHide: 0
        });
        this.form.setFieldsValue({
            'tensuatan': '',
            'tenloai': ''
        })
    }
    onReload = () => {
        this.loadSuatAn();
    }
    loadSuatAn(page = this.state.currentPage, size = this.state.pageSize) {
        cfgService.GetSuatAn(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstSuatAn: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        })
    }
    updateSuatAn() {
        if (this.validator.allValid()) {
            const data = {
                'id': this.state.id,
                'ten': this.state.tensuatan,
                'dongia': this.state.dongia,
                'loai': this.state.loai
            }
            cfgService.updateSuatAn(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    let list = this.state.lstSuatAn;
                    this.setState({ lstSuatAn: list });
                    this.onReload();
                    this.handleCancel();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    updateHide(id, check) {
        this.setState({ loadingHide: true })
        let status = 0;
        if (check === false) {
            status = 1;
        }
        cfgService.UpdateHideSuatAn(id, status).then(result => {
            let data = this.state.lstSuatAn;
            let item = data.find(x => x.id === id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienthi === 1 ? 0 : 1;
                    item.hienthi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstSuatAn: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstSuatAn;
            let item = data.find(x => x.id === id);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingHide: false, lstSuatAn: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    deleteSuatAn(idSuatAn) {
        cfgService.deleteSuatAn(idSuatAn).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstSuatAn;
                this.setState({ lstSuatAn: list });
                this.onReload(0);
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + (Number(index) + 1))
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left'
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Loại',
                dataIndex: 'tenloai',
                key: 'tenloai',
                align: 'center'
            },
            {
                title: 'Hiển thị',
                align: 'center',
                width: 200,
                render: (row) => {
                    return (
                        <Switch
                            loading={this.state.loadingHide}
                            checkedChildren="Hiển thị"
                            unCheckedChildren="Ẩn"
                            checked={row.hienthi === 0}
                            onChange={(e) => { this.updateHide(row.id, e); }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="btn btn-warning"
                                onClick={() => {
                                    this.setState({
                                        id: row.id, tensuatan: row.ten, dongia: row.dongia, loai: row.loai,
                                        tenloai: row.tenloai, visible: true
                                    });
                                    this.form.setFieldsValue({
                                        tenloai: row.tenloai
                                    })

                                }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteSuatAn(row.id) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        let dataSource = this.state.lstSuatAn.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className='h4 text-center mt-4 font-bold text-uppercase'>Cấu hình danh mục suất ăn</div>
                    <div style={{ padding: '10px' }}>
                        <div
                            className="row no-gutters"
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Form.Item>
                                <Input
                                    placeholder="Nhập tên suất ăn để tìm kiếm"
                                    value={this.state.key}
                                    style={{
                                        width: 400
                                    }}
                                    onChange={(e) => this.setState({
                                        key: e.target.value
                                    })}
                                    onKeyDown={() => {
                                        task = setTimeout(() => {
                                            this.loadSuatAn();
                                        }, 300);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}>
                                    <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                                    Tạo mới
                                </button>
                            </Form.Item>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                             scroll={{ y: 0 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: "max-content" }}
                            />
                        </div>
                        <Modal
                            maskClosable={false}
                            width={600}
                            visible={this.state.visibleCreate}
                            cancelText="Đóng"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <CreateSuatAn
                                onCancel={this.handleCancel}
                                onReload={this.onReload}
                            />
                        </Modal>
                        <Modal maskClosable={true}
                            width={600}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Cập nhật suất ăn</h3>
                            </div>
                            <div className="row" style={{ display: 'flex', justifyContent: 'center' }} >
                                <div className="col-md-12" style={{ padding: 10 }}>
                                    <Form.Item label="Tên suất ăn:">
                                        <Input
                                            name="tensuatan"
                                            value={this.state.tensuatan}
                                            onChange={(e) => this.setState({ tensuatan: e.target.value })}
                                        />
                                        {this.validator.message("Tên suất ăn", this.state.tensuatan, "required")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-12" style={{ padding: 10 }}>
                                    <Form.Item label="Đơn giá:">
                                        <InputNumber
                                            name="dongia"
                                            className="w-100"
                                            style={{ width: '200px' }}
                                            value={this.state.dongia}
                                            min={0}
                                            max={9999999}
                                            maxLength={8}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(e) => this.setState({ dongia: e })}
                                        />
                                        {this.validator.message("Đơn giá", this.state.dongia, "required|numeric|max:99999999,num|min:0,num")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-12" style={{ padding: 10 }}>
                                    <Form.Item label="Loại:">
                                        <Select
                                            name="tenloai"
                                            className="w-100"
                                            value={this.state.loai}
                                            placeholder="Chọn loại"
                                            style={{ width: 120 }}
                                            onChange={(e) => this.setState({ loai: Number(e) })}>
                                            <Option value={1}>Cơm - Cháo</Option>
                                            <Option value={2}>Sữa - Bột</Option>
                                        </Select>
                                        {this.validator.message("Loại suất ăn", this.state.loai, "required")}
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button
                                    className="btn btn-success"
                                    onClick={() => this.updateSuatAn()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({ visible: false });
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadSuatAn(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadSuatAn(page, size)
                            }
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}