import { Collapse, DatePicker, Form, notification, Select, Table, Input } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../libs/actions';
import * as StatisticService from '../../services/statisticService';
import SimpleReactValidator from 'simple-react-validator';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
export default class KiemTraXuatKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            makho: 0,
            lstKho: [],
            tongtien: 0,
            key: '',
            tenbn: '',
        };
    }
    componentWillMount() {
        this.LoadThongKeXuatKho(0);
    }
    LoadThongKeXuatKho() {
        Actions.setLoading(true);
        StatisticService.ThongKeKiemTraXuatKho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.makho, this.state.key, this.state.tenbn).then(result => {
                if (result.isSuccess) {
                    let tong = 0;
                    result.data.map(x => x.sort === '3' ? tong += 0 : tong += x.tongtien)
                    this.setState({
                        dataSource: result.data,
                        tongtien: tong,
                    });
                }
                else {
                    this.setState({
                        dataSource: [],
                    });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    async ExportKiemTraXuatKho() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            if (this.validator.allValid()) {
                Actions.setLoading(true);
                StatisticService.ExportKiemTraXuatkho(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
                    this.state.makho, this.state.key, this.state.tenbn)
                    .then((response) => {
                        response.blob().then((blob) => {
                            let url = window.URL.createObjectURL(blob);
                            this._hiddenLink.href = url;
                            this._hiddenLink.download = "ThongKeXuatKhoQuayThuoc";
                            this._hiddenLink.click();
                        })
                        Actions.setLoading(false);
                    }).catch(err => {
                        Actions.setLoading(false);
                    })
            }
            else {
                this.validator.showMessages();
            }
        }
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
            },
            {
                title: 'Hãng',
                dataIndex: 'tenhang',
                key: 'tenhang',
                align: 'left',
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
            },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>Loại</span>,
            //     dataIndex: 'tenloai',
            //     key: 'tenloai',
            //     align: 'left',
            // },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left'
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                // render: (value) => {
                //     return <span>{value.toLocaleString('en-US')}</span>;
                // },
            },
            {
                title: 'Giá bán',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                // render: (value) => {
                //     return <span>{value.toLocaleString('en-US')}</span>;
                // },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                // render: (value) => {
                //     return <span>{value.toLocaleString('en-US')}</span>;
                // },
            },
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'center',
            },
            {
                title: 'Loại',
                dataIndex: 'loai',
                key: 'loai',
                align: 'left',
            },
            {
                title: 'Họ tên',
                dataIndex: 'tenbn',
                key: 'tenbn',
                align: 'left',
            },
            {
                title: 'Chi tiết',
                dataIndex: 'lstData',
                key: 'lstData',
                render: (row) => {

                    return (
                        <Collapse className="remove-space-statistic">
                            <Panel header="Xem chi tiết">
                                <Table
                                    style={{ margin: '0 !important' }}
                                    pagination={false}
                                    // pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                    dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
                            </Panel>
                        </Collapse>
                    );
                },
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                align: 'right',
                render: (text, record, index) => {
                    return <span>{record.sort === '3' ? '0' : (record.tongtien).toLocaleString('en-US')}</span>;
                },
            }
        ]
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ padding: '20px' }}>
                        <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                            <div className="col-auto mb-2">
                                <label>Từ ngày:</label>
                                <Form.Item>
                                    <RangePicker allowClear={false} disabledDate={this.disabledDate}
                                        format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value }, () => this.LoadThongKeXuatKho()))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2">
                                <label>Loại:</label>
                                <Form.Item>
                                    <Select
                                        style={{ width: "150px" }}
                                        value={this.state.makho}
                                        onChange={(e) => { this.setState({ makho: e }, () => this.LoadThongKeXuatKho()) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={0} value={0}>TẤT CẢ</Option>
                                        <Option key={1} value={1}>ĐƠN THUỐC</Option>
                                        <Option key={2} value={2}>BÁN LẺ</Option>
                                        <Option key={3} value={3}>XUẤT KHO</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <Form.Item>
                                    <Input value={this.state.key} onChange={(e) => this.setState({ key: e.target.value })} placeholder="Nhập mã hoặc tên thuốc"
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.LoadThongKeXuatKho()
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <Form.Item>
                                    <Input value={this.state.tenbn} onChange={(e) => this.setState({ tenbn: e.target.value })} placeholder="Nhập mã hoặc tên người dùng"
                                        onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                this.LoadThongKeXuatKho()
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-auto mb-2" style={{ alignSelf: 'flex-end' }}>
                                <Form.Item>
                                    <button className="btn btn-success" style={{ margin: "0px 5px" }} onClick={() => { this.LoadThongKeXuatKho() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                    <button className="btn btn-primary" onClick={() => { this.ExportKiemTraXuatKho() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                </Form.Item>
                            </div>
                            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        </div>
                        <div >
                            <div style={{ textAlign: 'right' }}>
                                <strong>
                                    Tổng tiền: {this.state.tongtien.toLocaleString('en-US')}
                                </strong>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    scroll={{ x: 'max-content', y: 0 }}
                                    pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                    dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                            </div>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
