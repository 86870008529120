import { Button, Input, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as ConfigService from '../../../../services/configService';

const { Option } = Select;
const { TextArea } = Input;
export default (props) => {

    const [edit, setEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [ten, setTen] = useState('');
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');

    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])
    useEffect(() => {
        setDetail(props.detail)
    }, [props.detail])
    useEffect(() => {
        if (detail.id) {
            setDiachi(detail.address);
            setTen(detail.hubname);
            setDienthoai(detail.phone);
        }
    }, [detail])
    const Submit = () => {
        let data = {
            hubname: ten, phone: dienthoai, address: diachi, siteid: Number(props.chinhanh)
        }
  
        if (ten.trim() === '') {
            notification.warning({ message: "Vui lòng nhập tên!" });
            return;
        }
        if (!edit) {
            Actions.setLoading(true);
            ConfigService.CreateHub(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Thêm thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err)
            })
        }
        else {
            data.id = detail.id;
            Actions.setLoading(true);
            ConfigService.UpdateHub(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" })
                    if (props.onSuccess)
                        props.onSuccess()
                } else {
                    Actions.openMessageDialog('Lỗi', result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog('Lỗi', err)
            })
        }
    }
    return (
        <React.Fragment>
            <div className="h4 text-center text-uppercase">{edit ? detail.hubname : 'Tạo mới Hub'}</div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Tên</label>
                    <Input value={ten} className="w-100"
                        placeholder="Nhập tên"
                        onChange={(e) => {
                            setTen(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Điện thoại</label>
                    <Input value={dienthoai} className="w-100"
                        placeholder="Nhập điện thoại"
                        onChange={(e) => {
                            setDienthoai(e.target.value);
                        }} />
                </div>
                <div className="col-md-12 mb-2">
                    <label>Địa chỉ</label>
                    <TextArea rows={3}
                        onChange={e => {
                            setDiachi(e.target.value)
                        }}
                        value={diachi}
                    ></TextArea>
                </div>
            </div>
            <div className="d-flex align-self-center">
                <Button className="btn btn-color mr-1"
                    onClick={() => {
                        Submit()
                    }}>
                    Lưu</Button>
                <Button
                    className="ml-1"
                    onClick={() => {
                        if (props.onClose)
                            props.onClose()
                    }}>
                    Đóng</Button>
            </div>
        </React.Fragment>
    )
}
