import React, {  useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef, Fragment } from 'react';
import { Table, Pagination, Spin, List, Popover, Button } from 'antd';
import Notify, { NotifyStatus } from '../../../../../components/notify';
import * as ReportServices from '../../../../../services/ReportService';
import * as Actions from '../../../../../libs/actions';
import * as SystemConfig from "../../../../../configure/systemConfig";
import { groupBy, isNullOrEmpty, renumber } from "../../../../../libs/util";
import Handlebars from 'handlebars';
import handlebarHelper from "../../../../../libs/handlebarHelper";
import moment from "moment";

export default forwardRef((props, ref) => {
    //#region khai báo state
    const _hiddenLink = useRef();
    const notiRef = useRef(null);
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //state paging và sorting
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    //
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        loadData: () => {
            resetToFirstPage();
        },
    }));

    useEffect(() => {
    if (firstRender.current) {
        firstRender.current = false;
        return;
        }
        getData();
    }, [pageIndex]);

    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [pageSize])

    const getData = () => {
        Actions.setLoading(true);
        setLoading(true);
        let dateFrom=moment().format('YYYY-MM-DD HH:mm');
        if (!isNullOrEmpty(props.dateFrom)) dateFrom=moment(props.dateFrom).format('YYYY-MM-DD HH:mm');
        let dateTo=moment().format('YYYY-MM-DD HH:mm');
        if (!isNullOrEmpty(props.dateTo)) dateTo=moment(props.dateTo).format('YYYY-MM-DD HH:mm');
        let filterModel = {
            siteIds: props.siteIds ?? [],
            coporationIds: props.coporationIds,
            paymentTypeIds : props.paymentTypeIds,
            dateFrom: dateFrom ?? '',
            dateTo: dateTo ?? '',
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll : false
        }
        ReportServices.RevenueReportByPatient(filterModel).then(result => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                setListResult(result.data.items);
                setTotalRevenue(result.data.returnObject)
                notiRef && notiRef.current.Close();
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const onExportExcelClinkHandle = () => {
        Actions.setLoading(true);
        let filterModel = {
            siteIds: props.siteIds ?? [],
            coporationIds: props.coporationIds,
            paymentTypeIds : props.paymentTypeIds,
            dateFrom: props.dateFrom ?? '',
            dateTo: props.dateTo ?? '',
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll : false
        }
        ReportServices.ExportRevenueReportByPatient(filterModel).then(result => {
        result.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            _hiddenLink.current.href = url;
            let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_BCDoanhThuTongHop_BenhNhan`; 
            _hiddenLink.current.download = fileName;
            _hiddenLink.current.click();
        })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
        if (pageIndex != 1) setPageIndex(1);
        else getData();
    }

    const onPrintClinkHandle = (data) => {
        Actions.setLoading(true);
        let filterModel = {
            siteIds: props.siteIds ?? [],
            coporationIds: props.coporationIds,
            paymentTypeIds : props.paymentTypeIds,
            dateFrom: props.dateFrom ?? '',
            dateTo: props.dateTo ?? '',
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll : false
        }
        ReportServices.PrintRevenueReportByPatient(filterModel).then(result => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Actions.DoPrint(template(result.data));
                }
            else Actions.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).catch(() => {
            Actions.openMessageDialog("Lỗi", "Không thể in, vui lòng thử lại sau");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 30,
            render: (text, record, index) => {
                return <span>{ (pageIndex-1) * pageSize + (index + 1) }</span>;
            }
        },
        { 
            title: 'Mã khách hàng',
            dataIndex: 'patientCode',
            key: 'patientCode',
            align: 'left',
            width: 100,
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 180,
        },
        {
            title: 'Nguồn khách hàng tổng',
            dataIndex: 'coporationParentName',
            key: 'coporationParentName',
            align: 'left',
            width: 120,
        },
        {
            title: 'Nhóm nguồn con',
            dataIndex: 'coporationChildName',
            key: 'coporationChildName',
            align: 'left',
            width: 120,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'coporationName',
            key: 'coporationName',
            align: 'left',
            width: 120,
        },
        {
            title: 'Năm sinh',
            dataIndex: 'yob',
            key: 'yob',
            align: 'center',
            width: 80,
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
            width: 80,
        },
        {
            title: 'Ngày lập',
            dataIndex: 'billDate',
            key: 'billDate',
            align: 'left',
            width: 100,
            render: (value, record) => {
                return (
                    <span>{ moment(value).format('DD/MM/YYYY HH:mm') }</span>
                )
            }
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'totalBill',
            key: 'totalBill',
            align: 'right',
            width: 100,
            render: (value, record) => {
                return (
                    <span>{ Number(value).toLocaleString('en-US') }</span>
                )
            }
        },
        {
            title: 'Giảm khác',
            dataIndex: 'discountValue',
            key: 'discountValue',
            align: 'right',
            width: 80,
            render: (value, record) => {
                return (
                    <span>{ Number(record.totalBill - record.totalAmount).toLocaleString('en-US') }</span>
                )
            }
        },
        {
            title: 'Hoàn trả',
            dataIndex: 'totalRefund',
            key: 'totalRefund',
            align: 'right',
            width: 80,
            render: (value, record) => {
                return (
                    <span>{ Number(value).toLocaleString('en-US') }</span>
                )
            }
        },
        {
            title: 'Thực thu',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'right',
            width: 80,
            render: (value, record) => {
                return (
                    <span>{ Number(value - record.totalRefund).toLocaleString('en-US') }</span>
                )
            }
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'paymentTypeName',
            key: 'paymentTypeName',
            align: 'left',
            width: 80,
            render: (value, record) => {
                let text = value;
                if (record.debtDate != null && record.debtDate != undefined) {
                    text = text.replace('Công nợ', `Công nợ (Đã thu)`);
                }
                return (
                    <span>{text}</span>
                )
            }
        },
    ];

    return (
        <Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <Spin spinning={loading} className='spin-color'>
                <div className="list-page" >
                    <div className="list-page-body">{/* body */}
                        <div className="row">{/* notification */}
                            <div className="col-md-12"><Notify ref={notiRef} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-end p-0">
                                <h4>Tổng tiền: <b>{totalRevenue.toLocaleString('en-US')}</b></h4>
                                <div>
                                    <Button className="btn btn-secondary ml-2" 
                                        type="button"
                                        onClick={() => { onPrintClinkHandle() }}>
                                        <i style={{ marginRight: 5 }} className="fas fa-print" />In
                                    </Button>
                                    <Button className="btn btn-color ml-2" 
                                        type="button" 
                                        onClick={() => { onExportExcelClinkHandle() }}>
                                        <i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                            <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                                className="table-min-h-0 table-header-1row"
                                dataSource={listResult} 
                                columns={columns}
                                locale={{ emptyText: 'Không có dữ liệu' }} 
                                scroll={{ x: 1200, y: 0 }}
                                rowKey={(e) => e.billNo + e.patientCode}
                                rowClassName={(record, rowIndex) => {
                                }}
                                pagination={false}
                            />
                            <Pagination
                                current={pageIndex}
                                size={pageSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={totalRecord}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </Fragment>
    )
});
