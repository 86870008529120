import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import Notify from '../../components/notify';
import { Radio } from 'antd';
import moment from 'moment';
import { FORMAT_TIME_DATE, FORMAT_TIME_DATE_FULL } from '../../configure/systemConfig';

export default forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isUpdate, setIsUpdate] = useState(false);
    const [managermentId, setManagermentId] = useState();
    const [username, setUsername] = useState();
    const [dateOfAdmission, setDateOfAdmission] = useState();
    const [registSource, setRegistSource] = useState();

    useImperativeHandle(ref, () => ({
        setInfo: (info) => {
            setManagermentId(info.managermentId);
            setUsername(info.username);
            setDateOfAdmission(info.dateOfAdmission);
            setRegistSource(info.registSource);
        }
    }));

    useEffect(() => {
        let info = props.data;
        if (info) {
            setManagermentId(info.managermentId);
            setUsername(info.username);
            setDateOfAdmission(info.dateOfAdmission);
            setRegistSource(info.registSource);
        }
    }, [props.data]);

    return (
        <Fragment>
            <Notify ref={notiRef} />
            <div className='row'>
                <div className='col-md-6 d-flex flex-column mt-2'>
                    <label className='mita-title'>Mã tiếp đón</label>
                    <label className='mita-value'>{managermentId}</label>
                </div>
                <div className='col-md-6 d-flex flex-column mt-2'>
                    <label className='mita-title'>Ngày giờ tạo</label>
                    <label className='mita-value'>{dateOfAdmission && moment(dateOfAdmission).format(FORMAT_TIME_DATE_FULL)}</label>
                </div>
                <div className='col-md-6 d-flex flex-column mt-2'>
                    <label className='mita-title'>Người tạo</label>
                    <label className='mita-value'>{username}</label>
                </div>
                <div className='col-md-6 d-flex flex-column mt-2'>
                    <label className='mita-title required-field'>Nguồn đăng ký</label>
                    <div className='w-100'>
                        <Radio.Group buttonStyle="solid" style={{ width: "100%" }} defaultValue={0} value={registSource}>
                            <Radio.Button style={{ width: "calc(100% / 3)", textAlign: "center" }} value={0}>Quầy tiếp tân</Radio.Button>
                            <Radio.Button style={{ width: "calc(100% / 3)", textAlign: "center" }} value={1}>Lịch hẹn</Radio.Button>
                            <Radio.Button style={{ width: "calc(100% / 3)", textAlign: "center" }} value={2}>Khám sức khỏe</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        </Fragment>
    );
})