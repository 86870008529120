import { Button, DatePicker, Form, Pagination, Input, Select, Tabs, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';

export default forwardRef((props, ref) => {
    const _hiddenLink = useRef()
    const [ngay, setngay] = useState(props.ngay);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [filter, setFilter] = useState(props.filter);
    const [isMultisite, setIsMultisite] = useState(false);

    useImperativeHandle(ref, () => ({
        reload: () => {
            ThongKeChiTietNguoiGioiThieu();
        }
    }));

    useEffect(() => {
        GetListSite();
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
        });
    }, [])

    useEffect(() => {
        ThongKeChiTietNguoiGioiThieu();
    }, [ngay, page, size, filter]);

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }

    function ThongKeChiTietNguoiGioiThieu(){
        Actions.setLoading(true);
        StatisticService.ThongKeChiTietNguoiGioiThieu(props.ngay[0].format('YYYY-MM-DD'), props.ngay[1].format('YYYY-MM-DD'), chinhanh, key, props.filter, page, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    function ExportThongKeDoanhSoBS() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeChiTietNguoiGioiThieu(props.ngay[0].format('YYYY-MM-DD'), props.ngay[1].format('YYYY-MM-DD'), chinhanh, key, props.filter)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeNguoiGioiThieu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 180,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 120
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 120
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'mabn',
            key: 'mabn',
            width: 150
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'tenbn',
            key: 'tenbn',
            width: 200
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'tongtien',
            key: 'tongtien',
            align: 'right',
            width: 150,
            render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
        },
        {
            title: 'Ngày',
            dataIndex: 'ngay',
            key: 'ngay',
            align: 'right',
            width: 200,
            render: (value) => (<span>{value ? moment(value).format('DD/MM/YYYY HH:mm') : ''} </span>)
        }
    ];
    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div style={{ padding: "0px", display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom : '10px' }}>
                <Form.Item>
                    <Button className="btn btn-color" onClick={() => { ExportThongKeDoanhSoBS() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                </Form.Item>
            </div>
            <div style={{ marginBottom: '10px', height: 'calc(100% - 140px)' }}>
                    <Table dataSource={dataSource} scroll={{ y: 0 }}
                        pagination={false}
                        columns={columns} rowKey={(e, index) => index}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                        total={totalRecord}
                        showTotal={total => `Tổng: ${total}`}
                        size={size}
                        current={page}
                        onChange={(page, size) => {
                            setPage(page);
                            setSize(size)
                        }}
                        onShowSizeChange={(page, size) => {
                            setPage(1);
                            setSize(size)
                        }
                        }
                        showSizeChanger={true}
                    />
            </div>
        </div>
        </>
    )
}
)
