import { EditFilled } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, notification, Pagination, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as PhauThuThuatService from '../../../services/phauthuthuatService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { Option } = Select;
const initialState = {
    tungay: moment(),
    denngay: moment(),
    ngaypt: moment(),
    key: '',
    totalRecords: 0,
    id: null,
    ptID: null,
    gaymeID: null,
    vocamID: null,
    ptvID: null,
    phuID: null,
    makp: null
}
export default class LichPTT extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstLich: [],
            lstMeTe: [],
            lstDoctor: [],
            lstPhuongPhap: [],
            lstKhoa: [],
            visible: false,
            currentPage: 1,
        };
    }

    componentWillMount() {
        this.loadMeTe();
        this.loadPhuongPhapPT();
        this.loadDoctor();
        this.loadKhoa();
    }
    disabledDateCurrent(current) {
        return current && current < moment().endOf('day');
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    LoadLichPT(page = 1, size = 10) {
        if (this.state.tungay == null || this.state.denngay == null) {
            notification.warning({ message: 'Yêu cầu nhập thời gian tìm kiếm!' });
            return;
        }
        PhauThuThuatService.DanhSachLichPT(this.state.key, this.state.makp, this.state.tungay.format('YYYY-MM-DD'), this.state.denngay.format('YYYY-MM-DD'), page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLich: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải danh sách lịch phẫu thuật bị lỗi!' });
        })
    }
    loadDoctor() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    loadPhuongPhapPT() {
        PhauThuThuatService.DanhSachPPhauThuat().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhuongPhap: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phương pháp phẫu thuật bị lỗi' });
        })
    }
    loadMeTe() {
        PhauThuThuatService.DanhSachMeTe().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstMeTe: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu mê tê bị lỗi' });
        })
    }
    loadKhoa() {
        PhauThuThuatService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKhoa: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu khoa bị lỗi' });
        })
    }

    async submit() {
        Actions.setLoading(true);
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                'id': this.state.id,
                'ngay': moment(this.state.ngaypt, "YYYY-MM-DD HH:mm"),
                'phuongphapID': this.state.ptID,
                'vocamID': this.state.vocamID,
                'thuthuatvienID': this.state.ptvID,
                'gaymeID': this.state.gaymeID,
                'phuID': this.state.phuID,
            }
            PhauThuThuatService.CapNhatPTT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Cập nhật thành công!" });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Có lỗi xảy ra khi cập nhật!' });
            })
        } else {
            Actions.setLoading(false);
            this.validator.showMessages();
        }
    }
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
        this.LoadLichPT();
    };

    setValue(row) {
        this.setState({
            id: row.id,
            ptID: row.mamau,
            gaymeID: row.bsgaymeID,
            vocamID: row.vocamID,
            ptvID: row.ptvID,
            phuID: row.phuID,
            ngaypt: moment(row.ngay, "YYYY-MM-DD HH:mm"),
            visible: true
        });
        this.form.setFieldsValue({
            ptID: row.mamau,
            gaymeID: row.bsgaymeID,
            vocamID: row.vocamID,
            ptvID: row.ptvID,
            phuID: row.phuID,
            ngaypt: moment(row.ngay),
        })
    }
    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Mã quản lý',
                dataIndex: 'maql',
                key: 'maql',
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Họ tên',
                dataIndex: 'hoten',
                key: 'hoten',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Tên phẫu thuật',
                dataIndex: 'tenpt',
                key: 'tenpt',
            },
            {
                title: 'Ngày phẫu thủ thuật',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>Lúc {moment(value).format('HH:mm DD-MM-YYYY')}</span>;
                },
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <Button type="primary"
                            className="btn btn-outline"
                            title="Cập nhật lịch phẫu thủ thuật!"
                            onClick={() => { this.setValue(row) }}>
                            <EditFilled />Cập nhật
                        </Button>
                    )
                }
            }
        ];
        let dataSource = this.state.lstLich.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <Form.Item label="Khoa:" style={{ flex: 1 }}>
                                <Select value={this.state.makp}
                                    showSearch
                                    width={300}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ makp: e }) }}>
                                    {this.state.lstKhoa.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.ma}>{row.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Input value={this.state.key} style={{ width: 300 }}
                                    placeholder="Nhập mã hoặc họ tên bệnh nhân..."
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.LoadLichPT();
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Từ ngày:">
                                <DatePicker locale={viVN} format="DD-MM-YYYY" allowClear={false}
                                    disabledDate={this.disabledDate}
                                    defaultValue={moment()} value={this.state.tungay}
                                    onChange={(value => this.setState({ tungay: value }))}
                                />
                            </Form.Item>
                            <Form.Item label="Đến ngày:">
                                <DatePicker locale={viVN} format="DD-MM-YYYY" allowClear={false}
                                    disabledDate={this.disabledDateCurrent}
                                    defaultValue={moment()} value={this.state.denngay}
                                    onChange={(value => this.setState({ denngay: value }))}
                                />
                            </Form.Item>
                            <Form.Item>
                            <button className="btn btn-success"
                                onClick={() => { this.LoadLichPT() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                     </Form.Item>
                        </div>
                        <Modal maskClosable={false}
                            width={1400}
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                           
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}>

                            <div style={{ marginBottom: 5, padding: 40 }}>
                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                    <Form.Item label="Ngày phẫu thuật:" style={{ flex: 1 }} name="ngaypt" rules={[{ required: true, message: 'Yêu cầu chọn ngày phẫu thuật!' }]}>
                                        <DatePicker locale={viVN} format={"DD-MM-YYYY HH:mm"}
                                            value={moment(this.state.ngaypt)}
                                            onChange={(e => this.setState({ ngaypt: e }))}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Tên phẫu thuật:" style={{ flex: 1 }} name="ptID"
                                        rules={[{ required: true, message: 'Yêu cầu chọn phương pháp phẫu thuật!' }]}>
                                        <Select value={this.state.ptID}
                                            showSearch
                                            width={300}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => { this.setState({ ptID: e }) }}>
                                            {this.state.lstPhuongPhap.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.ma}>{row.ten}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="PP vô cảm:" style={{ flex: 1 }} name="vocamID"
                                        rules={[{ required: true, message: 'Yêu cầu chọn phương pháp vô cảm!' }]}>
                                        <Select value={this.state.vocamID}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => { this.setState({ vocamID: e }) }}>
                                            {this.state.lstMeTe.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.ma}>{row.ten}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                    <Form.Item label="Phẫu thủ thuật viên:" style={{ flex: 1 }} name="ptvID"
                                        rules={[{ required: true, message: 'Yêu cầu chọn phẫu thủ thuật viên!' }]}>
                                        <Select value={this.state.ptvID}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => { this.setState({ ptvID: e }) }}>
                                            {this.state.lstDoctor.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Phụ:" style={{ flex: 1 }} name="phuID">
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => { this.setState({ phuID: e }) }}>
                                            <Option key="" value="">-----Empty-----</Option>
                                            {this.state.lstDoctor.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Bác sĩ gây mê:" style={{ flex: 1 }} name="gaymeID">
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => { this.setState({ gaymeID: e }) }}>
                                            <Option key="" value="">-----Empty-----</Option>
                                            {this.state.lstDoctor.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 10, alignContent: 'center', justifyContent: 'center' }}>
                                    <button className="btn btn-success" onClick={() => this.submit()}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                        onClick={() => {
                                            this.props.onCancel()
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                        /> Đóng

                              </button>
                                </div>
                            </div>
                        </Modal>
                        <div className="col-sm-12">
                            <Table dataSource={dataSource}
                             scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index} />
                            <Pagination
                                current={this.state.currentPage}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.LoadLichPT(page, size) }}
                                onShowSizeChange={(page, size) => this.LoadLichPT(1, size)}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
