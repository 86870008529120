import React, { Component } from 'react';
import {Switch, Popconfirm, Form, Table, Input, Pagination, Modal, notification,InputNumber, Select} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import CreateNew from '../components/createNewLoaiThanhToan';
import * as VPService from '../services/VPService';
import * as FeatureService from '../../../services/FeatureService';
import * as Actions from '../../../libs/actions';
import  {IsSuperAdmin} from '../../../configure/superAdminConfig';
import { makeArray } from 'jquery';
import HeaderModal from '../../common/headerModal';

const { Option } = Select;
const initialState = {
    totalRecords: 0,
    visible: false,
    visibleCreate: false,
}

class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                required: 'Vui lòng nhập :attribute'
            },
            element: message => <div style={{ color: 'red' }}>{message}</div>
        });
        this.state = {
            ...initialState,
            lstLoaiTT: [],
            ten: '',
            hien_thi: '',
            id: 0,
            loadingHide: false,
            currentPage: 1,
            pageSize: 10,
            key: '',
            stt: 0,
            featureId: '',
            listFeature: []
        };
    }

    componentDidMount() {
        this.loadDanhSachLoaiThanhToan();
        if (IsSuperAdmin()) this.getListFeature();
    }

    loadDanhSachLoaiThanhToan(page = this.state.currentPage, size = this.state.pageSize) {
        Actions.setLoading(true);
        VPService.lstLoaiThanhToan(this.state.key, page,size).then(result =>{
            if(result.isSuccess){
                this.setState({
                    lstLoaiTT: result.data,
                    totalRecords: result.totalRecords,
                })
            }
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    getListFeature() {
        let filterModel = {
          maxResultCount: 0,
        }
        FeatureService.getList(filterModel).then(result => {
          if (result.isSuccess) {
            this.setState({listFeature: result.data.items});
            // notiRef && notiRef.current.Close();
          }
          else {
            // notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
          }
        }).catch(err => {
          // if (err.error && err.error.messageText)
          //   notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
          // else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            // forceUpdate();
        })
      }

    updateLoaiTT(){
        const data = {
            ma : this.state.id,
            ten: this.state.ten,
            stt: this.state.stt,
        }
        if (IsSuperAdmin()) data.featureId = this.state.featureId;
        VPService.UpdateLoaiTT(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' });
                this.loadDanhSachLoaiThanhToan();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            this.resetState();
            
        }).catch(err => {
        })
    }

    updateHide(ma, check) {
        this.setState({ loadingHide: true })
        let status = 0;
        if (check === false) {
            status = 1;
        }
        VPService.UpdateHideLoaiTT(ma, status).then(result => {
            let data = this.state.lstLoaiTT;
            let item = data.find(x => x.ma === ma);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienthi === 1 ? 0 : 1;
                    item.hienthi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstLoaiTT: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstLoaiTT;
            let item = data.find(x => x.value === ma);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingHide: false, lstLoaiTT: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    DeleteLoaiTT(ma){
        VPService.DeleteLoaiTT(ma).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xoá thành công!' });
                this.loadDanhSachLoaiThanhToan();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            this.resetState();
            
        }).catch(err => {
        })
    }

    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };

    onReload() {
        this.loadDanhSachLoaiThanhToan();
    }

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    };

    render() {
        const columns=[
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: '5%',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            // {
            //     title: 'Mã',
            //     dataIndex: 'ma',
            //     key: 'ma',
            //     align: 'center',
            //     width: '5%',
            // },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: '20%',
                align: 'left',
            },
            {
                title:'STT',
                dataIndex:'stt',
                key: 'stt',
                width:'5%',
                align: 'center',
            },
            {
                title: 'Hiển thị',
                align: 'center',
                width:'10%',
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingHide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hienthi === 0}
                            onChange={(e) => { this.updateHide(row.ma, e); }} />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                width: '30%',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật loại thanh toán!"
                                onClick={() => { this.setState({ id: row.ma, ten: row.ten, visible: true, stt: row.stt, featureId: row.featureid }); }} >
                                <i style={{ marginRight: 10 }} className="fa fa-edit"></i>Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { 
                                    this.DeleteLoaiTT(row.ma)
                                 }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]

        let dataSource = this.state.lstLoaiTT.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })

        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper'  >
                        {/* <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a> */}
                        <HeaderModal title="Danh mục loại thanh toán" />
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'right' }}>
                            {/* <Form.Item>
                                <Input
                                    style={{ width: 400, maxWidth: "70vw" }}
                                    value={this.state.key}
                                    placeholder="Nhập mã hoặc tên loại thanh toán..."
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    // onKeyDown={(e) => {
                                    //     task = setTimeout(() => { this.loadDanhSachNhomVP(1, this.state.pageSize) }, 300);
                                    // }
                                    // }
                                />
                            </Form.Item> */}
                            <Form.Item >
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}
                                ><i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>

                        <div style={{ paddingTop: 20, height: 'calc(100% - 140px)' }}>
                            <Table dataSource={dataSource} scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index} />
                        </div>
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }, this.loadDanhSachLoaiThanhToan(page, size)); }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }, this.loadDanhSachLoaiThanhToan(1, size)); }}
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={900}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật loại thanh toán</h3>
                    </div>
                    <div className="container-fluid row" style={{ padding: 'inherit' }}>
                        <div className="form-group" style={{ flex: 1 }} >
                            <Form.Item label="Tên loại thanh toán:" style={{ flex: 1 }}>
                                <Input
                                    placeholder="Tên loại thanh toán"
                                    value={this.state.ten}
                                    name="tenloaithanhtoan"
                                    onChange={(e) => {
                                        this.setState({ ten: e.target.value })
                                    }} />
                                {this.validator.message('Tên loại thanh toán', this.state.ten, 'required')}
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '8px' }} >
                            <div style={{ flex: 1 }}>
                                <Form.Item label="Thứ tự:" style={{ flex: 1 }}>
                                    <InputNumber
                                        placeholder="Thứ tự"
                                        value={this.state.stt}
                                        name="tenloaithanhtoan"
                                        onChange={(e) => {
                                            this.setState({ stt: e })
                                        }} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    {IsSuperAdmin() &&
                    <div className="container-fluid row" style={{ padding: 'inherit' }}>
                        <div className="form-group" style={{ minWidth: '50%' }}>
                            <Form.Item label="Phân hệ:">
                                <Select
                                    showSearch
                                    allowClear
                                    hidden={!IsSuperAdmin()}
                                    disabled={!IsSuperAdmin()}
                                    value={this.state.featureId}
                                    onChange={(value, option) => {  
                                        this.setState({featureId: value}); 
                                    }}
                                >
                                {(this.state.listFeature ?? []).map((record, index) => {
                                    return (
                                        <Option key={record.id} value={record.id}
                                        >{record.name}</Option>
                                    )
                                })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button className="btn btn-success" onClick={() => this.updateLoaiTT()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visible: false })
                                this.onReload();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={900}
                    visible={this.state.visibleCreate}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNew
                        onCancel={this.handleCancel}
                        onReload={this.onReload.bind(this)} />
                       
                </Modal>
            </React.Fragment>
        );
    }
}

export default index;
