import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import Create from './create';
import SentMessageNotification from './sentMessage';

const NotificationMessageConfig = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
    }, []);

    const [curTab, setCurTab] = useState([]);

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Quản lý thông báo" />
            <Tabs animated={true}
                style={{ height: "calc(100vh - 140px)", overflow: "auto" }} onChange={(key) => { setCurTab(key) }}>
                <Tabs.TabPane tab="Tạo mới thông báo" key="create">
                    <Create />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Danh sách thông báo đã gửi" key="sumerize">
                    <SentMessageNotification activeTab={curTab} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(NotificationMessageConfig)