import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Input, Modal, Pagination, Popconfirm, Select, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import * as CoporationServices from "../../../services/CorporationServices";
import Create from './create';
import { setLoading } from '../../../libs/actions';
import Update from './update';
import { isNullOrEmpty } from '../../../libs/util';

let task = null;

const CoporationChildGroup = forwardRef((props, ref) => {
    const _notify = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [parentId, setParentId] = useState();
    const [listParent, setListParent] = useState([]);
    const [listData, setListData] = useState([]);
    const [keyword, setKeyword] = useState();
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [selected, setSelected] = useState({});
    const [titleDelete, setTitleDelete] = useState("Bạn có chắc muốn xóa?");

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListParent();
        onSearch(1);
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Nguồn khách hàng tổng',
            dataIndex: 'parentName',
            key: 'parentName',
            align: 'left',
            width: 300,
        },
        {
            title: 'Tên',
            dataIndex: 'childName',
            key: 'childName',
            align: 'left',
            width: 300,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (val, record) => {
                return (
                    <Fragment>
                        <button className={`${BUTTON.WARNING}`} onClick={() => handleUpdate(record)}><i className='fas fa-edit mr-1' />Cập nhật</button>
                        <Popconfirm title={<div dangerouslySetInnerHTML={{ __html: `${titleDelete}` }}></div>}
                            okText="Đồng ý" cancelText="Hủy bỏ" placement='left'
                            onConfirm={() => onDelete(record.uid)}>
                            <button className={`${BUTTON.DANGER} ml-2`} onClick={() => { preCheckDelete(record.uid) }}><i className='fas fa-trash mr-1' />Xóa</button>
                        </Popconfirm>
                    </Fragment>
                )
            }
        }
    ];

    const onDelaySearch = (e) => {
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            setPage(1);
            onSearch(1, size, e.target.value);
        }, 400);
    }

    const getListParent = (key) => {
        const data = {
            keyword: key,
            skipCount: 0,
            maxResultCount: 20,
        }
        CoporationServices.GetListParent(data).then(res => {
            if (res.isSuccess) {
                setListParent(res.data.items);
            } else showError(res.error.messageText ?? "Đã xảy ra lỗi tìm kiếm nguồn khách hàng tổng, vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText);
        }).finally()
    }


    const onSearch = (sPage = page, sSize = size, key = keyword) => {
        const data = {
            keyword: key,
            skipCount: (sPage - 1) * sSize,
            maxResultCount: sSize,
            parentId
        }
        setLoading(true);
        CoporationServices.GetListChildGroup(data).then(res => {
            if (res.isSuccess) {
                setListData(res.data.items);
                setTotalRecords(res.data.totalCount);
            } else showError("Đã xảy ra lỗi tìm kiếm, vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText);
        }).finally(() => setLoading(false))
    }
    
    const preCheckDelete = (id) => {
        let isChild = true;
        CoporationServices.PreCheckDelete(id, isChild).then(res => {
            if (res.isSuccess) {
                if (!isNullOrEmpty(res.data)) setTitleDelete(res.data);
                else setTitleDelete("Bạn có chắc muốn xóa?");
            }
        }).catch(() => {
        })
    }

    const onDelete = (id) => {
        setLoading(true);
        CoporationServices.DeleteChildGroup(id).then(res => {
            if (res.isSuccess) {
                showSuccess("Xóa thành công.", 4);
                onSearch(1);
            } else showError(res.error.messageText ?? "Đã xảy ra lỗi khi xóa, vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText);
        }).finally(() => setLoading(false))
    }

    const handleUpdate = (data) => {
        onOpenModal("update");
        setSelected(data);
    }

    const onOpenModal = (a) => {
        if (a == "create") setVisibleCreate(true);
        if (a == "update") setVisibleUpdate(true);
    }

    const onCloseModal = (a) => {
        if (a == "create") setVisibleCreate(false);
        if (a == "update") setVisibleUpdate(false);
    }

    const showError = (msg = "Đã xảy ra lỗi, vui lòng thử lại sau.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Error);
    }

    const showSuccess = (msg = "Thao tác thành công.", timeout) => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Success, timeout);
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Nhóm nguồn con" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='d-flex flex-wrap justify-content-between' style={{ columnGap: 10 }}>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 400 }}>
                            <Select placeholder='Chọn nguồn khách hàng tổng'
                                value={parentId} className='w-100'
                                onChange={(e) => setParentId(e)}
                                showSearch allowClear filterOption={false}
                                onSearch={(e) => {
                                    if (task) clearTimeout(task);
                                    task = setTimeout(() => {
                                        getListParent(e);
                                    }, 400)
                                }}
                            >
                                {listParent && listParent.map((x, index) => {
                                    return (
                                        <Select.Option key={index} value={x.uid}>{x.parentName}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 400 }}>
                            <Input placeholder='Nhập tên để tìm'
                                value={keyword} className='w-100'
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={(e) => onDelaySearch(e)} />
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 400 }}>
                            <button className={`${BUTTON.THEME}`} onClick={() => {
                                onSearch(1); setPage(1);
                                _notify && _notify.current && _notify.current.Close();
                            }}><i className='fas fa-search mr-1' />Tìm</button>
                            <button className={`${BUTTON.THEME} ml-2`} onClick={() => { onOpenModal("create") }}><i className='fas fa-plus mr-1' />Tạo mới</button>
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.uid}
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size); onSearch(page); }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size); onSearch(1, size); }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
            <Modal open={visibleCreate}
                destroyOnClose
                maskClosable
                onCancel={() => onCloseModal("create")}>
                <Create onClose={() => onCloseModal("create")} onReload={() => { setPage(1); onSearch(1); }} />
            </Modal>
            <Modal open={visibleUpdate}
                destroyOnClose
                maskClosable
                onCancel={() => onCloseModal("update")}>
                <Update onClose={() => onCloseModal("update")} onReload={() => { setPage(1); onSearch(1); }} data={selected} />
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationChildGroup)