import React, {useState, useRef, useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

export default (props) => {
    const _imageGallery = useRef();
    // Datasource
    const [listFiles, setListFiles] = useState([]); 
    const [showVideo, setShowVideo] = useState({});
    // Config
    const [showIndex, setShowIndex] = useState(true);
    const [showBullet, setshowBullet] = useState(false);
    const [infinite, setInfinite] = useState(true);
    const [slideDuration, setSlideDuration] = useState(500);
    const [slideInterval, setSlideInterval] = useState(2000);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [thumbnailPosition, setThumbnailPosition] = useState('bottom');
    const [useWindowKeyDown, setUseWindowKeyDown] = useState(true);
    const [showFullscreenButton, setShowFullscreenButton] = useState(false);
    const [showGalleryPlayButton, setShowGalleryPlayButton] = useState(true);
    const [showPlayButton, setShowPlayButton] = useState(false);
    const [showGalleryFullscreenButton, setShowGalleryFullscreenButton] = useState(true);  

    useEffect(() => {
        changeProps();
    }, [props]);


    const changeProps = () => {
        let tListFiles = [];
        if (props.listFiles &&  props.listFiles .length > 0) {
            props.listFiles.map(x => {
                if (!x.uid || !x.uid.length) x.uid = (new Date * Math.random()).toString(36).substring(0,10);
                if(x.extension === 'mp4') x.renderItem = _renderVideo.bind(this);
                tListFiles.push(x);
            });
        }
        setListFiles(tListFiles);
    }

    const _renderVideo = (item) => {
        return (
            <div>
                <div className="video-wrapper">
                    <video id="myvideo" className='w-100 h-100' controls>
                        <source src={item.embedUrl} type="video/mp4" />
                    </video>
                </div>
            </div>
        )
    }
    
    return (
        <div className='col-12'>
        {listFiles && listFiles.length > 0 ? (
            <ImageGallery
                className="w-100 h-100"
                ref={_imageGallery}
                items={listFiles}
                showIndex={showIndex}
                showThumbnails={showThumbnails}
                showPlayButton={showPlayButton}
                showFullscreenButton={showFullscreenButton && showGalleryFullscreenButton}
                showBullets={showBullet}
                infinite={infinite}
            />
        ) : (
            <div style={{ textAlign: "center", color:"rgba(0, 0, 0, 0.25)" }}>Không có hình ảnh hoặc video</div>
        )}
        </div>
    )
};
