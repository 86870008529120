import moment from 'moment';
import React, { Fragment, useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import {Collapse, List, Modal} from 'antd';
import Notify, { NotifyStatus } from '../../../../components/notify'
import HeaderModal from '../../../../configureHIS/common/headerModal';
import RenderDataInfo from '../../../../configureHIS/common/renderDataInfo';
import LoginWF from '../../../hotline/loginWF';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as CommonService from '../../../../services/commonService';
import * as Actions from '../../../../libs/actions';
import * as WorldfoneService from '../../../../services/worldfoneService';

const { Panel } = Collapse;
export default forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const notiRefSec = useRef(null);

    const [patientID, setPatientID] = useState("");
    const [patientInfo, setPatientInfo] = useState([]);

    const [isLoginWF, setIsLoginWF] = useState(false);
    const [titleWF, setTitleWF] = useState('');

    useEffect(() => {
        getPatientInfo();
    },[props])

    const getPatientInfo = () => {
        CommonService.GetPatientDetail(props.patientID).then(result => {
            if (result.isSuccess) {
                setPatientInfo(result.item);
            } else {
                notiRef.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
            }
        }).catch(err => {
            notiRef.current.Show(getErrorMessage(err.msgString), NotifyStatus.Warning);
        })
    }

    const getErrorMessage = (message) => {
        if (message) {
            let errMsg = message.split("\r\n").find(x => x.includes("MessageText:"));
            return errMsg ? errMsg.trim().replace("MessageText: ", "") : message;
        } return "";
    }

    const MakeCall = () => {
        if (patientInfo.phone === null || patientInfo.phone === '' || patientInfo.phone.length < 5) {
            notiRef.current.Show("Bệnh nhân không có số điện thoại.", NotifyStatus.Warning);
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true);
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(patientInfo.phone, patientInfo.patientName, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF('Vui lòng đăng nhập Worldfone');
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true,'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    return (
        <React.Fragment>
            <HeaderModal title={`Xem chi tiết dịch vụ`} onClose={() => {
                props.onClose()
            }} />
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Thông tin bệnh nhân" key="1">
                    <Notify ref={notiRef} />
                    <div className='p-2'>
                        <RenderDataInfo
                            data={
                                [
                                    {
                                        label: "Mã khách hàng",
                                        value: patientInfo.patientID,
                                        column: 6,
                                    },
                                    {
                                        label: "Họ và tên",
                                        value: patientInfo.patientName,
                                        column: 6,
                                    },
                                    {
                                        label: "Năm sinh",
                                        value: moment(patientInfo.birthDay).format("YYYY"),
                                        column: 6,
                                    },
                                    {
                                        label: "Giới tính",
                                        value: Number(patientInfo.gender) === 0 ? "Nam" : "Nữ",
                                        column: 6,
                                    },
                                    {
                                        label: "Điện thoại",
                                        value: patientInfo.phone,
                                        column: 6,
                                    },
                                    {
                                        label: "Email",
                                        value: patientInfo.email,
                                        column: 6,
                                    },
                                    {
                                        label: "Địa chỉ",
                                        value: patientInfo.address,
                                        column: 12,
                                    },
                                ]
                            }
                        />
                    </div>
                </Panel>
                <Panel header="Chi tiết các dịch vụ" key="2">
                <div className='p-2'>
                    <div className='row'>
                        <div className='col-12'>
                            <Notify ref={notiRefSec} />
                            <h4>Danh sách các dịch vụ đã thực hiện từ ngày {moment(props.dateFrom).format(SystemConfig.FORMAT_DATE)} đến ngày {moment(props.dateTo).format(SystemConfig.FORMAT_DATE)}</h4>
                            <div className="w-100 rounded d-flex flex-column justify-content-center align-items-center p-2 px-4" style={{ backgroundColor:"#CCCCCC", fontSize: 16 }}>
                                <div className="w-100 d-flex justify-content-between">
                                    <span>Tổng tiền</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                                <div className="w-75 d-flex justify-content-between">
                                    <span>Khám bệnh</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                                <div className="w-75 d-flex justify-content-between">
                                    <span>Chẩn đoán hình ảnh</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                                <div className="w-75 d-flex justify-content-between">
                                    <span>Xét nghiệm</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                                <div className="w-75 d-flex justify-content-between">
                                    <span>Phẫu thuật - thủ thuật</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                                <div className="w-75 d-flex justify-content-between">
                                    <span>Khác</span>
                                    <div>{Number(0).toLocaleString('en-US')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {/* <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={{}}
                                renderItem={(item) => (
                                <List.Item
                                    key={item.title}
                                    actions={[
                                    ]}
                                    extra={
                                    <img
                                        width={272}
                                        alt="logo"
                                        src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                    />
                                    }
                                >
                                    <List.Item.Meta
                                    avatar={{}}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                    />
                                    {item.content}
                                </List.Item>
                                )}
                            /> */}
                        </div>
                    </div>
                </div>
                </Panel>
                </Collapse>
                <div className='custom-hr'></div>
                <div className='row'>
                        <div className='col-12 '>
                            <div className='d-flex justify-content-between' >
                                <button className="btn btn-secondary" onClick={() => { MakeCall() }}><i className='fas fa-phone mr-1'/> Gọi điện</button>
                                <button className="btn btn-danger"
                                    onClick={() => {
                                        props.onClose()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng
                                </button>
                            </div>
                            
                        </div>
                    </div>

        <Modal
            visible={isLoginWF}
            onCancel={() => {setIsLoginWF(false)}}> 
            <LoginWF title={titleWF} onClose={() => setIsLoginWF(false)} />
        </Modal>
        </React.Fragment>
    );
});
