import * as ApiCaller from '../libs/httpRequests';
const prefix = 'CardType';

export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/export-list`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};