import React, { Component } from 'react';
import {
    DatePicker, Select, Table, Pagination, Popconfirm,
    Input, Modal, Divider, notification, Tag, Collapse, Tooltip
} from 'antd';
import viVN from 'antd/lib/date-picker/locale/vi_VN';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Action from '../../libs/actions';
import * as EquipmentService from './equipmentService';
import { Doughnut, Pie } from "react-chartjs-2";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { FORMAT_DATE } from '../../configure/systemConfig';

const { Option } = Select;
const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
    key: "",
    vsbModal: false,
    create: false,
    expandableRow: [],

    cpage: 1,
    csize: 10
};
let task = null;
const { Panel } = Collapse;

export default class KiemKe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            type: "month",
            pickerValue: moment(),
            lstKiemKe: [],

            id: 0,
            code: "",
            createTime: moment(),
            note: "",
            status: 0,

            listEquipment: [],
            lstTaiSanKiemKe: [],
            canDel: false,
            lstTaiSanKiemKeAdd: [],
            lstTaiSanKiemKeDel: [],
            lstOriginal: [],

            ChartData: [],
            lstTaiSanChuaKiemKe: [],
            lstTypes: [],
            lstUsers: [],

            scanResult: "",
            vsbScan: false
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.loadListKiemKe();
        this.getAllEquipment();
        this.getListTypes();
        this.getListUsers();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }
    loadListKiemKe(type = this.state.type, date = moment().format("MM/YYYY"), page = this.state.currentPage, size = this.state.pageSize) {
        EquipmentService.loadListKiemKe(
            this.state.key, type, date, page, size).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstKiemKe: res.data,
                        currentPage: page,
                        pageSize: size,
                        totalRecords: res.totalRecords,
                    });
                }
            }).catch(err => { })
    }
    export(sort) {
        Action.setLoading(true);
        EquipmentService.exportKiemKeTaiSan(this.state.id, sort).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Danh-Sach-Kiem-Ke-Tai-San";
                this._hiddenLink.click();
            })
            Action.setLoading(false);
        }).catch(err => {
            Action.setLoading(false);
        })
    }
    cancelAction() {
        this.setState({
            vsbModal: false,
            create: false,
            canDel: false,

            vsbResult: false
        });
        if (task)
            clearTimeout(task);
    }
    resetState() {
        this.setState({
            createTime: moment(),
            note: "",
            status: 0,

            lstTaiSanKiemKe: [],
            lstTaiSanKiemKeAdd: [],
            lstTaiSanKiemKeDel: []
        });
        this.state.create && this.getCodeKiemKe();
    }
    getCodeKiemKe() {
        EquipmentService.getCodeKiemKe().then(res => {
            if (res.isSuccess) {
                const prefix = "KK" + moment().format("DDMMYY") + "-";
                this.setState({
                    code: prefix + res.returnObject.code
                });
            } else {
                notification.error({ message: "Không thể tạo mã chứng từ tại thời điểm này" })
            }
        }).catch(err => { })
    }
    getAllEquipment(bydate = "") {
        EquipmentService.getAllEquipment(bydate).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listEquipment: res.data
                });
            }
        }).catch(err => {
        })
    }
    addListTaiSan() {
        var { lstTaiSanKiemKe, listEquipment, equipmentID, lstTaiSanKiemKeAdd, lstTaiSanKiemKeDel, lstOriginal } = this.state;
        var data = listEquipment.filter(eq => eq.equipmentID === equipmentID)[0];
        if (equipmentID && lstTaiSanKiemKe.find(x => x.equipmentID === equipmentID) === undefined) {
            this.setState({
                lstTaiSanKiemKe: [
                    ...lstTaiSanKiemKe,
                    data
                ],
                equipmentID: null
            });
            notification.success({ message: "Đã thêm vào danh sách" });
            if (!this.state.create) {
                lstTaiSanKiemKeDel.find(x => x.equipmentID === data.equipmentID) !== undefined &&
                    this.setState({
                        lstTaiSanKiemKeDel: lstTaiSanKiemKeDel.filter(eq => eq.equipmentID !== data.equipmentID)
                    });
                lstOriginal.find(x => x.equipmentID === data.equipmentID) === undefined &&
                    this.setState({ lstTaiSanKiemKeAdd: [...lstTaiSanKiemKeAdd, data] });
            }
        } else {
            notification.warning({ message: equipmentID ? "Tài sản đã được thêm vào danh sách" : "Chưa chọn tài sản" });
        }
    }
    delTaiSan(id) {
        var { lstTaiSanKiemKe, lstTaiSanKiemKeDel, lstTaiSanKiemKeAdd } = this.state;
        if (id && lstTaiSanKiemKe.find(x => x.equipmentID === id) !== undefined) {
            var data = lstTaiSanKiemKe.filter(eq => eq.equipmentID !== id);
            this.setState({
                lstTaiSanKiemKe: data
            });
            if (!this.state.create) {
                lstTaiSanKiemKeAdd.find(x => x.equipmentID === id) !== undefined &&
                    this.setState({
                        lstTaiSanKiemKeAdd: lstTaiSanKiemKeAdd.filter(eq => eq.equipmentID !== id)
                    });
                this.setState({ lstTaiSanKiemKeDel: [...lstTaiSanKiemKeDel, lstTaiSanKiemKe.filter(eq => eq.equipmentID === id)[0]] });
            }
        }
    }
    create() {
        Action.setLoading(true);
        var {
            createTime, code, status, note,
            lstTaiSanKiemKe
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                createTime, code, status, note,
                lstDetails: lstTaiSanKiemKe
            }
            EquipmentService.createKiemKeTaiSan(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Thêm thành công!' });
                    this.resetState();
                    this.loadListKiemKe();
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    update() {
        Action.setLoading(true);
        var {
            id, createTime, code, status, note,
            lstTaiSanKiemKe, lstTaiSanKiemKeAdd, lstTaiSanKiemKeDel
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                id, createTime, code, status, note,
                lstDetails: lstTaiSanKiemKe,
                lstDetailsAdd: lstTaiSanKiemKeAdd,
                lstDetailsDel: lstTaiSanKiemKeDel
            }
            EquipmentService.updateKiemKeTaiSan(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.resetState();
                    this.cancelAction();
                    this.loadListKiemKe();
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    delPhieuKiemKe(id) {
        EquipmentService.deletePhieuKiemKe(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadListKiemKe(this.state.type, this.state.pickerValue.format("MM/YYYY"));
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(() => { })
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            this.setState({ expandableRow: [index] })
        }
        else {
            this.setState({ expandableRow: [] })
        }
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{
                padding: 8,
                borderRadius: 4,
                background: "#fff",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
            }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder="Tìm mã tài sản"
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <button
                    className="btn btn-success btn-sm"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 90, marginRight: 8 }}
                >
                    Tìm
                </button>
                <button
                    className="btn btn-light btn-sm"
                    onClick={() => this.handleReset(clearFilters)}
                    style={{ width: 90 }}
                >
                    Bỏ lọc
                </button>
            </div>
        ),
        filterIcon: filtered => (
            <i className="fa fa-search" style={{ marginTop: "40%", color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    })
    loadSummary(id, sort) {
        EquipmentService.getSummary(id, sort).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstTaiSanChuaKiemKe: res.data
                });
            }
        }).catch(err => {
        })
    }
    getListTypes() {
        EquipmentService.loadTypes().then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstTypes: res.data
                });
            }
        }).catch(() => { })
    }
    getListUsers(active = true) {
        EquipmentService.loadUsers(active).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstUsers: res.data
                });
            }
        }).catch(() => { })
    }
    //Tạo mã màu cho biểu đồ tròn.
    generateColor(numberOfColor) {
        let colorArray = [];
        for (var i = 0; i < numberOfColor; i++) {
            var o = Math.round, r = Math.random, s = 255;
            var clr = 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ', 0.6)';
            colorArray.push(clr);
        }
        return colorArray;
    }
    handleScan = data => {
        if (data) {
            this.setState({
                scanResult: data
            })
        }
    }
    handleError = err => {
        console.error(err)
    }
    setValue = (record) => {
        var total = this.state.listEquipment.length;
        var checked = record.lstDetails.length;
        var used = this.state.listEquipment.filter(x => x.status === 1).length;
        var storaged = this.state.listEquipment.filter(x => x.status === 0).length;
        var typeName = Object.keys(this.state.lstTypes).map((key) => this.state.lstTypes[key].typeName);
        var type = Object.keys(this.state.lstTypes).map((key) => this.state.lstTypes[key].typeCode);
        var bgrClr = this.generateColor(type.length);
        var eqCount = [];
        type.forEach(e => {
            eqCount.push(this.state.listEquipment.filter(x => x.type === e).length);
        });
        var eqCounted = [];
        type.forEach(e => {
            eqCounted.push(record.lstDetails.filter(x => x.type === e).length);
        });
        this.setState({
            vsbResult: true,
            code: record.code,
            id: record.id,
            createTime: record.createTime,
            ChartData: [
                {
                    datasets: [{
                        data: [checked, total - checked],
                        backgroundColor: [
                            'rgb(54, 162, 235)',
                            'rgb(255, 99, 132)'
                        ]
                    }],
                    labels: [
                        "Đã kiểm kê",
                        "Chưa kiểm kê"
                    ]
                },
                {
                    datasets: [{
                        data: [used, storaged, total - used - storaged],
                        backgroundColor: [
                            'rgb(54, 162, 235)',
                            'rgb(0, 194, 122)',
                            'rgb(255, 205, 86)'
                        ]
                    }],
                    labels: [
                        "Đang dùng",
                        "Trong kho",
                        "Thanh lý"
                    ]
                },
                {
                    datasets: [{
                        data: eqCount,
                        backgroundColor: bgrClr
                    }],
                    labels: typeName
                },
                {
                    datasets: [{
                        data: eqCounted,
                        backgroundColor: bgrClr
                    }],
                    labels: typeName
                },
            ]
        });
        this.forceUpdate();
    }

    customExpandIcon(props) {
        if (props.expanded) {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-minus fa-1x" /></button>
        }
        else {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-plus fa-1x" /></button>
        }
    }

    render() {
        let { totalRecords } = this.state;
        const fColumns = [
            {
                title: "Mã chứng từ",
                dataIndex: "code",
                key: "code",
                align: "left",
                width: 150
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                key: "note",
                align: "left",
                width: 400
            },
            {
                title: "Ngày tạo",
                dataIndex: "createTime",
                key: "createTime",
                align: "left",
                render: (day) => {
                    if (day) return <span>{day && moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Ngày cập nhật",
                dataIndex: "updateTime",
                key: "updateTime",
                align: "left",
                render: (day) => {
                    if (day) return <span>{day && moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Trạng thái",
                dataIndex: "status",
                key: "status",
                align: "center",
                width: 120,
                render: value => {
                    switch (value) {
                        case -1:
                            return <Tag color="orange" style={{ width: "100%" }}>Hủy</Tag>;
                        case 0:
                            return <Tag color="blue" style={{ width: "100%" }}>Đang xử lý</Tag>;
                        case 1:
                            return <Tag color="green" style={{ width: "100%" }}>Hoàn tất</Tag>;
                        default:
                            return "";
                    }
                }
            }
        ];
        const cColumns = [
            {
                title: "STT",
                align: "center",
                render: (text, record, index) => (this.state.cpage - 1) * this.state.csize + Number(index) + 1,
                width: 70
            },
            {
                title: "Mã tài sản",
                dataIndex: "equipmentID",
                key: "equipmentID",
                align: "center",
                width: 150,
                ...this.getColumnSearchProps("equipmentID")
            },
            {
                title: "Tên tài sản",
                dataIndex: "equipmentName",
                key: "equipmentName",
                align: "left",
                width: 250,
            },
            {
                title: "Giá",
                dataIndex: "price",
                key: "price",
                align: "right",
                width: 150,
                render: (value) => {
                    return <span>{value !== undefined ? value.toLocaleString("en-US") : ''}</span>
                }
            },
            {
                title: "Ngày nhập",
                dataIndex: "createTime",
                key: "createTime",
                align: "center",
                width: 120,
                render: (day) => {
                    return <span>{day && moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn bảo hành",
                dataIndex: "expiration",
                key: "expiration",
                align: "center",
                width: 160,
                render: (day) => {
                    if (day) return <span>{day && moment(day) < moment() ? <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span> : moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn khấu hao",
                dataIndex: "depreciation",
                key: "depreciation",
                align: "center",
                width: 160,
                render: (day) => {
                    if (day) return <span>{moment(day) < moment() ? <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span> : moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                align: "right",
                render: (row) => {
                    return (
                        this.state.canDel &&
                        <button className="btn btn-danger"
                            style={{ marginLeft: 10 }}
                            onClick={() => this.delTaiSan(row.equipmentID)}>
                            <i className="fa fa-trash" />
                        </button>
                    )
                }
            }
        ];
        const sColumns = [
            {
                title: "STT",
                align: "center",
                //fixed: 'left',
                render: (text, record, index) => (this.state.lstTaiSanChuaKiemKe.indexOf(record) + 1),
                width: 70
            },
            {
                title: "Mã tài sản",
                dataIndex: "equipmentID",
                key: "equipmentID",
                align: "center",
                width: 120,
                //fixed: 'left',
                ...this.getColumnSearchProps("equipmentID")
            },
            {
                title: "Tên tài sản",
                dataIndex: "equipmentName",
                key: "equipmentName",
                align: "left",
                width: 210
            },
            {
                title: "Hãng",
                dataIndex: "brand",
                key: "brand",
                align: "left",
                width: 120
            },
            {
                title: "Loại",
                dataIndex: "type",
                key: "type",
                align: "center",
                width: 150
            },
            {
                title: "Giá",
                dataIndex: "dongia",
                key: "dongia",
                align: "right",
                width: 120,
                render: (value) => {
                    return <span>{value !== undefined ? value.toLocaleString("en-US") : ''}</span>
                }
            },
            {
                title: "Ngày nhập",
                dataIndex: "createTime",
                key: "createTime",
                align: "center",
                width: 120,
                render: (day) => {
                    return <span>{day && moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn bảo hành",
                dataIndex: "expiration",
                key: "expiration",
                align: "center",
                width: 120,
                render: (day) => {
                    if (day) return <span>{day && moment(day) < moment() ? <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span> : moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn khấu hao",
                dataIndex: "depreciation",
                key: "depreciation",
                align: "center",
                width: 120,
                render: (day) => {
                    if (day) return <span>{day && moment(day) < moment() ? <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span> : moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Serial",
                dataIndex: "serial",
                key: "serial",
                width: 90,
            },
            {
                title: "Cấu hình",
                dataIndex: "infomation",
                key: "infomation",
                width: 150,
            },
            {
                title: "Bộ phận",
                dataIndex: "location",
                key: "location",
                width: 150
            },
            {
                title: "Tình trạng",
                dataIndex: "status",
                key: "status",
                align: "center",
                width: 100,
                render: state => {
                    switch (state) {
                        case 0:
                            return <Tag color="green" style={{ width: "100%" }}>Trong kho</Tag>
                        case 1:
                            return <Tag color="blue" style={{ width: "100%" }}>Đang sử dụng</Tag>
                        case 2:
                            return <Tag color="orange" style={{ width: "100%" }}>Thanh lý</Tag>
                        default:
                            return "";
                    }
                }
            },
            {
                title: "Cấp cho",
                dataIndex: "username",
                key: "username",
                width: 170
            },
            {
                title: "Ngày cấp",
                dataIndex: "dateinuse",
                key: "dateinuse",
                width: 100,
                render: (day) => {
                    if (day) return moment(day).format(FORMAT_DATE)
                }
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                key: "note",
                width: 250,
                align: "left"
            },
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <div className="h4 font-bold text-uppercase text-center">Kiểm kê tài sản cố định</div>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >Download</a>
                    <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="mt-2">
                            <Select value={this.state.type}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(e) => {
                                    this.setState({ type: e });
                                    this.loadListKiemKe(e, this.state.pickerValue.format("MM/YYYY"));
                                }}>
                                <Option value="month">Tháng</Option>
                                <Option value="year">Năm</Option>
                            </Select>
                            <DatePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                locale={viVN} picker={this.state.type}
                                value={this.state.pickerValue}
                                format={this.state.type === "month" ? "MM-YYYY" : "YYYY"}
                                allowClear={false}
                                style={{ marginLeft: 10 }}
                                onChange={(e) => {
                                    this.setState({ pickerValue: e });
                                    this.loadListKiemKe(this.state.type, e.format("MM/YYYY"));
                                }} />
                        </div>
                        <div className="col-md-4 col-sm-6 mt-2">
                            <Input placeholder="Nhập mã chứng từ để tìm kiếm" value={this.state.key}
                                style={{ height: 32 }}
                                onChange={e => {
                                    this.setState({
                                        key: e.target.value
                                    });
                                }}
                                onKeyPress={e => {
                                    if (e.key === "Enter") {
                                        this.loadListKiemKe();
                                    }
                                }}
                                suffix={<button style={{ backgroundColor: "#fff", border: "none" }} onClick={() => this.loadListKiemKe(this.state.type, this.state.pickerValue.format("MM/YYYY"), 1, this.state.pageSize)}><i className="fas fa-search"></i></button>}>
                            </Input>
                        </div>
                        <div className="mt-2">
                            <button className="btn btn-color" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.getCodeKiemKe();
                                    this.getAllEquipment(moment().format("YYYY/MM/DD"));
                                    this.setState({ vsbModal: true, create: true, canDel: true })
                                }}>Tạo phiếu kiểm kê</button>
                        </div>
                    </div>
                    <div style={{ width:"100%", height:'calc(100% - 120px)' , marginTop:20 }}>
                        <Table dataSource={this.state.lstKiemKe} columns={fColumns}
                            scroll={{ y: 0 }}
                            rowKey={row => "kiemke-tbl-f" + row.id}
                            expandable={{
                                expandedRowRender: record => (
                                    <div>
                                        <div style={{ padding: 10 }}>
                                            <Table dataSource={record.lstDetails}
                                                columns={cColumns}
                                                scroll={{ x: 'max-content', y: 0 }}
                                                rowKey={"taisan-tbl-c-" + record.lstDetails.id}
                                                pagination={{ showSizeChanger: true, current: this.state.cpage, onChange: (page, size) => this.setState({ cpage: page, csize: size }), showTotal: (total) => `Tổng: ${total}`, size: "small", position: "bottomCenter" }} />
                                        </div>
                                        <div style={{ margin: 5, display: "flex", justifyContent: "flex-end" }}>
                                            <button className="btn btn-warning"
                                                onClick={() => {
                                                    this.getAllEquipment(record.createTime ? moment(record.createTime).format("YYYY/MM/DD") : "");
                                                    this.setState({
                                                        vsbModal: true,
                                                        canDel: true,
                                                        id: record.id,
                                                        createTime: record.createTime ? moment(record.createTime) : "",
                                                        code: record.code,
                                                        status: record.status,
                                                        note: record.note,
                                                        lstTaiSanKiemKe: record.lstDetails,
                                                        lstOriginal: record.lstDetails,

                                                        cpage: 1
                                                    });
                                                }} >
                                                <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                                            </button>
                                            <button className="btn btn-primary" style={{ marginLeft: 10 }}
                                                onClick={() => {
                                                    this.getAllEquipment(moment(record.createTime).format("YYYY/MM/DD"));
                                                    task = setTimeout(() => {
                                                        this.setValue(record);
                                                    }, 50);
                                                    this.loadSummary(record.id, moment(record.createTime).format("YYYY/MM/DD"));
                                                }}>
                                                <i className="fas fa-eye" style={{ marginRight: 5 }} />Xem báo cáo
                                            </button>
                                            <Popconfirm
                                                title="Bạn có chắc muốn xóa?"
                                                placement="topRight"
                                                onConfirm={() => this.delPhieuKiemKe(record.id)}
                                                okText="Đồng ý" cancelText="Hủy">
                                                <button className="btn btn-danger" style={{ marginLeft: 10 }}>
                                                    <i className="fa fa-trash" style={{ marginRight: 5 }} />Xóa
                                                </button>
                                            </Popconfirm>
                                        </div>
                                    </div>)
                            }}
                            expandIcon={(props => this.customExpandIcon(props))}
                            expandRowByClick={true}
                            // expandedRowKeys={this.state.expandableRow}
                            onRow={(row, index) => {
                                return {
                                    onClick: () => {
                                        this.setState({
                                            selectedRow: row.id,
                                            cpage: 1,
                                            csize: 10
                                        });
                                        this.setExpandable("kiemke-tbl-f" + row.id);
                                    }
                                };
                            }}
                            rowClassName={(row, index) => {
                                if (row.id === this.state.selectedRow) {
                                    return "btn-color"
                                }
                                const rcName = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                return rcName
                            }}
                            pagination={false} />
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadListKiemKe(this.state.type, this.state.pickerValue.format("MM/YYYY"), page, size); }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadListKiemKe(this.state.type, this.state.pickerValue.format("MM/YYYY"), 1, size); }}
                            showSizeChanger={true} />
                    </div>
                </div>
                <Modal
                    maskClosable={false}
                    width={"90vw"}
                    visible={this.state.vsbModal}
                    cancelAction={this.cancelAction}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>{this.state.create ? "Thêm phiếu kiểm kê tài sản cố định" : "Cập nhật thông tin phiếu kiểm kê tài sản cố định"}</h3>
                    </div>
                    <div style={{ backgroundColor: "#f2f2f2", borderRadius: 5 }}>
                        <Divider orientation="left" className='h4 text-uppercase'>Thông tin phiếu kiểm kê</Divider>
                        <div className="row" style={{ padding: "0px 15px 15px" }}>
                            <div className="col-md-3">
                                <label>Ngày tạo</label>
                                <Tooltip placement="top" title="Danh sách tài sản sẽ được liệt kê dựa trên ngày bạn chọn và ngày nhập tài sản">
                                    <DatePicker locale={viVN} allowClear={false} className="w-100" format={"DD-MM-YYYY"} value={this.state.createTime}
                                        onChange={(e) => {
                                            if (this.state.lstTaiSanKiemKe.length > 0) {
                                                this.setState({
                                                    lstTaiSanKiemKe: this.state.lstTaiSanKiemKe.filter(x => moment(x.createTime).isSameOrBefore(e)),
                                                    lstTaiSanKiemKeDel: this.state.lstTaiSanKiemKe.filter(x => moment(x.createTime).isAfter(e))
                                                })
                                            }
                                            if (this.state.lstTaiSanKiemKeAdd.length > 0) {
                                                this.setState({
                                                    lstTaiSanKiemKeAdd: this.state.lstTaiSanKiemKeAdd.filter(x => moment(x.createTime).isSameOrBefore(e))
                                                })
                                            }
                                            this.setState({
                                                createTime: e,
                                                equipmentID: null
                                            });
                                            this.getAllEquipment(e.format("YYYY/MM/DD"));
                                        }}
                                        disabledDate={(current) => { return current && (current < moment().subtract(3, 'months')) }}
                                        getPopupContainer={trigger => trigger.parentNode} />
                                </Tooltip>
                            </div>
                            <div className="col-md-3">
                                <label>Mã chứng từ</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                <Input disabled value={this.state.code} maxLength={15}
                                    onChange={(e) => this.setState({ code: e.target.value })}
                                    style={{ height: 32 }}
                                    suffix={this.state.create && <button className="btn btn-color" onClick={() => this.getCodeKiemKe()}
                                        style={{ transform: "scale(.7)", marginRight: -13 }}><i className="fa fa-sync" /></button>}>
                                </Input>
                                {this.validator.message("Mã chứng từ", this.state.code, "required")}
                            </div>
                            <div className="col-md-3">
                                <label>Trạng thái</label>
                                <Select value={this.state.status} className="w-100"
                                    onChange={(e) => this.setState({ status: e })}
                                    getPopupContainer={trigger => trigger.parentNode}>
                                    <Option disabled={this.state.create} value={-1}>Hủy</Option>
                                    <Option value={0}>Đang xử lý</Option>
                                    <Option value={1}>Hoàn tất</Option>
                                </Select>
                            </div>
                            <div className="col-md-3">
                                <label>Ghi chú</label>
                                <Input value={this.state.note} maxLength={150}
                                    onChange={(e) => this.setState({ note: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Divider orientation="left" className='h4 text-uppercase'>Danh sách tài sản</Divider>
                        <div className="row">
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "stretch", alignItems: "center" }}>
                                <Select value={this.state.equipmentID}
                                    style={{ width: "65%" }}
                                    showSearch
                                    placeholder="Nhập mã tài sản để tìm và thêm vào danh sách"
                                    onChange={(e) => this.setState({ equipmentID: e })}
                                    filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}>
                                    {this.state.listEquipment.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.equipmentID}>{row.equipmentID} - {row.equipmentName}</Option>
                                        )
                                    })}
                                </Select>
                                <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => this.addListTaiSan()}><i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                                {/* <button className="btn btn-outline-info" style={{ marginLeft: 10 }} onClick={() => { this.setState({ vsbScan: true }) }}><i className="fas fa-qrcode" /></button> */}
                            </div>
                        </div>                     
                        <div className="row" style={{ margin: "5px 1px" }}>
                            <Table dataSource={this.state.lstTaiSanKiemKe}
                                columns={cColumns}
                                scroll={{ y: 0 }}
                                rowKey={(row) => "addtaisan-tbl-" + row.equipmentID}
                                pagination={{ showSizeChanger: true, current: this.state.cpage, onChange: (page, size) => this.setState({ cpage: page, csize: size }), showTotal: (total) => `Tổng: ${total}`, size: "small", position: "bottomCenter" }} />
                        </div>
                    </div>
                    <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="btn btn-success"
                            onClick={() => {
                                this.state.create ? this.create() : this.update();
                            }}>
                            <i className={this.state.create ? "fa fa-plus" : "fa fa-save"} style={{ marginRight: 5 }} />{this.state.create ? "Thêm" : "Cập nhật"}
                        </button>
                        {
                            this.state.create ?
                                this.state.lstTaiSanKiemKe.length > 0 ?
                                    <Popconfirm
                                        title="Mọi dữ liệu tài sản đã nhập sẽ mất. Bạn vẫn muốn nhập lại?"
                                        okText="Đồng ý" cancelText="Hủy"
                                        onConfirm={() => this.resetState()}>
                                        <button className="btn btn-warning" style={{ marginLeft: 10 }}>
                                            <i className="fa fa-eraser" style={{ marginRight: 5 }} />Nhập lại
                                        </button>
                                    </Popconfirm>
                                    :
                                    <button className="btn btn-warning" style={{ marginLeft: 10 }} onClick={() => this.resetState()}>
                                        <i className="fa fa-eraser" style={{ marginRight: 5 }} />Nhập lại
                                    </button>
                                : ""
                        }
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.cancelAction();
                                !this.state.create && this.resetState();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
                <Modal
                    maskClosable={false}
                    width={"90vw"}
                    visible={this.state.vsbResult}
                    cancelAction={this.cancelAction}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Phiếu kiểm kê {this.state.code}</h3>
                    </div>
                    <div>Tổng số tài sản tính đến ngày {moment(this.state.createTime).format("DD-MM-YYYY")}:&nbsp;<span className="font-bold">{this.state.listEquipment.length} tài sản</span>, trong đó:</div>
                    <div className="row" style={{ display: "flex", justifyContent: "center", margin: "20px 0px" }}>
                        {this.state.vsbResult && this.state.ChartData.map((element, index) => {
                            return (
                                index < 2 ?
                                    <div key={"doughnut-chart-" + index} className="col-md-3 col-sm-12" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                                        <Doughnut
                                            data={element}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        boxWidth: 15,
                                                        boxHeight: 2
                                                    }
                                                }
                                            }}
                                            height={200}
                                        />
                                    </div>
                                    :
                                    index === 2 ?
                                        <div key={"pie-chart-" + index} className="col-md-3 col-sm-12" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                                            <Pie
                                                data={element}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    legend: {
                                                        responsive: false,
                                                        position: 'right',
                                                        rtl: true,
                                                        onClick: null,
                                                        labels: {
                                                            boxWidth: 15,
                                                            boxHeight: 2
                                                        }
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Tổng tài sản phân theo loại'
                                                    }
                                                }}
                                                height={200}
                                                width={200}
                                            />
                                        </div>
                                        :
                                        <div key={"pie-chart-" + index} className="col-md-3 col-sm-12" style={{ display: "flex", justifyContent: "center", width: "50%" }}>
                                            <Pie
                                                data={element}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    legend: {
                                                        display: false
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Tài sản đã kiểm kê phân theo loại'
                                                    }
                                                }}
                                                height={200}
                                            />
                                        </div>
                            )
                        })}
                    </div>
                    {this.state.lstTaiSanChuaKiemKe.length > 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                <Collapse>
                                    <Panel header="Danh sách tài sản chưa kiểm kê" key="1">
                                        <div className="row" style={{ margin: "5px 1px" }}>
                                            <div className="col-md-12 mb-1" style={{ lineHeight: 2 }}>
                                                <Tag color="blue">Đang dùng ({this.state.lstTaiSanChuaKiemKe.filter(x => x.status === 1).length})</Tag>
                                                <Tag color="green">Trong kho ({this.state.lstTaiSanChuaKiemKe.filter(x => x.status === 0).length})</Tag>
                                                <Tag color="orange">Thanh lý ({this.state.lstTaiSanChuaKiemKe.filter(x => x.status === 2).length})</Tag>
                                                {this.state.vsbResult && this.state.lstTypes.map((e, index) => {
                                                    return <Tag key={index}>{e.typeName} ({this.state.lstTaiSanChuaKiemKe.filter(x => x.type === e.typeName).length})</Tag>
                                                })}
                                            </div>
                                            <Table dataSource={this.state.lstTaiSanChuaKiemKe}
                                                columns={sColumns}
                                                scroll={{ y: 0 }}
                                                rowKey={(row) => "Summarytaisan-tbl-" + row.equipmentID}
                                                pagination={{ showSizeChanger: true, showTotal: (total) => `Tổng: ${total}`, size: "small", position: 'bottomCenter' }} />
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>}
                    <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="btn btn-primary" onClick={() => this.export(moment(this.state.createTime).format("YYYY/MM/DD"))}><i className="fas fa-download" style={{ marginRight: 5 }} />Xuất kết quả</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.cancelAction();
                                this.resetState();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
            </ >
        );
    }
}