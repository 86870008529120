import { DatePicker, Tooltip, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import * as PrintService from '../../../../services/printService';
import * as ChiDinhService from '../../../../services/chiDinhService';
import CLSNoiTru from './CLSNoiTru';
import PatientInfo from './patientinfo';
const { RangePicker } = DatePicker;


class XetNghiem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            lstXetNghiem: [],
            listType: [],
            ListTypeDistinct: [],
            ngaySearch: [moment(this.props.patientDetail.dateInHospital), moment()],
            keySearch: '',
            isOld: false,
            heightListBtn: 47,
        };
    }
    componentDidMount() {
        this.GetListXetNghiem();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail, ngaySearch: [moment(nextProps.patientDetail.dateInHospital), moment()] }, () => {
                this.GetListXetNghiem();
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }

    GetListXetNghiem() {
        if (this.state.detail.enterCode === undefined)
            return;
        InHospitalService.GetListXetNghiem(this.state.detail.enterCode, this.state.detail.code,
            this.state.ngaySearch[0].format('YYYY/MM/DD'),
            this.state.ngaySearch[1].format('YYYY/MM/DD'), this.state.keySearch, this.state.isOld).then(result => {
                if (result.isSuccess) {
                    let listType = result.data.map(x => x.ngay);
                    let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    this.setState({
                        lstXetNghiem: result.data,
                        listType: ListTypeDistinct,
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", "Tải dữ liệu xét nghiệm bệnh nhân nội trú bị lỗi")
            })
    }

    PrintResultTest(data, all = true) {
        PrintService.PrintResultTestBenhTrinh(this.state.detail.code, this.state.detail.enterCode, this.state.detail.managerId,
            moment(data.ngayDate).format('YYYY/MM/DD'), this.state.ngaySearch[0].format('YYYY/MM/DD'), this.state.ngaySearch[1].format('YYYY/MM/DD'),
            all, this.state.keySearch, this.state.isOld).then((result) => {
                Actions.DoPrint(result.item)
            })
    }
    XoaChiDinh(id, ngay) {
        if (id !== 0) {
            ChiDinhService.XoaChiDinh(id, ngay).then(result => {
                if (result.isSuccess) {
                    this.GetListXetNghiem();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            });
        }
    }
    checkColor(data) {
        if (isNaN(data.ketQua) || isNaN(data.mucBinhThuongMin) || isNaN(data.mucBinhThuongMax) || data.ketQua === "" || data.mucBinhThuongMin === "" || data.mucBinhThuongMax === "") {
            return 'normal';
        }
        else {
            if (Number(data.ketQua) < Number(data.mucBinhThuongMin)) {
                return 'bold';
            } else if (Number(data.ketQua) > Number(data.mucBinhThuongMax)) {
                return 'bold';
            }
        }
        return 'normal';
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="row align-items-center" style={{ padding: '10px', paddingTop: 0 }}>
                            <div className="w-100 col-12 row no-gutters" style={{ backgroundColor: '#fff', paddingTop: 10 }}>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '115px' }} className="btn btn-color fontSize12" onClick={() => { this.setState({ visibleEditCLS: true }) }}
                                        data-toggle="collapse" data-target="#themchidinhxetnghiem"><i className="fa fa-plus"></i> Thêm chỉ định</button>
                                </div>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '105px' }} className="btn btn-outline-secondary fontSize12"
                                        onClick={() => { this.PrintResultTest({ ngaySearch: moment() }) }}><i className="fa fa-print"></i> In tất cả KQ</button>
                                </div>
                                <div className="col-auto mb-2 mr-2" style={{ width: '225px' }}>
                                    <RangePicker className="fontSize12" format="DD-MM-YYYY" value={this.state.ngaySearch} allowClear={false}
                                        locale={viVN} onChange={(value => this.setState({ ngaySearch: value }, () => { this.GetListXetNghiem() }))}
                                    />
                                </div>
                                <div className="col-auto mb-2 mr-2 align-self-center">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" value={this.state.isOld}
                                                onChange={(e) => { this.setState({ isOld: e.target.checked }, () => { this.GetListXetNghiem() }) }} />Hiện bệnh án cũ
                                    </label>
                                    </div>

                                </div>
                                <div className="col-auto mb-2">
                                    <div className="input-group fontSize12">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-color" type="button" onClick={() => { this.GetListXetNghiem(); }}><i className="fa fa-search" /></button>
                                        </div>
                                        <input value={this.state.keySearch} type="text" className="form-control" placeholder="Nhập tên dịch vụ"
                                            onChange={(e) => this.setState({ keySearch: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.GetListXetNghiem();
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.visibleEditCLS &&
                        <div className="pt-2 collapse" id='themchidinhxetnghiem' style={{ marginTop: '10px', backgroundColor: "#fff" }}>
                            <CLSNoiTru
                                ref={(c) => this.modalCLS = c}
                                patient={this.state.detail}
                                noitru={true}
                                ngay={moment()}
                                showType={true}
                                type={2}
                                idbenhtrinh={this.state.idbenhtrinh}
                                onClose={() => {
                                    this.GetListXetNghiem();
                                    this.setState({ visibleEditCLS: false })
                                }}
                            />
                        </div>
                    }
                    {this.state.listType.map((ngay, index) => {
                        let tmp = this.state.lstXetNghiem.filter(x => x.ngay === ngay)[0];
                        let dataTMP = this.state.lstXetNghiem.filter(x => x.ngay === ngay);
                        let listType = dataTMP.map(x => x.tenLoaiDV);
                        let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                        return (
                            <div className="w-100">
                                <div className="w-100" style={{ height: '1px', backgroundColor: '#F2F5FA' }}></div>
                                <div className="p-md-0">
                                    <div style={{ padding: '10px', backgroundColor: '#fff' }}>
                                        <div className="w-100" key={index}>
                                            <div data-toggle="collapse" data-target={`#BTXN${index}`} className="mb-0" style={{ cursor: 'pointer' }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <div style={{ marginRight: '30px' }}>{tmp.ngay}</div>
                                                        <div>BS điều trị: {tmp.tenbs}</div>
                                                    </div>
                                                    <div className="ml-auto" style={{ cursor: 'pointer', fontSize: '13px' }} onClick={(e) => { e.stopPropagation(); this.PrintResultTest(tmp, false) }}>
                                                        <i className="fa fa-print" style={{ marginRight: '5px' }}></i>In kết quả
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={`BTXN${index}`} className={`collapse ${index === 0 ? 'show' : ''}`}>
                                                <div className="table-responsive"></div>
                                                <table className="table table-striped table-inverse table-hover">
                                                    <thead className="thead-inverse">
                                                        <tr>
                                                            <th>TÊN XÉT NGHIỆM</th>
                                                            <th>KẾT QUẢ</th>
                                                            <th>CHỈ SỐ THAM CHIẾU</th>
                                                            <th>ĐƠN VỊ</th>
                                                            <th>MỨC NGUY HIỂM</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    {ListTypeDistinct.map((loai, indexDistinct) => (
                                                        <React.Fragment>
                                                            <tbody key={indexDistinct}>
                                                                <tr key={indexDistinct}>
                                                                    <th colSpan="5">{loai}</th>
                                                                </tr>
                                                                {
                                                                    dataTMP.filter(x => x.tenLoaiDV === loai).map((row, indexChild) => (
                                                                        <React.Fragment>
                                                                            <tr data-toggle="collapse" data-target={`#${row.listSubTestResult.length > 0 ? `resultChild${indexChild}` : ''}`} key={indexChild}
                                                                                style={{ cursor: `${row.listSubTestResult.length > 0 ? 'pointer' : 'unset'}` }}
                                                                            >
                                                                                <td className="d-flex">
                                                                                    {row.listSubTestResult.length > 0 &&
                                                                                        <div style={{ padding: 2, fontSize: 11 }}>
                                                                                            <i className="fa fa-plus"></i>
                                                                                        </div>
                                                                                    }
                                                                                    {row.tenDichVu}</td>
                                                                                <td style={{ fontWeight: `${this.checkColor(row)}` }}>{row.ketQua}</td>
                                                                                <td style={{ fontWeight: `${this.checkColor(row)}` }}>{row.normalRange}</td>
                                                                                <td style={{ fontWeight: `${this.checkColor(row)}` }}>{row.unit}</td>
                                                                                <td style={{ fontWeight: `${this.checkColor(row)}` }}>{row.normalRangeWarning}</td>
                                                                                <td>
                                                                                    <Tooltip align="top" title="Xóa">
                                                                                        <div className="text-danger"
                                                                                            onClick={() => { this.XoaChiDinh(row.ma, moment(row.ngay, 'DD/MM/YYYY').format("YYYY/MM/DD")) }}
                                                                                            style={{ cursor: "pointer" }}><i className="fa fa-trash"></i>
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </td>
                                                                            </tr>
                                                                            {row.listSubTestResult && row.listSubTestResult.length > 0 &&
                                                                                <tr id={`resultChild${indexChild}`} className="collapse" style={{
                                                                                    marginLeft: '30px'
                                                                                }}
                                                                                ><td colSpan="5" style={{ paddingLeft: '30px' }}>
                                                                                        <table className="table mb-0">
                                                                                            {row.listSubTestResult.map((childData, indexChildData) => (
                                                                                                <tr className="table-not-hover" key={indexChildData}>
                                                                                                    <td>{childData.tenDichVu}</td>
                                                                                                    <td style={{ fontWeight: `${this.checkColor(row)}` }}>{childData.ketQua}</td>
                                                                                                    <td style={{ fontWeight: `${this.checkColor(row)}` }}>{childData.normalRange}</td>
                                                                                                    <td style={{ fontWeight: `${this.checkColor(row)}` }}>{childData.unit}</td>
                                                                                                    <td style={{ fontWeight: `${this.checkColor(row)}` }}>{row.normalRangeWarning}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                            }
                                                                                        </table>
                                                                                    </td></tr>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </React.Fragment>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </React.Fragment>
        );
    }
}

export default XetNghiem;