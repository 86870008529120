import React, { useRef, Fragment, useState,useCallback, useImperativeHandle, forwardRef } from "react";
import { Collapse, Tabs, Drawer } from 'antd';
import VitalSign from "../../../patient/component/vitalSign";
import EditExamInfo from "./examination-Info";
import ViewExamInfo from "./view-examination-Info";
import ModifyVitalSign from "../components/ModifyVitalSign";
import Medicine from "../../../medicine/mitaMedicine";
//IndicationExam tạm xài cái cũ. Cái mới chưa xong, chưa ổn định
import IndicationExam from "./clinical-exam-indication";//indication có nghĩa là chỉ định á =]]
// import IndicationExam from '../../../register/register-products/registerproduct-indication';
import { useEffect } from "react";

const { Panel } = Collapse;
export default forwardRef((props, ref) => {
    const _examInfo = useRef();
    const _vitalSignRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [dataSource, setDataSource] = useState(props.data);
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [visibleModifyVitalSign, setVisibleModifyVitalSign] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('examinationInfo');
    
    useEffect(() => {
        props.tabActiveKey && props.tabActiveKey(tabActiveKey)
    }, [tabActiveKey])

    useImperativeHandle(ref, () => ({
        onSaveExam: (isDone) => {
            _examInfo.current && _examInfo.current.onSaveExam(isDone);
        }
    }));

    const onCloseModifyVitalSign = () => {
        _vitalSignRef.current && _vitalSignRef.current.Reload();
        setVisibleModifyVitalSign(false);
    }

    const setDoneExam = () => {
        setIsEdit(false); 
        forceUpdate();
    }

    return (
        <Fragment>
        <div className="w-100 h-100 d-flex flex-column justify-content-between overflow-hidden">
            <div className="row">
                <div className="col-12">
                    <Collapse defaultActiveKey={['dst']} expandIconPosition="right">
                        <Panel
                            key="dst"
                            header={
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>
                                    { isEdit && <span className='anchor-color' onClick={(e) => {e.stopPropagation(); setVisibleModifyVitalSign(true);}}><i className="fas fa-pen"></i></span> }
                                </div>
                            }
                        >
                            <VitalSign ref={_vitalSignRef} maql={dataSource.managermentId} />
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <div className="w-100 h-100 d-flex flex-column justify-content-between overflow-hidden">
                <Tabs defaultActiveKey="examinationInfo" onChange={(e) => setTabActiveKey(e)}>
                    <Tabs.TabPane tab="Thông tin khám" key="examinationInfo">
                        {isEdit ? 
                            <EditExamInfo 
                            ref={_examInfo}
                            examinationId={dataSource.examinationId} 
                            isDone={() => setDoneExam()} />
                        :
                            <ViewExamInfo examinationId={dataSource.examinationId} />
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Chỉ định" key="indications">
                        <IndicationExam data={dataSource} parentComponent='KHAMBENH' roomName={props.roomName} examRoom={props.examRoom} clinicRoom={props.clinicRoom}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Đơn thuốc" key="prescriptions">
                        <Medicine
                            data={{
                                tenBenhNhan: dataSource.patientName,
                                maDichVu: dataSource.serviceCode,
                                tenDichVu: dataSource.serviceName,
                                maQuanLy: dataSource.managermentId,
                                maBenhNhan: dataSource.patientCode,
                                maKhoa: props.clinicRoom,
                                maPhong: props.examRoom,
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
        <Drawer className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"40%"}
            placement="right"
            visible={visibleModifyVitalSign}
            destroyOnClose={true}
        >
            <ModifyVitalSign 
                patientCode = {dataSource.patientCode}
                patientName = {dataSource.patientName}
                maql = {dataSource.managermentId}
                onClose={() => { onCloseModifyVitalSign() }} 
            />
        </Drawer>
        </Fragment>
    )
});
