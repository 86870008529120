import React, { useRef, Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect } from "react";
import {  Input, Radio, InputNumber, Button } from "antd";
import Notify, { NotifyStatus } from "../../../../components/notify";
import HeaderModal from "../../../../configureHIS/common/headerModal";

export default (props) => {
    const _notiRef = useRef();
    const optionsDiscount = [
        { label : '%', value : 0},
        { label : 'đ', value : 1},
    ];
    const DISCOUNTTYPE = {
        PERCENT : 0,
        VALUE : 1
    }
    const [discountType, setDiscountType] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);      
    const [discountRemark, setDiscountRemark] = useState('')
    // Datasource
    const [serviceName, setServiceName] = useState('');
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if(props.data){
            setServiceName(props.data.serviceName);
            setAmount(props.data.price);
        }
    }, [props])

    const onSubmit = () => {
        if(discountRemark === ''){
            _notiRef.current.Show('Vui lòng nhập ghi chú', NotifyStatus.Warning);
            return
        };
        let data = {
            isPercent : discountType === DISCOUNTTYPE.PERCENT ? true : false,
            discountValue : Number(discountValue),
            discountPercent : Number(discountPercent),
            remark : discountRemark,
        }
        props.onSubmit && props.onSubmit(data);
        resetState(); 
    }

    const changeDiscountType = (value) => {
        setDiscountType(value);
        setDiscountPercent(0);
        setDiscountValue(0);
    }

    const resetState = () => {
        setDiscountPercent(0);
        setDiscountValue(0);
        setDiscountRemark('');
        _notiRef.current && _notiRef.current.Close();
    }

    return (
        <Fragment>
            <div className="list-page">
                <Notify ref={_notiRef} />
                {/* <HeaderModal title='‎' onClose={() => { props.onClose() }}/> */}
                <div className="w-100 d-flex justify-content-between">
                    <span>{serviceName}</span>
                    <span>{amount && amount.toLocaleString('en-US')}</span>
                </div>
                <div className="w-100 flex-column mt-1">
                    <span className="required-field">Miễn giảm</span>
                    <div className="d-flex justify-content-between">
                        <Radio.Group
                            className="mr-2"
                            options={optionsDiscount}
                            onChange={(e) => { changeDiscountType(e.target.value);}}
                            value={discountType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        {discountType === DISCOUNTTYPE.PERCENT && 
                            <InputNumber
                                className='w-25 mr-2'
                                value={discountPercent}
                                onChange={(e) => {
                                    setDiscountPercent(e);
                                    let value = Number(amount * (e / 100)).toFixed(0)
                                    setDiscountValue(value);
                                }}
                                min={0}
                                max={100}
                                placeholder='% giảm'
                            ></InputNumber>
                        }
                        <InputNumber
                            className='w-50'
                            value={discountValue}
                            onChange={(e) => {
                                setDiscountValue(e)
                                let value = Number((e / amount) * 100)
                                setDiscountPercent(value)
                            }}
                            min={0}
                            max={amount}
                            step={1000}
                            maxLength={12}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            disabled={discountType === 0}
                            placeholder='số tiền'
                        ></InputNumber>
                    </div>
                    
                </div>
                <div className="w-100 d-flex flex-column justify-content-between mt-1">
                    <span className="required-field">Lý do giảm</span>
                    <Input.TextArea 
                        className="w-100"
                        rows={2} 
                        value={discountRemark} 
                        onChange={(e) => setDiscountRemark(e.target.value)}
                    />
                </div>
                <div className="w-100 d-flex justify-content-end mt-2">
                    <Button 
                        className="btn-danger mr-2" 
                        onClick={() => { resetState(); props.onClose(); }}>
                        Hủy bỏ
                    </Button>
                    <Button 
                        className="btn-color" 
                        onClick={() => {onSubmit()}}>
                        Áp dụng
                    </Button>
                </div>
            </div>
        </Fragment>
    )
};
