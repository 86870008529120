import { Input, Select, Tag, Table, Pagination, notification, Modal } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import moment from 'moment';
import * as EquipmentService from './equipmentService';
import * as Action from '../../libs/actions';
import { FORMAT_DATE } from '../../configure/systemConfig';

const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
};
let task = null;
const { Option } = Select;

export default class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            key: "",
            status: '',
            listHistories: [],
            lstUsers: [],

            infomationUpdate: '',
            idUpdate: 0,
            equipmentIDUpdate: '',
            statusUpdate: '',
            isOpen: false
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.getHistories();
        this.getListUsers();
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }

    getHistories(page = this.state.currentPage, size = this.state.pageSize) {
        EquipmentService.getListHistories(
            this.state.key, this.state.status, page, size).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        listHistories: res.data,
                        currentPage: page,
                        pageSize: size,
                        totalRecords: res.totalRecords,
                    });
                }
            }).catch(err => {
            })
    }
    getListUsers() {
        EquipmentService.loadUsers().then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstUsers: res.data
                });
            }
        }).catch(() => { })
    }
    ModalAction(id, userid, equipmentID, status, isOpen) {
        this.setState({
            idUpdate: id,
            useridUpdate: userid,
            equipmentIDUpdate: equipmentID,
            statusUpdate: status,
            isOpen
        });
    }
    historyAction() {
        if (this.validator.allValid()) {
            Action.setLoading(true);
            const data = {
                id: this.state.idUpdate,
                userid: this.state.useridUpdate,
                equipmentID: this.state.equipmentIDUpdate,
                history: this.state.statusUpdate,
                infomation: this.state.infomationUpdate
            }
            EquipmentService.historyAction(data).then(res => {
                if (res.isSuccess) {
                    this.getHistories();
                    this.setState({
                        infomationUpdate: '',
                        isOpen: false
                    });
                } else {
                    notification.warning({ message: res.err.msgString });
                }
                Action.setLoading(false);
            }).catch(err => {
                Action.setLoading(false);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    cancelAction = () => {
        this.validator.hideMessages();
        this.setState({
            isOpen: false
        });
    }
    export = () => {
        Action.setLoading(true);
        EquipmentService.exportHistoryEquipmentsList().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Lich-Su-Ban-Giao-Thu-Hoi-Tai-San";
                this._hiddenLink.click();
            })
            Action.setLoading(false);
        }).catch(err => {
            Action.setLoading(false);
        })
    }
    printHandOverRetrieval(id, type) {
        EquipmentService.printHandOverRetrieval(id, type).then((result) => {
            Action.DoPrint(result.item)
        })
    }
    render() {
        let { currentPage, pageSize } = this.state;
        const columns = [
            {
                title: "STT",
                align: "center",
                render: (text, record, index) => (currentPage - 1) * pageSize + Number(index) + 1,
                width: 70
            },
            {
                title: "Mã tài sản",
                dataIndex: "equipmentID",
                key: "equipmentID",
                align: "center",
                width: 110,
            },
            {
                title: "Tên tài sản",
                dataIndex: "equipmentName",
                key: "equipmentName",
                align: "left",
                width: 150,
            },
            {
                title: "Trạng thái",
                dataIndex: "history",
                key: "history",
                align: "center",
                width: 110,
                render: state => {
                    switch (state) {
                        case 'complete':
                            return <Tag color="blue" style={{ width: "100%" }}>Đã bàn giao</Tag>
                        case 'cancel':
                            return <Tag color="orange" style={{ width: "100%" }}>Thu hồi</Tag>
                        default:
                            return ''
                    }
                }
            },
            {
                title: "Người sử dụng",
                dataIndex: "userid",
                key: "userid",
                width: 150,
                render: id => {
                    if (this.state.lstUsers.filter(x => x.id === id)[0] !== undefined) {
                        return this.state.lstUsers.filter(x => x.id === id)[0].hoten
                    }
                }
            },
            {
                title: "Ngày",
                dataIndex: "date",
                key: "date",
                width: 90,
                render: (day) => {
                    if (day) return moment(day).format(FORMAT_DATE)
                }
            },
            {
                title: "Nội dung",
                dataIndex: "infomation",
                key: "infomation",
                width: 250,
                align: "left"
            },
            {
                title: "Thao tác",
                align: "center",
                width: 250,
                render: (row) => {
                    return (
                        <div>
                            {row.history === 'complete' &&
                                <div>
                                    <button className={!row.removeable ? "btn btn-secondary" : "btn btn-primary"}
                                        style={{ width: "16%" }}
                                        title="In biên bản bàn giao"
                                        disabled={!row.removeable}
                                        onClick={() => this.printHandOverRetrieval(Number(row.id), 'handover')}>
                                        <i className="fas fa-print" />
                                    </button>
                                    <button className={!row.removeable ? "btn btn-secondary" : "btn btn-warning"}
                                        style={{ marginLeft: 10, width: "40%" }}
                                        disabled={!row.removeable}
                                        onClick={() => {
                                            this.ModalAction(row.id, row.userid, row.equipmentID, row.history, true)
                                        }}>
                                        <i className="fas fa-history" style={{ marginRight: 5 }} />Thu hồi
                                    </button>
                                </div>}
                            {row.history === 'cancel' &&
                                <button className="btn btn-primary"
                                    style={{ width: "60%" }}
                                    title="In biên bản thu hồi"
                                    onClick={() => this.printHandOverRetrieval(Number(row.id), 'retrieval')}>
                                    <i className="fas fa-print" style={{ marginRight: 5 }} />In biên bản
                                </button>}
                        </div>
                    )
                }
            }
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <div className="h4 font-bold text-uppercase text-center">Lịch sử bàn giao - thu hồi tài sản</div>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <div className="col-md-4 col-sm-6 mt-2">
                            <Input placeholder="Nhập tên, mã tài sản hoặc tên người dùng để tìm kiếm" value={this.state.key}
                                onChange={e => {
                                    this.setState({
                                        key: e.target.value
                                    });
                                    task = setTimeout(() => {
                                        this.getHistories(1, this.state.pageSize);
                                    }, 700);
                                }}>
                            </Input>
                        </div>
                        <div className="col-md-2 col-sm-6 mt-2">
                            <Select value={this.state.status}
                                className='w-100'
                                onChange={(e) => {
                                    this.setState({
                                        status: e
                                    });
                                    task = setTimeout(() => {
                                        this.getHistories(1, this.state.pageSize);
                                    }, 700);
                                }}>
                                <Option value=''>Tất cả trạng thái</Option>
                                <Option value='complete'>Đã bàn giao</Option>
                                <Option value='cancel'>Thu hồi</Option>
                            </Select>
                        </div>
                        <div className="mt-2">
                            {/* <button className="btn btn-success" onClick={() => this.getHistories(1, this.state.pageSize)}>
                            <i className="fas fa-sync-alt" />
                        </button> */}
                            <button className="btn btn-primary" style={{ marginLeft: 10 }}
                                onClick={() => this.export()}>
                                <i className="fas fa-download" style={{ marginRight: 5 }} />Xuất danh sách
                            </button>
                        </div>
                    </div>
                    <div style={{height:'calc(100% - 115px)' , marginTop:20}}>
                        <Table
                            dataSource={this.state.listHistories}
                            columns={columns}
                            rowKey={row => "history-tbl-" + row.id}
                            pagination={false}
                            scroll={{ x: 'max-content', y: 0 }} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.getHistories(page, size); }}
                        onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.getHistories(1, size); }}
                        showSizeChanger={true}
                    />

                    <Modal
                        visible={this.state.isOpen}
                        width={500}
                        onCancel={this.cancelAction}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Nội dung (Lý do thu hồi)</label>
                                <Input value={this.state.infomationUpdate} maxLength={100}
                                    onChange={(e) => this.setState({ infomationUpdate: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            this.historyAction();
                                        }
                                    }}></Input>
                                {this.state.isOpen && this.validator.message("Lý do thu hồi", this.state.infomationUpdate, "required")}
                            </div>
                        </div>
                        <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button className="btn btn-success"
                                onClick={() => {
                                    this.historyAction();
                                }}>
                                <i className="fa fa-save" style={{ marginRight: 5 }} />Thu hồi
                            </button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.cancelAction()
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </Modal>
                </div>
            </>
        );
    }
}