import { Button, Input, Modal, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../../commons/validationCustom';
import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as CommonService from '../../../../services/commonService';
import * as MedicineService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';
import Administrative from '../confirm/patientdetails';
import CreateCopy from './createCopy';

export default class index extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            activeThuoc: 0,
            thuoc: '',
            lstKho: [],
            lstNguon: [],
            maNguon: '',
            maKho: 502,
            lstDetail: [],
            lstDetailNew: [],
            lstColums: [],
            giaban: 0,
            soluong: 0,
            tongtien: 0,
            tongtienNew: 0,
            disible: true,
            hidden: true,
            tinhtien: true,
            listThuoc: [],
            drawShow: false,
            render: '',
            loai: '',
            sophieu: 0,
            AdministrativePatientName: 'Hành Chánh',
            numberAdd: 1,
            tenKho: '',
            tenNguon: '',
            detailDefault: {},
            ghichu: '',
            btnEdit: true,
            btnEditText: 'Chỉnh sửa',
        };
    }

    componentWillMount() {

        this.DanhSachChiTietToaBS(this.props.ngay, this.props.id, this.props.maql)
        this.SearchMedicine();
        this.loadKho();
        this.loadNguon()
        CommonService.GetPatientDetail(this.props.mabn).then(result => {
            if (result.isSuccess) {
                let selectedPatient = result.item;
                let temp = 'Hành Chánh';
                if (selectedPatient) {
                    temp += '    BN: ' + selectedPatient.patientName + '  -   mã BN:' + selectedPatient.patientID;
                }
                this.setState({ AdministrativePatientName: temp })
                this.setState({ selectedPatient })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })

    }

    DanhSachChiTietToaBS(ngay, id, maql) {
        MedicineService.DanhSachChiTietToaBS(ngay, id, maql).then(result => {
            if (result.isSuccess) {
                let data = [];
                data = result.data.filter(x => x.soluong > 0);
                // if (this.props.detail.done === 0) {
                //     data = result.data.filter(x => x.soluong > 0);
                // }
                // else {
                //     data = result.data.map(x => ({ ...x, soluong: x.soluongthuc })).filter(x => x.soluongthuc > 0);
                // }
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.dongia;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                }
                let tongtienNew = tongtien
                //this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data[0].ghichu
                })
                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
        })
            .catch(err => {
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
            })
    }

    loadDetailToa(ngay, id) {
        Actions.setLoading(true);
        MedicineService.GetListChiTietHoaDon(ngay, id).then(result => {
            if (result.isSuccess) {
                let data = [];
                if (this.props.detail.done === 0) {
                    data = result.data.filter(x => x.soluong > 0);
                }
                else {
                    data = result.data.map(x => ({ ...x, soluong: x.soluongthuc })).filter(x => x.soluongthuc > 0);
                }
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.giathucthu;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                }
                let tongtienNew = tongtien
                this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data[0].ghichu
                })
                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
            Actions.setLoading(false);
        })
            .catch(err => {
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
                Actions.setLoading(false);
            })
    }

    loadKho() {
        MedicineService.GetListKho().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        //maKho: result.data[0].value,
                        tenKho: result.data[0].label,
                    });
                }
                this.setState({
                    lstKho: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    loadNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        maNguon: result.data[0].value,
                        tenNguon: result.data[0].label,
                    });
                }
                this.setState({
                    lstNguon: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }



    LuuHoaDon() {
        let medicines = this.newTable.getDataSource()
        for (let item of medicines) {
            item.soluong = +item.soluong;
        }

        let data = {
            ngay: this.props.ngay,
            id: this.props.id,
            medicines,
            ghichu: this.state.ghichu
        }

        Actions.setLoading(true);
        MedicineService.LuuXacNhanHoaDon(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' })
                if (this.props.done !== 0)
                    this.ChangeButton();
                this.setState({ isPrint: true })
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.warning({ message: 'Lưu bị lỗi' });
            Actions.setLoading(false);
        })
    }


    onSelectPatient(item) {
        this.setState({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })

        this.form.setFieldsValue({
            mabn: item.patientID,
            hoten: item.patientName,
            ngaysinh: moment(item.birthYear),
            cmnd: item.cardId,
            sodt: item.phone,
            disibleSearch: true,
            activeKey: "1",
            tuoi: moment().year() - moment(item.birthYear).year()
        })

        this.AutocompletePatient.setValue(item.patientName);
    }

    xoathuoc(row) {
        let lstDetailNew = this.state.lstDetailNew;
        let lst = this.state.lstDetailNew.filter(x => x.id !== row.id);
        lstDetailNew = lst;
        this.newTable.setDataSource(lstDetailNew);
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }
        this.setState({ tongtienNew, lstDetailNew })
    }

    handleChangeNewMedicine(data) {
        try {
            let tongtienNew = 0;
            for (let item of data) {
                if (item.soluong && item.giathucthu)
                    tongtienNew += item.soluong * item.giathucthu;
            }
            let temp = data.map(function (e, index) {
                let o = Object.assign({}, e);
                return o;
            })
            this.newTable.setDataSource(temp);
            this.setState({ tongtienNew: tongtienNew, lstDetailNew: temp });
        } catch (error) {

        }



    }
    keyFunction = (e, row, col) => {
        if (col === 'cachdung') {
            e.preventDefault();

            if (e.key === 'Tab') {
                if (row < this.state.lstDetailNew.length) {
                    this.newTable.focusNewRow(row + 1,
                        'soluong');
                } else {
                    if (this.Autocomplete)
                        this.Autocomplete.focus();
                }

            }
            if (e.key === 'Enter') {
                if (this.ButtonSave)
                    this.ButtonSave.focus();
            }
        }

    }

    AddNewMedicine(item) {
        let { lstDetailNew } = this.state
        item.id = Number(item.value);
        item.makho = Number(item.makho);
        item.manguon = Number(item.manguon);
        let olditems = lstDetailNew.filter(record => record.ma === item.ma);
        if (olditems.length > 0) {
            return
        } else {
            item.tenthuoc = item.ten;
            item.dang = item.donvi;
            item.isNew = true
            item.key = item.ma
            item.soluong = null
            item.giathucthu = item.dongia
            item.countNumber = lstDetailNew.length + 1;
            lstDetailNew.push(item);
        }
        let tongtienNew = 0;
        for (let item of lstDetailNew) {
            if (item.soluong && item.giathucthu)
                tongtienNew += item.soluong * item.giathucthu;
        }

        this.newTable.setDataSource(lstDetailNew);
        setTimeout(() => {
            this.newTable.focusNewRow(lstDetailNew[lstDetailNew.length - 1].countNumber,
                'soluong');
        }, 200)

        this.setState({ tongtienNew: tongtienNew, lstDetailNew: lstDetailNew })

    }

    SearchMedicine(key) {
        return MedicineService.DanhSachThuocBanle(2, key, 11, 502)
    }

    onSelect(item) {
        if (item.slton === 0) {
            notification.warning({ message: `Thuốc ${item.ten} không còn trong kho !!!` })
        } else {
            this.AddNewMedicine(item)
        }
    }
    editPrice(row, gia) {
        let { lstDetailNew } = this.state;
        let index = lstDetailNew.findIndex(record => record.id === row.id && record.makho === row.makho && record.manguon === row.manguon && record.tenhang === row.tenhang);
        lstDetailNew[index].giathucthu = gia;
        let tongtien = 0;
        for (let item of lstDetailNew) {
            tongtien += item.soluong * item.giathucthu;
        }
        let tongtienNew = tongtien;
        this.newTable.setDataSource(lstDetailNew);
        this.setState({ lstDetailNew, tongtienNew })
    }
    PrintUse(id, ngay) {
        PrintService.PrintUse(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintMedicine(id, ngay) {
        PrintService.PrintMedicine(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintConclusion(mabn, maql, ngay, isDuoc = false, makp = "", loaiKham = "") {
        PrintService.PrintConclusion(this.props.id, mabn, maql, ngay, makp, loaiKham, isDuoc).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ChangeButton() {
        this.setState({ btnEdit: !this.state.btnEdit }, () => {
            let txt = '';
            if (this.state.btnEdit === true) {
                txt = 'Chỉnh sửa';
            }
            else {
                txt = 'Lưu';
                this.loadDetailToa(this.props.ngay, this.props.id);
            }
            this.setState({ btnEditText: txt });
        })
    }
    render() {

        let dataSourceNew = this.state.lstDetailNew.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.ma
            return o;
        })



        return (

            <React.Fragment>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Chi tiết đơn thuốc</h3>
                    <h3 onClick={() => this.props.onClose()}><i className='far fa-times-circle' /></h3>
                </div>
                <Administrative loai={1} item={this.state.selectedPatient}></Administrative>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>Dịch vụ khám:&nbsp;<strong>{this.props.detail.tendichvu && this.props.detail.tendichvu}</strong></span>
                            <span>Tổng tiền:&nbsp;<strong style={{ marginLeft: 5 }}>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</strong></span>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-inverse table-hover table-bordered">
                            <thead className="thead-inverse">
                                <tr>
                                    <th style={{ minWidth: '35px' }}>TT</th>
                                    <th style={{ minWidth: '150px' }}>Tên thuốc</th>
                                    <th style={{ minWidth: '50px' }}>ĐVT</th>
                                    <th style={{ minWidth: '100px' }}>Tên hoạt chất</th>
                                    <th style={{ minWidth: '110px' }}>Hãng sản xuất</th>
                                    <th style={{ minWidth: '110px' }}>Nước sản xuất</th>
                                    <th style={{ minWidth: '100px' }}>Số lượng</th>
                                    <th style={{ minWidth: '100px' }}>Cách dùng</th>
                                    <th style={{ minWidth: '100px' }}>Giá bán</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSourceNew && dataSourceNew.map((row, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{row.tenthuoc}</td>
                                        <td>{row.donvi}</td>
                                        <td>{row.tenhc}</td>
                                        <td>{row.hangsanxuat}</td>
                                        <td>{row.tennuoc}</td>
                                        <td>{row.soluong}</td>
                                        <td>{row.cachdung}</td>
                                        <td style={{ textAlign: 'right' }}>{row.dongia ? row.dongia.toLocaleString('en-US') : '0'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginTop: 10 }}>Ghi chú: <br></br>
                        <Input style={{ marginTop: 7 }}
                            disabled={this.state.btnEdit && this.props.done !== 0}
                            placeholder="Nhập lý do điều chỉnh giá thực thu"
                            value={this.state.ghichu}
                            onChange={(e) => { this.setState({ ghichu: e.target.value }) }} />
                    </div>
                    <div className='custom-hr'></div>
                    <div className="form-group" style={{
                        marginTop: 15, display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Button className="btn btn-color" style={{ marginRight: 5 }} key="0" onClick={() => { this.setState({ visibleCopy: true }) }} > Tạo phiếu</Button>
                        < div >
                            <Button className="btn btn-outline-secondary" key="1" style={{ marginRight: 5 }} onClick={() => {
                                this.PrintConclusion(this.props.mabn, this.props.detail.maql, this.props.ngay)
                            }} >
                                In đơn thuốc </Button>
                        </div>
                        <Button className="btn btn-danger"
                            onClick={() => this.props.onClose()}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            />  Đóng
                        </Button>
                    </div>
                </div>
                {this.state.visibleCopy &&
                    <Modal maskClosable={false}
                        destroyOnClose={true}
                        width={1400}
                        visible={this.state.visibleCopy}
                        placement="top"
                        closable={false}
                        onClose={() => this.setState({ visibleCopy: false })}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <CreateCopy detail={this.props.detail} ngay={this.props.ngay} mabn={this.props.detail.mabn}
                            done={this.props.detail.done} id={this.props.detail.id}
                            maql={this.props.maql}
                            onClose={this.props.onClose}
                        ></CreateCopy>
                    </Modal>
                }
            </React.Fragment>

        )
    }
}

