import { Button, DatePicker, notification, Popconfirm, Select, TimePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as SystemConfig from '../../configure/systemConfig';

const { Option } = Select;
export default ({ isEdit, onClose, detail, reLoad, chinhanh }) => {
    const [ngay, setNgay] = useState(moment());
    const [giobd, setGiobd] = useState(moment());
    const [giokt, setGiokt] = useState(moment());
    const [listctv, setListctv] = useState([]);
    const [listHen, setListHen] = useState([]);
    const [mactv, setMactv] = useState('');

    useEffect(() => {
        DanhSachCTV();
    }, [])
    useEffect(() => {
        if (!detail.autoid)
            return;
        setGiobd(moment(detail.datestart));
        setGiokt(moment(detail.dateend));
        setNgay(moment(detail.datestart));
        setMactv(detail.mactv.toString())
    }, [detail])
    useEffect(() => {
        if (Number(mactv) === 0)
            return;
        AppointmentService.DanhSachLichDangKyBenhNhanTheoNgay(mactv, giobd.format("DDMMYYYY")).then(result => {
            if (result.isSuccess) {
                setListHen(result.data);
            }
        })
    }, [ngay, mactv])
    const DanhSachCTV = (page = 1, size = 10, getAll = true) => {
        CommonService.GetListCollaborators(page, size, getAll, "all", chinhanh).then(result => {
            if (result.isSuccess) {
                setListctv(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cộng tác viên bị lỗi!' })
        })
    }
    const DeleteLichDangKy = () => {
        Actions.setLoading(true);
        AppointmentService.DeleteLichDangKy(detail.autoid, true).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" });
                if (onClose)
                    onClose()
                if (reLoad) {
                    reLoad()
                }
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    const Submit = () => {
        if (isEdit) {
            const data = {
                autoid: detail.autoid,
                mactv: Number(mactv),
                datestart: ngay.format("YYYY/MM/DD ") + giobd.format("HH:mm"),
                dateend: ngay.format("YYYY/MM/DD ") + giokt.format("HH:mm"),
            }
            if (data.mactv == 0) {
                notification.warning({ message: "Chưa chọn cộng tác viên" })
                return;
            }
            if (giokt < giobd) {
                notification.warning({ message: "Giờ không hợp lệ" })
                return;
            }
            Actions.setLoading(true);
            AppointmentService.UpdateLichDangKy(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    if (onClose)
                        onClose()
                    if (reLoad) {
                        reLoad()
                    }
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err)
            })
        } else {
            const data = {
                mactv: Number(mactv),
                datestart: ngay.format("YYYY/MM/DD ") + giobd.format("HH:mm"),
                dateend: ngay.format("YYYY/MM/DD ") + giokt.format("HH:mm"),
            }
            if (data.mactv == 0) {
                notification.warning({ message: "Chưa chọn cộng tác viên" })
                return;
            }
            if (giokt < giobd) {
                notification.warning({ message: "Giờ không hợp lệ" })
                return;
            }
            Actions.setLoading(true);
            AppointmentService.InsertLichDangKy(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" })
                    if (onClose)
                        onClose()
                    if (reLoad) {
                        reLoad()
                    }
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err)
            })
        }
    }
    return (
        <div className="warrperStyleGroup" style={{ height: '100%' }}>
            <div className="font-weight-bold h3 text-center mb-3">{isEdit ? "Cập nhật" : "Tạo mới"} lịch đăng ký</div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Ngày</label>
                    <DatePicker locale={viVN}
                        className="w-100"
                        value={ngay}
                        allowClear={false}
                        onChange={(value) => (setNgay(value))}
                        format={SystemConfig.FORMAT_DATE} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Cộng tác viên</label>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Chọn cộng tác viên"
                        allowClear={false}
                        value={mactv}
                        onChange={(e) => { setMactv(e) }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {listctv.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Giờ bắt đầu</label>
                    <TimePicker locale={viVN}
                        className="w-100"
                        allowClear={false}
                        value={giobd}
                        onChange={(value) => (setGiobd(value))}
                        format={"HH:mm"} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Giờ kết thúc</label>
                    <TimePicker locale={viVN}
                        className="w-100"
                        allowClear={false}
                        value={giokt}
                        onChange={(value) => (setGiokt(value))}
                        format={"HH:mm"} />
                </div>
                {Number(mactv) !== 0 &&
                    <div className="col-12 mb-2">
                        <div className="font-weight-bold">Danh sách đăng ký ngày {ngay.format(SystemConfig.FORMAT_DATE)}:</div>
                        {listHen.map((row, index) => <div key={index}>
                            {row.value}-{row.label}
                        </div>)}
                    </div>
                }
                <div className="col-12 d-flex justify-content-center">
                    {isEdit &&
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { DeleteLichDangKy() }}
                            okText="Đồng ý" cancelText="Hủy">
                            <Button className="btn btn-danger mr-1">Xóa</Button>
                        </Popconfirm>
                    }
                    <Button className="btn btn-color mr-1 ml-1"
                        onClick={e => { Submit() }}
                    >Lưu</Button>
                    <Button className="btn btn-secondary ml-1"
                        onClick={() => {
                            if (onClose)
                                onClose()
                        }}
                    >Đóng</Button>
                </div>
            </div>
        </div>
    )
}
