import { Form, Input, Modal, notification, Pagination, Select, Table, Button, Tooltip } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import * as MedicineService from '../../../../services/medicineService';
import CreateModal from './createModal';
import * as Actions from "../../../../libs/actions";
import HeaderModal from '../../../../configureHIS/common/headerModal';
import { BUTTON } from '../../../../configureHIS/common/constants';
const { Option } = Select;
let task;
export default function MaterialType() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);


    const [lstThuoc, setLstThuoc] = useState([]);
    const [lstNhom, setLstNhom] = useState([]);

    const [visibleCreate, setVisibleCreate] = useState(false);
    const [manhom, setManhom] = useState(0);
    const [maLoai, setMaLoai] = useState(0);
    const [tenLoai, setTenLoai] = useState("");
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [selectedType, setSelectedType] = useState("");

    const LoadNhom = () => {
        MedicineService.DanhSachNhomThuoc().then(result => {
            if (result.isSuccess) {
                let lstNhom = [];
                lstNhom.push({ value: 0, label: 'Tất cả nhóm' })
                lstNhom = lstNhom.concat(result.data);
                setLstNhom(lstNhom)
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm thuốc bị lỗi' });
        })
    }
    const ListLoaiThuoc = (k = key) => {
        Actions.setLoading(true);
        MedicineService.DanhSachLoaiTheoNhom(manhom, k, currentPage, size).then(result => {
            if (result.isSuccess) {
                setLstThuoc(result.data);
                setTotalRecords(result.totalRecords);
            } else {
                setLstThuoc([]);
                setTotalRecords(0);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu loại thuốc bị lỗi' });
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    const handleOk = e => {
        setVisibleCreate(false);
        ListLoaiThuoc();
    };
    useEffect(() => {
        LoadNhom();
    }, []);
    useEffect(() => {
        setCurrentPage(1)
    }, [key, manhom]);
    useEffect(() => {
        ListLoaiThuoc()
    }, [manhom, currentPage, size]);
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            width: 80,
            render: (zz, xx, index) => ((currentPage - 1) * size) + Number(index) + 1,
        },
        {
            title: 'Loại',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: 'Tên nhóm',
            dataIndex: 'groupname',
            key: 'groupname',
            width: 120,
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            width: 100,
            render: (row) =>
                <div>
                    <Tooltip title="Câp nhật thông tin">
                        <button className='btn btn-warning' onClick={() => { setVisibleUpdate(true); setMaLoai(row.id); setTenLoai(row.name); setSelectedType(row.groupname) }}><i className='fas fa-edit' /></button>
                    </Tooltip>
                </div>
        },
    ];
    let dataSource = lstThuoc.map(function (e, index) {
        let o = Object.assign({}, e);
        o.key = index;
        return o;
    })
    const editGroup = () => {
        Actions.setLoading(true);
        MedicineService.CapNhatLoaiThuoc(maLoai, { name: tenLoai }).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Cập nhật thành công" });
                ListLoaiThuoc();
            } else {
                result.err.msgString ? notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Không thể cập nhật" })
            }
        }).catch(err => {
            notification.warning({ message: 'Đã có lỗi, vui lòng thử lại sau' });
        }).finally(() => {
            setVisibleUpdate(false);
            Actions.setLoading(false);
        })
    }

    const onDelaySearch = (key) => {
        setKey(key);
        if (task) {
            clearTimeout(task);
            task = null;
        }
        task = setTimeout(() => {
            ListLoaiThuoc(key);
        }, 700);
    }
    return (
        <Fragment>
            <div className='containerChilderWrapper'>
                <HeaderModal title="Danh mục loại vật tư - thuốc" />
                <div className="row" style={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}>
                    <div className='col-sm-4 mt-2'>
                        <Select className="w-100" defaultValue={0} value={manhom}
                            onChange={(e) => {
                                setManhom(e)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {lstNhom.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-sm-4 mt-2'>
                        <Input
                            value={key}
                            onChange={(e) => onDelaySearch(e.target.value)}
                            placeholder="Nhập tên loại"

                        />
                    </div>
                    <div className='col-auto ml-auto mt-2'>
                        <Button className={`${BUTTON.THEME}`} onClick={() => setVisibleCreate(true)}><i style={{ marginRight: 5 }} className="fa fa-plus"></i>Tạo mới</Button>
                    </div>
                </div>
                <div className='mt-2' style={{ width: '100%', height: 'calc(100% - 160px)' }}>
                    <Table scroll={{ x: 'max-content', y: '65vh' }}
                        dataSource={dataSource}
                        pagination={false}
                        columns={columns} rowKey={(e, index) => index}
                    />
                </div>
                <Pagination
                    current={currentPage}
                    position={["bottomCenter"]}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { setCurrentPage(page) }}
                    onShowSizeChange={(page, size) => { setSize(size) }}
                    showSizeChanger={true}
                />
            </div>
            <Modal maskClosable={false}
                width={700}
                visible={visibleCreate}
                onCancel={handleOk}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <CreateModal onCancel={handleOk} />
            </Modal>
            <Modal maskClosable={false}
                width={700}
                visible={visibleUpdate}
                onCancel={() => setVisibleUpdate(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>

                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>Cập nhật loại {selectedType}</h3>
                </div>
                <div>
                    <label>Tên loại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input placeholder='Nhập tên loại' value={tenLoai} onChange={(e) => { setTenLoai(e.target.value) }}></Input>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                    <button className="btn btn-success" disabled={tenLoai.length === 0}
                        onClick={() => { editGroup() }}><i style={{ marginRight: 5 }} className="fa fa-save" />Cập nhật</button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                        onClick={() => {
                            setVisibleUpdate(false);
                        }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                        /> Đóng
                    </button>
                </div>
            </Modal>
        </Fragment>
    )
}
