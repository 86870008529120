import QuanLyKhoTong from '../screens/warehouse/khotong';
import Storage from '../screens/config/storage';
import QuanLyHSX from '../screens/warehouse/xuatnhapkho/hangsanxuat/index';
import QuanLyNCC from '../screens/warehouse/xuatnhapkho/nhacungcap';
import MaterialType from '../screens/warehouse/config/materialType';
import Material from '../screens/warehouse/config/material';
import XuatKho from '../screens/warehouse/xuatnhapkho/xuatkho/danhsachphieuxuat';
import HoanTra from '../screens/warehouse/xuatnhapkho/hoantra';
import PhieuXuatChuyenKhoThuoc from '../screens/warehouse/xuatnhapkho/chuyenkho/xuatchuyenkhothuoc';
import NhapKho from '../screens/warehouse/xuatnhapkho/nhapkho/danhsachphieunhapkho';
import ThemCDHA from '../screens/config/pasc/add';
import DanhMucTuVietTat from '../configureHIS/shortHand';
import TheoDoiBanThuoc from '../screens/theodoibanthuoc';
import ThongKeDeNghiKetLuan from '../screens/statistic/ketluan';
import ThanhToanHangLoat from '../screens/invoice/paymentmulti/DanhSachPhieuThanhToan';
import QuanLyUser from '../screens/managementUser/user';
import QuanLyUserGroup from '../screens/managementUser/group';
import EmailConfig from '../screens/config/email';
import CauHinhKhoaPhong from '../configureHIS/departmentConfig/';
import CauHinhNhomThanhVien from '../screens/loyalty/configure';
import ThongKeLayMauTaiNha from '../screens/statistic/laymautainha';
import ThongKeDauSinhTon from '../screens/statistic/dausinhton';
import NguoiGioiThieu from '../configureHIS/nguoigioithieu/index';
import ThongKeDichVu from '../screens/statistic/invoice/services';
import CatalogsDisctrict from '../configureHIS/catalogsdisctrict/';
import Config from '../configureHIS/systemConfig/index';
import Corporation from '../screens/corporation';
import Marketing from '../screens/config/marketing';
import CardAccount from '../screens/cards/cardaccount';
import ThongKeSinhNhat from '../screens/statistic/birthday/danhsachsinhnhat';
import Thongkenguonkhachhang from '../screens/statistic/invoice/doanhso/theonguonkhachhang/theoluotkham';
import QuanLyNhanVien from '../configureHIS/HR/quanlynhanvien';
import Loyalty from '../screens/loyalty';
import LichSuThanhToan from '../screens/statistic/paymenthistory/LichSuThanhToan';
import DanhSachLoaiThanhToan from '../configureHIS/update_vp/screens/danhsachloaithanhtoan';
import ThongKeLichHen from '../screens/statistic/appointment';
import NhomCTV from '../screens/nhomctv';
import ThongKeDaHoanTra from '../screens/statistic/invoice/doanhso/danhsachhoantra';
import Templatevoucher from '../screens/promotion/templatevoucher';
import QuanLyPhieuThuoc from '../screens/invoice/prescriptionMerge/index';
import VatTuDichVuKhamBenh from '../screens/config/equipment/patient';
import DanhSachNhomVP from '../configureHIS/update_vp/screens/danhsachnhomvp';
import ThongKeTiepNhanMau from '../screens/statistic/laymau';
import DieuPhoi from '../screens/dieuphoi';
import PatientStatistic from '../screens/statistic/patient/';
import Promotionnew from '../screens/promotionnew';
import QuanLyGoiThuoc from '../screens/config/goithuoc';
import QuanLyGoiDichVu from '../screens/config/servicepackage';
import QuanLyGoiDichVuNew from '../screens/config/servicepackagenew';
import LisStatistic from '../screens/statistic/lis/';
import ThongKeNguoiTiepDon from '../screens/statistic/invoice/doanhso/tieptan';
import CachDungThuoc from '../screens/medicine/cachdungthuoc';
import ThongKeSoLuongBenhNhan from '../screens/statistic/patient/soluongbenhnhan';
import DanhSachLoaiXN from '../configureHIS/update_vp/screens/typeXN';
import ThongKeNhapKho from '../screens/statistic/warehouse/nhapkho/nhapkho';
import ThongKeDoanhSo from '../screens/statistic/invoice/doanhso/doanhso';
import Card from '../screens/cards/card';
import ThongKeTieuHao from '../screens/statistic/warehouse/tieuhao';
import ThongKeNguoiGioiThieu from '../screens/statistic/invoice/doanhso/nguoigioithieunew';
import ThongKeXuatKho from '../screens/statistic/warehouse/xuatkho/xuatkho';
import ExpiredMedicine from '../screens/statistic/expiredMedicine';
import ThongKeSoLuongThuoc from '../screens/statistic/medicine/amount';
import DanhSachLoaiVP from '../configureHIS/update_vp/screens/danhsachloaivp';
import LichhenLayMau from '../screens/lichhenlaymau';
import LichDangKy from '../screens/lichdangky';
import Promotion from '../screens/promotion';
import Lichhen from '../screens/lichhen';
import TuVan from '../screens/tuvan';
import ThongKeDoanhThuTrungBinh from '../screens/statistic/invoice/doanhthutrungbinh';
import ThongKeChiTieu from '../screens/statistic/invoice/chitieu';
import DanhSachHoaDonDo from '../screens/vatinvoice/index';
import ThongKeVoucher from '../screens/statistic/voucher';
import ThongKeOngMauXetNghiem from '../screens/statistic/ThongKeOngMauXetNghiem';
import Thongkekenhkhachhang from '../screens/statistic/marketing';
import HotlineManage from '../screens/hotline/manager';
import DanhSachUpdateVP from '../configureHIS/update_vp/screens/danhsachvp';
import PhieuThanhToan from '../configureHIS/PhieuThanhToan';
import Hotline from '../screens/hotline';
import QuanLySite from '../screens/config/site';
import ThongKeChuyenKho from '../screens/statistic/warehouse/xuatkho/chuyenkho';
import Roles from '../screens/roles';
import Register from '../screens/recept/register';
import PascStatistic from '../screens/statistic/pasc/';
import CardType from '../screens/cards/cardtype';
import QuanLyBNMoi from '../screens/patient/quanlybenhnhanmoi';
import ResultTest from '../screens/lis/result';
import DMTaiSanCoDinh from '../configureHIS/taisancodinh';
import Menu from '../screens/super-admin/menu';
import ThongKeCongNo from '../screens/statistic/congno';
import Theobenhnhan from '../screens/statistic/invoice/doanhso/theobenhnhan';
import Feature from '../screens/features';
import PayMent from '../screens/invoice/payment';
import PaymentNew from '../screens/invoice/payment/indexNew';
import Survey from '../screens/survey';
import ThongKeApDungVoucher from '../screens/statistic/promotion';
import XacNhanLayMau from '../screens/xacnhanmau/index';
import CompanyExam from '../screens/companyexams';
import ResultSurgery from '../screens/surgery/result';
import VatTuDichVu from '../screens/config/equipment';
import ThuthuatStatistic from '../screens/statistic/thuthuat/';
import ThongKeDoanhThuCDHA from '../screens/statistic/invoice/doanhthu_cdha';
import ConfigQMS from '../screens/qms/configQMS';
import TestResult from '../screens/testresults';
import TestReceived from '../screens/testReceived';
import ThirdPartyConfigEmail from '../screens/thirdPartyConfig/email';
import ThirdPartyConfigSms from '../screens/thirdPartyConfig/sms';
import ThirdPartyConfigZalo from '../screens/thirdPartyConfig/zalo';
import PrintTemplate from '../screens/printTemplate';
import DanhSachLayMau from '../screens/danhSachLayMau';
import ThongKeNguoiDangKi from '../screens/statistic/indication/ThongKeNguoiDangKi';
import ThongKeNguoiThucHien from '../screens/statistic/indication/ThongKeNguoiThucHien';
import MitaRegister from '../screens/register';
import BaoCaoNhanVienTuVan from '../screens/statistic/baocaonhanvientuvan';
import ReportServiceSalesman from '../screens/statistic/baocaoservicesalesman';
import Log from '../screens/logAction';
import DailyReport from '../screens/statistic/invoice/doanhso/DailyReport';
import ConfigIntroVideo from '../configureHIS/introduction/config';
import ServiceMapping from '../configureHIS/thirdPartyServiceMapping';
import AppOrderTest from '../configureHIS/AppOrderTest';
import PhanQuyenChucNangTrenManHinh from '../configureHIS/PhanQuyenChucNangTrenManHinh';
import LogPayment from '../screens/logAction/LogPayment';
import BaoCaoNhanVienDatLich from '../screens/statistic/BaoCaoNhanVienDatLich';
import SoftwareExpiration from "../configureHIS/SoftwareExpiration";
import ReportLogAction from "../screens/super-admin/reportlogaction";
import YeuCauHoanTra from "../screens/yeucauhoantra";
import ThongKeNguoiLayMau from "../screens/statistic/thongkenguoilaymau";
import ConfigMessageTemplate from "../screens/super-admin/configMessageTemplate";
import NotificationMessageConfig from '../screens/notificationMessageConfig';
import CorporationParent from '../screens/corporation/parent';
import CorporationChildGroup from '../screens/corporation/childGroup';
import DiscountConfigure from '../configureHIS/update_vp/screens/discountConfigure';
import CoporationDiscountStatistic from '../screens/statistic/coporationDiscountStatistic';
import SQLIDE from '../screens/super-admin/sqlIDE';
export const screenComponents = {
	"QuanLyKhoTongContent": QuanLyKhoTong,
	"StorageContent": Storage,
	"QuanLyHSXContent": QuanLyHSX,
	"QuanLyNCCContent": QuanLyNCC,
	"MaterialTypeContent": MaterialType,
	"MaterialContent": Material,
	"XuatKhoContent": XuatKho,
	"HoanTraContent": HoanTra,
	"PhieuXuatChuyenKhoThuocContent": PhieuXuatChuyenKhoThuoc,
	"NhapKhoContent": NhapKho,
	"ThemCDHAContent": ThemCDHA,
	"DanhMucTuVietTatContent": DanhMucTuVietTat,
	"TheoDoiBanThuocContent": TheoDoiBanThuoc,
	"ThongKeDeNghiKetLuanContent": ThongKeDeNghiKetLuan,
	"ThanhToanHangLoatContent": ThanhToanHangLoat,
	"QuanLyUserContent": QuanLyUser,
	"QuanLyUserGroupContent": QuanLyUserGroup,
	"EmailConfigContent": EmailConfig,
	"CauHinhKhoaPhongContent": CauHinhKhoaPhong,
	"CauHinhNhomThanhVienContent": CauHinhNhomThanhVien,
	"ThongKeLayMauTaiNhaContent": ThongKeLayMauTaiNha,
	"ThongKeDauSinhTonContent": ThongKeDauSinhTon,
	"NguoiGioiThieuContent": NguoiGioiThieu,
	"ThongKeDichVuContent": ThongKeDichVu,
	"CatalogsDisctrictContent": CatalogsDisctrict,
	"ConfigContent": Config,
	"CorporationContent": Corporation,
	"MarketingContent": Marketing,
	"CardAccountContent": CardAccount,
	"ThongKeSinhNhatContent": ThongKeSinhNhat,
	"ThongkenguonkhachhangContent": Thongkenguonkhachhang,
	"QuanLyNhanVienContent": QuanLyNhanVien,
	"LoyaltyContent": Loyalty,
	"LichSuThanhToanContent": LichSuThanhToan,
	"DanhSachLoaiThanhToanContent": DanhSachLoaiThanhToan,
	"ThongKeLichHenContent": ThongKeLichHen,
	"NhomCTVContent": NhomCTV,
	"ThongKeDaHoanTraContent": ThongKeDaHoanTra,
	"TemplatevoucherContent": Templatevoucher,
	"QuanLyPhieuThuocContent": QuanLyPhieuThuoc,
	"VatTuDichVuKhamBenhContent": VatTuDichVuKhamBenh,
	"DanhSachNhomVPContent": DanhSachNhomVP,
	"ThongKeTiepNhanMauContent": ThongKeTiepNhanMau,
	"DieuPhoiContent": DieuPhoi,
	"PatientStatisticContent": PatientStatistic,
	"PromotionnewContent": Promotionnew,
	"QuanLyGoiThuocContent": QuanLyGoiThuoc,
	"LisStatisticContent": LisStatistic,
	"ThongKeNguoiTiepDonContent": ThongKeNguoiTiepDon,
	"CachDungThuocContent": CachDungThuoc,
	"ThongKeSoLuongBenhNhanContent": ThongKeSoLuongBenhNhan,
	"DanhSachLoaiXNContent": DanhSachLoaiXN,
	"ThongKeNhapKhoContent": ThongKeNhapKho,
	"ThongKeDoanhSoContent": ThongKeDoanhSo,
	"CardContent": Card,
	"ThongKeTieuHaoContent": ThongKeTieuHao,
	"ThongKeNguoiGioiThieuContent": ThongKeNguoiGioiThieu,
	"ThongKeXuatKhoContent": ThongKeXuatKho,
	"ExpiredMedicineContent": ExpiredMedicine,
	"ThongKeSoLuongThuocContent": ThongKeSoLuongThuoc,
	"DanhSachLoaiVPContent": DanhSachLoaiVP,
	"LichhenLayMauContent": LichhenLayMau,
	"LichDangKyContent": LichDangKy,
	"PromotionContent": Promotion,
	"LichhenContent": Lichhen,
	"TuVanContent": TuVan,
	"ThongKeDoanhThuTrungBinhContent": ThongKeDoanhThuTrungBinh,
	"ThongKeChiTieuContent": ThongKeChiTieu,
	"DanhSachHoaDonDoContent": DanhSachHoaDonDo,
	"ThongKeVoucherContent": ThongKeVoucher,
	"ThongKeOngMauXetNghiemContent": ThongKeOngMauXetNghiem,
	"ThongkekenhkhachhangContent": Thongkekenhkhachhang,
	"HotlineManageContent": HotlineManage,
	"DanhSachUpdateVPContent": DanhSachUpdateVP,
	"PhieuThanhToanContent": PhieuThanhToan,
	"HotlineContent": Hotline,
	"QuanLySiteContent": QuanLySite,
	"ThongKeChuyenKhoContent": ThongKeChuyenKho,
	"RolesContent": Roles,
	"RegisterContent": Register,
	"CardTypeContent": CardType,
	"QuanLyBNMoiContent": QuanLyBNMoi,
	"ResultTestContent": ResultTest,
	"DMTaiSanCoDinhContent": DMTaiSanCoDinh,
	"MenuContent": Menu,
	"ThongKeCongNoContent": ThongKeCongNo,
	"TheobenhnhanContent": DailyReport,
	"FeatureContent": Feature,
	"PayMentContent": PayMent,
	"PaymentNewContent": PaymentNew,
	"SurveyContent": Survey,
	"ThongKeApDungVoucherContent": ThongKeApDungVoucher,
	"XacNhanLayMauContent": XacNhanLayMau,
	"CompanyExamContent": CompanyExam,
	"PascStatisticContent": PascStatistic,
	"ResultSurgeryContent": ResultSurgery,
	"VatTuDichVuContent": VatTuDichVu,
	"ThuthuatStatisticContent": ThuthuatStatistic,
	"ThongKeDoanhThuCDHAContent": ThongKeDoanhThuCDHA,
	"ConfigQMSContent": ConfigQMS,
	"QuanLyGoiDichVuContent": QuanLyGoiDichVu,
	"TestResultContent": TestResult,
	"TestReceivedContent": TestReceived,
	"ThirdPartyConfigEmailContent": ThirdPartyConfigEmail,
	"ThirdPartyConfigSmsContent": ThirdPartyConfigSms,
	"ThirdPartyConfigZaloContent": ThirdPartyConfigZalo,
	"PrintTemplateContent": PrintTemplate,
	"DanhSachLayMauContent": DanhSachLayMau,
	"ThongKeNguoiDangKiContent" : ThongKeNguoiDangKi,
	"ThongKeNguoiThucHienContent" : ThongKeNguoiThucHien,
	"QuanLyGoiDichVuNewContent": QuanLyGoiDichVuNew,
	"MitaRegisterContent" : MitaRegister,
	"BaoCaoNhanVienTuVanContent": BaoCaoNhanVienTuVan,
	"ReportServiceSalesmanContent": ReportServiceSalesman,
	"LogContent": Log,
	"ConfigIntroVideoContent": ConfigIntroVideo,
	"ServiceMappingContent": ServiceMapping,
	"AppOrderTestContent": AppOrderTest,
	"PhanQuyenChucNangTrenManHinhContent": PhanQuyenChucNangTrenManHinh,
	"LogPaymentContent": LogPayment,
	"BaoCaoNhanVienDatLichContent": BaoCaoNhanVienDatLich,
	"SoftwareExpirationContent": SoftwareExpiration,
	"ReportLogActionContent": ReportLogAction,
	"YeuCauHoanTraContent": YeuCauHoanTra,
	"ThongKeNguoiLayMauContent": ThongKeNguoiLayMau,
	"ConfigMessageTemplateContent": ConfigMessageTemplate,
	"NotificationMessageConfigContent": NotificationMessageConfig,
	"CorporationParentContent": CorporationParent,
	"CorporationChildGroupContent": CorporationChildGroup,
	"DiscountConfigureContent": DiscountConfigure,
	"CoporationDiscountStatisticContent": CoporationDiscountStatistic,
	"SQLIDEContent": SQLIDE,
}