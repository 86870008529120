
import { Form, Input, Modal, notification, Pagination, Table, Select, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
import CreateWard from './createward';
const { Option } = Select;
const initialState = {
    visible: false,
    visibleCreate: false,
    matt: '',
    maquan: '',
    mapxa: '',
    tenpxa: '',
    totalRecords: 0,
    key: '',
    tenphongkham: ''
}
let task = null;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstCity: [],
            lstDistrict: [],
            lstWard: [],
            key: '',
            visabledUpdate: false,
            currentPage: 1,
            pageSize: 10,
        };
    }

    componentWillMount() {
        this.loadCity();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadCity() {
        CommonService.DanhMucThanhThi().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data,
                    lstDistrict: [],
                    lstWard: []
                })

            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    loadDistrict(e) {
        CommonService.DanhMucHuyen(e).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDistrict: result.data,
                    maquan: ''
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu quận huyện bị lỗi' })
        })
    }
    onReload = () => {
        this.setState({
            currentPage: 1
        })
        this.loadWard();
    }
    async loadWard(page = this.state.currentPage, size = this.state.pageSize) {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            CommonService.DanhMucPhuongXa(this.state.maquan, this.state.key, page, size).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    this.setState({
                        lstWard: result.data,
                        totalRecords: result.totalRecords

                    });
                }
                else {
                    this.setState({
                        lstWard: [],
                        totalRecords: 0
                    })
                }
                this.setState({ currentPage: page })
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu phường xã bị lỗi' })
            })
        }
        else {
            this.validator.showMessages();
        }
    }
    async updateWard() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                maquan: this.state.maquan,
                mapxa: this.state.mapxa,
                tenpxa: this.state.tenpxa,
                loai: 'phuongxa',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        visabledUpdate: false
                    })
                    notification.success({ message: 'Cập nhật thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.loadWard(this.state.currentPage);
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    DeleteWard(row) {
        const data = {
            mapxa: row.ma,
            tenquan: row.ten,
            maquan: row.maquan,
            loai: 'phuongxa',
        }
        CommonService.deleteCity(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.setState({ currentPage: 1 }, () => { this.loadWard() })
            }

        }).catch(err => {
        })
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };
    render() {
        const columns = [
            {
                title: 'Mã Phường/Xã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên Phường/Xã',
                dataIndex: 'ten',
                key: 'ten',
            },

            {
                title: '',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật Phường/Xã"
                                onClick={() => {
                                    this.setState({ mapxa: row.ma, tenpxa: row.ten, maquan: row.maquan, visible: true });
                                    this.form.setFieldsValue({
                                        tenpxa: row.ten
                                    })
                                }} > <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.DeleteWard(row) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstWard.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}  style={{height:'100%'}}>

                    <div className="row no-gutters" style={{ display: 'flex' }}>
                        <div className="col-md-3 mt-2">
                            <Form.Item name="matt" label="Thành phố:"
                                rules={[{ required: true, message: 'Chưa chọn thành thị!' }]}>
                                <Select
                                    showSearch
                                    onChange={(e) => {
                                        this.loadDistrict(e);
                                        this.form.setFieldsValue({
                                            maquan: ''
                                        })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstCity.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.key}>{row.value}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-3 mt-2">
                            <Form.Item label="Quận/Huyện:" name="maquan" rules={[{ required: true, message: 'Chưa chọn quận!' }]}>
                                <Select
                                    value={this.state.maquan}
                                    showSearch
                                    onChange={(e) => { this.setState({ maquan: e }, () => { this.loadWard(1) }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstDistrict.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.key}>{row.value}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-2 mt-2">
                            <Form.Item>
                                <Input
                                    value={this.state.key}
                                    placeholder="Nhập mã hoặc tên phường xã.."
                                    onChange={(e) => {
                                        this.setState({ key: e.target.value });
                                        task = setTimeout(() => {
                                            this.loadWard();
                                        }, 800)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.setState({ currentPage: 1 }, () => { this.loadWard() })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-3 mt-2" style={{ display: 'flex' }}>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={() => { this.loadWard(1, this.state.pageSize) }}>
                                    <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                </button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}>
                                    <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{height:'calc(100% - 90px)'}}>
                        <Table dataSource={dataSource}
                            scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }, () => { this.loadWard(page, size) }) }}
                        onShowSizeChange={(page, size) => this.setState({ currentPage: page, pageSize: size }, () => { this.loadWard(page, size) })}
                        showSizeChanger={true}
                    />


                </Form>

                <Modal maskClosable={false}
                    width={600}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                        <h3>Cập nhật dữ liệu phường/xã</h3>
                    </div>
                    <div className="container-fluid row" style={{ display: 'flex', padding: 'inherit' }}>
                        <div className="form-group" style={{ flex: 1 }} >
                            <Form.Item label="Tên phường/xã:" style={{ flex: 1 }} rules={[{ required: true, message: 'Tên Phường/Xã không được để trống!' }]}>
                                <Input
                                    value={this.state.tenpxa}
                                    onChange={(e) => {
                                        this.setState({ tenpxa: e.target.value })
                                    }} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className="btn btn-success"
                            onClick={() => { this.updateWard() }}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} /> Lưu</button>

                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => { this.handleCancel(); this.onReload(); }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng
                        </button>
                    </div>
                </Modal>
                {this.state.visibleCreate && <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleCreate}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateWard onCancel={this.handleCancel}
                        onReload={this.onReload}
                        onLoad={this.loadWard.bind(this)} />
                </Modal>
                }
            </React.Fragment>
        )
    }
}