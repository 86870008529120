import React,  { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment';

export default (props) => {
    const _hiddenLink = useRef();
    const _imageRef = useRef();
    const previewCanvasRef = useRef();
    const blobUrlRef = useRef('');
    const [previewImage, setPreviewImage] = useState('');
    // Crop picture
    const [cropPicture, setCropPicture] = useState(false);
    const [crop, setCrop] = useState({
        unit : '%',  
        width: 30,
    });

    useEffect(() => {
        setPreviewImage(props.image);
    }, [props.image])
    
    const onCropComplete = (crop) => {
        makeClientCrop(crop);
        setCropPicture(false);
    }

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop)
    };

    async function makeClientCrop(crop){
        let uid = moment().format("YYYYMMDDHHMMss") + moment().milliseconds().toString();
        let name = "snapshot" + uid + ".jpeg";
        if (_imageRef.current && previewCanvasRef.current && crop.width && crop.height) {
            const blob = await getCroppedImg( _imageRef.current, previewCanvasRef.current, crop, name );
            let fileUrl = URL.createObjectURL(blob)
            let temp = {
                uid: uid,
                name: name,
                status: 'done',
                url: fileUrl,
                blob: new File([blob], name)
            }
            props.onCropComplete && props.onCropComplete(temp)
        }   
    }

    const getCroppedImg = (image, canvas ,crop, fileName) => {
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
        );
        ctx.restore()
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                resolve(blob);
                }); 
        });
    }

    return (
        <>
        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='d-flex flex-column justify-content-between w-100 h-100 overflow-hide'>
            <div className='d-flex justify-content-center overflow-auto'>
                <div className='mt-2'>
                    {cropPicture ? 
                        <ReactCrop 
                            crop={crop} 
                            onChange={(crop, percentCrop) => onCropChange(crop, percentCrop)}>
                            <img ref={_imageRef} style={{ maxWidth: '100%', height: 'auto' }} alt="Crop" src={previewImage} />
                        </ReactCrop>
                    :
                        <img ref={_imageRef} style={{ maxWidth: '100%', height: 'auto' }} alt="example" src={previewImage} /> 
                    }
                </div>
            </div>
            <div>
                <div className='col-12 mt-2 d-flex justify-content-between'>
                    <div>
                        <button className='btn btn-secondary ml-2' onClick={() => setCropPicture(true)} disabled={cropPicture}> 
                            <i class="fas fa-crop-alt mr-2"/> Cắt hình
                        </button>
                    </div>
                    <div>
                        <button className='btn btn-color mr-2' onClick={() => onCropComplete(crop)}> 
                            <i class="fa fa-save mr-2"/> Lưu
                        </button>
                        <button className="btn btn-danger"
                            onClick={() => {
                                setCropPicture(false);
                                props.onClose();
                            }}>
                            <i className="fas fa-sign-out-alt mr-2" /> Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <canvas
            
            ref={previewCanvasRef}
            style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            }}
        />
        </>
    )
};
