import React, {  useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Table, Pagination, Spin, List, Popover } from 'antd';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import * as Actions from '../../../../libs/actions';

export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      resetToFirstPage();
    },
  }));
  //#region khai báo state
  const notiRef = useRef(null);
  const firstRender = useRef(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex]);

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    setLoading(true);
    Actions.setLoading(true);
    let filterModel = {
      siteIds: props.siteIds ?? [],
      accountIds : props.accountId ?? [],
      coporationIds: props.coporationIds ?? [],
      dateFrom: props.dateFrom ?? '',
      dateTo: props.dateTo ?? '',
      nhomMas : props.nhomMas ?? [],
      loaiIds : props.loaiIds ?? [],
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      IsGetAll : false
    }
    ReportServices.DetailRegisterServiceList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
      Actions.setLoading(false);
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }

  const renderComboService = (data) => {
      return (
          <div className='list-page' style={{ minWidth: "60vh" }}>
              <div className="list-page-header"> {/* header */}
                  <h5>Danh sách dịch vụ con của [{data.serviceName}]</h5>
              </div>
              <div className="list-page-body">{/* body */}
                  <div className="list-page-table">
                      <List className="none-border-bottom none-border-bottom-item"
                          bordered={false}
                          dataSource={data.listComboServiceDetail}
                          header={
                              <List.Item key={`header`} className='p-1'>
                                  <div className={`d-flex justify-content-between w-100 pl-2`}>{/* 1 item */}
                                      <div className="" style={{width: 'calc(60%)'}}>{/* column 1: tên - mã */}
                                          <span className={`mita-title`} style={{whiteSpace: "pre-line"}}>Tên dịch vụ</span>
                                      </div>
                                      <div className='' style={{width: '40%'}}>{/* column 2: nhóm/loại */}
                                          <span className='mita-title' style={{whiteSpace: "pre-line"}}>Loại</span>
                                      </div>
                                  </div>
                              </List.Item>
                          }
                          renderItem={(item) => {
                              let className = "p-1 border-2 border-top";
                              return (
                                  <List.Item key={`${item.id}`}
                                      className={className}
                                  >
                                      <div className={`d-flex justify-content-between w-100 pl-2`} key={`pr-${item.id}`}>{/* 1 item */}
                                          <div className="" style={{width: 'calc(60%)'}}>{/* column 1: tên - mã */}
                                              <span className={`mita-value`} style={{whiteSpace: "pre-line"}}>{item.name} - {item.maVP}</span>
                                          </div>
                                          <div className='' style={{width: '40%'}}>{/* column 2: nhóm/loại */}
                                              <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.groupName}</span>
                                          </div>
                                      </div>
                                  </List.Item>
                              )
                          }}
                      >
                      </List>
                  </div>
              </div>
          </div>
      )
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
  { 
      title: 'Người đăng kí',
      dataIndex: 'accountName',
      key: 'accountName',
      align: 'left',
      width: 115,
      // render: (value, record) => {
      // }
    },
    {
        title: 'Tên dịch vụ',
        dataIndex: 'serviceName',
        key: 'serviceName',
        align: 'left',
        width: 200,
        render: (value, record) => {
          return (
            <span>{value} 
              {record.serviceType === "COMBO" ? 
                <Popover trigger="hover" content = {() => renderComboService(record)}> 
                  <span><i className="fas fa-info-circle ml-1"></i></span> 
                </Popover> 
              : ''}</span>
          )
      }
    },
    {
        title: 'Nhóm dịch vụ',
        dataIndex: 'serviceType',
        key: 'serviceType',
        align: 'left',
        width: 100,
    },
    {
      title: 'Số lượng dịch vụ chỉ định',
      dataIndex: 'quantityIndication',
      key: 'quantityIndication',
      align: 'center',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value) }</span>
          )
      }
    },
    {
      title: 'Số lượng dịch vụ chưa thanh toán',
      dataIndex: 'quantityUnpay',
      key: 'quantityUnpay',
      align: 'center',
      width: 80,
      render: (value, record) => {
        return (
          <span>{ Number(value) }</span>
        )
      }
    },
    {
      title: 'Số lượng dịch vụ đã thanh toán',
      dataIndex: 'quantityPaid',
      key: 'quantityPaid',
      align: 'center',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value) }</span>
          )
      }
    },
    {
      title: 'Số lượng bệnh nhân',
      dataIndex: 'numberPatient',
      key: 'numberPatient',
      align: 'center',
      width: 100,
      render: (value, record) => {
          return (
            <span>{ Number(value) }</span>
          )
      }
    },
    {
      title: 'Tổng tiền dịch vụ',
      dataIndex: 'totalIndication',
      key: 'totalIndication',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
    {
      title: 'Tổng tiền chưa thanh toán',
      dataIndex: 'totalUnpay',
      key: 'totalUnpay',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
    {
      title: 'Tổng tiền đã thanh toán',
      dataIndex: 'totalPaid',
      key: 'totalPaid',
      align: 'right',
      width: 80,
      render: (value, record) => {
          return (
            <span>{ Number(value).toLocaleString('en-US') }</span>
          )
      }
    },
  ];
  //#endregion
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <div className="list-page" >
            <div className="list-page-body">{/* body */}
              <div className="row">{/* notification */}
                <div className="col-md-12"><Notify ref={notiRef} /></div>
              </div>
              <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                  className="table-min-h-0 table-header-1row"
                  dataSource={listResult} 
                  columns={columns}
                  locale={{ emptyText: 'Không có dữ liệu' }} 
                  scroll={{ x: 1200, y: 0 }}
                  rowKey={(e) => e.accountId + e.serviceCode}
                  rowClassName={(record, rowIndex) => {
                  }}
                  pagination={false}
                  // onChange={onTableChange} 
                />
                <Pagination
                  current={pageIndex}
                  size={pageSize}
                  style={{ textAlignLast: "center", marginTop: "10px" }}
                  total={totalRecord}
                  showTotal={total => `Tổng: ${total}`}
                  onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                  onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                  showSizeChanger={true}
                />
              </div>
            </div>
        </div>
      </Spin>
    </React.Fragment>
  );
  
})