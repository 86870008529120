import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Modal, notification, Radio, Select, Space, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';
import * as NhapkhoaService from '../../../services/nhapkhoaService';
import EditModalNhapKhoa from './editModal';

const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            department: '',
            lstdepartment: [],
            nhapXuatKhoa: false,
            lstPatient: [],
            totalPatient: 0,
            visible: false,
            detail: {},
        };
    }

    componentWillMount() {
        this.loadKhoaCanNhap();
    }

    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.value !== '00');
                this.setState({
                    lstdepartment: data
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu khoa cần nhập bị lỗi' })
        })
    }

    loadDanhSachBenhNhanNhapKhoa() {
        if (this.state.department === '') {
            notification.warning({ message: 'Chưa chọn khoa!' })
            return;
        }
        if (this.state.nhapXuatKhoa) {
            NhapkhoaService.DanhSachDaNhap(this.state.department).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstPatient: result.data,
                        totalPatient: result.data.length,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu bệnh nhân cần nhập bị lỗi' })
            })
        }
        else {
            NhapkhoaService.DanhSachChoNhap(this.state.department).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstPatient: result.data,
                        totalPatient: result.data.length,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu bệnh nhân đã nhập bị lỗi' })
            })
        }
    }

    async submit() {
        Actions.setLoading(true);
        this.editmodal.getData().then(result => {
            if (result !== null && result !== undefined) {
                const data = {
                    'code': this.state.detail.code,
                    'name': this.state.detail.name,
                    'recommend': result.recommend,
                    'recommendName': result.recommendName,
                    'receiveFrom': result.receiveFrom,
                    'subjectCode': this.state.detail.subjectCode === '' ? '2' : this.state.detail.subjectCode,
                    'cardNumber': result.cardNumber,
                    'cardFrom': result.dateExpired.length > 1 ? (result.dateExpired[0] !== '' && result.dateExpired[0] !== null) ? result.dateExpired[0].format('YYYY-MM-DD') : '0001-01-01' : '0001-01-01',
                    'cardTo': result.dateExpired.length > 1 ? (result.dateExpired[1] !== '' && result.dateExpired[1] !== null) ? result.dateExpired[1].format('YYYY-MM-DD') : '0001-01-01' : '0001-01-01',
                    'cardCode': result.cardCode,
                    'cardName': result.cardAddressLabel,
                    'comeFrom': result.comeFrom,
                    'department': this.state.department,
                    'route': result.route,
                    'routeCode': result.cardCode,
                    'routeName': result.cardAddressLabel,
                    'diagnostic': result.diagnostic,
                    'diagnosticName': result.diagnosticName,
                    'lstOtherDiseases': result.CDkemtheo,
                    'cdFrom': result.cdFrom,
                    'cdFromName': result.cdFromName,
                    'bed': result.bed,
                    'roomType': result.typeRoom,
                    'doctorReceive': result.doctorReceive,
                    'nearPoor': result.nearPoor,
                    'nearPoorCode': result.nearPoorCode,
                    'enterCode': this.state.detail.enterCode,
                    'enterNumber': this.state.detail.enterNumber !== '' ? this.state.detail.enterNumber : result.enterNumber,
                    'managerId': this.state.detail.managerId,
                    'timesInto': "1",
                    'clinical': result.clinical,
                    'switchFrom': result.switchFrom,
                    'dateInDepartment': result.dateInDepartment.format('YYYY-MM-DD HH:mm'),
                    'dateInHospital': result.dateInHospital.format('YYYY-MM-DD HH:mm'),
                }

                NhapkhoaService.LuuNhapKhoa(data).then(result => {
                    if (result.isSuccess) {
                        this.loadDanhSachBenhNhanNhapKhoa();
                        this.setState({ visible: false });
                        notification.success({ message: "Lưu thành công" });
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
            }
            Actions.setLoading(false);
        }).catch(() => Actions.setLoading(false));
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    // placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <button
                        className="btn btn-success"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        <i className="fa fa-search" style={{ marginRight: '5px' }} />  Tìm
                    </button>
                    <button className="btn btn-danger" danger onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        <i className="fa fa-eraser" style={{ marginRight: '5px' }} /> Xóa
                    </button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setFormEnterInfo(object) {
        this.form.setFieldsValue(object)
    }
    setData(row) {
        this.setState({ detail: row, visible: true, });
    }
    render() {
        const dataSource = this.state.lstPatient
        const columns = [
            {
                title: '',
                render: (row) => <div>
                    <button className="btn btn-color" onClick={() => {
                        this.setData(row);
                    }}><i className="fa fa-edit"></i></button>
                </div>
            },
            {
                title: 'Mã BN',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code'),
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'dob',
                key: 'dob',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY')}</span>);
                }
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Ngày vào viện',
                dataIndex: 'dateInHospital',
                key: 'dateInHospital',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>);
                }
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'diagnosticName',
                key: 'diagnosticName',
            },
            {
                title: 'Chẩn đoán nơi chuyển',
                dataIndex: 'cdFromName',
                key: 'cdFromName',
            },
            {
                title: 'Bác sĩ nhận',
                dataIndex: 'doctorReceiveName',
                key: 'doctorReceiveName',
            },
            {
                title: 'Nhận từ',
                dataIndex: 'receiveFromName',
                key: 'receiveFromName',
            },
            {
                title: 'Nơi giới thiệu',
                dataIndex: 'comeFromName',
                key: 'comeFromName',
            },
            {
                title: 'Khoa chuyển đến',
                dataIndex: 'switchFromName',
                key: 'switchFromName',
            },
            {
                title: 'Bệnh viện chuyển đến',
                dataIndex: 'switchHospitalName',
                key: 'switchHospitalName',
            },
        ];
        const columnHienDien = [
            // {
            //     title: '',
            //     render: (row) => <div>
            //         <button className="btn btn-color" onClick={() => {
            //             this.setData(row);
            //         }}><i className="fa fa-edit"></i></button>
            //     </div>
            // },
            {
                title: 'Mã BN',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code'),
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'dob',
                key: 'dob',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY')}</span>);
                }
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Ngày vào viện',
                dataIndex: 'dateInHospital',
                key: 'dateInHospital',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>);
                }
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'diagnosticName',
                key: 'diagnosticName',
            },
            {
                title: 'Chẩn đoán nơi chuyển',
                dataIndex: 'cdFromName',
                key: 'cdFromName',
            },
            {
                title: 'Bác sĩ nhận',
                dataIndex: 'doctorReceiveName',
                key: 'doctorReceiveName',
            },
            {
                title: 'Giường',
                dataIndex: 'bedName',
                key: 'bedName',
            },
            {
                title: 'Phòng',
                dataIndex: 'roomName',
                key: 'roomName',
            },
            {
                title: 'Loại phòng',
                dataIndex: 'roomTypeName',
                key: 'roomTypeName',
            },
            {
                title: 'Nhận từ',
                dataIndex: 'receiveFromName',
                key: 'receiveFromName',
            },
            {
                title: 'Nơi giới thiệu',
                dataIndex: 'comeFromName',
                key: 'comeFromName',
            },
            {
                title: 'Khoa chuyển đến',
                dataIndex: 'switchFromName',
                key: 'switchFromName',
            },
            {
                title: 'Bệnh viện chuyển đến',
                dataIndex: 'switchHospitalName',
                key: 'switchHospitalName',
            },
        ];
        return (
            <React.Fragment>
                <div className="font-weight-bold" style={{ textAlign: 'center', marginBottom: '10px' }}>DANH SÁCH CHỜ NHẬP KHOA</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <Form.Item label="Chọn khoa cần nhập:">
                            <Select style={{ width: '200px', textTransform: 'uppercase' }} value={this.state.department} onChange={(value) => { this.setState({ department: value }, () => { this.loadDanhSachBenhNhanNhapKhoa() }) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstdepartment.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value} style={{ textTransform: 'uppercase' }}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Radio.Group value={this.state.nhapXuatKhoa} onChange={() => { this.setState({ nhapXuatKhoa: !this.state.nhapXuatKhoa }, () => { this.loadDanhSachBenhNhanNhapKhoa() }) }}>
                                <Radio value={false}>Chờ nhập khoa</Radio>
                                <Radio value={true}>Hiện diện trong khoa</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-color"
                                onClick={() => { this.loadDanhSachBenhNhanNhapKhoa() }}>
                                <i className="fas fa-sync" title="refesh"></i></button>
                        </Form.Item>
                    </div>
                </div>
                <Table dataSource={dataSource} columns={this.state.nhapXuatKhoa ? columnHienDien : columns} rowKey={(e, index) => index}

                ></Table>
                {this.state.visible && <Modal maskClosable={false}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    width={1000}
                >
                    <EditModalNhapKhoa ref={(c) => this.editmodal = c} detail={this.state.detail} makp={this.state.department} />
                    <div className="d-flex justify-content-center">
                        <button key="2" className="btn btn-color" style={{ marginRight: 10 }}
                            onClick={() => { this.submit() }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                        <button key="3" className="btn btn-danger"
                            onClick={() => { this.setState({ visible: false }) }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </Modal>
                }
            </React.Fragment>
        )
    }
}
