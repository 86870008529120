import React, { useState, useEffect } from 'react';
import * as ConfirmOrderService from '../../../services/confirmOrderService';
import * as configService from '../../../services/configService';
import * as ConfigService from '../../../services/configService';
import * as Actions from '../../../libs/actions';
import moment from 'moment';
import Autocomplete from '../../../components/autocomplete';
import { Pagination, Popconfirm, Modal, notification, Input, InputNumber, Button, Checkbox, Select, Table } from 'antd';

const { Option } = Select;

export default () => {
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState(1);
    const [typeEquip, settypeEquip] = useState('');
    const [lstTypeEquip, setlstTypeEquip] = useState([]);
    const [lstEquip, setlstEquip] = useState([]);
    const [equip, setequip] = useState('');
    const [amount, setamount] = useState(1);
    const [lstData, setlstData] = useState([]);
    const [visible, setvisible] = useState(false);
    const [detail, setdetail] = useState({});
    const [amountedit, setamountedit] = useState(0);

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data)
            }
            else {
                setListSite([]);
                setChinhanh('')
            }
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        GetListSite();
        GetListEquipmentTypeConfig();
    }, []);

    useEffect(() => {
        GetListEquipReceipt()
    }, [chinhanh]);

    const GetListEquipmentTypeConfig = () => {
        configService.GetListEquipmentTypeConfig().then((respone) => {
            if (respone.isSuccess === true) {
                setListSite(respone.data)
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const GetListEquipmentConfig = (key) => {
        let type = typeEquip === undefined ? '' : typeEquip;
        configService.GetListEquipmentConfig(type, key).then((respone) => {
            if (respone.isSuccess === true) {
                setlstEquip(respone.data);
                setequip('');
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const InsertEquipOfService = () => {
        const data = {
            'mavp': equip,
            'chinhanh': chinhanh.toString(),
            'soluong': amount,
        }
        Actions.setLoading(true);
        configService.InsertEquipReceipt(data).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                // setequip('');
                setamount(1);
                GetListEquipReceipt();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const GetListEquipReceipt = () => {
        configService.GetListEquipReceipt(chinhanh).then((respone) => {
            if (respone.isSuccess === true) {
                setlstData(respone.data);
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const DeleteEquipReceipt = (chinhanh, mavp) => {
        Actions.setLoading(true);
        configService.DeleteEquipReceipt(chinhanh, mavp).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                GetListEquipReceipt();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const UpdateEquipReceipt = () => {
        const data = {
            'chinhanh': detail.chinhanh,
            'mavp': detail.mavp,
            'soluong': amountedit,
        }
        Actions.setLoading(true);
        configService.UpdateEquipReceipt(data).then((respone) => {
            if (respone.isSuccess === true) {
                notification.success({ message: "Thao tác thành công!" });
                setvisible(false);
                setamountedit(0);
                GetListEquipReceipt();
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
            Actions.setLoading(false);
        }).catch((ex) => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (text, record, index) => Number(index) + 1,
        },
        {
            title: 'Tên vật tư',
            dataIndex: 'tenbd',
            key: 'tenbd',
        },
        {
            title: 'Số lượng',
            dataIndex: 'soluong',
            key: 'soluong',
        },
        {
            title: 'Action',
            render: (row) => {
                return (
                    <div>
                        <button style={{ marginRight: 10 }} className="btn btn-warning" onClick={() => {
                            setvisible(true);
                            setdetail(row);
                            setamountedit(row.soluong);
                        }}>
                            <i className="fa fa-pencil-alt" style={{ marginRight: 5 }}></i>Cập nhật</button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa vật tư này không?"
                            onConfirm={() => { setvisible(false); DeleteEquipReceipt(row.chinhanh, row.mavp) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" ><i className="fa fa-trash" style={{ marginRight: 5 }}></i>Xóa</button>
                        </Popconfirm>
                    </div>
                );
            },
        }
    ]
    return (
        <div style={{ height: '100%' }}>
            <div className={'containerChilderWrapper'}>
                <div className="h4 text-center font-weight-bold my-2">CẤU HÌNH TIÊU HAO THEO LƯỢT KHÁM</div>
                <div className='row'>
                    <div className='col-md-6 my-1'>
                        <label>Chi nhánh</label>
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            allowClear={false}
                            value={chinhanh}
                            onChange={(e) => {
                                setChinhanh(e)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {listSite.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id}>{row.ma}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Loại vật tư</label>
                        <Select
                            allowClear={true}
                            showSearch
                            style={{ width: '100%' }}
                            value={typeEquip}
                            onChange={(e) => {
                                settypeEquip(e);
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {lstTypeEquip.map((sl, index) => {
                                return (
                                    <Option key={index} value={sl.value}>{sl.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Tên vật tư</label>
                        <Autocomplete width={'100%'}
                            dataSource={lstEquip}
                            handleSearch={GetListEquipmentConfig.bind(this)}
                            getItem={(key, value) => {
                                setequip(key)
                            }}
                        />
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Số lượng</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            maxLength={5}
                            max={99999}
                            onChange={(e) => setamount(e)}
                            value={amount} />
                    </div>
                    <div className='col-12 my-1 text-center'>
                        <button className='btn btn-color' onClick={() => {
                            InsertEquipOfService()
                        }}>Thêm</button>
                    </div>
                    <div className='col-12 my-1'>
                        <div className="table-responsive">
                            <Table columns={columns} scroll={{ y: 0 }}
                                pagination={false}
                                dataSource={lstData} />
                        </div>
                    </div>
                </div>
            </div>
            {visible && <Modal maskClosable={false}
                visible={visible}
                onCancel={() => { setvisible(false) }}
            >
                <div className='row'>
                    <div className='col-md-6 my-1'>
                        <label>Tên vật tư</label>
                        <Input disabled={true} value={detail.tenbd} />
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Tên vật tư</label>
                        <InputNumber min={0} maxLength={5} max={99999} style={{ width: '100%' }}
                            onChange={(e) => setamountedit(e)}
                            defaultValue={detail.soluong} />
                    </div>
                    <div className="col-12" style={{ justifyContent: 'center', display: 'flex' }}>
                        <button style={{ marginRight: 10 }} className="btn btn-success" onClick={() => UpdateEquipReceipt()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu</button>
                        <button className="btn btn-danger" onClick={() => { setvisible(false) }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}></i>Đóng</button>
                    </div>
                </div>
            </Modal>}
        </div>
    );
}
