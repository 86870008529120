import { Form, Input, InputNumber, notification, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import * as CommonService from '../../../services/commonService';
import * as NhapVienService from '../../../services/nhapVienService';

const { Option } = Select;
export default class editModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstdepartment: [],
            result: '',
            lstResult: [],
            status: '',
            lstStatus: [],
            lstDoctorReceive: [],
            lstDiagnostic: [],
            lstOtherDiseases: [],
            lstReason: [],
            hospitalType: '',
            lstHospitalType: [],
            lstSwitchHospital: [],
            detail: {},
            otherDiseases: [],
            diagnostic: '',
            diagnosticName: '',
        };
    }
    componentDidMount() {
        this.setState({
            detail: this.props.detail,
            diagnostic: this.props.detail.diagnostic,
            diagnosticName: this.props.detail.diagnosticName,
            lstDiagnostic: [{ 'value': this.props.detail.diagnostic, 'label': this.props.detail.diagnosticName }],
            doctorReceive: this.props.detail.doctorReceive,
            bed: this.props.detail.bedName,
            saveCode: this.props.detail.saveCode,
        })
        this.form.setFieldsValue({
            doctorReceive: this.props.detail.doctorReceive,
            diagnostic: this.props.detail.diagnostic,
        })
        NhapVienService.ThongTinBenhNhap(this.props.detail.code, this.props.detail.managerId, this.props.detail.enterCode).then(result => {
            if (result.isSuccess) {
                let lstCD = result.item.lstOtherDiseases.map(x => ({ 'label': x.children, 'value': x.value }))
                this.setState({
                    lstOtherDiseases: lstCD, otherDiseases: result.item.lstOtherDiseases,
                    bedName: result.item.bedName, roomName: result.item.roomName
                });
            }
        })
        this.DSKetQuaTinhTrang();
        this.loadDoctor();
        this.loadLoaiBenhVien();
    }
    loadBenhVienChuyenDen(value) {
        CommonService.BenhVienChuyenDen(value).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({ lstSwitchHospital: result.data });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ bệnh viện chuyển đến bị lỗi' })
        })
    }
    loadDiagnostic(value, title) {
        CommonService.GetListDiagnostic(value).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                if (title === 'bệnh chính') {
                    this.setState({ lstDiagnostic: result.data });
                }
                else if (title === 'nguyên nhân') {
                    this.setState({ lstReason: result.data });
                }
                else if (title === 'kèm theo') {
                    this.setState({ lstOtherDiseases: result.data });
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ bị lỗi' })
        })
    }
    loadDoctor() {
        CommonService.GetListDoctor(1, 200).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDoctorReceive: result.data,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    DSKetQuaTinhTrang() {
        CommonService.DanhSachKetQua().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstResult: result.data,
                    result: result.data[0].value,
                });
                this.form.setFieldsValue({
                    result: result.data[0].value,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kết quả bị lỗi' })
        })

        CommonService.DanhSachTinhTrang().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstStatus: result.data,
                    status: result.data[0].value,
                });
                this.form.setFieldsValue({
                    status: result.data[0].value,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu tình trạng bị lỗi' })
        })
    }
    loadLoaiBenhVien() {
        CommonService.LoaiBenhVien().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstHospitalType: result.data
                });
            }
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ display: "flex", marginTop: "30px", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Mã BN:">
                                <Input value={this.state.detail.code} disabled={true} />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Ngày giờ vào viện:" >
                                <Input value={moment(this.state.detail.dateInHospital).format('DD/MM/YYYY HH:mm')} disabled={true} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Tên BN:">
                                <Input value={this.state.detail.name} disabled={true} />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Ngày giờ vào khoa:" >
                                <Input value={moment(this.state.detail.dateInDepartment).format('DD/MM/YYYY HH:mm')} disabled={true} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Phòng:">
                                <Input disabled={true} value={this.state.roomName} />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Giường:">
                                <Input disabled={true} value={this.state.bedName} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Số lưu trữ :">
                                <Input value={this.state.saveCode} onChange={(e) => this.setState({ saveCode: e.target.value })} />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Tổng số ngày:">
                                <InputNumber disabled={true} style={{ width: "100%" }} value={((moment().diff(this.state.detail.dateInHospital, 'hours')) / 24).toFixed(2)} />
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Kết quả:" name="result" rules={[{ required: true, message: 'Vui lòng chọn kết quả!' }]}>
                                <Select value={this.state.result} onChange={(value, e) => { this.setState({ result: value, status: value !== 5 ? this.state.status : 7 }) }}>
                                    {this.state.lstResult.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Bác sĩ điều trị:" name="doctorReceive" rules={[{ required: true, message: 'Vui lòng chọn bác sĩ!' }]}>
                                <Select
                                    value={this.state.doctorReceive}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { this.setState({ doctorReceive: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstDoctorReceive.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Bệnh chính:" name="diagnostic" rules={[{ required: true, message: 'Vui lòng chọn bệnh chính!' }]}>
                                <Autocomplete
                                    width="100%"
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstDiagnostic}
                                    handleSearch={this.loadDiagnostic.bind(this)}
                                    getItem={(key, value) => {
                                        this.setState({ diagnostic: key, diagnosticName: value })
                                        this.form.setFieldsValue({
                                            diagnostic: key
                                        })
                                    }}
                                    value={this.state.diagnostic}
                                    title='bệnh chính'
                                />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1, height: "60px" }}>
                            <Form.Item style={{ flex: 1 }} label="Bệnh kèm theo:">
                                <AutocompleteMulti
                                    width="100%"
                                    maxWidth="376px"
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstOtherDiseases}
                                    handleSearch={this.loadDiagnostic.bind(this)}
                                    getItem={(key, value) => { this.setState({ otherDiseases: key, otherDiseasesName: value }) }}
                                    value={this.state.otherDiseases}
                                    title='kèm theo'
                                />
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1, display: "none" }}>
                            <Form.Item label="Tình trạng:" name="status" rules={[{ required: true, message: 'Vui lòng chọn tình trạng!' }]}>
                                <Select value={this.state.status} onChange={(value, e) => { this.setState({ status: value, result: value === 7 ? 5 : this.state.result === 5 ? 1 : this.state.result }) }}>
                                    {this.state.lstStatus.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }} className={`display-${this.state.status !== 6 ? 'none' : 'flex'}`}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Chuyển viện:">
                                <Select disabled={this.state.status !== 6} value={this.state.hospitalType} onChange={(value, e) => { this.setState({ hospitalType: value }) }}>
                                    {this.state.lstHospitalType.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.state.status === 6 ? this.validator.message('chuyển viện', this.state.hospitalType, 'required') : this.validator.fields['chuyển viện'] = true}
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Chuyển đến:">
                                <Autocomplete
                                    width="100%"
                                    disabled={this.state.status !== 6}
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstSwitchHospital}
                                    handleSearch={this.loadBenhVienChuyenDen.bind(this)}
                                    getItem={(key, value) => { this.setState({ switchHospital: key }) }}
                                    value={this.state.switchHospital}
                                />
                                {this.state.status === 6 ? this.validator.message('chuyển đến', this.state.switchHospital, 'required') : this.validator.fields['chuyển đến'] = true}
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px" }} className={`display-${this.state.status !== 5 ? 'none' : 'flex'}`}>
                        <div style={{ flex: 0.5 }}>
                            <Form.Item label="Khoa đến:">
                                <Select
                                    style={{ width: "100%" }}
                                    disabled={this.state.status !== 5}
                                    onChange={(value) => { this.setState({ switchDepartment: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstdepartment.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.state.status === 5 ? this.validator.message('khoa đến', this.state.switchDepartment, 'required') : this.validator.fields['khoa đến'] = true}
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
