import { Button, DatePicker, Form, Input, notification, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from '../../../components/autocomplete';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as PhauThuThuatService from '../../../services/phauthuthuatService';
import * as AppointmentService from '../../../services/appointmentService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { Option } = Select;
const initialState = {
    visible: false,
    visibleCreate: false,
    mabn: '',
    cicd10_ID: '',
    cicd10: '',
    tuluc: moment(),
    denluc: moment(),
    ngay: moment(),
    mamau: '',
    ppvocam: null,
    pttv: '',
    phu: null,
    bsgayme: null,
    giuong: '',
    icd_t: '',
    makp: ''
}
export default class PhongKham extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDiagnostic: [],
            lstPhuongPhap: [],
            lstMeTe: [],
            lstDoctor: [],
            lstBN: [],
            lstGiuong: [],
            lstKhoa: [],
            managerId: typeof (this.props.managerId) !== undefined ? this.props.managerId : '',
            hoten: typeof (this.props.hoten) !== undefined ? this.props.hoten : '',
            mabn: typeof (this.props.mabn) !== undefined ? this.props.mabn : '',
        };
    }
    componentDidMount() {
        this.loadPhuongPhapPT();
        this.loadMeTe();
        this.loadDoctor();
        this.loadKhoa();
    }

    loadDoctor() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    loadMeTe() {
        PhauThuThuatService.DanhSachMeTe().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstMeTe: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu vô cảm bị lỗi' });
        })
    }

    loadPhuongPhapPT() {
        PhauThuThuatService.DanhSachPPhauThuat().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhuongPhap: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phương pháp phẫu thuật bị lỗi' });
        })
    }
    TimKiemBenhNhan(key) {
        AppointmentService.TimKiemBN(key).then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
            }
        }).catch(err => {
        })
    }
    loadKhoa() {
        PhauThuThuatService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKhoa: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu khoa bị lỗi' });
        })
    }
    GetListDiagnostic(key, title) {
        CommonService.GetListDiagnostic(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDiagnostic: result.data,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải danh sách bệnh bị lỗi!' })
        })
    }
    reset() {
        this.setState({
            ...initialState,
            lstDiagnostic: [],
            lstPhuongPhap: [],
            lstMeTe: [],
            lstDoctor: [],
            lstBN: [],
            lstGiuong: [],
            lstKhoa: []
        })
        this.form.setFieldsValue({
            mabn: null,
            mabs: null,
            mamau: null,
            icd_t: null,
            ppvocam: null,
            giuong: null,
            pttv: null,
            phu: null,
            bsgayme: null
        })
        this.loadPhuongPhapPT();
        this.loadMeTe();
        this.loadDoctor();
        this.loadKhoa();
    }
    disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    async submit() {
        Actions.setLoading(true);
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                'mabn': this.state.mabn,
                'maql': this.state.managerId,
                'makp': this.state.makp,
                'icd_t': this.state.icd_t,
                'mamau': this.state.mamau,
                'ppvocam': this.state.ppvocam,
                'sogiuong': this.state.giuong,
                'mabs': this.state.pttv,
                'bsgayme': this.state.bsgayme,
                'phu': this.state.phu,
                'ngay': moment(this.state.ngay),
            }
            PhauThuThuatService.LuuLichPT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công!" });
                    this.reset();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        } else {
            Actions.setLoading(false);
            this.validator.showMessages();
        }
    }


    render() {

        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <Form.Item label="Mã BN:" style={{ flex: 1 }}>
                                <Input
                                    disabled
                                    value={this.state.mabn}
                                />
                            </Form.Item>
                            <Form.Item label="Họ tên:" style={{ flex: 1 }}>
                                <Input
                                    disabled
                                    value={this.state.hoten}
                                />
                            </Form.Item>
                            <Form.Item label="Mã quản lý:" style={{ flex: 1 }}>
                                <Input
                                    disabled
                                    value={this.state.managerId}
                                />
                            </Form.Item>
                            <Form.Item label="Ngày:" name="ngay"
                                rules={[{ required: true, message: 'Yêu cầu chọn ngày phẫu thủ thuật!' }]}>
                                <DatePicker locale={viVN} format={"DD-MM-YYYY HH:mm"}
                                    disabledDate={this.disabledDate.bind(this)}
                                    value={this.state.ngay} onChange={(value => this.setState({ ngay: value }))}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <Form.Item label="Khoa:" style={{ flex: 1 }} name="makp" rules={[{ required: true, message: 'Yêu cầu chọn khoa!' }]}>
                                <Select value={this.state.makp}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ makp: e }) }}>
                                    {this.state.lstKhoa.map((row, index) => {
                                        return (
                                            <Option key={index} style={{ width: 300 }}
                                                value={row.ma}>{row.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="CĐ trước:" style={{ flex: 1 }} name="icd_t"
                                rules={[{ required: true, message: 'Yêu cầu chọn chẩn đoán!' }]}>
                                <Autocomplete
                                    width={300}
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstDiagnostic}
                                    handleSearch={this.GetListDiagnostic.bind(this)}
                                    getItem={(key, value) => { this.setState({ icd_t: key, cicd10: value }); this.form.setFieldsValue({ icd_t: key }) }}
                                    value={this.state.icd_t} />
                            </Form.Item>
                            <Form.Item label="PP phẫu thuật:" style={{ flex: 1 }} name="mamau"
                                rules={[{ required: true, message: 'Yêu cầu chọn phương pháp phẫu thuật!' }]}>
                                <Select value={this.state.mamau}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ mamau: e }) }}>
                                    {this.state.lstPhuongPhap.map((row, index) => {
                                        return (
                                            <Option key={index} style={{ width: 300 }}
                                                value={row.ma}>{row.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="PP vô cảm:" style={{ flex: 1 }} name="ppvocam"
                                rules={[{ required: true, message: 'Yêu cầu chọn phương pháp vô cảm!' }]}>
                                <Select value={this.state.ppvocam}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ ppvocam: e }) }}>
                                    {this.state.lstMeTe.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.ma}>{row.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>

                            <Form.Item label="PTTT viên:" style={{ flex: 1 }} name="pttv"
                                rules={[{ required: true, message: 'Yêu cầu chọn phẫu thủ thuật viên!' }]}>
                                <Select value={this.state.pttv}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ pttv: e }) }}>
                                    {this.state.lstDoctor.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phụ:" style={{ flex: 1 }} >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ phu: e }) }}>
                                    {this.state.lstDoctor.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Bác sĩ gây mê:" style={{ flex: 1 }} >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ bsgayme: e }) }}>
                                    {this.state.lstDoctor.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item  style={{ flex: 1 }} >
                            <Button type="primary" onClick={() => this.submit()}> <i style={{marginRight:10}} className="fa fa-save"/>Lưu</Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}