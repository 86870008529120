import { DatePicker, Input, InputNumber, Popover } from 'antd';
import React, { useEffect, useState, forwardRef, useRef } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify'
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as InvoiceServices from '../../../services/invoiceService';
import SeparatePayment from '../../invoice/payment/components/SeparatePayment';

const { TextArea } = Input;

export default forwardRef((props, ref) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);

    const [datecreate, setDatecreate] = useState(moment())
    const [billSelected, setBillSelected] = useState('');
    const [billNoSelected, setBillNoSelected] = useState('');
    const [thamChieu, setThamChieu] = useState('');
    const [ghichu, setGhichu] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [dateRange,] = useState(props.dateRange);
    const [totalBill, setTotalBill] = useState(0);
    const [listPaymentMethod, setListPaymentMethod] = useState([]);

    const [visibleSeparatePayment, setVisibleSeparatePayment] = useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if(props.billSelected && props.billSelected !== null && props.billSelected !== ''){
            setBillSelected(props.billSelected.toString());
            setTotalBill(props.totalBill);
            setListPaymentMethod(props.listPayment);
        } else {
            setBillSelected('')
        }
        if(props.billNoSelected && props.billNoSelected !== null && props.billNoSelected !== '') {
            setBillNoSelected(props.billNoSelected.toString())
        } else {
            setBillNoSelected('')
        }
    },[props])

    const resetState = () => {
        setBillSelected('')
        setThamChieu('')
        setGhichu('')
        setDiscount(0);
        notiRef.current.Close()
    }

    const Submit = () => {
        if (validator.current.allValid()) {
            setDisableBtn(true);
            Actions.setLoading(true);
            const data = {
                billNumberList: billSelected,
                discountDate: datecreate.format(SystemConfig.FORMAT_MONTH_DATE),
                infomation: ghichu,
                discount,
                dateFrom: dateRange[0],
                dateTo: dateRange[1],
                listPaymentMethod : listPaymentMethod
            }
            InvoiceServices.DieuChinhMienGiam(data).then(res => {
                if (res.isSuccess) {
                    if (notiRef && notiRef.current) 
                    notiRef.current.Show("Điều chỉnh miễn giảm thành công.", NotifyStatus.Success)
                    setTimeout(() => {
                        if (props.onSubmit)
                            props.onSubmit();
                        setDisableBtn(false)
                        resetState();
                    }, 1000);
                    
                    Actions.setLoading(false);
                } else {
                    notiRef.current.Show(res.err.msgString, NotifyStatus.Error)
                    setDisableBtn(false);
                    Actions.setLoading(false);
                }
            }).catch((err) => {
                setDisableBtn(false);
                Actions.setLoading(false);
            })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
        
    }

    const onSubmitSeperatePayment = (data) => {
        if(data && data !== null && data.length > 0){
            let listSelected = data.map(x => {
                return ({
                    ma : x.ma,
                    ten : x.ten,
                    tongtien : x.amount
                })
            })
            setListPaymentMethod(listSelected)
        }
        setVisibleSeparatePayment(false);
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='h3'>Điều chỉnh miễn giảm</div>
                <h3 onClick={() =>{
                    props.onCancel()
                    resetState()
                }}>
                <i className='far fa-times-circle' /></h3>
            </div>
            <Notify ref={notiRef} />
            <div className='py-1'>
                <label>Mã phiếu chọn</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} disabled={true}
                    value={billNoSelected}
                />
            </div>
            <div className='py-1'>
                <label>Ngày giờ điều chỉnh</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <DatePicker locale={viVN} format="DD-MM-YYYY HH:mm" style={{ width: '100%' }}
                    disabledDate={disabledDate}
                    showTime
                    onChange={(e) => setDatecreate(e)}
                    allowClear={false}
                    value={datecreate} />
            </div>
            <div className='py-1'>
                <label>Miễn giảm</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <InputNumber style={{ width: '100%' }} addonAfter="VND" value={discount}
                    onChange={e => setDiscount(e)} step={5000} min={0} max={99999999}
                    formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
            </div>
            {listPaymentMethod && listPaymentMethod.length > 1 &&
                <div className='d-flex flex-row justify-content-between py-1'>
                    <label>Thành tiền sau miễn giảm</label>
                    <Popover
                        content={
                            <div style={{ width: 400, padding: 10 }}>
                                <SeparatePayment
                                    isPayment = {true}
                                    isChangePayment = {false}
                                    data = {{
                                        billNumber : billSelected,
                                        totalBill : Number(totalBill - discount)
                                    }}
                                    onSubmit = {(data) => onSubmitSeperatePayment(data) }
                                    onClose={() => setVisibleSeparatePayment(false)}
                                    showCancel={false} />
                            </div>}
                        trigger="click"
                        placement="left"
                        visible={visibleSeparatePayment}
                        onVisibleChange={(e) => setVisibleSeparatePayment(e)}
                    >
                        <div className="iconButton anchor-color" onClick={() => setVisibleSeparatePayment(true)}><i className="fas fa-sitemap"></i></div>
                    </Popover>
                    <label>₫ {Number(totalBill - discount).toLocaleString('en-US')}</label>
                </div>
            }
            
            <div className='py-1'>
                <label>Ghi chú</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} value={ghichu} onChange={e => setGhichu(e.target.value)} />
                {validator.current.message("Ghi chú", ghichu, "required")}
            </div>
            <div className='py-1 text-right'>
                <button className='btn btn-color'
                    onClick={() => {
                        Submit()
                    }}
                    disabled={disableBtn}
                ><i className="fas fa-save" style={{ marginRight: 5 }} />Xác nhận</button>
            </div>
        </>
    )
});
