import { Pagination, Button, Popconfirm, InputNumber, DatePicker, Form, notification, Table, Modal, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as TuTrucService from '../../../services/tutrucservice';
import * as MedicineService from '../../../services/medicineService';
import Autocomplete from '../../../components/autocomplete';
import { EyeOutlined } from '@ant-design/icons';
import TaoPhieuXuatTuTruc from '../components/taophieuxuattutruc';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { Option } = Select;
const initialState = {
    makp: '',
    makpUpdate: 0,
    madoituong: 0,
    makho: 0,
    idPhieu: null,
    mabd: 0,
    mabn: '',
    ngayUpdate: moment(),
    soluong: 0,
    ngay: moment(),
    totalRecords: 0,
    visibleUpdate: false,
    visibleCreate: false
}
export default class QuanLyPhieuXuatTuTruc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstPhieuTuTruc: [],
            lstDuocKP: [],
            lstDetail: [],
            lstMaterial: [],
            visibleModalEdit: false,
            hiddenUpdate: false,
            hiddenLuu: true,
            disabledUpdate: true,
            currentPage: 1,
        };
    }

    componentWillMount() {
        this.loadDuocKP();
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    loadMaterial(key) {

        MedicineService.GetListMaterial(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstMaterial: result.data,
                });

            }
        }).catch(err => {
        })
    }

    loadPhieuXuatTuTruc(page = 1, size = 10) {
        if (this.state.ngay === null || this.state.ngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        if (this.state.makp.length === 0 || this.state.makp === null) {
            notification.warning({ message: 'Vui lòng chọn khoa!' });
            return;
        }
        TuTrucService.DanhSachPhieuXuatTuTruc(this.state.ngay.format('YYYY-MM-DD'), this.state.makp, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhieuTuTruc: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phiếu xuất tủ trực bị lỗi!' })
        })
    }
    loadDuocKP() {
        TuTrucService.DanhSachDuocKP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDuocKP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dược khám phòng bị lỗi!' })
        })
    }
    loadDetailPhieu(ngay, id) {
        TuTrucService.ThongTinPhieuXuatTuTruc(moment(ngay).format('YYYY-MM-DD'), id).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.data,
                    visibleModalEdit: true
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thông tin phiếu xuất bị lỗi!' })
        })
    }
    deleteThuoc(mathuoc) {
        const data = {
            'id': this.state.idPhieu,
            'mabd': Number(mathuoc),
            'ngay': moment(this.state.ngayUpdate).format('YYYY-MM-DD'),
        }
        TuTrucService.XoaThuocTrongPhieu(data).then(result => {
            if (result.isSuccess) {
                this.setState({
                    visibleModalEdit: false,
                })
                this.loadPhieuXuatTuTruc();
                notification.success({ message: 'Đã xóa!' });
            }
        }).catch(err => {
        })
    }

    handleCancel = e => {
        this.setState({
            visibleUpdate: false,
            visibleCreate: false,
            visibleModalEdit: false,
            hiddenUpdate: false,
            hiddenLuu: true,
            disabledUpdate: true
        });
    };
    async loadCapNhat() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {

            const data = {
                'id': this.state.idPhieu,
                'mabd': Number(this.state.id),
                'ngay': moment(this.state.ngayUpdate).format('YYYY-MM-DD'),
                'mabn': this.state.mabn,
                'makp': Number(this.state.makpUpdate),
                'makho': Number(this.state.makho),
                'madoituong': Number(this.state.madoituong),
                'ghichu': this.state.ghichu,
                'soluong': Number(this.state.soluong),
            }
            TuTrucService.CapNhatPhieuXuatTuTruc(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        visibleModalEdit: false,
                        id: null,
                        soluong: 0
                    })
                    this.form.setFieldsValue({
                        id: null, soluong: 0
                    })
                    notification.success({ message: 'Cập nhật thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }

    loadSetState(row) {
        this.setState({
            idPhieu: row.id,
            mabn: row.mabn,
            ngayUpdate: row.ngay,
            makpUpdate: row.makp,
            makho: row.makho,
            madoituong: row.madoituong
        })
    }

    render() {
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngayud',
                key: 'ngayud',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Mã vào viện',
                dataIndex: 'mavaovien',
                key: 'mavaovien',
            },
            {
                title: 'Mã quản lý',
                dataIndex: 'maql',
                key: 'maql',
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
            },
            {
                title: 'Tên khoa',
                dataIndex: 'tenkp',
                key: 'tenkp',
            },
            {
                title: 'Ghi chú',
                dataIndex: 'ghichu',
                key: 'ghichu',
            },

            {
                title: '',
                render: (row) => {
                    return (
                        <Button
                            type="primary"
                            className="btn btn-outline"
                            title="Cập nhật phiếu xuất tủ trực!"
                            onClick={() => {
                                this.loadSetState(row);
                                this.loadDetailPhieu(row.ngay, row.id)
                            }}>
                            <EyeOutlined />
                            Xem chi tiết
                        </Button>
                    )
                }
            }
        ];
        let dataSource = this.state.lstPhieuTuTruc.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'ID thuốc',
                dataIndex: 'mabd',
                key: 'mabd',
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'tenbd',
                key: 'tenbd',
            },
            {
                title: 'Tên hợp chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
            },
            {
                title: 'Dạng',
                dataIndex: 'dang',
                key: 'dang',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <div>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa thuốc này không?"
                                onConfirm={() => { this.deleteThuoc(row.mabd) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button type="primary" danger >
                                    <i className="fa fa-trash"></i>
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        let dataSource1 = this.state.lstDetail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <Form.Item label="Khoa:">
                                <Select value={this.state.makp} style={{ width: 300 }}
                                    showSearch
                                    width={300}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ makp: e }) }}>
                                    {this.state.lstDuocKP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Ngày:">
                                <DatePicker locale={viVN} format="DD-MM-YYYY" allowClear={false}
                                    disabledDate={this.disabledDate}
                                    defaultValue={moment()} value={this.state.ngay}
                                    onChange={(value => this.setState({ ngay: value }))}
                                />
                            </Form.Item>
                            <Form.Item>
                            <button className="btn btn-success"
                                onClick={() => this.loadPhieuXuatTuTruc()}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                          </Form.Item>
                          <Form.Item>
                            <button className="btn btn-success"
                                onClick={() => this.setState({ visibleCreate: true })}><i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                       </Form.Item>
                        </div>
                    </div>
                    <div className="form-group" style={{ flex: 1 }} >
                        <Modal maskClosable={false}
                            width={1500}
                            visible={this.state.visibleCreate}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                           
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}>
                            <TaoPhieuXuatTuTruc />
                        </Modal>

                        <Modal maskClosable={false}
                            width={1000}
                            visible={this.state.visibleModalEdit}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                           
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <Form.Item label="Thuốc:" style={{ flex: 1 }}
                                    name="id" rules={[{ required: true, message: 'Yêu cầu chọn thuốc!' }]}>
                                    <Autocomplete
                                        ref={((c) => this.autocomplete = c)}
                                        dataSource={this.state.lstMaterial}
                                        handleSearch={this.loadMaterial.bind(this)}
                                        getItem={(key) => { this.form.setFieldsValue({ id: key }); this.setState({ id: key }); }} />
                                </Form.Item>
                                <Form.Item label="Số lượng:" style={{ flex: 1 }} name="soluong"
                                    rules={[{ required: true, message: 'Yêu cầu nhập số lượng!' }]}>
                                    <InputNumber value={this.state.soluong}
                                        min={0} maxLength={10}
                                        onChange={(e) => this.setState({ soluong: e })} />
                                </Form.Item>
                                <Form.Item style={{ flex: 1 }}>
                                    <Button type="primary" onClick={() => this.loadCapNhat()}>CẬP NHẬT</Button>
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20 }}>

                                <Table dataSource={dataSource1}
                                 scroll={{ y: 0 }}
                                    pagination={false}
                                    columns={columns1} rowKey={(e, index) => index} />
                            </div>
                        </Modal>


                        <Table dataSource={dataSource}
                         scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.loadPhieuXuatTuTruc(page, size) }}
                            onShowSizeChange={(page, size) => this.loadPhieuXuatTuTruc(1, size)}
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
