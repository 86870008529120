import { DatePicker, notification, Popover, TimePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../../commons/validationCustom';
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import * as PrintService from '../../../../services/printService';
import PatientInfo from './patientinfo';
import * as SystemConfig from "../../../../configure/systemConfig";
const { RangePicker } = DatePicker;

const initialState = {
    ngayChamSoc: moment(),
    nhietdo: '',
    mach: '',
    huyetap: '',
    nhiptho: '',
    cannang: '',
    dienbien: '',
    chamsoc: '',
}

class sochamsoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            lstChamSoc: [],
            listDate: [],
            dataChar: [],
            ngayIn: [moment(), moment()],
            heightListBtn: 47,
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
        // this.validator = new SimpleReactValidator({
        //     messages: {
        //         required: "Vui lòng nhập :attribute",
        //         min: ":attribute nhỏ nhất bằng :min",
        //         max: ":attribute lớn nhất bằng :max",
        //     },
        //     validators: {
        //         mach: {
        //             numeric: "Cần nhập :attribute là số",
        //             required: false,
        //             min: "Nhập :attribute nhỏ nhất :min",
        //             max: "Nhập :attribute lớn nhất :max",
        //         },
        //     },
        //     element: message => <div style={{ color: '#FF4D4F', fontSize: '14px' }}>{message}</div>
        // });
    }

    componentDidMount() {
        this.GetListSoChamSocByEnterCode();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail }, () => {
                this.GetListSoChamSocByEnterCode();
            })
        }
        return true;
    }

    PrintSoChamSoc() {
        if (this.state.detail.enterCode === undefined) {
            notification.warning({ message: 'Chưa chọn bệnh trình!' });
            return;
        }
        this.setState({ visibleDate: false })
        PrintService.PrintSoChamSoc(this.state.detail.enterCode, this.state.ngayIn[0].format('YYYY/MM/DD'), this.state.ngayIn[1].format('YYYY/MM/DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    PrintPhieuTheoDoiChucNangSong() {
        if (this.state.detail.enterCode === undefined) {
            notification.warning({ message: 'Chưa chọn bệnh trình!' });
            return;
        }
        this.setState({ visibleDate: false })
        PrintService.PrintPhieuTheoDoiChucNangSong(this.state.detail.enterCode, this.state.ngayIn[0].format('YYYY/MM/DD'), this.state.ngayIn[1].format('YYYY/MM/DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    GetListSoChamSocByEnterCode() {
        InHospitalService.GetListSoChamSocByEnterCode(this.state.detail.enterCode).then(result => {
            if (result.isSuccess) {
                let listType = result.data.map(x => x.ngayText);
                let dataChar = [];
                result.data.map(x => {
                    dataChar.push({
                        ngay: moment(x.ngayGio).format(SystemConfig.FORMAT_DATE_TIME),
                        nhietdo: x.nhietdo,
                        mach: x.mach,
                        huyetap: x.huyetap,
                        cannang: x.cannang,
                        nhiptho: x.nhiptho,
                    })
                });
                dataChar.reverse();
                let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                ListTypeDistinct = ListTypeDistinct.map(x => (
                    {
                        ngay: x,
                        count: result.data.filter(child => child.ngayText === x).length
                    }));
                this.setState({
                    initialState,
                    lstChamSoc: result.data,
                    listDate: ListTypeDistinct,
                    dataChar,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu chăm sóc bệnh nhân nội trú bị lỗi")
        })
    }

    UpdateSoChamSoc() {
        if (this.validator.allValid()) {
            const data = {
                //id: this.state.benhtrinh.id ? this.state.benhtrinh.id : '',
                mavaovien: this.state.detail.enterCode,
                maql: this.state.detail.managerId,
                mabn: this.state.detail.code,
                mabs: this.state.detail.doctorReceive,
                dienbien: this.state.dienbien,
                chamsoc: this.state.chamsoc,
                ngayGio: this.state.ngayChamSoc,
                //mabs: create === true ? this.state.mabs : this.state.benhtrinh.mabs,
                nhiptho: this.state.nhiptho,
                huyetap: this.state.huyetap,
                nhietdo: this.state.nhietdo,
                mach: this.state.mach,
                cannang: this.state.cannang,
            }
            if (data.ngayGio === undefined) {
                notification.warning({ message: 'Chưa chọn bệnh nhân!' });
                return;
            }
            InHospitalService.UpdateSoChamSoc(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công' });
                    this.GetListSoChamSocByEnterCode();
                    this.setState({
                        benhtrinh: { id: result.id, initialState }
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err)
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        let lstTMP = [];
        let dateReverse = this.state.listDate;
        dateReverse = [...dateReverse];
        const data = {
            labels: this.state.dataChar.map(x => x.ngay),
            datasets: [
                {
                    label: 'Mạch l/ph',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderCapStyle: 'butt',
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'red',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'red',
                    pointHoverBorderColor: 'red',
                    pointHoverBorderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: this.state.dataChar.map(x => x.mach),
                    yAxisID: 'A',
                },
                {
                    label: 'Nhiệt độ ℃',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'blue',
                    borderColor: 'blue',
                    borderCapStyle: 'butt',
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'blue',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'blue',
                    pointHoverBorderColor: 'blue',
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: this.state.dataChar.map(x => x.nhietdo),
                    yAxisID: 'B',
                }
            ]
        };
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="row no-gutters p-md-0 mb-2">
                        <div className="col-12 my-2 d-flex">
                            <DatePicker getPopupContainer={trigger => trigger.parentNode} locale={viVN} className="mr-2" allowClear={false} placeholder="dd/MM/yyyy" value={this.state.ngayChamSoc} onChange={(e) => { this.setState({ ngayChamSoc: e }) }} format={'DD/MM/YYYY'} />
                            <TimePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                locale={viVN}
                                value={this.state.ngayChamSoc}
                                format={'HH:mm'}
                                allowClear={false}
                                onChange={(date) => { this.setState({ ngayChamSoc: date }) }}
                            />
                            <Popover id=""
                                getPopupContainer={trigger => trigger.parentNode}
                                content={
                                    <div style={{}}>
                                        <RangePicker getPopupContainer={trigger => trigger.parentNode} format="DD-MM-YYYY" value={this.state.ngayIn} allowClear={false}
                                            locale={viVN} onChange={(value => this.setState({ ngayIn: value }))}
                                        />
                                        <div style={{ display: 'flex', marginTop: 10 }}>
                                            <button className="btn btn-secondary mr-2" disabled={this.state.lstChamSoc.length === 0 ? true : false}
                                                onClick={() => { this.PrintSoChamSoc() }}><i className="fa fa-print" style={{ marginRight: '5px' }}></i>Phiếu chăm sóc</button>
                                            <button className="btn btn-secondary" disabled={this.state.dataChar.length === 0 ? true : false}
                                                onClick={() => { this.PrintPhieuTheoDoiChucNangSong() }}><i className="fa fa-print" style={{ marginRight: '5px' }}></i>Phiếu theo dõi chức năng</button>
                                        </div>

                                    </div>
                                }
                                title="Chọn ngày"
                                trigger="click"
                                visible={this.state.visibleDate}
                                onVisibleChange={(e) => { this.setState({ visibleDate: e, openDate: e }) }}
                                placement="bottomRight"

                            >
                                <button className="btn btn-outline-secondary ml-auto" style={{ height: '33px' }}
                                    onClick={() => { this.setState({ visibleDate: true }) }}
                                ><i className="fa fa-print"></i> In phiếu</button>
                            </Popover>
                        </div>
                        <div className="col-md-6 mb-2 pr-md-2">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="input-group mr-1 my-1">
                                        <input value={this.state.nhietdo} type="number"
                                            onChange={(e) => {
                                                if (e.target.value.length > 4) return;
                                                this.setState({ nhietdo: e.target.value })
                                                this.validator.showMessages();
                                            }} className="form-control" placeholder="Nhiệt độ" />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><sup>o</sup>C</span>
                                        </div>
                                    </div>
                                    {this.validator.message("Nhiệt độ", this.state.nhietdo, "required|numeric|min:30,num|max:42,num")}
                                </div>
                                <div>
                                    <div className="input-group mr-1 my-1">
                                        <input value={this.state.mach} type="number"
                                            onChange={(e) => {
                                                if (e.target.value.length > 4) return;
                                                this.setState({ mach: e.target.value })
                                                this.validator.showMessages();
                                            }}
                                            className="form-control" placeholder="Mạch" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">l/ph</span>
                                        </div>
                                    </div>
                                    {this.validator.message("Mạch", this.state.mach, "required|numeric|min:40,num|max:250,num")}
                                </div>
                                <div>
                                    <div className="input-group my-1">
                                        <input value={this.state.huyetap} type="number" min={0} max={250}
                                            onChange={(e) => {
                                                if (e.target.value.length > 4) return;
                                                this.setState({ huyetap: e.target.value })
                                                this.validator.showMessages();
                                            }} className="form-control" placeholder="H/A" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">mmHg</span>
                                        </div>
                                    </div>
                                    {this.validator.message("Huyết áp", this.state.huyetap, "required|numeric|min:50,num|max:200,num")}
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="input-group mr-1 my-1">
                                    <input value={this.state.nhiptho} type="number" min={0}
                                        onChange={(e) => {
                                            if (e.target.value.length > 4 || Number(e.target.value) < 0) return;
                                            this.setState({ nhiptho: e.target.value })
                                            // this.validator.showMessages();
                                        }} className="form-control" placeholder="Nhịp thở" />
                                    <div className="input-group-append">
                                        <span className="input-group-text">l/ph</span>
                                    </div>
                                    {/* {this.validator.message("Nhịp thở", this.state.nhiptho, "numeric|min:0,num")} */}
                                </div>
                                <div className="input-group my-1">
                                    <input value={this.state.cannang} type="number" min={0}
                                        onChange={(e) => {
                                            if (e.target.value.length > 4 || Number(e.target.value) < 0) return;
                                            this.setState({ cannang: e.target.value })
                                            // this.validator.showMessages();
                                        }} className="form-control" placeholder="Cân nặng" />
                                    <div className="input-group-append">
                                        <span className="input-group-text">kg</span>
                                    </div>
                                    {/* {this.validator.message("Cân nặng", this.state.cannang, "numeric|min:0,num")} */}
                                </div>
                                <div className="input-group my-1" style={{ display: 'none' }}> </div>
                            </div>
                            <div className="mb-2">
                                <div className="h5 font-weight-bold">Diễn biến</div>
                                <textarea value={this.state.dienbien} className="form-control" rows="8"
                                    onChange={(e) => this.setState({ dienbien: e.target.value })}
                                ></textarea>
                            </div>
                            <div className="mb-2">
                                <div className="h5 font-weight-bold">Thực hiện y lệnh/chăm sóc</div>
                                <textarea value={this.state.chamsoc} className="form-control" rows="7"
                                    onChange={(e) => this.setState({ chamsoc: e.target.value })}
                                ></textarea>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-color"
                                    onClick={() => { this.UpdateSoChamSoc() }}><i className="fa fa-save"></i> Lưu</button>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2" style={{ backgroundColor: '#fff' }}>
                            <div style={{ backgroundColor: "#fff", width: '100%' }}>
                                <div style={{ display: 'flex', paddingRight: '10px' }}>
                                    <Line width={'90%'} data={data} height={300} options={{

                                        maintainAspectRatio: false,
                                        responsive: true,
                                        scales: {
                                            xAxes: [{
                                                ticks: {
                                                    display: false
                                                },
                                                offset: 1,
                                            }],
                                            yAxes: [{
                                                id: 'A',
                                                type: 'linear',
                                                position: 'left',
                                                offset: 1,
                                                display: true,
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Mạch l/ph',

                                                },

                                                ticks: {
                                                    beginAtZero: false,
                                                    min: 40,
                                                    max: 160
                                                }
                                            }, {
                                                id: 'B',
                                                type: 'linear',
                                                position: 'right',
                                                display: true,
                                                scaleLabel: {
                                                    display: true,
                                                    labelString: 'Nhiệt độ',
                                                },

                                                offset: 1,
                                                ticks: {
                                                    beginAtZero: false,
                                                    min: 35,
                                                    max: 41,
                                                }
                                            }],
                                        },
                                    }} />
                                </div>
                                <div style={{ padding: '10px' }}>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse table-bordered  padding2pxIm">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center" style={{ minWidth: '90px' }}>Ngày/tháng</td>
                                                    {dateReverse.reverse().map((row, index) => (
                                                        <td className="text-center" key={index} colSpan={row.count}>
                                                            {row.ngay}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="text-center">Giờ</td>
                                                    {this.state.dataChar.map(x => x.ngay).map((row, index) => (
                                                        <td className="text-center" key={index}>
                                                            {moment(row, 'DD/MM/YYYY HH:mm').format("HH:mm")}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="text-center">Huyết áp (mmHg)</td>
                                                    {this.state.dataChar.map(x => x.huyetap).map((row, index) => (
                                                        <td className="text-center" key={index}>
                                                            {row}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="text-center">Cân nặng (Kg)</td>
                                                    {this.state.dataChar.map(x => x.cannang).map((row, index) => (
                                                        <td className="text-center" key={index}>
                                                            {row}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="text-center">Nhịp thở (lần/phút)</td>
                                                    {this.state.dataChar.map(x => x.nhiptho).map((row, index) => (
                                                        <td className="text-center" key={index}>
                                                            {row}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-12 mb-2"></div>
                    </div>
                    <div style={{ padding: '10px 0px', height: '52px' }}>
                        <div className="table-responsive">
                            <table style={{ backgroundColor: '#fff' }} className="table table-striped table-inverse table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '80px' }}>Ngày</th>
                                        <th style={{ minWidth: '50px' }}>Giờ</th>
                                        <th style={{ minWidth: '150px' }}>Theo dõi diễn biến</th>
                                        <th style={{ minWidth: '200px' }}>Thực hiện y lệnh/Chăm sóc</th>
                                        <th style={{ minWidth: '150px' }}>Người thực hiện</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listDate.map((row, index) => {
                                        lstTMP = this.state.lstChamSoc.filter(x => x.ngayText === row.ngay);
                                        return (
                                            <React.Fragment>
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }} rowSpan={row.count}>{row.ngay}</td>
                                                    <td>{moment(lstTMP[0].ngayGio).format('HH:mm')}</td>
                                                    <td>{lstTMP[0].dienbien}</td>
                                                    <td>{lstTMP[0].chamsoc}</td>
                                                    <td>{lstTMP[0].nguoithuchien}</td>
                                                </tr>
                                                {
                                                    lstTMP.length > 1 && this.state.lstChamSoc.filter(x => x.ngayText === row.ngay).map((child, index) => {
                                                        if (index > 0)
                                                            return (
                                                                <tr>
                                                                    <td>{moment(child.ngayGio).format('HH:mm')}</td>
                                                                    <td>{child.dienbien}</td>
                                                                    <td>{child.chamsoc}</td>
                                                                    <td>{child.nguoithuchien}</td>
                                                                </tr>
                                                            )
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default sochamsoc;