import { Input } from 'antd';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { BUTTON } from '../../../configureHIS/common/constants';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as CoporationServices from "../../../services/CorporationServices";
import { setLoading } from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';

const CoporationParentCreate = forwardRef((props, ref) => {
    const [parentName, setParentName] = useState();
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
    }, []);


    const _notify = useRef();

    const onClose = () => {
        if (props.onClose) props.onClose();
        else showError();
    }

    const onSave = () => {
        if (isNullOrEmpty(parentName)) {
            showError("Thông tin tên cần được nhập.");
            return;
        }
        setLoading(true);
        const data = {
            parentName
        }
        _notify && _notify.current && _notify.current.Close();
        CoporationServices.CreateParent(data).then(res => {
            if (res.isSuccess) {
                showSuccess("Tạo mới thành công.");
                props.onReload && props.onReload();
            } else showError(res.error.messageText ?? "Không thể tạo mới. Vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText ?? "Đã xảy ra lỗi.");
        }).finally(() => setLoading(false))
    }

    const showError = (msg = "Không tìm thấy function.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Error);
    }
    
    const showSuccess = (msg = "Thao tác thành công.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Success);
    }

    return (
        <Fragment>
            <HeaderModal title="Tạo mới nguồn khách hàng tổng" onClose={() => onClose()} />
            <Notify ref={_notify} />
            <div>
                <label className='required-field'>Tên nguồn khách hàng tổng</label>
                <Input placeholder='Nhập tên nguồn khách hàng tổng' className='w-100'
                    value={parentName} onChange={(e) => setParentName(e.target.value)} />
            </div>
            <div className='d-flex justify-content-center'>
                <button className={`${BUTTON.THEME} mt-2`} onClick={() => onSave()}><i className='fas fa-save mr-1' />Lưu</button>
                <button className={`${BUTTON.DANGER} mt-2 ml-2`} onClick={onClose}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationParentCreate)