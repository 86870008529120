import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import { DatePicker, Input, InputNumber, Radio, Select, message } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../configure/systemConfig';
import moment from 'moment';
import { isNullOrEmpty } from '../../libs/util';
import * as AdministrativeService from '../../services/administrativeService';
import { connect } from 'react-redux';
import { isValidEmail, isValidPhone } from '../../commons/utils';
import PatientSearch from './patientsearch';

const { Option } = Select;

const PatientInfo = forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const _patientSearchRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isUpdate, setIsUpdate] = useState(false);
    const [dobType, setDobType] = useState("dob");
    const [ageType, setAgeType] = useState("year");

    const [patientName, setPatientName] = useState("");
    const [patientCode, setPatientCode] = useState("");
    const [gender, setGender] = useState();
    const [dob, setDob] = useState();
    const [listCity, setListCity] = useState("");
    const [listDistrict, setListDistrict] = useState("");
    const [listWard, setListWard] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [ward, setWard] = useState("");
    const [street, setStreet] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [idCard, setIdCard] = useState("");
    const [email, setEmail] = useState("");
    const [adjustAge, setAdjustAge] = useState();

    useImperativeHandle(ref, () => ({
        checkValidInfo: () => {
            return checkValidInfo();
        },
        clearData: () => {
            resetState();
        },
        getPatientInfo: () => {
            const data = {
                MaBN: patientCode,
                HoTen: patientName,
                NgaySinh: dob,
                Phai: gender,
                SoNha: address,
                Thon: street,
                MaTT: city,
                MaQu: district,
                MaPhuongXa: ward,
                CMND: idCard,
                DienThoai: phone,
                Email: email,
                OnlyYear: dobType == "yob",
                ChiNhanh: props.siteid.toString(),
            }
            return data;
        },
        ShowMessage: (message, status) => {
            notiRef.current.Show(message, status);
        },
        setPatientInfo: (info) => {
            setPatientCode(info.patientCode);
        },
        hideNotify: () => {
            notiRef && notiRef.current && notiRef.current.Close();
        }
    }));

    useEffect(() => {
        loadCity();
        loadDefaultAddress();
    }, []);

    useEffect(() => {
        city && loadDistrict(city);
        createFullAddress();
    }, [city]);

    useEffect(() => {
        district && loadWard(district);
        createFullAddress();
    }, [district]);

    useEffect(() => {
        createFullAddress();
    }, [ward]);

    useEffect(() => {
        createFullAddress();
    }, [street]);

    useEffect(() => {
        createFullAddress();
    }, [listCity, listDistrict, listWard]);

    useEffect(() => {
        onChangeDOB(dob);
    }, [dob]);

    const loadDefaultAddress = () => {
        if (props.defaultAddress) {
            const listDefaultAddr = props.defaultAddress;
            let foundCity = listDefaultAddr.find(x => x.key === 'city');
            let foundDistrict = listDefaultAddr.find(x => x.key === 'district');
            let foundWard = listDefaultAddr.find(x => x.key === 'ward');
            if (foundCity !== undefined && foundDistrict !== undefined && foundWard !== undefined) {
                setCity(foundCity.value ?? "717");
                setDistrict(foundDistrict.value ?? "71701");
                setWard(foundWard.value ?? "7170107");
            }
        }
    }

    const checkValidInfo = () => {
        let isError = true;
        let errMsg = "";
        if (isNullOrEmpty(patientName)) errMsg += "Chưa nhập tên bệnh nhân.\n";
        if (isNullOrEmpty(gender)) errMsg += "Chưa chọn giới tính.\n";
        if (isNullOrEmpty(dob)) errMsg += `Chưa nhập ${dobType == "dob" ? "ngày" : "năm"} sinh.\n`;
        if (isNullOrEmpty(city)) errMsg += "Chưa chọn tỉnh / thành phố.\n";
        if (!isNullOrEmpty(phone) && !isValidPhone(phone)) errMsg += "Số điện thoại không hợp lệ.\n";
        if (!isNullOrEmpty(email) && !isValidEmail(email)) errMsg += "Email không hợp lệ.\n";
        if (!isNullOrEmpty(errMsg)) {
            notiRef.current.Show(errMsg, NotifyStatus.Warning);
        } else isError = false;
        return isError;
    }

    const onChangeDOB = (date) => {
        let ageByMonth = moment().diff(date, "months");
        let ageByYear = moment().diff(date, "years");
        if (ageType === "year" && ageByYear > 0) setAdjustAge(ageByYear);
        else if (ageType === "month" && ageByMonth > 0) setAdjustAge(ageByMonth);
        else setAdjustAge();
        setDob(date);
    }

    const onAdjustAge = (ageValue) => {
        if (ageValue && Number(ageValue) > 0) {
            if (ageType === "month") {
                let dob = moment().subtract(ageValue, "months").startOf("month");
                setDob(dob);
                setDobType("dob");
            } else if (ageType === "year") {
                let yob = moment().subtract(ageValue, "years").startOf("year");
                setDob(yob);
            }
        }
    }

    const loadCity = () => {
        AdministrativeService.GetAllCity(1, 1000).then(result => {
            if (result.isSuccess) {
                setListCity(result.data);
                if (!isNullOrEmpty(city)) {
                    loadDistrict(city);
                }
            }
        }).catch(err => { })
    }

    const loadDistrict = (cityId) => {
        AdministrativeService.GetDistrictByCityId(cityId, 1, 1000).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setListDistrict(result.data);
                if (!isNullOrEmpty(district)) {
                    loadWard(district);
                }
            }
        }).catch(err => { })
    }

    const loadWard = (districtId) => {
        AdministrativeService.GetWardByDistrictId(districtId, 1, 1000).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setListWard(result.data);
            }
        }).catch(err => { })
    }

    const createFullAddress = (streetName = street) => {
        let cityName = "";
        let districtName = "";
        let wardName = "";
        cityName = city && listCity && listCity.find(x => x.value == city)?.label;
        districtName = district && listDistrict && listDistrict.find(x => x.value == district)?.label;
        wardName = ward && listWard && listWard.find(x => x.value == ward)?.label;
        onUpdateFullAddress(streetName, wardName, districtName, cityName);
    }

    const onUpdateFullAddress = (streetName, wardName, disctrictName, cityName) => {
        let fullAddress = "";
        if (!isNullOrEmpty(streetName)) fullAddress += `${streetName}`;
        if (!isNullOrEmpty(wardName)) fullAddress += `, ${wardName}`;
        if (!isNullOrEmpty(disctrictName)) fullAddress += `, ${disctrictName}`;
        if (!isNullOrEmpty(cityName)) fullAddress += `, ${cityName}`;
        setAddress(fullAddress);
    }

    const getPatientName = () => {
        let patientName = _patientSearchRef.current.getKeyword();
        setPatientName(patientName);
    }

    const handleFillPatientInfo = (info) => {
        if (info) {
            setPatientName(info.hoTen);
            setPatientCode(info.maBN);
            setGender(info.phai);
            setDobType(info.onlyYear ? "yob" : "dob");
            setDob(!isNullOrEmpty(info.ngaySinh) ? moment(info.ngaySinh) : null);
            setCity(info.maTT);
            setDistrict(info.maQu);
            setWard(info.maPhuongXa);
            setStreet(info.thon);
            setPhone(info.dienThoai);
            setIdCard(info.cmnd);
            setEmail(info.email);
            props.updatePatientCode && props.updatePatientCode(info.maBN);
        }
    }

    const resetState = () => {
        _patientSearchRef.current.clearData && _patientSearchRef.current.clearData();
        setPatientName();
        setPatientCode();
        setGender();
        setDobType("dob");
        setAgeType("year");
        setDob();
        loadDefaultAddress();
        setStreet();
        setPhone();
        setIdCard();
        setEmail();
        createFullAddress("");
        notiRef.current.Close();
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-12 d-flex flex-column mt-2'>
                    <Notify ref={notiRef}></Notify>
                </div>
                <div className='col-md-8 d-flex flex-column mt-2'>
                    <PatientSearch
                        ref={_patientSearchRef}
                        fillPatientInfo={(info) => handleFillPatientInfo(info)}
                        getPatientName={() => { getPatientName() }}
                        maBN={props.maBN}
                        disable={!isNullOrEmpty(patientCode)} />
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title required-field'>Mã bệnh nhân</label>
                    <label className={`font-bold ${!patientCode && 'text-danger'}`}>{`${patientCode ? patientCode : "Hệ thống tự tạo"}`}
                        {
                            patientCode &&
                            <span
                                className='text-color'
                                onClick={() => {
                                    setPatientCode(null);
                                }}><i className='fas fa-times mx-1'></i>Bỏ chọn</span>
                        }
                    </label>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title required-field'>Giới tính</label>
                    <div className='w-100'>
                        <Radio.Group buttonStyle="solid" style={{ width: "100%" }}
                            value={gender} disabled={!isNullOrEmpty(patientCode)}
                            onChange={(e) => setGender(e.target.value)}>
                            <Radio.Button style={{ width: "50%", textAlign: "center" }} value={0}>Nam</Radio.Button>
                            <Radio.Button style={{ width: "50%", textAlign: "center" }} value={1}>Nữ</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <div className='mita-title w-100'>
                        <Radio.Group style={{ width: "100%" }}
                            value={dobType} defaultValue="dob" disabled={!isNullOrEmpty(patientCode)}
                            onChange={(e) => { setDobType(e.target.value) }}>
                            <Radio style={{ width: "45%" }} value="dob">Ngày sinh</Radio>
                            <Radio style={{ width: "45%" }} value="yob">Năm sinh</Radio>
                        </Radio.Group>
                    </div>
                    <DatePicker
                        disabled={!isNullOrEmpty(patientCode)}
                        value={dob}
                        className='w-100'
                        locale={viVN}
                        picker={dobType === "dob" ? "date" : "year"}
                        getPopupContainer={(e) => e.parentNode}
                        format={dobType === "dob" ? SystemConfig.FORMAT_DATE : "YYYY"}
                        onChange={(date) => { onChangeDOB(date) }}
                        disabledDate={(currentDate) => currentDate >= moment().endOf("day")}
                    >
                    </DatePicker>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>&nbsp;</label>
                    <div className='d-flex flex-row'>
                        <InputNumber
                            disabled={!isNullOrEmpty(patientCode)}
                            value={adjustAge}
                            style={{ width: "20%" }}
                            min={1}
                            onChange={(val) => {
                                onAdjustAge(val);
                            }}
                        >
                        </InputNumber>
                        <Radio.Group buttonStyle="solid" style={{ width: "80%" }} value={ageType} defaultValue="year"
                            onChange={(e) => { setAdjustAge(); setDob(); setAgeType(e.target.value) }}
                            disabled={!isNullOrEmpty(patientCode)}>
                            <Radio.Button style={{ width: "50%", textAlign: "center" }} value="year">Tuổi</Radio.Button>
                            <Radio.Button style={{ width: "50%", textAlign: "center" }} value="month">Tháng tuổi</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title required-field'>Tỉnh / Thành phố</label>
                    <Select
                        getPopupContainer={(e) => e.parentNode}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={city}
                        onChange={(e) => {
                            setCity(e);
                            setListDistrict();
                            setDistrict();
                            setListWard();
                            setWard();
                        }}
                    >
                        {
                            listCity && listCity.map((c, index) => {
                                return (<Option key={c.value} value={c.value.toString()}>{c.label}</Option>)
                            })
                        }
                    </Select>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>Quận / Huyện</label>
                    <Select
                        getPopupContainer={(e) => e.parentNode}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={district}
                        onChange={(e) => {
                            setDistrict(e);
                            setListWard();
                            setWard();
                        }}
                    >
                        {
                            listDistrict && listDistrict.map((c, index) => {
                                return (<Option key={c.value} value={c.value.toString()}>{c.label}</Option>)
                            })
                        }
                    </Select>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>Phường / Xã</label>
                    <Select
                        getPopupContainer={(e) => e.parentNode}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={ward}
                        onChange={(e) => {
                            setWard(e);
                        }}
                    >
                        {
                            listWard && listWard.map((c, index) => {
                                return (<Option key={c.value} value={c.value.toString()}>{c.label}</Option>)
                            })
                        }
                    </Select>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>Số nhà / thôn xóm / tên đường</label>
                    <Input
                        value={street}
                        onChange={(e) => {
                            setStreet(e.target.value);
                            createFullAddress(e.target.value);
                        }}
                    ></Input>
                </div>
                <div className='col-md-8 d-flex flex-column mt-2'>
                    <label className='mita-title'>Địa chỉ</label>
                    <Input
                        placeholder='Nối chuỗi dữ liệu (số nhà/ thôn xóm / tên đường, tỉnh, quận, phường) với ký tự ","'
                        value={address}
                        readOnly
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                    ></Input>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>Điện thoại</label>
                    <Input
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                    ></Input>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>CCCD/CMND/Passport</label>
                    <Input
                        value={idCard}
                        onChange={(e) => {
                            setIdCard(e.target.value);
                        }}
                    ></Input>
                </div>
                <div className='col-md-4 d-flex flex-column mt-2'>
                    <label className='mita-title'>Email</label>
                    <Input
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    ></Input>
                </div>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => ({
    defaultAddress: state.global.defaultAddress,
    siteid: state.global.siteid,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(PatientInfo);