import { DatePicker, notification, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import * as Actions from '../../../../libs/actions';
import * as CommonService from '../../../../services/commonService';
import * as InHospitalService from '../../../../services/inPatientService';
import * as MedicineService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';
import CapThuocNoiTru from './capthuoc';
import PatientInfo from '../benhan/patientinfo';
import ThuocDaCap from './ThuocDaCap';
import SaoChepThuoc from './SaoChepThuoc';
import SuaDonThuoc from './SuaDonThuoc';
const { RangePicker } = DatePicker;

class ThuocNoiTru extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            lstThuoc: [],
            ngaySearch: [moment(this.props.patientDetail.dateInHospital), moment()],
            keySearch: '',
            makho: 502,
            tenkho: '',
            lstKho: [],
            idbenhtrinh: '',
            ngayCreate: moment(),
            lstDoctor: [],
            mabs: '',
            lstThuocDaCap: [],
            listTypeThuoc: [],
            isOld: false,
            ngayCopy: moment(),
            ngayEdit: moment(),
            visibleCopy: false,
            visibleEdit: false,
            visibleModal: false,
            heightListBtn: 47,
        };
    }
    componentDidMount() {
        this.loadKho();
        this.loadDoctor();
        this.DanhSachThuocNoiTruTheoNgay();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail, ngaySearch: [moment(nextProps.patientDetail.dateInHospital), moment()] }, () => {
                this.DanhSachThuocNoiTruTheoNgay()
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }

    loadKho() {
        MedicineService.GetListKho(0).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value,
                    tenkho: result.data[0].label,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }

    loadDoctor() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({
                        mabs: result.data[0].value,
                    });
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }

    DanhSachThuocNoiTruTheoNgay() {
        InHospitalService.DanhSachThuocNoiTruTheoNgay(this.state.detail.code, this.state.detail.enterCode,
            this.state.ngaySearch[0].format("YYYY/MM/DD"), this.state.ngaySearch[1].format("YYYY/MM/DD"), this.state.isOld, this.state.keySearch).then(result => {
                if (result.isSuccess) {
                    let listType = result.data.map(x => x.idBenhTrinh);
                    let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    this.setState({ lstThuocDaCap: result.data, listTypeThuoc: ListTypeDistinct })
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu cấp thuốc của bệnh nhân lỗi' })
            })
    }

    PrintToaThuocNoiTru(all = true) {
        PrintService.PrintToaThuocNoiTru('', this.state.detail.code, this.state.detail.enterCode,
            this.state.ngaySearch[0].format('YYYY/MM/DD'), this.state.ngaySearch[1].format('YYYY/MM/DD'), this.state.isOld, all).then((result) => {
                Actions.DoPrint(result.item)
            })
    }

    reset() {
        this.setState({
            visibleCopy: false, visibleModal: false, visibleAdd: false, visibleEdit: false,
            mabs: this.state.lstDoctor.length > 0 ? this.state.lstDoctor[0].value : '',
            makho: this.state.lstKho.length > 0 ? this.state.lstKho[0].value : '',
        });
    }

    CreateSubmit() {
        const dataCapthuoc = this.capthuoc.getData();
        const patient = {
            'code': this.state.detail.code,
            'cardNumber': this.state.detail.cardNumber,
            'department': this.state.detail.department,
            'subjectCode': this.state.detail.subjectCode,
            'managerId': this.state.detail.managerId,
            'doctorReceive': this.state.mabs,
            'diagnostic': this.state.detail.diagnostic === undefined ? '' : this.state.detail.diagnostic,
            'diagnosticName': this.state.detail.diagnosticName === undefined ? '' : this.state.detail.diagnosticName,
            'enterCode': this.state.detail.enterCode === undefined ? '' : this.state.detail.enterCode,
        }
        const data = {
            id: this.state.idbenhtrinh,
            mavaovien: this.state.detail.enterCode,
            maql: this.state.detail.managerId,
            mabn: this.state.detail.code,
            ngay: this.state.ngayCreate.format("YYYY/MM/DD HH:mm"),
            mabs: this.state.mabs,
            patient: patient,
            listCapThuoc: dataCapthuoc.listCapThuoc.map(x => ({ ...x, ngay: this.state.ngayCreate.format("YYYY/MM/DD HH:mm") }))
        }
        if (data.mabn === undefined || data.mabn === '') {
            notification.warning({ message: 'Chưa chọn bệnh nhân!' });
            return;
        }
        if (dataCapthuoc.listCapThuoc.length <= 0) {
            notification.warning({ message: 'Chưa chọn thuốc!' });
            return;
        }
        InHospitalService.UpdateBenhTrinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Tạo đơn thuốc thành công' });
                if (this.capthuoc) {
                    this.capthuoc.resetTable();
                }
                this.setState({
                    //idbenhtrinh: result.id,
                    visibleModal: false, visibleAdd: false,
                    ngayCreate: moment(),
                    mabs: this.state.lstDoctor.length > 0 ? this.state.lstDoctor[0].value : '',
                    makho: this.state.lstKho.length > 0 ? this.state.lstKho[0].value : '',
                }, () => {
                    this.DanhSachThuocNoiTruTheoNgay();
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }

    VisibleCopy(data) {

        this.setState({
            idBenhTrinhCopy: data.idBenhTrinh, ngayCopy: moment(data.ngay), visibleCopy: true, visibleModal: true,
            makho: data.makho,
            mabs: data.mabs,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="row align-items-center" style={{ padding: '10px', paddingTop: 0 }}>
                            <div className="w-100 col-12 row no-gutters" style={{ backgroundColor: '#fff', paddingTop: 10 }}>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '115px' }} className="btn btn-color fontSize12" onClick={() => { this.setState({ visibleAdd: true, visibleModal: true }) }}
                                        data-toggle="collapse" data-target="#themthuocnoitru" ><i className="fa fa-plus"></i> Thêm thuốc</button>
                                </div>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '115px' }} className="btn btn-outline-secondary fontSize12"
                                        onClick={() => { this.PrintToaThuocNoiTru(true) }}
                                    ><i className="fa fa-print"></i> In đơn thuốc</button>
                                </div>
                                <div className="col-auto mb-2 mr-2" style={{ width: '225px' }}>
                                    <RangePicker className="fontSize12" format="DD-MM-YYYY" value={this.state.ngaySearch} allowClear={false}
                                        locale={viVN} onChange={(value => this.setState({ ngaySearch: value }, () => { this.DanhSachThuocNoiTruTheoNgay() }))}
                                        //disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                                    />
                                </div>
                                <div className="col-auto mb-2 mr-2 align-self-center">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" value={this.state.isOld}
                                                onChange={(e) => { this.setState({ isOld: e.target.checked }, () => { this.DanhSachThuocNoiTruTheoNgay() }) }} />Hiện thuốc bệnh án cũ
                                    </label>
                                    </div>

                                </div>
                                <div className="col-auto mb-2">
                                    <div className="input-group fontSize12">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-color" type="button" onClick={() => { this.DanhSachThuocNoiTruTheoNgay() }}><i className="fa fa-search" /></button>
                                        </div>
                                        <input value={this.state.keySearch} type="text" className="form-control" placeholder="Nhập mã, tên thuốc"
                                            onChange={(e) => this.setState({ keySearch: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.DanhSachThuocNoiTruTheoNgay()
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ backgroundColor: "#fff" }}>
                            <div style={{ padding: '10px 0' }}>
                                <ThuocDaCap
                                    ref={(c) => this.thuocdacap = c}
                                    dataSource={this.state.lstThuocDaCap}
                                    listTypeThuoc={this.state.listTypeThuoc}
                                    patient={this.state.detail}
                                    ngaySearch={this.state.ngaySearch}
                                    SaoChepThuoc={this.VisibleCopy.bind(this)}
                                    reLoad={() => {
                                        this.DanhSachThuocNoiTruTheoNgay();
                                    }}
                                    SuaDonThuoc={(data) => {
                                        this.setState({
                                            idBenhTrinhEdit: data.idBenhTrinh,
                                            makho: data.makho,
                                            mabs: data.mabs,
                                            ngayEdit: moment(data.ngay),
                                            visibleEdit: true,
                                            visibleModal: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visibleModal &&
                    <Modal
                        width={1400}
                        visible={this.state.visibleModal}
                        onCancel={() => { 
                            this.setState({ visibleModal: false }) ;
                            this.reset();
                        }}
                    >
                        {this.state.visibleAdd &&
                            <div id='themthuocnoitru' className="collapse">
                                <div className="row">
                                    <div className="col-auto d-flex align-items-center">
                                        <div className="mr-1">Chọn kho</div>
                                        <select
                                            className="form-control"
                                            style={{ width: '200px' }}
                                            showSearch
                                            value={this.state.makho}
                                            onChange={(e) => { this.setState({ makho: e.target.value, tenkho: e.target.options[e.target.selectedIndex].text }) }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstKho.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.value}>{row.label}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <div className="mr-1">Bác sĩ</div>
                                        <select className="form-control"
                                            value={this.state.mabs}
                                            style={{ width: '200px' }}
                                            onChange={(value) => { this.setState({ mabs: value.target.value }) }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstDoctor.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.value}>{row.label}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-auto d-flex align-items-center">
                                        <div className="mr-1">Ngày</div>
                                        <DatePicker locale={viVN} allowClear={false} placeholder="dd/MM/yyyy" value={this.state.ngayCreate} onChange={(e) => { this.setState({ ngayCreate: e }) }} className="w-100" format={'DD/MM/YYYY'}
                                            //disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                                        />

                                    </div>
                                </div>
                                <CapThuocNoiTru
                                    ref={(c) => this.capthuoc = c}
                                    makho={this.state.makho}
                                    tenkho={this.state.tenkho}
                                    showHeader={true}
                                    scrollY='255px'
                                    idbenhtrinh={this.state.idbenhtrinh}
                                    managerId={this.state.detail.managerId}
                                    cardNumber={this.state.detail.cardNumber}
                                    subjectCode={this.state.detail.subjectCode}
                                    gender={this.state.detail.gender}
                                    department={this.state.detail.department}
                                    code={this.state.detail.code}
                                    enterCode={this.state.detail.enterCode}
                                    dateIn={this.state.ngayCreate}
                                    noitru={true}
                                />
                                <div className="text-center">
                                    <button type="button" className="btn btn-color mr-2"
                                        onClick={() => { this.CreateSubmit() }}
                                    ><i className="fa fa-save"></i> Lưu</button>
                                    <button type="button" className="btn btn-outline-secondary"
                                        onClick={() => { this.reset() }}
                                    >Đóng</button>
                                </div>
                            </div>
                        }
                        {this.state.visibleCopy &&
                            <div>
                                <SaoChepThuoc
                                    ref={(c) => this.saochepthuoc = c}
                                    idbenhtrinh={this.state.idBenhTrinhCopy}
                                    lstDoctor={this.state.lstDoctor}
                                    lstKho={this.state.lstKho}
                                    patient={this.state.detail}
                                    ngayCopy={this.state.ngayCopy}
                                    mabs={this.state.mabs}
                                    makho={this.state.makho}
                                    onClose={() => {
                                        this.reset();
                                        this.DanhSachThuocNoiTruTheoNgay();
                                    }}
                                />
                            </div>
                        }
                        {this.state.visibleEdit &&
                            <div>
                                <SuaDonThuoc
                                    ref={(c) => this.suachepthuoc = c}
                                    idbenhtrinh={this.state.idBenhTrinhEdit}
                                    lstDoctor={this.state.lstDoctor}
                                    lstKho={this.state.lstKho}
                                    patient={this.state.detail}
                                    ngayEdit={this.state.ngayEdit}
                                    mabs={this.state.mabs}
                                    makho={this.state.makho}
                                    onClose={() => {
                                        this.reset();
                                        this.DanhSachThuocNoiTruTheoNgay();
                                    }}
                                />
                            </div>
                        }
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default ThuocNoiTru;