import React from 'react';

import Barcode from 'react-barcode';
const MinHeight = 100;
const MinWidth = 140;
export default class MidItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            top: 0,
            left: 0,
            lastMouseX: 0,
            lastMouseY: 0,
            isResizeHorizon: false,
            isResizeVerical: false,
            isResizeBoth: false,
            moveState: false,
            fullMode: false,


            barcodeWith : 1.5
        }
    }
    handleRotate = (rotateAngle) => {
        this.setState({
            rotateAngle
        })
    }

    setLeft(left){
        this.setState({left})
    }
    setTop(top){
        this.setState({top})
    }
    setWidth(width){
        this.setState({width})
        this.checkCurrentWidth(width)
    }
    setHeight(height){
        this.setState({height})
    }

    checkCurrentWidth(width){
        let {barcodeWith} = this.state

        barcodeWith = width * 1.5 / 215
        this.setState({barcodeWith})
    }

    componentDidMount() {
        let { top, left, width, height } = this.props
        this.setState({ top, left, width, height })
    }

    handleMouseMove(e) {

        let { top, left, width, height } = this.state
        if (this.state.isResizeHorizon) {
            this.resizeHorizon(e, this.state.horizonType);
            return;
        }

        if (this.state.isResizeVerical) {
            this.resizeVertical(e, this.state.verticalType);
            return;
        }

        if (this.state.isResizeBoth) {
            this.resizeBoth(e, this.state.resizeBothType);
            return;
        }

        let {  lastMouseX, lastMouseY } = this.state;
        var difx = e.screenX - lastMouseX;
        var dify = e.screenY - lastMouseY;
        top += dify;
        left += difx;
        lastMouseX = e.screenX;
        lastMouseY = e.screenY;
        if (top > 0)
            this.setState({ top, left, lastMouseX, lastMouseY });

        this.props.onChange({ top, left, width, height })
    }

    resizeHorizon(e, type) {
        let { top, left, width, height } = this.state
        if (this.state.isResizeHorizon) {
            var { lastMouseX } = this.state;
            if (type === 1) {
                var right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                if (width >= MinWidth)
                    this.setState({ left, width, lastMouseX });
            } else {
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                if (width >= MinWidth)
                    this.setState({ width, lastMouseX });
            }
        }
        this.props.onChange({ top, left, width, height })
        this.checkCurrentWidth(width)
    }

    resizeVertical(e, type) {
        let { top, left, width, height } = this.state
        if (this.state.isResizeVerical) {
            let { lastMouseY } = this.state;
            if (type === 1) {
                let bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY
                if (height >= MinHeight) {
                    this.setState({ top, height, lastMouseY });
                }
            } else {
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                if (height >= MinHeight) {
                    this.setState({ height, lastMouseY });
                }
            }
        }
        this.props.onChange({ top, left, width, height })

        this.checkCurrentWidth(width)
    }

    resizeBoth(e, type) {
        let { lastMouseX, left, width, lastMouseY, top, height } = this.state;
        switch (type) {
            case 1:
                let right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                let bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY

                break;
            case 2:
                bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY
                this.setState({ top, height, lastMouseY });
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                break;
            case 3:
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                break;
            case 4:
                right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                break;
            default:
                break;
        }
        if (width >= MinWidth && height >= MinHeight)
            this.setState({ top, height, lastMouseY, left, width, lastMouseX });
        this.props.onChange({ top, left, width, height })


        this.checkCurrentWidth(width)
    }

    clearState() {
        this.setState({
            isResizeHorizon: false,
            isResizeVerical: false,
            isResizeBoth: false,
            moveState: false
        })
    }
    render() {

        return (

            <div key={this.props.key}
                cursor={'e-resize'}
                onMouseDown={(e) => {


                    this.setState({ lastMouseX: e.screenX, lastMouseY: e.screenY, moveState: true }, () => {
                        this.props.onActiveMove(this);
                    })

                }}

                style={{
                    position: "absolute", zIndex: this.props.index, display: 'flex', flexDirection: 'column',
                    top: this.state.fullMode === true ? 0 : this.state.top,
                    left: this.state.fullMode === true ? 0 : this.state.left,
                    borderRadius: 3,

                    width: this.state.fullMode === true ? '100%' : this.state.width,
                    height: this.state.fullMode === true ? '100%' : this.state.height,
                    //  userSelect: 'none',
                    backgroundColor: 'white'
                }}>
                <div style={{
                    width: '100%', height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden'
                }}>

                    <Barcode
                        value="HN20129230492"
                        displayValue={false}
                        // width={this.state.width}
                         height={this.state.height}
                        marginTop={3}
                        marginBottom={0}
                        marginLeft={3}
                        marginRight={0}
                        margin={0}
                        width={this.state.barcodeWith}
                    />

                    <div
                        style={{ position: "absolute", height: 4, left: 5, right: 5, top: 0, borderTop: '2px solid rgb(233,233,233)', cursor: 'ns-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeVerical: true, verticalType: 1, lastMouseY: e.screenY });
                            this.props.onActiveMove(this);
                            e.preventDefault();
                        }}
                    >

                    </div>
                    <div style={{ position: "absolute", height: 4, left: 5, right: 5, bottom: 0, borderBottom: '2px solid rgb(233,233,233)', cursor: 'ns-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeVerical: true, verticalType: 2, lastMouseY: e.screenY });
                            this.props.onActiveMove(this);

                        }}
                    ></div>
                    <div style={{ position: "absolute", width: 4, left: 0, top: 5, bottom: 5, borderLeft: '2px solid rgb(233,233,233)', cursor: 'ew-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeHorizon: true, horizonType: 1, lastMouseX: e.screenX });
                            this.props.onActiveMove(this);

                        }}

                    ></div>
                    <div style={{ position: "absolute", width: 4, right: 0, top: 5, bottom: 5, borderRight: '2px solid rgb(233,233,233)', cursor: 'ew-resize' }}

                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeHorizon: true, horizonType: 2, lastMouseX: e.screenX });
                            this.props.onActiveMove(this);

                        }}

                    ></div>

                    <div style={{
                        position: "absolute", width: 8, height: 8, left: 0, top: 0, borderLeft: '2px solid rgb(233,233,233)',
                        borderTop: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nwse-resize'

                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 1 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>


                    <div style={{
                        position: "absolute", width: 8, height: 8, right: 0, top: 0, borderRight: '2px solid rgb(233,233,233)',
                        borderTop: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nesw-resize'
                    }}

                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 2 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>

                    <div style={{
                        position: "absolute", width: 8, height: 8, bottom: 0, right: 0, borderRight: '2px solid rgb(233,233,233)',
                        borderBottom: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nwse-resize'
                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 3 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>



                    <div style={{
                        position: "absolute", width: 8, height: 8, bottom: 0, left: 0, borderLeft: '2px solid rgb(233,233,233)',
                        borderBottom: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nesw-resize'
                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 4 });
                            this.props.onActiveMove(this);

                        }}

                    ></div>
                </div>


            </div>

        );
    }
}
