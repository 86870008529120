import { Button, Checkbox, Tooltip, Form, notification, Select, Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as PrintService from '../../../../services/printService';
import * as MedicineService from '../../../../services/medicineService';
import * as EnterInfo from '../../../../services/enterInfoService';
import * as commonService from '../../../../services/commonService';
import ReceiptsDetails from './receiptDetails';
import CreateCopy from './createCopy';
import Administrative from '../confirm/patientdetails';
import * as CommonService from '../../../../services/commonService';
import * as SystemConfig from "../../../../configure/systemConfig";

const { Option } = Select;
export default class DonBacSi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lstDon: [],
            lstdepartment: [],
            lstDoiTuong: [],
            mabn: '',
            id: '',
            maql: '',
            hoten: '',
            makhoa: '',
            madoituong: 0,
            tungay: moment(),
            denngay: moment(),
            visible: false,
            ngay: [moment(), moment()],
            lstDetail: [],
            selectedPatient: {},
            tongtienNew: 0,
        };
    }

    componentWillMount() {
        this.loadDoiTuong();
        this.loadPhongKham();
        this.reset();
    }

    componentDidMount() {
        this.searchToa();
    }

    reset() {
        this.setState({
            lstDon: [],
            lstdepartment: [],
            mabn: '',
            madoituong: 0,
            id: '',
            maql: '',
            hoten: '',
            makhoa: '',
            tungay: moment(),
            denngay: moment(),
            visible: false
        })
        this.loadPhongKham();
        this.loadDoiTuong();
        this.searchToa();
    }

    loadDoiTuong() {
        EnterInfo.GetNhomDoiTuong().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoiTuong: result.data
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu đối tượng bị lỗi' })
        })
    }

    searchToa(dateFrom, dateTo) {
        let tungay = this.state.tungay;
        let denngay = this.state.denngay;
        if (dateFrom) {
            tungay = dateFrom;
            this.setState({ tungay: moment(dateFrom) });
        }
        if (dateTo) {
            denngay = dateTo;
            this.setState({ denngay: moment(dateTo) });
        }
        if (tungay === null || tungay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        let data = new FormData();
        data.append('isDuoc', true);
        data.append('tenbn', this.state.hoten);
        data.append('mabn', this.state.mabn);
        data.append('makhoa', this.state.makhoa);
        data.append('madoituong', this.state.madoituong);
        data.append('tungay', moment(tungay).format('YYYY/MM/DD'));
        data.append('denngay', moment(denngay).format('YYYY/MM/DD'));
        if (this.props.trangthai === '2') {
            MedicineService.SearchDonDaXacNhap(data).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.done === 1 || x.done === 2).map(x => ({ ...x, 'trangthai': 'Đã xuất phiếu' }));
                    //data = data.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    data = data.filter(x => x.iscopy === '0').map(x => ({ ...x, }));
                    this.setState({
                        lstDon: data,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        } else if (this.props.trangthai === '1') {
            MedicineService.SearchDonThuoc(data).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.done === 0 && x.isdeleted === "0").map(x => ({ ...x, 'trangthai': 'Mới' }));
                    //data = data.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    data = data.filter(x => x.iscopy === '0').map(x => ({ ...x, }));
                    this.setState({
                        lstDon: data,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        }
        else if (this.props.trangthai === '') {
            MedicineService.SearchDonDaXacNhap(data).then(result => {
                if (result.isSuccess) {
                    let data1 = result.data;
                    let data0 = data1.filter(x => x.done === 3).map(x => ({ ...x, 'trangthai': 'Đã hoàn trả' }));
                    let data2 = data1.filter(x => x.isdeleted === "1").map(x => ({ ...x, 'trangthai': 'Đã xóa phiếu' }));
                    data1 = data1.filter(x => (x.done === 1 || x.done === 2) && x.isdeleted == "0").map(x => ({ ...x, 'trangthai': 'Đã xuất phiếu' }));
                    //data1 = data1.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                    data1 = data1.filter(x => x.iscopy === '0').map(x => ({ ...x, }));
                    MedicineService.SearchDonThuoc(data).then(result2 => {
                        if (result2.isSuccess) {
                            let dataTotal = result2.data;
                            dataTotal = dataTotal.filter(x => x.done === 0 && x.isdeleted === "0").map(x => ({ ...x, 'trangthai': 'Mới' }));
                            //dataTotal = dataTotal.filter(x => x.isdeleted !== '1').map(x => ({ ...x, }));
                            dataTotal = dataTotal.filter(x => x.iscopy === '0').map(x => ({ ...x, }));
                            let dataKQ = dataTotal.concat(data1).concat(data0).concat(data2);;
                            dataKQ.sort(function (a, b) {
                                return new Date(b.ngaychidinh) - new Date(a.ngaychidinh);
                            });
                            this.setState({
                                lstDon: dataKQ
                            })
                        }
                    }).catch(err => {
                        notification.warning({ message: 'Tìm kiếm bị lỗi' })
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tìm kiếm bị lỗi' })
            })
        }
    }


    loadPhongKham(pageNumber, pageSize) {
        commonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstdepartment: result.data,

                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi' })
        })
    }

    onClose = e => {
        this.setState({
            visible: false, maql: '', visibleCopy: false,
        });
    };
    PrintMedicine(id, ngay) {
        PrintService.PrintMedicine(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUse(id, ngay, isDuoc = false) {
        PrintService.PrintUse(id, ngay, isDuoc).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintConclusion(id, mabn, maql, ngay, isDuoc = false, makp = "", loaiKham = "") {
        const data = {
            presciptionId: id,
            isDuoc: false,
            serviceId: null,
        }
        Actions.setLoading(true);
        MedicineService.printDonThuoc(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data);
            }
        }).catch(() => {
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    xoathuoc(row) {
        MedicineService.XoaToaTheoDon(row.id, moment(row.ngaychidinh).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.searchToa();
                notification.success({ message: 'Xóa thành công!' });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadDetailPatient(mabn) {
        CommonService.GetPatientDetail(mabn).then(result => {
            if (result.isSuccess) {
                let selectedPatient = result.item;
                this.setState({ selectedPatient })
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    loadDetailToa(ngay, id, maql) {
        MedicineService.DanhSachChiTietToaBS(ngay, id, maql).then(result => {
            if (result.isSuccess) {
                let data = [];
                data = result.data.filter(x => x.soluong > 0);
                // if (this.state.detail.done === 0) {
                //     data = result.data.filter(x => x.soluong > 0);
                // }
                // else {
                //     data = result.data.map(x => ({ ...x, soluong: x.soluongthuc })).filter(x => x.soluongthuc > 0);
                // }
                let tongtien = 0;
                let index = 1;
                for (let item of data) {
                    tongtien += item.soluong * item.dongia;
                    item.key = item.ma;
                    item.idPhieu = item.idPhieu;
                    item.countNumber = index;
                    index++
                }
                let tongtienNew = tongtien
                //this.newTable.setDataSource(data);
                this.setState({
                    lstDetail: JSON.parse(JSON.stringify(data)),
                    lstDetailNew: data,
                    hidden: false,
                    tongtienNew, tongtien,
                    ghichu: result.data[0].ghichu
                })
                if (data.length > 0) {
                    this.setState({
                        detailDefault: data[0],
                    })
                }
            }
        })
            .catch(err => {
                notification.warning({ message: 'Tải dữ liệu chi tiết đơn bị lỗi' })
            })
    }
    CopyToaThuoc(id, ngay, maql) {
        MedicineService.CloneToaThuocBS(id, ngay, maql).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Tạo phiếu mới thành công' });
                this.searchToa();
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }

        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        })
    }
    SearchWhenChangeDate(date) {
        this.searchToa(date[0], date[1]);
    }
    render() {
        let dataSource = this.state.lstDon;
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ display: 'none' }}>
                        <Select
                            style={{ width: 200, flex: 1 }}
                            showSearch
                            defaultValue='-----'
                            value={this.state.makhoa}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(key) => { this.setState({ makhoa: key }) }}>
                            <Option key={0} value=''>Phòng khoa</Option>
                            {this.state.lstdepartment.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}
                                    >{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div style={{ display: 'none' }}>
                        <Checkbox onChange={(e) => this.setState({ done: e.target.checked }, () => this.searchToa())}>Đã xác nhận</Checkbox>
                    </div>
                    <div className="table-responsive" style={{ height: 'calc(100vh - 320px)' }}>
                        <table className="table table-striped table-inverse table-hover table-bordered">
                            <thead>
                                <tr className='text-center'>
                                    <th style={{ minWidth: '75px' }}>Mã BN</th>
                                    <th style={{ minWidth: '150px' }}>Họ tên</th>
                                    <th style={{ minWidth: '100px' }}>BS chỉ định</th>
                                    <th style={{ minWidth: '100px' }}>Ngày chỉ định</th>
                                    <th style={{ minWidth: '100px' }}>Trạng thái</th>
                                    <th style={{ minWidth: '90px', textAlign: 'right' }}>Tổng tiền</th>
                                    <th style={{ minWidth: '110px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource && dataSource.map(row => (
                                    <tr style={{ cursor: 'pointer' }} onClick={() => {
                                        this.setState({ detail: row, visible: true });
                                        if (row.done === 1) { this.loadDetailToa(row.ngaychidinh, row.id, row.maql); this.loadDetailPatient(row.mabn) }
                                    }}>
                                        <td>{row.mabn}</td>
                                        <td>{row.hoten}</td>
                                        <td>{row.tenbs}{row.tendichvu && <i>&nbsp;({row.tenphong} - {row.tendichvu})</i>}</td>
                                        <td>{moment(row.ngaychidinh).format(SystemConfig.FORMAT_DATE)}</td>
                                        <td>{row.trangthai}</td>
                                        <td>{Number((row.sotienbs * ((100 - row.discount) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                        <td>
                                            <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex' }}>
                                                <Button className="btn btn-outline-secondary" style={{ textAlign: 'center', marginRight: 10 }} onClick={(e) => { this.PrintConclusion(row.id, row.mabn, row.maql, row.ngaychidinh, false) }}><i className="fa fa-print"></i></Button>
                                                <Tooltip align="top" title="Xuất phiếu"><Button className="btn btn-color" onClick={(e) => {
                                                    this.setState({ visibleCopy: true, detail: row })
                                                }} ><i className="fa fa-plus"></i></Button></Tooltip>
                                            </div>
                                        </td>
                                    </tr>))}

                            </tbody>
                        </table>
                    </div>
                    {this.state.visible && this.state.detail.done !== 1 &&
                        <Modal maskClosable={false}
                            destroyOnClose={true}
                            width={1400}
                            visible={this.state.visible}
                            placement="top"
                            closable={false}
                            onClose={() => this.setState({ visible: false })}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            {this.state.detail.id !== '' &&
                                <ReceiptsDetails detail={this.state.detail} ngay={this.state.detail.ngaychidinh} mabn={this.state.detail.mabn}
                                    done={this.state.detail.done} id={this.state.detail.id}
                                    maql={this.state.detail.maql}
                                    onClose={this.onClose}
                                ></ReceiptsDetails>
                            }
                        </Modal>
                    }
                    {this.state.visible && this.state.detail.done === 1 ?
                        <Modal maskClosable={false}
                            visible={this.state.visible}
                            width={1400}
                            footer={null}
                        >
                            {this.state.detail.id !== '' &&
                                <div>
                                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h3>Chi tiết đơn thuốc</h3>
                                        <h3 onClick={() => this.onClose()}><i className='far fa-times-circle' /></h3>
                                    </div>
                                    <Administrative loai={1} thanhtoan={true} item={this.state.selectedPatient}></Administrative>
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Dịch vụ khám:&nbsp;<strong>{this.state.detail.tendichvu}</strong></span>
                                        <span>Tổng tiền:&nbsp;<strong style={{ marginLeft: 5 }}>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</strong></span>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: '35px' }}>TT</th>
                                                    <th style={{ minWidth: '150px' }}>Tên thuốc</th>
                                                    <th style={{ minWidth: '50px' }}>ĐVT</th>
                                                    <th style={{ minWidth: '100px' }}>Tên hoạt chất</th>
                                                    <th style={{ minWidth: '110px' }}>Hãng sản xuất</th>
                                                    <th style={{ minWidth: '110px' }}>Nước sản xuất</th>
                                                    <th style={{ minWidth: '80px' }}>Số lượng</th>
                                                    <th style={{ minWidth: '110px' }}>Cách dùng</th>
                                                    <th style={{ minWidth: '80px' }}>Giá bán</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lstDetail.map((row, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{row.tenthuoc}</td>
                                                        <td>{row.donvi}</td>
                                                        <td>{row.tenhc}</td>
                                                        <td>{row.hangsanxuat}</td>
                                                        <td>{row.tennuoc}</td>
                                                        <td>{row.soluong}</td>
                                                        <td>{row.cachdung}</td>
                                                        <td>{row.dongia ? row.dongia.toLocaleString('en-US') : '0'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='custom-hr'></div>
                                    <div style={{
                                        textAlign: 'center', marginTop: '20px', display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Button className="btn btn-color" style={{ marginRight: 5 }} key="0" onClick={() => { this.setState({ visibleCopy: true }) }} > Tạo phiếu</Button>
                                        <Button className="btn btn-outline-secondary" key="1" onClick={() => { this.PrintConclusion(this.state.detail.id, this.state.detail.mabn, this.state.detail.maql, this.state.detail.ngaychidinh) }} >
                                            In đơn thuốc </Button>
                                        {/* <Button key="2" style={{ marginLeft: 5 }} onClick={() => { this.PrintUse(this.state.detail.id, this.state.detail.ngaychidinh, true) }} >
                                            In cách dùng </Button> */}
                                        <Button className="btn btn-danger" style={{ marginLeft: 5 }}
                                            onClick={() => {
                                                this.onClose()
                                            }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                            /> Đóng</Button>
                                    </div>
                                </div>
                            }
                        </Modal>
                        :
                        ""
                    }
                    {this.state.visibleCopy &&
                        <Modal maskClosable={false}
                            destroyOnClose={true}
                            width={1400}
                            visible={this.state.visibleCopy}
                            placement="top"
                            closable={false}
                            onClose={() => this.setState({ visibleCopy: false })}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <CreateCopy detail={this.state.detail} ngay={this.state.detail.ngaychidinh} mabn={this.state.detail.mabn}
                                done={this.state.detail.done} id={this.state.detail.id}
                                maql={this.state.detail.maql}
                                onClose={this.onClose}
                            ></CreateCopy>
                        </Modal>
                    }
                </Form>
            </React.Fragment>
        )
    }
}
