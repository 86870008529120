import { Button, DatePicker, notification, Pagination, Select, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Autocomplete from '../../../components/autocomplete';
import * as Actions from '../../../libs/actions';
import * as PromotionalCampagin from '../../../services/PromotionServices';
import * as Statistic from '../../../services/statisticService';
import * as SystemConfig from "../../../configure/systemConfig";
const { Option } = Select;

const { RangePicker } = DatePicker;
export default (props) => {
    const _hiddenLink = useRef()
    const [date, setDate] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [siteId,] = useState(props.siteid);
    const [dataPromotion, setDataPromotion] = useState([]);
    const [key, setKey] = useState('')
    const [type, setType] = useState(null);

    useEffect(() => {
        setPage(1);
        // GetStatisticVoucher();
    }, [date, key, type])

    useEffect(() => {
        GetStatisticVoucher();
    }, [page, size])

    useEffect(() => {
        GetPromotionsList()
    }, [])

    const GetStatisticVoucher = () => {
        Actions.setLoading(true);
        Statistic.StatisticVoucher(date[0] == null ? null : date[0].format("yyyy/MM/DD"),
            date[1] == null ? null : date[1].format("yyyy/MM/DD"), page, size,
            key || '', type || '').then(res => {
                if (res.isSuccess) {
                    setDataSource(res.data);
                    setTotalRecord(res.totalRecords);
                    Actions.setLoading(false);
                }
            }).catch(() => {
                Actions.setLoading(false);
            })
    }

    const ExportStatisticVoucher = (typeExport) => {
        Actions.setLoading(true);
        Statistic.ExportStatisticVoucher(date[0] == null ? null : date[0].format("yyyy/MM/DD"),
            date[1] == null ? null : date[1].format("yyyy/MM/DD"), 0, 0, typeExport,
            key || '', type || '').then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeVouher";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const GetPromotionsList = (key) => {
        Actions.setLoading(true);
        PromotionalCampagin.getPromotionsList(key || '', 1, 50).then(result => {
            if (result.isSuccess) {
                setDataPromotion(result.data.map(x => ({
                    value: x.id, label: x.promotionname
                })))
            } else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    const columns = [
        {
            title: "STT",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 50
        },
        {
            title: 'Voucher',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 100,
        },
        {
            title: 'Chương trình',
            dataIndex: 'promotionName',
            key: 'promotionName',
            width: 150,
        },
        {
            title: 'Tỉ lệ',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            width: 80,
            render: (discount) => {
                return (
                    discount.includes("$") ? <span>{Number(discount.replace("$", "")).toLocaleString("en-US")} đồng</span>
                        : <span>{discount}</span>
                )
            }
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'beginning',
            key: 'beginning',
            align: 'center',
            width: 90,
            render: (value) => {
                return (
                    <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME)}</span>
                )
            }
        },
        {
            title: 'Kết thúc',
            dataIndex: 'beginning',
            key: 'ending',
            align: 'center',
            width: 90,
            render: (value) => {
                return (
                    <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME)}</span>
                )
            }
        },
        {
            title: 'Số lượng',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 70,
        },
        {
            title: 'Đã dùng',
            dataIndex: 'total_used',
            key: 'total_used',
            align: 'center',
            width: 70,
        },
        {
            title: 'Chưa dùng',
            dataIndex: 'total_other',
            key: 'total_other',
            align: 'center',
            width: 70,
        },
        {
            title: 'Người dùng',
            width: 150,
            render: (value) => {
                return (
                    <span>{value.patientId == '' ? '' : value.patientId + '-'}{value.patientName}</span>
                )
            }
        },
        {
            title: 'Ngày dùng',
            dataIndex: 'usedDate',
            key: 'usedDate',
            align: 'center',
            width: 90,
        },
        {
            title: 'Phiếu thanh toán',
            dataIndex: 'usedIn',
            key: 'usedIn',
            width: 120,
        },
        {
            title: 'Số tiền',
            dataIndex: 'totalPay',
            key: 'totalPay',
            width: 100,
            align:'right',
            render: (value) => {
                return (
                    <span>{value.toLocaleString("en-US")}</span>
                )
            }
        },
    ];

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
            <div className='containerChilderWrapper'>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê Voucher</div>
                <div className='row'>
                    <div className='col-md-6 my-1 align-self-center'>
                        <Autocomplete
                            dataSource={dataPromotion}
                            handleSearch={(key) => GetPromotionsList(key)}
                            getItem={(key, value) => { setKey(key) }}
                            width='100%'
                            allowClear={true}
                        />
                    </div>
                    <div className='col-md-6 my-1'>
                        <div className="d-flex justify-content-center my-1 float-right">
                            <div className='mr-2'>
                                <Select
                                    style={{ width: 200 }}
                                    placeholder="Loại"
                                    value={type}
                                    allowClear={true}
                                    onChange={(e) => {
                                        if (e)
                                            setType(e)
                                        else
                                            setType(null)
                                    }}>
                                    <Option key={1} value="1">Ngày bắt đầu</Option>
                                    <Option key={2} value="2">Ngày kết thúc</Option>
                                </Select>
                            </div>
                            <div className="mr-2">
                                <RangePicker allowClear={true} format="DD-MM-YYYY"
                                    placeholder={['Từ ngày', 'Đến ngày']}
                                    value={date}
                                    locale={viVN}
                                    onChange={(value) => {
                                        if (value == null)
                                            setDate([null, null])
                                        else
                                            setDate(value)
                                    }}
                                />
                            </div>
                            <div className="d-flex">
                                <Button className="btn btn-color"
                                    onClick={() => {
                                        if (page == 1)
                                            GetStatisticVoucher()
                                        else
                                            setPage(1)
                                    }}>
                                    <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                </Button>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 text-right'>
                        <div class="btn-group">
                            <button type="button" class="btn btn-color dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"                            >
                                Xuất excel
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#"
                                    onClick={() => { ExportStatisticVoucher("1") }}
                                >Xuất theo chương trình</a>
                                <a class="dropdown-item" href="#"
                                    onClick={() => { ExportStatisticVoucher("2") }}
                                >Xuất theo Voucher</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: 'calc(100% - 145px)' }}>
                    <Table
                        scroll={{ y: 0, x: 'max-content' }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        rowKey={(row, index) => index}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};
