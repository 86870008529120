import { openMessageDialog } from "../../../libs/actions";
import { isNullOrEmpty } from "../../../libs/util";

export function NotifyUpdateServiceModified(data, bangGia, listSelectedServices) {
    const modifyQuotation = data.quotation;
    const modifyServiceId = data.serviceId;
    const includeModifyServiceId = listSelectedServices.find(x => x.id == modifyServiceId);
    if (bangGia == modifyQuotation) {
        let noti = "";
        if (bangGia != "default") noti = `Bảng giá <b>${modifyQuotation}</b> vừa được cập nhật thông tin dịch vụ. Vui lòng tải lại bảng giá dịch vụ.`;
        else modifyQuotation = "mặc định";
        if (includeModifyServiceId != undefined) noti = `Dịch vụ <b>${includeModifyServiceId.ten}</b> trong bảng giá <b>${modifyQuotation}</b> vừa được ẩn/xóa. Vui lòng kiểm tra lại.`
        if (!isNullOrEmpty(noti)) openMessageDialog("Cảnh báo", noti);
    }
}