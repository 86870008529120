import { Input } from 'antd';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { BUTTON } from '../../../configureHIS/common/constants';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as CoporationServices from "../../../services/CorporationServices";
import { isNullOrEmpty } from '../../../libs/util';
import { setLoading } from '../../../libs/actions';

const CoporationParentUpdate = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const _notify = useRef();
    const [data, setData] = useState(props.data ?? {});

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onClose = () => {
        if (props.onClose) props.onClose();
        else showError();
    }

    const onChangeData = (field = "", newData) => {
        let cData = { ...data };
        if (cData.hasOwnProperty(`${field}`)) {
            cData[field] = newData;
            setData(cData);
        }
    }

    const onUpdate = () => {
        if (isNullOrEmpty(data.parentName)) {
            showError("Thông tin tên cần được nhập.");
            return;
        }
        setLoading(true);
        _notify && _notify.current && _notify.current.Close();
        CoporationServices.UpdateParent(data).then(res => {
            if (res.isSuccess) {
                showSuccess("Cập nhật thành công.");
                props.onReload && props.onReload();
            } else showError(res.error.messageText ?? "Không thể cập nhật. Vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText ?? "Đã xảy ra lỗi.");
        }).finally(() => setLoading(false))
    }

    const showError = (msg = "Không tìm thấy function.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Error);
    }

    const showSuccess = (msg = "Thao tác thành công.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Success);
    }

    return (
        <Fragment>
            <HeaderModal title="Cập nhật nguồn khách hàng tổng" onClose={() => onClose()} />
            <Notify ref={_notify} />
            <div>
                <label className='required-field'>Tên nhóm nguồn con</label>
                <Input placeholder='Nhập tên nguồn khách hàng tổng' className='w-100'
                    value={data?.parentName} onChange={(e) => onChangeData("parentName", e.target.value)} />
            </div>
            <div className='d-flex justify-content-center'>
                <button className={`${BUTTON.THEME} mt-2`} onClick={() => onUpdate()}><i className='fas fa-save mr-1' />Cập nhật</button>
                <button className={`${BUTTON.DANGER} mt-2 ml-2`} onClick={onClose}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationParentUpdate)