import { DatePicker, Form, Input, notification, Select, TimePicker } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import Administrative from '../components/administrative';
import Autocomplete from '../../../components/autocomplete';
import EnterInfo from '../components/enterInfo';
import * as CommonService from '../../../services/commonService';
import * as StorageService from '../../../services/storageService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { TextArea } = Input;

const { Option } = Select;
const initialState = {
    diagnostic: '',
    diagnosticName: '',
    symptom: '',
    symptomName: '',
    note: '',
    receiveFrom: '',
}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            dateIn: moment(),
            lstDiagnostic: [],
            doctorReceive: '',
            lstDoctorReceive: [],
            lstSymptom: [],
            lstReceiveFrom: [],
        };
    }
    componentWillMount() {
        this.loadDoctor();
        this.loadNhanTu();
    }
    loadDoctor() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDoctorReceive: result.data,
                    doctorReceive: result.data[0].value
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    loadDiagnostic(value, title) {
        CommonService.GetListDiagnostic(value).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                if (title === 'chẩn đoán') {
                    this.setState({ lstDiagnostic: result.data });
                }
                else if (title === 'triệu chứng') {
                    this.setState({ lstSymptom: result.data });
                }
                else if (title === 'cđ nơi chuyển') {
                    this.setState({ lstCdFrom: result.data });
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ chẩn đoán bị lỗi' })
        })
    }
    loadNhanTu() {
        CommonService.GetListNhanTu().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstReceiveFrom: result.data,
                    receiveFrom: result.data[0].value
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu nhận từ bị lỗi' })
        })
    }
    resetData() {
        this.administrative.resetState();
        this.enterinfo.resetState();
        this.setState(initialState);
        this.form.setFieldsValue(initialState);
    }
    async submit() {
        const validEnterInfo = this.enterinfo.checkValid()
        let numberError = 0;
        await this.administrative.getForm().validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        if (this.validator.allValid() && (numberError === 0) && validEnterInfo) {
            let data = new FormData();
            let dataAdministrative = this.administrative.getState();
            let dataEnterInfo = this.enterinfo.state;
            data.append('barCode', dataAdministrative.code);
            //data.append('managerId', this.administrative.state.managerId);
            data.append('code', dataAdministrative.code);
            data.append('name', dataAdministrative.name);

            data.append('dob', moment(dataAdministrative.dob).format('YYYY-MM-DD'));
            data.append('age', moment(dataAdministrative.dob, 'YYYY-MM-DD').year());
            data.append('gender', dataAdministrative.gender);
            data.append('people', dataAdministrative.people);
            data.append('job', dataAdministrative.job);
            data.append('cmnd', dataAdministrative.cmnd);
            data.append('city', dataAdministrative.city);
            data.append('district', dataAdministrative.district);
            data.append('ward', dataAdministrative.ward);
            data.append('jobAddress', dataAdministrative.jobAddress);
            data.append('village', dataAdministrative.village !== undefined ? dataAdministrative.village : '');
            data.append('country', dataAdministrative.nation);
            data.append('comeFrom', dataEnterInfo.comeFrom);
            data.append('recommendName', dataEnterInfo.recommendName);
            data.append('dateInHospital', this.state.dateIn.format('YYYY/MM/DD HH:mm'));
            data.append('receiveFrom', this.state.receiveFrom);
            data.append('subjectCode', "2");
            data.append('cardNumber', dataEnterInfo.cardNumber);
            data.append('cardFrom', dataEnterInfo.dateExpired.length > 1 ? dataEnterInfo.dateExpired[0] !== '' ? dataEnterInfo.dateExpired[0] : '0001-01-01' : '0001-01-01');
            data.append('cardTo', dataEnterInfo.dateExpired.length > 1 ? dataEnterInfo.dateExpired[1] !== '' ? dataEnterInfo.dateExpired[1] : '0001-01-01' : '0001-01-01');
            data.append('route', dataEnterInfo.route);
            data.append('routeCode', dataEnterInfo.routeCode !== undefined ? dataEnterInfo.routeCode : '');
            data.append('routeName', dataEnterInfo.routeName !== undefined ? dataEnterInfo.routeName : '');
            data.append('diagnostic', this.state.diagnostic);
            data.append('diagnosticName', this.state.diagnosticName);
            data.append('symptom', this.state.symptom);
            data.append('symptomText', this.state.symptomName);
            data.append('relative', dataEnterInfo.relative);
            data.append('relativeName', dataEnterInfo.relativeName);
            data.append('relativeAddress', dataEnterInfo.relativeAddress);
            data.append('relativePhone', dataEnterInfo.relativePhone);
            data.append('cdFrom', dataEnterInfo.cdFrom);
            data.append('note', this.state.note);
            data.append('doctorReceive', this.state.doctorReceive);
            StorageService.SaveStorage(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    this.administrative.resetState();
                    this.enterinfo.resetState();
                    this.setState(initialState);
                    this.form.setFieldsValue(initialState);
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {

            })
        } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    renderStorageForm() {
        return (
            <Form ref={(c) => this.form = c}>
                <div style={{ display: "flex", marginBottom: '8px' }}>
                    <div style={{ "flex": 1 }}>
                        <Form.Item label="Ngày vào:" rules={[{ required: true }]} >
                            <DatePicker locale={viVN} allowClear={false} value={this.state.dateIn} format={"DD-MM-YYYY"} placeholder="DD-MM-YYYY" onChange={(date) => { this.setState({ dateIn: date }) }} />
                            <TimePicker
                                locale={viVN}
                                allowClear={false}
                                value={this.state.dateIn}
                                format={'HH:mm'}
                                onChange={(date) => { this.setState({ timeIn: date }) }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ "flex": 1 }}>
                        <Form.Item label="Nhận từ:" name="receiveFrom" rules={[{ required: true, message: "Vui lòng chọn nhận từ!" }]}>
                            <Select value={this.state.receiveFrom} onChange={(value) => { this.setState({ receiveFrom: value }); this.form.setFieldsValue({ receiveFrom: value }) }}>
                                {this.state.lstReceiveFrom.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <EnterInfo
                    ref={((c) => this.enterinfo = c)}
                    setForm={(data) => this.form.setFieldsValue(data)}
                />
                <div style={{ display: "flex", marginBottom: '8px' }}>
                    <div style={{ "flex": 1 }} >
                        <Form.Item label="Triệu chứng:" rules={[{ required: true }]}>
                            <Autocomplete
                                width='100%'
                                ref={((c) => this.autocomplete = c)}
                                dataSource={this.state.lstSymptom}
                                handleSearch={this.loadDiagnostic.bind(this)}
                                getItem={(key, value) => { this.setState({ symptom: key, symptomName: value }) }}
                                title="triệu chứng"
                            />
                        </Form.Item>
                    </div>
                    <div style={{ "flex": 1 }} >
                        <Form.Item label="Chẩn đoán:" name='diagnostic' rules={[{ required: true, message: 'Vui lòng nhập chẩn đoán' }]}>
                            <Autocomplete
                                ref={((c) => this.autocomplete = c)}
                                dataSource={this.state.lstDiagnostic}
                                width='100%'
                                handleSearch={this.loadDiagnostic.bind(this)}
                                getItem={(key, value) => { this.setState({ diagnostic: key, diagnosticName: value }); this.form.setFieldsValue({ diagnostic: key }) }}
                                title="chẩn đoán"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{ display: "flex", marginBottom: '8px' }}>
                    <div style={{ "flex": 1 }} >
                        <Form.Item label="Ghi chú:">
                            <TextArea rows={4} onChange={(e) => { this.setState({ note: e.target.value }) }} />
                        </Form.Item>
                    </div>
                    <div style={{ "flex": 1 }} >
                        <Form.Item label="BS nhận bệnh:">
                            <Select
                                value={this.state.doctorReceive}
                                onChange={(value) => { this.setState({ doctorReceive: value }) }}
                                showSearch
                                style={{ width: 200 }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstDoctorReceive.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>

                </div>
            </Form>
        )
    }

    render() {

        return (
            <React.Fragment>
                <div >
                    <div className="col-lg-6 col-sm-12">
                        <Administrative
                            ref={((c) => this.administrative = c)}
                        />
                    </div>
                    <div style={{ padding: 10 }}>
                        <div className="h6 text-center" style={{ marginTop: 10, marginBottom: 30, textAlign: 'center' }}>THÔNG TIN VÀO PHÒNG LƯU</div>
                        {this.renderStorageForm()}
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <button className="btn btn-success" onClick={() => { this.resetData() }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Nhập mới</button>
                        <button className="btn btn-success" style={{ marginLeft: '5px' }} type="primary" onClick={() => { this.submit() }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Tạo mới</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
