import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Select } from 'antd';
import HeaderModal from "../../../../configureHIS/common/headerModal";
import Notify, { NotifyStatus } from '../../../../components/notify';
import * as DepartmentConfigureService from '../../../../services/departmentConfigureService';

const { Option } = Select;
export default (props) => {
    const _notiRef = useRef();
    const validator = useRef();
    // Data Source
    const [listDepartment, setListDepartment] = useState([]);
    const [listExamRoom, setListExamRoom] = useState([]);
    const [departmentId, setDepartmentId] = useState(props.departmentId ? props.departmentId : null);
    const [examRoomId, setExamRoomId] = useState(props.examRoomId ? props.examRoomId : null);
    // Props
    const [selectedExaminations, setSelectedExaminations] = useState(props.examination);
    const [examName, setExamName] = useState(props.examName);

    useEffect(()=> {
        LoadDepartment();
    }, [])

    useEffect(() => {
        props.examination && setSelectedExaminations(props.examination);
        if(props.examination && props.examination.length > 1){
            setExamRoomId(null);
            setDepartmentId(null);
        } else {
            setExamRoomId(props.examRoomId);
            setDepartmentId(props.departmentId);
        }
    }, [props])

    useEffect(() => {
        if(departmentId && departmentId !== '') LoadExamRoom(departmentId);
    }, [departmentId])

    const LoadDepartment = () => {
        DepartmentConfigureService.GetListDepartmentAsync().then(result => {
            if (result.isSuccess) {
                setListDepartment(result.data)
            } else {
                _notiRef.current.Show(result.err.msgString, NotifyStatus.Error)
            }
        }).catch(() => {
            _notiRef.current.Show("Đã có lỗi xảy ra. Vui lòng thử lại sau", NotifyStatus.Error)
        })
    }

    const LoadExamRoom = (departmentId) => {
        DepartmentConfigureService.GetListExamRoomAsync(departmentId).then(result => {
            if (result.isSuccess) {
                setListExamRoom(result.data)
            } else {
                _notiRef.current.Show(result.err.msgString, NotifyStatus.Error)
            }
        }).catch(() => {
            _notiRef.current.Show("Đã có lỗi xảy ra. Vui lòng thử lại sau", NotifyStatus.Error)
        })
    }

    const SubmitChangeRoom = () => {
        if(!examRoomId || examRoomId === ''){
            _notiRef.current.Show("Vui lòng chọn phòng.", NotifyStatus.Warning)
            return;
        }
        let listId = selectedExaminations.map(x => x.examinationId);
        let data = {
            'ExaminationIds' : listId,
            'ExamRoomCode' : examRoomId,
            'DepartmentCode' : departmentId
        }
        DepartmentConfigureService.ChangeExaminationRoom(data).then(result => {
            if (result.isSuccess) {
                _notiRef.current.Show("Chuyển phòng thành công.", NotifyStatus.Success);
                setTimeout(() => {
                    onChangeComplete();
                }, 1000)
            } else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
            }
        }).catch((err) => {
            _notiRef.current.Show(err.error.messageText, NotifyStatus.Error)
        })
    }

    const onChangeComplete = () => {
        onClose();
        props.onComplete();
    }

    const onClose = (e) => {
        _notiRef.current.Close();
        props.onClose(e);
    }

    return (
        <>
        <HeaderModal title="Đổi khoa phòng" onClose = {(e) => {onClose(e)}} />
        <Notify ref={_notiRef} />
        <div className="row">
            <div className='col-12 py-1 d-flex flex-column'>
                <label className="mita-title required-field">Tên bệnh nhân - Tên dịch vụ</label>
                <label className="mita-value">{selectedExaminations.map(x => `${x.patientName} - ${x.serviceName}`).join("; ")}</label>
            </div>
            <div className='col-12 py-1'>
                <label className="mita-title required-field">Khoa</label>
                <div>
                    <Select 
                        value={departmentId} 
                        className="w-100" 
                        placeholder="Lựa chọn khoa"
                        onChange={(e) => {
                            setDepartmentId(e);
                            setExamRoomId(null);
                        }}>
                        {listDepartment.map((row, index) => {
                            return (
                                <Option key={row.departmentCode} value={row.departmentCode}>{row.departmentName}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className='col-12 py-1'>
                <label className="mita-title required-field">Phòng</label>
                <div>
                    <Select 
                        value={examRoomId} 
                        className="w-100" 
                        placeholder="Lựa chọn phòng"
                        onChange={(e) => {
                            setExamRoomId(e);
                        }}>
                        {listExamRoom.map((row, index) => {
                            return (
                                <Option key={row.examRoomCode} value={row.examRoomCode}>{row.examRoomName}</Option>
                            )
                        })}
                    </Select>
                </div>
            </div>
            <div className='col-12 py-1 text-right'>
                <button className='btn btn-color'
                    onClick={() => { SubmitChangeRoom() }}
                ><i className="fas fa-save" style={{ marginRight: 5 }} />Lưu</button>
            </div>
        </div>
        </>
    )
};
