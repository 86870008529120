import moment from "moment";
import { connect } from 'react-redux';
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import { Table, Pagination, Select, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Action from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as ReportServices from '../../../services/ReportService';
import SiteFilter from "../../../configureHIS/common/siteFilter";
import Notify, { NotifyStatus } from '../../../components/notify';
import { isNullOrEmpty } from "../../../libs/util";

const { Option } = Select;
// const ThongKeNguoiLayMauChiTiet = forwardRef((props, ref) => {
export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getData: () => {
            getData();
        }
    }));
  //#region biến cục bộ
  const now = new Date();
  //#endregion
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [siteIds, setSiteIds] = useState(props.siteIds);
  const [dateFrom, setDateFrom] = useState(props.dateFrom);
  const [dateTo, setDateTo] = useState(props.dateTo);
  const [keyword, setKeyword] = useState(props.keyword);
  const [selectedNguoiLayMau_UserName, setSelectedNguoiLayMau_UserName] = useState(props.selectedNguoiLayMau_UserName ?? []);
  //model result
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //other
  //#endregion
  //#region useEffect
  // useEffect(() => {
  //   setSiteIds(props.siteIds);
  //   setDateFrom(props.dateFrom);
  //   setDateTo(props.dateTo);
  //   setKeyword(props.keyword);
  //   setSelectedNguoiLayMau_UserName(props.selectedNguoiLayMau_UserName);
  //   // if (props.selectedNguoiLayMau_UserName) getData();
  // }, [props]);
  
  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false;
  //     return;
  //   }
  //   getData();
  // }, [selectedNguoiLayMau_UserName]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex, sorting, pageSize]);
  //#endregion 
  
  //#region get data
  const getData = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      keyword: props.keyword,
      nguoiLayMau_UserName: props.selectedNguoiLayMau_UserName,
      
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getThongKeNguoiLayMauChiTietList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
        forceUpdate();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
      Action.setLoading(false);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
      Action.setLoading(false);
    }).finally(() => {
    })
  }
  //#endregion 

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }
  
  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  // , Tên nhân viên, tên bệnh nhân, Tên dịch vụ, Tên nhóm - loại, Thanh toán, Số phiếu, Nguồn khách hàng
  const columns= [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 45,
        render: (text, record, index) => {
          return <span>{ record.isChild ? '' : (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    {
      title: <>
        <span>Người lấy mẫu</span>
        <Tooltip title="Thông tin người lấy mẫu chỉ có từ các dữ liệu sau khi update version 2.4.4. Vì trước đó chưa ghi nhận thông tin người lấy mẫu."><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>
      </>,
      dataIndex: 'nguoiLayMau_HoTen',
      key: 'nguoiLayMau_HoTen',
      align: 'left',
      width: 170,
      render: (value, record, index) => {
        return (
            <span>{isNullOrEmpty(value) ? record.nguoiLayMau_UserName : value}</span>
        )
      }
    },
    {
        title: 'Ngày lấy mẫu',
        dataIndex: 'ngayGioLayMau',
        key: 'ngayGioLayMau',
        align: 'left',
        width: 115,
        render: (value, record, index) => {
          return (
              <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME)}</span>
          )
        }
    },
    {
      title: 'SID',
      dataIndex: 'sid',
      key: 'sid',
      align: 'left',
      width: 140,
      // sorter: () => {},
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'maBN',
      key: 'maBN',
      align: 'left',
      width: 130,
      // sorter: () => {},
    },
    {
        title: 'Tên khách hàng',
        dataIndex: 'hoTen',
        key: 'hoTen',
        align: 'left',
        // width: 120,
        // sorter: () => {},
        // render: (text, record, index) => {
        //   return <span>{ `${text}${(record.isChildInCombo ? " (combo)" : "")}` }</span>;
        // }
    },
    {
        title: 'Lấy mẫu tại nhà',
        dataIndex: 'layMauTaiNha',
        key: 'layMauTaiNha',
        align: 'center',
        width: 120,
        render: (value, record, index) => {
          return <span>{value && <i className='fas fa-check'></i>}</span>;
        }
    },
    {
        title: 'Chi nhánh',
        dataIndex: 'siteName',
        key: 'siteName',
        align: 'left',
        width: 260,
    },
  ];

  return (
    <React.Fragment>
      <div>
        <h4>Thống kê chi tiết người lấy mẫu</h4>
      </div>
      <div className="row"><div className="col-md-12"><Notify ref={notiRef} /></div></div>
      <div className="h-100 w-100 overflow-auto">
        <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
            className="table-min-h-0"
            dataSource={listResult} columns={columns}
            locale={{ emptyText: 'Không có dữ liệu' }} 
            scroll={{ x: 0, y: 0 }}
            rowKey={(e) => e.key}
            // rowSelection={{
            //   type: 'checkbox',
            //   ...rowSelection,
            // }}
            rowClassName={(record, rowIndex) => {
                // return "rowCustom"
            }}
            pagination={false}
            onChange={onTableChange} 
        />
        <Pagination
            current={pageIndex}
            size={pageSize}
            style={{ textAlignLast: "center", marginTop: "10px" }}
            total={totalRecord}
            showTotal={total => `Tổng: ${total}`}
            onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
            onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
            showSizeChanger={true}
        />
      </div>
    </React.Fragment>
  );
});
// const mapStateToProps = (state) => {
//     return ({
//         // siteid: state.global.siteid,
//         // siteCode: state.global.siteCode
//     })
// };
// export default connect(mapStateToProps)(ThongKeNguoiLayMauChiTiet)