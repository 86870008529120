import React, { Fragment, useEffect, useRef, useState, useCallback, forwardRef } from 'react';
import moment from "moment";
import { Input, Table, Modal, Button, notification, Select, InputNumber, Radio } from 'antd';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import * as SystemConfig from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import DanhSachBenhNhan from '../../patient/quanlybenhnhanmoi';
import * as PromotionVoucherService from '../../../services/PromotionVoucherServices';
import * as ChiDinhService from "../../../services/chiDinhService";
import Notify, { NotifyStatus } from '../../../components/notify';

const { Option } = Select;
const { TextArea } = Input;


export default forwardRef((props, ref) => {
// function VoucherCreate(props) {
    //#region khai báo state
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isShowPopupPatient, setIsShowPopupPatient] = useState(false);
    const [listPatients, setListPatients] = useState([]);
    const [quantityType, setQuantityType] = useState('limited');// limited or unlimited
    const [listProducts, setListProducts] = useState([]);
    //model
    const [voucherType, setVoucherType] = useState(0);
    const [voucherCode, setVoucherCode] = useState('');
    const [isSpecifiedPatient, setIsSpecifiedPatient] = useState(false);
    const [quantity, setQuantity] = useState(null);
    // const [limitNumberPerPatient, setLimitNumberPerPatient] = useState(null); // nếu theo nghiệp vụ hiện tại thì chưa dùng tới cái này
    // const [patientId, setPatientId] = useState('');// create thì chưa dùng tới cái này. mà dùng criteriaPatientIds
    const [criteriaPatientIds, setCriteriaPatientIds] = useState([]);
    const [discountProductIds, setDiscountProductIds] = useState([]);
    const [criteriaProductIds, setCriteriaProductIds] = useState([]);
    const [hasRenderProduct, setHasRenderProduct] = useState(props.promotionType == 1);
    const [isCriteriaDiscountProduct, setIsCriteriaDiscountProduct] = useState(false);
    //#endregion

    const model = {
        promotionId: props.promotionId,
        voucherCode: voucherType === 0 ? '' : voucherCode,
        isSpecifiedPatient: isSpecifiedPatient,
        quantity: quantityType === 'limited' ? quantity : 0,
        // limitNumberPerPatient: limitNumberPerPatient,// nếu theo nghiệp vụ hiện tại thì chưa dùng tới cái này
        // patientId: '',// create thì chưa dùng tới cái này. mà dùng criteriaPatientIds
        // Các điều kiện kèm theo
        criteriaProductIds: criteriaProductIds.map(x => Number(x.key)),
        criteriaPatientIds: isSpecifiedPatient ? listPatients.map(x => x.maBN) : [],
        discountProductIds: discountProductIds.map(x => Number(x.key)),
    };

    //#region useEffect
    useEffect(() => {
        // getListProducts();
    }, []);

    useEffect(() => {
        if (voucherType == 0) { // mã ngẫu nhiên
            setQuantityType('limited');
        }
        else {// mã dùng chung
            if (isSpecifiedPatient) setQuantityType('limited');
        }
        setDisable();
    }, [voucherType]);

    useEffect(() => {
        if (isSpecifiedPatient) {
            if (voucherType == 1) setQuantityType('limited');
        }
        else 
            setListPatients([]);
        setDisable();
    }, [isSpecifiedPatient]);

    useEffect(() => {
        setQuantity(listPatients.length);
    }, [listPatients]);

    useEffect(() => {
        if (quantityType == 'unlimited') 
            setQuantity(null);
        setDisable();
    }, [quantityType]);

    useEffect(() => {
        setHasRenderProduct(props.promotionType == 1);
    }, [props.promotionType]);
    //#endregion

    const reset = () => {
        setIsShowPopupPatient(false);
        setListPatients([]);
        setDisabledQuantity(false);
        setQuantityType('limited');
        setListProducts([]);
        setVoucherType(0);
        setVoucherCode('');
        setIsSpecifiedPatient(false);
        setQuantity(null);
        setCriteriaProductIds([]);
        setCriteriaPatientIds([]);
        setDiscountProductIds([]);
        notiRef && notiRef.current.Close();
        validator && validator.current.hideMessages();
    }
    
    const submitSave = () => {
        Actions.setLoading(true);
        PromotionVoucherService.create(model).then(result => {
            if (result.isSuccess) {
                reset();
                notification.success({ message: "Thành công" });
                props.onCancel(true);
            }
            else {
            //   notification.error({ message: result.error.messageText });
              notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
            }
          }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
            //   notification.error({ message: err.error.messageText });
            else notiRef.current.Show(err, NotifyStatus.Warning)
            // notification.error({ message: err });
          }).finally(() => {
            Actions.setLoading(false);
        })
    }
    /*
    1. Mã ngẫu nhiên
    a. KH xác định
    - SL checkbox: disabled - giới hạn
    - SL input: disable - theo SL KH được chọn
    b. KH KHÔNG xác định
    - SL checkbox: disabled - giới hạn
    - SL input: enable
    2. Mã dùng chung
    a. KH xác định
    - SL checkbox: disabled - giới hạn
    - SL input: disable - theo SL KH được chọn
    b. KH KHÔNG xác định
    - SL checkbox: enable
    - SL input: disable khi SL không giới hạn - enable khi SL giới hạn
    */
    const [disabledVoucherCode, setDisabledVoucherCode] = useState(false);
    const [disabledQuantityType, setDisabledQuantityType] = useState(false);
    const [disabledQuantity, setDisabledQuantity] = useState(false);
    const setDisable = () => {
        if (voucherType == 0) {
            setDisabledVoucherCode(true);
            setDisabledQuantityType(true);
        }
        else {
            setDisabledVoucherCode(false);
            if (isSpecifiedPatient)
                setDisabledQuantityType(true);
            else 
                setDisabledQuantityType(false);
        }
        // do disabled của thằng quantity Input nó chỉ phụ thuộc vào cái quantityType & isSpecifiedPatient, nên tách riêng ra đây cho dễ
        setDisabledQuantity(quantityType == 'unlimited' || isSpecifiedPatient); 
    }

    const validate = () => {
        let isValid = true;
        setIsCriteriaDiscountProduct(false);
        if (props.promotionType == 1) {
            if (discountProductIds.length == 1 && criteriaProductIds.length == 1 && discountProductIds[0]?.key == criteriaProductIds[0]?.key) {
                isValid=false;
                setIsCriteriaDiscountProduct(true);
            }
        }
        return isValid;
    }
    //#region event element
    const onSaveButtonClickHandle = () => {
        if (validator.current.allValid() && validate()) {
        // if (validate()) {
        // if (validator.current.allValid()) {
            submitSave();
        } else {
            validator.current.showMessages();
            notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
            forceUpdate();
        }
    }
    const onCloseButtonClickHandle = () => {
        reset();
        props.onCancel(false);
    }

    const onCloseListPatientPoptupHandle = (data) => {
        let arrDataToAdd = [];
        console.log(listPatients);
        data.forEach(element => {
            if (listPatients.find(p => p.maBN === element.maBN)) {
                // notification.warning({ message: element.hoTen + " đã có trong danh sách" });
            } else {
                arrDataToAdd.push(element);
            }
        });
        // setNumberOfReach(listPatients.length + arrDataToAdd.length);
        setListPatients([...listPatients, ...arrDataToAdd]);
        setQuantity(listPatients.length + arrDataToAdd.length);
        setIsShowPopupPatient(false);
    }
    //#endregion

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 30,
            render: (text, record, index) => {
                return <span>{ index + 1 }</span>;
            }
        },
        {
            title: 'Khách hàng',
            dataIndex: 'maBN',
            key: 'maBN',
            align: 'left',
            // width: 250,
            render: (value, row) => {
                return <span>{row.maBN + ' - ' + row.hoTen}</span>
            }
        },
        {
            title: 'Giới tính',
            dataIndex: 'phai',
            key: 'phai',
            align: 'left',
            width: 80,
            render: (value, row) => {
                return <span>{value == 0 ? 'nam' : 'nữ'}</span>
            }
        },
        {
            title: 'Năm sinh',
            dataIndex: 'ngaySinh',
            key: 'ngaySinh',
            align: 'left',
            width: 84,
            render: (value, row) => {
                let d = moment(value);
                return (
                    <span>{d ? d.format('yyyy') : ''}</span>
                )
            }
        },
        {
            title: '',
            dataIndex: 'options',
            key: 'id',
            align: 'center',
            width: 30,
            render: (value, record) => {
              return (
                <div>
                  <Button type="link" shape="circle" 
                    onClick={() => {setListPatients(listPatients.filter(x => x.maBN != record.maBN))}}
                  ><i className="fa fa-trash"></i></Button>
                </div>
              )
            }
        }
    ];

    //#region product/service/extensions
    const getListProducts = (value, title) => {
        ChiDinhService.searchSerivce(0, 0, '', value ?? '', 1, 2147483647).then(result => {
            setListProducts(result.data);
        }).catch(err => {
          if (err.error && err.error.messageText)
            notification.error({ message: err.error.messageText });
          else notification.error({ message: err });
        })
    }

    const generateVoucherCode = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }
    //#endregion
    return (
        <React.Fragment>
            <div className='h-100 d-flex flex-column justify-content-between' >
                <div className="modal-header d-flex justify-content-between">
                    <h3>Tạo mã </h3>
                    <h3 hidden={ !props.isDialog }
                        onClick={() => { props.onCancel(false) }}
                        style={{ cursor: 'pointer' }}
                    ><i className='far fa-times-circle' /></h3>
                </div>
                <div className='overflow-auto max-h-100 h-100 px-3 d-flex flex-column justify-content-start'>{/* body */}
                    <Notify ref={notiRef} />
                    <div className="row">{/* Cách tạo mã  */}
                        <div className="col-md-12 mt-2 d-flex flex-column">{/* Cách tạo mã  */}
                            <div>
                                <label>Cách tạo mã </label>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Select
                                    className='w-100'
                                    value={ voucherType }
                                    onChange={(e) => { setVoucherType(e); setVoucherCode(''); }}
                                    // filterOption={(input, option) => { }}
                                >
                                    <Option key={0} value={0}>Hàng loạt (Ngẫu nhiên)</Option>{/* disable nhập mã. buộc phải nhập số lượng () */}
                                    <Option key={1} value={1}>Dùng chung</Option>{/* enable nhập mã */}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="row">{/* voucherCode*/}
                        <div className="col-md-12 mt-2 d-flex flex-column">{/* voucherCode*/}
                            <div>
                                <label>Nhập mã</label>
                                <Button className="pl-0"
                                    type="link"
                                    hidden={voucherType == 0}
                                    onClick={() => {
                                        voucherType == 1 && setVoucherCode(generateVoucherCode(10));
                                    }}
                                ><i style={{ marginLeft: 5, color: 'black' }} className="fa fa-sync-alt" /></Button>
                            </div>
                            <Input 
                                value={voucherCode} 
                                onChange={(e) => { setVoucherCode(e.target.value); }}
                                placeholder={voucherType == 0 ? 'Tạo mã hàng loạt (ngẫu nhiên) thì không cần nhập trường này' : ''}
                                disabled={disabledVoucherCode}
                            >
                            </Input>
                            {voucherType == 1 && validator.current.message("Mã voucher", voucherCode, "required")}
                            {/* {validator.current.messageWhenPresent("voucherCode", "Vui lòng nhập mã voucher")} */}
                        </div>
                    </div>
                    <div className="row">{/* Xác định khách hàng */}
                        <div className="col-md-12 mt-2 d-flex flex-column">{/* Xác định khách hàng */}
                            <div>
                                <label>Xác định khách hàng </label>
                            </div>
                            <div>
                                <Select
                                    className='w-100'
                                    value={ isSpecifiedPatient }
                                    onChange={(e) => { 
                                        setIsSpecifiedPatient(e); 
                                     }}
                                >
                                    <Option key={true} value={true}>Xác định</Option>
                                    <Option key={false} value={false}>Không xác định</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="row">{/* Số lượng */}
                        <div className="col-md-12 mt-2 d-flex flex-column">{/* Số lượng */}
                            <div>
                                <label>Số lượng </label>
                                <Radio.Group className='ml-2' 
                                    disabled={disabledQuantityType}
                                    value={quantityType}
                                    onChange={(e) => { setQuantityType(e.target.value); }}
                                >
                                    <Radio value="limited">Giới hạn</Radio>
                                    <Radio value="unlimited">Không giới hạn</Radio>
                                </Radio.Group>
                            </div>
                            
                            <InputNumber
                                className='w-100 ml-1'
                                disabled={disabledQuantity}
                                value={quantity} 
                                onChange={(e) => { setQuantity(e); }}
                                placeholder=''>
                            </InputNumber>
                            {validator.current.message("Số lượng", quantity, quantityType == "limited" ? "required|numeric|min:1,num|max:1000,num" : "")}
                        </div>
                    </div>
                    <div className="row">{/* button Tìm kiếm và chọn khách hàng */}
                        <div className="col-md-12 mt-2 d-flex flex-column justify-content-center">{/* button Tìm kiếm và chọn khách hàng */}
                            <Button
                                // type="primary"
                                disabled={!isSpecifiedPatient}
                                onClick={() => setIsShowPopupPatient(true)}
                                // icon={<SearchOutlined />}
                                // size="small"
                                // style={{
                                // width: 90,
                                // }}
                            ><i className="fa fa-external-link-alt mr-1"></i>
                                Tìm kiếm và chọn khách hàng
                            </Button>
                        </div>
                    </div>
                    <div className="row">{/* Danh sách khách hàng */}
                        <div className="col-md-12 mt-2">{/* Danh sách khách hàng */}
                            <label className='border-bottom border-dark font-weight-bold'>Danh sách khách hàng</label>
                        </div>
                    </div>
                    <div className="row" >{/* table danh sách KH */}
                        <div className="col-md-12" style={{ width: '100%', maxHeight: '500px' }}>
                            <Table 
                                dataSource={listPatients} 
                                columns={columns}
                                locale={{ emptyText: 'Không có dữ liệu' }} 
                                scroll={{ x: 'max-content', y: 200}}
                                rowKey={(e) => e.maBN}
                                pagination={false}
                                // onChange={onTableChange} 
                            />
                        </div>
                    </div>
                    <div className="row" hidden={!hasRenderProduct}>{/* Đăng ký dịch vụ */}
                        <div className="col-md-12 mt-2 d-flex flex-column">
                            <div>
                                <label>Đăng ký dịch vụ</label>
                            </div>
                            <div>
                                <AutocompleteMulti
                                    // ref={((c) => this.autocomplete = c)}
                                    className="w-100"
                                    placeholder='Gõ vào để tìm kiếm'
                                    dataSource={listProducts.map(x => { return {value: x.id, label: x.ten} })}
                                    handleSearch={getListProducts.bind(this)}
                                    getItem={(items) => { setCriteriaProductIds(items) }}
                                    value={criteriaProductIds}
                                    title=''
                                />
                                <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
                                    {isCriteriaDiscountProduct ? "Đăng ký dịch vụ và Khuyến mại dịch vụ không nên trùng nhau (khi chỉ chọn 1 dịch vụ)" : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" hidden={!hasRenderProduct}>{/* Khuyến mại  dịch vụ */}
                        <div className="col-md-12 mt-2 d-flex flex-column">
                            <div>
                                <label>Khuyến mại dịch vụ</label>
                            </div>
                            <div>
                                <AutocompleteMulti
                                    // ref={((c) => this.autocomplete = c)}
                                    className="w-100"
                                    placeholder='Gõ vào để tìm kiếm'
                                    dataSource={listProducts.map(x => { return {value: x.id, label: x.ten} })}
                                    handleSearch={getListProducts.bind(this)}
                                    getItem={(items) => { setDiscountProductIds(items); }}
                                    value={discountProductIds}
                                    title=''
                                />
                                {props.promotionType == 1 && validator.current.message("Khuyến mại dịch vụ", discountProductIds, "selection")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-1">{/* footer */}
                    <div>
                        {/* <Button>
                            <i className='fa fa-copy'></i>
                            Sao chép
                        </Button> */}
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className='btn btn-color mr-2' 
                            onClick={onSaveButtonClickHandle}
                        >
                            <i className='fa fa-save mr-2'></i>
                            Lưu
                        </Button>
                        <Button
                            className='btn btn-danger'
                            onClick={onCloseButtonClickHandle}
                        >
                            <i className='fa fa-sign-out-alt mr-2'></i>
                            Đóng
                        </Button>
                    </div>
                </div>
            </div>
            <Modal visible={isShowPopupPatient} 
                destroyOnClose={true} 
                maskClosable={false} 
                onCancel={() => setIsShowPopupPatient(false)} 
                width={1200}
                style={{paddingBottom:0}}
            >
                <DanhSachBenhNhan 
                    isDlg={true}
                    mode={SystemConfig.LIST_PATIENT_MODE.READ}
                    listPatientsSelected={[...listPatients]}
                    onCancel={() => setIsShowPopupPatient(false)} 
                    onCloseListPatientPoptup={(data) => { onCloseListPatientPoptupHandle(data) }}/>
            </Modal>
        </React.Fragment>
    );
})

// export default VoucherCreate;
