import { Button, DatePicker, Form, Input, Pagination, Select, Table, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService';
import ModalResult from './modalResult'
import { FORMAT_DATE_TIME } from '../../../configure/systemConfig';
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function ListLog() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [date, setDate] = useState([moment(), moment()]);
    const [selectedId, setSelectedId] = useState('');
    const [detail, setDetail] = useState({});
    const [visable, setVisable] = useState(false);
    useEffect(() => {
        GetListPatientExam();
    }, []);


    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListPatientExam()
    }, [currentPage, size]);


    const GetListPatientExam = () => {
        Actions.setLoading(true);
        PatientService.GetListPatientExam(date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD"), key, currentPage, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }


    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'applyDate',
            key: 'applyDate',
            width: 120,
            render: (value) => {
                return (
                    <div>{moment(value).format(FORMAT_DATE_TIME)}</div>
                )
            }
        },
        {
            title: 'Mã BN',
            dataIndex: 'patientID',
            key: 'patientID',
            width: 80
        },
        {
            title: 'Tên BN',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 150
        },
        {
            title: 'Năm sinh',
            dataIndex: 'birthYear',
            key: 'birthYear',
            width: 80,
        },
        {
            title: 'Lý do',
            dataIndex: 'reason',
            key: 'reason',
            width: 80,
        },
        {
            title: 'Chẩn đoán',
            dataIndex: 'diagnosticName',
            key: 'diagnosticName',
            width: 150,
        },
        {
            title: 'Kết luận',
            dataIndex: 'conclude',
            key: 'conclude',
            width: 150,
        },
    ];
    return (
        <div className={'containerChilderWrapper'}>
            <div className="row no-gutters mb-2" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={date}
                            style={{ width: '200px' }}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(e) => { setDate(e) }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Input
                            value={key}
                            placeholder="Nhập từ khóa"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
            </div>
            <div style={{height:'calc(100% - 100px)'}}>
                <Table dataSource={dataSource}
                    scroll={{ y: '65vh', x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index}
                    onRow={(record) => {
                        return {
                            onClick: e => {
                                setSelectedId(record.managementID);
                                setDetail(record);
                                setVisable(true);
                            }
                        };
                    }}
                    rowClassName={(record) => {
                        return `rowCustomSelect ${(selectedId === record.managementID) ? 'rowCustomSelectActive' : ''}`
                    }}
                />
            </div>
            <Pagination
                current={currentPage}
                size={size}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page) }}
                onShowSizeChange={(page, size) => { setSize(size) }}
                showSizeChanger={true}
            />
            {visable &&
                <Modal
                    visible={visable}
                    onCancel={() => { setVisable(false) }}
                    width={1400}
                >
                    <ModalResult detail={detail} />
                </Modal>
            }
        </div>
    )

}