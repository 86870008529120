import { Tabs } from 'antd';
import DanhSachController from './ListController'
import ConfigMonitor from './configMonitor'
import Log from './log/index'
import React from 'react';
import SubMenu from './SubMenu'
import HiddenMenu from './HiddenMenu'

const { TabPane } = Tabs;
export default function Roles() {
    return (
        <div className='containerChilderWrapper'>
            <Tabs style={{ padding: 10, overflowY: 'auto' }}>
                <TabPane tab={"Danh sách controller"} key="1">
                    <DanhSachController />
                </TabPane>
                <TabPane tab={"controller theo màn hình"} key="2">
                    <ConfigMonitor />
                </TabPane>
                <TabPane tab={"Log"} key="3">
                    <Log />
                </TabPane>
                <TabPane tab={"Menu ẩn"} key="4">
                    <HiddenMenu />
                </TabPane>
            </Tabs>
        </div>
    )

}