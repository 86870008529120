import { Button, Modal, Pagination, Popconfirm, Input, notification, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as ConfigService from '../../../../services/configService';
import Update from './update';
import { FEATURE } from '../../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../../configure/featureConfig';

export default (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [detail, setDetail] = useState({});
    const [modalCreate, setModalCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        GetList();
    }, [])
    const GetList = () => {
        Actions.setLoading(true);
        ConfigService.GetListSite().then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const DeleteSite = (id) => {
        Actions.setLoading(true);
        ConfigService.DeleteSite(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                GetList();
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const UpdateSiteDefault = (id) => {
        Actions.setLoading(true);
        ConfigService.UpdateSiteDefault(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công' })
                GetList();
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            width: 100
        },
        {
            title: 'Code BN',
            dataIndex: 'codebn',
            key: 'codebn',
            width: 100
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: 250
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'diachi',
            key: 'diachi',
            width: 300,
            render: (value) => {
                return (
                    <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: `${value}` }}>
                    </div>
                )
            }
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 200
        },
        ...FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 ?
        [{
            title: 'Bảng giá',
            dataIndex: 'banggia',
            key: 'banggia',
            width: 100
        }] : [],
        {
            title: 'HostDMS',
            dataIndex: 'hostDMS',
            key: 'hostDMS',
            width: 120
        },
        {
            title: 'Kho bán thuốc',
            dataIndex: 'tenkhothuoc',
            key: 'tenkhothuoc',
            width: 120
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            width: 100,
            render: (value) => {
                return (
                    <div style={{ width: '100px' }}>
                        <img style={{ width: '100%' }} src={value} />
                    </div>
                )
            }
        },
        {
            title: 'Action',
            width: 150,
            render: (row) => {
                return (
                    <div className='="d-flex'>
                        <Tooltip placement="top" title="Sửa">
                            <Button className='btn btn-warning'
                                onClick={() => {
                                    setEdit(true);
                                    setDetail(row);
                                    setModalCreate(true);
                                }}
                            ><i className='fa fa-edit'></i></Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa">
                            <Popconfirm
                                title="Xác nhận xóa?"
                                onConfirm={(e) => {
                                    DeleteSite(row.id)
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">

                                <Button className='="btn btn-danger ml-2'><i className='fa fa-trash'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                        {row.default_site === false &&
                            <Tooltip placement="top" title="Chọn làm mặc định">
                                <Button className='btn btn-color ml-2'
                                    onClick={() => {
                                        UpdateSiteDefault(row.id)
                                    }}
                                ><i className='fa fa-cog'></i></Button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];
    return (
        <React.Fragment>
            <div style={{ padding: '10px', height: '100%' }}>
                {FeatureDeactive().indexOf(FEATURE.MULTI_SITE) == -1 &&
                    <div className="row justify-content-center align-content-between">
                        <div className="col-auto mb-2 ml-auto">
                            <button type="button" className="btn btn-success ml-2"
                                onClick={() => {
                                    setEdit(false);
                                    setModalCreate(true);
                                }}>
                                <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới
                            </button>
                        </div>
                    </div>
                }
                <div style={{ marginBottom: '10px', height: 'calc(100% - 75px)' }}>
                    <Table dataSource={dataSource} scroll={{ y: 0 }}
                        pagination={{
                            showTotal: total => `Tổng: ${total}`,
                            position: ["bottomCenter"],
                            style: {
                                marginBottom: 5
                            },
                            showSizeChanger: true
                        }}
                        columns={columns} rowKey={(e, index) => index}
                    />
                </div>
            </div>
            {modalCreate &&
                <Modal
                    visible={modalCreate}
                    onCancel={() => {
                        setEdit(false);
                        setModalCreate(false);
                        setDetail({});
                    }}
                    width={1000}
                >
                    <Update edit={edit}
                        detail={detail}
                        onSuccess={() => {
                            GetList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                            if (props.onSuccess)
                                props.onSuccess()
                        }}
                        onClose={() => {
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }} />
                </Modal>
            }
        </React.Fragment>
    );
}
