import React, { useState, useEffect, Fragment, useRef, useCallback } from "react";
import { Collapse, Drawer } from 'antd';
import * as Actions from '../../../../../libs/actions';
import MitaDisplayLongText from '../../../../../components/MitaComponents/mitaDisplayLongText';
import SlideShow from '../../../../pasc/result/Component/slideShow';
import Notify, { NotifyStatus } from '../../../../../components/notify';
import * as PacsService from '../../../../../services/pascService';

const { Panel } = Collapse;
const showMoreText = "...Xem thêm";
const showLessText = " Rút gọn";
export default (props) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const _notiRef = useRef();
    const [managementId, setManagementId] = useState('');
    const [appointmentId, setAppointmentId] = useState('');
    const [visibleSlideShow, setVisibleSlideShow] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        setManagementId(props.managementId);
    },[props.managementId])

    useEffect(() => {
        if(managementId != ''){
            ListPACSAppoimentDetailByManagementId(managementId)
        }
    },[managementId])

    const ListPACSAppoimentDetailByManagementId = (id) => {
        Actions.setLoading(true);
        PacsService.ListPACSAppoimentDetailByManagementId(id).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const onOpenSlideShow = (e) => {
        e.stopPropagation();
        setVisibleSlideShow(true);
    }

    const onCloseSlideShow = () => {
        setVisibleSlideShow(false);
    }

    return (
    <Fragment>
        <div style={{ overflowY: "auto" }}>
            <Notify ref={_notiRef} />
            <div className="border">
                <Collapse expandIconPosition="right">
                {dataSource && dataSource.map(item => (
                    <Panel header={<label onClick={() => forceUpdate()} className='mita-header-title d-flex justify-content-between align-items-center w-100'>
                        <span><i className="fas fa-images mr-2"/> {item.serviceName}</span>
                        <span onClick={(e) => { e.stopPropagation(); setAppointmentId(item.appointmentId) ; onOpenSlideShow(e); }}>
                            <i className="far fa-play-circle"/></span></label>} key={item.appointmentId}>
                        <div className='row'>
                            <div className='col-6 mt-2'>
                                <label className='mita-title'>Chẩn đoán ban đầu</label>
                                <div>{item.icd && item.icd.map(x => x.label).join(", ")}</div>
                            </div>
                            <div className='col-6 mt-2'>
                                <label className='mita-title'>Trạng thái</label>
                                <div>{item.isDone ? "Đã thực hiện" : "Chưa thực hiện"}</div>
                            </div>
                            <div className='col-12 mt-2'>
                                <label className='mita-title'>Mô tả</label>
                                <div className='table-collapse-pasc text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${item.description !== null ? item.description : ''}` }}></div>
                            </div>
                            <div className='col-12 mt-2 d-flex flex-column'>
                                <label className='mita-title'>Kết luận</label>
                                <label className='mita-value' dangerouslySetInnerHTML={{ __html: `${item.result !== null ? item.result : ''}` }}></label>
                                
                                {/* Ẩn do thằng này ko re-render đc
                                <MitaDisplayLongText
                                    content={item.result}
                                    maxLength={100}
                                    showMoreText={showMoreText}
                                    showLessText={showLessText}
                                    displayMode="inner"
                                ></MitaDisplayLongText> */}
                            </div>
                            <div className='col-12 mt-2 d-flex flex-column'>
                                <label className='mita-title'>Đề nghị</label>
                                <label className='mita-value' style={{ whiteSpace: "pre-wrap", height:"fit-content" }}>{item.advice}</label>
                                {/* <MitaDisplayLongText
                                    content={item.advice}
                                    maxLength={100}
                                    showMoreText={showMoreText}
                                    showLessText={showLessText}
                                    displayMode="inner"
                                ></MitaDisplayLongText> */}
                            </div>
                        </div>
                    </Panel>
                ))}
                </Collapse>
            </div>
        </div>
        <Drawer
            className='custom-ant-drawer'
            maskClosable={false}
            mask={true}
            closable={false}
            width={"50%"}
            placement="right"
            visible={visibleSlideShow}
            destroyOnClose={true}
            onCancel={() => { onCloseSlideShow() }}>
            <SlideShow
                componentKey="CDHA-Multimedia"
                refId={appointmentId}
                onCancel={onCloseSlideShow}
            ></SlideShow>
        </Drawer>
    </Fragment>
    )
};