/*
Component này là thông tin Kết quả xét nghiệm. 
Right panel theo màn hình 6/10 của mockup "HIS-phan he xet nghiem.pdf": thông tin phía trên (Chẩn đoán ban đầu, trạng thái)
*/
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ExamResultService from '../../../services/ExamResultServices';
import { connect } from 'react-redux';
import { examResultStatusEnum } from "../examResultStatusEnum";

const TestResultBegin = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [chanDoanBanDau, setChanDoanBanDau] = useState();
  const [status, setStatus] = useState();
  const [statusText, setStatusText] = useState();
  const [tgTraKq, setTgTraKq] = useState();
  const [statusTextShow, setStatusTextShow] = useState();
  //#region useEffect
  useEffect(() => {
    getById(props.sid);
  }, []);
  //#endregion 
  const setData = (data) => {
    setChanDoanBanDau(data.chanDoanBanDau);
    setStatus(data.status);
    setStatusText(data.statusText);
    setTgTraKq(data.tgTraKq);
    let t='';
    if (data.status === examResultStatusEnum.processing) {
      switch (true) {
        case (data.configTuongTacVoiLab && data.sidSentLabStatus == 0):
          t = ' - Chưa gửi xuống lab ';
          break;
        // case (data.status === examResultStatusEnum.hadResult):
        //   t = ' '
        //   break;
        case (data.tgTraKq != undefined && data.tgTraKq != null):
          t = ' - ' + SystemConfig.COUNTDOWN1(data.tgTraKq, 'Đến giờ trả ');
          break;
        default: 
          t = <Tooltip title="Bạn chưa cấu hình thời gian trả kết quả dự kiến! Vui lòng truy cập: Tiện ích > Cấu hình viện phí > Danh mục loại xét nghiệm "><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>;
          break;
      }
    }
    setStatusTextShow(<label>{data.statusText}{t}</label>);
  }

  const getById = (sid) => {
    ExamResultService.getExamResultInfo(sid).then(result => {
      if (result.isSuccess) {
        notiRef && notiRef.current.Close();
        setData(result.data);
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  return (
    <React.Fragment>
      <div className="row">{/* notification */}
        <div className="col-md-12"><Notify ref={notiRef} /></div>
      </div>
      <div className="row mt-2">{/* Chẩn đoán ban đầu | Trạng thái */}
        <div className="col-md-6 d-flex flex-column">
          <label className="mita-title">Chẩn đoán ban đầu</label>
          <label className="mita-value">{chanDoanBanDau}</label>
        </div>
        <div className="col-md-6 d-flex flex-column">
          <label className="mita-title">Trạng thái</label>
          <label className="mita-value">{statusTextShow}</label>
        </div>
      </div>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TestResultBegin);