import React, { Component, Fragment } from 'react'
import { DatePicker, Input, Pagination, Form, Select, Table, Modal, notification, Button, Checkbox } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as KhoService from '../../services/KhoService';
import DateMedicine from '../medicine/components/datemedicine';
import moment from 'moment';
import * as Actions from '../../libs/actions';
import * as StatisticService from '../../services/statisticService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import HeaderModal from '../../configureHIS/common/headerModal';
import { BUTTON } from '../../configureHIS/common/constants';
import MinRequirement from '../medicine/components/minRequirement';
const { Option } = Select;
const { RangePicker } = DatePicker;
const initialState = {
    id: 0,
    key: '',
    matat: '',
    nhom: 0,
    nhomcc: 0,
    makho: '',
    sotk: '',
    masothue: '',
    diachi: '',
    sodienthoai: '',
    ngay: moment(),
    totalRecords: 0,
    ngaysx: moment(),
    ngayhh: moment(),
    detail: {},
    ngayRange: [moment(), moment()],
}
export default class QuanLyKhoThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKho: [],
            lstTonKho: [],
            visibleCreate: false,
            visibleUpdate: false,
            disibleLuu: true,
            hideUpdate: false,
            visibleTonKho: false,
            visibleItem: false,
            currentPage: 1,
            tenloai: '',
            size: 10,
            tonkho: '',
            notZero: true,
            visibleMinRequired: false,
        };
    }
    componentWillMount() {
        this.loadKho();
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    loadKho() {
        KhoService.GetListKho("502").then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value,
                    tenloai: result.data[0].label

                });
                this.ListThuoc();
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }

    showModalTonKho = () => {
        this.setState({
            visibleTonKho: true,
        });
    };
    showModalDate = () => {
        this.setState({
            visibleItem: true,
        });
    };
    handleCancel = e => {
        this.setState({
            visibleCreate: false,
            visibleUpdate: false,
            visibleTonKho: false,
            visibleItem: false
        });
    };
    ListThuoc(page = this.state.currentPage, size = this.state.size) {
        if (this.state.ngay === null || this.state.ngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        Actions.setLoading(true);
        KhoService.SearchTonKhoThang(this.state.ngayRange[0].format('YYYY-MM-DD'), this.state.ngayRange[1].format('YYYY-MM-DD'), this.state.makho, this.state.tonkho, this.state.key, page, size, this.state.notZero).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        lstTonKho: result.data,
                        totalRecords: result.totalRecords
                    })
                }
                else {
                    this.setState({
                        lstTonKho: [],
                        totalRecords: 0
                    })
                }
            }
            else {
                this.setState({
                    lstTonKho: [],
                    totalRecords: 0
                })
            }
            this.setState({ currentPage: page })

        }).catch(err => {
            alert(err)
        }).finally(() => Actions.setLoading(false))
    }
    ExportThongKe() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeQuayThuoc(this.state.ngayRange[0].format('YYYY-MM-DD'), this.state.ngayRange[1].format('YYYY-MM-DD'), this.state.makho.toString(), this.state.tonkho, this.state.notZero)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeThuocTrongVatTu";
                    this._hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    UpdateDate() {
        if (this.state.ngaysx.year() > 1 && this.state.ngayhh.year() > 1 && (this.state.ngaysx) > (this.state.ngayhh)) {
            notification.error({ message: 'Ngày hết hạn phải đứng sau ngày sản xuất!' });
            return;
        }
        Actions.setLoading(true);
        KhoService.UpdateDateStorage(this.state.detail.mabd, this.state.detail.losx, this.state.detail.makho, this.state.ngayhh.format('YYYY-MM-DD'), this.state.ngaysx.format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.ListThuoc();
                this.setState({ visible: false })
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                Actions.openMessageDialog(result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog(err);
            Actions.setLoading(false);
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 100,
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
                width: 150,
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
                width: 120,
            },
            {
                title: 'Hợp chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                align: 'left',
                width: 120,
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 90,
            }
            ,
            {
                title: 'Lô',
                dataIndex: 'losx',
                key: 'losx',
                align: 'left',
                width: 180,
            },
            {
                title: 'Tồn đầu',
                dataIndex: 'tondau',
                key: 'tondau',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'SL nhập',
                dataIndex: 'slnhap',
                key: 'slnhap',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'SL xuất',
                dataIndex: 'slxuat',
                key: 'slxuat',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Tồn kho',
                dataIndex: 'slton',
                key: 'slton',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày SX',
                dataIndex: 'ngsx',
                key: 'ngsx',
                align: 'left',
                render: (value) => {
                    if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                        return <span>Không xác định</span>
                    }
                    else {
                        return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                    }
                }
            },
            {
                title: 'Hết hạn',
                dataIndex: 'nghh',
                key: 'nghh',
                align: 'left',
                render: (value) => {
                    if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                        return <span>Không xác định</span>
                    }
                    else {
                        return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                    }
                }
            },
            {
                title: 'Giá nhập',
                dataIndex: 'gianhap',
                key: 'gianhap',
                align: 'right',
                width: 110,
                render: (value) => {
                    return value.toLocaleString('en-US');
                }
            },
            {
                title: '',
                width: 70,
                align: "center",
                render: (row) => {
                    return <Button className={BUTTON.THEME} onClick={() => {

                        this.setState({ detail: row, visible: true, ngaysx: moment(row.ngsx), ngayhh: moment(row.nghh) })
                    }}><i className="fa fa-edit"></i></Button>
                }
            },

        ];
        let dataSource1 = this.state.lstTonKho.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <Fragment>
                <div className='containerChilderWrapper'>
                    <HeaderModal title="Tồn kho" />
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className="row" style={{ justifyContent: 'center', alignItems: "center" }}>
                        <div className="col-sm-3 mt-2">
                            <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                allowClear={false} format="DD-MM-YYYY" value={this.state.ngayRange}
                                onChange={(value => this.setState({ ngayRange: value }, () => this.ListThuoc()))}
                                placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']} className="w-100"
                            />
                        </div>
                        <div className="col-sm-3 mt-2">
                            <Select className="w-100" showSearch
                                onChange={(value, label) => { this.setState({ makho: value, tenloai: label.children }, () => { this.ListThuoc() }) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstKho.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-sm-3 mt-2">
                            <Input
                                style={{ height: 32 }}
                                value={this.state.key}
                                onChange={(e) => this.setState({ key: e.target.value, currentPage: 1 })}
                                placeholder="Nhập mã hoặc tên"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.ListThuoc();
                                    }
                                }}
                            />
                        </div>
                        <div className="col-sm-3 mt-2">
                            <Checkbox checked={this.state.notZero} onChange={() => this.setState({ notZero: !this.state.notZero })}>Tồn kho khác 0</Checkbox>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-auto mr-auto'>
                            <button className={`${BUTTON.THEME} mt-2`}
                                onClick={() => this.showModalDate()}>Thuốc sắp hết hạn</button>
                            <button className={`${BUTTON.THEME} mt-2 ml-2`}
                                onClick={() => this.setState({ visibleMinRequired: true })}>Thuốc chạm mức tồn tối thiểu</button>
                        </div>
                        <div className='col-auto'>
                            <button className={`${BUTTON.THEME} mt-2`} onClick={() => this.ListThuoc()}><i style={{ marginRight: 5 }} className="fa fa-search"></i>Tìm</button>
                            <button className={`${BUTTON.THEME} mt-2 ml-2`}
                                onClick={(e) => { this.ExportThongKe() }} >
                                <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất Excel
                            </button>
                        </div>
                    </div>

                    <div className="mt-2" style={{ width: '100%', height: 'calc(100% - 190px)' }}>
                        <Table dataSource={dataSource1}
                            pagination={false}
                            scroll={{ x: 'max-content', y: 0 }}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    {(dataSource1 ?? []).length > 0 && <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ size: size }, () => this.ListThuoc(page, size)) }}
                        onShowSizeChange={(page, size) => this.ListThuoc(1, size)}
                        showSizeChanger={true}
                    />}
                </div>
                <Modal maskClosable={false}
                    width={1500}
                    open={this.state.visibleItem}
                    onCancel={this.handleCancel}

                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <DateMedicine onCancel={() => { this.handleCancel() }} />
                </Modal>
                <Modal maskClosable={true}
                    width={1500}
                    open={this.state.visibleMinRequired}
                    onCancel={() => this.setState({ visibleMinRequired: false })}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <MinRequirement onCancel={() => { this.setState({ visibleMinRequired: false }) }} />
                </Modal>
                {
                    this.state.visible && <Modal maskClosable={false}
                        open={this.state.visible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <HeaderModal title="Cập nhật thông tin thuốc" onClose={() => this.setState({ visible: false })} />
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <Form.Item label="Ngày sản xuất">
                                    <Checkbox style={{ display: 'none' }} onChange={(e) => { this.setState({ enbSX: e.target.checked }) }} checked={this.state.ngaysx.year()}>Ngày sản xuất</Checkbox>
                                    <DatePicker
                                        disabledDate={(date) => moment(date) < moment().subtract(5, "year")}
                                        locale={viVN} allowClear={false} onChange={(e) => this.setState({ ngaysx: e })} format="DD/MM/YYYY" value={this.state.ngaysx.year() === 1 ? null : this.state.ngaysx} />
                                </Form.Item>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Checkbox style={{ display: 'none' }} onChange={(e) => { this.setState({ enbHH: e.target.checked }) }} checked={this.state.ngayhh.year() !== 1}>Ngày hết hạn</Checkbox>
                                <Form.Item label="Ngày hết hạn">
                                    <DatePicker locale={viVN} allowClear={false} onChange={(e) => this.setState({ ngayhh: e })} format="DD/MM/YYYY" value={this.state.ngayhh.year() === 1 ? null : this.state.ngayhh} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='custom-hr'></div>
                        <div style={{ textAlign: 'center' }}>
                            <button className={BUTTON.THEME} style={{ marginRight: 5 }} onClick={(e) => this.UpdateDate()} ><i className="fa fa-save" style={{ marginRight: 10 }}></i>Lưu</button>
                            <button className={BUTTON.DANGER} onClick={(e) => this.setState({ visible: false })}><i className="fas fa-sign-out-alt" style={{ marginRight: 10 }}></i>Đóng</button>
                        </div>
                    </Modal>
                }
            </Fragment >
        )
    }
}
