import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, InputNumber, Button, Radio, Select, Tag, Switch, Popover, Tooltip, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ThirdPartyConfigService from '../../../services/thirdPartyConfigService';
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { TextArea } = Input;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [selectedListServices, setSelectedListServices] = useState();
  //validate
  //result model
  const [id, setId] = useState(props.id);
  const [description, setDescription] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [emailHost, setEmailHost] = useState();
  const [emailPort, setEmailPort] = useState();
  const [emailEnableSSL, setEmailEnableSSL] = useState(true);
  const [emailFrom, setEmailFrom] = useState();
  const [emailUser, setEmailUser] = useState();
  const [emailPassword, setEmailPassword] = useState();
  //#endregion 
  //gửi test
  const [emailTo, setEmailTo] = useState();
  const [emailContent, setEmailContent] = useState();
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    getById(props.id);
  }, [props.id]);

  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
      if (props.detailScreenMode == detailScreenModeEnum.create)
        setHeaderText('Tạo mới');
  }, [props.detailScreenMode]);

  useEffect(() => {
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setDescription(data.description);
    setIsDefault(data.isDefault);

    setEmailHost(data.config.emailHost);
    setEmailPort(data.config.emailPort);
    setEmailEnableSSL(data.config.emailEnableSSL);
    setEmailFrom(data.config.emailFrom);
    setEmailUser(data.config.emailUser);
    setEmailPassword(data.config.emailPassword);
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - ' + data.config.emailUser);
    else if (props.detailScreenMode == detailScreenModeEnum.update)
      setHeaderText('Cập nhật - ' + data.config.emailUser);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    ThirdPartyConfigService.emailGetById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const model = {
    id : null,
    description: '',
    isDefault : '',
    config: {
      emailHost : '',
      emailPort : '',
      emailEnableSSL : '',
      emailFrom : '',
      emailUser : '',
      emailPassword : '',
    }
  };

  const setModel = () => {
    model.id = id;
    model.description = description;
    model.isDefault = isDefault;
    model.config={
      emailHost : emailHost,
      emailPort : emailPort.toString(),
      emailEnableSSL : emailEnableSSL,
      emailFrom : emailFrom,
      emailUser : emailUser,
      emailPassword : emailPassword,
    };
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.emailCreate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.emailUpdate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const validate = () => {
    let isValid = true;
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && id) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const sendEmailTest = () => {
    if (!emailTo || emailTo.length == 0 || !emailContent || emailContent.length == 0 ) {
      notification.error({ message: "Phải nhập Email nhận và Nội dung" });
      return;
    }
    Actions.setLoading(true);
    let data = {id: id, emailTo: emailTo, emailContent: emailContent};
    ThirdPartyConfigService.sendEmailTest(data).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mô tả / Mặc định*/}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mô tả</label>
                  </div>
                  <TextArea
                    value={description}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextArea>
                  {validator.current.message("Mô tả", description, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label className="mita-title">Mặc định</label>
                  <div>
                    <Tooltip title={'Chỉ 1 tài khoản được bật mặc định. Nếu bạn bật mặc định cho tài khoản này thì các tài khoản khác sẽ bị tắt.'}>
                      <Switch 
                        checked={isDefault} 
                        onChange={(checked, e) => { 
                          if (detailScreenMode == detailScreenModeEnum.detail) {
                            props.onIsDefaultClick(id);
                          }
                          setIsDefault(checked); 
                        }} 
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Host | Port | emailEnableSSL */}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Host</label>
                  </div>
                  <Input
                    value={emailHost}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setEmailHost(e.target.value)}
                  ></Input>
                  {validator.current.message("Host", emailHost, "required")}
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Port</label>
                  </div>
                  <InputNumber
                    // className="ant-input-number-text-right"
                    // addonBefore="đ"
                    // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    min={0}
                    max={99999}
                    // step='1'
                    value={ emailPort }
                    onChange={(value) => {setEmailPort(value)}}
                  />
                  {validator.current.message("Port", emailPort, "required|numeric|min:0,num|max:1000000000000,num")}
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <label className="mita-title">SSL</label>
                  <div>
                    <Switch 
                      // disabled={detailScreenMode==detailScreenModeEnum.detail || hasAccount}
                      checked={emailEnableSSL} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setEmailEnableSSL(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* EmailFrom | EmailUser | Password */}
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className="mita-title">Email gửi</label>
                    <label className='required-field' hidden={detailScreenMode==detailScreenModeEnum.detail}>*</label>
                  </div>
                  <Input
                    value={emailFrom}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setEmailFrom(e.target.value)}
                  ></Input>
                  {validator.current.message("Email gửi", emailFrom, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Email user</label>
                  </div>
                  <Input
                    value={emailUser}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setEmailUser(e.target.value)}
                  ></Input>
                  {validator.current.message("Email user", emailUser, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mật khẩu</label>
                  </div>
                  <Input.Password
                    // placeholder="input password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    value={emailPassword}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setEmailPassword(e.target.value)}
                  />
                  {/* <Input
                    value={emailPassword}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setEmailPassword(e.target.value)}
                  ></Input> */}
                  {validator.current.message("Mật khẩu", emailPassword, "required")}
                </div>
              </div>
              <div hidden={detailScreenMode!=detailScreenModeEnum.detail}>
              <div className="row mt-2 border border-top"> {/* */}
                <div className="col-md-12">
                  <label className="mita-title">Gửi email test</label>
                </div>
              </div>
              <div className="row mt-2"> {/* EmailTo | emailContent */}
                <div className="col-md-5 d-flex flex-column">
                  <div>
                    <label className="mita-title">Email nhận</label>
                  </div>
                  <Input
                    value={emailTo}
                    readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                    onChange={(e) => setEmailTo(e.target.value)}
                  ></Input>
                </div>
                <div className="col-md-5 d-flex flex-column">
                  <div>
                    <label className="mita-title">Nội dung</label>
                  </div>
                  <Input
                    value={emailContent}
                    readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                    onChange={(e) => setEmailContent(e.target.value)}
                  ></Input>
                </div>
                <div className="col-md-2 d-flex flex-column justify-content-end">
                  <button 
                    className='btn btn-color mr-2' 
                    onClick={sendEmailTest}
                  >
                    <i className='fas fa-paper-plane  mr-2'></i>
                    Gửi
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})