import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle  } from 'react';
import { Spin } from 'antd';
import * as Actions from '../../../../libs/actions';
import * as ReportServices from '../../../../services/ReportService';

// const { Option } = Select;
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      getData();
    },
  }));
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [loading, setLoading] = useState(false);
  //search/filter
  const [totalOrder, setTotalOrder] = useState();
  const [totalPatient, setTotalPatient] = useState();
  const [totalNewPatient, setTotalNewPatient] = useState();
  const [totalOldPatient, setTotalOldPatient] = useState();
  const [averageRevenueNewPatient, setAverageRevenueNewPatient] = useState();
  const [averageRevenueOldPatient, setAverageRevenueOldPatient] = useState();
  //other
  //#endregion
  
  //#region useEffect
  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      paymentTypeIds: props.paymentTypeIds,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }
    ReportServices.getAverageRevenueSumary(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalOrder(result.data.totalOrder);
        setTotalPatient(result.data.totalPatient);
        setTotalNewPatient(result.data.totalNewPatient);
        setTotalOldPatient(result.data.totalOldPatient);
        setAverageRevenueNewPatient(result.data.averageRevenueNewPatient);
        setAverageRevenueOldPatient(result.data.averageRevenueOldPatient);
        // notiRef && notiRef.current.Close();
      }
      else {
        // notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      // if (err.error && err.error.messageText)
      //   notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      // else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
    })
  }

  //#endregion 
  //#region table info & event
  //#endregion 
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <div className="list-page" >
          <div className="row">
            <div className="col-md-12 p-0 d-flex justify-content-between">
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2" style={{ backgroundColor:"#2b78e4", color:"white" }}>
                <span style={{ fontSize: 20 }}>Trung bình đơn khách hàng mới</span>
                <div className='d-flex' style={{ fontSize: 30 }}>
                  <div><u>đ</u></div>
                  <div className="ml-3" style={{ flex: 1,}}>{isNaN(averageRevenueNewPatient) ? '' : Number(averageRevenueNewPatient).toLocaleString('en-US')}</div>
                </div>
              </div>
              <div style={{width: "10%" }}></div>
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2" style={{ backgroundColor:"#009E0F", color:"white" }}>
                <span style={{ fontSize: 20 }}>Trung bình đơn khách hàng tái khám</span>
                <div className='d-flex' style={{ fontSize: 30 }}>
                  <div><u>đ</u></div>
                  <div className="ml-3" style={{ flex: 1,}}>{isNaN(averageRevenueOldPatient) ? '' : Number(averageRevenueOldPatient).toLocaleString('en-US')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 p-0 d-flex justify-content-between">
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2 px-4" style={{ backgroundColor:"#CCCCCC", fontSize: 20 }}>
                <div className="w-100 d-flex justify-content-between">
                  <span>Tổng số phiếu</span>
                  <div>{Number(totalOrder).toLocaleString('en-US')}</div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <span>Tổng số khách hàng</span>
                  <div>{Number(totalPatient).toLocaleString('en-US')}</div>
                </div>
              </div>
              <div style={{width: "10%" }}></div>
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2 px-4" style={{ backgroundColor:"#CCCCCC", fontSize: 20 }}>
                <div className="w-100 d-flex justify-content-between">
                  <span>Khách hàng mới</span>
                  <div>{Number(totalNewPatient).toLocaleString('en-US')}</div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <span>Khách hàng tái khám</span>
                  <div>{Number(totalOldPatient).toLocaleString('en-US')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
})