import { Button, Form, Input, Pagination, DatePicker, Table, Select } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import * as Actions from '../../../../libs/actions';
import * as MedicineService from '../../../../services/medicineService';
import * as StatisticService from '../../../../services/statisticService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as SytemConfig from "../../../../configure/systemConfig";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default function ListController() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [status, setStatus] = useState(-1);
    const [date, setDate] = useState([moment(), moment()]);
    const _hiddenLink = useRef()

    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        ThongKeVatTuTieuHao()
    }, [currentPage, size]);


    const ThongKeVatTuTieuHao = () => {
        Actions.setLoading(true);
        MedicineService.ThongKeVatTuTieuHao(date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD"), key, currentPage, size, status).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    
    const customExpandIcon = (props) => {
        if (props.expanded) {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-minus fa-1x" /></button>
        }
        else {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-plus fa-1x" /></button>
        }
    }

    function ExportThongKeVattuTieuHao() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeVattuTieuHao(date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD"), key, status)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeVattuTieuHao";
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    const columns = [
        {
            title: 'Ngày Chỉ định',
            dataIndex: 'ngay',
            key: 'ngay',
            width: 120,
            render: (value) => (moment(value).format(SytemConfig.FORMAT_DATE_TIME)),
        },
        {
            title: 'Mã BN',
            dataIndex: 'mabn',
            key: 'mabn',
            width: 100
        },
        {
            title: 'Tên BN',
            dataIndex: 'tenbn',
            key: 'tenbn',
            width: 180
        },
        {
            title: 'Mã VP',
            dataIndex: 'mavp',
            key: 'mavp',
            width: 180
        },
        {
            title: 'Tên VP',
            dataIndex: 'tenvp',
            key: 'tenvp',
            width: 180,
        }
    ];
    const columnsChild = [
        {
            title: 'Ngày tạo',
            dataIndex: 'ngay',
            key: 'ngay',
            width: 120,
            render: (value) => (moment(value).format(SytemConfig.FORMAT_DATE)),
        },
        {
            title: 'Mã Thuốc/Vật tư',
            dataIndex: 'ma',
            key: 'ma',
            width: 180
        },
        {
            title: 'Tên Thuốc/Vật tư',
            dataIndex: 'tenthuoc',
            key: 'tenthuoc',
            width: 180,
        },
        {
            title: 'Số lượng',
            dataIndex: 'soluong',
            key: 'soluong',
            width: 100,
            render: (value) => (value.toLocaleString('en-US')),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'tinhphi',
            key: 'tinhphi',
            width: 100,
            render: (value) => (value === true ? 'Tính phí' : 'Hao phí'),
        },
        {
            title: 'Tên kho',
            dataIndex: 'tenkho',
            key: 'tenkho',
            width: 120,
        },
    ]
    return (
        <div className='containerChilderWrapper'>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
            <div className='h4 text-center mb-2 font-bold text-uppercase'>THỐNG KÊ VẬT TƯ TIÊU HAO</div>
            <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item>
                        <Select
                            allowClear={false}
                            value={status}
                            onChange={e => { setStatus(e) }}
                        >
                            <Option key={0} value={-1}>Trạng thái</Option>
                            <Option key={1} value={1}>Tính phí</Option>
                            <Option key={2} value={0}>Hao phí</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={date}
                            style={{ width: '200px' }}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(e) => { setDate(e) }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item label="Tìm theo tên:">
                        <Input
                            value={key}
                            placeholder="Nhập từ khóa"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                        setCurrentPage(1);
                                    }, 100);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => { ExportThongKeVattuTieuHao() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </Form.Item>
                </div>
            </div>
            <div style={{ height: 'calc(100% - 110px)' }}>
                <Table dataSource={dataSource}
                    scroll={{ y: '65vh', x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index}
                    expandRowByClick={true}
                    // expandedRowKeys={this.state.expandableRow}
                    expandIcon={(props => customExpandIcon(props))}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div className='expandBorder' style={{ marginTop: "-7px", marginBottom: '-7px', marginLeft: 35, fontStyle: 'italic' }}>
                                <Table
                                    onRow={(row, rowIndex) => {
                                        return {
                                            onClick: e => { },
                                        };
                                    }}
                                    style={{ marginLeft: '7px' }} showHeader={true} columns={columnsChild} dataSource={record.lstAddThuoc} pagination={false} rowKey={(row, index) => index} />
                            </div>
                        ), rowExpandable: record => record.lstAddThuoc.length > 0,
                    }}
                />
            </div>
            <Pagination
                current={currentPage}
                size={size}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page) }}
                onShowSizeChange={(page, size) => { setSize(size) }}
                showSizeChanger={true}
            />
        </div>
    )

}