import { DatePicker, Input, notification } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify'
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as InvoiceServices from '../../../services/invoiceService';

const { TextArea } = Input;

export default forwardRef((props, ref) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const [datecreate, setDatecreate] = useState(moment())
    const [billSelected, setBillSelected] = useState('');
    const [billNoSelected, setBillNoSelected] = useState('');
    const [thamChieu, setThamChieu] = useState('');
    const [ghichu, setGhichu] = useState('');
    const [, updateState] = React.useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [dateRange, setDateRange] = useState(props.dateRange);

    useEffect(() => {
        if(props.billSelected && props.billSelected !== null && props.billSelected !== '') {
            setBillSelected(props.billSelected.toString())
        } else {
            setBillSelected('')
        }
        if(props.billNoSelected && props.billNoSelected !== null && props.billNoSelected !== '') {
            setBillNoSelected(props.billNoSelected.toString())
        } else {
            setBillNoSelected('')
        }
        setDatecreate(moment());
        setDateRange(props.dateRange);
    },[props])

    const resetState = () => {
        setBillSelected('')
        setThamChieu('')
        setGhichu('')
        notiRef.current.Close()
    }

    const Submit = () => {
        if (validator.current.allValid()) {
            setDisableBtn(true);
            Actions.setLoading(true);
            const data = {
                billNumberList: billSelected,
                dateRefund: datecreate,
                infomation: ghichu,
                dateFrom: dateRange[0],
                dateTo: dateRange[1],
            }
            InvoiceServices.HoanTraCongNo(data).then(res => {
                if (res.isSuccess) {
                    // if (notiRef && notiRef.current) 
                    //     notiRef.current.Show("Hoàn trả công nợ thành công.", NotifyStatus.Success)
                    let dataReturn = res.returnObject;
                    let mes = renderMessage(dataReturn['DanhSachHoanTra'],dataReturn['DanhSachyeuCauHoanTra']);
                    Actions.openMessageDialog("Thông báo", mes);
                    notification.success({message:'Thành công'});
                    // if (notiRef && notiRef.current) 
                    //     notiRef.current.Show(mes, NotifyStatus.Success)

                    setTimeout(() => {
                        if (props.onSubmit)
                            props.onSubmit();
                        setDisableBtn(false)
                        resetState();
                    }, 1000);
                    
                    Actions.setLoading(false);
                } else {
                    notification.error({message:'Hoàn trả không thành công'});
                    notiRef.current.Show(res.err.msgString ?? res.error.messageText, NotifyStatus.Error)
                    setDisableBtn(false);
                    Actions.setLoading(false);
                }
            }).catch((err) => {
                setDisableBtn(false);
                Actions.setLoading(false);
            })
        }else {
            validator.current.showMessages();
            forceUpdate();
        }
    }
    const renderMessage = (listSuccess, listYCHT) => {
        let res = '';
        if ((listSuccess ?? []).length > 0){
            res+='<b>Các phiếu thanh toán được hoàn trả thành công:</b><br>'
                +listSuccess.map(x=>{return (`<span key={record}>- ${x}</span>`)});
        }
        if ((listYCHT ?? []).length > 0){
            res+=((listSuccess ?? []).length > 0 ? '<br>' : '')+'<b>Các phiếu thanh toán qua ngày được tạo phiếu yêu cầu hoàn trả:</b><br>'
                +listYCHT.map(x=>{return (`<span>- ${x}</span>`)}).join('<br>');
        }
        return res;
        // return (
        // <>
        //     {((listSuccess ?? []).length > 0) ?? <div>
        //         <b>Các phiếu thanh toán được hoàn trả thành công:</b>
        //         <ul>
        //             {listSuccess.map((record, index) => {
        //                 return (
        //                     <li key={record}>{record}</li>
        //                 )
        //             })}
        //         </ul>
        //     </div>}
        //     {((listYCHT ?? []).length > 0) ?? <div>
        //         <b>Các phiếu thanh toán qua ngày được tạo phiếu yêu cầu hoàn trả:</b>
        //         <ul>
        //             {listYCHT.map((record, index) => {
        //                 return (
        //                     <li key={record}>{record}</li>
        //                 )
        //             })}
        //         </ul>
        //     </div>}
        // </>);
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='h3'>Hoàn trả công nợ</div>
                <h3 onClick={() =>{
                    props.onCancel()
                    resetState()
                }}>
                <i className='far fa-times-circle' /></h3>
            </div>
            <Notify ref={notiRef} />
            <div className='py-1'>
                <label>Mã phiếu chọn</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} disabled={true}
                    value={billNoSelected}
                />
            </div>
            <div className='py-1'>
                <label>Ngày giờ hoàn trả</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <DatePicker locale={viVN} format="DD-MM-YYYY HH:mm" style={{ width: '100%' }}
                    showTime
                    disabledDate={disabledDate}
                    onChange={(e) => setDatecreate(e)}
                    allowClear={false}
                    value={datecreate} />
            </div>
            <div className='py-1'>
                <label>Ghi chú</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} value={ghichu} onChange={e => setGhichu(e.target.value)} />
                {validator.current.message("Ghi chú", ghichu, "required")}
            </div>
            <div className='py-1 text-right'>
                <button className='btn btn-color'
                    onClick={() => {
                        Submit()
                    }}
                    disabled={disableBtn}
                ><i className="fas fa-save" style={{ marginRight: 5 }} />Xác nhận</button>
            </div>
        </>
    )
});
