export let BASE_URL = window.publicConfig.url;
export let BASE_NOTIFY_URL = window.publicConfig.urlNotify;
export let BASE_URL_WORLDWFONE = window.publicConfig.urlWorldfone;
export let BASE_URL_WORLDWFONE_LOCAL = window.publicConfig.urlWorldfonelocal;
export let SECRETKEY_WORLDWFONE = window.publicConfig.secretWorldfone;
export let VERSION = window.publicConfig.version;
export let KHAMBENH_NAME = window.publicConfig.khambenh;
export let CDHA_NAME = window.publicConfig.cdha;
export let HIS_HOTLINE = window.publicConfig.hotlineHIS;
export let API_TIME = window.publicConfig.API_TIME;
export let MAX_TIME_DIFFERENCE_IN_MIN = window.publicConfig.API_TIME_MAX_DIFFERENCE;
export let EMAILJS_KEY = window.publicConfig.EMAILJS_KEY;
export let EMAILJS_SERVICEID = window.publicConfig.EMAILJS_SERVICEID;
export let EMAILJS_TEMPLATEID = window.publicConfig.EMAILJS_TEMPLATEID;
export let GEMINI_KEY = window.publicConfig.GEMINI_KEY;