import { SyncOutlined } from "@ant-design/icons";
import { Button, Input, notification, Radio, Select, DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import QRCode from "qrcode.react";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import React, { useEffect, useState } from "react";
import {
    LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha
} from "react-simple-captcha";
import "./khaibao.css";
import * as AdministrativeService from '../../services/administrativeService';
import * as KhaibaoService from '../../services/khaibaoService';
import * as SystemConfig from "../../configure/systemConfig";

const { Option } = Select;
function removeAccents(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

    return str;
}

const DoiTuong =
    [
        {
            "patientStatusID": 0,
            "patientStatusCode": null,
            "patientStatusName": "Người lành",
            "levelObject": 0
        },
        {
            "patientStatusID": 1,
            "patientStatusCode": "Test N",
            "patientStatusName": "Sàng lọc test nhanh (+)",
            "levelObject": 0
        },
        {
            "patientStatusID": 2,
            "patientStatusCode": "NV",
            "patientStatusName": "Nhân viên y tế",
            "levelObject": 0
        },
        {
            "patientStatusID": 3,
            "patientStatusCode": "BN",
            "patientStatusName": "Bệnh nhân CSYT",
            "levelObject": 0
        },
        {
            "patientStatusID": 4,
            "patientStatusCode": "CL-NC",
            "patientStatusName": "Cách ly (nhập cảnh)",
            "levelObject": 0
        },
        {
            "patientStatusID": 5,
            "patientStatusCode": "nghi",
            "patientStatusName": "Ca nghi",
            "levelObject": 0
        },
        {
            "patientStatusID": 6,
            "patientStatusCode": "TXCÐ",
            "patientStatusName": "Tiếp xúc cộng đồng",
            "levelObject": 0
        },
        {
            "patientStatusID": 7,
            "patientStatusCode": "Ðon K",
            "patientStatusName": "Đơn khẩn",
            "levelObject": 0
        },
        {
            "patientStatusID": 8,
            "patientStatusCode": "Ðon T",
            "patientStatusName": "Đơn thường",
            "levelObject": 0
        },
        {
            "patientStatusID": 9,
            "patientStatusCode": "Gop K",
            "patientStatusName": "Gộp khẩn",
            "levelObject": 0
        },
        {
            "patientStatusID": 10,
            "patientStatusCode": "Gop T",
            "patientStatusName": "Gộp thường",
            "levelObject": 0
        },
        {
            "patientStatusID": 11,
            "patientStatusCode": "F1CÐ",
            "patientStatusName": "F1 Cộng đồng",
            "levelObject": 0
        },
        {
            "patientStatusID": 12,
            "patientStatusCode": "F1V",
            "patientStatusName": "F1 vào",
            "levelObject": 0
        },
        {
            "patientStatusID": 13,
            "patientStatusCode": "F1R",
            "patientStatusName": "F1 ra",
            "levelObject": 0
        },
        {
            "patientStatusID": 14,
            "patientStatusCode": "F1CL",
            "patientStatusName": "F1 KCL",
            "levelObject": 0
        },
        {
            "patientStatusID": 15,
            "patientStatusCode": "F1X",
            "patientStatusName": "F1 xa",
            "levelObject": 0
        },
        {
            "patientStatusID": 16,
            "patientStatusCode": "SLCC",
            "patientStatusName": "Sàng lọc cộng đồng",
            "levelObject": 0
        },
        {
            "patientStatusID": 17,
            "patientStatusCode": "CNKCN",
            "patientStatusName": "Công nhân KCN",
            "levelObject": 0
        },
        {
            "patientStatusID": 18,
            "patientStatusCode": "CN",
            "patientStatusName": "Công nhân",
            "levelObject": 0
        },
        {
            "patientStatusID": 19,
            "patientStatusCode": "KPT",
            "patientStatusName": "Khu phong tỏa",
            "levelObject": 0
        },
        {
            "patientStatusID": 20,
            "patientStatusCode": "F0",
            "patientStatusName": "F0",
            "levelObject": 0
        },
        {
            "patientStatusID": 21,
            "patientStatusCode": "F0-BHYT",
            "patientStatusName": "F0-BHYT",
            "levelObject": 0
        }
    ]
function KhaiBaoYTe() {
    const [qrtext, setQrtext] = useState('');
    const [patient, setPatient] = useState({});
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [gender, setGender] = useState(0);
    const [dob, setDob] = useState(moment());
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [ward, setWard] = useState('');
    const [address, setAddress] = useState('');
    const [subject, setSubject] = useState('');
    const [listSubject, setListSubject] = useState(DoiTuong);
    const [datein, setDatein] = useState(null);
    const [place, setPlace] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [isloading, setIsloading] = useState(false);
    const [numErr, setNumErr] = useState(0);
    const [listCity, setListCity] = useState([]);
    const [listDistrict, setListDistrict] = useState([]);
    const [listWard, setListWard] = useState([]);
    const [cardid, setCardid] = useState("");
    const [checkCaptcha, setCheckCaptcha] = useState(false);

    useEffect(() => {
        LoadCapcha();
        loadCity();
    }, [])

    const loadCity = () => {
        AdministrativeService.GetAllCity().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setListCity(result.data);
                setCity(101);
                setWard(null);
                setListWard([]);
                setDistrict(null);
                setListDistrict([]);
            }
        }).catch(err => {
            console.log(err);
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }

    useEffect(() => {
        loadDistrict(city)
    }, [city])

    const loadDistrict = (id) => {
        AdministrativeService.GetDistrictByCityId(id).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setListDistrict(result.data);
            }
            else {
                setListDistrict([]);
            }
            setListWard([]);
            setDistrict(null);
            setWard(null);
        }).catch(err => {
            console.log(err);
            notification.warning({ message: 'Tải dữ quận huyện thị bị lỗi' })
        })
    }

    useEffect(() => {
        loadWard(district);
    }, [district])

    const loadWard = (id) => {
        AdministrativeService.GetWardByDistrictId(id).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setWard(null);
                setListWard(result.data);
            }
            else {
                setWard(null);
                setListWard([]);
            }
        }).catch(err => {
            console.log(err);
            notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }

    const LoadCapcha = () => {
        if (qrtext === '')
            loadCaptchaEnginge(4);
    };
    const Submit = () => {
        let errCount = 0;
        if (name.trim() === "") {
            errCount++;
        }
        if (validateCaptcha(captcha) === false) {
            setCheckCaptcha(true);
            loadCaptchaEnginge(4);
            errCount++;
        }
        if (address.trim() === "") {
            errCount++;
        }
        if (phone.trim() === "") {
            errCount++;
        }
        if (subject.toString().trim() === "") {
            errCount++;
        }

        setNumErr(errCount);
        if (errCount === 0) {
            const dt = DoiTuong.find(x => x.patientStatusID === subject);
            const obCity = listCity.find(x => x.value === city);
            const obDistrict = listDistrict.find(x => x.value === district);
            const obWard = listWard.find(x => x.value === ward);
            let data = {
                name: name,
                dob: moment(dob).format("YYYY-MM-DD"),
                address: address + `${obWard ? ', ' + obWard.label : ''}` + `${obDistrict ? ', ' + obDistrict.label : ''}` + `${obCity ? ', ' + obCity.label : ''}`,
                city: city ? city.toString() : '',
                district: district ? district.toString() : '',
                ward: ward ? ward.toString() : ward,
                cmnd: cardid,
                phone: phone,
                noicachly: place,
                doituongkhaibao: dt ? dt.patientStatusName : '',
                gender: gender
            };
            if (datein === null || moment(datein).year() === 1) {
                data.ngaycachly = null;
            } else {
                data.ngaycachly = moment(datein).format("YYYY-MM-DD");
            }
         
            setIsloading(true);
            KhaibaoService.SaveKhaiBaoYTe(data)
                .then((result) => {
                    if (result.isSuccess) {
                        setPatient(data);
                        setQrtext(result.returnObject);
                        notification.success({ message: "Gửi thành công!" });
                    } else {
                        notification.success({
                            message: "Lỗi: " + result.err.msgString,
                        });
                    }
                    setIsloading(false);
                })
                .catch((err) => {
                    notification.warning({ message: err });
                    setIsloading(false);
                });
        } else {
            notification.error({ message: "Vui lòng kiểm tra lại dữ liệu!" });
        }
    };
    return (
        <React.Fragment>
            <div className="khaibaoyte">
                {/* <div className="bg-header text-center h6 font-weight-bold">
                    <img
                        className="khaibao-image"
                        alt="logo"
                        src={configApi.URL_LOGO}
                    />
                </div> */}
                <div className="khaibao-container">
                    <div>
                        <div className="text-center font-weight-bold">
                            TỜ KHAI Y TẾ TRƯỚC KHI LẤY MẪU SARS-CoV-2<br />
                            <i>MEDICAL DECLARATION BEFORE SAMPLE COLLECTION SARS-CoV-2</i>
                        </div>
                        <div className="text-danger text-center font-weight-bold mb-2 fontsize12">
                            Khuyến cáo: Khai báo thông tin sai là vi phạm pháp luật Việt Nam và có thể bị xử lý hình sự<br />
                            <i>Warning: Declaring false information is a violation of Vietnamese law and may result in criminal prosecution</i>
                        </div>
                    </div>
                    {qrtext !== "" ?
                        <React.Fragment>
                            <div className="text-center">
                                <QRCode className="submit-qrcode" value={qrtext} />
                            </div>
                            <div>
                                Họ tên/<i>Name</i>: <strong>{patient.name}</strong>{" "}
                            </div>
                            <div>
                                Giới tính/<i>Sex</i>: <strong>{patient.gender === 1 ? 'Nữ' : 'Nam'}</strong>
                            </div>
                            <div>
                                Ngày sinh/<i>Date Of Birth</i>: <strong>{moment(patient.dob).format(SystemConfig.FORMAT_DATE)}</strong>
                            </div>
                            <div>
                                Địa chỉ/<i>Address</i>:{" "}
                                <strong>{`${patient.address}`}</strong>
                            </div>
                            <div>
                                Điện thoại/<i>Telephone</i>: <strong>{patient.phone}</strong>
                            </div>
                            <div>
                                CMND hoặc CCCD/<i>Proof of identity or Citizen identification</i>: <strong>{patient.cmnd}</strong>
                            </div>
                            <div>
                                Ngày vào khu cách ly/<i>Date of entering the isolation area</i>:{" "}
                                <strong>{patient.ngaycachly}</strong>
                            </div>
                            <div>
                                Nơi cách ly/<i>Place of isolation</i>: <strong>{patient.noicachly}</strong>
                            </div>
                            <div>
                                Đối tượng/<i>Object</i>: <strong>{patient.doituongkhaibao}</strong>
                            </div>

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="row no-gutters">
                                <div className="col-12 font-weight-bold info-header h6">
                                    Thông tin cá nhân/<i>Personal information</i>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label className="font-weight-bold">
                                    Điện thoại/<i>Telephone</i> <span className="start-important">*</span>
                                </label>
                                <Input
                                    type="tel"
                                    value={phone}
                                    className="form-control"
                                    maxLength={11}
                                    onChange={(e) => {
                                        if (isNaN(e.target.value)) {
                                            return;
                                        }
                                        setPhone(e.target.value);
                                    }}
                                />
                                {numErr > 0 &&
                                    (phone.trim() === "") && (
                                        <small className="text-danger">
                                            Vui lòng nhập số điện thoại !/<i>Please enter the phone number !</i>
                                        </small>
                                    )}
                            </div>
                            <div className="max-width-khaibao">
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Họ tên/<i>Full name</i>{" "}
                                        <span className="start-important">*</span>
                                    </label>
                                    <Input
                                        type="text"
                                        value={name}
                                        className="form-control "
                                        placeholder="NGUYỄN VĂN A"
                                        maxLength={200}
                                        onChange={(e) => {
                                            setName(e.target.value.toLocaleUpperCase());
                                        }}
                                    />
                                    {numErr > 0 && name.trim() === "" && (
                                        <small className="text-danger">
                                            Vui lòng nhập họ tên !/<i>Please enter your name</i>
                                        </small>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Giới tính/<i>Sex</i> <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Radio.Group
                                        onChange={(e) => {
                                            setGender(e.target.value);
                                        }}
                                        value={gender}
                                    >
                                        <Radio value={0}>Nam/<i>Male</i></Radio>
                                        <Radio value={1}>Nữ/<i>Female</i></Radio>
                                    </Radio.Group>
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Ngày tháng năm sinh/<i>Date of birth</i>{" "}
                                        <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        className="w-100 border"
                                        format="DD/MM/YYYY"
                                        value={dob}
                                        onChange={(e) => {
                                            setDob(e);
                                        }}
                                    />
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12 font-weight-bold info-header h6">
                                        Địa chỉ liên lạc/<i>Contact address</i>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Tỉnh,Thành phố/<i>Province,City</i> <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Select
                                        className="w-100"
                                        allowClear={false}
                                        showSearch
                                        value={city}
                                        onChange={(e) => {
                                            setCity(e);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {listCity.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>
                                                    {row.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Quận,huyện/<i>District</i> <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Select
                                        className="w-100"
                                        allowClear={false}
                                        showSearch
                                        value={district}
                                        onChange={(e) => {
                                            setDistrict(e);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {listDistrict.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>
                                                    {row.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Phường,xã/<i>Wards</i> <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Select
                                        className="w-100"
                                        allowClear={false}
                                        showSearch
                                        value={ward}
                                        onChange={(e) => {
                                            setWard(e);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {listWard.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>
                                                    {row.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Địa chỉ/<i>Address</i>{" "}
                                        <span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Input
                                        type="text"
                                        value={address}
                                        className="form-control"
                                        maxLength={200}
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                    />
                                    {numErr > 0 && address.trim() === "" && (
                                        <small className="text-danger">
                                            Vui lòng nhập địa chỉ !/<i>Please enter your address !</i>
                                        </small>
                                    )}
                                </div>

                                <div className="mb-2">
                                    <label className="font-weight-bold">CMND hoặc CCCD/<i>Proof of identity or Citizen identification</i></label>
                                    <Input
                                        type="text"
                                        value={cardid}
                                        maxLength={20}
                                        className="form-control"
                                        onChange={(e) => {
                                            setCardid(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Đối tượng/<i>Object</i><span className="start-important">*</span>
                                    </label>
                                    <br />
                                    <Select
                                        className="w-100"
                                        allowClear={false}
                                        showSearch
                                        // value={subject}
                                        onChange={(e) => {
                                            setSubject(e);
                                        }}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {listSubject.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.patientStatusID}>
                                                    {row.patientStatusName}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {numErr > 0 && subject.toString().trim() === "" && (
                                        <small className="text-danger">
                                            Vui lòng chọn đối tượng !/<i>Please select an object !</i>
                                        </small>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Ngày vào khu cách ly hoặc khu điều trị/<i>Date of entering the isolation or treatment area</i>
                                    </label>
                                    {/* <DatePicker className="w-100" format="D/M/YYYY" allowClear={true} locale={viVN}
                                                  value={datein}
                                                  onChange={(e) => { setDatein(e) }}
                                             /> */}
                                    <DatePicker
                                        className="w-100 border"
                                        locale={viVN}
                                        dateFormat="dd/MM/yyyy"
                                        placeholder="Chọn ngày vào khu cách ly"
                                        selected={datein}
                                        onChange={(e) => {
                                            setDatein(e);
                                        }}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="font-weight-bold">
                                        Nơi cách ly,khu điều trị/<i>Isolation place,treatment area</i>
                                    </label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={place}
                                        maxLength={200}
                                        onChange={(e) => {
                                            setPlace(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="mb-2">
                                    <label className="font-weight-bold">Mã xác nhận/<i>Verification</i></label>
                                    <div
                                        className="text-center d-flex"
                                        style={{ placeContent: "center" }}
                                    >
                                        <LoadCanvasTemplateNoReload />
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                color: "blue",
                                                fontSize: "15px",
                                                paddingBottom: "10px",
                                            }}
                                            onClick={() => LoadCapcha()}
                                        >
                                            <SyncOutlined />
                                        </div>
                                    </div>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={captcha}
                                        maxLength={6}
                                        placeholder="Nhập mã xác nhận/Enter the confirmation code"
                                        onChange={(e) => {
                                            setCaptcha(e.target.value);
                                        }}
                                    />
                                    {numErr > 0 && checkCaptcha === true && (
                                        <small className="text-danger">
                                            Mã xác nhận không đúng !/<i>Incorrect code !</i>
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="text-justify text-danger my-2">
                                Dữ liệu bạn cung cấp hoàn toàn bảo mật và chỉ phục vụ cho việc phòng chống dịch và điều tra dịch tễ, thuộc quản lý của ban chỉ đạo quốc gia về Phòng chống dịch Covid-19. Khi bạn nhấn gửi là bạn đã hiểu vào đồng ý/
                                <i>The data you provide is completely confidential and is only used for epidemic prevention and epidemiological investigation, under the management of the national steering committee on Covid-19 prevention and control. When you click submit, you understand and agree</i>
                            </div>
                            <div className="text-center mb-3">
                                <Button
                                    type="primary"
                                    className="btn btn-primary w-40"
                                    loading={isloading}
                                    onClick={(e) => {
                                        Submit();
                                    }}
                                >
                                    Gửi tờ khai/<i>Submit declaration</i>
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default KhaiBaoYTe;
