import { Input, Tooltip, notification, Select, Checkbox } from 'antd';
import React, { Fragment, useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import * as KhoService from '../../../../services/KhoService';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import { DanhSachNhanVien } from '../../../../services/employeeService';
import * as XuatKhoService from '../../../../services/xuatkhoService';
import Autocomplete from '../../../../components/autocomplete';
import EditableTable from '../../../../commons/editableTable';
import { ValidationCustom } from '../../../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as PrintService from '../../../../services/printService';
import * as Actions from '../../../../libs/actions';
import * as NhapKhoService from '../../../../services/medicineService';
import result from '../../../lis/result';
import * as SystemConfig from "../../../../configure/systemConfig";
import Notify, { NotifyStatus } from '../../../../components/notify';

const { Option } = Select;
const { TextArea } = Input;

export default forwardRef((props,ref) => {
    const [inEditMode, setInEditMode] = useState(false);
    const [inViewMode, setInViewMode] = useState(false);
    const [nguoiHoantra,] = useState(localStorage.getItem("name"));
    const [ngayHoantra, setNgayHoantra] = useState(new Date());
    const [kho, setKho] = useState(null);
    const [listHoantra, setListHoantra] = useState([]);
    const [IDNguoiNhan, setIDNguoiNhan] = useState(null);
    const [tenNguoiNhan, setTenNguoiNhan] = useState(null);
    const [noiNhan, setNoiNhan] = useState(null);
    const [lyDoHoantra, setLyDoHoantra] = useState([]);
    const [listThuocVatTu, setListThuocVatTu] = useState([]);
    const [IDPhieuHoantra, setIDPhieuHoantra] = useState(null);
    const [idPhieuNhap, setIdPhieuNhap] = useState(null);
    const [soPhieu, setSoPhieu] = useState(null);
    const [sohoadon, setSohoadon] = useState(null);
    const [lstNhapKho, setLstNhapKho] = useState([]);

    const ThongTinNguoiNhan = useRef();
    const tableListHoantra = useRef();
    const _thuocAC = useRef();

    const notificationFirst = useRef();
    const notificationSecond = useRef();


    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        setData: (data, inViewMode) => {
            setData(data, inViewMode);
        }
    }));

    const columnsTableListHoantra = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: 60,
            render: (text, record, index) => (Number(index) + 1),
        },
        ...inViewMode ? [] : [{
            title: '',
            align: 'center',
            width: 60,
            render: (item) => {
                return (
                    <Checkbox checked={item.selected} onChange={() => handleSelectItem(item)} />
                )
            }
        }],
        {
            title: 'Mã thuốc - vật tư',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
            width: 90
        },
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 180
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'center',
            width: 80
        },
        {
            title: 'Quy cách',
            dataIndex: 'quycach',
            key: 'quycach',
            align: 'left',
            width: 100,
        },
        {
            title: 'Bảo quản',
            dataIndex: 'baoquan',
            key: 'baoquan',
            align: 'left',
            width: 100,
        },
        {
            title: 'Lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 90,
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            width: 80,
            render: (value) => {
                return <span>{(value !== "" && value != null && moment(value).year() != 1) ? moment(value).format(SystemConfig.FORMAT_DATE) : ""}</span>;
            }
        },
        {
            title: 'Số lượng nhập',
            dataIndex: 'soluong',
            key: 'soluong',
            align: 'right',
            type: 'number',
            width: 100,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Số lượng xuất',
            dataIndex: 'soluongcu',
            key: 'soluongcu',
            align: 'right',
            width: 100,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Số lượng còn lại',
            align: 'right',
            width: 100,
            render: (value,record) => {
                return <span>{Number(record.soluong - record.soluongcu).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Số lượng hoàn trả',
            dataIndex: 'soluongthuc',
            key: 'soluongthuc',
            align: 'right',
            editable: !inViewMode,
            type: 'number',
            width: 100,
            insideWidth: 80,
            maxLength: 10,
            min: 1,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'ghichu',
            key: 'ghichu',
            align: 'right',
            editable: !inViewMode,
            type: 'text',
            width: 100,
            insideWidth: 80,
            render: (value) => {
                return <span>{value}</span>;
            },
        },
        
    ]

    const columnsThuocVatTu = [
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 300
        },
        {
            title: 'Mã thuốc',
            dataIndex: 'ma',
            key: 'ma',
            align: 'left',
            width: 100
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'left',
            width: 80
        },
        {
            title: 'Nước sản xuất',
            dataIndex: 'nuocsanxuat',
            key: 'nuocsanxuat',
            align: 'left',
            width: 120
        },
        {
            title: 'Mã lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 120,
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            align: 'left',
            width: 100,
            render: (value) => value ? moment(value).format(SystemConfig.FORMAT_DATE) : ""
        },
        {
            title: 'Tồn kho',
            dataIndex: 'soluongton',
            key: 'soluongton',
            align: 'left',
            width: 90,
        },
    ]

    // const loadlistKho = () => {
    //     KhoService.GetListKho("all").then(result => {
    //         if (result.isSuccess) {
    //             setListKho(result.data);
    //             setKho(result.data[0].value);
    //         }
    //     }).catch(err => {
    //         notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
    //     })
    // }

    const loadNhapKho = (key) => {
        KhoService.LstNhapKho(key).then(result => {
            if (result.isSuccess) {
                let lst = [];
                lst = result.data.map((item) => ({value : item.id, label : item.sophieu})) 
                setLstNhapKho(lst);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhập kho bị lỗi!!!!' });
        })
    }

    const setData = (data, inViewMode) => {
        setInViewMode(inViewMode);
        setSohoadon(data.hoadonnhap.sohoadon);
        setIdPhieuNhap(data.hoadonnhap.sophieu2);
        setTenNguoiNhan(data.hoadonnhap.tennguoinhan);
        setSoPhieu(data.hoadonnhap.sophieu);
        setLyDoHoantra(data.hoadonnhap.lydo);
        setIDPhieuHoantra(data.hoadonnhap.id);
        setNgayHoantra(moment(data.hoadonnhap.ngaynhap).toDate());
        setListThuocVatTu(data.lstAddThuoc);
        setKho(data.hoadonnhap.makhonhap);
        setInEditMode(true);
        tableListHoantra && tableListHoantra.current && tableListHoantra.current.setDataSource(data.lstAddThuoc);
    }

    const resetState =() => {
        setSoPhieu(null)
        setInViewMode(false);
        setInEditMode(false);
        setSohoadon(null);
        setIdPhieuNhap(null);
        setTenNguoiNhan(null);
        setSoPhieu(null);
        setLyDoHoantra(null);
        setIDPhieuHoantra(null);
        setNgayHoantra(new Date());
        setListThuocVatTu(null);
        tableListHoantra && tableListHoantra.current && tableListHoantra.current.setDataSource([]);
        notificationFirst && notificationFirst.current && notificationFirst.current.Close();
        notificationSecond && notificationSecond.current && notificationSecond.current.Close();
    }

    const getUpdate = (key) => {
        NhapKhoService.ThongTinHoaDonNhap('0001-01-01', key, true).then(result => {
            if (result.isSuccess) {
                let data = result.item
                data.idPhieu = key;
                for (let item of data.lstAddThuoc) {
                    if (item.nghh) {
                        item.nghh = moment(item.nghh).format("YYYY-MM-DD");
                    }
                    if (item.ngsx) {
                        item.ngsx = moment(item.ngsx).format("YYYY-MM-DD");
                    }
                }
                setListThuocVatTu(data.lstAddThuoc);
                setSohoadon(data.hoadonnhap.sohoadon);
                setKho(data.hoadonnhap.makho);
                let lstAddThuoc = data.lstAddThuoc.map((item)=>({...item, selected:false}));
                tableListHoantra && tableListHoantra.current && tableListHoantra.current.setDataSource(lstAddThuoc)
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }

    const handleSelectItem = (item) => {
        if (item) {
            let data = listThuocVatTu;
            let foundItemIndex = data.findIndex(x => x.id === item.id && x.stt === item.stt);
            if (foundItemIndex !== -1) {
                data[foundItemIndex].selected = !data[foundItemIndex].selected;
                handleChangeListHoantra(data);
            }
        }
    }

    const handleChangeListHoantra = (data) => {
        if (data) {
            tableListHoantra && tableListHoantra.current.setDataSource(data);
            setListThuocVatTu(data);
        }
    }


    const saveOrUpdatePhieuHoantra = () => {
        if (validator.current.allValid()) {
            notificationFirst && notificationFirst.current && notificationFirst.current.Close();
            if (listThuocVatTu.length === 0) {
                notificationSecond.current.Show("Không có thuốc - vật tư trong danh sách cần hoàn trả", NotifyStatus.Error);
                return;
            }
            Actions.setLoading(true);
            const hoadonnhap = {
                'id' : IDPhieuHoantra,
                'sophieu' : soPhieu,
                'sophieu2': idPhieuNhap,
                'lydo': lyDoHoantra,
                'ngaynhap': moment(ngayHoantra).format("YYYY-MM-DD"),
                'sohoadon': sohoadon,
                'tennguoinhan': tenNguoiNhan,
                'makhonhap' : kho,
            }
            for (let record of listThuocVatTu) {
                if (record.nghh === "") record.nghh = null
            }
            const data = {
                hoadonnhap,
                lstAddThuoc: listThuocVatTu,
            }
            if (!inEditMode) {
                KhoService.Hoantra(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công' });
                        notificationSecond.current.Show("Lưu thành công", NotifyStatus.Success);
                        resetState();
                        props.onCancel();
                    }
                    else {
                        //Actions.openMessageDialog("Lỗi", result.err.msgString);
                        notificationSecond.current.Show(result.err.msgString, NotifyStatus.Error);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", "Lưu dữ liệu bị lỗi")
                    notificationSecond.current.Show("Lưu dữ liệu thất bại", Notify.Error);
                    Actions.setLoading(false);
                })
            } else {
                KhoService.editHoantra(IDPhieuHoantra, data).then(result => {
                    if (result.isSuccess) {
                        setIDPhieuHoantra(result.id);
                        notification.success({ message: 'Cập nhật thành công!' });
                        notificationSecond.current.Show("Cập nhật thành công", NotifyStatus.Success);
                    }
                    else {
                        notificationSecond.current.Show(result.err.msgString, NotifyStatus.Error);
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", "Cập nhật dữ liệu bị lỗi")
                    notificationSecond.current.Show("Cập nhật dữ liệu thất bại", NotifyStatus.Error);
                    Actions.setLoading(false);
                })
            }
        } else {
            validator.current.showMessages();
            notificationFirst.current.Show("Vui lòng kiểm tra lại dữ liệu", NotifyStatus.Warning);
            forceUpdate();
        }
    }

    const deleteThuocVatTuHoantra = () => {
        let dataDelete = listThuocVatTu.filter(x => x.selected === true && x.selected !== undefined);
        if (dataDelete) {
            handleChangeListHoantra(listThuocVatTu.filter(x => x.selected === false || x.selected === undefined));
            notificationSecond.current.Show(`Đã xóa ${dataDelete.length} thuốc - vật tư`, NotifyStatus.Warning);
        }
        if (dataDelete.length === 0 || dataDelete === undefined) {
            notificationFirst.current.Show("Chưa chọn thuốc - vật tư cần xóa", NotifyStatus.Warning)
        }
    }


    const printPhieuHoantra = () => {
        if (IDPhieuHoantra) {
            PrintService.PrintPhieuHoanTra(IDPhieuHoantra, moment(ngayHoantra).format("YYYY-MM-DD")).then((result) => {
                Actions.DoPrint(result.item)
            })
        } else {
            notificationSecond.current.Show("Không có thông tin để in", NotifyStatus.Error);
        }
    }

    return (
        <Fragment>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>{!inViewMode ? (!inEditMode ? "Tạo mới" : "Cập nhập") : "Xem"} phiếu hoàn trả</h3>
                <h3 onClick={() => props.onCancel()}><i className='far fa-times-circle' /></h3>
            </div>
            <Notify ref={notificationFirst} />
            <div className='row'>
                <div className='col-md-4'>
                    <label>Mã phiếu hoàn trả</label>
                    <Input placeholder='Tạo tự động từ hệ thống' readOnly style={{ cursor: 'not-allowed' }} value={soPhieu}></Input>
                </div>
                <div className='col-md-4'>
                    <label>Người hoàn trả</label>
                    <Input placeholder='Người xuất' value={nguoiHoantra} readOnly style={{ cursor: 'not-allowed' }}></Input>
                </div>
                <div className='col-md-4'>
                    <label className='required-field'>Ngày hoàn trả </label>
                    <DateTimePicker
                        className="w-100"
                        locale='vi'
                        format={'dd-MM-yyyy'}
                        isClockOpen={false}
                        isCalendarOpen={false}
                        calendarIcon={<Tooltip title="Mở lịch"><i className='far fa-calendar-alt customclearicon' /></Tooltip>}
                        clearIcon={null}
                        onChange={(e) => setNgayHoantra(moment(e).toDate())}
                        maxDate={moment().toDate()}
                        value={ngayHoantra ? ngayHoantra : new Date()}
                        disabled={inViewMode || inEditMode}/>
                    {validator.current.message("Ngày xuất kho", ngayHoantra, "required")}
                </div>
                <div className='col-md-4'>
                    <label className='required-field'>Mã phiếu nhập kho</label>
                    <Autocomplete width={'100%'}
                        listHeight={250}
                        ref={_thuocAC}
                        value={idPhieuNhap}
                        dataSource={lstNhapKho}
                        handleSearch={loadNhapKho.bind(this)}
                        placeholder={`Nhập số phiếu nhập `}
                        getItem={(key) => {
                            setIdPhieuNhap(key)
                            getUpdate(key)
                        }}
                        title="-----------"
                        disabled={inViewMode || inEditMode} />
                    {validator.current.message("Mã phiếu nhập kho", idPhieuNhap, "required")}
                </div>
                <div className='col-md-4'>
                    <label>Hóa đơn số</label>
                    <Input placeholder='Số hóa đơn' value={sohoadon} readOnly style={{ cursor: 'not-allowed' }}></Input>
                </div>
                <div className='col-md-4'>
                    <label className='required-field'>Người nhận</label>
                    <Input value={tenNguoiNhan} onChange={(e) => setTenNguoiNhan(e.target.value)} disabled={inViewMode || inEditMode}/>
                    {validator.current.message("Tên người nhận", tenNguoiNhan, "selection")}
                </div>
                <div className='col-md-12'>
                    <label className='required-field'>Lý do hoàn trả</label>
                    <TextArea size={2} value={lyDoHoantra} onChange={(e) => setLyDoHoantra(e.target.value)} placeholder="Nhập lý do hoàn trả" disabled={inViewMode} />
                    {validator.current.message("Lý do hoàn trả", lyDoHoantra, "required")}
                </div>
            </div>
            <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                <h5>Chi tiết phiếu hoàn trả</h5>
            </div>
            <Notify ref={notificationSecond} />
            <div className='row'>
                <div className='col-md-12 mb-2 mt-2'>
                    {!inViewMode &&
                        <button
                        className="btn btn-danger"
                        onClick={() => { deleteThuocVatTuHoantra() }}>
                        <i className="far fa-trash-alt mr-2" />Xóa hạng mục
                        </button>
                    }
                </div>
                <div className="col-md-12" style={{ width: '100%' }}>
                    <EditableTable
                        ref={tableListHoantra}
                        height={"100%"}
                        scrollY={""}
                        onchange={(data) => { handleChangeListHoantra(data) }}
                        dataSource={listThuocVatTu}
                        columns={columnsTableListHoantra}
                        pagination={false}
                        rowClassName={(record) => {
                            return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                        }}
                        keyFind={'id'}
                        keyFind2={'stt'}
                    ></EditableTable>
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className='row'>
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="btn btn-color" onClick={() => { printPhieuHoantra() }}>
                        <i className="fa fa-print mr-2" />In
                    </button>
                    <div>
                        {!inViewMode && 
                            <button className="btn btn-color" onClick={() => { saveOrUpdatePhieuHoantra() }}>
                                <i className="fa fa-save mr-2" />{true ? "Lưu" : "Cập nhật"}
                            </button>
                        }
                        <button className="btn btn-danger ml-2" onClick={() => { props.onCancel(); resetState(); setInEditMode(false) }}>
                            <i className="fas fa-sign-out-alt mr-2" />Đóng
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})
