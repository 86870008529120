import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../common/headerModal';
import { DatePicker, Pagination, Table, Input, Button, Popconfirm, Alert } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import { isNullOrEmpty, timeConvert } from '../../libs/util';
import { FORMAT_TIME_DATE_FULL } from '../../configure/systemConfig';
import * as ConfigService from "../../services/configService";
import * as Actions from '../../libs/actions';
import moment from 'moment';
import UnSupport from '../../screens/unknowPage/unSupport';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const { TextArea } = Input;

const SoftwareExpiration = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const _notify = useRef();
    const [key, setKey] = useState("");
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListPartner();
    }, [page, size]);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Địa chỉ IP',
            dataIndex: 'partnerIP',
            key: 'partnerIP',
            align: 'center',
            width: 150,
        },
        {
            title: 'Tên',
            dataIndex: 'partnerName',
            key: 'partnerName',
            align: 'left',
            width: 300,
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'expired',
            key: 'expired',
            width: 250,
            render: (val, row) => {
                return (
                    <DatePicker locale={viVN} className='w-100'
                        allowClear value={val ? moment(val) : null}
                        format={FORMAT_TIME_DATE_FULL} showTime
                        onChange={(e) => {
                            onChangeInfo("exp", e, row);
                        }}
                    ></DatePicker>
                )
            }
        },
        {
            title: "Số ngày còn lại",
            key: "dateCount",
            align: 'center',
            render: (row) => {
                let days = 0;
                if (row.expired)
                    days = moment(row.expired).diff(moment(), "minutes");
                else return "";
                return days > 0 ? timeConvert(days) : <span className='text-danger'>Đã hết hạn</span>;
            }
        },
        {
            title: 'Thông tin',
            dataIndex: 'information',
            key: 'information',
            align: 'left',
            width: 400,
            render: (val, row) => {
                return (
                    <TextArea autoSize={{ minRows: 1, maxRows: 4 }} value={val}
                        onChange={(e) =>
                            onChangeInfo("infor", e.target.value, row)
                        } />
                )
            }
        },
        {
            title: "Thao tác",
            align: "center",
            width: 200,
            render: (rowData) => {
                return (
                    <Fragment>
                        <Button className={`${BUTTON.WARNING}`} onClick={() => updatePartner(rowData)}>Cập nhật</Button>
                        <Popconfirm title="Bạn có chắc chắn?" okText="Đồng ý" cancelText="Hủy bỏ" onConfirm={() => deletePartner(rowData.id)}>
                            <Button className={`${BUTTON.DANGER} ml-1`} >Xóa</Button>
                        </Popconfirm>
                    </Fragment>
                )
            }
        }
    ];

    const notAllowToDo = () => {
        return window.location.hostname.indexOf('demohis.viettinlab.com') < 0;
    }

    const getListPartner = (p = page) => {
        Actions.setLoading(true);
        _notify && _notify.current && _notify.current.Close();
        ConfigService.getListPartner(key, p, size).then(result => {
            if (result.isSuccess) {
                setListData(result.data);
                setTotalRecords(result.totalRecords);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false));
    }

    const updatePartner = (info) => {
        Actions.setLoading(true);
        const data = {
            "PartnerName": info.partnerName,
            "Expired": info.expired,
            "Information": info.information
        }
        ConfigService.UpdatePartner(data).then(result => {
            if (result.isSuccess) {
                getListPartner();
                _notify.current && _notify.current.Show("Cập nhật thành công", NotifyStatus.Success);
            } else _notify.current && _notify.current.Show("Đã xảy ra lỗi", NotifyStatus.Warning);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false));
    }

    const deletePartner = (id) => {
        Actions.setLoading(true);
        ConfigService.DeletePartner(id).then(result => {
            if (result.isSuccess) {
                getListPartner(1);
                _notify.current && _notify.current.Show("Xóa thành công", NotifyStatus.Success);
            } else
                _notify.current && _notify.current.Show("Đã xảy ra lỗi", NotifyStatus.Error);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false));
    }

    const onChangeInfo = (key, val, record) => {
        let data = [...listData];
        let indexOfRecord = data.indexOf(record);
        if (key == "exp") {
            data[indexOfRecord].expired = val;
        } else if (key == "infor") {
            data[indexOfRecord].information = val;
        }
        setListData(data);
    }

    return (
        notAllowToDo() ?
            <UnSupport />
            :
            <div className="list-page containerChilderWrapper">
                <HeaderModal title="Quản lý đối tác sử dụng phần mềm" />
                <div className="list-page-body">
                    <Alert className='mb-2' type='info' message="Có thể mất đến 6 tiếng để cấu hình mới được áp dụng tại server khách hàng"></Alert>
                    <Notify ref={_notify} />
                    <div className="list-page-search">
                        <div className='d-flex justify-content-start align-items-end'>
                            <Input style={{ width: "80%" }} value={key} onChange={(e) => setKey(e.target.value)} onPressEnter={() => getListPartner(1)} placeholder='Nhập tên cần tìm' />
                            <div className='mt-2'>
                                <button className={`${BUTTON.THEME} ml-2`} onClick={() => { getListPartner(1) }}><i className='fas fa-search mr-1' />Tìm</button>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table mt-2">
                        <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                            className="table-min-h-0"
                            dataSource={listData}
                            columns={columns}
                            locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                            scroll={{ x: 0, y: 0 }}
                            rowKey={(e) => e.id}
                            pagination={false}
                        />
                        <Pagination
                            current={page}
                            size={size}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { setPage(page); setSize(size) }}
                            onShowSizeChange={(page, size) => { setPage(1); setSize(size) }}
                            showSizeChanger={true}
                            locale={{ items_per_page: "kết quả / trang" }}
                        />
                    </div>
                </div>
            </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SoftwareExpiration)