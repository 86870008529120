import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../components/notify';
import HeaderModal from '../common/headerModal';
import { Checkbox, DatePicker, Input, InputNumber, Select, Switch } from 'antd';
import { BUTTON } from '../common/constants';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as RoleService from '../../services/roleService';
import { setLoading } from '../../libs/actions';

const CreateSysConf = forwardRef((props, ref) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const _notify = useRef();
    const [key, setKey] = useState();
    const [title, setTitle] = useState();
    const [dataType, setDataType] = useState();
    const [description, setDescription] = useState();
    const [value, setValue] = useState();
    const [code, setCode] = useState();
    const [option, setOption] = useState();
    const [featureId, setFeatureId] = useState();
    const [listConfigureTitle, setListConfigureTitle] = useState(props.listConfigureTitle ?? []);
    const [listDataType,] = useState([
        {
            value: "title",
            title: "Tiêu đề"
        },
        {
            value: "input",
            title: "Kiểu chữ"
        },
        {
            value: "inputnumber",
            title: "Kiểu số"
        },
        {
            value: "switch",
            title: "Bật/tắt"
        },
        {
            value: "select",
            title: "Lựa chọn"
        },
        {
            value: "checkbox",
            title: "Check"
        },
        {
            value: "datetime",
            title: "Ngày giờ"
        },
    ]);

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setListConfigureTitle(props.listConfigureTitle ?? []);
    }, [props.listConfigureTitle]);

    const onClose = () => {
        if (props.onClose) props.onClose();
        else showError();
    }

    const renderInputField = () => {
        switch (dataType) {
            case "inputnumber": return <InputNumber className='w-100' value={value} placeholder='Nhập giá trị' onChange={(e) => setValue(e)}></InputNumber>;
            case "datetime": return <DatePicker showTime className='w-100' locale={viVN} value={value} placeholder='Nhập giá trị' onChange={(e) => setValue(e)}></DatePicker>;
            case "switch": return <Switch checkedChildren="Bật" unCheckedChildren="Tắt" checked={value} onChange={(e) => setValue(e)}></Switch>;
            case "checkbox": return <Checkbox className='w-100' checked={value} onChange={(e) => setValue(e)}></Checkbox>;
            default: return <Input className='w-100' value={value} placeholder='Nhập giá trị' onChange={(e) => setValue(e.target.value)}></Input>;
        }
    }

    const onSave = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate();
            return;
        }
        _notify && _notify.current && _notify.current.Close();
        setLoading(true);
        const data = {
            key, value: value?.toString(), title, dataType, description, code, option, featureId
        }
        RoleService.CreateSystemConfig(data).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Tạo mới cấu hình thành công.", NotifyStatus.Success);
                resetState();
                props.onSuccess();
            } else
                _notify && _notify.current && _notify.current.Show(res.error.messageText ?? "Không thể tạo mới cấu hình.", NotifyStatus.Warning);
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText ?? "Đã xảy ra lỗi, vui lòng thử lại sau.", NotifyStatus.Error);
        }).finally(() => setLoading(false))
    }

    const resetState = () => {
        setKey();
        setTitle();
        setDataType();
        setValue();
        setCode();
        setDescription();
        setOption();
        setFeatureId();
    }

    const showError = (err = "Không tìm thấy function.") => {
        _notify && _notify.current && _notify.current.Show(err, NotifyStatus.Error);
    }

    return (
        <Fragment>
            <HeaderModal title="Tạo mới cấu hình" onClose={() => onClose()} />
            <Notify ref={_notify} />
            <div className='row'>
                <div className='col-12 mt-2'>
                    <label className='required-field'>Key</label>
                    <Input value={key} onChange={(e) => setKey(e.target.value)} placeholder="Nhập key"
                        onKeyDown={(e) => {
                            if (e.code == "Space")
                                e.preventDefault();
                        }}
                    ></Input>
                    {validator.current && validator.current.message("Key", key, "required")}
                </div>
                <div className='col-12 mt-2'>
                    <label className='required-field'>Tiêu đề</label>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Nhập tiêu đề"></Input>
                    {validator.current && validator.current.message("Tiêu đề", title, "required")}
                </div>
                <div className='col-12 mt-2'>
                    <label className='required-field'>Kiểu dữ liệu</label>
                    <Select className='w-100' value={dataType} onChange={(e) => { setDataType(e); setValue(); setCode(); }} placeholder="Chọn kiểu dữ liệu">
                        {
                            listDataType && listDataType.map((type, index) => {
                                return (
                                    <Select.Option key={index} value={type.value}>{type.title}</Select.Option>
                                )
                            })
                        }
                    </Select>
                    {validator.current && validator.current.message("Kiểu dữ liệu", dataType, "selection")}
                </div>
                {dataType != "title" && <div className='col-12 mt-2'>
                    <label>Giá trị</label><br />
                    {renderInputField()}
                </div>}
                {dataType && <div className='col-12 mt-2'>
                    {
                        dataType == "title" ?
                            <Fragment>
                                <label className='required-field'>Nhập code</label>
                                <Input value={code} onChange={(e) => setCode(e.target.value)} placeholder="Nhập code" maxLength={2} minLength={2}></Input>
                            </Fragment>
                            :
                            <Fragment>
                                <label className='required-field'>Thuộc menu</label>
                                <Select className='w-100' value={code} onChange={(e) => setCode(e)} placeholder="Chọn menu cha">
                                    {
                                        listConfigureTitle.map((cof, index) => {
                                            return <Select.Option key={index} value={cof.code}>{cof.title}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Fragment>
                    }
                    {validator.current && validator.current.message(dataType == "title" ? "Code" : "Menu cha", code, dataType == "title" ? "required|minLen:2|maxLen:2" : "selection")}
                </div>}
                <div className='col-12 mt-2'>
                    <label>Mô tả</label>
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 8 }} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Nhập mô tả"></Input.TextArea>
                </div>
                <div className='col-12 mt-2'>
                    <label>Option</label>
                    <Input value={option} onChange={(e) => setOption(e.target.value)} placeholder="Nhập key lựa chọn"></Input>
                </div>
                <div className='col-12 mt-2'>
                    <label>ID tính năng</label>
                    <Input value={featureId} onChange={(e) => setFeatureId(e.target.value)} placeholder="Nhập khóa tính năng"></Input>
                </div>
            </div>
            <div className='text-center mt-3'>
                <button className={`${BUTTON.THEME}`} onClick={() => onSave()}><i className='fas fa-save mr-1' />Lưu</button>
                <button className={`${BUTTON.DANGER} ml-2`} onClick={() => onClose()}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CreateSysConf)