import * as ApiCaller from '../libs/httpRequests';

const prefix = 'DepartmentConfig';
export function DanhSachGiaVPTheoKhoa() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsgiavienphitheokhoa?`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatVienPhiKhoa(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/capnhatvienphikhoa`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachVienPhiMacDinhTheoKhoa(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachVienPhiMacDinhTheoKhoa?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatDanhSachVienPhiMacDinhTheoKhoa(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CapNhatDanhSachVienPhiMacDinhTheoKhoa`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachSoLuongBenhNhanKhamBenh(makhoa) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachSoLuongBenhNhanKhamBenh?makhoa=${makhoa}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListDepartmentAsync() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListDepartmentAsync`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListExamRoomAsync(departmentId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListExamRoomAsync/${departmentId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ChangeExaminationRoom(data){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ChangeExaminationRoom`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
