import React, { Fragment, useEffect, useState, useImperativeHandle, forwardRef } from 'react';

export const COLOR = {
    Success: "#029e0e",
    Primary: "#2c78e4",
    Error: "#B22222",
    Warning: "#fe9a01",
    Secondary: "#696969"
}

export default forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    const [col, setCol] = useState(6);
    useImperativeHandle(ref, () => ({
    }));

    const getColor = (state) => {
        if (state) {
            return state;
        } else return "#696969"
    }
    useEffect(() => {
        let dataSource = props.data;
        if (dataSource) {
            let dlength = Number(dataSource.length);
            if (dlength % 2 == 0)
                setCol(12 / dlength)
            else if (dlength % 3 === 0)
                setCol(4)
            else setCol(6)
            setData(dataSource);
        }
    }, [props.data])
    return (
        <Fragment>
            <div className="row">
                {data && data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <div key={index} className={`col-${col} w-100 pt-3 pb-2`}>
                                <div className='d-flex justify-content-center flex-column align-items-center' style={{ padding: 10, backgroundColor: getColor(item.color), borderRadius: 10, color: 'white' }}>
                                    <h5>{item.title}</h5>
                                    <h5>{item.value}</h5>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </Fragment>
    );
})