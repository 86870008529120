import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import {List, Popover, Tag, Popconfirm, Tooltip} from 'antd';
import * as Actions from '../../libs/actions';
import { groupBy, isNullOrEmpty, isnotnull, renumber } from '../../libs/util';
import HeaderModal from '../../configureHIS/common/headerModal';
import { BUTTON, USER_AUTH } from '../../configureHIS/common/constants';
import * as SystemConfig from '../../configure/systemConfig';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import SmallShowSummaryAmount from '../invoice/components/smallshowsummaryamount';
import * as YeuCauHoanTraService from '../../services/YeuCauHoanTraService';
import { connect } from 'react-redux';

const YeuCauHoanTra = forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [id, setId] = useState();
  const [data, setData] = useState({});
  const [listServices, setListServices] = useState([]);
  const [listSupplies, setListSupplies] = useState([]);
  const [patient, setPatient] = useState();
  //#endregion 
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  const classNameListItem = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    getById(id);
  }, [id]);
  
  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useEffect(() => {
  }, []);
  //#endregion 
  //#region search & filter
  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    YeuCauHoanTraService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        setPatient(result.data?.patientInfo);
        setListServices(result.data?.listDetails.filter(x=>x.loai == 1));
        setListSupplies(result.data?.listDetails.filter(x=>x.loai != 1));
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const ychtStatusEnum = {
    new: 'new',
    confirm: 'confirm', 
    cancel: 'cancel'
  }

  const onCloseModal = () => {
    props.onCancel && props.onCancel();
    notiRef && notiRef.current && notiRef.current.Close();
  }
  
  const renderPopoverRefundOrPaid = (billNumber) => {
    return (
      <SmallShowSummaryAmount
        billNumber={billNumber}
        showBtnPrint={false}
        showBtnRefund={false}
          // btnPrintClick={() => {
          //     isRefunded ? printRefund(childInfo.refundBill, childInfo.managementId) : printReceipt(receiptInfo.receiptNumber, receiptInfo.paidDate, childInfo.managementId)
          // }}
          // btnRefundClick={(e) => {
          //     e.preventDefault(); e.stopPropagation();
          //     // setVisiblePopupSec(true); 
          //     setVisiblePopup(true);
          //     selectAllBillSer(true, receiptInfo.receiptNumber);
          // }}
      />
        // (bill == null || bill == undefined) ?
        //   <Spin tip="Đang load" size="small" /> :
    )
  }

  //#endregion 
  return (
    <div className="list-page">{/* body */}
      <HeaderModal title={`Chi tiết phiếu yêu cầu hoàn trả - ${data.soPhieu}`} onClose={() => { onCloseModal() }} />
        <Notify ref={notiRef} />
        {patient && <div className="list-page-search mb-3 border-bottom border-3">{/* Patient Information */}
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <span className="mr-2">
                        <label className="mita-title">Tên bệnh nhân: </label>
                        <label className="mita-value">{patient.hoTen}</label>
                    </span>
                    <span className="mr-2">
                        <label className="mita-title">Mã bệnh nhân: </label>
                        <label className="mita-value">{patient.maBN}</label>
                    </span>
                    <span className="mr-2">
                        <label className="mita-title">Giới tính: </label>
                        <label className="mita-value">{!isNullOrEmpty(patient.phai) && (patient.phai === 0 ? 'Nam' : 'Nữ')}</label>
                    </span>
                    <span>
                        <label className="mita-title">{patient.onlyYear ? 'Năm sinh: ' : 'Ngày sinh: '}</label>
                        <label className="mita-value">{!isNullOrEmpty(patient.onlyYear) && (patient.onlyYear ? patient.namSinh : moment(patient.ngaySinh).format('DD-MM-YYYY'))}</label>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <span>
                        <label className="mita-title">Điện thoại: </label> 
                        <label className="mita-value">{patient.dienThoai}</label>
                    </span>
                </div>
                <div className="col-8">
                    <label>
                        <span className="mita-title">Địa chỉ: </span> 
                        <span className="mita-value">{patient.soNha}</span>
                    </label>
                </div>
            </div>
        </div>}
        <div className="list-page-search">{/* data Information */}
            <div className="row">
                <div className="col-12 d-flex justify-content-between">
                    <span className="mr-2">
                        <label className="mita-title">Người yêu cầu: </label>
                        <label className="mita-value">{data?.userNameCreate}</label>
                    </span>
                    <span className="mr-2">
                        <label className="mita-title">Ngày yêu cầu: </label>
                        <label className="mita-value">{moment(data?.createAt).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                    </span>
                    <span className="mr-2">
                        <label className="mita-title">Chi nhánh: </label>
                        <label className="mita-value">{data?.siteName}&nbsp;({data?.siteCode})</label>
                    </span>
                    <span>
                        <label className="mita-title">Trạng thái: </label>
                        <label className="mita-value">
                          <Tag color={data?.status == ychtStatusEnum.new ? "cyan" : (data?.status == ychtStatusEnum.confirm ? "green" : "red")}>{data?.statusText}</Tag>
                        </label>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span>
                        <label className="mita-title">Lý do hoàn trả: </label> 
                        <label className="mita-value">{data?.ghiChu}</label>
                    </span>
                </div>
            </div>
        </div>
        <div className="list-page-body"> {/* Payment Information */}
          <div style={{ height: "100%", overflowY: "auto" }}>
            {listServices && listServices.length > 0 &&
            <div className='w-100'>
              <div className='row mt-2'>
                <div className='col-12'>
                  <h5 className='d-flex align-items-center'><i className="fas fa-medkit mr-3" style={{ fontSize: 20 }}></i><span>DỊCH VỤ</span></h5>
                </div>
              </div>
              <div className="d-flex justify-content-between w-100">
                <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto w-100"
                  bordered={false}
                  dataSource={listServices && renumber(Object.keys(groupBy(listServices, "nhomTen")).map((key) => ({ key, value: groupBy(listServices, "nhomTen")[key] })))}
                  renderItem={(record, index) => {
                    return (
                      <Fragment>
                        <div className='col-12'>
                          <div style={{ fontWeight: "bold", backgroundColor: "#D8D8D8", height: 35, display: "flex", alignItems: "center", padding: "0px 5px" }}>{record.key}</div>
                          {
                            record.value.map((r, i) => {
                              return (
                                <List.Item key={`${index}-${r.vpId}`} className={classNameListItem}>
                                  <div className='d-flex flex-column w-100'>
                                    <div className="d-flex justify-content-between w-100">
                                      <div style={{ width: "60%" }}><label>{r.vienPhiTen}</label></div>
                                      <div style={{ width: "20%" }}>
                                          <label className='mita-value'>{r.status}</label>
                                      </div>
                                      <div style={{ width: "calc(20% )" }} className='d-flex flex-row justify-content-end'>
                                        <label>
                                        {
                                            isNullOrEmpty(r.billNo_Refund) ?
                                              <Popover placement='bottomLeft'
                                                content={renderPopoverRefundOrPaid(r.soBienLai_Payment)}
                                              >
                                                <Tag className='ml-2' color={"#576cdf"}
                                                    onClick={(e) => {  }}>
                                                    {r.billNo}
                                                </Tag>
                                              </Popover>
                                              :
                                              <Popover placement='bottomLeft'
                                                content={renderPopoverRefundOrPaid(r.soBienLai_Refund)}
                                              >
                                                <Tag className='ml-2' color={"#b13431"}
                                                    onClick={(e) => {  }}>
                                                    {r.billNo_Refund}
                                                </Tag>
                                              </Popover>
                                        }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </List.Item>
                              )
                            })
                          }
                        </div>
                      </Fragment>
                    )
                  }}
                >
                </List>
              </div>
            </div>
            }
            {listSupplies && listSupplies.length > 0 &&
            <div className='w-100'>
                <div className='row mt-4'>
                  <div className='col-12'>
                      <h5 className='d-flex align-items-center'><i className="fas fa-capsules mr-3" style={{ fontSize: 20 }}></i><span>THUỐC & VẬT TƯ</span></h5>
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100">
                    <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto w-100"
                        bordered={false}
                        dataSource={listSupplies}
                        renderItem={(r, index) => {
                            let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";
                            return (
                                <Fragment>
                                    <div className='col-12'>
                                        <div className='d-flex flex-column w-100'>
                                          <List.Item key={`${index}-${r.vpId}`} className={classNameListItem}>
                                            <div className='d-flex flex-column w-100'>
                                              <div className="d-flex justify-content-between w-100">
                                                <div style={{ width: "60%" }}><label>{r.vienPhiTen}</label></div>
                                                <div style={{ width: "20%" }}>
                                                    {/* <label className='mita-value'>{'Số phiếu: '+r.idHoanTra}</label> */}
                                                    <span>Số phiếu: {r.idHoanTra}</span>
                                                </div>
                                                <div style={{ width: "calc(20% )" }} className='d-flex flex-row justify-content-end'>
                                                    <label>
                                                        <Popover placement='bottomLeft'
                                                            content={""}
                                                        >
                                                            <Tag className='ml-2' color={"#576cdf"}
                                                                onClick={(e) => {  }}>
                                                                {r.billNo}
                                                            </Tag>
                                                        </Popover>
                                                    </label>
                                                </div>
                                              </div>
                                            </div>
                                          </List.Item>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }}
                    ></List>
                </div>
              </div>
            }
            {(listServices ?? []).length == 0 && (listSupplies ?? []).length == 0 &&
            <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto w-100"
            bordered={false}
            dataSource={[]}
            >
            </List>
            }
          </div>
        </div>
        <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
          <div>
            {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND] && <>
            <Popconfirm
              title={() => {return (<span>Bạn chắc chắn hoàn trả những dịch vụ trong phiếu yêu cầu này?</span>)}}
              onConfirm={() => { props.onConfirm(data.id, data, getById); }}
              onCancel={() => {}}
              // okText="Đồng ý"
              // cancelText="Hủy"
              >{data.status == ychtStatusEnum.new &&
                <button 
                  className='btn btn-color mr-2' 
                  hidden={data.status != ychtStatusEnum.new}
                >
                  <i className='fas fa-check mr-2'></i>
                  Xác nhận
                </button>
              }
            </Popconfirm>
            <Popconfirm
              title={() => {return (<span>Bạn chắc chắn muốn hủy phiếu?</span>)}}
              onConfirm={() => { props.onDeny(data.id, data, getById); }}
              onCancel={() => {}}
              // okText="Đồng ý"
              // cancelText="Hủy"
              >{data.status == ychtStatusEnum.new &&
                <button 
                  className='btn btn-danger mr-2' 
                  hidden={data.status != ychtStatusEnum.new}
                >
                  <i className='fas fa-times mr-2'></i>
                  Hủy phiếu
                </button>
              }
            </Popconfirm>
            </>}
          {data.status == ychtStatusEnum.confirm && isnotnull(data.refundNumber) &&
            <Tooltip title="">
              <button className={BUTTON.SECONDARY} onClick={() => props.onPrintRefund && props.onPrintRefund(data.refundNumber)}>
                <i className="fas fa-print mr-1" />In phiếu hoàn trả
              </button>
            </Tooltip>}
          </div>
          <div className='d-flex justify-content-end' >
              <button
                className='btn btn-danger'
                onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
          </div>
        </div>
    </div>
  );
})

const mapStateToProps = (state) => ({
  siteid: state.global.siteid,
  PAYMENTTYPE: state.global.PAYMENTTYPE,
  userScreenAuthorization: state.global.userScreenAuthorization,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(YeuCauHoanTra);
