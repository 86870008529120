import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  EditFilled } from '@ant-design/icons';
import { Input, InputNumber, Button, Table, notification, Drawer, Popconfirm, Pagination, Dropdown, Menu, Select, Tag } from 'antd';
import * as SystemConfig from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import * as CardTypeService from '../../../services/CardTypeService';
import * as CardAccountService from '../../../services/CardAccountService';
import * as CardService from '../../../services/CardService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import { createCardModeEnum } from "../createCardModeEnum";
import CardDetail from './detail';
import CardAccountDetail from '../cardaccount/detail';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const detailRef = useRef();
  const deletePopupRef = useRef([]);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [firstRender, setFirstRender] = useState(true); 
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [cardTypeIds, setCardTypeIds] = useState([]);
  const [cardAccountIds, setCardAccountIds] = useState([]);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [selectedId, setSelectedId] = useState(); // set visible modal create/update/detail
  const [visibleCardAccountDetail, setVisibleCardAccountDetail] = useState(false); // set visible modal create/update/detail
  const [selectedCardAccountId, setSelectedCardAccountId] = useState(); // set visible modal create/update/detail
  //table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    if (firstRender) return;
    resetToFirstPage();
  }, [cardTypeIds, cardAccountIds])

  useEffect(() => {
    setFirstRender(false);
    if (props.cardAccountId) {
      setCardAccountIds([props.cardAccountId]);
    }
    else resetToFirstPage();
  }, []);
  //#endregion 
  //#region search & filter
  const search = (isResetSelectedRow = false) => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardTypeIds: cardTypeIds,
      cardAccountIds: cardAccountIds
    }
    CardService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        if (isResetSelectedRow) setSelectedRowKeys([]);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    setDetailScreenMode(detailScrMode);
    setVisibleDetail(true);
  }

  const onDeleteClickHandle = (id) => {
    Actions.setLoading(true);
    CardService.deleteById(id).then(result => {
      if (result.isSuccess) {
        search();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };
  
  const onExportListClickHandle = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: 0,
      maxResultCount: 0,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardTypeIds: cardTypeIds,
      cardAccountIds: cardAccountIds
    }
    CardService.getListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "DanhSachThe_"+moment(new Date()).format('YYMMDDhhmmss');
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  useEffect(() => {
    // console.log(`selectedRowKeys useEffect: ${selectedRowKeys}`);
  }, [selectedRowKeys])

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let newSelecteds = selectedRowKeys.filter(x => x != record.id);
      if (selected) {
        newSelecteds.push(record.id);
      }
      setSelectedRowKeys(newSelecteds);
      return true;
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      let selectedRowIds = changeRows.map(c => c.id);
      let newSelecteds = selectedRowKeys.filter(x => selectedRowIds.indexOf(x) < 0);
      if (selected) {
        newSelecteds = [...newSelecteds,...changeRows.map(x => x.id)];
        newSelecteds = Array.from(new Set(newSelecteds.map((item) => item)));
      }
      setSelectedRowKeys(newSelecteds);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) search();
  };

  //các options cho row
  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                //show modal tạo CardAccount
                loadDetail(record, detailScreenModeEnum.update);
              }}><i className='fas fa-pencil-alt mr-2'/> Chỉnh sửa</a>,
            key: '0',
          },
          {
            label: 
              <a href="#" onClick={() => {
              }}><i className='fa fa-history mr-2'/> Lịch sử áp dụng</a>,
            key: '1',
          },
          {
            label: 
              <a href="#" onClick={() => { deletePopupRef.current[record.id].click() }}
              ><i className='fas fa-trash-alt mr-2'/> Xóa số thẻ</a>
              ,
            key: 'delete',
          },
        ]}
      />
    )
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
    {
      title: 'Số thẻ',
      dataIndex: 'cardCode',
      key: 'cardCode',
      align: 'left',
      width: 200,
      sorter: () => {},
      render: (value, record) => {
        return (
          <>
            <a href="#"
              onClick={() => {
                loadDetail(record, detailScreenModeEnum.detail);
              }}
            >{value}
            </a>
            <Popconfirm
                title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{value}</b>?</span>)}}
                onConfirm={() => onDeleteClickHandle(record.id)}
                onCancel={() => {}}
                okText="Đồng ý"
                cancelText="Hủy"
              >
                <a ref={e => deletePopupRef.current[record.id] = e}></a>
              </Popconfirm>
            </>
        )
      }
  },
  {
      title: '',
      align: 'left',
      width: 25,
      render: (value, record) => {
          return (
            <Dropdown overlay={rowOptions(record)} trigger={['click']}>
              <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
            </Dropdown>
          )      
      }
  },
  {
      title: 'Số tài khoản',
      dataIndex: 'cardAccountCode',
      key: 'cardAccountCode',
      align: 'left',
      width: 200,
      sorter: () => {},
      render: (value, record) => {
        return <a href="#"
          onClick={() => {
            setSelectedCardAccountId(record.cardAccountId);
            setVisibleCardAccountDetail(true);
          }}
        >{value}</a>
      }
    },
    {
        title: 'Tên chủ tài khoản',
        dataIndex: 'accountName',
        key: 'accountName',
        align: 'left',
        // width: 180,
        sorter: () => {},
        render: (value, record) => {
            return (
              <span>{value}</span>
            )      
        }
    },
    {
        title: 'Ngày bắt đầu',
        dataIndex: 'dateActivated',
        key: 'dateActivated',
        align: 'left',
        width: 120,
        sorter: () => {},
        render: (value, record) => {
            return (
              <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>
            )      
        }
    },
    {
        title: 'Ngày kết thúc',
        dataIndex: 'dateExpired',
        key: 'dateExpired',
        align: 'left',
        width: 125,
        sorter: () => {},
        render: (value, record) => {
            return (
              <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>
            )      
        }
    },
    {
        title: 'Hoạt động',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
        width: 100,
        render: (value, record) => {
            return (
              <span>{value ? "Có": "Không" }</span>
            )
        }
    },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách mở thẻ</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                <div className="col-md-12 d-flex">
                  <Input
                    placeholder="Tìm theo số thẻ, số tài khoản, tên chủ tài khoản -> Gõ xong nhấn Enter để tìm"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          loadDetail(null, detailScreenModeEnum.create);
                        }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
                  <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  </div>
              </div>
            </div>
            <div className="list-page-table">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      {/* <Modal maskClosable={false}
          width={800}
          height="calc(100% - 10px)"
          visible={visibleDetail}
          onCancel={() => handleCancel() }
          destroyOnClose={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <CardDetail ref={detailRef}
            isDialog={ true } 
            detailScreenMode = {detailScreenMode}
            createMode = {createCardModeEnum.listCard}
            onCancel={(reload = false) => {handleCancel(reload)}}
          />
      </Modal> */}
      <Drawer className='custom-ant-drawer'
        maskClosable={true}
        closable={false}
        width={700}
        placement="right"
        mask={false}
        // onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <CardDetail ref={detailRef}
          isDialog={ true } 
          cardId={selectedId}
          detailScreenMode = {detailScreenMode}
          createMode = {createCardModeEnum.listCard}
          onCancel={(reload = false) => {handleCancel(reload)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={true}
        closable={false}
        mask={false}
        width={700}
        placement="right"
        visible={visibleCardAccountDetail}
        destroyOnClose={true}
      >
        <CardAccountDetail ref={detailRef}
          isDialog={ true } 
          cardAccountId={selectedCardAccountId}
          detailScreenMode = {detailScreenModeEnum.detail}
          onCancel={(reload) => {setVisibleCardAccountDetail(false)}}/>
      </Drawer>
    </React.Fragment>
  );
})