import { Form, Input, notification, Pagination, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as MedicineService from '../../../services/medicineService';
import * as StatisticService from '../../../services/statisticService';
import * as Actions from '../../../libs/actions';
const initialState = {
    id: 0,
    key: '',
    tenthuoc: '',
    donvi: '',
    hamluong: '',
    ngay: moment(),
    totalRecords: 0,
    giaban: 0,
    visible: false,
    visibleCreate: false,
    quycach: '',
    loai: null,
    ma: '',
    hangsx: null,
    nuocsx: null
}
export default class CapNhatThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstThuoc: [],
            lstTonKho: [],
            disableUpdate: true,
            currentPage: 1,
            pageSize: 10,
            lstDVT: [],
            lstHangSX: [],
            lstNuocSX: [],
            lstLoai: [],
            visibleLuu: true,
            disabledUpdate: true,
            hiddenUpdate: false,
            visibleUpdate: false,
            loadingHide: false,
        };
    }

    componentWillMount() {
        this.loadDanhSachThuoc();
        this.loadLoaiDMBD();
        this.loadNuocSX();
        this.loadHSX();
    }

    loadDanhSachThuoc(page = this.state.currentPage, size = this.state.pageSize) {
        MedicineService.DanhSachLoaiThuoc(this.state.key, page, size, false).then(result => {
            if (result.isSuccess && result.data.length > 0) {

                this.setState({
                    lstThuoc: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({ totalRecords: result.data[0].totalRecords })
                }
                this.setState({ currentPage: page })
            }
            else {
                this.setState({
                    lstThuoc: [],
                    totalRecords: 0,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách thuốc bị lỗi' });
        })
    }
    loadHSX(e, page = 1, size = 10) {
        MedicineService.HangSanXuat(e, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstHangSX: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu hãng sản xuất bị lỗi' })
        })
    }
    loadNuocSX() {
        MedicineService.NuocSanXuatThuoc().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNuocSX: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nước sản xuất bị lỗi' })
        })
    }
    loadDonViTinh(key) {
        MedicineService.DonViTinh(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDVT: result.data,
                });
            }
        }).catch(err => {
        })
    }
    loadLoaiDMBD() {
        MedicineService.DanhSachLoaiDMBD().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoai: result.data,
                })
            }
            else {
            }
        }).catch(err => {
        })
    }
    // async update() {
    //     let numberError = 0;
    //     await this.form.validateFields().then(values => {
    //         if (values.errorFields)
    //             numberError = values.errorFields.length
    //     }).catch((ex) => {
    //         if (ex.errorFields)
    //             numberError = ex.errorFields.length
    //     })
    //     if (this.state.giaban < 0 || this.state.giaban === undefined) {
    //         notification.warning({ message: 'Giá bán không hợp lệ!' });
    //         return;
    //     }
    //     if (numberError === 0) {
    //         const data = {
    //             ma: this.state.ma,
    //             id: Number(this.state.id),
    //             ten: this.state.tenthuoc,

    //             donvi: this.state.donvi,
    //             hamluong: this.state.hamluong,
    //             giaban: Number(this.state.giaban),
    //             hangsx: this.state.hangsx,
    //             quycach: this.state.quycach,
    //             nuocsx: this.state.nuocsx,
    //             loai: this.state.loai,
    //             idhang: Number(this.state.hangsx),
    //             nhom: 1
    //         }
    //         MedicineService.CapNhatThuoc(data).then(result => {
    //             if (result.isSuccess) {
    //                 this.setState({
    //                     visibleCreate: false,
    //                     visibleLuu: true,
    //                     disabledUpdate: true,
    //                     hiddenUpdate: false,
    //                 })

    //                 let lstThuoc = (this.state.lstThuoc)

    //                 for (let item of lstThuoc) {
    //                     if (item.id == this.state.id) {
    //                         item.ma = this.state.ma;
    //                         item.id = Number(this.state.id);
    //                         item.ten = this.state.tenthuoc;
    //                         item.tenbd = this.state.tenthuoc + ' ' + this.state.hamluong;
    //                         item.donvi = this.state.donvi;
    //                         item.hamluong = this.state.hamluong;
    //                         item.giaban = Number(this.state.giaban);
    //                         item.hangsx = this.state.hangsx;
    //                         item.quycach = this.state.quycach;
    //                         item.nuocsx = this.state.nuocsx;
    //                         item.loai = this.state.loai;
    //                         item.idhang = Number(this.state.hangsx);
    //                         item.nhom = 1;
    //                     }
    //                 }
    //                 this.setState({ lstThuoc: JSON.parse(JSON.stringify(lstThuoc)) })
    //                 this.handleCancel()
    //                 notification.success({ message: 'Cập nhật thành công!' });

    //             }
    //             else {
    //                 notification.warning({ message: result.err.msgString });
    //             }

    //             // this.loadDanhSachThuoc();
    //         }).catch(err => {
    //             notification.warning({ message: err })
    //         })
    //     } else {
    //         this.validator.showMessages();
    //     }
    // }
    showModal = () => {
        this.setState({
            visibleUpdate: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visibleUpdate: false,
            visibleCreate: false,
            visibleLuu: true,
            disabledUpdate: true,
            hiddenUpdate: false
        });
    };

    handleCancel = e => {
        this.setState({
            visibleUpdate: false,
            visibleCreate: false,
            visibleLuu: true,
            disabledUpdate: true,
            hiddenUpdate: false
        });
        // this.loadDanhSachThuoc();
    };

    setStateValues(row) {
        this.setState({
            quycach: row.quycach,
            loai: row.loai,
            ma: row.ma,
            id: row.id,
            tenthuoc: row.ten,
            donvi: row.donvi,
            hamluong: row.hamluong,
            giaban: row.giaban,
            hangsx: row.idhang,
            nuocsx: row.nuocsx,
            visibleUpdate: true
        });
        this.form.setFieldsValue({
            quycach: row.quycach,
            loai: row.loai,
            ma: row.ma,
            tenthuoc: row.ten,
            donvi: row.donvi,
            giaban: row.giaban,
            hangsx: row.tenhang,
            nuocsx: row.nuocsx,
            hamluong: row.hamluong
        })
    }
    updateHide(mabd, check) {
        this.setState({ loadingHide: true })
        let status = '0';
        if (check === false) {
            status = '1';
        }
        MedicineService.updateHide(mabd, status).then(result => {
            let data = this.state.lstThuoc;
            let item = data.find(x => x.id === mabd);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienthi === '1' ? '0' : '1';
                    item.hienthi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstThuoc: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstThuoc;
            let item = data.find(x => x.id === mabd);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingHide: false, lstThuoc: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    ExportDanhMuc() {
        Actions.setLoading(true);
        StatisticService.ExportDanhMucThuoc(1, this.state.key) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDanhMucThuoc-VatTu";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {

        const columns = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 80,
                align: 'center'
            },
            {
                title: 'Tên',
                dataIndex: 'tenbd',
                key: 'tenbd',
                align: 'left',
                render: (value) => {
                    return <span style={{ maxWidth: 150 }}>{value}</span>;
                }
            },
            {
                title: 'Hợp chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                align: 'left',

            },
            {
                title: 'Quy cách',
                dataIndex: 'quycach',
                key: 'quycach',
                width: 100,
                align: 'left'
            },
            {
                title: 'Hãng SX',
                dataIndex: 'tenhang',
                key: 'tenhang',
                align: 'left'
            },
            {
                title: 'Nước',
                dataIndex: 'tennuocsx',
                key: 'tennuocsx',
                width: 120,
                align: 'left'
            },
            {
                title: 'Loại',
                dataIndex: 'tenloai',
                key: 'tenloai',
                align: 'left'
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,
                align: 'left'
            },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>GIÁ NHẬP</span>,
            //     dataIndex: 'gianhap',
            //     key: 'gianhap',
            //     align: 'right',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     }
            // },
            {
                title: 'Giá bán',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>HIỂN THỊ</span>,
            //     align: 'center',
            //     render: (row) => {
            //         return (
            //             <Switch loading={this.state.loadingHide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hienthi === '0'}
            //                 onChange={(e) => {
            //                     this.updateHide(row.id, e)
            //                 }} />
            //         )
            //     }
            // },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>ACTION</span>,
            //     align: 'center',
            //     render: (row) => {
            //         return (
            //             <div style={{ display: 'flex', justifyContent: 'center' }}>
            //                 <Button type="primary"
            //                     className="btn btn-outline"
            //                     title="Cập nhật thuốc!"
            //                     onClick={() => {
            //                         this.setStateValues(row);
            //                     }} >
            //                     <EditFilled /> Cập nhật
            //             </Button>
            //             </div>
            //         )
            //     }
            // }
        ];
        const dataSource = [...this.state.lstThuoc];

        return (
            <div style={{ padding: "10px 20px", backgroundColor: "rgb(250,250,250)" }}>
                <Form ref={(c) => this.form = c} >
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className='h4 text-center mt-2 font-bold text-uppercase'>Danh mục thuốc</div>
                    <div className="row no-gutters" style={{ justifyContent: 'center', display: 'flex' }}>
                        <div className="col-md-3 mt-2">
                            <Form.Item label="Tìm thuốc:">
                                <Input
                                    value={this.state.key}
                                    placeholder="Nhập mã hoặc tên thuốc"
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.loadDanhSachThuoc();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-auto mt-2" style={{ display: 'flex' }}>
                            <Form.Item>
                                <button className="btn btn-success" onClick={() => this.loadDanhSachThuoc(1, this.state.pageSize)}>
                                    <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                </button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-primary"
                                    onClick={() => { this.ExportDanhMuc() }}>
                                    <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                            </Form.Item>
                            {/* <Form.Item style={{ flex: 1 }}>
                                <Button type="primary"
                                    onClick={this.showModalCreate}
                                > <i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới</Button>
                            </Form.Item>
                            <Modal maskClosable={false}
                                width={1300}
                                visible={this.state.visibleUpdate}
                                cancelButtonProps={{ style: { display: 'none' } }}
                                okButtonProps={{ style: { display: 'none' } }}>
                                <div style={{ padding: "5px 20px" }}>
                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                        <Form.Item style={{ flex: 1 }} label="Mã:" name="ma"
                                            rules={[{ required: true, message: 'Yêu cầu nhập mã thuốc!' }]}>
                                            <Input
                                                maxLength={6}
                                                value={this.state.ma}
                                                onChange={(e) => this.setState({ ma: e.target.value })} />
                                        </Form.Item>

                                        <Form.Item style={{ flex: 1 }} label="Tên:" name="tenthuoc"
                                            rules={[{ required: true, message: 'Yêu cầu nhập tên thuốc!' }]}>
                                            <Input
                                                value={this.state.tenthuoc}
                                                onChange={(e) => this.setState({ tenthuoc: e.target.value })}>
                                            </Input>
                                        </Form.Item>
                                        <Form.Item style={{ flex: 1 }} label="Loại:" name="loai" rules={[{ required: true, message: 'Vui lòng chọn loại thuốc!' }]}>
                                            <Select style={{ width: 300 }}

                                                showSearch
                                                onChange={(e) => this.setState({ loai: e })}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.lstLoai.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.value}>{row.label}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                        <Form.Item style={{ flex: 1 }} label="Hàm lượng:">
                                            <Input
                                                maxLength={20}
                                                value={this.state.hamluong}
                                                onChange={(e) => this.setState({ hamluong: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item style={{ flex: 1 }} label="Đơn vị tính:" name="donvi" style={{ flex: 1 }}
                                            rules={[{ required: true, message: 'Yêu cầu nhập đơn vị tính!' }]}>
                                            <Autocomplete
                                                width='100%'
                                                ref={((c) => this.autocomplete = c)}
                                                dataSource={this.state.lstDVT}
                                                handleSearch={this.loadDonViTinh.bind(this)}
                                                getItem={(key, value) => { this.setState({ donvi: value }); this.form.setFieldsValue({ donvi: key }) }} />
                                        </Form.Item>
                                        <Form.Item label="Giá bán:" name="giaban" style={{ flex: 1 }}>
                                            <InputNumber
                                                defaultValue={0}
                                                style={{ width: '100%' }}
                                                min={0}
                                                maxLength={10}
                                                value={this.state.giaban}
                                                onChange={(e) => this.setState({ giaban: e })}>
                                            </InputNumber>
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 10 }}>
                                        <Form.Item label="Hãng SX:" name="hangsx" style={{ flex: 1 }}
                                            rules={[{ required: true, message: 'Yêu cầu chọn hãng sản xuất!' }]}>
                                            <Autocomplete
                                                value={this.state.hangsx}
                                                width='100%'
                                                ref={((c) => this.autocomplete = c)}
                                                dataSource={this.state.lstHangSX}
                                                handleSearch={this.loadHSX.bind(this)}
                                                getItem={(key) => { this.form.setFieldsValue({ hangsx: key }); this.setState({ hangsx: key }); }} />
                                        </Form.Item>
                                        <Form.Item style={{ flex: 1 }} label="Nước SX:" name="nuocsx" rules={[{ required: true, message: 'Vui lòng chọn nước sản xuất!' }]}>
                                            <Select className="auto"
                                                showSearch
                                                onChange={(e) => this.setState({ nuocsx: e })}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {this.state.lstNuocSX.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.value}>{row.label}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item style={{ flex: 1 }} label="Quy cách:">
                                            <Input placeholder="Ví dụ: 1 lọ/60 viên"
                                                maxLength={20}
                                                value={this.state.quycach}
                                                onChange={(e) => this.setState({ quycach: e.target.value })} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                                        <Button type="primary"

                                            onClick={() => { this.update() }} >
                                            Lưu
                                        </Button>
                                        <Button type="primary" style={{ marginLeft: 5 }} danger
                                            onClick={() => { this.handleCancel() }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</Button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal maskClosable={false}
                                width={1300}
                                visible={this.state.visibleCreate}
                                onCancel={this.handleCancel}

                                cancelButtonProps={{ style: { display: 'none' } }}
                                okButtonProps={{ style: { display: 'none' } }}
                            >
                                <CreateThuoc onCancel={this.handleCancel} />
                            </Modal> */}
                        </div>
                    </div>
                    <div className="table-responsive" style={{ paddingTop: 10 }}>
                        <Table dataSource={dataSource}
                            scroll={{ x: 'max-content', y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSachThuoc(page, size) }}
                        onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadDanhSachThuoc(1, size)}}
                        showSizeChanger={true}
                    />
                </Form>
            </div>
        )
    }
}