import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { getListLoyalGroups } from './LoyalServices';
import * as LoyalServices from './LoyalServices';
import { Input, Tooltip, Select, Modal, Pagination, Table, Tag, Slider, notification, Popconfirm, InputNumber } from 'antd';
import * as AdministrativeService from "../../services/administrativeService";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import { login } from '../../services/worldfoneService';
import UnSupport from '../unknowPage/unSupport';

const { Option } = Select;
const DEFAULT_CITY = 101; // Hà Nội
const DEFAULT_DISTRICT = 10101; // Bắc Từ Liêm

function CauHinhNhomThanhVien() {
    const [kwd, setKwd] = useState('');
    const [vsbCreate, setVsbCreate] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [listGroup, setListGroup] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [suggest, setSuggest] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [initGroupName, setInitGroupName] = useState('');
    const [initTieuChi, setInitTieuChi] = useState('');

    const [listTieuChiCreate, setListTieuChiCreate] = useState([]);
    const [ageRange, setAgeRange] = useState([18, 50]);
    const [gender, setGender] = useState(0);
    const [spent, setSpent] = useState(1);
    const [minspent, setminSpent] = useState(0);
    const [maxspent, setmaxSpent] = useState(0);
    const [vsbAgeRange, setVsbAgeRange] = useState(false);
    const [vsbGender, setVsbGender] = useState(false);
    const [vsbLocation, setVsbLocation] = useState(false);
    const [vsbSpent, setVsbSpent] = useState(false);
    const [groupName, setGroupName] = useState("");

    const [lstCity, setLstCity] = useState([]);
    const [city, setCity] = useState(DEFAULT_CITY);
    const [lstDistrict, setLstDistrict] = useState([]);
    const [district, setDistrict] = useState(0);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [discount, setDiscount] = useState(0);
    const [percent, setPercent] = useState(true);

    const loadCity = (pageNumber, pageSize) => {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess) {
                setLstCity(result.data);
                !vsbLocation && setCity(DEFAULT_CITY);
            }
        }).catch(err => {
        })
    }

    const loadDistrict = (id = city, pageNumber = 1, pageSize = 100) => {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess) {
                setLstDistrict(result.data);
                !vsbLocation && setDistrict(0);
            }
            else {
                setLstDistrict([])
            }
        }).catch(err => {
        })
    }

    const getGroupsList = (page = currentPage, size = pageSize, getAll = false) => {
        getListLoyalGroups(kwd, page, size, getAll).then(res => {
            if (res.isSuccess) {
                setListGroup(res.data);
                setCurrentPage(page);
                setPageSize(size);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }

    const setGroupInfo = (info) => {
        const tieuChi = info.template;
        const tenNhom = info.label;
        setInitGroupName(tenNhom);
        setInitTieuChi(tieuChi);
        const sliceParts = tieuChi.split(" and ");
        if (tieuChi.includes("age")) {
            setListTieuChiCreate(listTieuChiCreate => [...listTieuChiCreate, "age"]);
            setVsbAgeRange(true);
            const stringMinAge = sliceParts.find(x => x.includes("age >="));
            const minAge = stringMinAge && stringMinAge.slice(6 - stringMinAge.length);
            const stringMaxAge = sliceParts.find(x => x.includes("age <="));
            const maxAge = stringMaxAge && stringMaxAge.slice(6 - stringMaxAge.length);
            if (minAge >= 0 && maxAge >= 0) {
                setAgeRange([minAge, maxAge])
            }
        }
        if (tieuChi.includes("gender")) {
            setListTieuChiCreate(listTieuChiCreate => [...listTieuChiCreate, "gender"]);
            setVsbGender(true);
            const stringGender = sliceParts.find(x => x.includes("gender ="));
            const gender = stringGender && stringGender.slice(8 - stringGender.length);
            if (Number(gender) >= 0) setGender(Number(gender.trim()));
        }
        if (tieuChi.includes("city")) {
            setListTieuChiCreate(listTieuChiCreate => [...listTieuChiCreate, "location"]);
            setVsbLocation(true);
            const stringCity = sliceParts.find(x => x.includes("city ="));
            const city = stringCity && stringCity.slice(6 - stringCity.length);
            if (Number(city) > 0) setCity(Number(city));
            const stringDistrict = sliceParts.find(x => x.includes("district ="));
            const district = stringDistrict && stringDistrict.slice(10 - stringDistrict.length);
            Number(district) > 0 ? setDistrict(Number(district)) : setDistrict(0);
        }
        if (tieuChi.includes("spent")) {
            setListTieuChiCreate(listTieuChiCreate => [...listTieuChiCreate, "spent"]);
            setVsbSpent(true);
            const stringMinSpent = sliceParts.find(x => x.includes("spent >="));
            const minSpent = stringMinSpent && stringMinSpent.slice(8 - stringMinSpent.length);
            minSpent && setminSpent(Number(minSpent.trim()))
            const stringMaxSpent = sliceParts.find(x => x.includes("spent <"));
            const maxSpent = stringMaxSpent && stringMaxSpent.slice(7 - stringMaxSpent.length);
            maxSpent && setmaxSpent(Number(maxSpent.trim()))
        }
        if (tenNhom) setGroupName(tenNhom);
        if (info.discount != null && info.discount != "") {
            if (info.discount.includes("%")) {
                setPercent(true);
                setDiscount(Number(info.discount.replace("%", "")));
            } else {
                setPercent(false);
                setDiscount(Number(info.discount));
            }
        } else {
            setPercent(true);
            setDiscount(0);
        }
    }

    const updateGroup = () => {
        if (validator.current.allValid()) {
            if(minspent !== 0 && maxspent !== 0 && minspent > maxspent){
                notification.warning({ message: "Khoảng số tiền không hợp lệ. Vui lòng kiểm tra lại. " });
            } else {
                var querryString = createQueryTieuChi();
                const data = {
                    label: initGroupName !== groupName ? "" : initGroupName,
                    templateId: Number(selectedId),
                    key: groupName,
                    value: querryString,
                    template: initTieuChi !== querryString ? "" : initTieuChi,
                    value: querryString,
                    discount: percent ? discount.toString() + "%" : discount.toString(),
                }
                LoyalServices.updateLoyalGroup(data).then(res => {
                    if (res.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" });
                        getGroupsList();
                        resetState();
                    }
                    else {
                        if (res.err.msgString) notification.warning({ message: res.err.msgString })
                        else notification.warning({ message: "Không thể cập nhật, vui lòng thử lại sau" })
                    }
                }).catch(() => { })
                }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }

    const deleteGroup = (id) => {
        LoyalServices.deleteLoyalGroup(Number(id)).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã xóa thành công" })
                getGroupsList();
            } else {
                notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
        }).catch(() => { })
    }

    useEffect(() => {
        getGroupsList();
    }, [])

    const ThongTinTieuChi = (groupId) => {
        var found = listGroup.find(x => x.key === groupId);
        if (found) {
            var preSet = found.value.replaceAll("age ", "Tuổi ")
                .replaceAll("gender = 0", "Giới tính: Nam")
                .replace("gender = 1", "Giới tính: Nữ")
                .replace("city =", "Tỉnh/thành phố:")
                .replace("district =", "Quận/huyện:")
                .replaceAll("spent", "Tổng chi tiêu");
            var listTieuChi = preSet.split(" and ");
            if (listTieuChi.length > 0) return listTieuChi.map(tc => <Tag key={tc}>{tc}</Tag>)
        }
    }

    const createQueryTieuChi = () => {
        var querryString = "";
        listTieuChiCreate.sort().forEach((tieuChi) => {
            switch (tieuChi) {
                case "age":
                    querryString === "" ?
                        querryString = `${tieuChi} >= ${ageRange[0]} and ${tieuChi} <= ${ageRange[1]}`
                        : querryString += `and ${tieuChi} >= ${ageRange[0]} and ${tieuChi} <= ${ageRange[1]} `;
                    break;
                case "gender":
                    querryString === "" ?
                        querryString = `${tieuChi} = ${gender}`
                        : querryString += ` and ${tieuChi} = ${gender}`;
                    break;
                case "location":
                    querryString === "" ?
                        district === 0 ? querryString = `city = ${city}`
                            : querryString = `city = ${city} and district = ${district}`
                        : district !== 0 ? querryString += ` and city = ${city} and district = ${district}`
                            : querryString += ` and city = ${city}`;
                    break;
                case "spent":
                    querryString = querryString === "" ? querryString : querryString + " and ";
                    // if (spent === 1) querryString += `${tieuChi} < 5000000`
                    // else if (spent === 2) querryString += `${tieuChi} >= 5000000 and ${tieuChi} < 10000000`
                    // else if (spent === 3) querryString += `${tieuChi} >= 10000000 and ${tieuChi} < 20000000`
                    // else if (spent === 4) querryString += `${tieuChi} >= 20000000`
                    if (maxspent === null || maxspent === 0) querryString += `${tieuChi} >= ${minspent}`
                    else if (minspent === null || minspent === 0) querryString += `${tieuChi} < ${maxspent}`
                    else querryString += `${tieuChi} >= ${minspent} and ${tieuChi} < ${maxspent}`
                    //querryString += `${tieuChi} >= ${minspent} and ${tieuChi} < ${maxspent}`
                    break;
            }
        })
        return querryString;
    }

    const createGroup = () => {
        if (validator.current.allValid()) {
            if(minspent !== 0 && maxspent !== 0 && minspent > maxspent){
                notification.warning({ message: "Khoảng số tiền không hợp lệ. Vui lòng kiểm tra lại. " });
                forceUpdate();
            } else {
                var querryString = createQueryTieuChi();
                const data = {
                    key: groupName,
                    value: querryString,
                    discount: percent ? discount.toString() + "%" : discount.toString(),
                };
                LoyalServices.createLoyalGroup(data).then(res => {
                    if (res.isSuccess) {
                        notification.success({ message: "Đã tạo thành công" });
                        resetState();
                        getGroupsList();
                    }
                    else {
                        if (res.err.msgString) notification.warning({ message: res.err.msgString })
                        else notification.warning({ message: "Không thể tạo nhóm, vui lòng thử lại sau" })
                    }
                }).catch(() => { })
            }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }

    useEffect(() => {
        if (vsbLocation) {
            loadCity();
            loadDistrict();
        }
    }, [vsbLocation])

    useEffect(() => {
        vsbLocation && loadDistrict()
    }, [city])

    const setVisibleFields = (e) => {
        e.find(x => x === "age") !== undefined ? setVsbAgeRange(true) : setVsbAgeRange(false);
        e.find(x => x === "gender") !== undefined ? setVsbGender(true) : setVsbGender(false);
        e.find(x => x === "location") !== undefined ? setVsbLocation(true) : setVsbLocation(false);
        e.find(x => x === "spent") !== undefined ? setVsbSpent(true) : setVsbSpent(false);
    }

    const resetState = () => {
        validator.current.hideMessages();
        setVsbCreate(false);
        setGroupName("");
        setListTieuChiCreate([]);
        setAgeRange([18, 50]);
        setGender(0);
        setCity(DEFAULT_CITY);
        setDistrict(0);
        setSpent(1);
        setVsbAgeRange(false);
        setVsbGender(false);
        setVsbLocation(false);
        setVsbSpent(false);
        setIsUpdate(false);
        setDiscount(0);
        setPercent(true);
        setminSpent(0);
        setmaxSpent(0);
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 80
        },
        {
            title: 'Tên nhóm',
            dataIndex: 'label',
            key: 'label',
            width: 300,
        },
        {
            title: "Tiêu chí phân loại",
            key: 'value',
            width: 700,
            align: "left",
            render: (row) => {
                return <span>{ThongTinTieuChi(row.key)}</span>
            }
        },
        {
            title: 'Mức giảm',
            dataIndex: 'discount',
            key: 'discount',
            width: 180,
            render: (discount) => discount && <span>{discount.includes("%") ? discount : Number(discount).toLocaleString('en-US') + " đồng" }</span>
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            width: 150,
            render: (row) => {
                return (
                    <div>
                        <Tooltip title="Cập nhật" onClick={() => { setIsUpdate(true); setVsbCreate(true); setGroupInfo(row) }}>
                            <button className="btn btn-color" style={{ marginLeft: 10 }}><i className="fas fa-edit" /></button>
                        </Tooltip>
                        <Popconfirm title="Xóa nhóm này?" onConfirm={() => deleteGroup(row.key)
                        } okText="Đồng ý" cancelText="Hủy" >
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    return (
        1===1 ? <UnSupport title="Chức năng này chưa hỗ trợ ở thời điểm hiện tại" /> :
        <Fragment>
            <div className={'containerChilderWrapper'}>
                <h4 className="font-bold text-center text-uppercase mt-2">Cấu hình nhóm thành viên</h4>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-4">
                        <Input
                            className="mt-2"
                            value={kwd} placeholder="Nhập tên nhóm sau đó bấm Enter để tìm kiếm"
                            onChange={(e) => setKwd(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    getGroupsList(1);
                                }
                            }}>
                        </Input>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-color mt-2" onClick={() => getGroupsList(1)}>
                            <i className="fas fa-search" />
                        </button>
                        <Tooltip title="Thêm nhóm thành viên">
                            <button className="btn btn-color ml-2 mt-2" onClick={() => { setVsbCreate(true) }}>
                                <i className="fas fa-plus" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 130px)', padding: 10 }}>
                    <Table scroll={{ y: 0 }} pagination={false} dataSource={listGroup}
                        columns={columns}
                        rowKey={row => `table-item-${row.key}`}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setSelectedId(record.key)
                                }
                            };
                        }}
                        rowClassName={(record) => {
                            return `rowCustomSelect ${(selectedId === record.key) ? 'rowCustomSelectActive' : ''}`
                        }}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            getGroupsList(page, size);
                        }}
                        onShowSizeChange={() => { }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <Modal visible={vsbCreate} maskClosable={false} width={700}
                onCancel={() => resetState()}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>{`${isUpdate ? "Cập nhật" : "Thêm"} nhóm thành viên`}</h3>
                </div>
                <div className="row">
                    <div className="col-md-8 mt-2">
                        <label>Tên nhóm</label>
                        <Input placeholder="Nhập tên nhóm" value={groupName} onChange={(e) => setGroupName(e.target.value)}></Input>
                        {validator.current.message("Tên nhóm", groupName, "required")}
                    </div>
                    <div className="col-md-4 mt-2">
                        <label>Mức giảm</label>
                        <Input placeholder="Nhập mức giảm" value={discount} onChange={(e) => setDiscount(Number(e.target.value))} maxLength={9}
                            onKeyPress={(e) => {
                                if (e.key === " " || isNaN(e.key) === true)
                                    e.preventDefault();
                            }}
                            prefix={<Tooltip title="Bấm để thay đổi"><span style={{ width: 80, borderRight: "1px solid #ccc", paddingRight: 5 }} onClick={() => setPercent(!percent)}>{percent ? "Phần trăm" : "Số tiền"}</span></Tooltip>} />
                        {validator.current.message("Mức giảm", discount, percent ? "required|numeric|max:100,num|min:0,num" : "required|numeric|max:999999999,num|min:0,num")}
                    </div>
                    <div className="col-md-12 mt-2">
                        <label>Tiêu chí phân loại</label>&nbsp;
                        <span onClick={() => setSuggest(!suggest)}><i className="fas fa-question-circle" style={{ marginRight: 5 }} /></span>
                        <Select mode="multiple" style={{ width: '100%' }}
                            value={listTieuChiCreate} placeholder="Bấm vào để chọn, có thể chọn nhiều"
                            onChange={(e) => { setListTieuChiCreate(e); setVisibleFields(e) }}>
                            <Option value="age">Tuổi</Option>
                            <Option value="gender">Giới tính</Option>
                            <Option value="location">Vị trí (địa chỉ)</Option>
                            <Option value="spent">Số tiền đã chi</Option>
                        </Select>
                        {validator.current.message("Tiêu chí phân loại", setListTieuChiCreate.length < 0, "required")}
                    </div>
                    {vsbAgeRange &&
                        <div className="col-md-6 mt-2">
                            <label>Chọn khoảng tuổi  {ageRange.length > 0 && <span>({ageRange[0]} - {ageRange[1]})</span>}</label>
                            <Slider range value={ageRange} onChange={(e) => setAgeRange(e)} />
                        </div>}
                    {vsbGender &&
                        <div className="col-md-6 mt-2">
                            <label>Chọn giới tính </label>
                            <Select className="w-100" value={gender} onChange={(e) => setGender(e)}>
                                <Option value={0}>Nam</Option>
                                <Option value={1}>Nữ</Option>
                            </Select>
                        </div>}
                    {vsbLocation &&
                        <>
                            <div className="col-md-6 mt-2">
                                <label>Chọn tỉnh/thành </label>
                                <Select className="w-100" value={city} onChange={(e) => { setCity(e); setDistrict(0); }} showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {lstCity.map((city, index) => {
                                        return <Option value={city.value} key={city.value}>{city.label}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label>Chọn quận/huyện </label>
                                <Select className="w-100" value={district} onChange={(e) => setDistrict(e)} showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option value={0}>Tất cả</Option>
                                    {lstDistrict.map((dist, index) => {
                                        return <Option value={dist.value} key={dist.value}>{dist.label}</Option>
                                    })}
                                </Select>
                            </div>
                        </>}
                    {vsbSpent &&
                    <>
                        <div className="col-md-12 mt-2">
                            <label>Chọn khoảng tiền</label>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-6 mt-2">
                                    <InputNumber placeholder="Từ" min={0} value={minspent} className='w-100'
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => {
                                            setminSpent(e)
                                        }} />
                                        {validator.current.message("Khoảng tiền từ ", minspent , "required")}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <InputNumber placeholder="Đến" min={0} value={maxspent} className='w-100' 
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => {
                                            setmaxSpent(e)
                                        }} />
                                        {validator.current.message("Khoảng tiền đến ", maxspent , "required")}
                                </div>
                            </div>
                        </div>
                            {/* <Select className="w-100" value={spent} onChange={(e) => setSpent(e)}>
                                <Option value={1}>Dưới 5 triệu</Option>
                                <Option value={2}>5 triệu đến dưới 10 triệu</Option>
                                <Option value={3}>10 triệu đến dưới 20 triệu</Option>
                                <Option value={4}>Từ 20 triệu</Option>
                            </Select> */}
                    </>
                        }
                    <div className="col-md-12 mt-2" onClick={() => setSuggest(!suggest)}>
                        <ol type="I" visible={!suggest && "none"} style={{ display: suggest ? "flow-root" : "none" }}>
                            <li>Nhóm 1 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi</li>
                                    <li>Giới tính</li>
                                    <li>Vị trí (địa chỉ)</li>
                                    <li>Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 2 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính</li>
                                    <li>Tuổi + Vị trí (địa chỉ)</li>
                                    <li>Tuổi + Số tiền đã chi</li>
                                    <li>Giới tính + Vị trí (địa chỉ)</li>
                                    <li>Giới tính + Số tiền đã chi</li>
                                    <li>Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 3 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính + Vị trí (địa chỉ)</li>
                                    <li>Tuổi + Giới tính + Số tiền đã chi</li>
                                    <li>Tuổi + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                    <li>Giới tính + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                            <li>Nhóm 4 tiêu chí:
                                <ol type="1">
                                    <li>Tuổi + Giới tính + Vị trí (địa chỉ) + Số tiền đã chi</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <button className="btn btn-success" disabled={listTieuChiCreate.length === 0} onClick={() => { isUpdate ? updateGroup() : createGroup() }}>
                        <i className="fas fa-save" style={{ marginRight: 5 }} />Lưu
                    </button>
                    <button className="ml-2 btn btn-danger" onClick={() => resetState()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                </div>
            </Modal>
        </Fragment>
    );
}

export default CauHinhNhomThanhVien;