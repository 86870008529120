import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Select } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as CompanyExamService from '../../services/CompanyExamService';
import * as ConfigService from "../../services/configService";
import { connect } from 'react-redux';

const { Option } = Select;
const DownLoadTemplate = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [coporationId, setCoporationId] = useState();
  const [banggia, setBanggia] = useState();  
  const [banggiaText, setBanggiaText] = useState();
  const [validBangGia, setValidBangGia] = useState(true);
  //#region useEffect
  useEffect(() => {
  }, []);
  //#endregion 
  const onExportListClickHandle = () => {
    if (!validator.current.allValid() || !banggia) {
      notiRef.current.Show('Chưa chọn nguồn khách hàng hoặc chưa có thông tin bảng giá.', NotifyStatus.Error);
      return;
    }
    Actions.setLoading(true);
    let filterModel = {
      coporationId: coporationId,
      bangGia: banggia,
    }
    CompanyExamService.getimporttemplate(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        hiddenLink.current.href = url;
        hiddenLink.current.download = "MauImport"+moment(new Date()).format('YYMMDDhhmmss');
        hiddenLink.current.click();
      })
      notiRef && notiRef.current.Close();
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  const onChangeCoporation = (value) => {
    let record = props.listCoporations.filter(x=>x.ma == value)[0];
    if (record) {
      if (record.banggia) {
        setBanggia(record.banggia == 'default' ? 'Mặc định' : record.banggia);
        setBanggiaText('');
        setValidBangGia(true);
      }
      else {
        ConfigService.BangGiaDefaultSite(props.siteid).then((respone) => {
            if (respone.isSuccess) {
              if (respone.item != '' && respone.item != 'default' && respone.item != null) {
                setBanggia(respone.item);
                setBanggiaText(` (theo chi nhánh: ${props.siteName})`);
                setValidBangGia(true);
                return;
              }
            }
            setBanggia('');
            setBanggiaText();
            setValidBangGia(false);
        }).catch(err => {
            // this.getAllTypeServices(true);
        });
      }
    }
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Tải mẫu - Khám sức khỏe công ty</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row mt-2">
                <div className="col-md-12 d-flex flex-column">
                  <div><label className='required-field'>Nguồn khách hàng</label></div>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    className="w-100"
                    placeholder="Chọn nguồn khách hàng"
                    maxTagCount= 'responsive'
                    value={ coporationId }
                    onChange={(value) => {setCoporationId(value); onChangeCoporation(value);}}
                  >
                    {(props.listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                  </Select>
                  {validator.current.message("Nguồn khách hàng", coporationId, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Tên phân hệ - tính năng*/}
                <div className="col-md-12 d-flex flex-column">
                  <div><label className='required-field'>Bảng giá</label></div>
                  <div hidden={!validBangGia}><label>{banggia}</label><label className="text-color"> {banggiaText}</label></div>
                  <label hidden={validBangGia} className='text-danger'>Không tìm thấy bảng giá. Vui lòng cấu hình lại bảng giá cho nguồn khách hàng hoặc chi nhánh</label>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  onClick={onExportListClickHandle}
                >
                  <i className='fas fa-file-excel mr-2'></i>
                  Tải mẫu
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);