
import { DatePicker, Form, Modal, Select, Button } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import BanLeThuoc from './banle';
import DonBacSi from './donbacsi';
import PhieuXuatThuoc from './phieuxuat';
import { connect } from 'react-redux';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import HeaderModal from '../../../configureHIS/common/headerModal';
import UnSupport from '../../unknowPage/unSupport';
const { RangePicker } = DatePicker;
const { Option } = Select;
class Prescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            ngay: [moment(), moment()],
            trangthaiBS: '1',
            trangthaipx: '',
            createBanle: false,
        };
    }
    notifyUpdate = () => {
        if (this.props.medicineMarket) {
            this.formBS.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
            this.phieuxuat.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
        }
    }
    componentDidUpdate() {
        if (this.props.reloadPhieuThuoc === true && this.props.medicineMarket) {
            Actions.ReloadPhieuThuoc(false);
            this.formBS.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
            this.phieuxuat.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
        }
    }

    render() {
        return (
            !this.props.medicineMarket ?
                <UnSupport title="Chức năng bán thuốc không hoạt động" />
            :
            <>
                <div className='containerChilderWrapper' style={{ height: 'auto' }} >
                    <div className='h3 text-center mt-2'>Quản lý phiếu thuốc</div>
                    <div style={{ marginTop: 20, display: 'flex', position: 'relative' }}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            <div className="col-md-3 mt-1" >
                                <RangePicker allowClear={false} format={SystemConfig.FORMAT_DATE} value={this.state.ngay}
                                    locale={viVN} onChange={(value) => {
                                        this.setState({ ngay: value }, () => {
                                            this.formBS.SearchWhenChangeDate(value);
                                            this.phieuxuat.SearchWhenChangeDate(value);
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-md-3 mt-1" >
                                <input className="w-100 form-control" placeholder='Nhập số phiếu, mã hoặc tên bệnh nhân'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.formBS.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                                            this.phieuxuat.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({ key: e.target.value });
                                        this.formBS.setState({ hoten: e.target.value });
                                        this.phieuxuat.setState({ hoten: e.target.value });
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: 5 }}  >
                                <Button className='btn btn-color' onClick={() => {
                                    this.formBS.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                                    this.phieuxuat.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                                }}><i className='fa fa-search'></i></Button>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: 10, right: 0 }}>
                        <Button className="btn btn-color" onClick={() => this.setState({ createBanle: true })}
                                type='primary'><i className="fas fa-plus" style={{ marginRight: 5 }}></i>Phiếu bán lẻ</Button>
                        </div>
                    </div>
                </div>
                <div style={{
                    width: '100%', marginTop: 10, marginLeft: 0, display: 'flex', justifyContent: 'space-between',
                    height: 'calc(100% - 140px)'
                }} className="row">
                    <div className='col-md-6 mb-2 pl-0 h-100' >
                        <div className="containerChilderWrapper">
                            <div className='h5 text-center mt-2 font-bold'>ĐƠN THUỐC BÁC SĨ KÊ</div>
                            <Form.Item >
                                <Select style={{ width: '130px' }} value={this.state.trangthaiBS}
                                    onChange={(value) => this.setState({ trangthaiBS: value }, () => this.formBS.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD')))}>
                                    <Option key={0} value={''}>Trạng thái</Option>
                                    <Option key={1} value={'1'}>Mới</Option>
                                    <Option key={2} value={'2'}>Đã xuất phiếu</Option>
                                </Select>
                            </Form.Item>
                            <div style={{ marginTop: 5 }}>
                                <DonBacSi ref={(c) => this.formBS = c} trangthai={this.state.trangthaiBS} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 mb-2 pr-0 h-100'>
                        <div className="containerChilderWrapper">
                            <div className='h5 text-center mt-2 font-bold'>DANH SÁCH PHIẾU THUỐC ĐÃ XUẤT</div>
                            <Form.Item >
                                <Select style={{ width: '130px' }} value={this.state.trangthaipx}
                                    onChange={(value) => this.setState({ trangthaipx: value }, () => this.phieuxuat.searchToa(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD')))}>
                                    <Option key={0} value={''}>Trạng thái</Option>
                                    <Option key={1} value={'1'}>Đã xuất phiếu</Option>
                                    <Option key={2} value={'2'}>Đã thanh toán</Option>
                                </Select>
                            </Form.Item>
                            <div style={{ marginTop: 5 }}>
                                <PhieuXuatThuoc ref={(c) => this.phieuxuat = c} trangthai={this.state.trangthaipx} />
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.createBanle === true &&
                    <Modal maskClosable={false}
                        //destroyOnClose={true}
                        width={1400}
                        visible={this.state.createBanle}
                        placement="top"
                        closable={false}
                        onClose={this.handleCancel}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        {this.state.createBanle === true &&
                            <BanLeThuoc
                                onClose={() => this.setState({ createBanle: false })}>
                            </BanLeThuoc>
                        }
                    </Modal>}
            </>
        )
    }

}
const mapStateToProps = (state) => {
    return ({
        reloadPhieuThuoc: state.global.reloadPhieuThuoc,
        medicineMarket: state.global.sysConfig.medicineMarket ? state.global.sysConfig.medicineMarket : false,
    })
};
export default connect(
    mapStateToProps, null, null, { forwardRef: true }
)(Prescription);