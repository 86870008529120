import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Checkbox, Input, InputNumber, Pagination, Select, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import * as SendMessageService from '../../../services/ConfigMessageTemplateService';
import { setLoading } from '../../../libs/actions';
import ActionControl from '../../../libs/actionControl';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../../libs/suneditor-vi-lang';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';

const ViewCreateUpdate = forwardRef((props, ref) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const [title, setTitle] = useState("Tạo mới cấu hình gửi tin nhắn");
    const [viewMode, setViewMode] = useState(false);
    const [id, setId] = useState();
    const [tempId, setTempId] = useState();
    const [tempName, setTempName] = useState();
    const [sendMessageType, setSendMessageType] = useState();
    const [sendMessageTemplateType, setSendMessageTemplateType] = useState();
    const [tempContent, setTempContent] = useState();
    const [listSendMessageType, setListSendMessageType] = useState([]);
    const [listSendMessageTemplateType, setListSendMessageTemplateType] = useState([]);
    const [htmlContext, setHtmlContext] = useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        let tit = "";
        if (props.mode == "view") {
            setViewMode(true);
            tit = "Xem chi tiết";
        }
        if (props.mode == "update") {
            setViewMode(false);
            tit = "Cập nhật";
        }
        if (props.mode == "create") {
            setViewMode(false);
            tit = "Tạo mới";
        } else {
            if (props.data) {
                setId(props.data.id);
                setTempId(props.data.tempId);
                setTempName(props.data.tempName);
                setSendMessageType(props.data.sendMessageType);
                setSendMessageTemplateType(props.data.sendMessageTemplateType);
                setTempContent(props.data.tempContent);
                if (checkUseHtml(props.data.tempContent)) setHtmlContext(true);
            }
        }
        tit += " cấu hình gửi tin nhắn";
        setTitle(tit);
    }, [props.mode, props.data]);

    useEffect(() => {
        GetMessageType();
        GetTemplateMessageType();
    }, []);

    const GetMessageType = () => {
        SendMessageService.GetListSendMessageType().then(res => {
            if (res.isSuccess) {
                setListSendMessageType(res.data);
            }
        }).catch(err => {

        }).finally();
    }

    const GetTemplateMessageType = () => {
        SendMessageService.GetListSendMessageTemplateType().then(res => {
            if (res.isSuccess) {
                setListSendMessageTemplateType(res.data);
            }
        }).catch(err => {

        }).finally();
    }

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        } else {
            _notify && _notify.current && _notify.current.Show("Không tìm thấy function", NotifyStatus.Warning);
        }

    }

    const onSave = () => {
        _notify && _notify.current && _notify.current.Close();
        const data = {
            id,
            tempId: tempId,
            tempName,
            tempContent,
            sendMessageType,
            sendMessageTemplateType
        }
        if (validator.current.allValid()) {
            if (props.mode == "update") {
                SendMessageService.UpdateSendMessageTemplate(data).then(res => {
                    if (res.isSuccess) {
                        _notify && _notify.current && _notify.current.Show("Cập nhật thành công", NotifyStatus.Success);
                        setViewMode(true);
                    } else {
                        _notify && _notify.current && _notify.current.Show(res.error.messageText ?? "Đã xảy ra lỗi khi cập nhật", NotifyStatus.Error);
                    }
                }).catch(err => {
                    _notify && _notify.current && _notify.current.Show(err.messageText ?? "Đã xảy ra lỗi khi cập nhật", NotifyStatus.Error);
                }).finally(() => {
                    setLoading(false);
                });
            }
            else if (props.mode == "create") {
                SendMessageService.CreateSendMessageTemplate(data).then(res => {
                    if (res.isSuccess) {
                        _notify && _notify.current && _notify.current.Show("Thêm mới thành công", NotifyStatus.Success);
                        setViewMode(true);
                    } else {
                        _notify && _notify.current && _notify.current.Show(res.error.messageText ?? "Đã xảy ra lỗi khi tạo mới", NotifyStatus.Error);
                    }
                }).catch(err => {
                    _notify && _notify.current && _notify.current.Show(err.messageText ?? "Đã xảy ra lỗi khi tạo mới", NotifyStatus.Error);
                }).finally(() => {
                    setLoading(false);
                });
            }

        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const checkUseHtml = (content) => {
        let useHtmlContext = false;
        if (content.includes("</")) useHtmlContext = true;
        return useHtmlContext;
    }

    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={`${title}`} onClose={onClose} />
                <Notify ref={_notify} />
                <div className="list-page-body">
                    <div className='row'>
                        {!viewMode ?
                            <Fragment>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='mita-title required-field'>Loại tin nhắn</div>
                                    <Select
                                        disabled={viewMode}
                                        allowClear
                                        showSearch
                                        className="w-100"
                                        placeholder="Chọn loại tin nhắn"
                                        value={sendMessageType}
                                        filterOption={(input, option) => {
                                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }}
                                        onChange={(value) => { setSendMessageType(value); }}
                                    >
                                        {(listSendMessageType ?? []).map((record) => {
                                            return (
                                                <Select.Option key={record.Key} value={record.Key} label={record.Value}>{record.Value}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                    {validator.current.message("Loại tin nhắn", sendMessageType, "required")}
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='mita-title'>Mẫu dành cho</div>
                                    <Select
                                        disabled={viewMode}
                                        allowClear
                                        showSearch
                                        className="w-100"
                                        placeholder="Chọn mẫu dành cho"
                                        value={sendMessageTemplateType}
                                        filterOption={(input, option) => {
                                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }}
                                        onChange={(value) => { setSendMessageTemplateType(value); }}
                                    >
                                        {(listSendMessageTemplateType ?? []).map((record) => {
                                            return (
                                                <Select.Option key={record.Key} value={record.Key} label={record.Value}>{record.Value}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-2'>
                                    <div className='mita-title'>Template ID</div>
                                    <Input
                                        disabled={viewMode}
                                        className='w-100'
                                        value={tempId}
                                        placeholder='Nhập template ID'
                                        onChange={(e) => setTempId(e.target.value)}
                                    ></Input>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-2'>
                                    <div className='mita-title required-field'>Tên mẫu</div>
                                    <Input
                                        disabled={viewMode}
                                        className='w-100'
                                        value={tempName}
                                        placeholder='Nhập tên mẫu'
                                        onChange={(e) => setTempName(e.target.value)}
                                    ></Input>
                                    {validator.current.message("Tên mẫu", tempName, "required")}
                                </div>
                                <Fragment>
                                    <div className='col-12 mt-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='mita-title required-field'>Nội dung</div>
                                            <Checkbox checked={htmlContext} onChange={(e) => setHtmlContext(e.target.checked)}>Sử dụng định dạng HTML</Checkbox>
                                        </div>
                                        {htmlContext ?
                                            <Fragment>
                                                <SunEditor
                                                    onBlur={(e, value) => {
                                                        setTempContent(value)
                                                    }}
                                                    onChange={(e) => setTempContent(e)}
                                                    lang={suneditorViLang}
                                                    setDefaultStyle="font-size: 13px; paragraphStyles: normal; font-family: Arial;"
                                                    setContents={tempContent}
                                                    setOptions={{
                                                        height: 300,
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                                            ['outdent', 'indent', 'align', 'horizontalRule', 'list', 'table', 'lineHeight'],
                                                        ]
                                                    }}
                                                />
                                            </Fragment>
                                            : <Input.TextArea autoSize={{ minRows: 4, maxRows: 10 }}
                                                value={tempContent}
                                                onChange={(e) => setTempContent(e.target.value)}
                                            ></Input.TextArea>}
                                            {validator.current.message("Nội dung", tempContent, "required")}
                                    </div>
                                    {htmlContext && <div className='col-12 mt-2'>
                                        <div className='mita-title'>Text preview</div>
                                        <div>{tempContent}</div>
                                    </div>}
                                </Fragment>
                            </Fragment>
                            :
                            <Fragment>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='mita-title'>Loại tin nhắn</div>
                                    <div>{(listSendMessageType ?? []).find(x => x.Key == sendMessageType)?.Value}</div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='mita-title'>Mẫu dành cho</div>
                                    <div>
                                        {(listSendMessageTemplateType ?? []).find(x => x.Key == sendMessageTemplateType)?.Value}
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-2'>
                                    <div className='mita-title'>Template ID</div>
                                    <div>{tempId}</div>
                                </div>
                                <div className='col-lg-6 col-md-12 mt-2'>
                                    <div className='mita-title'>Tên mẫu</div>
                                    <div>{tempName}</div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='mita-title'>Nội dung</div>
                                    <div dangerouslySetInnerHTML={{ __html: `${tempContent}` }}></div>
                                </div>
                            </Fragment>}
                    </div>
                </div>
                <div className="list-page-footer d-flex justify-content-between mt-2">
                    <div className="w-100">
                        <ActionControl
                            activeSave={!viewMode}
                            activeClose={true}
                            handleClose={() => onClose()}
                            handleSave={() => onSave()}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ViewCreateUpdate)