import React, { Component } from 'react';
import { Form, Input, notification } from 'antd';
import * as MedicineService from '../../../../../services/medicineService';

let TOL = null;
export default class Unit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unit: "",
        };
    }
    componentWillUnmount() {
        if (TOL)
            clearTimeout(TOL)
    }
    async createUnit() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                ten: this.state.unit,
                action: "insert",
                nhom: 1
            }
            MedicineService.TaoDonViTinh(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Thêm thành công!' });
                    this.resetState();
                    if (this.props.onSubmit) {
                        this.props.onSubmit(result.returnObject);
                        this.props.onCancel();
                    }
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        }
    }
    resetState() {
        this.form.setFieldsValue({
            unit: null,
        });
        this.setState({
            unit: '',
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'left' }}>
                        <h3>Thêm đơn vị tính</h3>
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                            <Form.Item style={{ flex: 1 }} label="Tên đơn vị tính:" name="unit" className='input-not-formitem'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị tính' }]}>
                                <Input
                                    value={this.state.unit}
                                    onChange={(e) => this.setState({ unit: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        <div className='custom-hr'></div>
                        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" style={{ margin: "-4px 0px 0px 0px" }}
                                onClick={() => this.createUnit()}>Tạo</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10, marginTop: -3 }}
                                onClick={() => {
                                    TOL = setTimeout(() => {
                                        this.form.resetFields();
                                    }, 50);
                                    this.props.onCancel();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}