import { Collapse, DatePicker, Form, notification, Select, Table, Pagination } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.LoadThongKeDoanhSoBS();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSoBS() {
        Actions.setLoading(true);
        StatisticService.ThongKeBacSi(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.loai).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSoBS() {
        Actions.setLoading(true);
        StatisticService.ExportDoanhSoBS(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeBacSi";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'chuandoan',
                key: 'chuandoan',
            }
        ]
        const columns = [
            {
                title: 'Tên bác sĩ',
                dataIndex: 'tenbs',
                key: 'tenbs',
            },
            {
                title: 'Chi tiết',
                dataIndex: 'lstData',
                key: 'lstData',
                render: (row) => {
                    const data = row.filter(x => x.ma !== '');
                    return (
                        <Collapse className="remove-space-statistic">
                            <Panel header="Xem chi tiết">
                                {data.length > 0 ?
                                    <Table
                                        style={{ margin: '0 !important' }}

                                        pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                        dataSource={data} columns={columnChild} rowKey={(row) => row.ma} />
                                    : 'Không có dữ liệu'
                                }
                            </Panel>
                        </Collapse>
                    );
                },
            },
            {
                title: 'Tổng',
                render: (text, record, index) => {
                    return <span>{record.lstData.length}</span>;
                },
            }
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê lượt khám bác sĩ</div>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.isMultisite === true &&
                            <div className="col-auto mt-3">
                                <Form.Item>
                                    <Select
                                        style={{ width: '200px' }}
                                        showSearch
                                        allowClear={false}
                                        value={this.state.chinhanh}
                                        onChange={(e) => {
                                            this.setState({ chinhanh: e })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={''}>Chi nhánh</Option>
                                        {this.state.listSite.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ma}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        }
                        <div className="col-md-3 mt-2">
                            <Form.Item>
                                <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                    locale={viVN}
                                    onChange={(value => this.setState({ ngay: value }
                                        // , () => this.LoadThongKeDoanhSoBS()
                                    ))}
                                />
                            </Form.Item>
                        </div>
                        <Form.Item style={{ display: "none" }} label="Loại:">
                            <Select
                                style={{ width: 200 }}
                                value={this.state.loai}
                                showSearch
                                onChange={(e) => this.setState({ loai: e })}>
                                <Option key={1} value={''}>Tất cả</Option>
                                <Option key={2} value={'1'}>Xét nghiệm</Option>
                                <Option key={3} value={'2'}>Chẩn đoán hình ảnh</Option>
                                <Option key={4} value={'3'}>Tư vấn khám</Option>
                                <Option key={5} value={'4'}>Thuốc</Option>
                            </Select>
                        </Form.Item>
                        <div className="col-md-3 mt-2" style={{ display: 'flex' }}>
                            <Form.Item>
                                <button className="btn btn-color" onClick={() => { this.LoadThongKeDoanhSoBS() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-color" onClick={() => { this.ExportThongKeDoanhSoBS() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                            </Form.Item>
                        </div>

                    </div>
                    <div style={{ marginTop: 10, height: 'calc(100% - 80px)' }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                            dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index}
                            pagination={false} />
                        {/* <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size });
                             this.getMaintenance(page, size); }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); 
                            this.getMaintenance(1, size); }}
                            showSizeChanger={true}
                        /> */}
                    </div>
                </div>
            </>
        )
    }
}
