import { Form, Input, notification } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as ManagerStoreService from '../../../services/managerStore';
const initialState = {
    ma: '',
    ten: '',
    diachi: '',
    masothue: '',
    sotk: '',
    dienthoai: '',

}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstUser: [],
        };
    }
    resetState() {
        this.form.setFieldsValue({
            ma: '',
            ten: '',
            diachi: '',
            masothue: '',
            sotk: '',
            dienthoai: '',
        });
        this.setState({
            ma: '',
            ten: '',
            diachi: '',
            masothue: '',
            sotk: '',
            dienthoai: '',
        });
    }
    async CreateNhaCungCap() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })

        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                ma: this.state.ma,
                ten: this.state.ten,
                sotk: this.state.sotk,
                masothue: this.state.masothue,
                diachi: this.state.diachi,
                dienthoai: this.state.dienthoai,
            }
            if (this.validator.allValid()) {
                ManagerStoreService.ThemNCC(data).then(result => {
                    if (result.isSuccess) {
                        this.resetState();
                        notification.success({ message: 'Tạo thành công!' })
                        if (this.props.onSubmit) {
                            this.props.onSubmit(result.returnObject);
                            this.props.onCancel();
                        }
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                }).catch(err => {
                    notification.warning({ message: 'Tạo mới nhà cung cấp bị lỗi!' })
                })
            } else {
                this.validator.showMessages();
            }
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ position: 'relative' }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm nhà cung cấp</h3>
                    </div>
                    <Form ref={(c) => this.form = c} style={{height: 'auto'}}>
                        <div style={{ justifyContent: 'center' }}>
                            <div className="row no-gutters mt-2">
                                <div className="col-md-4">
                                    <Form.Item label="Mã:" name="ma"
                                        rules={[{ required: true, message: 'Yêu cầu nhập mã!' }]}>
                                        <Input maxLength={5}
                                            value={this.state.ma}
                                            onChange={(e) => this.setState({ ma: e.target.value })} />
                                    </Form.Item>
                                </div>
                                <div className="col-md-8">
                                    <Form.Item label="Tên:" name="ten"
                                        rules={[{ required: true, message: 'Yêu cầu nhập tên!' }]}>
                                        <Input
                                            value={this.state.ten}
                                            onChange={(e) => this.setState({ ten: e.target.value })}>
                                        </Input>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2">
                            <div className="col-md-12">
                                <Form.Item label="Địa chỉ:">
                                    <Input
                                        value={this.state.diachi}
                                        onChange={(e) => this.setState({ diachi: e.target.value })} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2">
                            <div className="col-md-4">
                                <Form.Item label="Mã số thuế:">
                                    <Input
                                        maxLength={20}
                                        value={this.state.masothue}
                                        onChange={(e) => this.setState({ masothue: e.target.value })}>
                                    </Input>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Số tài khoản:">
                                    <Input
                                        maxLength={20}
                                        value={this.state.sotk}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value))
                                                return;
                                            this.setState({ sotk: e.target.value })
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item label="Số điện thoại:">
                                    <Input
                                        value={this.state.dienthoai}
                                        onChange={(e) => {
                                            if (isNaN(e.target.value) || e.target.value.length > 12)
                                                return;
                                            this.setState({ dienthoai: e.target.value });
                                            this.validator.showMessageFor("Số điện thoại");
                                        }} />
                                    {this.validator.message("Số điện thoại", this.state.dienthoai, "phonenumber")}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    <button className="btn btn-success"
                        onClick={() => { this.CreateNhaCungCap() }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => { this.resetState(); this.props.onCancel() }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                </div>
            </React.Fragment>
        )
    }
}
