import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import RenderDataInfo from '../../configureHIS/common/renderDataInfo';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';
import { Input, Table, Select, Tooltip, DatePicker } from 'antd';
import * as Actions from '../../libs/actions';
import * as ConfigService from '../../services/configService';
import * as AppointmentService from '../../services/appointmentService';
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import moment from 'moment';
import { isNullOrEmpty } from '../../libs/util';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../configure/systemConfig';

const { TextArea } = Input;

const AppointmentDetail = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _nguonKhachHang = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [inEditmode, setInEditmode] = useState(false);
    const [patient, setPatient] = useState({});
    const [siteCreate, setSiteCreate] = useState();
    const [listSite, setListSite] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [listChiDinh, setListChiDinh] = useState([]);
    const [dataSourceChiDinh, setDataSourceChiDinh] = useState([]);
    const [bangGia, setBangGia] = useState();

    useEffect(() => {
        getListSite();
        if (props.data)
            getAppoimentDetailByID(props.data.id, props.data.chiNhanhHen);
    }, [props.data]);

    const getListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else setListSite([]);
        }).catch(() => { setListSite([]) })
    }

    const getAppoimentDetailByID = (id, site) => {
        Actions.setLoading(true);
        AppointmentService.GetAppoimentDetailByID(id, site).then(result => {
            if (result.isSuccess) {
                let data = result.item.lstChiDinh;
                setPatient(result.item);
                let dataSourceChiDinh = data.map(function (e, index) {
                    let o = Object.assign({}, e);
                    o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
                    o.children = o.listChildCd;
                    return o;
                })
                setDataSourceChiDinh(dataSourceChiDinh);
                setListChiDinh(data);
                setBangGia(data.find(x => x.banggia !== "default") ? data.find(x => x.banggia !== "default").banggia : "default");
                setSiteCreate(result.item.chinhanh);
                forceUpdate();
            } else {
                Actions.openMessageDialog("Thông báo" + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        }).finally(() => {
            Actions.setLoading(false);
        })
    };

    const regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title: 'Đơn giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        {
            title: 'Số lượng',
            render: (row) => (
                <span>{1}</span>
            ),
            align: 'center',
            width: '15%'
        },
        {
            title: 'Thành tiền',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 100,
            render: (value, row) => {
                return (
                    row.isChild ? "" :
                        (<Tooltip title={row.dongia && row.dongia.length > 0 && "Thuộc chương trình tặng dịch vụ"}><span>{(value).toLocaleString('en-US')}</span></Tooltip>)
                )
            }
        },
    ];

    const checkBangGia = (info) => {
        let banggia = bangGia;
        let itemFound = listChiDinh.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        let banggianguonkhachhang = bangGia;
        if (info.banggia !== null) {
            banggianguonkhachhang = info.banggia
        }
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && banggianguonkhachhang !== banggia) {
            if (listChiDinh.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${banggianguonkhachhang === "default" ? "Mặc định" : banggianguonkhachhang}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                cancelExam();
                banggia = banggianguonkhachhang;
            }
            setBangGia(banggia);
        } else setBangGia(banggianguonkhachhang);
    }

    const cancelExam = () => {
        setListChiDinh([]);
    }

    return (
        <Fragment>
            {patient &&
                <Fragment>
                    {/* <RenderDataInfo
                        data={
                            [
                                {
                                    label: "Tên bệnh nhân",
                                    value: patient.tenbn,
                                    column: 6,
                                },
                                {
                                    label: "Mã bệnh nhân",
                                    value: patient.mabn,
                                    column: 6,
                                },
                                {
                                    label: "Ngày sinh",
                                    value: patient.onlyyear ? "Không ghi nhận" : moment(patient.ngaysinh).format("DD-MM"),
                                    column: 6,
                                },
                                {
                                    label: "Năm sinh",
                                    value: moment(patient.ngaysinh).format("YYYY"),
                                    column: 6,
                                },
                                {
                                    label: "Giới tính",
                                    value: patient.gioitinh === 0 ? "Nam" : "Nữ",
                                    column: 6,
                                },
                                {
                                    label: "CMND/CCCD",
                                    value: patient.cmnd,
                                    column: 6,
                                },
                                {
                                    label: "Địa chỉ",
                                    value: patient.diachi,
                                    column: 12,
                                },
                                {
                                    label: "Điện thoại",
                                    value: patient.dienthoai,
                                    column: 6,
                                },
                                {
                                    label: "Thời gian",
                                    value: moment(selectedDate).format(FORMAT_DATE_TIME),
                                    column: 6,
                                },
                            ]
                        }
                    /> */}
                    <div className="row">
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:</span><b>{patient.tenbn}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:</span><b>{patient.mabn}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:</span><b>{patient.onlyyear ? "Không ghi nhận" : moment(patient.ngaysinh).format("DD-MM")}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:</span><b>{moment(patient.ngaysinh).format("YYYY")}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:</span><b>{patient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND:</span> <b>{patient.cmnd}</b></div>
                        <div className="col-12 d-flex mb-2"><span style={{ width: '100px' }}>Địa chỉ:</span><b>{patient.diachi}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:</span><b>{patient.dienthoai}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Thời gian:</span>
                            <DatePicker locale={viVN} style={{ width: '200px' }}
                                allowClear={false} showTime format={SystemConfig.FORMAT_DATE_TIME}
                                onChange={(e) => { setSelectedDate(e) }} value={moment(selectedDate)}
                                disabledDate={(current) => (current && current.valueOf() < Date.now())}
                                disabled={!inEditmode} placeholder="Ngày-tháng-năm giờ:phút" />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <Nguonkhachhang
                            disable={!inEditmode}
                            ref={_nguonKhachHang}
                            managermentId={patient.maql}
                            updateInfoBangGia={(info) => { checkBangGia(info) }}
                            banggiaSite={bangGia} />
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                            <Select
                                placeholder="Chọn chi nhánh"
                                disabled={!inEditmode}
                                className="w-100"
                                value={siteCreate}
                                onChange={(value => setSiteCreate(value))}>
                                {listSite && listSite.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.ten}</option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 d-flex my-2"><span style={{ width: '100px' }}>Ghi chú:</span><b>{patient.ghichu}</b></div>
                        {!isNullOrEmpty(patient.ghichuHotline) && <div className="col-12 d-flex mb-2">
                            <label>Ghi chú hotline:</label>
                            <TextArea rows={3} value={patient.ghichuHotline} readOnly={true}></TextArea>
                        </div>}
                    </div>
                    <div className="mt-2" style={{ height: 'calc(100% - 500px)' }} >
                        <Table dataSource={dataSourceChiDinh}
                            rowKey={(e) => (e.key ?? e.idChiDinh)}
                            columns={regiterServiceColumn}
                            pagination={false}
                            scroll={{ y: 0 }}
                        />
                    </div>
                    <div className='w-100 d-flex justify-content-center align-items-center mt-2'>
                        <button className="btn btn-danger ml-2"
                            onClick={() => {
                                props.onCancel && props.onCancel();
                            }}
                        ><i className="fas fa-sign-out-alt mr-1" />Đóng
                        </button>
                    </div>
                </Fragment>}
        </Fragment>
    )
})

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        availableSites: state.global.availableSites
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AppointmentDetail);