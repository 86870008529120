import React, { Fragment, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify';
import { DatePicker, Table, Pagination, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import Input from 'antd/lib/input/Input';
import moment from 'moment';
import * as SystemConfig from "../../../configure/systemConfig";
import * as PromotionalCampagin from '../../../../src/services/PromotionServices';
import * as InvoiceService from '../../../services/invoiceService';
import BillDetail from './billdetail';
import PatientDetail from '../../../screens/patient/thongtinbenhnhan';
import * as Actions from '../../../libs/actions';
import TagVertical, { COLOR } from '../../../configureHIS/common/TagVertical';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { RangePicker } = DatePicker;
const CURRENTCY = "";

export default forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const _hiddenLink = useRef();
    const [promotionName, setPromotionName] = useState("");
    const [promotionInfo, setPromotionInfo] = useState([]);
    const [rangeDateInUsed, setRangeDateInUsed] = useState([null, null]);
    const [key, setKey] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listVouchers, setListVouchers] = useState([]);
    const [lstPaymentDetail, setLstPaymentDetail] = useState([]);
    const [visiblePay, setVisiblePay] = useState(false);
    const [selectedBillId, setSelectedBillId] = useState('');
    const [visiblePatient, setVisiblePatient] = useState(false);
    const [patientID, setPatientID] = useState('');
    const tableColumns = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 120,
            render: (patientName, record) => {
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        loadPatientDetail(record.patientId);
                    }}>{patientName}</a>
            },
            sorter: (a, b) => a.patientName.localeCompare(b.patientName),
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'billId',
            key: 'billId',
            width: 90,
            render: (billId, record) => {
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        loadPaymentDetail(record);
                    }}>{billId}</a>
            }
        },
        {
            title: 'Mã voucher',
            dataIndex: 'voucherCode',
            key: 'voucherCode',
            width: 90,
        },
        {
            title: 'Ngày áp dụng',
            dataIndex: 'usedDate',
            key: 'usedDate',
            width: 100,
            render: (date) => (date && moment(date).format(SystemConfig.FORMAT_DATE_TIME)),
            sorter: (a, b) => moment(a.usedDate) - moment(b.usedDate),
        },
        // {
        //     title: 'Số tiền KM',
        //     dataIndex: 'discountValue',
        //     key: 'discountValue',
        //     width: 80,
        //     align: "right",
        //     render: (totalDiscount) => (totalDiscount && totalDiscount.toLocaleString("en-US") + " " + CURRENTCY)
        // },
        {
            title: 'Thực thu',
            dataIndex: 'paid',
            key: 'paid',
            width: 80,
            align: "right",
            render: (paid) => (paid && paid.toLocaleString("en-US"))
        },
    ]

    useEffect(() => {
        const data = props.data;
        if (props.dateSelected) {
            setRangeDateInUsed(props.dateSelected);
        }
        setPromotionName(data.promotionName);
    }, [props.data, props.dateSelected, props.site]);

    const loadListVouchers = (currentPage = page, currentSize = size, keyword = key, rangeDate = rangeDateInUsed, promotionId = props.data.id) => {
        let sortingAndPagingModel = { pageIndex: currentPage, pageSize: currentSize };
        let filterModel = {
            keyword: keyword !== undefined && keyword !== null ? keyword : "",
            usedDateFrom: rangeDate !== null ? rangeDate[0] : null,
            usedDateTo: rangeDate !== null ? rangeDate[1] : null,
            site: props.site ? props.site : "",
            allSite: props.allSite ? props.allSite : false,
        };
        notiRef && notiRef.current && notiRef.current.Close();
        PromotionalCampagin.getListUsedVouchersByPromotion(promotionId, sortingAndPagingModel, filterModel).then(res => {
            if (res.isSuccess) {
                setListVouchers(res.data.items);
                setTotalRecords(res.data.totalCount);
                const colorList = [COLOR.Primary, COLOR.Success, COLOR.Warning];
                let dataSumar = res.data.returnObject.map((x, index) => ({
                    title: x.label, color: colorList[index],
                    value: x.label.toLowerCase().includes("thực thu".toLowerCase()) || x.label.toLowerCase().includes("tiền".toLowerCase())
                        ? <span><u>đ</u>&nbsp;{x.value.toLocaleString('en-US')}</span>
                        : x.value.toLocaleString('en-US')
                }));
                setPromotionInfo(dataSumar);
                if (res.data.items.length === 0 || res.data.totalCount === 0)
                    notiRef.current.Show("Không tìm thấy dữ liệu", NotifyStatus.Warning);
            } else {
                notiRef.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch(() => { })
    }

    const exportListVouchers = (currentPage = page, currentSize = size, keyword = key, rangeDate = rangeDateInUsed, promotionId = props.data.id) => {
        Actions.setLoading(true);
        let sortingAndPagingModel = { pageIndex: currentPage, pageSize: currentSize };
        let filterModel = {
            keyword: keyword !== undefined && keyword !== null ? keyword : "",
            usedDateFrom: rangeDate !== null ? rangeDate[0] !== null ? rangeDate[0] : null : null,
            usedDateTo: rangeDate !== null ? rangeDate[1] !== null ? rangeDate[1] : null : null,
            site: props.site ? props.site : "",
            allSite: props.allSite ? props.allSite : false,
        };
        notiRef && notiRef.current && notiRef.current.Close();
        PromotionalCampagin.exportListUsedVouchers(promotionId, sortingAndPagingModel, filterModel)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "DanhSachVoucherDaSuDung";
                    _hiddenLink.current.click();
                    Actions.setLoading(false);
                })
            }).catch(() => {
                Actions.setLoading(false);
            })
    }

    const onCloseDrawer = () => {
        props.onClose();
    }

    useImperativeHandle(ref, () => ({
        reload: () => {
            setPage(1);
        },
        loadDetail: () => {
            let currentPage = 1;
            const data = props.data;
            let date = [null, null];
            if (props.dateSelected) {
                date = props.dateSelected;
            }
            loadListVouchers(currentPage, size, key, date, data.id);
        }
    }));

    const loadPaymentDetail = (data) => {
        InvoiceService.PatientPaymentInvoiceDetail(data.billId, moment(data.ngaylap).format('YYYY-MM-DD'), false).then(result => {
            if (result.isSuccess) {
                setSelectedBillId(data.billId);
                setLstPaymentDetail(result.data)
                setVisiblePay(true);
            }
            else {
                notiRef.current.Show(result.err.msgString, NotifyStatus.Warning);
            }
        }).catch(err => {
            notiRef.current.Show(err, NotifyStatus.Error);
        })
    }

    const loadPatientDetail = (patientID) => {
        setPatientID(patientID);
        setVisiblePatient(true);
    }

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink}></a>
            <HeaderModal title={`Xem chi tiết - ${promotionName}`} onClose={() => { onCloseDrawer() }} />
            {promotionInfo && promotionInfo.length > 0 &&
                <TagVertical data={promotionInfo} />
                // promotionInfo.map((info, index) => {
                //     return (
                //         <div className={`col-md-${info.column ? info.column : 12} d-flex ${index > 0 && "mt-2"}`} key={index}>
                //             <span style={{ width: `${info.labelWidth ? `${info.labelWidth}px` : "200px"}` }}>{info.label}</span>
                //             <span style={{ width: `${info.infoWidth ? `${info.infoWidth}px` : "90px"}`, textAlign: "right", }}>
                //                 <strong className="ml-2">
                //                     {info.value !== null && info.value !== undefined && info.value !== ""
                //                         ? typeof (info.value) == "number" ? info.value.toLocaleString("en-US") : info.value
                //                         : "Không có thông tin"
                //                     }</strong>
                //             </span>
                //         </div>
                //     )
                // })
            }
            {/* <div className="custom-hr-doted"></div> */}
            <div className="row">
                <div className='col-12 mt-2'>
                    <Notify ref={notiRef} id="promotion-notify" />
                </div>
                <div className='col-12'>
                    <label>Ngày áp dụng</label>
                    <RangePicker id="promodetail-dpkr-userange" format="DD-MM-YYYY" value={rangeDateInUsed} allowClear={true}
                        locale={viVN} onChange={value => setRangeDateInUsed(value)}
                    />
                </div>
                <div className='col-12 mt-2'>
                    <label>Từ khóa</label>
                    <Input
                        id="promodetail-ipt-key" value={key} className="w-100"
                        onChange={(e) => {
                            setKey(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setPage(1);
                                loadListVouchers(1);
                            }
                        }}
                        placeholder="Nhập tên khách hàng, mã voucher">
                    </Input>
                </div>
                <div className='col-12 text-right mt-2'>
                    <button className='btn btn-primary' id="promodetail-btn-fnd"
                        onClick={() => { notiRef && notiRef.current && notiRef.current.Close(); setPage(1); loadListVouchers(1) }}
                    ><i className='fa fa-search mr-1'></i>Tìm kiếm</button>
                    <button className='btn btn-secondary ml-2' id="promodetail-btn-clr"
                        onClick={() => {
                            setRangeDateInUsed([null, null])
                            setKey(null);
                            setPage(1);
                            loadListVouchers(1, size, null, null);
                        }}
                    ><i className='fa fa-eraser mr-1'></i>Xóa dữ liệu nhập</button>
                </div>
                <div className="col-12 text-right mt-2">
                    <button className='btn btn-secondary' id="promodetail-btn-exl" onClick={() => exportListVouchers()}><i className='fa fa-file-excel mr-1'></i>Xuất danh sách</button>
                </div>
                <div className='col-12 mt-2'>
                    <Table
                        columns={tableColumns}
                        dataSource={listVouchers}
                        pagination={false}
                        rowKey={(row, index) => index}
                        scroll={{ x: "max-content" }}
                        locale={{
                            triggerDesc: "Bấm để sắp xếp giảm dần",
                            triggerAsc: "Bấm để sắp xếp tăng dần",
                            cancelSort: "Bấm để hủy bỏ sắp xếp",
                        }}
                    />
                </div>
                <div className='col-12 mt-2'>
                    <Pagination
                        className="text-center mt-2 pb-2"
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        size={size}
                        current={page}
                        onChange={(page, size) => {
                            setPage(page);
                            setSize(size);
                            loadListVouchers(page, size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setPage(1);
                            setSize(size);
                            loadListVouchers(1, size)
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <Modal
                width={1000}
                visible={visiblePay}
                maskClosable={true}
                onClose={() => setVisiblePay(false)}>
                <BillDetail lstPaymentDetail={lstPaymentDetail} billId={selectedBillId} onClose={() => setVisiblePay(false)} />
            </Modal>
            <Modal
                width={1000}
                visible={visiblePatient}
                maskClosable={true}
                onClose={() => setVisiblePatient(false)}>
                <PatientDetail patientID={patientID} isPreview={true} onCancel={() => setVisiblePatient(false)} />
            </Modal>
        </Fragment >
    );
})
