import { Modal, Tabs, Upload, Button } from 'antd';
import React, { Component } from 'react';
import * as PascService from '../../services/pascService';
const { TabPane } = Tabs;

export default class chidinh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewImage: false, previewVisible: false, previewTitle: '', fileList: [],
            lstCount: undefined
        }

    }

    componentDidMount() {
        this.loadimage(this.props.item);

        let lstCount = [];
        for (let index = 0; index < Number(this.props.item.slthai); index++) {
            lstCount.push(index);
        }

        this.setState({ lstCount })
    }


    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        let previewImage = file.url || file.preview;
        let previewVisible = true;
        let previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);

        this.setState({ previewImage, previewVisible, previewTitle })
    };

    SortImages() {
        let data = this.state.fileList;
        data.sort(function (a, b) {
            return Number(a.name.split('.')[0]) - (b.name.split('.')[0]);
        });
        this.setState({ fileList: data.map(x => ({ ...x })) })
    }

    loadimage(data) {
        let fileList = []
        let index = 0;
        for (let item of data.images) {
            PascService.loadImage(item.maql, item.idcd, item.filename).then(result => {
                result.blob().then((blob) => {
                    let urlTemp = window.URL.createObjectURL(blob);

                    let temp = {
                        uid: item.maql + item.filename,
                        name: item.filename,
                        status: 'done',
                        response: 'Server Error 500', // custom error message to show
                        url: urlTemp,
                    }
                    index++;
                    fileList.push(temp);
                    this.setState({ fileList }, () => {
                        if (index === data.images.length) {
                            this.SortImages();
                        }
                    })
                })
                //  this.setState({ lstData: lstDatatmp, lstCount: count })

            }).catch(err => {
                //    notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
        }
    }



    render() {

        let { item } = this.props;

        let { fileList, previewVisible, previewTitle, previewImage, lstCount } = this.state

        return (
            <div style={{ padding: '10px' }} >
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <strong style={{ fontSize: '24px' }}>{`${item.tenloai !== null ? item.tenloai : ''}`}</strong>
                    {item.loaidichvu === '26' && <span style={{ width: 250, display: 'none' }}>{`SL thai: ${item.slthai}`}</span>}
                    {item.loaidichvu === '26' && <span style={{ width: 250, display: 'none' }}>{`Cử động thai: ${item.cudongthai !== null ? item.cudongthai : ''}`}</span>}
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 2 }}>
                        <p style={{ color: 'black', textAlignLast: 'left' }}><strong>Mô tả:</strong></p>
                        <div className='table-collapse-pasc table-collapse-pascDetails text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${item.mota !== null ? item.mota : ''}` }}></div>
                        <div style={{ display: "flex", margin: "10px 0px", textAlignLast: 'left' }}>
                            <p style={{ color: 'black', }}><strong>Kết luận:</strong>
                                <div className='table-collapse-pasc table-collapse-pascDetails text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${item.ketluan ?? ''}` }}></div>
                            </p>
                        </div>
                        {lstCount !== undefined &&
                            <Tabs animated={true} style={{ display: 'none' }}>
                                {
                                    lstCount.map((i) => {
                                        return (

                                            <TabPane tab={item.loaidichvu === "26" ? `Thông tin: ${i + 1}` : "Thông tin:"} key={`Thông tin thai: ${i + 1}`} >
                                                <div style={{
                                                    marginTop: 10, gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))'
                                                    , display: 'grid', gridGap: '5px',
                                                }}>

                                                    {item.lstChiTiet[i].lstChiTiet.map((row, index) => {
                                                        return (
                                                            <div key={"abc" + index} style={{ display: 'flex' }}>
                                                                <span style={{ width: 200, textAlign: 'right', marginRight: 5 }}>{row.ten + ': '} </span> <span>  {row.ketqua + ' ' + row.dvt}</span>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </TabPane>
                                        )
                                    })
                                }
                            </Tabs>
                        }
                    </div>
                    <div style={{ flex: 1, backgroundColor: 'aliceblue' }}>
                        <Upload
                            showUploadList={{
                                showRemoveIcon: false
                            }}
                            className={"historyUpload"}
                            listType="picture-card"
                            fileList={fileList}
                            style={{ height: '100%' }}
                            onPreview={this.handlePreview.bind(this)}
                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                            itemRender={(originNode, file, fileList) => {
                                return <div className="w-50">
                                    {originNode}
                                </div>
                            }}
                        >
                            {fileList.length >= 8 ? null : null}
                        </Upload>

                    </div>
                </div>
                <Modal maskClosable={false}
                    width={0}
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => {
                        this.setState({ previewVisible: false })
                    }}
                >
                    <div>
                        <div style={{
                            position: 'fixed', top: 30, left: 100, right: 100, bottom: 30, backgroundColor: 'rgba(0,0,0,0)',
                            display: 'flex', alignContent: 'center', justifyContent: 'center'
                        }}>

                            <img alt="example" style={{ width: '65%' }} src={previewImage} />
                            <Button onClick={() => this.setState({ previewVisible: false })}>Đóng</Button>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}
