import { Button, notification, Collapse } from 'antd';
import moment from 'moment';
import React, {Fragment ,useEffect, useRef, useState } from 'react';
import History from '../../../components/history/index';
import Upload from '../../recept/register/upload';
import * as PatientService from '../../../services/patientService';
import { FORMAT_DATE } from '../../../configure/systemConfig';
import { HISTORYFEATURE } from '../../../configureHIS/common/constants';
import PatientInfo from '../../patient/component/patientInfo';
import VitalSign from '../../patient/component/vitalSign';
import HistoryNew from '../../../components/history/indexNew';
import UploadNew from '../../../components/MitaComponents/UploadFile/mitaUploadFile';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { Panel } = Collapse;

export default function XemHoSo(props) {
    const [patient, setPatient] = useState({});
    const [mabn, setMabn] = useState('');
    const historyRef = useRef();

    useEffect(() => {
        setMabn(props.patientId);
        historyRef.current && historyRef.current.getListHistory();
    }, [props.patientId])   

    useEffect(() => {
        if(mabn !== '')
        GetPatientDetail();
    }, [mabn])

    const GetPatientDetail = () => {
        PatientService.GetPatientDetail(mabn).then(result => {
            if (result.isSuccess) {
                setPatient(result.data)
            } else {
            notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }
    return(
    <Fragment>
        <HeaderModal title="Hồ sơ khám" onClose={() => {props.onClose()}} />
        <div>
        <Collapse defaultActiveKey={['pif', 'history', 'attachment']} expandIconPosition="right">
            <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                <PatientInfo patientId={mabn} showContact={true} isViewInfo={true}/>
            </Panel>
            <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="history">
                <div className="row my-2 px-2" style={{ lineHeight: "20px" }}>
                    <HistoryNew 
                        patientID={props.patientId} 
                        maxResult={3} 
                        feature= {[HISTORYFEATURE.CDHA, HISTORYFEATURE.XETNGHIEM, HISTORYFEATURE.KHAMBENH, HISTORYFEATURE.THUTHUAT]}
                    />
                </div>
            </Panel>
            <Panel header={<label className='mita-header-title'>Đính kèm hồ sơ</label>} key="attachment">
                <div className="row my-2 px-2" style={{ lineHeight: "20px" }}>
                    <UploadNew 
                        featureName="patient" 
                        componentKey="patientInfo" 
                        layout="Details"
                        extention=".png, .jpg, .jpge, .docx, .doc, .pdf"
                        isEdit={true}  
                        refId={props.patientId}
                    />
                </div>
            </Panel>
        </Collapse>
        </div>
    </Fragment>
    ) 
}