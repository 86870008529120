import moment from "moment";
import { connect } from 'react-redux';
import React, { useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Table, Pagination, Select, DatePicker, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Action from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as ReportServices from '../../../services/ReportService';
import * as ProductServices from "../../../services/ProductService";
import * as NhanVienSerivce from '../../../services/employeeService';
import SiteFilter from "../../../configureHIS/common/siteFilter";
import Notify, { NotifyStatus } from '../../../components/notify';
import CoporationReport from "../../../components/coporation-report";

const { Option } = Select;

const BaoCaoServiceSalesman = forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const now = new Date();
  //#endregion
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const _coporationSelect = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  const [salesmanIds, setSalesmanIds] = useState();
  const [dateFrom, setDateFrom] = 
  useState(new moment());
  // useState(new moment((now).setMonth(now.getMonth() - 1)));
  const [dateTo, setDateTo] = useState(new moment());
  const [nhomIds, setNhomIds] = useState([]);
  const [loaiIds, setLoaiIds] = useState([]);
  const [vienPhiIds, setVienPhiIds] = useState([]);
  // const [salesmanIds, setSalesmanIds] = useState([]);
  const [selectedNhomShortcutKey, setSelectedNhomShortcutKey] = useState([]);
  //model result
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //other
  const [listCoporations, setListCoporations] = useState([]);
  const [listNhomShortcut, setListNhomShortcut] = useState([]);
  const [listSalesmans, setListSalesmans] = useState([]);
  const [salesmanIdCoporations, setSalesmanIdCoporations] = useState([]);
  // const [salesmanNameSelected, setSalesmanNameSelected] = useState();
  
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    getListServiceType();
    getListSalesmans();
    // getListNhomVP();
  }, []);
  
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    setNhomIds([]);
    setLoaiIds([]);
    selectedNhomShortcutKey.map(c => {
      let nhom = listNhomShortcut.filter(x => x.keyValue == c);
      if (nhom.length > 0) {
        let selectedNhomShortcut = nhom[0];
        if (selectedNhomShortcut.loaiId > 0){
          setLoaiIds(u => [...u, selectedNhomShortcut.loaiId]);
        } else {
          setNhomIds(u => [...u, selectedNhomShortcut.nhomMa]);
        }
      }
    })
  },[selectedNhomShortcutKey])
  
  useEffect(() => {
    if ((salesmanIds ?? []).length > 0) setSalesmanIdCoporations(salesmanIds);
    else setSalesmanIdCoporations(listSalesmans.map(x=>x.id));
  },[salesmanIds, listSalesmans])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex, sorting, pageSize]);

  //#endregion 
  
  //#region get list data cho bộ lọc
  function getListSalesmans() {
    NhanVienSerivce.getEmployeeListRef().then(result => {
      if (result.isSuccess) {
        setListSalesmans(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        forceUpdate();
    })
  }

  const getListServiceType = () => {
    ProductServices.nhomGetListShortcut().then(result => {
      if (result.isSuccess) {
        setListNhomShortcut(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Action.setLoading(false);
        forceUpdate();
    })
  }
  //#endregion 
  //#region get data
  const getData = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      coporationIds: _coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId(),// ? [coporationId] : [],
      nhomIds: nhomIds,
      loaiIds: loaiIds,
      salesmanIds: salesmanIds,// ? [Number(salesmanId)] : [],//salesmanIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getServiceSalesmanReportList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        setTotalAmount(result.data.returnObject);
        notiRef && notiRef.current.Close();
        forceUpdate();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
      Action.setLoading(false);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
      Action.setLoading(false);
    }).finally(() => {
    })
  }
  //#endregion 

  const onExportExcelClinkHandle = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      coporationIds: _coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId(),// ? [coporationId] : [],
      nhomIds: nhomIds,
      loaiIds: loaiIds,
      vienPhiIds: vienPhiIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      
      // skipCount: (pageIndex - 1) * pageSize,
      // maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getServiceSalesmanReportListExport(filterModel).then(result => {
      let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_BaoCaoDichVuDonViGui`;
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = fileName;
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        Action.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Action.setLoading(false);
      forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  // , Tên nhân viên, tên bệnh nhân, Tên dịch vụ, Tên nhóm - loại, Thanh toán, Số phiếu, Nguồn khách hàng
  const columns = [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 55,
        render: (text, record, index) => {
          return <span>{ record.isChild ? '' : (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'salesmanName',
      key: 'salesmanName',
      align: 'left',
      width: 170,
      sorter: () => {},
    },
    {
      title: 'Tên bệnh nhân',
      dataIndex: 'patientName',
      key: 'patientName',
      align: 'left',
      width: 170,
      sorter: () => {},
    },
    {
        title: 'Tên dịch vụ',
        dataIndex: 'vienPhiTen',
        key: 'vienPhiTen',
        align: 'left',
        width: 120,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${text}${(record.isChildInCombo ? " (combo)" : "")}` }</span>;
        }
    },
    // Table.EXPAND_COLUMN, //không dùng được
    {
        title: 'Nhóm dịch vụ',
        dataIndex: 'nhomTen',
        key: 'nhomTen',
        align: 'left',
        width: 170,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${(record.isChild ? "" : text)}` }</span>;
        }
    },
    {
        title: 'Loại dịch vụ',
        dataIndex: 'loaiTen',
        key: 'loaiTen',
        align: 'left',
        width: 170,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${(record.isChild ? "" : text)}` }</span>;
        }
    },
    {
        title: 'Thanh toán',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'right',
        width: 100,
        render: (value) => (<span>{isNaN(value) ? '' :Number(value).toLocaleString('en-US')}</span>),
    },
    {
        title: 'Số phiếu',
        dataIndex: 'billNumber',
        key: 'billNumber',
        align: 'center',
        width: 90,
        sorter: () => {},
    },
    {
        title: 'Nguồn khách hàng',
        dataIndex: 'tenNguonKhachHang',
        key: 'tenNguonKhachHang',
        align: 'left',
        width: 170,
        sorter: () => {},
    },
    {
      title: 'Nguồn khách hàng tổng',
      dataIndex: 'coporationParentName',
      key: 'coporationParentName',
      align: 'left',
      width: 170,
      sorter: () => {},
    },
    {
      title: 'Nhóm nguồn con',
      dataIndex: 'coporationChildName',
      key: 'coporationChildName',
      align: 'left',
      width: 170,
      sorter: () => {},
    },
  ];

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Báo cáo dịch vụ đơn vị gửi</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Nhân viên kinh doanh | Ngày lập phiếu | Hình thức thanh toán */}
                  <div className='col-md-6 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Ngày đăng ký</label>
                    </div>
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-6 d-flex flex-column'>{/* Nhân viên kinh doanh */}
                    <div>
                      <label>Nhân viên kinh doanh</label>
                      {(listSalesmans ?? []).length == 0 &&
                      <Tooltip title="Nếu danh sách Nhân viên kinh doanh không có người nào, có nghĩa là tài khoản đang đăng nhập chưa được liên kết với nhân viên">
                        <label><i className="fas fa-info-circle ml-1"></i></label>
                      </Tooltip>
                      }
                    </div>
                    <Select
                      mode="multiple"  
                      allowClear
                      showSearch
                      className="w-100"
                      placeholder="Chọn nhân viên kinh doanh"
                      maxTagCount= 'responsive'
                      value={ salesmanIds }
                      optionLabelProp="hoTen" //"hoTen" này là cáo prop  hoTen={record.hoTen} trong thẻ <Option>
                      filterOption={(input, option) => {
                        return (option?.code ?? '').toLowerCase().includes(input.toLowerCase()) 
                          || (option?.hoTen ?? '').toLowerCase().includes(input.toLowerCase())
                          || (option?.phone ?? '').toLowerCase().includes(input.toLowerCase())
                          || (option?.header ?? false)
                      }}
                      onChange={(value, obj) => {
                        setSalesmanIds(value);
                      }}
                    >
                    <Option key={-1} value={-1} header={true} disabled style={{color:'black', fontWeight:'bold'}}>
                        <div className="row">
                          <div className='col-md-3'>Mã</div>
                          <div className='col-md-6'>Tên</div>
                          <div className='col-md-3'>Điện thoại</div>
                        </div>
                      </Option>
                    {(listSalesmans ?? []).map((record, index) => {
                      return (
                        <Option value={record.id} key={record.id} code={record.code} hoTen={record.hoTen} phone={record.phone} label={record.hoTen}>
                          <div className="row">
                            <div className='col-md-3'>{record.code}</div>
                            <div className='col-md-6'>{record.hoTen}</div>
                            <div className='col-md-3'>{record.phone}</div>
                          </div>
                        </Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
                <div className="row mt-2">{/* Nguồn khách hàng | Nhóm dịch vụ */}
                  <div className='col-md-6 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <CoporationReport ref={_coporationSelect} salesmanIds={salesmanIdCoporations} modeMultiple={true} allowClear={true} />
                  </div>
                  <div className='col-md-6 d-flex flex-column'>{/* Nhóm dịch vụ */}
                    <div>
                      <label>Nhóm dịch vụ</label>
                    </div>
                    <Select
                      allowClear
                      mode="multiple"
                      className="w-100"
                      maxTagCount= 'responsive'
                      placeholder="Chọn nhóm dịch vụ"
                      value={ selectedNhomShortcutKey }
                      onChange={(value) => {setSelectedNhomShortcutKey(value);}}
                    >
                    {(listNhomShortcut ?? []).map((record, index) => {
                      return (
                        <Option key={record.keyValue} value={record.keyValue}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              <div className="row mt-2">{/* button */}
                <div className='col-md-12 d-flex justify-content-end'>{/* tổng tiền | button thống kê | xuất excel */}
                  <h4>Tổng tiền: {totalAmount.toLocaleString("en-US")}</h4>
                  <button 
                    className='btn btn-color ml-2' 
                    onClick={() => {
                      resetToFirstPage();
                    }}
                  >
                    <i className='far fa-chart-bar mr-2'></i>
                    Thống kê
                  </button>
                  <button className="btn btn-color ml-2" type="button" 
                      onClick={() => { onExportExcelClinkHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                  </button>
                </div>
              </div>
            </div>
            <div className="list-page-table mt-2">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.key}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
});
const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps)(BaoCaoServiceSalesman)