import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { Button, Checkbox, Dropdown, Input, List, Menu, Popover, Select } from 'antd';
import { BUTTON } from '../../../configureHIS/common/constants';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { groupBy, isNullOrEmpty, renumber } from '../../../libs/util';
import * as InvoiceService from '../../../services/invoiceService';
import * as Action from '../../../libs/actions';
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import Handlebars from 'handlebars';
import handlebarHelper from "../../../libs/handlebarHelper";
import * as PrintService from '../../../services/printService';

const Refund = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reloadInfo: (pid, mid) => {
            getListRefund(pid, mid);
            notiRef && notiRef.current && notiRef.current.Close();
        },
    }));

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const notiRef = useRef();

    // useEffect(() => {
    //     getListRefund();
    //     notiRef && notiRef.current && notiRef.current.Close();
    // }, [props]);
    const [listRefund, setListRefund] = useState([]);

    const getListRefund = (pid = props.patientCode, mid = props.managementId) => {
        Action.setLoading(true);
        InvoiceService.getListRefund(pid, mid).then(res => {
            if (res.isSuccess) {
                setListRefund(res.data);
            }
        }).catch(err => {

        }).finally(() => {
            Action.setLoading(false);
        })
    }

    const printRefund = (refundNumber, managementId) => {
        Actions.setLoading(true);
        PrintService.PrintRefund(refundNumber).then((result) => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Actions.DoPrint(template(result.data));
                }
            else Actions.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    return (
        <Fragment>
            <Notify ref={notiRef} />
            {listRefund && listRefund.map((rept, reptIndex) => {
                return (
                    <div className='w-100 p-2'>
                        <div className='row'>
                            <div className='col-4'>
                                <Popover content={<div style={{ whiteSpace: "pre-line" }}>{rept.tag ? rept.tag : "Ghi chú (nếu có)"}</div>} placement='right'>
                                    <a href='#'>{rept.refundBillNumber ?? ""}<i className="fas fa-info-circle ml-1" /></a>
                                </Popover>
                            </div>
                            <div className='col-5'>
                                <i class="far fa-clock mr-1" />{rept.refundDate && moment(rept.refundDate).format(FORMAT_TIME_DATE)}
                            </div>
                            <div class="ml-auto mr-3">
                                <label className='mr-3'>Thu ngân: {rept.cashier}</label>
                                <label style={{ marginRight: 5 }} onClick={() => printRefund(rept.refundBillNumber)}>
                                    <i style={{ fontSize: 20 }} className="fas fa-print"></i>
                                </label>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                                    <div>
                                        <label className='mr-2'>HOÀN TRẢ</label>
                                        <label style={{ color: "#b13431" }}>{Number(rept.refundTotal ?? 0).toLocaleString("en-US")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {rept.listServices && rept.listServices.length > 0 &&
                            <Fragment>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <label className='d-flex align-items-center'><i class="fas fa-medkit mr-3" style={{ fontSize: 20 }}></i><span style={{ color: "#636363" }}>DỊCH VỤ</span></label>
                                    </div>
                                </div>
                                <div className="list-page">
                                    <div className="list-page-body">
                                        <div className="list-page-table mt-2">
                                            <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                                                bordered={false}
                                                dataSource={rept.listServices && rept.listServices.length > 0 && renumber(Object.keys(groupBy(rept.listServices, "groupName")).map((key) => ({ key, value: groupBy(rept.listServices, "groupName")[key] })))}
                                                renderItem={(record, index) => {
                                                    let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";
                                                    return (
                                                        <Fragment>
                                                            <div style={{ fontWeight: "bold" }}>{record.key}</div>
                                                            {
                                                                record.value.map((r, i) => {
                                                                    return (
                                                                        <List.Item key={r.name} className={className}>
                                                                            <div className='d-flex flex-column w-100'>
                                                                                <div className="d-flex justify-content-between w-100">
                                                                                    <div style={{ width: "40%" }}><label>{r.name}</label></div>
                                                                                    <div style={{ width: "15%" }}>
                                                                                        <a href='#' style={{ textDecoration: "underline" }}>
                                                                                            {r.subServices && r.subServices.length > 0 ? "" : r.status}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div style={{ width: "45%" }} className='d-flex flex-row justify-content-end'>
                                                                                        <Fragment>
                                                                                            <div style={{ display: "flex", justifyContent: "end", width: "100%", }}>
                                                                                                <div className='ml-2'>
                                                                                                    <label className='mr-2'>Hoàn trả</label>
                                                                                                    <label style={{ color: "#b13431" }}>{Number(r.total ?? 0).toLocaleString("en-US")}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-between w-100">
                                                                                    {
                                                                                        r.subServices && <div style={{ width: "100%" }}>
                                                                                            {r.subServices.map((sub, index) => {
                                                                                                return (
                                                                                                    <div className='mt-2 d-flex justify-content-between w-100' key={`${index}-${sub.name}`}>
                                                                                                        <div style={{ width: "40%", paddingLeft: 15 }}>{sub.name}</div>
                                                                                                        <div style={{ width: "15%" }}><a href='#' style={{ textDecoration: "underline" }}>{sub.status}</a></div>
                                                                                                        <div style={{ width: "45%" }}>&nbsp;</div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </List.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Fragment>
                                                    )
                                                }}
                                            >
                                            </List>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>}
                        {rept.listMedis && rept.listMedis.length > 0 &&
                            <Fragment>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <label className='d-flex align-items-center'><i class="fas fa-capsules mr-3" style={{ fontSize: 20 }}></i><span style={{ color: "#636363" }}>THUỐC & VẬT TƯ</span></label>
                                    </div>
                                </div>
                                <div className="list-page">
                                    <div className="list-page-body">
                                        <div className="list-page-table mt-2">
                                            <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                                                bordered={false}
                                                dataSource={rept.listMedis}
                                                renderItem={(record, index) => {
                                                    let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";
                                                    return (
                                                        <Fragment>
                                                            <List.Item key={record.name} className={className}>
                                                                <div className='d-flex flex-column w-100'>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <div style={{ width: "40%" }}><label>{record.name}</label></div>
                                                                        <div style={{ width: "25%" }}>Số phiếu: {record.prescriptionId}</div>
                                                                        <div style={{ width: "35%" }} className='d-flex flex-row justify-content-end'>
                                                                            <div style={{ paddingRight: 15 }}>
                                                                                <label>{record.quantity} {record.unit}</label>
                                                                            </div>
                                                                            <div style={{ display: "flex", justifyContent: "end", }}>
                                                                                <div className='ml-2'>
                                                                                    <label className='mr-2'>Hoàn trả</label>
                                                                                    <label style={{ color: "#b13431" }}>{Number(record.total ?? 0).toLocaleString("en-US")}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </List.Item>
                                                        </Fragment>
                                                    )
                                                }}
                                            >
                                            </List>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>}
                    </div>
                )
            })}
        </Fragment>
    );
})

const mapStateToProps = (state) => ({
    siteid: state.global.siteid,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Refund);