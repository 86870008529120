import { Input, Button, Table, Dropdown, Menu, Drawer, Modal, Pagination } from 'antd';
import React, { Fragment, useRef, useState, useEffect } from 'react';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import Notify, { NotifyStatus } from '../../components/notify';
import HeaderModal from '../../configureHIS/common/headerModal';
import CreateUpdate from './createUpdate';
import ReactJson from 'react-json-view';
import * as QMSService from '../../services/QMSService';

function ConfigQMS(props) {
    const [keyword, setKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [visibleCreateUpdate, setVisibleCreateUpdate] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selected, setSelected] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const notify = useRef();
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: 80,
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            width: 200,
            render: (title, record) => {
                return (
                    <a href="#" style={{ textDecoration: "underline" }} onClick={() => { openModalCreateUpdate(record) }}>{title}</a>
                )
            }
        },
        {
            title: '',
            key: 'nothing',
            align: 'center',
            render: (row) => {
                return (
                    <Fragment>
                        <i className="fas fa-external-link-alt" onClick={() => openQMSInNewTab(row.id)}></i>
                        <Dropdown overlay={rowOptions(row)} trigger={['click']} className="ml-3">
                            <a className="ant-dropdown-link ml-2"><i className='fas fa-ellipsis-h'></i></a>
                        </Dropdown>
                    </Fragment>
                )
            }
        },
        {
            title: 'Thông tin đối số',
            dataIndex: 'params',
            key: 'params',
            align: 'left',
            width: 500,
            render: (value) => {
                return value && <ReactJson src={value} />
            }
        },
        {
            title: 'Diễn giải',
            dataIndex: 'info',
            key: 'info',
            align: 'left',
            width: 600,
            render: (info) => {
                return (
                    <MitaDisplayLongText
                        content={info}
                        maxLength={100}
                        showMoreText={'...xem thêm'}
                        showLessText={' rút gọn'}
                    />
                )
            }
        },
    ];

    const [dataSource, setDataSource] = useState([]);

    const rowOptions = (row) => {
        return (
            <Menu
                items={[
                    {
                        label:
                            <a href="#" onClick={() => { openModalCreateUpdate(row) }}><i className="fas fa-pencil-alt mr-1" />Cập nhật</a>,
                        key: 'edt',
                    },
                    {
                        label:
                            <a href="#" onClick={() => { deleteQMSConfig(row.id) }}><i className="far fa-trash-alt mr-1"></i>Xóa</a>,
                        key: 'del',
                    },
                ]}
            />
        )
    }

    const openQMSInNewTab = (configId) => {
        let currentLocation = window.location;
        let url = currentLocation.origin + "/qms?id=" + configId;
        window.open(url, '_blank').focus();
    }

    const openModalCreateUpdate = (data) => {
        setSelected(data);
        setIsUpdate(data && data.id != undefined);
        setTimeout(() => {
            setVisibleCreateUpdate(true);
        }, 200);
    }

    const onClose = () => {
        setIsUpdate(false);
        setVisibleCreateUpdate(false);
        getListQMSConfig();
    }

    useEffect(() => {
        getListQMSConfig();
    }, []);

    const getListQMSConfig = (key = keyword, page = currentPage, size = pageSize) => {
        QMSService.getListQMSConfig(key, page, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords)
                notify.current && notify.current.Close();
            } else {
                notify.current && notify.current.Show("Lỗi tải danh sách cấu hình", NotifyStatus.Error);
                setDataSource([]);
            }
        })
            .catch(() => { })
    }

    const deleteQMSConfig = (id) => {
        if (id > 0) {
            QMSService.deleteQMSConfig(id).then(result => {
                if (result.isSuccess) {
                    notify.current.Show("Xóa thành công", NotifyStatus.Success);
                    getListQMSConfig();
                } else {
                    if (result.err.msgString) notify.current.Show(result.err.msgString, NotifyStatus.Error);
                    else notify.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
                }
            })
        } else {
            notify.current.Show("Không tìm thấy ID cần xóa", NotifyStatus.Error);
        }
    }

    return (
        <Fragment>
            <div className="containerChilderWrapper">
                <HeaderModal title="Cấu hình danh sách hàng đợi" />
                <Notify ref={notify} />
                <div className="row mt-2">
                    <div className="col-md-12 mt-2">
                        <Input
                            placeholder="Nhập ID, tiêu đề để tìm kiếm"
                            prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                            value={keyword}
                            onChange={(e) => { setKeyword(e.target.value); }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setCurrentPage(1);
                                    getListQMSConfig(keyword, 1);
                                }
                            }}
                        ></Input>
                    </div>
                </div>
                <div className="row mt-2">
                    <Button
                        type="link"
                        onClick={() => {
                            openModalCreateUpdate();
                        }}
                        style={{ width: "fit-content", display: "flex", alignItems: "center" }}
                    ><i className="fa fa-plus-circle mr-1" style={{ fontSize: 20 }} />Tạo mới</Button>
                </div>
                <div style={{ height: "calc(100% - 190px)" }}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'max-content', y: 0 }}
                        rowKey={(e, index) => index}
                    />
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                            getListQMSConfig(keyword, page, size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setPageSize(size);
                            getListQMSConfig(keyword, 1, size)
                        }}
                        showSizeChanger={true}
                    ></Pagination>
                </div>
            </div>
            <Drawer
                destroyOnClose={true}
                visible={visibleCreateUpdate}
                closable={false}
                width={600}
            >
                <CreateUpdate isUpdate={isUpdate} data={selected} onClose={() => onClose()}></CreateUpdate>
            </Drawer>
        </Fragment>
    );
}

export default ConfigQMS;