import * as ApiCaller from '../libs/httpRequests';
const prefix = 'YeuCauHoanTra';

export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function confirm(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/confirm/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function cancel(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/cancel/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PrintRefund(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/PrintRefund`, data).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};