import { Form, Input, Modal, notification, Pagination, Table, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import CreateCity from './createcity';
import * as CommonService from '../../../services/commonService';
const initialState = {
    visible: false,
    visibleCreate: false,
    matp: '',
    totalRecords: 0,
    currentPage: 0,
    mavung: '',
    key: '',
    tentp: ''
}
let task = null;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstCity: [],
            key: '',
            disabledUpdate: true,
            visabledLuu: false,
            visabledUpdate: false,
            currentPage: 1,
            pageSize: 10,
        };
    }

    componentDidMount() {
        this.loadCity();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadCity(page = this.state.currentPage, size = this.state.pageSize) {
        CommonService.DanhMucThanhPho(this.state.key, page, size).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data,
                    totalRecords: result.totalRecords
                });
            }
            else {
                this.setState({
                    lstCity: [],
                    totalRecords: 0
                })
            }
            this.setState({ currentPage: page })
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    async updateCity() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                matt: this.state.matp,
                tentt: this.state.tentp,
                mavung: this.state.mavung,
                loai: 'thanhthi',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        disabledUpdate: true,
                        visabledLuu: false,
                        visabledUpdate: false
                    })
                    notification.success({ message: 'Cập nhật thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.loadCity(this.state.currentPage);
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    DeleteCity(row) {
        const data = {
            matt: row.ma,
            tentt: row.ten,
            mavung: row.mavung,
            loai: 'thanhthi',
        }
        CommonService.deleteCity(data).then(result => {

            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadCity();
            }

        }).catch(err => {
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };
    render() {
        const columns = [
            {
                title: 'Mã thành phố',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên thành phố',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: '',
                render: (row) => {
                    return (<div>
                        <button className="btn btn-warning"
                            title="Cập nhật thành phố"
                            onClick={() => {
                                this.setState({ matp: row.ma, tentp: row.ten, mavung: row.mavung, visible: true });
                                this.form.setFieldsValue({
                                    tentp: row.ten
                                })
                            }} >
                            <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                        </button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { this.DeleteCity(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: "15px" }}>
                                <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                        </Popconfirm>
                    </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstCity.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{height:'100%'}}>
                        <div className="d-flex">
                            <input
                                className="form-control mb-2"
                                style={{ width: 300 }}
                                value={this.state.key}
                                placeholder="Nhập mã hoặc tên thành phố cần tìm"
                                onChange={(e) => {
                                    this.setState({ key: e.target.value });
                                    task = setTimeout(() => {
                                        this.loadCity();
                                    }, 800)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.loadCity();
                                    }
                                }}
                            />
                            <button className="btn btn-success ml-2 mr-2 mb-2" onClick={() => this.loadCity(1, this.state.pageSize)}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </button>
                            <button className="btn btn-success ml-auto mb-2"
                                onClick={this.showModalCreate}
                            > <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>

                        </div>
                        <div style={{height:'calc(100% - 85px)'}}>
                            <Table dataSource={dataSource}
                                scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index} />
                        </div>
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadCity(page, size) }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadCity(page, size) }}
                            showSizeChanger={true}
                        />
                </Form>
                <Modal maskClosable={false}
                    width={600}
                    visible={this.state.visible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                        <h3>Cập nhật dữ liệu thành phố</h3>
                    </div>
                    <div className="container-fluid row" style={{ display: 'flex', padding: 'inherit' }}>
                        <div className="form-group" style={{ flex: 1 }} >
                            <Form.Item label="Tên thành phố:" style={{ flex: 1 }} rules={[{ required: true, message: 'Tên thành phố không được để trống!' }]}>
                                <Input
                                    // disabled={this.state.disabledUpdate}
                                    value={this.state.tentp}
                                    onChange={(e) => {
                                        this.setState({ tentp: e.target.value })
                                    }} />
                            </Form.Item>
                        </div>

                    </div>
                    <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center', alignItems: 'center' }}>
                        {/* <button style={{ marginTop: -4 }} hidden={this.state.visabledUpdate}
                                        onClick={() => { this.setState({ disabledUpdate: false, visabledLuu: false, visabledUpdate: true }) }}
                                        className="btn btn-warning">Cập nhật</button> */}
                        <button style={{ marginTop: -4 }}
                            hidden={this.state.visabledLuu}
                            className="btn btn-success" onClick={() => this.updateCity()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>
                            Lưu</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10, marginTop: -4 }} danger
                            onClick={() => {
                                this.handleCancel()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleCreate}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}

                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateCity onCancel={this.handleCancel} />
                </Modal>
            </React.Fragment>
        )
    }
}