import React, { Fragment, useEffect, useState, forwardRef, useRef } from 'react'
import { Input, Modal, Radio, Collapse } from 'antd';
import Notify, { NotifyStatus } from '../../components/notify'
import moment from 'moment';
import * as Actions from '../../libs/actions';
import * as SystemConfig from "../../configure/systemConfig";
import HeaderModal from '../../configureHIS/common/headerModal';
import * as CommonService from '../../services/commonService';
import RenderDataInfo from '../../configureHIS/common/renderDataInfo';
import PrintSID from "../invoice/paymentmulti/InSIDBarcode";
import * as MedicalExamServices from '../../services/MedicalExam';
import PatientInfo from "../../screens/patient/component/patientInfo";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'; 

const SID_SYSTEM = 0;
const SID_CUSTOM = 1;
const Include_Characters = new RegExp('([^0-9])');
const SID_MAX_LENGTH = 6;
const SID_VALID = new RegExp('^[1-9]{1}[0-9]{1,5}\\b|^[1-9]{1,6}\\b');
var timeOutTask = null;
const { Panel } = Collapse;
export default forwardRef((props) => {
    const notiRef = useRef(null);
    const notiRefSec = useRef(null);
    const [SID, setSID] = useState(props.data.sid); //sid current
    const [customSID, setCustomSID] = useState(!props.data.isSystemCode ? props.data.sid : null);
    const [systemSID, setSystemSID] = useState(props.data.isSystemCode ? props.data.sid : null);
    const [managementID, setManagementID] = useState(props.data.managementID);
    const [patientInfo, setPatientInfo] = useState([]);
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [SIDType, setSIDType] = useState(null);
    const [dateUpdate, setDateUpdate] = useState(null);
    const [prefixSIDCustom, setPrefixSIDCustom] = useState();
    const [SIDValid, setSIDValid] = useState(false);
    const _customSIDInput = useRef();
    const [orderId, setOrderId] = useState(props.data.orderId);
    const [disableSave, setDisableSave] = useState(false);
    const [sidInfo, setSidInfo] = useState();
    const [hasChange, setHasChange] = useState(false);

    useEffect(() => {
        getPatientInfo(props.data.patientID);
        setManagementID(props.data.managementID);
        // setSID(props.data.sid);
        setOrderId(props.data.orderId);
        getSIDInfo(props.data.managementID, props.data.orderId);
    }, [props]);

    const resetState = () => {
        setCustomSID(null);
        setSIDType(null);
        notiRef.current && notiRef.current.Close();
        notiRefSec.current && notiRefSec.current.Close();
    }

    const getPatientInfo = (patientID) => {
        CommonService.GetPatientDetail(patientID).then(result => {
            if (result.isSuccess) {
                setPatientInfo(result.item);
            } else {
                notiRef.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
            }
        }).catch(err => {
            notiRef.current.Show(getErrorMessage(err.msgString), NotifyStatus.Warning);
        })
    }

    const getSIDInfo = (managementID, orderid) => {
        // MedicalExamServices.GetSIDInfo(managementID, SID).then(result => {
        //     if (result.isSuccess) {
        //         if (result.item) {
        //             result.item.dateUpdate ? setDateUpdate(result.item.dateUpdate) : setDateUpdate(null);
        //             result.item.prefixSIDCustom ? setPrefixSIDCustom(result.item.prefixSIDCustom + "-") : setPrefixSIDCustom(null);
        //             // setOrderId(result.item.orderId && result.item.orderId !== "" ? result.item.orderId : null);
        //         }
        //     } else {
        //         setDisableSave(true);
        //         notiRef.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
        //     }
        // }).catch(err => {
        //     setDisableSave(true);
        //     notiRef.current.Show(getErrorMessage(err.msgString), NotifyStatus.Warning);
        // })
        MedicalExamServices.GetSIDInfoByOrderId(managementID, orderid).then(result => {
            if (result.isSuccess) {
                if (result.item) {
                    result.item.dateUpdate ? setDateUpdate(result.item.dateUpdate) : setDateUpdate(null);
                    result.item.prefixSIDCustom ? setPrefixSIDCustom(result.item.prefixSIDCustom + "-") : setPrefixSIDCustom(null);
                    setSidInfo(result.item);
                }
            } else {
                setDisableSave(true);
                notiRef.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
            }
        }).catch(err => {
            setDisableSave(true);
            notiRef.current.Show(getErrorMessage(err.msgString), NotifyStatus.Warning);
        })
    }

    //2023.04.10: thêm reload vào để sau này component nào (tạm gọi là component cha) gọi đến sidChange này có cần dùng thì dùng.
    //Mục đích reload: true - component cha sẽ reload lại để tải dữ liệu mới khi sidChange close. false - component cha chỉ close sidChange bình thường.
    //2023.04.11: trả thêm sidInfo, do 1 số nơi gọi sidChange này, nhưng lại không có api load dữ liệu riêng. VD screen "chi tiết lấy mẫu", screen "chi tiết nhận mẫu"
    const onCancel = (reload = false) => {
        props.onCancel && props.onCancel(reload, sidInfo);
    }

    const updateSID = (print) => {
        if (disableSave) return;
        Actions.setLoading(true);
        let errMsg = "";
        if (SIDType === SID_CUSTOM) {
            errMsg = checkValidSID(customSID);
        }
        else if (SIDType === SID_SYSTEM) {
            errMsg = checkValidSID(SID);
        }
        if (errMsg !== "") {
            Actions.setLoading(false);
            notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
            return;
        }
        notiRefSec && notiRefSec.current && notiRefSec.current.Close();
        MedicalExamServices.UpdateSID(managementID, orderId, SIDType, SIDType == SID_SYSTEM ? systemSID : customSID)
        .then(result => {
            if (result.isSuccess) {
                notiRefSec.current.Show("Mã SID đã được cập nhật", NotifyStatus.Success);
                //returnObject là SIDInfo trong BE. getSIDInfo ở trên cũng trả về SIDInfo
                if (result.returnObject) {
                    result.returnObject.dateUpdate ? setDateUpdate(result.returnObject.dateUpdate) : setDateUpdate(null);
                    setSID(result.returnObject.sid);
                    setSidInfo(result.returnObject);
                    setHasChange(true);
                }

                if (print) {
                    setVisiblePrint(true);
                } else {
                    onCancel(true);
                    resetState();
                }
                Actions.setLoading(false);
            } else {
                Actions.setLoading(false);
                if (result.err.msgString)
                    notiRefSec.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
                else notiRefSec.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Warning);
            }
        }).catch(ex => {
            Actions.setLoading(false);
            notiRefSec.current.Show(getErrorMessage(ex.toString()), NotifyStatus.Warning);
        })
    }

    const checkValidSID = (SIDValue) => {
        if (SIDValue === null || SIDValue === undefined || SIDValue === "") {
            return "Mã SID cần được nhập";
        }
        if (SIDValue.includes(" ")) {
            return "Mã SID không được chứa khoảng trắng";
        }
        if (SIDType === SID_CUSTOM && Include_Characters.test(SIDValue)) {
            return "Mã SID không được chứa chữ cái và (hoặc) ký tự đặc biệt";
        }
        if (SIDType === SID_CUSTOM && SIDValue.length > SID_MAX_LENGTH) {
            return `Mã SID không được vượt quá ${SID_MAX_LENGTH} ký số`;
        }
        return "";
    }

    const onSubmit = () => {
        resetState();
        setVisiblePrint(false);
    }

    const renewSIDFromSystem = () => {
        Actions.setLoading(true);
        MedicalExamServices.renewSIDFromSystem(managementID, orderId).then(result => {
            if (result.isSuccess) {
                Actions.setLoading(false);
                notiRefSec.current.Show("Mã SID đã được tạo tự động", NotifyStatus.Success);
                // setSID(result.item);
                setSystemSID(result.item);
            } else {
                Actions.setLoading(false);
                if (result.err.msgString)
                    notiRefSec.current.Show(getErrorMessage(result.err.msgString), NotifyStatus.Warning);
                else notiRefSec.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Warning);
            }
        }).catch(ex => {
            Actions.setLoading(false);
            notiRefSec.current.Show(getErrorMessage(ex), NotifyStatus.Warning);
        })
    }

    const getErrorMessage = (message) => {
        if (message) {
            let errMsg = message.split("\r\n").find(x => x.includes("MessageText:"));
            return errMsg ? errMsg.trim().replace("MessageText: ", "") : message;
        } return "";
    }

    async function checkValidSIDFromSrv(SID) {
        let regValid = SID_VALID.test(SID);
        await MedicalExamServices.ValidSID(SID).then(res => {
            setSIDValid(regValid && res.isSuccess);
        });
    }

    return (
        <Fragment>
            <HeaderModal title={`Cập nhật SID - ${patientInfo.patientName}`} onClose={() => {
                resetState();
                onCancel();
            }} />
            <div>
                <Collapse defaultActiveKey={['pif', 'sidc']} expandIconPosition="right">
                    <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                        <Notify ref={notiRef} />
                        <PatientInfo patientId={props.data.patientID} col={12}></PatientInfo>
                    </Panel>
                    <Panel header={<label className='mita-header-title'>Thông tin cập nhật SID</label>} key="sidc">
                    <Notify ref={notiRefSec} />
                    <div className='col-12 m-2'>
                        <RenderDataInfo
                            data={
                                [
                                    {
                                        label: "Mã đăng ký",
                                        value: orderId ?? props.data.managementID,
                                        column: 6,
                                    },
                                    {
                                        label: "Ngày tạo/cập nhật",
                                        value: `${dateUpdate ? moment(dateUpdate).format(SystemConfig.FORMAT_DATE_TIME_SECOND) : "Ngày giờ hệ thống"}`,
                                        column: 6,
                                    },
                                ]
                            }
                        />
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <label className="mita-title">SID hiện tại</label>
                                <div>{SID}</div>
                            </div>
                            <div className='col-md-6'>
                                <label className="mita-title">SID cập nhật</label>&nbsp;
                                <Radio.Group
                                    options={[
                                        { label: "Theo hệ thống", value: SID_SYSTEM },
                                        { label: "Tự nhập", value: SID_CUSTOM },
                                    ]}
                                    onChange={(e) => {
                                        setSIDType(e.target.value);
                                        if (e.target.value == SID_CUSTOM) {
                                            setCustomSID(null);
                                            _customSIDInput && _customSIDInput.current && _customSIDInput.current.focus();
                                            notiRefSec && notiRefSec.current.Close();
                                        }
                                        if (e.target.value == SID_SYSTEM) setCustomSID(renewSIDFromSystem());
                                    }}
                                    value={SIDType}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                                {SIDType != null ?
                                    SIDType == 0 ?
                                        <div>
                                            <span>{systemSID}</span>
                                            <i className="fas fa-redo ml-2 anchor-color"
                                                onClick={() => {
                                                    renewSIDFromSystem();
                                                }} />
                                        </div>
                                        :
                                        <div className='d-flex align-items-center'>
                                            <span>{prefixSIDCustom ? prefixSIDCustom : moment().format("DDMMYY-")}</span>
                                            <Input
                                                autoFocus
                                                ref={_customSIDInput}
                                                style={{ width: 200 }}
                                                value={customSID}
                                                onChange={(e) => {
                                                    setCustomSID(e.target.value);
                                                    if (timeOutTask) clearTimeout(timeOutTask);
                                                    timeOutTask = setTimeout(() => {
                                                        checkValidSIDFromSrv(e.target.value);
                                                    }, 600);
                                                }}
                                                placeholder="Nhập hoặc quét mã"
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        if (SIDValid) {
                                                            updateSID();
                                                        } else {
                                                            notiRefSec && notiRefSec.current && notiRefSec.current.Show("Mã không hợp lệ", NotifyStatus.Error, 2);
                                                            _customSIDInput.current.focus();
                                                            _customSIDInput.current.select();
                                                        }
                                                    }
                                                }}>
                                            </Input>
                                            <i style={{ fontSize: 25, color: SIDValid ? "blue" : "red" }} className={`ml-2 fas fa-${SIDValid ? "check-circle" : "times-circle"}`}></i>
                                        </div>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                    </Panel>
                </Collapse>
            </div>
            <div className='custom-hr'/>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                {!disableSave &&
                <>
                    <button className="btn btn-primary" onClick={() => { updateSID() }}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                    <button className="btn btn-success ml-2" onClick={() => { updateSID(true) }}>Lưu & in</button>
                </>
                }
                <button className="btn btn-danger ml-2"
                    onClick={() => {
                        onCancel(hasChange);
                        resetState();
                    }}>
                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                    /> Đóng
                </button>
            </div>
            <Modal
                width={800}
                visible={visiblePrint}
                maskClosable={true}
                onClose={() => setVisiblePrint(false)}>
                <PrintSID billSelected={SID} onSubmit={() => onSubmit()} onCancel={() => { resetState(); setVisiblePrint(false) }} />
            </Modal>
        </Fragment>
    );
});