import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Button, List, Dropdown, Menu, Select, Checkbox, Drawer, Input, DatePicker, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as TestReceivedServices from '../../services/testReceivedService';
import * as CoporationServices from "../../services/CorporationServices";
import Notify, { NotifyStatus } from '../../components/notify';
import { connect } from 'react-redux';
import HeaderModal from "../../configureHIS/common/headerModal";
import PrintSIDComp from "../invoice/paymentmulti/InSIDBarcode";
import Detail from "./detail";
import SidChange from '../../screens/xacnhanmau/sidChange';
import { isNullOrEmpty } from '../../libs/util';

const { Option } = Select;

const TestReceived = forwardRef((props, ref) => {
    const _hiddenLink = useRef();
    const _notiRef = useRef();
    const _SID = useRef();
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //state paging và sorting
    const [skipCount, setSkipCount] = useState(0);
    const [sortingType, setSortingType] = useState('');
    const [sortingField, setSortingField] = useState('');
    const [maxResultCount, setMaxResultCount] = useState(10);
    //search/filter
    const [coporationId, setCoporationId] = useState();
    const [keyword, setKeyword] = useState('');
    const [SIDKey, setSIDKey] = useState('');
    const [sampleType, setSampleType] = useState();
    const [sendResultType, setSendResultType] = useState();
    const [dateFrom, setDateFrom] = useState(new moment());
    const [dateTo, setDateTo] = useState(new moment());
    const [statusNhanMau, setStatusNhanMau] = useState();
    //result model
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [sortFields, setSortFields] = useState([]);
    const [sortTypes, setSortTypes] = useState([]);
    const [listStatusNhanMau, setListStatusNhanMau] = useState([]);
    //row options
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [listCoporations, setListCoporations] = useState([]);
    const [checkPrintAll, setCheckPrintAll] = useState(false);
    const [indeterminateCheckPrintAll, setIndeterminateCheckPrintAll] = useState(false);
    const listPageSize = [10, 20, 50, 100];
    const [sids, setSids] = useState([]);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [dataUpdateSID, setDataUpdateSID] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const enterToSearch = (e) => {
        if (e.keyCode == 13) resetToFirstPage();
    }
    const enterToSearchSID = (e) => {
        if (e.key == "Enter") {
            if (skipCount != 0) setSkipCount(0);
            Actions.setLoading(true);
            let filterModel = {
                skipCount: skipCount,
                maxResultCount: maxResultCount,
                sorting: ((sortingField ?? '').length < 1 ? 'hoten' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
                sid: SIDKey,
                // keyword: SIDKey,
                dateFrom: dateFrom,
                dateTo: dateTo,
                coporationIds: coporationId ? [coporationId] : [],
                sampleType: sampleType,
                statusNhanMau: statusNhanMau,
            }
            TestReceivedServices.listOrderXN(filterModel).then(result => {
            // TestReceivedServices.getListTestReceivedBySID(filterModel).then(result => {
                if (result.isSuccess) {
                    setTotalRecord(result.data.totalCount);
                    setSortFields(result.data.sortFields);
                    setSortTypes(result.data.sortTypes);
                    if (skipCount == 0)
                        setListResult(result.data.items);
                    else {
                        setListResult([...listResult, ...result.data.items]);
                    }
                    if (result.data.totalCount == 1 && !isNullOrEmpty(SIDKey)) {
                        loadDetail(result.data.items[0]);
                    }
                    _notiRef && _notiRef.current.Close();
                }
                else {
                    _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
                else _notiRef.current.Show(err, NotifyStatus.Error);
            }).finally(() => {
                Actions.setLoading(false);
                forceUpdate();
            })
        }
    }

    // const resetToFirstPageOldApi = () => {
    //     if (dateFrom > dateTo) {
    //         _notiRef.current.Show('"Ngày đăng ký từ" phải nhỏ hơn hoặc bằng "ngày đăng ký đến"', NotifyStatus.Warning);
    //         return;
    //     }
    //     if (skipCount != 0) setSkipCount(0);
    //     else {
    //         if (!isNullOrEmpty(SIDKey)) enterToSearchSID({ key: "Enter" });
    //         else searchOldApi();
    //     }
    // }
    // const searchOldApi  = () => {
    //     Actions.setLoading(true);
    //     let filterModel = {
    //         skipCount: skipCount,
    //         maxResultCount: maxResultCount,
    //         sorting: ((sortingField ?? '').length < 1 ? 'hoten' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
    //         keyword: keyword,
    //         dateFrom: dateFrom,
    //         dateTo: dateTo,
    //         coporationIds: coporationId ? [coporationId] : [],
    //         sampleType: sampleType,
    //         statusNhanMau: statusNhanMau,
    //     }
    //     TestReceivedServices.getListTestReceived(filterModel).then(result => {
    //         if (result.isSuccess) {
    //             setTotalRecord(result.data.totalCount);
    //             setSortFields(result.data.sortFields);
    //             setSortTypes(result.data.sortTypes);
    //             if (skipCount === 0) {
    //                 setListResult(result.data.items);
    //             }
    //             else {
    //                 setListResult([...listResult, ...result.data.items]);
    //             }
    //             if (result.data.totalCount == 1 && !isNullOrEmpty(keyword)) {
    //                 loadDetail(result.data.items[0]);
    //             }
    //             _notiRef && _notiRef.current.Close();
    //         }
    //         else {
    //             _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
    //         }
    //     }).catch(err => {
    //         if (err.error && err.error.messageText)
    //             _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
    //         else _notiRef.current.Show(err, NotifyStatus.Error);
    //     }).finally(() => {
    //         Actions.setLoading(false);
    //         forceUpdate();
    //     })
    // }

    const resetToFirstPage = () => {
        if (dateFrom > dateTo) {
            _notiRef.current.Show('"Ngày đăng ký từ" phải nhỏ hơn hoặc bằng "ngày đăng ký đến"', NotifyStatus.Warning);
            return;
        }
        if (skipCount != 0) setSkipCount(0);
        else {
            if (!isNullOrEmpty(SIDKey)) enterToSearchSID({ key: "Enter" });
            else search();
        }
    }
    const getListStatusNhanMau = () => {
        TestReceivedServices.getListStatusNhanMau().then(result => {
            if (result.isSuccess) {
                setListStatusNhanMau(result.data.items);
                // _notiRef && _notiRef.current.Close();
            }
            else {
                // _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            // if (err.error && err.error.messageText)
            //     _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            // else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            forceUpdate();
        })
    }
    const search = () => {
        Actions.setLoading(true);
        let filterModel = {
            skipCount: skipCount,
            maxResultCount: maxResultCount,
            sorting: ((sortingField ?? '').length < 1 ? 'hoten' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
            keyword: keyword,
            dateFrom: dateFrom,
            dateTo: dateTo,
            coporationIds: coporationId ? [coporationId] : [],
            sampleType: sampleType,
            statusNhanMau: statusNhanMau,
        }
        TestReceivedServices.listOrderXN(filterModel).then(result => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                setSortFields(result.data.sortFields);
                setSortTypes(result.data.sortTypes);
                if (skipCount === 0) {
                    setListResult(result.data.items);
                }
                else {
                    setListResult([...listResult, ...result.data.items]);
                }
                if (result.data.totalCount == 1 && !isNullOrEmpty(keyword)) {
                    loadDetail(result.data.items[0]);
                }
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }
    const onChangeCheckPrintAll = (e) => {
        setCheckPrintAll(e.target.checked);
        if (e.target.checked) {
            setSids(listResult.map(x => x.sid));
        }
        else { setSids([]); }
    }
    const resetFilter = () => {
        setCoporationId();
        setKeyword();
        setSampleType();
        setSendResultType();
        setSIDKey();
    }
    const rowOptions = (record) => {

        return (
            <Menu
                items={[
                    {
                        label:
                            <a href="#" className='stopRowClick' onClick={() => {
                                loadDetail(record);
                            }}><i className='fa fa-eye mr-2' />Xem chi tiết</a>,
                        key: 'viewdetail',
                    },
                    isNullOrEmpty(record.nguoiXacNhan) && {
                        label:
                            <a href="#" className='stopRowClick' onClick={(e) => {
                                handleOpenEditSID(e, record.sid);
                                let data = {
                                    patientID: record.maBN,
                                    managementID: record.maQL,
                                    sid: record.sid,
                                    orderId: record.orderId,
                                }
                                setDataUpdateSID(data);
                            }}><i className="fas fa-pen mr-2"></i>Cập nhật SID</a>,
                        key: 'updatesid',
                    },
                    {
                        label:
                            <a href="#" className='stopRowClick' onClick={(e) => {
                                handleOpenPrintSID(record.sid);
                                setVisibleMenu(false);
                            }}><i className='fa fa-print mr-2' />In SID</a>,
                        key: 'printsid',
                    },
                    // {
                    //     label:
                    //         <a href="#" onClick={() => {
                    //         }}><i className='fas fa-expand-alt mr-2' /> Gửi lại chỉ số</a>,
                    //     key: 'send',
                    // },
                ]}
            />
        )
    }
    const loadDetail = (row) => {
        if (row) {
            setSelectedData(row);
        }
        setVisibleDetail(true);
        setVisibleMenu(false);
    }
    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [maxResultCount, sortingField, sortingType]);

    useEffect(() => {
        if (firstRender.current) return;
        search();
    }, [skipCount])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            search();
        }
        getListCoporations();
        getListStatusNhanMau();
    }, []);

    useEffect(() => {
        setIndeterminateCheckPrintAll(sids.length > 0 && sids.length < listResult.length);
        if (!firstRender.current) setCheckPrintAll(sids && sids.length > 0 && sids.length == listResult.length);
    }, [sids, listResult]);

    const getListCoporations = () => {
        CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
            if (result.isSuccess) {
                setListCoporations(result.data);
                _notiRef && _notiRef.current.Close();
            }
            else {
                if (result.error)
                    _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                if (result.err)
                    _notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            forceUpdate();
        })
    }
    const handleOpenPrintSID = (sid) => {
        if (sid != undefined || (sids && sids.length > 0)) {
            if (sid != undefined) setSids(sid);
            setVisiblePrint(true);
            _notiRef.current && _notiRef.current.Close();
        }
        else _notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const handleClosePrintSID = () => {
        setVisiblePrint(false);
        setSids([]);
        setCheckPrintAll(false);
        setIndeterminateCheckPrintAll(false);
    }
    const handleOpenEditSID = (e, sid) => {
        if (sid != undefined || (sids && sids.length > 0)) {
            if (sid != undefined) setSids(sid);
            setVisibleEdit(true);
            setVisibleMenu(false);
            _notiRef.current && _notiRef.current.Close();
            // e.stopPropagation();
        }
        else _notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const handleCloseEditSID = () => {
        setVisibleEdit(false);
        setSids([]);
        setCheckPrintAll(false);
        setIndeterminateCheckPrintAll(false);
        search();
    }
    const handleFocusSID = () => {
        if (!isNullOrEmpty(SIDKey)) {
            setTimeout(() => {
                setVisibleDetail(false);
                setTimeout(() => {
                    _SID.current.focus();
                    _SID.current.select();
                }, 100);
            }, 400);
        }
    }
    const onClickFunction = (e,label ,record) => {
        if (
          e.target.className.indexOf('stopRowClick')>-1
        ) return;
        record && loadDetail(record);
        e.stopPropagation();

        // switch (label) {
        //     case 'list':
        //         record && loadDetail(record);
        //         break;
        //     case 'menu':
        //         setVisibleMenu(c => !c);
        //         e.preventDefault()
        //         break;
        //     default:
        //         break;
        // }
        // e.stopPropagation();
    }

    return (
        <Fragment>
            <div className="list-page containerChilderWrapper">
                <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink} ></a>
                <HeaderModal title="Danh sách nhận mẫu" />
                <Notify ref={_notiRef} />
                <div className="list-page-body">
                    <div className="list-page-search">
                        <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                                <label>Ngày đăng ký</label>
                                <div className="d-flex">
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder={"Từ ngày"}
                                        value={dateFrom}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateFrom(e ? moment(e) : null);
                                        }}
                                    />
                                    <div className="m-auto">&#160;-&#160;</div>
                                    <DatePicker
                                        allowClear={false}
                                        locale={viVN}
                                        showNow={false}
                                        className="w-100"
                                        placeholder={"Đến ngày"}
                                        value={dateTo}
                                        format={SystemConfig.FORMAT_DATE}
                                        onChange={(e) => {
                                            setDateTo(e ? moment(e) : null);
                                        }}
                                        disabledDate={(date) => date < moment(dateFrom)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Nguồn khách hàng</label>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    className="w-100"
                                    placeholder="Chọn nguồn khách hàng"
                                    maxTagCount='responsive'
                                    value={coporationId}
                                    onChange={(value) => { setCoporationId(value); }}
                                >
                                    {(listCoporations ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <label>Trạng thái</label>
                                <Select
                                    allowClear
                                    className="w-100"
                                    placeholder="Chọn trạng thái"
                                    value={statusNhanMau}
                                    onChange={(value) => { setStatusNhanMau(value); }}
                                >
                                    {(listStatusNhanMau ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.key} value={record.key}>{record.text}</Option>
                                        )
                                    })}
                                    {/* <Option key={0} value={0}>Đợi xác nhận</Option>
                                    <Option key={1} value={1}>Đã xác nhận</Option> */}
                                </Select>
                            </div>
                            {/* <div className="col-auto d-flex flex-column">
                                <label>Loại mẫu</label>
                                <Select
                                    allowClear
                                    className="w-100"
                                    placeholder=""
                                    maxTagCount='responsive'
                                    value={sampleType}
                                    onChange={(value) => { setSampleType(value); }}
                                >
                                    <Option key={1} value={1}>Mẫu tại chỗ</Option>
                                    <Option key={2} value={2}>Mẫu gửi</Option>
                                </Select>
                            </div> */}
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6 d-flex flex-column">
                                <label>Từ khóa bệnh nhân</label>
                                <Input
                                    placeholder="Nhập mã, tên bệnh nhân hoặc quét mã (PID, đăng ký)"
                                    // prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                                    value={keyword}
                                    onChange={(e) => { 
                                        if (!isNullOrEmpty(SIDKey)) 
                                            setSIDKey(null);
                                        setKeyword(e.target.value); }}
                                    onKeyUp={(e) => { enterToSearch(e); }}
                                ></Input>
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                                <label>SID</label>
                                <Input
                                    ref={_SID}
                                    placeholder="Nhập hoặc quét mã"
                                    value={SIDKey}
                                    onChange={(e) => { setSIDKey(e.target.value); }}
                                    onKeyDown={(e) => { enterToSearchSID(e); }}
                                ></Input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 d-flex justify-content-end'>
                                {/* <button className="btn btn-color"
                                    onClick={() => { resetToFirstPageOldApi(); }}
                                > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm api cũ</button> */}
                                <button className="btn btn-color"
                                    onClick={() => { resetToFirstPage(); }}
                                > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm</button>
                                <Button className="btn btn-light ml-2"
                                    onClick={() => { resetFilter(); }}
                                > <i style={{ marginRight: 5 }} className="fa fa-eraser" />Xóa dữ liệu nhập</Button>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-md-12 pl-1 d-flex justify-content-between'>
                                <div>
                                    <Checkbox checked={checkPrintAll}
                                        indeterminate={indeterminateCheckPrintAll}
                                        onChange={(e) => {
                                            onChangeCheckPrintAll(e);
                                        }}
                                    ></Checkbox>
                                    <label className="mx-1"><span style={{ fontSize: 25, color: "#4d4d4d" }}>|</span></label>
                                    <Button className="pl-0"
                                        type="link"
                                        onClick={(e) => {handleOpenPrintSID() }}
                                    ><i className="fas fa-print mr-1" />In SID</Button>
                                </div>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <label>Sắp xếp theo</label>
                                    <Select className="ml-1" style={{ width: '140px' }}
                                        placeholder="tên, mã, sid"
                                        value={sortFields.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingField(value ?? ''); }}
                                    >
                                        {(sortFields ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <Select className="mx-1" style={{ width: '100px' }}
                                        placeholder="tăng dần/giảm dần"
                                        maxTagCount='responsive'
                                        value={sortTypes.find(x => x.selected)?.key}
                                        onChange={(value) => { setSortingType(value ?? ''); }}
                                    >
                                        {(sortTypes ?? []).map((record, index) => {
                                            return (
                                                <Option key={record.key} value={record.key}>{record.text}</Option>
                                            )
                                        })}
                                    </Select>
                                    <label>Hiển thị</label>
                                    <Select className="ml-1"
                                        placeholder="10, 20, 50, 100"
                                        value={maxResultCount}
                                        onChange={(value) => { setMaxResultCount(value); }}
                                    >
                                        {listPageSize.map((record, index) => {
                                            return (
                                                <Option key={record} value={record}>{record}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table mt-2">
                        <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                            bordered={false}
                            dataSource={listResult}
                            renderItem={(record, index) => {
                                let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                                let soLuongChoLayMau = record.soLuongMau - (record.soLuongDaLay + record.soLuongDaNhan + record.soLuongDaTuChoi);
                                return (
                                    <List.Item 
                                        onClick={(e) => {onClickFunction(e,'list',record)}}
                                        key={`${record.siteId}_${record.maQL}_${record.maBN}_${record.sid}`}
                                        className={className}
                                    >
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 18 }}>
                                                {index + 1}
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 16 }}>
                                                <Checkbox className="mr-2"
                                                    checked={sids.indexOf(record.sid) > -1}
                                                    onChange={(e) => {
                                                        if (e.target.checked) setSids([...sids, record.sid]);
                                                        else setSids(sids.filter(x => x != record.sid))
                                                    }}
                                                    onClick={(e) => {e.stopPropagation();}}
                                                ></Checkbox>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                                <div className="">
                                                    <a href="#" onClick={() => { loadDetail(record) }}><b>{!isNullOrEmpty(record.orderId) ? record.orderId : record.maQL}</b></a>
                                                </div>
                                                <div className="">
                                                    <label><b>{record.hoTen} - {record.maBN}</b></label>
                                                </div>
                                                <div className="">
                                                    <label>Ngày sinh: <b>{record.onlyYear == true ? record.namSinh : moment(record.ngaySinh).format(SystemConfig.FORMAT_DATE)}</b></label>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 'calc(36% - 46px)' }}>
                                                <div className="">
                                                    <label>Ngày đăng ký: <b>{moment(record.ngayDangKy).format(SystemConfig.FORMAT_DATE_TIME)}</b></label>
                                                </div>
                                                <div className="">
                                                    <label style={{ width: '40%' }}>Giới tính: <b>{record.phai == 0 ? "Nam" : "Nữ"}</b></label>
                                                    <label>Điện thoại: <b>{record.dienThoai}</b></label>
                                                </div>
                                                <div className="">
                                                    <label>Địa chỉ: <b>{record.diaChiFull}</b></label>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: '30%' }}>
                                                <div className="">
                                                    <label>Nguồn khách hàng: <b>{record.tenNguonKhachHang}</b>
                                                    </label>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <label>SID: <a href="#" onClick={() => { loadDetail(record) }}><b>{record.sid}</b></a></label>
                                                    {/* <label style={{ color: "blue" }}>{record.nguoiXacNhan ? record.soLuongMau - record.soLuongMauGui : 0}/{record.soLuongMau}</label> */}
                                                    {
                                                        // (record.soLuongDaNhan > 0 || record.soLuongDaTuChoi > 0) ?
                                                        <div>
                                                            {soLuongChoLayMau > 0 &&
                                                            <Tooltip title={`Có ${soLuongChoLayMau} dịch vụ chờ lấy mẫu`}>
                                                                <label className="text-warning mr-2"><i className="far fa-clock mr-1" />{soLuongChoLayMau}/{record.soLuongMau}</label>
                                                            </Tooltip>
                                                            }
                                                            {record.soLuongDaLay > 0 &&
                                                            <Tooltip title={`Có ${record.soLuongDaLay} dịch vụ đã lấy mẫu`}>
                                                                <label className="text-success mr-2"><i className="fas fa-tint mr-1" />{record.soLuongDaLay}/{record.soLuongMau}</label>
                                                            </Tooltip>
                                                            }
                                                            {record.soLuongDaNhan > 0 &&
                                                            <Tooltip title={`Có ${record.soLuongDaNhan} dịch vụ đã nhận mẫu`}>
                                                                <label className="text-info mr-2"><i className="fas fa-check-circle mr-1" />{record.soLuongDaNhan}/{record.soLuongMau}</label>
                                                            </Tooltip>
                                                            }
                                                            {record.soLuongDaTuChoi > 0 &&
                                                            <Tooltip title={`Có ${record.soLuongDaTuChoi } dịch vụ bị từ chối`}>
                                                                <label className="text-danger"><i className="fas fa-times-circle mr-1" />{record.soLuongDaTuChoi}/{record.soLuongMau}</label>
                                                            </Tooltip>
                                                            }
                                                        </div>
                                                        // : <label>{0}/{record.soLuongMau}</label>
                                                    }
                                                </div>
                                                <div className="align-self-end">
                                                    {record.ngayXacNhan ?
                                                        <Fragment>
                                                            <label><i className="far fa-clock mr-1" /><b>{moment(record.ngayXacNhan).format(SystemConfig.FORMAT_DATE_TIME)}</b></label>&nbsp;&nbsp;
                                                            <label><i className="fas fa-user mr-1" /><b>{record.nguoiXacNhan ?? ''}</b></label>
                                                        </Fragment>
                                                        : <label>Đợi xác nhận</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 12 }}>
                                                <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                                                    <a className="ant-dropdown-link" onClick={(e) => {onClickFunction(e,'menu')}}><i className='fas fa-ellipsis-v'></i></a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }}
                        >
                            <List.Item key="showMore">
                                <div className="w-100 d-flex justify-content-center">
                                    <Button
                                        hidden={totalRecord <= listResult.length}
                                        onClick={() => {
                                            setSkipCount(skipCount + maxResultCount);
                                        }}
                                    >Hiển thị thêm {totalRecord - listResult.length}</Button>
                                </div>
                            </List.Item>
                        </List>
                    </div>
                </div>
            </div>
            <Drawer className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={1200}
                placement="right"
                visible={visibleDetail}
                destroyOnClose={true}>
                <Detail
                    data={selectedData}
                    onCancel={() => { setVisibleDetail(false); handleFocusSID(); }}
                    handleFocusSID={() => { handleFocusSID(); }}
                />
            </Drawer>
            <Drawer
                closable={false}
                width={700}
                placement="right"
                visible={visiblePrint}>
                <PrintSIDComp billSelected={sids}
                    onSubmit={() => handleClosePrintSID()}
                    onCancel={() => handleClosePrintSID()} />
            </Drawer>
            <Drawer 
                maskClosable={false}
                mask={true}
                closable={false}
                width={700}
                placement="right"
                destroyOnClose={true}
                visible={visibleEdit}>
                <div style={{ lineHeight: "20px" }}>
                    <SidChange data={dataUpdateSID} onCancel={() => handleCloseEditSID()}></SidChange>
                </div>
            </Drawer>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TestReceived)