import React, { Fragment, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from "react";
import { Input } from 'antd';
import Notify, { NotifyStatus } from '../../../../components/notify';
import MitaDisplayLongText from '../../../../components/MitaComponents/mitaDisplayLongText';
import * as TienSuService from "../../../../services/TienSuService";
import HeaderModal from "../../../../configureHIS/common/headerModal";

const { TextArea } = Input;
export default forwardRef((props, ref) => {
    const _notiRef = useRef();
    //biến cục bộ
    const showMoreText='...Xem thêm';
    const showLessText=' Rút gọn';
    const [isEdit, setIsEdit] = useState(props.isEdit);
    const [patientId, setPatientId] = useState('');
    const [patientName, setPatientName] = useState('');
    const [medicalHistory, setMedicalHistory] = useState({});
    const [banThan, setBanThan] = useState('');
    const [giaDinh, setGiaDinh] = useState('');
    const [diUngThuoc, setDiUngThuoc] = useState('');
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        Reload: () => {
            GetMedicalHistory();
        },
    }));

    useEffect(() => {
        props.patientId && setPatientId(props.patientId);
        props.patientName && setPatientName(props.patientName);
    }, [props])

    useEffect(() => {
        if(patientId !== '')
            GetMedicalHistory();
    }, [patientId])
    
    const GetMedicalHistory = () => {
        TienSuService.GetMedicalHistoryAsync(patientId).then(result => {
            if (result.isSuccess) {
                setMedicalHistory(result.data);
                setBanThan(result.data?.banthan);
                setGiaDinh(result.data?.giadinh);
                setDiUngThuoc(result.data?.diungthuoc);
                forceUpdate();
            } else {
                _notiRef &&_notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notiRef &&_notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    const CreateUpdateMedicalHistoryAsync = () => {
        let data = {
            'banthan' : banThan,
            'giadinh' : giaDinh,
            'diungthuoc' : diUngThuoc,
            'maBN' : patientId
        }
        TienSuService.CreateUpdateMedicalHistoryAsync(data).then(result => {
            if (result.isSuccess) {
                _notiRef &&_notiRef.current.Show("Cập nhật thành công.", NotifyStatus.Success);
                forceUpdate();
                setTimeout(() => {
                    props.onClose()
                }, 1000);
            } else {
                _notiRef &&_notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notiRef &&_notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    return (
        <Fragment>
        <div className='col-12 my-2'>
            {isEdit && <HeaderModal title={`Cập nhật thông tin tiền sử - ${patientName}`} onClose={() => { props.onClose() }} /> }
            <Notify ref={_notiRef} />
            <div className="row">
                <div className="col-12">
                    <label className="mita-title">Bản thân</label>
                    {isEdit ? 
                        <TextArea rows={3} value={banThan} onChange={(e) => setBanThan(e.target.value)} />
                    :
                        <MitaDisplayLongText
                            content={banThan}
                            maxLength={64} // mặc định 64
                            showMoreText={showMoreText}
                            showLessText={showLessText}
                        />
                    }
                    
                </div>
                <div className="col-12 mt-2">
                    <label className="mita-title">Gia đình</label>
                    {isEdit ? 
                        <TextArea rows={3} value={giaDinh} onChange={(e) => setGiaDinh(e.target.value)} />
                    :
                        <MitaDisplayLongText
                            content={giaDinh}
                            maxLength={64} // mặc định 64
                            showMoreText={showMoreText}
                            showLessText={showLessText}
                        />
                    }
                    
                </div>
                <div className="col-12 mt-2">
                    <label className="mita-title">Dị ứng thuốc</label>
                    {isEdit ? 
                        <TextArea rows={3} value={diUngThuoc} onChange={(e) => setDiUngThuoc(e.target.value)} />
                    :
                        <MitaDisplayLongText
                            content={diUngThuoc}
                            maxLength={64} // mặc định 64
                            showMoreText={showMoreText}
                            showLessText={showLessText}
                        />
                    }
                </div>
                {isEdit && 
                <>
                    <div className='custom-hr' />
                    <div className='col-12 d-flex justify-content-end'>
                        <button className='btn btn-color mr-2' onClick={() => CreateUpdateMedicalHistoryAsync()}><i className='fas fa-save mr-2'/> Lưu</button>
                        <button className="btn btn-secondary" onClick={() => {props.onClose && props.onClose()}}><i className="far fa-times-circle mr-2" /> Đóng</button>
                    </div>
                </>
                }
                
            </div>
        </div>
        </Fragment>
    )
});
