import { notification } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';

function ActionControl(props) {
    // Hiển thị nút lưu, action props.handleSave()
    const [activeSave, setActiveSave] = useState(props.activeSave);
    // Hiển thị nút cập nhật, action props.handleUpdate()
    const [activeUpdate, setActiveUpdate] = useState(props.activeUpdate);
    // Hiển thị nút lưu và in, action props.handleSavePrint()
    const [activeSavePrint, setActiveSavePrint] = useState(props.activeSavePrint);
    // Hiển thị nút nhập mới, action props.handleRetype()
    const [activeRetype, setActiveRetype] = useState(props.activeRetype);
    // Hiển thị nút lưu và chuyển bước, action props.hanldeSaveAdNextStep()
    const [activeSaveAndNextStep, setActiveSaveAndNextStep] = useState(props.activeSaveAndNextStep);
    // Hiển thị nút quay lại bước trước đó, action props.handleBackward()
    const [activeBackward, setActiveBackward] = useState(props.activeBackward);
    // Hiển thị nút đóng, action props.handleClose()
    const [activeClose, setActiveClose] = useState(props.activeClose ?? true);
    // Action đóng props.handleClose()

    const handleSave = () => {
        if (props.handleSave) {
            props.handleSave();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleUpdate = () => {
        if (props.handleUpdate) {
            props.handleUpdate();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleSaveAndPrint = () => {
        if (props.handleSavePrint) {
            props.handleSavePrint();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleRetype = () => {
        if (props.handleRetype) {
            props.handleRetype();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleSaveAndNextStep = () => {
        if (props.handleSaveAndNextStep) {
            props.handleSaveAndNextStep();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleClose = () => {
        if (props.handleClose) {
            props.handleClose();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    const handleBackward = () => {
        if (props.handleBackward) {
            props.handleBackward();
        } else {
            notification.error({ message: "Không thể thao tác, không tìm thấy function" });
        }
    }

    useEffect(() => {
        setActiveSave(props.activeSave);
        setActiveUpdate(props.activeUpdate);
        setActiveSavePrint(props.activeSavePrint);
        setActiveRetype(props.activeRetype);
        setActiveSaveAndNextStep(props.activeSaveAndNextStep);
        setActiveBackward(props.activeBackward);
        setActiveClose(props.activeClose);
    }, [props])

    return (
        <Fragment>
            <div className='custom-hr' />
            <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    {activeRetype && <button
                        className='btn btn-secondary'
                        onClick={handleRetype}>
                        <i className='fas fa-plus-circle mr-1' />Nhập mới
                    </button>}
                    {activeBackward && <button
                        className='btn btn-light ml-2'
                        onClick={handleBackward}>
                        <i className='fas fa-chevron-left mr-1' />Quay lại
                    </button>}
                </div>
                <div className='d-flex justify-content-end'>
                    {activeSaveAndNextStep && <button
                        className='btn btn-primary'
                        onClick={handleSaveAndNextStep}>
                        <i className='fas fa-save mr-1' />Lưu và chuyển bước
                    </button>}
                    {activeSave && <button
                        className='btn btn-color ml-2'
                        onClick={handleSave}>
                        <i className='fas fa-save mr-1' />Lưu
                    </button>}
                    {activeUpdate && <button
                        className='btn btn-color ml-2'
                        onClick={handleUpdate}>
                        <i className='fas fa-save mr-1' />Cập nhật
                    </button>}
                    {activeSavePrint && <button
                        className='btn btn-primary ml-2'
                        onClick={handleSaveAndPrint}>
                        <i className='fas fa-save mr-1' />Lưu và in
                    </button>}
                    {activeClose && <button
                        className='btn btn-danger ml-2'
                        onClick={handleClose}>
                        <i className='far fa-times-circle mr-1' />Đóng
                    </button>}
                </div>
            </div>
        </Fragment>
    );
}

export default ActionControl;