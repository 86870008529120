import { Button, Form, Input, Modal, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../../commons/css/barcode.css';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/commonService';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import Table from 'rc-table';
import moment from 'moment';
import * as SystemConfig from "../../../configure/systemConfig";

let task = null
class barcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            barcodekey: '',
            barcodeTxt: '',
            activeBarcode: false,
            lstPatient: [],
            lstPatientKhaibao: [],
            key: '',
            hoten: '',
            idkhaibao: '',
        };
        // this.handleKey = this.handleKey.bind(this);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKey.bind(this));
    }
    handleKey(e) {
        if (this.state.visible) {
            if (e.keyCode === 13) {
                let qrtext = this.state.barcodekey;
                this.SearchPatientByIdKhaiBao(qrtext);
            }
            else {
                this.setState({ barcodekey: (this.state.barcodekey + e.key).trim(), barcodeTxt: (this.state.barcodekey + e.key).trim() })
            }
        }
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    updateDataTable(key = '', page = 1, size = 10) {
        if (this.props.changePage) {
            this.props.changePage(key, page, size);
        }
    }

    loadPatient(data, page = 1) {
        if (this.props.onType) {
            this.props.onType(data);
        }
        this.setState({ key: data });
        return PatientService.searchPatient(data, page);
        // .then(result => {
        //     if (result.isSuccess) {
        //         // if (result.data.length === 1) {
        //         //     this.loadBarCode(result.data[0].patientID);
        //         //     this.setState({
        //         //         visible: false,
        //         //         activeBarcode: false,
        //         //         lstPatient: [],
        //         //         barcodekey: '',
        //         //         barcodeTxt: '',
        //         //     })
        //         // }
        //         // else {
        //         this.setState({
        //             lstPatient: result.data
        //         })
        //         //  }
        //     }
        // })
    }
    SearchPatientByIdKhaiBao(textdata) {
        Actions.setLoading(true);
        PatientService.SearchPatientByIdKhaiBao(textdata).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPatientKhaibao: result.data,
                    idkhaibao: result.returnObject,
                    barcodeTxt: '',
                    barcodekey: '',
                })
                if (result.data.length === 0) {
                    notification.error({ message: 'Không tìm thấy dữ liệu' });
                    this.setState({
                        visible: false
                    })
                }
                if (result.data.length === 1) {
                    if (result.data[0].patientID == null) {
                        result.data[0].idkhaibao = result.returnObject;
                        this.setState({ key: result.data[0].patientName })
                        this.props.onSuccessLoad(result.data[0])
                    }
                    else {
                        result.data[0].idkhaibao = result.returnObject;
                        this.loadBarCode(result.data[0]);
                    }
                    this.setState({
                        visible: false
                    })
                }
            } else {
                notification.error({ message: result.err.msgString });
                this.setState({
                    lstPatientKhaibao: [],
                    barcodeTxt: '',
                    barcodekey: '',
                })
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: err })
        })
    }
    loadBarCode(data) {
        this.setState({ key: data.patientName });
        PatientService.GetPatientDetail(data.patientID).then(result => {
            if (result.isSuccess) {
                this.setState({
                    barcodekey: '',
                    barcodeTxt: '',
                    visible: false,
                    activeBarcode: false,
                    lstPatient: []
                })
                result.item.idkhaibao = this.state.idkhaibao;
                this.props.onSuccessLoad(result.item);
                Actions.setDataBarCode(data.patientID);
            } else {
                this.setState({
                    barcodeTxt: '',
                    barcodekey: '',
                    visible: false,
                    lstPatient: []
                })
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            this.setState({
                visible: false,
                barcodekey: '',
                barcodeTxt: '',
                activeBarcode: false,
                lstPatient: []
            })
            notification.warning({ message: err })
        })
    }


    showModal = () => {
        // document.addEventListener('keydown', this.handleKey, false);
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            barcodekey: '',
            barcodeTxt: ''
        });
    };
    handleCancel = e => {
        // document.removeEventListener('keydown', this.handleKey, false);
        this.setState({
            visible: false,
            barcodekey: '',
            barcodeTxt: ''
        });
    };

    onBlurPatientName(data) {
        if (this.props.onBlurPatientName) {
            this.props.onBlurPatientName(data);
        }
    }

    render() {
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
                width: 100,
            },
            {
                title: 'Tên BN',
                dataIndex: 'patientName',
                key: 'patientName',
                width: 250,
            },
            // {
            //     title: 'Năm sinh',
            //     dataIndex: 'birthYear',
            //     key: 'birthYear',
            // render: text => moment(text, "YYYY-MM-DD").format(SystemConfig.FORMAT_DATE)
            // },
            {
                title: 'Năm sinh',
                dataIndex: 'year',
                key: 'year',
                width: 80,
                align: "right",
                render: (value, record) => {
                    return <span>{record.onlyyear ? value : moment(record.birthYear).format(SystemConfig.FORMAT_DATE)}</span>
                },
            },
            {
                title: 'Giới tính',
                dataIndex: 'gender',
                key: 'gender',
                width: 80,
                render: (value) => {
                    return <span>{Number(value) === 0 ? 'NAM' : 'NỮ'}</span>
                },
                format: 'gender',
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 80,
                align: "center",
            },
            {
                title: 'CCCD/HC',
                dataIndex: 'cardId',
                key: 'cardId',
                width: 100,
                align: "center",
            },
            // {
            //     title: '',
            //     render: (row) => {
            //         return (
            //             <React.Fragment>
            //                 <Button type="primary" title="Chọn"
            //                     onClick={() => this.loadBarCode(row.patientID)}
            //                     className="btn btn-primary">Chọn</Button>
            //             </React.Fragment>
            //         )
            //     }
            // }
        ];
        return (
            <div>
                <Form.Item >
                    <AutocompletetableNoGroup
                        ref={(c) => this.AutocompletePatient = c}
                        placeholder={"Nhập họ tên, mã bệnh nhân hoặc số điện thoại"}
                        width='100%'
                        tablewidth={750}
                        onSearch={(value, page) => this.loadPatient(value, page)}
                        onSelect={(value) => {
                            this.setState({
                                idkhaibao: ''
                            }, () => {
                                this.loadBarCode(value)
                            })
                        }}
                        onBlurPatientName={this.onBlurPatientName.bind(this)}
                        headers={columns}
                        keyValue={'patientID'}
                        value={this.state.key}
                        qrcode={this.props.qrcode === false ? false : true}
                        showQRCode={() => {
                            this.setState({ visible: true, idkhaibao: '' })
                        }}
                        pagination={true}
                    >
                    </AutocompletetableNoGroup>
                    {this.state.visible === true &&
                        <Modal maskClosable={false}
                            title="Barcode Scanning"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            width={800}
                            onCancel={this.handleCancel}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >

                            <div className="ant-modal-header" style={{ display: 'flex', justifyContent: 'center' }}>
                                <h3>Barcode Scanning</h3>
                            </div>
                            <div className="ant-modal-body">
                                <div style={{ width: '100px' }} className='anim-box center spacer align-self-center'>
                                    <div className='scanner'>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{
                                        display: 'flex', flex: 1, width: '60%', justifyContent: 'center', alignItems: 'center',
                                        marginBottom: 15
                                    }} >
                                        <Form.Item style={{ width: 350, marginRight: 30, display: 'none' }}>
                                            <Input
                                                autoFocus={true}
                                                placeholder="Mã,tên bệnh nhân,chứng minh hoặc số điện thoại..."
                                                value={this.state.barcodeTxt}
                                            // onChange={(e) => {
                                            //     this.setState({ barcodeTxt: e.target.value })
                                            // }} 
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                {this.state.lstPatientKhaibao.length > 1 &&
                                    <div className="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Mã BN</th>
                                                    <th>Họ tên</th>
                                                    <th>Ngày sinh</th>
                                                    <th>Địa chỉ</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lstPatientKhaibao.map((row, index) => <tr key={index}>
                                                    <td>{row.patientID}</td>
                                                    <td>{row.patientName}</td>
                                                    <td>{moment(row.birthDay).format(SystemConfig.FORMAT_DATE)}</td>
                                                    <td>{row.address}</td>
                                                    <td>
                                                        <Button
                                                            onClick={() => {
                                                                row.idkhaibao = this.state.idkhaibao;
                                                                this.loadBarCode(row)
                                                            }}
                                                        >Chọn</Button>
                                                        {/* <div
                                                    style={{ cursor: 'pointer', border:'1px solid yellow' }}
                                                >Chọn</div> */}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                                    <Button type="primary" style={{ marginLeft: 5 }} danger
                                        onClick={() => {
                                            this.setState({ visible: false, lstPatientKhaibao: [] })
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                        /> Đóng

                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    }
                </Form.Item>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        activeBarcode: state.global.activeBarcode,
    })
};
export default connect(
    mapStateToProps,
)(barcode);

