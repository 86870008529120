
import { EditFilled, DeleteFilled } from '@ant-design/icons';
import create from '@ant-design/icons/lib/components/IconFont';
import { Button, Form, Input, Modal, notification, Pagination, Table, Select, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as MedicineService from '../../../../services/medicineService';
import CreateHSX from './CreateModal';
import * as Actions from "../../../../libs/actions";
import HeaderModal from '../../../../configureHIS/common/headerModal';
import { BUTTON } from '../../../../configureHIS/common/constants';
const initialState = {
    id: '',
    ten: '',
    key: '',
    ghichu: '',
    totalRecords: 0,
    currentNumber: 1,
    pageSize: 10
}
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstHangSX: [],
            visible: false,
            visibleCreate: false,
            disabledUpdate: true,
            visibleLuu: true,
            visibleUpdate: false
        };
    }

    componentWillMount() {
        this.loadHSX();
    }

    loadHSX(page = this.state.currentNumber, size = this.state.pageSize) {
        Actions.setLoading(true);
        MedicineService.HangSanXuat(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstHangSX: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu hãng sản xuất bị lỗi' })
        }).finally(() => Actions.setLoading(false))
    }

    loadDetail(id) {
        Actions.setLoading(true);
        MedicineService.GetDetailHSX(id).then(result => {
            if (result.isSuccess) {
                this.setState({
                    id: result.item.value,
                    ten: result.item.label,
                    ghichu: result.item.typeName,
                    visibleUpdate: true
                })
                this.form.setFieldsValue({
                    id: result.item.value,
                    ten: result.item.label,
                    ghichu: result.item.typeName,
                })
            }
        }).catch(err => {

        }).finally(() => Actions.setLoading(false))
    }

    async upd_HSX() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            Actions.setLoading(true);
            const data = {
                id: this.state.id,
                ten: this.state.ten,
                ghichu: this.state.ghichu,
                action: 'update',
            }
            MedicineService.TaoHangSanXuat(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        disabledUpdate: true,
                        visabledLuu: true,
                        visibleUpdate: false
                    })
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.loadHSX();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            }).finally(() => Actions.setLoading(false))
        } else {
            this.validator.showMessages();
        }
    }
    DeleteHSX(row) {
        const data = {
            id: row.value,
            action: "delete",
        }
        Actions.setLoading(true);
        MedicineService.TaoHangSanXuat(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadHSX();
            }

        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    showModalUpdate = () => {

        this.setState({
            visibleUpdate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visibleCreate: false,
            visibleUpdate: false
        });
        this.loadHSX();
    };

    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'value',
                key: 'value',
                width: 70
            },
            {
                title: 'Tên hãng',
                dataIndex: 'label',
                key: 'label',
                width: 300
            },
            {
                title: 'Ghi chú',
                dataIndex: 'typeName',
                key: 'typeName',
                width: 300
            },
            {
                title: '',
                width: 200,
                render: (row) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-color" title="Cập nhật"
                                onClick={() => this.loadDetail(row.value)}>
                                <i className="fa fa-edit" />
                            </button>
                            <Popconfirm title="Bạn có chắc muốn xóa nhà cung cấp này không?"
                                onConfirm={() => this.DeleteHSX(row)}
                                okText="Đồng ý"
                                cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: 15 }}
                                    title="Xóa">
                                    <i className="fa fa-eraser" />
                                </button>
                            </Popconfirm>
                        </React.Fragment>
                    )
                }
            }
        ];
        let dataSource = this.state.lstHangSX.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <>
                <div className='containerChilderWrapper'>
                    <HeaderModal title="Quản lý hãng sản xuất" />
                    <div className="row justify-content-center">
                        <div className="col-auto mt-2">
                            <Input
                                style={{ width: "300px" }}
                                value={this.state.key}
                                onChange={(e) => this.setState({ key: e.target.value })}
                                placeholder="Nhập mã hoặc tên hãng sản xuất"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.loadHSX(1, this.state.pageSize);
                                    }
                                }} />
                        </div>
                        <div className="col-auto mt-2">
                            <button className={`${BUTTON.THEME}`}
                                onClick={() => this.loadHSX(1, this.state.pageSize)}><i className="fa fa-search mr-1" />Tìm</button>
                            <button className={`${BUTTON.THEME} ml-2`}
                                onClick={this.showModalCreate}><i className="fa fa-plus mr-1" />Tạo mới</button>
                        </div>
                    </div>
                    <div className="mt-2" style={{ width: '100%', height: 'calc(100% - 160px)' }}>
                        <Table dataSource={dataSource} scroll={{ x: 'max-content', y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentNumber}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => {
                            this.setState({ currentNumber: page, pageSize: size }, () => {
                                this.loadHSX(page, size);
                            })
                        }}
                        onShowSizeChange={(page, size) => { this.setState({ currentNumber: 1, pageSize: size }); this.loadHSX(1, size) }}
                        showSizeChanger={true}
                    />
                </div>

                <Modal maskClosable={false}
                    width={1000}
                    style={{ display: 'plex' }}
                    visible={this.state.visibleCreate}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateHSX onCancel={this.handleCancel} />
                </Modal>

                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visibleUpdate}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <Form ref={(c) => this.form = c}>
                        <div className="ant-modal-header" style={{ display: 'flex', justifyContent: 'center' }}>
                            <h3>CẬP NHẬT HÃNG SẢN XUẤT</h3>
                        </div>
                        <div className="ant-modal-body">
                            <div style={{ display: 'flex' }}>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Tên:" name="ten"
                                        rules={[{ required: true, message: 'Yêu cầu nhập tên!' }]}>
                                        <Input
                                            value={this.state.ten}
                                            onChange={(e) => this.setState({ ten: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                    <Form.Item label="Ghi chú:" name="ghichu"
                                        rules={[{ required: false }]}>
                                        <Input
                                            value={this.state.ghichu}
                                            onChange={(e) => this.setState({ ghichu: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="form-group" style={{ flex: 1 }} >
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <button className="btn btn-color" style={{ marginLeft: 10 }}
                            onClick={() => this.upd_HSX() && this.setState({ hideUpdate: true })}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visibleUpdate: false })
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </button>
                    </div>
                </Modal>
            </>

        )
    }
}