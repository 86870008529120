import { Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as estimatesService from '../../../../services/estimatesService';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstDetail: [],
        };
    }
    componentDidMount() {
        this.ChiTietPhieu();
    }
    ChiTietPhieu() {
        estimatesService.ChiTietPhieu(this.props.id, moment(this.props.ngay).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bị lỗi")
        })
    }

    render() {
        const columnDuTru = [
            {
                title: 'Bệnh nhân',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Mã QL',
                dataIndex: 'maql',
                key: 'maql',
            },
            {
                title: 'Mã vào viện',
                dataIndex: 'mavaovien',
                key: 'mavaovien',
            },
            {
                title: 'Kho',
                dataIndex: 'tenkho',
                key: 'tenkho',
            },
            {
                title: 'Nguồn',
                dataIndex: 'tennguon',
                key: 'tennguon',
            }
        ]
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'mathuoc',
                key: 'mathuoc',
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Số ngày',
                dataIndex: 'songay',
                key: 'songay',
            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
            }
        ]
        return (
            <React.Fragment>
                <div style={{ padding: "5px 20px" }}>
                    <div className='ccCenter'>
                        <h4 style={{ textTransform: "uppercase" }}> Chi tiết phiếu</h4>
                    </div>
                </div>
                <Table scroll={{ y: 0 }}  dataSource={this.state.lstDetail} columns={columnDuTru} rowKey={(row, index) => index}
                    expandable={{
                        expandedRowRender: (record) => (
                            <Table columns={columnChild} dataSource={record.lstChiTiet} pagination={false} rowKey={(row, index) => index} />
                        ), rowExpandable: record => record.lstChiTiet.length > 0,
                    }}
                />
            </React.Fragment>
        )
    }
}
