import React from 'react';
import { Tabs } from 'antd';
import DanhsachPhieuLanhThuoc from '../phieulanhthuoc/danhsach';
import DanhsachPhieuTraThuoc from '../phieutrathuoc/danhsach';
const { TabPane } = Tabs

function XacNhanPhieuLanhThuoc(props) {
    return (
        <React.Fragment>
            {/* Khoa dược xác nhận */}
            <Tabs className='tab-header-services tab-header-exam' centered={true} defaultActiveKey={'1'}>
                <TabPane tab={"Phiếu lãnh thuốc"} key={'1'}>
                    <div className="h-100" style={{ padding: "10px 15px" }}>
                        <div className="h-100" style={{ background: '#fff', padding: '10px', overflowY: 'auto' }}>
                            <DanhsachPhieuLanhThuoc
                                isKhoaDuoc={true}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={"Phiếu trả thuốc"} key={'2'}>
                    <div className="h-100" style={{ padding: "10px 15px" }}>
                        <div className="h-100" style={{ background: '#fff', padding: '10px', overflowY: 'auto' }}>
                            <DanhsachPhieuTraThuoc
                                isKhoaDuoc={true}
                            />
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </React.Fragment>
    )
}

export default XacNhanPhieuLanhThuoc;