import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, Input, Button, Tooltip, InputNumber, notification } from 'antd';
import Handlebars from 'handlebars';
import handlebarHelper from "../../../libs/handlebarHelper";
import * as Action from '../../../libs/actions';
import { getPageSetting, isNullOrEmpty} from '../../../libs/util';
import Notify, { NotifyStatus } from '../../../components/notify';
import { BUTTON } from "../../../configureHIS/common/constants";
import * as Constants from "../../../configureHIS/common/constants";
import * as InvoiceService from '../../../services/invoiceService';
import * as PrintService from '../../../services/printService';

/** Component này dùng để hiển thị popup hoàn trả
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\invoice\trahoadon\preview.js
    - src\screens\invoice\payment\components\ListPayment.js
 ** Các props nhận vào:
* @param {string} props.managementId: mã quản lý.
* @param {string} props.patientId: mã bệnh nhân.
* @param {Array} props.listMethod: list hình thức thanh toán.
* @param {Array} props.listDetail: array. list item cần hoàn trả
    props.listDetail = [{
        @param {string} "billNumber": "10315",
        @param {string} "code": "2-1508",
        @param {string} "name": "Anti TG",
        @param {number} "type": 0, // enum: SERVICE = 0 | MEDICALSUPPLIES = 1 | MEDICINE = 2
        @param {string} "typeText": "DỊCH VỤ" | VẬT TƯ | THUỐC,
        @param {boolean} "isCombo": false,
        @param {boolean} "isChild": false,
        @param {boolean} "isRefund": false,
        @param {string} "idChiDinh": "230712093920537004", // Là id của chidinh nếu là dịch vụ. Id của d_ngtrull nếu là vật tư & thuốc
        @param {number} "quantity": null, // Số lượng, nếu là thuốc hay vật tư
        @param {number} "price": 280000,
        @param {number} "discount": 0,
        @param {number} "discountPercent": null,
        @param {number} "loyalty": 0,
        @param {number} "discountCard": 0,
        @param {number} "total": 280000,
        @param {Array} "listChild": [], // Này là như listDetail luôn. Nếu là combo thì có cái này
    }]
* @param {Array} props.listMaster: array. list bill liên quan đến các detail ở trên
    props.listMaster = [{
        @param {number} "siteId": 1,
        @param {string} "billNumber": "10315",
        @param {string} "remark": "",
        @param {number} "billType": 0, // enum
        @param {string} "billTypeText": "thanh toán",
        @param {string} "managementId": "230712092010947001",
        @param {string} "paidDate": "2023-07-12 03:13:46Z",
        @param {string} "cashierId": 1,
        @param {string} "cashier": "MTC",
        @param {string} "vatInvoice": true,
        @param {Array[string]} "paymentTypeName": ["Tiền mặt"],
        @param {Array[number]} "paymentType": [1],
        @param {number} "total": 678989, // Tồng tiền 
        @param {number} "discount": 200000,
        @param {number} "discountPercent": null,
        @param {number} "loyalty": 0,
        @param {number} "paid": 478989,
        @param {number} "refund": null,
        @param {number} "revenue": null,
        @param {Array} "listDetail": [], // listDetail giống ở trên. Nhưng list này là full của bill
        @param {Array} "listRef": null
    ]
 */
const ALLOW_TO_CHANGE_MEDICINE_QUANTITY = false;
//tương ứng enum ProductTypeEnum
const ProductTypeEnum = {
    SERVICE: 0,
    MEDICALSUPPLIES: 1,
    MEDICINE: 2,
}
var defaultMethod = null;
const ActionRefund = forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [listMaster, setListMaster] = useState(props.listMaster ?? []);
    const [listDetail, setListDetail] = useState(props.listDetail ?? []);
    const [managementId, setManagementId] = useState(props.managementId);
    const [patientId, setPatientId] = useState(props.patientId);
    const [listMethod, setListMethod] = useState(props.listMethod);

    const [disableButtonRefund, setDisableButtonRefund] = useState(false);
    const [listError, setListError] = useState([]);
    //tổng tiền
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [loyalty, setLoyalty] = useState(0);
    const [refund, setRefund] = useState(0);
    //model để đẩy lên api refund
    const [ghiChu, setGhiChu] = useState();
    const [refundMode, setRefundMode] = useState();
    const [refundList, setRefundList] = useState();

    const [listHinhThucThanhToan, setListHinhThucThanhToan] = useState([]);
    // const [billNumber, setBillNumber] = useState(props.billNumber);
    // const [isBillRefund, setIsBillRefund] = useState(props.isBillRefund);

    useEffect(() => {
    }, [])
    
    useEffect(() => {
        setListHinhThuc();
    }, [listHinhThucThanhToan])
    
    useEffect(() => {
        setListMaster(props.listMaster);
        setListDetail(props.listDetail);
        setManagementId(props.managementId);
        setPatientId(props.patientId);
    }, [props])

    useEffect(() => {
        if (listError.length > 0) {
            setDisableButtonRefund(true);
            notiRef && notiRef.current.Show(listError.join('\n'), NotifyStatus.Warning);
        }
        else {
            setDisableButtonRefund(false);
            notiRef && notiRef.current && notiRef.current.Close();
        }
    }, [listError])

    useEffect(() => {
        let listHTTT = props.listMaster.map(x => x.paymentType).flat();
        setListHinhThucThanhToan(listHTTT);
        validate();
    }, [listMaster, listDetail])

    useEffect(() => {
        if (defaultMethod != null) setRefundMode(defaultMethod);
    }, [defaultMethod])

    function setListHinhThuc() {
        let t = [];
        if (listHinhThucThanhToan.indexOf(Constants.PAYMENTTYPE.DEBT) > -1) {
            t = props.listMethod.filter(x => x.ma == Constants.PAYMENTTYPE.DEBT);
        }
        else if (listHinhThucThanhToan.indexOf(Constants.PAYMENTTYPE.CARD) > -1){
            t = props.listMethod.filter(x => x.ma == Constants.PAYMENTTYPE.CARD);
        }
        else {
            t = props.listMethod.filter(x => x.ma != Constants.PAYMENTTYPE.CARD && x.ma != Constants.PAYMENTTYPE.DEBT);
        }
        setListMethod(t);
    }

    function validate(lMaster = listMaster, lDetail = listDetail) {
        let errStrs = [];
        // Nếu bill có miễn giảm toàn bill, thì phải hoàn trả full.
        let ttotal = 0;
        let tdiscount = 0;
        let tloyalty = 0;
        let trefund = 0;
        // "receiptNumber": 10513,
        // "tag": "tee",
        // "managermentId": "230712092010947001",
        // "paidDate": "2023-07-14 09:12:22Z",
        // "cashier": "MTC",
        // "paymentTypeName": "Tiền mặt",
        // "paymentType": 1,
        // "resume": {
        //     "total": 300000,
        //     "discount": 50000,
        //     "loyalty": 0,
        //     "paid": 250000,
        //     "refund": 0,
        //     "revenue": 250000
        // },
        // "listServices": [
        // ],
        // "listMedis": []
        let trefundList = [];
        listMaster.forEach(x => {
            let listDetailRefundInMaster = listDetail.filter(c => c.billNumber == x.billNumber) ?? [];
            //data to api
            let listService = (listDetailRefundInMaster.filter(c => c.type == ProductTypeEnum.SERVICE) ?? []).map(c => { return {
                "idChiDinh": c.idChiDinh,
                "name": c.name,
                // "status": "Chưa khám",
                "isChecked": true,
                "isRefunded": false,
                "refundBill": null,
                "price": c.price,
                "discount": c.discount,
                "loyalty": c.loyalty,
                // "card": 0,
                "total": c.total,
                // "groupName": "Khám bệnh",
                "voucher": null,
                "cardId": null,
                "paymentType": 1,
                "isHasDiscount": x.discount > 0 || x.loyalty > 0,
                "subServices": c.listDetail?.map(v => {return {
                    "idChiDinh": v.idChiDinh,
                    "name": v.name,
                    // "status": "Chưa khám",
                    "isChecked": true,
                    "isRefunded": false,
                    "refundBill": null,
                    "price": v.price,
                    "discount": v.discount,
                    "loyalty": v.loyalty,
                    // "card": 0,
                    "total": v.total,
                    // "groupName": "Khám bệnh",
                    "voucher": null,
                    "cardId": null,
                    "paymentType": 1,
                    "isHasDiscount": x.discount > 0 || x.loyalty > 0,
                    "unit": null,
                    "quantity": v.quantity,
                    "chiDinhComboId": v.chiDinhComboId,
                    "cashier": x.cashier,
                    // "paymentTypeName": "Tiền mặt",
                    "tag": x.remark,
                    "paidDate": x.paidDate
                }}) ?? [],
                "unit": null,
                "quantity": c.quantity,
                "chiDinhComboId": null,
                "cashier": x.cashier,
                // "paymentTypeName": "Tiền mặt",
                "tag": x.remark,
                "paidDate": x.paidDate
            }});
            let listMedis = (listDetailRefundInMaster.filter(c => c.type != ProductTypeEnum.SERVICE) ?? []).map(c => { return {
                "isMedicine": c.type == ProductTypeEnum.MEDICINE,
				"prescriptionNumber": c.idChiDinh,
				"name": c.name,
				"isChecked": false,
				"isRefunded": false,
				"refundBill": null,
				"total": c.total,
				"discount": c.discount,
				"revenue": c.total,
				"refund": 0,
                "isHasDiscount": x.discount > 0 || x.loyalty > 0,
				"subMedis": [
					{
						"name": c.name,
						"id": Number(c.idVienPhi),
						"quantity": c.quantity,
                        // api refund lấy quantityRefund làm số lượng hoàn trả. 
                        // nhưng theo model listDetail của namth thì quantityRefund là SL đã hoàn trả trước đó. rối rối ha, hậu quả của sự kết hợp nhiều code.
                        // Nên chỗ này set lại quantityRefund = quantity (cho hoàn trả full SL) cho nhanh do bị dí task quá.
                        // Nếu sau này mở ra cho hoàn trả 1 phần SL thì sửa lại nha.
						"quantityRefund": c.quantity,
						"price": c.price,
						// "listRefundNumber": "",
						"isRefunded": false,
						"maxCount": c.maxCount
					}
				]
            }});
            let refundMaster = {
                "billNo": x.billNo,
                "receiptNumber": Number(x.billNumber),
                "tag": x.remark,
                "managermentId": managementId,
                "paidDate": x.paidDate,
                "cashier": x.cashier,
                // "paymentTypeName": "Tiền mặt",
                // "paymentType": 1,
                "resume": {
                    "total": x.total,
                    "discount": x.discount,
                    "loyalty": x.loyalty,
                    "paid": x.paid,
                    "refund": x.refund,
                    "revenue": x.revenue
                },
                listServices: listService,
                listMedis: listMedis,
            };
            if (listService.length != 0 || listMedis.length != 0)
                trefundList.push(refundMaster);

            if (x.listDetail.length != listDetailRefundInMaster.length) {
                ttotal += listDetailRefundInMaster.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
                if (x.discount > 0 || x.loyalty > 0) {
                    errStrs.push(`Hóa đơn ${x.billNo}: Tất cả dịch vụ được hưởng giảm giá phải được hoàn trả cùng lúc.`);
                }
            }
            else {
                ttotal += x.total;
                tdiscount += (x.discount ?? 0);
                tloyalty += (x.loyalty ?? 0);
            }
            //2023.07.25: tạm không cho hoàn trả thuốc vì lỗi. Sẽ sửa sau.
            //2023.08.10: đóng cho hoàn trả thuốc để test #3987
            // if (listMedis.length > 0)
            //     errStrs.push(`Hiện tại không được phép hoàn trả thuốc & vật tư.`);
        })
        if (
            (listHinhThucThanhToan.indexOf(Constants.PAYMENTTYPE.DEBT) > -1 && listHinhThucThanhToan.find(x=>x != Constants.PAYMENTTYPE.DEBT)) ||
            (listHinhThucThanhToan.indexOf(Constants.PAYMENTTYPE.CARD) > -1 && listHinhThucThanhToan.find(x=>x != Constants.PAYMENTTYPE.CARD))
        )
            errStrs.push(`Các dịch vụ hoàn trả có thanh toán phức tạp. Vui lòng tách bill hoàn trả`);
        setListError(errStrs);
        trefund = ttotal - tdiscount - tloyalty;
        setTotal(ttotal);
        setDiscount(tdiscount);
        setLoyalty(tloyalty);
        setRefund(trefund);
        setRefundList(trefundList);
        // return errStrs;
    }

    function validateBeforeSave() {
        let errStrings = [];
        if (managementId == null || managementId == undefined)
            errStrings.push('Mã quản lý bắt buộc nhập.');
        if (patientId == null || patientId == undefined)
            errStrings.push('Mã bệnh nhân bắt buộc nhập.');
        if (refundMode == null || refundMode == undefined)
            errStrings.push('Vui lòng chọn hình thức hoàn trả.');
        if (ghiChu == null || ghiChu == undefined || ghiChu.length == 0)
            errStrings.push('Vui lòng nhập ghi chú.');
        if (refundList == null || refundList == undefined || refundList.length == 0)
            refundList.push('Vui lòng chọn ít nhất 1 dịch vụ / thuốc / vật tư (Không bao gồm đã hoàn trả).');
        return errStrings;
    }

    function handleRefund() {
        let errStrings = validateBeforeSave();
        if (errStrings.length > 0) {
            notiRef && notiRef.current && notiRef.current.Show(errStrings.join('\n'), NotifyStatus.Warning);
            return;
        }
        Action.setLoading(true);
        const data = {
            "RefundList": refundList,
            "Mode": refundMode,
            "Comment": ghiChu,
            "PatientCode": patientId,
            "ManagermentId": managementId,
            "TotalRefund": refund,
        };

        InvoiceService.refund(data).then(res => {
            if (res.isSuccess) {
                // props.onReload();
                if (!isNullOrEmpty(res.data['SoPhieuYeuCauHoanTra']))
                    Action.openMessageDialog("Lưu ý", `Có dịch vụ thuộc phiếu thanh toán trước ngày hôm nay. Hệ thống đã tạo phiếu yêu cầu hoàn trả ${res.data['SoPhieuYeuCauHoanTra']}`)
                if (getPageSetting("invoicePreview") && !isNullOrEmpty(res.data['SoPhieuHoanTra'])) printRefund(res.data['SoPhieuHoanTra']);
                notification.success({ message: "Hoàn trả thành công" });
                notiRef && notiRef.current && notiRef.current.Close();
                props.refundSuccess && props.refundSuccess();
            }
            else {
                notiRef && notiRef.current && notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
                props.refundError && props.refundError();
            }
        }).catch(err => {
            props.refundError && props.refundError();

        }).finally(() => {
            Action.setLoading(false);
        })
    }

    const printRefund = (refundNumber, managementId) => {
        Action.setLoading(true);
        PrintService.PrintRefund(refundNumber).then((result) => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Action.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Action.DoPrint(template(result.data));
                }
            else Action.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).finally(() => {
            Action.setLoading(false);
        })
    }

    function getDefaultMethod() {
        let type = null;
        let l = listMaster.map(x => (x?.paymentType ?? []));
        let foundPaymentType = l.flat();
        if (foundPaymentType.length == 1) {
            type = foundPaymentType[0];
        } else if (foundPaymentType.length > 1 && !foundPaymentType.includes(Constants.PAYMENTTYPE.DEBT) && !foundPaymentType.includes(Constants.PAYMENTTYPE.CARD)) {
            type = Constants.PAYMENTTYPE.CASH;
        }
        // let foundListReceiptNumber = list?.filter(x => (x.listMedis?.filter(y => (y.subMedis?.filter(z => z.isChecked && z.isRefunded == false) ?? []).length > 0).length > 0) || (x.listServices?.filter(y => y.isChecked && y.isRefunded == false) ?? []).length > 0).map(x => x.receiptNumber);
        // if (isnotnull(list)) {
        //     let foundPaymentType = [... new Set(list.filter(x => foundListReceiptNumber.includes(x.receiptNumber)).map(x => (x.paymentType)))];
        //     if (foundPaymentType.length == 1) {
        //         type = foundPaymentType[0];
        //     } else if (foundPaymentType.length > 1 && !foundPaymentType.includes(Constants.PAYMENTTYPE.DEBT) && !foundPaymentType.includes(Constants.PAYMENTTYPE.CARD)) {
        //         type = Constants.PAYMENTTYPE.CASH;
        //     }
        // }
        defaultMethod = type;
        return type;
    }

    function renderDichVu(r) {
        return (
            <div className='pl-2 mt-1 border-bottom d-flex justify-content-between' key={`ser-${r.idChiDinh}`}>
                <label className='mita-label' style={{ width: "40%" }}>{r.name}</label>
                <div className='d-flex' style={{ textAlign: 'end' }}>
                    <div>
                        <label className='mr-2'>* Đơn giá</label>
                        <label>{Number(r.price ?? 0).toLocaleString("en-US")}</label>
                    </div>
                    {r.discount && Number(r.discount) > 0 ? 
                    <div className='ml-2'>
                        <label className='mr-2'>* Miễn giảm</label>
                        <label className='text-warning'>{Number(r.discount ?? 0).toLocaleString("en-US")}</label>
                    </div> : ""}
                    {r.loyalty && Number(r.loyalty) > 0 ? 
                    <div className='ml-2'>
                        <label className='mr-2'>* CTKM</label>
                        <label className='text-success'>{Number(r.loyalty ?? 0).toLocaleString("en-US")}</label>
                    </div> : ""}
                    {r.discountCard && Number(r.discountCard) > 0 ? 
                    <div className='ml-2'>
                        <label className='mr-2'>Thẻ</label>
                        <label className='text-success'>{Number(r.discountCard ?? 0).toLocaleString("en-US")}</label>
                    </div> : ""}
                    <div className='ml-2'>
                        <label className='mr-2'>* Thành tiền</label>
                        <label className='text-info'>{Number(r.total ?? 0).toLocaleString("en-US")}</label>
                    </div>
                </div>
            </div>
        )
    }

    //vật tư & thuốc
    function renderVatTu(y) {
        let maxCount = y.quantity - y.quantityRefund;
        return(
            <div className='pl-2 d-flex justify-content-between mt-1' key={`med-${y.idChiDinh}`}>
                <label className='mita-label' style={{ width: "40%" }}>{y.name}</label>
                <Tooltip title={`Có thể hoàn trả tối đa ${maxCount}`} color='#00c254'>
                    <div className='d-flex flex-row align-items-center'>
                        <span>SL</span>
                        <InputNumber disabled={!ALLOW_TO_CHANGE_MEDICINE_QUANTITY} value={maxCount} className='ml-1' min={1} max={maxCount} 
                            // onChange={(quant) => { ALLOW_TO_CHANGE_MEDICINE_QUANTITY && updateQuantityRefund(x.receiptNumber, x.prescriptionNumber, cIndex, y.name, quant) }}
                        ></InputNumber>
                        <span className='ml-1'>{(y.price ?? 0).toLocaleString("en-US")}</span>
                        <span style={{ color: "#576cdf", marginLeft: 20 }} className='d-flex justify-content-end'>{((maxCount * y.price) ?? 0).toLocaleString("en-US")}</span>
                    </div>
                </Tooltip>
            </div>
        )
    }

    function getDanhSachCanHoanTra() {
        let res = [];
        let detDV = listDetail.filter(x => x.type == ProductTypeEnum.SERVICE);
        (detDV ?? []).forEach(element => {
            res.push(renderDichVu(element));
        });
        let detVT = listDetail.filter(x => x.type != ProductTypeEnum.SERVICE);
        (detVT ?? []).forEach(element => {
            res.push(renderVatTu(element));
        });
        return res;
    }

    return (
        <Fragment>
            <div style={{ width: 600 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <Notify ref={notiRef} />
                <div className='required-field mita-title'>Danh mục hoàn trả</div>
                <div className='w-100'>
                    {getDanhSachCanHoanTra()}
                </div>
                <div className='w-100 mt-2'>
                    {total > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#576cdf", height: 40 }}>
                        <label style={{ textTransform: "uppercase" }}>Thành tiền</label>
                        <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{total.toLocaleString("en-US")}</label>
                    </div>}
                    {discount > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#e4a033", height: 40 }}>
                        <label style={{ textTransform: "uppercase" }}>Miễn giảm</label>
                        <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{discount.toLocaleString("en-US")}</label>
                    </div>}
                    {loyalty > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#57a127", height: 40 }}>
                        <label style={{ textTransform: "uppercase" }}>CTKM</label>
                        <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{loyalty.toLocaleString("en-US")}</label>
                    </div>}
                    {/* {calcSpecial("CARD") > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#57a127", height: 40 }}>
                        <label style={{ textTransform: "uppercase" }}>Thẻ trả trước</label>
                        <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcSpecial("CARD").toLocaleString("en-US")}</label>
                    </div>} */}
                    <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#b13431", height: 40 }}>
                        <label style={{ textTransform: "uppercase" }}>Hoàn trả</label>
                        <Select value={refundMode}
                            placeholder="Chọn phương thức"
                            onChange={(e) => {
                                setRefundMode(e);
                            }}
                            defaultValue={getDefaultMethod()}
                            style={{ width: "60%" }}
                        >
                            {
                                listMethod && listMethod.map((medthod, index) => {
                                    let key = index + '-' + medthod.ma;
                                    return (
                                        <Select.Option key={key} value={medthod.ma}>{medthod.ten}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                        <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{(refund).toLocaleString("en-US")}</label>
                    </div>
                    <div className='mt-2'>
                        <label className='required-field mita-title'>Ghi chú</label>
                        <Input.TextArea placeholder='Nhập thông tin ghi chú' value={ghiChu} onChange={(e) => { setGhiChu(e.target.value) }} autoSize></Input.TextArea>
                    </div>
                    <div className='d-flex justify-content-end mt-2'>
                        <Button style={{ textTransform: "uppercase" }}
                            className={disableButtonRefund ? BUTTON.SECONDARY : BUTTON.INFO}
                            disabled={disableButtonRefund}
                            id={"btn-refund"}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRefund();
                            }}>Hoàn trả</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
});

const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps)(ActionRefund)