import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Storage';

export function SaveStorage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/luuphongluu`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function XuatPhongLuu(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XuatPhongLuu`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

