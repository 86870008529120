import React, { useEffect, useState, } from 'react'
import { Popconfirm, DatePicker, Modal, notification } from 'antd';
import { useSelector } from 'react-redux';
import * as Actions from '../../../../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as InHospitalService from '../../../../../services/inPatientService';
import moment from 'moment';
import PhieuTraThuoc from './phieutrathuoc'
import * as PrintService from '../../../../../services/printService';
const { RangePicker } = DatePicker;
function DanhSachPhieuLanhThuoc(props) {
    const [isKhoaDuoc, setIsKhoaDuoc] = useState(props.isKhoaDuoc === true);
    const [ngayTao, setNgayTao] = useState([moment(), moment()]);
    const [trangthai, setTrangthai] = useState(0);
    const [keySearch, setKeySearch] = useState('');
    const [lstTrangthai, setLstTrangthai] = useState([]);
    const [lstDataSource, setLstDataSource] = useState([]);
    const [checkAllSelect, setCheckAllSelect] = useState(true);
    const [visiblePhieuTraThuoc, setVisiblePhieuTraThuoc] = useState(false);
    const [idphieutra, setidphieutra] = useState(0);
    const [lstNgay, setLstNgay] = useState([]);
    const isReload = useSelector(state => state.global.resetListPhieuTraThuoc);
    useEffect(() => {
        setIsKhoaDuoc(props.isKhoaDuoc);
    }, [props.isKhoaDuoc]);
    useEffect(() => {
        GetListTrangThaiPhieuThuoc();
        GetListPhieuTraThuoc();
    }, []);
    useEffect(() => {
        if (isReload === true)
            GetListPhieuTraThuoc();
        Actions.ResetListPhieuTraThuoc(false);
    }, [isReload]);
    useEffect(() => {
        GetListPhieuTraThuoc();
    }, [ngayTao, trangthai]);
    const GetListTrangThaiPhieuThuoc = () => {
        Actions.setLoading(true);
        InHospitalService.GetListTrangThaiPhieuTraThuoc(isKhoaDuoc).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                setLstTrangthai(result.data)
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const GetListPhieuTraThuoc = () => {
        Actions.setLoading(true);
        InHospitalService.GetListPhieuTraThuoc(keySearch, trangthai, ngayTao[0].format("YYYY/MM/DD"), ngayTao[1].format("YYYY/MM/DD"), isKhoaDuoc).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                let data = result.data.map(x => ({
                    ...x,
                    check: true,
                }));
                let listType = data.map(x => moment(x.ngay).format('DD/MM/YYYY'));
                let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                setLstNgay(ListTypeDistinct);
                setLstDataSource(data)
                if (data.length > 0) {
                    setCheckAllSelect(true);
                } else {
                    setCheckAllSelect(false);
                }
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const XacNhanPhieuTraThuoc = (id) => {
        Actions.setLoading(true);
        InHospitalService.XacNhanPhieuTraThuoc(id).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Xác nhận thành công' });
                GetListPhieuTraThuoc();
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const DeletePhieuTraThuoc = (id) => {
        Actions.setLoading(true);
        InHospitalService.DeletePhieuTraThuoc(id).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                GetListPhieuTraThuoc();
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }

    const UpdateCheckSelect = (data, ischeck) => {
        let dbSource = lstDataSource;
        let index = dbSource.findIndex(x => x.maphieu === data.maphieu && x.sophieu === data.sophieu);
        if (index >= 0) {
            dbSource[index].check = ischeck;
        }
        if (dbSource.findIndex(x => x.check !== true) >= 0) {
            setCheckAllSelect(false);
        }
        else {
            setCheckAllSelect(true);
        }
        setLstDataSource(dbSource.map(x => ({ ...x })));
    }
    const UpdateCheckAllSelect = (ischeck) => {
        setCheckAllSelect(ischeck);
        let dbSource = lstDataSource;
        if (ischeck === true) {
            setLstDataSource(dbSource.map(x => ({ ...x, check: true })));
        }
        else {
            setLstDataSource(dbSource.map(x => ({ ...x, check: false })));
        }
    }
    const UpdateTrangThai = (status) => {
        if (idphieutra === 0) {
            return;
        }
        Actions.setLoading(true);
        InHospitalService.UpdateTrangThaiPhieuTraThuoc(idphieutra, status).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công' });
                Actions.ResetListPhieuTraThuoc(true);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
            Actions.setLoading(false);
        })
    }
    const PrintListPhieuTraThuoc = () => {
        let lstId = lstDataSource.filter(x => x.check === true).map(x => x.idphieutra);
        if (lstId.length === 0)
            return;
        let arrId = lstId.join(',');
        PrintService.PrintListPhieuTraThuoc(arrId).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6 my-1">
                    <RangePicker className="fontSize12 w-100" format="DD-MM-YYYY" value={ngayTao} allowClear={false}
                        locale={viVN} onChange={(value => setNgayTao(value))}
                    />
                </div>
                <div className="col-md-6 my-1">
                    <select className="form-control"
                        value={trangthai}
                        style={{ width: '100%' }}
                        onChange={(value) => { setTrangthai(value.target.value) }}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <option key={-1} value={0}>Trạng thái</option>
                        {lstTrangthai.map((row, index) => {
                            return (
                                <option key={index} value={row.value}>{row.label}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-md-6 my-1 text-right">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <button className="btn btn-color" type="button" onClick={() => { GetListPhieuTraThuoc() }}><i className="fa fa-search" /></button>
                        </div>
                        <input type="text" className="form-control" placeholder="Nhập số phiếu"
                            onChange={(e) => { setKeySearch(e.target.value) }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    GetListPhieuTraThuoc()
                                }
                            }} />
                    </div>
                </div>
                <div className="col-md-6 my-1">
                    <button className="btn btn-outline-secondary" onClick={() => {
                        PrintListPhieuTraThuoc();
                    }}><i className="fa fa-print mr-2"></i>In phiếu thuốc</button>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-inverse table-hover">
                    <thead>
                        <tr>
                            {isKhoaDuoc !== true &&
                                <th style={{ minWidth: '50px', verticalAlign: 'middle' }}>
                                    <input checked={checkAllSelect} type="checkbox" className="form-check"
                                        onChange={(e) => {
                                            UpdateCheckAllSelect(e.target.checked);
                                        }}
                                    />
                                </th>
                            }
                            <th style={{ minWidth: '100px' }}>Ngày</th>
                            <th style={{ minWidth: '120px' }}>Số phiếu</th>
                            <th style={{ minWidth: '120px' }}>Trạng thái</th>
                            <th style={{ minWidth: '50px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lstDataSource.map((x, index) => (
                                <React.Fragment>
                                    <tr key={'ngay' + index} style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            setidphieutra(x.idphieutra)
                                            setVisiblePhieuTraThuoc(true);
                                        }}>
                                        {isKhoaDuoc !== true &&
                                            <td style={{ verticalAlign: 'middle' }} onClick={(e) => { e.stopPropagation(); }}>
                                                <input checked={x.check === true} type="checkbox" className="form-check"
                                                    onChange={(e) => {
                                                        UpdateCheckSelect(x, e.target.checked);
                                                    }}
                                                />
                                            </td>
                                        }
                                        <td>{moment(x.ngay).format('DD/MM/YYYY')}</td>
                                        <td>{x.maphieu}{x.sophieu}</td>
                                        <td>{x.tentrangthai}</td>
                                        <td>
                                            {isKhoaDuoc !== true ?
                                                <div onClick={(e) => {
                                                    setidphieutra(x.idphieutra);
                                                    e.stopPropagation();
                                                }}>
                                                    <Popconfirm
                                                        title="Bạn có chắc muốn xóa?"
                                                        onConfirm={(e) => { DeletePhieuTraThuoc(x.idphieutra) }}
                                                        okText="Đồng ý" cancelText="Hủy">
                                                        <div className="text-danger" style={{ cursor: 'pointer' }} >
                                                            <i className="fa fa-trash"></i>
                                                        </div>
                                                    </Popconfirm>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div className="d-flex" onClick={(e) => {
                                                        setidphieutra(x.idphieutra);
                                                        e.stopPropagation();
                                                    }}>
                                                        <Popconfirm
                                                            title="Xác nhận phiếu thuốc?"
                                                            onConfirm={(e) => { XacNhanPhieuTraThuoc(x.idphieutra) }}
                                                            okText="Đồng ý" cancelText="Hủy">
                                                            <button style={{ height: '30px' }} type="button" className="btn btn-color mr-2 py-1">
                                                                Xác nhận
                                                            </button>
                                                        </Popconfirm>
                                                        <Popconfirm
                                                            title="Xác nhận từ chối?"
                                                            onConfirm={(e) => { UpdateTrangThai(6) }}
                                                            okText="Đồng ý" cancelText="Hủy">
                                                            <button style={{ height: '30px' }} type="button" className="btn btn-danger py-1">
                                                                Từ chối
                                                            </button>
                                                        </Popconfirm>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {visiblePhieuTraThuoc &&
                <Modal
                    width={1000}
                    visible={visiblePhieuTraThuoc}
                    onCancel={() => { setVisiblePhieuTraThuoc(false); setidphieutra(0) }}
                >
                    <PhieuTraThuoc
                        onClose={(isreset) => {
                            setidphieutra(0);
                            setVisiblePhieuTraThuoc(false);
                            // if (isreset === true)
                            //     GetListPhieuTraThuoc();
                        }}
                        isKhoaDuoc={isKhoaDuoc}
                        idphieutra={idphieutra}
                    />
                </Modal>
            }
        </React.Fragment>
    );
}

export default DanhSachPhieuLanhThuoc;