import { Tabs } from 'antd';
import React, { Component } from 'react';
import Users from './user';
import UseGroup from './group';

const { TabPane } = Tabs;

export default class danhmuc extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Users />
            </React.Fragment>
        )
    }
}
