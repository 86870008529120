import React, { Component } from 'react';
import { Select, Input, Form, Pagination, notification, Button, Table, Modal, InputNumber, Tooltip, Menu, Upload, Dropdown } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as VPService from '../services/VPService';
import SubTest from './subTest';
import ConfigTest from './configTest';
import moment from 'moment';
import { NHOMXETNGHIEM } from '../../common/constants';
const { Option } = Select;
const { Dragger } = Upload;
let task = null;
const initialState = {
    ngay: moment(),
    key: '',
    tenvp: '',
    id: 0,
    maloai: 0,
    manhomvp: null,
    maloaivp: null,
    totalRecords: 0,
    giatien: 0,
    visible: false,
    visibleCreate: false,

    tylekhuyenmai: 0,
    timeToReturn: null
}
export default class DanhSachUpdateVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstVP: [],
            lstNhomVP: [],
            lstLoaiVP: [],
            maloaivp: null,
            currentPage: 1,
            pageSize: 10,
            typeName: '',
            typeNameEdit: '',
            idChange: '',
            typeChange: '',
            modalTest: false,
            testDetail: {},
            modalConfigTest: false,
            vsbImport: false,
            fileList: [],
        };
    }
    componentWillMount() {
        this.loadNhomVP();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }

    loadNhomVP() {
        VPService.GetLoaiVPByNhom(2).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNhomVP: result.data,
                    maloaivp: result.data[0].value,
                    typeName: result.data[0].label,
                }, () => this.loadDanhSachVP());
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm bị lỗi' });
        })

    }
    onReload() {
        this.loadDanhSachVPWithRenew(true);
    }
    loadDanhSachVP(page = this.state.currentPage, size = this.state.pageSize) {
        this.setState({ pageSize: size })
        Actions.setLoading(true);
        VPService.GetDanhSachVP(false, this.state.key, 'default', NHOMXETNGHIEM, this.state.maloaivp, page, size).then(result => {
            const data = result.data.map(x => ({
                'id': x.id,
                'ma': x.ma,
                'ten': x.ten,
                'loai': this.state.typeName,
                'expecTime': Number(x.tgtrakq)
            }))
            if (result.isSuccess) {
                this.setState({
                    lstVP: data,
                    currentPage: page,
                    pageSize: size,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))

    }
    UpdateTypeName() {
        Actions.setLoading(true);
        VPService.CapNhapTenLoaiXN(this.state.maloaivp, this.state.typeNameEdit).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thao tác thành công' })
                this.setState({ visible: false })
                if (this.state.timeToReturn !== null) {
                    this.updateTimeReturnResult("", this.state.timeToReturn, this.state.maloaivp)
                }
                this.loadNhomVP();
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))

    }

    UpdateType() {
        Actions.setLoading(true);
        VPService.CapNhapLoaiXNVP(this.state.idChange, this.state.typeChange).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thao tác thành công' })
                this.setState({ visibleCreate: false })
                this.loadNhomVP();
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))

    }

    updateTimeReturnResult(id, value, loai = -1) {
        Actions.setLoading(true);
        value = this.state.lstVP.find(x => x.id === id) ? this.state.lstVP.find(x => x.id === id).expecTime : this.state.timeToReturn;
        VPService.updateTimeReturnResult(id, value, loai).then(result => {
            if (result.isSuccess) {
                this.loadDanhSachVP();
                notification.success({message: "Cập nhật thành công"});
            }
            else {
                Actions.openMessageDialog('Lỗi', "Không thể cập nhật thông tin, vui lòng thử lại sau")
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))
    }
    
    MauImport() {
        Actions.setLoading(true);
        var configTime = true;
        VPService.MauImportGiaVienPhi(configTime).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "DanhMucThoiGianTraKQDuKien";
                this._hiddenLink.click();
            })
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    MauImportSubTest() {
        Actions.setLoading(true);
        VPService.MauImportSubTest().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "DanhMucImportTestCon";
                this._hiddenLink.click();
            })
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    importTGTKQ() {
        Actions.setLoading(true);
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        VPService.importTGTKQ(formData).then((response) => {
            if (response.isSuccess) {
                notification.success({ message: "Hoàn tất import" });
                this.loadDanhSachVP(1, this.state.pageSize);
            } else {
                if (response.err.msgString) notification.warning({ message: response.err.msgString });
                else notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
            }
            this.setState({
                fileList: [],
            });
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    ImportTestCon() {
        Actions.setLoading(true);
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        VPService.ImportSubTest(formData).then((response) => {
            if (response.isSuccess) {
                notification.success({ message: "Hoàn tất import" });
                this.loadDanhSachVP(1, this.state.pageSize);
            } else {
                if (response.err.msgString) notification.warning({ message: response.err.msgString });
                else notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
            }
            this.setState({
                fileList: [],
            });
        }).catch(err => {
        }).finally(() => Actions.setLoading(false))
    }

    onDelaySearch(key) {
        this.setState({
            key: key
        })
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            this.loadDanhSachVP(1, this.state.pageSize);
        }, 700)
    }

    delayToSave(id) {
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            this.updateTimeReturnResult(id)
        }, 500);
    }

    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 80,
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            {
                title: 'Loại',
                dataIndex: 'loai',
                key: 'loai',
                width: 180,
                align: 'left',
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 100,
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: 180,
                align: 'left',
            },
            {
                title: 'TG trả KQ dự tính',
                dataIndex: 'tgtrakq',
                key: 'tgtrakq',
                align: 'center',
                width: 130,
                render: (unsign, row) => {
                    return (
                        <Tooltip title="Hệ thống tự động lưu sau 0,7 giây">
                            <InputNumber
                                value={row.expecTime}
                                min={0}
                                step={30}
                                onChange={e => {
                                    dataSource.find(x => x.id === row.id).expecTime = (e === '' || e === null) ? 0 : e;
                                    this.setState({ lstVP: dataSource })
                                    this.delayToSave(row.id);
                                }}
                                onKeyDown={e => {
                                    if ((e.key === " " || isNaN(e.key) === true) && e.key !== "Backspace")
                                        e.preventDefault();
                                }}
                            />
                            &nbsp;<span style={{}}>phút</span>
                        </Tooltip>
                    )
                }
            },
            {
                title: 'Thao tác',
                align: 'center',
                width: 300,
                render: (row) => {
                    return (
                        <div style={{ display: 'flex' }}>
                            <Button
                                title="Cập nhật viện phí!"
                                onClick={() => { this.setState({ visibleCreate: true, idChange: row.id, typeChange: this.state.maloaivp }) }} >
                                Chuyển loại
                            </Button>
                            <Button className="btn btn-color ml-2"
                                title="Cập nhật test con!"
                                onClick={() => { this.setState({ testDetail: row, modalTest: true }) }} >
                                Test con
                            </Button>
                            <Button className="btn btn-color ml-2"
                                title="Cấu hình!"
                                onClick={() => { this.setState({ testDetail: row, modalConfigTest: true }) }} >
                                Cấu hình
                            </Button>
                        </div>
                    )
                }
            }
        ];
        const columnType = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            {
                title: 'Mã',
                dataIndex: 'value',
                key: 'value',
                width: 100,
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'label',
                key: 'label',
                align: 'left',
            },
            {
                title: 'Thao tác',
                align: 'center',
                render: (record) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật viện phí!"
                                onClick={(e) => { this.setState({ visible: true, maloaivp: record.value, typeName: record.label, typeNameEdit: record.label }); e.stopPropagation(); }} >
                                <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                            </button>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstVP.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        });
        var { fileList } = this.state;
        const ConfigUpdload = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        };
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.MauImport()}>Tải danh mục</button>
                </Menu.Item>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ vsbImport: true })}>Import danh mục</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={500}
                    visible={this.state.vsbImport}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ width: "100%", maxHeight: 200 }}>
                            <Dragger
                                {...ConfigUpdload}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}>
                                <div>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.importTGTKQ()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        vsbImport: false,
                                    });
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        )
        const menuSubtest = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.MauImportSubTest()}>Tải danh mục</button>
                </Menu.Item>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ vsbImport: true })}>Import danh mục</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={500}
                    visible={this.state.vsbImport}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ width: "100%", maxHeight: 200 }}>
                            <Dragger
                                {...ConfigUpdload}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}>
                                <div>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.ImportTestCon()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu test con</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        vsbImport: false,
                                    });
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        )
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{ height: '100%' }}>
                    <div className='containerChilderWrapper' >
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>


                        <div style={{ display: 'flex', height:'100%' }}>
                            <div className="warrperStyleGroup" style={{ width: '40%', marginRight: 20 }}>
                                <div className="grouplabel">
                                    <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Danh mục xét nghiệm</h4>
                                </div>
                                <div className='d-flex justify-content-end mb-2'>
                                    <Dropdown
                                        overlay={menuSubtest} trigger={['click']}
                                        onClick={e => e.preventDefault()}>
                                        <buton className="btn btn-primary" style={{ marginLeft: 10 }}><i style={{ marginRight: 5 }} className="fas fa-upload" />Import Test con</buton>
                                    </Dropdown>
                                    <Dropdown
                                        overlay={menu} trigger={['click']}
                                        onClick={e => e.preventDefault()}>
                                        <buton className="btn btn-primary" style={{ marginLeft: 10 }}><i style={{ marginRight: 5 }} className="fas fa-upload" />Import thời gian trả kết quả dự kiến</buton>
                                    </Dropdown>
                                </div>
                                <div style={{ height: 'calc(100% - 30px)' }}>
                                    <Table dataSource={this.state.lstNhomVP}
                                        scroll={{ y: 0 }}
                                        pagination={false}
                                        columns={columnType} rowKey={(e, index) => index}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: e => { this.setState({ maloaivp: record.value, typeName: record.label, typeNameEdit: record.label }, () => this.loadDanhSachVP(1, this.state.pageSize)) },
                                            };
                                        }}
                                        rowClassName={(record, rowIndex) => {
                                            if (record.value === this.state.maloaivp) {
                                                return "btn-color"
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="warrperStyleGroup" style={{ width: '58%', height: '100%' }}>
                                <div className="grouplabel">
                                    <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Cấu hình</h4>
                                </div>
                                <div>
                                    <Form.Item label="Tìm kiếm">
                                        <Input
                                            placeholder="Nhập tên viện phí"
                                            onChange={(e) => {
                                                this.onDelaySearch(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{ height: 'calc(100% - 65px)' }}>
                                    <Table dataSource={dataSource}
                                        scroll={{ y: 0 }}
                                        pagination={false}
                                        columns={columns} rowKey={(e, index) => index} />
                                </div>
                                <Pagination
                                    current={this.state.currentPage}
                                    style={{ textAlignLast: "center", marginTop: "10px" }}
                                    total={this.state.totalRecords}
                                    showTotal={total => `Tổng: ${total}`}
                                    onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSachVP(page, size) }}
                                    onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadDanhSachVP(1, size) }}
                                    showSizeChanger={true}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
                {this.state.modalTest === true &&
                    <Modal
                        visible={this.state.modalTest}
                        width={1000}
                    >
                        <SubTest detail={this.state.testDetail}
                            onclose={() => {
                                this.setState({ modalTest: false })
                            }}
                        />
                    </Modal>
                }
                {this.state.modalConfigTest === true &&
                    <Modal
                        visible={this.state.modalConfigTest}
                        width={1000}
                    >
                        <ConfigTest detail={this.state.testDetail}
                            onclose={() => {
                                this.setState({ modalConfigTest: false })
                            }}
                        />
                    </Modal>
                }
                <Modal maskClosable={false}
                    width={500}
                    visible={this.state.visible}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật danh mục xét nghiệm</h3>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <Form.Item label="Tên:">
                                <Input
                                    style={{ textTransform: 'uppercase' }}
                                    value={this.state.typeNameEdit}
                                    onChange={(e) => {
                                        this.setState({ typeNameEdit: e.target.value })
                                    }} />
                            </Form.Item>
                        </div>
                        <div className="col-12 mt-2">
                            <label>Thời gian trả kết quả dự tính (phút)</label>
                            <InputNumber
                                style={{ width: "100%" }}
                                value={this.state.timeToReturn}
                                step={30}
                                min={0}
                                onChange={(e) => {
                                    this.setState({ timeToReturn: e })
                                }} />
                            <div style={{ display: this.state.timeToReturn === null || this.state.typeNameEdit.length === 0 ? "none" : "unset" }}>
                                Sẽ cập nhật cho tất cả dịch vụ ứng với loại <b>{this.state.typeNameEdit}</b>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
                        <button className="btn btn-warning" onClick={() => this.UpdateTypeName()}>
                            <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật</button>

                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visible: false, timeToReturn: null });
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            />Đóng</button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visibleCreate}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    {/* <div className="form-group" style={{ flex: 1 }} > */}
                        <Form.Item label="Loại:">
                            <Select
                                showSearch
                                value={this.state.typeChange}
                                onChange={(e) => { this.setState({ typeChange: e }) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstNhomVP.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    {/* </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button className="btn btn-warning" onClick={() => this.UpdateType()}>
                            <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.setState({ visibleCreate: false });
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng</button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}


