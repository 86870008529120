import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Space, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';
import * as XuatVienService from '../../../services/xuatVienService';
import EditModalXuatVien from './editModal';

class XuatVien extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstdepartment: [],
            department: '',
            result: '',
            lstResult: [],
            status: '',
            visible: false,
            detail: {},
            lstPatient: [],
            totalPatient: 0,
        };
    }
    componentDidMount() {
        this.loadDanhSachBenhNhanXuatVien();
    }
    loadDanhSachBenhNhanXuatVien() {
        XuatVienService.DanhSachXuatVien().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPatient: result.data,
                    totalPatient: result.data.length,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh nhân cần xuất viện bị lỗi' })
        })
    }

    loadThongTinVaoVien(mabn) {
        XuatVienService.ThongTinVaoVien(mabn).then(result => {
            if (result.isSuccess) {

                const patient = result.item;
                let lstDiagnostic = this.state.lstDiagnostic;
                if (lstDiagnostic.filter(e => e.value === patient.diagnostic).length === 0) {
                    lstDiagnostic.push({ label: patient.diagnosticName, value: patient.diagnostic })
                }
                let lstOtherDiseases = this.state.lstOtherDiseases;
                if (lstOtherDiseases.filter(e => e.value === patient.otherDiseases).length === 0) {
                    lstOtherDiseases.push({ label: patient.otherDiseasesName, value: patient.otherDiseases })
                }
                let lstReason = this.state.lstReason;
                if (lstReason.filter(e => e.value === patient.reason).length === 0) {
                    lstReason.push({ label: patient.reasonName, value: patient.reason })
                }
                let lstSwitchHospital = this.state.lstSwitchHospital;
                if (lstSwitchHospital.filter(e => e.value === patient.switchHospital).length === 0) {
                    lstSwitchHospital.push({ label: patient.switchHospitalName, value: patient.switchHospital })
                }
                this.setState({
                    managerId: patient.managerId,
                    subjectCode: patient.subjectCode,
                    enterNumber: patient.enterNumber,
                    enterCode: patient.enterCode,
                    room: patient.room,
                    countDays: patient.countDays,
                    department: patient.department,
                    status: Number(patient.status),
                    diagnostic: patient.diagnostic,
                    diagnosticName: patient.diagnosticName,
                    reason: patient.reason,
                    reasonName: patient.reasonName,
                    otherDiseases: patient.otherDiseases,
                    otherDiseasesName: patient.otherDiseasesName,
                    doctorReceive: patient.doctorReceive,
                    result: Number(patient.result),
                    saveCode: patient.saveCode,
                    year: patient.year,
                    hospitalType: patient.hospitalType,
                    switchHospital: patient.switchHospital,
                    switchHospitalName: patient.switchHospitalName,
                    timeInDepartment: patient.dateInDepartment !== '' ? moment(patient.dateInDepartment).format('HH:mm') : moment().format('HH:mm'),
                    dateInDepartment: patient.dateInDepartment !== '' ? moment(patient.dateInDepartment) : moment(),
                    timeInHospital: patient.dateInHospital !== '' ? moment(patient.dateInHospital).format('HH:mm') : moment().format('HH:mm'),
                    dateInHospital: patient.dateInHospital !== '' ? moment(patient.dateInHospital) : moment(),
                    timeOutDepartment: patient.dateOutDepartment !== '' ? moment(patient.dateOutDepartment).format('HH:mm') : moment().format('HH:mm'),
                    dateOutDepartment: patient.dateOutDepartment !== '' ? moment(patient.dateOutDepartment) : moment(),
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải thông tin vào viện bị lỗi' })
        })
    }

    async submit() {
        Actions.setLoading(true);
        this.editmodal.getData().then(result => {
            if (result !== null && result !== undefined) {
                const data = {
                    'code': this.state.detail.code,
                    'name': this.state.detail.name,
                    'lstOtherDiseases': this.editmodal.state.otherDiseases,
                    'diagnostic': this.editmodal.state.diagnostic,
                    'diagnosticName': this.editmodal.state.diagnosticName,
                    'doctorReceive': this.editmodal.state.doctorReceive,
                    'managerId': this.state.detail.managerId,
                    'result': this.editmodal.state.result.toString(),
                    'status': this.editmodal.state.status.toString(),
                    'enterCode': this.state.detail.enterCode,
                    'enterNumber': this.state.detail.enterNumber,
                    'subjectCode': this.state.detail.subjectCode,
                    'saveCode': this.state.detail.saveCode,
                    'switchDepartment': this.editmodal.state.switchDepartment,
                    'dateOutDepartment': moment().format('YYYY-MM-DD HH:mm'),
                    'dateInHospital': moment(this.state.detail.dateInHospital).format('YYYY-MM-DD HH:mm'),
                    'hospitalType': this.editmodal.state.hospitalType.toString(),
                    'switchHospital': this.editmodal.state.switchHospital,
                }
                XuatVienService.LuuXuatVien(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Lưu thành công" });
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                    this.loadDanhSachBenhNhanXuatVien();
                    this.setState({ visible: false });
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
            }
            Actions.setLoading(false);
        }).catch(() => Actions.setLoading(false));
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                </Button>
                    <Button type="primary" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    setData(row) {
        this.setState({ visible: true, detail: row });
    }
    render() {
        const dataSource = this.state.lstPatient
        const columns = [
            {
                title: '',
                render: (row) => <div>
                    <Button className="btn btn-color" onClick={() => {
                        this.setData(row);
                    }}><i className="fa fa-edit"></i></Button>
                </div>
            },
            {
                title: 'Mã BN',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code'),
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'dob',
                key: 'dob',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY')}</span>);
                }
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Ngày vào viện',
                dataIndex: 'dateInHospital',
                key: 'dateInHospital',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>);
                }
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'diagnosticName',
                key: 'diagnosticName',
            },
            {
                title: 'Chẩn đoán nơi chuyển',
                dataIndex: 'cdFromName',
                key: 'cdFromName',
            },
            {
                title: 'Bác sĩ nhận',
                dataIndex: 'doctorReceiveName',
                key: 'doctorReceiveName',
            },
            {
                title: 'Giường',
                dataIndex: 'bedName',
                key: 'bedName',
            },
            {
                title: 'Phòng',
                dataIndex: 'roomName',
                key: 'roomName',
            },
            {
                title: 'Loại phòng',
                dataIndex: 'roomTypeName',
                key: 'roomTypeName',
            },
            {
                title: 'Nhận từ',
                dataIndex: 'receiveFromName',
                key: 'receiveFromName',
            },
            {
                title: 'Nơi giới thiệu',
                dataIndex: 'comeFromName',
                key: 'comeFromName',
            },
            {
                title: 'Khoa điều trị',
                dataIndex: 'department',
                key: 'department',
            },
            {
                title: 'Bệnh viện chuyển đến',
                dataIndex: 'switchHospitalName',
                key: 'switchHospitalName',
            },
        ];
        return (
            <React.Fragment>
                <div className="font-weight-bold" style={{ textAlign: 'center', marginBottom: '10px' }}>DANH SÁCH CHỜ XUẤT VIỆN</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <Form.Item>
                            <button className="btn btn-color" onClick={() => { this.loadDanhSachBenhNhanXuatVien() }}><i className="fas fa-sync" title="refesh"></i></button>
                        </Form.Item>
                    </div>
                </div>
                <Table dataSource={dataSource} columns={columns} rowKey={(e, index) => index}></Table>
                {this.state.visible && <Modal maskClosable={false}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    width={1000}
                >
                    <EditModalXuatVien ref={(c) => this.editmodal = c} detail={this.state.detail} makp={this.state.department} />
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-color mr-2" key="2" onClick={() => { this.submit() }}> <i style={{ marginRight: 10 }} className="fa fa-save" />Lưu</button>
                        <button key="3" className="btn btn-danger"
                        onClick={() => { this.setState({ visible: false }) }}>
                            <i className="fas fa-sign-out-alt" style={{marginRight: 5}}/>Đóng</button>
                    </div>
                </Modal>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        patient: state.global.patient,
    })
}
export default connect(
    mapStateToProps,
)(XuatVien);
