import * as Actions from '../libs/actions';
import * as ApiCaller from '../libs/httpRequests';
const prefix = 'PromotionVoucher';

//#region new version
export function getList(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, filterModel)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/{id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function edit(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteByIds(promotionVoucherIds) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/delete-many`, promotionVoucherIds).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function delete_by_promotionid(promotionId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete-by-promotionid/` + promotionId).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function exportList(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/export-list`, filterModel)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion
//#region VoucherHistory
export function getListHistory(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/listhistory`, filterModel)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion