import React, { Component } from 'react';
import { Form, Input, Table, Switch, Popconfirm, Modal, notification, Pagination, Select } from 'antd';
import * as cfgService from '../../../services/configService';
import * as Actions from '../../../libs/actions';
import CreatePhong from '../components/createNewPhong';
import SimpleReactValidator from 'simple-react-validator';
import * as PhongKhamService from '../../departmentConfig/services/PhongKhamService';

let task = null;
const initialState = {
    visible: false,
    visibleCreate: false,
    totalRecords: 0
}
const { Option } = Select;
export default class DanhSachLoaiPhong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            currentPage: 1,
            pageSize: 10,
            lstLoaiPhong: [],
            lstdepartment: [],
            lstPhong: [],
            makp: '0',
            makp_update: '0',
            idloaiphong: 0,
            idloaiphong_update: 0,
            key: "",
            idphong: 0,
            tenphong: '',
            loadingHide: 0,
            maphong: ''
        };;
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadLoaiPhongByKhoa();
        this.LoadDepartment();
        this.loadPhong();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        })
    }

    reset() {
        this.state({
            lstLoaiPhong: [],
            key: "",
            idloaiphong: 0,
            tenloaiphong: "",
            loadingHide: 0
        });
    }
    onReload = () => {
        this.loadPhong();
    }
    loadLoaiPhongByKhoa(makp = this.state.makp) {
        cfgService.getSelectBoxLoaiPhongByKhoa(makp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiPhong: result.data
                });
            }
        }).catch(err => {
        })
    }
    LoadDepartment() {
        PhongKhamService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    loadPhong(page = this.state.currentPage, size = this.state.pageSize) {
        cfgService.getPhong(this.state.key, this.state.makp, this.state.idloaiphong, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    deletePhong(idphong) {
        cfgService.deletePhong(idphong).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstPhong;
                this.setState({ lstPhong: list });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }
    updatePhong() {
        if (this.validator.allValid()) {
            const data = {
                'id': this.state.idphong,
                'maKP': this.state.makp_update,
                'maPhong': this.state.maphong,
                'tenPhong': this.state.tenphong,
                'idLoaiPhong': this.state.idloaiphong_update
            }
            cfgService.updatePhong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.onReload();
                    this.setState({
                        visible: false
                    });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    updateHide(id, check) {
        this.setState({ loadingHide: 0 })
        let status = 1;
        if (check === false) {
            status = 0;
        }
        cfgService.updateHidePhong(id, status).then(result => {
            let data = this.state.lstPhong;
            let item = data.find(x => x.id === id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienThi === 0 ? 1 : 0;
                    item.hienThi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienThi;
                    item.hienThi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstPhong: data,
                loadingHide: 0,
            })
        }).catch(() => {
            let data = this.state.lstPhong;
            let item = data.find(x => x.id === id);
            if (item !== undefined) {
                const newcheck = item.hienThi;
                item.hienThi = newcheck;
            }
            this.setState({ loadingHide: 0, lstPhong: data })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + (Number(index) + 1))
            },
            {
                title: 'Khoa',
                dataIndex: 'tenKP',
                key: 'tenKP',
                align: 'left'
            },
            {
                title: 'Loại phòng',
                dataIndex: 'tenLoaiPhong',
                key: 'tenLoaiPhong',
                width: 300,
                align: 'left'
            },
            {
                title: 'Mã',
                dataIndex: 'maPhong',
                key: 'maPhong',
                align: 'left'
            },
            {
                title: 'Tên phòng',
                dataIndex: 'tenPhong',
                key: 'tenPhong',
                align: 'left'
            },
            {
                title: 'Hiển thị',
                align: 'center',
                width: 100,
                render: (row) => {
                    return (
                        <Switch
                            loading={this.state.loadingHide}
                            checkedChildren="Hiển thị"
                            unCheckedChildren="Ẩn"
                            checked={row.hienThi === 1}
                            onChange={(e) => { this.updateHide(row.id, e); }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="btn btn-warning"
                                onClick={() => {
                                    this.setState({
                                        makp_update: row.maKP,
                                        idloaiphong_update: row.idLoaiPhong,
                                        maphong: row.maPhong,
                                        tenphong: row.tenPhong,
                                        idphong: row.id,
                                        visible: true
                                    });
                                    task = setTimeout(() => { this.loadLoaiPhongByKhoa(this.state.makp_update) }, 100)
                                }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deletePhong(row.id) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        let dataSource = this.state.lstPhong.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: '30px 10px' }}>
                        <div
                            className="row no-gutters"
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <div className="col-md-2">
                                <Form.Item>
                                    <Select
                                        defaultValue='0'
                                        onChange={(e) => {
                                            this.setState({ makp: e, idloaiphong: 0 })
                                            task = setTimeout(() => { this.loadPhong() }, 100)
                                            task = setTimeout(() => { this.loadLoaiPhongByKhoa() }, 100)
                                        }}
                                    >
                                        <Option key={-2} value='0'>Tất cả khoa</Option>
                                        {this.state.lstdepartment.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <Select
                                        defaultValue={0}
                                        value={this.state.idloaiphong}
                                        onChange={(e) => {
                                            this.setState({ idloaiphong: e })
                                            task = setTimeout(() => { this.loadPhong() }, 100)
                                        }}
                                    >
                                        <Option key={-1} value={0}>Tất cả loại phòng</Option>
                                        {this.state.lstLoaiPhong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <Input
                                        placeholder="Nhập tên phòng để tìm kiếm"
                                        value={this.state.key}
                                        onChange={(e) => this.setState({
                                            key: e.target.value
                                        })}
                                        onKeyDown={() => {
                                            task = setTimeout(() => {
                                                this.loadPhong();
                                            }, 300);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={this.showModalCreate}>
                                        <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                                        Tạo mới
                                    </button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                             scroll={{ y: 0 }}
                                dataSource={dataSource}
                                columns={columns}
                                rowkey={(index) => index}
                                pagination={false}
                                scroll={{ x: 'max-content', y: 0 }}
                            />
                        </div>
                        <Modal
                            maskClosable={false}
                            width={1000}
                            visible={this.state.visibleCreate}
                            cancelText="Đóng"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <CreatePhong
                                onCancel={this.handleCancel}
                                onReload={this.onReload}
                            />
                        </Modal>
                        <Modal maskClosable={true}
                            width={1000}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <Form ref={(c) => this.form = c}>
                                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                    <h3>Cập nhật phòng nội trú</h3>
                                </div>
                                <div>
                                    <div className="row no-gutters" style={{ display: 'flex', margin: 15 }} >
                                        <div className="col-md-6">
                                            <Form.Item label="Khoa:" name="makp_update">
                                                <Select
                                                    placeholder="Chọn khoa"
                                                    value={this.state.makp_update}
                                                    onChange={(e) => {
                                                        this.setState({ makp_update: e, idloaiphong_update: null })
                                                        task = setTimeout(() => { this.loadLoaiPhongByKhoa(this.state.makp_update) }, 100)
                                                    }}
                                                >
                                                    {this.state.lstdepartment.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Khoa", this.state.makp_update, "required")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Loại phòng:" name="idloaiphong_update">
                                                <Select
                                                    value={this.state.idloaiphong_update}
                                                    onChange={(e) => {
                                                        this.setState({ idloaiphong_update: e })
                                                    }}
                                                >
                                                    {this.state.lstLoaiPhong.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Loại phòng", this.state.idloaiphong_update, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', margin: 15 }} >
                                        <div className="col-md-6">
                                            <Form.Item label="Mã phòng:" name="maphong">
                                                <Input
                                                    placeholder="Nhập mã phòng"
                                                    value={this.state.maphong}
                                                    onChange={(e) => this.setState({
                                                        maphong: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Mã phòng", this.state.maphong, "required|max:10")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Tên phòng:" name="tenphong">
                                                <Input
                                                    placeholder="Nhập tên phòng"
                                                    value={this.state.tenphong}
                                                    onChange={(e) => this.setState({
                                                        tenphong: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Tên phòng", this.state.tenphong, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-success" onClick={() => this.updatePhong()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({ visible: false });
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadPhong(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadPhong(page, size)
                            }
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}