import React, { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import { Form, notification, InputNumber, Input } from 'antd';
import Notify, { NotifyStatus } from '../../../../components/notify';
import * as PatientService from '../../../../services/patientService';
import HeaderModal from "../../../../configureHIS/common/headerModal";
export default (props) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const _notiRef = useRef();
    //biến cục bộ
    const [maql, setMaql] = useState('');
    const [mach, setMach] = useState('');
    const [nhietdo, setNhietdo] = useState('');
    const [chieucao, setChieucao] = useState('');
    const [cannang, setCannang] = useState('');
    const [huyetap, setHuyetap] = useState('');
    const [nhiptho, setNhiptho] = useState('');
    const [detail, setDetail] = useState(null);
    const [patientCode, setPatientCode] = useState('');
    const [patientName,setPatientName] = useState('');

    useEffect(() => {
        setPatientCode(props.patientCode);
        setPatientName(props.patientName)
        setMaql(props.maql);
    }, [props])   

    useEffect(() => {
        if(maql !== '')
        GetVitalSign();
    }, [maql])  

    const GetVitalSign = () => {
        PatientService.GetVitalSigns(maql).then(result => {
            if (result.isSuccess) {
                setDetail(result.data);
                setMach(result.data?.mach);
                setNhietdo(result.data?.nhietDo);
                setChieucao(result.data?.chieuCao);
                setCannang(result.data?.canNang);
                setNhiptho(result.data?.nhipTho);
                setHuyetap(result.data?.huyetAp);
                forceUpdate()
            } else {
                _notiRef &&_notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notiRef &&_notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    const InsertUpdateVitalSigns = () => {
        if (mach === undefined 
            && nhietdo === undefined 
            && huyetap === undefined 
            && cannang === undefined 
            && chieucao === undefined 
            && nhiptho === undefined) {
            return;
        }
        let data = {
            'chieuCao' : chieucao ? chieucao.toString() : '',
            'canNang' : cannang ? cannang.toString() : '',
            'mach' : mach ? mach.toString() : '',
            'nhietDo' : nhietdo ? nhietdo.toString() : '',
            'huyetAp' : huyetap,
            'nhipTho' : nhiptho ? nhiptho.toString() : '',
            'maQL' : maql,
            'maBN' : patientCode
        }
        PatientService.InsertUpdateVitalSigns(data).then(result => {
            if (result.isSuccess) {
                _notiRef &&_notiRef.current.Show("Cập nhật thành công.", NotifyStatus.Success);
                forceUpdate()
                setTimeout(() => {
                    props.onClose()
                }, 1000);
            } else {
                _notiRef &&_notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notiRef &&_notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    return (
        <Fragment>
            <div className="col-12">
                <HeaderModal title={`Cập nhật dấu hiệu sinh tồn - ${patientName}`} onClose={() => { props.onClose() }} />
                <Notify ref={_notiRef} />
                <div className="row">
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Chiều cao</label>
                        <div>
                            <InputNumber className="w-50 mr-2" value={chieucao} min={0} maxLength={5}
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setChieucao(e);
                                }}
                            />
                            <label className="mita-value">cm</label>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Cân Nặng</label>
                        <div>
                            <InputNumber className="w-50 mr-2 " value={cannang} min={0} maxLength={5}
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setCannang(e);
                                }}
                            />
                            <label className="mita-value">kg</label>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">BMI</label>
                        <label className="mita-value">{cannang && chieucao && Number(cannang/(Math.pow(chieucao/100,2))).toFixed(1)}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Huyết áp</label>
                        <div>
                            <Input className="w-50 mr-2" value={huyetap} maxLength={8}
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setHuyetap(e.target.value.toString().replace(/[a-zA-Z~!@#$%^&*()_+*.{}|;:,?'"\-\\=]/g, ""))
                                }}
                            />
                            <label className="mita-value">mmHg</label>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Mạch</label>
                        <div>
                            <InputNumber className="w-50 mr-2" value={mach} type="number" min={40} maxLength={5} max={250}
                            onKeyDown={(e) => {
                                if (e.key === 'e') {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setMach(e)
                            }} />
                            <label className="mita-value">lần / phút</label>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className="mita-title">Nhiệt độ</label>
                        <div>
                            <InputNumber className="w-50 mr-2" value={nhietdo} type="number" min={30} maxLength={5} max={42}
                                onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    setNhietdo(e)
                                }}
                            />
                            <label className="mita-value"> ℃</label>
                        </div>
                    </div>
                </div>
                <div className='custom-hr' />
                <div className='row my-2'>
                    <div className='col-12 d-flex justify-content-end'>
                        <button className='btn btn-color mr-2' onClick={() => InsertUpdateVitalSigns()}><i className='fas fa-save mr-2'/> Lưu</button>
                        <button className="btn btn-secondary" onClick={() => props.onClose()}><i className="far fa-times-circle mr-2" /> Đóng</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
