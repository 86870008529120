import { Button, Input, Popover, Select } from 'antd';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { BUTTON } from '../../../configureHIS/common/constants';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as CoporationServices from "../../../services/CorporationServices";
import { isNullOrEmpty, isnotnull } from '../../../libs/util';
import { setLoading } from '../../../libs/actions';
import CoporationReport from '../../../components/coporation-report';

let task = null;

const CoporationChildGroupUpdate = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const _notify = useRef();
    const [data, setData] = useState(props.data ?? {});
    const [listParent, setListParent] = useState([]);
    const _coporationSelect = useRef();

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListParent();
    }, []);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onClose = () => {
        if (props.onClose) props.onClose();
        else showError();
    }

    const onChangeData = (field = "", newData) => {
        let cData = { ...data };
        if (cData.hasOwnProperty(`${field}`)) {
            cData[field] = newData;
            setData(cData);
        }
    }

    const getListParent = (key) => {
        const data = {
            keyword: key,
            skipCount: 0,
            maxResultCount: 20,
        }
        CoporationServices.GetListParent(data).then(res => {
            if (res.isSuccess) {
                let list = res.data.items;
                if (list.find(x => x.parentId) == undefined) {
                    if (props.data.parentId) {
                        list.push({
                            parentName: props.data.parentName,
                            uid: props.data.parentId,
                        })
                    }
                }
                setListParent(list);
            } else showError("Đã xảy ra lỗi tìm kiếm nguồn khách hàng tổng, vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText);
        }).finally()
    }

    const onUpdate = () => {
        if (isNullOrEmpty(data.parentId)) {
            showError("Thông tin nguồn khách hàng tổng cần được chọn.");
            return;
        }
        if (isNullOrEmpty(data.parentName)) {
            showError("Thông tin tên cần được nhập.");
            return;
        }
        setLoading(true);
        _notify && _notify.current && _notify.current.Close();
        let coporationId =_coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId();
        data.coporationId = coporationId;
        CoporationServices.UpdateChildGroup(data).then(res => {
            if (res.isSuccess) {
                showSuccess("Cập nhật thành công.");
                props.onReload && props.onReload();
            } else showError(res.error.messageText ?? "Không thể cập nhật. Vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText ?? "Đã xảy ra lỗi.");
        }).finally(() => setLoading(false))
    }

    const renderInfo = () => {
        let data = _coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporation();
        return (
            <div>
                <div className='border-bottom backgroundColorThemesAlpha'>
                    <label style={{ width: "30px", textAlign: "center", }}>#</label>
                    <label style={{ width: "80px", textAlign: "center", }}>Mã</label>
                    <label style={{ width: "260px", }}>Tên</label>
                </div>
                {data.map((c, index) => {
                    return (
                        <div className='border-bottom'>
                            <label style={{ width: "30px", textAlign: "center", }}>{index + 1}</label>
                            <label style={{ width: "80px", textAlign: "center", }}>{c.ma}</label>
                            <label style={{ width: "260px" }}>{c.ten}</label>
                        </div>
                    )
                })}
            </div>
        )
    }

    const showError = (msg = "Không tìm thấy function.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Error);
    }

    const showSuccess = (msg = "Thao tác thành công.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Success);
    }

    return (
        <Fragment>
            <HeaderModal title="Cập nhật nhóm nguồn con" onClose={() => onClose()} />
            <Notify ref={_notify} />
            <div className='mt-2'>
                <label className='required-field'>Nguồn khách hàng tổng</label>
                <Select placeholder='Chọn nguồn khách hàng tổng'
                    value={data.parentId} className='w-100'
                    onChange={(e) => onChangeData("parentId", e)}
                    showSearch filterOption={false}
                    onSearch={(e) => {
                        if (task) clearTimeout(task);
                        task = setTimeout(() => {
                            getListParent(e);
                        }, 400)
                    }}
                >
                    {listParent && [...new Set(listParent)].map((x, index) => {
                        return (
                            <Select.Option key={index} value={x.uid}>{x.parentName}</Select.Option>
                        )
                    })}
                </Select>
            </div>
            <div className='mt-2'>
                <label className='required-field'>Tên nhóm nguồn con</label>
                <Input placeholder='Nhập tên nhóm nguồn con' className='w-100'
                    value={data?.childName} onChange={(e) => onChangeData("childName", e.target.value)} />
            </div>
            <div className='mt-2'>
                <CoporationReport ref={_coporationSelect} modeMultiple={true} allowClear={true} coporationId={data.coporationId} />
            </div>
            <div className='mt-2'>
                <Popover
                    overlayStyle={{
                        width: 400
                    }}
                    destroyTooltipOnHide
                    title="Nguồn khách hàng đã chọn"
                    content={() => renderInfo()}
                >
                    <Button type='link'>Xem danh sách nguồn khách hàng đã chọn</Button>
                </Popover>
            </div>
            <div className='d-flex justify-content-center'>
                <button className={`${BUTTON.THEME} mt-2`} onClick={() => onUpdate()}><i className='fas fa-save mr-1' />Cập nhật</button>
                <button className={`${BUTTON.DANGER} mt-2 ml-2`} onClick={onClose}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationChildGroupUpdate)