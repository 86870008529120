import { Input, Button, notification } from 'antd';
import React, { useState, useEffect } from 'react'
import * as Actions from '../../../libs/actions';
import * as VPService from '../services/VPService';

export default (props) => {
    const [detail, setDetail] = useState({});
    const [btmax, setBtmax] = useState('');
    const [btmin, setBtmin] = useState('');
    const [unit, setUnit] = useState('');
    const [normalrange, setNormalrange] = useState('');

    useEffect(() => {
        if (detail.id)
            LoadData();
    }, [detail])
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail])

    const LoadData = () => {
        Actions.setLoading(true);
        VPService.GetTestConfig(detail.id).then(result => {
            if (result.isSuccess) {
                setBtmax(result.item.binhthuongmax);
                setBtmin(result.item.binhthuongmin);
                setUnit(result.item.unit)
                setNormalrange(result.item.normalrange)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: err });
        })

    }

    const submit = () => {
        const data = {
            binhthuongmax: btmax,
            binhthuongmin: btmin,
            unit: unit,
            idtest: detail.id,
            normalrange:normalrange,
        }
        Actions.setLoading(true);
        VPService.UpdateTestConfig(data).then(result => {
            if (result.isSuccess) {
                if (props.onclose) {
                    props.onclose()
                }
                setBtmax('');
                setBtmin('');
                setUnit('');
                setNormalrange('');
                notification.success({ message: 'Lưu thành công' });
            }
            else
                notification.warning({ message: result.err.msgString });
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: err });
        })
    }
    return (
        <React.Fragment>
            <div
                className="text-center h4 text-uppercase"
            >{detail.ten}</div>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label>Bình thường min</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập bình thường min" value={btmin} onChange={(e) => {
                        setBtmin(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Bình thường max</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập bình thường max" value={btmax} onChange={(e) => {
                        setBtmax(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Đơn vị</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập đơn vị" value={unit} onChange={(e) => {
                        setUnit(e.target.value)
                    }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>NormalRange</label>
                    <Input className="w-100" maxLength={100} placeholder="Nhập NormalRange" value={normalrange} onChange={(e) => {
                        setNormalrange(e.target.value)
                    }} />
                </div>
                <div className="col-12 mb-2 text-center align-self-end">
                    <div className="d-flex justify-content-center">
                        <Button className="btn-color"
                            onClick={() => {
                                submit()
                            }}
                        >Lưu</Button>
                        <Button className="ml-2"
                            onClick={() => {
                                if (props.onclose)
                                    props.onclose()
                            }}
                        >Đóng</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
