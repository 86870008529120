import * as ApiCaller from '../libs/httpRequests';
const prefix = 'CompanyExam';

export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getimporttemplate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/get-importtemplate`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(id, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/update/${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

//tiếp đón
export function tiepDonList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/tiepdon-list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PrintTestIndication(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/PrintTestIndication`, data).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};