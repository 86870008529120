import { Button, DatePicker, Modal, Pagination, Table, Input } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as InvoiceService from '../../services/invoiceService';
import { connect } from 'react-redux';
import CustomerVATInfo from './customer';
import * as SystemConfig from "../../configure/systemConfig";

const { RangePicker } = DatePicker;
const VATInvoice = (props) => {
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [key, setKey] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceDetail, setDataSourceDetail] = useState([]);
    const [modalDetail, setModalDetail] = useState(false);
    const [chiNhanh, setChiNhanh] = useState("");
    const [maql, setMaql] = useState("");
    useEffect(() => {
        GetList();
    }, [currentPage, currentSize, chiNhanh])
    useEffect(() => {
        setChiNhanh(props.siteid);
    }, [props.siteid]);
    const GetList = () => {
        Actions.setLoading(true);
        InvoiceService.GetListVATInvoice(key, ngay[0].format("YYYY/MM/DD"), ngay[1].format("YYYY/MM/DD"), currentPage, currentSize, chiNhanh).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    const GetDetail = (maql) => {
        Actions.setLoading(true);
        InvoiceService.GetDetailVATInvoice(maql, chiNhanh).then(result => {
            if (result.isSuccess) {
                setDataSourceDetail(result.data);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }


    const columns = [
        {
            title: 'STT',
            width: 50,
            dataIndex: 'enterNumber',
            key: 'enterNumber',
            render: (text, record, index) => ((currentPage - 1) * currentSize + Number(index) + 1),
        },
        {
            title: 'Mã BN',
            dataIndex: 'mabn',
            key: 'mabn',
        },
        {
            title: 'Họ Tên',
            dataIndex: 'hoten',
            key: 'hoten',

        },
        {
            title: 'Ngày vào',
            dataIndex: 'ngay',
            key: 'ngay',
            render: text => moment(text).format(SystemConfig.FORMAT_DATE)
        },

        {
            title: 'Năm sinh',
            dataIndex: 'namsinh',
            key: 'namsinh',
        },
        {
            title: 'Giới tính',
            dataIndex: 'phai',
            key: 'phai',
            width: 120,
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'sotien',
            key: 'sotien',
            width: 120,
            render: text => text.toLocaleString('en-US')
        },
    ];




    return (
        <>
            <div className='containerChilderWrapper' >
                <div className="h4 text-center font-weight-bold my-2">DANH SÁCH XUẤT HÓA ĐƠN ĐỎ</div>
                <div className="row justify-content-center align-content-between">
                    <div className="col-auto mb-2">
                        <RangePicker format="DD-MM-YYYY" value={ngay} allowClear={false}
                            locale={viVN} onChange={value => setNgay(value)}
                        />
                    </div>
                    <div className="col-auto mb-2">
                        <input className="form-control" autoFocus style={{ width: "200px" }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    GetList();
                                }
                            }}
                            onChange={(e) => setKey(e.target.value)}
                            placeholder="Nhập mã hoặc tên bệnh nhân"
                        />
                    </div>
                    <div className="col-auto mb-2">
                        <button type="button" className="btn btn-color"
                            onClick={() => {
                                GetList();
                            }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </button>
                    </div>
                </div>
                <div style={{ backgroundColor: '#fff', height: 'calc(100% - 115px)' }}>

                    <Table dataSource={dataSource} columns={columns}
                        scroll={{ y: 0 }}
                        rowKey={(e, index) => index}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: e => {
                                    setMaql(record.maql);
                                    GetDetail(record.maql)
                                    setModalDetail(true);
                                },
                            };
                        }}
                        rowClassName={(record, rowIndex) => {
                            return "rowCustom"
                        }}
                        pagination={{
                            showTotal: total => `Tổng: ${total}`,
                            position: ["bottomCenter"],
                            style: {
                                marginBottom: 5
                            },
                            showSizeChanger: true
                        }}
                    />

                </div>
            </div>
            <Modal
                visible={modalDetail}
                onCancel={() => { setModalDetail(false) }}
                width={1400}
            >
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>Thông tin xuất hóa đơn</h3>
                </div>
                <div className='mb-2'>
                    <CustomerVATInfo disableChange={true}
                        info={{ maql:maql }} hideControl={true}/>
                </div>
                <div className='d-flex w-100 justify-content-center'>
                    <h4 className='font-bold text-uppercase'>Chi tiết hóa đơn</h4>
                    <b className='ml-auto align-self-end'>Tổng tiền: {dataSourceDetail.reduce(function (a, b) { return a + b.sotien }, 0)?.toLocaleString('en-US')} (VNĐ)</b>
                </div>
                <div className="table-responsive">
                    <table class="table table-striped table-inverse table-hover">
                        <thead class="thead-inverse">
                            <tr>
                                <th>STT</th>
                                <th>Mã</th>
                                <th>Tên</th>
                                <th>Hóa đơn</th>
                                <th>Đơn giá</th>
                                <th>Giảm giá</th>
                                <th>Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSourceDetail.map((row, index) => <tr
                                key={index}
                            >
                                <td>{Number(index) + 1}</td>
                                <td>{row.mavp}</td>
                                <td>{row.tenvp}</td>
                                <td>{row.sohoadon}</td>
                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                <td>{row.giamtrukhac?.toLocaleString('en-US')}</td>
                                <td>{row.sotien?.toLocaleString('en-US')}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <Button onClick={() => { setModalDetail(false) }}>Đóng</Button>
                </div>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(VATInvoice)
