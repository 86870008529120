import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { Button, Checkbox, notification} from 'antd';
import { isTrueSet } from '../../libs/util';
import * as RoleService from '../../services/roleService';

export default (props) => {
    const [sitePrefix, setSitePrefix] = useState(false);
    const [yearPrefix, setYearPrefix] = useState(false);
    const [monthPrefix, setMonthPrefix] = useState(false);
    const [dayPrefix, setDayPrefix] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        if(props.data && props.data.length > 0){
            props.data.forEach(x => {
                if(x.key === 'siteprefix'){
                    setSitePrefix(isTrueSet(x.value))
                } else if (x.key === 'yearprefix') {
                    setYearPrefix(isTrueSet(x.value))
                } else if (x.key === 'monthprefix') {
                    setMonthPrefix(isTrueSet(x.value))
                } else if (x.key === 'dayprefix') {
                    setDayPrefix(isTrueSet(x.value))
                }
            });
            forceUpdate();
            
        }
    }, [props.data])

    const onChangeSitePrefix = (checked) => {
        setSitePrefix(checked);
    }

    const onChangeYearPrefix = (checked) => {
        setYearPrefix(checked);
        if(!checked){
            onChangeMonthPrefix(checked);
            onChangeDayPrefix(checked);
        }
    }

    const onChangeMonthPrefix = (checked) => {
        setMonthPrefix(checked);
        if(!checked){
            onChangeDayPrefix(checked);
        }
    }

    const onChangeDayPrefix = (checked) => {
        setDayPrefix(checked);
    }

    const saveConfig = () => {
        const data = [
            {
                key: "siteprefix",
                value: sitePrefix.toString(),
            },
            {
                key: "yearprefix",
                value: yearPrefix.toString(),
            },
            {
                key: "monthprefix",
                value: monthPrefix.toString(),
            },
            {
                key: "dayprefix",
                value: dayPrefix.toString(),
            },
        ]
        RoleService.UpdateSysConfigMulti(data).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Cập nhật thành công" });
            } else {
                notification.warning({ message: "Không thể cập nhật, vui lòng thử lại sau" });
            }
        })
    }

    return (
        <Fragment>
            <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                <h5 className='font-weight-bold'>Cấu hình định dạng số biên lai</h5>
            </div>
            <div className='row mb-2'>
                <div className='col-2'>
                    <Checkbox checked={sitePrefix} onChange={(e) => onChangeSitePrefix(e.target.checked)}>Theo chi nhánh</Checkbox>
                </div>
                <div className='col-2'>
                    <Checkbox checked={yearPrefix} onChange={(e) => onChangeYearPrefix(e.target.checked)}>Theo năm</Checkbox>
                </div>
                <div className='col-2'>
                    <Checkbox checked={monthPrefix} onChange={(e) => onChangeMonthPrefix(e.target.checked)} disabled={!yearPrefix}>Theo tháng</Checkbox>
                </div>
                <div className='col-2'>
                    <Checkbox checked={dayPrefix} onChange={(e) => onChangeDayPrefix(e.target.checked)} disabled={!monthPrefix}>Theo ngày</Checkbox>
                </div>
                <div className='col-2'>
                    <button className="btn btn-success" onClick={() => saveConfig()}>Lưu</button>
                </div>
            </div>
        </Fragment>
    )
};
