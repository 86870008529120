import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import moment from 'moment';
import { DatePicker, InputNumber, Popconfirm, Drawer } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../configure/systemConfig';
import { isNullOrEmpty } from '../../libs/util';
import * as AppointmentService from '../../services/appointmentService';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import ScheduleDetail from './scheduleDetail';

const Schedule = forwardRef((props, ref) => {
    // Nếu có scheduleId (id lịch hẹn) thì tìm theo scheduleId,
    // không thì BẮT BUỘC phải tìm theo CẢ managementId (mã quản lý) và serviceId (mã dịch vụ)
    const [scheduleId, setScheduleId] = useState(props.data.scheduleId);
    const [soNgayHen, setSoNgayHen] = useState();
    const [ngayGioHen, setNgayGioHen] = useState();
    const [tenNguoiTaoLichHen, setTenNguoiTaoLichHen] = useState("");
    const [ngayTaoLichHen, setNgayTaoLichHen] = useState();
    const [isPreview,] = useState(props.isPreview ?? false);
    const [visibleScheduleDetail, setVisibleScheduleDetail] = useState(false);

    const _notiRef = useRef();

    useImperativeHandle(ref, () => ({
        getData: () => {
            let data = {
                ngayGioHen: ngayGioHen,
                idLichHen: scheduleId,
            }
            return data;
        },
        reload: () => {
            getScheduleDetail()
        }
    }));

    useEffect(() => {
        if(props.data.managermentId && props.data.serviceId ) getScheduleDetail();
    }, [props.data.scheduleId, props.data.managermentId, props.data.serviceId]);

    const getScheduleDetail = () => {
        const data = props.data;
        const dataToSend = {
            scheduleId: data.scheduleId ?? null,
            managermentId: data.managermentId ?? "",
            serviceId: data.serviceId ?? null,
        };
        AppointmentService.getScheduleDetail(dataToSend).then(result => {
            if (result.isSuccess) {
                setNgayGioHen(result.data.date);
                setTenNguoiTaoLichHen(result.data.creator);
                setNgayTaoLichHen(result.data.createAt);
                setScheduleId(result.data.id);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        });
    }

    const deleteSchedule = () => {
        Actions.setLoading(true);
        AppointmentService.deleteSchedule(scheduleId).then(result => {
            if (result.isSuccess) {
                _notiRef.current.Show("Xóa lịch hẹn thành công", NotifyStatus.Success);
                getScheduleDetail();
                setSoNgayHen(null);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const onShowScheduleDetail = () => {
        setVisibleScheduleDetail(true);
    }

    const onCloseScheduleDetail = () => {
        setVisibleScheduleDetail(false);
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    return (
        <Fragment>
            <Notify ref={_notiRef} />
            <label className='font-weight-bold'>Hẹn khám</label>
            {isPreview ?
                <div className='mita-value'><a href='#' />{ngayGioHen && moment(ngayGioHen).format(SystemConfig.FORMAT_DATE_TIME)}</div>
                :
                <div className='w-100 d-flex align-items-center'>
                    <InputNumber placeholder="Số ngày" min={0} maxLength={3}
                        value={soNgayHen}
                        onKeyDown={(e) => {
                            if (e.key === 'e') {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            let ngayHen = moment().add(e, 'days');
                            setNgayGioHen(ngayHen);
                            setSoNgayHen(e);
                        }}
                    />
                    <DatePicker
                        className='ml-2'
                        style={{ width: 180 }}
                        showTime
                        locale={viVN}
                        format={SystemConfig.FORMAT_DATE_TIME}
                        placeholder='Chọn ngày hẹn khám'
                        value={ngayGioHen ? moment(ngayGioHen) : null}
                        onChange={(e) => setNgayGioHen(e)}
                        disabledDate={disabledDate} 
                    ></DatePicker>
                    {tenNguoiTaoLichHen &&
                        <span className='ml-2'>
                            Được tạo bởi {tenNguoiTaoLichHen} vào lúc {moment(ngayTaoLichHen).format(SystemConfig.FORMAT_DATE_TIME)}
                            <span>
                                <a href='#' onClick={onShowScheduleDetail}><i className='fas fa-pencil-alt ml-2' /></a>
                                <Popconfirm
                                    title="Có chắc xóa lịch hẹn này?"
                                    onConfirm={() => { deleteSchedule() }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <a href='#'><i className='fas fa-trash-alt ml-2' /></a>
                                </Popconfirm>
                            </span>
                        </span>}
                </div>
            }
            <Drawer
                className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"60%"}
                placement="right"
                visible={visibleScheduleDetail}
                destroyOnClose={true}
                onCancel={onCloseScheduleDetail}>
                <ScheduleDetail
                    scheduleId={scheduleId}
                    onCancel={onCloseScheduleDetail}
                ></ScheduleDetail>
            </Drawer>
        </Fragment>
    );
})

export default Schedule;