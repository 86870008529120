import { Drawer, Modal, Popover, notification } from 'antd';
import React, { Fragment, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import * as IntroVideoService from '../../services/introVideoServices';
import * as Actions from '../../libs/actions';
import "./style.scss";
import { BUTTON } from '../common/constants';
import SupportRequirement from './supportRequirement';
import { isNullOrEmpty } from '../../libs/util';

const Introduction = forwardRef((props, ref) => {
    const [listData, setListData] = useState([]);
    const [listUrl, setListUrl] = useState([]);
    const [videoIndex, setVideoIndex] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [screenName, setScreenName] = useState("");
    const [vsbSendEmail, setVsbSendEmail] = useState(false);
    const [emailData, setEmailData] = useState({});

    useEffect(() => {
        getListIntroVideo();
    }, []);

    useEffect(() => {
        if (props.tabs && props.tabs.length > 0) {
            let screenCode = props.tabs.find(x => x.key == props.activeTab)?.code;
            let screenName = props.tabs.find(x => x.key == props.activeTab)?.label;
            setScreenName(screenName);
            setListScreenVideo(screenCode);
        }
    }, [props.activeTab]);

    const getListIntroVideo = () => {
        IntroVideoService.getListIntroVideo("").then((respone) => {
            if (respone.isSuccess) {
                setListData(respone.data);
            }
            else setListData([]);
        }).catch(err => {
            setListData([]);
        }).finally(() => {
        });
    }

    const setListScreenVideo = (screenCode) => {
        let found = listData.filter(x => x.screen == screenCode);
        if (found.length > 0) {
            setListUrl(found);
        } else {
            setListUrl([]);
        }
        setVideoIndex(0);
    };

    const renderSelectVideo = () => {
        return (
            <div className='w-100 d-flex justify-content-center mt-2'>
                {videoIndex > 0 && <button className={`${BUTTON.THEME} mr-2`} onClick={() => onChangeVideo("prev")}>Video trước đó</button>}
                {videoIndex < listUrl.length - 1 && <button className={BUTTON.THEME} onClick={() => onChangeVideo("next")}>Video tiếp theo</button>}
            </div>
        )
    }

    const onChangeVideo = (state) => {
        if (state == "next") {
            setVideoIndex(videoIndex + 1);
        } else if (state == "prev") {
            setVideoIndex(videoIndex - 1);
        }
    }

    const renderVideoTitle = () => {
        let title = "Không có tiêu đề"
        if (listUrl && listUrl.length > 0) {
            title = listUrl[videoIndex]?.title;
        }
        return <div className='mb-2 w-100 font-weight-bold'>{title}</div>;
    }

    const onSendEmail = (type) => {
        const hostName = window.location.origin;
        const data = {
            hostName,
            screenName,
            type
        }
        setEmailData(data);
        setVsbSendEmail(true);
    }

    return (
        <Fragment>
            {
                !isNullOrEmpty(screenName) && <Popover
                    onOpenChange={(vsb) => {
                        setPlaying(vsb);
                    }}
                    title={<div className='d-flex justify-content-between'>
                        <label>Hướng dẫn sử dụng</label>
                        {listUrl && listUrl.length > 0 && <label className='anchor-color' onClick={() => onSendEmail("error")}>Báo lỗi</label>}
                    </div>}
                    trigger="click"
                    placement='bottom'
                    content={
                        listUrl && listUrl.length > 0 ?
                            <div style={{ width: 600 }}>
                                {renderVideoTitle()}
                                <ReactPlayer
                                    width={600}
                                    controls={true}
                                    playing={playing}
                                    url={listUrl[videoIndex]?.src}
                                />
                                {listUrl.length > 0 && renderSelectVideo()}
                            </div>
                            :
                            <div style={{ width: 600 }}>
                                <div>Hiện tại chưa có video hướng dẫn sử dụng cho màn hình này. Nếu có nhu cầu, vui lòng tạo yêu cầu.</div>
                                <div className='d-flex justify-content-center'>
                                    <button className={`${BUTTON.THEME}`} onClick={() => onSendEmail("request")}>Yêu cầu video hướng dẫn</button>
                                </div>
                            </div>
                    }>
                    <div
                        // className='question'
                        style={{
                            display: 'flex',
                            color: 'white',
                            cursor: 'pointer',
                            alignItems: 'center',
                            justifyContent: "center",
                            width: 40,
                            height: 45,
                        }}
                    >
                        <i style={{ fontSize: 20, }} className="far fa-question-circle text-white" />
                    </div>
                </Popover>
            }
            <Modal
                open={vsbSendEmail}
                onCancel={() => setVsbSendEmail(false)}
                width={500} destroyOnClose
            >
                <SupportRequirement
                    data={emailData}
                    onClose={() => setVsbSendEmail(false)} />
            </Modal>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        tabs: state.global.tabs,
        activeTab: state.global.activeTab,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Introduction)