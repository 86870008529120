import React, { useState, useEffect } from 'react'
import { notification, Switch, Pagination, Modal, Button, Select, Input, Checkbox } from 'antd';
import * as ConfigService from '../../../services/configService';
import * as Actions from '../../../libs/actions';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import suneditorViLang from '../../../libs/suneditor-vi-lang';
const { Option } = Select;
function EmailConfig(props) {

    const [detail, setDetail] = useState({});
    const [dataSite, setDataSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [noidung, setNoidung] = useState('');
    const [footer, setFooter] = useState('');
    useEffect(() => {
        GetListSite();
        // GetListNguoiGioiThieu();
    }, [])
    useEffect(() => {
        if (chinhanh !== '')
            GetEmailConfigBySiteId();
    }, [chinhanh])

    const GetListSite = () => {
        Actions.setLoading(true);
        ConfigService.GetListSite(true).then(result => {
            if (result.isSuccess) {
                setDataSite(result.data);
                if (result.data.length > 0) {
                    setChinhanh(result.data[0].id.toString())
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }
    function GetEmailConfigBySiteId() {
        Actions.setLoading(true);
        ConfigService.GetEmailConfigBySiteId(chinhanh).then(results => {
            if (results.isSuccess) {
                setDetail(results.item);
                setNoidung(results.item.content || '')
                setFooter(results.item.footer || '')
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    function UpdateEmailConfig() {
        Actions.setLoading(true);
        const data = {
            ...detail,
            siteid: Number(chinhanh),
            content: noidung,
            footer: footer
        }
        ConfigService.UpdateEmailConfig(data).then(results => {
            if (results.isSuccess) {
                notification.success({ message: "Lưu thành công" })
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }


    return <React.Fragment>
        <div className='containerChilderWrapper' style={{ overflow: 'auto' }}>
            <div className='h4 text-center mt-2 font-bold' style={{ padding: "5px 20px" }}>CẤU HÌNH EMAIL</div>
            <div className="padding-table" style={{ background: '#fff' }}>
                <div className='row'>
                    <div className='col-12 my-1 text-center'>
                        <Button className='btn btn-color'
                            onClick={() => {
                                UpdateEmailConfig()
                            }}
                        >Lưu</Button>
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Chi nhánh</label>
                        <Select
                            showSearch
                            value={chinhanh}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setChinhanh(e)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {dataSite.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id.toString()}>{row.ma}-{row.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Tiêu đề</label>
                        <Input value={detail.title} onChange={(e) => {
                            detail.title = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1'>
                        <label>Tên người gửi</label>
                        <Input value={detail.senderName} onChange={(e) => {
                            detail.senderName = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1 d-none'>
                        <label>Host</label>
                        <Input value={detail.host} onChange={(e) => {
                            detail.host = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1 d-none'>
                        <label>Port</label>
                        <Input value={detail.port} onChange={(e) => {
                            detail.port = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1 align-self-center d-none'>
                        {/* <label>EnableSsl</label> */}
                        <Checkbox
                        checked={detail.enableSsl}
                        onChange={e=>{
                            detail.enableSsl = e.target.checked;
                            setDetail({ ...detail })
                        }}
                        >EnableSsl</Checkbox>
                        {/* <Input type={"checkbox"} value={detail.enableSsl} onChange={(e) => {
                            detail.enableSsl = e.target.value;
                            setDetail({ ...detail })
                        }} /> */}
                    </div>
                    <div className='col-md-6 my-1 d-none'>
                        <label>Gừi từ Email</label>
                        <Input value={detail.fromEmail} onChange={(e) => {
                            detail.fromEmail = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1 d-none'>
                        <label>Email User</label>
                        <Input value={detail.emailUser} onChange={(e) => {
                            detail.emailUser = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-md-6 my-1 d-none'>
                        <label>Email Password</label>
                        <Input value={detail.emailPassword} onChange={(e) => {
                            detail.emailPassword = e.target.value;
                            setDetail({ ...detail })
                        }} />
                    </div>
                    <div className='col-12 my-1'>
                        <label>Nội dung</label>
                        <SunEditor
                            lang={suneditorViLang}
                            setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                            onChange={(e) => {
                                setNoidung(e)
                            }}
                            showToolbar={true}
                            setContents={noidung}
                            setOptions={{
                                height: 350,
                                buttonList: [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list'],
                                    ['link', 'image', 'codeView']
                                ]
                            }} />
                    </div>
                    <div className='col-12 my-1'>
                        <label>Chữ ký</label>
                        <SunEditor
                            setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                            onChange={(e) => {
                                setFooter(e)
                            }}
                            showToolbar={true}
                            setContents={footer}
                            setOptions={{
                                height: 250,
                                buttonList: [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list'],
                                    ['link', 'image', 'codeView']
                                ]
                            }}
                            lang={suneditorViLang}/>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default EmailConfig;