import * as ApiCaller from '../libs/httpRequests';
const prefix='Coporation';

export function GetList(key, page, size, getAll) {
    if (getAll === undefined || getAll === "") getAll = false;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListCoporation?key=${key}&page=${page}&size=${size}&all=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetAllCoporation(key, status, page, size, getAll, sale, bangGia, daiDien) {
    if (getAll === undefined || getAll === "") getAll = false;
    if (sale === undefined || sale === null) sale = "";
    if (bangGia === undefined || bangGia === null) bangGia = "";
    if (daiDien === undefined || daiDien === null) daiDien = "";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetAllCoporation?key=${key}&status=${status}&page=${page}&size=${size}&getAll=${getAll}&sale=${sale}&quotation=${bangGia}&legalType=${daiDien}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CoporationDetail(key){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetCoporationDetail?ma=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateCoporation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateCoporation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateCoporation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateCoporation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteCoporation(ma) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteCoporation/${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ResetPWCoporation(ma) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/ResetPWCoporation/${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function HideCoporation(ma,status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/HideCoporation?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListParent(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListParent`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateParent(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateParent`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateParent(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateParent`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PreCheckDelete(id, isChild = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PreCheckDelete/${id}?isChild=${isChild}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteParent(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteParent/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function GetListChildGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListChildGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateChildGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateChildGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateChildGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateChildGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteChildGroup(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteChildGroup/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};