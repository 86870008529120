import React,{ useState, useRef } from "react"
import { Input, Form, notification,InputNumber } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as ConfigService from '../../../services/configService'

function CreateMarketing(props)  {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [id, setId] = useState('')
    const [ten, setTen] = useState('')
    const [viettat, setViettat] = useState('')
    const [checkValid, setcheckValid] = useState(false);
    const form = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const CreateMarketing = () => {
        const data = {ten : ten, viettat : viettat}
        if (validator.current.allValid()) {
            ConfigService.CreateKenhKhachHang(data).then(result =>{
                if(result.isSuccess){
                    notification.success({message : 'Tạo mới thành công.'});
                    resetState();
                } else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {

            })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const resetState = () => {
        setTen('')
        setViettat('')
    }

    return (
        <React.Fragment>
                <Form ref={form}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm kênh khách hàng</h3>
                    </div>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', justifyContent: 'left' }} >
                            <label>Tên</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <Form.Item style={{width : '300px'}} name="ten">
                                <Input value={ten}
                                    onChange={(e) => setTen(e.target.value)}
                                />
                                {checkValid && validator.current.message("Tên", ten, "required")}
                            </Form.Item>
                            <label>Viết tắt</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <Form.Item style={{width : '300px'}} name="viettat">
                                <Input value={viettat}
                                    onChange={(e) => setViettat(e.target.value)}
                                />
                                {checkValid && validator.current.message("Viết tắt", viettat, "required")}
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                            <button className="btn btn-success"
                                onClick={() => {CreateMarketing()} }>
                                <i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                            <button className="btn btn-warning" style={{ marginLeft: 10 }}
                                onClick={() => resetState()}>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    resetState();
                                    props.onCancel();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
    )
}

export default CreateMarketing;
