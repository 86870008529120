import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import { Input, Table, Popconfirm, Pagination, Select, Tag , Drawer, DatePicker, Tooltip, notification} from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import { BUTTON, USER_AUTH } from '../../configureHIS/common/constants';
import SiteFilter from "../../configureHIS/common/siteFilter";
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import * as ManageService from '../../services/ManageService';
import * as YeuCauHoanTraService from '../../services/YeuCauHoanTraService';
import Notify, { NotifyStatus } from '../../components/notify';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Detail from './detail';
import { connect } from 'react-redux';
import Handlebars from 'handlebars';
import handlebarHelper from "../../libs/handlebarHelper";
import { isNullOrEmpty, isnotnull } from "../../libs/util";

const ychtStatusEnum = {
  new: 'new',
  confirm: 'confirm', 
  cancel: 'cancel'
}
const { Option } = Select;
const YeuCauHoanTra = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    notifyUpdate: () => {
      notifyUpdate();
    }
  }));

  const notifyUpdate = () => {
    let loading = false;
    search(false, loading);
  }

  const notiRef = useRef(null);
  const firstRender = useRef(true); 
  const detailRef = useRef();
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [dateFrom, setDateFrom] = useState(moment().add(-7,'days'));
  const [dateTo, setDateTo] = useState(moment());
  const [states, setStates] = useState([]);
  const [userCreates, setUserCreates] = useState([]);
  const [siteIds, setSiteIds] = useState([]);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  const [listUser, setListUser] = useState([]);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [selectedId, setSelectedId] = useState();
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    loadListUser();
    search();
  }, []);
  //#endregion 
  //#region search & filter
  const search = (isResetSelectedRow = false, loading = true) => {
    Actions.setLoading(loading);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      dateFrom: dateFrom,
      dateTo: dateTo,
      states: states,
      userCreates: userCreates,
      siteIds: siteIds,
    }
    YeuCauHoanTraService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = (isResetSelectedRow = false) => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    // if (row) setSelectedId(row.id);
    // setDetailScreenMode(detailScrMode);
    // setVisibleDetail(true);
  }

  const loadListUser = () => {
      // Actions.setLoading(true);
      ManageService.GetListAccount('', 1, 1000).then(results => {
          if (results.isSuccess) {
            let data = results.data;
            if (!props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND]) {
              data = data.filter(x => x.id.toString() == props.userId);
            }
            setListUser(data);
            if (data && data.length == 1) {
              setUserCreates([data[0].id])
            }
          }
      }).catch(err => {
          // notification.warning({ message: 'Có lỗi xảy ra! Vui lòng thử lại.' });
      }).finally(() => {})
  }

  const onConfirm = (id, record, getById) => {
    if (record.status != ychtStatusEnum.new) return;
    Actions.setLoading(true);
    YeuCauHoanTraService.confirm(id).then(result => {
      if (result.isSuccess) {
        notification.success({ message: 'Xác nhận thành công!' });
        if (getById) getById(id);
        search();
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const onCancel = (id, record, getById) => {
    if (record.status != ychtStatusEnum.new) return;
    Actions.setLoading(true);
    YeuCauHoanTraService.cancel(id).then(result => {
      if (result.isSuccess) {
        notification.success({ message: 'Hủy thành công!' });
        if (getById) getById(id);
        search();
        notiRef && notiRef.current.Close();
        return true;
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 30,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      // Table.SELECTION_COLUMN,
      {
          title: 'Số phiếu',
          dataIndex: 'soPhieu',
          key: 'soPhieu',
          align: 'left',
          width: 120,
          // sorter: () => {},
          // render: (value, record) => {
          //   return (
          //   <>
          //     <a href="#"
          //       onClick={() => {
          //         loadDetail(record, detailScreenModeEnum.detail);
          //       }}
          //     >{value}</a>
          //     <Popconfirm
          //       title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{value}</b>?</span>)}}
          //       onConfirm={() => onDeleteClickHandle(record.id)}
          //       onCancel={() => {}}
          //       okText="Đồng ý"
          //       cancelText="Hủy"
          //     >
          //       <a ref={e => deletePopupRef.current[record.id] = e}></a>
          //     </Popconfirm>
          //   </>
          //   )
          // }
      },
      {
          title: 'Mã khách hàng',
          dataIndex: 'maBN',
          key: 'maBN',
          align: 'left',
          width: 120,
      },
      {
          title: 'Tên khách hàng',
          dataIndex: 'patientInfo',
          key: 'patientInfo',
          align: 'left',
          width: 160,
          // sorter: () => {},
          render: (value, record, index) => {
              return <span>{ record.patientInfo.hoTen }</span>;
          }
      },
      {
          title: 'Giới tính',
          dataIndex: 'phai',
          key: 'phai',
          align: 'center',
          width: 76,
          render: (value, record) => {
              return <span>{record.patientInfo.phai === 0 ? "Nam" : "Nữ"}</span>;
          },
      },
      {
          title: 'Ngày sinh',
          dataIndex: 'ngaySinh',
          key: 'ngaySinh',
          align: 'center',
          width: 120,
          render: (value, record) => {
              return <span>{record.patientInfo.onlyYear ? record.patientInfo.namSinh : moment(record.patientInfo.ngaySinh).format(SystemConfig.FORMAT_DATE)}</span>;
          },
      },
      // {
      //     title: 'Mã nguồn KH',
      //     dataIndex: 'coporationCode',
      //     key: 'coporationCode',
      //     align: 'center',
      //     width: 70,
      // },
      {
          title: 'Nguồn khách hàng',
          dataIndex: 'coporationName',
          key: 'coporationName',
          align: 'left',
          width: 180,
      },
      {
          title: 'Người yêu cầu',
          dataIndex: 'userNameCreate',
          key: 'userNameCreate',
          align: 'left',
          width: 120,
      },
      {
          title: 'Lý do hoàn trả',
          dataIndex: 'ghiChu',
          key: 'ghiChu',
          align: 'left',
          width: 130,
          render: (value, record) => {
            return <MitaDisplayLongText
              content={value}
              maxLength={200}
              showMoreText={showMoreText}
              showLessText={showLessText}
            />
          }
      },
      {
          title: 'Trạng thái',
          dataIndex: 'statusText',
          key: 'statusText',
          align: 'center',
          width: 88,
          render: (value, record) => {
            let color = "cyan";
            let statusName = "";
            switch(record.status) {
              case ychtStatusEnum.new:
                color = "cyan"; break;
              case ychtStatusEnum.confirm:
                color = "green";
                statusName = "Xác nhận";
                break;
              case ychtStatusEnum.cancel:
                color = "red";
                statusName = "Từ chối";
                break;
              default:
                break;
            }
            return (
              <Tooltip title={isNullOrEmpty(statusName) ? "" : !isNullOrEmpty(record.userNameUpdate) && `${statusName} bởi ${record.userNameUpdate}, vào lúc ${moment(record.updateAt).format(SystemConfig.FORMAT_TIME_DATE_FULL)}`}>
                <Tag color={color}>{value}</Tag>
              </Tooltip>
            )
          }
      },
      {
          title: 'Thao tác',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          width: (props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND] ? 120 : 75),
          render: (value, record) => {
            let className = props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND] ? "d-flex justify-content-start": "d-flex justify-content-center";
              return (
                <div className={className}>
                  <button className="btn btn-color"
                    onClick={() => { setSelectedId(record.id); setVisibleDetail(true); }}
                  ><Tooltip title="Xem chi tiết"><i className='fa fa-eye' /></Tooltip></button>
                  {record.status == ychtStatusEnum.new && props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND] && <Popconfirm
                    title={() => {return (<span>Bạn chắc chắn hoàn trả những dịch vụ trong phiếu yêu cầu này?</span>)}}
                    onConfirm={() => onConfirm(record.id, record)}
                    onCancel={() => {}}
                    // okText="Đồng ý"
                    // cancelText="Hủy"
                    >{record.status == ychtStatusEnum.new &&
                    <button className="ml-1 btn btn-color"><Tooltip title="Xác nhận"><i className='fas fa-check' /></Tooltip></button>
                    }
                  </Popconfirm>}
                  {record.status == ychtStatusEnum.confirm && isnotnull(record.refundNumber) &&
                    <Tooltip title="In phiếu hoàn trả">
                      <button className={`ml-1 ${BUTTON.SECONDARY}`} onClick={() => PrintRefund(record.refundNumber)}>
                        <i className="fas fa-print" />
                      </button>
                    </Tooltip>}
                  {record.status == ychtStatusEnum.new && props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.PAYMENT_ACCEPTREFUND] && <Popconfirm
                    title={() => {return (<span>Bạn chắc chắn muốn hủy phiếu?</span>)}}
                    onConfirm={() => onCancel(record.id, record)}
                    onCancel={() => {}}
                    // okText="Đồng ý"
                    // cancelText="Hủy"
                    >{record.status == ychtStatusEnum.new &&
                    <button className="ml-1 btn btn-danger"><Tooltip title="Hủy"><i className='fas fa-times' /></Tooltip></button>
                    }
                  </Popconfirm>}
                </div>
              )
          },
      },
  ];

  const PrintRefund = (refundNumber) => {
    Actions.setLoading(true);
    let listBillNo = [refundNumber.toString()];
    YeuCauHoanTraService.PrintRefund(listBillNo).then((result) => {
      if (result.isSuccess) {
        if (result.data && result.data.length > 0) {
          let templateFull = "";
          result.data.map((bill) => {
            if (isNullOrEmpty(bill.template)) {
              Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
              return;
            } else {
              const template = Handlebars.compile(bill.template);
              Handlebars.registerHelper(handlebarHelper);
              templateFull += template(bill);
            }
          })
          Actions.DoPrint(templateFull);
        }
      } else Actions.openMessageDialog("Đã xảy ra lỗi", result.error.messageText ?? "Không thể in phiếu hoàn trả");
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className='containerChilderWrapper list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách xác nhận hoàn trả</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  this.props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">
                <div className='col-md-3 d-flex flex-column'>{/* Ngày tạo yêu cầu */}
                  <div><label>Ngày tạo yêu cầu</label></div>
                  <div className="d-flex">
                    <DatePicker 
                      allowClear={false}
                      locale={viVN}
                      showNow={false}
                      className="w-100"
                      placeholder={"Từ ngày"}
                      value={dateFrom}
                      format = { SystemConfig.FORMAT_DATE }
                      onChange={(e) => { 
                        setDateFrom(e ? moment(e) : null);
                      }}
                    />
                    <div className="m-auto">&#160;-&#160;</div>
                    <DatePicker 
                      allowClear={false}
                      locale={viVN}
                      showNow={false}
                      className="w-100"
                      placeholder={"Đến ngày"}
                      value={dateTo}
                      format = { SystemConfig.FORMAT_DATE }
                      onChange={(e) => { 
                        setDateTo(e ? moment(e) : null);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex flex-column">
                  <div><label>Từ khóa</label></div>
                  <Input
                    placeholder="Tìm theo số phiếu, mã khách hàng, lý do"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
                <div className="col-md-3 d-flex flex-column">
                  <div><label>Người yêu cầu</label></div>
                    <Select
                      allowClear
                      showSearch
                      className="w-100"
                      placeholder="Chọn người yêu cầu"
                      maxTagCount= 'responsive'
                      value={ userCreates }
                      filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(value) => {setUserCreates(value ? [value] : []);}}
                    >
                    {(listUser ?? []).map((record, index) => {
                      return (
                        <Option key={record.id} value={record.id} label={record.name}>
                          <div className="d-flex justify-content-between">
                            <span>{record.name}</span>
                            {/* <span>{record.ma}</span> */}
                          </div>
                        </Option>
                      )
                    })}
                    </Select>
                </div>
                <div className="col-md-3 d-flex flex-column">
                  <div><label>Trạng thái</label></div>
                  <div className="d-flex">
                    <Select
                      allowClear
                      showSearch
                      className="w-100"
                      placeholder="Chọn trạng thái"
                      maxTagCount= 'responsive'
                      value={ states[0] }
                      // filterOption={(input, option) => {
                      //   return (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      // }}
                      onChange={(value) => {setStates(value ? [value] : []);}}
                    >
                      <Option key={"new"} value={"new"} label={'Yêu cầu mới'}><span>Yêu cầu mới</span></Option>
                      <Option key={"confirm"} value={"confirm"} label={'Đã xác nhận'}><span>Đã xác nhận</span></Option>
                      <Option key={"cancel"} value={"cancel"} label={'Đã hủy'}><span>Đã hủy</span></Option>
                    </Select>
                    <button className="btn btn-color ml-2" onClick={() => { search(); }}>
                      <i className='fas fa-search' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="list-page-table  mt-2">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0 table-header-2row"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                // onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={1000}
        // getContainer={() => detailDrawRef.current}
        placement="right"
        onClose={() => {setVisibleDetail(false)}}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <Detail ref={detailRef}
          isDialog={ true } 
          id={selectedId}
          detailScreenMode = {detailScreenMode}
          onCancel={() => {setVisibleDetail(false)}}
          onConfirm={(id, record, getById)=>{onConfirm(id, record, getById)}}
          onDeny={(id, record, getById)=>{onCancel(id, record, getById)}}
          onPrintRefund={(refundNumber) => PrintRefund(refundNumber)}
        />
      </Drawer>
    </React.Fragment>
  );
})

const mapStateToProps = (state) => ({
  siteid: state.global.siteid,
  // PAYMENTTYPE: state.global.PAYMENTTYPE,
  userScreenAuthorization: state.global.userScreenAuthorization,
  userId: state.global.idUser,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(YeuCauHoanTra);
