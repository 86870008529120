import React, { Component } from 'react';
import { Form, Input, notification, Select } from 'antd';
import * as cfgService from '../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';

const { Option } = Select;
let task = null;

export default class createMaDichVuKyThuatTuongDuong extends Component {
    constructor() {
        super();
        this.state = {
            madichvu: "",
            tendichvubhyt: "",
            matuongduong: "",
            malienthong: "",
            value: null,
            nhom: null,
            tendichvu: '',
            iddichvu: 0,
            lstdanhmucmadvkt: []
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    resetState = () => {
        this.setState({
            madichvu: '',
            tendichvu: '',
            iddichvu: 0,
            tendichvubhyt: null,
            matuongduong: null,
            malienthong: null,
            nhom: null,
            value: null
        });
    }
    async CreateDanhMuc() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            var { madichvu, tendichvu, iddichvu, tendichvubhyt, matuongduong, malienthong, nhom } = this.state;
            if (this.validator.allValid()) {
                const data = {
                    ten: tendichvubhyt,
                    madichvu: madichvu,
                    matuongduong: matuongduong,
                    malienthong: malienthong,
                    tendichvu: tendichvu,
                    iddichvu: iddichvu,
                    nhom: nhom
                }
                cfgService.createDanhMuc(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Tạo thành công!' });
                        this.validator.hideMessages();
                        this.resetState();
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                }).catch(() => {
                    notification.warning({ message: 'Tạo thất bại!' });
                })
            }
            else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    loadSelectBox() {
        var { nhom } = this.state;
        cfgService.getSelectBoxDichVuByNhom(nhom).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdanhmucmadvkt: result.data
                });
            }
        }).catch(() => {
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ margin: 10 }}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Thêm mapping mã dịch vụ kỹ thuật tương đương</h3>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: "10px" }} >
                            <div className="col-md-6">
                                <Form.Item label="Mã tương đương:">
                                    <Input
                                        name="matuongduong"
                                        value={this.state.matuongduong}
                                        placeholder="Nhập mã tương đương"
                                        onChange={(e) => {
                                            this.setState({
                                                matuongduong: e.target.value
                                            })
                                        }}
                                    />
                                    {this.validator.message("Mã tương đương", this.state.matuongduong, "required")}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item label="Mã liên thông:">
                                    <Input
                                        name="malienthong"
                                        placeholder="Nhập mã liên thông giá BHYT"
                                        value={this.state.malienthong}
                                        onChange={(e) => this.setState({
                                            malienthong: e.target.value
                                        })}
                                    />
                                    {this.validator.message("Mã liên thông", this.state.malienthong, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                            <div className="col-md-12">
                                <Form.Item label="Tên dịch vụ BHYT:">
                                    <Input
                                        name="tendichvubhyt"
                                        value={this.state.tendichvubhyt}
                                        placeholder="Nhập tên theo danh mục giá Thông tư 39/2018/TT-BYT"
                                        onChange={(e) => {
                                            this.setState({
                                                tendichvubhyt: e.target.value
                                            })
                                        }}
                                    />
                                    {this.validator.message("Tên dịch vụ bảo hiểm y tế", this.state.tendichvubhyt, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
                            <hr className="btn-color" style={{ width: "100%" }} />
                            <p style={{ textTransform: 'uppercase', position: 'absolute', transform: "translateY(40%)", backgroundColor: "#fff", width: "50%" }}>Chọn dịch vụ kỹ thuật tương đương</p>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Item label="Chọn nhóm:">
                                    <Select
                                        name="nhom"
                                        placeholder="Chọn nhóm"
                                        value={this.state.nhom}
                                        onChange={(e) => {
                                            this.setState({
                                                nhom: e,
                                                value: null,
                                            });
                                            task = setTimeout(() => this.loadSelectBox(), 500);
                                        }}
                                    >
                                        <Option value={1} key='1'>Nhóm viện phí</Option>
                                        <Option value={2} key='2'>Nhóm vật tư</Option>
                                        <Option value={3} key='3'>Nhóm thuốc</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-8">
                                <Form.Item>
                                    <Select
                                        disabled={this.state.nhom == null ? true : false}
                                        showSearch
                                        placeholder="Nhập tên hoặc mã dịch vụ kỹ thuật cần tìm"
                                        value={this.state.value}
                                        optionFilterProp="children"
                                        onChange={(e, data) => {
                                            this.setState({
                                                value: e,
                                                tendichvu: data.children[3],
                                                iddichvu: Number(data.value),
                                                madichvu: data.children[1]
                                            });
                                        }}
                                    >
                                        {this.state.lstdanhmucmadvkt.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>[{row.ma}] - {row.ten}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success"
                                onClick={() => { this.CreateDanhMuc() }}>
                                <i className="fas fa-save" style={{ marginRight: 5 }} />Thêm
                            </button>
                            <button className="btn btn-success" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.resetState();
                                    this.validator.hideMessages();
                                }
                                }>
                                <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới
                            </button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.props.onCancel();
                                    this.props.onReload();
                                    this.resetState();
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                />Đóng
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}