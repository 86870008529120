import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import * as UploadFileService from '../../services/UploadFileService';
import * as Actions from '../../libs/actions';
import { Alert, Progress, Tooltip, notification, Carousel } from 'antd';
import Marquee from 'react-fast-marquee';
import "./style.scss";
import Icons from "./data-font-awesome.json";

const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' };
var listIconSelected = [];

const AppOrderTestDownload = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        const numberOfIcons = 80;
        listIconSelected = selectRandomIcons(numberOfIcons);
        downloadFile();
    }, []);

    const _hiddenLink = useRef();
    const [failed, setFailed] = useState(false);
    const [downloaded, setDownloaded] = useState(0);
    const [listImageIntr,] = useState(
        [
            {
                alt: "Step1",
                src: "/images/AppInstallInstruction/Step1.jpg",
                label: "Bước 0: Đang tải xuống file"
            },
            {
                alt: "Step2",
                src: "/images/AppInstallInstruction/Step2.jpg",
                label: "Bước 1-A: Tải xuống file hoàn tất, bấm mở để cài đặt ngay"
            },
            {
                alt: "Step3",
                src: "/images/AppInstallInstruction/Step3.jpg",
                label: "Hoặc bấm mở Tệp đã tải xuống."
            },
            {
                alt: "Step4",
                src: "/images/AppInstallInstruction/Step4.jpg",
                label: "Bước 1-B: Bấm vào tên file vừa tải, để cài đặt."
            },
            {
                alt: "Step5",
                src: "/images/AppInstallInstruction/Step5.jpg",
                label: "Bước 2-1: Truy cập cài đặt"
            },
            {
                alt: "Step6",
                src: "/images/AppInstallInstruction/Step6.jpg",
                label: "Bước 2-2: Cài đặt ứng dụng"
            },
            {
                alt: "Step7",
                src: "/images/AppInstallInstruction/Step7.jpg",
                label: "Bước 2-3: Truy cập quản lý ứng dụng"
            },
            {
                alt: "Step8",
                src: "/images/AppInstallInstruction/Step8.jpg",
                label: "Bước 2-4: Tìm ứng dụng OrderTest"
            },
            {
                alt: "Step9",
                src: "/images/AppInstallInstruction/Step9.jpg",
                label: "Bước 2-4: Cấp các quyền cần thiết"
            },
            {
                alt: "Step10",
                src: "/images/AppInstallInstruction/Step10.jpg",
                label: "Bước 2-4: Cấp các quyền cần thiết"
            },
            {
                alt: "Step11",
                src: "/images/AppInstallInstruction/Step11.jpg",
                label: "Bước 2-4: Cấp các quyền cần thiết"
            },
            {
                alt: "Step12",
                src: "/images/AppInstallInstruction/Step12.jpg",
                label: "Bước 2-4: Cấp các quyền cần thiết"
            }
        ]
    );

    const downloadFile = () => {
        if (downloaded > 0 && downloaded < 100) {
            notification.warning({ message: "Bấm chậm thôi nào!", placement: "bottomLeft" });
            return;
        }
        UploadFileService.DownloadAppOrder()
            .then(response => {
                const contentEncoding = response.headers.get('content-encoding');
                const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');
                if (contentLength === null) {
                    throw Error('Response size header unavailable');
                }

                const total = parseInt(contentLength, 10);
                let loaded = 0;

                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read().then(({ done, value }) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    loaded += value.byteLength;
                                    controller.enqueue(value);
                                    let percent = Math.round((loaded / total) * 100);
                                    setDownloaded(percent);
                                    read();
                                }).catch(error => {
                                    controller.error(error)
                                })
                            }
                        }
                    })
                )
            })
            .then(result => result.blob())
            .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "MTC_ORDER_APP.apk";
                _hiddenLink.current.click();
            })
            .catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.messageText ?? "Đã xảy ra lỗi tải xuống");
                setFailed(true);
            }).finally(() => {
            })
    }

    const generateBunchOfIcons = (reverse = false) => {
        return (
            <div className='row-icon'>
                {reverse ? listIconSelected.reverse() : listIconSelected}
            </div>
        )
    }

    const selectRandomIcons = (n = 30) => {
        var arrSelected = [];
        var result = [];
        for (let i = 0; i < n; i++) {
            let num = findNumber(arrSelected);
            result.push(<i className={Icons[num]}></i>)
        }
        return result;
    }

    const findNumber = (arrSelected = []) => {
        const maxNumberOfIconsHas = Icons.length;
        let num = Math.floor(Math.random() * maxNumberOfIconsHas) + 1;
        if (arrSelected.includes(num)) num = findNumber();
        else return num;
    }

    return (
        <div className='nvqt-bg'>
            <div className='nvqt-row-icon'>
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
                {generateBunchOfIcons()}
            </div>
            <div>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
                <div className='p-2 nvqt-main'>
                    <Alert className="nvqt-main nvqt-alert" type='success' banner showIcon icon={<span><i className='fas fa-info mr-2' />Thông tin:</span>} message={
                        <Marquee pauseOnHover gradient={false}>
                            <span className='text-white'>Bạn đang tải về phiên bản ứng dụng dành riêng cho thiết bị <span className='text-success ml-1'>Android</span>. File cài đặt chỉ có thể hoạt động trên thiết bị chạy hệ điều hành <span className='text-success ml-1 mr-1'>Android<i className="fab fa-android ml-1" />.</span></span>
                        </Marquee>}
                    />
                </div>
                <div
                    ref={element => {
                        if (element) element.style.setProperty('display', 'flex', 'important');
                    }}
                    style={{ height: "calc(100vh - 60px)", overflowY: "auto" }} className='w-100 d-flex flex-column justify-content-center align-items-center'>
                    {
                        failed ?
                            <Fragment>
                                <div className='text-center nvqt-main'>
                                    <img alt="error" src="/images/TubeBroke.png" height={250} />
                                </div>
                                <div className='mt-2 text-center nvqt-main'>Lỗi rồi. Hãy thử tải lại trang.</div>
                            </Fragment>
                            :
                            <Fragment>
                                {downloaded !== 100 && <div className='text-center nvqt-main'>
                                    <img alt="loading" src="/images/TubeLoading.gif" width={400} />
                                </div>}
                                <div className='mt-2 nvqt-main'>
                                    <Progress type="dashboard" percent={downloaded} className={`nvqt-progress ${downloaded === 100 && "nvqt-progress-done"}`}
                                        strokeColor={conicColors} strokeWidth={12} size={900}
                                        format={(percent) => <span className='text-white'>{percent}%</span>} />
                                </div>
                                <div className='mt-2 text-center nvqt-main'>{downloaded === 100 ? "Kiểm tra thư mục tải về, và tiến hành cài đặt. Xem hướng dẫn bên dưới." : "Vui lòng chờ, file của bạn đang được tải xuống."}</div>
                                {downloaded == 100 &&
                                    <div className='mt-2 nvqt-carousel'>
                                        <Carousel autoplay autoplaySpeed={5000} className='nvqt-main'>
                                            {listImageIntr && listImageIntr.map((x, index) => {
                                                return (
                                                    <div className='nvqt-carousel-group-item' key={index}>
                                                        <div className='nvqt-carousel-item'>
                                                            <img alt={x.alt} src={x.src} />
                                                        </div>
                                                        <label>{x.label}</label>
                                                    </div>
                                                )
                                            })}
                                        </Carousel>
                                    </div>
                                }
                            </Fragment>
                    }
                    <div className='nvqt-main' onClick={() => downloadFile()} style={{ position: "absolute", bottom: 20, left: 30 }}>
                        <div className='text-success'>
                            <Tooltip color='green' defaultOpen title="Chờ quá lâu, mà vẫn chưa tải về? Bấm vào đây!" placement='rightTop'>
                                <i style={{ fontSize: 50 }} className="fab fa-android"></i>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AppOrderTestDownload)