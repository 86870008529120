import * as ApiCaller from '../libs/httpRequests';

const prefix = 'log';
export function GetListLog(dateFrom,dateTo,type,status,key='',page=1,size=10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListLog?key=${key}&page=${page}&size=${size}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateCheck(id, ischeck) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateCheck?id=${id}&ischeck=${ischeck}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};