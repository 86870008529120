import { Input, InputNumber, Carousel } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef, useCallback } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify'
import * as Actions from '../../../libs/actions';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as PrintService from '../../../services/printService';
import { Fragment } from 'react';
import Barcode from 'react-barcode';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export default forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const [billSelected, setBillSelected] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [emptySheet, setEmptySheet] = useState(1);
    const [patientInfo,] = useState(props.patientInfo ?? null);
    const [isPayment,] = useState(props.isPayment ?? false);
    const carouselRef = useRef();

    useEffect(() => {
        if (props.billSelected && props.billSelected !== null && props.billSelected !== '') {
            let uniqList = [...new Set(props.billSelected.toString().split(","))];
            uniqList = uniqList.filter(x => x != "");
            setBillSelected(uniqList.join(",").toString());
        } else {
            setBillSelected('')
        }
    }, [props])

    const resetState = () => {
        setEmptySheet(1);
        setBillSelected('');
        notiRef.current.Close()
    }

    const totalSelected = () => {
        let total = billSelected.split(",").length;
        if (billSelected && total > 1)
            return (
                <b>({total})</b>
            )
        else return "";
    }

    const printBarcode = () => {
        if(isPayment) {
            printSIDBarcodeByMaQL()
        } else  {
            printSIDBarcode();
        }
    }

    const printSIDBarcodeByMaQL = () => {
        Actions.setLoading(true);
        PrintService.PrintSIDByMaql(billSelected, emptySheet).then((result) => {
            if (result.isSuccess) {
                Actions.DoPrint(result.item);
                resetState();
                if (props.onSubmit)
                    props.onSubmit();
                setDisableBtn(false);
                Actions.setLoading(false);
            } else {
                notiRef.current.Show(result.err.msgString, NotifyStatus.Error)
                setDisableBtn(false);
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    // Đổi từ in SID theo SID sang in SID theo Maql
    const printSIDBarcode = () => {
        Actions.setLoading(true);
        PrintService.PrintSID(billSelected, emptySheet).then((result) => {
            if (result.isSuccess) {
                Actions.DoPrint(result.item);
                resetState();
                if (props.onSubmit)
                    props.onSubmit();
                setDisableBtn(false);
                Actions.setLoading(false);
            } else {
                notiRef.current.Show(result.err.msgString, NotifyStatus.Error)
                setDisableBtn(false);
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const btnNextClickHandle = () => {
        carouselRef.current.next();
    }

    const btnPrevClickHandle = () => {
        carouselRef.current.prev();
    }

    return (
        <Fragment>
            <HeaderModal title="In mã SID barcode" onClose={() => {
                props.onCancel();
                resetState();
            }} />
            <Notify ref={notiRef} />
            <div className='py-1'>
                <label className="d-none">Đã chọn {totalSelected()}</label>
                <label className="d-none" style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={4} disabled={true}
                    value={billSelected}
                    className="d-none"
                />
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    {billSelected && billSelected.split(",").length > 1 && <div className="image-move image-move-left" title="Prev" onClick={() => { btnPrevClickHandle(); }}>
                        <LeftOutlined className="m-auto" />
                    </div>}
                    <div style={{ width: "94%" }}>
                        <Carousel ref={carouselRef}>
                            {
                                billSelected && billSelected.split(",").map((x, index) => {
                                    return (
                                        <div className='w-100 d-flex justify-content-center align-items-center flex-column'>
                                            <div>
                                                {/* {patientInfo && <div className='text-left'>
                                                    <div className='font-bold'>{patientInfo.patientId}</div>
                                                    <div className='font-bold'>{patientInfo.patientName}</div>
                                                    <div className='font-bold'>{patientInfo.dob} - {patientInfo.gender}</div>
                                                </div>} */}
                                                <Barcode
                                                    key={index}
                                                    value={x.includes("-") ? x.split("-")[1] : x}
                                                    displayValue={true}
                                                    marginTop={3}
                                                    marginBottom={0}
                                                    marginLeft={3}
                                                    marginRight={0}
                                                    margin={0}
                                                    height={60}
                                                    text={x}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    {billSelected && billSelected.split(",").length > 1 && <div className="image-move image-move-right" title="Next" onClick={() => { btnNextClickHandle(); }}>
                        <RightOutlined className="m-auto" />
                    </div>}
                </div>
            </div>
            <div className='py-1'>
                <span>In thêm số lượng </span><InputNumber value={emptySheet} min={0} onChange={(e) => setEmptySheet(e)} /><span> tem trắng</span>
            </div>
            <div className='py-1 text-right'>
                <button className='btn btn-color'
                    onClick={() => {
                        printBarcode()
                    }}
                    disabled={disableBtn}
                ><i className="fas fa-print" style={{ marginRight: 5 }} />In</button>
                <button className='btn btn-danger ml-1'
                    onClick={() => {
                        props.onCancel && props.onCancel();
                        resetState();
                    }}
                ><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
            </div>
        </Fragment>
    )
});
