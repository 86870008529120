import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../common/headerModal';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Configure from '../../configure/urlConfig';
import { setLoading } from '../../libs/actions';
import * as NotificationMessageService from "../../services/notificationMessage";
import * as SupportServices from "../../services/supportService";

const SupportRequirement = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const [detail, setDetail] = useState();

    // useEffect(() => emailjs.init(Configure.EMAILJS_KEY), []);

    const _notify = useRef();

    const handleSubmit = async () => {
        const data = props.data;
        let type = data.type == "error" ? "Báo lỗi video HDSD" : "Yêu cầu video HDSD";
        try {
            setLoading(true);
            const dataEmail = {
                title: type,
                from_name: props.name,
                screen: data.screenName,
                message: detail,
            };
            SupportServices.SendSupportRequirement(dataEmail).then(res => {
                if (res.isSuccess) {
                    _notify && _notify.current && _notify.current.Show("Cảm ơn bạn đã gửi thông tin. Chúng tôi sẽ xử lý yêu cầu sớm nhất có thể.", NotifyStatus.Success);
                } else {
                    // sendByEmailJS(dataEmail);
                }
            }).catch(err => {
                console.log("LỖI GỬI EMAIL HỖ TRỢ TRONG HIS", err);
                // sendByEmailJS(dataEmail);
            }).finally(() => setLoading(false))
        } catch (error) {
            _notify && _notify.current && _notify.current.Show("Chưa thể gửi yêu cầu, vui lòng thử lại sau.", NotifyStatus.Error);
        } finally {
            sendNotificationMessage(type);
        }
    }

    // const sendByEmailJS = async (dataEmail) => {
    //     await emailjs.send(Configure.EMAILJS_SERVICEID, Configure.EMAILJS_TEMPLATEID, dataEmail);
    //     _notify && _notify.current && _notify.current.Show("Cảm ơn bạn đã gửi thông tin. Chúng tôi sẽ xử lý yêu cầu sớm nhất có thể.", NotifyStatus.Success);
    // }

    const sendNotificationMessage = (type) => {
        const data = {
            type: "regular",
            title: type,
            icon: {
                value: "fas fa-question-circle",
                style: {
                    color: "#f88181"
                }
            },
            detail: `
                Màn hình: <b>${props.data.screenName}</b>.<br/>
                Người yêu cầu: ${props.name}.<br/>
                Nội dung chi tiết: ${detail}.
            `,
            listUser: [{ username: "admin" }],
        };
        NotificationMessageService.CreateNotificationMessage(data);
    }

    return (
        <div className="list-page">
            <HeaderModal title={props.data?.type == "error" ? "Báo lỗi" : "Yêu cầu video"} onClose={() => props.onClose()} />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-table mt-2">
                    <div>
                        <label className='mita-title'>Màn hình</label>
                        <Input readOnly value={props.data?.screenName ?? "Chưa có thông tin màn hình"}></Input>
                    </div>
                    <div className='mt-2'>
                        <label className='mita-title required-field'>Người gửi</label>
                        <Input readOnly value={props.name}></Input>
                    </div>
                    <div className='mt-2'>
                        <label className='mita-title'>Chi tiết {props.data?.type == "error" ? "lỗi" : "yêu cầu"}</label>
                        <Input.TextArea value={detail} onChange={(e) => setDetail(e.target.value)} autoSize={{ minRows: 5, maxRows: 10 }}></Input.TextArea>
                    </div>
                </div>
            </div>
            <div className="list-page-footer">
                <div className='d-flex justify-content-end'>
                    <Button className={`${BUTTON.THEME}`} onClick={() => handleSubmit()}>Gửi</Button>
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
        name: state.global.name
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SupportRequirement)