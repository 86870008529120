import { DatePicker, Modal, Pagination, Table, Tooltip, Select, Tag, notification, Popconfirm, Checkbox, Popover, Button } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as TestService from '../../../services/lisService';
import * as PrintService from '../../../services/printService';
import ResultModal from '../component/result';
import ResultEdit from './edit';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GetList } from '../../corporation/CorporationServices';
import { connect } from 'react-redux';
import * as SystemConfig from '../../../configure/systemConfig';
import SendResult from '../component/send-result';

const { RangePicker } = DatePicker;
const { Option } = Select;
var timeOut = null;
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstBN: [],
            totalRecords: 0,
            key: '',
            ngay: [moment(), moment()],
            visible: false,
            ngayvao: moment(),
            maql: '',
            currentPage: 1,
            currentSize: 10,
            sidxn: '',
            patientName: '',
            yob: 0,
            listErrorTests: [],
            detail: {},
            modalEdit: false,
            sendby: "all",
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            maugui: 0,
            coporator: "all",
            listCoporators: [],
            listFile: [],
            // Gửi kết quả
            selectedResultToSend: {},
            visibleSendResult: false,
        }
    }
    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.DanhSachBenhNhan();
        timeOut = setInterval(() => {
            this.forceUpdate()
        }, 10000);
        this.loadCoporation();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    componentWillUnmount() {
        if (timeOut) clearInterval(timeOut);
    }
    notifyUpdate = () => {
        const loading = false;
        this.DanhSachBenhNhan(this.state.sendby, loading);
    }
    DanhSachBenhNhan(loading = true) {
        this.setState({ idPopover: '' })
        Actions.setLoading(loading);
        // if (this.state.maugui === 1) {
        //     this.DanhSachBenhNhanTaiCho();
        // } else {
        //     this.DanhSachBenhNhanMauGui();
        // }
        this.DanhSachBenhNhanTaiCho();
    }

    DanhSachBenhNhanTaiCho(){
        TestService.DanhSachBenhNhanDichVu(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.maugui, this.state.key, this.state.currentPage, this.state.currentSize, this.state.sendby, this.state.coporator).then(result => {
            if (result.isSuccess) {
                this.setState({ lstBN: result.data.reverse(), totalRecords: result.totalRecords });
                this.getListErrorTests();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
            this.autoFocusSIDInput();
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    DanhSachBenhNhanMauGui(){
        TestService.DanhSachBenhNhanGuiMau(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.key, this.state.currentPage, this.state.currentSize, this.state.sendby, this.state.coporator).then(result => {
                if (result.isSuccess) {
                    this.setState({ lstBN: result.data.reverse(), totalRecords: result.totalRecords });
                    this.getListErrorTests();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        this.autoFocusSIDInput();
    }

    PrintResultTest(sid, maql, ngay) {
        PrintService.PrintResultTest(sid, maql, ngay.format('YYYY/MM/DD'), this.state.chinhanh).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintDetailResultTest(sid, maql, ngay) {
        PrintService.PrintDetailResultTest(sid, maql, ngay.format('YYYY/MM/DD'), this.state.chinhanh).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ResendDataToLis(maql, ngay, sid) {
        Actions.setLoading(true);
        TestService.ResendDataToLis(maql, moment(ngay, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD'), sid, this.state.chinhanh).then(result => {
            if (result.isSuccess) {
                this.DanhSachBenhNhan();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    getListErrorTests() {
        TestService.getListErrorTests(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({ listErrorTests: result.data });
            }
        }).catch(() => {
        })
    }
    ResendAllErrorDataToLis() {
        const { listErrorTests } = this.state;
        if (listErrorTests.length > 0) {
            listErrorTests.forEach(test => {
                this.ResendDataToLis(test.managementID, test.dateIn, test.sid);
            });
        }
    }
    TraKetQuaGuiMau(maql, sid) {
        Actions.setLoading(true);
        TestService.TraKetQuaGuiMau(maql, sid, this.state.chinhanh).then(result => {
            if (result.isSuccess) {
                // this.DanhSachBenhNhan();
                notification.success({ message: 'Trả kết quả thành công.' })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    TraKetQua(sid, pid) {
        const data = {
            sid: sid,
            siteid: this.state.chinhanh,
            listFile: this.state.listFile.filter(x => x.checked === true),
            pid: pid,
        }
        if (data.listFile.length == 0) {
            notification.warning({ message: 'Chưa chọn file.' });
            return;
        }
        TestService.TraKetQuaChoBenhNhan(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Trả kết quả thành công.' })
                this.setState({ idPopover: '' })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    loadCoporation(key = "", page = 1, size = 10) {
        GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporators: res.data
                })
            }
        })
    }
    GetListLISFile(maql) {
        TestService.GetListLISFile(maql).then(result => {
            if (result.isSuccess) {
                let res = result.data;
                // if (res.length > 0)
                //     res[0].checked = true;
                this.setState({ listFile: res })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    ViewResultPdf(data) {
        Actions.setLoading(true);
        TestService.DownLoadLisFile(data.uuid) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.DanhSachBenhNhan();
        }
    }

    autoFocusSIDInput = () => {
        this.search.focus();
        this.search.select();
    }
    clearSIDInput = () => {
        this.setState({key : ''})
    }

    render() {
        const columns = [
            {
                title: 'SID',
                dataIndex: 'sid',
                key: 'sid',
                width: 120,
            },
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
                width: 120,
            },
            {
                title: 'Họ tên',
                width: 250,
                dataIndex: 'patientName',
                key: 'patientName',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Giới tính',
                width: 80,
                dataIndex: 'gender',
                key: 'gender',
            },
            // {
            //     title: 'Số CMND',
            //     dataIndex: 'cardId',
            //     key: 'cardId',
            // },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 100,
            },
            {
                title: 'Nguồn khách hàng',
                dataIndex: 'coporator',
                key: 'coporator',
                width: 150,
            },
            // {
            //     title: 'Mã quản lý',
            //     dataIndex: 'managementID',
            //     key: 'managementID',
            //     width: 120,
            // },
            {
                title: 'Ngày vào',
                dataIndex: 'dateIn',
                key: 'dateIn',
                width: 120,
                render: (value) => (
                    moment(value).format(SystemConfig.FORMAT_DATE_TIME)
                )
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
                width: 200,
            },
            {
                title: 'Trạng thái',
                align: 'center',
                width: 120,
                render: (row) => {
                    return (
                        <div>
                            {!row.waitingSample ?
                                row.sidstatus === "1" ?
                                    "Phiếu đã khóa"
                                    :
                                    row.status === "1" ? "Đã gửi xuống LIS" :
                                        <button className="btn btn-outline-success" onClick={() => { this.ResendDataToLis(row.managementID, row.dateIn, row.sid) }} >Gửi lại</button>
                                : "Chờ lấy mẫu"
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Trả KQ sau',
                key: 'deadline',
                width: 100,
                align: "center",
                render: (row) => {
                    var expTime = moment(row.tgtrakq).diff(moment(), "minutes");
                    return (
                        !row.waitingSample ?
                            row.done !== "1" ?
                                moment(row.tgtrakq).year() !== 1 ?
                                    row.done !== "1" ?
                                        <Tag color={expTime <= 5 ? "red" : "blue"}>{expTime <= 5 ? "Đến giờ trả" : `${expTime} phút`}</Tag>
                                        : <Tag color="green">Hoàn tất</Tag>
                                    : <Tooltip title="Bạn chưa cấu hình thời gian trả kết quả dự kiến! Vui lòng truy cập: Tiện ích > Cấu hình viện phí > Danh mục loại xét nghiệm"><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>
                                : <Tag color="green">Hoàn tất</Tag>
                            : <LoadingOutlined />
                    )
                }
            },
            {
                title: "Thao tác",
                render: (row) => {
                    return (
                        <div>
                            <Tooltip align='top' title="Xem kết quả">
                                <button className="btn btn-color" onClick={() => {
                                    this.setState({ 
                                        patientName: row.patientName, 
                                        maql: row.managementID, 
                                        ngayvao: row.dateIn, 
                                        sidxn: row.sid, 
                                        yob: row.year, 
                                        visible: true, 
                                        done: row.done,
                                        idPopover: '' ,
                                        selectedResultToSend: row,
                                    })}}
                                ><i className='fa fa-eye'></i></button>
                            </Tooltip>
                            <Tooltip align='top' title="In kết quả">
                                <button className="btn btn-outline-secondary" style={{ marginLeft: '5px' }} onClick={() => {
                                    this.setState({ idPopover: '' })
                                    this.PrintResultTest(row.sid, row.managementID, moment(row.dateIn))}}><i className="fa fa-print"></i></button>
                            </Tooltip>
                            {row.kqdiengiai ?
                                <Tooltip align='top' title="In diễn giải kết quả" >
                                    <button className="btn btn-outline-secondary" disabled={!row.kqdiengiai} style={{ marginLeft: '5px' }}
                                        onClick={() => {this.setState({ idPopover: '' })
                                        this.PrintDetailResultTest(row.sid, row.managementID, moment(row.dateIn))}}>
                                        <i className="fas fa-scroll"></i>
                                    </button>
                                </Tooltip>
                                :
                                <button className="btn btn-outline-secondary" disabled={!row.kqdiengiai} style={{ marginLeft: '5px' }}
                                    onClick={() => {
                                        this.setState({ idPopover: '' })
                                        this.PrintDetailResultTest(row.sid, row.managementID, moment(row.dateIn))}}>
                                    <i className="fas fa-scroll"></i>
                                </button>
                            }
                            <Tooltip align='top' title="Sửa kết quả">
                                <button className="btn btn-color ml-2" onClick={() => this.setState({ detail: row, modalEdit: true,idPopover: '' })}><i className='fa fa-edit'></i></button>
                            </Tooltip>
                            {this.state.maugui === 2 &&
                                <Tooltip align='top' title="Trả kết quả">
                                    <button className="btn btn-warning ml-2" onClick={() => {this.setState({ idPopover: '' })
                                    this.TraKetQuaGuiMau(row.managementID, row.sid)}}><i className='fa fa-reply'></i></button>
                                </Tooltip>
                            }
                            {/* {!row.datraketqua && */}
                            <Tooltip title="Trả kết quả cho bệnh nhân">
                                <button className="btn btn-warning ml-2"
                                    onClick={() => {
                                        this.setState({ 
                                            selectedResultToSend: row,
                                            visibleSendResult: true,
                                        })
                                        this.GetListLISFile(row.managementID)
                                    }}
                                ><i className='fas fa-paper-plane' /></button>
                                {/* <Popover
                                    title={`Xác nhận trả kết quả cho bệnh nhân ${row.patientID}`}
                                    okText="Đồng ý" cancelText="Hủy"
                                    visible={row.sid === this.state.idPopover}
                                    content={
                                        <SendResult
                                            isDialog={true}
                                            onCancel={() => this.setState({ idPopover: '' })}
                                        ></SendResult>
                                    }
                                >
                                    <button className="btn btn-warning ml-2"
                                        onClick={() => {
                                            this.setState({ idPopover: row.sid })
                                            this.GetListLISFile(row.managementID)
                                        }}
                                    ><i className='fas fa-paper-plane' /></button>
                                </Popover> */}
                                {/* <Popover
                                    title={`Xác nhận trả kết quả cho bệnh nhân ${row.patientID}`}
                                    okText="Đồng ý" cancelText="Hủy"
                                    visible={row.sid === this.state.idPopover}
                                    content={<div style={{ maxHeight: 300, overflow: 'auto', position: 'relative' }}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Xem</th>
                                                    <th style={{ width: 90 }}>Ngày</th>
                                                    <th>Tiêu đề kết quả</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.listFile.map((row, index) => <tr key={index}>
                                                    <td><Checkbox
                                                        checked={row.checked || false}
                                                        onChange={e => {
                                                            row.checked = e.target.checked
                                                            this.setState({ listFile: this.state.listFile })
                                                        }}
                                                    ></Checkbox></td>
                                                    <td>
                                                        <div className='text-center' style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                this.ViewResultPdf(row)
                                                            }}
                                                        >
                                                            <i className='fas fa-file-pdf'></i>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: 90 }}>{moment(row.datecreate).format(SystemConfig.FORMAT_DATE_TIME)}</td>
                                                    <td>{row.title}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                        <div style={{ position: 'sticky', bottom: 0, width: '100%', backgroundColor: '#fff' }}>
                                            <div className='text-center'>
                                                <Button className='btn btn-color mr-1' onClick={() => {
                                                    this.TraKetQua(row.sid, row.patientID)
                                                }}>Xác nhận</Button>
                                                <Button className='btn btn-secondary ml-1' onClick={() => {
                                                    this.setState({ idPopover: '' })
                                                }}>Đóng</Button>
                                            </div>
                                        </div>
                                    </div>}
                                >
                                    <button className="btn btn-warning ml-2"
                                        onClick={() => {
                                            this.setState({ idPopover: row.sid })
                                            this.GetListLISFile(row.managementID)
                                        }}
                                    ><i className='fas fa-paper-plane' /></button>
                                </Popover> */}
                            </Tooltip>
                            {/* } */}
                        </div>
                    )
                }
            }
        ];
        return (
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <div className='h4 text-center mt-2 font-bold'>KẾT QUẢ XÉT NGHIỆM</div>
                    {/* <Tooltip title="Mở màn hình chờ kết quả">
                        <Link to="/result" target="_blank" style={{ position: "absolute", top: 70, right: 40 }}><i class="fas fa-desktop" style={{ fontSize: 20 }}></i></Link>
                    </Tooltip> */}
                    <div className="row justify-content-center align-content-between">
                        {/* {this.state.isMultisite === true &&
                            <div className="col-auto mb-2">
                                <Select
                                    style={{ width: '160px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e }, () => {
                                            this.DanhSachBenhNhan(this.state.sendby, true, this.state.coporator);
                                        })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        } */}
                        <div className="col-auto mb-2">
                            <Select
                                style={{ width: '160px' }}
                                showSearch
                                allowClear={false}
                                value={this.state.maugui}
                                onChange={(e) => {
                                    this.setState({ maugui: e })
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={0} value={0}>Tất cả</Option>
                                <Option key={1} value={1}>Mẫu tại chỗ</Option>
                                <Option key={2} value={2}>Mẫu gửi</Option>
                            </Select>
                        </div>
                        <div className="col-auto mb-2">
                            <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                                locale={viVN} onChange={(value => this.setState({ ngay: value }))}
                            />
                        </div>
                        {this.state.maugui !== 2 &&
                            <div className='col-md-2 mb-2'>
                                <Select className='w-100' value={this.state.sendby} placeholder="Chọn hình thức trả kết quả"
                                    onChange={(value) => {
                                        this.setState({ sendby: value })
                                    }}>
                                    <Option key={0} value="all">Tất cả hình thức trả kết quả</Option>
                                    <Option key={1} value="sms">Trả kết quả bằng SMS</Option>
                                    <Option key={2} value="zalo">Trả kết quả qua Zalo</Option>
                                    <Option key={3} value="email">Trả kết quả qua Email</Option>
                                    <Option key={4} value="atCenter">Trả kết quả tại trung tâm</Option>
                                    <Option key={5} value="atClinic">Trả kết quả tại phòng khám</Option>
                                </Select>
                            </div>}
                        <div className='col-md-2 mb-2'>
                            <Select className='w-100' value={this.state.coporator} placeholder="Tất cả nguồn khách hàng"
                                onChange={(value) => {
                                    this.setState({ coporator: value })
                                    this.DanhSachBenhNhan(this.state.sendby, true, value);
                                }}
                                showSearch
                                // allowClear
                                onSearch={(value) => this.loadCoporation(value)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option value="all" key={0}>Tất cả nguồn khách hàng</Option>
                                {this.state.listCoporators && this.state.listCoporators.map((copr, index) => {
                                    return (
                                        <Option key={index} value={copr.ma}>{copr.ten}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-auto mb-2">
                            <input className="form-control" autoFocus style={{ width: "200px" }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() })
                                    }
                                }}
                                ref={(c) => this.search = c}
                                onChange={(e) => this.setState({ key: e.target.value })} placeholder="Nhập mã hoặc tên bệnh nhân"
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <button type="button" className="btn btn-color" onClick={() => { this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() }) }}>
                                <i style={{ marginRight: 0 }} className="fa fa-search" />
                                {/* Tìm */}
                            </button>
                            {this.state.listErrorTests.length > 0 &&
                                <button type="button" className="btn btn-warning" style={{ marginLeft: 10 }}
                                    onClick={() => { this.ResendAllErrorDataToLis() }}>{`Gửi lại ${this.state.listErrorTests.length} xét nghiệm lỗi`}
                                </button>}
                        </div>
                    </div>
                    <div style={{ height: 'calc(100% - 120px)', padding: 10 }}>
                        <Table scroll={{ x: 'max-content', y: 0 }} pagination={false} dataSource={this.state.lstBN} columns={columns} rowKey={(e, index) => index}
                        // rowClassName={(record, rowIndex) => {
                        //     return `rowCustom`
                        // }}
                        />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        size={this.state.currentSize}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => { this.DanhSachBenhNhan() }) }}
                        onShowSizeChange={(page, size) => this.setState({ currentPage: 1, currentSize: size }, () => { this.DanhSachBenhNhan() })}
                        showSizeChanger={true}
                    />

                        <Modal maskClosable={true}
                            destroyOnClose={true}
                            width={'98%'}
                            visible={this.state.visible}
                            onCancel={() => this.setState({ visible: false })}
                            footer={null}>
                            {/* {this.state.visible && this.state.maql !== undefined && this.state.ngayvao !== undefined && */}
                            <ResultModal
                                patientName={this.state.patientName}
                                maql={this.state.maql}
                                sidxn={this.state.sidxn}
                                ngay={this.state.ngayvao}
                                namsinh={this.state.yob}
                                done={this.state.done}
                                chinhanh={this.state.chinhanh}
                                resultInfo = { this.state.selectedResultToSend }
                                onUpdate={() => { this.DanhSachBenhNhan() }}
                                onCancel={() => {
                                    this.setState({ visible: false });
                                }}
                                showUpload={true}
                            />                    
                        </Modal>

                        <Modal
                            visible={this.state.modalEdit}
                            destroyOnClose={true}
                            width={1000}
                        >
                            <ResultEdit detail={this.state.detail}
                                onClose={() => {
                                    this.setState({ modalEdit: false })
                                }}
                                chinhanh={this.state.chinhanh}
                                onSuccess={() => {
                                    this.DanhSachBenhNhan();
                                    this.setState({ modalEdit: false });
                                }}
                            />
                        </Modal>
                    
                    <Modal maskClosable={false}
                        destroyOnClose={true}
                        width={1400}
                        style={{minWidth: '600px'}}
                        className="w-25"
                        visible={this.state.visibleSendResult}
                        onCancel={() => this.setState({visibleSendResult: false})}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <SendResult 
                            isDialog={ true } 
                            // resultInfo = { this.state.selectedResultToSend }
                            resultInfo = {{
                                patientID: this.state.selectedResultToSend.patientID,
                                maQL: this.state.selectedResultToSend.managementID,
                                sid: this.state.selectedResultToSend.sid,
                                siteid: this.state.selectedResultToSend.siteid,
                                coporator : this.state.selectedResultToSend.nguonkhachhang
                            }}
                            listFile = { [...this.state.listFile] }
                            onCancel={() => {this.setState({visibleSendResult: false})}}/>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps , null, null, { forwardRef: true })(index);