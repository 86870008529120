import { Drawer, Table, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import moment from 'moment'
import CreatePackageModal from './CreatePackageModal'
import EditPackageModal from './EditPackageModal'
import * as packageService from '../../../services/packageService';
import * as Actions from '../../../libs/actions';
import { FORMAT_DATE } from '../../../configure/systemConfig';
export default class enterInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            listPackage: [],
            pagenumber: 0,
            pagesize: 10,
            //userid:undefined, 
            name: '',
            expandableRow: [],
            flagCreateModal: false,
            flagEditModal: false,
            selectedEditId: null,
        };
    }

    componentWillMount() {
        this.search();
    }
    removeService(packageID) {
        packageService.deletePackage(packageID).then((respone) => {
            if (respone.isSuccess === true) {
                this.search()
            } else {
                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        })
    }

    search() {
        let { pagenumber, pagesize, name } = this.state;
        packageService.getListPackage(pagenumber, pagesize, '', name).then((respone) => {
            if (respone.isSuccess === true) {
                this.setState({ listPackage: respone.data, expandableRow: [] })
            } else {

                Actions.openMessageDialog("Error", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Error", ex.toString());
        }
        )
    }

    getColumns() {
        return [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 50,
                render: (text, record, index) => (this.state.listPackage.indexOf(record) + 1),
            },
            {
                title: 'Mã',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'tên',
            },
            {
                title: 'Giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => (value ? value.toLocaleString('en-US') : value),
            },
            {
                title: 'Mô tả',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Thời hạn(ngày)',
                dataIndex: 'expiry_time',
                key: 'expiry_time',
                align: 'center'
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'createdat',
                key: 'createdat',
                render: text => moment(text).format(FORMAT_DATE)
            },
            {
                title: '',
                width: 120,
                render: (row, index) => {
                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <button className="btn btn-warning" style={{ marginRight: '5px' }} onClick={(e) => {
                                this.setState({
                                    flagEditModal: true,
                                    selectedEditId: row.packageid
                                })
                            }}>
                                <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-edit"></i>  </span>
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa thuốc này không?"
                                onConfirm={() => { this.removeService(row.packageid) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger">
                                    <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }

        ];
    }
    onClose = e => {
        this.setState({
            flagCreateModal: false,
        }, this.search());
    };
    onCloseEdit = e => {
        this.setState({
            flagEditModal: false, selectedEditId: null
        }, this.search());
    };

    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            this.setState({ expandableRow: [index] })
        }
        else {
            this.setState({ expandableRow: [] })
        }
    }

    render() {
        let { flagCreateModal, flagEditModal } = this.state;
        const columnsDetails = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 50,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Giá tiền',
                dataIndex: 'newPrice',
                key: 'newPrice',
                align: 'right',
                render: (value) => (value.toLocaleString('en-US')),
            },
            {
                title: 'Số lần khám',
                dataIndex: 'times',
                key: 'times',
                align: 'canter'
            },
        ]
        const expandable = {
            expandedRowRender: record => (
                <div style={{ padding: 10 }}>
                    <Table dataSource={record.lstDetails} pagination={false} columns={columnsDetails}
                        scroll={{ y: 0 }} />
                </div>)
        };
        return (
            <div className='containerChilderWrapper' >
                <div className='h4 text-center mt-2 font-bold' style={{ padding: "5px 20px" }}>DANH SÁCH GÓI KHÁM</div>
                <div className="d-flex">
                    <input className="form-control" value={this.state.txtSearchPatient} onChange={(e) => {
                        this.setState({ name: e.target.value })
                    }} style={{ width: 250, marginRight: 10 }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.search();
                            }
                        }}
                        placeholder="Tên gói khám" />

                    <button className="btn btn-success mb-2 ml-2 mr-2" onClick={() => {
                        this.search();
                    }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    <button className="btn btn-success mb-2 ml-auto" style={{ alignSelf: 'flex-end' }} onClick={() => {
                        this.setState({ flagCreateModal: true })
                    }} >  <i className="fa fa-plus" style={{ marginRight: 5 }}></i>Tạo mới</button>
                </div>
                <div className='ccCenter' style={{ height: 'calc(100% - 140px)', paddingTop: 10 }} >
                    <Table dataSource={this.state.listPackage} columns={this.getColumns()}
                        scroll={{ y: 0 }}
                        rowKey={(e, index) => index}
                        expandable={expandable}
                        expandRowByClick={true}
                        expandIconColumnIndex={-1}
                        // expandedRowKeys={this.state.expandableRow}
                        onRow={(record, index) => {
                            return {
                                onClick: e => {
                                    this.setExpandable(index);
                                }
                            };
                        }}
                        rowClassName={(row, index) => {
                            const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                            return xx
                        }}
                        pagination={{ showSizeChanger: true, showTotal: (total) => `Tổng: ${total}` }}
                    />

                </div>


                {flagCreateModal && <Drawer
                    height={"100%"}
                    visible={flagCreateModal}
                    placement="top"
                    closable={false}
                    onClose={this.onClose}
                >
                    <CreatePackageModal onClose={this.onClose} />
                </Drawer>
                }
                {flagEditModal && <Drawer
                    height={"100%"}
                    visible={flagEditModal}
                    placement="top"
                    closable={false}
                    onClose={this.onCloseEdit}

                >
                    {this.state.selectedEditId !== null &&
                        <EditPackageModal packageID={this.state.selectedEditId} onClose={this.onCloseEdit}
                        />
                    }
                </Drawer>
                }
            </div>
        )
    }
}
