import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useMemo  } from 'react';
import { Tree, Select, Upload, Button, Popconfirm, Tabs, Tooltip, Input } from 'antd';
import { UploadOutlined, DownOutlined } from '@ant-design/icons';
import * as Actions from '../../../libs/actions';
import * as MenuService from '../../../services/MenuService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MenuDetail from './detail';

const { Option } = Select;
const { Search } = Input;
const dataList = [];
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const firstRender = useRef(true); 
  const detailRef = useRef();
  const uploadRef = useRef();
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [searchValue, setSearchValue] = useState();
  const [reloadMenu, setReloadMenu] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [expandedMenuIds, setExpandedMenuIds] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  //#endregion
  
  //#region useEffect

  useEffect(() => {
    search();
  }, []);
  //#endregion 
  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    MenuService.getAllWithLevelDivision().then(result => {
      if (result.isSuccess) {
        setMenuData(result.data);
        generateList(result.data);
        setReloadMenu(!reloadMenu);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const importJson = (file) => {
    Actions.setLoading(true);
    // let file = e.file;
    if (!file) return;
    let formData = new FormData();
    formData.append('file', file);
    MenuService.importByJson(formData).then(result => {
      if (result.isSuccess) {
        search();
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const exportJson = () => {
    Actions.setLoading(true);
    MenuService.exportJson().then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "menuData.json";
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const exportJsListComponents = () => {
    Actions.setLoading(true);
    MenuService.exportJsListComponents().then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "screenComponents.js";
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
      let node = data[i];
      dataList.push({
        id: node.id,
        label: node.label,
        nodes: node.nodes,
      });
      if (node.nodes) {
        generateList(node.nodes);
      }
    }
  };

  const getParentId = (id, tree) => {
    let parentId;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.nodes) {
        if (node.nodes.some((item) => item.id === id)) {
          parentId = node.id;
        } else if (getParentId(id, node.nodes)) {
          parentId = getParentId(id, node.nodes);
        }
      }
    }
    return parentId;
  };
  
  const onSearchMenu = (textSearch) => {
    // const { value } = e.target;
    // if (!value || value=='')  return;
    textSearch = (textSearch ?? '').trim().toLowerCase();
    if (textSearch == '') {
      setExpandedMenuIds([]);
      setReloadMenu(!reloadMenu);
      return;
    }
    const newExpandedIds = dataList
      .map((item) => {
        if (item.label.toLowerCase().indexOf(textSearch) > -1) {
          return getParentId(item.id, dataList);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedMenuIds(newExpandedIds);
    // setSearchValue(value);
    setAutoExpandParent(true);
    setReloadMenu(!reloadMenu);
  };

  const treeData = useMemo(() => {
    let textSearch = (searchValue ?? '').trim().toLowerCase();
    const loop = (data) =>
      data.map((item) => {
        const labelRoot = (item.label ?? '');
        const strTitle = labelRoot.toLowerCase();
        const index = textSearch.length ? strTitle.indexOf(textSearch) : -1;
        const beforeStr = labelRoot.substring(0, index);
        const afterStr = labelRoot.slice(index + textSearch.length);
        const searchValueRoot = labelRoot.substring(index, index+textSearch.length)
        if (index >= 0) {
        }
        const label =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ backgroundColor:"yellow" }}>{searchValueRoot}</span>
              {afterStr}
            </span>
          ) : (
            <span>{labelRoot}</span>
          );
        if (item.nodes) {
          return {
            label: label,
            id: item.id,
            nodes: loop(item.nodes),
          };
        }
        return {
          label: item.label,
          id: item.id,
        };
      });
    return loop(menuData);
  }, [reloadMenu]);

  const warningImport = () => {
    return (
      <>
        Import sẽ thay thế toàn bộ menu hiện có bằng dữ liệu được import vào. Vì vậy nên cân nhắc trước khi import.<br/>
        Để chắc ăn thì nên "Export Json", rồi thêm menu mới vào đó.<br/>
        Lưu ý khi thêm 1 menu mới:<br/>
        - Các dữ liệu bắt buộc: Code, Key, Label. <br/>
        - Các dữ liệu khác không cần, kể cả ParentId. Nếu muốn menu mới là con của 1 menu cha nào đó thì quăng cái menu mới này vào nodes của menu cha.<br/>
      </>
    );
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className='containerChilderWrapper list-page' >
        <div className="list-page-header mb-1 d-flex justify-content-between"> {/* header */}
            <h3>Quản lý Menu</h3>
            <h3 hidden={!props.isDialog}
              onClick={() => {
                this.props.onCancel()
              }}
            ><i className='far fa-times-circle' /></h3>
        </div>
        <div className="list-page-body">{/* body */}
          <div className="row">{/* notification */}
            <div className="col-md-12"><Notify ref={notiRef} /></div>
          </div>
          <div className="list-page-search border-bottom pb-1">{/* search/filter */}
            <div className="row">
              <div className="col-md-12 d-flex align-items-center pl-0">
                <Upload
                  hidden
                  name='file'
                  accept=".json"
                  showUploadList={false}
                  customRequest={({ file, onSuccess }) => {
                    importJson(file);
                    onSuccess("ok")
                  }}
                >
                  <Button ref={uploadRef} hidden></Button>
                </Upload>
                <Popconfirm
                  title={() => warningImport()}
                  onConfirm={() => {uploadRef.current.click()}}
                  onCancel={() => {}}
                  okText="Đồng ý"
                  cancelText="Hủy"
                >
                  <Button icon={<UploadOutlined />} style={{ borderRadius: "0.25rem" }}>Import Json</Button>
                </Popconfirm>
                <Button
                  className="btn btn-color ml-2"
                  onClick={() => { exportJson() }}
                >
                  Export Json
                </Button>
                <Tooltip title='Export file js. File này import các component theo menu. Replace vào file "screenComponent.js" trong "src/commons"'>
                  <Button
                    className="btn btn-color ml-2"
                    onClick={() => { exportJsListComponents() }}
                  >
                    Export list components
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="h-100 overflow-hidden d-flex justify-content-between pt-1">
            
            <div className="h-100 border-right border-dark d-flex flex-column" style={{ width:350 }}>
              <div>
                <Search
                  style={{
                    marginBottom: 8,
                    paddingRight: 2
                  }}
                  placeholder="Nhập tên để tìm"
                  value={searchValue}
                  onChange={(e) => {setSearchValue(e.target.value); onSearchMenu(e.target.value); }}
                  onSearch={onSearchMenu}
                />
              </div>
              <div className="h-100 overflow-auto">
                <Tree
                  expandedKeys={expandedMenuIds}
                  autoExpandParent={autoExpandParent}
                  defaultSelectedKeys={[selectedId]}
                  switcherIcon={<DownOutlined />}
                  treeData={treeData}
                  fieldNames={{title: 'label', key: 'id', children: 'nodes', }}
                  icon={(props) => (<i className={props}/>) }
                  titleRender={(node) => (<span style={{ marginLeft: "-5px" }}><i className={node.icon} style={{ marginRight: "2px" }}/>{ node.label }</span>) }
                  onSelect={(selectedKeys, e) => {
                    setSelectedId(e.node.id);
                  }}
                  onExpand={(expandedKeys, {expanded: bool, node}) => {
                    setExpandedMenuIds(expandedKeys);
                  }}
                />
              </div>
            </div>
            <div className="h-100 w-100">
              <Tabs>
                <Tabs.TabPane tab="Thông tin" key="tab-item-1">
                  <MenuDetail
                    menuId={selectedId}
                    listMenu={menuData}
                    reloadMenu={() => search()}
                  >
                  </MenuDetail>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Controllers" key="tab-item-2">
                  <h2 className="m-auto text-center">Coming soon</h2>
                </Tabs.TabPane>
              </Tabs>;
            </div>
          </div>
        </div>
        {/* footer (nếu có)*/}
        {/* <div className="list-page-footer d-flex justify-content-between mt-2 d-none">
        </div> */}
      </div>
    </React.Fragment>
  );
})