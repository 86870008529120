import { DatePicker, Modal, Pagination, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import TaoLichPTT from '../../../configureHIS/lichphauthuthuat/components/taolichptt';
import * as Actions from '../../../libs/actions';
import * as TestService from '../../../services/lisService';
import ThuThuat from './detail';
import * as SystemConfig from "../../../configure/systemConfig";
import HeaderModal from '../../../configureHIS/common/headerModal';

const { RangePicker } = DatePicker;
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstBN: [],
            totalRecords: 0,
            key: '',
            ngay: [moment(), moment()],
            visible: false,
            ngayvao: moment(),
            maql: '',
            hoten: '',
            mabn: '',
            detail: {},
            visibleCreate: false,
            currentPage: 1,
            currentSize: 10,
            trangthai: '',
            isThuThuat: true,
        }
    }

    componentDidMount() {
        this.DanhSachBenhNhan();
    }
    notifyUpdate = () => {
        this.DanhSachBenhNhan();
    }
    DanhSachBenhNhan() {
        Actions.setLoading(true);
        TestService.DanhSachThuThuatBenhNhanIncludeInfo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.isThuThuat ? 1 : 5, this.state.key, this.state.currentPage, this.state.currentSize, this.state.trangthai).then(result => {
                if (result.isSuccess) {
                    this.setState({ lstBN: result.data, totalRecords: result.totalRecords });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
    }
    handleCancel = e => {
        this.setState({
            visibleCreate: false,
        });
        // this.DanhSachBenhNhan();
    };
    handleOk = e => {
        this.setState({
            visibleCreate: false,
        });
    };
    closeModal() {
        this.setState({ visible: false })
    }
    onReload = () => {
        this.DanhSachBenhNhan();
    }
    render() {
        const columns = [
            {
                title: 'Ngày chỉ định',
                width: 120,
                dataIndex: 'dateCD',
                key: 'dateCD',
                render: (value) => (
                    moment(value).format(SystemConfig.FORMAT_TIME_DATE)
                )
            },
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
                width: 80,
            },
            {
                title: 'Họ tên',
                dataIndex: 'patientName',
                key: 'patientName',
                width: 250,
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            // {
            //     title: 'Giới tính',
            //     width: 70,
            //     dataIndex: 'gender',
            //     key: 'gender',
            // },
            {
                title: 'Năm sinh',
                dataIndex: 'year',
                key: 'year',
                width: 80,
            },
            {
                title: 'Tên chỉ định',
                dataIndex: 'vPname',
                key: 'vPname',
                width: 150,
            },
            {
                title: 'Người chỉ định',
                dataIndex: 'doctorName',
                key: 'doctorName',
                width: 150,
            },
            {
                title: 'Trạng thái',
                width: 120,
                render: (record) => {
                    return (
                        <span>{record.idpttt === null || record.idpttt === '' ? "Chưa thực hiện" : "Hoàn thành"}</span>
                    )
                },
            },
         
            {
                title: 'Điện thoại',
                width: 100,
                dataIndex: 'phone',
                key: 'phone',
            },
        
        ];
        return (
            <div style={{  height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                <HeaderModal title="Thủ thuật" />
                <div className="row justify-content-center align-content-between">
                    <div className="col-auto mt-2">
                        <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                            locale={viVN} onChange={(value => this.setState({ ngay: value }, () => this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() })))}
                        />
                    </div>
                    <div className="col-auto mt-2">
                        <input className="form-control" style={{ width: "200px" }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() })
                                }
                            }}
                            onChange={(e) => this.setState({ key: e.target.value })} placeholder="Nhập mã hoặc tên bệnh nhân" />
                    </div>
                    <div className="col-auto mt-2">
                        <select
                            className="form-control"
                            onChange={(value) => { this.setState({ trangthai: value.target.value }, () => { this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() }) }) }}
                            style={{ width: "200px" }}
                        >
                            <option key={0} value=''>Trạng thái</option>
                            <option key={1} value='1'>Chưa thực hiện</option>
                            <option key={2} value='2'>Hoàn thành</option>
                        </select>
                    </div>
                    <div className="col-auto mt-2">
                        <button className="btn btn-color" onClick={() => { this.setState({ currentPage: 1 }, () => { this.DanhSachBenhNhan() }) }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    </div>
                </div>
                <div className='mt-2' style={{ height: 'calc(100% - 150px)' }}>
                    <Table pagination={false} dataSource={this.state.lstBN} columns={columns} rowKey={(e, index) => index}
                        scroll={{ x: 'max-content', y:0 }}
                        onRow={(row, rowIndex) => {
                            return {
                                onClick: e => {
                                    this.setState({
                                        detail: row, maql: row.managementID,
                                        ngayvao: row.dateIn, visible: true
                                    })
                                },
                            };
                        }}
                        rowClassName={(record, rowIndex) => {
                            return "rowCustom"
                        }}
                    />
                </div>
                <Pagination
                    current={this.state.currentPage}
                    size={this.state.currentSize}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={this.state.totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => { this.DanhSachBenhNhan() }) }}
                    onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, currentSize: size }, () => { this.DanhSachBenhNhan() }) }}
                    showSizeChanger={true}
                />


                {this.state.visible && <Modal maskClosable={false}
                    width='100%'
                    visible={this.state.visible}
                    onCancel={() => { this.setState({ visible: false }); this.DanhSachBenhNhan() }}
                    footer={null}>
                    {this.state.visible && this.state.maql !== undefined && this.state.ngayvao !== undefined &&
                        <ThuThuat
                            key={this.state.isThuThuat ? "1" : "5"}
                            thuthuat={this.state.isThuThuat}
                            managerId={this.state.detail.managementID}
                            dateIn={this.state.detail.dateIn}
                            //cardNumber={this.state.detail.}
                            subjectCode={this.state.detail.subjectCode}
                            //gender={this.state.gender}
                            //subjectName={this.state.detail.}
                            department={this.state.detail.department}
                            //departmentName={this.state.departmentName}
                            name={this.state.detail.patientName}
                            code={this.state.detail.patientID}
                            enterNumber={this.state.detail.enterNumber}
                            closeModal={this.closeModal.bind(this)}
                            detail={this.state.detail}
                            onReload={this.onReload}
                        />
                    }
                </Modal>
                }
                {this.state.visibleCreate && <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.visibleCreate}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}

                    okButtonProps={{ disabled: true, hidden: true }}
                    cancelButtonProps={{ disabled: false }}>
                    {this.state.visibleCreate && this.state.maql !== undefined &&
                        <TaoLichPTT
                            onCancel={this.handleCancel}
                            managerId={this.state.maql}
                            mabn={this.state.mabn}
                            hoten={this.state.hoten}
                        />
                    }
                </Modal>
                }
            </div>
            </div>
        )
    }
}

export default index;