import React, { useState, useEffect, useCallback } from "react";
import { Radio } from 'antd';
import * as Actions from '../../../../libs/actions';
import * as UrlConfig from '../../../../configure/urlConfig';
import * as MedicalExamService from '../../../../services/MedicalExam';
import { NHOMXETNGHIEM, NHOMCDHA } from '../../../../configureHIS/common/constants';
import PacsResult from "./subclinical-component/pacsResult";
import TestResult from "./subclinical-component/testResult";

export default (props) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [dataSource, setDataSource] = useState(props.data);
    const [listType, setListType] = useState([]);
    const [selectedType, setSelectedType] = useState(NHOMXETNGHIEM);

    useEffect(() => {
        ListRegisterSubClinical(dataSource.managermentId)
    },[dataSource])

    const ListRegisterSubClinical = (id) => {
        MedicalExamService.ListRegisterSubClinical(id).then(result => {
            if (result.isSuccess) {
                setListType(result.data)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", 'Lỗi tải danh sách nhóm');
        })
    }

    const switchRender = (type) => {
        switch(type){
            case NHOMCDHA:
                return <PacsResult managementId={dataSource.managermentId} />;
            case NHOMXETNGHIEM :
                return <TestResult managementId={dataSource.managermentId} />;
        }
        forceUpdate();
    }

    return (
        <div className='list-page'>
            <div className="list-page-body">
            { listType && listType.length > 0 ? (
                <>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            value={selectedType}
                            onChange={(e) => { setSelectedType(e.target.value) }}
                        >
                            {listType.map(x => (
                                <Radio.Button key={x.key} value={x.key}>{x.value}</Radio.Button>
                            ))}
                        </Radio.Group>
                    </div>
                </div>
                <div className="w-100 h-100 d-flex flex-column justify-content-between overflow-auto mt-2">
                    {switchRender(selectedType)}
                </div>
                </>
            ) : (
                <div className="col-12 d-flex justify-content-center">
                    <label className="mita-value">Không có kết quả cận lâm sàng.</label>
                </div>
            )}
                
            </div>
        </div>
    )
};
