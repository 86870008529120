import moment from "moment";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { defaultStyles, FileIcon } from 'react-file-icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popconfirm, notification} from 'antd';
import * as SystemConfig from '../../../../configure/systemConfig';

export default (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showCopy, setShowCopy] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setFileList(props.fileList);
        setIsEdit(props.isEdit);
        setShowSelect(props.showSelect);
        setShowCopy(props.showCopy);
    },[props])

    function copyToClipboard(e) {
        setCopied(true);
        notification.success({ message: 'Sao chép thành công' });
        setCopied(false);   
    }

    const viewFile = (data) => {
        props.onViewFile && props.onViewFile(data);
    }

    const onDeleteFileClickHandle = (data) => {
        props.onDelete && props.onDelete(data);
    }

    function onChangeSelected(e) {
        console.log(e);
    }

    return (
        <div className="row">
            {fileList && fileList.map((row, index) => (
                <div className="col-sm-6 col-md-3 col-lg-2 py-1" key={index}>
                    <div className="upload-file-container">
                        <div className="upload-file-content">
                            <div className="upload-file-action" title="Xem" onClick={() => {
                                viewFile(row);
                            }}>
                                <div className="upload-file-icon">
                                    <i className="fa fa-eye"/>
                                </div>
                            </div>
                            <div className="upload-file-action" title="Copy" onClick={() => {copyToClipboard()}}>
                                <div className="upload-file-icon">
                                <CopyToClipboard text={row.fileUrl}
                                    onCopy={() => {}}>
                                    <i className="far fa-clone mr-2" style={{ cursor: 'pointer' }} />
                                </CopyToClipboard>
                                </div>
                            </div>
                            <Popconfirm
                                title={`Xác nhận xóa ${row.fileName}?`}
                                onConfirm={() => { onDeleteFileClickHandle(row) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <div className="upload-file-action" title="Xóa" onClick={() => {}}>
                                    <div className="upload-file-icon"><i className="fa fa-trash"/></div>
                                </div>
                            </Popconfirm>
                        </div>
                        <div className="w-50" style={{ margin: '0 auto' }}>
                            <FileIcon extension={row.extensionName} {...defaultStyles[`${row.extensionName}`]} />
                        </div>
                    </div>
                    <div>
                        {row.fileName}
                    </div>
                </div>
            ))}
        </div>
    )
};
