import { Input, Table, Pagination, Tooltip, notification, Popconfirm } from 'antd';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import * as AliasServices from './aliasServices';

const { TextArea } = Input;
const PARENT_GROUP = "CDHA";

function AliasPasc(props) {
    const [keyword, setKeyword] = useState("");
    const [output, setOutput] = useState("");
    const [listData, setListData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [newKeyword, setNewKeyword] = useState("");
    const [newOutput, setNewOutput] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedID, setSelectedID] = useState(null);

    const _keyword = useRef();
    const _output = useRef();

    const columns = [
        {
            title: 'STT',
            key: 'stt',
            width: 50,
            align: "center",
            render: (text, record, index) => (currentPage - 1) * pageSize + Number(index) + 1,
        },
        {
            title: 'Từ khóa',
            dataIndex: 'keyword',
            key: 'keyword',
            width: 300,
            render: (keyword, row) => {
                if (row.id === selectedID && isUpdate) {
                    return (
                        <TextArea
                            rows={2} defaultValue={row.keyword}
                            value={newKeyword} onChange={(e) => setNewKeyword(e.target.value)}
                            onPressEnter={() => updateData(row.id)}
                            placeholder="Nhập từ khóa">
                        </TextArea>
                    )
                } else {
                    return (
                        <span>{isUpdate && row.id === selectedID ? newKeyword : keyword}</span>
                    )
                }
            }
        },
        {
            title: 'Cụm từ thay thế',
            dataIndex: 'output',
            key: 'output',
            width: 400,
            render: (output, row) => {
                if (row.id === selectedID && isUpdate) {
                    return (
                        <TextArea
                            rows={2} defaultValue={row.output}
                            value={newOutput} onChange={(e) => setNewOutput(e.target.value)}
                            onPressEnter={() => updateData(row.id)}
                            placeholder="Nhập cum từ thay thế">
                        </TextArea>
                    )
                } else {
                    return (
                        <span>{isUpdate && row.id === selectedID ? newOutput : output}</span>
                    )
                }
            }
        },
        {
            title: "Thao tác",
            key: "action",
            width: 120,
            align: "center",
            render: (row) => {
                return (
                    <div>
                        <Tooltip title="Cập nhật">
                            {!isUpdate ?
                                <button className="btn btn-warning" onClick={() => { setSelectedID(row.id); setIsUpdate(!isUpdate) }}>
                                    <i className='fas fa-edit' />
                                </button>
                                :
                                row.id === selectedID &&
                                <Fragment>
                                    <button className="btn btn-success" onClick={() => updateData(row.id)}>
                                        <i className='fas fa-save' />
                                    </button>
                                    <button className="btn btn-danger ml-2"
                                        onClick={(e) => { e.stopPropagation(); resetState(); setIsUpdate(false); }}>
                                        <i className="fas fa-times" />
                                    </button>
                                </Fragment>
                            }
                        </Tooltip>
                        {!isUpdate &&
                            <Tooltip title="Xóa" placement='bottom'>
                                <Popconfirm title={`Chắc chắn xóa bộ khóa: ${row.keyword}`} okText="Đồng ý" cancelText="Hủy"
                                    onConfirm={(e) => { e.stopPropagation(); deleteData(row.id) }}
                                    onCancel={(e) => e.stopPropagation()}>
                                    <button className='btn btn-danger ml-2' onClick={(e) => e.stopPropagation()}><i className='fas fa-trash' /></button>
                                </Popconfirm>
                            </Tooltip>}
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        loadListData();
    }, [currentPage, pageSize])

    const loadListData = (key = keyword, out = output) => {
        AliasServices.getAliasList(key, out, currentPage, pageSize, PARENT_GROUP).then(res => {
            if (res.isSuccess) {
                setListData(res.data);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }
    const addData = () => {
        if (keyword.length > 0 && output.length > 0) {
            const data = {
                keyword,
                output,
                groupname: PARENT_GROUP,
            }
            AliasServices.createAlias(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Tạo mới thành công" });
                    setKeyword("");
                    setOutput("");
                    loadListData("", "");
                    _keyword.current.focus();
                } else {
                    if (res.err.msgString) notification.warning({ message: res.err.msgString });
                    else notification.warning({ message: "Đã có lỗi xảy ra, vui lòng thử lại sau" });
                    _keyword.current.focus();
                }
            }).catch(() => { })
        } else {
            if ((!keyword || keyword.length === 0) && (!output || output.length === 0)) notification.warning({ message: "Từ khóa và cụm từ thay thế cần được nhập" })
            else if (!keyword || keyword.length === 0) notification.warning({ message: "Từ khóa cần được nhập" });
            else if (!output || output.length === 0) notification.warning({ message: "Cụm từ thay thế cần được nhập" });
        }
    }

    const updateData = (id) => {
        if (id === null || id === "" || id === undefined) {
            notification.warning({ message: "Không nhận được thông tin dữ liệu cần cập nhật" });
            return;
        } else if (isUpdate) {
            if (newKeyword && newKeyword.length > 0 && newOutput && newOutput.length > 0) {
                const data = {
                    keyword: newKeyword,
                    output: newOutput,
                    id,
                    groupname: PARENT_GROUP,
                }
                AliasServices.updateAlias(data).then(res => {
                    if (res.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" });
                        setIsUpdate(!isUpdate);
                        resetState();
                        loadListData();
                        _keyword.current.focus();
                    } else {
                        if (res.err.msgString) notification.warning({ message: res.err.msgString });
                        else notification.warning({ message: "Đã có lỗi xảy ra, vui lòng thử lại sau" });
                    }
                }).catch(() => { })
            } else {
                if ((!newKeyword || newKeyword.length === 0) && (!newOutput || newOutput.length === 0)) notification.warning({ message: "Từ khóa và cụm từ thay thế cần được nhập" })
                else if (!newKeyword || newKeyword.length === 0) notification.warning({ message: "Từ khóa cần được nhập" });
                else if (!newOutput || newOutput.length === 0) notification.warning({ message: "Cụm từ thay thế cần được nhập" });
            }
        }
    }

    const resetState = () => {
        setSelectedID(null);
        setNewKeyword("");
        setNewOutput("");
    }

    const deleteData = (id) => {
        if (id === null || id === "" || id === undefined) {
            notification.warning({ message: "Không nhận được thông tin dữ liệu cần cập nhật" });
            return;
        } else {
            AliasServices.deleteAlias(id).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Xóa thành công" });
                    loadListData();
                    _keyword.current.focus();
                } else {
                    if (res.err.msgString) notification.warning({ message: res.err.msgString });
                    else notification.warning({ message: "Đã có lỗi xảy ra, vui lòng thử lại sau" });
                }
            }).catch(() => { })
        }
    }

    return (
        <Fragment>
            <div className="row justify-content-center align-items-center">
                <div className='col-md-3'>
                    <Input className="w-100" value={keyword} ref={_keyword}
                        onChange={(e) => setKeyword(e.target.value.replace(" ", ""))}
                        placeholder="Nhập từ khóa và bấm enter để tìm"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                loadListData();
                                _output.current.focus();
                            };
                        }}>
                    </Input>
                </div>
                <div className='col-md-4'>
                    <Tooltip title="Bấm CTRL + SHIFT + L để tạo mới">
                        <Input className="w-100" value={output}  ref={_output}
                            onChange={(e) => setOutput(e.target.value)}
                            placeholder="Nhập cụm từ thay thế và bấm enter để tìm"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") loadListData();
                                if (e.key === 'L' && e.ctrlKey && e.shiftKey) addData();
                            }}>
                        </Input>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={keyword && output && `Thêm dữ liệu thay thế ${keyword} bởi ${output}`}>
                        <button className='btn btn-success' onClick={() => addData()}><i className="fas fa-plus" /></button>
                    </Tooltip>
                    <Tooltip title="Tìm kiếm">
                        <button className='btn btn-color ml-2' onClick={() => loadListData()}><i className="fab fa-sistrix" /></button>
                    </Tooltip>
                </div>
            </div>
            <div className='row mt-2' style={{ height: "calc(100% - 100px)" }}>
                <Table dataSource={listData} columns={columns} pagination={false}
                    scroll={{ x: "max-content", y: "90%" }}
                    rowKey={(e, index) => index}
                    onRow={(row) => {
                        return {
                            onClick: () => {
                                setSelectedID(row.id);
                                setIsUpdate(true);
                                setNewKeyword(newKeyword ? newKeyword : row.keyword);
                                setNewOutput(newOutput ? newOutput : row.output);
                            }
                        };
                    }}
                    rowClassName={(row) => {
                        return `rowCustomSelect ${(selectedID === row.id) ? 'rowCustomSelectActive' : ''}`
                    }} />
            </div>
            <Pagination
                current={currentPage}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setCurrentPage(1); setPageSize(size) }}
                showSizeChanger={true}
            />
        </Fragment>
    );
}

export default AliasPasc;