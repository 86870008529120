import React, { useEffect, useState, useCallback, useRef, } from 'react';
import { Input, Modal, Pagination, Table, Button, Select, notification, Popconfirm, Tooltip } from 'antd';
import * as TemplateVoucherService from './templateVoucherServices';
import EditForm from './EditForm';
import * as Actions from "../../../libs/actions";

const TemplateVoucher = () => {
    const [key, setKey] = useState("");
    const [lstTemplate, setLstTemplate] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [editModal, setEditModal] = useState(false);
    const [templateType, setTemplateType] = useState(0);
    const [selectedTempID, setSelectedTempID] = useState(0);
    const [infoUpdate, setInfoUpdate] = useState([]);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            align: 'center',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1)
        },
        {
            title: 'Loại mẫu in',
            dataIndex: 'templateType',
            key: 'templateType',
            height: 80,
            render: (type) => {
                return <span>{type === 1 ? "Một mặt" : "Hai mặt"}</span>
            }
        },
        {
            title: 'Tên Mẫu In',
            dataIndex: 'templateName',
            key: 'templateName',
            width: 150,
        },
        {
            title: 'Hình',
            key: 'templateBackground',
            width: 400,
            render: (bgr) => {
                return (
                    <div style={{ width: bgr.backSide ? '400px' : '200px' }}>
                        <img style={{ width: bgr.backSide ? '50%' : "100%", padding: 5 }} src={bgr.frontSide} />
                        {bgr.backSide && <img style={{ width: '50%', padding: 5 }} src={bgr.backSide} />}
                    </div>
                )
            }
        },
        {
            title: "Kích thước ảnh",
            key: "bgrSize",
            render: (row) => <span>{row.templateWidth} x {row.templateHeight}</span>
        },
        {
            title: "Vị trí barcode",
            dataIndex: "barcodeFront",
            key: "barcodeFront",
            render: (inFront) => <span>{inFront ? "Mặt trước" : "Mặt sau"}</span>
        },
        {
            title: "Kích thước barcode",
            key: "barcodeSize",
            render: (row) => <span>{row.barcodeWidth} x {row.barCodeHeight}</span>
        },
        {
            title: "Thao tác",
            key: "action",
            align: "center",
            render: (row) => {
                return (
                    <div>
                        <Tooltip title="Cập nhật">
                            <button className='btn btn-warning' onClick={() => { setEditModal(true); setInfoUpdate([row]);}}><i className='fas fa-edit' /></button>
                        </Tooltip>
                        <Popconfirm title="Bạn có chắc chắn xóa?" onConfirm={() => onDelete(row.id)} okText="Đồng ý" cancelText="Hủy bỏ">
                            <button className='btn btn-danger ml-2'><i className='fas fa-trash' /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    useEffect(() => {
        onSearch();
    }, [templateType, currentPage, pageSize])

    const onSearch = (page = currentPage, type = templateType, size = pageSize) => {
        Actions.setLoading(true);
        TemplateVoucherService.getList(key, type, page, size).then(res => {
            if (res.isSuccess) {
                setLstTemplate(res.data);
                setCurrentPage(page);
                setPageSize(size);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
        .finally(() => Actions.setLoading(false))
    }

    const onDelete = (id = selectedTempID) => {
        Actions.setLoading(true);
        TemplateVoucherService.deleteTemplate(id).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã xóa thành công" });
                setSelectedTempID(0);
                onSearch(1);
            } else {
                notification.warning({ message: "Không thể xóa, vui lòng thử lại sau" });
            }
        }).catch(() => { })
        .finally(() => Actions.setLoading(false))
    }
    return (
        <>
            <div className={'containerChilderWrapper'}>
                <div className='h4 text-center mt-2 font-bold'>DANH SÁCH MẪU IN</div>
                <div className="row justify-content-center align-content-between" style={{ position: 'relative' }}>
                    <div className="col-auto mb-2">
                        <Select value={templateType} onChange={(e) => setTemplateType(e)}>
                            <Select.Option value={0}>Tất cả loại mẫu in</Select.Option>
                            <Select.Option value={1}>Mẫu in 1 mặt</Select.Option>
                            <Select.Option value={2}>Mẫu in 2 mặt</Select.Option>
                        </Select>
                    </div>
                    <div className='col-auto mb-2'>
                        <Input placeholder="Nhập tên mẫu in và bấm Enter để tìm" style={{ width: 300 }}
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    onSearch(1);
                                }
                            }} />
                        <button className='btn btn-color ml-2' onClick={() => onSearch(1)}><i className='fas fa-search' /></button>
                    </div>
                    <div style={{ position: 'absolute', right: 20 }}>
                        <button className='btn btn-color' onClick={() => {
                            setEditModal(true)
                        }} ><i className='fas fa-plus'></i></button>
                    </div>
                </div>

                <div style={{ height: 'calc(100% - 120px)', padding: 10 }}>
                    <Table scroll={{ x: 'max-content', y: 0 }}
                        pagination={false}
                        dataSource={lstTemplate}
                        columns={columns}
                        rowKey={(e, index) => index}


                    />
                </div>
                <Pagination
                    current={currentPage}
                    size={pageSize}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                    }}
                    onShowSizeChange={() => { }}
                    showSizeChanger={true}
                />
            </div>
            <Modal maskClosable={true}
                width={1200}
                visible={editModal}
                onCancel={() => { setEditModal(false); onSearch(1) }}
                footer={null}>
                <EditForm onCancel={() => { setEditModal(false); onSearch(1) }} infoUpdate={infoUpdate} ></EditForm>
            </Modal>
        </>
    )
}

export default TemplateVoucher 