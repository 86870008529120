import { DatePicker, InputNumber, Modal, notification, Popconfirm } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as CommonService from '../../../../services/commonService';
import * as InHospitalService from '../../../../services/inPatientService';
import * as NhapkhoaService from '../../../../services/nhapkhoaService';
import PatientInfo from './patientinfo';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const initialState = {
    ngaysudung: moment(),
    soluong: 0,
}

class GiuongBenh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            department: '',
            lstdepartment: [],
            detailGiuong: {},
            room: '',
            lstRoom: [],
            typeRoom: '',
            lstTypeRoom: [],
            bed: '',
            lstBed: [],
            giuongbenh: {},
            lstGiuongBenh: [],
            typeRoomEdit: [],
            lstBedEdit: [],
            lstRoomEdit: [],
            lstTypeRoomEdit: [],
            heightListBtn: 47,
        };
    }
    componentDidMount() {
        this.loadKhoaCanNhap();
        this.GetListSoChamSocByEnterCode();
    }
    loadLoaiPhong() {
        let makp = this.state.visibleEdit !== true ? this.state.department : this.state.departmentEdit;
        CommonService.DanhSachLoaiPhong(makp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTypeRoom: result.data,
                    lstTypeRoomEdit: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({ typeRoom: result.data[0].value }, () => { this.loadPhong(); })
                }
                else {
                    this.setState({ lstBed: [], lstRoom: [], lstRoomEdit: [], lstBedEdit: [], room:'', bed:'' });
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadPhong() {
        let makp = this.state.visibleEdit !== true ? this.state.department : this.state.departmentEdit;
        let loaiphong = this.state.visibleEdit !== true ? this.state.typeRoom : this.state.typeRoomEdit;
        CommonService.DanhSachPhong(makp, loaiphong).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstRoom: result.data,
                    lstRoomEdit: result.data,
                });
                if (result.data.length > 0 && this.state.visibleEdit !== true) {
                    this.setState({ room: result.data[0].value }, () => { this.loadGiuong(); })
                }
                else if (result.data.length === 0) {
                    this.setState({
                        lstBed: [],
                        bed: '',
                    })
                }
                else {
                    this.setState({ roomEdit: result.data[0].value }, () => { this.loadGiuong(); })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadGiuong() {
        let room = this.state.visibleEdit !== true ? this.state.room : this.state.roomEdit;
        CommonService.DanhSachGiuong(room).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBed: result.data,
                    lstBedEdit: result.data,
                });
                if (result.data.length > 0 && this.state.visibleEdit !== true) {
                    this.setState({ bed: result.data[0].value })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.value !== '00');
                this.setState({
                    lstdepartment: data
                });
                if (data.length > 0) {
                    this.setState({ department: data[0].value }, () => { this.loadLoaiPhong(); })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu khoa bị lỗi")
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail, lstGiuongBenh: [] }, () => {
                this.GetListSoChamSocByEnterCode();
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }


    GetListSoChamSocByEnterCode() {
        InHospitalService.GetListGiuongBenhByEnterCode(this.state.detail.enterCode).then(result => {
            if (result.isSuccess) {
                this.setState({
                    initialState,
                    lstGiuongBenh: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu chăm sóc bệnh nhân nội trú bị lỗi")
        })
    }

    UpdateGiuong(isEdit = false) {
        let data = {};
        if (isEdit)
            data = {
                idTheoDoi: this.state.detailGiuong.idTheoDoi,
                maKP: this.state.departmentEdit,
                mabn: this.state.detail.code,
                mavaovien: this.state.detail.enterCode,
                maql: this.state.detail.managerId,
                doituong: this.state.detail.subjectCode,
                idGiuong: Number(this.state.bedEdit),
                dateFrom: this.state.ngaysudungEdit.format("YYYY/MM/DD"),
                dateTo: this.state.ngaysudungEdit.format("YYYY/MM/DD"),
                soluong: Number(this.state.soluongEdit),
            }
        else
            data = {
                idTheoDoi: this.state.giuongbenh.idTheoDoi ? this.state.giuongbenh.idTheoDoi : '',
                maKP: this.state.department,
                mabn: this.state.detail.code,
                mavaovien: this.state.detail.enterCode,
                maql: this.state.detail.managerId,
                doituong: this.state.detail.subjectCode,
                idGiuong: Number(this.state.bed),
                dateFrom: this.state.ngaysudung.format("YYYY/MM/DD"),
                dateTo: this.state.ngaysudung.format("YYYY/MM/DD"),
                soluong: Number(this.state.soluong),
            }
        if (data.mavaovien === undefined || data.mavaovien === '') {
            notification.warning({ message: 'Chưa chọn bệnh nhân!' });
            return;
        }
        if (data.idGiuong === undefined || data.idGiuong === 0) {
            notification.warning({ message: 'Chưa chọn giường!' });
            return;
        }
        InHospitalService.UpdateGiuongBenh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công' });
                this.setState({
                    giuongbenh: { id: result.id }, initialState, visibleEdit: false
                });
                this.GetListSoChamSocByEnterCode();
                if (this.props.reloadGiuong) {
                    this.props.reloadGiuong();
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    XoaGiuongBenh(id) {
        InHospitalService.UpdateDeleteGiuongBenh(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                this.GetListSoChamSocByEnterCode();
                if (this.props.reloadGiuong) {
                    this.props.reloadGiuong();
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="row no-gutters p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="col-auto mb-2 mr-2">
                            <label>Ngày sử dụng</label>
                            <br />
                            <DatePicker locale={viVN} allowClear={false} placeholder="DD/MM/YYYY" value={this.state.ngaysudung}
                                onChange={(e) => { this.setState({ ngaysudung: e }) }} format={'DD/MM/YYYY'}
                                disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                            />
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Khoa</label>
                            <br />
                            <select className="form-control" placeholder="Chọn khoa"
                                value={this.state.department}
                                style={{ width: '150px' }}
                                onChange={(value) => {
                                    this.setState({ department: value.target.value });
                                    setTimeout(() => {
                                        this.loadLoaiPhong();
                                    }, 200);
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstdepartment.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value} style={{ textTransform: 'uppercase' }}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Loại phòng</label>
                            <br />
                            <select
                                className="form-control"
                                value={this.state.typeRoom}
                                onChange={(value) => { this.setState({ typeRoom: value.target.value }, () => this.loadPhong()) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstTypeRoom.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Phòng</label>
                            <br />
                            <select
                                value={this.state.room}
                                className="form-control"
                                onChange={(value) => { this.setState({ room: value.target.value }, () => this.loadGiuong()); }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstRoom.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Giường</label>
                            <br />
                            <select
                                className="form-control"
                                value={this.state.bed}
                                onChange={(value) => { this.setState({ bed: value.target.value }); }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstBed.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Số ngày dùng</label>
                            <br />
                            <InputNumber min={0} max={999} value={this.state.soluong} step='0.1' precision={1}
                                onChange={(e) => { this.setState({ soluong: e }) }} />
                        </div>
                        <div className="col-auto mb-2 mr-2 align-self-end">
                            <button onClick={() => { this.UpdateGiuong() }} className="btn btn-color">
                                <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu</button>
                        </div>
                    </div>
                    <div style={{ padding: '10px 0px' }}>
                        <div className="table-responsive" style={{ maxHeight: '560px' }}>
                            <table className="table table-striped table-inverse table-bordered" style={{ backgroundColor: '#fff', overflow: 'auto' }}>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: '80px' }}>Mã</th>
                                        <th style={{ minWidth: '100px' }}>Ngày</th>
                                        <th style={{ minWidth: '80px' }}>Mã khoa</th>
                                        <th style={{ minWidth: '80px' }}>Phòng</th>
                                        <th style={{ minWidth: '150px' }}>Loại phòng</th>
                                        <th style={{ minWidth: '80px' }}>Giường</th>
                                        <th style={{ minWidth: '80px' }}>Số lượng</th>
                                        <th style={{ minWidth: '100px' }}>Đơn giá</th>
                                        <th style={{ minWidth: '100px' }}>Thành tiền</th>
                                        <th style={{ minWidth: '55px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.lstGiuongBenh.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.idTheoDoi}</td>
                                            <td>{moment(row.ngay).format('DD/MM/YYYY')}</td>
                                            <td>{row.maKP}</td>
                                            {/* <td>{row.tenKP}</td> */}
                                            <td>{row.tenPhong}</td>
                                            <td>{row.tenLoaiPhong}</td>
                                            <td>{row.tenGiuong}</td>
                                            <td>{row.soluong.toLocaleString('en-US')}</td>
                                            <td>{row.giaTH.toLocaleString('en-US')}</td>
                                            <td>{(row.soluong * row.giaTH).toLocaleString('en-US')}</td>
                                            <td>
                                                <div style={{ display: 'flex', placeContent: 'center' }}>
                                                    <div style={{ cursor: 'pointer', marginRight: '5px' }}
                                                        onClick={() => {
                                                            this.setState({
                                                                visibleEdit: true,
                                                                detailGiuong: row,
                                                                ngaysudungEdit: moment(row.ngay),
                                                                departmentEdit: row.maKP,
                                                                typeRoomEdit: row.idLoaiPhong,
                                                                roomEdit: row.idPhong,
                                                                bedEdit: row.idGiuong,
                                                                soluongEdit: row.soluong,
                                                            }, () => {
                                                                this.loadPhong();
                                                            })
                                                        }}
                                                    >
                                                        <button className="btn btn-warning"><i className="fa fa-edit"></i></button>
                                                    </div>
                                                    <Popconfirm
                                                        title="Bạn có chắc muốn xóa?"
                                                        onConfirm={() => { this.XoaGiuongBenh(row.idTheoDoi) }}
                                                        okText="Đồng ý" cancelText="Hủy">
                                                        <div style={{ cursor: 'pointer' }} className="text-danger">
                                                            <button className="btn btn-danger"><i className="fa fa-times"></i></button></div>
                                                    </Popconfirm>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {this.state.visibleEdit === true && <Modal
                    width={850}
                    visible={this.state.visibleEdit === true}
                >
                    <div className="row no-gutters p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="col-auto mb-2 mr-2">
                            <label>Ngày sử dụng</label>
                            <br />
                            <DatePicker locale={viVN} allowClear={false} placeholder="DD/MM/YYYY" value={this.state.ngaysudungEdit}
                                onChange={(e) => { this.setState({ ngaysudungEdit: e }) }} format={'DD/MM/YYYY'} />
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Khoa</label>
                            <br />
                            <select className="form-control" placeholder="Chọn khoa"
                                value={this.state.departmentEdit}
                                style={{ width: '150px' }}
                                onChange={(value) => {
                                    this.setState({ departmentEdit: value.target.value });
                                    setTimeout(() => {
                                        this.loadLoaiPhong();
                                    }, 200);
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstdepartment.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value} style={{ textTransform: 'uppercase' }}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Loại phòng</label>
                            <br />
                            <select
                                className="form-control"
                                value={this.state.typeRoomEdit}
                                onChange={(value) => { this.setState({ typeRoomEdit: value.target.value }, () => this.loadPhong()) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstTypeRoomEdit.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Phòng</label>
                            <br />
                            <select
                                value={this.state.roomEdit}
                                className="form-control"
                                onChange={(value) => { this.setState({ roomEdit: value.target.value }, () => this.loadGiuong()); }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstRoomEdit.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Giường</label>
                            <br />
                            <select
                                className="form-control"
                                value={this.state.bedEdit}
                                onChange={(value) => { this.setState({ bedEdit: value.target.value }); }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstBedEdit.map((row, index) => {
                                    return (
                                        <option key={index} value={row.value}>{row.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col-auto mb-2 mr-2">
                            <label>Số ngày dùng</label>
                            <br />
                            <InputNumber min={0} max={999} value={this.state.soluongEdit} step='0.1' precision={1}
                                onChange={(e) => { this.setState({ soluongEdit: e }) }} />
                            {/* <input type="number" min={0} max={999} className="form-control" value={this.state.soluongEdit}
                                onChange={(e) => { if (e.target.value.length > 4) return; this.setState({ soluongEdit: Number(e.target.value) }) }} /> */}
                        </div>

                    </div>
                    <div className="text-center">
                        <button onClick={() => { this.UpdateGiuong(true) }} className="btn btn-color mr-2">
                            <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu</button>
                        <button onClick={() => { this.setState({ visibleEdit: false }) }} className="btn btn-danger">
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </Modal>}
            </React.Fragment>
        );
    }
}

export default GiuongBenh;