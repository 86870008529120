import React from 'react';
import './custom.css';
import InnerHTML from 'dangerously-set-html-content'
import { store } from '../../stores';

export default class Printer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { file: null };
    }
    componentDidMount() {
        this.hideOrDisplayTooltip("none");
        this.setState({file:this.props.file},()=>{
            if(typeof(this.props.file) == 'object'){
                let OldIframe = document.getElementById('iframeprintblob');
                if(OldIframe)  OldIframe && OldIframe.remove()
                let url = window.URL.createObjectURL(this.props.file);
                var iframe =  document.createElement('iframe'); //load content in an iframe to print later
                document.body.appendChild(iframe);
                iframe.style.display = 'none';
                iframe.src = url;
                iframe.onload = function() {
                    setTimeout(function() {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };
                iframe.setAttribute('id','iframeprintblob')
            } else {
                setTimeout(() => {
                    window.print()
                    this.hideOrDisplayTooltip("");
                }, 1000);
            }
            setTimeout(() => {
                store.dispatch({ type: 'MODAL_CLOSE_PRINT_MODAL' });
            }, 1000);
        })
        
    }
    
    hideOrDisplayTooltip(display) {
        const tooltipArr = Array.from(
            document.getElementsByClassName('ant-tooltip')
        );
        tooltipArr && tooltipArr.forEach(tooltip => {
            tooltip.style.display = display;
        });
    }

    render() {
        
        return (
            <React.Fragment>
                { this.state.file !== null && this.state.file !== '' &&
                    <div className="printarea" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 300, }}>
                        <InnerHTML style={{ width: '100%', height: '100%' }} html={this.props.file} />
                        {/* <div>{ ReactHtmlParser(this.props.file) }</div> */}
                    </div>
                }
            </React.Fragment>
        );
    }
}
