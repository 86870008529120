import * as ApiCaller from '../libs/httpRequests';
const prefix = 'ThirdPartyServiceMapping';

export function GetListServiceMapping(filterModal) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListServiceMapping`, filterModal).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SaveServiceMapping(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveServiceMapping`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteServiceMapping(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteServiceMapping?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportListServiceMapping(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportListServiceMapping`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function ImportServiceMapping(data, thirdPartyId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportServiceMapping?party=${thirdPartyId}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListMapped(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListMapped`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};