import React, { Fragment, useEffect, useState, useRef } from 'react';
import { DatePicker, Input, Tabs } from 'antd';
import { connect } from "react-redux";
import moment from 'moment';
import TaiCho from './taicho';
import TaiNha from './tainha';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs

function ThongKeLayMau(props) {
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [key, setKey] = useState('');
    const [activeKey, setActiveKey] = useState(1);
    const [chiNhanh, setChiNhanh] = useState(1);
    const taichoRef = useRef();
    const tainhaRef = useRef();

    useEffect(() => {
        setChiNhanh(props.siteid);
        if (Number(activeKey) === 1) {
            if (taichoRef && taichoRef.current)
                taichoRef.current.GetList()
        } else {
            if (tainhaRef && tainhaRef.current)
                tainhaRef.current.GetList()
        }
    }, [props.siteid, activeKey])

    const Export = () => {
        if (Number(activeKey) === 1) {
            if (taichoRef && taichoRef.current)
                taichoRef.current.Export()
        } else {
            if (tainhaRef && tainhaRef.current)
                tainhaRef.current.Export()
        }
    }

    const Print = () => {
        if (Number(activeKey) === 1) {
            if (taichoRef && taichoRef.current)
                taichoRef.current.Print()
        } else {
            if (tainhaRef && tainhaRef.current)
                tainhaRef.current.Print()
        }
    }

    return (
        <Fragment>
            <div className={'containerChilderWrapper'}>
                <div className="h4 text-center font-weight-bold my-2">THỐNG KÊ TIẾP NHẬN MẪU</div>
                <div className='row d-flex justify-content-center'>
                    <div className="col-auto mb-2">
                        <RangePicker format="DD-MM-YYYY" value={ngay} allowClear={false}
                            locale={viVN} onChange={value => setNgay(value)}
                        />
                    </div>
                    <div className="col-auto mb-2 d-none">
                        <Input style={{ width: "200px" }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    if (activeKey === '1') {
                                        if (taichoRef && taichoRef.current)
                                            taichoRef.current.GetList()
                                    } else {
                                        if (tainhaRef && tainhaRef.current)
                                            tainhaRef.current.GetList()
                                    }
                                }
                            }}
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            placeholder="Nhập mã hoặc tên bệnh nhân"
                        />
                    </div>
                    <div className="col-auto mb-2">
                        <button type="button" className="btn btn-color"
                            onClick={() => {
                                if (Number(activeKey) === 1) {
                                    if (taichoRef && taichoRef.current)
                                        taichoRef.current.GetList()
                                } else {
                                    if (tainhaRef && tainhaRef.current)
                                        tainhaRef.current.GetList()
                                }
                            }}>
                            <i className="fa fa-search" />
                        </button>
                        <button className="btn btn-color ml-2" onClick={() => { Export() }}><i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                        <button className="btn btn-color ml-2" onClick={() => { Print() }}><i style={{ marginRight: 5 }} className="fas fa-print" />In</button>
                    </div>
                </div>
                <div style={{height:'calc(100% - 90px)'}}>
                    <Tabs className='tab-header-services' centered={true} onChange={e => { setActiveKey(Number(e)); }}>
                        <TabPane tab={"MẪU TẠI CHỖ"} key={1}>
                            <TaiCho ref={taichoRef}
                                keySearch={key}
                                dateFrom={ngay[0]}
                                dateTo={ngay[1]}
                                chiNhanh={chiNhanh}
                            />
                        </TabPane>
                        <TabPane tab={"MẪU TẠI NHÀ"} key={2}>
                            <TaiNha ref={tainhaRef}
                                keySearch={key}
                                dateFrom={ngay[0]}
                                dateTo={ngay[1]}
                                chiNhanh={chiNhanh}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeLayMau);