import React, { Fragment, useRef, useState } from 'react';
import { BUTTON } from '../../common/constants';
import { Alert, Modal, Upload } from 'antd';
import * as VPService from '../services/VPService';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from '../../common/headerModal';
import CoporationLinked from '../screens/coporationLinked';
import { setLoading } from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';

function ImportCoporationDiscount(props) {
    const _notify = useRef();
    const [fileList, setFileList] = useState([]);
    const [showCoporationLinked, setShowCoporationLinked] = useState(false);
    const [response, setResponse] = useState([]);

    const onClose = () => {
        props.onClose();
    }

    const importDiscount = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', fileList[0]);
        _notify && _notify.current && _notify.current.Close();
        setResponse([]);
        VPService.ImportCoporationDiscount(formData, props.bangGia).then(res => {
            if (res.isSuccess) {
                showNotify(res.data.message ?? "Xem kết quả import ở bên dưới.", res.data.errorItems?.length > 0 ? NotifyStatus.Error : NotifyStatus.Success);
                setResponse(res.data);
                props.onSuccess();
            } else {
                showNotify(res.error.messageText ?? "Đã xảy ra lỗi.", NotifyStatus.Error);
            }
        }).catch(err => {
        }).finally(() => {
            setFileList([]);
            setLoading(false);
        })
    }

    const showNotify = (msg, type) => {
        _notify && _notify.current && _notify.current.Show(msg, type);
    }

    const uploadProps = {
        showUploadList: {
            showDownloadIcon: false,
        },
        onRemove: file => {
            setFileList([]);
            return [];
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList
    }

    return (
        <div className='list-page'>
            <HeaderModal title="Import cập nhật chiết khấu dịch vụ" onClose={() => props.onClose()} />
            <div className='list-page-body'>
                <Notify ref={_notify} />
                {isNullOrEmpty(props.bangGia)
                    ?
                    <Alert type='warning' message="Vui lòng chọn bảng giá cần import thông tin cập nhật chiết khấu." />
                    :
                    <Fragment>
                        <div>
                            <label className='mita-title'>Đang chọn cập nhật cho bảng giá:</label>
                            <span className='text-danger ml-1'>{props.bangGia == "default" ? "Mặc định" : props.bangGia}</span>
                        </div>
                        <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ width: "100%" }}>
                                <Upload.Dragger
                                    {...uploadProps}
                                    accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    maxCount={1}
                                    style={{ width: '100%' }}>
                                    <div>
                                        <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                        <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                        <p className="ant-upload-hint">
                                            Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                        </p>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </div>
                        {(response.effort || response.errorItems) &&
                        <>
                            <b className='border-bottom'>Kết quả import cập nhật</b>
                            <div className='d-flex flex-column overflow-auto'>
                                {
                                    response && response.effort && response.effort.map((ef, index) => {
                                        return (
                                            <div className='mt-1' key={index}>&diams; {ef}</div>
                                        )
                                    })
                                }
                                {
                                    response && response.errorItems && response.errorItems.map((er, index) => {
                                        return (
                                            <div className='mt-1' key={index}>&diams; {er}</div>
                                        )
                                    })
                                }
                            </div>
                        </>
                        }
                    </Fragment>
                }
            </div>
            <div className='list-page-footer'>
                <div className='mt-2'>
                    <Alert type='warning' showIcon message="Lưu ý" closable
                        description={<span>Các dịch vụ trong bảng giá sẽ được chiết khấu theo cấu hình trong file excel,
                            KHÔNG ĐƯỢC chiết khấu theo nguồn khách hàng liệt kê
                            <b><a href="#" className="ml-1" onClick={() => setShowCoporationLinked(true)}>tại đây</a></b>.</span>}
                    />
                </div>
                <div className='d-flex justify-content-end align-item-center mt-2'>
                    {!isNullOrEmpty(props.bangGia) &&
                        <button disabled={fileList ? false : true} className={`${BUTTON.THEME}`} onClick={() => importDiscount()}>
                            <i className="fa fa-save mr-1"></i>Import dữ liệu</button>}
                    <button className={`${BUTTON.DANGER} ml-2`}
                        onClick={() => {
                            onClose();
                        }}><i className="fas fa-sign-out-alt mr-1" />Đóng
                    </button>
                </div>
            </div>
            <Modal open={showCoporationLinked}
                closable={false}
                destroyOnClose
                width={800}>
                <CoporationLinked
                    bangGia={props.bangGia}
                    onClose={() => { setShowCoporationLinked(false) }} />
            </Modal>
        </div >
    );
}

export default ImportCoporationDiscount;