import { Input, Table, Tooltip, notification, Modal } from "antd";
import React ,{ Fragment, useState, useRef, useEffect } from "react";
import * as ChiDinhService from "../../../services/chiDinhService";
import * as PackageService from '../../../services/packageService';
import ModalCLS from "../../../components/MDCLS";
import HeaderModal from "../../../configureHIS/common/headerModal";
import Notify, { NotifyStatus } from "../../../components/notify";
import AutocompleteTable from "../../../components/autocompletetable";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';

export default (props) => {
    const _notiRef = useRef();
    const _modalCLS = useRef();
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [keyword, setKeyword] = useState('');
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [visibleCLS, setVisibleCLS] = useState(false);

    const [packageId, setPackageId] = useState('')
    const [detail, setDetail] = useState({});
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [description , setDescription] = useState('');

    const [dataSource, setDataSource] = useState([]);
    const [listSelectedServices, setListSelectedServices] = useState([]);

    const onClose = () => {
        props.onClose();
    }

    useEffect(() => {
        props.isUpdate && setIsUpdate(props.isUpdate);
        props.packageId && setPackageId(props.packageId);
        
    }, [props])
    
    useEffect(() => {
        if(packageId !== ''){
            GetPackageDetail();
        }
    }, [packageId])
    

    const searchIndication = (value) => {
		return ChiDinhService.searchSerivce(0, 0, "default", value, 1, 20);
	}

	const onSelectIndication = (item) => {
		var existed = listSelectedServices.find((x) => x.id === item.id);
		if (existed === undefined) {
            item.banggia = "default";
            item.soluong = 1;
            item.idChiDinh = "0";
            let total = 0;
            listSelectedServices.push(item);
            setListSelectedServices(listSelectedServices);
            CreateDataSource(listSelectedServices);
		}
	}

    const DeleteService = (row) => {
        let data = listSelectedServices;
        data = data.filter(x => x.id !== row.id)
        if(row.type===1)
        {
            data = data.filter(x => x.packageId !== row.packageId && x.type===3)
        }
        data = data.map(x => ({ ...x }));
        setListSelectedServices(data);
        CreateDataSource(data);
    }

    const GetPackageDetail = () => {
        PackageService.ServicePackageDetail(packageId).then(result => {
            if (result.isSuccess) {
                setDetail(result.data);
                setCode(result.data.code);
                setName(result.data.name);
                setDescription(result.data.description)
                let data = result.data.listServicePackageItem.map(e => ({
                    id : e.idvp,
                    mavp : e.maVP,
                    ten : e.name,
                    soluong : e.quantity
                }));
                setListSelectedServices(data);
                CreateDataSource(data);
            } else {
                Actions.openMessageDialog('Lỗi', result.error.messageText);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => {
            forceUpdate();
        })
    }

    const SubmitCreate = () => {
        if(validator.current.allValid()){
            //Actions.setLoading(true);
            let servicePackageItems = listSelectedServices.map(e => ({ 
                idvp : e.id.toString(),
                mavp : e.mavp.toString(),
                name : e.ten.toString(),
                quantity : 1,
            }));
            let data = {
                code,
                name, 
                description,
                ListServicePackageItem : servicePackageItems
            }
            PackageService.CreateServicePackage(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Tạo gói thành công." })
                    onClose();
                } else {
                    Actions.openMessageDialog('Lỗi', result.error.messageText);
                }
                }).catch(err => {
                    Actions.openMessageDialog('Lỗi', err)
                }).finally(() => {
                    Actions.setLoading(false);
                    forceUpdate();
                })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const SubmitUpdate = () => {
        if(validator.current.allValid()){
            //Actions.setLoading(true);
            let servicePackageItems = listSelectedServices.map(e => ({ 
                idvp : e.id.toString(),
                mavp : e.mavp.toString(),
                name : e.ten.toString(),
                quantity : 1,
            }));
            let data = {
                id : detail.id,
                code,
                name, 
                description,
                ListServicePackageItem : servicePackageItems
            }
            if(detail && Object.keys(detail).length !== 0){
                PackageService.UpdateServicePackage(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Cập nhật gói thành công." })
                    onClose();
                } else {
                    Actions.openMessageDialog('Lỗi', result.error.messageText);
                }
                }).catch(err => {
                    Actions.openMessageDialog('Lỗi', err)
                }).finally(() => {
                    Actions.setLoading(false);
                    forceUpdate();
                })
            }
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const CreateDataSource = (data) => {
        let dataSource = data.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })
        setDataSource(dataSource);
    } 

    const columnsSearch = [
			{
				title: "Mã Dịch vụ",
				dataIndex: "mavp",
				key: "mavp",
				width: 70,
			},
			{
				title: "Tên dịch vụ",
				dataIndex: "ten",
				key: "ten",
				width: 300,
			}
		];

    const columns = [
        {
            title: "Mã Dịch vụ",
            dataIndex: "mavp",
            key: "mavp",
            width: 70,
        },
        {
            title: "Tên dịch vụ",
            dataIndex: "ten",
            key: "ten",
            width: 300,
        },
        {
            title: "Số lượng",
            dataIndex: "soluong",
            key: "soluong",
            align: 'Center',
            width: 70,
            format: "number",
        },
        {
            title: "",
            key: "",
            width: 50,
            align: 'Center',
            render: (value ,record) => {
                return <span><Tooltip title="Xóa dịch vụ"><i onClick={() => {DeleteService(record)}} className="fa fa-trash"></i></Tooltip></span>;
            },
        },
    ]
    
    return (
        <Fragment>
            <HeaderModal title={isUpdate ? "Cập nhật gói dịch vụ" : "Tạo mới gói dịch vụ" } onClose={() => {onClose()}}/>
            <div className="row">
                <div className="col-md-12"><Notify ref={_notiRef}/></div>
            </div>
            <div className="row">
                <div className="col-12 my-1">
                    <label>Mã gói</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input className="w-100" placeholder="Nhập mã gói" value={code.toUpperCase()} onChange={(e) => {setCode(e.target.value.toUpperCase())}} />
                    {validator.current.message('Mã gói', code, 'required')}
                </div>
                <div className="col-12 my-1">
                    <label>Tên gói</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                    <Input className="w-100" placeholder="Nhập tên gói" value={name} onChange={(e) => {setName(e.target.value)}} />
                    {validator.current.message("Tên gói", name, "required")}
                </div>
                <div className="col-12 my-1">
                    <label>Mô tả</label>
                    <Input className="w-100" placeholder="Nhập mô tả" value={ description } onChange={(e) => {setDescription(e.target.value)}} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 my-1">
                    <label>Chọn dịch vụ</label>
                    <div style={{ display: 'flex' }}>
                        <AutocompleteTable
                            width="50%"
                            placeholder="Nhập mã, tên dịch vụ để tìm kiếm"
                            tablewidth={500}
                            listHeight={"50vh"}
                            onSearch={(value) => searchIndication(value)}
                            onSelect={(item) => onSelectIndication(item)}
                            value={keyword}
                            headers={columnsSearch}
                            keyValue={"id"}
                        ></AutocompleteTable>
                        {/* <div style={{ marginLeft: 10 }}>
                            <button 
                                className="btn btn-secondary ml-auto" 
                                onClick={e => {
                                    e.stopPropagation();
                                    setVisibleCLS(true);
                                    setTimeout(() => {
                                        _modalCLS.current && _modalCLS.current.UpdateSelectedFromProps(listSelectedServices)
                                        _modalCLS.current && _modalCLS.current.focusSearchControl()
                                    }, 200)
                            }}><i className={'fa fa-plus-circle'}/></button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="row my-2" style={{ height:'calc(100% - 210px)' }}>
                <div className="col-12" >
                    <Table 
                        dataSource={dataSource}
                        scroll={{ y: 0 }}
                        pagination={false}
                        columns={columns} 
                        rowKey={(e, index) => index} 
                    />
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className="row">
                <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-color mr-2" onClick={() => {isUpdate ? SubmitUpdate() : SubmitCreate()}}><i className="fa fa-save mr-2"/>Lưu</button>
                    <button className="btn btn-danger mr-2" onClick={() => {onClose()}}><i className="fas fa-sign-out-alt mr-2"/>Đóng</button>
                </div>
            </div>

            <Modal
                width={1400}
                visible={visibleCLS}
            >
                <ModalCLS
                    hidePhongKham={true}
                    showGoiKham={false}
                    maskClosable={false}
                    ref={_modalCLS}
                    onClose={(data) => {
                        CreateDataSource(data);
                        setVisibleCLS(false);
                    }}
                />
            </Modal>
        </Fragment>
    )
};
