import { SaveFilled } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, InputNumber, notification, Popconfirm, Select, Table, Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as KhoService from '../../../services/KhoService';
import * as MedicineService from '../../../services/medicineService';
import * as VatTuService from '../../../services/VatTuService';
import * as XuatKhoService from '../../../services/xuatkhoService';
import * as SystemConfig from "../../../configure/systemConfig";

const { Option } = Select;
const { TextArea } = Input;
const initialState = {
    id: '',
    makho: 519,
    soluongton: 0,
    soluong: 0,
    ma: '',
    tenthuoc: '',
    lydo: '',
    hamluong: '',
    hopchat: '',
    lonhap: '',
    ngaynhap: '',
    loai: '',
}
export default class CreateXuatKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstAddThuoc: [],
            lstKho: [],
            lstThuoc: [],
            lstInfo: [],
            lstRequest: [],
            lstLoai: [],
            checkVatTu: false,
            lstLichSu: [],
        };
    }
    componentWillMount() {
        this.loadKho();
        if (this.state.lydo !== null || this.state.id !== 0) {
            this.setState({
                ...initialState,
                lstAddVT: [],
                lstKho: [],
                lstVatTu: [],
                disableLD: false
            })
        }
    }
    componentDidMount() {
        if (this.state.lydo !== null || this.state.id !== 0) {
            this.setState({
                ...initialState,
                lstAddThuoc: [],
                lstKho: [],
                lstThuoc: [],
            })
        }
        this.loadLoaiDMBD();

    }
    loadLoaiDMBD() {
        MedicineService.DanhSachLoaiDMBD(true).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoai: result.data,
                })
            }
            else {
            }
        }).catch(err => {
        })
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                }, () => { this.loadVatTuDichVu(this.props.mavp); this.loadThuocTrongKho(this.state.makho) });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi!' })
        })
    }
    loadThuocTrongKho(value) {
        VatTuService.GetVatTuTrongKho(value, this.state.loai).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        lstThuoc: result.data,
                        id: result.data[0].id
                    });
                    this.form.setFieldsValue({
                        id: result.data[0].id,
                        lstThuoc: result.data
                    })
                }
                else {
                    this.setState({
                        lstThuoc: [],
                    });
                }
            }
        }).catch(err => {
        })

    }
    loadLoSX(mabd) {
        VatTuService.GetSLTonKhoTheoID(this.state.makho, mabd).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstInfo: result.data,
                });
            }
            else {
                this.setState({
                    lstInfo: []
                });
            }
        }).catch(err => {
        })
    }

    loadVatTuDichVu(mabd) {
        XuatKhoService.SoLuongTonkhoXuatVatTu(this.state.makho, mabd).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                data = data.map(x => ({ ...x, id: x.mabd, tinhphi: false }));
                this.setState({
                    lstRequest: data,
                });
            }
            else {
                this.setState({
                    lstRequest: []
                });
            }
        }).catch(err => {
        })
    }

    AddThuoc(row, tieuhao = false) {
        let lst = this.state.lstRequest;
        const current = lst.find(x => x.ma === row.ma && x.tinhphi === tieuhao);
        const current2 = lst.find(x => x.losx === row.losx && x.tinhphi === tieuhao);
        if (current !== undefined && current2 !== undefined) {
            notification.warning({ message: 'Vật tư đã tồn tại trong danh sách!' });
            return;
        }
        if (row.soluong > row.soluongton) {
            notification.warning({ message: 'Số lượng xuất phải nhỏ hơn số lượng tồn!' });
            return;
        }
        lst.unshift({
            makho: row.makho,
            manguon: row.manguon,
            idPhieu: row.idPhieu,
            id: row.mabd,
            ma: row.ma,
            ten: row.ten,
            losx: row.losx,
            ngaynhap: moment(row.ngaynhap).format('YYYY-MM-DD'),
            soluongton: row.soluongton,
            soluong: 0,
            donvi: row.donvi,
            tinhphi: tieuhao
        })
        this.setState({
            soluong: 0,
            lstRequest: lst.map(d => ({ ...d }))
        });
    }
    SaveDon(id = '') {
        const xuatkho = {
            'makho': Number(this.state.makho),
            'lydo': `Thực hiện ${this.props.thuthuat === true ? 'thủ thuật' : 'đông y'} [${id}] '${this.props.detail.mavp}-${this.props.detail.ten}' cho bệnh nhân [${this.props.mabn}]`,
            'idpttt': `${id}`,
        }
        let lstVatTu = [];
        this.state.lstRequest.forEach(row => {
            if (row.soluong > 0 && row.soluongton > 0)
                lstVatTu.unshift({
                    makho: row.makho,
                    manguon: row.manguon,
                    idPhieu: row.idPhieu,
                    ma: row.ma,
                    tenthuoc: row.tenthuoc,
                    losx: row.losx,
                    ngaynhap: moment(row.ngaynhap).format('YYYY-MM-DD'),
                    soluongton: row.soluongton,
                    soluong: row.soluong,
                    tinhphi: row.tinhphi,
                    id: row.id,
                })
        });
        if (lstVatTu.length > 0 && this.state.checkVatTu === true) {
            const data = {
                "hoadonxuat": xuatkho,
                'lstAddThuoc': lstVatTu,
                'maql':this.props.maql,
            }
            MedicineService.LuuVatTuPTTT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu vật tư thành công" });
                }
                else {
                    notification.warning({ message: result.err.msgString })
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", "Lưu dữ liệu vật tư bị lỗi")
            })
        }
    }

    XoaThuoc(record) {
        let data = this.state.lstRequest;
        data = data.filter(x => !(
            // x.losx !== record.losx
            // || 
            x.id === record.id
            // || x.idPhieu !== record.idPhieu
            && x.makho === record.makho
            && x.tinhphi === record.tinhphi
            && x.manguon === record.manguon));
        if (data.length === 0) {
            this.setState({
                lstRequest: []
            })
        }
        this.setState({ lstRequest: data.map(d => ({ ...d })) })

    }

    resetField() {
        this.form.setFieldsValue({
            lydo: null,
        });
        this.setState({
            lstRequest: [],
            lstInfo: []
        })
        this.loadKho();
    }
    editSL(data, sl) {
        if (sl === null || sl === '')
            return;
        if (data.soluongton === 0)
            return;
        if (data.soluongton < sl) {
            Actions.openMessageDialog("Thông báo", `Số lượng tồn kho không đủ (Số lượng tồn: ${data.soluongton})`)
            return;
        }
        let lstRequest = this.state.lstRequest;
        let index = lstRequest.findIndex(x =>
            x.losx === data.losx
            && x.id === data.id
            && x.idPhieu === data.idPhieu
            && x.makho === data.makho
            && x.tinhphi === data.tinhphi
            && x.manguon === data.manguon);
        lstRequest[index].soluong = sl;
        this.setState({ lstRequest: lstRequest.map(d => ({ ...d })) });
    }
    editTinhPhi(data, check) {
        if (data.soluongton === 0)
            return;
        let lstRequest = this.state.lstRequest;
        let index = lstRequest.findIndex(x =>
            x.losx === data.losx
            && x.mabd === data.mabd
            && x.idPhieu === data.idPhieu
            && x.makho === data.makho
            && x.manguon === data.manguon);
        lstRequest[index].tinhphi = check;
        this.setState({ lstRequest: lstRequest.map(d => ({ ...d })) });
    }

    LichSuDungVatTu(id) {
        Actions.setLoading(true);
        MedicineService.LichSuDungVatTu(id, this.props.maql).then(result => {
            if (result.isSuccess) {
                this.setState({ lstLichSu: result.data, visable: true });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    XoaPhieuXuat(id, ngayxuat, ngoaitru = false) {
        Actions.setLoading(true);
        XuatKhoService.deleteXuatkho(id, moment(ngayxuat).format("YYYY-MM-DD"), ngoaitru).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công!" });
                this.LichSuDungVatTu(this.props.detail.idpttt);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                width: 80,
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: 150
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,
            },
            // {
            //     title: 'Lô nhập',
            //     dataIndex: 'losx',
            //     key: 'losx',
            //     width: 100,
            // },
            {
                title: 'Tồn kho',
                dataIndex: 'soluongton',
                key: 'soluongton',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                width: 150,
                render: (row, value, rowIndex) => {
                    return <React.Fragment>
                        <div className="d-flex" style={{ cursor: `${this.state.checkVatTu === false ? 'not-allowed' : 'pointer'}` }}>
                            <div className="mr-2 btn-vtsd" style={{ pointerEvents: `${this.state.checkVatTu === false ? 'none' : 'unset'}` }} onClick={() => {
                                this.AddThuoc(row, false)
                            }}>Tiêu hao</div>
                            <div className="btn-vtsd" style={{ pointerEvents: `${this.state.checkVatTu === false ? 'none' : 'unset'}` }} onClick={() => {
                                this.AddThuoc(row, true)
                            }}>Tính phí</div>
                        </div>
                    </React.Fragment>;
                },
            },
        ]
        let dataSource = this.state.lstInfo.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
            },
            {
                title: 'SL tồn kho',
                dataIndex: 'soluongton',
                key: 'soluongton',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'SL xuất',
                dataIndex: 'soluong',
                key: 'soluong',
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : 0}</span>;
                },
            },
            {
                title: 'Action',
                render: (row) => {
                    return (
                        <Popconfirm
                            title="Bạn có chắc muốn xóa chỉ định này?"
                            onConfirm={() => { this.XoaThuoc(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginRight: '5px' }}>
                                <i className="fa fa-trash" /></button>
                        </Popconfirm>
                    )
                },
            }
        ]
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div>
                        <div style={{ marginBottom: 10 }}><strong>Danh mục thuốc/vật tư</strong></div>
                        <div className="form-group" style={{ display: 'flex' }} >

                            <Form.Item style={{ flex: 1, display: 'flex', alignSelf: 'center' }}
                                rules={[{ required: true, message: 'Yêu cầu chọn kho!' }]}>
                                <label>Kho</label>
                                <Select
                                    showSearch
                                    value={this.state.makho}
                                    onChange={(e) => {
                                        this.setState({ makho: e, lstRequest: [], lstInfo: [], id: '' }, () => {
                                            this.loadVatTuDichVu(this.props.mavp);
                                            this.loadThuocTrongKho(this.state.makho);
                                        });
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstKho.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ flex: 1, display: 'flex', alignSelf: 'center' }} >
                                <label>Nhóm</label>
                                <Select className="auto"
                                    showSearch
                                    value={this.state.loai}
                                    onChange={(e) => this.setState({ loai: e }, () => this.loadThuocTrongKho(this.state.makho))}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value=''>Tất cả</Option>
                                    {this.state.lstLoai.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ flex: 1, alignSelf: 'flex-end' }}>
                                <Select style={{ width: '100%' }}
                                    showSearch
                                    placeholder="Nhập từ khóa tìm vật tư"
                                    value={this.state.id}
                                    onChange={(value) => {
                                        this.setState({ rowNumber: [] })
                                        this.loadLoSX(value)
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstThuoc.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: 20, justifyContent: 'center' }}>
                            <Table pagination={false} dataSource={dataSource} columns={columns} rowKey={(e, index) => index}
                                scroll={{ y: '200px' }}
                                onRow={(row, rowIndex) => {
                                    return {
                                        onClick: e => {
                                            // this.setState({
                                            //     ma: row.ma, tenthuoc: row.tenthuoc, lonhap: row.losx,
                                            //     soluongton: row.soluongton, ngaynhap: row.ngaynhap
                                            // });
                                            //this.AddThuoc(row, rowIndex)
                                        },
                                    };
                                }}
                            // rowClassName={(record, rowIndex) => {
                            //     return "rowCustom"
                            // }}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginTop: 15, flex: 1 }}>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">
                                        <Checkbox defaultChecked={false} onChange={(e) => this.setState({ checkVatTu: e.target.checked })}><strong>Sử dụng vật tư</strong></Checkbox>
                                    </div>
                                    <Button className="btn btn-outline-secondary"
                                        onClick={() => {
                                            this.LichSuDungVatTu(this.props.detail.idpttt);
                                        }}
                                    >Lịch sử thêm vật tư</Button>
                                </div>
                                {this.state.checkVatTu === true &&
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse" style={{ border: '1px #e9ecef solid' }}>
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th style={{ minWidth: '80px' }}>Mã</th>
                                                    <th style={{ minWidth: '150px' }}>Tên</th>
                                                    <th style={{ minWidth: '80px' }}>ĐVT</th>
                                                    {/* <th style={{ minWidth: '100px' }}>Lô nhập</th> */}
                                                    <th style={{ minWidth: '80px' }}>Tồn kho</th>
                                                    <th style={{ minWidth: '100px' }}>SL xuất</th>
                                                    <th style={{ minWidth: '50px' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lstRequest.filter(x => x.tinhphi !== true).length > 0 &&
                                                    <tr>
                                                        <td colSpan="6"><strong>Vật tư tiêu hao</strong></td>
                                                    </tr>
                                                }
                                                {this.state.lstRequest.filter(x => x.tinhphi !== true).map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{row.ma}</td>
                                                        <td>{row.ten}</td>
                                                        <td>{row.donvi}</td>
                                                        {/* <td>{row.losx}</td> */}
                                                        <td>{row.soluongton.toLocaleString('en-US')}</td>
                                                        <td><InputNumber placeholder="Nhập số lượng" value={row.soluongton === 0 ? 0 : row.soluong} min={1} maxLength={8}
                                                            onChange={(e) => {
                                                                this.editSL(row, e);
                                                            }} /></td>
                                                        <td><Button onClick={() => { this.XoaThuoc(row) }} className="btn btn-danger" style={{ marginRight: '5px' }}>
                                                            <i className="fa fa-trash" /></Button></td>
                                                    </tr>
                                                )}
                                                {this.state.lstRequest.filter(x => x.tinhphi === true).length > 0 &&
                                                    <tr>
                                                        <td colSpan="6"><strong>Vật tư tính phí</strong></td>
                                                    </tr>
                                                }
                                                {this.state.lstRequest.filter(x => x.tinhphi === true).map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{row.ma}</td>
                                                        <td>{row.ten}</td>
                                                        <td>{row.donvi}</td>
                                                        {/* <td>{row.losx}</td> */}
                                                        <td>{row.soluongton.toLocaleString('en-US')}</td>
                                                        <td><InputNumber placeholder="Nhập số lượng" value={row.soluongton === 0 ? 0 : row.soluong} min={0} maxLength={8}
                                                            onChange={(e) => { this.editSL(row, e) }} /></td>
                                                        <td><Button onClick={() => { this.XoaThuoc(row) }} className="btn btn-danger" style={{ marginRight: '5px' }}>
                                                            <i className="fa fa-trash" /></Button></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                }
                            </div>
                        </div>
                        <div style={{ flex: 1, padding: "5px", display: 'none' }}>
                            <div style={{ display: 'flex', flex: 1, padding: "5px 20px", flexWrap: 'wrap' }}>
                                <Table dataSource={this.state.lstRequest} columns={columns1} rowKey={(e, index) => index}
                                //scroll={{ x: 'max-content', y: 0 }}
                                />
                            </div>
                            <div style={{ display: 'none', marginTop: 10 }}>
                                <Form.Item >
                                    <TextArea rows={3}
                                        value={this.state.lydo}
                                        placeholder="Nhập lý do xuất kho"
                                        onChange={(e) => this.setState({ lydo: e.target.value })} />
                                </Form.Item>
                            </div>
                            <div style={{ display: 'none', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-success">
                                    <Popconfirm
                                        title="Xác nhận lưu?"
                                        onConfirm={() => { this.SaveDon() }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <SaveFilled /> Xuất
                                    </Popconfirm>
                                </button>
                                <button className="btn btn-danger" style={{ marginLeft: 5 }}
                                    onClick={() => {
                                        this.props.onCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
                <Modal
                    width={1000}
                    visible={this.state.visable}
                    onCancel={() => {
                        this.setState({ visable: false })
                    }}
                >
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Mã thuốc</th>
                                    <th>Tên Thuốc</th>
                                    <th>Số lượng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.lstLichSu.filter(x => x.tinhphi === true).length > 0 &&
                                    <tr key='tinhphi'>
                                        <th colSpan="3">
                                            <div className="font-weight-bold">
                                                TÍNH PHÍ
                                            </div>
                                        </th>
                                    </tr>
                                }
                                {this.state.lstLichSu.filter(x => x.tinhphi === true).map((row, index) =>
                                    <React.Fragment>
                                        <tr key={`tinhphi` + index}>
                                            <th colSpan="3">
                                                <div className="row w-100">
                                                    <div className="col-auto">
                                                        {moment(row.ngay).format(SystemConfig.FORMAT_DATE_TIME)}
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="font-weight-normal">Kho: </span> {row.tenkho}
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="font-weight-normal">Trạng thái: </span>{row.trangthai}
                                                    </div>
                                                    {row.noitru === false ?
                                                        <React.Fragment>
                                                            {row.trangthai === 'Đã thanh toán' ?
                                                                <div className="col-auto text-danger">
                                                                    Tính phí ngoại trú chỉ có thể hoàn trả !
                                                                </div>
                                                                :
                                                                <div className="col-auto">
                                                                    <Popconfirm
                                                                        title="Bạn có chắc muốn xóa?"
                                                                        onConfirm={() => { this.XoaPhieuXuat(row.idxuat, row.ngay, true) }}
                                                                        okText="Đồng ý" cancelText="Hủy">
                                                                        <div style={{ cursor: 'pointer' }} className="text-danger"><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa phiếu xuất</div>
                                                                    </Popconfirm>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {row.idxuat !== '' &&
                                                                <div className="col-auto">
                                                                    <Popconfirm
                                                                        title="Bạn có chắc muốn xóa?"
                                                                        onConfirm={() => { this.XoaPhieuXuat(row.idxuat, row.ngay) }}
                                                                        okText="Đồng ý" cancelText="Hủy">
                                                                        <div style={{ cursor: 'pointer' }} className="text-danger"><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa phiếu xuất</div>
                                                                    </Popconfirm>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                        {row.lstAddThuoc.map((child, indexchild) =>
                                            <tr key={'tinhphichild-' + indexchild}>
                                                <td style={{ width: '120px' }}>{child.ma}</td>
                                                <td>{child.tenthuoc}</td>
                                                <td style={{ width: '120px' }}>{child.soluong}</td>
                                            </tr>)}
                                    </React.Fragment>
                                )
                                }
                                {this.state.lstLichSu.filter(x => x.tinhphi === false).length > 0 &&
                                    <tr key='haophi'>
                                        <th colSpan="3">
                                            <div className="font-weight-bold">
                                                HAO PHÍ
                                            </div>
                                        </th>
                                    </tr>
                                }
                                {this.state.lstLichSu.filter(x => x.tinhphi === false).map((row, index) =>
                                    <React.Fragment>
                                        <tr key={`haophi` + index}>
                                            <th colSpan="3">
                                                <div className="row w-100">
                                                    <div className="col-auto">
                                                        {moment(row.ngay).format(SystemConfig.FORMAT_DATE_TIME)}
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="font-weight-normal">Kho: </span> {row.tenkho}
                                                    </div>
                                                    {row.idxuat !== '' &&
                                                        <div className="col-auto">
                                                            <Popconfirm
                                                                title="Bạn có chắc muốn xóa?"
                                                                onConfirm={() => { this.XoaPhieuXuat(row.idxuat, row.ngay) }}
                                                                okText="Đồng ý" cancelText="Hủy">
                                                                <div style={{ cursor: 'pointer' }} className="text-danger"><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa phiếu xuất</div>
                                                            </Popconfirm>
                                                        </div>
                                                    }
                                                </div>
                                            </th>
                                        </tr>
                                        {row.lstAddThuoc.map((child, indexchild) =>
                                            <tr key={'haophichild-' + indexchild}>
                                                <td style={{ width: '120px' }}>{child.ma}</td>
                                                <td>{child.tenthuoc}</td>
                                                <td style={{ width: '120px' }}>{child.soluong}</td>
                                            </tr>)}
                                    </React.Fragment>
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        <Button className="btn btn-outline-secondary"
                            onClick={() => {
                                this.setState({ visable: false })
                            }}>Đóng</Button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}