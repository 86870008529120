import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import * as Actions from '../../../libs/actions';
import { Pagination, Table, Tooltip, Tag } from 'antd';
import moment from 'moment';
import * as SystemConfig from "../../../configure/systemConfig";
import { NHOMXETNGHIEM } from '../../../configureHIS/common/constants';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { capitalizeFirstLeter } from "../../../commons/utils";
import * as StatisticService from '../../../services/statisticService';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getList: () => {
            setCurrentPage(1);
            getList();
        },
        export: () => {
            exportStatistic();
        }
    }));
    const [listPatients, setListPatients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const _hiddenLink = useRef();
    const [tableColumns, setTableColumns] = useState([]);
    useEffect(() => {
        let listTestType = props.listTypes;
        let newTableColumns = [
            {
                title: '#',
                key: 'index',
                width: 80,
                align: "center",
                render: (text, record, index) => ((currentPage - 1) * currentSize + (Number(index) + 1))
            },
            {
                title: 'Ngày giờ xác nhận',
                dataIndex: 'dateConfirm',
                key: 'dateConfirm',
                width: 200,
                render: (date) => {
                    if (date)
                        return <span>{moment(date).format(SystemConfig.FORMAT_DATE_TIME)}</span>;
                    else return "Không xác định"
                },
            },
            {
                title: 'Mã khách hàng',
                dataIndex: 'patientID',
                key: 'patientID',
                width: 150,
                align: 'center',
            },
            {
                title: 'Khách hàng',
                dataIndex: 'patientName',
                key: 'patientName',
                width: 250,
            },
            {
                title: 'SID',
                dataIndex: 'sid',
                key: 'sid',
                width: 180,
            },
            {
                title: 'Tổng',
                dataIndex: 'total',
                key: 'total',
                width: 100,
                align: 'right',
                render: (total, record) => {
                    if (total)
                        return (
                            <Tooltip title={`Bao gồm ${total.toLocaleString('en-US')} dịch vụ xét nghiệm`}>
                                <Tag color="green">{record.listTestType.reduce((total, x) => (x.total > 0 ? total + 1 : total), 0)}</Tag>
                            </Tooltip>
                        )
                    else return 0
                },
            },
        ];
        if (listTestType === undefined || listTestType === null || listTestType.length === 0) {
            VPService.GetLoaiVPByNhom(NHOMXETNGHIEM).then(result => {
                if (result.isSuccess) {
                    listTestType = result.data;
                }
            })
        }
        if (listTestType.length > 0)
            listTestType.forEach(type => {
                let newColumn = {
                    title: capitalizeFirstLeter(type.label),
                    key: Number(type.value),
                    align: 'right',
                    width: type.label.length * 10 + 15,
                    render: (record) => {
                        if (record.listTestType && record.listTestType.length > 0) {
                            let list = record.listTestType;
                            let found = list.find(x => Number(x.type) === Number(type.value));
                            if (found !== undefined)
                                return (
                                    found.total === 0 ? <Tag>0</Tag> :
                                        <Tooltip title={`Bao gồm ${found.total.toLocaleString('en-US')} dịch vụ xét nghiệm`}>
                                            <Tag color="green">{found.total / found.total}</Tag>
                                        </Tooltip>
                                )
                            else return 0;
                        }
                    },
                };
                newTableColumns.push(newColumn);
            });
        setTableColumns(newTableColumns);
    }, [props.listTypes, currentPage]);
    useEffect(() => {
        getList();
    }, [currentPage, currentSize]);
    const getList = () => {
        Actions.setLoading(true);
        let dateFrom = props.dateRange && props.dateRange[0];
        let dateTo = props.dateRange && props.dateRange[1];
        let site = props.site;
        let coporator = props.coporator ? props.coporator : "";
        let type = props.listTypes ? props.listTypes.map(x => (x.value)) : "";
        StatisticService.GetThongKeOngMau(dateFrom.format(SystemConfig.FORMAT_YYYYMMDD), dateTo.format(SystemConfig.FORMAT_YYYYMMDD), currentPage, currentSize, coporator, type, site).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                setListPatients(data);
                setTotalRecords(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bị lỗi");
        })
    }
    const exportStatistic = () => {
        Actions.setLoading(true);
        let dateFrom = props.dateRange && props.dateRange[0];
        let dateTo = props.dateRange && props.dateRange[1];
        let site = props.site;
        let coporator = props.coporator ? props.coporator : "";
        let type = props.listTypes ? props.listTypes.map(x => (x.value)) : "";
        StatisticService.ExportThongKeOngMau(dateFrom.format(SystemConfig.FORMAT_YYYYMMDD), dateTo.format(SystemConfig.FORMAT_YYYYMMDD), coporator, type, site)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeOngMauXetNghiem";
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    return (
        <React.Fragment>
            <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink}></a>
            <div style={{ height: 'calc(100% - 20px)', marginTop: 10 }}>
                <Table dataSource={listPatients}
                    scroll={{ y: 0 }}
                    pagination={false}
                    columns={tableColumns}
                    rowKey={(e, index) => index} />
            </div>
            <Pagination
                className="mt-3"
                style={{ textAlignLast: "center" }}
                current={currentPage}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page); setCurrentSize(size); }}
                onShowSizeChange={(page, size) => { setCurrentPage(1); setCurrentSize(size); }}
                showSizeChanger={true}
            />
        </React.Fragment>
    );
})