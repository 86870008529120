
import { Form, Input, Modal, notification, Pagination, Table, Select, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as CommonService from '../../../services/commonService';
import CreateDistrict from './createdistrict';
const { Option } = Select;
const initialState = {
    visible: false,
    visibleCreate: false,
    maquan: 0,
    matt: '',
    totalRecords: 0,
    key: '',
    tenphongkham: ''
}
let task = null;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDistrict: [],
            lstCity: [],
            key: '',
            disabledUpdate: true,
            visabledLuu: true,
            visabledUpdate: false,
            currentPage: 1,
            pageSize: 10,
        };
    }

    componentWillMount() {
        this.loadCity();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadCity() {
        CommonService.DanhMucThanhThi().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data
                })
            }
        }).catch(err => {
        })
    }
    loadDistrict(page = this.state.currentPage, size = this.state.pageSize) {
        CommonService.DanhMucQuanHuyen(this.state.matt, this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDistrict: result.data,
                    totalRecords: result.totalRecords,
                });
            }
        }).catch(err => {
        })
    }
    async updateDistrict() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            const data = {
                maquan: this.state.maquan,
                tenquan: this.state.tenquan,
                matt: this.state.matt,
                loai: 'quanhuyen',
            }
            CommonService.updateCity(data).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        disabledUpdate: true,
                        visabledLuu: true,
                        visabledUpdate: false
                    })
                    notification.success({ message: 'Cập nhật thành công!' });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                this.loadDistrict(this.state.currentPage);
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }
    }
    DeleteCity(row) {
        const data = {
            maquan: row.ma,
            tenquan: row.ten,
            matt: row.matt,
            loai: 'quanhuyen',
        }
        CommonService.deleteCity(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.setState({ currentPage: 1 }, () => { this.loadDistrict(this.state.currentPage) })
            }
        }).catch(err => {
        })
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };
    render() {
        const columns = [
            {
                title: 'Mã Quận/Huyện',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên Quận/Huyện',
                dataIndex: 'ten',
                key: 'ten',
            },

            {
                title: '',
                render: (row) => {
                    return (<div>
                        <button className="btn btn-warning"
                            title="Cập nhật quận/huyện"
                            onClick={() => {
                                this.setState({ maquan: row.ma, tenquan: row.ten, matt: row.matt, visible: true });
                                this.form.setFieldsValue({
                                    tenquan: row.ten
                                })
                            }} >
                            <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                        </button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { this.DeleteCity(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: "15px" }}><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                        </Popconfirm>
                    </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstDistrict.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{ height: '100%' }}>

                    <div className="row no-gutters" style={{ display: 'flex' }}>
                        <div className="col-md-3 mt-2">
                            <Form.Item label="Thành phố:">
                                <Select defaultValue=''
                                    showSearch
                                    onChange={(e) => { this.setState({ matt: e }, () => { this.setState({ currentPage: 1 }, () => { this.loadDistrict(this.state.currentPage) }) }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value=''>Tất cả</Option>
                                    {this.state.lstCity.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.key}>{row.value}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-3 mt-2">
                            <Form.Item>
                                <Input
                                    value={this.state.key}
                                    placeholder="Nhập mã hoặc tên quận huyện.."
                                    onChange={(e) => {
                                        this.setState({ key: e.target.value });
                                        task = setTimeout(() => {
                                            this.loadDistrict();
                                        }, 800)
                                    }
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.setState({ currentPage: 1 }, () => { this.loadDistrict(this.state.currentPage) })
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-3" style={{ display: 'flex', alignSelf: 'center' }}>
                            <Form.Item>
                                <button className="btn btn-success" onClick={() => { this.loadDistrict(1, this.state.pageSize) }}>
                                    <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                                </button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}
                                > <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>
                    </div>
                    <div style={{ height: 'calc(100% - 90px)' }}>
                        <Table dataSource={dataSource}
                            scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDistrict(page, size) }}
                        onShowSizeChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDistrict(page, size) }}
                        showSizeChanger={true}
                    />


                </Form>


                <Modal maskClosable={false}
                    width={600}
                    visible={this.state.visible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: " 30px" }}>
                        <h3>Cập nhật dữ liệu quận/huyện</h3>
                    </div>
                    <div className="container-fluid row" style={{ display: 'flex', padding: 'inherit' }}>
                        <div className="form-group" style={{ flex: 1 }} >
                            <Form.Item label="Quận/Huyện:" style={{ flex: 1 }} rules={[{ required: true, message: 'Tên Quận/Huyện không được để trống!' }]}>
                                <Input
                                    value={this.state.tenquan}
                                    onChange={(e) => {
                                        this.setState({ tenquan: e.target.value })
                                    }} />
                            </Form.Item>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <button hidden={this.state.visabledUpdate}
                                    onClick={() => { this.setState({ disabledUpdate: false, visabledLuu: false, visabledUpdate: true }) }}
                                    className="btn btn-warning"><i className="fa fa-edit" style={{ marginRight: 5 }}></i> Cập nhật</button> */}
                        <button
                            // hidden={this.state.visabledLuu}
                            className="btn btn-success" onClick={() => this.updateDistrict()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i> Lưu</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.handleCancel()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng
                        </button>
                    </div>
                </Modal>
                {
                    this.state.visibleCreate && <Modal maskClosable={false}
                        width={800}
                        visible={this.state.visibleCreate}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <CreateDistrict onCancel={this.handleCancel} onLoad={this.loadDistrict.bind(this)} />
                    </Modal>
                }
            </React.Fragment>
        )
    }
}