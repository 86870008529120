import React, { Fragment, useEffect, useState } from 'react'
import { Select, Checkbox } from 'antd';
import { connect } from 'react-redux';
import * as AuthServices from '../../services/authServices';
import { isnotnull } from '../../libs/util';
const { Option } = Select;

function SiteFilter(props) {
    const [site, setSite] = useState(props.availableSites);
    const [availableSites, setAvailableSites] = useState([]);
    const [allSite, setAllSite] = useState(false);
    const [modeMultiple, setModeMultiple] = useState(props.modeMultiple != undefined ? props.modeMultiple : true);
    const [col,] = useState(props.col ?? 12);

    useEffect(() => {
        getAvaiableSites();
    }, []);

    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };

    useEffect(() => {
        if (site.length === props.availableSites.length)
            setAllSite(true);
        else setAllSite(false)
        props.updateSeletedSite && props.updateSeletedSite(site);
    }, [site]);

    useEffect(() => {
        setModeMultiple(props.modeMultiple != undefined ? props.modeMultiple : true);
        if (modeMultiple == false) {
            setSite([props.siteId]);
        }
    }, [props.modeMultiple, props.siteId]);

    const setSelectedSite = (site) => {
        if (typeof (site) == "object")
            setSite(site);
        else if (typeof (site) == "number") {
            let siteList = [site];
            setSite(siteList);
        }
    }

    return (
        <div className={`col-md-${col} col-sm-12 mt-2`}>
            <label>Chi nhánh&nbsp;
                {modeMultiple && <Checkbox
                    style={{ height: 10 }}
                    className='ml-2'
                    onChange={(e) => {
                        setAllSite(!allSite);
                        if (!e.target.checked) setSite([]);
                        else setSite(props.availableSites);
                    }}
                    checked={allSite}>Tất cả</Checkbox>}
            </label>
            <Select
                id="prosta-slt-site"
                mode={modeMultiple ? "multiple" : ""}
                allowClear
                className="w-100"
                placeholder="Chọn chi nhánh"
                value={site}
                onChange={(site) => { setSelectedSite(site) }}
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {availableSites && availableSites.map((row, index) => {
                    return (
                        <Option key={index} value={row.id}>{`${row.ma} - ${row.ten}`}</Option>
                    )
                })}
            </Select>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        availableSites: state.global.availableSites,
        siteCode: state.global.siteCode,
        siteId: state.global.siteid,
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(SiteFilter);