import moment from "moment";
import React, { useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Input, Button, Table, Pagination, Select, DatePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import * as CardHistoryService from '../../../services/CardHistoryService';
import Notify, { NotifyStatus } from '../../../components/notify';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true); 
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [cardIds, setCardIds] = useState([]);
  const [cardAccountIds, setCardAccountIds] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    setCardAccountIds([props.cardAccountId]);
  }, [props.cardAccountId])

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [cardAccountIds])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    firstRender.current = false;
  }, []);
  //#endregion 
  //#region search & filter
  const search = (isResetSelectedRow = false) => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardIds: cardIds,
      cardAccountIds: cardAccountIds,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
    CardHistoryService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        // if (isResetSelectedRow) setSelectedRowKeys([]);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const onExportListClickHandle = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      cardIds: cardIds,
      cardAccountIds: cardAccountIds,
      dateFrom: dateFrom,
      dateTo: dateTo
    }
    // CardHistoryService.getListExport(filterModel).then(result => {
    //   result.blob().then((blob) => {
    //     let url = window.URL.createObjectURL(blob);
    //     _hiddenLink.current.href = url;
    //     _hiddenLink.current.download = "DanhSachThe_"+moment(new Date()).format('YYMMDDhhmmss');
    //     _hiddenLink.current.click();
    //   })
    // }).catch(err => {
    //   if (err.error && err.error.messageText)
    //     notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
    //   else notiRef.current.Show(err, NotifyStatus.Error);
    // }).finally(() => {
    //   Actions.setLoading(false);
    //   forceUpdate();
    // })
  }

  const onClearFilterClickHandle = () => {
    setKeyword('');
    setDateFrom(null);
    setDateTo(null);
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    {
      title: 'Khách hàng',
      dataIndex: 'patientName',
      key: 'patientName',
      align: 'left',
      width: 200,
  },
  {
      title: 'Mã phiếu',
      dataIndex: 'billId',
      key: 'billId',
      align: 'left',
      width: 120,
      render: (value, record) => {
        return (
          value
        )
      }
    },
    {
        title: 'Mã phiếu hoàn trả',
        dataIndex: 'refundBillId',
        key: 'refundBillId',
        align: 'left',
        width: 120,
        render: (value, record) => {
          return (
            record.refundBillId
          )
        }
      },
    {
        title: 'Ngày áp dụng',
        dataIndex: 'createAt',
        key: 'createAt',
        align: 'left',
        width: 140,
        render: (value, record) => {
            return (
              <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>
            )      
        }
    },
    {
        title: 'Thanh toán',
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        align: 'right',
        width: 120,
        render: (value, record) => {
          return <span>{ value?.toLocaleString('en-US') }</span>;   
        }
    },
    {
        title: 'Số tiền giảm',
        dataIndex: 'discount',
        key: 'discount',
        align: 'right',
        width: 120,
        render: (value, record) => {
          return <span>{ value?.toLocaleString('en-US') }</span>;   
        }
    },
  ];
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
        <div className="list-page-body">{/* body */}
          <div className="row">{/* notification */}
            <div className="col-md-12"><Notify ref={notiRef} /></div>
          </div>
          <div className="list-page-search">{/* search/filter */}
            <div className="row mt-2"><div className="col-md-12">Ngày áp dụng</div></div>
            <div className="row">
              <div className="col-md-5">
                <DatePicker allowClear={false}
                  locale={viVN}
                  showNow={false}
                  className='w-100 mr-2'
                  placeholder={"Từ ngày"}
                  value={dateFrom}
                  format = { SystemConfig.FORMAT_DATE }
                  onChange={(e) => { 
                    setDateFrom(e ? moment(e) : null);
                  } }
                />
              </div>
              <div className="col-md-2 text-center">~</div>
              <div className="col-md-5">
                <DatePicker allowClear={false}
                  locale={viVN}
                  showNow={false}
                  className='w-100 mr-2'
                  placeholder={"Đến ngày"}
                  value={dateTo}
                  format = { SystemConfig.FORMAT_DATE }
                  onChange={(e) => { 
                    setDateTo(e ? moment(e) : null);
                  } }
                />
              </div>
            </div>
            <div className="row mt-2"><div className="col-md-12">Từ khóa</div></div>
            <div className="row">
              <div className="col-md-12 d-flex">
                <Input
                  placeholder="Nhập tên khách hàng"
                  prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                  value={keyword} 
                  onChange={(e) => { setKeyword(e.target.value); }}
                  onKeyUp={(e) => { enterToSearch(e); }}
                ></Input>
              </div>
            </div>
            <div className="row mt-2">
              <div className='col-md-12 d-flex justify-content-end'>
                <button className="btn btn-color"
                    onClick={() => { resetToFirstPage(); }}
                > <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm kiếm</button>
                <Button className="btn btn-light ml-2"
                    onClick={() => { onClearFilterClickHandle(); }}
                > <i style={{ marginRight: 5 }} className="fa fa-eraser" />Xóa dữ liệu nhập</Button>
              </div>
            </div>
            {/* <div className="row mt-2">
                <div className='col-md-12 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                  <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                  ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                </div>
            </div> */}
          </div>
          <div className="list-page-table">
            <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
              className="table-min-h-0"
              dataSource={listResult} columns={columns}
              locale={{ emptyText: 'Không có dữ liệu' }} 
              scroll={{ x: 0, y: 0 }}
              rowKey={(e) => e.id}
              // rowSelection={{
              //   type: 'checkbox',
              //   ...rowSelection,
              // }}
              rowClassName={(record, rowIndex) => {
                  // return "rowCustom"
              }}
              pagination={false}
              // onChange={onTableChange} 
            />
            <Pagination
              current={pageIndex}
              size={pageSize}
              style={{ textAlignLast: "center", marginTop: "10px" }}
              total={totalRecord}
              showTotal={total => `Tổng: ${total}`}
              onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
              onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
              showSizeChanger={true}
            />
          </div>
        </div>
        <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
        >{/* footer (nếu có) */}
          <div>
          </div>
          <div className='d-flex justify-content-end' >
          </div>
        </div>
      </div>
    </React.Fragment>
  );
})