import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Input, Radio, DatePicker, Table,  Pagination, Drawer, Select, Popover } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../configure/systemConfig';
import * as Actions from '../../libs/actions';
import { isNullOrEmpty } from '../../libs/util';
import * as LogActionService from '../../services/LogActionService';
import Notify, { NotifyStatus } from '../../components/notify';
import LogDetail from './detail';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  //#region khai báo state
  // const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [firstRender, setFirstRender] = useState(true); 
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [appSource, setAppSource] = useState('His');
  const [dateFrom, setDateFrom] = useState(new moment());
  const [dateTo, setDateTo] = useState(new moment());
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [selectedLog, setSelectedLog] = useState(); // set visible modal create/update/detail
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender) return;
    resetToFirstPage();
  }, [pageSize])
  
  useEffect(() => {
    if (firstRender) return;
    resetToFirstPage();
  }, [appSource])

  useEffect(() => {
    setFirstRender(false);
    resetToFirstPage();
  }, []);
  //#endregion 
  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      appSources: [appSource],
      dateFrom: dateFrom,
      dateTo: dateTo,
    }
    LogActionService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
      Actions.setLoading(false);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
      Actions.setLoading(false);
    }).finally(() => {
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event

  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) search();
  };

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 35,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // {
    //   title: 'Chi nhánh',
    //   dataIndex: 'siteCode',
    //   key: 'siteCode',
    //   align: 'left',
    //   width: 160,
    //   render: (value, record) => {
    //     return isNullOrEmpty(value) ? "" :
    //       <Popover trigger="hover" content = {record.siteName}>
    //         <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>{value}
    //       </Popover>
    //   }
    // },
    {
      title: 'Controller',
      dataIndex: 'controllerName',
      key: 'controllerName',
      align: 'left',
      width: 170,
      sorter: () => {},
    },
    {
      title: 'Action',
      dataIndex: 'actionName',
      key: 'actionName',
      align: 'left',
      width: 200,
      sorter: () => {},
      render: (value, record) => {
        return <a href="#"
          onClick={() => {
            setSelectedLog(record);
            setVisibleDetail(true);
          }}
        >{value}</a>
      }
    },
    // Chưa rảnh cập nhật hết description nên tạm ẩn đi
    // {
    //   title: 'Mô tả',
    //   dataIndex: 'description',
    //   key: 'description',
    //   align: 'left',
    //   // width: 70,
    // },
    {
      title: 'Method',
      dataIndex: 'httpMethod',
      key: 'httpMethod',
      align: 'left',
      width: 70,
      sorter: () => {},
    },
    {
        title: 'Thời gian gọi',
        dataIndex: 'executionTimeStart',
        key: 'executionTimeStart',
        align: 'left',
        width: 130,
        sorter: () => {},
        render: (value, record) => {
          return <span>{ moment(value).format(SystemConfig.FORMAT_DATE_TIME_SECOND) }</span>; 
        }
    },
    {
        title: 'Thời gian thực thi',
        dataIndex: 'executionDuration',
        key: 'executionDuration',
        align: 'right',
        width: 135,
        sorter: () => {},
        render: (value, record) => {
          return <span>{ value } ms</span>; 
        }
      },
      {
          title: 'Người gọi',
          dataIndex: 'userFullName',
          key: 'userFullName',
          align: 'left',
          width: 220,
          sorter: () => {},
      },
    ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 
  const appSourcePptions = [
    {
      label: 'His',
      value: 'His',
    },
    {
      label: 'Lis',
      value: 'Lis',
    },
  ];
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách log</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                  <div className='col-md-4'>{/* Ngày lập phiếu */}
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                  </div>
                <div className="col-md-4 d-flex">
                  <Input
                    placeholder="Gõ xong nhấn Enter để tìm"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
                <div className="col-md-4 d-flex">
                  <Radio.Group options={appSourcePptions} value={appSource} optionType="button" 
                     onChange={(e) => { setAppSource(e.target.value) }}
                  />
                  <button 
                    className='btn btn-color ml-2' 
                    onClick={() => {
                      resetToFirstPage();
                    }}
                  ><i className='fa fa-sync-alt mr-2'></i>
                    Làm mới
                  </button>
                </div>
              </div>
              {/* <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          loadDetail(null, detailScreenModeEnum.create);
                        }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
                  <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  </div>
              </div> */}
            </div>
            <div className="list-page-table mt-2">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={true}
        closable={false}
        width={700}
        placement="right"
        mask={true}
        // onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <LogDetail ref={detailRef}
          isDialog={ true } 
          data={selectedLog}
          onCancel={(reload = false) => {handleCancel(reload)}}
        />
      </Drawer>
    </React.Fragment>
  );
})