import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment, useCallback } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Drawer, Input, Modal, Popconfirm, Popover, Tabs } from 'antd';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import SqlQuery from './sqlQuery';
import { IsSuperAdmin } from '../../../commons/utils';
import UnSupport from '../../unknowPage/unSupport';
import SQLExecuteHistory from './history';

const { TabPane } = Tabs;

const SQLIDE = forwardRef((props, ref) => {
    const _sqlQuery = useRef();
    const [visibleHistory, setVisibleHistory] = useState(false);
    const [inputUpdate, setInputUpdate] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const showHistory = () => {
        setVisibleHistory(true);
    }
    const initialItems = [
        {
            label: 'Query 1',
            key: 'default',
            closable: false,
        },
    ];
    const _notify = useRef();
    const [activeKey, setActiveKey] = useState(initialItems[0].key);
    const [items, setItems] = useState(initialItems);
    const newTabIndex = useRef(1);

    const onChange = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
    }, []);

    const add = () => {
        const newIndex = newTabIndex.current++;
        const newActiveKey = `newQuery${newIndex}`;
        const newPanes = [...items];
        newPanes.push({
            label: `Query ${newIndex + 1}`,
            key: newActiveKey,
        });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey, action) => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };

    const setReuseQuery = (q) => {
        add();
        setTimeout(() => {
            _sqlQuery && _sqlQuery.current && _sqlQuery.current.setReuseQuery(q, activeKey);
        }, 300);
    }

    const showErrorMessage = (err) => {
        _notify && _notify.current && _notify.current.Show(err.messageText ?? err, NotifyStatus.Error);
    }

    const closeNotify = () => {
        _notify && _notify.current && _notify.current.Close();
    }

    const changeTabName = () => {
        setInputUpdate(true);
    }

    const onChangeTabName = (newName, index) => {
        items[index].label = newName;
        forceUpdate();
    }

    return (
        <Fragment>
            {IsSuperAdmin ?
                <div className="containerChilderWrapper">
                    <HeaderModal title="SQL IDE" />
                    <Tabs style={{ padding: 10, overflowY: 'auto' }}>
                        <TabPane tab="Query" key="Query">
                            <div className="list-page">
                                <div className="list-page-body">
                                    <Notify ref={_notify} />
                                    <Tabs
                                        type="editable-card"
                                        onChange={onChange}
                                        activeKey={activeKey}
                                        onEdit={onEdit}
                                        size='large'
                                    >
                                        {items && items.map((pane, index) => (
                                            <Tabs.TabPane
                                                tab={!inputUpdate
                                                    ? <span onDoubleClick={() => changeTabName()}>{pane.label}</span>
                                                    : pane.key != activeKey
                                                        ? <span onDoubleClick={() => changeTabName()}>{pane.label}</span>
                                                        : <Input autoFocus size='small'
                                                            placeholder={pane.label.toLocaleUpperCase()}
                                                            value={pane.label.toLocaleUpperCase()} onBlur={() => setInputUpdate(false)} onChange={(e) => onChangeTabName(e.target.value, index)}></Input>}
                                                key={pane.key} closable={pane.closable ?? true}>
                                                <SqlQuery
                                                    tabKey={pane.key} ref={_sqlQuery}
                                                    showError={(err) => showErrorMessage(err)}
                                                    closeNotify={() => closeNotify()}
                                                    showHistory={showHistory} />
                                            </Tabs.TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="Coming soon" key="commingsoon">
                            <UnSupport title="Đang phát triển" />
                        </TabPane>
                    </Tabs>
                </div>
                :
                <UnSupport title="BẠN KHÔNG CÓ QUYỀN" />
            }
            <Drawer
                open={visibleHistory}
                destroyOnClose
                closable={false}
                width={800}
                className='custom-ant-drawer'
                onClose={() => setVisibleHistory(false)}
            >
                <SQLExecuteHistory
                    onClose={() => { setVisibleHistory(false); }}
                    reuseQuery={(q) => { setReuseQuery(q); }}
                />
            </Drawer>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SQLIDE)