import React, { useEffect, useState, useCallback, forwardRef } from 'react'
import { Button, Form, Input, Select, Table, Tooltip, Popover } from 'antd'
import * as NhanVienSerivce from '../../services/employeeService';
import AutocompletetableNoGroup from '../autocompletetable/autocompletetableNoGroup';
import HeaderModal from '../../configureHIS/common/headerModal';
import { connect } from 'react-redux';
import { isNullOrEmpty } from '../../libs/util';
import { openMessageDialog } from '../../libs/actions';
/** Component này dùng để hiển thị popup hoàn trả
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\components\MDCLS\index.js
    - src\screen\recept\register\index.js
 ** Các props nhận vào:
* @param {boolean} props.isDialog: hiển thị dạng dialog hay page 
* @param {Array} props.dataSource: list chỉ định
    props.dataSource=[{
        //
        @param {number} "consultantId": 0,
        @param {string} "consultantName": "aaa",
        [require]
        @param {string} "idChiDinh": "0",
        @param {string} "id": 867, // id của viện phí
        @param {string} "mavp": "KCC01", // mã viện phí
        @param {string} "ten": "Khám cấp cứu", // tên viện phí
        //Khúc trên quan trọng hơn.
        //Khúc dưới thật sự không cần. Lười nên component cha truyền nguyên list vào thôi.
        //Chứ chỉ cần những thông tin trên. Khi submit (Lưu) thì comnponent cha dựa vào id hoặc idChiDinh với thông tin {consultantId, consultantName} rồi tự xử lý nha.
        //Mấy thông tin dưới đây không quan trọng lắm. Nhận list từ MDCLS index truyền vào.
        @param {string} "makp": "125", //mã khoa
        @param {string} "phongkham": "138", //mã phòng
        @param {string} "tenphong": "Ngoại 2", //tên phòng
        @param {string} "maphong": "138", //mã phòng
        @param {string} "banggia": "Bảng giá 12",
        @param {string} "soluong": 1,
        @param {string} "madoituong": "2",
        @param {number} "dongia": 100000,
        @param {date} "ngay": "2023/05/24 10:16",
        @param {string} "mabn": "",
        @param {date} "groupId": 8,
        @param {string} "listChild": null,
        @param {date} "typeId": 28,
        @param {string} "typeName": "Khám bệnh",
        @param {boolean} "isCombo": false,
        @param {string} "dongiakm": null,
        @param {number} "discount": null,
    }]
 */
const { Option } = Select;
const Consultant = forwardRef((props, ref) => {

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [dataSource, setDataSource] = useState(props.dataSource ?? []);
    const [listSale, setListSale] = useState([]);
    const [selectedSale, setSelectedSale] = useState({});

    useEffect(() => {
        loadListSale();
    }, [])

	// loadSale(data) {
    //     if (this.props.onType) {
    //         this.props.onType(data);
    //     }
    //     this.setState({ key: data });
    //     return NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'26');
    // }
    function loadListSale(data) {
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'');
    }

    function selectSale(data) {
        setSelectedSale(data);
        // this.setState({ idsale: data.id, saleName: data.hoten });
    }

    function btnUpdateAll() {
        dataSource.forEach(element => {
            btnUpdateOne(element);
        });
        forceUpdate();
    }

    function btnUpdateOne(record) {
        let listSelectedServices = dataSource;
        let index = listSelectedServices.findIndex(x => x.id === record.id);
        listSelectedServices[index].consultantId = selectedSale.id;
        listSelectedServices[index].consultantName = selectedSale.hoten;
        setDataSource(listSelectedServices);
        forceUpdate();
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (Number(index) + 1),
            align: 'center',
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
        },
        {
            title: <span className={props.consultantRequired && "required-field"}>NV tư vấn</span>,
            dataIndex: 'consultantName',
            key: 'consultantName',
            render: (text, record, index) => {
                return record.consultantName
            },
        },
        {
            title: '',
            render: (text, record, index) => {
                return (
                    <Button
                        onClick={() => {
                            btnUpdateOne(record);
                        }}
                    >Cập nhật</Button>
                )
            },
            align: 'center',
        }
    ];
    const columnSale = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
        },
    ];

    const onPressSave = () => {
		let isNullConsultant = dataSource.some(x => isNullOrEmpty(x.consultantId));
		if (props.consultantRequired && isNullConsultant) {
			openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        if (props.onSubmit) props.onSubmit(dataSource)
    }

    return (
        <React.Fragment>
            <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
                <HeaderModal title="Nhân viên tư vấn" onClose={() => { props.onClose && props.onClose() }} />
                <div className="list-page-body">{/* body */}
                    <div className="list-page-search">{/* search/filter */}
                        <div className="row">
                            <div className='col-12 mb-2 d-flex'>
                                <label className={`mita-title mr-2 ${props.consultantRequired && "required-field"}`}>NV tư vấn 
                                    <Popover trigger="hover"
                                        content = {
                                            <div>
                                                Chọn 1 nhân viên tư vấn, rồi click button "Cập nhật tất cả" để cập nhật cho toàn bộ dịch vụ.<br/>
                                                Hoặc click button "Cập nhật" để cập nhật cho từng dịch vụ.<br/>
                                                <span className='text-danger'>*</span> Nếu không có NV tư vấn nào được chọn, khi click các button cập nhật sẽ xóa nhân viên tư vấn tương ứng với các dịch vụ.
                                            </div>
                                        }
                                    >
                                        <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>
                                    </Popover>
                                </label>
                                <Form.Item 
                                    // onKeyDown={(e) => this.handleEnter(e, 11)}
                                    // className={`${disabled === true ? 'input-formitem-disable' : 'input-formitem'}`}
                                >
                                    <AutocompletetableNoGroup
                                        // ref={(c) => this.AutocompletePatient = c}
                                        placeholder={"Nhập thông tin sale"}
                                        width='400px'
                                        tablewidth={500}
                                        onSearch={loadListSale}
                                        onSelect={selectSale}
                                        headers={columnSale}
                                        keyValue={'id'}
                                        value={selectedSale.hoten}
                                        showRemove={selectedSale.hoten !== '' && selectedSale.hoten !== undefined && selectedSale.hoten !== null} 
                                        CleanText={() => {
                                            setSelectedSale({});
                                        }}
                                    />
                                </Form.Item>
                                <Tooltip title='Áp dụng nhân viên tư vấn đang chọn cho tất cả dịch vụ'>
                                    <Button
                                        onClick={() => { btnUpdateAll(); }}
                                    >Cập nhật tất cả</Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table">
                        <Table dataSource={dataSource}
                            rowKey={(e, index) => index} columns={columns} scroll={{ y: 0 }}
                            pagination={false}
                        />
                    </div>
                </div>
                <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
                >{/* footer (nếu có) */}
                    <div>
                    </div>
                    <div className='d-flex justify-content-end' >
                        <Button className='btn btn-secondary mr-1' onClick={() => {
                            if (props.onClose) props.onClose()
                        }}
                        >Đóng</Button>
                        <Button className='btn btn-color ml-1' onClick={() => {
                            onPressSave()
                        }}
                        >Lưu</Button>
                    </div>
                </div>
            </div>

            {/* <div className='row'>
                <div className='col-12 mb-2 d-flex'>
                    <label className='mita-title mr-2'>NV tư vấn </label>
                    <Form.Item 
                        // onKeyDown={(e) => this.handleEnter(e, 11)}
                        // className={`${disabled === true ? 'input-formitem-disable' : 'input-formitem'}`}
                    >
                        <AutocompletetableNoGroup
                            // ref={(c) => this.AutocompletePatient = c}
                            placeholder={"Nhập thông tin sale"}
                            width='400px'
                            tablewidth={500}
                            onSearch={loadListSale}
                            onSelect={selectSale}
                            headers={columnSale}
                            keyValue={'id'}
                            value={selectedSale.hoten}
                            showRemove={selectedSale.hoten !== '' && selectedSale.hoten !== undefined && selectedSale.hoten !== null} 
                            CleanText={() => {
                                setSelectedSale({});
                            }}
                        />
                    </Form.Item>
                    <Tooltip title='Áp dụng nhân viên tư vấn đang chọn cho tất cả dịch vụ'>
                        <Button
                            onClick={() => { btnUpdateAll(); }}
                        >Cập nhật tất cả</Button>
                    </Tooltip>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-2'>
                    <Table dataSource={dataSource}
                        rowKey={(e, index) => index} columns={columns} scroll={{ y: 0 }}
                        pagination={false}
                        // rowClassName={(record) => {
                        //     return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : record && record.voucher && record.voucher.length > 0 ? 'text-danger' : ''}`
                        // }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-2 text-center'>
                    <Button className='btn btn-secondary mr-1' onClick={() => {
                        if (props.onClose) props.onClose()
                    }}
                    >Đóng</Button>
                    <Button className='btn btn-color ml-1' onClick={() => {
                        if (props.onSubmit) props.onSubmit(dataSource)
                    }}
                    >Lưu</Button>
                </div>
            </div> */}
        </React.Fragment>
    )
})

const mapStateToProps = (state) => {
    return ({
        consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Consultant)
