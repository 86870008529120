import { Input, notification, Select } from "antd";
import React, { useEffect, useState } from 'react';
import * as InvoiceService from '../../services/invoiceService';

const { Option } = Select;

function CustomerVATInfo(props) {
    const [disableChange,] = useState(props.disableChange ? props.disableChange : false);
    const [customerName, setCustomerName] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerCopr, setCustomerCopr] = useState("");
    const [customerTax, setCustomerTax] = useState("");
    const [customerPaidDate, setCustomerPaidDate] = useState("");
    const [customerInvoice, setCustomerInvoice] = useState("");
    const [customerPaymentType, setCustomerPaymentType] = useState(1);
    const [customerCurrency, setCustomerCurrency] = useState("VND");
    const [managementID, setManagementID] = useState("");

    useEffect(() => {
        if (props.info && props.info !== undefined && props.info !== null) {
            setCustomerName(props.info.name);
            setManagementID(props.info.maql);
            getInfo(props.info.maql);
        }
    }, [props])

    const getInfo = (maql) => {
        InvoiceService.getCustomerVATInfo(maql).then(res => {
            if (res.isSuccess) {
                let data = res.item;
                setCustomerName(data.name ? data.name : props.info.name);
                setCustomerCopr(data.company);
                setCustomerAddress(data.address);
                setCustomerTax(data.taxCode);
                setCustomerPaymentType(data.paymentType ? data.paymentType : 1);
                setCustomerCurrency(data.currency ? data.currency : "VND");
            }
        }).catch(err => { });
    } 

    const saveCustomerVATInfo = () => {
        const data = {
            managementID: managementID,
            name: customerName,
            company: customerCopr,
            address: customerAddress,
            taxCode: customerTax,
            paymentType: customerPaymentType,
            currency: customerCurrency,
        };
        InvoiceService.saveCustomerVATInfo(data).then(res => {
            if (res.isSuccess) {
                props.onClose && props.onClose();
            } else {
                if (res.err.msgString) notification.warning({ message: res.err.msgString });
                else notification.warning({ message: "Chưa thể lưu thông tin hóa đơn đỏ" });
            }
        }).catch(err => {
            notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
        });
    }
    return (
        <React.Fragment>
            <div className='row mb-2'>
                <div className='col-md-4'>
                    <label>Họ và tên người mua hàng</label>
                    <Input
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        placeholder="Nhập tên khách hàng"
                        disabled={disableChange}>
                    </Input>
                </div>
                <div className='col-md-4'>
                    <label>Đơn vị</label>
                    <Input
                        value={customerCopr}
                        onChange={(e) => setCustomerCopr(e.target.value)}
                        placeholder="Nhập đơn vị"
                        disabled={disableChange}>
                    </Input>
                </div>
                <div className='col-md-4'>
                    <label>Địa chỉ</label>
                    <Input
                        value={customerAddress}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        placeholder="Nhập địa chỉ"
                        disabled={disableChange}>
                    </Input>
                </div>
            </div>
            <div className="row mb-2">
                <div className='col-md-4'>
                    <label>Mã số thuế</label>
                    <Input
                        value={customerTax}
                        onChange={(e) => setCustomerTax(e.target.value)}
                        placeholder="Nhập mã số thuế"
                        disabled={disableChange}>
                    </Input>
                </div>
                <div className='col-md-4'>
                    <label>Hình thức thanh toán</label>
                    <Select
                        className="w-100"
                        value={customerPaymentType}
                        onChange={(e) => setCustomerPaymentType(e)}
                        placeholder="Chọn hình thức thanh toán"
                        disabled={disableChange}>
                            <Option key="Tiền mặt" value={1}>Tiền mặt</Option>
                            <Option key="Chuyển khoản" value={2}>Chuyển khoản</Option>
                    </Select>
                </div>
                <div className='col-md-4'>
                    <label>Đồng tiền thanh toán</label>
                    <Input
                        defaultValue={"VND"}
                        value={customerCurrency}
                        onChange={(e) => setCustomerCurrency(e.target.value)}
                        placeholder="Nhập đồng tiền thanh toán"
                        disabled={disableChange}>
                    </Input>
                </div>
            </div>
            {/* <div className="row mb-2">
                <div className="col-md-4">
                <label>Ngày thanh toán</label>
                    <Input
                        value={customerPaidDate}
                        onChange={(e) => setCustomerPaidDate(e.target.value)}
                        placeholder="Nhập ngày thanh toán"
                        disabled={disableChange}>
                    </Input>
                </div>
                <div className="col-md-4">
                <label>Số hóa đơn</label>
                    <Input
                        value={customerInvoice}
                        onChange={(e) => setCustomerInvoice(e.target.value)}
                        placeholder="Nhập số hóa đơn"
                        disabled={disableChange}>
                    </Input>
                </div>
            </div> */}
            {!props.hideControl && <div className='d-flex justify-content-center mt-2'>
                <button className="btn btn-success" onClick={() => { saveCustomerVATInfo() }}>Lưu</button>
                <button className="btn btn-danger" style={{ marginLeft: 5}} onClick={() => props.onClose & props.onClose()}>Đóng</button>
            </div>}
        </React.Fragment>
    )
}
export default CustomerVATInfo;