import { Checkbox, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { listData } from '../../commons/menuData';
import * as ConfigService from '../../services/configService';
import * as RoleService from '../../services/roleService';
import SubMenu from './SubMenu';
const { Panel } = Collapse;

export default function ConfigMonitor() {
    const [selectMonitor, setSelectMonitor] = useState('');
    const [monitorName, setMonitorName] = useState('');
    const [listMenu, setListMenu] = useState(JSON.parse(JSON.stringify(listData)))
    useEffect(() => {
        renderSmallMenu()
    }, [])

    const renderSmallMenu = () => {
        ConfigService.GetListSubMenu(true).then(result => {
            if (result.isSuccess) {
                const smallMenu = result.data;
                const newListData = JSON.parse(JSON.stringify(listMenu));
                for (let index = 0; index < newListData.length; index++) {
                    let row = newListData[index];
                    let data = smallMenu.filter(x => x.parent === row.code);
                    if (data.length > 0) {
                        if (row.nodes && row.nodes.length > 0) {
                            // row.nodes.concat(data);
                            row.nodes.forEach(e => {
                                const i = smallMenu.findIndex(x => x.code === e.code)
                                if (i >= 0)
                                    e.label = smallMenu[i].label
                            });
                            data.forEach(element => {
                                if (row.nodes.filter(x => x.code === element.code).length <= 0) {
                                    row.nodes.push(element)
                                }
                            });
                        }
                        else {
                            row.nodes = data;
                        }
                    }
                    if (row.nodes && row.nodes.length > 0) {
                        for (let indexChild = 0; indexChild < row.nodes.length; indexChild++) {
                            const child = row.nodes[indexChild];
                            data = smallMenu.filter(x => x.parent === child.code);
                            if (data.length > 0) {
                                if (child.nodes && child.nodes.length > 0) {
                                    // child.nodes.concat(data);
                                    child.nodes.forEach(e => {
                                        const i = smallMenu.findIndex(x => x.code === e.code)
                                        if (i >= 0)
                                            e.label = smallMenu[i].label
                                    });
                                    data.forEach(element => {
                                        if (child.nodes.filter(x => x.code === element.code).length <= 0) {
                                            child.nodes.push(element)
                                        }
                                    });
                                }
                                else {
                                    child.nodes = data;
                                }
                            }
                            if (child.nodes && child.nodes.length > 0) {
                                for (let indexSub = 0; indexSub < child.nodes.length; indexSub++) {
                                    const sub = child.nodes[indexSub];
                                    data = smallMenu.filter(x => x.parent === sub.code);
                                    if (data.length > 0) {
                                        if (sub.nodes && sub.nodes.length > 0) {
                                            // sub.nodes.concat(data);
                                            sub.nodes.forEach(e => {
                                                const i = smallMenu.findIndex(x => x.code === e.code)
                                                if (i >= 0)
                                                    e.label = smallMenu[i].label
                                            });
                                            data.forEach(element => {
                                                if (sub.nodes.filter(x => x.code === element.code).length <= 0) {
                                                    sub.nodes.push(element)
                                                }
                                            });
                                        }
                                        else {
                                            sub.nodes = data;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                setListMenu(JSON.parse(JSON.stringify(newListData)))
            }
        })
    }

    const checkNode = (node, index) => {
        return <div className="config-monitor" style={{ marginLeft: '15px' }} key={index}>
            <div style={{ paddingLeft: '16px' }}>
                {(node.nodes === undefined || node.nodes.length === 0) &&
                    <div className='d-flex'>
                    <div style={{ width: 24 }}></div>
                    <div onClick={() => {
                            setSelectMonitor(node.code);
                            setMonitorName(node.label)
                        }}
                        >
                            {selectMonitor === node.code ?
                                <mark>{node.label}</mark>
                                :
                                node.label
                            }

                        </div>
                        {/* <Checkbox checked={selectMonitor === node.code} style={{ marginRight: '10px' }}
                            onChange={() => {
                                setSelectMonitor(node.code);
                                setMonitorName(node.label)
                            }}
                        >
                            {node.label}
                        </Checkbox> */}
                    </div>
                }

            </div>
            {node.nodes !== undefined && node.nodes.length > 0 &&
                <Collapse ghost className="m-0 p-0">
                    <Panel className="m-0 p-0"
                        header={<>
                            {/* <Checkbox checked={selectMonitor === node.code} style={{ marginRight: '10px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={() => {
                                setSelectMonitor(node.code);
                                setMonitorName(node.label)
                            }}
                        >

                        </Checkbox> */}
                            {/* {node.label} */}
                            <div onClick={() => {
                                setSelectMonitor(node.code);
                                setMonitorName(node.label)
                            }}
                            >
                                {selectMonitor === node.code ?
                                    <mark>{node.label}</mark>
                                    :
                                    node.label
                                }

                            </div>
                        </>}>
                        {
                            node.nodes.map((data, index) => {
                                return checkNode(data)
                            })}
                    </Panel>
                </Collapse>
            }
        </div>
    }

    const UpdateMenuDelete = (code, list) => {
        console.log(code);
        let data = list;//.map(x => ({ ...x }))
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.nodes && element.nodes.length > 0) {
                element.nodes = element.nodes.filter(x => x.code !== code)
            }
            if (element.nodes && element.nodes.length > 0) {
                UpdateMenuDelete(code, element.nodes)
            }
        }
        // setListMenu(data)
    }
    return (
        <>
            <div style={{ height: '100%', display: 'flex' }}>
                <div className="warrperStyleGroup" style={{ width: '50%', height: '100%', marginRight: 20 }}>
                    <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold' }}>Menu</h4>
                    </div>
                    <div style={{ height: 'calc(100% - 15px)', overflow: 'auto' }}>
                        <div style={{ marginLeft: '-20px' }}>
                            {listMenu.map((row, index) => {
                                return <React.Fragment key={index}>
                                    {checkNode(row, index)}
                                </React.Fragment>
                            })}
                        </div>
                    </div>
                </div>
                <div className="warrperStyleGroup" style={{ width: '50%', height: '100%', marginRight: 20 }}>
                    <div className="grouplabel">
                        <div style={{ fontWeight: 'bold' }} className="h4 text-center">Màn hình/Tính năng {monitorName === '' ? '' : `(${monitorName})`}</div>
                    </div>
                    <SubMenu
                        selectMonitor={selectMonitor}
                        reloadMenu={(isReset, code) => {
                            if (isReset === true) {
                                if (code && code !== '') {
                                    UpdateMenuDelete(code, listMenu)
                                }
                                else {
                                    setListMenu(listData.map(x => ({ ...x })))
                                }
                            }
                            renderSmallMenu();
                        }}
                    />
                </div>
            </div>
        </>
    )

}