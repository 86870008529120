import { Collapse, DatePicker, Form, notification, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '4',
            tenloai: 'Tất cả',
        };
    }

    componentDidMount() {
        this.LoadThongKeDoanhSo();
    }

    LoadThongKeDoanhSo(page = 1, size = 10) {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.loai, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportDoanhSo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.loai, this.state.tenloai)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSo";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Thành tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                },
                width: 100
            },
            {
                title: 'Chi tiết',
                dataIndex: 'lstData',
                key: 'lstData',
                render: (row) => {

                    return (
                        <Collapse className="remove-space-statistic">
                            <Panel header="Xem chi tiết">
                                <Table
                                    style={{ margin: '0 !important' }}

                                    pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                                    dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
                            </Panel>
                        </Collapse>
                    );
                },
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtien',
                key: 'tongtien',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        return (
            <div style={{ padding: "0px 20px", display: 'flex', flexDirection: 'column', height: '100%' }}>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê thuốc</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <div className="row no-gutters">
                        <div className="col-auto">
                            <Form.Item label="Từ ngày:">
                                <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                    allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                    placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                    onChange={(value => this.setState({ ngay: value }, () => this.LoadThongKeDoanhSo()))}
                                />
                            </Form.Item>

                        </div>
                        <Form.Item style={{ marginLeft: 20, display: 'none' }} label="Loại:">
                            <Select
                                style={{ width: 200 }}
                                value={this.state.loai}
                                showSearch
                                onChange={(e, x) => this.setState({ loai: e, tenloai: x.children })}>
                                <Option key={1} value={''}>Tất cả</Option>
                                <Option key={2} value={'1'}>Xét nghiệm</Option>
                                <Option key={3} value={'2'}>Chẩn đoán hình ảnh</Option>
                                <Option key={4} value={'3'}>Tư vấn khám</Option>
                                <Option key={5} value={'4'}>Thuốc</Option>

                            </Select>
                        </Form.Item>
                            <Form.Item>
                                <button className="btn btn-success" style={{ margin: "0px 5px" }} onClick={() => { this.LoadThongKeDoanhSo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-primary" onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                            </Form.Item>
                    </div>
                </div>
                <Table
                    pagination={{
                        showSizeChanger: true,
                        style: { textAlignLast: "center" },
                        total: this.state.totalRecords,
                        showTotal: (total) => (`Tổng: ${total}`),

                        // onChange: (page, size) => { this.LoadThongKeDoanhSo(page, size) },
                        // onShowSizeChange: (page, size) => { this.LoadThongKeDoanhSo(1, size) }
                    }}
                    dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
            </div>
        )
    }
}
