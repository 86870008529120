import { Button, Input, Popover, Select } from 'antd';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { BUTTON } from '../../../configureHIS/common/constants';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as CoporationServices from "../../../services/CorporationServices";
import { setLoading } from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';
import CoporationReport from '../../../components/coporation-report';

let task = null;

const CoporationChildGroupCreate = forwardRef((props, ref) => {
    const [childName, setChildName] = useState();
    const [parentId, setParentId] = useState();
    const [listParent, setListParent] = useState([]);
    const _coporationSelect = useRef();

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListParent();
    }, []);


    const _notify = useRef();

    const onClose = () => {
        if (props.onClose) props.onClose();
        else showError();
    }

    const getListParent = (key) => {
        const data = {
            keyword: key,
            skipCount: 0,
            maxResultCount: 20,
        }
        CoporationServices.GetListParent(data).then(res => {
            if (res.isSuccess) {
                setListParent(res.data.items);
            } else showError("Đã xảy ra lỗi tìm kiếm nguồn khách hàng tổng, vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText);
        }).finally()
    }

    const onSave = () => {
        if (isNullOrEmpty(parentId)) {
            showError("Thông tin nguồn khách hàng tổng cần được chọn.");
            return;
        }
        if (isNullOrEmpty(childName)) {
            showError("Thông tin tên cần được nhập.");
            return;
        }
        setLoading(true);
        const data = {
            parentId,
            childName,
            coporationId: _coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId()
        }
        _notify && _notify.current && _notify.current.Close();
        CoporationServices.CreateChildGroup(data).then(res => {
            if (res.isSuccess) {
                showSuccess("Tạo mới thành công.");
                props.onReload && props.onReload();
            } else showError(res.error.messageText ?? "Không thể tạo mới. Vui lòng thử lại sau.");
        }).catch(err => {
            showError(err.error.messageText ?? "Đã xảy ra lỗi.");
        }).finally(() => setLoading(false))
    }

    const renderInfo = () => {
        let data = _coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporation();
        return (
            <div>
                <div className='border-bottom backgroundColorThemesAlpha'>
                    <label style={{ width: "30px", textAlign: "center", }}>#</label>
                    <label style={{ width: "80px", textAlign: "center", }}>Mã</label>
                    <label style={{ width: "260px", }}>Tên</label>
                </div>
                {data.map((c, index) => {
                    return (
                        <div className='border-bottom'>
                            <label style={{ width: "30px", textAlign: "center", }}>{index + 1}</label>
                            <label style={{ width: "80px", textAlign: "center", }}>{c.ma}</label>
                            <label style={{ width: "260px" }}>{c.ten}</label>
                        </div>
                    )
                })}
            </div>
        )
    }

    const showError = (msg = "Không tìm thấy function.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Error);
    }

    const showSuccess = (msg = "Thao tác thành công.") => {
        _notify && _notify.current && _notify.current.Show(msg, NotifyStatus.Success);
    }

    return (
        <Fragment>
            <HeaderModal title="Tạo mới nhóm nguồn con" onClose={() => onClose()} />
            <Notify ref={_notify} />
            <div className='mt-2'>
                <label className='required-field'>Nguồn khách hàng tổng</label>
                <Select placeholder='Chọn nguồn khách hàng tổng'
                    value={parentId} className='w-100'
                    onChange={(e) => setParentId(e)}
                    showSearch filterOption={false}
                    onSearch={(e) => {
                        if (task) clearTimeout(task);
                        task = setTimeout(() => {
                            getListParent(e);
                        }, 400)
                    }}
                >
                    {listParent && listParent.map((x, index) => {
                        return (
                            <Select.Option key={index} value={x.uid}>{x.parentName}</Select.Option>
                        )
                    })}
                </Select>
            </div>
            <div className='mt-2'>
                <label className='required-field'>Tên nhóm nguồn con</label>
                <Input placeholder='Nhập tên nhóm nguồn con' className='w-100'
                    value={childName} onChange={(e) => setChildName(e.target.value)} />
            </div>
            <div className='mt-2'>
                <CoporationReport ref={_coporationSelect} modeMultiple={true} allowClear={true} />
            </div>
            <div className='mt-2'>
                <Popover
                    overlayStyle={{
                        width: 400
                    }}
                    destroyTooltipOnHide
                    title="Nguồn khách hàng đã chọn"
                    content={() => renderInfo()}
                >
                    <Button type='link'>Xem danh sách nguồn khách hàng đã chọn</Button>
                </Popover>
            </div>
            <div className='d-flex justify-content-center'>
                <button className={`${BUTTON.THEME} mt-2`} onClick={() => onSave()}><i className='fas fa-save mr-1' />Lưu</button>
                <button className={`${BUTTON.DANGER} mt-2 ml-2`} onClick={onClose}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationChildGroupCreate)