import { Form, Input, Modal, notification, Pagination, Table, Switch, Popconfirm, InputNumber } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as PhongKhamService from '../services/PhongKhamService';
import CreatePhongKham from './creatRoom';
import { ValidationCustom } from '../../../commons/validationCustom';

const initialState = {
    visible: false,
    visibleCreate: false,
    mapk: 0,
    totalRecords: 0,
    currentPage: 1,
    key: '',
    tenphongkham: '',
    size: 10,
}
export default class PhongKham extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstPK: [],
            lstLP: [],
            loadingSwitch: false,
            thutuhienthi: 0,
            notifyUpdate: false,
        };
    }

    componentDidMount() {
        this.loadDanhSachPhongKham();
    }
    onReload = () => {
        this.setState({
            currentPage: 1
        });
    }
    loadDanhSachPhongKham() {
        PhongKhamService.GetDanhSachPKham(this.state.key, this.state.currentPage, this.state.size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPK: result.data,
                    totalRecords: result.totalRecords,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi' });
        })
    }

    async updatePhongKham() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                tenkp: this.state.tenphongkham,
                makp: this.state.mapk,
                stt: this.state.thutuhienthi,
            }
            PhongKhamService.CapNhatPhongKham(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.setState({ notifyUpdate:  false })
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
            notifyUpdate: false,
        });
        this.loadDanhSachPhongKham();
    };
    updateCheckSwitch(row, check) {
        this.setState({ loadingSwitch: true });
        CommonService.UpdateHienThiPhong(row.id, check).then(result => {
            let data = this.state.lstPK;
            let item = data.find(x => x.id === row.id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstPK: data.map(x => ({ ...x })),
                loadingSwitch: false,
            })
        }).catch(err => {
            let data = this.state.lstPK;
            let item = data.find(x => x.id === row.id);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingSwitch: false, lstPK: data.map(x => ({ ...x })) })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    XoaPhong(idPhong) {
        PhongKhamService.deleteRoom(idPhong).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thao tác thành công' });
                this.loadDanhSachPhongKham();
            }
            else{
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.warning({ message: err });
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã phòng khám',
                dataIndex: 'makp',
                key: 'makp',
                width: 120
            },
            {
                title: 'Tên phòng khám',
                dataIndex: 'tenkp',
                key: 'tenkp',
                width: 300
            },
            {
                title: 'Loại phòng khám',
                dataIndex: 'tenloaipk',
                key: 'tenloaipk',
                width: 300
            },
            {
                title: 'Khoa',
                dataIndex: 'tenkhoa',
                key: 'tenkhoa'
            },
            {
                title: 'Hiển thị',
                width: 100,
                align: "center",
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingSwitch} checkedChildren="Có" unCheckedChildren="Không" checked={row.hienthi === 1 || row.hienthi === true}
                            onChange={(e) => { this.updateCheckSwitch(row, e) }} />
                    )
                }
            },
            {
                title: 'Thứ tự hiển thị',
                dataIndex: 'stt',
                key: 'stt',
                align: "center",
            },
            {
                title: 'Action',
                width: 200,
                render: (row) => {
                    return (
                        <>
                        <button className="btn btn-warning"
                            title="Cập nhật phòng khám!"
                            onClick={() => {
                                this.setState({ mapk: row.makp, tenphongkham: row.tenkp, thutuhienthi: row.stt, visible: true });
                            }} >
                            <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                        </button>
                        <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { 
                                    this.XoaPhong(row.id)
                                }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </>
                    )
                }
            }
        ];
        let dataSource = this.state.lstPK.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{ height: '100%' }}>

                    <div className="row" style={{ display: 'flex' }}>
                        <div className="col-md-6">
                            <input
                                className="form-control mb-2"
                                value={this.state.key}
                                placeholder="Nhập mã hoặc tên phòng khám..."
                                onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({
                                            currentPage: 1,
                                        }, () => this.loadDanhSachPhongKham());
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success mb-2" onClick={() => this.setState({
                                currentPage: 1,
                            }, () => this.loadDanhSachPhongKham())}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </button>
                            <button className="btn btn-success mb-2" style={{ marginLeft: 10 }}
                                onClick={this.showModalCreate}
                            > <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                        </div>
                    </div>

                    <div style={{ height: 'calc(100% - 100px)' }}>
                        <Table dataSource={dataSource} scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={this.state.currentPage}
                        onChange={(page, size) => {
                            this.setState({
                                currentPage: page,
                                size: size
                            }, () => this.loadDanhSachPhongKham());
                        }}
                        onShowSizeChange={(page, size) => {
                            this.setState({
                                currentPage: 1,
                                size: size
                            }, () => this.loadDanhSachPhongKham());
                        }}
                        showSizeChanger={true}
                    />

                </Form>
                <Modal maskClosable={false}
                    width={600}
                    visible={this.state.visible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật thông tin phòng khám</h3>
                    </div>
                    <div className="row" style={{ display: 'flex', padding: 'inherit' }}>
                        <div className="col-md-12">
                            <div className="form-group" style={{ flex: 1 }} >
                                <Form.Item style={{ flex: 1 }} >
                                    <label>Tên phòng khám</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <Input
                                        value={this.state.tenphongkham}
                                        onChange={(e) => {
                                            this.setState({ tenphongkham: e.target.value })
                                        }} />
                                    {this.validator.message("Tên phòng khám", this.state.tenphongkham, "required")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="col-md-12 mt-1">
                            <div className="form-group" style={{ flex: 1 }} >
                                <Form.Item style={{ flex: 1 }}>
                                    <label>Thứ tự hiển thị</label>
                                    <InputNumber min={0} max={9999} className="w-100"
                                        value={this.state.thutuhienthi}
                                        onChange={(e) => {
                                            this.setState({ thutuhienthi: e, notifyUpdate: true})
                                        }} />
                                </Form.Item>
                            </div>
                            {this.state.notifyUpdate && <label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14 }}><i>Sau khi cập nhật thứ tự hiển thị, cần phải Reload lại trang để áp dụng thứ tự hiển thị mới ở menu "KHÁM BỆNH"</i></label>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                        <button style={{ marginTop: -4 }} className="btn btn-success"
                            onClick={() => this.updatePhongKham()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} />Cập nhật</button>

                        <button type="primary" style={{ marginLeft: 10, marginTop: -4 }} className="btn btn-danger"
                            onClick={() => {
                                this.handleCancel();
                                this.onReload();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng

                        </button>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleCreate}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreatePhongKham onCancel={this.handleCancel}
                        onReload={this.onReload} />
                </Modal>
            </React.Fragment>
        )
    }
}