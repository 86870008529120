import { Checkbox, DatePicker, Input, Pagination, Popover, Select, Table } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify'
import moment from 'moment';
import * as Statistic from '../../../services/statisticService';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
export default forwardRef((props, ref) => {
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const [datecreate, setDatecreate] = useState(moment())
    const [billSelected, setBillSelected] = useState('');
    const [billNoSelected, setBillNoSelected] = useState('');
    const [thamChieu, setThamChieu] = useState('');
    const [ghichu, setGhichu] = useState('');
    const [, updateState] = React.useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if(props.billSelected && props.billSelected !== null && props.billSelected !== ''){
            setBillSelected(props.billSelected)
            setBillNoSelected(props.billNoSelected)
        } else {
            setBillSelected('')
            setBillNoSelected('')
        }
    },[props.billSelected])

    const resetState = () => {
        setBillSelected('')
        setBillNoSelected('')
        setThamChieu('')
        setGhichu('')
        notiRef.current.Close()
    }

    const deleteCongNo = () => {
        Actions.setLoading(true);
        Statistic.deleteCongNo(billSelected).then(res => {
            if (res.isSuccess) {
                if (notiRef && notiRef.current) 
                    notiRef.current.Show("Xóa thu hồi công nợ thành công.", NotifyStatus.Success)
                    setTimeout(() => {
                        if (props.onSubmit)
                            props.onSubmit();
                        resetState();
                        setDisableBtn(false)
                    }, 2000);
                    
                    Actions.setLoading(false);
            } else {
                if (notiRef && notiRef.current)
                    notiRef.current.Show(res.err.msgString, NotifyStatus.Warning)
                Actions.setLoading(false);
            }
        }).catch((err) => {
            Actions.setLoading(false);
        })
    }

    return (
        <>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='h3'>Xóa công nợ đã thu</div>
                <h3 onClick={() => {props.onCancel();notiRef.current.Close()}}><i className='far fa-times-circle' /></h3>
            </div>
            <Notify ref={notiRef} />
            <div className='py-1'>
                <label>Mã phiếu chọn</label>
                <label style={{ marginLeft: 4, color: 'rgb(255, 77, 79)', fontSize: '14' }}>*</label>
                <TextArea rows={2} readOnly
                    value={billNoSelected ?? billSelected}
                />
            </div>
            <div className='py-1 text-right'>
                <button className='btn btn-color'
                    onClick={() => {
                        deleteCongNo()
                        setDisableBtn(true)
                    }}
                    disabled={disableBtn}
                ><i className="fa fa-check-circle mr-1" style={{ marginRight: 5 }} />Xác nhận</button>
            </div>
        </>
    )
});
