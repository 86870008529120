import moment from "moment";
import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { Checkbox} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popconfirm, notification} from 'antd';
import * as SystemConfig from '../../../../configure/systemConfig';

export default (props) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isEdit, setIsEdit] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showCopy, setShowCopy] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [selectedFileId, setSelectedFileId] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setFileList(props.fileList);
        setIsEdit(props.isEdit);
        setShowSelect(props.showSelect);
        setShowCopy(props.showCopy);
    },[props])

    useEffect(() => {
        if (props.defaultSelectAll) {
            let list = props.fileList.map(x=> {return x.id});
            setSelectedFileId(list);
            props.changeSelectedIds(list);
        }
    },[props.defaultSelectAll])

    function copyToClipboard(e) {
        setCopied(true);
        notification.success({ message: 'Sao chép thành công' });
        setCopied(false);   
    }

    function onChangeSelected(e, row) {
        let list = selectedFileId ?? [];
        if (e.target.checked) {
            list.push(row.id);
        }
        else {
            list = list.filter(x => x.id != row.id);
        }
        setSelectedFileId(list);
        props.changeSelected(list);
        forceUpdate();
    }

    const viewFile = (data) => {
        props.onViewFile && props.onViewFile(data);
    }

    const onDeleteFileClickHandle = (data) => {
        props.onDelete && props.onDelete(data);
    }

    return (
        <div className="col-12">
            {fileList && fileList.map(row => (
                <div>
                    <i className='fas fa-file mr-2'/>
                    <a href="#" className='mr-2' style={{ cursor: 'pointer' }}
                    onClick={() => {
                        viewFile(row);
                    }}
                    >{row.fileName}</a>
                    <label className='mr-2'>{moment(row.dateTime).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                    {showSelect && 
                    <Checkbox className="mr-2"
                        checked={selectedFileId.indexOf(row.id) > -1}
                        onChange={(e) => { onChangeSelected(e, row); }}
                        onClick={(e) => { }}
                    ></Checkbox>
                    }
                    {showCopy && 
                    <CopyToClipboard text={row.fileUrl}
                        onCopy={() => copyToClipboard()}>
                        <i className="far fa-clone mr-2" style={{ cursor: 'pointer' }} />
                    </CopyToClipboard>
                    }
                    {isEdit && 
                        <Popconfirm
                            title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{row.fileName}</b>?</span>)}}
                            onConfirm={() => onDeleteFileClickHandle(row)}
                            onCancel={() => {}}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <i className="fas fa-trash-alt mr-2" style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                    }
                </div>
            ))}
        </div>
    )
};
