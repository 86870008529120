import * as ApiCaller from '../libs/httpRequests';
const prefix = 'CardHistory';

export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
