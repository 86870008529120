import { Input, InputNumber, notification, Switch, Select, Tooltip, DatePicker, Button, Modal } from 'antd';
import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import * as Actions from '../../libs/actions';
import * as RoleService from '../../services/roleService';
import * as AdministrativeService from '../../services/administrativeService';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import BillFormatConfig from './BillFormatConfig';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { FORMAT_TIME_DATE_FULL } from '../../configure/systemConfig';
import HeaderModal from '../common/headerModal';
import { BUTTON } from '../../configureHIS/common/constants';
import Create from './create';
import { CopyToClipboard } from 'react-copy-to-clipboard';

var task = null;
const { Option } = Select;
const lstDateFormat = [
    {
        key: 'dd-MM-yyyy',
        value: 'dd-MM-yyyy'
    },
    {
        key: 'dd/MM/yyyy',
        value: 'dd/MM/yyyy'
    },
]
const lstTimeFormat = [
    {
        key: '12h',
        value: '12h'
    },
    {
        key: '24h',
        value: '24h'
    },
]

export default function Config() {
    const [lstConfigure, setLstConfigure] = useState([]);
    const [lstDistrict, setLstDistrict] = useState([]);
    const [lstWard, setLstWard] = useState([]);
    const [lstCity, setLstCity] = useState([]);
    const [city, setCity] = useState(null);
    const [district, setDistrict] = useState(null);
    const [ward, setWard] = useState(null);
    const [lstPaymentType, setLstPaymentType] = useState([]);
    const [dynamicState, setDynamicState] = useState({});
    const [listPrefix, setListPrefix] = useState({});
    const [vsbCreate, setVsbCreate] = useState(false);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        SystemConfig();
        getPaymentMethod();
        loadCity();
        return () => {
            if (task != null) {
                clearTimeout(task)
            }
        }
    }, []);

    const SystemConfig = () => {
        Actions.setLoading(true);
        RoleService.SystemConfig().then(result => {
            if (result.isSuccess) {
                // Filter các trường set Phường, Quận, Tỉnh
                let data = result.data.filter(x => x.code.substring(0, 2) !== "lo").filter(x => x.code.substring(0, 2) !== "bi");
                setLstConfigure(data);
                if (result.data) {
                    var listDefaultAddr = result.data.filter(x => x.code.substring(0, 2) === "lo");
                    if (listDefaultAddr) {
                        let foundCity = listDefaultAddr.find(x => x.key === 'city');
                        let foundDistrict = listDefaultAddr.find(x => x.key === 'district');
                        let foundWard = listDefaultAddr.find(x => x.key === 'ward');
                        foundCity !== undefined && setCity(Number(foundCity.value));
                        loadDistrict(Number(foundCity.value));
                        foundDistrict !== undefined && setDistrict(Number(foundDistrict.value));
                        loadWard(Number(foundDistrict.value), 1, 100);
                        foundWard !== undefined && setWard(Number(foundWard.value));
                    }
                    let listPrefix = result.data.filter(x => x.code.substring(0, 2) === "bi");
                    setListPrefix(listPrefix)
                }
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const getPaymentMethod = () => {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let data = result.data.map(d => ({ ...d, key: d.ma.toString(), value: d.ten }))
                setLstPaymentType(data)
                let state = dynamicState;
                state["lstDateFormat"] = lstDateFormat;
                state["lstTimeFormat"] = lstTimeFormat;
                state["lstPaymentType"] = data;
                setDynamicState(state);
            }
        }).catch(err => {
        })
    }

    const UpdateThongTinSysConfig = (key, value) => {
        Actions.setLoading(true);
        const data = {
            key: key,
            value
        }
        RoleService.UpdateThongTinSysConfig(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                SystemConfig();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const renderRecord = (record) => {
        let content = '';
        switch (record.dataType) {
            case 'inputnumber':
                content = (
                    <InputNumber defaultValue={+record.value} onChange={(e) => {
                        if (task) clearTimeout(task)
                        task = setTimeout(() => {
                            UpdateThongTinSysConfig(record.key, e.toString());
                        }, 500)
                    }}></InputNumber>
                )
                break;
            case 'switch':
                content = (
                    <Switch defaultChecked={record.value.toLowerCase() == "true"} onChange={(e) => {
                        UpdateThongTinSysConfig(record.key, e.toString());
                    }}>
                    </Switch>
                )
                break;
            case 'select':
                content = (
                    <Select
                        className="w-100"
                        value={record.value}
                        onChange={(e) => {
                            if (task) clearTimeout(task)
                            task = setTimeout(() => {
                                UpdateThongTinSysConfig(record.key, e.toString());
                            }, 500)
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {dynamicState[record.option] && dynamicState[record.option].map((row, index) => {
                            return (
                                <Option key={index} value={row.key}>{row.value}</Option>
                            )
                        })}
                    </Select>
                )
                break;
            case "datetime":
                content = (
                    <DatePicker locale={viVN} className="w-100" format={FORMAT_TIME_DATE_FULL} showTime defaultValue={record.value ? moment(record.value) : null}
                        onChange={(e) => {
                            UpdateThongTinSysConfig(record.key, e);
                        }}></DatePicker>
                )
                break;
            default:
                content = (
                    <Input defaultValue={record.value} onChange={(e) => {
                        if (task) clearTimeout(task)
                        task = setTimeout(() => {
                            UpdateThongTinSysConfig(record.key, e.target.value.toString());
                        }, 500)
                    }}></Input>
                )
        }
        return (
            <div>
                <div>{record.title}
                    <CopyToClipboard text={record.script ?? ""}
                        onCopy={() => notification.success({ message: "Sao chép thành công." })}>
                        <Tooltip title="Bấm để copy câu lệnh insert trường dữ liệu này">
                            <i className='fas fa-code ml-2 text-color' />
                        </Tooltip>
                    </CopyToClipboard>
                    {record.description &&
                        <Tooltip title={record.description}>
                            <span className='ml-1'><i className="fas fa-info-circle"></i></span>
                        </Tooltip>
                    }
                </div>
                <div>{content}</div>
            </div>
        )
    }

    const loadCity = (pageNumber, pageSize) => {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setLstCity(result.data);
                setLstDistrict([]);
                setLstWard([]);
            }
        }).catch(err => { })
    }

    const loadDistrict = (id, pageNumber, pageSize) => {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setLstDistrict(result.data);
                setLstWard([]);
            }
            else {
                setLstDistrict([]);
                setLstWard([]);
            }
        }).catch(err => { })
    }

    const loadWard = (id, pageNumber, pageSize) => {
        AdministrativeService.GetWardByDistrictId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setLstWard(result.data);
            }
            else setLstWard([]);
        }).catch(err => { })
    }

    const saveDefaultAddress = () => {
        if (validator.current.allValid()) {
            const data = [
                {
                    key: "city",
                    value: city.toString(),
                },
                {
                    key: "district",
                    value: district.toString(),
                },
                {
                    key: "ward",
                    value: ward.toString(),
                },
            ]
            RoleService.UpdateSysConfigMulti(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" });
                } else {
                    notification.warning({ message: "Không thể cập nhật, vui lòng thử lại sau" });
                }
            })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    return (
        <Fragment>
            <div className={'containerChilderWrapper'}>
                <HeaderModal title="Cấu hình hệ thống" />
                <div className='w-100 mt-2 text-right'>
                    <button className={`${BUTTON.THEME}`} onClick={() => setVsbCreate(true)}>Tạo mới</button>
                </div>
                {lstConfigure && lstConfigure.filter(x => x.dataType === 'title').map((row, index) => {
                    return (
                        <div className='my-3 border-bottom'>
                            <div key={index} className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                                <h5 className='font-weight-bold'>{row.title}
                                    <CopyToClipboard text={row.script ?? ""}
                                        onCopy={() => notification.success({ message: "Sao chép thành công." })}>
                                        <Tooltip title="Bấm để copy câu lệnh insert trường dữ liệu này">
                                            <i className='fas fa-code ml-2 text-color' />
                                        </Tooltip>
                                    </CopyToClipboard>
                                </h5>
                            </div>
                            <div className='row mb-2'>
                                {lstConfigure && lstConfigure.filter(x => x.code.substring(0, 2) === row.code && x.dataType !== 'title').map((row, index) => {
                                    return (
                                        <div className="col-3" key={index}>
                                            {renderRecord(row)}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
                <div className='my-3 border-bottom'>
                    <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                        <h5 className='font-weight-bold'>Cấu hình địa chỉ mặc định</h5>
                    </div>
                    <div className='row mb-2' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "left" }}>
                        <div className="col-lg-3 mt-1">
                            <label className='required-field'>Tỉnh/TP</label>
                            <Select
                                className='w-100'
                                value={city}
                                onChange={(e) => {
                                    setCity(e);
                                    setDistrict(null);
                                    setWard(null);
                                    loadDistrict(e);
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstCity && lstCity.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                            {validator.current.message("Tỉnh, thành", city, "selection")}
                        </div>
                        <div className="col-lg-3 mt-1">
                            <label className='required-field'>Quận/Huyện</label>
                            <Select
                                className='w-100'
                                value={district}
                                showSearch
                                onChange={(e) => {
                                    setDistrict(e);
                                    loadWard(e, 1, 100);
                                    setWard(null);
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstDistrict && lstDistrict.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                            {validator.current.message("Quận, huyện", district, "selection")}
                        </div>
                        <div className="col-lg-3 mt-1">
                            <label className='required-field'>Phường/Xã</label>
                            <Select
                                className='w-100'
                                value={ward}
                                onChange={(e) => {
                                    setWard(e);
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstWard && lstWard.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                            {validator.current.message("Phường, xã", ward, "selection")}
                        </div>
                        <div className="col-auto mt-1 d-flex">
                            <button className='btn btn-success' style={{ alignSelf: "end" }} onClick={() => saveDefaultAddress()}>Lưu</button>
                        </div>
                    </div>
                </div>
                <div className='my-3 border-bottom'>
                    <BillFormatConfig data={listPrefix} />
                </div>
            </div>
            <Modal
                open={vsbCreate}
                onCancel={() => setVsbCreate(false)}
                destroyOnClose
            >
                <Create
                    onClose={() => setVsbCreate(false)}
                    onSuccess={() => {
                        SystemConfig();
                    }}
                    listConfigureTitle={lstConfigure.filter(x => x.dataType === 'title')}
                />
            </Modal>
        </Fragment>
    )
}