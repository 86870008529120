import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../../components/notify';
import { Button, Checkbox, Input, List, Popover, Select, notification, InputNumber, Drawer, Tag, Spin, Tooltip, Popconfirm } from 'antd';
import { BUTTON } from '../../../configureHIS/common/constants';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { getPageSetting, groupBy, isNullOrEmpty, isnotnull, renumber } from '../../../libs/util';
import * as InvoiceService from '../../../services/invoiceService';
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import Handlebars from 'handlebars';
import handlebarHelper from "../../../libs/handlebarHelper";
import * as PrintService from '../../../services/printService';
import SmallShowSummaryAmount from '../components/smallshowsummaryamount';
import Preview from './preview';
import ActionRefund from '../components/actionrefund';
import * as Constants from "../../../configureHIS/common/constants";
import "./refund.scss";
import { IsSuperAdmin, roundTo } from '../../../commons/utils';
import NoContent from '../../../components/loading/noContent';
import { TransactionOutlined } from '@ant-design/icons';

const ALLOW_TO_CHANGE_MEDICINE_QUANTITY = false;
var defaultMethod = null;
const classNameListItem = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";

const Refund = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reloadInfo: (pid = null, mid = null) => {
            setIsAtLeastOneCheck(false);
            setListReceipt([]);
            setIsCheckAll(false);
            if (pid && mid) getListPaid(pid, mid);
            // getBillList();
            notiRef && notiRef.current && notiRef.current.Close();
            notiRefSec && notiRefSec.current && notiRefSec.current.Close();
        },
    }));

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const notiRef = useRef();
    const notiRefSec = useRef();
    const firstRender = useRef(true);
    const [ghiChu, setGhiChu] = useState();
    const [listMethod, setListMethod] = useState([]);
    const [refundMode, setRefundMode] = useState();
    const [listReceipt, setListReceipt] = useState([]);
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [visiblePopupSec, setVisiblePopupSec] = useState(false);
    const [disableButtonRefund, setDisableButtonRefund] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isAtLeastOneCheck, setIsAtLeastOneCheck] = useState(false);
    const [visiblePreview, setVisiblePreview] = useState(false);
    const [selectedReceipt, setSelectedReceipt] = useState([]);
    const [selectedReceiptNumber, setSelectedReceiptNumber] = useState();
    const [listBill, setListBill] = useState([]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            getListPaid();
        }
    }, []);

    useEffect(() => {
        if (!firstRender.current) {
            getListPaid();
        }
    }, [props.billType])

    useEffect(() => {
        if (defaultMethod != null) setRefundMode(defaultMethod);
    }, [defaultMethod])

    const reloadInfo = () => {
        setIsAtLeastOneCheck(false);
        setListReceipt([]);
        setIsCheckAll(false);
        getListPaid(props.patientCode, props.managementId);
        // getBillList();
        notiRef && notiRef.current && notiRef.current.Close();
        notiRefSec && notiRefSec.current && notiRefSec.current.Close();
    }

    //itemData là item nằm trong listServices hoặc listMedis. 
    //Item trong listMedis là cái phiếu trước nha. Phiếu thuốc hoặc phiếu vật tư á, có prescriptionNumber là key. 
    //subMedis nằm trong listMedis mới là item detail cụ thể (tên, Sl,...)
    const onSelectDeselect = (reptIndex, group, itemIndex, isSelected, itemData) => {
        if (reptIndex != undefined && reptIndex >= 0) {
            let list = listReceipt;
            let listServices = list[reptIndex].listServices;
            let listMedis = list[reptIndex].listMedis;
            if (itemIndex >= 0) {
                if (group == "service") {
                    let foundIndex = listServices.findIndex(x => x.name == itemData.name);
                    if (foundIndex && foundIndex >= 0);
                    listServices[foundIndex].isChecked = listServices[foundIndex].isRefunded ? false : isSelected;
                } else if (group == "medicine") {
                    let firstPrescription = listMedis.find(x => x.prescriptionNumber == itemData.prescriptionNumber)//listMedis[0];
                    if (firstPrescription.subMedis && firstPrescription.subMedis.length > 0) {
                        firstPrescription.subMedis[itemIndex].isChecked = firstPrescription.subMedis[itemIndex].isRefunded ? false : isSelected;
                        firstPrescription.subMedis[itemIndex].quantityRefund = firstPrescription.subMedis[itemIndex].maxCount;
                    }
                }
                setListReceipt(list);
                handleSetIndeterminate();
            }
            forceUpdate();
        }
    }

    const handleVisiblePopupRefund = (isOpen) => {
        notiRefSec && notiRefSec.current && notiRefSec.current.Close();
        if (!isOpen) {
            setRefundMode();
            setGhiChu();
        } else {
            notiRef && notiRef.current && notiRef.current.Close();
            getListPaymentType();
        }
    }

    const getListPaymentType = () => {
        VPService.GetListPaymentType().then((res) => {
            // 2023.08.14: listMethod trong paid này thấy chủ yếu là truyền vào popup hoàn trả. 
            // Mà popup hoàn trả dùng component actionrefund, mà actionrefund thì cứ truyền listMethod, trong đó xử lý filter CARD THẺ các kiểu lại rồi.
            // Nên không cần cái foundListReceiptNumber, foundPaymentType ở đây nữa.
            // const list = listReceipt;
            // let foundListReceiptNumber = list?.filter(x => (x.listMedis?.filter(y => (y.subMedis?.filter(z => z.isChecked && z.isRefunded == false) ?? []).length > 0).length > 0) || (x.listServices?.filter(y => y.isChecked && y.isRefunded == false) ?? []).length > 0).map(x => x.receiptNumber);
            let listPaymentType = res.data;
            // if (isnotnull(list)) {
            //     let foundPaymentType = [... new Set(list.filter(x => foundListReceiptNumber.includes(x.receiptNumber)).map(x => (x.paymentType)))];
            //     if (!foundPaymentType.includes(Constants.PAYMENTTYPE.CARD) && !foundPaymentType.includes(Constants.PAYMENTTYPE.DEBT))
            //         listPaymentType = listPaymentType.filter(x => x.ma != Constants.PAYMENTTYPE.CARD && x.ma != Constants.PAYMENTTYPE.DEBT);
            //     else listPaymentType = listPaymentType.filter(x => foundPaymentType.includes(x.ma));
            // }
            setListMethod(listPaymentType);
        }).catch((err) => { })
    }

    const calcRefund = () => {
        let val = 0;
        let list = listReceipt;
        let totalSer = 0;
        let totalMedi = 0;
        const totalLoyalty = calcSpecial("CARD") + calcSpecial("CTKM");
        const totalDiscount = calcSpecial("DISCOUNT");
        list.forEach(item => {
            let selectedSer = item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
            let selectedMedi = item.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? [];
            selectedMedi = selectedMedi.filter(x => x.subMedis && x.subMedis.length > 0);
            if (selectedSer.length > 0) totalSer = selectedSer.reduce((tmp, cur) => tmp + cur.total, 0);
            else totalSer = 0;
            if (selectedMedi.length > 0) {
                let sumMediRefund = 0;
                // sumMediRefund = selectedMedi.reduce((tmp, cur) => tmp + cur.refund, 0);
                totalMedi = selectedMedi.reduce((tmp, cur) => tmp + cur.subMedis.reduce((sumSub, curSub) => sumSub + ((curSub.quantityRefund ?? curSub.quantity) * curSub.price), 0) - sumMediRefund, 0);
            }
            else totalMedi = 0;
            val += totalSer + totalMedi;
        });
        val = val - totalLoyalty - totalDiscount;
        return roundTo(Number(val));
    }

    const calcTotal = () => {
        let val = 0;
        let list = listReceipt;
        let totalSer = 0;
        let totalMedi = 0;
        list.forEach(item => {
            let selectedSer = item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
            let selectedMedi = item.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? [];
            selectedMedi = selectedMedi.filter(x => x.subMedis && x.subMedis.length > 0);
            if (selectedSer.length > 0) totalSer = selectedSer.reduce((tmp, cur) => tmp + cur.total, 0);
            else totalSer = 0;
            if (selectedMedi.length > 0)
                totalMedi = selectedMedi.reduce((tmp, cur) => tmp + cur.subMedis.reduce((sumSub, curSub) => sumSub + curSub.maxCount * curSub.price, 0), 0);
            else totalMedi = 0;
            val += totalSer + totalMedi;
        });
        return roundTo(Number(val));
    }

    const calcSpecial = (type = "CTKM") => {
        let total = 0;
        let list = listReceipt;
        let foundListReceiptNumber = list?.filter(x => (x.listMedis?.filter(y => (y.subMedis?.filter(z => z.isChecked && z.isRefunded == false) ?? []).length > 0).length > 0) || (x.listServices?.filter(y => y.isChecked && y.isRefunded == false) ?? []).length > 0).map(x => x.receiptNumber);
        foundListReceiptNumber && foundListReceiptNumber.forEach(reNum => {
            let foundDetail = list.find(x => x.receiptNumber == reNum);
            let errMes = `Hóa đơn ${reNum}: Tất cả dịch vụ được hưởng giảm giá phải được hoàn trả cùng lúc`;
            // if (foundDetail.listMedis?.filter(x => x.subMedis && x.subMedis.length > 0).length > 0) {
            //2023.08.09: đóng cho hoàn trả thuốc để test #3987
            // if (foundDetail.listMedis?.filter(x => (x.subMedis.filter(c => c.isChecked) ?? []).length > 0).length > 0) {
            //     errMes=`Hiện tại không được phép hoàn trả thuốc & vật tư.`;
            //     notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMes, NotifyStatus.Warning);
            //     return 0;
            // }
            if ((foundDetail.resume?.discount ?? 0) > 0) {
                if ((foundDetail.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? []).concat(foundDetail.listMedis?.filter(x => (x.subMedis.filter(c => c.isChecked) ?? []).length > 0) ?? []).length != (foundDetail.listServices ?? []).concat(foundDetail.listMedis ?? []).length) {
                    notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMes, NotifyStatus.Warning);
                    return 0;
                }
            }
            if ((foundDetail.resume?.loyalty ?? 0) > 0) {
                if ((foundDetail.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? []).concat(foundDetail.listMedis?.filter(x => x.isChecked) ?? []).length != (foundDetail.listServices ?? []).concat(foundDetail.listMedis ?? []).length) {
                    notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMes, NotifyStatus.Warning);
                    return 0;
                }
            }
            if (type.toLocaleUpperCase() == "CTKM")
                total += foundDetail.resume.loyalty;
            // else if (type.toLocaleUpperCase() == "CARD")
            //     total += foundDetail.resume.loyalty;
            else if (type.toLocaleUpperCase() == "DISCOUNT")
                total += foundDetail.resume.discount;
            else if (type.toLocaleUpperCase() == "TOTAL")
                total += foundDetail.resume.total;
        });
        return roundTo(Number(total));
    }

    const getListPaid = (pid = props.patientCode, mid = props.managementId, billType = props.billType) => {
        if (!isNullOrEmpty(mid)) {
            Actions.setLoading(true);
            InvoiceService.getListPaid(pid, mid, billType).then(res => {
                if (res.isSuccess) {
                    setListReceipt(res.data);
                    getBillList(mid);
                }
            }).catch(err => {
            }).finally(() => {
                Actions.setLoading(false);
            })
        }
    }

    const handleRefund = () => {
        let errMsg = "Vui lòng chọn hình thức hoàn trả";
        if (isNullOrEmpty(refundMode)) {
            notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
            return;
        }
        const list = listReceipt;
        let selectedSer = [];
        let selectedMedi = [];
        list.forEach(item => {
            selectedSer = selectedSer.concat(item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? []);
            selectedMedi = selectedMedi.concat(item.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? []);
        });
        //2023.07.25: tạm không cho hoàn trả thuốc & vật tư.
        //2023.08.09: đóng cho hoàn trả thuốc để test #3987
        // if (selectedMedi.filter(x => x.subMedis && x.subMedis.length > 0).length > 0) {
        //     errMsg = "Hiện tại không được phép hoàn trả thuốc & vật tư.";
        //     notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
        //     return;
        // }
        if (selectedSer.concat(selectedMedi).length == 0) {
            errMsg = "Vui lòng chọn ít nhất 1 dịch vụ / thuốc / vật tư (Không bao gồm đã hoàn trả)";
            notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
            return;
        }
        if (isNullOrEmpty(ghiChu)) {
            errMsg = "Vui lòng nhập ghi chú";
            notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
            return;
        }
        notiRefSec && notiRefSec.current && notiRefSec.current.Close();

        let refundList = [];
        list.forEach(item => {
            let itemNew = item;
            itemNew.listServices = itemNew.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
            let selectedMedi = itemNew.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? [];
            itemNew.listMedis = selectedMedi.filter(x => x.subMedis && x.subMedis.length > 0);
            if (itemNew.listServices.concat(itemNew.listMedis).length > 0) refundList.push(itemNew);
        });
        const data = {
            "RefundList": refundList,
            "Mode": refundMode,
            "Comment": ghiChu,
            "PatientCode": props.patientCode,
            "ManagermentId": props.managementId,
            "TotalRefund": calcRefund(),
        };

        InvoiceService.refund(data).then(res => {
            if (res.isSuccess) {
                let soHoaDon = refundList[0] ? `#${refundList[0].receiptNumber}` : "đã thanh toán";
                let notiInfo = visiblePopup ? "Hoàn trả thành công" : `Hoàn trả các dịch vụ trong phiếu ${soHoaDon} thành công`;
                notiRefSec && notiRefSec.current && notiRefSec.current.Show(notiInfo, NotifyStatus.Success);
                if (!isNullOrEmpty(res.data['SoPhieuYeuCauHoanTra']))
                    Actions.openMessageDialog("Lưu ý", `Có dịch vụ thuộc phiếu thanh toán trước ngày hôm nay. Hệ thống đã tạo phiếu yêu cầu hoàn trả ${res.data['SoPhieuYeuCauHoanTra']}`)
                if (getPageSetting("invoicePreview") && !isNullOrEmpty(res.data['SoPhieuHoanTra'])) printRefund(res.data['SoPhieuHoanTra']);
                // if (getPageSetting("invoicePreview")) printRefund(res.data);
                setVisiblePopup(false);
                setVisiblePopupSec(false);
                setRefundMode();
                setGhiChu();
            } else {
                notiRefSec && notiRefSec.current && notiRefSec.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {
            notiRefSec && notiRefSec.current && notiRefSec.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            getListPaid();
            // getBillList();
            setIsAtLeastOneCheck(false);
            setIsCheckAll(false);
        })
    }

    const printReceipt = (soHoaDon, ngayChiDinh, maQuanLy) => {
        Actions.setLoading(true);
        let banLe = false;
        PrintService.PrintPayment(soHoaDon, moment(ngayChiDinh).format('YYYY-MM-DD'), maQuanLy, banLe).then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                return;
            } else {
                const template = Handlebars.compile(result.item.template);
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const selectAllBillSer = (loadListPayment, receiptNumber, check = true, all = false) => {
        if (!isNullOrEmpty(receiptNumber)) {
            if (loadListPayment) getListPaymentType();
            const list = listReceipt;
            list.forEach(x => {
                if (x.receiptNumber == receiptNumber) {
                    if (x.listServices && x.listServices.length > 0) {
                        x.listServices.forEach(s => {
                            s.isChecked = s.isRefunded ? false : check;
                        })
                    }
                    //2023.07.26: tạm không cho hoàn trả thuốc
                    //2023.08.09: mở ra cho hoàn trả thuốc để test #3987
                    if (x.listMedis && x.listMedis.length > 0) {
                        x.listMedis.forEach(s => {
                            if (s.subMedis && s.subMedis.length > 0) {
                                s.subMedis.forEach(k => {
                                    k.isChecked = Number(k.maxCount) <= 0 ? false : check;
                                    k.quantityRefund = k.maxCount;
                                })
                            }
                        })
                    }
                } else if (!all) {
                    if (x.listServices && x.listServices.length > 0) {
                        x.listServices.forEach(s => {
                            s.isChecked = false;
                        })
                    }
                    //2023.07.26: tạm không cho hoàn trả thuốc
                    //2023.08.09: mở ra cho hoàn trả thuốc để test #3987
                    if (x.listMedis && x.listMedis.length > 0) {
                        x.listMedis.forEach(s => {
                            if (s.subMedis && s.subMedis.length > 0) {
                                s.subMedis.forEach(k => {
                                    k.isChecked = false;
                                    k.quantityRefund = k.maxCount;
                                })
                            }
                        })
                    }
                }
            })
            setListReceipt(list);
            if (!check) {
                notiRefSec && notiRefSec.current && notiRefSec.current.Close();
                setRefundMode();
                setGhiChu();
            } else {
                notiRef && notiRef.current && notiRef.current.Close();
            }
            handleSetIndeterminate();
            forceUpdate();
        }
    }

    const printRefund = (refundNumber, managementId) => {
        Actions.setLoading(true);
        PrintService.PrintRefund(refundNumber).then((result) => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Actions.DoPrint(template(result.data));
                }
            else Actions.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const refundPopoverContent = () => {
        const list = listReceipt;
        let selectedSer = [];
        let selectedMedi = [];
        list.forEach(item => {
            selectedSer = selectedSer.concat(item.listServices?.map(x => ({ ...x, receiptNumber: item.receiptNumber })).filter(x => x.isChecked && x.isRefunded == false) ?? []);
            selectedMedi = selectedMedi.concat(item.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? []);
        });
        let listDetail = [];
        let listMaster = [];
        // model của service của listpaid
        // "idChiDinh": "230809154037465006",
        // "name": "Nội Soi Đại Tràng (Sau Khi Có Kết Quả Siêu Âm/Xét Nghiệm)",
        // "status": "Chưa thực hiện",
        // "isChecked": true,
        // "isRefunded": false,
        // "refundBill": null,
        // "price": 500000,
        // "discount": 0,
        // "loyalty": 0,
        // "card": 0,
        // "total": 500000,
        // "groupName": "Chẩn đoán chức năng",
        // "voucher": null,
        // "cardId": null,
        // "paymentType": 2,
        // "isHasDiscount": true,
        // "subServices": [],
        // "unit": null,
        // "quantity": null,
        // "chiDinhComboId": null,
        // "cashier": "MTC",
        // "paymentTypeName": "Chuyển khoản",
        // "tag": "ok",
        // "paidDate": "2023-08-09 08:44:41Z",
        // "receiptNumber": 11411
        selectedSer.forEach(x => {
            let itemDetail = {
                "billNumber": x.receiptNumber,
                // "code": "2-1508",
                "name": x.name,
                "type": 0, // enum: SERVICE = 0 | MEDICALSUPPLIES = 1 | MEDICINE = 2
                "typeText": "DỊCH VỤ",
                "isCombo": x.subServices.length,
                "isChild": false,
                "isRefund": x.isRefunded,
                "idChiDinh": x.idChiDinh, // Là id của chidinh nếu là dịch vụ. Id của d_ngtrull nếu là vật tư & thuốc
                "quantity": 1, // Số lượng, nếu là thuốc hay vật tư
                "price": x.price,
                "discount": x.discount,
                "discountPercent": null,
                "loyalty": x.loyalty,
                "discountCard": x.card,
                "total": x.total,
                "listChild": //[], // Này là như listDetail luôn. Nếu là combo thì có cái này
                    x.subServices.map(c => {
                        return {
                            "billNumber": c.receiptNumber,
                            // "code": "2-1508",
                            "name": c.name,
                            "type": 0, // enum: SERVICE = 0 | MEDICALSUPPLIES = 1 | MEDICINE = 2
                            "typeText": "DỊCH VỤ",
                            "isCombo": false,
                            "isChild": true,
                            "isRefund": x.isRefunded,
                            "idChiDinh": x.idChiDinh, // Là id của chidinh nếu là dịch vụ. Id của d_ngtrull nếu là vật tư & thuốc
                            "quantity": 1, // Số lượng, nếu là thuốc hay vật tư
                            "price": 0,
                            "discount": 0,
                            "discountPercent": 0,
                            "loyalty": 0,
                            "discountCard": 0,
                            "total": 0,
                        }
                    })
            }
            listDetail.push(itemDetail);
            if (listMaster.findIndex(master => master.billNumber == x.receiptNumber) < 0) {
                let bill = listBill.find(master => master.billNumber == x.receiptNumber);
                listMaster.push(bill);
            }
        })
        // model của medis của listpaid
        // {
        //   isMedicine: true,
        //   prescriptionNumber: '230809154409012204',
        //   name: null,
        //   isChecked: false,
        //   isRefunded: false,
        //   refundBill: null,
        //   total: 20000,
        //   discount: 769.2307692307693,
        //   revenue: 19230.76923076923,
        //   refund: 0,
        //   subMedis: [
        //     {
        //       name: 'THUỐC TEST 1307',
        //       id: 135477,
        //       quantity: 1,
        //       quantityRefund: 1,
        //       price: 20000,
        //       listRefundNumber: '',
        //       isRefunded: false,
        //       maxCount: 1,
        //       isChecked: true
        //     }
        //   ],
        //   receiptNumber: 11411
        // }
        selectedMedi.forEach(mediMaster => {
            let listSubMedisChecked = mediMaster.subMedis.filter(x => x.isChecked) ?? [];
            listSubMedisChecked.forEach(mediDetail => {
                let itemDetail = {
                    "billNumber": mediMaster.receiptNumber,
                    // "code": "2-1508",
                    "idVienPhi": mediDetail.id,
                    "name": mediDetail.name,
                    "type": mediMaster.isMedicine ? 2 : 1, // enum: SERVICE = 0 | MEDICALSUPPLIES = 1 | MEDICINE = 2
                    "typeText": "DỊCH VỤ",
                    "isCombo": false,
                    "isChild": false,
                    "isRefund": mediDetail.isRefunded,
                    "idChiDinh": mediMaster.prescriptionNumber, // Là id của chidinh nếu là dịch vụ. Id của d_ngtrull nếu là vật tư & thuốc
                    "quantity": mediDetail.quantity, // Số lượng, nếu là thuốc hay vật tư
                    // Số lượng hoàn trả. Trong component actionrefund hiểu quantityRefund là SL đã được hoàn trả trước đó (để sau này có thể mở ra cho hoàn trả 1 phần số lượng. Còn hiện tại là phải hoàn trả hết).
                    // Nhưng trong component paid này có 1 số nơi set lại quantityRefund = maxcount, mà maxcount hình như là quantity gì đó.
                    // Nên chỗ này check nếu medis này đã hoàn trả thì set cứng quantityRefund = quantity, còn chưa thì = 0
                    "quantityRefund": mediDetail.isRefunded ? mediDetail.quantity : 0,
                    "price": mediDetail.price,
                    "discount": mediDetail.discount,
                    "discountPercent": null,
                    "loyalty": 0,
                    "discountCard": 0,
                    "total": mediDetail.price * mediDetail.quantity,
                    "listChild": [], // Này là như listDetail luôn. Nếu là combo thì có cái này
                    // y.quantity - y.quantityRefund;
                }
                listDetail.push(itemDetail);
                if (listMaster.findIndex(master => master.billNumber == mediMaster.receiptNumber) < 0) {
                    let bill = listBill.find(x => x.billNumber == mediMaster.receiptNumber);
                    listMaster.push(bill);
                }
            })
        })
        return (
            <ActionRefund
                managementId={props.managementId}
                patientId={props.patientCode}
                listDetail={listDetail}
                listMaster={listMaster}
                listMethod={listMethod}
                refundSuccess={() => {
                    setVisiblePopup(false);
                    setIsCheckAll(false);
                    setIsAtLeastOneCheck(false);
                    getListPaid(props.patientCode, props.managementId);
                }}
                refundError={() => { }}
            // onReload={() => {GetList(0, listResult.length); 
            //     setShowHideRefundPopover(billSelected.billNumber, false);
            // }}
            ></ActionRefund>
        )
        // return (
        //     <div style={{ width: 600 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
        //         <Notify ref={notiRefSec} />
        //         <div className='w-100'>
        //             {getDanhSachCanHoantra()}
        //         </div>
        //         <div className='w-100 mt-2'>
        //             {calcTotal() > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#576cdf", height: 40 }}>
        //                 <label style={{ textTransform: "uppercase" }}>Thành tiền</label>
        //                 <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcTotal().toLocaleString("en-US")}</label>
        //             </div>}
        //             {calcSpecial("DISCOUNT") > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#e4a033", height: 40 }}>
        //                 <label style={{ textTransform: "uppercase" }}>Miễn giảm</label>
        //                 <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcSpecial("DISCOUNT").toLocaleString("en-US")}</label>
        //             </div>}
        //             {calcSpecial("CTKM") > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#57a127", height: 40 }}>
        //                 <label style={{ textTransform: "uppercase" }}>CTKM</label>
        //                 <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcSpecial("CTKM").toLocaleString("en-US")}</label>
        //             </div>}
        //             {calcSpecial("CARD") > 0 && <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#57a127", height: 40 }}>
        //                 <label style={{ textTransform: "uppercase" }}>Thẻ trả trước</label>
        //                 <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcSpecial("CARD").toLocaleString("en-US")}</label>
        //             </div>}
        //             <div className='d-flex justify-content-between align-items-center p-2 text-white' style={{ backgroundColor: "#b13431", height: 40 }}>
        //                 <label style={{ textTransform: "uppercase" }}>Hoàn trả</label>
        //                 <Select value={refundMode}
        //                     placeholder="Chọn phương thức"
        //                     onChange={(e) => {
        //                         setRefundMode(e);
        //                     }}
        //                     defaultValue={getDefaultMedthod()}
        //                     style={{ width: "60%" }}>
        //                     {
        //                         listMethod && listMethod.map((medthod, index) => {
        //                             return (
        //                                 <Select.Option key={`${index}-${medthod.ma}`} value={medthod.ma}>{medthod.ten}</Select.Option>
        //                             )
        //                         })
        //                     }
        //                 </Select>
        //                 <label><span style={{ textDecoration: "underline" }} className='mr-1'>đ</span>{calcRefund().toLocaleString("en-US")}</label>
        //             </div>
        //             <div className='mt-2'>
        //                 <label className='required-field mita-title'>Ghi chú</label>
        //                 <Input.TextArea placeholder='Nhập thông tin ghi chú' value={ghiChu} onChange={(e) => { setGhiChu(e.target.value) }} autoSize></Input.TextArea>
        //             </div>
        //             <div className='d-flex justify-content-end mt-2'>
        //                 <Button style={{ textTransform: "uppercase" }}
        //                     className={disableButtonRefund ? BUTTON.SECONDARY : BUTTON.INFO}
        //                     disabled={disableButtonRefund}
        //                     id={"btn-refund"}
        //                     onClick={(e) => {
        //                         e.stopPropagation();
        //                         handleRefund();
        //                     }}>Hoàn trả</Button>
        //             </div>
        //         </div>
        //     </div>
        // )
    }

    // const getDanhSachCanHoantra = () => {
    //     const list = listReceipt;
    //     let selectedSer = [];
    //     let selectedMedi = [];
    //     list.forEach(item => {
    //         selectedSer = selectedSer.concat(item.listServices?.map(x => ({ ...x, receiptNumber: item.receiptNumber })).filter(x => x.isChecked && x.isRefunded == false) ?? []);
    //         selectedMedi = selectedMedi.concat(item.listMedis?.map(x => ({ ...x, subMedis: x.subMedis.filter(m => !m.isRefunded && m.isChecked), receiptNumber: item.receiptNumber })) ?? []);
    //     });
    //     let listReturn = [];
    //     selectedSer.length > 0 && listReturn.push(selectedSer.map((x, index) => {
    //         return (
    //             <div className='pl-2 mt-1 border-bottom d-flex justify-content-between' key={`ser-${index}`}>
    //                 <label className='mita-label' style={{ width: "40%" }}>{x.name}</label>
    //                 <div className='d-flex' style={{ textAlign: 'end' }}>
    //                     <div>
    //                         <label className='mr-2'>* Đơn giá</label>
    //                         <label>{Number(x.price ?? 0).toLocaleString("en-US")}</label>
    //                     </div>
    //                     {x.discount && Number(x.discount) > 0 ?
    //                         <div className='ml-2'>
    //                             <label className='mr-2'>* Miễn giảm</label>
    //                             <label className='text-warning'>{roundTo(Number(x.discount ?? 0)).toLocaleString("en-US")}</label>
    //                         </div> : ""}
    //                     {x.loyalty && Number(x.loyalty) > 0 ?
    //                         <div className='ml-2'>
    //                             <label className='mr-2'>* CTKM</label>
    //                             <label className='text-success'>{roundTo(Number(x.loyalty ?? 0)).toLocaleString("en-US")}</label>
    //                         </div> : ""}
    //                     {x.card && Number(x.card) > 0 ?
    //                         <div className='ml-2'>
    //                             <label className='mr-2'>Thẻ</label>
    //                             <label className='text-success'>{roundTo(Number(x.card ?? 0)).toLocaleString("en-US")}</label>
    //                         </div> : ""}
    //                     <div className='ml-2'>
    //                         <label className='mr-2'>* Thành tiền</label>
    //                         <label className='text-info'>{roundTo(Number(x.total ?? 0)).toLocaleString("en-US")}</label>
    //                     </div>
    //                 </div>
    //                 {/* <ul id="mita-list-style" style={{ listStylePosition: "inside", display: "flex", justifyContent: "end", textAlign: "end", width: "100%" }}>
    //                     <li>
    //                         <label className='mr-2'>Đơn giá</label>
    //                         <label>{Number(x.price ?? 0).toLocaleString("en-US")}</label>
    //                     </li>
    //                     {x.discount && Number(x.discount) > 0 ? <li style={{ marginLeft: 20 }}>
    //                         <label className='mr-2'>Miễn giảm</label>
    //                         <label style={{ color: "#e4a033" }}>{roundTo(Number(x.discount ?? 0)).toLocaleString("en-US")}</label>
    //                     </li> : ""}
    //                     {x.loyalty && Number(x.loyalty) > 0 ? <li style={{ marginLeft: 20 }}>
    //                         <label className='mr-2'>CTKM</label>
    //                         <label style={{ color: "#57a127" }}>{roundTo(Number(x.loyalty ?? 0)).toLocaleString("en-US")}</label>
    //                     </li> : ""}
    //                     {x.card && Number(x.card) > 0 ? <li style={{ marginLeft: 20 }}>
    //                         <label className='mr-2'>Thẻ</label>
    //                         <label style={{ color: "#57a127" }}>{roundTo(Number(x.card ?? 0)).toLocaleString("en-US")}</label>
    //                     </li> : ""}
    //                     <li style={{ marginLeft: 20 }}>
    //                         <label className='mr-2'>Thành tiền</label>
    //                         <label style={{ color: "#576cdf" }}>{roundTo(Number(x.total ?? 0)).toLocaleString("en-US")}</label>
    //                     </li>
    //                 </ul> */}
    //             </div>
    //         )
    //     }));
    //     if (selectedMedi.length > 0) {
    //         selectedMedi.forEach((x, pIndex) => {
    //             listReturn.push(x.subMedis.map((y, cIndex) => {
    //                 return (
    //                     <div className='pl-2 d-flex justify-content-between mt-1' key={`med-${cIndex}`}>
    //                         <label className='mita-label' style={{ width: "40%" }}>{y.name}</label>
    //                         <Tooltip title={`Có thể hoàn trả tối đa ${y.maxCount}`} color='#00c254'>
    //                             <div className='d-flex flex-row align-items-center'>
    //                                 <span>SL</span>
    //                                 <InputNumber disabled={!ALLOW_TO_CHANGE_MEDICINE_QUANTITY} value={y.quantityRefund} className='ml-1' min={1} max={y.maxCount} onChange={(quant) => { ALLOW_TO_CHANGE_MEDICINE_QUANTITY && updateQuantityRefund(x.receiptNumber, x.prescriptionNumber, cIndex, y.name, quant) }}></InputNumber>
    //                                 <span className='ml-1'>{(y.price ?? 0).toLocaleString("en-US")}</span>
    //                                 <span style={{ color: "#576cdf", marginLeft: 20 }} className='d-flex justify-content-end'>{(((y.quantityRefund ?? y.quantity) * y.price) ?? 0).toLocaleString("en-US")}</span>
    //                             </div>
    //                         </Tooltip>
    //                     </div>
    //                 )
    //             }))
    //         })
    //     }
    //     return (
    //         <Fragment>
    //             <div className='required-field mita-title'>Danh mục hoàn trả</div>
    //             {listReturn}
    //         </Fragment>
    //     );
    // }

    // const updateQuantityRefund = (receiptNumber, prescriptionNumber, cIndex, cName, newQuant) => {
    //     const list = listReceipt;
    //     let found = list.find(x => x.receiptNumber == receiptNumber);
    //     if (found != undefined && found.listMedis && found.listMedis.length > 0) {
    //         found = found.listMedis.find(x => x.prescriptionNumber == prescriptionNumber);
    //         if (found != undefined && found.subMedis.length > 0) {
    //             let foundMedIndex = found.subMedis.findIndex(x => x.name == cName);
    //             if (foundMedIndex >= 0) {
    //                 found.subMedis[foundMedIndex].quantityRefund = newQuant;
    //                 setListReceipt(list);
    //                 forceUpdate();
    //             }
    //         }
    //     }
    // }

    const onChangeSelectAll = (e) => {
        const isCheck = e.target.checked;
        let list = listReceipt;
        let all = true;
        let loadListPayment = false;
        if (list && list.length > 0) {
            list.map(x => x.receiptNumber).forEach(re => {
                selectAllBillSer(loadListPayment, re, isCheck, all);
            })
        }
        handleSetIndeterminate();
    }

    const renderPopoverRefundOrPaid = (receiptInfo, childInfo) => {
        let isRefunded = childInfo.isRefunded;
        let billNumber = isRefunded ? childInfo.refundBill : receiptInfo.receiptNumber;
        let bill = listBill.find(x => x.billNumber == billNumber);
        return (
            (bill == null || bill == undefined) ?
                <Spin tip="Đang load" size="small" /> :
                <SmallShowSummaryAmount
                    data={bill}
                    // data = {{
                    //             paymentTypeName: bill?.paymentTypeName, //Hình thức thanh toán.
                    //             paidDate: isRefunded ? childInfo.paidDate : receiptInfo.paidDate, //Ngày thanh toán / hoàn trả.
                    //             cashier: isRefunded ? childInfo.cashier : receiptInfo.cashier, //Thu ngân.
                    //             remark: isRefunded ? childInfo.tag : receiptInfo.tag, //Ghi chú.
                    //             resume: receiptInfo.resume,

                    //             total: bill?.total, //Thành tiền: tổng tiền các dịch vụ (sau khi đã giảm dịch vụ).
                    //             discount: bill?.discount, //Miễn giảm: giảm giá trên bill (giảm tay trên bill).
                    //             loyalty: bill?.loyalty, //CTKM / Thẻ: giảm giá trên bill theo CTKM / thẻ.
                    //             paid: bill?.paid, //Thành tiền = total - discount - loyalty.
                    //             refund: bill?.refund, //Hoàn trả: số tiền phải hoàn trả nếu là bill hoàn trả.
                    //             revenue: bill?.revenue, //Thanh toán: số tiền phải thanh toán nếu là bill thanh toán.
                    //         }}
                    // managementId={childInfo.managementId}
                    // patientId={props.patientCode}
                    // billNumber={isRefunded ? childInfo.refundBill :  receiptInfo.receiptNumber}
                    // isRefunded={isRefunded}
                    btnPrintClick={() => {
                        isRefunded ? printRefund(childInfo.refundBill, childInfo.managementId) : printReceipt(receiptInfo.receiptNumber, receiptInfo.paidDate, childInfo.managementId)
                    }}
                    btnRefundClick={(e) => {
                        e.preventDefault(); e.stopPropagation();
                        // setVisiblePopupSec(true); 
                        setVisiblePopup(true);
                        selectAllBillSer(true, receiptInfo.receiptNumber);
                    }}
                />
            //cũ không dùng. tạm comment.
            // <div style={{ width: 400 }} onClick={e => { e.stopPropagation(); e.preventDefault(); }}>
            //     <div className='row'>
            //         <div className='col-6'>
            //             <label className='mita-title'>Số phiếu</label>&nbsp;
            //             <label className='mita-value'>{isRefunded ? childInfo.refundBill : receiptInfo.receiptNumber}</label>
            //         </div>
            //         <div className='col-6'>
            //             <label className='mita-title'>Hình thức</label>&nbsp;
            //             <label className='mita-value'>{isRefunded ? childInfo.paymentTypeName : receiptInfo.paymentTypeName}</label>
            //         </div>
            //         <div className='col-6'>
            //             <label className='mita-title'>{isRefunded ? "Ngày giờ hoàn trả" : "Ngày giờ thanh toán"}</label>&nbsp;
            //             <label className='mita-value'>{isRefunded ? childInfo.paidDate && moment(childInfo.paidDate).format(FORMAT_TIME_DATE) : receiptInfo.paidDate && moment(receiptInfo.paidDate).format(FORMAT_TIME_DATE)}</label>
            //         </div>
            //         <div className='col-6'>
            //             <label className='mita-title'>Thu ngân</label>&nbsp;
            //             <label className='mita-value'>{isRefunded ? childInfo.cashier : receiptInfo.cashier}</label>
            //         </div>
            //         <div className='col-12'>
            //             <label className='mita-title'>Ghi chú</label><br />
            //             <label className='mita-value'>{isRefunded ? childInfo.tag : receiptInfo.tag}</label>
            //         </div>
            //     </div>
            //     <div className='d-flex justify-content-end flex-column'>
            //         <div style={{ textAlign: "end" }}>
            //             <label className='mita-title' style={{ textTransform: "uppercase" }}>Thành tiền&nbsp;</label><u>đ</u>&nbsp;
            //             <label className='mita-title' style={{ width: 150 }}>{roundTo(Number(receiptInfo.resume?.total ?? 0)).toLocaleString("en-US")}</label>&nbsp;
            //             <label style={{ width: 20 }}>&nbsp;</label>
            //         </div>
            //         {roundTo(Number(receiptInfo.resume?.discount ?? 0)) > 0 && <div style={{ textAlign: "end" }}>
            //             <label className='mita-title' style={{ textTransform: "uppercase" }}>Miễn giảm&nbsp;</label><u>đ</u>&nbsp;
            //             <label className='mita-title' style={{ width: 150, color: "#e4a033" }}>{roundTo(Number(receiptInfo.resume?.discount ?? 0)).toLocaleString("en-US")}</label>&nbsp;
            //             <label style={{ width: 20 }}>&nbsp;</label>
            //         </div>}
            //         {roundTo(Number(receiptInfo.resume?.loyalty ?? 0)) > 0 && <div style={{ textAlign: "end" }}>
            //             <label className='mita-title' style={{ textTransform: "uppercase" }}>CTKM / Thẻ&nbsp;</label><u>đ</u>&nbsp;
            //             <label className='mita-title' style={{ width: 150, color: "#57a127" }}>{roundTo(Number(receiptInfo.resume?.loyalty ?? 0)).toLocaleString("en-US")}</label>&nbsp;
            //             <label style={{ width: 20 }}>&nbsp;</label>
            //         </div>}
            //         {isRefunded ? <div style={{ textAlign: "end" }}>
            //             <label className='mita-title' style={{ textTransform: "uppercase" }}>Hoàn trả&nbsp;</label><u>đ</u>&nbsp;
            //             <label className='mita-title' style={{ width: 150, color: "#b13431" }}>{roundTo(Number(receiptInfo.resume?.refund ?? 0)).toLocaleString("en-US")}</label>&nbsp;
            //             <label style={{ width: 20 }}>&nbsp;</label>
            //         </div>
            //             : <div style={{ textAlign: "end" }}>
            //                 <label className='mita-title' style={{ textTransform: "uppercase" }}>Thanh toán&nbsp;</label><u>đ</u>&nbsp;
            //                 <label className='mita-title' style={{ width: 150, color: "#576cdf" }}>{roundTo(Number(receiptInfo.resume?.revenue ?? 0)).toLocaleString("en-US")}</label>&nbsp;
            //                 <label style={{ width: 20 }}><i className='fas fa-network-wired mr-1' /></label>
            //             </div>}
            //     </div>
            //     <div className='custom-hr'></div>
            //     <div className='d-flex flex-row justify-content-between'>
            //         <div>
            //             <a href='#' onClick={(e) => { e.stopPropagation(); childInfo.isRefunded ? printRefund(childInfo.refundBill, childInfo.managementId) : printReceipt(receiptInfo.receiptNumber, receiptInfo.paidDate, receiptInfo.managementId) }}
            //             ><i className='fas fa-print mr-1' />In phiếu</a>
            //         </div>
            //         {!isRefunded &&
            //             <div>
            //                 <Popover
            //                     placement="left"
            //                     onVisibleChange={(isOpen) => {
            //                         selectAllBillSer(receiptInfo.receiptNumber, isOpen);
            //                         setVisiblePopupSec(isOpen);
            //                     }}
            //                     destroyTooltipOnHide
            //                     visible={visiblePopupSec}
            //                     content={
            //                         refundPopoverContent()
            //                     }
            //                     trigger="click"
            //                 ><a href='#' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVisiblePopupSec(true); selectAllBillSer(receiptInfo.receiptNumber); }}><i className='fas fa-undo mr-1'></i>Hoàn trả</a>
            //                 </Popover>
            //             </div>}
            //     </div>
            // </div>
        )
    }

    const handleOpenPreview = (rept, record) => {
        setVisiblePreview(true);
        setSelectedReceipt(rept);
        setSelectedReceiptNumber(record.isRefunded ? record.refundBill : rept.receiptNumber);
    }

    const handleClosePreview = () => {
        setVisiblePreview(false);
    }

    const handleSetIndeterminate = () => {
        let list = listReceipt;
        let totalPaid = 0;
        let totalChecked = 0;
        if (list && list.length > 0) {
            list.forEach(item => {
                if (item.listServices && item.listServices.length > 0) {
                    item.listServices.forEach((s) => {
                        if (!s.isRefunded) {
                            totalPaid++;
                            if (s.isChecked)
                                totalChecked++;
                        }
                    })
                }
                //2023.07.26: tạm không cho hoàn trả thuốc
                //2023.08.09: mở ra cho hoàn trả thuốc để test #3987
                if (item.listMedis && item.listMedis.length > 0) {
                    item.listMedis.forEach((sm) => {
                        if (sm.subMedis && sm.subMedis.length > 0) {
                            sm.subMedis.forEach((m) => {
                                if (!m.isRefunded) {
                                    totalPaid++;
                                    if (m.isChecked)
                                        totalChecked++;
                                }
                            })
                        }
                    })
                }
            });
            setIsAtLeastOneCheck(totalChecked > 0 && totalChecked < totalPaid);
            setIsCheckAll(totalChecked > 0 && totalChecked == totalPaid);
        } else {
            setIsAtLeastOneCheck(false);
            setIsCheckAll(false);
        }
    }

    const countItem = (type) => {
        const list = listReceipt;
        let count = 0;
        if (!isnotnull(list)) return false;
        let existService = false;
        let existMedicine = false;
        list.forEach(x => {
            if (type == "service") count = x.listServices?.length ?? 0;
            else if (type == "medicine") count = x.listMedis?.length ?? 0;
        })
        return count;
    }

    const isExists = (type) => {
        const list = listReceipt;
        if (!isnotnull(list)) return false;
        let existService = false;
        let existMedicine = false;
        list.forEach(x => {
            if (isnotnull(x.listServices) && x.listServices.length > 0)
                existService = true;
            if (isnotnull(x.listMedis) && x.listMedis.length > 0)
                existMedicine = true;
        })
        if (type == "service") return existService;
        else if (type == "medicine") return existMedicine;
    }

    const renderListServices = () => {
        const list = listReceipt;
        if (isnotnull(list)) {
            let finalListService = [];
            list.map((rept, reptIndex) => {
                if (isnotnull(rept.listServices)) {
                    rept.listServices.forEach(item => {
                        finalListService.push({ ...item, rept, reptIndex });
                    })
                }
            })
            return (
                isnotnull(finalListService) &&
                <Fragment>
                    <div className='row'>
                        <div className="list-page w-100">
                            <div className="list-page-body">
                                <div className="list-page-table mt-2">
                                    <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto w-100"
                                        bordered={false}
                                        dataSource={finalListService && renumber(Object.keys(groupBy(finalListService, "groupName")).map((key) => ({ key, value: groupBy(finalListService, "groupName")[key] })))}
                                        renderItem={(record, index) => {
                                            return (
                                                <Fragment>
                                                    <div className='col-12'>
                                                        <div style={{ fontWeight: "bold", backgroundColor: "#D8D8D8", height: 35, display: "flex", alignItems: "center", padding: "0px 5px" }}>{record.key}</div>
                                                        {
                                                            record.value.map((r, i) => {
                                                                return (
                                                                    <List.Item key={r.name} className={classNameListItem}
                                                                        onClick={() => { onSelectDeselect(r.reptIndex, "service", r.stt, r.isChecked ? !r.isChecked : true, r) }}>
                                                                        <div className='d-flex flex-column w-100'>
                                                                            <div className="d-flex justify-content-between w-100">
                                                                                <div style={{ width: 30 }}>
                                                                                    <Checkbox
                                                                                        checked={r.isChecked}
                                                                                        onChange={(e) => { !r.isRefunded && onSelectDeselect(r.reptIndex, "service", r.stt, e.target.checked, r) }}
                                                                                        disabled={r.isRefunded}
                                                                                    ></Checkbox>
                                                                                </div>
                                                                                <div style={{ width: "40%" }}><label>{r.name}</label></div>
                                                                                <div style={{ width: "15%" }}>
                                                                                    <label className='mita-value'>
                                                                                        {r.subServices && r.subServices.length > 0 ? "" : r.status}
                                                                                    </label>
                                                                                </div>
                                                                                <div style={{ width: "calc(45% - 30px)" }} className='d-flex flex-row justify-content-end'>
                                                                                    <label>{r.isRefunded ? "Đã hoàn trả" : "Đã thanh toán"}

                                                                                        {/* <Popover
                                                                                            placement="topLeft"
                                                                                            // onVisibleChange={(isOpen) => {
                                                                                            //     setVisiblePopupSec(isOpen);
                                                                                            //     forceUpdate();
                                                                                            // }}
                                                                                            // destroyTooltipOnHide
                                                                                            visible={visiblePopupSec}
                                                                                            content={
                                                                                                refundPopoverContent()
                                                                                            }
                                                                                            trigger="click"
                                                                                        >
                                                                                            <Button ref={btnRefundRef}
                                                                                                className="pl-0"
                                                                                                type="link"
                                                                                                // onClick={(e) => {
                                                                                                //     props.btnRefundClick && props.btnRefundClick(e);
                                                                                                // }}
                                                                                            ></Button>
                                                                                        </Popover> */}
                                                                                        <Popover placement='bottomLeft'
                                                                                            // content={renderPopoverRefundOrPaid(r.rept, r)}
                                                                                            content={renderPopoverRefundOrPaid(r.rept, r)}
                                                                                        >
                                                                                            <Tag className='ml-2' color={`${r.isRefunded ? "#b13431" : "#576cdf"}`}
                                                                                                onClick={(e) => { e.stopPropagation(); handleOpenPreview(r.rept, r); }}>
                                                                                                {r.isRefunded ? r.refundBillNo : r.rept.billNo}
                                                                                            </Tag>
                                                                                        </Popover>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100">
                                                                                {
                                                                                    r.subServices && <div style={{ width: "100%" }}>
                                                                                        {r.subServices.map((sub, index) => {
                                                                                            return (
                                                                                                <div className='mt-2 d-flex justify-content-between w-100' key={`${index}-${sub.name}`}>
                                                                                                    <div style={{ width: 30 }}>&nbsp;</div>
                                                                                                    <div style={{ width: "40%", paddingLeft: 15 }}>{sub.name}</div>
                                                                                                    <div style={{ width: "15%" }}><label className='mita-value'>{sub.status}</label></div>
                                                                                                    <div style={{ width: "calc(45% - 30px)" }}>&nbsp;</div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </List.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Fragment>
                                            )
                                        }}
                                    >
                                    </List>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    // const getDefaultMedthod = () => {
    //     const list = listReceipt;
    //     let type = null;
    //     let foundListReceiptNumber = list?.filter(x => (x.listMedis?.filter(y => (y.subMedis?.filter(z => z.isChecked && z.isRefunded == false) ?? []).length > 0).length > 0) || (x.listServices?.filter(y => y.isChecked && y.isRefunded == false) ?? []).length > 0).map(x => x.receiptNumber);
    //     if (isnotnull(list)) {
    //         let foundPaymentType = [... new Set(list.filter(x => foundListReceiptNumber.includes(x.receiptNumber)).map(x => (x.paymentType)))];
    //         if (foundPaymentType.length == 1) {
    //             type = foundPaymentType[0];
    //         } else if (foundPaymentType.length > 1 && !foundPaymentType.includes(Constants.PAYMENTTYPE.DEBT) && !foundPaymentType.includes(Constants.PAYMENTTYPE.CARD)) {
    //             type = Constants.PAYMENTTYPE.CASH;
    //         }
    //     }
    //     defaultMethod = type;
    //     return type;
    // }

    const getBillList = (managementId = props.managementId.length) => {
        if (managementId && managementId.length > 0) {
            InvoiceService.getBillListByMaQl(managementId, true).then(res => {
                if (res.isSuccess) {
                    setListBill(res.data);
                    forceUpdate();
                }
            }).catch(err => {

            }).finally(() => {
            })
        }
    }

    const deleteAllInvoice = (maql = props.managementId) => {
        Actions.setLoading(true);
        InvoiceService.DeletePaidAndDoneInvoice(maql).then(res => {
            if (res.isSuccess) {
                getListPaid();
                notiRef && notiRef.current && notiRef.current.Show("Thao tác thành công", NotifyStatus.Success);
            }
        }).catch(err => {
            notiRef && notiRef.current && notiRef.current.Show(err.error.messageText ?? "Đã xảy ra lỗi", NotifyStatus.Success);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    return (
        <Fragment>
            <Notify ref={notiRef} />
            {listReceipt && listReceipt.length === 0 &&
                <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" />
            }
            {listReceipt && listReceipt.length > 0 &&
                <div className='row w-100 my-2'>
                    <div className='col-12 d-flex justify-content-between'>
                        <div style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
                            <Checkbox style={{ width: 30 }} indeterminate={isAtLeastOneCheck} checked={isCheckAll} onChange={(e) => onChangeSelectAll(e)} />
                            <Popover trigger={"click"} placement="bottom"
                                destroyTooltipOnHide
                                onVisibleChange={(e) => {
                                    handleVisiblePopupRefund(e);
                                    setVisiblePopup(e);
                                }}
                                visible={visiblePopup}
                                content={
                                    refundPopoverContent()
                                }>
                                <a href='#' className='d-flex align-items-center'><i style={{ fontSize: 20 }} className='fas fa-undo mr-1' /><span>Hoàn trả</span></a>
                            </Popover>
                        </div>
                        {IsSuperAdmin() && <Popconfirm
                            title="Xóa hết toàn bộ hóa đơn của lần đăng ký này. Bạn chắc chắn muốn xóa?"
                            placement='left'
                            okText="Đồng ý" cancelText="Hủy bỏ"
                            onConfirm={() => { deleteAllInvoice() }}>
                            <Button className={`${BUTTON.DANGER}`}>
                                <div className='d-flex align-items-center'><TransactionOutlined className='mr-1' />Xóa hóa đơn</div>
                            </Button>
                        </Popconfirm>}
                    </div>
                </div>
            }
            {isExists("service") && <Fragment>
                <div className='w-100'>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <h5 className='d-flex align-items-center'><i className="fas fa-medkit mr-3" style={{ fontSize: 20 }}></i><span>DỊCH VỤ</span></h5>
                        </div>
                    </div>
                </div>
                {renderListServices()}
            </Fragment>}
            {isExists("medicine") && <Fragment>
                <div className='w-100'>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <h5 className='d-flex align-items-center'><i className="fas fa-capsules mr-3" style={{ fontSize: 20 }}></i><span>THUỐC & VẬT TƯ</span></h5>
                        </div>
                    </div>
                </div>
                {
                    listReceipt && listReceipt.map((rept, reptIndex) => {
                        return (
                            rept.listMedis && rept.listMedis.length > 0 &&
                            <Fragment>
                                <div className='row'>
                                    <div className="list-page w-100">
                                        <div className="list-page-body">
                                            <div className="list-page-table mt-2">
                                                <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto w-100"
                                                    bordered={false}
                                                    dataSource={rept.listMedis}
                                                    renderItem={(record, index) => {
                                                        let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover w-100";
                                                        return (
                                                            <Fragment>
                                                                <div className='col-12'>
                                                                    <div className='d-flex flex-column w-100'>
                                                                        {
                                                                            record.subMedis &&
                                                                            <div style={{ width: "100%" }}>
                                                                                {record.subMedis.map((sub, index) => {
                                                                                    return (
                                                                                        <div className="d-flex justify-content-between w-100">
                                                                                            <List.Item key={sub.name} className={className}
                                                                                                //2023.07.25: tạm không cho hoàn trả thuốc vì lỗi. Sẽ sửa sau
                                                                                                //2023.08.11: mở ra luôn
                                                                                                onClick={() => { onSelectDeselect(reptIndex, "medicine", index, sub.isChecked ? !sub.isChecked : true, record) }}
                                                                                            >
                                                                                                <div className='mt-2 d-flex justify-content-between w-100' key={`${index}-${sub.name}`}>
                                                                                                    <div style={{ width: 30 }}>
                                                                                                        {/* 2023.07.25: tạm không cho hoàn trả thuốc vì lỗi. Sẽ sửa sau*/}
                                                                                                        <Checkbox checked={sub.isChecked} onChange={(e) => { onSelectDeselect(reptIndex, "medicine", index, e.target.checked, record) }}></Checkbox>
                                                                                                    </div>
                                                                                                    <div style={{ width: "40%", }}>{sub.name}</div>
                                                                                                    <div style={{ width: "20%" }}>
                                                                                                        <span>Số phiếu: {record.prescriptionNumber}</span>
                                                                                                    </div>
                                                                                                    <div style={{ width: "calc(40% - 30px)" }} className='d-flex flex-row justify-content-end'>
                                                                                                    {
                                                                                                        sub.isRefunded == true ?
                                                                                                            <div style={{ textAlign: "end" }}>
                                                                                                                <span>Đã hoàn trả</span>
                                                                                                                <Popover placement='bottomLeft'
                                                                                                                    content={renderPopoverRefundOrPaid(rept, sub)}>
                                                                                                                    <Tag key={`ref-med-${sub.refundBill}-${index}`} className='ml-2' color="#b13431"
                                                                                                                        onClick={(e) => { e.stopPropagation(); handleOpenPreview(rept, sub); }}>
                                                                                                                        {sub.refundBillNo}
                                                                                                                    </Tag>
                                                                                                                </Popover>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className='d-flex flex-column'>
                                                                                                                <div style={{ textAlign: "end" }}>
                                                                                                                    <span>Đã thanh toán</span>
                                                                                                                    <Popover placement='bottomLeft'
                                                                                                                        content={renderPopoverRefundOrPaid(rept, sub)}>
                                                                                                                        <Tag className='ml-2' color="#576cdf"
                                                                                                                            onClick={(e) => { e.stopPropagation(); handleOpenPreview(rept, sub); }}>
                                                                                                                            {rept.billNo}
                                                                                                                        </Tag>
                                                                                                                    </Popover>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </List.Item>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    }}
                                                ></List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })
                }
            </Fragment>}
            <Drawer
                className='custom-ant-drawer'
                closable={false}
                width={1400}
                placement="right"
                onClose={() => handleClosePreview()}
                visible={visiblePreview}
                destroyOnClose={true}
                maskClosable={true}
            >
                <Preview
                    data={selectedReceipt}
                    patientCode={props.patientCode}
                    patientName={props.patientName}
                    managermentId={props.managementId}
                    receiptNumber={selectedReceiptNumber}
                    onClose={() => handleClosePreview()}
                    onReload={() => reloadInfo()}
                />
            </Drawer>
        </Fragment>
    );
})

const mapStateToProps = (state) => ({
    siteid: state.global.siteid,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Refund);