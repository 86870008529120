import * as ApiCaller from '../libs/httpRequests';

export function speak(text , voice , speed) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Speaker/getUrl?text=${text}&voice=${voice}&speed=${speed}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};