import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  EditFilled } from '@ant-design/icons';
import { Input, InputNumber, Button, Table, notification, Modal, Popconfirm, Pagination, Dropdown, Menu, Select, Tag , Drawer} from 'antd';
import * as Actions from '../../../libs/actions';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import * as CardTypeService from '../../../services/CardTypeService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import CardTypeDetail from './detail';
import CardAccountList from '../cardaccount/index';
import CardAccountDetail from '../cardaccount/detail';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const firstRender = useRef(true); 
  const detailRef = useRef();
  const deletePopupRef = useRef([]);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [showMoreText, setShowMoreText] = useState("...Xem thêm");
  const [showLessText, setShowLessText] = useState("\nRút gọn");
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [visibleAdvangeSearch, setVisibleAdvangeSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [valueFrom, setValueFrom] = useState(null);
  const [valueTo, setValueTo] = useState(null);
  const [isActives, setIsActives] = useState([]);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [selectedId, setSelectedId] = useState();
  const [selectedCardType, setSelectedCardType] = useState();
  const [visibleCardAccountList, setVisibleCardAccountList] = useState(false); 
  const [visibleCardAccountDetail, setVisibleCardAccountDetail] = useState(false);
  //table
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    search();
  }, []);
  //#endregion 
  //#region search & filter
  const search = (isResetSelectedRow = false) => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      valueFrom: valueFrom,
      valueTo: valueTo,
      isActives: isActives
    }
    CardTypeService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        if (isResetSelectedRow) setSelectedRowKeys([]);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = (isResetSelectedRow = false) => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    setDetailScreenMode(detailScrMode);
    setVisibleDetail(true);
  }

  const onDeleteClickHandle = (id) => {
    Actions.setLoading(true);
    CardTypeService.deleteById(id).then(result => {
      if (result.isSuccess) {
        search();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };

  const loadCardAccountCreate = (row) => {//đăng ký
    if (row) {
      setSelectedId(row.id);
      setSelectedCardType(row);
      if (!row.isActive) {
        notification.warning({ message: "Không thể đăng ký tài khoản cho loại thẻ không còn hoạt động!" });
        return;
      }
      setVisibleCardAccountDetail(true);
    }
  }

  const loadCardAccountList= (row) => {//list account
    if (row) setSelectedId(row.id);
    setVisibleCardAccountList(true);
  }
  
  const onExportListClickHandle = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: 0,
      maxResultCount: 0,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      valueFrom: valueFrom,
      valueTo: valueTo,
      isActives: isActives
    }
    CardTypeService.getListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "DanhSachLoaiThe_"+moment(new Date()).format('YYMMDDhhmmss');
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  useEffect(() => {
    // console.log(`selectedRowKeys useEffect: ${selectedRowKeys}`);
  }, [selectedRowKeys])

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      let newSelecteds = selectedRowKeys.filter(x => x != record.id);
      if (selected) {
        newSelecteds.push(record.id);
      }
      setSelectedRowKeys(newSelecteds);
      return true;
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      let selectedRowIds = changeRows.map(c => c.id);
      let newSelecteds = selectedRowKeys.filter(x => selectedRowIds.indexOf(x) < 0);
      if (selected) {
        newSelecteds = [...newSelecteds,...changeRows.map(x => x.id)];
        newSelecteds = Array.from(new Set(newSelecteds.map((item) => item)));
      }
      setSelectedRowKeys(newSelecteds);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) search();
  };

  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                loadCardAccountCreate(record);
              }}><i className='fa fa-plus-circle mr-2'/> Đăng ký</a>,
            key: '0',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadCardAccountList(record);
              }}><i className='fa fa-bars mr-2'/> Danh sách đăng ký</a>,
            key: '1',
          },
          {
            label: 
              <a href="#" onClick={() => {
                loadDetail(record, detailScreenModeEnum.update);
              }}><EditFilled /><i className='fa fa-pencil mr-2'/> Chỉnh sửa</a>,
            key: '2',
          },
          {
            label: 
              <a href="#" onClick={() => { deletePopupRef.current[record.id].click() }}
              ><i className='fas fa-trash mr-2'/> Xóa loại thẻ</a>,
            key: '3',
          },
        ]}
      />
    )
  }

  const geneListStringToTag = (list = []) => {
    return list?.map((x, index) =>
          <Tag key={index} style={{whiteSpace: "pre-line"}}>{x}
          </Tag>
        )
  }

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 30,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      // Table.SELECTION_COLUMN,
      {
          title: 'Mã loại thẻ',
          dataIndex: 'cardTypeCode',
          key: 'cardTypeCode',
          align: 'left',
          width: 120,
          sorter: () => {},
          render: (value, record) => {
            return (
            <>
              <a href="#"
                onClick={() => {
                  loadDetail(record, detailScreenModeEnum.detail);
                }}
              >{value}</a>
              <Popconfirm
                title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{value}</b>?</span>)}}
                onConfirm={() => onDeleteClickHandle(record.id)}
                onCancel={() => {}}
                okText="Đồng ý"
                cancelText="Hủy"
              >
                <a ref={e => deletePopupRef.current[record.id] = e}></a>
              </Popconfirm>
            </>
            )
          }
      },
      {
          title: '',
          align: 'left',
          width: 25,
          render: (value, record) => {
              return (
                <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                  <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                </Dropdown>
              )      
          }
      },
      {
          title: 'Tên loại thẻ',
          dataIndex: 'cardTypeName',
          key: 'cardTypeName',
          align: 'left',
          // width: 180,
          sorter: () => {},
          render: (value, record) => {
              return (
                // <span>{value}</span>
                <MitaDisplayLongText
                  // content={() => geneRemark(record.remarkBonus, record.remarkOther, record.remarkPreferential, record.remarkServiceConcat) }
                  content={value}
                  maxLength={30} // mặc định 64
                  showMoreText={showMoreText}
                  showLessText={showLessText}
                  // displayMode="popup"
                />
              )      
          }
      },
      {
          title: 'Giá trị',
          dataIndex: 'cardTypeValue',
          key: 'cardTypeValue',
          align: 'right',
          width: 100,
          sorter: () => {},
          render: (value, record, index) => {
              return <span>{ value.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Giảm giá %',
          dataIndex: 'discountPercent',
          key: 'discountPercent',
          align: 'center',
          width: 80,
          sorter: () => {},
          render: (value) => {
            return <span>{ value.toLocaleString('en-US') }</span>;
          }
      },
      {
          title: 'Thời hạn',
          dataIndex: 'expiredTime',
          key: 'expiredTime',
          align: 'center',
          width: 96,
          // sorter: () => {},
          render: (value, record) => {
              return <span>{ value + ' ' + record.expiredTimeTypeText }</span>;
          }
      },
      {
          title: 'Các dịch vụ',
          className: "border-bottom",
          children: [
            {
              title: 'Đi kèm',
              dataIndex: 'cardTypeProducts',
              key: 'cardTypeProducts',
              render: (value, record) => {
                return (
                  record.isAllProductPreferential ? "Tất cả" :
                  <div>
                    <MitaDisplayListElement
                      content={ () => geneListStringToTag(value?.map(x => x.productName)) }
                      // maxLength={5} // mặc định 3
                      showMoreText={ showMoreText }
                      showLessText={ showLessText }
                      displayMode="popup"
                      elementDisplay="block"
                    />
                  </div>
                )
              }
            },
            {
              title: 'Loại trừ',
              dataIndex: 'cardTypeProductExcludes',
              key: 'cardTypeProductExcludes',
              render: (value, record) => {
                  return (
                    <div>
                      {/* {geneListStringToTag(value?.map(x => x.productName))} */}
                      <MitaDisplayListElement
                      content={ geneListStringToTag(value?.map(x => x.productName)) }
                        // maxLength={5} // mặc định 3
                        showMoreText={showMoreText}
                        showLessText={showLessText}
                        displayMode="popup"
                        elementDisplay="block"
                      />
                    </div>
                  )
              }
            },
            {
              title: 'Tặng kèm',
              dataIndex: 'cardTypeProductFrees',
              key: 'cardTypeProductFrees',
              render: (value, record) => {
                return (
                  <div>
                    <MitaDisplayListElement
                      content={ geneListStringToTag(value?.map(x => x.productName)) }
                      // maxLength={5} // mặc định 3
                      showMoreText={showMoreText}
                      showLessText={showLessText}
                      displayMode="popup"
                      elementDisplay="block"
                    />
                  </div>
                )
              }
            },
          ]
      },
      {
          title: 'Trả lại tiền',
          dataIndex: 'hasRefund',
          key: 'hasRefund',
          align: 'center',
          width: 70,
          render: (value, record) => {
              return (
                <span>{value ? "Có": "Không" }</span>
              )
          }
      },
      {
          title: 'Ghi chú',
          dataIndex: 'remarkOther',
          key: 'remarkOther',
          align: 'left',
          render: (value, record) => {
              return (
                <MitaDisplayLongText
                  // content={() => geneRemark(record.remarkBonus, record.remarkOther, record.remarkPreferential, record.remarkServiceConcat) }
                  content={value}
                  // maxLength={100} // mặc định 64
                  showMoreText={showMoreText}
                  showLessText={showLessText}
                  displayMode="popup"
                />
              );
          }
      },
      {
          title: 'Hoạt động',
          dataIndex: 'isActive',
          key: 'isActive',
          align: 'center',
          width: 70,
          render: (value, record) => {
              return (
                <span>{value ? "Có": "Không" }</span>
              )
          }
      },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className='containerChilderWrapper list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách loại thẻ</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  this.props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                <div className="col-md-12 d-flex">
                  <Input
                    placeholder="Tìm theo mã, tên"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                  {/* < Button className="text-color" //Chưa dùng tới. Có thời gian làm sau.
                    type="link"
                    onClick={() => { setVisibleAdvangeSearch(!visibleAdvangeSearch) }}
                  ><i className={"mr-1 fa fa-filter" } style={{ fontSize: "x-large" }}/></Button> */}
                </div>
                {/* <div className="col-md-4">
                </div> */}
              </div>
              <div hidden={!visibleAdvangeSearch}>
                <div className="row mt-2" >
                  <div className="col-md-4 d-flex flex-column">
                    <label>Giá trị</label>
                    <div className="d-flex justify-content-between">
                      <InputNumber
                          step = { 1000000 }
                          className='w-100 mr-1'
                          placeholder='Giá trị từ'
                          value={valueFrom} 
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          onChange={(value) => { setValueFrom(value); }}
                          onKeyUp={(e) => { enterToSearch(e); }}>
                      </InputNumber> ~
                      <InputNumber
                          step = { 1000000 }
                          className='w-100 ml-1'
                          placeholder='Giá trị đến'
                          value={valueTo} 
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          onChange={(value) => { setValueTo(value); }}
                          onKeyUp={(e) => { enterToSearch(e); }}>
                      </InputNumber>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column">
                    <label>Hoạt động</label>
                    <div className="d-flex justify-content-between">
                      <Select
                        className="w-100"
                        mode="multiple"
                        allowClear
                        placeholder="Chọn..."
                        onChange={(e) => { 
                          setIsActives(e);
                        }}
                      >
                        <Option key={true} value={true}>Có</Option>
                        <Option key={false} value={false}>Không</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                          onClick={() => {
                            loadDetail(null, detailScreenModeEnum.create);
                          }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
                  <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <button className="btn btn-color" type="button" onClick={() => { onExportListClickHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  </div>
              </div>
            </div>
            <div className="list-page-table">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0 table-header-2row"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          {/* footer (nếu có)*/}
          {/* <div className="list-page-footer d-flex justify-content-between mt-2 d-none">
          </div> */}
      </div>
      {/* <Modal maskClosable={false}
          width={800}
          height="calc(100% - 10px)"
          visible={visibleDetail}
          onCancel={() => handleCancel() }
          destroyOnClose={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <CardTypeDetail ref={detailRef}
            isDialog={ true } 
            cardTypeId={selectedId}
            detailScreenMode = {detailScreenMode}
            onCancel={(reload = false) => {handleCancel(reload)}}/>
      </Modal> */}
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={700}
        // getContainer={() => detailDrawRef.current}
        placement="right"
        onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <CardTypeDetail ref={detailRef}
          isDialog={ true } 
          cardTypeId={selectedId}
          detailScreenMode = {detailScreenMode}
          onCancel={(reload = false) => {handleCancel(reload)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={700}
        placement="right"
        visible={visibleCardAccountDetail}
        destroyOnClose={true}
      >
        <CardAccountDetail
          isDialog={ true } 
          cardTypeId={selectedId}
          // cardTypeName={selectedName}
          cardTypeSelected={selectedCardType}
          detailScreenMode = {detailScreenModeEnum.create}
          onCancel={() => {setVisibleCardAccountDetail(false)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={true}
        closable={false}
        width={1200}
        placement="right"
        visible={visibleCardAccountList}
        destroyOnClose={true}
      >
        <CardAccountList
          isDialog={ true } 
          cardTypeId={selectedId}
          // detailScreenMode = {detailScreenMode}
          onCancel={() => {setVisibleCardAccountList(false)}}
        />
      </Drawer>
    </React.Fragment>
  );
})