import React from 'react';
import { Component } from 'react';
import {
    DatePicker, Input, Table, Pagination,
    Modal, InputNumber, Popconfirm,
    Select, notification, Tag, Tabs,
    Menu, Upload, Dropdown, Checkbox, Tooltip
} from 'antd';
import viVN from 'antd/lib/date-picker/locale/vi_VN';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Action from '../../libs/actions';
import * as EquipmentService from './equipmentService';
import Create from './create';
import History from './history';
import Maintenance from './maintenance';
import Type from './type';
import KiemKe from './KiemKe';
import Location from './location';
import { FORMAT_DATE } from '../../configure/systemConfig';
import UnSupport from '../../screens/unknowPage/unSupport';

const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,

    visibleCreate: false,
    visibleUpdate: false,
    isChecked: false,
    expiredTime: '',
    depreciatedTime: '',
    note: '',
    _equipmentID: ''
};

var task = null;

export default class DMVatTuCoDinh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            key: "",

            createID: '',
            equipmentID: '',
            equipmentName: '',
            brand: '',
            type: '',
            price: 0,
            createTime: moment(),
            expiration: null,
            depreciation: null,
            listEquipments: [],
            serial: '',
            infomation: '',
            location: null,
            status: 0,
            userid: null,
            dateinuse: moment(),
            lstUsers: [],
            uploading: false,
            fileList: [],
            visibleImport: false,
            showImportResult: false,
            errorList: "",
            insertRow: 0,
            updateRow: 0,
            rejectList: "",
            rejectRow: 0,

            lstTypes: [],
            listLocation: [],
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.loadListEquipments();
        this.getListUsers();
        this.getListTypes();
        this.getListLocation();
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    loadListEquipments(page = this.state.currentPage, size = this.state.pageSize) {
        EquipmentService.getListEquipments(
            this.state.key,
            this.state.expiredTime, this.state.depreciatedTime,
            page, size).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        listEquipments: res.data,
                        currentPage: page,
                        pageSize: size,
                        totalRecords: res.totalRecords,
                    });
                }
            }).catch(err => {
            })
    }
    cancelAction = () => {
        this.setState({
            visibleCreate: false,
            visibleUpdate: false
        });
    }
    reloadAction = () => {
        this.loadListEquipments(1, this.state.pageSize);
    }
    resetState = () => {
        this.validator.hideMessages();
        this.setState({
            createID: '',
            equipmentID: '',
            equipmentName: '',
            brand: '',
            type: '',
            price: 0,
            createTime: moment(),
            expiration: null,
            depreciation: null,
            note: '',
            serial: '',
            infomation: '',
            location: null,
            status: 0,
            userid: null,
            dateinuse: moment(),
        })
    }
    updateEquipment() {
        Action.setLoading(true);
        var {
            createID, createTime, equipmentID, _equipmentID, equipmentName,
            brand, type, price, expiration, depreciation,
            note, serial, infomation, location, status, userid, dateinuse
        } = this.state;
        if (this.validator.allValid()) {
            const data = {
                createID, createTime, equipmentID, _equipmentID, equipmentName,
                brand, type, dongia: price, expiration, depreciation, note, serial, infomation,
                location, status, userid: userid ? userid : -1, dateinuse
            }
            EquipmentService.updateEquipment(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    status === 1 && this.addHistory({ equipmentID, note, userid, dateinuse });
                    task = setTimeout(() => {
                        this.loadListEquipments(1, this.state.pageSize);
                        this.cancelAction();
                    }, 100);
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => { })
            Action.setLoading(false);
        } else {
            Action.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    addHistory(data) {
        EquipmentService.createHistory(data).then(res => {
            if (res.isSuccess) {
            } else {
                notification.warning({ message: "Lỗi khi thêm thông tin bàn giao" });
            }
        }).catch(() => { })
    }
    deleteEquipment(id) {
        EquipmentService.deleteEquipment(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadListEquipments(this.state.currentPage, this.state.pageSize);
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }
    export = () => {
        Action.setLoading(true);
        EquipmentService.exportEquipmentsList(this.state.expiredTime, this.state.depreciatedTime).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Danh-Sach-Tai-San-Co-Dinh";
                this._hiddenLink.click();
            })
            Action.setLoading(false);
        }).catch(err => {
            Action.setLoading(false);
        })
    }
    getListUsers(active = true) {
        EquipmentService.loadUsers(active).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstUsers: res.data
                });
            }
        }).catch(() => { })
    }
    template = () => {
        Action.setLoading(true);
        EquipmentService.templateTaiSanCoDinh().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Mau-Import-Tai-San-Co-Dinh";
                this._hiddenLink.click();
            })
            Action.setLoading(false);
        }).catch(err => {
            Action.setLoading(false);
        })
    }
    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        this.setState({
            uploading: true
        });
        EquipmentService.importDanhSach(formData).then(res => {
            Action.setLoading(true);
            if (res.isSuccess) {
                this.setState({
                    uploading: false,
                    fileList: [],
                    insertRow: res.returnObject.insertRow,
                    updateRow: res.returnObject.updateRow,
                    errorList: res.returnObject.errorList,
                    rejectRow: res.returnObject.rejectRow,
                    rejectList: res.returnObject.rejectList,
                    showImportResult: true
                });
                this.loadListEquipments();
                Action.setLoading(false);
            }
            else {
                notification.warning({ message: res.err.msgString });
                this.setState({
                    uploading: false,
                    showImportResult: true,
                    rejectRow: res.returnObject.rejectRow,
                    rejectList: res.returnObject.rejectList,
                    fileList: [],
                });
                Action.setLoading(false);
            }
        }).catch(err => {
            this.setState({
                uploading: false,
                fileList: [],
            });
            Action.setLoading(false);
        })
    }
    getListTypes() {
        EquipmentService.loadTypes().then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstTypes: res.data
                });
            }
        }).catch(() => { })
    }
    generateCode(prefix) {
        EquipmentService.generateCode(prefix).then(res => {
            if (res.isSuccess) {
                this.setState({
                    equipmentID: res.returnObject.code
                });
            } else {
                notification.error({ message: "Đã xảy ra lỗi khi tạo mã" })
            }
        }).catch(() => { })
    }
    getListLocation = (page = 1, size = 10) => {
        var getAll = true;
        var key = "";
        EquipmentService.getListLocation(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                this.setState({ listLocation : res.data })
            }
        }).catch(() => { })
    }
    render() {
        let { fileList } = this.state;
        const props = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        };
        let { currentPage, pageSize, totalRecords } = this.state;
        const columns = [
            {
                title: "STT",
                align: "center",
                render: (text, record, index) => (currentPage - 1) * pageSize + Number(index) + 1,
                width: 70
            },
            {
                title: "Mã tài sản",
                dataIndex: "equipmentID",
                key: "equipmentID",
                align: "center",
                width: 100
            },
            {
                title: "Tên tài sản",
                dataIndex: "equipmentName",
                key: "equipmentName",
                align: "left",
                width: 230
            },
            {
                title: "Hãng",
                dataIndex: "brand",
                key: "brand",
                align: "left",
                width: 120
            },
            {
                title: "Loại",
                dataIndex: "type",
                key: "type",
                align: "center",
                width: 150,
                render: type => {
                    if (this.state.lstTypes.filter(x => x.typeCode === type)[0] !== undefined) {
                        return this.state.lstTypes.filter(x => x.typeCode === type)[0].typeName
                    } else {
                        return ""
                    }
                }
            },
            {
                title: "Giá",
                dataIndex: "dongia",
                key: "dongia",
                align: "right",
                width: 100,
                render: (value) => {
                    return <span>{value !== undefined ? value.toLocaleString("en-US") : ''}</span>
                }
            },
            {
                title: "Ngày nhập",
                dataIndex: "createTime",
                key: "createTime",
                align: "center",
                width: 100,
                render: (day) => {
                    return <span>{day && moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn bảo hành",
                dataIndex: "expiration",
                key: "expiration",
                align: "center",
                width: 120,
                render: (day) => {
                    if (day) return <span>{day && moment(day) < moment() ? <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span> : moment(day).format(FORMAT_DATE)}</span>
                }
            },
            {
                title: "Hạn khấu hao",
                dataIndex: "depreciation",
                key: "depreciation",
                align: "center",
                width: 120,
                render: (day) => {
                    if (day) return <span>
                        {moment(day).year() === 1 ? "" :
                            moment(day) < moment() ?
                                <span style={{ color: 'red' }}>{moment(day).format(FORMAT_DATE)}</span>
                                : moment(day).format(FORMAT_DATE)}
                    </span>
                }
            },
            {
                title: "Serial",
                dataIndex: "serial",
                key: "serial",
                width: 100,
            },
            {
                title: "Cấu hình",
                dataIndex: "infomation",
                key: "infomation",
                width: 150,
            },
            {
                title: "Bộ phận",
                key: "location",
                width: 140,
                render: (row) => {
                    var found = this.state.listLocation.find(x => x.code === row.location);
                    return this.state.listLocation && found && found.location;
                }
            },
            {
                title: "Tình trạng",
                dataIndex: "status",
                key: "status",
                align: "center",
                width: 120,
                render: state => {
                    switch (state) {
                        case 0:
                            return <Tag color="green" style={{ width: "100%" }}>Trong kho</Tag>
                        case 1:
                            return <Tag color="blue" style={{ width: "100%" }}>Đang sử dụng</Tag>
                        case 2:
                            return <Tag color="orange" style={{ width: "100%" }}>Thanh lý</Tag>
                        default:
                            break;
                    }
                }
            },
            {
                title: "Cấp cho",
                dataIndex: "userid",
                key: "userid",
                width: 130,
                render: id => {
                    if (this.state.lstUsers.filter(x => x.id === id)[0] !== undefined) {
                        return this.state.lstUsers.filter(x => x.id === id)[0].hoten
                    }
                }
            },
            {
                title: "Ngày cấp",
                dataIndex: "dateinuse",
                key: "dateinuse",
                width: 110,
                render: (day) => {
                    if (day) return moment(day).format(FORMAT_DATE)
                }
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                key: "note",
                width: 170,
                align: "left"
            },
            {
                title: "Thao tác",
                align: "center",
                width: 140,
                render: (row) => {
                    return (
                        <div>
                            <Tooltip title="Cập nhật (Bàn giao...)">
                                <button className={!row.removeable ? "btn btn-light" : "btn btn-warning"}
                                    disabled={!row.removeable}
                                    title={!row.removeable ? "Tài sản đã bàn giao hoặc đang bảo trì" : ""}
                                    onClick={() => {
                                        this.setState({
                                            visibleUpdate: true,
                                            createID: row.createID,
                                            createTime: row.createTime && moment(row.createTime),
                                            equipmentID: row.equipmentID,
                                            equipmentName: row.equipmentName,
                                            brand: row.brand,
                                            type: row.type,
                                            price: Number(row.dongia),
                                            expiration: row.expiration && moment(row.expiration),
                                            depreciation: row.depreciation && moment(row.depreciation),
                                            note: row.note,
                                            _equipmentID: row.equipmentID,
                                            serial: row.serial,
                                            infomation: row.infomation,
                                            location: row.location,
                                            status: row.status,
                                            userid: row.userid < 0 ? null : row.userid,
                                            dateinuse: row.dateinuse ? moment(row.dateinuse) : moment()
                                        })
                                    }}><i className="fa fa-edit" />
                                </button>
                            </Tooltip>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                disabled={!row.removeable}
                                onConfirm={() => { this.deleteEquipment(row.createID) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className={!row.removeable ? "btn btn-light" : "btn btn-danger"}
                                    title={!row.removeable ? "Tài sản đã bàn giao hoặc đang bảo trì" : ""}
                                    style={{ marginLeft: 10, cursor: !row.removeable && "not-allowed" }}>
                                    <i className="fa fa-trash" />
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.template()}>Tải file excel mẫu</button>
                </Menu.Item>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ visibleImport: true })}>Import dữ liệu</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={500}
                    visible={this.state.visibleImport}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div >
                            <Dragger
                                {...props}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}
                                onChange={info => {
                                    this.setState({
                                        loaded: info.file.percent,
                                    })
                                    if (info.file.status === 'uploading') {
                                        notification.info({ message: `Đã upload: ${info.file.percent} %` })
                                    }
                                    if (info.file.status === 'done') {
                                        notification.success({ message: "Đã hoàn tất upload" });
                                        this.handleUpload();
                                    } else if (info.file.status === 'error') {
                                        notification.error({ message: `Không thể upload file: ${info.file.name}` });
                                    }
                                }}>
                                <div style={{ position: 'relative', width: 400 }}>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                            <h5 style={{ textTransform: "uppercase", textAlign: "center", display: this.state.showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</h5>
                            {this.state.insertRow > 0 && <p style={{ display: this.state.insertRow > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="green">{`${this.state.insertRow}`}</Tag> hàng dữ liệu.</p>}
                            {this.state.updateRow > 0 && <p>Không thực hiện cập nhật với <Tag color="red">{this.state.updateRow}</Tag>mã tài sản: {this.state.errorList}</p>}
                            {this.state.rejectRow > 0 && <p>Không thể thêm do thiếu thông tin với <Tag color="red">{this.state.rejectRow}</Tag>tài sản: {this.state.rejectList}</p>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.handleUpload()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        visibleImport: false,
                                        fileList: [],
                                        insertRow: 0,
                                        updateRow: 0,
                                        errorList: "",
                                        rejectRow: 0,
                                        rejectList: "",
                                        showImportResult: false
                                    });
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        );
        return (
            1===1 ? <UnSupport title="Chức năng này chưa hỗ trợ ở thời điểm hiện tại" /> :
            <>
                <div className='containerChilderWrapper' >
                    <Tabs onChange={() => { this.loadListEquipments(); this.getListTypes() }}>
                        <TabPane tab={"Danh sách tài sản"} key="1">
                            <div style={{ padding: "0px 5px", height: '100%' }}>
                                <div className="h4 font-bold text-uppercase text-center">Quản lý tài sản cố định</div>
                                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                                <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="col-md-3 col-sm-12 mt-2">
                                        <Input placeholder="Nhập tên, mã hoặc hãng để tìm kiếm" value={this.state.key}
                                            onChange={e => {
                                                this.setState({
                                                    key: e.target.value
                                                });
                                                task = setTimeout(() => {
                                                    this.loadListEquipments(1, this.state.pageSize);
                                                }, 700);
                                            }}>
                                        </Input>
                                    </div>
                                    <div className="col-md-2 col-sm-6 mt-2">
                                        <Select value={this.state.expiredTime}
                                            className="w-100"
                                            onChange={(e) => {
                                                this.setState({
                                                    expiredTime: e
                                                });
                                                task = setTimeout(() => {
                                                    this.loadListEquipments(1, this.state.pageSize);
                                                }, 200);
                                            }}>
                                            <Option value=''>Tất cả thời hạn bảo hành</Option>
                                            <Option value='0'>Đã hết hạn bảo hành</Option>
                                            <Option value='3'>Hết hạn trong 3 tháng tới</Option>
                                            <Option value='6'>Hết hạn trong 6 tháng tới</Option>
                                            <Option value='12'>Hết hạn trong 1 năm tới</Option>
                                        </Select>
                                    </div>
                                    <div className="col-md-2 col-sm-6 mt-2">
                                        <Select value={this.state.depreciatedTime}
                                            className="w-100"
                                            onChange={(e) => {
                                                this.setState({
                                                    depreciatedTime: e
                                                });
                                                task = setTimeout(() => {
                                                    this.loadListEquipments(1, this.state.pageSize);
                                                }, 200);
                                            }}>
                                            <Option value=''>Tất cả thời hạn khấu hao</Option>
                                            <Option value='0'>Đã hết khấu hao</Option>
                                            <Option value='3'>Hết khấu hao trong 3 tháng tới</Option>
                                            <Option value='6'>Hết khấu hao trong 6 tháng tới</Option>
                                            <Option value='12'>Hết khấu hao trong 1 năm tới</Option>
                                        </Select>
                                    </div>
                                    <div>
                                        <button className="btn btn-success mt-2"
                                            style={{ marginLeft: 10 }}
                                            onClick={() => {
                                                this.setState({
                                                    visibleCreate: !this.state.visibleCreate
                                                });
                                            }}>
                                            <i className="fa fa-plus" style={{ marginRight: 5 }} />Tạo mới
                                        </button>
                                        <button className="btn btn-primary mt-2" style={{ marginLeft: 10 }} onClick={() => this.export()}><i className="fas fa-download" style={{ marginRight: 5 }} />Xuất danh sách</button>
                                        <Dropdown
                                            overlay={menu} trigger={['click']}
                                            onClick={e => e.preventDefault()}>
                                            <button className="btn btn-primary mt-2"
                                                style={{ marginLeft: 10 }}>
                                                <i className="fa fa-upload" style={{ marginRight: '10px' }} />
                                                {this.state.uploading ? "Đang tải lên" : "Nhập danh sách"}
                                            </button>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div style={{ height: 'calc(100% - 145px)', marginTop: 20 }}>
                                    <Table
                                        dataSource={this.state.listEquipments}
                                        columns={columns}
                                        rowKey={row => "equipment-tbl-" + row.createID}
                                        pagination={false}
                                        scroll={{ y: 0, x: 'max-content' }}
                                        rowClassName={(row) => {
                                            if (row.removeable && row.status === 1) {
                                                return "bg-danger";
                                            }
                                        }} />
                                </div>
                                <Pagination
                                    current={this.state.currentPage}
                                    style={{ textAlignLast: "center", marginTop: "20px" }}
                                    total={totalRecords}
                                    showTotal={total => `Tổng: ${total}`}
                                    onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadListEquipments(page, size); }}
                                    onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadListEquipments(1, size); }}
                                    showSizeChanger={true}
                                />
                            </div>

                        </TabPane>
                        <TabPane tab={"Bàn giao - thu hồi"} key="2" onChange={() => this.History.getHistories()}>
                            <History ref={(c) => this.History = c} />
                        </TabPane>
                        <TabPane tab={"Bảo trì"} key="3" onChange={() => this.Maintenance.getMaintenance()}>
                            <Maintenance ref={(c) => this.Maintenance = c} />
                        </TabPane>
                        <TabPane tab="Danh mục loại tài sản" key="4" onChange={() => this.getTypeOfEq.loadListTypes()}>
                            <Type ref={(c) => this.getTypeOfEq = c} />
                        </TabPane>
                        <TabPane tab="Danh sách bộ phận" key="6" onChange={() => this.Location.loadListLocation()}>
                            <Location ref={(c) => this.Location = c} />
                        </TabPane>
                        <TabPane tab="Kiểm kê tài sản" key="5" onChange={() => this.kiemKe.loadListKiemKe("month", moment().format("MM/YYYY"))}>
                            <KiemKe ref={(c) => this.kiemKe = c} />
                        </TabPane>
                    </Tabs>
                </div>
                <Modal
                    visible={this.state.visibleCreate}
                    width={1200}
                    onCancel={this.cancelAction}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <Create onCancel={this.cancelAction} onReload={this.reloadAction} />
                </Modal>
                <Modal
                    visible={this.state.visibleUpdate}
                    width={1200}
                    onCancel={this.cancelAction}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>

                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Cập nhật thông tin tài sản cố định</h3>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label>Ngày nhập</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.createTime}
                                onChange={(e) => this.setState({ createTime: e })}
                                allowClear={false}
                                disabledDate={(current) => { return current && (current < moment().subtract(3, 'months')) }} />
                            {this.validator.message("Thời gian nhập liệu", this.state.createTime, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Loại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <Input style={{ display: "none" }} value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}></Input>
                            <Select
                                className="w-100"
                                value={this.state.type}
                                showSearch
                                onChange={(e) => {
                                    this.setState({ type: e });
                                    this.generateCode(e);
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.state.lstTypes.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.typeCode}>{row.typeName}</Option>
                                    )
                                })}
                            </Select>
                            {this.validator.message("Loại tài sản", this.state.type, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Bộ phận</label>
                            <Select className="w-100"
                                value={this.state.location}
                                placeholder="Chọn bộ phận"
                                allowClear={true}
                                onChange={(e) => {
                                    this.setState({ location: e });
                                    if (e !== 1) {
                                        this.setState({
                                            userid: null,
                                            dateinuse: null
                                        });
                                    }
                                }}>
                                {this.state.listLocation && this.state.listLocation.map((loc, index) => {
                                    return (
                                        <Option value={loc.code} key={index}>{loc.location}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <label>Mã tài sản</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                </div>
                                <Checkbox checked={this.state.isChecked} onChange={(e) => this.setState({ isChecked: e.target.checked })}>Tùy chỉnh</Checkbox>
                            </div>
                            <Input disabled={!this.state.isChecked} value={this.state.equipmentID} onChange={(e) => this.setState({ equipmentID: e.target.value })} maxLength={10}></Input>
                            {this.validator.message("Mã tài sản", this.state.equipmentID, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Tên tài sản</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <Input value={this.state.equipmentName} maxLength={100} onChange={(e) => this.setState({ equipmentName: e.target.value })}></Input>
                            {this.validator.message("Tên tài sản", this.state.equipmentName, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Hãng</label>
                            <Input value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })}></Input>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <label>Giá</label>
                            <InputNumber className="w-100" value={this.state.price} min={0} max={999999999} maxLength={11}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(e) => this.setState({ price: e })} />
                            {this.validator.message("Giá", this.state.price, "required")}
                        </div>
                        <div className="col-md-4">
                            <label>Hạn bảo hành</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"}
                                value={this.state.expiration}
                                onChange={(e) => this.setState({ expiration: e })}
                                disabledDate={(current) => { return current && (current < moment("1990-01-01")) }} />
                        </div>
                        <div className="col-md-4">
                            <label>Hạn khấu hao</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.depreciation}
                                disabledDate={current => { return current <= this.state.expiration }}
                                onChange={(e) => this.setState({ depreciation: e })} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <label>Ghi chú</label>
                            <Input value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })}></Input>
                        </div>
                        <div className="col-md-4">
                            <label>Serial</label>
                            <Input value={this.state.serial} maxLength={50} onChange={(e) => this.setState({ serial: e.target.value })}></Input>
                        </div>
                        <div className="col-md-4">
                            <label>Cấu hình</label>
                            <Input value={this.state.infomation} maxLength={100} onChange={(e) => this.setState({ infomation: e.target.value })}></Input>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4">
                            <label>Trạng thái <i style={{ color: "#ccc" }}>(Chuyển bàn giao ở đây)</i></label>
                            <Select className="w-100" value={this.state.status} placeholder="Chọn trạng thái"
                                onChange={(e) => {
                                    this.setState({ status: e });
                                    this.validator.purgeFields();
                                    if (e !== 1) {
                                        this.setState({
                                            userid: null,
                                            dateinuse: null
                                        });
                                    }
                                }}>
                                <Option value={0}>Trong kho</Option>
                                <Option value={1}>Đang sử dụng</Option>
                                <Option value={2}>Thanh lý</Option>
                            </Select>
                        </div>
                        <div className="col-md-4" style={{ visibility: this.state.status === 1 ? "visible" : "hidden" }}>
                            <label>Cấp cho</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <Select className="w-100" value={this.state.userid} showSearch
                                onChange={(e) => this.setState({ userid: e })}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.state.lstUsers.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.hoten}</Option>
                                    )
                                })}
                            </Select>
                            {this.state.status === 1 && this.validator.message("Người sử dụng", this.state.userid, "required")}
                        </div>
                        <div className="col-md-4" style={{ visibility: this.state.status === 1 ? "visible" : "hidden" }}>
                            <label>Ngày cấp</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                            <DatePicker locale={viVN} className="w-100" format={"DD-MM-YYYY"} value={this.state.dateinuse}
                                disabledDate={current => { return current <= moment().subtract(5, 'years') || current >= moment().add(3, 'months') }}
                                onChange={(e) => this.setState({ dateinuse: e })}></DatePicker>
                            {this.state.status === 1 && this.validator.message("Ngày cấp", this.state.dateinuse, 'required')}
                        </div>
                    </div>
                    <div className="mt-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="btn btn-success"
                            onClick={() => {
                                this.updateEquipment();
                            }}>
                            <i className="fa fa-save" style={{ marginRight: 5 }} />Cập nhật
                        </button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.cancelAction()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
            </>
        )
    }
}