import * as ApiCaller from '../libs/httpRequests';
import { FORMAT_YYYYMMDD } from '../configure/systemConfig';
import moment from 'moment';

const prefix = 'PatientStatictic';

export function PatientStatisticChart(type, fromDate, toDate, clsType, chinhanh) {
    return new Promise((resolve, reject) => {
        let url = `${prefix}/PatientStatisticChart?type=${type}&fromDate=${fromDate}&clsType=${clsType}&chinhanh=${chinhanh}`
        if (toDate != null) {
            url += `&toDate=${toDate}`
        }
        return ApiCaller.httpGet(url).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function PatientStatistic(dateFrom, dateTo, chinhanh, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PatientStatistic?dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportPatientStatistic(dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportPatientStatistic?dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetPatientStatistic(dateFrom = null, dateTo = null, page = 1, size = 10, site = "", keyword = "", getAll = false) {
    let skipCount = (page - 1) * size;
    let filter = "";
    if (keyword)
        filter = `&keyword=${keyword}`;
    if (site && site !== "")
        filter = `&site=${site}`;
    if (dateFrom !== null && dateTo !== null) {
        filter += `&dateFrom=${moment(dateFrom).format(FORMAT_YYYYMMDD)}&dateTo=${moment(dateTo).format(FORMAT_YYYYMMDD)}`;
    }
    filter += `&getAll=${getAll}`;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${size}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetPatientStatistic${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportPatientStatisticV2(dateFrom = null, dateTo = null, page = 1, size = 10, site = "", keyword = "", getAll = false) {
    let skipCount = (page - 1) * size;
    let filter = "";
    if (keyword)
        filter = `&keyword=${keyword}`;
    if (site && site !== "")
        filter = `&site=${site}`;
    if (dateFrom !== null && dateTo !== null) {
        filter += `&dateFrom=${moment(dateFrom).format(FORMAT_YYYYMMDD)}&dateTo=${moment(dateTo).format(FORMAT_YYYYMMDD)}`;
    }
    filter += `&getAll=${getAll}`;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${size}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportPatientStatisticV2${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};