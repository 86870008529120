import React, { Component } from 'react';
import { Form, Input, Table, Popconfirm, Modal, notification, Pagination } from 'antd';
import * as cfgService from '../../../services/configService';
import CreateLoaiPhong from '../components/createNewLoaiPhong';
import SimpleReactValidator from 'simple-react-validator';
let task = null;
const initialState = {
    visible: false,
    visibleCreate: false,
    totalRecords: 0
}
export default class DanhSachLoaiPhong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            currentPage: 1,
            pageSize: 10,
            lstLoaiPhong: [],
            key: "",
            id: 0,
            tenloaiphong: "",
            maloaiphong: '',
            loadingHide: 0
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadLoaiPhong();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        })
    }

    reset() {
        this.state({
            lstLoaiPhong: [],
            key: "",
            id: 0,
            tenloaiphong: "",
            loadingHide: 0
        });
        this.form.setFieldsValue({
            'tenloaiphong': ''
        })
    }
    onReload = () => {
        this.loadLoaiPhong();
    }
    loadLoaiPhong(page = this.state.currentPage, size = this.state.pageSize) {
        cfgService.getLoaiPhong(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiPhong: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        })
    }
    updateLoaiPhong() {
        if (this.validator.allValid()) {
            const data = {
                'id': this.state.id,
                'tenLoaiPhong': this.state.tenloaiphong,
                'maLoaiPhong': this.state.maloaiphong
            }
            cfgService.updateLoaiPhong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    let list = this.state.lstLoaiPhong;
                    this.setState({ lstLoaiPhong: list });
                    this.onReload();
                    this.handleCancel();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    // updateHide(id, check) {
    //     this.setState({ loadingHide: true })
    //     let status = 0;
    //     if (check === false) {
    //         status = 1;
    //     }
    //     cfgService.updateHideLoaiPhong(id, status).then(result => {
    //         let data = this.state.lstLoaiPhong;
    //         let item = data.find(x => x.id === id);
    //         if (result.isSuccess) {
    //             if (item !== undefined) {
    //                 const tmp = item.hienthi === 1 ? 0 : 1;
    //                 item.hienthi = tmp;
    //             }
    //             notification.success({ message: 'Cập nhật thành công!' })
    //         }
    //         else {
    //             if (item !== undefined) {
    //                 const newcheck = item.hienthi;
    //                 item.hienthi = newcheck;
    //             }
    //             Actions.openMessageDialog("Lỗi", result.err.msgString)
    //         }
    //         this.setState({
    //             lstLoaiPhong: data,
    //             loadingHide: false,
    //         })
    //     }).catch(err => {
    //         let data = this.state.lstLoaiPhong;
    //         let item = data.find(x => x.id === id);
    //         if (item !== undefined) {
    //             const newcheck = item.hienthi;
    //             item.hienthi = newcheck;
    //         }
    //         this.setState({ loadingHide: false, lstLoaiPhong: data, })
    //         notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
    //     })
    // }
    deleteLoaiPhong(idLoaiPhong) {
        cfgService.deleteLoaiPhong(idLoaiPhong).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstLoaiPhong;
                this.setState({ lstLoaiPhong: list });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + (Number(index) + 1))
            },
            {
                title: 'Mã',
                dataIndex: 'maLoaiPhong',
                key: 'maLoaiPhong',
                align: 'center'
            },
            {
                title: 'Tên',
                dataIndex: 'tenLoaiPhong',
                key: 'tenLoaiPhong',
                align: 'left'
            },
            // {
            //     title: 'Hiển thị',
            //     align: 'center',
            //     width: 200,
            //     render: (row) => {
            //         return (
            //             <Switch
            //                 loading={this.state.loadingHide}
            //                 checkedChildren="Hiển thị"
            //                 unCheckedChildren="Ẩn"
            //                 checked={row.hienthi === 0}
            //                 onChange={(e) => { this.updateHide(row.id, e); }}
            //             />
            //         )
            //     }
            // },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="btn btn-warning"
                                onClick={() => {
                                    this.setState({
                                        id: row.id,
                                        tenloaiphong: row.tenLoaiPhong,
                                        maloaiphong: row.maLoaiPhong,
                                        visible: true
                                    });
                                }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteLoaiPhong(row.id) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        let dataSource = this.state.lstLoaiPhong.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: '30px 10px' }}>
                        <div
                            className="row no-gutters"
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Form.Item>
                                <Input
                                    placeholder="Nhập tên loại phòng để tìm kiếm"
                                    value={this.state.key}
                                    style={{
                                        width: 400
                                    }}
                                    onChange={(e) => this.setState({
                                        key: e.target.value
                                    })}
                                    onKeyDown={() => {
                                        task = setTimeout(() => {
                                            this.loadLoaiPhong();
                                        }, 300);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={this.showModalCreate}>
                                    <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                                    Tạo mới
                                </button>
                            </Form.Item>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                rowkey={(index) => index}
                                pagination={false}
                                scroll={{ x: 'max-content', y: 0 }}
                            />
                        </div>
                        <Modal
                            maskClosable={false}
                            width={800}
                            visible={this.state.visibleCreate}
                            cancelText="Đóng"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <CreateLoaiPhong
                                onCancel={this.handleCancel}
                                onReload={this.onReload}
                            />
                        </Modal>
                        <Modal maskClosable={true}
                            width={800}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Cập nhật loại phòng nội trú</h3>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <Form.Item style={{ width: 300 }} label="Mã loại phòng:" name="maloai">
                                    <Input
                                        value={this.state.maloaiphong}
                                        onChange={(e) => this.setState({ maloaiphong: e.target.value })}
                                    />
                                    {this.validator.message("Mã loại phòng", this.state.maloaiphong, "required|max:10")}
                                </Form.Item>
                                <Form.Item style={{ width: 400 }} label="Loại phòng:" name="tenloaiphong">
                                    <Input
                                        value={this.state.tenloaiphong}
                                        onChange={(e) => this.setState({ tenloaiphong: e.target.value })}
                                    />
                                    {this.validator.message("Tên loại phòng", this.state.tenloaiphong, "required")}
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-success" onClick={() => this.updateLoaiPhong()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({ visible: false });
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadLoaiPhong(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadLoaiPhong(page, size)
                            }
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}