import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import HeaderModal from "../../../configureHIS/common/headerModal";
import PatientInfo from "../../../screens/patient/component/patientInfo";
import VitalSignInfo from "../../../screens/patient/component/vitalSign";
import History from '../../../components/history/indexNew';
import MutilMedia from '../../../components/MitaComponents/UploadFile/mitaUploadFile';
import { Collapse, Drawer } from 'antd';
import { HISTORYFEATURE, HISTORYFEATURETYPE } from '../../../configureHIS/common/constants';
import SlideShow from './Component/slideShow';
import Speaker from '../../../components/speaker';
import Notify, { NotifyStatus } from '../../../components/notify';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
import moment from 'moment';
import * as SystemConfig from '../../../configure/systemConfig';
import * as PacsService from '../../../services/pascService';
import * as Actions from '../../../libs/actions';
import Schedule from '../../lichhen/schedule';
import { IsActive_FeatureLH } from '../../../configure/featureConfig';

const { Panel } = Collapse;
const showMoreText = "...Xem thêm";
const showLessText = " Rút gọn";

const PACSDetail = forwardRef((props, ref) => {
    const [data, setData] = useState(props.data);
    const [visibleSlideShow, setVisibleSlideShow] = useState(false);
    const [chanDoan, setChanDoan] = useState();
    const [isDone, setIsDone] = useState();
    const [moTa, setMoTa] = useState();
    const [ketLuan, setKetLuan] = useState();
    const [deNghi, setDeNghi] = useState();
    const [ngayGioHen, setNgayGioHen] = useState();
    const [PACSType, setPACSType] = useState('')
    const [technician, setTechnician] = useState('');
    const historyRef = useRef();
    const _notiRef = useRef();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setData(props.data);
        if (props.data.appointmentId) {
            getAppoimentDetail(props.data.appointmentId);
            forceUpdate();
        }
    }, [props.data]);

    const onOpenSlideShow = (e) => {
        e.stopPropagation();
        setVisibleSlideShow(true);
    }

    const onCloseSlideShow = () => {
        setVisibleSlideShow(false);
    }

    const getAppoimentDetail = (id) => {
        Actions.setLoading(true);
        PacsService.getPACSAppoimentDetail(id).then(result => {
            if (result.isSuccess) {
                const data = result.data;
                if (data.icd) {
                    let ICDs = data.icd.map(x => x.label).join(", ");
                    setChanDoan(ICDs);
                } else setChanDoan(data.diagnostic);
                setIsDone(data.isDone);
                setMoTa(data.description);
                setKetLuan(data.result);
                setDeNghi(data.advice);
                setNgayGioHen(data.appointmentSchedule && moment(data.appointmentSchedule).format(SystemConfig.FORMAT_DATE_TIME));
                setPACSType(data.pacsType)
                setTechnician(data.technician)
                _notiRef && _notiRef.current.Close();
            }
            else {
                resetState();
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const resetState = () => {
        setChanDoan();
        setIsDone();
        setMoTa();
        setKetLuan();
        setDeNghi();
        setNgayGioHen();
    }

    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={`${data.serviceName} - ${data.patientName}`} onClose={() => { props.onCancel() }} />
                <div className="list-page-body">
                    <div className="w-100 h-100 d-flex justify-content-between" style={{ overflowY: "auto" }}>
                        <div style={{ width: '60%', overflowY: "auto" }}>
                            <Collapse defaultActiveKey={['pif', 'multimedia']} expandIconPosition="right">
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={props.data.patientCode} col={12}></PatientInfo>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>} key="vts">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <VitalSignInfo maql={props.data.managermentId} col={12}></VitalSignInfo>
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title d-flex justify-content-between align-items-center w-100'>
                                    <span>Thư viện hình ảnh & video</span>
                                    <span onClick={(e) => { e.stopPropagation(); onOpenSlideShow(e); }}><i className="far fa-play-circle"></i></span>
                                </label>} key="multimedia">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <MutilMedia col={12}
                                            featureName="cdha"
                                            componentKey="CDHA-Multimedia"
                                            refId={props.data.appointmentId.toString()}
                                            isEdit={false}
                                            showPreview={true}
                                            layout="SmallIcons"
                                        >
                                        </MutilMedia>
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="htr">
                                    <div className="w-100 my-2" style={{ lineHeight: "20px" }}>
                                        <History 
                                            ref={historyRef} 
                                            iShowResult={true} 
                                            feature= {[HISTORYFEATURE.CDHA]}
                                            type= {[PACSType.toString()]}
                                            patientID={props.data.patientCode} 
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="h-100 w-100 position-relative" style={{ overflowY: "auto" }}>
                            <div className='h-100 mx-2 border position-relative'>
                                <Collapse defaultActiveKey={['xctcdha']} expandIconPosition="right">
                                    <Panel
                                        key="xctcdha"
                                        header={
                                            <div className='d-flex justify-content-between align-items-center w-100'>
                                                <label className='mita-header-title'>Thông tin {data.serviceName}</label>
                                                <span onClick={(e) => e.stopPropagation()}><Speaker sentence={`Mời khách hàng ${data.patientName} vào phòng ${props.roomName && props.roomName.toLowerCase().replace("phòng ", "")}`}
                                                    notShowButton={true}></Speaker></span>
                                            </div>
                                        }
                                    >
                                        <Notify ref={_notiRef} />
                                        <div className='row'>
                                            <div className='col-6 mt-2'>
                                                <label className='font-weight-bold'>Chẩn đoán ban đầu</label>
                                                <div>{chanDoan}</div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label className='font-weight-bold'>Trạng thái</label>
                                                <div>{isDone ? `Đã thực hiện bởi ${technician}` : "Chưa thực hiện"}</div>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Mô tả</label>
                                                {/* <MitaDisplayLongText
                                                    content={moTa}
                                                    maxLength={100}
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                    displayMode="inner"
                                                ></MitaDisplayLongText> */}
                                                <div className='table-collapse-pasc text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${moTa !== null ? moTa : ''}` }}></div>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Kết luận</label>
                                                {/* <MitaDisplayLongText
                                                    content={ketLuan}
                                                    maxLength={100}
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                    displayMode="inner"
                                                ></MitaDisplayLongText> */}
                                                <div className='table-collapse-pasc text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${ketLuan !== null ? ketLuan : ''}` }}></div>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Đề nghị</label>
                                                <MitaDisplayLongText
                                                    content={deNghi}
                                                    maxLength={100}
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                    displayMode="inner"
                                                ></MitaDisplayLongText>
                                            </div>
                                            {IsActive_FeatureLH() &&
                                                <div className='col-12 mt-2'>
                                                    <Schedule
                                                        isPreview={true}
                                                        data={{
                                                            managermentId: data.managermentId,
                                                            serviceId: data.serviceId,
                                                            patientCode: data.patientCode,
                                                        }} />
                                                </div>}
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Đính kèm tập tin</label>
                                                <MutilMedia col={12}
                                                    featureName="cdha"
                                                    componentKey="CDHA-Attachment"
                                                    refId={props.data.appointmentId.toString()}
                                                    isEdit={false}
                                                    layout="Details"
                                                >
                                                </MutilMedia>
                                            </div>
                                        </div>
                                        <div className="row mt-2 my-2">
                                            <div className='col-12 d-flex justify-content-between'>
                                                <a href='#'
                                                    // onClick={() => {props.printData(data.appointmentId, data.managermentId, data.technicalId, data.patientCode, data.appointmentDate)}}
                                                    onClick={() => {props.printData()}}
                                                ><i className="fas fa-barcode mr-1" />In kết quả<i className="fas fa-print ml-1" /></a>
                                                <div>
                                                    {isDone &&
                                                        <a href='#' className='mr-2' onClick={() => { props.hanldeUpdate() }}><i className="fas fa-pen mr-1" />Chỉnh sửa</a>}
                                                    <button className="btn btn-danger" onClick={() => props.onCancel()}><i className="fas fa-times-circle mr-1" />Đóng</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"50%"}
                placement="right"
                visible={visibleSlideShow}
                destroyOnClose={true}
                onCancel={() => { onCloseSlideShow() }}>
                <SlideShow
                    componentKey="CDHA-Multimedia"
                    refId={props.data.appointmentId.toString()}
                    onCancel={onCloseSlideShow}
                ></SlideShow>
            </Drawer>
        </Fragment>
    );
})

export default PACSDetail;