import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Input, Table, DatePicker, Select, notification, Modal, Tooltip, Popconfirm, Pagination } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import PhieuHoantra from './phieuhoantra';
import * as KhoService from '../../../../services/KhoService';
import * as PrintService from '../../../../services/printService';
import * as Actions from '../../../../libs/actions';
import phieuhoantra from "./phieuhoantra";
import * as SystemConfig from "../../../../configure/systemConfig";
import HeaderModal from "../../../../configureHIS/common/headerModal";


const {Option} = Select;
const {RangePicker} = DatePicker;

function DanhSachHoanTra(props){
    const [lstHoantra, setLstHoantra] = useState([])
    const [date, setDate] = useState([moment(),moment()])
    const [detailHoantra, setDetailHoantra] = useState(null)
    const [visibleDetail, setVisibleDetail] = useState(false)
    const [id, setId] = useState(0)
    const [lstKho, setLstKho] = useState([])
    const [makho, setMakho] = useState('')
    const [key, setKey] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentSize, setCurrentSize] = useState(10)
    const _hiddenLink = useRef()
    const _phieuhoantra = useRef()

    useEffect(() => {
        loadlistKho();
        search();
    }, [])

    useEffect(() => {
        search();
    }, [date, currentPage, currentSize])
    
    const search = () => {
        Actions.setLoading(true);
        KhoService.LstHoanTra(key, date[0].format('YYYY-MM-DD'), date[1].format('YYYY-MM-DD'),currentPage,currentSize).then(result => {
            if (result.isSuccess) {
                setLstHoantra(result.data)
                setTotalRecords(result.totalRecords)
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách hoàn trả' });
        }).finally(() => Actions.setLoading(false))
    }

    const detailHoanTra = (row, status) => {
        Actions.setLoading(true);
        KhoService.detailHoanTra(row.id).then(result => {
            if (result.isSuccess) {
                if(_phieuhoantra && _phieuhoantra.current){
                    _phieuhoantra.current.setData(result.item, status);
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Lỗi ở đây nè' });
        }).finally(() => Actions.setLoading(false))
    }

    const deleteHoanTra = (row) => {
        Actions.setLoading(true);
        KhoService.deleteHoantra(row.id).then(res => {
            if(res.isSuccess){
                notification.success({message : 'Đã xóa thành công.'})
                search();
            }
        }).catch(err => {
            notification.warning({ message: 'Lỗi ở đây nè' });
        }).finally(() => Actions.setLoading(false))
    }

    const loadlistKho = () => {
        KhoService.GetListKho("all").then(result => {
            if (result.isSuccess) {
                setLstKho(result.data);
                setMakho(result.data[0].value);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }

    const handleCancel = () => {
        setVisibleDetail(false)
        search()
    }

    const printPhieuHoantra = (row) => {
        Actions.setLoading(true);
        PrintService.PrintPhieuHoanTra(row.id, moment().format("YYYY-MM-DD")).then((result) => {
            Actions.DoPrint(result.item)
        }).finally(() => Actions.setLoading(false))
    }
    
    const columns = [
        {
            title: 'Mã phiếu hoàn trả',
            dataIndex: 'sophieu',
            key: 'sophieu',
            align: 'left',
            width: 150
        },
        {
            title: 'Mã phiếu nhập',
            dataIndex: 'sophieu2',
            key: 'id',
            align: 'left',
            width: 150,
        },
        {
            title: 'Ngày hoàn trả',
            dataIndex: 'ngaynhap',
            key: 'ngaynhap',
            align: 'left',
            width: 150,
            render: (value) => {
                return <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>;
            }
        },
        {
            title: 'Người hoàn trả',
            dataIndex: 'nguoinhap',
            key: 'nguoinhap',
            align: 'left',
            width: 200,
        },
        {
            title: 'Hóa đơn số',
            dataIndex: 'sohoadon',
            key: 'sohoadon',
            align: 'left',
            width: 120,
        },
        {
            title: 'Người nhận',
            dataIndex: 'tennguoinhan',
            key: 'tennguoinhan',
            align: 'left',
            width: 150,
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 300,
            render: (row) => {
                return (
                <div>
                    <Tooltip title="Xem thông tin">
                        <button
                            className="btn btn-color"
                            title="Xem thông tin!"
                            onClick={() => { 
                                setId(id)
                                setVisibleDetail(true)
                                detailHoanTra(row, true) 
                            }} >
                            <i className="fa fa-eye"/>
                        </button>
                    </Tooltip>
                    {/* <Tooltip title="Cập nhật thông tin">
                        <button type="primary"
                            style={{ marginLeft: 10 }}
                            className="btn btn-color"
                            title="Cập nhật sản phẩm!"
                            onClick={() => { 
                                setId(id)
                                setVisibleDetail(true)
                                detailHoanTra(row, false) 
                            }} >
                            <i className=" fa fa-edit"/>
                        </button>
                    </Tooltip> */}
                    <Popconfirm
                        title="Bạn có chắc muốn xóa?"
                        onConfirm={() => { 
                            setId(id)
                            deleteHoanTra(row, false) 
                            }}
                        okText="Đồng ý" cancelText="Hủy">
                        <Tooltip title="Xóa phiếu" placement='bottom'>
                            <button className="btn btn-danger ml-1"
                                ><i className="fa fa-eraser" /></button>
                        </Tooltip>
                    </Popconfirm>
                    <Tooltip title="In phiếu">
                        <button className="btn btn-primary ml-1"
                            onClick={() => { printPhieuHoantra(row) }} >
                            <i className="fa fa-print"/></button>
                    </Tooltip>
                </div>
                )
            }
        }
    ];

    const columnsDetail = [
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'left',

        },
        {
            title: 'Lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
        },
        {
            title: 'Ngày sản xuất',
            dataIndex: 'ngsx',
            key: 'ngsx',
            align: 'left',
            render: (value) => {
                if (value === null) return <span>Không xác định</span>
                if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                    return <span>Không xác định</span>
                }
                else {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            align: 'left',
            render: (value) => {
                if (value === null) return <span>Không xác định</span>
                if (moment(value).format('DD/MM/YYYY') === '01/01/0001') {
                    return <span>Không xác định</span>
                }
                else {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
        },
        {
            title: 'Nước',
            dataIndex: 'tennuoc',
            key: 'tennuoc',
            align: 'left',
        },
        {
            title: 'Số lượng',
            dataIndex: 'soluong',
            key: 'soluong',
            align: 'right',
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Đơn giá',
            dataIndex: 'giaban',
            key: 'giaban',
            align: 'right',
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
        },
    ];

    return (
        <React.Fragment>
            <div className='containerChilderWrapper' >
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
                <HeaderModal title="Danh sách hoàn trả" />
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4 mt-2 d-none">
                        <Select onChange={(e) => { setMakho(e) }}
                            value={makho}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={-1} value={''}>Tất cả</Option>
                            {lstKho.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-md-4 mt-2">
                        <RangePicker format="DD-MM-YYYY" value={date} allowClear={false} locale={viVN} onChange={(e) => setDate(e)}/>
                    </div>
                    <div className="col-md-4 mt-2">
                        <Input
                            value={key}
                            placeholder="Nhập số phiếu hoàn trả, số phiếu nhập"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    search();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col-auto mr-auto mt-2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <button className="btn btn-color"
                            onClick={() => setVisibleDetail(true)}
                        ><i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                    </div>
                    <div className="col-auto mt-2">
                        <button className="btn btn-color" onClick={() => { search() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    </div>
                    {/* <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <button className="btn btn-success"
                            //onClick={() => setVid}
                        ><i style={{ marginRight: 5 }} className="fa fa-plus" />In </button>
                    </div> */}
                </div>            
                <div className="mt-2" style={{ width: '100%', height: 'calc(100% - 180px)' }}>
                    <Table dataSource={lstHoantra} columns={columns}
                        scroll={{ x: 'max-content', y: 0 }}
                        rowKey={(e, index) => index}
                        rowClassName={(record, rowIndex) => {
                            return "rowCustom"
                        }}
                        pagination={false}
                    />
                    {(lstHoantra ?? []).length > 0 && <Pagination
                            current={currentPage}
                            size={currentSize}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { setCurrentPage(page); setCurrentSize(size) }}
                            onShowSizeChange={(page, size) => { setCurrentPage(1); setCurrentSize(size) }}
                            showSizeChanger={true}
                        />}
                </div>

                <Modal maskClosable={false}
                    width={1400}
                    visible={visibleDetail}
                    onCancel={handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <PhieuHoantra ref={_phieuhoantra} onCancel={handleCancel}/>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default DanhSachHoanTra;