import { DeleteFilled } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, notification, Popconfirm, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from '../../../components/autocomplete';
import * as MedicineStore from '../../../services/managerStore';
import * as MedicineService from '../../../services/medicineService';
import * as TuTrucService from '../../../services/tutrucservice';
import * as AppointmentService from '../../../services/appointmentService';

const { TextArea } = Input;
const { Option } = Select;
const initialState = {
    mabn: '',
    tenbn: '',
    maphieu: '',
    madoituong: '',
    makp: '',
    makho: '',
    soluong: 0,
    ghichu: '',
}
export default class TaoPhieuXuatTuTruc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDuocKP: [],
            lstKho: [],
            lstPhieu: [],
            lstAddThuoc: [],
            lstMaterial: [],
            lstDoiTuong: [],
            disabled: false,
        };
    }
    componentWillMount() {
        this.loadDuocKP();
        this.loadKho();
        this.loadPhieu();
        this.loadDoiTuong();
    }
    loadKho() {
        MedicineService.GetListKho().then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value
                });
                this.form.setFieldsValue({ makho: result.data[0].value })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    loadPhieu() {
        TuTrucService.DanhSachLoaiPhieu().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhieu: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách phiếu bị lỗi!' })
        })
    }
    loadDuocKP() {
        TuTrucService.DanhSachDuocKP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDuocKP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dược khám phòng bị lỗi!' })
        })
    }
    loadDoiTuong() {
        TuTrucService.DanhSachDoiTuong().then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstDoiTuong: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu đối tượng bị lỗi' })
        })
    }
    TimKiemBenhNhan(key) {
        AppointmentService.TimKiemBN(key).then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
            }
        }).catch(err => {
        })
    }
    loadMaterial(key) {

        MedicineService.GetListMaterial(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstMaterial: result.data,
                });
            }
        }).catch(err => {
        })
    }
    async AddThuoc() {
        if (this.state.soluong <= 0) {
            notification.warning({ message: 'Số lượng phải lớn hơn 0!' });
            return;
        }
        if (this.state.mabn.length > 8 || this.state.mabn == null) {
            notification.warning({ message: 'Mã bệnh nhân trống hoặc không hợp lệ, tối đa 8 ký tự!' });
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            if (this.validator.allValid() && this.state.id !== 0) {
                MedicineStore.getDetails(this.state.id).then(result => {
                    if (result.isSuccess) {
                        let lst = this.state.lstAddThuoc;
                        const current = lst.find(x => x.ten === result.item.ten)
                        if (current !== undefined) {
                            notification.warning({ message: 'Thuốc đã tồn tại trong danh sách!' })
                            return;
                        }
                        lst.unshift({
                            id: Number(this.state.id),
                            ma: result.item.ma,
                            soluong: Number(this.state.soluong),
                            dang: result.item.dang,
                            ten: result.item.ten,
                            tenhc: result.item.tenhc,
                            tenhang: result.item.tenhang,
                            tennuoc: result.item.tennuoc,
                        })
                        this.setState({
                            disabled: true,
                            soluong: 0,
                            lstAddThuoc: lst.map(d => ({ ...d })),
                        });
                        this.form.setFieldsValue({
                            soluong: 0
                        })
                    }
                }).catch(err => {
                    notification.warning({ message: 'Thêm thuốc bị lỗi. Vui lòng nhập lại!' })
                })
            }
        } else {
            this.validator.showMessages();
        }
    }

    submitCreate() {
        if (this.state.lstAddThuoc.length === 0) {
            notification.warning({ message: 'Không có thuốc nào trong danh sách!' });
            return;
        }
        if (this.state.makp === 0) {
            notification.warning({ message: 'Yêu cầu chọn khoa!' });
            return;
        }
        const taophieuxuattutruc = {
            'mabn': this.state.mabn,
            'makp': Number(this.state.makp),
            'makho': Number(this.state.makho),
            'madoituong': Number(this.state.madoituong),
            'ghichu': this.state.ghichu,
            'phieu': this.state.maphieu
        }
        const data = {
            "taophieuxuattutruc": taophieuxuattutruc,
            'lstAddThuoc': this.state.lstAddThuoc,
        }
        TuTrucService.LuuPhieuXuatTuTruc(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công!' });
                this.reset();
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
        })
    }
    reset() {
        this.setState({
            ghichu:'',
            mabn:'',
            madoituong:'',           
            makp: '',
            id:'',
            maphieu:'',
            soluong: 0,
            disabled: false,
            lstAddThuoc: [],
            lstMaterial: [],
        })
        this.form.setFieldsValue({
            ghichu:null,
            mabn:null,
            madoituong:null,           
            makp: null,
            id:null,
            maphieu:null,
            lstDuocKP: null,
            soluong: 0
        })
    }
    XoaThuoc(record) {
        if (record.id !== 0) {
            let lst = this.state.lstAddThuoc.filter(x => x.ten !== record.ten);
            if (lst.length === 0) {
                this.reset();
            }
            this.setState({ lstAddThuoc: lst.map(d => ({ ...d })) })
        }
    }

    disabledDate(current) {
        return current && current < moment().endOf('day');
    }
    render() {

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
            },
            {
                title: 'ĐVT',
                dataIndex: 'dang',
                key: 'dang',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                render: (record) => {
                    return (
                        <span>

                            <Button type="primary" danger style={{ marginRight: '5px' }}>
                                <Popconfirm
                                    title="Bạn có chắc muốn xóa chỉ định này?"
                                    onConfirm={() => { this.XoaThuoc(record) }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <DeleteFilled style={{ display: 'flex' }} />
                                </Popconfirm>
                            </Button>
                        </span>

                    )
                },
            },
        ];
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{ padding: 10 }}>
                    <div style={{ padding: "5px 20px" }}>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <Form.Item label="Mã BN:" style={{ flex: 1 }} name="mabn" rules={[{ required: true, message: 'Vui lòng nhập mã bệnh nhân!' }]} >
                                <Autocomplete
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstBN}
                                    handleSearch={this.TimKiemBenhNhan.bind(this)}
                                    getItem={(key, value) => { this.setState({ mabn: key, hoten: value }); this.form.setFieldsValue({ mabn: key }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Đối tượng:" name="doituong" style={{ flex: 1 }}
                                rules={[{ required: true, message: 'Yêu cầu chọn đối tượng!' }]}>
                                <Select
                                    showSearch
                                    onChange={(value) => { this.setState({ madoituong: value }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstDoiTuong.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phiếu:" name="phiếu" style={{ flex: 1 }}
                                rules={[{ required: true, message: 'Yêu cầu chọn phiếu!' }]}>
                                <Select
                                    showSearch
                                    onChange={(value) => { this.setState({ maphieu: value }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstPhieu.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Khoa:" style={{ flex: 1 }} name="makp"
                                rules={[{ required: true, message: 'Yêu cầu chọn khoa!' }]}>
                                <Select value={this.state.makp}
                                    disabled={this.state.disabled}
                                    showSearch style={{ width: 200 }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ makp: e }) }}>
                                    {this.state.lstDuocKP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>

                            <Form.Item label="Kho:" name="makho" style={{ flex: 1 }}
                                rules={[{ required: true, message: 'Yêu cầu chọn kho!' }]}>
                                <Select
                                    showSearch
                                    onChange={(value) => { this.setState({ makho: value }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstKho.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Thuốc:" style={{ flex: 1 }}
                                name="id" rules={[{ required: true, message: 'Yêu cầu chọn thuốc!' }]}>
                                <Autocomplete
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstMaterial}
                                    handleSearch={this.loadMaterial.bind(this)}
                                    getItem={(key) => { this.form.setFieldsValue({ id: key }); this.setState({ id: key }); }} />
                            </Form.Item>
                            <Form.Item label="Số lượng:" style={{ flex: 1 }} name="soluong"
                                rules={[{ required: true, message: 'Yêu cầu nhập số lượng!' }]}>
                                <InputNumber value={this.state.soluong}
                                    min={0} maxLength={10}
                                    onChange={(e) => this.setState({ soluong: e })} />
                            </Form.Item>
                            <Form.Item label="Ghi chú:" style={{ flex: 1 }}>
                                <TextArea rows={3}
                                    placeholder="Nhập ghi chú"
                                    value={this.state.ghichu}
                                    onChange={(e => { this.setState({ ghichu: e.target.value }); })}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <Button style={{ margin: "0px 15px 0px 0px" }} onClick={() => this.AddThuoc()}> <i style={{marginRight:10}} className="fa fa-plus"/>Tạo mới</Button>
                            <Button type="primary" onClick={() => this.submitCreate()}> <i style={{marginRight:10}} className="fa fa-save"/>Lưu</Button>
                        </div>
                    </div>

                </Form>
                <Table    scroll={{ y: 0 }} dataSource={this.state.lstAddThuoc} columns={columns} rowKey={(e, index) => index} />

            </React.Fragment>
        )
    }
}


