import React, { Component } from 'react'
import { Input, InputNumber, Form, notification, Button, Select, Modal, Switch, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import *as MedicineService from '../../../../../services/medicineService';
import * as Action from '../../../../../libs/actions';
import Autocomplete from '../../../../../components/autocomplete';
import CreateHSX from '../../../xuatnhapkho/hangsanxuat/CreateModal';
import CreateUnit from '../Unit/';
import { ValidationCustom } from '../../../../../commons/validationCustom'
import HeaderModal from '../../../../../configureHIS/common/headerModal';

const { Option, OptGroup } = Select;
export default class TaoMoiThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            idthuoc: null,
            nuocsx: '',
            idhang: '',
            ma: '',
            ten: '',
            dang: '',
            hamluong: '',
            quycach: '',
            baoquan: null,
            loai: null,
            lstLoai: [],
            lstNuocSX: [],
            lstHangSX: [],
            dongia: 0,
            gia_bh: 0,
            giaban : 0,
			gianhap: 0,
			madang : '',
            donvi: null,
            lstDVT: [],
            modalCreateHSX: false,
            hopchat: '',
            dangthuoc: null,
            lstDangThuoc: [],
            modalCreateUnit: false,
            lstBaoQuan:[],
            hienthi: true,
            tontoithieu: 0,
            donViSuDung: null,
        };
    }
    componentWillMount() {
        this.loadLoaiDMBD();
        this.loadNuocSX();
        this.loadDangThuoc(); 
        this.loadBaoQuan();
    }


    resetState() {
        this.form.setFieldsValue({
            quycach: '',
            nuoc: '',
            idhang: '',
            ma: '',
            ten: '',
            dang: '',
            hamluong: '',
            baoquan:'',
            loai: null,
            dongia: 0,
            donvi: '',
            gia_bh: 0,
            dangthuoc: null,
            donViSuDung: null,
        });
        this.setState({
            idthuoc: null,
            nuocsx: '',
            idhang: '',
            quycach: '',
            ma: '',
            ten: '',
            dang: '',
            hamluong: '',
            baoquan:'',
            loai: null,
            dongia: 0,
            donvi: null,
            hopchat: '',
            gia_bh: 0,
            dangthuoc: null,
            hienthi: true,
            tontoithieu: 0,
        });
        this.validator.hideMessages();
    }
    loadHSX(e, page = 1, size = 1000) {
        MedicineService.HangSanXuat(e, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstHangSX: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu hãng sản xuất bị lỗi' })
        })
    }
    loadNuocSX() {
        MedicineService.NuocSanXuatThuoc().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNuocSX: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nước sản xuất bị lỗi' })
        })
    }
    loadDangThuoc() {
        MedicineService.DangThuoc().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDangThuoc: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dạng thuốc bị lỗi' })
        })
    }
    loadLoaiDMBD() {
        MedicineService.DanhSachLoaiDMBD().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoai: result.data,
                })
            }
            else {
            }
        }).catch(err => {
        })
    }
    loadDonViTinh(key) {
        MedicineService.DonViTinh(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDVT: result.data,
                });
            }
        }).catch(err => {
        })
    }
    loadBaoQuan(){
        MedicineService.DanhSachBaoQuan().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBaoQuan: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dạng bảo quản bị lỗi' })
        })
    }
    async CreateThuoc() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })

        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                ma: this.state.ma,
                ten: this.state.ten,
                nhom: 1,
                hamluong: this.state.hamluong,
                dongia: Number(this.state.dongia),
                gia_bh: Number(this.state.gia_bh),
                loai: Number(this.state.loai),
                idhang: Number(this.state.idhang),
                nuocsx: Number(this.state.nuocsx),
                quycach: this.state.quycach,
                donvi: this.state.donvi,
                tenhc: this.state.hopchat,
                dang: this.state.dangthuoc,
                baoquan : this.state.baoquan != null ? this.state.baoquan.toString() : '',
                hienthi : this.state.hienthi ? '0' : '1',
                sltoithieu: this.state.tontoithieu,
                donViDung: this.state.donViSuDung,
            }
            Action.setLoading(true);
            MedicineService.TaoMoiThuoc(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' })
                    this.resetState();
                    Action.setLoading(false);
                    if (this.props.onCancel){
                        this.props.onCancel();
                    }
                }
                else {
                    Action.setLoading(false);
                    Action.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {
                Action.setLoading(false);
                Action.openMessageDialog("Lỗi", err);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            Action.setLoading(false);
        }
    }

    isMedicine(item){
        return this.state.lstLoai && this.state.loai && (this.state.lstLoai.find(x => x.value === item)).nhom === 1;
    }

    render() {
        return (
            <React.Fragment>
                {/* <Form.Item style={{ flex: 1, display: 'none' }} label="Mã:" name="ma"
                >
                    <Input placeholder="Nhập mã thuốc, tối đa 6 ký tự"
                        maxLength={6}
                        value={this.state.ma}
                        onChange={(e) => this.setState({ ma: e.target.value })} />
                </Form.Item> */}
                <HeaderModal title="Thêm thuốc - vật tư" onClose={() => { this.props.onCancel && this.props.onCancel() }} />
                <div style={{ position: 'relative' }}>
                    <Form ref={(c) => this.form = c}>
                        <div>
                            <div className="row">
                                <div className="col-md-10 mt-2">
                                    <label>Mã thuốc - vật tư</label>
                                    <Form.Item>
                                        <Input
                                            placeholder="Nhập mã thuốc - vật tư"
                                            value={this.state.ma}
                                            onChange={(e) => this.setState({ ma: e.target.value })}>
                                        </Input>
                                    </Form.Item>
                                </div>
                                <div className="col-md-2 mt-2">
                                    <label>Sử dụng</label>
                                    <Form.Item>
                                        <Switch checkedChildren="Hiện" unCheckedChildren="Ẩn" 
                                            checked={this.state.hienthi}
                                            onChange={(e) => {
                                            this.setState({hienthi:e})
                                         }} 
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Tên thuốc - vật tư</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <Input placeholder="Nhập tên thuốc - vật tư"
                                        value={this.state.ten}
                                        onChange={(e) => this.setState({ ten: e.target.value })}>
                                    </Input>
                                    {this.validator.message("Tên thuốc - vật tư", this.state.ten, "required")}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Hoạt chất</label>
                                    <Input placeholder="Nhập hoạt chất"
                                        value={this.state.hopchat}
                                        onChange={(e) => this.setState({ hopchat: e.target.value })} />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Loại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <Select
                                        className="w-100"
                                        value={this.state.loai}
                                        showSearch
                                        placeholder="Chọn loại thuốc"
                                        onChange={(e) => {
                                            this.setState({ loai: e });
                                            if (!this.isMedicine(e)) {
                                                this.setState({ dangthuoc: null });
                                                this.form.setFieldsValue({
                                                    dangthuoc: null
                                                });
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                            option.children !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <OptGroup label="Nhóm thuốc">
                                            {this.state.lstLoai.filter(x => x.nhom === 1).map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </OptGroup>
                                        <OptGroup label="Nhóm vật tư">
                                            {this.state.lstLoai.filter(x => x.nhom === 2).map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </OptGroup>
                                    </Select>
                                    {this.validator.message("Loại thuốc - vật tư", this.state.loai, "required")}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Nước sản xuất</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <Select
                                        className="w-100"
                                        value={this.state.nuocsx}
                                        showSearch
                                        placeholder="Chọn nước sản xuất"
                                        onChange={(e) => this.setState({ nuocsx: e })}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstNuocSX.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Nước sản xuất", this.state.nuocsx, "required")}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Hãng sản xuất</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <Autocomplete
                                            value={this.state.idhang}
                                            width='92%' placeholder="Chọn hãng sản xuất"
                                            listHeight="35vh"
                                            ref={((c) => this.autocomplete = c)}
                                            dataSource={this.state.lstHangSX}
                                            handleSearch={this.loadHSX.bind(this)}
                                            getItem={(key) => { this.form.setFieldsValue({ idhang: key }); this.setState({ idhang: key }); }} />
                                        <button className="btn btn-color btn-sm btn-circle ml-1 btn-scale" onClick={() => {
                                        this.setState({ modalCreateHSX: true })
                                        }} ><i className="fa fa-plus" ></i></button>
                                    </div>
                                    {this.validator.message("Hãng sản xuất", this.state.idhang, "required")}
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Giá nhập</label>
                                    <InputNumber className="w-100" value={0}
                                        disabled={true}
                                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </div>
                                <div className="col-md-4 mt-2" style={{ flex: 1 }}>
                                    <label>Giá bán</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <InputNumber placeholder="Nhập giá bán"
                                        onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        defaultValue={0}
                                        style={{ width: '100%' }}
                                        min={0}
                                        maxLength={10}
                                        value={this.state.dongia}
                                        formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={(e) => this.setState({ dongia: e })}>
                                    </InputNumber>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label>Đơn vị tính</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <Autocomplete width="72%" placeholder="Chọn đơn vị tính"
                                            value={this.state.donvi}
                                            listHeight="35vh"
                                            disabled={this.state.disabled}
                                            ref={((c) => this.autocomplete = c)}
                                            dataSource={this.state.lstDVT}
                                            handleSearch={this.loadDonViTinh.bind(this)}
                                            getItem={(key, value) => { this.setState({ donvi: value }); this.form.setFieldsValue({ donvi: key }) }} />
                                        <button className="btn btn-color btn-sm btn-circle ml-1 btn-scale" onClick={() => {
                                        this.setState({ modalCreateUnit: true })
                                        }} ><i className="fa fa-plus" ></i></button>
                                    </div>
                                    {this.validator.message("Đơn vị tính", this.state.donvi, "required")}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <label>Hàm lượng</label>
                                    <Input
                                        placeholder="Nhập hàm lượng"
                                        maxLength={20}
                                        value={this.state.hamluong}
                                        onChange={(e) => this.setState({ hamluong: e.target.value })} />
                                </div>
                                <div className="col-md-3 mt-2">
                                    <label>Tồn tối thiểu</label>
                                    <InputNumber
                                        className="w-100" 
                                        placeholder="Nhập số lượng tồn tối thiểu"
                                        min={0}
                                        value={this.state.tontoithieu}
                                        onChange={(e) => this.setState({ tontoithieu: e })} />
                                </div>
                                <div className="col-md-3 mt-2">
                                    <label>Đơn vị sử dụng</label><Tooltip title="Đơn vị tính khi sử dụng: Ví dụ: 1 viên, 1 giọt..."><i className='ml-1 far fa-question-circle'/></Tooltip>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <Autocomplete
                                            className="w-100"
                                            placeholder="Chọn đơn vị sử dụng"
                                            value={this.state.donViSuDung}
                                            listHeight="35vh"
                                            disabled={this.state.disabled}
                                            ref={((c) => this.listDonViSuDung = c)}
                                            dataSource={this.state.lstDVT}
                                            handleSearch={this.loadDonViTinh.bind(this)}
                                            allowClear
                                            getItem={(key, value) => { this.setState({ donViSuDung: value }); this.form.setFieldsValue({ donViSuDung: key }) }} />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Quy cách</label>
                                    <Input placeholder="Ví dụ: 1 lọ/60 viên"
                                        maxLength={20}
                                        value={this.state.quycach}
                                        onChange={(e) => this.setState({ quycach: e.target.value })} />
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>{this.isMedicine(this.state.loai) ? <span className="ant-form-item-required">Dạng thuốc </span> : "Dạng thuốc"}</label>
                                    <label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                    <Select className="w-100"
                                        placeholder="Chọn dạng thuốc"
                                        value={this.state.dangthuoc}
                                        disabled={!this.isMedicine(this.state.loai)}
                                        onChange={(e) => this.setState({ dangthuoc: e })}
                                    >
                                        {this.state.lstDangThuoc.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.ma}>{row.ten}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.isMedicine(this.state.loai) && this.validator.message("Dạng thuốc", this.state.dangthuoc, 'required')}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <label>Bảo quản</label>
                                    <Select className="w-100"
                                        value={this.state.baoquan}
                                        showSearch
                                        placeholder="Chọn cách bảo quản"
                                        onChange={(e) => 
                                            this.setState({ baoquan: e })
                                        }
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstBaoQuan.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ten}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                
                                {/* <div className="col-md-6 mt-1">
                                    <Form.Item label="Giá BHYT:" name="gia_bh" style={{ flex: 1 }}>
                                        <InputNumber placeholder="Nhập giá BHYT"
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            defaultValue={0}
                                            style={{ width: '100%' }}
                                            min={0}
                                            maxLength={14}
                                            value={this.state.gia_bh}
                                            formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(e) => this.setState({ gia_bh: e })}>
                                        </InputNumber>
                                    </Form.Item>
                                </div> */}
                            </div>
                            <div className='custom-hr'></div>
                            <div style={{ display: 'flex', margin: '10px 0 10px 0', justifyContent: 'center', alignItems: 'center'}}>
                                <Button style={{ marginLeft: 10 }} className="btn btn-success"
                                    onClick={() => {  
                                        if(this.state.idthuoc === null || this.state.idthuoc === undefined){
                                            this.CreateThuoc();
                                        } else {
                                            this.UpdateThuoc();
                                        }
                                    }}> 
                                    <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</Button>
                                <Button className="btn btn-success" style={{ marginLeft: 10 }}
                                    onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</Button>

                                <Button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.props.onCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng

                                </Button>
                            </div>
                            <Modal maskClosable={false}
                                width={600}
                                visible={this.state.modalCreateHSX}
                                cancelButtonProps={{ style: { display: 'none' } }}
                                okButtonProps={{ style: { display: 'none' } }}>
                                <CreateHSX onCancel={() => {
                                    this.setState({ modalCreateHSX: false });
                                }}
                                    onSubmit={(item) => {
                                        let { lstHangSX } = this.state;
                                        lstHangSX.push(item);
                                        this.setState({ idhang: item.value.toString(), modalCreateHSX: false });
                                        this.form.setFieldsValue({ idhang: item.value.toString(), lstHangSX })
                                    }}
                                />
                            </Modal>
                            <Modal maskClosable={false}
                                width={600}
                                visible={this.state.modalCreateUnit}
                                cancelButtonProps={{ style: { display: 'none' } }}
                                okButtonProps={{ style: { display: 'none' } }}>
                                <CreateUnit onCancel={() => {
                                    this.setState({ modalCreateUnit: false });
                                }}
                                    onSubmit={(item) => {
                                        let { lstDVT } = this.state;
                                        lstDVT.push(item);
                                        this.setState({ donvi: item.label.toString(), modalCreateUnit: false });
                                        this.form.setFieldsValue({ donvi: item.value.toString(), lstDVT })
                                    }}
                                />
                            </Modal>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}
