import {
    Button, Input, InputNumber, notification, Table, Form
} from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';
import * as packageService from '../../../services/packageService';
import * as ChiDinhService from '../../../services/chiDinhService';

var task = null;

export default class CreatePackageModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            name: '',
            price: 0,
            description: '',
            expiry_time: 1,

            serviceName: '',
            numberAdd: 1,

            selectedId: '',
            selectedGroup: '',
            selectedType: '',
            listGroups: [],
            listServices: [],
            selectedServices: []
        };
    }

    componentDidMount() {
        if (task)
            clearInterval(task)
        this.getAllTypeServices();
        this.SearchServices('', '8', '');
    }
    getAllTypeServices() {
        ChiDinhService.getAllTypeService().then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    listGroups: respone.data,
                });
            }
        })
    }
    AddNewService() {
        let { listServices, selectedId, selectedServices, numberAdd } = this.state
        let item = listServices.filter(record => record.id === selectedId)[0];
        let olditems = selectedServices.filter(record => record.id === selectedId);
        if (olditems.length > 0) {
            if (olditems.length > 0) {
                if (item.selected === true) {
                    this.removeService(item.id)
                } else {
                    item.selected = true;
                }
            }
            return;
        }
        if (item.selected === true) {
            item.selected = false;
        } else {
            item.selected = true;
        }
        if (olditems.length > 0) {
            olditems[0].times = +olditems[0].times + +numberAdd;
            olditems[0].newPrice = olditems[0].dongia;
        } else {
            item.times = numberAdd;
            item.newPrice = item.dongia;
            selectedServices.push(item);
        }
        let tong = selectedServices.reduce((tmp, cur) => tmp + (cur.newPrice * cur.times), 0);
        this.setState({ selectedServices, selectedId: '', price: tong })
    }

    SearchServices() {
        let olddata = this.state.selectedServices;
        packageService.getListService(this.state.serviceName, this.state.selectedGroup, this.state.selectedType).then(result => {
            if (result.isSuccess) {
                let data = result.data.map(x => ({
                    ...x,
                    selected: olddata.filter(o => o.id.toString() === x.id.toString()).length > 0
                }))
                this.setState({
                    listServices: data,
                });
            } else {
                Actions.openMessageDialog("Error", result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error", err.toString());
        })
    }

    removeService(index) {
        let { selectedServices } = this.state
        let item = selectedServices.filter(x => x.id === index)[0];
        if (item) {
            if (item.selected === true) {
                item.selected = false;
            } else {
                item.selected = true;
            }
        }
        selectedServices = selectedServices.filter(x => x.id !== index);
        let tong = selectedServices.reduce((tmp, cur) => tmp + (cur.newPrice * cur.times), 0);
        this.setState({ selectedServices, price: tong })
    }

    async savePackage() {

        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            let { name, price, description, expiry_time, selectedServices, code } = this.state
            for (let item of selectedServices) {
                item.idvp = item.id
                item.times = + item.times
            }
            let data = {
                name, price, description, expiry_time, code,
                lstDetails: selectedServices
            }
            packageService.CreatePackage(data).then(object => {
                if (object.isSuccess) {
                    notification.success({ message: 'Thêm gói khám thành công' })
                    this.props.onClose();
                }
                else {
                    Actions.openMessageDialog('error', object.err.msgString.toString());
                }
            }).catch(err => {
                Actions.openMessageDialog('error', err);
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    editAmount(row, soluong) {
        let { selectedServices } = this.state
        let index = selectedServices.findIndex(record => record.id === row.id);
        selectedServices[index].times = soluong;
        let price = selectedServices.reduce((tmp, cur) => tmp + (cur.newPrice * cur.times), 0);
        this.setState({ selectedServices, price })
    }
    editPrice(row, price) {
        let { selectedServices } = this.state
        let index = selectedServices.findIndex(record => record.id === row.id);
        selectedServices[index].newPrice = price;
        let tong = selectedServices.reduce((tmp, cur) => tmp + (cur.newPrice * cur.times), 0);
        this.setState({ selectedServices, price: tong })
    }
    render() {

        const columnsSearh = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'code',
                key: 'code',
                width: 100
            },
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Giá tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => (value.toLocaleString('en-US')),
            },
            {
                title: 'Loại',
                dataIndex: 'typeName',
                key: 'typeName',
            },
            {
                title: '',
                width: 50,
                align: 'center',
                render: (row) => (
                    row.selected === true ? <i className="fa fa-check"></i> : ''
                )
            },
        ];
        const columnsSearh2 = [
            {
                title: 'TT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Loại',
                dataIndex: 'typeName',
                key: 'typeName',
            },
            {
                title: 'Giá tiền',
                // dataIndex: 'dongia',
                // key: 'dongia',
                render: (row) => (<InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')} style={{ width: '100px' }} defaultValue={row.dongia} min={0} maxLength={10} max={999999999} 
                    onChange={(e) => { this.editPrice(row, e) }} />)
            },
            {
                title: 'Số lần khám',
                align: 'center',
                render: (row) => (<InputNumber style={{ width: '100px' }} defaultValue={1} min={1} maxLength={4} max={9999} onChange={(e) => { this.editAmount(row, e) }} />)
            },
            {
                title: '',
                render: (value, row, index) => {
                    return (
                        <Button type="primary" danger onClick={() => { this.removeService(row.id) }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i>  </span>
                        </Button>
                    )
                }
            }
        ];
        let { listServices, selectedServices } = this.state;


        let dataSource = listServices.map(function (e, index) {
            let o = Object.assign({}, e);

            o.key = o.id
            return o;
        })


        let dataSourceNew = selectedServices.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })

        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Form ref={(c) => this.form = c}>
                    <div className="row equal">
                        <div className="col-md-6 mb-2">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <h3 style={{ textTransform: "uppercase" }}> Gói khám</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Mã</label>
                                    <Form.Item>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.code}
                                            onChange={(e) => { this.setState({ code: e.target.value }) }}
                                        />
                                        {this.validator.message("Mã gói khám", this.state.code, "required|maxLen:10")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-3">
                                    <label>Tên gói khám</label>
                                    <Form.Item>
                                        <Input
                                            style={{ width: '100%' }}
                                            value={this.state.name}
                                            onChange={(e) => { this.setState({ name: e.target.value }) }}
                                        />
                                        {this.validator.message("Tên gói khám", this.state.name, "required|maxLen:60")}
                                    </Form.Item>
                                </div>
                                <div className="col-md-3">
                                    <label>Giá tiền</label>
                                    <Form.Item>
                                        <InputNumber
                                            min={0}
                                            maxLength={11}
                                            max={999999999}
                                            defaultValue={0}
                                            style={{ width: '100%' }}
                                            value={this.state.dongia}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(value) => { this.setState({ price: value }) }}

                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-3">
                                    <label>Thời hạn dùng(ngày)</label>
                                    <Form.Item>
                                        <InputNumber
                                            min={1}
                                            maxLength={6}
                                            max={99999}
                                            style={{ width: '100%' }}
                                            value={this.state.expiry_time}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={(value) => { this.setState({ expiry_time: value }) }}
                                        />
                                        {this.validator.message("Thời hạn dùng", this.state.expiry_time, "required|min:1,num")}
                                    </Form.Item>
                                </div>
                                <div className="col-12">
                                    <label>Mô tả</label>
                                    <Form.Item>
                                        <Input.TextArea
                                            style={{ height: 60, maxHeight: 60, minHeight: 60 }}
                                            value={this.state.description}
                                            onChange={(e) => { this.setState({ description: e.target.value }) }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <h3 className="my-2 text-center" style={{ textTransform: "uppercase" }}>Lựa Chọn Dịch vụ</h3>
                                <div style={{ display: 'flex' }} className="text-uppercase">
                                    {this.state.listGroups.map((item, index) => {
                                        return (
                                            <button className="btn size11"
                                                key={"group" + index}
                                                style={{
                                                    border: (item.value === this.state.selectedGroup && this.state.selectedType === item.type) ? '1px solid #1890ff' : '1px solid rgb(232,232,232)',
                                                    color: (item.value === this.state.selectedGroup && this.state.selectedType === item.type) ? '#1890ff' : 'black'
                                                    , padding: 5, textAlign: 'center', cursor: 'pointer', fontSize: '12px'
                                                    , width: '100%', marginBottom: 5, flex: 1, borderRadius: 0, backgroundColor: '#fff'
                                                }}
                                                onClick={() => {
                                                    if (this.state.selectedGroup === 3 && this.state.selectedType !== item.type) {
                                                        this.setState({ selectedGroup: item.value, selectedType: item.type }, () => this.SearchServices())
                                                    }
                                                    else {
                                                        if (this.state.selectedGroup !== item.value) {
                                                            this.setState({ selectedGroup: item.value, selectedType: item.type }, () => this.SearchServices())
                                                        }
                                                    }

                                                }}>
                                                {item.label}
                                            </button>

                                        )
                                    })
                                    }
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        value={this.state.serviceName}
                                        onChange={(e) => {
                                            this.setState({ serviceName: e.target.value }, () => {
                                                task = setTimeout(() => {
                                                    this.SearchServices()
                                                },
                                                    100)
                                            })
                                        }}
                                        placeholder="Nhập tên dịch vụ"
                                        style={{ width: '100%' }}
                                    />
                                    <Input
                                        style={{ margin: "0px 10px", width: 100, display: 'none' }}
                                        value={this.state.numberAdd}
                                        type="number"
                                        onChange={(e) => { this.setState({ numberAdd: e.target.value }) }}
                                    />
                                    <Button type="primary" disabled={this.state.selectedId === ''}
                                        onClick={() => this.AddNewService()} style={{ marginRight: 5, display: 'none' }}>
                                        <i className="fa fa-plus" > Thêm</i>
                                    </Button>
                                </div>
                                <Table dataSource={dataSource} pagination={false}  scroll={{ y: 0 }}
                                    rowKey={(e, index) => index} columns={columnsSearh} bordered={false}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: e => { this.setState({ selectedId: record.id }, () => this.AddNewService()) }
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        return `rowCustomSelect ${this.state.selectedId === record.id ? 'rowCustomSelectActive' : ''}`
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <h3 className="my-2 text-center" style={{ textTransform: "uppercase" }}>Các dịch vụ đã chọn</h3>
                            <Table dataSource={dataSourceNew} pagination={false}  scroll={{ y: 0 }}
                                rowKey={(e, index) => index} columns={columnsSearh2} bordered={false}
                            />
                            {/* {this.validator.message("expiry_time",
                                (this.state.selectedServices && this.state.selectedServices.length > 0), 'required')} */}
                        </div>
                    </div>
                </Form>
                <div className="form-group" style={{
                    marginTop: 15, display: 'flex', justifyContent: 'center',
                    alignItems: 'center', height: 100
                }}>

                    <button className="btn btn-success"
                        onClick={() => this.savePackage()}>
                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu
                    </button>

                    <button className="btn btn-danger" style={{ marginLeft: 5 }}
                        onClick={() => this.props.onClose()}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}/>Đóng
                    </button>
                </div>
            </div>

        )
    }
}

