import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Input, Select, Button } from 'antd'
import moment from 'moment';
import Notify, { NotifyStatus } from '../../../../components/notify';
import AutocompleteMulti from '../../../../components/autocompleteMulti';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../../../libs/suneditor-vi-lang';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../../libs/actions';
import * as CommonService from '../../../../services/commonService';
import * as MedicalExamService from '../../../../services/MedicalExam';
import MitaDisplayLongText from '../../../../components/MitaComponents/mitaDisplayLongText';
import { IsActive_FeatureLH } from '../../../../configure/featureConfig';
import Schedule from '../../../lichhen/schedule';

const { TextArea } = Input;
const { Option } = Select;
const showMoreText = "...Xem thêm";
const showLessText = " Rút gọn";
export default (props) => {
    const _notiRef = useRef();
    const _icd = useRef();
    const _scheduleRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Datasource 
    const [listICDs, setListICDs] = useState([]);
    const [listTemplate, setListTemplate] = useState([]);
    const [examinationId, setExaminationId] = useState('')
    const [dataSource, setDataSource] = useState({});
    const [listTreatmentDirection, setListTreatmentDirection] = useState([])
    // Biến cục bộ
    const [lyDo, setLyDo] = useState();
    const [moTa, setMoTa] = useState();
    const [ketLuan, setKetLuan] = useState();
    const [denghi, setDenghi] = useState();
    const [ICDs, setICDs] = useState([]);
    const [henkham, setHenkham] = useState();
    const [treatmentDirection, setTreatmentDirection] = useState(null);
    const [appointmentScheduleId, setAppointmentScheduleId] = useState();

    useEffect(() => {
        setExaminationId(props.examinationId);
    }, [props])
    
    useEffect(() => {
        if(examinationId && examinationId !== '') GetDetail();
        GetListTreatmentDirection();
    }, [examinationId])
    
    const GetDetail = () => {
        Actions.setLoading(true);
        MedicalExamService.GetExaminationDetail(examinationId).then((result) => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setLyDo(result.data?.lyDo);
                setMoTa(result.data?.moTa);
                setTreatmentDirection(result.data?.huongXuTri);
                setDenghi(result.data?.deNghi);
                setKetLuan(result.data?.ketLuan);
                setHenkham(result.data?.appointmentDate);
                setICDs(result.data?.icDs);
                setListICDs(result.data?.icDs);
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const SaveExamination = (isDone) => {
        Actions.setLoading(true);
        let ngayHen = null;
        let idLichHen = null;
        if (_scheduleRef && _scheduleRef.current) {
            const dataHen = _scheduleRef.current.getData();
            ngayHen = dataHen.ngayGioHen;
            idLichHen = dataHen.idLichHen
        }
        let data = {
            'ExaminationId' : dataSource.examinationId,
            'isDone' : isDone,
            'LyDo' : lyDo,
            'MoTa' : moTa,
            'KetLuan' : ketLuan,
            'LoiDan' : denghi,
            'XuTri' : treatmentDirection,
            'ICDs' : ICDs,
            'HenKham' : ngayHen,
            'AppointmentScheduleId' : idLichHen
        }
        MedicalExamService.SaveExamination(data).then((result) => {
            if (result.isSuccess) {
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const GetListTreatmentDirection = () => {
        MedicalExamService.GetListTreatmentDirection().then((result) => {
            if (result.isSuccess) {
                setListTreatmentDirection(result.data);
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const SetListChiDinhICD = (item) => {
        let lst = [];
        item.map(x => {
            lst.push({ value: x.key, label: x.children })
        })
        setICDs(lst);
    }

    const GetListDiagnostic = (key) => {
        let skip = 0;
        let maxCount = 10;
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxCount,
            keyword: key,
        }
        CommonService.GetListICD10(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                data = data.map(x => ({ ...x, label: `${x.value} - ${x.label}` }))
                setListICDs(data);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", 'Lỗi tải danh chẩn đoán ICD');
        })
    }

    const renderStatus = (record) => {
        switch(record.examPatientStatus) {
            case '?':
                return (
                    <label className="mita-value">Đang khám</label>
                );
            case 'x':
                return (
                    <label className="mita-value">Đã khám</label>
                );
            default:
                return (
                    <label className="mita-value">Chưa khám</label>
                );
        }
    }

    return (
        <Fragment>
            <div className="h-100 overflow-auto">
                <Notify ref={_notiRef} />
                <div className="row">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Tên dịch vụ </label>
                        <label className="mita-value">{dataSource.serviceName}</label>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Trạng thái </label>
                        <label className="mita-value">{renderStatus(dataSource)}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 d-flex flex-column">
                        <label className="mita-title">Lý do khám</label>
                        <label className="mita-value">{lyDo}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 d-flex flex-column">
                        <label className="mita-title">Chẩn đoán ICD</label>
                        <label className="mita-value">{listICDs.map(x => x.label).join("; ")}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Mô tả</label>
                        <div className='table-collapse-pasc text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${moTa !== null ? moTa : ''}` }}></div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Kết luận</label>
                        <MitaDisplayLongText
                            content={ketLuan}
                            maxLength={100}
                            showMoreText={showMoreText}
                            showLessText={showLessText}
                            displayMode="inner"
                        ></MitaDisplayLongText>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Lời dặn - Đề nghị</label>
                        <MitaDisplayLongText
                            content={denghi}
                            maxLength={100}
                            showMoreText={showMoreText}
                            showLessText={showLessText}
                            displayMode="inner"
                        ></MitaDisplayLongText>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 d-flex flex-column">
                        <label className="mita-title">Hướng xử trí</label>
                        <label className="mita-value">{listTreatmentDirection.find(x => x.ma === treatmentDirection)?.ten}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <Schedule
                            ref={_scheduleRef}
                            isPreview={true}
                            data={{
                                managermentId: dataSource.managermentId,
                                serviceId: dataSource.serviceCode,
                                patientCode: dataSource.patientCode,
                            }} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
