import React, { Fragment, useState, useRef, useCallback } from 'react';
import HeaderModal from '../common/headerModal';
import { Alert, Input, Switch } from 'antd';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as ConfigService from '../../services/configService';
import { setLoading } from '../../libs/actions';

function CreateConfig(props) {
    const _notify = useRef();
    const [active, setActive] = useState(true);
    const [title, setTitle] = useState();
    const [component, setComponent] = useState();
    const [key, setKey] = useState();

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleSave = () => {
        if (validator.current.allValid()) {
            const data = {
                feature: props.feature,
                title,
                component,
                key,
                active
            };
            setLoading(true);
            ConfigService.CreateScreenFunction(data)
            .then(res => {
                if (res.isSuccess) {
                    props.onReload && props.onReload();
                    _notify && _notify.current && _notify.current.Show("Thêm thành công");
                } else {
                    _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
                }
            }).catch((err) => {
                _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    return (
        <Fragment>
            <HeaderModal title="Thêm chức năng của màn hình" onClose={() => props.onClose()} />
            <Notify ref={_notify} />
            <div className='list-page'>
                <div className='list-page-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <Alert type='info' message={`Màn hình: ${props.screen ?? ""}`}/>
                        </div>
                        <div className='col-12 mt-2'>
                            <Switch checkedChildren="Sử dụng" unCheckedChildren="Không dùng" checked={active} onChange={(e) => setActive(e)}></Switch>
                        </div>
                        <div className='col-12 mt-2'>
                            <label className='mita-title required-field'>Tiêu đề</label>
                            <Input placeholder='Nhập tiêu đề' value={title} onChange={(e) => setTitle(e.target.value)}></Input>
                            {validator.current.message("Tiều đề", title, "required")}
                        </div>
                        <div className='col-12 mt-2'>
                            <label className='mita-title'>Tên component</label>
                            <Input placeholder='Nhập component' value={component} onChange={(e) => setComponent(e.target.value)}></Input>
                        </div>
                        <div className='col-12 mt-2'>
                            <label className='mita-title required-field'>Key</label>
                            <Input placeholder='Nhập key' value={key} onChange={(e) => setKey(e.target.value)}></Input>
                            {validator.current.message("Key", key, "required")}
                        </div>
                    </div>
                </div>
            </div>
            <div className='list-page-footer'>
                <div className='d-flex justify-content-end'>
                    <button className={`${BUTTON.THEME}`} onClick={() => handleSave()}><i className='fas fa-save mr-1' />Thêm</button>
                    <button className={`${BUTTON.DANGER} ml-2`} onClick={() => props.onClose()}><i className='fas fa-times mr-1' />Đóng</button>
                </div>
            </div>
        </Fragment>
    );
}

export default CreateConfig;