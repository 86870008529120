import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Button, Input, Select, Table, Tooltip, notification, Alert, Modal } from 'antd';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import moment from 'moment';
import { FORMAT_DATE, FORMAT_TIME_DATE } from '../../configure/systemConfig';
import NguonKhachHang from "../recept/register/nguonkhachhang";
import * as ConfigService from '../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as AppointmentService from '../../services/appointmentService';
import Notify, { NotifyStatus } from '../../components/notify';
import ModalCLS from '../../components/MDCLS';
import { isNullOrEmpty, isnotnull } from '../../libs/util';
import DateTimePicker from 'react-datetime-picker';
import { ALLOW_COMBO } from "../../configureHIS/common/constants";
import * as HotlineServices from "./hotlineservices";
import SelectConsultant from '../../components/MDCLS/selectConsultant';
import * as ChiDinhService from "../../services/chiDinhService";
import ConsultantWarning from '../recept/register/consultantWarning';

const { TextArea } = Input;
var taskDelay = null;
const username = localStorage.getItem("userID");

const TaoCapNhatLichHenKhamHotline = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const _nguonKhachHang = useRef();
    const _orderRef = useRef();
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [patientInfo, setPatientInfo] = useState(props.patientInfo ?? {});
    const [bangGia, setBangGia] = useState("");
    const [bangGiaTheoSite, setBangGiaTheoSite] = useState("");
    const [bangGiaTheoNguonKH, setBangGiaTheoNguonKH] = useState("");
    const [dataOrder, setDataOrder] = useState(props.dataOrder ?? []);
    const [caseInfo, setCaseInfo] = useState(props.caseInfo);
    const [managementId, setManagementId] = useState("");
    const [site, setSite] = useState(props.site);
    const [listSite, setListSite] = useState([]);
    const [detail, setDetail] = useState();
    const [initDetail, setInitDetail] = useState();
    const [ngayHen, setNgayHen] = useState(new Date());
    const [idhotline, setIdhotline] = useState(null);
    const [ngayHenGanNhat, setNgayHenGanNhat] = useState({});
    const [modalSelectConsultant, setModalSelectConsultant] = useState(false);
    const [listHTTKQ, setListHTTKQ] = useState([]);
    const [sendBy, setSendBy] = useState([]);

    useEffect(() => {
        getListSite();
        getListHTTKQ();
    }, []);

    useEffect(() => {
        if (caseInfo.mabn) getNgayHenGanNhat(caseInfo.mabn);
        caseInfo.idhotline && Number(caseInfo.idhotline) > 0 ? setIdhotline(Number(caseInfo.idhotline)) : setIdhotline(caseInfo.id);
        setNgayHen(moment(caseInfo.ngayhenkham).year() !== 1 ? moment(caseInfo.ngayhenkham).toDate() : new Date());
        caseInfo.ghichu && setInitDetail(caseInfo.ghichu);
        loadChiDinhInfo();
    }, [props.caseInfo])

    useEffect(() => {
        let s = listSite.find(x => x.id == site);
        if (s) setBangGiaTheoSite(s.banggia);
    }, [site])

    useEffect(() => {
        if (_orderRef && _orderRef.current) {
            _orderRef.current.UpdateSelectedFromProps(dataOrder);
        }
    }, _orderRef.current)

    const onCloseModal = () => {
        props.onClose && props.onClose();
        resetState();
        _notify && _notify.current && _notify.current.Close();
    }

    const resetState = () => {
        setSite();
    }

    const checkBangGia = (info) => {
        let banggia = bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia;
        let listOrder = [...dataOrder];
        let itemFound = listOrder && listOrder.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        if (itemFound !== null && itemFound !== undefined) banggia = itemFound.banggia; //nếu đã lưu chỉ định có banggia thì lấy banggia của chidinh
        if (itemFound !== null && itemFound !== undefined && info.banggia !== banggia) {
            //nếu banggia của nguonKH khác bangia hiện tại thì vào thay đổi
            //nếu đổi banggia mà có chidinh thì xóa chidinh cũ
            if (listOrder.length > 0) {
                xoaTatCaChiDinh();
                if (_orderRef && _orderRef.current) {
                    _orderRef.current.UpdateSelectedFromProps([]);
                }
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`);
                if (itemFound.maql) {
                    cancelExam(listOrder);
                }
                banggia = info.banggia;
            }
            setBangGia(banggia);
        } else setBangGia(info.banggia);
    }

    const xoaTatCaChiDinh = () => {
        setDataOrder([]);
    }

    const cancelExam = (listOrder) => {
        xoaTatCaChiDinh();
        if (_orderRef && _orderRef.current) {
            _orderRef.current.UpdateSelectedFromProps([]);
        }
        if (_orderRef && _orderRef.current && Number(caseInfo.idhenkham) > 0) {
            listOrder.forEach(chiDinh => {
                _orderRef.current.XoaChiDinh(chiDinh);
            });
            loadChiDinhInfo();
        }
    }

    const loadChiDinhInfo = () => {
        if (Number(caseInfo.idhenkham) > 0) {
            Actions.setLoading(true);
            AppointmentService.GetAppoimentDetailByID(caseInfo.idhenkham, site).then(result => {
                if (result.isSuccess) {
                    let data = result.item.lstChiDinh;
                    for (let item of data) {
                        item.listChild = item.listChildCd;
                    }
                    setDataOrder(data);
                    setSendBy(result.item.sendBy);
                    setManagementId(result.item.maql);
                } else Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            }).finally(() => Actions.setLoading(false))
        }
    }

    const getListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) setListSite(result.data);
            else setListSite([]);
        }).catch(err => { })
    }

    const onChangeDetail = () => {
        if (detail !== undefined && detail !== null && detail !== "" && detail != " ") {
            let data = initDetail ? `${initDetail}\n[${username}]: ${detail}` : `[${username}]: ${detail}`;
            setInitDetail(data);
            setDetail("");
        }
    }

    let dataSource = (dataOrder ?? []).map(function (e, index) {
        let o = Object.assign({}, e);
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    });

    let regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title:
                <div>
                    <span className={props.consultantRequired && "required-field"}>NV tư vấn</span>
                    <Tooltip title='Cập nhật nhân viên tư vấn'>
                        <Button type='link' style={{ color: 'white' }}
                            className='pl-2'
                            onClick={() => { setModalSelectConsultant(true) }}
                        ><i className='fa fa-pen' />
                        </Button>
                    </Tooltip>
                </div>,
            dataIndex: 'consultantName',
            key: 'consultantName',
            width: 130,
            render: (text, record, index) => {
                return record.consultantName
            },
        },
        {
            title: 'Giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: '10%',
            render: (text, row, index) => {
                return (
                    (row.isChild) ? "" : (
                        <div style={{ cursor: 'pointer' }}
                            className="text-danger"
                            onClick={() => {
                                xoaChiDinh(row);
                                if (_orderRef && _orderRef.current) {
                                    _orderRef.current.XoaChiDinh(row);
                                }
                            }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </div>
                    )
                )
            }
        }
    ];

    const xoaChiDinh = (row) => {
        let data = [...dataOrder];
        data = data.filter(x => x.id !== row.id)
        setDataOrder(data.map(x => ({ ...x })));
    }

    const getNgayHenGanNhat = (mabn, laymau = false) => {
        AppointmentService.NgayHenGanNhat(mabn, laymau).then(result => {
            if (result.isSuccess) setNgayHenGanNhat(result.item);
            else setNgayHenGanNhat({});
        })
    }

    const saveLichHen = () => {
        if (isNullOrEmpty(site)) {
            _notify.current.Show("Cần xác nhận nơi khám", NotifyStatus.Warning);
            return;
        }
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        let checkNgayHen = moment(ngayHen);
        if (!checkNgayHen.isValid()) {
            notification.warning({ message: 'Ngày hẹn sai định dạng' });
            return;
        }
        if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            _notify.current.Show("Bắt buộc chọn nguồn khách hàng", NotifyStatus.Warning);
            return;
        }
		let isNullConsultant = dataOrder.some(x => isNullOrEmpty(x.consultantId));
		if (props.consultantRequired && isNullConsultant) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        Actions.setLoading(true);
        let listChiDinh = [];
        let lstSelect = [...dataOrder];
        if (!lstSelect || lstSelect.length === 0)
            lstSelect = [];
        for (let item of lstSelect) {
            item.ma = "0";
            item.madoituong = "2";
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment().format("YYYY-MM-DD HH:mm");
            listChiDinh.push(item);
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = xChild;
                    itemChild.giaaTH = xChild.dongia.toString();
                    itemChild.dongia = xChild.dongia;
                    itemChild.soluong = xChild.soluong;
                    itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                    itemChild.vienPhiIdOfCombo = item.id;
                    if (listChiDinh.filter(x => x.id == itemChild.id).length > 0) {
                        notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                        return;
                    }
                    listChiDinh.push(itemChild);
                });
        }
        const dataAdministrative = caseInfo.dataAdministrative;
        const dataHenKham = idhotline && Number(idhotline) > 0 ?
            {
                lstChiDinh: listChiDinh,
                mabn: caseInfo.mabn.toString(),
                ngayhen: checkNgayHen.format("YYYY-MM-DD HH:mm"),
                machidinh: Number(''),
                newPatient: caseInfo.mabn ? false : true,
                idhotline: idhotline,
                patient: {ward: "IGNORE", "sendby": sendBy,},
                idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
                site: site.toString(),
            } : {
                lstChiDinh: listChiDinh,
                mabn: caseInfo.mabn.toString(),
                ngayhen: checkNgayHen.format("YYYY-MM-DD HH:mm"),
                machidinh: Number(''),
                newPatient: caseInfo.mabn ? false : true,
                idhotline: idhotline,
                patient: {
                    'barCode': dataAdministrative.code ? dataAdministrative.code : '',
                    'code': dataAdministrative.code ? dataAdministrative.code : '',
                    'name': dataAdministrative.name.toUpperCase(),
                    'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    'age': dataAdministrative.age,
                    'gender': dataAdministrative.gender,
                    'people': "25",
                    'cmnd': dataAdministrative.cmnd,
                    'phone': dataAdministrative.phone,
                    'email': dataAdministrative.email,
                    'address': dataAdministrative.address,
                    'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                    'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                    'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                    'jobAddress': dataAdministrative.jobAddress,
                    'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                    'country': dataAdministrative.nation,
                    'dateInHospital': moment().format('YYYY/MM/DD'),
                    'timeInHospital': moment().format('HH:mm'),
                    'subjectCode': "2",
                    'coporator': dataAdministrative.coporator,
                    'sendby': sendBy,
                    'onlyyear': dataAdministrative.onlyyear,
                    'year': dataAdministrative.year,
                },
                site: site.toString(),
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            }
        AppointmentService.LuuLichHen(dataHenKham).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' });
                _notify.current && _notify.current.Show("Lưu thành công", NotifyStatus.Success);
                resetState();
                reload();
            } else {
                Actions.openMessageDialog(result.err.msgCode ?? "Lỗi", result.err.msgString);
            }
        }).catch(err => {
        }).finally(() => {
            updateGhiChu(idhotline);
            Actions.setLoading(false);
        })
    }

    const updateGhiChu = (hotlineId) => {
        HotlineServices.UpdateDetailArrangerment(hotlineId, { ghichu: initDetail }, props.activeSite).then(() => { })
    }

    const updateLichHen = () => {
        let checkNgayHen = moment(ngayHen);
        if (!checkNgayHen.isValid() == true) {
            _notify.current.Show("Thời gian hẹn khám không hợp lệ", NotifyStatus.Warning);
            onScrollToTop();
            return;
        }
        Actions.setLoading(true);
        updateGhiChu(caseInfo.id);
        if (isNullOrEmpty(site)) {
            _notify.current.Show("Cần xác nhận nơi khám", NotifyStatus.Warning);
            onScrollToTop();
            return;
        }
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            _notify.current.Show("Bắt buộc chọn nguồn khách hàng", NotifyStatus.Warning);
            return;
        }
		let isNullConsultant = dataOrder.some(x => isNullOrEmpty(x.consultantId));
		if (props.consultantRequired && isNullConsultant) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        if (Number(caseInfo.idhenkham) > 0) {
            let lstSelect = dataOrder;
            let listChiDinh = [];
            for (let item of lstSelect) {
                item.ma = "0";
                item.madoituong = "2";
                item.giaaTH = item.dongia.toString();
                item.dongia = item.dongia;
                item.soluong = item.soluong;
                item.ngay = moment().format("YYYY-MM-DD HH:mm")
                if (listChiDinh.filter(x => x.id == item.id).length > 0) {
                    notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                    return;
                }
                listChiDinh.push(item);
                if (item.isCombo)
                    item.listChild.forEach(xChild => {
                        let itemChild = xChild;
                        itemChild.giaaTH = xChild.dongia.toString();
                        itemChild.dongia = xChild.dongia;
                        itemChild.soluong = xChild.soluong;
                        itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                        itemChild.vienPhiIdOfCombo = item.id;
                        if (listChiDinh.filter(x => x.id == itemChild.id).length > 0) {
                            notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                            return;
                        }
                        listChiDinh.push(itemChild);
                    });
            }
            const data = {
                ngayhen: ngayHen,
                lstChiDinh: listChiDinh,
                mabn: caseInfo.mabn,
                maql: managementId,
                site: caseInfo.chiNhanhHenKham, // Chi nhánh hẹn ban đầu (chi nhánh hẹn khám đã chọn thực tế lưu trong DB)
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                lstChiDinh: dataOrder
            }
            AppointmentService.updateAppointment(caseInfo.idhenkham, data, site).then((result) => {
                if (result.isSuccess) {
                    _nguonKhachHang && _nguonKhachHang.current.updateInfo();
                    notification.success({ message: 'Cập nhật thành công' });
                    _notify.current.Show("Cập nhật thành công", NotifyStatus.Success);
                    resetState();
                    reload();
                } else {
                    _notify.current.Show(result.err?.msgString ?? "Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
                    onScrollToTop();
                }
            }).catch(err => {
                _notify.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
                onScrollToTop();
            }).finally(() => Actions.setLoading(false))
        }
    }

    const reload = () => {
        props.onReload && props.onReload();
    }

    const onScrollToTop = () => {
        var containerToScroll = document.getElementById('container');
        containerToScroll && containerToScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const onUpdateConsultant = (newData) => {
        let listSelectedServices = [...dataOrder];
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });
        setDataOrder(listSelectedServices);
    }
    
    const getListHTTKQ = (key = "", page = 1, size = 10) => {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                setListHTTKQ(res.data);
            } else {
                setListHTTKQ([]);
            }
        })
    }

    return (
        <Fragment>
            <HeaderModal title={`${props.isCreate ? "Tạo lịch hẹn khám" : "Cập nhật lịch hẹn khám"}`} onClose={() => { onCloseModal() }} />
            <Notify ref={_notify} />
            <div style={{ overflowY: "auto", padding: "0px 10px" }} id="container">
                <div className="row mt-2">
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Mã BN:</span><strong className="ml-2">{patientInfo.mabn}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Họ tên:</span><strong className="ml-2">{patientInfo.hoten}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>{patientInfo.onlyyear ? "Năm sinh" : "Ngày sinh"}:</span>
                        <strong className="ml-2">{patientInfo.onlyyear ? moment(patientInfo.ngaysinh, "YYYY/MM/DD").format("YYYY") : moment(patientInfo.ngaysinh, "YYYY/MM/DD").format(FORMAT_DATE)}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Giới tính:</span><strong className="ml-2">{patientInfo.gioitinh}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Điện thoại:</span><strong className="ml-2">{patientInfo.dienthoai}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Email:</span><strong className="ml-2">{patientInfo.email}</strong>
                    </div>
                    <div className="col-md-12 my-1 d-flex">
                        <span style={{ width: '100px' }}>Địa chỉ:</span><strong className="ml-2">{patientInfo.sonha}</strong>
                    </div>
                </div>
                {isnotnull(ngayHenGanNhat) &&
                    <div className='row mt-2'>
                        <div className="col-md-12">
                            <div className='backgroundColorThemes rounded w-100 p-1'>Lịch hẹn đã tạo gần nhất:
                                <strong className="ml-2">{ngayHenGanNhat === null ? '' : moment(ngayHenGanNhat.ngayhen).format(FORMAT_TIME_DATE)}</strong>
                            </div>
                        </div>
                    </div>}
                <Alert type='warning' className='mt-2'
                    message={
                        <div className='row'>
                            {/* <div className="col-md-12"><i>* Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div> */}
                            <div className="col-md-12"><i className='text-danger'>* Việc thay đổi nguồn khách hàng có thể hủy toàn bộ chỉ định ngay lập tức mà <b>không thể hoàn tác.</b></i></div>
                            <div className='col-md-12'><i>* Cần xác nhận <b>nơi khám, nguồn khách hàng, hình thức trả kết quả</b>. Không cho phép điều chỉnh thông tin này khi hoàn tất thao tác tạo mới.</i></div>
                        </div>
                    }
                ></Alert>
                <div className='row mt-2'>
                    <NguonKhachHang ref={_nguonKhachHang}
                        managermentId={managementId}
                        chiNhanhDatLich={site}
                        updateInfoBangGia={(info) => {
                            let bgia = info.banggia;
                            if (bgia == null || bgia == '' || bgia == undefined) {
                                bgia = bangGiaTheoSite
                                if (bgia == null || bgia == '' || bgia == undefined)
                                    bgia = 'default';
                            }
                            setBangGiaTheoNguonKH(info.banggia);
                            checkBangGia({ banggia: bgia });
                        }}
                        setBangGiaDefault={false}
                        disable={!props.isCreate}
                    />
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                        <Select
                            placeholder="Chọn chi nhánh"
                            style={{ width: '100%' }}
                            value={site}
                            onChange={(value, item) => {
                                //cần xét trường hợp load để edit (có sẵn banggiaTheoNguonKH theo nguonKH đã lưu), thì set banggiaTheoNguonKH từ lúc mới load
                                if (bangGiaTheoNguonKH == null || bangGiaTheoNguonKH == '' || bangGiaTheoNguonKH == undefined) {
                                    let bgia = item.item.banggia;
                                    if (bgia == null || bgia == '' || bgia == undefined)
                                        bgia = 'default';
                                    checkBangGia({ banggia: bgia });
                                }
                                setBangGiaTheoSite(item.item.banggia);
                                setSite(value);
                            }}
                            disabled={!props.isCreate}
                        >
                            {listSite && listSite.map((item, index) => {
                                return (
                                    <option key={index} value={item.id} item={item}>{item.ten}</option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <label>Hình thức trả kết quả<Tooltip title={`Chỉ áp dụng cho lần đăng ký này.${!props.isCreate ? " Nếu muốn cập nhật, vui lòng chuyển đến màn hình đăng ký (sau khi xác nhận đến)." : ""}`}>&nbsp;<i className='far fa-question-circle' /></Tooltip></label>
                        <Select value={sendBy} placeholder="Chọn hình thức trả kết quả" className='w-100'
                            onChange={(value) => {
                                setSendBy(value);
                            }}
                            disabled={!props.isCreate}
                            showSearch
                            onSearch={(e) => {
                                if (taskDelay) clearTimeout(taskDelay);
                                taskDelay = setTimeout(() => {
                                    getListHTTKQ(e);
                                }, 400);
                            }}
                            mode='multiple'
                            filterOption={(input, option) => {
                                return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                    option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }}>
                            {
                                listHTTKQ && listHTTKQ.map((x, index) => {
                                    return (
                                        <Select.Option key={x.id} value={x.keyValue}>{x.keyName}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="row mt-2" >
                    <div className='col-12'>
                        <label>Ghi chú</label>
                        <TextArea rows={5} value={initDetail} readOnly={true}
                        ></TextArea>
                    </div>
                    <div className="col-12 d-flex mt-2" >
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }}
                            onChange={(e) => {
                                setDetail(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && e.ctrlKey) {
                                    onChangeDetail();
                                }
                            }}
                            value={detail}
                            placeholder="Nhập ghi chú và bấm thêm hoặc bấm Ctrl+Enter">
                        </TextArea>
                        <button style={{ width: 90 }} className={`btn ${!isNullOrEmpty(detail) ? "btn-warning-sparkle" : "btn-color"} ml-2`}
                            onClick={() => { onChangeDetail() }}><i className="fa fa-plus mr-1"></i>Thêm</button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <span style={{ width: '80px' }}>Ngày hẹn: </span>
                        <DateTimePicker
                            className="w-100"
                            locale="vi"
                            calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                            clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                            format={'dd-MM-yyyy HH:mm'}
                            isClockOpen={false}
                            isCalendarOpen={false}
                            onChange={setNgayHen}
                            value={ngayHen ? ngayHen : new Date()}
                            minDate={moment(ngayHen).year() > 1 && moment(ngayHen) < moment() ? moment(ngayHen).toDate() : moment().toDate()}
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <div className="border-register">
                            <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                <div className="h4 font-weight-bold">Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) =>
                                    tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>
                                <Button className="btn btn-secondary ml-auto" onClick={e => {
                                    e.stopPropagation();
                                    setVisibleOrder(true);
                                }}>Thêm/Sửa chỉ định</Button>
                            </div>
                            <div className="collapse mt-2" id="chidinh">
                                <div className="table-responsive">
                                    <Table dataSource={dataSource}
                                        rowKey={(e) => e.id}
                                        columns={regiterServiceColumn}
                                        pagination={false}
                                        scroll={{ y: 0 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-hr'></div>
                <ConsultantWarning visible={dataOrder.length > 0 && dataOrder.some(x => isNullOrEmpty(x.consultantId))} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn btn-color" onClick={() => {
                        props.isCreate ? saveLichHen() : updateLichHen()
                    }}> <i className="fa fa-save mr-1" />{props.isCreate ? "Thêm" : "Cập nhật"}</button>
                    <button className="btn btn-danger ml-2" onClick={() => {
                        onCloseModal();
                    }}><i className="fas fa-sign-out-alt mr-1" />Đóng</button>
                </div>
                <Modal
                    width={1400}
                    visible={visibleOrder}
                    onCancel={() => setVisibleOrder(false)}>
                    <ModalCLS
                        showGoiKham={false}
                        showCombo={ALLOW_COMBO}
                        maskClosable={false}
                        chinhanh={site}
                        ref={_orderRef}
                        onClose={(data, state) => {
                            setVisibleOrder(false);
                            if (state == 'save') {
                                if (data === null) data = [];
                                setDataOrder(data);
                            }
                        }}
                        banggia={bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia}
                        disableSelectBangGia={!props.allowToChangeServicePriceList}
                        editAfterRegister={true}
                    />
                </Modal>
                <Modal
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={modalSelectConsultant}
                    destroyOnClose={true}
                    width={1000}>
                    <SelectConsultant
                        isDialog={true}
                        dataSource={((dataOrder) ?? []).map(x => ({ ...x }))}
                        onClose={() => { setModalSelectConsultant(false) }}
                        onSubmit={(values) => {
                            onUpdateConsultant(values);
                            setModalSelectConsultant(false)
                        }}
                    />
                </Modal>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
		coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
		allowToChangeServicePriceList: state.global.sysConfig.allowToChangeServicePriceList ? state.global.sysConfig.allowToChangeServicePriceList : false,
		consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaoCapNhatLichHenKhamHotline)