import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'Promotion';

export function createPromotion(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreatePromotion`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getPromotionsList(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetPromotionsList?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListVouchers(groupid, key, page, size, used) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListVouchers?groupid=${groupid}&key=${key}&page=${page}&size=${size}&used=${used}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updatePromotion(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdatePromotion`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deletePromotion(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeletePromotion/${id}`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function clonePromotion(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ClonePromotion/${id}`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getVouchers(code, mabn, date, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetVouchersNew?code=${code}&mabn=${mabn}&date=${date}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printVouchers(id, mau, code) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintVouchers?id=${id}&mau=${mau}&code=${code}`).then(respone => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function getPromotions(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Loyalty/GetPromotions?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListPatients(promotionid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPatients/${promotionid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetServiceVoucher(voucher, mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetPromotionByVoucher?code=${voucher}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListServiceVoucher(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListServiceVoucher/${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListVouchers(groupid, key, used) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListVouchers?groupid=${groupid}&key=${key}&used=${used}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListPatients(promotionid) {
    return new Promise((resolve, reject) => {
        // return ApiCaller.httpGet(`${prefix}/GetListPatients/${promotionid}`).then(respone => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListPatients/${promotionid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetLoyaltyService(bangGia, code, promote, listCriteria, listDiscount) {
    let data = {
        bangGia: bangGia,
        voucherCode: code,
        promotionId: promote,
        criteriaProduct: listCriteria,
        discountProduct: listDiscount
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetLoyaltyService`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};