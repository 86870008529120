import { Button, Checkbox, DatePicker, Form, Input, Modal, InputNumber, notification, Popconfirm, Select, Table, TimePicker, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EnterInfoService from '../../../services/enterInfoService';
import * as Actions from '../../../libs/actions';
import * as PrintService from '../../../services/printService';
import Administrative from '../../../screens/recept/components/administrative';
import * as InvoiceService from '../../../services/invoiceService';
import viVN from 'antd/es/date-picker/locale/vi_VN';

const { Option } = Select;
const init = {
    ngaythu: moment(),
    giothu: moment().format('HH:mm'),
    loai: '',
    quyenso: '',
    loaibn: '1',
    kyhieubienlai: '',
    sothe: '',
    hanthe: [moment(), moment()],
    manoidangky: '',
    noidangky: '',
    lstNoidangky: [],
    ngayvv: '',
    lstNgayvv: [],
    doituongvao: '',
    khoavao: '',
    khoatamung: '',
    doituongtamung: '',
    madoituongvao: '',
    makhoavao: '',
    makhoatamung: '',
    madoituongtamung: '',
    sotien: 0,
    checkChange: true,
    mabn: '',
    maql_ngayvv: '',
    mavaovien: '',
    lydo: '',
    tongtientamung: 0,
    tongtienvienphi: 0,
    hinhthucthanhtoan: 1,
    isCTTU: false,
    visible: false,
    tenbn: '',
}
class TamUng extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...init,
            lstLoai: [],
            lstQuyenso: [],
            lstLoaibn: [],
            lstKyhieubienlai: [],
            lstThutrongngay: [],
            lstDathu: [],
            locSo: true,
            lstCanThanhToan: [],
            ghichu: '',
        };
    }
    componentWillMount() {
        this.loadKCB();
        this.LoadLoaiThu();
        //this.LoadLoaiBenhNhan();
        //this.LoadDanhSachDaThu();
        //this.LoadDanhSachThuTrongNgay();
    }
    componentDidUpdate() {

        if (this.props.data !== undefined && this.props.data !== null) {
            this.LoadDanhSachNgayVaoVien(this.props.data);
            this.setState({ mabn: this.props.data });
            Actions.setDataBarCode(null);
        }
    }
    loadKCB(key) {
        EnterInfoService.GetDSKCB(key).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNoidangky: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi đăng ký bị lỗi' })
        })
    }
    LoadLoaiThu() {
        InvoiceService.LoaiThuTamUng().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstLoai: result.data,
                    loai: result.data[0].value
                }, () => this.LoadDanhSachQuyenSo());
                this.form.setFieldsValue({ loaithu: result.data[0].value })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu loại thu tạm ứng bị lỗi' })
        })
    }
    LoadLoaiBenhNhan() {
        InvoiceService.LoaiBenhNhan().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstLoaibn: result.data,
                    loaibn: result.data[0].value
                });
                this.form.setFieldsValue({ loaibn: result.data[0].value })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh nhân bị lỗi' })
        })
    }
    LoadDanhSachQuyenSo() {
        InvoiceService.DanhSachKyHieu(this.state.loai, this.state.locSo, true).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstQuyenso: result.data,
                    quyenso: result.data[0].value,
                }, () => this.LoadDanhSachKyHieu());
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu quyển sổ bị lỗi' })
        })
    }
    LoadDanhSachKyHieu() {
        InvoiceService.DanhSachKyHieu(this.state.loai, this.state.locSo, false).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstKyhieubienlai: result.data,
                    kyhieubienlai: this.state.quyenso
                });
                this.form.setFieldsValue({
                    kyhieubienlai: this.state.quyenso,
                    quyenso: this.state.quyenso,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu ký hiệu bị lỗi' })
        })
    }
    resetState() {
        this.form.setFieldsValue({
            ngayvv: null,
            quyenso: null,
            kyhieubienlai: null,
            //loaithu: null,
            //loaibn: null,
        })
        this.setState(init);
        this.administrative.resetState();
    }
    LoadDanhSachNgayVaoVien(mabn) {
        InvoiceService.DanhSachNgayVaoKham(this.state.ngaythu.format('YYYY-MM-DD'), mabn).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNgayvv: result.data,
                    ngayvv: result.data[0].ngayvv,
                    maql_ngayvv: result.data[0].maql,
                    tenbn: result.data[0].tenbn,
                    mavaovien: result.data[0].mavaovien,
                }, () => { this.LoadDanhSachDaThu(); this.LoadDanhSachCanThanhToan() });
                this.form.setFieldsValue({
                    ngayvv: result.data[0].maql,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu ngày vào khám bị lỗi' })
        })
    }
    LoadDanhSachDaThu() {
        if (this.state.ngayvv === '') {
            notification.warning({ message: 'Chưa chọn ngày vào!' })
            return;
        }
        InvoiceService.DanhSachDaTamUng(moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'), this.state.ngaythu.format('YYYY/MM/DD HH:mm'), this.state.mabn, this.state.loai).then(result => {
            if (result.isSuccess) {
                let tongtien = 0;
                result.data.map(x => tongtien += Number(x.sotien))
                this.setState({
                    lstDathu: result.data,
                    tongtientamung: tongtien,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu loại thu tạm ứng bị lỗi' })
        })
    }
    LoadDanhSachThuTrongNgay(key = '') {
        InvoiceService.DanhSachThuTrongNgay(this.state.ngaythu.format('YYYY/MM/DD HH:mm'), key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstThutrongngay: result.data,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thu trong ngày bị lỗi' })
        })
    }

    LoadDanhSachCanThanhToan() {
        if (this.state.maql_ngayvv === '' || this.state.mabn === '') {
            notification.warning({ message: 'Chưa chọn bệnh nhân' })
            return;
        }
        if (this.state.ngayvv === '') {
            notification.warning({ message: 'Chưa chọn ngày vào!' })
        }
        InvoiceService.DanhSachCanThanhToan(this.state.mabn, this.state.maql_ngayvv, moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm')).then(result => {
            if (result.isSuccess) {
                let tongtien = 0;
                result.data.map(x => tongtien += (x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100)))
                this.setState({
                    lstCanThanhToan: result.data,
                    tongtienvienphi: tongtien,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu chi tiết viện phí bị lỗi' })
        })
    }
    async LuuTamUng() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            Actions.setLoading(true);
            const data = {
                'ngayvv': moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
                'kyhieu': this.state.kyhieubienlai,
                'mabn': this.state.mabn,
                'maql': this.state.maql_ngayvv,
                'ngaythu': this.state.ngaythu.format('YYYY/MM/DD') + ' ' + this.state.giothu,
                'sotien': this.state.sotien,
                'loaithu': this.state.loai,
                'loaibn': this.state.loaibn,
                'mavaovien': this.state.mavaovien,
                'lydo': this.state.lydo,
                'paymenttype': this.state.hinhthucthanhtoan,
            }
            InvoiceService.LuuThuTamUng(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    this.LoadDanhSachDaThu();
                    this.LoadDanhSachCanThanhToan();
                    this.setState({ lydo: '', sotien: 0 })
                    //this.LoadDanhSachThuTrongNgay();
                }
                else {
                    notification.success({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        }
    }
    printImprestFund(id, bienlai, ngay) {
        const date = moment(ngay, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm');
        PrintService.PrintImprestFund(id, bienlai, date, this.state.maql_ngayvv).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintQuyetToan() {
        PrintService.PrintQuyetToan(this.state.mabn, this.state.maql_ngayvv, moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'), this.state.ngaythu.format('YYYY/MM/DD'), this.state.loai).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    XemChiTietTU() {
        Actions.setLoading(true);
        InvoiceService.DanhSachCanHoanTraChiTietTamUng(moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.state.mabn, this.state.maql_ngayvv).then(result => {
            if (result.isSuccess) {
                let tonghoantra = 0;
                result.data.map(x => x.hoantra !== 'Đã hoàn trả' ? tonghoantra += x.sotien : tonghoantra += 0);
                this.setState({
                    isCTTU: true,
                    lstTamUngCT: result.data,
                    tonghoantraTU: tonghoantra,
                });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu hoàn trả chi tiết tạm ứng bị lỗi' })
        })
    }
    HoanTraTamUng(row) {
        const data = {
            mabn: this.state.mabn,
            maql: this.state.maql_ngayvv,
            idhoantra: row.idtamung,
            ghichu: this.state.ghichu,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTamUng(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTietTU(row);
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }
    HoanTraTatCaTamUng() {
        const data = {
            mabn: this.state.mabn,
            maql: this.state.maql_ngayvv,
            dateFrom: moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD'),
            ghichu: this.state.ghichu,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTatCaTamUng(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.setState({ ghichu: '' });
                this.XemChiTietTU({ mabn: data.mabn, maql: data.maql });
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }
    PrintRefundTamUng() {
        this.setState({ isCTTU: false });
        PrintService.PrintRefundTamUng(moment(this.state.ngayvv, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.state.mabn, this.state.maql_ngayvv).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        const columnDaThu = [
            {
                title: 'Ngày hóa đơn',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
            },
            // {
            //     title: 'Ký hiệu',
            //     dataIndex: 'kyhieu',
            //     key: 'kyhieu',
            // },
            {
                title: 'Số HĐ',
                dataIndex: 'sobienlai',
                key: 'sobienlai',
            },
            {
                title: 'Số tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Hình thức',
                dataIndex: 'paymenttype',
                key: 'paymenttype',
                render: (value) => {
                    const hinhthuc = value === 1 ? 'Tiền mặt' : (value === 2 ? 'Chuyển khoản' : 'Quẹt thẻ');
                    return <span>{hinhthuc}</span>;
                },
            },
            // {
            //     title: 'Loại thu',
            //     dataIndex: 'sobienlai',
            //     key: 'sobienlai',
            // },
            // {
            //     title: 'Loại bệnh nhân',
            //     dataIndex: 'loaibn',
            //     key: 'loaibn',
            // },
            // {
            //     title: 'Số lần in',
            //     dataIndex: 'solanin',
            //     key: 'solanin',
            // },
            {
                title: 'Nhân viên thu ngân',
                dataIndex: 'nhanvienthu',
                key: 'nhanvienthu',
            },
            {
                title: (<div><Button type='primary' onClick={() => { this.XemChiTietTU() }} title="Hoàn trả">Hoàn trả</Button></div>),
                render: (row) => {
                    return (
                        <div>
                            <Button onClick={() => { this.printImprestFund(row.idtamung, row.sobienlai, row.ngaythu) }} title="In">
                                <i className="fa fa-print"></i>
                            </Button>
                        </div>)
                },
            }
        ]
        const columnCanThanhToan = [
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Tên',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                render: (value, row) => {
                    return <span>{(value * ((100 - row.tylegiamgia) / 100)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Tổng tiền',
                render: (row) => {
                    return <span>{(row.soluong * row.dongia * ((100 - row.tylegiamgia) / 100)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
            },
            // {
            //     title: 'Loại khám',
            //     dataIndex: 'loaikham',
            //     key: 'loaikham',
            // }
        ]
        const columndetailTU = [
            {
                title: 'Tổng tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
            },
            {
                title: 'Trạng thái',
                dataIndex: 'hoantra',
                key: 'hoantra',
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả hóa đơn tạm ứng này không?"
                                onConfirm={() => { this.HoanTraTamUng(row) }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả hóa đơn tạm ứng">
                                    <Button type="primary" className="btn btn-outline"><i className="fa fa-undo"></i></Button>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        const columnQT = [
            {
                title: 'STT',
                render: (value, row, index) => {
                    return <span>{index + 1}</span>;
                },
            },
            {
                title: 'Thời gian',
                dataIndex: 'ngaythu',
                key: 'ngaythu',
            },
            {
                title: 'Lý do',
                dataIndex: 'lydo',
                key: 'lydo',
            },
            {
                title: 'Số tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            }
        ]
        return (
            <div style={{ padding: "5px 20px" }}>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }}>download</a>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Administrative
                            ref={((c) => this.administrative = c)}
                        />
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: '0 10px' }}>
                        <Form ref={(c) => this.form = c}>
                            <div className="h6 text-center" style={{ marginTop: 5, marginBottom: 10, textAlign: "center" }}>THÔNG TIN TẠM ỨNG</div>
                            <div style={{ display: "none" }}>
                                <div className="form-group d-flex">
                                    <Form.Item label="Ngày:" style={{ flex: 2 }}>
                                        <DatePicker locale={viVN} value={this.state.ngaythu}
                                            disabled={this.state.checkChange}
                                            format={"DD-MM-YYYY"}
                                            defaultValue={moment()} placeholder="DD-MM-YYYY" onChange={(date, dateString) => { this.setState({ ngaythu: date }) }} />
                                        <TimePicker locale={viVN}
                                            disabled={this.state.checkChange}
                                            defaultValue={moment()}
                                            format={'HH:mm'}
                                            onChange={(date, dateString) => { this.setState({ giothu: dateString }) }}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ flex: 1 }}>
                                        <Checkbox checked={this.state.locSo} onChange={(e) => this.setState({ locSo: e.target.checked }, () => this.LoadDanhSachQuyenSo())}>Lọc sổ theo loại</Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="form-group d-flex">
                                    <Form.Item label="Loại:" style={{ flex: 1 }} name="loaithu" rules={[{ required: true, message: 'Vui lòng chọn loại thu !' }]}>
                                        <Select value={this.state.loai} onChange={(value) => { this.setState({ loai: value }, () => this.LoadDanhSachQuyenSo()) }}>
                                            {this.state.lstLoai.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ flex: 1 }} label="Quyển sổ:" name="quyenso" rules={[{ required: true, message: 'Vui lòng chọn sổ lưu !' }]}>
                                        <Select value={this.state.quyenso}
                                            onChange={(value) => { this.setState({ quyenso: value, kyhieubienlai: value }); this.form.setFieldsValue({ kyhieubienlai: value }) }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstQuyenso.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="form-group d-flex">
                                    {/* <Form.Item label="Loại bệnh nhân:" style={{ flex: 1 }} name="loaibn" rules={[{ required: true, message: 'Vui lòng chọn loại bệnh nhân !' }]}>
                                        <Select value={this.state.loaibn} onChange={(value) => { this.setState({ loaibn: value }) }}>
                                            {this.state.lstLoaibn.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item> */}
                                    <Form.Item style={{ flex: 1 }} name="kyhieubienlai" rules={[{ required: true, message: 'Vui lòng chọn ký hiệu biên lai !' }]}
                                        label={<Tooltip placement="top" title="Nhấn vào đây để chọn ngày thu, ký hiệu thu">
                                            <span onClick={() => this.setState({ checkChange: !this.state.checkChange })} style={{ textDecorationLine: "underline", cursor: "pointer" }}>Ký hiệu biên lai</span>
                                        </Tooltip>}
                                    >
                                        <Select disabled={this.state.checkChange} value={this.state.kyhieubienlai}
                                            onChange={(value) => { this.setState({ kyhieubienlai: value }) }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstKyhieubienlai.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            {/*
                        <div className="form-group " style={{ display: 'flex' }}>
                            <Form.Item style={{ flex: 1 }} label="Số thẻ KCB:">
                                <Input  value={this.state.sothe} maxLength="20" placeholder="Nhập số thẻ"
                                    onChange={(e) => { this.setState({ sothe: e.target.value }) }} />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} label="Từ ngày:">
                                <RangePicker  value={this.state.hanthe} format={'DD-MM-YYYY'} placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                    onChange={(date, dateString) => { this.setState({ hanthe: date }) }} />
                            </Form.Item>
                        </div>
                        <div className="form-group" style={{ display: "flex" }}>
                            <Form.Item style={{ flex: 1 }} label="NĐKKCB:">
                                <Input disabled={true} value={this.state.manoidangky} placeholder="Mã" onChange={(e) => { this.setState({ manoidangky: e.target.value }) }} />
                            </Form.Item>
                            <Form.Item style={{ flex: 1 }} name="cardAddress">
                                <Autocomplete
                                    
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstNoidangky}
                                    handleSearch={this.loadKCB.bind(this)}
                                    getItem={(key, value) => { this.setState({ manoidangky: key }) }}
                                    value={this.state.manoidangky}
                                />
                            </Form.Item>
                        </div>
                        */}
                            <div style={{ marginTop: 5, display: "flex" }}>
                                <Form.Item label="Ngày vào khám:" style={{ flex: 1 }} name="ngayvv" rules={[{ required: true, message: 'Vui lòng chọn ngày vào khám !' }]}>
                                    <Select value={this.state.maql_ngayvv} onChange={(value, e) => { this.setState({ maql_ngayvv: value, ngayvv: e.children }); }}>
                                        {this.state.lstNgayvv.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.maql}>{row.ngayvv}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="số tiền:" style={{ flex: 1 }}>
                                    <InputNumber
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }} value={this.state.sotien} onChange={(e) => this.setState({ sotien: e })} />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", marginTop: 5 }}>
                                <Form.Item label="Hình thức thanh toán:" style={{ flex: 1 }}>
                                    <Select style={{ width: '100%' }} defaultValue={1} onChange={(value) => this.setState({ hinhthucthanhtoan: value })}>
                                        <Option key={1} value={1}>Tiền mặt</Option>
                                        <Option key={2} value={2}>Chuyển khoản</Option>
                                        <Option key={3} value={3}>Quẹt thẻ</Option>
                                    </Select></Form.Item>

                            </div>
                            <div style={{ marginTop: 5 }}>
                                <Form.Item label="Lý do:">
                                    <Input
                                        value={this.state.lydo} onChange={(e) => this.setState({ lydo: e.target.value })} />
                                </Form.Item>
                            </div>
                        </Form>
                        <div style={{ textAlign: "center" }}>
                            <button className="btn btn-success" style={{ marginRight: "5px", display: "none" }} onClick={() => { this.resetState() }}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                            <Popconfirm title="Xác nhận lưu tạm ứng?"
                                onConfirm={() => { this.LuuTamUng() }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Lưu tạm ứng">
                                    <button className="btn btn-success" > <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                                </Tooltip>
                            </Popconfirm>

                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{ marginTop: 10, textAlign: "center" }}>DANH SÁCH ĐÃ TẠM ỨNG
                        <Button style={{ marginLeft: '5px' }} onClick={() => { this.LoadDanhSachDaThu() }}><i className="fas fa-sync"></i></Button>
                        </div>
                        <Table pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                            dataSource={this.state.lstDathu} columns={columnDaThu}
                            rowKey={(row, index) => index}

                            onRow={(record, rowIndex) => {
                                return {
                                    //onClick: e => { this.setValue(record); this.ChiTietBenhNhan(record.mabn, record.maql, record) },
                                };
                            }}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", padding: "5px", justifyContent: "center", fontWeight: "bold", fontSize: "large" }}>
                    <div style={{ margin: "5px 20px" }}>
                        Tạm ứng: {this.state.tongtientamung.toLocaleString('en-US')}
                    </div>
                    <div style={{ margin: "5px 20px" }}>
                        Viện phí: {this.state.tongtienvienphi.toLocaleString('en-US')}
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, padding: "5px" }}>
                        <div style={{ marginTop: 5, marginBottom: 10, textAlign: "center" }}>DANH SÁCH VIỆN PHÍ
                        <Button style={{ marginLeft: '5px' }} onClick={() => { this.LoadDanhSachCanThanhToan() }}><i className="fas fa-sync"></i></Button>
                            <Button type='primary' style={{ marginLeft: '5px' }} onClick={() => { this.setState({ visible: true }) }}>Quyết toán</Button>
                        </div>
                        <Table pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                            dataSource={this.state.lstCanThanhToan} columns={columnCanThanhToan}
                            rowKey={(row, index) => index}

                            onRow={(record, rowIndex) => {
                                return {
                                    //onClick: e => { this.setValue(record); this.ChiTietBenhNhan(record.mabn, record.maql, record) },
                                };
                            }}
                        />
                    </div>

                </div>
                {this.state.isCTTU && <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.isCTTU}
                    onCancel={() => this.setState({ isCTTU: false })}
                    footer={
                        <div style={{ display: "flex" }}>
                            <Input placeholder="Nhập ghi chú" onChange={(e) => this.setState({ ghichu: e.target.value })} />
                            <Popconfirm title="Bạn có chắc muốn hoàn trả tất cả hóa đơn này không?"
                                onConfirm={() => { this.HoanTraTatCaTamUng() }} okText="Đồng ý" cancelText="Hủy">
                                <Tooltip placement="top" title="Hoàn trả tất cả hóa đơn">
                                    <Button type="primary" className="btn btn-outline">Hoàn trả tất cả</Button>
                                </Tooltip>
                            </Popconfirm>
                            <Tooltip placement="top" title="In hóa đơn hoàn trả">
                                <button className="btn btn-primary" onClick={() => this.PrintRefundTamUng()}><i style={{ marginRight: 5 }} className="fa fa-print" />In</button>
                            </Tooltip>
                            <button className="btn btn-danger" onClick={() => this.setState({ isCTTU: false })}>Đóng</button>
                        </div>
                    }
                >
                    <div>
                        Tổng tiền hoàn trả: {this.state.tonghoantraTU.toLocaleString('en-US')} VNĐ
                    </div>
                    <Table dataSource={this.state.lstTamUngCT} columns={columndetailTU} rowKey={(row, index) => index}></Table>
                </Modal>}
                {this.state.visible && <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    footer={null}
                >
                    <h1 style={{ textAlign: 'center', marginTop: '30px' }}>Quyết toán viện phí</h1>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>Tên BN: <strong>{this.state.tenbn}</strong></div>
                        <div style={{ flex: 1 }}>Mã BN: <strong>{this.state.mabn}</strong></div>
                    </div>
                    <h3>1. DANH SÁCH TẠM ỨNG</h3>
                    <Table dataSource={this.state.lstDathu} columns={columnQT} pagination={false} rowKey={(row, index) => index}
                        footer={() => (
                            <div style={{ textAlign: 'end', paddingRight: '20px' }}><strong>Tổng: {this.state.tongtientamung.toLocaleString('en-US')}</strong></div>
                        )}
                    ></Table>
                    <h3>2. DANH SÁCH VIỆN PHÍ</h3>
                    <Table dataSource={this.state.lstCanThanhToan} columns={columnCanThanhToan} rowKey={(row, index) => index}
                        footer={() => (
                            <div style={{ textAlign: 'end', paddingRight: '20px' }}><strong>Tổng: {this.state.tongtienvienphi.toLocaleString('en-US')}</strong></div>
                        )}
                    ></Table>
                    <div style={{ textAlign: 'center' }}><Button type='primary' onClick={() => { this.PrintQuyetToan() }}><strong>Quyết toán</strong></Button></div>
                </Modal>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        data: state.global.data,
    })
}
export default connect(
    mapStateToProps,
)(TamUng);
