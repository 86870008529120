import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from '../../../configureHIS/common/headerModal';
import ActionControl from '../../../libs/actionControl';
import MedicineList from './medicineList';
import MedicineSearch from './medicineSearch';
import * as MedicineService from '../../../services/medicineService';
import { isNullOrEmpty } from '../../../libs/util';
import * as Actions from '../../../libs/actions';

function CreateUpdate(props) {
    // Thuốc đơn (liên quan đến kho) hay thuốc kê ngoài (Không liên quan đến kho)
    const [isDonNgoai, setIsDonNgoai] = useState(props.isDonNgoai ?? true);
    // Cập nhật đơn thuốc?
    const [isUpdate, setIsUpdate] = useState(props.isUpdate ?? false);
    // data đơn thuốc: maDon (Mã đơn), tenBenhNhan (Tên bệnh nhân), maDichVu (ID dịch vụ khám)...
    const [data, setData] = useState([]);
    const [title, setTitle] = useState();
    const [tenKhoThuoc, setTenKhoThuoc] = useState("");
    const [maKhoThuoc, setMaKhoThuoc] = useState();
    const [trangThai, setTrangThai] = useState("");
    const [listThuoc, setListThuoc] = useState([]);

    const notiRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        let title = "";
        props.isThuocNgoai && setIsDonNgoai(props.isDonNgoai);
        if (props.isUpdate) {
            setIsUpdate(props.isUpdate);
            title = "Cập nhật"
        } else title = "Tạo đơn thuốc"
        if (props.data) {
            setData(props.data);
            let danhSachThuoc = props.data.danhSachThuoc;
            if (danhSachThuoc && danhSachThuoc.length > 0) {
                danhSachThuoc = danhSachThuoc.map(x => ({...x, sang: x.sang.toString(), trua: x.trua.toString(), chieu: x.chieu.toString(), toi: x.toi.toString()}))
            }
            setListThuoc(danhSachThuoc ?? []);
            if (props.data.maDon) title += ` - ${props.data.maDon}`;
            if (props.data.tenBenhNhan) title += ` - ${props.data.tenBenhNhan}`;
        }
        setTitle(title);
        getThongTinDonThuoc();
    }, [props.data]);

    const getThongTinDonThuoc = (hideNoti = true) => {
        let idDonThuoc = data.maDon ? data.maDon : props.data.maDon ?? "";
        MedicineService.getThongTinDonThuoc(idDonThuoc).then(result => {
            if (result.isSuccess) {
                hideNoti && notiRef && notiRef.current.Close();
                setTenKhoThuoc(result.data.tenKhoThuoc);
                setMaKhoThuoc(result.data.maKhoThuoc);
                setTrangThai(result.data.trangThai);
                let danhSachThuoc = result.data.danhSachThuoc;
                if (danhSachThuoc && danhSachThuoc.length > 0) {
                    danhSachThuoc = danhSachThuoc.map(x => ({...x, sang: x.sang.toString(), trua: x.trua.toString(), chieu: x.chieu.toString(), toi: x.toi.toString()}))
                }
                setListThuoc(danhSachThuoc);
            }
            else {
                if (result.error)
                    notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                if (result.err)
                    notiRef.current.Show(result.err.msgString, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            forceUpdate();
        })
    }

    const handleClose = () => {
        props.onClose && props.onClose();
        setTitle();
    }

    const handleSelectMedicine = (record) => {
        let isExisted = false;
        let newMedicineList = listThuoc;
        let tenThuoc = "";
        if (record.isGoiThuoc && record.listThuoc && record.listThuoc.length > 0) {
            record.listThuoc.every(subThuoc => {
                isExisted = newMedicineList.filter(x => x.idThuoc === subThuoc.idThuoc).length > 0;
                tenThuoc = subThuoc.tenThuoc;
                if (isExisted) return;
            });
        }
        else if (newMedicineList.length > 0) isExisted = newMedicineList.filter(x => x.idThuoc === record.idThuoc).length > 0;
        if (isExisted) {
            notiRef && notiRef.current && notiRef.current.Show(`Thuốc "${record.isGoiThuoc ? tenThuoc : record.tenThuoc}" đã tồn tại trong danh sách`, NotifyStatus.Warning);
        } else {
            notiRef && notiRef.current && notiRef.current.Close();
            if (record.isGoiThuoc) {
                record.listThuoc.forEach(subThuoc => {
                    if (subThuoc.soLuong === undefined || subThuoc.soLuong === null || subThuoc.soLuong === 0)
                        subThuoc.soLuong = 1;
                    newMedicineList.push(subThuoc);
                });
            } else newMedicineList.push(record);
            setListThuoc(newMedicineList);
            forceUpdate();
        }
    }

    const handleSave = (isPrint) => {
        if (isNullOrEmpty(data.maDichVu)) {
            notiRef.current.Show('Chưa chọn dịch vụ khám', NotifyStatus.Warning);
            return;
        }
        if (!listThuoc || listThuoc.length === 0) {
            notiRef.current.Show('Chưa chọn thuốc - vật tư', NotifyStatus.Error);
            return;
        }
        let listErrorMedicine = listThuoc.filter(x => x.soLuong === 0 || isNullOrEmpty(x.soLuong) || x.soLuong.toString() == "NaN");
        let listErrorQuantity = [];
        if (!isDonNgoai) {
            listErrorMedicine = listThuoc.filter(x => x.soLuong === 0 || isNullOrEmpty(x.soLuong) || x.soLuong.toString() == "NaN");
            listErrorQuantity = listThuoc.filter(x => x.soLuong > x.soLuongTon);
        }
        if (listErrorMedicine.length > 0 || listErrorQuantity.length > 0) {
            let notiMessage = "";
            if (listErrorQuantity.length > 0) {
                notiMessage += `Số lượng tồn không đủ xuất: ${listErrorQuantity.map(x => (x.tenThuoc)).join(", ")}`;
            }
            if (listErrorMedicine.length > 0) {
                if (notiMessage != "") notiMessage += ". ";
                notiMessage += `Số lượng không hợp lệ: ${listErrorMedicine.map(x => (x.tenThuoc)).join(", ")}`;
            }
            notiRef.current.Show(notiMessage, NotifyStatus.Error);
            return;
        }
        let listMissingUseDate = listThuoc.filter(x => x.ngayDung === 0 || isNullOrEmpty(x.ngayDung) || x.ngayDung.toString().includes("."));
        if (listMissingUseDate.length > 0) {
            notiRef.current.Show(`Số ngày dùng không hợp lệ: ${listMissingUseDate.map(x => (x.tenThuoc)).join(", ")}`, NotifyStatus.Error);
            return;
        }
        notiRef && notiRef.current.Close();
        Actions.setLoading(true);
        const patientInfo = {
            maBenhNhan: data.maBenhNhan,
            maQuanLy: data.maQuanLy,
            maKhoa: data.maKhoa,
            maPhong: data.maPhong,
            maDichVu: data.maDichVu,
            isDonNgoai: isDonNgoai,
            maDon: data.maDon,
        }
        const dataToSend = {
            patients: patientInfo,
            medicines: listThuoc,
        }
        MedicineService.saveDonThuoc(dataToSend).then(result => {
            if (result.isSuccess) {
                notiRef.current.Show("Lưu thành công", NotifyStatus.Success);
                if (isPrint) {
                    printPrescription(result.data);
                } else
                    handleClose();
            }
            else {
                notiRef.current.Show(`${result.error.messageText}${result.error.extensionData ? `: ${result.error.extensionData}` : ""}`, NotifyStatus.Error);
            }
        }).catch(err => {
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const printPrescription = (maDonThuoc) => {
        const data = {
            presciptionId: maDonThuoc,
            //printTemplateId: null,
            isDuoc: false,
            serviceId: props.data.maDichVu,
        }
        Actions.setLoading(true);
        MedicineService.printDonThuoc(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data);
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(() => {
        }).finally(() => {
            Actions.setLoading(false);
            handleClose();
        })
    }

    const updateNotify = (noti, state) => {
        notiRef && notiRef.current.Show(noti, state);
    }

    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={title} onClose={() => { handleClose() }} />
                <div className="list-page-body">
                    <div className="row">
                        <div className="col-md-12"><Notify ref={notiRef} /></div>
                    </div>
                    <div className='row'>
                        <div className={`col-md-${isDonNgoai ? 6 : 4} d-flex flex-column mt-2`}>
                            <label className='mita-title'>Mã đơn</label>
                            <label className='mita-value'>{data.maDon && data.maDon}</label>
                        </div>
                        {!isDonNgoai && <div className='col-md-4 d-flex flex-column mt-2'>
                            <label className='mita-title required-field'>Kho thuốc</label>
                            <label className='mita-value'>{tenKhoThuoc}</label>
                        </div>}
                        <div className={`col-md-${isDonNgoai ? 6 : 4} d-flex flex-column mt-2`}>
                            <label className='mita-title'>Trạng thái</label>
                            <label className='mita-value'>{trangThai}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2'>
                            <label className='mita-title'>Chi tiết thuốc</label>
                            <MedicineSearch
                                handleSelect={(record) => { handleSelectMedicine(record) }} isDonNgoai={isDonNgoai} />
                        </div>
                    </div>
                    <div className='row' style={{ overflowY: "auto" }}>
                        <div className='col-12 mt-2'>
                            <MedicineList
                                isEdit={true}
                                data={listThuoc}
                                maDon={props.data.maDon}
                                maDichVu={props.data.maDichVu}
                                handleGetNewData={() => { const hideNoti = false; getThongTinDonThuoc(hideNoti); }}
                                updateNotify={(noti, state) => updateNotify(noti, state)} />
                        </div>
                    </div>
                </div>
                <div className="list-page-footer d-flex justify-content-between mt-2">
                    <div className="w-100">
                        <ActionControl
                            activeSave={true}
                            activeSavePrint={true}
                            handleClose={() => handleClose()}
                            handleSave={() => handleSave()}
                            handleSavePrint={() => {
                                let isPrint = true;
                                handleSave(isPrint)
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CreateUpdate;