import { Button, Checkbox, DatePicker, Form, InputNumber, Modal, notification, Radio, Select, Table, Input } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Actions from '../../libs/actions';
import * as PrintService from '../../services/printService';
import * as InvoiceService from '../../services/invoiceService';
import DetailInvoice from './components/detailInvoice';
import InvoiceLst from './components/table';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as StatisticService from '../../services/statisticService';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default class Invoice extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstInvoice: [],
            lstColums: [],
            info: null,
            tungay: moment(),
            denngay: moment(),
            userid: '',
            visibleInvoice: false,
            visible: false,
            maql: '',
            ngay: moment(),
            totalRecords: 0,
            loai: 2,
            loaithu: '',
            loaithanhtoan: 1,
            hinhthucthanhtoan: 1,
            mucgiamgia: 0,
            tientamung: 0,
            dadongtamung: false,
            hiddenLuu: false,
            paid: false,
            lstResult: [],
            mabn: '',
            key: '',
            thutructiep: 0,
            thutamung: 0,
            idvpnewSave: 0,
            tongthanhtoan: 0,
            detail: {},
            ngayRange: [moment(), moment()],
        };
    }
    showModal = (e) => {
        this.setState({
            visibleInvoice: true,
        });
    };

    notifyUpdate = () => {
        this.search();
    }

    hideModal = () => {
        this.setState({
            maql: null,
            ngay: null,
            info: null,
            visibleInvoice: false,
            hiddenLuu: false,
            mucgiamgia: 0,
        });
    };
    componentWillMount() {
        this.search();
    }
    detail = () => {
        let tongthanhtoan = 0;
        let dataResult = [];
        if (this.state.paid === false) {
            var objectngay = moment(this.state.ngay);
            const data = new FormData();
            data.append('ngay', objectngay.format('YYYY/MM/DD'));
            data.append('maql', this.state.maql);
            InvoiceService.GetDetailInvoice(data).then(result => {
                if (result.isSuccess) {
                    dataResult = result.data;
                    this.setState({
                        info: result.data,
                    })
                }
                tongthanhtoan = dataResult.reduce((tmp, cur) => tmp + cur.thanhtien, 0);
                this.setState({
                    tongthanhtoan: tongthanhtoan,
                })
            }).catch(err => {
                this.setState({
                    tongthanhtoan: 0,
                })
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
        } else {
            InvoiceService.DanhSachHoaDonDaDong(this.state.mabn, this.state.maql).then(result => {
                if (result.isSuccess) {
                    dataResult = result.data;
                    this.setState({
                        lstResult: result.data
                    })
                }
                tongthanhtoan = dataResult.reduce((tmp, cur) => tmp + cur.thanhtien, 0);
                this.setState({
                    tongthanhtoan: tongthanhtoan,
                })
            }).catch(err => {
                this.setState({
                    tongthanhtoan: 0,
                })
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
        }
        this.TienTamUngTienChiDinh();
    }
    TienTamUngTienChiDinh() {
        InvoiceService.TienTamUngTienChiDinh(this.state.mabn, this.state.maql).then(result => {
            if (result.isSuccess) {
                this.setState({
                    thutamung: result.item.tamung,
                    thutructiep: result.item.thutructiep
                })
            }
            else {
                this.setState({
                    thutamung: 0,
                    thutructiep: 0
                })
            }
        }).catch(err => {
            this.setState({
                thutamung: 0,
                thutructiep: 0
            })
        })
    }
    save = () => {
        Actions.setLoading(true);
        var objectngay = moment(this.state.ngay);
        const data = new FormData();
        data.append('ngay', objectngay.format('YYYY/MM/DD'));
        data.append('maql', this.state.maql);
        data.append('loaithu', this.state.loaithu);
        data.append('hinhthucthanhtoan', this.state.hinhthucthanhtoan);
        data.append('loaithanhtoan', this.state.loaithanhtoan);
        data.append('tientamung', this.state.tientamung);
        data.append('discount', this.state.mucgiamgia);
        data.append('dadongtamung', this.state.tientamung === 0 ? true : false)
        InvoiceService.LuuInvoice(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công' });
                this.setState({
                    loaithanhtoan: 1,
                    hinhthucthanhtoan: 1,
                    tientamung: 0,
                    idvpnewSave: result.id,
                    hiddenLuu: true,
                })
                this.search();
                this.TienTamUngTienChiDinh();
            }
            else {
                // this.setState({
                //     hiddenLuu: false
                // })
                notification.warning({ message: result.err.msgString })
            }
            //  this.setState({ visibleInvoice: false });
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Lưu dữ liệu bị lỗi")
            Actions.setLoading(false);
        })
    }
    search() {
        Actions.setLoading(true);
        let data = {
            tungay: this.state.tungay.format('YYYY/MM/DD hh:mm'),
            denngay: this.state.denngay.format('YYYY/MM/DD hh:mm'),
            paid: this.state.paid,
            mabn: this.state.key,
        }
        let getInVoice;
        if (this.state.loai === 2) {
            getInVoice = InvoiceService.GetListInvoice(data);
        }
        else {
            getInVoice = InvoiceService.GetListInvoiceBHYT(data);
        }
        getInVoice.then(result => {
            this.setState({
                lstInvoice: result.data,
                totalRecords: result.totalRecords,

            })
            Actions.setLoading(false);
        }).catch(() => Actions.setLoading(false))
    }

    printInvoice(row) {
        if (this.state.paid === false) {
            PrintService.PrintInvoice(this.state.maql, moment(this.state.ngay).format('YYYY-MM-DD')).then((result) => {
                Actions.DoPrint(result.item)
            })
        }
        else {
            PrintService.PrintInvoicePaid(this.state.maql, row.idvienphi, moment(row.ngay).format('YYYY-MM-DD')).then((result) => {
                Actions.DoPrint(result.item)
            })
        }
    }

    printAfterSave() {

        PrintService.PrintInvoicePaid(this.state.maql, this.state.idvpnewSave, moment(this.state.ngay).format('YYYY-MM-DD')).then((result) => {
            Actions.DoPrint(result.item)
        })

    }
    ClickRow(row) {

        this.setState({ visibleInvoice: true, maql: row.maql, ngay: row.ngaycd, loaithu: row.loaithu, mabn: row.mabn, detail: row },

            () => this.detail())
    }
    ExportDanhSachChoTheoNgay() {
        Actions.setLoading(true);
        StatisticService.ExportDanhSachChoTheoNgay(this.state.ngayRange[0].format('YYYY-MM-DD'), this.state.ngayRange[1].format('YYYY-MM-DD'))
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDanhSachHoaDon";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Mã ql',
                dataIndex: 'maql',
                key: 'maql',
            },
            {
                title: 'Họ tên',
                dataIndex: 'hoten',
                key: 'hoten',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                render: (value) => {
                    return <span>{moment(value, 'YYYY/MM/DD').format('DD-MM-YYYY')}</span>;
                },
            },
            {
                title: 'Ngày chỉ định',
                dataIndex: 'ngaycd',
                key: 'ngaycd',
                render: (value) => {
                    return <span>{moment(value, 'YYYY/MM/DD').format('DD-MM-YYYY')}</span>;
                },
            },
            // {
            //     title: 'Số tiền',
            //     dataIndex: 'sotien',
            //     key: 'sotien',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            // },
            // {
            //     title: 'Tiền giảm',
            //     render: (row) => {
            //         return <span>{(row.sotien - row.sotienkm).toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                title: 'Tiền thanh toán',
                align: 'right',
                render: (row) => {
                    return <span>{(row.sotienkm).toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Loại thu',
            //     dataIndex: 'loaithu',
            //     key: 'loaithu'
            // },
            // {
            //     title: '',
            //     //render: (row) => <button onClick={() => this.setState({ visiblePatient: true })} className="btn btn-outline-danger" title="In hóa đơn"><i className="fa fa-print"></i></button>,
            //     render: (e, row) => {
            //         return (
            //             <button
            //                 onClick={() => {
            //                     this.setState({ visibleInvoice: true, maql: row.maql, ngay: row.ngaycd, loaithu: row.loaithu, mabn: row.mabn },
            //                         () => this.detail())
            //                 }}
            //                 className="btn btn-outline-danger" title="Xem chi tiết">
            //                 <i className="fa fa-edit"></i>
            //             </button>)
            //     }

            // }
        ];
        const columnVP = [
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>{moment(value).format('DD-MM-YYYY HH:mm')}</span>;
                },
            },
            {
                title: 'Hóa đơn',
                dataIndex: 'sobienlai',
                key: 'sobienlai',
            },
            // {
            //     title: 'Số tiền',
            //     dataIndex: 'tongtien',
            //     key: 'tongtien',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            // },
            // {
            //     title: 'Tiền giảm',
            //     render: (row) => {
            //         return <span>{(row.tongtien - row.tongtienkm * ((100 - row.giamgia) / 100)).toLocaleString('en-US')}({row.giamgia}%)</span>;
            //     },
            // },
            {
                title: 'Giảm giá',
                render: (row) => {
                    return <span>{row.giamgia}%</span>;
                },
            },
            {
                title: 'Tiền thanh toán',
                align: 'right',
                render: (row) => {
                    return <span>{Number((row.tongtienkm * ((100 - row.giamgia) / 100)).toFixed(0)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                render: (e, row) => {
                    return (
                        <Button className="btn btn-primary" onClick={() => { this.printInvoice(row) }} title="In">
                            <i className="fa fa-print"></i> </Button>)
                }

            }
        ];
        const columnVPDetail = [
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Tên',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
            // {
            //     title: 'Giá',
            //     dataIndex: 'dongia',
            //     key: 'dongia',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                title: 'Đơn giá',
                dataIndex: 'dongiakm',
                key: 'dongiakm',
                align: 'right',
                render: (value) => {
                    return <span>{(value).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'SL',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
            },
        ];
        let tongtien = this.state.info ? this.state.info.reduce((tmp, cur) => tmp + (cur.giakm * cur.soluong), 0) : 0;
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <Modal maskClosable={false}
                    width={700}
                    visible={this.state.visibleInvoice}
                    onCancel={() => { this.setState({ visibleInvoice: false, hiddenLuu: false, mucgiamgia: 0, hinhthucthanhtoan: 1 }) }}
                    onOk={this.save}
                    footer={null}
                >
                    {this.state.info !== null && this.state.paid === false ?
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>Tên: <strong>{this.state.detail.hoten}</strong></div>
                                <div style={{ flex: 1 }}>Mã BN: <strong>{this.state.detail.mabn}</strong></div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>Năm sinh: <strong>{moment(this.state.detail.ngaysinh, 'YYYY/MM/DD').year()}</strong></div>
                                <div style={{ flex: 1 }}>Điện thoại: <strong>{this.state.detail.dt_didong}</strong></div>
                            </div>
                            <div>Địa chỉ: <strong>{this.state.detail.diachi}</strong></div>
                            <div style={{ display: 'flex', marginTop: '15px', marginBottom: '15px' }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Item label="Hình thức thanh toán:">
                                        <Select style={{ width: '100%', flex: 2 }} defaultValue={1} onChange={(value) => this.setState({ hinhthucthanhtoan: value })}>
                                            <Option key={1} value={1}>Tiền mặt</Option>
                                            <Option key={2} value={2}>Chuyển khoản</Option>
                                            <Option key={3} value={3}>Quẹt thẻ</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Item label={<div>Mức giảm giá (%):<br />(Tổng thanh toán)</div>}>
                                        <InputNumber min={0} max={100} value={this.state.mucgiamgia} onChange={(e) => this.setState({ mucgiamgia: e })} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ display: 'none' }}>
                                <Radio.Group style={{ display: "flex", marginBottom: '10px' }} onChange={(e) => this.setState({ loaithanhtoan: e.target.value })} value={this.state.loaithanhtoan}>
                                    <Radio style={{ flex: 1 }} value={1}>Thu trực tiếp</Radio>
                                    <Radio style={{ flex: 2 }} value={2}>Thu tạm ứng</Radio>
                                </Radio.Group>
                            </div>
                            <div style={{ display: 'none' }}>
                                <div style={{ display: `${this.state.loaithanhtoan === 2 ? 'flex' : 'none'}`, marginBottom: "10px", marginTop: "10px" }}>
                                    <div style={{ flex: 1 }}>
                                        Tiền tạm ứng: <strong>{this.state.thutamung.toLocaleString('en-US')}</strong>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        Đã chi tạm ứng: <strong>{this.state.thutructiep.toLocaleString('en-US')}</strong>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        Tạm ứng còn: <strong>{(this.state.thutamung - this.state.thutructiep).toLocaleString('en-US')}</strong>
                                    </div>
                                </div>
                            </div>
                            {this.state.loaithanhtoan === 2 &&
                                <div style={{ display: "none", marginBottom: '10px' }}>
                                    <Checkbox style={{ flex: 1, display: "none" }} checked={this.state.dadongtamung}
                                        onChange={(e) => this.setState({ dadongtamung: e.target.checked })}>Đã đóng tạm ứng</Checkbox>
                                    <label style={{ flex: 1 }}>Số tiền tạm ứng: </label>
                                    <InputNumber
                                        disabled={this.state.dadongtamung}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%', flex: 1 }} value={this.state.tientamung} onChange={(e) => this.setState({ tientamung: e })} />
                                </div>
                            }
                            <DetailInvoice detail={this.state.info} loaithu={this.state.loaithu} />
                            <div><strong style={{ color: 'red' }}>{(this.state.detail.dongtien === false && !this.state.hiddenLuu) ? 'Chưa Thanh Toán' : 'Đã thanh toán'}</strong></div>
                            <div style={{ display: 'flex', marginBottom: '8px' }}>
                                <div style={{ width: 550, textAlign: 'right', paddingRight: '10px' }}>Tổng tiền: </div>
                                <div style={{}}><strong>{tongtien.toLocaleString('en-US')}</strong></div>
                            </div>
                            <div style={{ display: 'flex', marginBottom: '8px' }}>
                                <div style={{ width: 550, textAlign: 'right', paddingRight: '10px' }}>Tiền được giảm: </div>
                                <div style={{}}><strong>{Number(tongtien - (this.state.tongthanhtoan * ((100 - this.state.mucgiamgia) / 100)).toFixed(0)).toLocaleString('en-US')}</strong></div>
                            </div>
                            <div style={{ display: 'flex', marginBottom: '8px' }}>
                                <div style={{ width: 550, textAlign: 'right', paddingRight: '10px' }}>Số tiền thanh toán: </div>
                                <div style={{}}><strong>{Number((this.state.tongthanhtoan * ((100 - this.state.mucgiamgia) / 100)).toFixed(0)).toLocaleString('en-US')}</strong></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 20 }}>
                                <button className="btn btn-success" key="Lưu" style={{ marginRight: 10 }} disabled={this.state.hiddenLuu}
                                    onClick={() => { this.save() }}>   <i style={{ marginRight: 5 }} className="fa fa-save" />Xác nhận thanh toán</button>
                                <button className="btn btn-primary" key="In" style={{ marginRight: 10 }} disabled={!this.state.hiddenLuu}
                                    onClick={() => { this.printAfterSave() }}> <i style={{ marginRight: 5 }} className="fa fa-print" /> In</button>
                                <button className="btn btn-danger" key="Thoát" style={{ marginRight: 10 }} onClick={this.hideModal}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng  </button>
                            </div>
                        </div>
                        :
                        <div style={{ marginTop: "20px" }}>
                            <Table
                                className="components-table-demo-nested"
                                columns={columnVP}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <div style={{ padding: 10 }}>
                                            <Table columns={columnVPDetail} dataSource={record.lstSub} pagination={false} rowKey={(row, index) => index} />
                                        </div>
                                    )
                                }}
                                dataSource={this.state.lstResult}
                                rowKey={(row, index) => index}
                                pagination={false}
                            />

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>

                                <Button className="btn btn-danger" style={{ marginLeft: 5 }}
                                    onClick={() => {
                                        this.setState({ visibleInvoice: false, hiddenLuu: false, mucgiamgia: 0, hinhthucthanhtoan: 1 })
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng</Button>
                            </div>
                        </div>

                    }
                </Modal>
                <div >
                    <div className='ccCenter'>
                        <h4 style={{ textTransform: "uppercase" }}>   DANH SÁCH CHỜ ĐÓNG VIỆN PHÍ</h4>
                    </div>

                    <div className="form-group" style={{ margin: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <Form.Item>
                            <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngayRange}
                                locale={viVN} onChange={(value => this.setState({ ngayRange: value, tungay: value[0], denngay: value[1] }, () => this.search()))}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input style={{ width: '200px' }} placeholder="Nhập tên hoặc mã bệnh nhân"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.search();
                                    }
                                }}
                                onChange={(e) => this.setState({ key: e.target.value })} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={this.state.paid} onChange={() => { this.setState({ paid: !this.state.paid }, () => this.search()) }}>Đã đóng</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-success" onClick={() => { this.search() }}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" /> Tìm
                                </button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-primary" onClick={() => { this.ExportDanhSachChoTheoNgay() }}>
                                <i className="fas fa-download" style={{ marginRight: 5 }}></i>Xuất Excel</button>
                        </Form.Item>
                    </div>
                </div>
                <div className="col-12 text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                </div>
                <InvoiceLst

                    ref={((c) => this.patient = c)}
                    column={columns}
                    dataSource={this.state.lstInvoice}
                    totalRecords={this.state.totalRecords}
                    ClickRow={this.ClickRow.bind(this)}
                />
            </React.Fragment>
        )
    }
}