import * as ApiCaller from '../libs/httpRequests';
const prefix = 'ExamResult';

export function SendResult(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/send-testresult`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function sendFileToWebPatient(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/sendfiletowebpatient`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function recallFileToWebPatient(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/recallfiletowebpatient`, id).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteExamResultFile(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteExamResultFile/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListExamResultTiepDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListExamResultTiepDon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListExamResultTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListExamResultTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getExamResultInfo(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetExamResultInfo`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getExamResultInfoBottom(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetExamResultInfoBottom`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListExamResultStatus() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListExamResultStatus`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function examResultUpdate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ExamResultUpdate`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ListExamResultByManagementId(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListExamResultByManagementId/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};