import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { DatePicker, Input, Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import SiteFilter from '../../../configureHIS/common/siteFilter';
import { FORMAT_DATE_TIME, FORMAT_PREFIX_EXCEL } from '../../../configure/systemConfig';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import CoporationReport from '../../../components/coporation-report';
import moment from 'moment';
import * as ReportServices from '../../../services/ReportService';
import { openMessageDialog, setLoading } from '../../../libs/actions';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';

const CoporationDiscountStatistic = forwardRef((props, ref) => {
    const _notify = useRef();
    const _coporationSelect = useRef();
    const _hiddenLink = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [date, setDate] = useState([moment(moment().format('YYYY-MM-DD 00:00')), moment()]);
    const [siteIds, setSiteIds] = useState([]);
    const [keyword, setKeyword] = useState();

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        onSearch();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Số phiếu',
            dataIndex: 'billNo',
            key: 'billNo',
            align: 'center',
            width: 170
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'maBN',
            key: 'maBN',
            align: 'center',
            width: 130
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 250
        },
        {
            title: 'Ngày TT',
            dataIndex: 'billDate',
            key: 'billDate',
            align: 'right',
            render: (val) => moment(val).format(FORMAT_DATE_TIME),
            width: 140
        },
        {
            title: 'Mã DV',
            dataIndex: 'vienPhiMa',
            key: 'vienPhiMa',
            align: 'right',
            width: 100
        },
        {
            title: 'Dịch vụ',
            dataIndex: 'vienPhiTen',
            key: 'vienPhiTen',
            align: 'left',
            width: 250
        },
        {
            title: 'Loại DV',
            dataIndex: 'vienPhiLoai',
            key: 'vienPhiLoai',
            align: 'left',
            width: 120
        },
        {
            title: 'Số lượng',
            dataIndex: 'soLuong',
            key: 'soLuong',
            align: 'center',
            render: (val) => val?.toLocaleString('en-US'),
            width: 90
        },
        {
            title: 'Đơn giá',
            dataIndex: 'donGiaGoc',
            key: 'donGiaGoc',
            align: 'right',
            render: (val) => val?.toLocaleString('en-US'),
            width: 100
        },
        {
            title: 'Thành tiền',
            dataIndex: 'thucThu',
            key: 'thucThu',
            align: 'right',
            render: (val) => val?.toLocaleString('en-US'),
            width: 100
        },
        {
            title: 'Mức chiết khấu',
            dataIndex: 'coporationDiscountPercent',
            key: 'coporationDiscountPercent',
            align: 'right',
            width: 120,
            render: (val, record) => {
                return record.discountType == "percent" ? (val && val + "%") : record.coporationDiscountAmount?.toLocaleString('en-US');
            }
        },
        {
            title: 'Số tiền chiết khấu',
            dataIndex: 'coporationDiscountAmount',
            key: 'coporationDiscountAmount',
            align: 'right',
            render: (val) => val?.toLocaleString('en-US'),
            width: 140
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'coporationName',
            key: 'coporationName',
            align: 'left',
            width: 260,
            render: (val) => {
                return <MitaDisplayLongText
                    content={val}
                    maxLength={60}
                    showMoreText="...xem thêm"
                    showLessText=" rút gọn"
                />
            }
        }
    ];

    const onSearch = (p = page, s = size, key = keyword) => {
        setLoading(true);
        let filterModel = {
            siteIds: siteIds ?? [],
            coporationIds: (_coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId()) ?? [],
            dateFrom: date[0],
            dateTo: date[1],
            skipCount: (p - 1) * s,
            maxResultCount: s,
            keyword: key
        }
        ReportServices.ReportCoporationDiscount(filterModel).then(result => {
            if (result.isSuccess) {
                setListData(result.data.items);
                setTotalRecords(result.data.totalCount);
                _notify && _notify.current.Close();
            }
            else {
                _notify.current && _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notify.current && _notify.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onExportExcel = () => {
        setLoading(true);
        let filterModel = {
            siteIds: siteIds ?? [],
            coporationIds: (_coporationSelect && _coporationSelect.current && _coporationSelect.current.getListCoporationId()) ?? [],
            dateFrom: date[0],
            dateTo: date[1],
        }
        ReportServices.ExportReportCoporationDiscount(filterModel).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BCChietKhauNguonKhachHang`;
                _hiddenLink.current.download = fileName;
                _hiddenLink.current.click();
            })
        }).catch(err => {
            openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Chiết khấu nguồn khách hàng" />
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <div className="list-page-body overflow-auto">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className="row">
                        <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
                    </div>
                    <div className="row mt-2">
                        <div className='col-md-4 d-flex flex-column'>
                            <CoporationReport ref={_coporationSelect} modeMultiple={true} allowClear={true} />
                        </div>
                        <div className='col-md-4 d-flex flex-column'>
                            <label>Khoảng thời gian</label>
                            <DatePicker.RangePicker
                                allowClear={false}
                                locale={viVN}
                                showNow={false}
                                showTime={{ format: 'HH:mm' }}
                                className="w-100"
                                value={date}
                                format={FORMAT_DATE_TIME}
                                onChange={(e) => { setDate(e) }}
                            />
                        </div>
                        <div className='col-md-4 d-flex flex-column'>
                            <label>Từ khóa</label>
                            <Input className='w-100'
                                placeholder='Tìm theo số phiếu, mã khách hàng, tên khách hàng, dịch vụ'
                                prefix={<i className="fa fa-search mr-1" />}
                                value={keyword} onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter")
                                        onSearch(1, size, e.target.value);
                                }}
                            ></Input>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-md-12 d-flex justify-content-end'>
                            <button
                                className={`${BUTTON.THEME}`}
                                onClick={() => {
                                    onSearch(1);
                                }}
                            ><i className='far fa-chart-bar mr-2' />Thống kê
                            </button>
                            <button className={`${BUTTON.THEME} ml-2`}
                                onClick={() => { onExportExcel(); }}
                            ><i className="fas fa-file-excel mr-1" />Xuất excel
                            </button>
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowClassName="rowCustom"
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size); onSearch(page, size); }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size); onSearch(1, size); }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
        </div >
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationDiscountStatistic)