import { DatePicker, notification, Form, Pagination } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as bhytService from '../../../services/bhytService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../configure/systemConfig';
const { RangePicker } = DatePicker;
function ShowData(props) {
    const _hiddenLink = useRef();
    const [dataSource, setData] = useState(props.dataSource);
    const [totalRecord, setTotalRecord] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSize, setCurrentSize] = useState(10);
    const CreateXMLByPatientId = (maql, ngay) => {
        Actions.setLoading(true);
        bhytService.CreateXMLByPatientId(maql, ngay)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "XML1_" + maql;
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    const CreateXMLPatientMedic = (maql, ngay) => {
        Actions.setLoading(true);
        bhytService.CreateXMLPatientMedic(maql, ngay)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "XML2_" + maql;
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    const CreateXMLPatientService = (maql, ngay) => {
        Actions.setLoading(true);
        bhytService.CreateXMLPatientService(maql, ngay)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "XML3_" + maql;
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    useEffect(() => {
        setData(props.dataSource)
        setTotalRecord(props.total)
    });
    const CheckCardDate = (date) => {
        if (moment(date).year() === 1) {
            return "";
        }
        else {
            return moment(date).format(SystemConfig.FORMAT_DATE);
        }
    }
    return (
        <div className="padding-table">
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <div className="table-responsive" style={{ backgroundColor: '#fff' }}>
                <table className="table table-striped table-inverse table-hover">
                    <thead>
                        <tr>
                            <th style={{ minWidth: '100px' }}>Mã BN</th>
                            <th style={{ minWidth: '150px' }}>Tên BN</th>
                            <th style={{ minWidth: '130px' }}>Ngày vào</th>
                            <th style={{ minWidth: '150px' }}>Số thẻ</th>
                            <th style={{ minWidth: '180px' }}>Hạn thẻ</th>
                            <th style={{ minWidth: '150px' }}>Địa chỉ</th>
                            <th style={{ minWidth: '120px' }}>Mã QL</th>
                            <th style={{ minWidth: '300px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataSource.map((row, index) =>
                        (<tr key={index}>
                            <td>{row.code}</td>
                            <td>{row.name}</td>
                            <td>{moment(row.dateInHospital).format(SystemConfig.FORMAT_DATE_TIME)}</td>
                            <td>{row.cardNumber}</td>
                            <td>{CheckCardDate(row.cardFrom)} - {CheckCardDate(row.cardTo)}</td>
                            <td>{row.address}</td>
                            <td>{row.managerId}</td>
                            <td className="d-flex">
                                <button type="button" className="btn btn-info mr-1"
                                    onClick={() => CreateXMLByPatientId(row.managerId, moment(row.dateInHospital).format("YYYY/MM/DD HH:mm"))}>
                                    <i className="fa fa-download"></i> XML 1
                                </button>
                                <button type="button" className="btn btn-info  mr-1"
                                    onClick={() => CreateXMLPatientMedic(row.managerId, moment(row.dateInHospital).format("YYYY/MM/DD HH:mm"))}>
                                    <i className="fa fa-download"></i> XML 2
                                </button>
                                <button type="button" className="btn btn-info"
                                    onClick={() => CreateXMLPatientService(row.managerId, moment(row.dateInHospital).format("YYYY/MM/DD HH:mm"))}>
                                    <i className="fa fa-download"></i> XML 3
                                </button>
                            </td>
                        </tr>)
                        )}
                    </tbody>
                </table>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={currentSize}
                    current={currentPage}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setCurrentSize(size)
                        if (props.reload)
                            props.reload(page, size);
                        if (props.setPage)
                            props.setPage(page);
                        if (props.setSize)
                            props.setSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        if (props.reload)
                            props.reload(page, size);
                        if (props.setPage)
                            props.setPage(page);
                        if (props.setSize)
                            props.setSize(size);
                    }
                    }
                    showSizeChanger={true}
                />
            </div>
        </div>
    )
}
function Patient(props) {
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [keySearch, setKeySearch] = useState("");
    const [dataSource, setData] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const GetListXMLPatientInfoBHYT = (newPage = 1, newSize = 10) => {
        Actions.setLoading(true);
        bhytService.GetListXMLPatientInfoBHYT(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), keySearch, newPage, newSize).then(result => {
            if (result.isSuccess) {
                setData(result.data)
                setTotalRecord(result.totalRecords)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    useEffect(() => {
        GetListXMLPatientInfoBHYT(page, size);
    }, [ngay]);
    return (
        <React.Fragment>
            <div className='h4 text-center mt-5 mb-3 font-bold text-uppercase'>Tạo file XML chỉ tiêu thanh toán BHYT</div>
            <div className="d-flex justify-content-center my-2">
                <Form.Item>
                    <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                        allowClear={false} format="DD-MM-YYYY" value={ngay}
                        style={{ width: '200px' }}
                        placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                        onChange={(e) => { setNgay(e) }}
                    />
                </Form.Item>
                <Form.Item>
                    <input type="text" className="form-control" style={{ width: '250px' }} value={keySearch}
                        placeholder="Nhập mã, tên, số thẻ bệnh nhân"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                GetListXMLPatientInfoBHYT(page, size);
                            }
                        }}
                        onChange={(e) => setKeySearch(e.target.value)} />
                </Form.Item>
                <Form.Item>
                    <button type="button" className="btn btn-success" onClick={() => GetListXMLPatientInfoBHYT(page, size)}><i className="fa fa-search"></i> Tìm</button>
                </Form.Item>
            </div>
            <ShowData dataSource={dataSource} total={totalRecord}
                setSize={setSize.bind(this)}
                setPage={setPage.bind(this)}
                reload={GetListXMLPatientInfoBHYT.bind(this)} />
        </React.Fragment>
    )
}
export default Patient;