import { Button, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import TextArea from 'antd/lib/input/TextArea';
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import HeaderModal from '../../configureHIS/common/headerModal';
import { isNullOrEmpty } from '../../libs/util';
import * as AuthServices from '../../services/authServices';
import * as KhoaPhongServices from '../../configureHIS/departmentConfig/services/PhongKhamService';
import * as PhongKhamService from '../../services/PhongKhamService';
import * as QMSService from '../../services/QMSService';

const { Option } = Select;
const MAX_SCREEN_TO_SHOW = 4;

function CreateUpdate(props) {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [listSite, setListSite] = useState([]);
    const [listDepartment, setListDepartment] = useState([]);
    const [listRoom, setListRoom] = useState([]);
    const [isUpdate, setIsUpdate] = useState(props.isUpdate ?? false);
    const [id, setId] = useState(props.data && props.data.id ? props.data.id : 0);
    const [tieuDe, setTieuDe] = useState(props.data && props.data.title ? props.data.title : "");
    const [dienGiai, setDienGiai] = useState(props.data && props.data.info ? props.data.info : "");
    const [listConfig, setListConfig] = useState(
        props.data && props.data.params ? props.data.params : [{
            site: null,
            department: null,
            room: null,
            limit: null,
        }]
    );
    const notify = useRef();
    const onClose = () => {
        props.onClose && props.onClose();
        resetState();
        notify && notify.current && notify.current.Close();
    }
    const resetState = () => {
        setId(0);
        setTieuDe(null);
        setDienGiai(null);
        setListConfig([
            {
                site: null,
                department: null,
                room: null,
                limit: null,
            },
        ]);
        setIsUpdate(false);
    }
    const onSaveOrUpdate = () => {
        let errorMsg = "";
        listConfig.forEach((cof, index) => {
            if (isNullOrEmpty(cof.site)) {
                errorMsg = `Chưa chọn chi nhánh của đối số ${index + 1}`;
                return;
            }
            if (isNullOrEmpty(cof.department)) {
                errorMsg = `Chưa chọn khoa của đối số ${index + 1}`;
                return;
            }
            if (isNullOrEmpty(cof.room)) {
                errorMsg = `Chưa chọn phòng của đối số ${index + 1}`;
                return;
            }
            if (isNullOrEmpty(cof.limit)) {
                errorMsg = `Chưa chọn giới hạn của đối số ${index + 1}`;
                return;
            }
        });
        if (isNullOrEmpty(tieuDe)) errorMsg = "Chưa nhập tiêu đề";
        if (listConfig && listConfig.length <= 0) errorMsg = "Cần ít nhất 1 đối số";
        if (duplicated(listConfig)) errorMsg = "Dữ liệu trùng nhau";
        if (!isNullOrEmpty(errorMsg)) {
            notify.current && notify.current.Show(errorMsg, NotifyStatus.Error);
            return;
        }
        notify && notify.current && notify.current.Close();
        const data = {
            id: id,
            title: tieuDe,
            info: dienGiai,
            params: listConfig
        }
        QMSService.CreateUpdateQMSConfig(data).then(result => {
            if (result.isSuccess) {
                notify && notify.current && notify.current.Show(`${id > 0 ? "Cập nhật" : "Tạo"} thành công`, NotifyStatus.Success);
                if (id == 0) resetState();
                setTimeout(() => {
                    props.onClose && props.onClose();
                }, 1000);
            } else {
                if (result.err.msgString) notify.current.Show(result.err.msgString, NotifyStatus.Error);
                else notify.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
            }
        }).catch(() => {
            notify.current.Show("Đã xảy ra lỗi, vui lòng thử lại sau", NotifyStatus.Error);
        })
    }
    const addNewParam = () => {
        if (listConfig && listConfig.length === MAX_SCREEN_TO_SHOW) {
            notify && notify.current.Show(`Tối đa ${MAX_SCREEN_TO_SHOW}`, NotifyStatus.Warning);
        } else {
            let newItem = {
                site: null,
                department: null,
                room: null,
                limit: null,
            };
            listConfig.push(newItem);
            forceUpdate();
        }
    }
    const removeParam = (index) => {
        let newListConfig = listConfig.filter((x, ind) => ind !== index);
        setListConfig(newListConfig);
    }
    useEffect(() => {
        getListSite();
        getListDepartment();
        getListRoom();
    }, []);
    const getListSite = () => {
        AuthServices.getAvailableSites().then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
                notify && notify.current.Close();
            }
            else {
                notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notify.current.Show(err.error.messageText, NotifyStatus.Error);
            else notify.current.Show(err, NotifyStatus.Error);
        })
    }
    const getListDepartment = () => {
        KhoaPhongServices.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                setListDepartment(result.data);
            }
        })
    }
    const getListRoom = () => {
        let key = "";
        const page = 1;
        const size = 300;
        PhongKhamService.GetDanhSachPKham(key, page, size).then(result => {
            if (result.isSuccess) {
                setListRoom(result.data);
            }
        }).catch(err => {
        })
    }
    const updateParamValue = (filed, index, value) => {
        let listConf = listConfig;
        if (filed === "site") {
            listConf[index].site = value;
        }
        if (filed === "department") {
            listConf[index].department = value;
        }
        if (filed === "room") {
            listConf[index].room = value;
        }
        if (filed === "limit") {
            listConf[index].limit = value;
        }
        setListConfig(listConf);
        forceUpdate();
    }
    const duplicated = (arr) => {
        let newArr = [];
        arr.map(x => {
            newArr.push(JSON.stringify([x.site, x.department, x.room]));
        });
        const result = newArr.some(element => {
            if (newArr.indexOf(element) !== newArr.lastIndexOf(element)) {
                return true;
            }
            return false;
        });
        return result;
    }
    return (
        <Fragment>
            <HeaderModal title={`${isUpdate ? "Cập nhật" : "Tạo mới"} - Hàng đợi`} onClose={() => { onClose(); }} />
            <Notify ref={notify} />
            <div className="mt-2">
                <label>Tiêu đề</label><label className='required-field'>*</label>
                <Input value={tieuDe} onChange={(e) => setTieuDe(e.target.value)} />
            </div>
            <div className="mt-2 d-flex flex-column justify-content-start">
                <label className='required-field'>Thông tin đối số</label>
                <Button
                    type="link"
                    onClick={() => {
                        addNewParam();
                    }}
                    style={{ width: "fit-content", display: "flex", alignItems: "center", padding: 0 }}
                ><i className="fa fa-plus-circle mr-1" style={{ fontSize: 20 }} />Thêm mới đối số</Button>
            </div>
            {listConfig && listConfig.length > 0 &&
                <table className='w-100'>
                    <thead>
                        <th style={{ width: "10%", textAlign: "center" }}>#</th>
                        <th style={{ width: "30%" }}>Đối số</th>
                        <th style={{ width: "50%" }}>Giá trị</th>
                        <th style={{ width: "10%" }}></th>
                    </thead>
                    <tbody>
                        {listConfig && listConfig.map((cof, index) => {
                            return (
                                <Fragment>
                                    <tr style={{ backgroundColor: `${index % 2 == 0 ? '#dddddd' : "#cccccc"}`, borderTop: "18px solid transparent" }}>
                                        <td style={{ textAlign: "center" }} rowSpan={4}>{index + 1}</td>
                                        <td><label className='required-field'>Chi nhánh</label></td>
                                        <td>
                                            <Select className='w-100' value={cof.site}
                                                style={{ maxWidth: 270 }}
                                                onChange={(e) => {
                                                    updateParamValue("site", index, e);
                                                }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {listSite && listSite.map((site, index) => {
                                                    return (
                                                        <Option key={`site-${index}-${site.id}`} value={site.id}>{site.ten}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </td>
                                        <td className='w-100 text-center' rowSpan={4} onClick={() => { removeParam(index); }}>
                                            <i className="far fa-trash-alt" style={{ fontSize: 20 }} />
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: `${index % 2 == 0 ? '#dddddd' : "#cccccc"}` }}>
                                        <td><label className='required-field'>Khoa</label></td>
                                        <td>
                                            <Select className='w-100' value={cof.department}
                                                style={{ maxWidth: 270 }}
                                                onChange={(e) => {
                                                    updateParamValue("department", index, e);
                                                    updateParamValue("room", index, null);
                                                }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {listDepartment && listDepartment.map((dep, index) => {
                                                    return (
                                                        <Option key={`depa-${index}-${dep.makp}`} value={dep.makp}>{dep.tenkp}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: `${index % 2 == 0 ? '#dddddd' : "#cccccc"}` }}>
                                        <td><label className='required-field'>Phòng</label></td>
                                        <td>
                                            <Select className='w-100' value={cof.room}
                                                style={{ maxWidth: 270 }}
                                                onChange={(e) => {
                                                    updateParamValue("room", index, e);
                                                }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {listRoom && listRoom.filter(room => room.makhoa == cof.department).map((room, index) => {
                                                    return (
                                                        <Option key={`room-${index}-${room.id}-${room.makp}`} value={room.id}>{room.tenkp}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: `${index % 2 == 0 ? '#dddddd' : "#cccccc"}`, borderBottom: "18px solid transparent" }}>
                                        <td><label className='required-field'>Giới hạn</label></td>
                                        <td>
                                            <Select className='w-100' value={cof.limit}
                                                style={{ maxWidth: 270 }}
                                                onChange={(e) => {
                                                    updateParamValue("limit", index, e);
                                                }}>
                                                {[5, 10, 20].map((lim, index) => {
                                                    return (
                                                        <Option key={index} value={lim}>{lim}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                    </tbody>
                </table>}
            <div className="mt-2">
                <label>Diễn giải</label>
                <TextArea value={dienGiai} rows={7} onChange={(e) => { setDienGiai(e.target.value) }} />
            </div>
            <div className='mt-2' style={{ position: "fixed", right: 15, bottom: 15 }}>
                <Button className='btn btn-color' onClick={() => { onSaveOrUpdate(); }}><i className="fa fa-save mr-2" />Lưu</Button>
                <Button className='btn btn-danger ml-2' onClick={() => onClose()}><i className="fas fa-times-circle mr-2" />Đóng</Button>
            </div>
        </Fragment>
    );
}

export default CreateUpdate;