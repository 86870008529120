import { Button, Collapse, DatePicker, Form, notification, Select, Table, Pagination } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../libs/actions';
import * as StatisticService from '../../services/statisticService';
import SimpleReactValidator from 'simple-react-validator';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as KhoService from '../../services/KhoService';
import { connect } from 'react-redux';
import HeaderModal from '../../configureHIS/common/headerModal';
import UnSupport from '../unknowPage/unSupport';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
class TheoDoiBanThuoc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            listSite: [],
            chinhanh: 1,
            isMultisite: false,
            makho: 502,
            listKho: [],
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
        };
    }
    componentWillMount() {
        if (this.props.medicineMarket) {
            // UsersService.CheckPremissionMultisite().then(result => {
            //     if (result.isSuccess) {
            //         this.setState({
            //             isMultisite: result.item
            //         });
            //     }
            // });
            // this.GetListSite();
            this.loadKho();
            this.LoadTheoDoiBanThuoc();
        }
    }
    loadKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    listKho: result.data,
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi!' })
        })
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadTheoDoiBanThuoc(page = this.state.currentPage, size = this.state.pageSize) {
        Actions.setLoading(true);
        StatisticService.ThongKeTheoDoiBanThuoc(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.makho, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                    totalRecords: result.totalRecords,
                });
            }
            else {
                this.setState({
                    dataSource: [],
                    totalRecords: 0,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeTheoDoiBanThuoc() {
        Actions.setLoading(true);
        StatisticService.ExportTheoDoiBanThuoc(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh, this.state.makho)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeTheoDoiBanThuoc";
                    this._hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    render() {
        const columnChild = [
            {
                title: 'Ngày bán',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'center',
                width: 120,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 80,
                render: (value) => {
                    return <span style={{ textAlign: 'center' }}>{value}</span>;
                }
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                width: 200,
                align: 'left',
                render: (value) => {
                    return <span style={{ textAlign: 'center' }}>{value}</span>;
                }
            },
            {
                title: 'Hãng',
                dataIndex: 'tenhang',
                key: 'tenhang',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span style={{ textAlign: 'center' }}>{value}</span>;
                }
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span style={{ textAlign: 'center' }}>{value}</span>;
                }
            },
            {
                title: 'Loại',
                dataIndex: 'tenloai',
                key: 'tenloai',
                align: 'left',
                width: 120,
                render: (value) => {
                    return <span style={{ textAlign: 'center' }}>{value}</span>;
                }
            },
            {
                title: 'SL',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 70,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Tiền chênh lệch',
                dataIndex: 'chenhlech',
                key: 'chenhlech',
                align: 'right',
                width: 150,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Người tạo',
                dataIndex: 'nguoitao',
                key: 'nguoitao',
                align: 'left',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Lý do thay đổi giá',
                dataIndex: 'ghichu',
                key: 'ghichu',
                align: 'left',
                width: 150,
            },
        ]
        return (
            !this.props.medicineMarket ?
                <UnSupport title="Chức năng bán thuốc không hoạt động" />
            :
            <>
                <Form ref={(c) => this.form = c} >
                    <div className='containerChilderWrapper'>
                        <a href="#download" style={{ visibility: "hidden", display: "none" }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <HeaderModal title="Thống kê chênh lệch giá bán thuốc" />
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* {this.state.isMultisite === true && */}
                            {/* <div className="col-md-3 mt-2">
                                <Form.Item>
                                    <Select
                                        style={{ width: '200px' }}
                                        showSearch
                                        allowClear={false}
                                        value={this.state.chinhanh}
                                        onChange={(e) => {
                                            this.setState({ chinhanh: e },()=>{
                                                this.LoadTheoDoiBanThuoc();
                                            })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.listSite.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ma}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div> */}
                            {/* } */}
                            <div className="col-md-3 mt-2">
                                <Form.Item>
                                    <div style={{ display: 'flex' }}>
                                        <RangePicker allowClear={false} disabledDate={this.disabledDate}
                                            format="DD-MM-YYYY" value={this.state.ngay}
                                            locale={viVN} onChange={(value => this.setState({ ngay: value, currentPage: 1 }, () => this.LoadTheoDoiBanThuoc()))}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2">

                                <Form.Item>
                                    <div style={{ display: 'flex' }}>
                                        <label style={{ marginRight: 5, width: 80, marginTop: 5 }}>Kho:</label>
                                        <Select
                                            value={this.state.makho}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e) => {
                                                this.setState({ makho: e, currentPage: 1 }, () => {
                                                    this.LoadTheoDoiBanThuoc();
                                                })
                                            }}
                                        >
                                            {this.state.listKho.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2" style={{ display: 'flex', alignSelf: 'flex-end' }}>
                                <Form.Item>
                                <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => { this.setState({ currentPage: 1 }, () => this.LoadTheoDoiBanThuoc())}}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                </Form.Item>
                                <Form.Item>
                                    <button className="btn btn-color" onClick={() => { this.ExportThongKeTheoDoiBanThuoc() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 160px)', marginTop: 10 }}>
                            <Table
                                pagination={false}
                                scroll={{ x: 'max-content', y: 0 }}
                                dataSource={this.state.dataSource}
                                columns={columnChild} rowKey={(row, index) => index}
                                />
                        </div>
                        <Pagination
                            position={['bottomCenter']}
                            showSizeChanger={true}
                            style={{ textAlignLast: "center", marginTop: 15 }}
                            total={this.state.totalRecords}
                            showTotal={(total) => (`Tổng: ${total}`)}
                            onChange={(currentPage, pageSize) => {
                                this.setState({
                                    currentPage,
                                    pageSize
                                }, () => this.LoadTheoDoiBanThuoc())
                            }}
                            onShowSizeChange={(page, pageSize) => {
                                this.setState({
                                    currentPage: 1,
                                    pageSize
                                }, () => this.LoadTheoDoiBanThuoc())
                            }}
                        />
                    </div>
                </Form>
            </ >
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        medicineMarket: state.global.sysConfig.medicineMarket ? state.global.sysConfig.medicineMarket : false,
    })
};
export default connect(
    mapStateToProps, null, null, { forwardRef: true }
)(TheoDoiBanThuoc);
