import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { EditFilled } from '@ant-design/icons';
import { Button, Table, notification, Checkbox , Popconfirm, Tooltip, Dropdown, Menu, Select, Switch, Drawer} from 'antd';
import * as Actions from '../../../libs/actions';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';
// import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import * as ThirdPartyConfigService from '../../../services/thirdPartyConfigService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import Detail from './detail';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const firstRender = useRef(true); 
  const detailRef = useRef();
  const deletePopupRef = useRef([]);
  const isDefaultPopupRef = useRef([]);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [showMoreText, setShowMoreText] = useState("...Xem thêm");
  const [showLessText, setShowLessText] = useState("\nRút gọn");
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [selectedId, setSelectedId] = useState();
  const [loadingIsDefault, setLoadingIsDefault] = useState(false);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [pageIndex, sorting])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    search();
  }, []);
  //#endregion 
  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.zaloGetList().then(result => {
      if (result.isSuccess) {
        setListResult(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = (isResetSelectedRow = false) => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    else setSelectedId(null);
    setDetailScreenMode(detailScrMode);
    setVisibleDetail(true);
  }

  const onDeleteClickHandle = (id) => {
    Actions.setLoading(true);
    ThirdPartyConfigService.zaloDeleteById(id).then(result => {
      if (result.isSuccess) {
        search();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };

  const onSetIsDefaultClickHandle = (id) => {
    Actions.setLoading(true);
    setLoadingIsDefault(true);
    let list = [...listResult];
    let item = list.find(x => x.id === id);
    ThirdPartyConfigService.onOffDefaultById(id).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
        search();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        setListResult(list);
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
      setListResult(list);
    }).finally(() => {
      Actions.setLoading(false);
      setLoadingIsDefault(false);
    })
  };

  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) search();
  };

  const rowOptions = (record) => {
    return (
      <Menu
        items={[
          {
            label: 
              <a href="#" onClick={() => {
                loadDetail(record, detailScreenModeEnum.update);
              }}><EditFilled /><i className='fa fa-pencil mr-2'/> Chỉnh sửa</a>,
            key: '2',
          },
          {
            label: 
              <a href="#" onClick={() => { deletePopupRef.current[record.id].click() }}
              ><i className='fas fa-trash mr-2'/> Xóa</a>,
            key: '3',
          },
        ]}
      />
    )
  }

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 30,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      // Table.SELECTION_COLUMN,
      {
          title: 'OA ID',
          dataIndex: 'zaloOAID',
          key: 'zaloOAID',
          align: 'left',
          width: 200,
          render: (value, record) => {
              return (
                <>
                  <a href="#" style={{whiteSpace: "pre-line"}}
                    onClick={() => {
                      loadDetail(record, detailScreenModeEnum.detail);
                    }}
                  >{record.config.zaloOAID}</a>
                  <Popconfirm
                    title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{record.config.zaloOAID}</b>?</span>)}}
                    onConfirm={() => onDeleteClickHandle(record.id)}
                    onCancel={() => {}}
                    okText="Đồng ý"
                    cancelText="Hủy"
                  >
                    <a ref={e => deletePopupRef.current[record.id] = e}></a>
                  </Popconfirm>
                </>
              )      
          }
      },
      {
          title: '',
          align: 'left',
          width: 25,
          render: (value, record) => {
              return (
                <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                  <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                </Dropdown>
              )      
          }
      },
      {
          title: 'Api key',
          dataIndex: 'zaloApiKey',
          key: 'zaloApiKey',
          align: 'left',
          width: 250,
          render: (value, record, index) => {
            return <span>{ record.config.zaloApiKey }</span>;
          }
      },
      {
          title: 'Secret key',
          dataIndex: 'zaloSecretKey',
          key: 'zaloSecretKey',
          align: 'left',
          width: 250,
          render: (value, record, index) => {
              return <span>{ record.config.zaloSecretKey }</span>;
          }
      },
      {
          title: 'Mô tả',
          dataIndex: 'description',
          key: 'description',
          align: 'left',
          // width: 120,
          render: (value, record) => {
            return (
              <MitaDisplayLongText
                content={value}
                maxLength={30} // mặc định 64
                showMoreText={showMoreText}
                showLessText={showLessText}
                // displayMode="popup"
              />
            )
          }
      },
      {
          title: 'Mặc định',
          dataIndex: 'isDefault',
          key: 'isDefault',
          align: 'center',
          width: 85,
          render: (value, record) => {
            return (
              <>
                <Tooltip title={value ?  '' : 'Chỉ 1 tài khoản được bật mặc định. Nếu bạn bật mặc định cho tài khoản này thì các tài khoản khác sẽ bị tắt.'}>
                  <Switch 
                    checked={value} 
                    loading={loadingIsDefault} 
                    onChange={(checked, e) => { 
                      onSetIsDefaultClickHandle(record.id);
                    }}
                  />
                </Tooltip>
              </>
            )
          }
      },
  ];
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className='containerChilderWrapper list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách tài khoản Zalo</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  this.props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row mt">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                          onClick={() => {
                            loadDetail(null, detailScreenModeEnum.create);
                          }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                      <Button className="pl-0"
                        type="link"
                          onClick={() => {
                            search();
                          }}
                      ><i className="fas fa-sync-alt mr-1" />Refresh</Button>
                  </div>
              </div>
            </div>
            <div className="list-page-table">
              <Table style={{ height: "calc(100%)", width: "100%" }}
                className="table-min-h-0 table-header-2row"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.id}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                // onChange={onTableChange} 
              />
            </div>
          </div>
          {/* footer (nếu có)*/}
          {/* <div className="list-page-footer d-flex justify-content-between mt-2 d-none">
          </div> */}
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={1000}
        // getContainer={() => detailDrawRef.current}
        placement="right"
        onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <Detail ref={detailRef}
          isDialog={ true } 
          id={selectedId}
          detailScreenMode = {detailScreenMode}
          onCancel={(reload = false) => {handleCancel(reload)}}
          onUpdateClick={() => {loadDetail({id: selectedId}, detailScreenModeEnum.update)}}
          onIsDefaultClick={(id) => {onSetIsDefaultClickHandle(id);}}
        />
      </Drawer>
    </React.Fragment>
  );
})