import React, { useEffect, useRef, useState } from 'react';
import HeaderModal from '../../common/headerModal';
import { BUTTON } from '../../common/constants';
import NoContent from '../../../components/loading/noContent';
import { isnotnull } from '../../../libs/util';
import * as VPService from '../services/VPService';
import { setLoading } from '../../../libs/actions';
import Notify, { NotifyStatus } from '../../../components/notify';
import moment from 'moment';
import { FORMAT_TIME_DATE_FULL } from '../../../configure/systemConfig';

function DiscountConfigureLog(props) {
    const _notify = useRef();
    const [listData, setListData] = useState([]);

    useEffect(() => {
        getListLogDiscountModify();
    }, [props.data])

    const getListLogDiscountModify = () => {
        setLoading(true);
        const data = {
            "Quotaion": props.bangGia,
            "ServiceCode": props.data.ma,
            "ServiceId": props.data.id
        }
        VPService.GetListLogDiscountModify(data).then(res => {
            if (res.isSuccess) {
                setListData(res.data);
            } else {
                showError(res.err.messageText ?? "Đã xảy ra lỗi. Vui lòng thử lại sau.", NotifyStatus.Error);
            }
        }).catch(err => {
            showError(err.err.messageText ?? "Đã xảy ra lỗi.", NotifyStatus.Error);
        }).finally(() => setLoading(false))
    }

    const showError = (noti, type = NotifyStatus.Warning) => {
        _notify && _notify.current && _notify.current.Show(noti, type);
    }

    const renderItem = (record, index) => {
        return (
            <div className='d-flex flex-column mb-2' key={index}>
                <label className='border-bottom py-2'>Cập nhật bởi <b>{record.userCreated}</b> vào lúc <b>{moment(record.createdDate).format(FORMAT_TIME_DATE_FULL)}</b></label>
                <label className='ml-2 py-2'>{record.description}</label>
            </div>
        )
    }

    return (
        <div className='list-page'>
            <HeaderModal title="Lịch sử cập nhật" onClose={() => props.onClose()} />
            <Notify ref={_notify} />
            <div className='list-page-body'>
                {isnotnull(listData)
                    ?
                    <div className="list-page-table mt-2" style={{ overflowY: "auto" }}>
                        {
                            listData.map((x, index) => {
                                return renderItem(x, index);
                            })
                        }
                    </div>
                    :
                    <NoContent title="Không có lịch sử thay đổi thông tin" />
                }
                <div className='list-page-footer d-flex justify-content-end mt-2'>
                    <button className={`${BUTTON.DANGER} ml-2`} onClick={() => props.onClose()}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
                </div>
            </div>
        </div>
    );
}

export default DiscountConfigureLog;