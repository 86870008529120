import { Radio, Input, Divider, List, Form, notification, Select, DatePicker } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import * as KhaoSat from "../../../services/khaoSatService";
import * as RegisMediService from '../../../services/regisMediService';
import moment from 'moment';
import viVN from "antd/es/date-picker/locale/vi_VN";
import Administrative from "../components/administrative";

const { Option } = Select;

export default function Survey(props) {
    const [dataCH, setDataCH] = useState([]);
    const [patientID, setPatientID] = useState("");
    const [dataTest, setDataTest] = useState([]);
    const [ttKhac, setTtKhac] = useState({});
    const [detailPatient, setDetailPatient] = useState({});
    const [tieusubenh, setTieusubenh] = useState("");
    const [benhquantam, setBenhquantam] = useState("");
    const [tthonnhan, setTthonnhan] = useState("Không chia sẻ");
    const [chieucao, setChieucao] = useState(null);
    const [cannang, setCannang] = useState(null);
    const [madoituong,] = useState("2");
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const administrativeSec = useRef();
    const [notAllowedToDo, setNotAllowedToDo] = useState(false);
    const [dateCompleted, setDateCompleted] = useState(null);

    useEffect(() => {
        setDetailPatient(props.detailPatient);
        setPatientID(props.detailPatient.code ? props.detailPatient.code : "");
        GetListQuestion(props.detailPatient.code);
    }, [props.detailPatient]);

    const GetListQuestion = (mabn = patientID) => {
        KhaoSat.GetListQuestions(mabn).then((respone) => {
            if (respone.isSuccess) {
                setDataCH(respone.data);
                setNotAllowedToDo(false);
            } else {
                setDataCH([]);
                setNotAllowedToDo(true);
                setDateCompleted(respone.err.msgString.split(": ")[1]);
            }
        }).catch(() => { });
    }

    const resetStateSurvey = (visible = false) => {
        setTtKhac({});
        setCannang(null);
        setChieucao(null);
        setBenhquantam(null);
        setTieusubenh(null);
        setTthonnhan(null);
        setDataCH(!visible ? [] : GetListQuestion());
        setDataTest([]);
        setPatientID(null);
        administrativeSec && administrativeSec.current && administrativeSec.current.resetState();
        props.onCancel && props.onCancel(visible);
        setDateCompleted(null);
    }

    async function CreateSurvey() {
        if (dataCH && dataCH.length > 0) {
            const choice = dataCH.filter((value) => {
                return Object.keys(value).find((element) => element === "selectedValue");
            });
            choice.map((valueChoice, index) => {
                valueChoice.ndtl.split("\\n").map((answerChoice, indexAnswer) => {
                    if (indexAnswer === valueChoice.selectedValue) {
                        if (answerChoice.includes("<input>")) {
                            const a = Object.keys(valueChoice).filter(
                                (element) => element.indexOf("value") > -1
                            );
                            var resArr = answerChoice.split("<input>");
                            a.reverse().forEach(element => {
                                let valueSelectedInput = Number(element.slice(-1)) + 1;
                                resArr.splice(valueSelectedInput, 0, valueChoice[`${element}`])
                            });
                            var finalString = resArr.join("");
                            answerChoice = finalString;
                        }
                        dataTest.push({
                            mach: valueChoice.mach,
                            ndtl: answerChoice,
                        });
                    }
                });
            });
        }
        if (patientID === null || patientID === "") {
            let numberError = 0;
            await administrativeSec.current.getForm().validateFields().then((values) => {
                if (values.errorFields) numberError += values.errorFields.length;
            }).catch((ex) => {
                if (ex.errorFields) numberError += ex.errorFields.length;
            });

            if (administrativeSec.current.validator.allValid() && numberError === 0) {
                let dataAdministrative = administrativeSec.current.getState();
                const dataPatient = {
                    barCode: dataAdministrative.code ? dataAdministrative.code : "",
                    code: dataAdministrative.code ? dataAdministrative.code : "",
                    name: dataAdministrative.name.toUpperCase(),
                    dob: moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    age: dataAdministrative.age,
                    gender: dataAdministrative.gender,
                    people: "25",
                    cmnd: dataAdministrative.cmnd,
                    phone: dataAdministrative.phone,
                    email: dataAdministrative.email,
                    address: dataAdministrative.address,
                    city: dataAdministrative.city ? dataAdministrative.city.toString() : "",
                    district: dataAdministrative.district ? dataAdministrative.district.toString() : "",
                    ward: dataAdministrative.ward ? dataAdministrative.ward.toString() : "",
                    jobAddress: dataAdministrative.jobAddress,
                    village: dataAdministrative.village !== undefined ? dataAdministrative.village : "",
                    country: dataAdministrative.nation,
                    dateInHospital: moment().format("YYYY/MM/DD"),
                    timeInHospital: moment().format("HH:mm"),
                    subjectCode: madoituong,
                    coporator: dataAdministrative.coporator,
                    sendby: dataAdministrative.sendby,
                    onlyyear: dataAdministrative.onlyyear,
                };
                RegisMediService.CreatePatient(dataPatient).then((result) => {
                    if (result.isSuccess) {
                        const data = {
                            lsch: dataTest,
                            ttKhac: {
                                mabn: result.patientID,
                                chieucao: chieucao,
                                cannang: cannang,
                                tiensubenh: tieusubenh,
                                benhquantam: benhquantam,
                                tthonnhan: tthonnhan,
                            }
                        }
                        KhaoSat.CreateSurvey(data).then((respone) => {
                            if (respone.isSuccess) {
                                notification.success({ message: "Đã hoàn thành khảo sát" });
                                resetStateSurvey();
                            }
                        }).catch((err) => { });
                    }
                })
            } else {
                notification.error({ message: "Vui lòng kiểm tra lại dữ liệu!" });
                administrativeSec.current.validator.showMessages();
                forceUpdate();
            }
        } else {
            const data = {
                lsch: dataTest,
                ttKhac: ttKhac,
            };
            KhaoSat.CreateSurvey(data).then((respone) => {
                if (respone.isSuccess) {
                    notification.success({ message: "Đã hoàn thành khảo sát" });
                    resetStateSurvey();
                } else {
                    notification.warning({ message: respone.err.msgString })
                }
            }).catch((err) => { });
        }
    }

    return (
        notAllowedToDo ?
            <React.Fragment>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <h3>Thông báo</h3>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>Khách hàng <strong>{detailPatient.name}</strong> đã thực hiện khảo sát này{dateCompleted !== null ? ` vào ngày ${dateCompleted}.` : "!" }</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
                    <button className="btn btn-warning"
                        onClick={() =>  {
                            setDetailPatient({});
                            setPatientID("");
                            GetListQuestion("");
                            setNotAllowedToDo(false);
                        }}>Chọn khách hàng khác</button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                        onClick={() => {
                            resetStateSurvey();
                        }}><i style={{ marginRight: 5 }} className="fa fa-sign-out-alt" />Đóng
                    </button>
                </div>
            </React.Fragment>
            :
            <React.Fragment>
                <h3 style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 5,
                    textTransform: "uppercase"
                }}>
                    Phiếu khảo sát thông tin khách hàng
                    <span className="ml-auto"><button className="btn btn-warning" onClick={() => resetStateSurvey(true)}>Nhập mới</button></span>
                </h3>
                <div style={{ position: "relative" }}>
                    <Form
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        size="default"
                    >
                        <div className="warrperStyleGroup">
                            <div className="row">
                                {patientID !== null && patientID !== "" ?
                                    <React.Fragment>
                                        <div className="col-lg-4">
                                            <Form.Item label="Họ tên: " className="mt-1">
                                                <Input value={detailPatient.name} disabled={patientID !== null}/>
                                            </Form.Item>
                                            <Form.Item label="Số điện thoại: " className="mt-1">
                                                <Input
                                                    disabled={patientID !== null}
                                                    min={0}
                                                    maxLength={11}
                                                    value={detailPatient.phone}
                                                    placeholder="Nhập số điện thoại"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4">
                                            <Form.Item label="Giới tính: " className="mt-1">
                                                <Select
                                                    disabled={patientID !== null}
                                                    value={detailPatient.gender}
                                                    className="w-100"
                                                >
                                                    <Option value={0}>Nam</Option>
                                                    <Option value={1}>Nữ</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Email: " className="mt-1">
                                                <Input
                                                    disabled={patientID !== null}
                                                    value={detailPatient.email}
                                                    type="email"
                                                    placeholder="Nhập email"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4">
                                            <Form.Item label="Ngày sinh:" className="mt-1">
                                                <DatePicker
                                                    disabled={patientID !== null}
                                                    value={moment(detailPatient.dob)}
                                                    className="w-100"
                                                    format="DD/MM/YYYY"
                                                    allowClear={false}
                                                    locale={viVN}
                                                    disabledDate={(current) => {
                                                        return (
                                                            current && current >= moment().endOf("day")
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item label="CMND/CCCD: " className="mt-1">
                                                <Input
                                                    className="w-100"
                                                    value={detailPatient.cmnd}
                                                    disabled={patientID !== null}
                                                    min={0}
                                                    maxLength={20}
                                                    placeholder="Nhập căn cước công dân"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-12">
                                            <Form.Item label="Địa chỉ: " className="mt-1">
                                                <Input
                                                    value={detailPatient.address}
                                                    placeholder="Nhập địa chỉ bệnh nhân"
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <Administrative
                                        isnotRegister={true}
                                        isNotShowBarCode={true}
                                        newPatient={true}
                                        nguoigioithieu={false}
                                        showDepartment={false}
                                        ref={administrativeSec}
                                        onSeached={(patientID) => { GetListQuestion(patientID) }}
                                    />
                                }
                                <div className="col-lg-6 mt-2">
                                    <Form.Item label="Chiều cao:">
                                        <Input
                                            value={chieucao}
                                            placeholder="Nhập chiều cao"
                                            suffix="cm"
                                            onChange={(e) => {
                                                setChieucao(e.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === " " || isNaN(e.key) === true)
                                                    e.preventDefault();
                                            }}
                                        ></Input>
                                    </Form.Item>
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <Form.Item label="Cân nặng:">
                                        <Input
                                            value={cannang}
                                            suffix="Kg"
                                            placeholder="Nhập cân nặng"
                                            onChange={(e) => {
                                                setCannang(e.target.value);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === " " || isNaN(e.key) === true)
                                                    e.preventDefault();
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item label="Tiểu sử bệnh: " className="mt-2">
                                <Input
                                    value={tieusubenh}
                                    onChange={(e) => {
                                        setTieusubenh(e.target.value);
                                    }}
                                    placeholder="Nhập tiểu sử bệnh"
                                />
                            </Form.Item>
                            <Form.Item label="Các bệnh lý quan tâm: " className="mt-2">
                                <Input
                                    value={benhquantam}
                                    onChange={(e) => {
                                        setBenhquantam(e.target.value);
                                    }}
                                    placeholder="Nhập các bệnh lý đang quan tâm"
                                />
                            </Form.Item>
                            <Form.Item label="Tình trạng hôn nhân: " className="mt-2">
                                <Select
                                    className="w-100"
                                    value={tthonnhan}
                                    onChange={(e) => {
                                        setTthonnhan(e);
                                    }}
                                    placeholder="Nhập tình trạng hôn nhân"
                                >
                                    <Option value="Không chia sẻ">Không chia sẻ</Option>
                                    <Option value="Chưa kết hôn">Chưa kết hôn</Option>
                                    <Option value="Đã kết hôn">Đã kết hôn</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div>
                    <h3><Divider className="text-uppercase">{dataCH && dataCH.length} Câu hỏi khảo sát</Divider></h3>
                    <div style={{ padding: "0px 20px" }}>
                        <List
                            dataSource={dataCH}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {item.mach}. {item.ndch}
                                    <List.Item>
                                        <Radio.Group
                                            value={item.selectedValue}
                                            onChange={(e) => {
                                                item.selectedValue = e.target.value;
                                                setDataCH(dataCH);
                                            }}
                                        >
                                            {item.ndtl.split("\\n").map((value, index) => {
                                                return (
                                                    <Radio key={index}
                                                        value={index}
                                                        onClick={() => {
                                                            setTtKhac({
                                                                mabn: patientID,
                                                                chieucao: chieucao,
                                                                cannang: cannang,
                                                                tiensubenh: tieusubenh,
                                                                benhquantam: benhquantam,
                                                                tthonnhan: tthonnhan,
                                                            });
                                                        }} >
                                                        {value.search("<input>") === -1 ? value
                                                            : value.split("<input>").map((x, indexchild, arr) => {
                                                                return (
                                                                    <span>
                                                                        {x}
                                                                        {indexchild !== arr.length - 1 && (
                                                                            <Input
                                                                                style={{ width: 100 }}
                                                                                value={item["value" + indexchild]}
                                                                                onKeyPress={(e) => {
                                                                                    if (e.key === " " || isNaN(e.key) === true)
                                                                                        e.preventDefault();
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    item["value" + indexchild] = e.target.value;
                                                                                    if (e.target.value) {
                                                                                        if (item.selectedValue === index) {
                                                                                            let t = 0
                                                                                            value = value.replace(/<input>/g, match => ++t === indexchild ? e.target.value : match)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </span>
                                                                );
                                                            })}
                                                    </Radio>
                                                );
                                            })}
                                        </Radio.Group>
                                    </List.Item>
                                </List.Item>
                            )}
                        ></List>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
                    <button
                        className="btn btn-color"
                        onClick={() => {
                            CreateSurvey();
                        }}
                    ><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu
                    </button>
                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                        onClick={() => {
                            resetStateSurvey();
                        }}><i style={{ marginRight: 5 }} className="fa fa-sign-out-alt" />Đóng
                    </button>
                </div>
            </React.Fragment>
    )
}