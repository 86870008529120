import React, { useEffect, useRef, useState, useCallback } from "react";
import * as Actions from '../../../libs/actions';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Table, Menu, Dropdown, Input, Pagination, Modal, Spin, notification } from "antd";
import Notify, { NotifyStatus } from '../../../components/notify';
import * as PackageService from '../../../services/packageService';
import Create from './create';
import View from './view';

export default (props) => {
    const notiRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [keyword , setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [packageId, setPackageId] = useState('');

    useEffect(() => {
        GetListServicePackage();
    },[page, size])
    
    const rowOptions = (row) => {
        return (
            
        <Menu
            items={[
                {
                    label: 
                    <a href="#" onClick={() => {
                        setPackageId(row.id);
                        setVisibleView(true);
                    }}><i className="fas fa-eye mr-2" /> Xem chi tiết</a>,
                    key: '0',
                },
                {
                    label: 
                    <a href="#" onClick={() => {
                        setPackageId(row.id);
                        setIsUpdate(true);
                        setVisibleCreate(true);
                    }}><i className="fas fa-edit mr-2" /> Cập nhật</a>,
                    key: '1',
                },
                {
                    label: 
                    <a href="#" onClick={() => {
                        DeleteServicePackage(row.id);
                    }}><i className="fas fa-trash mr-2" /> Xóa</a>,
                    key: '2',
                },
            ]}
        />
        )
    }

    const GetListServicePackage = () => {
        setLoading(true);
        let filter = {
            Keyword : keyword,
            SkipCount : (page - 1)*size,
            MaxResultCount : size 
        }
        PackageService.ListServicePackage(filter).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data.items);
                setTotalRecords(result.data.totalCount);
            } else {
                Actions.openMessageDialog('Lỗi', result.error.messageText);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => {
            setLoading(false);
            forceUpdate();
        })
    }

    const DeleteServicePackage = (packageId) => {

        PackageService.DeleteServicePackage(packageId).then(result => {
            if (result.isSuccess) {
                GetListServicePackage();
                notification.success({ message: "Xóa gói thành công." })
            } else {
                Actions.openMessageDialog('Lỗi', result.error.messageText);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => {
            setLoading(false);
            forceUpdate();
        })
    }

    const onClose = () => {
        setPackageId('');
        setIsUpdate(false);
        setVisibleCreate(false); 
        setVisibleView(false);
        GetListServicePackage();
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 30,
            render: (text, record, index) => {
                return <span>{ (page-1)*size + (index + 1) }</span>;
            }
        },
        { 
            title: 'Mã gói',
            dataIndex: 'code',
            key: 'code',
            align: 'left',
            width: 115,
            // render: (value, record) => {
            // }
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 50,
            render: (row, record) => {
                return (
                    <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                        <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                    </Dropdown>
                )          
            }
        },
        {
            title: 'Tên gói',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 150,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            width: 150,
        },
        {
            title: 'Số lượng dịch vụ',
            dataIndex: 'quantityService',
            key: 'quantityService',
            align: 'center',
            width: 80,
        },

    ];
    return (
        <React.Fragment>
            <div className="containerChilderWrapper">
                <HeaderModal title="Quản lý gói dịch vụ"/>
                <div className="row">
                    <div className="col-md-12"><Notify ref={notiRef} /></div>
                </div>
                <div className="row my-2">
                    <div className="col-12">
                        <label>Từ khóa</label>
                        <Input
                            placeholder="Nhập mã, tên gói dịch vụ."
                            value={keyword}
                            onChange={(e) => {setKeyword(e.target.value)}}
                            onPressEnter={() => {GetListServicePackage()}}
                        />
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-6 d-flex justify-content-start">
                        <button className="btn btn-success" 
                            onClick={() => {setVisibleCreate(true)}}>
                            <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới
                        </button>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <button className="btn btn-color" 
                            onClick={() => {GetListServicePackage()}}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </button>
                    </div>
                </div>
                {/* <div className="row my-2">
                    <div className="col-12" style={{ height:'calc(100% - 210px)' }}>
                        <Spin spinning={loading} className='spin-color'>
                            <Table 
                                className="table-min-h-0 table-header-1row"
                                dataSource={dataSource} 
                                columns={columns}
                                locale={{ emptyText: 'Không có dữ liệu' }} 
                                scroll={{ y: 0 }}
                                rowClassName={(record, rowIndex) => {
                                }}
                                pagination={false}
                            />
                        </Spin>
                    </div>
                </div> */}
                <div style={{ paddingTop: 10, height: 'calc(100% - 210px)' }}>
                    <Table dataSource={dataSource}
                        scroll={{ y: 0 }}
                        pagination={false}
                        locale={{ emptyText: 'Không có dữ liệu' }} 
                        columns={columns} />
                </div>
                <Pagination
                    current={page}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size);
                    }}
                    onShowSizeChange={(page, size) =>{
                        setPage(1);
                        setSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>

        <Modal 
            visible={visibleCreate}
            destroyOnClose={true}
            maskClosable={false}
            width={'60%'}>
            <Create isUpdate={isUpdate} packageId={packageId} onClose={() => { onClose() }}/>
        </Modal>

        <Modal 
            visible={visibleView}
            destroyOnClose={true}
            maskClosable={false}
            width={'60%'}>
            <View packageId={packageId} onClose={() => { onClose() }}/>
        </Modal>

        </React.Fragment>
    )
};
