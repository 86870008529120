import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../common/headerModal';
import { Alert, Button, Input, Modal, Pagination, Popconfirm, Select, Table, Tooltip, Upload } from 'antd';
import { connect } from 'react-redux';
import { BUTTON, NHOMXETNGHIEM } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import { isNullOrEmpty, isnotnull } from '../../libs/util';
import * as Actions from '../../libs/actions';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import * as ThirdPartyService from "../../services/thirdPartyServiceMappingService";
import moment from 'moment';

let task = null;

const ServiceMapping = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const _notifyModal = useRef();
    const _notifyModalUpload = useRef();
    const _hiddenLink = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [listThirdParty, setListThirdParty] = useState([]);
    const [selectedThirdParty, setSelectedThirdParty] = useState();
    const [visibleModalMapping, setVisibleModalMapping] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [isCreateNew, setIsCreateNew] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [keywordService, setKeywordService] = useState("");
    const [listOneTouchService, setListOneTouchService] = useState([]);
    const [mappedStatus, setMappedStatus] = useState("all");
    const [fileList, setFileList] = useState([]);
    const [visibleModalUpload, setVisibleModalUpload] = useState();
    const [errorList, setErrorList] = useState([]);
    const [effortList, setEffortList] = useState([]);
    const [listPairOfMapping, setListPairOfMapping] = useState([
        {
            thirdParty_ServiceId: null,
            thirdParty_ServiceName: null,
        }
    ]);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 80,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Tên dịch vụ HIS OneTouch',
            dataIndex: 'oneTouch_ServiceName',
            key: 'oneTouch_ServiceName',
            align: 'left',
            width: 250,
        },
        {
            title: 'Mã dịch vụ HIS OneTouch',
            dataIndex: 'oneTouch_ServiceCode',
            key: 'oneTouch_ServiceCode',
            align: 'center',
            width: 180,
        },
        {
            title: 'Mã dịch vụ HIS đối tác',
            dataIndex: 'thirdParty_ServiceId',
            key: 'thirdParty_ServiceId',
            align: 'center',
            width: 180,
        },
        {
            title: 'Tên dịch vụ HIS đối tác',
            dataIndex: 'thirdParty_ServiceName',
            key: 'thirdParty_ServiceName',
            align: 'left',
            width: 250,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: 350,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 300,
            render: (e, record) => {
                return (
                    <Fragment>
                        <Button className={`${BUTTON.THEME}`} onClick={() => { let createNew = false; getMappedByOneTouchServiceId(record, createNew); }}><i className='fas fa-link mr-1' />Map mã</Button>
                        {!isNullOrEmpty(record.thirdParty_ServiceId) &&
                            <Popconfirm title="Xác nhận xóa thông tin map mã?"
                                okText="Đồng ý" cancelText="Hủy bỏ" placement='rightTop'
                                onConfirm={() => onDeleteMapping(record.id)}>
                                <Button className={`${BUTTON.WARNING} ml-2`}><i className='fas fa-unlink mr-1' />Hủy map mã</Button>
                            </Popconfirm>}
                    </Fragment>
                )
            }
        },
    ];

    useEffect(() => {
        getListThirdParty();
    }, []);

    useEffect(() => {
        selectedThirdParty && getListServiceMapping();
    }, [selectedThirdParty, mappedStatus, page, size]);

    const getListThirdParty = () => {
        const data = [
            {
                id: "",
                title: "TNET",
                value: "3"
            },
        ]
        setListThirdParty(data);
        if (data && data.length > 0) {
            setSelectedThirdParty(data[0].value);
        }
    }

    const getListServiceMapping = (thirdPartyId = selectedThirdParty, status = mappedStatus, key = keyword) => {
        Actions.setLoading(true);
        let filterModel = {
            skipCount: (page - 1) * size,
            maxResultCount: size,
            keyword: key,
            mappedStatus: status,
            thirdPartyId
        }
        setListData([]);
        ThirdPartyService.GetListServiceMapping(filterModel).then(res => {
            if (res.isSuccess) {
                let data = res.data.items;
                setListData(data);
                setTotalRecords(res.data.totalCount);
                _notify && _notify.current && _notify.current.Close();
            } else _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onDeleteMapping = (id) => {
        if (!isnotnull(id)) {
            _notify && _notify.current && _notify.current.Show("Không có thông tin dữ liệu cần xóa, vui lòng thử lại sau", NotifyStatus.Warning);
            return;
        }
        Actions.setLoading(true);
        ThirdPartyService.DeleteServiceMapping(id).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Thao tác thành công", NotifyStatus.Success);
            } else _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            getListServiceMapping();
            Actions.setLoading(false);
        })
    }

    const onOpenModalMapping = (data, createNew = true) => {
        createNew && getListOneTouchService();
        setVisibleModalMapping(true);
        setSelectedRecord(data);
        setIsCreateNew(createNew);
    }

    const onCloseModalMapping = () => {
        setVisibleModalMapping(false);
        setSelectedRecord({});
        setListPairOfMapping([
            {
                thirdParty_ServiceId: null,
                thirdParty_ServiceName: null,
            }
        ]);
        setIsCreateNew(true);
        _notifyModal && _notifyModal.current && _notifyModal.current.Close();
    }

    const onSaveMapping = () => {
        let itemError = false;
        if (isNullOrEmpty(selectedThirdParty)) {
            _notifyModal && _notifyModal.current && _notifyModal.current.Show("Vui lòng chọn HIS đối tác", NotifyStatus.Warning);
            return;
        }
        if (!isnotnull(selectedRecord)) {
            _notifyModal && _notifyModal.current && _notifyModal.current.Show("Không có thông tin để lưu", NotifyStatus.Warning);
            return;
        }
        if (isNullOrEmpty(selectedRecord.oneTouch_ServiceId)) {
            _notifyModal && _notifyModal.current && _notifyModal.current.Show("Vui lòng chọn mã dịch vụ HIS OneTouch", NotifyStatus.Warning);
            return;
        }
        if (!isnotnull(listPairOfMapping)) {
            _notifyModal && _notifyModal.current && _notifyModal.current.Show("Vui lòng nhập ít nhất 1 bộ mã dịch vụ HIS đối tác", NotifyStatus.Warning);
            return;
        } else {
            listPairOfMapping.forEach((partySer, index) => {
                if (isNullOrEmpty(partySer.thirdParty_ServiceId)) {
                    let noti = listPairOfMapping.length == 1 ? "." : ` cho bộ mã thứ ${index + 1}.`;
                    _notifyModal && _notifyModal.current && _notifyModal.current.Show(`Vui lòng nhập mã dịch vụ HIS đối tác${noti}`, NotifyStatus.Warning);
                    itemError = true;
                }
            })
        }
        if (itemError) return;
        Actions.setLoading(true);
        selectedRecord.ThirdParty_Id = selectedThirdParty;
        selectedRecord.listThirdPartyService = listPairOfMapping;
        ThirdPartyService.SaveServiceMapping(selectedRecord).then(res => {
            if (res.isSuccess) {
                getListServiceMapping();
                _notifyModal && _notifyModal.current && _notifyModal.current.Show("Lưu thành công", NotifyStatus.Success);
            } else _notifyModal && _notifyModal.current && _notifyModal.current.Show(res.error.messageText, NotifyStatus.Error);
        }).catch((err) => {
            _notifyModal && _notifyModal.current && _notifyModal.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onChangeSelectedRecordInfo = (val, field, info) => {
        if (!isnotnull(info)) info = { ...selectedRecord };
        if (val == undefined) val = null;
        info[field] = val;
        setSelectedRecord(info);
        return info;
    }

    const onChangeKeyword = (key) => {
        setKeyword(key);
        setPage(1);
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            getListServiceMapping(selectedThirdParty, mappedStatus, key);
        }, 700);
    }

    const getListOneTouchService = (key = keywordService, isLoading = true) => {
        Actions.setLoading(isLoading);
        const sPage = 1, sSize = 100, nhomVP = NHOMXETNGHIEM, bangGia = "default";
        VPService.GetDanhSachVP(false, key, bangGia, nhomVP, 0, sPage, sSize)
            .then(result => {
                if (result.isSuccess) {
                    setListOneTouchService(result.data);
                }
            })
            .catch(err => { })
            .finally(() => Actions.setLoading(false))
    }

    const onChangeServiceInfo = (record) => {
        let info = { ...selectedRecord };
        info = onChangeSelectedRecordInfo(record.children, "oneTouch_ServiceName", info);
        info = onChangeSelectedRecordInfo(record.key, "oneTouch_ServiceId", info);
        info = onChangeSelectedRecordInfo(record.value, "oneTouch_ServiceCode", info);
    }

    const onDelaySearchOneTouchService = (key) => {
        let loading = false;
        setKeywordService(key);
        if (task) {
            clearTimeout(task);
            task = null;
        }
        task = setTimeout(() => {
            getListOneTouchService(key, loading);
        }, 700);
    }

    const exportListServiceMapping = () => {
        Actions.setLoading(true);
        let filterModel = {
            maxResultCount: 0,
            skipCount: 0,
            mappedStatus,
            thirdPartyId: selectedThirdParty
        }
        ThirdPartyService.ExportListServiceMapping(filterModel)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = moment().format('DDMMYY') + "_DanhSachMapMaDichVu";
                    _hiddenLink.current.click();
                })
            }).catch(err => {
            }).finally(() => { Actions.setLoading(false); })
    }

    const configUpload = {
        showUploadList: {
            showDownloadIcon: false,
        },
        onRemove() {
            setFileList([])
        },
        onChange(info) {
            setFileList(info.fileList)
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList
    };

    const onImport = () => {
        Actions.setLoading(true);
        if (fileList.length == 0) {
            _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Show("Vui lòng chọn file", NotifyStatus.Warning);
            Actions.setLoading(false);
            return;
        }
        resetImportInfo();
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        ThirdPartyService.ImportServiceMapping(formData, selectedThirdParty).then(res => {
            if (res.isSuccess) {
                if (res.data.effort != null && res.data.effort.length > 0)
                    setEffortList(res.data.effort);
                if (res.data.errorItems != null && res.data.errorItems.length > 0) {
                    setErrorList(res.data.errorItems);
                    _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Show(res.error?.messageText ?? "Đã xảy ra lỗi", NotifyStatus.Error);
                }
                else {
                    setFileList([]);
                    _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Show("Import hoàn tất", NotifyStatus.Success);
                }
            } else _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Show(res.error?.messageText ?? "Đã xảy ra lỗi", NotifyStatus.Error);
        }).catch(err => {
            _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Show(err.error?.messageText ?? "Đã xảy ra lỗi", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            getListServiceMapping();
        })
    }

    const onOpenModalUpload = () => {
        setVisibleModalUpload(true);
    }

    const onCloseModalUpload = () => {
        setVisibleModalUpload(false);
        setFileList([]);
        resetImportInfo();
        _notifyModalUpload && _notifyModalUpload.current && _notifyModalUpload.current.Close();
    }

    const resetImportInfo = () => {
        setErrorList([]);
        setEffortList([]);
    }

    const onAddNewPairOfMapping = () => {
        let data = [...listPairOfMapping];
        let pair = {
            thirdParty_ServiceId: null,
            thirdParty_ServiceName: null,
        };
        data.push(pair);
        setListPairOfMapping(data);
    }

    const onClearPairOfMapping = (index, data) => {
        let dataNew = [...data];
        if (index > -1) {
            dataNew.splice(index, 1);
        }
        setListPairOfMapping(dataNew);
    }

    const onChange3rdMappingInfo = (index, val, field) => {
        let data = [...listPairOfMapping];
        if (index > -1) {
            data[index][field] = val;
        }
        setListPairOfMapping(data);
    }

    const getMappedByOneTouchServiceId = (record) => {
        Actions.setLoading(true);
        const data = {
            id: record.oneTouch_ServiceId,
            party: selectedThirdParty
        }
        ThirdPartyService.GetListMapped(data).then(res => {
            if (res.isSuccess) {
                let data = res.data && res.data.length > 0 ? res.data : [{
                    thirdParty_ServiceId: null,
                    thirdParty_ServiceName: null,
                }];
                setListPairOfMapping(data);
                onOpenModalMapping(record, false);
            } else _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    return (
        <div className="list-page containerChilderWrapper">
            <a href="#download" style={{ display: 'none', visibility: "hidden" }} ref={_hiddenLink}></a>
            <HeaderModal title="Map mã dịch vụ HIS" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='row d-flex align-item-end'>
                        <div className="col-md-4 mt-2">
                            <label className='mita-title required-field'>Chọn HIS kết nối</label>
                            <Select
                                disabled
                                placeholder="Chọn mã HIS kết nối"
                                value={selectedThirdParty}
                                onChange={(e) => {
                                    setSelectedThirdParty(e)
                                }}
                                className='w-100'>
                                {
                                    listThirdParty && listThirdParty.map((tp, index) => {
                                        return <Select.Option key={index} value={tp.value}>{tp.title}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className="col-md-2 mt-2">
                            <label className='mita-title'>Trạng thái map mã</label>
                            <Select
                                className='w-100'
                                placeholder="Chọn trạng thái"
                                value={mappedStatus}
                                onChange={(val) => { setPage(1); setMappedStatus(val) }}
                                defaultValue={"all"}
                            >
                                <Select.Option key="all" value="all">Tất cả trạng thái map</Select.Option>
                                <Select.Option key="mapped" value="mapped">Đã map mã</Select.Option>
                                <Select.Option key="unMapped" value="unMapped">Chưa map mã</Select.Option>
                            </Select>
                        </div>
                        <div className="col-md-6 mt-2">
                            <label className='mita-title'>&nbsp;</label>
                            <Input
                                className="w-100"
                                placeholder="Nhập mã hoặc tên dịch vụ để tìm"
                                value={keyword}
                                onChange={(e) => {
                                    onChangeKeyword(e.target.value);
                                }}
                                suffix={<span onClick={() => { getListServiceMapping() }}><i className='fas fa-search mr-1' /></span>}
                            ></Input>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 mt-2 d-flex justify-content-end'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { exportListServiceMapping() }}><i className='fas fa-download mr-1' />Xuất</button>
                            <button className={`${BUTTON.THEME} ml-2`} onClick={() => { onOpenModalUpload() }}><i className='fas fa-upload mr-1' />Import</button>
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.oneTouch_ServiceId}
                        pagination={false}
                        onRow={(record) => {
                            let createNew = false;
                            return {
                                onDoubleClick: () => { getMappedByOneTouchServiceId(record, createNew); }
                            };
                        }}
                        rowClassName={() => {
                            return "rowCustom";
                        }}
                        components={{
                            body: {
                                row: (props) =>
                                    <Tooltip title="Nhấp đôi chuột trái để liên kết mã" color='#adadadc7'>
                                        <tr {...props} />
                                    </Tooltip>
                            }
                        }}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size) }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size) }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
            <Modal visible={visibleModalMapping}
                width={600}
                destroyOnClose
                maskClosable
                onCancel={() => onCloseModalMapping()}>
                <Fragment>
                    <HeaderModal title={`Map mã dịch vụ ${selectedRecord.oneTouch_ServiceName ?? ""}`} onClose={() => { onCloseModalMapping() }}></HeaderModal>
                    <Notify ref={_notifyModal} />
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <label className='mita-title w-100'>
                                <div className='d-flex justify-content-between'>
                                    <span className='required-field'>Tên dịch vụ HIS OneTouch</span>
                                    <span className='required-field'>Mã dịch vụ HIS OneTouch</span>
                                </div>
                            </label>
                            <div>
                                <Select
                                    style={{ width: "70%" }}
                                    placeholder="Nhập mã hoặc tên dịch vụ HIS OneTouch"
                                    disabled={!isCreateNew}
                                    value={selectedRecord?.oneTouch_ServiceName}
                                    showSearch
                                    onSearch={(key) => {
                                        onDelaySearchOneTouchService(key);
                                    }}
                                    filterOption={false}
                                    onChange={(e, record) => {
                                        onChangeServiceInfo(record);
                                    }}
                                >
                                    {
                                        listOneTouchService && listOneTouchService.map((ser, index) => {
                                            return (
                                                <Select.Option key={ser.id} value={ser.ma}>{ser.ten}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                                <Input
                                    readOnly
                                    placeholder="Mã dịch vụ HIS OneTouch"
                                    style={{ width: "30%" }}
                                    value={selectedRecord?.oneTouch_ServiceCode}>
                                </Input>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-12 text-center'>
                            <i className='fas fa-link'></i>
                        </div>
                    </div>
                    {listPairOfMapping && listPairOfMapping.map((record, index, dataSource) => {
                        return (
                            <div className='row mt-2' id={`${index}-${record.thirdParty_ServiceId}`}>
                                <div className='col-md-12'>
                                    <label className='mita-title w-100'>
                                        <div className='d-flex justify-content-between'>
                                            <span>Tên dịch vụ HIS đối tác</span>
                                            <span className='required-field'>Mã dịch vụ HIS đối tác</span>
                                        </div>
                                    </label>
                                    <div>
                                        <Tooltip title="Nhập tên dịch vụ HIS đối tác" placement='top'>
                                            <Input
                                                placeholder="Nhập tên dịch vụ HIS đối tác"
                                                style={{ width: "70%" }}
                                                value={record?.thirdParty_ServiceName}
                                                onChange={(e) => {
                                                    onChange3rdMappingInfo(index, e.target.value, "thirdParty_ServiceName");
                                                }}>
                                            </Input>
                                        </Tooltip>
                                        {listPairOfMapping.length == 1 ?
                                            <Tooltip title="Nhập mã dịch vụ HIS đối tác" placement='top'>
                                                <Input
                                                    placeholder="Nhập mã dịch vụ HIS đối tác"
                                                    style={{ width: "30%" }}
                                                    value={record?.thirdParty_ServiceId}
                                                    onChange={(e) => {
                                                        onChange3rdMappingInfo(index, e.target.value, "thirdParty_ServiceId");
                                                    }}>
                                                </Input>
                                            </Tooltip>
                                            :
                                            <Fragment>
                                                <Tooltip title="Nhập mã dịch vụ HIS đối tác" placement='top'>
                                                    <Input
                                                        placeholder="Nhập mã dịch vụ HIS đối tác"
                                                        style={{ width: "calc(30% - 40px)" }}
                                                        value={record?.thirdParty_ServiceId}
                                                        onChange={(e) => {
                                                            onChange3rdMappingInfo(index, e.target.value, "thirdParty_ServiceId");
                                                        }}>
                                                    </Input>
                                                </Tooltip>
                                                <Tooltip title="Xóa" placement='top'>
                                                    <Popconfirm title="Bạn có chắc chắn?" okText="Đồng ý" cancelText="Hủy bỏ"
                                                        onConfirm={() => {
                                                            onClearPairOfMapping(index, dataSource);
                                                        }}>
                                                        <Button style={{ width: "40px" }} className={`${BUTTON.DANGER}`} >
                                                            <i className='far fa-trash-alt' />
                                                        </Button>
                                                    </Popconfirm>
                                                </Tooltip>
                                            </Fragment>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='w-100 text-end mt-2'>
                        <Button className={`${BUTTON.THEME}`} onClick={() => { onAddNewPairOfMapping() }}>
                            <i className='fas fa-plus-circle mr-1' />Thêm 1 bộ mã HIS đối tác
                        </Button>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <label className='mita-title'>Mô tả</label>
                            <Input.TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={selectedRecord?.description}
                                onChange={(e) => {
                                    onChangeSelectedRecordInfo(e.target.value, "description");
                                }}
                            ></Input.TextArea>
                        </div>
                    </div>
                    <div className='custom-hr'></div>
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-end'>
                            <Button className={`${BUTTON.THEME}`} onClick={() => onSaveMapping()}><i className="fa fa-save mr-1" />Lưu</Button>
                            <Button className={`${BUTTON.DANGER} ml-2`} onClick={() => onCloseModalMapping()}><i className="fa fa-sign-out-alt mr-1" />Đóng</Button>
                        </div>
                    </div>
                </Fragment>
            </Modal>
            <Modal visible={visibleModalUpload}
                width={600}
                destroyOnClose
                maskClosable
                onCancel={() => onCloseModalUpload()}>
                <HeaderModal title={`Import map mã dịch vụ ${selectedRecord.oneTouch_ServiceName ?? ""}`} onClose={() => { onCloseModalMapping() }}></HeaderModal>
                <Alert className='text-center mt-2' type='info' message={<span>HIS kết nối: <b>{listThirdParty.find(x => x.value == selectedThirdParty)?.title ?? "CHƯA CÓ THÔNG TIN"}</b></span>}></Alert>
                <div className='mt-2'>
                    <Notify ref={_notifyModalUpload} />
                </div>
                <div className='mt-2'>
                    <Upload.Dragger
                        height={140}
                        {...configUpload}
                        accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxCount={1}
                        style={{ width: '100%' }}>
                        <div style={{ position: 'relative', }}>
                            <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                            <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                            <p className="ant-upload-hint">
                                Chỉ hỗ trợ các định dạng file: *.xls, *.xlsx, *.csv
                            </p>
                        </div>
                    </Upload.Dragger>
                    <div className='custom-hr'></div>
                    <div className='d-flex justify-content-end'>
                        <Button className={`${BUTTON.THEME}`} onClick={() => onImport()}><i className="fa fa-save mr-1" />Import</Button>
                        <Button className={`${BUTTON.DANGER} ml-2`} onClick={() => onCloseModalUpload()}><i className="fa fa-sign-out-alt mr-1" />Đóng</Button>
                    </div>
                </div>
                {errorList && errorList.length > 0 &&
                    <div className='w-100'>
                        <label className='text-danger font-weight-bold text-uppercase'>Thông tin lỗi</label>
                        <ul>
                            {errorList.map((er, index) => {
                                return <li key={index}>{er}</li>
                            })}
                        </ul>
                    </div>}
                {effortList && effortList.length > 0 &&
                    <div className='w-100'>
                        <label className='text-success font-weight-bold text-uppercase'>Kết quả import</label>
                        <ul>
                            {effortList.map((su, index) => {
                                return <li key={index}>{su}</li>
                            })}
                        </ul>
                    </div>}
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ServiceMapping)