import React, { useState, useEffect, useRef } from "react";
import { Collapse } from 'antd';
import * as Actions from '../../../../../libs/actions';
import Notify, { NotifyStatus } from '../../../../../components/notify';
import * as ExamResultService from '../../../../../services/ExamResultServices';
import TestResultBegin from '../../../../testresults/detail-components/testresult-begin';
import TestResultDetail from '../../../../testresults/detail-components/testresult-detail';
import TestResultBottom from '../../../../testresults/detail-components/testresult-bottom';

const { Panel } = Collapse;
export default (props) => {
    const beginRef = useRef();
    const detailRef = useRef();
    const bottomRef = useRef();
    const _notiRef = useRef();
    const [managementId, setManagementId] = useState('');
    const [dataSource, setDataSource] = useState([]);

    useEffect(()=> {
        setManagementId(props.managementId);
    },[props.managementId])

    useEffect(() => {
        if(managementId !== '')
            ListExamResultByManagementId(managementId)
    },[managementId])

    const ListExamResultByManagementId = (id) => {
        Actions.setLoading(true);
        ExamResultService.ListExamResultByManagementId(id).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    return (
        <div style={{ overflowY: "auto" }}>
            <Notify ref={_notiRef} />
            <div className="border">
                <Collapse expandIconPosition="right">
                {dataSource && dataSource.map(item => (
                    <Panel header={<label className='mita-header-title d-flex justify-content-start align-items-center w-100'>
                        <span><i className="fas fa-vials mr-2"/>Kết quả xét nghiệm - {item.sid}</span></label>} key={item.sid}>
                        <div className="h-100 d-flex flex-column overflow-auto">{/**Right Panel */}
                        {item.status ? 
                        <>
                            <div>{/**Left Panel - top: thông tin phía trên*/}
                                <TestResultBegin ref={beginRef}
                                sid={item.sid}
                                />
                            </div>
                            <div>{/**Left Panel - body: chi tiết kết quả*/} 
                                <TestResultDetail ref={detailRef}
                                maQL={item.maQL}
                                sid={item.sid}
                                />
                            </div>
                            <div>{/**Left Panel - bottom: thông tin phía dưới*/} 
                                <TestResultBottom ref={bottomRef}
                                sid={item.sid}
                                />
                            </div>
                        </>
                        :
                            <div className="d-flex justify-content-center">
                                <label className="mita-value">SID chưa xác nhận mẫu </label>
                            </div>
                        }
                        </div>
                    </Panel>
                ))}
                </Collapse>
            </div>
        </div>
        
    )
};
