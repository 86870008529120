import { Button, DatePicker, Form, Pagination, Input, Select, Tabs,Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import Chitiet from './nguoigioithieuchitiet'

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function NguoiGioiThieu() {
    const _hiddenLink = useRef()
    const [ngay, setngay] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [filter, setFilter] = useState('1');
    const [isMultisite, setIsMultisite] = useState(false);
    const _report = useRef()

    // useEffect(() => {
    //     GetListSite();
    //     UsersService.CheckPremissionMultisite().then(result => {
    //         if (result.isSuccess) {
    //             setIsMultisite(result.item)
    //         }
    //     });
    // }, [])

    useEffect(() => {
        ThongKeNguoiGioiThieu();
        if(_report && _report.current){
            _report.current.reload();
        }
    }, [ngay, page, size, filter]);

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    function ThongKeNguoiGioiThieu() {
        Actions.setLoading(true);
        StatisticService.ThongKeNguoiGioiThieu(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), chinhanh, key, filter, page, size).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    function ExportThongKeDoanhSoBS() {
        Actions.setLoading(true);
        StatisticService.ExportThongKeNguoiGioiThieu(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), chinhanh, key, filter)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeNguoiGioiThieu";
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 180,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 120
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 120
        },
        {
            title: 'Số người giới thiệu',
            dataIndex: 'tongNguoiGioiThieu',
            key: 'tongNguoiGioiThieu',
            width: 150
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'tongtien',
            key: 'tongtien',
            width: 100,
            align: "right",
            render: (value) => value.toLocaleString("en-US")
        }
    ];

    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='containerChilderWrapper'>
            <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê người giới thiệu</div>
            <div className="d-flex justify-content-center my-1">
                {/* {isMultisite === true &&
                    <div style={{ width: 200 }} className="mr-2">
                        <Form.Item>
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                allowClear={false}
                                value={chinhanh}
                                onChange={(e) => {
                                    setChinhanh(e)
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={''}>Chi nhánh</Option>
                                {listSite.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                } */}
                <div style={{ width: 200 }} className="mr-2">
                    <Form.Item>
                        <Select
                            style={{ width: '200px' }}
                            showSearch
                            allowClear={false}
                            value={filter}
                            onChange={(e) => {
                                setFilter(e)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={1} value={'1'}>Nguồn khách hàng</Option>
                            <Option key={2} value={'2'}>Người giới thiệu</Option>
                            <Option key={3} value={'3'}>Sale</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div style={{ width: 250 }} className="mr-2">
                    <Form.Item>
                        <RangePicker allowClear={false} format="DD-MM-YYYY" value={ngay}
                            locale={viVN}
                            onChange={((value) => setngay(value))}
                        />
                    </Form.Item>
                </div>
                <div style={{ width: 250 }} className="mr-2">
                    <Form.Item>
                        <Input className="form-control" value={key} placeholder="Nhập tên, điện thoại người giới thiệu"
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className="d-flex">
                    <Form.Item>
                        <Button className="btn btn-color"
                            onClick={() => { 
                                ThongKeNguoiGioiThieu()
                                if(_report && _report.current){
                                    _report.current.reload();
                                }
                            }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />
                            Tìm
                        </Button>
                    </Form.Item>
                    
                </div>
            </div>
            <Tabs animated={true} style={{ height: "90%" }}
            >
                <Tabs.TabPane tab={"Tổng hợp"} key="1">
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom : '10px' }}>
                            <Form.Item>
                                <Button className="btn btn-color" onClick={() => { ExportThongKeDoanhSoBS() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                            </Form.Item>
                        </div>
                        {/* <div className="table-responsive" style={{
                            backgroundColor: '#fff', marginTop: 20,
                            height: 'calc(100% - 165px)'
                        }}>
                            <table className="table table-striped table-inverse table-hover">
                                <thead className="thead-inverse">
                                    <tr>
                                        <th>Mã</th>
                                        <th>Tên</th>
                                        <th>Điện thoại</th>
                                        <th>Email</th>
                                        <th style={{ minWidth: '150px' }}>Số người giới thiệu</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataSource.map((row, index) =>
                                        <React.Fragment key={index}>
                                            <tr style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#collapseNGT` + index}>
                                                <td>{row.id}</td>
                                                <td>{row.hoten}</td>
                                                <td>{row.dienthoai}</td>
                                                <td>{row.email}</td>
                                                <td>{row.tongNguoiGioiThieu}</td>
                                            </tr>
                                            {row.listPatient && row.listPatient.length > 0 &&
                                                <tr className="collapse" id={`collapseNGT` + index}>
                                                    <td colSpan="6">
                                                        <div className="collapse" id={`collapseNGT` + index}>
                                                            <table className="table table-expand" style={{ marginBottom: '0' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Mã BN</th>
                                                                        <th>Tên BN</th>
                                                                        <th>Ngày vào</th>
                                                                        <th className="text-right">Tổng tiền</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {row.listPatient.map((item, childIndex) =>
                                                                        <React.Fragment>
                                                                            <tr key={childIndex} style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#collapseNGTChild` + childIndex}>
                                                                                <td>{item.patientID}</td>
                                                                                <td>{item.patientName}</td>
                                                                                <td>{moment(item.applyDate).format("DD/MM/YYYY")}</td>
                                                                                <td className="text-right">{item.tongtien.toLocaleString('en-US')}</td>
                                                                            </tr>
                                                                            {item.listSubPatient && item.listSubPatient.length > 0 && item.tongtien > 0 &&
                                                                                <tr className="collapse" id={`collapseNGTChild` + childIndex}>
                                                                                    <td colSpan="6">
                                                                                        <div className="collapse" id={`collapseNGTChild` + childIndex}>
                                                                                            <table className="table table-expand" style={{ marginBottom: '0' }}>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Mã VP</th>
                                                                                                        <th>Tên VP</th>
                                                                                                        <th className="text-center">Số lượng</th>
                                                                                                        <th className="text-right">Tổng tiền</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {item.listSubPatient.map((child, childVPIndex) =>
                                                                                                        <tr key={childVPIndex}>
                                                                                                            <td>{child.mavp}</td>
                                                                                                            <td>{child.ten}</td>
                                                                                                            <td className="text-center">{child.totalAmount.toLocaleString('en-US')}</td>
                                                                                                            <td className="text-right">{child.dongia.toLocaleString('en-US')}</td>
                                                                                                        </tr>)}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </React.Fragment>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div> */}
                        <div style={{ marginBottom: '10px', height: 'calc(100% - 110px)' }}>
                            <Table dataSource={dataSource} scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} rowKey={(e, index) => index}
                            />
                            <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                                total={totalRecord}
                                showTotal={total => `Tổng: ${total}`}
                                size={size}
                                current={page}
                                onChange={(page, size) => {
                                    setPage(page);
                                    setSize(size)
                                }}
                                onShowSizeChange={(page, size) => {
                                    setPage(1);
                                    setSize(size)
                                }
                                }
                                showSizeChanger={true}
                            />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Chi tiết"} key="2">
                    <Chitiet ref={_report} ngay={ngay} filter={filter}/>
                </Tabs.TabPane>
            </Tabs>
        </div>
        </>
    )
}
