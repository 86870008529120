import React, { Fragment, useRef, useState, useEffect } from 'react';
import HeaderModal from '../common/headerModal';
import { Input, Modal, Popconfirm, Select, Table, notification } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import * as IntroVideoService from '../../services/introVideoServices';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import NoContent from "../../components/loading/noContent";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import ReactPlayer from 'react-player';
import { isnotnull } from '../../libs/util';

const regexURL = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;

function ConfigVideoIntro(props) {
    const [screen, setScreen] = useState({
        uuid: null,
        title: null,
        src: null,
        screen: null,
    });
    const [listIntroVideo, setListIntroVideo] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selected, setSelected] = useState({
        uuid: null,
        title: null,
        src: null,
        screen: null,
    });

    const _notify = useRef();
    const _notifySec = useRef();
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const renderScreenList = () => {
        let listOpts = [];
        props.menuData && props.menuData.length > 0 && props.menuData.map((x, index) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n, nInd) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc, ncInd) => {
                            listOpts.push(getOption(nc));
                        })
                    } else listOpts.push(getOption(n));
                })
            } else listOpts.push(getOption(x));
        })
        return listOpts;
    }

    const getOption = (opt) => {
        return <Select.Option key={`${opt.menuKey}`} value={opt.code}>{opt.label}</Select.Option>
    }

    useEffect(() => {
        onSearchListVideo();
    }, [screen])

    const onSearchListVideo = () => {
        Actions.setLoading(true);
        IntroVideoService.getListIntroVideo(screen?.value ?? "").then(res => {
            if (res.isSuccess) {
                setListIntroVideo(res.data);
                _notify && _notify.current && _notify.current.Close();
            } else {
                setListIntroVideo([]);
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch(() => {
            _notify && _notify.current && _notify.current.Show("Không thể tải danh sách video", NotifyStatus.Error);
            setListIntroVideo([]);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Mã màn hình',
            dataIndex: 'screen',
            key: 'screen',
            align: 'left',
            width: 50,
        },
        {
            title: 'Menu truy cập',
            dataIndex: 'path',
            key: 'path',
            align: 'left',
            width: 100,
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
            align: 'left',
            width: 150,
        },
        {
            title: 'URL',
            dataIndex: 'src',
            key: 'src',
            align: 'left',
            width: 200,
            render: (url) => {
                return (
                    <a href={`${url}`} target="_blank">{url}</a>
                )
            }
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 200,
            render: (val, rec) => {
                return (
                    <Fragment>
                        <button className={`${BUTTON.WARNING}`} onClick={() => onHandleCreateUpdate(true, rec)}><i className='fas fa-edit mr-1' />Cập nhật</button>
                        <Popconfirm title="Bạn có chắc chắn xóa?"
                            okText="Đồng ý"
                            cancelText="Hủy bỏ"
                            onConfirm={() => onDelete(rec.uuid)}
                        >
                            <button className={`${BUTTON.DANGER} ml-2`}><i className='fas fa-trash mr-1' />Xóa</button>
                        </Popconfirm>
                    </Fragment>
                )
            }
        },
    ];

    const onHandleCreateUpdate = (isUpdate = false, data = {
        uuid: null,
        title: null,
        src: null,
        screen: null,
    }) => {
        if (isUpdate)
            setScreen({
                value: data.screen,
                children: data.name,
            });
        setIsUpdate(isUpdate);
        setVisibleUpdate(true);
        setSelected(data);
    }

    const onCreateUpdate = () => {
        if (!isnotnull(screen) || !isnotnull(screen.value)) {
            _notifySec && _notifySec.current && _notifySec.current.Show("Vui lòng chọn màn hình", NotifyStatus.Warning);
            return;
        }
        if (validator.current.allValid()) {
            Actions.setLoading(true);
            if (isUpdate && selected.uuid) {
                IntroVideoService.updateIntroVideo(selected).then((res) => {
                    if (res.isSuccess) {
                        _notifySec && _notifySec.current && _notifySec.current.Show("Cập nhật thành công", NotifyStatus.Success);
                    } else {
                        _notifySec && _notifySec.current && _notifySec.current.Show(`Không thể cập nhật: ${res.error.messageText}`, NotifyStatus.Error);
                    }
                }).catch((err) => {
                    _notifySec && _notifySec.current && _notifySec.current.Show(err.error.messageText, NotifyStatus.Error);
                }).finally(() => {
                    Actions.setLoading(false);
                    onSearchListVideo();
                })
            } else {
                IntroVideoService.createIntroVideo(selected).then((res) => {
                    if (res.isSuccess) {
                        _notifySec && _notifySec.current && _notifySec.current.Show("Thêm thành công", NotifyStatus.Success);
                    } else {
                        _notifySec && _notifySec.current && _notifySec.current.Show(`Không thể thêm: ${res.error.messageText}`, NotifyStatus.Error);
                    }
                }).catch((err) => {
                    _notifySec && _notifySec.current && _notifySec.current.Show(err.error.messageText, NotifyStatus.Error);
                }).finally(() => {
                    Actions.setLoading(false);
                    onSearchListVideo();
                })
            }
            validator.current.hideMessages();
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const onDelete = (id) => {
        Actions.setLoading(true);
        IntroVideoService.deleteIntroVideo(id).then((res) => {
            if (res.isSuccess) {
                _notifySec && _notifySec.current && _notifySec.current.Show("Xóa thành công", NotifyStatus.Success);
            } else {
                _notifySec && _notifySec.current && _notifySec.current.Show(`Không thể xóa: ${res.error.messageText}`, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notifySec && _notifySec.current && _notifySec.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            onSearchListVideo();
        })
    }

    const onCloseModalUpdate = () => {
        setIsUpdate(false);
        setVisibleUpdate(false);
        validator && validator.current && validator.current.hideMessages();
        _notifySec && _notifySec.current && _notifySec.current.Close();
        _notify && _notify.current && _notify.current.Close();
    }

    const updateSelected = (lbl, val) => {
        let item = { ...selected };
        if (lbl == "src") {
            val = val?.trim();
        }
        item[`${lbl}`] = val;
        item.screen = screen?.value;
        setSelected(item);
    }

    const renderPreviewSource = () => {
        if (regexURL.test(selected.src)) {
            return (
                <div className='mt-2'>
                    <ReactPlayer
                        width={"100%"}
                        controls={true}
                        url={selected.src}
                    />
                </div>
            )
        }
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Cấu hình video hướng dẫn" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='d-flex justify-content-start align-items-center' style={{ gap: 20 }}>
                        <div className='mt-2'>
                            <label>Màn hình</label>&nbsp;
                        </div>
                        <div className='mt-2'>
                            <Select
                                style={{ width: 350 }}
                                placeholder="Chọn màn hình"
                                onChange={(val, e) => setScreen(e)}
                                value={screen?.value ?? ""}
                                onClear={() => {
                                    setScreen({
                                        uuid: null,
                                        title: null,
                                        src: null,
                                        screen: null,
                                    })
                                }}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                >
                                {renderScreenList()}
                            </Select>
                        </div>
                        <div className='mt-2'>
                            <button className={`${BUTTON.THEME}`} onClick={() => onSearchListVideo()}><i className='fas fa-search mr-1' />Tìm</button>
                            <button className={`${BUTTON.THEME} ml-2`} onClick={() => onHandleCreateUpdate()}><i className='fas fa-plus mr-1' />Thêm</button>
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listIntroVideo}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.id}
                        pagination={{
                            showSizeChanger: true,
                            current: page,
                            onChange: (page, size) => { setPage(page); setSize(size); },
                            onShowSizeChange: (page, size) => { setPage(1); setSize(size); },
                            showTotal: (total) => `Tổng: ${total}`,
                            position: "bottomCenter"
                        }}
                    />
                </div>
            </div>
            <Modal
                visible={visibleUpdate}
                open={visibleUpdate}
                onCancel={onCloseModalUpdate}
                maskClosable
                width={600}
                destroyOnClose
            >
                <HeaderModal title={`${isUpdate ? "Cập nhật" : "Tạo mới"} thông tin video hướng dẫn`}
                    onClose={() => { onCloseModalUpdate() }} />
                {screen?.value ?
                    <Fragment>
                        <Notify ref={_notifySec} />
                        <div className='w-100'>
                            <label className='mita-title'>Màn hình</label>
                            <Input readOnly className="w-100" value={`${screen?.children} - ${screen?.value}`} />
                        </div>
                        <div className='w-100 mt-2'>
                            <label className='mita-title'>Tiêu đề</label>
                            <Input className="w-100" value={selected.title} onChange={(e) => { updateSelected("title", e.target.value) }} />
                        </div>
                        <div className='w-100 mt-2'>
                            <label className='mita-title required-field'>URL</label>
                            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} className="w-100" value={selected.src} onChange={(e) => { updateSelected("src", e.target.value) }} />
                            {validator.current && validator.current.message("Đường dẫn video", selected.src, "url")}
                        </div>
                        {renderPreviewSource()}
                        <div className='w-100 d-flex justify-content-center mt-2'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { onCreateUpdate() }}>{isUpdate ? "Cập nhật" : "Thêm"}</button>
                            <button className={`${BUTTON.DANGER} ml-2`} onClick={onCloseModalUpdate}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <NoContent title="Vui lòng chọn màn hình trước" message="LỖI" />
                        <div className='w-100 d-flex justify-content-center mt-2'>
                            <button className={`${BUTTON.DANGER} ml-2`} onClick={onCloseModalUpdate}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
                        </div>
                    </Fragment>
                }
            </Modal>
        </div >
    );
}

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ConfigVideoIntro)