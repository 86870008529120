import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Carousel, Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as Actions from '../../libs/actions';
import Notify, { NotifyStatus } from '../../components/notify';
import MitaCarouselImagePreview from '../../components/MitaComponents/mitaCarouselImagePreview';
import * as FeatureService from '../../services/FeatureService';

const { Dragger } = Upload;
const { TextArea } = Input;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [headerText, setHeaderText] = useState(''); 
  const [files, setFiles] = useState([]); 
  
  //#region useEffect
  useEffect(() => {
    getById(props.featureId);
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setHeaderText("Ảnh minh họa " + data.name);
    if (data.files && data.files.length > 0) {
      setFiles(data.files.map(x=> { return {
          uid: x.id,
          name: x.fileName,
          status: 'success',
          url: x.hostFileUrl,
        }
      }));
    }
  }

  const getById = (id) => {
    if (!id) return;
    Actions.setLoading(true);
    FeatureService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
        <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
            <h3>{ headerText }</h3>
            <h3 hidden={!props.isDialog}
              onClick={() => {
                props.onCancel()
              }}
            ><i className='far fa-times-circle' /></h3>
        </div>
        <div className="list-page-body">{/* body */}
          <div className="row">{/* notification */}
            <div className="col-md-12"><Notify ref={notiRef} /></div>
          </div>
          <div className="h-100 w-100">
            <MitaCarouselImagePreview
              listFiles={files}
            ></MitaCarouselImagePreview>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
})