import { Checkbox, DatePicker, Form, Input, notification, Select } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import * as CommonService from '../../../services/commonService';
import * as EnterInfoService from '../../../services/enterInfoService';
import * as NhapVienService from '../../../services/nhapVienService';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const initialState = {
    cardNumber: '',
    recommend: '',
    comeFrom: '',
    cdFrom: '',
    cdFromName: '',
    route: false,
    relative: '',
    relativeName: '',
    relativeAddress: '',
    relativePhone: '',
    cardCode: '',
    cardAddress: '',
    dateExpired: ['', ''],
    cardAddressLabel: '',
    cardLabel: false,
    lstRecommend: [],
}
export default class enterInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstSubject: [],
            lstcardAddress: [],
            lstCdFrom: [],
            lstComeFrom: [],
            subject: this.props.madoituong ? Number(this.props.madoituong) : 2,
            ...initialState,
        };
    }
    componentDidMount() {
        this.loadNhomDoiTuong();
        //this.loadKCB();
        this.loadDenTu();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.madoituong !== this.props.madoituong) {
            this.setState({ subject: Number(nextProps.madoituong) });
        }
        return true;
    }
    loadNhomDoiTuong() {
        EnterInfoService.GetNhomDoiTuong().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstSubject: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu đối tượng bị lỗi' })
        })
    }
    loadKCB(key) {
        EnterInfoService.GetDSKCB(key).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstcardAddress: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi đăng ký bị lỗi' })
        })
    }
    loadDiagnostic(value, title) {
        CommonService.GetListDiagnostic(value).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({ lstCdFrom: result.data });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ chẩn đoán nơi chuyển bị lỗi' })
        })
    }
    loadDenTu() {
        CommonService.GetListDenTu().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstComeFrom: result.data,
                    //recommend: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi giới thiệu bị lỗi' })
        })
    }
    loadHospital(value) {
        EnterInfoService.GetHospital(value).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstRecommend: result.data,
                    recommend: result.data[0].value
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu nơi giới thiệu bị lỗi' })
        })
    }
    checkValid() {
        if (this.validator.allValid()) {
            return true
        } else {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
    }
    resetState() {
        this.setState(initialState)
    }

    resetForm() {
        return {
            comeFrom: null,
            recommend: null,
            cardNumber: null,
            dateExpired: [moment(), moment()],
            cardAddress: null,
            cdFrom: null,
            subject: null,
        }
    }

    setForm(object) {
        if (this.props.setForm)
            this.props.setForm(object);
        return object;
    }

    loadThongTinBaoHiem(madoituong) {
        if (madoituong !== 1)
            return;
        if (this.props.patientCode !== undefined && this.props.thongtinBaohiem !== undefined && this.props.patientCode !== '') {
            NhapVienService.ThongTinBaoHiem(this.props.patientCode, madoituong, this.props.ngay).then(result => {
                if (result.isSuccess) {
                    this.props.thongtinBaohiem(result.item);
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu BHYT bị lỗi' })
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <label>Đến từ</label>
                        <Form.Item name="comeFrom" rules={[{ required: true, message: 'Vui lòng chọn nơi giới thiệu!' }]}>
                            <Select value={this.state.comeFrom} onChange={(value, e) => {
                                this.setState({ comeFrom: value });
                                this.setForm({
                                    comeFrom: value,
                                    recommend: null,
                                    cdFrom: null,
                                })
                            }}>
                                {this.state.lstComeFrom.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>CĐ nơi chuyển</label>
                        <Form.Item name="cdFrom" rules={[{ required: this.state.comeFrom === "1", message: 'Vui lòng nhập chẩn đoán nơi giới thiệu!' }]}>
                            <Autocomplete
                                width="100%"
                                disabled={this.state.comeFrom !== "1"}
                                dataSource={this.state.lstCdFrom}
                                handleSearch={this.loadDiagnostic.bind(this)}
                                getItem={(key, value) => { this.setState({ cdFrom: key, cdFromName: value }); this.setForm({ cdFrom: key }) }}
                                value={this.state.cdFrom}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Tên nơi chuyển</label>
                        <Form.Item style={{ flex: 3 }} name="recommend" rules={[{ required: this.state.comeFrom === "1", message: "Nhập tên cơ quan y tế!" }]}>
                            <Autocomplete
                                disabled={this.state.comeFrom !== "1"}
                                width='100%'
                                dataSource={this.state.lstRecommend}
                                handleSearch={this.loadHospital.bind(this)}
                                getItem={(key, value) => { this.setState({ recommend: key }); this.setForm({ recommend: key }) }}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Đối tượng</label>
                        <Form.Item name="subject" rules={[{ required: true, message: 'Vui lòng chọn đối tượng!' }]}>
                            <Select value={this.state.subject}
                                onChange={(value) => {
                                    this.setState({
                                        subject: value,
                                        cardNumber: '',
                                        dateExpired: [null, null],
                                        cardAddress: '',
                                        route: false,
                                    });
                                    this.loadThongTinBaoHiem(value);
                                    this.setForm({
                                        subject: value,
                                        cardNumber: '',
                                        dateExpired: [null, null],
                                        cardAddress: '',
                                    })
                                }}>
                                {this.state.lstSubject.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Số thẻ BHYT</label>
                        <Form.Item name="cardNumber" rules={[{ required: this.state.subject === 1, min: 12, message: 'Vui lòng nhập từ 12 ký tự thẻ !' }]}>
                            <Input id="cardNumber" disabled={this.state.subject !== 1} value={this.state.cardNumber} maxLength="20" placeholder="Nhập số thẻ"
                                onChange={(e) => { this.setState({ cardNumber: e.target.value }); this.setForm({ cardNumber: e.target.value }) }} />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Thời hạn thẻ</label>
                        <Form.Item name="dateExpired" rules={[{ required: this.state.subject === 1, message: 'Vui lòng chọn ngày hết hạn !' }]}>
                            <RangePicker allowClear={false} value={this.state.dateExpired} disabled={this.state.subject !== 1} format={'DD-MM-YYYY'} placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                onChange={(date) => { this.setState({ dateExpired: date }); this.setForm({ dateExpired: date }) }} />
                        </Form.Item>
                    </div>
                    {/* <div className="col-md-6 mb-2">
                        <label>KCB</label>
                        <Form.Item rules={[{ required: this.state.subject === 1 }]}>
                            <Input disabled={true} value={this.state.cardCode} placeholder="Mã" onChange={(e) => { this.setState({ cardCode: e.target.value }) }} />
                        </Form.Item>
                    </div> */}
                    <div className="col-md-6 mb-2">
                        <label>BV đăng ký</label>
                        <Form.Item name="cardAddress" rules={[{ required: this.state.subject === 1, message: 'Vui lòng chọn bệnh viện!' }]}>
                            <Autocomplete
                                width="100%"
                                dataSource={this.state.lstcardAddress}
                                handleSearch={this.loadKCB.bind(this)}
                                getItem={(key, value) => { this.setState({ cardCode: key, cardAddress: key, cardAddressLabel: value }); this.setForm({ cardAddress: key }) }}
                                value={this.state.cardAddress}
                                disabled={this.state.subject !== 1}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Đúng tuyến</label>
                        <Form.Item rules={[{ required: this.state.subject === 1 }]}>
                            <Checkbox disabled={this.state.subject !== 1} checked={this.state.route} onChange={(e) => { this.setState({ route: e.target.checked }) }}></Checkbox>
                        </Form.Item>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
