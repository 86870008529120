import { Collapse, Drawer, Menu, Dropdown, Popconfirm } from 'antd';
import moment from 'moment';
import React, { Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import * as SystemConfig from '../../../configure/systemConfig';
import CreateUpdate from './createUpdate';
import MedicineList from './medicineList';
import CreateMedicineSample from "./medicineSample";
import * as MedicineService from '../../../services/medicineService';
import * as Actions from '../../../libs/actions';
import Notify, { NotifyStatus } from '../../../components/notify';

const { Panel } = Collapse;

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
    }));
    // data cần có tenBenhNhan, maDon (nếu có), maDichVu (ID dịch vụ khám), maQuanLy, maKhoa, maPhong, maBenhNhan. Data được truyền từ màn hình khám bệnh
    const [data, setData] = useState(props.data ?? [
        // {
        //     tenBenhNhan: "",
        //     maBenhNhan: "",
        //     maDichVu: 0,
        //     tenDichVu: "",
        //     maKhoa: "",
        //     maPhong: "",
        //     maQuanLy: "",
        //     maDon: null,
        // }
    ]);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [listDonThuoc, setListDonThuoc] = useState([]);
    const [isDonNgoai, setIsDonNgoai] = useState();
    const [visibleCreate, setVisibleCreate] = useState();
    const [isUpdate, setIsUpdate] = useState();
    const [visibleCreateSample, setVisibleCreateSample] = useState(false);
    const [selectedMedicineList, setSelectedMedicineList] = useState([]);
    const [selectedPrescription, setSelectedPrescription] = useState([]);

    const _notify = useRef();

    useEffect(() => {
        setData(props.data);
        if (props.data?.maQuanLy) {
            getDanhSachDonThuoc(props.data.maQuanLy, props.data.maDichVu);
        }
    }, []);

    const handleOpenCreateUpdate = (donNgoai, isUpdate = false) => {
        setIsDonNgoai(donNgoai === "donNgoai");
        setIsUpdate(isUpdate);
        if (!isUpdate) {
            let dataDonThuoc = data;
            data.maDon = null;
            setSelectedPrescription(dataDonThuoc);
        }
        setVisibleCreate(true);
        forceUpdate();
    }

    const handleCancel = () => {
        setVisibleCreate(false);
        setVisibleCreateSample(false);
        setIsDonNgoai(false);
        setIsUpdate(false);
        setSelectedPrescription([]);
        getDanhSachDonThuoc(props.data.maQuanLy, props.data.maDichVu);
    }

    const rowOptions = (record) => {
        return (
            <Menu
                items={[
                    {
                        label:
                            <Popconfirm
                                title="Chắc chắn muốn xóa?"
                                onConfirm={() => handleDelete(record)}
                                okText="Đồng ý"
                                cancelText="Hủy"
                            >
                                <a href="#"><i className='fas fa-trash-alt mr-2' />Xóa đơn thuốc</a>
                            </Popconfirm>
                        ,
                        key: 'delete',
                    },
                    {
                        label:
                            <a href="#" onClick={(e) => {
                                setVisibleCreateSample(true);
                                setSelectedMedicineList(record);
                            }}><i className='fas fa-file-alt mr-2' />Đặt làm đơn thuốc mẫu</a>,
                        key: 'createSample',
                    },
                ]}
            />
        )
    }

    const getDanhSachDonThuoc = (maQuanLy, maDichVu) => {
        MedicineService.getDanhSachDonThuoc(maQuanLy, maDichVu).then(result => {
            if (result.isSuccess) {
                setListDonThuoc(result.data.items);
            }
        }).catch(err => {
        }).finally(() => {
            forceUpdate();
        })
    }

    const handleDelete = (dataRecord) => {
        Actions.setLoading(true);
        _notify.current.Close();
        MedicineService.deleteDonThuoc(dataRecord.maDon).then(result => {
            if (result.isSuccess) {
                _notify.current.Show("Xóa đơn thuốc thành công", NotifyStatus.Success);
                getDanhSachDonThuoc(props.data.maQuanLy, props.data.maDichVu);
            } else {
                _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const handlePrint = (maDonThuoc) => {
        const data = {
            presciptionId: maDonThuoc,
            //printTemplateId: null,
            isDuoc: false,
            serviceId: props.data.maDichVu,
        }
        Actions.setLoading(true);
        _notify.current.Close();
        MedicineService.printDonThuoc(data).then(result => {
            if (result.isSuccess) {
                Actions.DoPrint(result.data);
            }
            else {
                _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(() => {
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    return (
        <Fragment>
            <div className="h-100 w-100 overflow-auto">
                <Notify ref={_notify} />
                <div className='d-flex'>
                    <button id="donthuoc" className={`btn btn-light text-color`}
                        onClick={() => { handleOpenCreateUpdate() }}>
                        <i className="fas fa-plus-circle mr-1" />
                        Tạo đơn thuốc
                    </button>
                    <button id="donngoai" className={`btn btn-light ml-2 text-success`}
                        onClick={() => { handleOpenCreateUpdate("donNgoai") }}>
                        <i className="fas fa-plus-circle mr-1" />
                        Tạo đơn thuốc ngoài
                    </button>
                </div>
                <Collapse className='mt-2' defaultActiveKey={listDonThuoc.map((x, index) => index)} bordered={false}
                    expandIcon={({ isActive }) => <i style={{ fontSize: 18 }} className={`text-white fas fa-angle-${isActive ? "down" : "right"}`} />}>
                    {
                        listDonThuoc && listDonThuoc.map((donThuoc, index) => {
                            return (
                                <Panel
                                    className={`${donThuoc.donNgoai ? "bg-success" : "backgroundColorThemes"}`}
                                    key={index}
                                    header={
                                        <div className='d-flex justify-content-between align-items-center text-white w-100'>
                                            <div className='d-flex flex-column'>
                                                <label>Mã đơn: {donThuoc.maDon && donThuoc.maDon}</label>
                                                <label>{donThuoc.serviceName ?? ""}</label>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                {donThuoc.doctorName && <label>{donThuoc.doctorName}</label>}
                                                <label>Cập nhật sau cùng: {donThuoc.ngayCapNhat && moment(donThuoc.ngayCapNhat).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                                            </div>
                                            <div>
                                                <label style={{ width: 110, textAlign: "right" }} className='mital-title'><u>đ</u> {donThuoc.tongTien && donThuoc.tongTien.toLocaleString("en-US")}</label>
                                                <i className='ml-3 fas fa-print'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePrint(donThuoc.maDon);
                                                    }}
                                                />
                                                <i className='ml-3 fas fa-pencil-alt'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelectedPrescription(
                                                            {...donThuoc,
                                                                tenBenhNhan: data.tenBenhNhan,
                                                                maBenhNhan: data.maBenhNhan,
                                                                maDichVu: data.maDichVu,
                                                                tenDichVu: data.tenDichVu,
                                                                maKhoa: data.maKhoa,
                                                                maPhong: data.maPhong,
                                                            });
                                                        handleOpenCreateUpdate(donThuoc.donNgoai ? "donNgoai" : "", true);
                                                    }}
                                                />
                                                <Dropdown className='ml-3' overlay={rowOptions(donThuoc)} trigger={['click']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}>
                                                    <a className="ant-dropdown-link"><i className='fas fa-ellipsis-v' style={{ width: 15, height: 15, }} /></a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    }>
                                    <MedicineList
                                        data={donThuoc.danhSachThuoc}
                                        maDon={donThuoc.maDon}
                                        maDichVu={props.data.maDichVu}
                                        canDelete={false}
                                        handleGetNewData={() => { handleCancel() }} />
                                </Panel>
                            )
                        })
                    }
                </Collapse >
            </div>
            <Drawer
                className='custom-ant-drawer'
                closable={false}
                width={1000}
                placement="right"
                onClose={() => handleCancel()}
                visible={visibleCreate}
                destroyOnClose={true}
                maskClosable={false}
            >
                <CreateUpdate
                    data={selectedPrescription}
                    isUpdate={isUpdate}
                    isDonNgoai={isDonNgoai}
                    onClose={() => handleCancel()}
                />
            </Drawer>
            <Drawer
                className='custom-ant-drawer'
                closable={false}
                width={1000}
                placement="right"
                onClose={() => handleCancel()}
                visible={visibleCreateSample}
                destroyOnClose={true}
                maskClosable={false}
            >
                <CreateMedicineSample
                    // data cần có maDon (mã đơn thuốc), dichVu (tên dịch vụ khám)
                    // và danh sách thuốc của đơn
                    data={selectedMedicineList}
                    tenDichVu={props.data.tenDichVu}
                    maDichVu={props.data.maDichVu}
                    onClose={() => handleCancel()}
                />
            </Drawer>
        </Fragment >
    );
})