
import * as ApiCaller from '../libs/httpRequests';
const prefix='RegisterMedical';
export function RegisterMedical() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet()
    })
}
export function CreatePatient(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreatePatient`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};