import React, { Fragment, useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import moment from "moment";
import { Button, notification, Checkbox, Modal, Tabs } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as ExamResultService from '../../../services/ExamResultServices';
import * as PatientService from '../../../services/patientService';
import * as TestService from '../../../services/lisService';
import { ValidationCustom } from '../../../commons/validationCustom';
import { sendMessageTypeEnum } from '../../../commons/enums/sendMessageTypeEnum';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as AppointmentService from '../../../services/appointmentService';
import * as CoporationServices from "../../../services/CorporationServices";
import HeaderModal from '../../../configureHIS/common/headerModal';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
      reset: () => {
        
      },
    }));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef(null);
    const _hiddenLink = useRef();
    //#region khai báo state
    const [listFile, setListFile] = useState([]);
    const [resultInfo, setResultInfo] = useState({});
    const [patientInfo, setPatientInfo] = useState({});
    const [sendMessageTypes, setSendMessageTypes] = useState([]);
    const [listSendMessageTypes, setListSendMessageTypes] = useState([]);
    const [fileUrlBlob, setFileUrlBlob] = useState('');
    const [detail, setDetail] = useState({});
    const [visiblePreviewFile, setVisiblePreviewFile] = useState(false);
    const [chkEmail, setChkEmail] = useState(false);
    const [chkSms, setChkSms] = useState(false);
    const [chkZalo, setChkZalo] = useState(false);
    const [chkTT, setChkTT] = useState(false);
    const [chkPK, setChkPK] = useState(false);
    const [idcoporation, setIdCoporation] = useState(props.resultInfo?.coporator);
    const [coporation, setCoporation] = useState({});
    const [isCoporation, setIsCoporation] = useState(false);

    const reset = () => {
        setListFile([]);
        // setResultInfo({});
        setSendMessageTypes([]);
        setChkEmail(false);
        setChkSms(false);
        setChkZalo(false);
        setChkTT(false);
        setChkPK(false);
        setIsCoporation(false);
        setCoporation({});
        setIdCoporation('');
        notiRef && notiRef.current.Close();
        validator && validator.current.hideMessages();
        forceUpdate();
    }

    const resetChkBox = () => {
        setSendMessageTypes([]);
        setChkEmail(false);
        setChkSms(false);
        setChkZalo(false);
        setChkTT(false);
        setChkPK(false);
    }

    //#endregion
    useEffect(() => {
        getCoporationDetail();
    }, [idcoporation])

    useEffect(() => {
        if (!props.resultInfo) 
            notiRef.current.Show("Truyền thiếu thông tin", NotifyStatus.Warning)
        setResultInfo(props.resultInfo);
        getPatientInfo(props.resultInfo?.patientID);
        setIdCoporation(props.resultInfo?.coporator);
    }, [props.resultInfo]);

    useEffect(() => {
        setListFile(props.listFile);
    }, [props.listFile]);
    
    useEffect(() => {
        getHinhThucTraKetQua(props.resultInfo?.maQL)
    }, [props.resultInfo.maQL]);
    
    const validateSend = () => {
        if (!sendMessageTypes || sendMessageTypes.length == 0)
        {
            notiRef.current.Show("Chưa chọn hình thức gửi trả kết quả.", NotifyStatus.Warning);
            return false;
        }
        if (listFile.filter(x => x.checked).length == 0) {
            notiRef.current.Show("Chưa chọn file để gửi.", NotifyStatus.Warning);
            return false;
        }

        if(isCoporation && !coporation){
            notiRef.current.Show("Không có thông tin nguồn khách hàng.", NotifyStatus.Warning);
            return false;
        }
        return true;
    }

    const submitSend = () => {
        if (!validateSend()) return;
        Actions.setLoading(true);
        const model = {
            sId: resultInfo.sid,
            // siteId: resultInfo.siteid,
            maQL: resultInfo?.maQL,
            patientId: resultInfo?.patientID,
            sendMessageTypes: sendMessageTypes,
            lisFiles: listFile.filter(x=>x.checked),
            isCoporation : isCoporation,
            coporationId : idcoporation,
        }
        if (sendMessageTypes.indexOf(sendMessageTypeEnum.Sms) > -1 || sendMessageTypes.indexOf(sendMessageTypeEnum.Zalo) > -1) {
            model.lisFiles.forEach(element => {
                sendFileToWebPatient(element);
            });
        }
        ExamResultService.SendResult(model).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thành công" });
                props.onCancel(true);
                reset();
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Warning)
            else notiRef.current.Show(err, NotifyStatus.Warning)
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const sendFileToWebPatient = (row) => {
        ExamResultService.sendFileToWebPatient(row).then(result => {
            if (result.isSuccess) {
                // row.urlWebPatient = result.data;
                // this.setState({ 
                //     listUrlWebFileIds: [...this.state.listUrlWebFileIds, row.uuid]
                //     });
                // notification.success({ message: "Thành công" });
            }
            else {
                // notification.error({ message: "Lỗi " + result.error.messageText});
            }
        }).catch(err => {
            // if (err.error && err.error.messageText)
            //     notification.error({ message: "Lỗi " + err.error.messageText});
            // else 
            //     notification.error({ message: "Lỗi " + err});
        }).finally(() => {
        });
    }

    const getCoporationDetail = () => {
        CoporationServices.CoporationDetail(idcoporation).then(result => {
            if (result.isSuccess) {
                setCoporation(result.item)
            }
            else {
                // notification.error({ message: "Lỗi " + result.error.messageText});
            }
        }).catch(err => {
            // if (err.error && err.error.messageText)
            //     notification.error({ message: "Lỗi " + err.error.messageText});
            // else 
            //     notification.error({ message: "Lỗi " + err});
        })
    }

    const viewResultPdf = (data) => {
        Actions.setLoading(true);
        TestService.DownLoadLisFile(data.uuid) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    setFileUrlBlob(url);
                    setVisiblePreviewFile(true);
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    const downloadFile = () => {
        TestService.DownLoadLisFile(detail.uuid).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "KetQuaXetNgiem.pdf";
                _hiddenLink.current.click();
            })
        }).catch(err => {
        })
    }
    const getPatientInfo = (id) => {
      PatientService.GetPatientDetail(id).then(result => {
        if (result.isSuccess) {
            setPatientInfo(result.data);
        //   notiRef && notiRef.current.Close();
        }
        else {
          notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        }
      }).catch(err => {
        if (err.error && err.error.messageText)
          notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
        else notiRef.current.Show(err, NotifyStatus.Warning);
      }).finally(() => {
          Actions.setLoading(false);
      })
    }

    const getHinhThucTraKetQua = (managermentId = props.resultInfo?.maQL, curTab) => {
        AppointmentService.GetHinhThucTraKetQua(managermentId).then(res => {
            if (res.isSuccess) {
                if (res.data) {
                    let t = sendMessageTypes;
                    res.data.map(x => {
                        if(x.autorecord === true)
                            t.push(x.id);
                    })
                    setSendMessageTypes(t);
                    setListSendMessageTypes(res.data);
                    // if (curTab == 2) setSendMessageTypes(res.data.map(x => ({...x, autorecord: false})));
                    // else setSendMessageTypes(res.data);
                }
            }
        }).catch(() => { })
    }

    
    const changeSendMessageTypes = (checked, value) => {
        let t = sendMessageTypes;
        if (checked) {
            t.push(value);
        }
        else {
            t = sendMessageTypes.filter(x => x != value);
        }
        setSendMessageTypes(t);
        forceUpdate();
    }


    const handleChangeCheck = (isCheck, index) => {
        let list = sendMessageTypes;
        list[index].autorecord = isCheck;
        setSendMessageTypes(list);
        forceUpdate();
    }

    const getContactInfo = (key, isCop = false) => {
        if (key == "email") return isCop ? coporation?.email : patientInfo.email;
        else if (key == "sms" || key == "zalo") return isCop ? coporation?.dienThoai : patientInfo.dienThoai;
    }
    
    return (
        <React.Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <HeaderModal title={`Gửi kết quả - ${resultInfo?.sid} - ${patientInfo?.hoTen}`} onClose={() => {  reset(); props.onCancel(false); }} />
            <Notify ref={notiRef} />
            <div className='row mt-2'>
                <div className='col-12 d-flex flex-column'>
                    <lable className="mita-title">Đính kèm tệp tin</lable>
                    {listFile.map((row, index) => (
                        <div>
                            <Checkbox className="mr-2" onChange={e => { row.checked = e.target.checked; }} />
                            <i className='fas fa-file-pdf mr-2'/>
                            <a href="#" className='mr-2' style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setDetail(row);
                                viewResultPdf(row);
                            }}>{row.title}</a>
                            <label className='mr-2'>{moment(row.datecreate).format(SystemConfig.FORMAT_DATE_TIME)}</label>
                            <label className='mr-2'>( {row.datesend ? "Đã gửi: " + moment(row.datesend).format(SystemConfig.FORMAT_DATE_TIME) : 'Chưa gửi kết quả'} )</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <Tabs defaultActiveKey="1" onChange={(e) => { setIsCoporation(Number(e) === 2 ) ; resetChkBox(); getHinhThucTraKetQua(props.resultInfo?.maQL, Number(e)); }} destroyInactiveTabPane>
                        <Tabs.TabPane tab="Khách hàng" key="1">
                            <div className='row'>
                                {
                                    listSendMessageTypes && listSendMessageTypes.map((x, index) => {
                                        return (
                                            <div className="col-md-3 d-flex flex-column" key={x.key}>
                                                <Checkbox checked={sendMessageTypes.indexOf(x.id) > -1}
                                                    onChange={(e) => { changeSendMessageTypes(e.target.checked, x.id); }}
                                                >{x.value}</Checkbox>
                                                <lable className="mita-title">{getContactInfo(x.key)}</lable>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Nguồn khách hàng" key="2">
                            <div className='row'>
                                {
                                    listSendMessageTypes && listSendMessageTypes.map((x, index) => {
                                        return (
                                            <div className="col-md-3 d-flex flex-column" key={x.key}>
                                                <Checkbox checked={sendMessageTypes.indexOf(x.id) > -1}
                                                    onChange={(e) => { changeSendMessageTypes(e.target.checked, x.id); }}
                                                >{x.value}</Checkbox>
                                                <lable className="mita-title">{getContactInfo(x.key, true)}</lable>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                
            </div>
            <div className='row mt-2'>
                <div className='col-12 d-flex justify-content-end'>
                    <button className='btn btn-color mr-2' onClick={submitSend} >
                        <i className='fa fa-paper-plane mr-2' /> Gửi
                    </button>
                    <button className='btn btn-danger' onClick={() => {props.onCancel(); reset();}} >
                        <i className='fa fa-times-circle mr-2'/> Đóng
                    </button>
                </div>
            </div>
                
            {visiblePreviewFile &&
                <Modal maskClosable={true}
                    width={1000}
                    visible={visiblePreviewFile}
                    onCancel={() => {setVisiblePreviewFile(false);}}
                    footer={null}>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <div className="list-button-preview">
                            <Button className="mr-2" onClick={() => {
                                downloadFile()
                            }}><i className="fa fa-download"></i></Button>
                            <Button onClick={() =>{setVisiblePreviewFile(false);}}>Đóng</Button>
                        </div>
                        <embed src={`${fileUrlBlob}#toolbar=0`}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
})