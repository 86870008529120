import React, { Fragment, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../common/headerModal';
import { Alert, InputNumber, Modal, Select } from 'antd';
import { BUTTON } from '../../common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import { isNullOrEmpty } from '../../../libs/util';
import * as VPService from '../services/VPService';
import { setLoading } from '../../../libs/actions';
import CoporationLinked from '../screens/coporationLinked';

let taskDelay = null;

function UpdateDiscount(props) {
    const [data, setData] = useState({});
    const [updateMultiple, setUpdateMultiple] = useState(props.updateMultiple ?? false);
    const _notify = useRef();
    const [showCoporationLinked, setShowCoporationLinked] = useState(false);
    const [showServiceDetail, setShowServiceDetail] = useState(false);

    useEffect(() => {
        setData(props.data);

    }, [props.data])

    useEffect(() => {
        setUpdateMultiple(props.updateMultiple);
    }, [props.updateMultiple])

    const onChangeData = (field, newData) => {
        // if (taskDelay) clearTimeout(taskDelay);
        // taskDelay = setTimeout(() => {
        let curData = { ...data };
        curData[field] = newData;
        setData(curData);
        // }, 500);
    }

    const onUpdate = () => {
        if (isNullOrEmpty(data.discounttype) && !isNullOrEmpty(data.discount)) {
            showError("Vui lòng chọn hình thức chiết khấu.");
            return;
        }
        if (isNullOrEmpty(data.discount) && !isNullOrEmpty(data.discounttype)) {
            showError(`Vui lòng chọn ${data.discounttype == "percent" ? "phần trăm" : "số tiền"} chiết khấu.`);
            return;
        }
        if (data.discounttype == "percent" && data.discount > 100) {
            showError("Vui lòng nhập phần trăm chiết khấu không vượt quá 100%.");
            return;
        }
        _notify && _notify.current && _notify.current.Close();
        setLoading(true);
        const dataUpdate = {
            bangGia: props.bangGia,
            updateMultiple,
            ...data
        }
        VPService.UpdateServiceDiscount(dataUpdate).then(res => {
            debugger
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Cập nhật thành công.", NotifyStatus.Success);
                props.onSuccess();
            } else {
                showError(res.err?.msgString ?? res.error?.messageText ?? "Đã xảy ra lỗi. Vui lòng thử lại sau.", NotifyStatus.Error);
            }
        }).catch(err => {
            showError(err.err?.msgString ?? err.error?.messageText ?? "Đã xảy ra lỗi.", NotifyStatus.Error);
        }).finally(() => setLoading(false))
    }

    const showError = (noti, type = NotifyStatus.Warning) => {
        _notify && _notify.current && _notify.current.Show(noti, type);
    }

    return (
        <div className='list-page'>
            <HeaderModal title="Cập nhật chiết khấu dịch vụ" onClose={() => props.onClose()} />
            <Notify ref={_notify} />
            <div className='list-page-body d-flex flex-column flex-wrap' style={{ gap: 10 }}>
                <div className='w-100 mt-1'>
                    <label className='mita-title'>Bảng giá:</label>
                    <label className='mita-value'>{props.bangGia.toLowerCase() == "default" ? "Mặc định" : props.bangGia}</label>
                </div>
                {!updateMultiple &&
                    <Fragment>
                        <div className='w-100 mt-1'>
                            <label className='mita-title'>Mã dịch vụ:</label>
                            <label className='mita-value'>{data.ma}</label>
                        </div>
                        <div className='w-100 mt-1'>
                            <label className='mita-title'>Tên dịch vụ:</label>
                            <label className='mita-value'>{data.ten}</label>
                        </div>
                        <div className='w-100 mt-1'>
                            <label className='mita-title'>Giá dịch vụ:</label>
                            <label className='mita-value'>{(data.gia_th ?? "").toLocaleString('en-US')}</label>
                        </div>
                    </Fragment>
                }
                <div className='w-100 mt-1'>
                    <label className='mita-title'>Hình thức chiết khấu</label>
                    <Select
                        className='w-100' allowClear
                        placeholder="Chọn hình thức chiết khấu"
                        value={data.discounttype}
                        onChange={(e) => {
                            onChangeData("discounttype", e);
                        }}
                    >
                        <Select.Option key="percent" value="percent">Phần trăm</Select.Option>
                        <Select.Option key="amount" value="amount">Số tiền</Select.Option>
                    </Select>
                </div>
                <div className='w-100 mt-1'>
                    <label className='mita-title'>{data.discounttype == "percent" ? "Phần trăm" : data.discounttype == "amount" ? "Số tiền" : "Tỷ lệ"} chiết khấu</label>
                    <InputNumber
                        min={0} max={data.discounttype == "percent" ? 100 : (data.gia_th ?? 9999999999)}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        className='w-100' value={data.discount}
                        onChange={(e) => onChangeData("discount", e)}
                        placeholder='Nhập tỷ lệ chiết khấu'>
                    </InputNumber>
                </div>
                {
                    !updateMultiple && data.discount && data.discounttype &&
                    <div className='w-100 mt-1'>
                        <label className='mita-title'>Giá dịch vụ sau chiết khấu:</label>
                        <label className='text-danger'>
                            {
                                data.gia_th && data.discount ?
                                    data.discounttype == "percent" ? (data.gia_th * (1 - (data.discount / 100))).toLocaleString('en-US')
                                        : (data.gia_th - data.discount).toLocaleString('en-US')
                                    : ""
                            }
                        </label>
                    </div>
                }
            </div>
            <div className='list-page-footer'>
                <div className='mt-2'>
                    <Alert type='warning' showIcon message="Lưu ý"
                        description={<span>Dịch vụ trong bảng giá sẽ được chiết khấu theo cấu hình bên trên,
                            KHÔNG ĐƯỢC chiết khấu theo nguồn khách hàng liệt kê
                            <b><a href="#" className="ml-1" onClick={() => setShowCoporationLinked(true)}>tại đây</a></b>.</span>}
                    />
                </div>
                {updateMultiple && data.discounttype == "amount" &&
                    <div className='mt-2'>
                        <Alert type='info' showIcon message="Thông tin"
                            description={`Bấm cập nhật, bạn hiểu và đồng ý rằng, các dịch vụ trong bảng giá có giá thấp hơn số tiền chiết khấu đã nhập bên trên,
                            sẽ được chiết khấu tối đa giá tiền dịch vụ.`}
                        />
                    </div>}
                <div className='d-flex justify-content-end mt-2'>
                    <button className={`${BUTTON.THEME}`} onClick={() => onUpdate()}><i className='fas fa-save mr-1' />Cập nhật</button>
                    <button className={`${BUTTON.DANGER} ml-2`} onClick={() => props.onClose()}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
                </div>
            </div>
            <Modal open={showCoporationLinked}
                closable={false}
                destroyOnClose
                width={800}>
                <CoporationLinked
                    bangGia={props.bangGia}
                    onClose={() => { setShowCoporationLinked(false) }} />
            </Modal>
        </div>
    );
}

export default UpdateDiscount;