import React, { Component } from 'react'
import { connect } from 'react-redux';
import { DatePicker, Popconfirm, Popover, notification, Modal, Checkbox, Input, Table, Tag, Tooltip, Select, InputNumber, Menu,Drawer} from 'antd';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as InvoiceService from '../../../services/invoiceService';
import * as AppointmentService from '../../../services/appointmentService';
import * as PrintService from '../../../services/printService';
import * as MedicineService from '../../../services/medicineService';
import * as ConfirmOrderService from '../../../services/confirmOrderService';
import * as StatisticService from '../../../services/statisticService';
import Speaker from '../../../components/speaker';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import Report from './report';
import { getVouchers } from '../../promotion/PromotionServices';
import { getPromotions } from '../../promotion/PromotionServices';
import * as UsersService from '../../../services/usersService';
import * as ConfigService from '../../../services/configService';
import CustomerVATInfo from '../../vatinvoice/customer';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import PatientInfo from '../../../configureHIS/common/patientinfo';
import AppliedVoucher from './appliedVoucher';
import { FEATURE, LOYALTY_TYPE, PAYMENT_SERVICE, SERVICE_TYPE } from '../../../configureHIS/common/constants';
import {FeatureDeactive} from '../../../configure/featureConfig';
import { FORMAT_DATE_TIME, FORMAT_DATE_TIME_FULL, FORMAT_TIME_DATE_FULL, PAYMENT_WITHOUT_MEDICINE } from '../../../configure/systemConfig';
import OTPConfirm from './OTPConfirm';
import { FORMAT_DATE } from "../../../configure/systemConfig";
import Handlebars from 'handlebars';
import { isNullOrEmpty } from '../../../libs/util';
import handlebarHelper from "../../../libs/handlebarHelper";

const { RangePicker } = DatePicker;
class PayMent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            key: '',
            isPaid: false,
            type: this.props.payMedicineAtCashDesk ? 1 : "",
            lstPatient: [],
            lstServicesPatient: [],
            lstServiceWithoutVoucher: [],
            patient: {},
            giamgia: 0,
            tongthanhtoan: 0,
            tongtien: 0,
            hiddenLuu: false,
            hinhthucthanhtoan: 1,
            hinhthuchoantra: 1,
            loaithanhtoan: 1,
            sohoadon: 0,
            lstBangKe: [],
            ghichu: '',
            lstMedicOld: [],
            modalTongHop: false,
            banle: false,
            vattu: false,
            visible: false,
            visiblePay: false,
            lstPaymentDetail: [],
            paymentDetail: {},
            reportDetail: {},
            openDate: true,
            optionDiscount: 1,//1: %, 2: tiềnvoucherServiceId
            tienGiam: 0, vatinvoice: false, payconfirm: false, tonggiamtru : 0,
            vsbSetVoucher: false,
            listVouchers: [],
            selectedVoucher: "",
            selectedVoucherService: "",
            loyal: '',
            campaignid: 0,
            isLoyalty: false,
            isMultisite: false, listSite: [],
            warningVoucher: null,
            listServiceUseVoucher: [],
            equipment: false,
            barcode: "",
            thoiGianTraKQ: 0,
            disableTT: true,
            isAdmin: false,
            disableBtn: false, showBtn: false,
            checkvat: false, checkboxVAT: true,
            vsbCustomerVATInfo: false,
            listRefunds: [],
            lstLoaiTT: [],
            lstLoaiTT_hoantra: [],
            lstLoaiTT_congno: [],
            lstLoaiTT_the : [],
            trangThaiThanhToan: 1,
            disablePayment: false,
            ghichupromo : null,
            namepromo:null,
            listVoucherUsed: [],
            checkTestResult: 0,
            visiblePayment: false,
            showTestResult:false,
            visiblePromotion: false,
            voucherid : 0,
            billDetail : {},
            voucherServiceId : 0,
            listServiceUseVoucherNew : [],
            selectedPromotion : [],
            idCongno : 4,
            idTheTraTruoc : 5,
            loyaltyType : LOYALTY_TYPE.VOUCHER,
            promotionid : null,
            lstPaymentTypes: [],
            OTPConfirm : false,
            popoverOTP : false,
            billRemark : '',
            reviewBillRemark : '',
            sidbybill : '',
        };
    }
    notifyUpdate() {
        var loading = false;
        // Nhận notify từ nhà thuốc và cập nhật danh sách cần thanh toán
        if (Object.keys(this.state.patient).length !== 0
        && this.state.patient.constructor === Object) {
            InvoiceService.ListPatientPayment(this.state.key, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'), this.state.isPaid, this.state.type, this.props.siteid.toString(), this.state.trangThaiThanhToan).then(result => {
                if (result.isSuccess) {
                    this.setState({ lstPatient: result.data });
                    let currentPatient = this.state.patient;
                    let foundPatientSelected = this.state.lstPatient.find(x => x.mabn === currentPatient.mabn && x.maql === currentPatient.maql);
                    if (foundPatientSelected !== undefined) {
                        currentPatient.listInvoiceId = foundPatientSelected.listInvoiceId;
                        this.setState({
                            lstServicesPatient: [],
                            lstMedicOld: [],
                            patient: currentPatient,
                        }, () => {  this.LoadDetailServices(foundPatientSelected, loading); });
                        // notification.warning({ message: "Danh mục thuốc thanh toán được cập nhật từ nhà thuốc" });
                    }
                }
            }).catch(err => { })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.GetListPatientPayment()
        }
    }


    componentWillUnmount() {
        try {
            window.removeEventListener('resize')
        } catch (error) {
            console.log(error);
        }
    }

    SetHeightTable() {
        try {
            let report = document.getElementById('report-register');
            let height = window.getComputedStyle(report).height.replace('px', '');
            let tabHeader = document.querySelector('#table-list-register')
            tabHeader.style.height = `calc(100% - ${Number(height) + 75}px)`;
        } catch (error) {
            console.log(error);
        }
    }
    componentDidMount() {
        this.GetListPatientPayment();
        this.getPaymentMethod();
        this.getAllPaymentMethod();
        this.getPaymentMethodRefund();
        this.SetHeightTable();
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
                if (result.item == true) {
                    this.GetListSite(); 
                }
            }
        });
        window.addEventListener('resize', (size, e) => {
            this.SetHeightTable();
        })
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    GetListPatientPayment(loading = true) {
        Actions.setLoading(loading);
        InvoiceService.ListPatientPayment(this.state.key, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'), this.state.isPaid, this.state.type, this.props.siteid.toString(), this.state.trangThaiThanhToan).then(result => {
            if (result.isSuccess) {
                this.setState({ lstPatient: result.data });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    GetVATPayByMaql(maql) {
        Actions.setLoading(true);
        AppointmentService.GetVATPayconfirmByMaql(maql, this.state.isPaid).then(result => {
            if (result.isSuccess) {
                this.setState({
                    vatinvoice: result.returnObject.vatinvoice,
                    payconfirm: result.returnObject.payconfirm
                })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    LoadDetailServices(x, loading = true) {
        Actions.setLoading(loading);
        x.listInvoiceId.forEach(row => {
            if (row.loai === "1") {
                const data = new FormData();
                data.append('ngay', moment(row.ngay).format('YYYY/MM/DD'));
                data.append('maql', row.maql);
                let billDetail = {
                    'ngay' : moment(row.ngay).format('YYYY/MM/DD'),
                    'maql' : row.maql
                }
                this.setState({ billDetail : billDetail})
                InvoiceService.GetDetailInvoice(data).then(result => {
                    if (result.isSuccess) {
                        let data = result.data.map(x => ({
                            mavp: x.mavp,
                            tenvp: x.tenvp,
                            soluong: x.soluong,
                            dongia: x.dongia,
                            dongiakm: x.giakm,
                            thanhtien: x.thanhtien,
                            type: x.type,//1: gói khám 2: dịch vụ
                            loai: x.type === 1 ? '0' : '1',
                            giabh: x.giabh,
                            labService: x.includeLabSer, // Là dịch vụ thuộc xét nghiệm
                        }))
                        let promotionInfo = result.returnObject && result.returnObject.promotionInfo;
                        this.setState({
                            lstServicesPatient: this.state.lstServicesPatient.concat(data),
                            thoiGianTraKQ: result.data.length > 0 ? this.findMaxTime(result.data) : 0,
                        }, () => {
                            this.getPaymentMethod();
                            this.getPaymentMethodRefund();
                            if (promotionInfo) {
                                this.SubmitVoucher(promotionInfo);
                            }
                        })
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", err);
                })
            } else if (row.loai === "2") {
                MedicineService.GetListChiTietHoaDon(moment(row.ngay).format('YYYY/MM/DD'), row.id).then(result => {
                    if (result.isSuccess) {
                        let data = result.data.filter(x => x.soluongthuc > 0).map(x => ({
                            mavp: x.ma,
                            tenvp: x.tenthuoc,
                            soluong: x.soluongthuc,
                            dongia: x.giathucthu,
                            dongiakm: x.giathucthu,
                            thanhtien: x.giathucthu * x.soluongthuc,
                            idPhieu: x.idPhieu,
                            loai: '2',
                            giabh: x.giabh,
                        }))
                        let oldData = [];
                        if (result.data.length > 0) {
                            oldData = result.data.map(x => ({ ...x, ten: x.tenthuoc }));
                            oldData = oldData.filter(x => x.soluongthuc > 0);
                        }
                        this.setState({
                            lstServicesPatient: this.state.lstServicesPatient.concat(data),
                            lstMedicOld: this.state.lstMedicOld.concat(oldData),
                        }, () => { this.getPaymentMethod(); this.getPaymentMethodRefund() })
                    }
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", err);
                })

            }
            else if (row.loai === "3") {
                MedicineService.ThongTinHoaDon(moment(row.ngay).format('YYYY/MM/DD'), row.id, 1, 1000).then(result => {
                    if (result.isSuccess) {
                        let data = result.data.map(x => ({
                            mavp: x.mathuoc,
                            tenvp: x.ten,
                            soluong: x.soluong,
                            dongia: x.dongia,
                            dongiakm: x.giathucthu,
                            thanhtien: x.giathucthu * x.soluong,
                            idPhieu: x.id,
                            loai: '3',
                            giabh: x.giabh,
                        }))
                        this.setState({
                            lstServicesPatient: this.state.lstServicesPatient.concat(data),
                            lstMedicOld: this.state.lstMedicOld.concat(result.data)
                        }, () => { this.getPaymentMethod(); this.getPaymentMethodRefund() })
                    }
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", err);
                })
            }
            else if (row.loai === "4") {
                MedicineService.ThongTinHoaDon(moment(row.ngay).format('YYYY/MM/DD'), row.id, 1, 1000).then(result => {
                    if (result.isSuccess) {
                        let data = result.data.map(x => ({
                            mavp: x.mathuoc,
                            tenvp: x.ten,
                            soluong: x.soluong,
                            dongia: x.dongia,
                            dongiakm: x.giathucthu,
                            thanhtien: x.giathucthu * x.soluong,
                            idPhieu: x.id,
                            loai: '4',
                            giabh: x.giabh,
                        }))
                        this.setState({
                            lstServicesPatient: this.state.lstServicesPatient.concat(data),
                            lstMedicOld: this.state.lstMedicOld.concat(result.data)
                        }, () => { this.getPaymentMethod(); this.getPaymentMethodRefund() })
                    }
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", err);
                })
            }
        });

        //bảng kê
        if (this.state.isPaid === true) {
            let banle = false
            let sohd = "";
            if (x.loaithu === "3") {
                banle = true;
                sohd = x.sohoadon;
            }
            InvoiceService.DanhSachCanHoanTraChiTiet(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'), x.mabn, x.maql, banle, sohd).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstBangKe: result.data,
                        sidxn: result.returnObject.sid,
                        equipment: result.returnObject.equipment,
                        thoiGianTraKQ: result.data.length > 0 ? this.findMaxTime(result.data) : 0
                    })
                    this.getVoucherUsed();
                    this.GetPaymentType(x.sohoadon)
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })

        }
    }

    LoadSummary() {
        InvoiceService.DanhSachCanHoanTraChiTiet(this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'), this.state.patient.mabn, this.state.patient.maql,
            this.state.banle, this.state.sohoadon).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstBangKe: result.data,
                        sidxn: result.returnObject.sid,
                        equipment: result.returnObject.equipment,
                        thoiGianTraKQ: result.data.length > 0 ? this.findMaxTime(result.data) : 0
                    });
                    this.getVoucherUsed();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })
    }
    XuatKhoKhauHaoKhamBenh() {
        InvoiceService.XuatKhoKhauHaoKhamBenh(this.state.patient.maql).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thao tác thành công.' })
                this.LoadSummary();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    PayConfirm() {
        Actions.setLoading(true);
        let patient = this.state.patient;
        let services = {};
        let medicines = [];
        let retails = [];
        let banle = false;
        let vattu = false;
        let vatinvoice = this.state.vatinvoice;
        let totalMoneyWithMedicine = 0;
        let listServiceWithMedicine = this.state.lstServicesPatient.filter(x => x.loai == SERVICE_TYPE.THUOCBANLE.toString() || x.loai == SERVICE_TYPE.THUOCKEDON.toString());
        const tongthanhtoan = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
        if (PAYMENT_WITHOUT_MEDICINE && (this.state.giamgia > 0 || this.state.tienGiam > 0)) {
            totalMoneyWithMedicine = listServiceWithMedicine.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongia), 0);
        }
        const tongbhyt = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.giabh), 0);
        const tongtien = Number((tongthanhtoan - this.getTotalDiscountAmount(tongthanhtoan, tongbhyt, totalMoneyWithMedicine)));
        let lstHTTT = this.state.lstLoaiTT;
        let itemHT = lstHTTT.find(x => x.ma == this.state.hinhthucthanhtoan);
        if (itemHT) {
            itemHT.tongtien = tongtien;
        }
        // if (tongtien != this.state.lstLoaiTT.reduce((tmp, cur) => tmp + (cur.tongtien || 0), 0)) {
        //     notification.warning({ message: 'Vui lòng kiểm tra lại tổng tiền!' })
        //     Actions.setLoading(false);
        //     return;
        // }
        let payFor = this.state.type === "" ? 0 : Number(this.state.type);
        patient.listInvoiceId.forEach(row => {
            if (row.loai === SERVICE_TYPE.DICHVU.toString()) {
                services = {
                    ngay: moment(row.ngay).format('YYYY/MM/DD'),
                    maql: row.maql,
                    hinhthucthanhtoan: this.state.hinhthucthanhtoan,
                    loaithanhtoan: this.state.loaithanhtoan,
                    tientamung: 0,
                    discount: this.state.giamgia,
                    dadongtamung: false,
                    voucher: this.state.selectedVoucher,
                    voucherservice : this.state.selectedVoucherService,
                    loyal: this.state.loyal != "" ? true : false,
                    campaignid: this.state.campaignid,
                    listServiceUseVoucher: this.state.listServiceUseVoucher,
                    listServiceUseVoucherNew: this.state.listServiceUseVoucherNew,
                    tongtien: tongtien,
                    voucherid : this.state.voucherid,
                    voucherserviceid : this.state.voucherServiceId,
                    promotionid : this.state.promotionid,
                    otpConfirm : this.state.OTPConfirm,
                    billRemark : this.state.billRemark
                }
            }
            else if (row.loai === SERVICE_TYPE.THUOCKEDON.toString()) {
                let lstMedic = this.state.lstMedicOld.filter(x => x.idPhieu === row.id);
                for (let item of lstMedic) {
                    item.soluong = +item.soluong;
                }
                services = {
                    ngay: moment(row.ngay).format('YYYY/MM/DD'),
                    maql: row.maql,
                    hinhthucthanhtoan: this.state.hinhthucthanhtoan,
                    loaithanhtoan: this.state.loaithanhtoan,
                    tientamung: 0,
                    discount: this.state.giamgia,
                    dadongtamung: false,
                    voucher: this.state.selectedVoucher,
                    loyal: this.state.loyal != "" ? true : false,
                    campaignid: this.state.campaignid,
                    listServiceUseVoucher: this.state.listServiceUseVoucher,
                    tongtien: tongtien,
                    voucherid : this.state.voucherid,
                    billRemark : this.state.billRemark
                }
                let data = {
                    ngay: moment(row.ngay).format('YYYY/MM/DD'),
                    id: row.id,
                    paymenttype: this.state.hinhthucthanhtoan,
                    discount: (PAYMENT_WITHOUT_MEDICINE && this.state.selectedVoucher !== "") ? 0 : this.state.giamgia, // Thanh toán chung dịch vụ và thuốc và ko phải áp dụng voucher + thẻ
                    medicines: lstMedic,
                    mabn: row.mabn,
                    maql: row.maql,
                }
                medicines.push(data);
            }
            else if (row.loai === SERVICE_TYPE.THUOCBANLE.toString() || row.loai === SERVICE_TYPE.VATTU.toString()) {
                banle = true;
                if (row.loai === SERVICE_TYPE.VATTU.toString()) {
                    vattu = true;
                }
                let dataRetail = {
                    ngay: moment(row.ngay).format('YYYY/MM/DD'),
                    id: row.id,
                    discount: PAYMENT_WITHOUT_MEDICINE && row.loai === SERVICE_TYPE.THUOCBANLE.toString() ? 0 : this.state.giamgia,
                    hinhthuc: this.state.hinhthucthanhtoan.toString(),
                    tongtien: tongtien,
                }
                retails.push(dataRetail);
            }
        })
        // 230721 : Thay đổi chỉ lấy 1 HTTT
        // let lstHinhThucTT = lstHTTT.map(x => ({ ...x, tongtien: x.ma.toString() === this.state.hinhthucthanhtoan.toString() ? tongtien : 0 }))
        let lstHinhThucTT = lstHTTT.filter(x => x.ma.toString() === this.state.hinhthucthanhtoan.toString()).map(x => ({ ...x, tongtien: tongtien }));
        if (banle === false) {
            let payment = {
                medicines: medicines,
                services: services,
                vatinvoice: vatinvoice,
                equipment: this.state.equipment,
                lstLoaiThanhToan: lstHinhThucTT,
                tongtien: tongtien,
                payFor: payFor,
                discount: this.state.giamgia,
            }
            //checkTestResult dùng để kiểm tra xem xét nghiệm đã được xác nhận lấy mẫu chưa
            // checkTestResult = 0 => Chưa kiểm tra
            // checkTestResult = 1 => Đã kiểm tra và đã xác nhận lấy mẫu
            // checkTestResult = 2 => Đã kiểm tra và chưa xác nhận lấy mẫu
            if(this.state.checkTestResult === 0){
                this.CheckTestResult(payment);
                Actions.setLoading(false);
            }
            if(this.state.checkTestResult === 2){
                InvoiceService.SavePayMent(payment).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    this.setState({
                        loaithanhtoan: 1,
                        hinhthucthanhtoan: 1,
                        sohoadon: result.id,
                        hiddenLuu: true,
                        banle: false,
                        listVouchers: [],
                        selectedVoucher: "",
                        loyal: false,
                        campaignid: 0,
                        listServiceUseVoucher: [],
                        lstServicesPatient: [],
                        lstLoaiTT: lstHinhThucTT.map(x => ({ ...x, tongtien: 0 })),
                        checkTestResult : 0,
                        tonggiamtru : 0,
                        promotionid : null,
                        voucherServiceId: 0,
                        voucherid : 0,
                        OTPConfirm : false,
                        billRemark : ''
                    }, () => {
                        this.LoadSummary();
                        this.GetListPatientPayment(false);
                    Actions.setLoading(false);
                    })
                }
                else {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                
                }).catch(err => {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", err);
                })
            }
            
        }
        else {
            let payment = {
                medicines: medicines,
                retails: retails,
                services: services,
                vatinvoice: vatinvoice,
                lstLoaiThanhToan: lstHinhThucTT,
                tongtien: tongtien,
                payFor: payFor,
                equipment: vattu,
            }
            InvoiceService.SavePayMentRetail(payment).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    this.setState({
                        loaithanhtoan: 1,
                        hinhthucthanhtoan: 1,
                        sohoadon: result.id,
                        hiddenLuu: true,
                        banle: vattu === false,
                        vattu: vattu,
                        lstLoaiTT: lstHinhThucTT.map(x => ({ ...x, tongtien: 0 })),
                        voucherServiceId: 0,
                        billRemark : '',
                        lstServicesPatient: [],
                    }, () => {
                        this.LoadSummary();
                        this.GetListPatientPayment();
                        Actions.setLoading(false);
                    })
                }
                else {
                    Actions.setLoading(false);
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })
        }
    }
    Print() {
        let patient = this.state.patient;
        this.PrintListPatientPayment(this.state.sohoadon, moment(patient.ngaycd).format('YYYY-MM-DD'), this.state.banle);
    }
    PrintAllSID() {
        PrintService.PrintOrderSID(this.state.patient.maql, 1, 1, true).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    /*2023.03.17: Có thể cái này không dùng nữa.
    sau 3 tháng mà không dùng nữa thì xóa nhé */
    // SendToOTBBarcode(sentOTBarcode, chinhanh) {
    //     Actions.setLoading(true);
    //     ConfirmOrderService.SendToOTBBarcode(this.state.patient.maql, chinhanh, sentOTBarcode).then(result => {
    //         if (result.isSuccess) {
    //             notification.success({ message: 'Thao tác thành công' })
    //         } else {
    //             Actions.openMessageDialog('Lỗi', result.err.msgString);
    //         }
    //         Actions.setLoading(false);
    //     }).catch(err => {
    //         Actions.setLoading(false);
    //         Actions.openMessageDialog('Lỗi', err)
    //     })
    // }

    PrintListPatientPayment(sohoadon, ngaycd, maql) {
        let x = this.state.patient;
        let banle = false
        if (x.loaithu === "3") {
            banle = true;
        }
        PrintService.PrintPayment(sohoadon, moment(ngaycd).format('YYYY-MM-DD'), maql, banle).then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                return;
            } else {
                const template = Handlebars.compile(result.item.template);
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        })
    }
    PrintRefund() {
        let x = this.state.patient;
        let banle = false
        let sohd = "";
        if (x.loaithu === "3") {
            banle = true;
            sohd = x.sohoadon;
        }
        PrintService.PrintRefundTrucTiep(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.patient.mabn, this.state.patient.maql, banle, sohd).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    HoanTraTrucTiep(row) {
        const tongtt = (row.soluong * row.dongia - row.soluong * row.giabh) * ((100 - row.tylegiamgia) / 100)
        // if (tongtt != this.state.lstLoaiTT_hoantra.reduce((tmp, cur) => tmp + (cur.tongtien || 0), 0)) {
        //     notification.warning({ message: 'Vui lòng kiểm tra lại tổng tiền!' })
        //     return;
        // }
        const data = {
            mabn: row.mabn,
            maql: row.maql,
            mavp: row.mavp,
            loaikham: row.loaikham,
            ghichu: this.state.ghichu,
            idchidinh: row.idchidinh,
            lstLoaiThanhToan: this.state.lstLoaiTT_hoantra,
            tongtien: tongtt,
        }
        Actions.setLoading(true);
        InvoiceService.LuuHoanTraTrucTiep(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Hoàn trả thành công" });
                this.setState({
                    ghichu: '', visible: false,
                    banle: row.loaikham === PAYMENT_SERVICE.BANLE || row.loaikham === PAYMENT_SERVICE.VATTU ? true : false,
                    sohoadon: row.loaikham === PAYMENT_SERVICE.BANLE || row.loaikham === PAYMENT_SERVICE.VATTU ? row.sohoadon : "",
                }, () => {this.LoadSummary() });
                //this.LoadDetailServices(this.state.patient);
            }
            else {
                Actions.openMessageDialog("Error", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Error", err);
        })
    }
    PrintSummary(type = "") {
        let x = this.state.patient;
        let banle = false
        let sohd = "";
        if (x.loaithu === "3") {
            banle = true;
            sohd = x.sohoadon;
            if (sohd === '') {
                sohd = this.state.sohoadon
            }
        }
        PrintService.PrintSummary(this.state.patient.maql, this.state.patient.mabn, moment(this.state.patient.ngaycd, 'MM/DD/YYYY HH:mm').format('YYYY-MM-DD HH:mm'), banle, sohd, type).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    LoadPaymentDetail(data) {
        let x = this.state.patient;
        let banle = false
        if (x.loaithu === "3") {
            banle = true;
        }
        InvoiceService.PatientPaymentInvoiceDetail(data.sohoadon, moment(data.ngaythu).format('YYYY-MM-DD'), banle).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPaymentDetail: result.data,
                    barcode: result.returnObject.barcode ? result.returnObject.barcode : "",
                    visiblePay: true,
                    sohoadon: data.sohoadon,
                    reviewBillRemark : result.returnObject.billRemark,
                    sidbybill :  result.returnObject.sid,
                });
                this.getVoucherUsed(data.sohoadon);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })

        InvoiceService.GetPaymentType(data.sohoadon).then(result => {
            if (result.isSuccess) {
                if (result.item.trangthai === "True") {
                    this.setState({
                        checkvat: true
                    })
                }
                this.setState({
                    isAdmin: true,
                    hinhthucthanhtoan: result.item.hinhthucthu,
                })
            }
        })
    }

    GetPaymentType(sohd){
        InvoiceService.CheckPaymentType(sohd).then(result => {
            if (result.isSuccess) {
                this.setState({
                    hinhthucthanhtoan: result.item.hinhthucthu,
                    hinhthuchoantra : result.item.hinhthucthu,
                    loaithanhtoan : result.item.hinhthucthu,
                })
            }
        })
    }

    ChangePaymentType(sohoadon, loaithanhtoan, checkvat) {
        InvoiceService.ChangePaymentType(sohoadon, loaithanhtoan, checkvat).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                this.setState({
                    disableTT: true,
                    checkboxVAT: true,
                    showBtn: false
                })
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err)
        })
    }

    getListVoucher(code = this.state.selectedVoucher, mabn = this.state.patient.mabn, maql = this.state.patient.maql) {
        if (code === "") this.getListPromotions();
        else
            getVouchers(code, mabn, moment().format("YYYY/MM/DD HH:mm:ss"), maql).then(res => {
                if (res.isSuccess) {
                    this.setState({ listVouchers: res.data, isLoyalty: false })
                }
                else {
                    notification.warning({ message: res.err ? res.err.msgString : res.error.messageText });
                    this.setState({ listVouchers: [], warningVoucher: res.err ? res.err.msgString : res.error.messageText  });
                }
            }).catch(err => {
            })
    }

    getPaymentMethod() {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                if (res.length > 0) {
                    let totalMoneyWithMedicine = 0;
                    let listServiceWithMedicine = this.state.lstServicesPatient.filter(x => x.loai == SERVICE_TYPE.THUOCBANLE || x.loai == SERVICE_TYPE.THUOCKEDON);
                    if (PAYMENT_WITHOUT_MEDICINE && (this.state.giamgia > 0 || this.state.tienGiam > 0)) {
                        totalMoneyWithMedicine = listServiceWithMedicine.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongia), 0);
                    }
                    const tongthanhtoan = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
                    const tongbhyt = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.giabh), 0);
                    const tongtt = Number(tongthanhtoan - this.getTotalDiscountAmount(tongthanhtoan, tongbhyt, totalMoneyWithMedicine));
                    let itemHT = res.find(x => x.ma == this.state.hinhthucthanhtoan);
                    if (itemHT) {
                        itemHT.tongtien = tongtt;
                    } else {
                        res[0].tongtien = tongtt;
                    }
                }
                this.setState({
                    lstLoaiTT: res,
                    //lstLoaiTT_hoantra : res
                })
            }
        }).catch(err => {
        })
    }

    getAllPaymentMethod() {
        const getAll = true;
        VPService.GetDanhSachLoaiThanhToan(getAll).then(result => {
            if (result.isSuccess) {
                let res = result.data;
                this.setState({
                    lstPaymentTypes: res,
                })
            }
        }).catch(err => { })
    }

    getPaymentMethodRefund() {
        let lstTT = this.state.lstLoaiTT;
        
        StatisticService.IdConfigCongNo().then(result => {
            if (result.isSuccess) {
                let congno = result.data;
                let lstTTCN = lstTT.filter(item => item.ma === Number(congno));
                this.setState({
                    lstLoaiTT_congno : lstTTCN,
                    idCongno : Number(congno),
                })
            }
        }).catch(err => {
        })

        StatisticService.IdConfigTheTraTruoc().then(result => {
            if (result.isSuccess) {
                let thetratruoc = result.data;
                let lstTTTTT = lstTT.filter(item => item.ma === Number(thetratruoc));
                this.setState({
                    lstLoaiTT_the : lstTTTTT,
                    idTheTraTruoc : Number(thetratruoc),
                })
            }
        }).catch(err => {
        })

        let lstTTHT = lstTT.filter(item => item.ma !== Number(this.state.idCongno)).filter(item => item.ma !== Number(this.state.idTheTraTruoc));
        this.setState({
            lstLoaiTT_hoantra: lstTTHT,
        })
    }

    getListPromotions(mabn = this.state.patient.mabn) {
        // Actions.setLoading(true);
        // getPromotions(mabn).then(res => {
        //     if (res.isSuccess) {
        //         this.setState({ listVouchers: res.data, isLoyalty: true });
        //         Actions.setLoading(false);
        //     }
        //     else {
        //         res.err.msgString ?
        //             notification.warning({ message: res.err.msgString })
        //             : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
        //         this.setState({ listVouchers: []});
        //         Actions.setLoading(false);
        //     }
        // }).catch(() => Actions.setLoading(false))
    }
    findMaxTime = (array) => {
        let max = 0;
        if (array.length > 0) {
            array.forEach(item => {
                if (Number(item.thoigiantraketqua) > max)
                    max = Number(item.thoigiantraketqua);
            })
        }
        return max;
    }
    updateTongTienHoanTra() {
        let lttht = this.state.lstLoaiTT_hoantra;
        let tongtt = 0
        this.state.listRefunds.forEach(x => {
            tongtt += ((x.soluong * x.dongia - x.soluong * x.giabh) * ((100 - x.tylegiamgia) / 100));
        });
        if (this.state.lstLoaiTT_hoantra.length > 0) {
            lttht[0].tongtien = tongtt;
        }
        this.setState({ lstLoaiTT_hoantra: lttht })
    }
    updateListRefund = (item) => {
        let { listRefunds } = this.state;
        if (item != null && item != undefined) {
            let foundInRefundList = listRefunds.find(x => x.idchidinh === item.idchidinh && x.mavp === item.mavp);
            if (foundInRefundList !== null && foundInRefundList !== undefined) {
                let listRefundsAfterRemoved = listRefunds.filter(x => x.idchidinh !== item.idchidinh && x.mavp !== item.mavp);
                this.setState({
                    listRefunds: listRefundsAfterRemoved
                }, () => this.updateTongTienHoanTra())
            }
            else {
                this.setState({
                    listRefunds: [...listRefunds, item]
                }, () => this.updateTongTienHoanTra())
            }
        }
    }
    onChangeRefundAll = (event) => {
        if (!event.target.checked) {
            this.setState({ listRefunds: [] }, () => this.updateTongTienHoanTra());
        } else {
            this.setState({ listRefunds: this.state.lstBangKe.filter(x => x.hoantra == 'Chưa hoàn trả') }, () => this.updateTongTienHoanTra());
        }
    }

    RefundByList() {
        let { listRefunds } = this.state;
        let tongtt = 0
        this.state.listRefunds.forEach(x => {
            tongtt += ((x.soluong * x.dongia - x.soluong * x.giabh) * ((100 - x.tylegiamgia) / 100));
        });
        // if (tongtt != this.state.lstLoaiTT_hoantra.reduce((tmp, cur) => tmp + (cur.tongtien || 0), 0)) {
        //     notification.warning({ message: 'Vui lòng kiểm tra lại tổng tiền!' })
        //     return;
        // }
        if (listRefunds && listRefunds.length > 0) {
            listRefunds.forEach(element => {
                element.ghichu = this.state.ghichu;
                element.tongtien = tongtt;
                // element.lstLoaiThanhToan = this.state.lstLoaiTT.map(x => ({ ...x, tongtien: x.ma.toString() === this.state.hinhthuchoantra.toString() ? tongtt : 0 }));
                element.lstLoaiThanhToan = this.state.lstLoaiTT.filter(x => x.ma.toString() === this.state.hinhthuchoantra.toString()).map(x => ({ ...x, tongtien: tongtt }));
                element.paymenttype = Number(this.state.hinhthuchoantra);
            });
            const data = listRefunds;
            Actions.setLoading(true);
            InvoiceService.RefundByList(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Hoàn trả thành công" });
                    this.setState({ ghichu: '', visible: false, listRefunds: [], hinhthuchoantra: 1 });
                    this.LoadSummary();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })
        } else {
            notification.warning({ message: "Chưa chọn dịch vụ để hoàn trả" });
        }
    }
    onCloseDetail() {
        this.setState({
            visiblePay: false,
            disableTT: true,
            disableBtn: false,
            checkvat: false,
            checkboxVAT: true,
            showBtn: false
        });
    }
    getVoucherUsed(sohoadon = this.state.sohoadon) {
        //Lấy danh sách voucher đã sử dụng
        var listVoucherUsedUnique = [];
        var listVoucherUsed = this.state.lstBangKe.filter(x => x.hoantra === "Chưa hoàn trả" && x.voucher !== "" && x.voucher !== null && x.voucher !== undefined && Number(x.sohoadon) === Number(sohoadon));
        if (listVoucherUsed !== undefined && listVoucherUsed.length > 0) {
            listVoucherUsed = listVoucherUsed.map(x => (x.voucher));
            listVoucherUsedUnique = [...new Set(listVoucherUsed)];
            this.setState({
                listVoucherUsed: listVoucherUsedUnique,
            })
        } else {
            this.setState({
                listVoucherUsed: [],
            })
        }
    }
    CheckTestResult(payment){
        InvoiceService.CheckTestResult(payment).then(result => {
        if(result.isSuccess){
            if(result.returnObject.toString() === "true" && this.includeLabServices()){
                this.setState({
                    checkTestResult : 1,
                    visiblePayment : true
                })
            } else {
                this.setState({
                    checkTestResult : 2,
                }, () => {
                    this.PayConfirm()
                })
            }
        }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    SubmitVoucher = (promotion) => {
        let tongtien = 0;
        let discountValue = 0;
        let totalFreeServices = 0;
        this.state.lstServicesPatient.forEach(x => { x.dongiakm = x.dongia; })
        let lstServicesPatientOld = PAYMENT_WITHOUT_MEDICINE
            ? this.state.lstServicesPatient.filter(x => x.loai != SERVICE_TYPE.THUOCBANLE && x.loai != SERVICE_TYPE.THUOCKEDON && x.loai != SERVICE_TYPE.VATTU)
            : this.state.lstServicesPatient;
        let lstServicesPatientFree = this.state.lstServicesPatient;
        this.setState({
            lstServiceWithoutVoucher : lstServicesPatientOld,
            selectedPromotion : promotion,
        }, () => {
            if(promotion && promotion.length == 0 ){
                
                this.setState({
                    selectedPromotion : [],
                    tonggiamtru : 0,
                    voucherServiceId : 0,
                    disablePayment : false,
                    hinhthucthanhtoan : 1,
                })
            } else {
                // Dịch vụ khuyến mãi
                if(promotion.isAllProductPreferential){
                    lstServicesPatientOld = lstServicesPatientOld.filter(x => !promotion.excludeProductIds.includes(x.mavp))
                } else {
                    lstServicesPatientOld = lstServicesPatientOld.filter(x => promotion.discountProductIds.includes(x.mavp))
                }
                lstServicesPatientOld.map(x => {tongtien = tongtien + x.dongia})
                if(promotion.isPercent){
                    discountValue = promotion.discountValue
                } else {
                    if(promotion.discountValue >= tongtien){
                        discountValue = 100;
                    } else {
                        discountValue = (promotion.discountValue/tongtien)*100
                    }
                }
                lstServicesPatientOld.forEach(x => {
                    x.dongiakm = (x.dongiakm * ( 100 - discountValue )) / 100;
                    x.voucher = promotion.voucherCode;
                })
                // Dịch vụ tặng kèm
                if(promotion.freeProductIds && promotion.freeProductIds.length > 0){
                    lstServicesPatientFree = lstServicesPatientFree.filter(x => promotion.freeProductIds.includes(x.mavp))
                    lstServicesPatientFree.map(x => {totalFreeServices = totalFreeServices + x.dongia})
                    lstServicesPatientFree.forEach(x => {
                        x.dongiakm = 0;
                        x.voucher = promotion.voucherCode;
                    })
                }
                if(promotion.loyaltyType && promotion.loyaltyType === LOYALTY_TYPE.CARD){
                    this.setState({
                        promotionid : promotion.id.toString(), 
                        disablePayment : true , 
                        hinhthucthanhtoan : this.state.idTheTraTruoc
                    })
                } else {
                    this.setState({voucherServiceId : promotion.id})
                }
                this.setState({
                    //promotionid : promotion.id.toString(),
                    //voucherServiceId : promotion.id,
                    listServiceUseVoucherNew : [...lstServicesPatientOld, ...lstServicesPatientFree],
                    tonggiamtru : ((tongtien * discountValue) / 100) + totalFreeServices,
                })
                this.state.lstServicesPatient.filter(x => !lstServicesPatientOld.includes(x)).push(lstServicesPatientOld);
                this.state.lstServicesPatient.filter(x => !lstServicesPatientFree.includes(x)).push(lstServicesPatientFree);
                // this.setState({
                //     lstServicesPatient : lstServicesPatientAfterDiscount
                // })
            }
        })
    }

    getTotalDiscountAmount(tongTien, tongBHYT, tongTienThuoc) {
        let { giamgia, tienGiam, selectedVoucher } = this.state;
        // Nếu giảm giá trên màn hình thanh toán thì giảm cả thuốc, còn ko thì loại thuốc ra
        if( PAYMENT_WITHOUT_MEDICINE && (giamgia > 0 || tienGiam > 0) && selectedVoucher != ""){
            return (tongTien - tongBHYT - tongTienThuoc) * (giamgia / 100)
        } else {
            return (tongTien - tongBHYT) * (giamgia / 100)
        }
    }

    // 2023-04-19 : Sau 3 tháng ko lỗi thì xóa
    // getTotalPayAmount(tongTien, tongBHYT, tongGiamTru, tongTienThuoc, type = "giamGia") {
    //     return tongTien - getTotalDiscountAmount(tongTien, tongBHYT, tongTienThuoc)
    // }

    renderSwitch(loaithanhtoan) {
        switch(loaithanhtoan) {
            case this.state.idCongno:
                return (
                    this.state.lstLoaiTT_congno && this.state.lstLoaiTT_congno.map((hinhthuc, index) =>
                        <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                    )
                );
            case this.state.idTheTraTruoc:
                return (
                    this.state.lstLoaiTT_the && this.state.lstLoaiTT_the.map((hinhthuc, index) =>
                        <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                    )
                );
            default:
                return (
                    this.state.lstLoaiTT_hoantra && this.state.lstLoaiTT_hoantra.map((hinhthuc, index) =>
                        <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                    )
                );
        }
    }

    includeLabServices() {
        const foundLabServices = this.state.lstServicesPatient.filter(x => x.labService == true);
        if (foundLabServices !== undefined && foundLabServices.length > 0)
            return true;
        else return false;
    }

    render() {
        let tongtien = 0;
        let tongthanhtoan = 0;
        let tongbhyt = 0;
        let tonggiamtru = 0;
        let totalMoneyWithMedicine = 0;
        let lengthService = this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.DICHVU.toString() || x.loai == SERVICE_TYPE.VATTU).length;
        let lengthPayService = this.state.lstPaymentDetail.filter(x => x.loai === SERVICE_TYPE.DICHVU.toString() || x.loai == SERVICE_TYPE.VATTU).length;
        let listServiceWithMedicine = this.state.lstServicesPatient.filter(x => x.loai == SERVICE_TYPE.THUOCBANLE || x.loai == SERVICE_TYPE.THUOCKEDON);
        if (this.state.isPaid === false) {
            tongtien = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongia), 0);
            tongthanhtoan = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongiakm), 0);
            //tongbhyt = this.state.lstServicesPatient.reduce((tmp, cur) => tmp + (cur.soluong * cur.giabh), 0);
            tongbhyt = 0;
            tonggiamtru = tongtien - tongthanhtoan;
            if (PAYMENT_WITHOUT_MEDICINE) {
                totalMoneyWithMedicine = listServiceWithMedicine.reduce((tmp, cur) => tmp + (cur.soluong * cur.dongia), 0);
            }
        }
        let posGK = 0;
        let posGKM = 0;
        const menu = (
            <Menu
                onClick={() => {
                    this.setState({
                        visiblePromotion : true
                    })
                }}
                items={[
                {
                    label: 'Mã giảm giá dịch vụ',
                    key: '1',
                },
                ]}
            />
            );

        const columnTablePaid = [
            {
                title: '#',
                width: 60,
                align: 'Center',
                fixed: 'left',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Tên dịch vụ',
                width: 200,
                dataIndex: 'tenvp',
                key: 'tenvp',
                fixed: 'left',
            },
            {
                title: 'SL',
                width: 60,
                align: 'Center',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Đơn giá',
                width: 100,
                align: 'Right',
                dataIndex: 'dongiagoc',
                key: 'dongiagoc',
                render: (text, record, index) => <span>{text.toLocaleString('en-US')}</span>,
            },
            {
                title: 'Thành tiền',
                width: 100,
                align: 'Right',
                render: (text, x, index) => <span>{(x.soluong * x.dongiagoc).toLocaleString('en-US')}</span>,
            },
            {
                title: 'Giảm trừ khác',
                width: 80,
                align: 'Right',
                dataIndex: 'name',
                key: 'name',
                render: (text, x, index) => {
                    let totalDiscount = ((x.dongiagoc - x.dongia - x.giabh) + x.dongia * (x.tylegiamgia / 100)) * x.soluong
                    return (
                        <span>{Number(totalDiscount.toFixed(3)).toLocaleString('en-US')}</span>
                    )
                },
            },
            {
                title: 'Bệnh nhân trả',
                width: 100,
                align: 'Right',
                dataIndex: 'name',
                key: 'name',
                render: (text, x, index) => <span>{(Number(((x.soluong * x.dongia - x.soluong * x.giabh) * ((100 - x.tylegiamgia) / 100)).toFixed(3))).toLocaleString('en-US')}</span>,
            },
            {
                title: 'Trạng thái',
                width: 150,
                dataIndex: 'hoantra',
                key: 'hoantra',
                render: (text, x, index) => { return text === 'Chưa hoàn trả' && x.thuchien === 1 ? <div>Đã thực hiện</div> : <div></div>},
            },
            {
                title: 'Phiếu thanh toán',
                width: 60,
                align: 'Right',
                dataIndex: 'sohoadon',
                key: 'sohoadon',
                render: (text, x, index) => {
                    return (
                        <div
                        onClick={(e) => { this.LoadPaymentDetail(x); this.setState({ paymentDetail: x }) }}
                        style={{
                            textAlign: 'center',
                            borderRadius: 15, cursor: 'pointer', boxShadow: '-2px 1px 8px -3px rgba(13,94,138,0.78)', marginTop: 2, height: 25
                        }}>{text}</div>
                    )       
                },
            },
            {
                title: 
                    <Tooltip title="Chọn/bỏ chọn tất cả" placement="left">
                        <Checkbox checked={this.state.listRefunds.length > 0 && this.state.lstBangKe.length > 0 && this.state.listRefunds.length === this.state.lstBangKe.filter(x => x.hoantra == 'Chưa hoàn trả').length}
                            onChange={(e) => this.onChangeRefundAll(e)} style={{ marginRight: 5 }}/>Hoàn trả
                    </Tooltip>,
                width: 120,
                align: 'Center',
                dataIndex: 'hoantra',
                key: 'hoantra',
                render: (value, x, index) => {
                    return (
                        value === 'Chưa hoàn trả' ?
                        <Checkbox checked={this.state.listRefunds && this.state.listRefunds.find(item => x.idchidinh === item.idchidinh && x.mavp === item.mavp)}
                            onChange={() => this.updateListRefund(x)}
                        ></Checkbox> : "Đã hoàn trả"
                    )
                }
            },
        ]
        return (
            <React.Fragment>
                <div style={{ height: '100%' }}>
                    <div style={{ height: '100%', width: '100%', display: 'flex' }}>
                        <div className={'containerChilderWrapper'} style={{ marginRight: 10, witdh: 450 }}>
                            <div style={{ height: '100%', position: 'relative' }}>
                                <div className='row' style={{ marginBottom: '10px' }}>
                                    <div className="col-6 mt-1">
                                        <div className="">
                                            <select className="form-control form-control-md" onChange={(e) => { this.setState({ type: e.target.value }, () => this.GetListPatientPayment()) }}>
                                                {!this.props.payMedicineAtCashDesk && <option key={0} value=''>Dịch vụ, thuốc...</option>}
                                                <option key={1} value='1'>Dịch vụ</option>
                                                <option key={2} value='2'>Thuốc</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 mt-1" style={{ display: 'flex' }}>
                                        <select style={{ width: 'calc(100% - 50px)' }} className="form-control form-control-md" onChange={(e) => { this.setState({ isPaid: e.target.value !== '1', thoiGianTraKQ: 0, trangThaiThanhToan: e.target.value }, () => this.GetListPatientPayment()) }}>
                                            <option key={1} value='1'>Chưa thanh toán</option>
                                            <option key={2} value='2'>Đã thanh toán</option>
                                            <option key={3} value='3'>Hoàn trả</option>
                                            <option key={0} value='0'>Tất cả hóa đơn</option>
                                        </select>
                                        <Popover
                                            content={
                                                <RangePicker allowClear={false} format={FORMAT_DATE} value={this.state.ngay}
                                                    // disabledDate={(current) => { return current && (current > moment()) }}
                                                    locale={viVN} onChange={(value => this.setState({ ngay: value }, () => this.GetListPatientPayment()))}
                                                />
                                            }
                                            title="Chọn ngày"
                                            trigger="click"
                                            visible={this.state.visibleDate}
                                            onVisibleChange={(e) => { this.setState({ visibleDate: e, openDate: e }) }}
                                            placement="bottomRight"
                                        >
                                            <button className="btn btn-secondary ml-auto" onClick={() => { this.setState({ visibleDate: true }) }}><i className="fa fa-calendar-alt"></i></button>
                                        </Popover>
                                    </div>
                                    <div className={`col-12 mt-1`}>
                                        <div className="input-group input-group-md">
                                            <div className="input-group-prepend">
                                                <button onClick={(e) => this.GetListPatientPayment()} className="btn btn-color btn-md" type="button"><i className="fa fa-search"></i></button>
                                            </div>
                                            <input type="text" className="form-control form-control-md" placeholder="Nhập họ tên hoặc mã bệnh nhân"
                                                onChange={(e) => this.setState({ key: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        this.GetListPatientPayment();
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div id="table-list-register" className="table-responsive">
                                    <table className="table table-striped table-hover table-bordered" style={{ maxHeight: '97%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ minWidth: '250px' }}>Họ tên</th>
                                                <th style={{ minWidth: '80px' }}>Ngày sinh</th>
                                                {/* <th style={{ minWidth: '80px' }}>Tổng tiền</th> */}
                                                {/* <th style={{ minWidth: '120px' }}>Loại</th> */}
                                                <th style={{ minwidth: 100 }}>Số phiếu</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.lstPatient.map((x, index) => (
                                                <tr key={index} className="click" onClick={() => {
                                                    this.setState({
                                                        patient: x,
                                                        loaithanhtoan: 1,
                                                        hinhthucthanhtoan: x.paymenttype !== '' && x.paymenttype !== 0 ? x.paymenttype : 1,
                                                        hiddenLuu: false,
                                                        lstBangKe: [],
                                                        giamgia: 0,
                                                        tienGiam: 0,
                                                        lstServicesPatient: [],
                                                        selectedVoucher: "",
                                                        listVouchers: [],
                                                        listServiceUseVoucher: [],
                                                        listServiceUseVoucherNew: [],
                                                        equipment: false,
                                                        listRefunds: [],
                                                        promotionid : null,
                                                        disablePayment : false,
                                                        selectedPromotion : [],
                                                        OTPConfirm : false,
                                                        billRemark : '',
                                                        ghichu : '',
                                                        voucherServiceId : 0,
                                                        voucherid : 0,
                                                        //disablePayment: x.paymenttype !== '' && x.paymenttype !== 0 ? true : false,
                                                    }, () => {
                                                        this.GetVATPayByMaql(x.maql);
                                                        this.LoadDetailServices(x);
                                                    });
                                                }}>
                                                    <td>{x.hoten}</td>
                                                    <td>{x.namsinh && x.namsinh.length > 4 ? moment(x.namsinh, "DD/MM/YYYY").format(FORMAT_DATE): x.namsinh}</td>
                                                    {/* <td className='text-right'>{x.sotienkm.toLocaleString('en-US')}</td> */}
                                                    {/* <td>{(x.loaithu === '1' || x.loaithu === '4') ? 'Viện phí' : (x.loaithu === '2' ? (this.state.type !== '2' ? 'Viện phí' : 'Thuốc theo đơn') : 'Thuốc bán lẻ')}</td> */}
                                                    <td>{x.listbienlai}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div id="report-register" className='col align-self-center' style={{ position: 'absolute', bottom: 0, left: 0 }}>
                                    <div className="h5 font-bold" style={{ marginTop: '10px', width: '100%' }}>Thống kê báo cáo</div>
                                    <div className='row'>
                                        <div className='col-lg-4 mt-1'>
                                            <button className="btn btn-outline-secondary align-items-center w-100"
                                                onClick={() => {
                                                    this.setState({
                                                        modalReport: true,
                                                        reportDetail: {
                                                            title: 'Báo cáo dịch vụ',
                                                            filter: '1',
                                                        }
                                                    })
                                                }}>
                                                <div><i className="fas fa-briefcase-medical fa-2x"></i></div>
                                                <div>Dịch vụ</div>
                                            </button>
                                        </div>
                                        <div className='col-lg-4 mt-1'>
                                            <button className="btn btn-outline-secondary align-items-center w-100"
                                                onClick={() => {
                                                    this.setState({
                                                        modalReport: true,
                                                        reportDetail: {
                                                            title: 'Báo cáo thuốc',
                                                            filter: '2',
                                                        }
                                                    })
                                                }}>
                                                <div><i className="fas fa-capsules fa-2x"></i></div>
                                                <div>Thuốc</div>
                                            </button>
                                        </div>
                                        <div className='col-lg-4 mt-1'>
                                            <button className="btn btn-outline-secondary align-items-center w-100"
                                                onClick={() => {
                                                    this.setState({
                                                        modalReport: true,
                                                        reportDetail: {
                                                            title: 'Báo cáo tổng hợp',
                                                            filter: '',
                                                        }
                                                    })
                                                }}>
                                                <div> <i className="fas fa-dollar-sign fa-2x"></i></div>
                                                <div>Tổng hợp</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'containerChilderWrapper'} style={{ overflowX: "auto", flex: 1 }}>
                            <div className="h-100" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                <div className="">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                            Tên: <strong>{this.state.patient.hoten}</strong>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                            Ngày sinh: <strong>{this.state.patient.namsinh && this.state.patient.namsinh.length > 4 ? moment(this.state.patient.namsinh, "DD/MM/YYYY").format(FORMAT_DATE) : this.state.patient.namsinh}</strong>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                            Mã số BN: <strong>{this.state.patient.mabn}</strong>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4">
                                            Điện thoại: <strong>{this.state.patient.dt_didong}</strong>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-8">
                                            Địa chỉ: <strong>{this.state.patient.sonha}</strong>
                                        </div>
                                    </div>
                                    <div className='font-weight-bold'>
                                        <div>DỊCH VỤ CHỜ THANH TOÁN
                                            {/* 2023-04-07 : Ẩn do task #3053
                                            {(this.state.hiddenLuu || this.state.patient.done === '1') &&
                                                <span style={{ marginLeft: '10px' }}>
                                                    {this.state.sidxn && this.state.sidxn != "" &&
                                                        <span>
                                                            (SID: {this.state.sidxn})
                                                        </span>
                                                    }
                                                </span>
                                            } */}
                                            <div style={{ float: 'right' }}>{this.state.patient && this.state.patient.hoten !== undefined &&
                                                <Speaker ref={(c) => this.speaker = c}
                                                    sentence={`Mời khách hàng ${this.state.patient.hoten} tới quầy tính tiền`}
                                                >
                                                </Speaker>
                                            }</div>
                                        </div>
                                    </div>
                                    <div className='table-responsive' style={{ maxHeight: '335px', marginBottom: 10 }}>
                                        <table className="table table-striped table-hover table-bordered w-100">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '50px' }}>STT</th>
                                                    <th>Tên dịch vụ</th>
                                                    <th>SL</th>
                                                    <th>Đơn giá</th>
                                                    <th>Thành tiền</th>
                                                    <th>Giảm trừ khác</th>
                                                    <th>Người bệnh trả</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.lstServicesPatient.filter(x => x.loai === '0').length > 0 && this.state.isPaid === false &&
                                                    <React.Fragment>
                                                        {posGK === 0 &&
                                                            <tr key={-1}>
                                                                <span className="d-none">{posGK = posGK + 1}</span>
                                                                <td colSpan='7' className='font-bold'>Gói khám</td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {this.state.isPaid === false && this.state.lstServicesPatient.filter(x => x.loai === '0').map((x, index) => (
                                                    <tr key={'1' + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{x.tenvp}</td>
                                                        <td>{x.soluong}</td>
                                                        <td className='text-right'>{x.dongia? x.dongia.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>{x.thanhtien ? x.thanhtien.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>0</td>
                                                        <td className='text-right'>{(x.dongiakm * x.soluong).toLocaleString('en-US')}</td>
                                                    </tr>
                                                ))
                                                }
                                                {this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.DICHVU.toString()).length > 0 && this.state.isPaid === false &&
                                                    <tr key={-1}>
                                                        <td colSpan='7' className='font-bold'>Dịch vụ</td>
                                                    </tr>
                                                }
                                                {this.state.isPaid === false && this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.DICHVU.toString()).map((x, index) => (
                                                    <tr key={'1' + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{x.tenvp}</td>
                                                        <td>{x.soluong}</td>
                                                        <td className='text-right'>{x.dongia? x.dongia.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>{x.thanhtien ? x.thanhtien.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>{x.dongia ? (x.dongia - x.dongiakm).toLocaleString('en-US'): '0'}</td>
                                                        <td className='text-right'>{(x.dongiakm * x.soluong).toLocaleString('en-US')}</td>
                                                    </tr>
                                                ))
                                                }
                                                {this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.VATTU.toString()).length > 0 && this.state.isPaid === false &&
                                                    <tr key={-4}>
                                                        <td colSpan='7' className='font-bold'>Vật tư sử dụng</td>
                                                    </tr>
                                                }
                                                {this.state.isPaid === false && this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.VATTU.toString()).map((x, index) => (
                                                    <tr key={'4' + index}>
                                                        <td>{index + 1}</td>
                                                        <td>{x.tenvp}</td>
                                                        <td>{x.soluong}</td>
                                                        <td className='text-right'>{x.dongiakm ? x.dongiakm.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>{x.thanhtien ? x.thanhtien.toLocaleString('en-US') : '0'}</td>
                                                        <td className='text-right'>0</td>
                                                        <td className='text-right'>{(x.dongiakm * x.soluong - x.giabh * x.soluong).toLocaleString('en-US')}</td>
                                                    </tr>
                                                ))
                                                }
                                                {this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.THUOCKEDON.toString() || x.loai === SERVICE_TYPE.THUOCBANLE.toString()).length > 0 && this.state.isPaid === false &&
                                                    <tr key={-2}>
                                                        <td colSpan='7' className='font-bold'>Thuốc</td>
                                                    </tr>
                                                }
                                                {this.state.isPaid === false && this.state.lstServicesPatient.filter(x => x.loai === SERVICE_TYPE.THUOCKEDON.toString() || x.loai === SERVICE_TYPE.THUOCBANLE.toString()).map((x, index) => (
                                                    <tr key={'1' + index}>
                                                        <td>{index + 1 + lengthService}</td>
                                                        <td>{x.tenvp}</td>
                                                        <td>{x.soluong}</td>
                                                        <td className='text-right'>{x.dongiakm ? x.dongiakm.toLocaleString('en-US') : ''}</td>
                                                        <td className='text-right'>{x.thanhtien ? x.thanhtien.toLocaleString('en-US') : ''}</td>
                                                        <td className='text-right'>0</td>
                                                        <td className='text-right'>{(x.dongiakm * x.soluong - x.giabh * x.soluong).toLocaleString('en-US')}</td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className='col-lg-6 col-sm-12'>
                                            <div class="btn-group">
                                                {FeatureDeactive().indexOf(FEATURE.CTKM) == -1 &&
                                                <>
                                                <button
                                                    onClick={() => 
                                                    this.setState({
                                                        vsbSetVoucher: true
                                                        //visiblePromotion : true
                                                    }, this.state.selectedVoucher && this.state.loyal === "" ? this.getListVoucher() : this.getListPromotions())}
                                                    disabled={this.state.patient.mabn === null || this.state.patient.mabn === undefined || this.state.patient.mabn === "" || this.state.lstServicesPatient.length === 0}
                                                    className="btn btn-warning">
                                                    Mã giảm giá
                                                </button>
                                                <button
                                                    disabled={this.state.patient.mabn === null || this.state.patient.mabn === undefined || this.state.patient.mabn === "" || this.state.lstServicesPatient.length === 0}
                                                    className="btn btn-warning dropdown-toggle dropdown-toggle-split" 
                                                    data-toggle="dropdown">
                                                </button>
                                                </>
                                                }
                                                <div class="dropdown-menu">
                                                    <a className="dropdown-item"
                                                        disabled={this.state.patient.mabn === null || this.state.patient.mabn === undefined || this.state.patient.mabn === "" || this.state.lstServicesPatient.length === 0}
                                                        onClick={() =>
                                                            this.setState({
                                                                //vsbSetVoucher: true
                                                                visiblePromotion : true
                                                            })}
                                                    >Mã giảm giá dịch vụ</a>
                                                </div>
                                            </div>
                                            {/* <button className="btn btn-warning"
                                                disabled={this.state.patient.mabn === null || this.state.patient.mabn === undefined || this.state.patient.mabn === "" || this.state.lstServicesPatient.length === 0}
                                                onClick={() =>
                                                    this.setState({
                                                        vsbSetVoucher: true
                                                        //visiblePromotion : true
                                                    }, this.state.selectedVoucher && this.state.loyal === "" ? this.getListVoucher() : this.getListPromotions())}
                                            >Mã giảm giá</button> */}
                                            {/* <button className="btn btn-warning"
                                                disabled={this.state.patient.mabn === null || this.state.patient.mabn === undefined || this.state.patient.mabn === "" || this.state.lstServicesPatient.length === 0}
                                                onClick={() =>
                                                    this.setState({
                                                        visiblePromotion : true
                                                    })}
                                            >Mã giảm giá</button> */}
                                            <div class="btn-group">
                                                <button disabled={this.state.selectedVoucher != ""} className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">{this.state.optionDiscount === 1 ? '% giảm' : 'Tiền giảm'}</button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href onClick={() => {
                                                        this.setState({ optionDiscount: 1 })
                                                    }}>% giảm</a>
                                                    <a className="dropdown-item" href onClick={() => {
                                                        this.setState({ optionDiscount: 2 })
                                                    }}>Tiền giảm</a>
                                                </div>
                                                {this.state.ghichupromo && 
                                                    <Tooltip title={'Ghi chú: ' + this.state.ghichupromo}>
                                                        <span className='ml-2'><i className="fas fa-info-circle"></i></span>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12 mt-2'>
                                            <label>Hình thức thanh toán</label>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-lg-6 col-sm-12'>
                                            {this.state.optionDiscount === 1 ?
                                                <InputNumber className="w-100" maxLength={3} min={0} max={100} value={this.state.giamgia} disabled={this.state.selectedVoucher != ""}
                                                    id='input-discount-percent'
                                                    onKeyDown={(e) => {
                                                        if (e === "e") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            giamgia: e,
                                                            tienGiam: tongthanhtoan * Number(e) / 100,
                                                        })
                                                        this.getPaymentMethod();
                                                    }} />
                                            :
                                                <InputNumber className="w-100" maxLength={12} min={0} value={this.state.tienGiam} disabled={this.state.selectedVoucher != ""}
                                                    id='input-discount-value'
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    step={5000}
                                                    onKeyDown={(e) => {
                                                        if (e === "e" || e.key === "." || e.keyCode == 110) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        if (e >= 0 && e <= tongthanhtoan ) {
                                                            this.setState({
                                                                tienGiam: e,
                                                                giamgia: e * 100 / tongthanhtoan
                                                            })
                                                            this.getPaymentMethod();
                                                        }
                                                    }} />
                                            }
                                        </div>
                                        <div className='col-lg-6 col-sm-12'>
                                            <div className="form-group w-100">
                                                <select className="form-control form-control-md" value={this.state.hinhthucthanhtoan}
                                                    disabled={this.state.disablePayment}
                                                    onChange={(e) => {
                                                        // let lstLoaiTT = this.state.lstLoaiTT;
                                                        // const index = lstLoaiTT.findIndex(x => x.ma === e.target.value)
                                                        // lstLoaiTT[index].tongtien = e;
                                                        this.setState({ hinhthucthanhtoan: Number(e.target.value) })
                                                        if(Number(e.target.value) === this.state.idTheTraTruoc) {
                                                            this.setState({
                                                                visiblePromotion : true,
                                                                loyaltyType : LOYALTY_TYPE.CARD
                                                            })
                                                        }
                                                    }}>
                                                    {this.state.lstLoaiTT && this.state.lstLoaiTT.map((hinhthuc, index) =>
                                                        <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                                                    )}
                                                </select>
                                                {/* {this.state.lstLoaiTT && this.state.lstLoaiTT.map((hinhthuc, index) => {
                                                    return (
                                                        <div className='payment-input-money my-1' key={index}>
                                                            <InputNumber className='w-100' prefix={hinhthuc.ten} value={hinhthuc.tongtien || 0}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={(e) => {
                                                                    let lstLoaiTT = this.state.lstLoaiTT;
                                                                    hinhthuc.tongtien = e;
                                                                    this.setState({ lstLoaiTT })
                                                                }
                                                                }
                                                                onBlur={(e) => {
                                                                    const tongtt = Number(((tongthanhtoan - tongbhyt) * ((100 - this.state.giamgia) / 100)).toFixed(4));
                                                                    const t = Number(e.target.value.replaceAll(',', ''))
                                                                    let lstLoaiTT = this.state.lstLoaiTT;
                                                                    if (t > tongtt) {
                                                                        hinhthuc.tongtien = tongtt;
                                                                        this.setState({ lstLoaiTT })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 d-flex justify-content-start'>
                                        <div className='d-block w-100'>
                                        <label>Ghi chú</label>
                                            <Input 
                                                value={this.state.billRemark} 
                                                onChange={(e) => {this.setState({billRemark : e.target.value})}} 
                                                placeholder="Nhập ghi chú"/>
                                            </div>
                                        </div>
                                        <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                            <div className='d-block total-payment'>
                                                <div className="d-flex align-items-center">
                                                    <div style={{ minWidth: '75px' }} className="float-left mr-auto">Tổng:</div>
                                                    <div className="mr-2"><strong>{tongtien.toLocaleString('en-US')}</strong></div>
                                                </div>
                                                {/* <div className="d-flex align-items-center">
                                                    <div style={{ minWidth: '75px' }} className="float-left">BHYT trả:</div>
                                                    <div><strong>{Number(tongbhyt.toFixed(0)).toLocaleString('en-US')}</strong></div>
                                                </div> */}
                                                <div className="d-flex align-items-center">
                                                    <div style={{ minWidth: '75px' }} className="float-left mr-auto">Miễn giảm:</div>
                                                    <div className="mr-2"><strong>{Number(this.getTotalDiscountAmount(tongthanhtoan, tongbhyt, totalMoneyWithMedicine)).toLocaleString('en-US')}</strong></div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div style={{ minWidth: '75px' }} className="float-left mr-auto">Thực thu:</div>
                                                    <div className="mr-2"><strong>{Number(tongthanhtoan - this.getTotalDiscountAmount(tongthanhtoan, tongbhyt, totalMoneyWithMedicine)).toLocaleString('en-US')}</strong></div>
                                                </div>
                                                {Object.keys(this.state.patient).length !== 0 && this.state.thoiGianTraKQ >= 0 && <div className='ml-auto align-self-center'>
                                                    <Tooltip title="Thời gian dự kiến trả kết quả kể từ thời điểm xác nhận lấy mẫu">
                                                        {this.state.thoiGianTraKQ > 0 ? <Tag color="red">Thời gian trả KQXN: {this.state.thoiGianTraKQ} phút</Tag>
                                                            : <Tag color="warning">Thời gian trả KQXN: Chưa có thông tin</Tag>}
                                                    </Tooltip>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <Checkbox checked={this.state.vatinvoice}
                                                onChange={(e) => {
                                                    this.setState({ vatinvoice: e.target.checked, vsbCustomerVATInfo: e.target.checked ? true : false })
                                                }}
                                            >Ghi nhận xuất hóa đơn đỏ</Checkbox>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12'>
                                            <Checkbox disabled={true} checked={this.state.payconfirm}>CTV xác nhận đã thanh toán</Checkbox>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-8 col-sm-12 mb-2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                {this.state.isPaid === false &&
                                                    <React.Fragment>
                                                        <button style={{ marginRight: 5, marginTop: 5, }} className='btn btn-outline-secondary' onClick={() => { this.Print() }} disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}><i className="fa fa-print" style={{ marginRight: 5 }}></i>In phiếu thanh toán</button>
                                                        <button style={{ marginRight: 5, marginTop: 5, }} className='btn btn-outline-secondary' onClick={() => { this.PrintAllSID() }}
                                                            disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}>
                                                            <i className="fa fa-print" style={{ marginRight: 5 }}></i>In tất cả barcode
                                                        </button>
                                                    </React.Fragment>
                                                }
                                        </div>
                                        <div className='col-lg-4 col-sm-12 mb-2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}} >
                                            <div>
                                                {this.state.isPaid === false &&
                                                    <React.Fragment>
                                                    {this.state.promotionid && this.state.hinhthucthanhtoan === this.state.idTheTraTruoc && 
                                                        <Popover
                                                            content={<div style={{ width: 300, padding: 10 }}>
                                                                <OTPConfirm 
                                                                    cardId={this.state.promotionid} 
                                                                    onSubmit={() => {this.setState({ OTPConfirm : true})}} 
                                                                    onClose={() => {this.setState({popoverOTP : false})}}
                                                                    />
                                                            </div>}
                                                            trigger="click"
                                                            placement="top"
                                                            visible={this.state.popoverOTP}
                                                            onVisibleChange={(e) => {
                                                                this.setState({popoverOTP : e})
                                                            }}
                                                        >
                                                            <button className='btn btn-secondary mr-1' href='#' onClick={() => { this.setState({popoverOTP : true}) }}>
                                                                Gửi OTP
                                                            </button>
                                                        </Popover>
                                                    }
                                                        <Popconfirm
                                                            title={this.state.checkTestResult === 1 ? "Mẫu đã được xác nhận. Khi thanh toán sẽ đẩy thẳng xuống LIS"  : "Xác nhận thanh toán?"}
                                                            onConfirm={() => { 
                                                                this.PayConfirm(); 
                                                                this.setState({ visiblePayment:false });
                                                                if(this.state.checkTestResult === 1){
                                                                    this.setState({ checkTestResult: 2 }, () => this.PayConfirm());
                                                                }
                                                            }}
                                                            onCancel={() => { this.setState({ visiblePayment:false, checkTestResult : 0 }) }}
                                                            onVisibleChange={(e) => {
                                                                if (!e) {
                                                                    this.setState({ visiblePayment: e })
                                                                } else {
                                                                    this.setState({ visiblePayment : e})
                                                                }
                                                            }}
                                                            visible={this.state.visiblePayment}
                                                            disabled={this.state.hiddenLuu || Object.keys(this.state.patient).length === 0 || this.state.patient.done === '1'}
                                                            okText="Đồng ý" cancelText="Hủy">
                                                            <button className='btn btn-color'><i className="fa fa-save" style={{ marginRight: 5 }}></i>Thanh toán</button>
                                                        </Popconfirm>
                                                        <button style={{ marginRight: 5, marginTop: 5, }} className='btn btn-outline-secondary d-none' onClick={() => { this.Print() }} disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}><i className="fa fa-print" style={{ marginRight: 5 }}></i>In phiếu thanh toán</button>
                                                    </React.Fragment>
                                                }
                                                    <button style={{ marginRight: 5, marginTop: 5, }} className='btn btn-outline-secondar d-none' onClick={() => { this.PrintAllSID() }}
                                                        disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}>
                                                        <i className="fa fa-print" style={{ marginRight: 5 }}></i>In tất cả barcode
                                                    </button>
                                                    {/*2023.03.17: Có thể cái này không dùng nữa.
                                                        sau 3 tháng mà không dùng nữa thì xóa nhé */}
                                                    {/* <button style={{ marginRight: 5, display: 'none', marginTop: 5, }} className='btn btn-outline-secondary' onClick={() => { this.SendToOTBBarcode(false, this.props.siteid.toString()) }}
                                                        disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}>
                                                        <i className="fa fa-print" style={{ marginRight: 5 }}></i>In SID
                                                    </button>
                                                    <button style={{ marginRight: 5, display: 'none', marginTop: 5, }} className='btn btn-outline-secondary' onClick={() => { this.SendToOTBBarcode(true, this.props.siteid.toString()) }}
                                                        disabled={!this.state.hiddenLuu && this.state.patient.done !== '1'}>
                                                        <i className="fa fa-print" style={{ marginRight: 5 }}></i>Gửi OTBarcode
                                                    </button> */}
                                                    <div className='d-none'>
                                                        {(this.state.hiddenLuu || this.state.patient.done === '1') && this.state.equipment === false && this.state.isPaid === true &&
                                                            <Popconfirm
                                                                title="Xác nhận trừ khấu hao?"
                                                                onConfirm={() => { this.XuatKhoKhauHaoKhamBenh() }}
                                                                okText="Đồng ý" cancelText="Hủy">
                                                                <button className='btn btn-color' style={{ marginTop: 5, }}>Trừ khấu hao</button>
                                                            </Popconfirm>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: '10px', backgroundColor: '#F3F5FA' }}></div>
                                <div className="">
                                    <div className='font-weight-bold'>BẢNG KÊ DỊCH VỤ ĐÃ THANH TOÁN {(this.state.equipment === true && this.state.isPaid === true) ? "(Đã trừ khấu hao vật tư)" : ""}</div>
                                    <div className='row mb-1'>
                                        <div className='col-12' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                            <Popover
                                                placement="left"
                                                content={
                                                    <div className="text-center">
                                                        <select className="form-control form-control-md" value={this.state.hinhthuchoantra}
                                                            onChange={(e) => {
                                                                this.setState({ hinhthuchoantra: Number(e.target.value) })
                                                            }}>
                                                            {this.renderSwitch(Number(this.state.loaithanhtoan))}
                                                            {/* {Number(this.state.loaithanhtoan) !== Number(this.state.idCongno) ?
                                                                this.state.lstLoaiTT_hoantra && this.state.lstLoaiTT_hoantra.map((hinhthuc, index) =>
                                                                    <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                                                                )
                                                            :
                                                                this.state.lstLoaiTT_congno && this.state.lstLoaiTT_congno.map((hinhthuc, index) =>
                                                                <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                                                                )
                                                            } */}
                                                        </select>
                                                        <div className='payment-input-money my-1'>
                                                            <InputNumber className='w-100'
                                                                //prefix={hinhthuc.ten}
                                                                readOnly
                                                                value={this.state.listRefunds.reduce((tmp, cur) => tmp + ((cur.soluong * cur.dongia - cur.soluong * cur.giabh) * ((100 - cur.tylegiamgia) / 100)), 0)}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            />
                                                        </div>
                                                        {/* {this.state.lstLoaiTT_hoantra && this.state.lstLoaiTT_hoantra.map((hinhthuc, index) => {
                                                            return (
                                                                <div className='payment-input-money my-1' key={index}>
                                                                    <InputNumber className='w-100' prefix={hinhthuc.ten} value={hinhthuc.tongtien || 0}
                                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                        onChange={(e) => {
                                                                            if (e >= 0 && e <= 1000000000) {
                                                                                let tongtt = 0
                                                                                this.state.listRefunds.forEach(x => {
                                                                                    tongtt += ((x.soluong * x.dongia - x.soluong * x.giabh) * ((100 - x.tylegiamgia) / 100));
                                                                                });
                                                                                if (e <= tongtt) {
                                                                                    let lstLoaiTT_hoantra = this.state.lstLoaiTT_hoantra;
                                                                                    hinhthuc.tongtien = e;
                                                                                    this.setState({ lstLoaiTT_hoantra })
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                        } */}
                                                        <input className='form-control' placeholder="Nhập ghi chú" value={this.state.ghichu} onChange={(e) => this.setState({ ghichu: e.target.value })} />
                                                        <button className="btn btn-success mt-2" onClick={() => this.RefundByList()}>Xác nhận</button>
                                                    </div>}
                                                title="Bạn có chắc muốn hoàn trả dịch vụ đã chọn?"
                                                trigger="click"
                                                visible={this.state.listRefunds.length !== 0 && this.state.visible}
                                                onVisibleChange={() => { this.setState({ visible: !this.state.visible, ghichu : '' }) }}
                                            ><button className={`btn btn-${this.state.listRefunds.length === 0 ? "color" : "warning"} ml-auto`} >
                                                    Hoàn trả {this.state.listRefunds.length > 0 && `(${this.state.listRefunds.length})`}</button>
                                            </Popover>
                                        </div>
                                    </div>
                                    <Table
                                        scroll={{ x: 'max-content', y: 0 }}
                                        columns={columnTablePaid}
                                        dataSource={this.state.lstBangKe}
                                        pagination={false}
                                        rowKey={(row, index) => index}
                                        locale={{
                                            emptyText : 'Không có dữ liệu',
                                        }}
                                        rowClassName={ () => {
                                            return "ant-table-row-fixhover"
                                        }}
                                    />
                                    {this.state.lstBangKe.length > 0 &&
                                        <div className="text-center mt-2">
                                            <button onClick={() => this.PrintRefund()} className='btn btn-outline-secondary'><i className='fa fa-print' style={{ marginRight: 5 }}></i> In phiếu hoàn trả</button>
                                            {!this.props.print80mm ?
                                            <button onClick={() => this.PrintSummary()} className='btn btn-outline-secondary' style={{ marginLeft: 10 }}><i className='fa fa-print' style={{ marginRight: 5 }}></i> In bảng kê</button>
                                            :
                                            <Popover
                                                content={
                                                    <div className='d-flex flex-column'>
                                                        <button className='btn btn-color w-100 mb-2' onClick={() => { this.PrintSummary(); this.setState({ visiblePopover: false }) }}>Bản in thường</button>
                                                        <button className='btn btn-color w-100 mb-2' onClick={() => { this.PrintSummary("80mm"); this.setState({ visiblePopover: false }) }}>Bản in 80mm</button>
                                                        <button className='btn btn-danger' onClick={() => this.setState({ visiblePopover: false })}>Đóng</button>
                                                    </div>
                                                }
                                                title="Chọn bản in"
                                                trigger="click"
                                                visible={this.state.visiblePopover}
                                                onVisibleChange={() => this.setState({ visiblePopover: !this.state.visiblePopover })}
                                            >
                                                <button className='btn btn-outline-secondary' style={{ marginLeft: 10 }}><i className='fa fa-print' style={{ marginRight: 5 }}></i>In bảng kê</button>
                                            </Popover>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Báo cáo thanh toán*/}
                    {this.state.modalReport && <Modal maskClosable={false}
                        width={1300}
                        visible={this.state.modalReport}
                        onCancel={this.handleCancel}
                    >
                        <Report onCancel={() => this.setState({ modalReport: false })}
                            chinhanh={this.props.siteid}
                            data={this.state.reportDetail}
                        />
                    </Modal>
                    }
                    {/* Chi tiết phiếu thanh toán*/}
                    <Modal
                        width={1000}
                        visible={this.state.visiblePay} >
                        <div>
                            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3>Thông tin phiếu thanh toán {this.state.sohoadon && "#" + this.state.sohoadon}</h3>
                                <h3 onClick={() => { this.onCloseDetail() }}><i className='far fa-times-circle' /></h3>
                            </div>
                            <PatientInfo
                                patientInfo={
                                    [
                                        {
                                            label: "Mã khách hàng",
                                            value: this.state.patient.mabn,
                                            column: 6,
                                        },
                                        {
                                            label: "Họ và tên",
                                            value: this.state.patient.hoten,
                                            column: 6,
                                        }, 
                                        {
                                            label: "Năm sinh",
                                            value: this.state.patient.namsinh,
                                            column: 6,
                                        },
                                        {
                                            label: "Giới tính",
                                            value: Number(this.state.patient.phai) === 0 ? "Nam" : "Nữ",
                                            column: 6,
                                        }, 
                                        {
                                            label: "Điện thoại",
                                            value: this.state.patient.dt_didong,
                                            column: 6,
                                        }, 
                                        {
                                            label: "Email",
                                            value: this.state.patient.email,
                                            column: 6,
                                        },
                                        {
                                            label: "Địa chỉ",
                                            value: this.state.patient.sonha,
                                            column: 12,
                                        },
                                    ]
                                }
                                showAdditionalInfo={true}
                                additionalInfo={
                                    [
                                        {
                                            label: "Mã đăng ký/quản lý",
                                            labelWidth: 150,
                                            value: this.state.patient.maql,
                                            column: 6,
                                        },
                                        {
                                            label: "Mã kiểm tra kết quả online",
                                            labelWidth: 180,
                                            value: this.state.barcode ? this.state.barcode : "Chưa có thông tin",
                                            column: 6,
                                            className: "text-danger"
                                        },
                                        ...this.state.listVoucherUsed && this.state.listVoucherUsed.length > 0 ? [{
                                            label: "Mã giảm giá sử dụng",
                                            labelWidth: 150,
                                            value: this.state.listVoucherUsed.join(", "),
                                            column: 6,
                                        }] : [],
                                        ...this.state.reviewBillRemark ? [{
                                            label: "Ghi chú",
                                            labelWidth: 150,
                                            value: this.state.reviewBillRemark,
                                            column: 6,
                                        }] : [],
                                        ...this.state.sidbybill && this.state.sidbybill != "" ? [{
                                            label: "SID",
                                            labelWidth: 150,
                                            value: this.state.sidbybill,
                                            column: 6,
                                        }] : [],
                                    ]
                                }
                            />
                            <div className='form-group form-inline w-100'>
                                <select className="form-control" style={{ marginRight: '10px' }}
                                    disabled={this.state.disableTT}
                                    value={this.state.hinhthucthanhtoan}
                                    onChange={(e) => { this.setState({ hinhthucthanhtoan: Number(e.target.value), disableBtn: false, showBtn: true }) }}>
                                    {this.state.lstPaymentTypes && this.state.lstPaymentTypes.map((hinhthuc, index) =>
                                        <option key={hinhthuc.ma} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                                    )}
                                </select>
                                <Checkbox checked={this.state.checkvat}
                                    style={{ marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ checkvat: e.target.checked, disableBtn: false, showBtn: true })
                                    }}
                                    disabled={this.state.checkboxVAT}
                                >Xuất hóa đơn đỏ</Checkbox>
                                {this.state.isAdmin === true &&
                                    <>{!this.state.showBtn ?
                                        <button className='btn btn-color form-control'
                                            disabled={this.state.disableBtn}
                                            onClick={() => this.setState({ disableTT: false, disableBtn: true, checkboxVAT: false })}>Sửa</button> : null
                                    }

                                        {this.state.showBtn ?
                                            <button className='btn btn-color form-control'
                                                disabled={this.state.disableBtn}
                                                onClick={() => {
                                                    this.ChangePaymentType(this.state.paymentDetail.sohoadon, this.state.hinhthucthanhtoan, this.state.checkvat)
                                                }}>Lưu</button> : null}
                                    </>
                                }
                            </div>
                            <div className='text-right'>Tổng tiền: <strong>{Math.round(this.state.lstPaymentDetail.reduce((tmp, cur) =>
                                tmp + ((cur.soluong * cur.dongia * ((100 - cur.tylegiamgia) / 100) - cur.soluong * cur.giabh) * ((100 - cur.giamgia) / 100)), 0)).toLocaleString('en-US')}</strong></div>
                            <div className='table-responsive' style={{ maxHeight: '50vh', marginBottom: 10 }}>
                                <table className="table table-striped table-hover table-bordered w-100">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }}>STT</th>
                                            <th>Tên dịch vụ/Thuốc</th>
                                            <th>SL</th>
                                            <th>Đơn giá</th>
                                            <th>Thành tiền</th>
                                            {/* <th>Bảo hiểm trả</th> */}
                                            <th>Giảm trừ khác</th>
                                            <th>Bệnh nhân trả</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '0').length > 0 &&
                                            <React.Fragment>
                                                {posGK === 0 &&
                                                    <tr key={-1}>
                                                        <span className="d-none">{posGKM = posGKM + 1}</span>
                                                        <td colSpan='8' className='font-bold'>Gói khám</td>
                                                    </tr>}
                                            </React.Fragment>
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '0').map((x, index) => {
                                            let totalDiscount = (x.dongiagoc - x.dongia - x.giabh) + (x.dongia * x.tylegiamgia / 100) + (x.dongia * x.giamgia / 100 );
                                            return (
                                                <tr key={'1' + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{x.tenvp}</td>
                                                    <td>{x.soluong}</td>
                                                    <td className='text-right'>{Math.round(x.dongiagoc).toLocaleString('en-US')}</td>
                                                    <td className='text-right'>{Math.round(x.soluong * x.dongiagoc).toLocaleString('en-US')}</td>
                                                    {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                                    <td className='text-right'>{x.tylegiamgia === 0 ? Number(totalDiscount.toFixed(3)).toLocaleString('en-US') : Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                                    <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '1').length > 0 &&
                                            <tr key={-1}>
                                                <td colSpan='8' className='font-bold'>Dịch vụ</td>
                                            </tr>
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '1').map((x, index) =>{
                                            let totalDiscount = (x.dongiagoc - x.dongia - x.giabh) + (x.dongia * x.tylegiamgia / 100) + (x.dongia * x.giamgia / 100 );
                                            return (
                                            <tr key={'1' + index}>
                                                <td>{index + 1}</td>
                                                <td>{x.tenvp}</td>
                                                <td>{x.soluong}</td>
                                                <td className='text-right'>{Math.round(x.dongiagoc).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Math.round(x.soluong * x.dongiagoc).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Number(totalDiscount.toFixed(3)).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                            </tr>
                                        )})}
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '4').length > 0 &&
                                            <tr key={-2}>
                                                <td colSpan='8' className='font-bold'>Vật tư sử dụng</td>
                                            </tr>
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '4').map((x, index) => (
                                            <tr key={'1' + index}>
                                                <td>{index + 1 + lengthPayService}</td>
                                                <td>{x.tenvp}</td>
                                                <td>{x.soluong}</td>
                                                <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                                {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                                <td className='text-right'>{Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                            </tr>
                                        ))
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').length > 0 &&
                                            <tr key={-2}>
                                                <td colSpan='8' className='font-bold'>Thuốc</td>
                                            </tr>
                                        }
                                        {this.state.lstPaymentDetail.filter(x => x.loai === '2' || x.loai === '3').map((x, index) => (
                                            <tr key={'1' + index}>
                                                <td>{index + 1 + lengthPayService}</td>
                                                <td>{x.tenvp}</td>
                                                <td>{x.soluong}</td>
                                                <td className='text-right'>{Math.round(x.dongia * ((100 - x.tylegiamgia) / 100)).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Math.round(x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100))).toLocaleString('en-US')}</td>
                                                {/* <td className='text-right'>{(x.soluong * x.giabh).toLocaleString('en-US')}</td> */}
                                                <td className='text-right'>{Number((x.soluong * (x.dongia * ((100 - x.tylegiamgia) / 100) - x.giabh) * (x.giamgia / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                                <td className='text-right'>{Number(((x.soluong * x.dongia * ((100 - x.tylegiamgia) / 100) - x.soluong * x.giabh) * ((100 - x.giamgia) / 100)).toFixed(3)).toLocaleString('en-US')}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='custom-hr' />
                            <div className="text-center">
                                <button className="btn btn-outline-secondary mr-2" onClick={() => {
                                    this.PrintListPatientPayment(this.state.paymentDetail.sohoadon, this.state.paymentDetail.ngaythu, this.state.paymentDetail.maql)
                                }}
                                ><i className="fa fa-print"></i> In</button>
                                <button className="btn btn-danger" onClick={() => { this.onCloseDetail() }}>Đóng</button>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={this.state.vsbSetVoucher} maskClosable={true} onCancel={() => this.setState({ vsbSetVoucher: false })} width={800}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Chọn mã giảm giá</h3>
                        </div>
                        <Input value={this.state.voucher}
                            onChange={(e) => {
                                this.setState({ voucher: e.target.value, warningVoucher: null });
                                if (e.target.value === "")
                                    this.getListPromotions();
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.getListVoucher(e.target.value);
                                }
                            }}
                            placeholder='Nhập mã khuyến mại và bấm Enter để tìm'
                        ></Input>
                        {this.state.warningVoucher && <span style={{ color: "#ff4d4f" }}>{this.state.warningVoucher}</span>}
                        {/* {this.state.listVouchers.length = 0 && <span style={{ color: "#ff4d4f" }}>Chương trình khuyến mãi không khả dụng (Hết hạn, đã hết mã khuyến mãi,...)</span>} */}
                        {this.state.listServiceUseVoucher && this.state.listServiceUseVoucher.length > 0 &&
                            <div className='mt-2 mb-2'>
                                <label>Đã chọn tặng dịch vụ: </label>
                                {this.state.listServiceUseVoucher.map((data) => {
                                    return (
                                        this.state.lstServicesPatient.find(x => x.mavp === data.value) !== undefined && <Tag color="lime" key={`vc-${data.value}`}>{this.state.lstServicesPatient.find(x => x.mavp === data.value).tenvp}</Tag>
                                    )
                                })}
                                <Tooltip title="Bỏ chọn tất cả">
                                    <i className='fas fa-times text-danger' onClick={() => this.setState({ listServiceUseVoucher: [], lstServicesPatient: [] }, this.LoadDetailServices(this.state.patient))} />
                                </Tooltip>
                            </div>}
                        {this.state.listVouchers.length > 0 &&
                            <table className="table table-striped table-hover table-bordered w-100 mt-2">
                                <thead>
                                    <tr style={{ textAlign: 'center', fontSize: '13px' }}>
                                        <th style={{ width: '50px' }}>STT</th>
                                        {!this.state.isLoyalty && <th>Mã</th>}
                                        <th>Mức giảm</th>
                                        {this.state.isLoyalty && <th>Thành viên/Chương trình</th>}
                                        {!this.state.isLoyalty && <th>Tên chương trình</th>}
                                        <th>Ghi chú</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listVouchers.map((vc, index) => (
                                        <tr key={index} className={this.state.selectedVoucher === vc.code && this.state.voucherid === vc.id ? "rowCustomSelectActive" : ""}>
                                            <td style={{ textAlign: 'center' }}>{this.state.selectedVoucher === vc.code && this.state.voucherid === vc.id ? <i className="far fa-check-circle"></i> : index + 1}</td>
                                            {!this.state.isLoyalty && <td>{vc.code}</td>}
                                            <td style={{ textAlign: 'center' }}>{Number(vc.discount.substr(0, vc.discount.length - 1)).toLocaleString("en-US")}{vc.discount.includes("$") ? " đồng" : "%"}</td>
                                            {this.state.isLoyalty && <td>{vc.loyalgroup === "" ? <span><Tag color="cyan">CTKM</Tag>{vc.campaign}</span> : vc.loyalgroup}</td>}
                                            {!this.state.isLoyalty && <td>{vc.campaign}</td>}
                                            <td>{vc.ghichu}</td>
                                            <td style={{ width: '120px', textAlign: 'center' }}>
                                                <button className={`btn btn-${this.state.selectedVoucher === vc.code && this.state.voucherid === vc.id ? "danger" : "success"}`}
                                                    disabled={this.state.listServiceUseVoucher.find(x => x.label === vc.code) !== undefined}
                                                    onClick={() => {
                                                        if (vc.serviceid === 0) {
                                                            this.setState({ voucherid : vc.id ,selectedVoucher: vc.code, loyal: vc.loyalgroup, campaignid: vc.campaignid, ghichupromo : vc.ghichu });                                                           
                                                            if (vc.discount.includes("%")) {
                                                                this.setState({ optionDiscount: 1, giamgia: Number(vc.discount.replace("%", "")) });
                                                            } else if (vc.discount.replace("$", "") >= tongthanhtoan - totalMoneyWithMedicine) {
                                                                this.setState({ optionDiscount: 2, tienGiam: Number(tongthanhtoan - totalMoneyWithMedicine), giamgia: 100 });
                                                            } else {
                                                                this.setState({
                                                                    optionDiscount: 2,
                                                                    tienGiam: tongthanhtoan - totalMoneyWithMedicine > 0 ? Number(vc.discount.replace("$", "")) : 0,
                                                                    giamgia: tongthanhtoan - totalMoneyWithMedicine > 0 ? Number(vc.discount.replace("$", "")) * 100 / (tongthanhtoan - totalMoneyWithMedicine) : 0,
                                                                })
                                                            }
                                                            if (this.state.selectedVoucher === vc.code && this.state.voucherid === vc.id) {
                                                                this.setState({ selectedVoucher: "", tienGiam: 0, giamgia: 0, ghichupromo: null, voucherid : 0 });
                                                            }
                                                        } else {
                                                            var serviceFound = this.state.lstServicesPatient.find(ser => ser.mavp === vc.serviceid);
                                                            if (serviceFound === undefined) notification.warning({ message: "Không có dịch vụ áp dụng được mã này" });
                                                            else {
                                                                var serviceFoundIndex = this.state.lstServicesPatient.findIndex(record => record.mavp === vc.serviceid);
                                                                var serviceFoundNewValue = this.state.lstServicesPatient[serviceFoundIndex];
                                                                serviceFoundNewValue.dongia = 0;
                                                                serviceFoundNewValue.dongiakm = 0;
                                                                serviceFoundNewValue.thanhtien = 0;
                                                                this.setState({
                                                                    lstServicesPatient: this.state.lstServicesPatient,
                                                                })
                                                                this.state.listServiceUseVoucher.find(x => x.value === vc.serviceid) === undefined
                                                                    &&
                                                                    this.setState({
                                                                        listServiceUseVoucher: [...this.state.listServiceUseVoucher, { value: vc.serviceid, label: vc.code }],
                                                                    })
                                                                notification.success({ message: "Đã áp dụng thành công" });
                                                            }
                                                        }
                                                    }}>{this.state.selectedVoucher === vc.code && this.state.voucherid === vc.id ? "Bỏ chọn" : "Chọn"}</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}
                        <button className="btn btn-success mt-2" onClick={() => this.setState({ vsbSetVoucher: false, warningVoucher: "" }, () => {this.getPaymentMethod(); this.getPaymentMethodRefund() })}>Hoàn tất</button>
                    </Modal>
                    <Modal maskClosable={false}
                        visible={this.state.vatinvoice && this.state.vsbCustomerVATInfo} width={1000}>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Thông tin phiếu xuất hóa đơn đỏ</h3>
                        </div>
                        <CustomerVATInfo disableChange={false} info={{ name: this.state.patient.hoten, maql: this.state.patient.maql }}
                            onClose={() => this.setState({ vsbCustomerVATInfo: false })} />
                    </Modal>
                    <Drawer 
                        placement='left'
                        key={'left'}
                        closable={false}
                        visible={this.state.visiblePromotion}
                        width={'40%'}
                        className='custom-ant-drawer'
                        maskClosable={false}
                    >
                        <AppliedVoucher
                            loyaltyType = {this.state.loyaltyType} //Type = 0 là voucher, Type = 1 là dịch vụ
                            isView = {false}
                            patient={this.state.patient} 
                            listSelectedServices={this.state.lstServicesPatient}
                            selectedPromotion={this.state.selectedPromotion}
                            onCancel={() => {this.setState({visiblePromotion : false})}}
                            onSubmit={(promotion) => this.SubmitVoucher(promotion)} 
                            />
                    </Drawer>
                    
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        print80mm: state.global.sysConfig.print80mm ? state.global.sysConfig.print80mm : false,
        payMedicineAtCashDesk: state.global.sysConfig.payMedicineAtCashDesk ? state.global.sysConfig.payMedicineAtCashDesk : false,
        marketingRequired: state.global.sysConfig.marketingRequired ? state.global.sysConfig.marketingRequired : false,
        administrativeRequired: state.global.sysConfig.administrativeRequired ? state.global.sysConfig.administrativeRequired : true,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PayMent)