import { Button, Form, Input, Select, Table, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import SelectRoomDetail from './selectRoomDetail'
/* props truyền vào gồm:
- dataSource = {
    "makp": "125", //mã khoa
    "phongkham": "138", //mã phòng
    "tenphong": "Ngoại 2", //tên phòng
    "maphong": "138", //mã phòng
    
    "banggia": "Bảng giá 12",
    "soluong": 1,
    "idChiDinh": "0",
    "madoituong": "2",
    "dongia": 100000,
    "ngay": "2023/05/24 10:16",
    "mabn": "",
    "id": 867, // id của viện phí
    "mavp": "KCC01",
    "ten": "Khám cấp cứu",
    "groupId": 8,
    "listChild": null,
    "typeId": 28,
    "typeName": "Khám bệnh",
    "isCombo": false,
    "dongiakm": null,
    "discount": null,
}
*/
export default (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceFull, setDataSourceFull] = useState([]);
    useEffect(() => {
        // if (props.dataSource)
        //     setDataSource(props.dataSource)
        // props.item của selectRoomDetail: {makp: 'mã khoa', phongkham: 'mã phòng'}
        if (props.dataSource) {
            let dts = [];
            props.dataSource.forEach(item => {
                dts.push(item);
                if (item.isCombo && item.listChild) {
                    item.listChild.forEach(itemChild => {
                        itemChild.vienPhiIdOfCombo = item.id;
                        itemChild.isChild = true;
                        dts.push(itemChild);
                    })
                }
            });
            setDataSourceFull(dts);
        }
    }, [props.dataSource])

    useEffect(() => {
        let dts = [];
        dataSourceFull.forEach(item => {
            if (!item.isChild) {
                dts.push(item);
            }
        });
        setDataSource(dts);
    }, [dataSourceFull])

    const editPhongKham = (row, dataPK) => {
        let listSelectedServices = dataSourceFull;
        let index = listSelectedServices.findIndex(record => record.id === row.id);
        if(listSelectedServices[index].status_kham && listSelectedServices[index].status_kham !== ""){
            notification.warning({ message: "Dịch vụ đã khám hoặc đang khám. Không thể đổi phòng." });
            return;
        }
        listSelectedServices[index].makp = dataPK.makhoa;
        listSelectedServices[index].maphong = dataPK.maphong;
        listSelectedServices[index].phongkham = dataPK.maphong;
        listSelectedServices[index].tenphong = dataPK.tenphong;
        // setDataSource(listSelectedServices.map(x => ({ ...x })))
        //region 2023.05.18
        if (row.isChild) {
            let indexCombo = listSelectedServices.findIndex(record => record.id === row.vienPhiIdOfCombo);
            let indexChild = listSelectedServices[indexCombo].listChild.findIndex(record => record.id === row.id);
            listSelectedServices[indexCombo].listChild[indexChild].makp = dataPK.makhoa;
            listSelectedServices[indexCombo].listChild[indexChild].maphong = dataPK.maphong;
            listSelectedServices[indexCombo].listChild[indexChild].phongkham = dataPK.maphong;
            listSelectedServices[indexCombo].listChild[indexChild].tenphong = dataPK.tenphong;
        }
        setDataSourceFull(listSelectedServices.map(x => ({ ...x })));
    }
    return (
        <React.Fragment>
            <div className='row p-2'>
                <div className='col-1 mb-2'>
                    <div className='h4 font-weight-bold'>STT</div>
                </div>
                <div className='col-11 mb-2'>
                    <div className='h4 font-weight-bold'>Dịch vụ</div>
                </div>
                {dataSourceFull.filter(x => x.groupId === 8)?.map((row, index) => <React.Fragment key={index}>
                    <div className='col-1 mb-2'>
                        <div className='h4 font-weight-bold'>{index + 1}</div>
                    </div>
                    <div className='col-11 mb-2'>
                        <div className='h4 font-weight-bold'>{row.ten}</div>
                        <SelectRoomDetail
                            item={row}
                            onSubmit={(value) => {
                                editPhongKham(row, value)
                            }}
                        />
                    </div>
                </React.Fragment>)}
                <div className='col-12 mb-2 text-center'>
                    <Button className='btn btn-secondary mr-1' onClick={() => {
                        if (props.onClose) props.onClose()
                    }}
                    >Đóng</Button>
                    <Button className='btn btn-color ml-1' onClick={() => {
                        if (props.onSubmit) props.onSubmit(dataSource)
                    }}
                    >Lưu</Button>
                </div>
            </div>
        </React.Fragment>
    )
}
