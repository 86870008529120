import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Configure';

export function GetListAccount(key, page, size)  {
    if(key === undefined) key = '';
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/listuser?key=${key}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CreateAccount(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/createAccount`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function checkUserID(userid)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/search?userId=${userid}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function UpdateAccount(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/update`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DeleteAccount(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deleteAccount/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListGroupUser(key, page, size)  {
    if(key === undefined) key = '';
    if(page === undefined) page = 1;
    if(size === undefined) size = 50;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListGroupUser?key=${key}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function GetListUsersOfGroup(groupid, key, page, size, getAll = false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListUsersOfGroup?groupid=${groupid}&key=${key}&page=${page}&size=${size}&getAll=${getAll}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListAccountForSelect(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListAccountForSelect`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};