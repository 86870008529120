import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, InputNumber, Button, Radio, Select, Tag, Switch, Popover, Modal, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import Notify, { NotifyStatus } from '../../../components/notify';
import SelectProduct from '../../../components/MDCLS/selectProduct';
import * as CardTypeService from '../../../services/CardTypeService';

const { Option } = Select;
const { TextArea } = Input;
const fieldProductEnum = {
  cardTypeProducts: 'cardTypeProducts', cardTypeProductFrees: 'cardTypeProductFrees', cardTypeProductExcludes: 'cardTypeProductExcludes'
};
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [isDisableFewField, setIsDisableFewField] = useState(false); //disable các field không được sửa khi đã có tài khoản
  const [visibleSelectProduct, setVisibleSelectProduct] = useState(false);
  const [fieldSelectProduct, setFieldSelectProduct] = useState();
  const [selectedListServices, setSelectedListServices] = useState();
  //validate
  const [validateDuplicateExcludeVsFree, setValidateDuplicateExcludeVsFree] = useState(false);
  const [validateDuplicateProductVsFree, setValidateDuplicateProductVsFree] = useState(false);
  const [validateExpiredTimeInteger, setValidateExpiredTimeInteger] = useState(false);

  //result model
  const [cardTypeCode, setCardTypeCode] = useState('');
  const [cardTypeName, setCardTypeName] = useState('');
  const [cardTypeProductExcludes, setCardTypeProductExcludes] = useState([]);
  const [cardTypeProductFrees, setCardTypeProductFrees] = useState([]);
  const [cardTypeProducts, setCardTypeProducts] = useState([]);
  const [cardTypeValue, setCardTypeValue] = useState();
  const [discountPercent, setDiscountPercent] = useState();
  const [expiredTime, setExpiredTime] = useState();
  const [expiredTimeType, setExpiredTimeType] = useState('month');
  const [expiredTimeTypeText, setExpiredTimeTypeText] = useState();
  const [hasRefund, setHasRefund] = useState(true);
  const [cardTypeId, setCardTypeId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [isAllProductPreferential, setIsAllProductPreferential] = useState(true);
  const [remarkOther, setRemarkOther] = useState();
  const [hasAccount, setHasAccount] = useState(false);
  /*
  hasAccount: check xem loại thẻ này có tài khoản hay không.
  Nếu hasAccount=true (nghĩa là loại thẻ này đang được sử dụng), thì form edit sẽ không cho sửa (readonly) các field sau:
  - cardTypeCode
  - cardTypeName
  - cardTypeProductExcludes
  - cardTypeProductFrees
  - cardTypeProducts
  - cardTypeValue
  - discountPercent
  - expiredTime
  - expiredTimeType
  - expiredTimeTypeText
  - hasRefund
  - isAllProductPreferential
  trừ các field
  - isActive
  - remarkOther
   */
  //#endregion 
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    getById(cardTypeId);
  }, [cardTypeId]);

  useEffect(() => {
    setCardTypeId(props.cardTypeId);
  }, [props.cardTypeId]);

  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
      if (props.detailScreenMode == detailScreenModeEnum.create)
        setHeaderText('Tạo mới - Loại thẻ');
  }, [props.detailScreenMode]);

  useEffect(() => {
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setCardTypeCode(data.cardTypeCode);
    setCardTypeName(data.cardTypeName);
    setCardTypeProductExcludes(data.cardTypeProductExcludes ?? []);
    setCardTypeProductFrees(data.cardTypeProductFrees ?? []);
    setCardTypeProducts(data.cardTypeProducts ?? []);
    setCardTypeValue(data.cardTypeValue);
    setDiscountPercent(data.discountPercent);
    setExpiredTime(data.expiredTime);
    setExpiredTimeType(data.expiredTimeType);
    setExpiredTimeTypeText(data.expiredTimeTypeText);
    setHasRefund(data.hasRefund);
    // setId(data.id);
    setIsActive(data.isActive);
    setIsAllProductPreferential(data.isAllProductPreferential);
    setRemarkOther(data.remarkOther);
    setHasAccount(data.hasAccount);

    if (detailScreenMode == detailScreenModeEnum.update && data.hasAccount) setIsDisableFewField(true);
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - ' + data.cardTypeName);
    else if (props.detailScreenMode == detailScreenModeEnum.update)
      setHeaderText('Cập nhật - ' + data.cardTypeName);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    CardTypeService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const model = {
    cardTypeCode: '',
    cardTypeName : '',
    cardTypeProductIdExcludes : [],
    cardTypeProductIdFrees : [],
    cardTypeProductIds : [],
    cardTypeValue : null,
    discountPercent : null,
    expiredTime : null,
    expiredTimeType : '',
    // expiredTimeTypeText : '',
    hasRefund : true,
    id : '',
    isActive : true,
    isAllProductPreferential : true,
    remarkOther : ''
    // hasAccount : ''
  };

  const setModel = () => {
    model.cardTypeCode = cardTypeCode;
    model.cardTypeName = cardTypeName;
    model.cardTypeProductIdExcludes = cardTypeProductExcludes?.map(x => x.productId);
    model.cardTypeProductIdFrees = cardTypeProductFrees?.map(x => x.productId);
    model.cardTypeProductIds = cardTypeProducts?.map(x => x.productId);
    model.cardTypeValue = cardTypeValue;
    model.discountPercent = discountPercent;
    model.expiredTime = expiredTime;
    model.expiredTimeType = expiredTimeType;
    model.hasRefund = hasRefund;
    model.id = cardTypeId;
    model.isActive = isActive;
    model.isAllProductPreferential = isAllProductPreferential;
    model.remarkOther = remarkOther;
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    CardTypeService.create(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    CardTypeService.update(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const [duplicateIds, setDuplicateIds] = useState([]);

  const validate = () => {
    let isValid = true;
    setValidateDuplicateExcludeVsFree(false);
    setValidateDuplicateProductVsFree(false);
    setValidateExpiredTimeInteger(false);
    let dup = [];
    if (isAllProductPreferential) {
      let exludeIds = cardTypeProductExcludes.map(x=>x.productId);
      let freeIds = cardTypeProductFrees.map(x=>x.productId);
      dup = exludeIds.filter(x => freeIds.indexOf(x) > -1);
      if (dup?.length) {
        isValid=false;
        setValidateDuplicateExcludeVsFree(true);
      }
    }
    else {
      let productIds = cardTypeProducts.map(x=>x.productId);
      let freeIds = cardTypeProductFrees.map(x=>x.productId);
      dup = productIds.filter(x => freeIds.indexOf(x) > -1);
      if (dup?.length) {
          isValid=false;
          setValidateDuplicateProductVsFree(true);
      }
    }
    setDuplicateIds(dup);
    if (!Number.isInteger(expiredTime)) {
      isValid=false;
      setValidateExpiredTimeInteger(true);
    }
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && cardTypeId) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const onConfirmSelectProductHandle = (arrayResult = []) => {
    let arrProductSelected = convertSelectComponentToProduct(arrayResult.filter(x => !x.uiDeleted));
    switch (fieldSelectProduct) {
      case fieldProductEnum.cardTypeProductExcludes:
        setCardTypeProductExcludes(arrProductSelected ?? []);
        break;
      case fieldProductEnum.cardTypeProductFrees:
        setCardTypeProductFrees(arrProductSelected ?? []);
        break;
      case fieldProductEnum.cardTypeProducts:
        setCardTypeProducts(arrProductSelected ?? []);
        break;
      default:
        break;
    }
    notification.success({ message: "Xác nhận thành công" });
  }

  const convertProductToSelectComponent = (arrProduct) => {
    return arrProduct?.map(x => {
      return {
        id: x.productId,
        ma: x.productCode,
        ten: x.productName,
        allowDelete: !isDisableFewField,
        uiStatus: x.uiStatus,
        uiDeleted: x.uiDeleted
      }
    })
  }

  const convertSelectComponentToProduct = (arrProductFromSelectComponent) => {
    return arrProductFromSelectComponent.map(x => {
      return {
        productId: x.id,
        productCode: x.ma,
        productName: x.ten,
        // allowDelete: isDisableFewField,
        uiStatus: x.uiStatus,
        uiDeleted: x.uiDeleted
      }
    })
  }

  const showSelectProductComponent = (e) => {
    setVisibleSelectProduct(true); 
    setFieldSelectProduct(e);
    switch (e) {
      case fieldProductEnum.cardTypeProductExcludes:
        setSelectedListServices(convertProductToSelectComponent(cardTypeProductExcludes));
        break;
      case fieldProductEnum.cardTypeProductFrees:
        setSelectedListServices(convertProductToSelectComponent(cardTypeProductFrees));
        break;
      case fieldProductEnum.cardTypeProducts:
        setSelectedListServices(convertProductToSelectComponent(cardTypeProducts));
        break;
      default:
        break;
    }
  }

  const geneListStringToTag = (list = [], fieldProduct) => {
    return list?.map((x, index) =>
      <Tag key={x.productId} style={{whiteSpace: "pre-line"}}
        color={duplicateIds.indexOf(x.productId) > -1 ? "error" : ""}
        closable={detailScreenMode != detailScreenModeEnum.detail && !isDisableFewField}
        onClose={() => {/**screen create & edit (chưa có account) thì được sửa */
          switch (fieldProduct) {
            case fieldProductEnum.cardTypeProductExcludes:
              setCardTypeProductExcludes(cardTypeProductExcludes?.filter(c => c.productId != x.productId));
              break;
            case fieldProductEnum.cardTypeProductFrees:
              setCardTypeProductFrees(cardTypeProductFrees?.filter(c => c.productId != x.productId));
              break;
            case fieldProductEnum.cardTypeProducts:
              setCardTypeProducts(cardTypeProducts?.filter(c => c.productId != x.productId));
              break;
            default:
              break;
          }
        }}
      >{x.productName}
      </Tag>
    )
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mã loại thẻ / Hoạt động*/}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mã loại thẻ</label>
                  </div>
                  <Input
                    value={cardTypeCode}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={isDisableFewField}
                    onChange={(e) => setCardTypeCode(e.target.value)}
                  ></Input>
                  {validator.current.message("Mã loại thẻ", cardTypeCode, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Hoạt động</label>
                  <div>
                    <Switch 
                      // disabled={detailScreenMode==detailScreenModeEnum.detail || hasAccount}
                      checked={isActive} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsActive(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Tên loại thẻ */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên loại thẻ</label>
                  </div>
                  <Input
                    value={cardTypeName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={isDisableFewField}
                    onChange={(e) => setCardTypeName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên loại thẻ", cardTypeName, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Giá trị / Giảm giá */}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Giá trị</label>
                  </div>
                  <InputNumber
                    className="ant-input-number-text-right"
                    addonBefore="đ"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={isDisableFewField}
                    min={0}
                    max={1000000000000}
                    step='1000000'
                    value={ cardTypeValue }
                    onChange={(value) => {setCardTypeValue(value)}}
                  />
                  {validator.current.message("Giá trị", cardTypeValue, "required|numeric|min:1000,num|max:1000000000000,num")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Giảm giá</label>
                  </div>
                  <InputNumber
                    className="ant-input-number-text-right"
                    addonAfter="%"
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={isDisableFewField}
                    min={0}
                    max={100}
                    step={5}
                    value={discountPercent?.toLocaleString('en-US') }
                    onChange={(value) => setDiscountPercent(value)}
                  />
                  {validator.current.message("Giảm giá", discountPercent, "required|numeric|min:1,num|max:100,num")}
                </div>
              </div>
              <div className="row mt-2"> {/* Hạn sử dụng / Trả lại tiền */}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Hạn sử dụng</label>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="w-100">
                      <InputNumber
                        className="ant-input-number-text-right w-100"
                        readOnly={detailScreenMode==detailScreenModeEnum.detail}
                        disabled={isDisableFewField}
                        min={1}
                        max={1000}
                        value={ expiredTime }
                        onChange={(value) => setExpiredTime(value)}
                      />
                      {validator.current.message("Hạn sử dụng", expiredTime, "required|numeric|min:1,num|max:1000,num")}
                      <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
                        {validateExpiredTimeInteger ? "Không được nhập số thập phân" : ""}
                      </div>
                    </div>
                    <div style={{ minWidth:"134px" }}>
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[{label: 'Tháng', value: 'month' }, {label: 'Năm', value: 'year' },]}
                        // disabled={isReadOnlyFewField}
                        value={expiredTimeType?.toLowerCase()}
                        onChange={(e) => {
                          //Radio, Switch không có readonly. Mà dùng disabled thì cái mày nó xấu. nên dùng cách này
                          if (detailScreenMode == detailScreenModeEnum.detail || isDisableFewField) return;
                          setExpiredTimeType(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Trả lại tiền</label>
                  <div>
                    <Switch
                      disabled={isDisableFewField}
                      checked={hasRefund} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setHasRefund(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Dịch vụ đi kèm */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <Popover
                      content={
                        <span>
                          Lựa chọn các dịch vụ được hưởng giảm giá.<br/>
                          Nếu chọn "Tất cả" sẽ chọn tất cả dịch vụ đang có ở thời điểm hiện tại.
                        </span>
                      }
                      trigger="click"
                    >
                      <Button type="link" className="p-0" title="Click để xem hướng dẫn"><i className="far fa-question-circle"></i></Button>
                    </Popover>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Dịch vụ đi kèm</label>
                    <Radio.Group className="ml-2"
                      options={[{label: 'Tất cả', value: true }, {label: 'Lựa chọn', value: false },]}
                      disabled={hasAccount}
                      onChange={(e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsAllProductPreferential(e.target.value);}}
                      value={isAllProductPreferential}
                    />
                  </div>
                  <div style={{ maxHeight: 250, overflow: "auto", padding: "2px 0" }} hidden={isAllProductPreferential}>
                    <MitaDisplayListElement
                      content={() => geneListStringToTag(cardTypeProducts, fieldProductEnum.cardTypeProducts) }
                      hidden={isAllProductPreferential}
                      maxLength={5} // mặc định 3
                      showMoreText={ showMoreText }
                      showLessText={ showLessText }
                      // displayMode="popup"
                      // elementDisplay="block"
                    />
                  </div>
                  {validator.current.message("Dịch vụ đi kèm", cardTypeProducts.length, !isAllProductPreferential ? "numeric|min:1,num" : "numeric")}
                  <Button className="mt-1"
                      hidden={detailScreenMode==detailScreenModeEnum.detail}
                      disabled={ isAllProductPreferential || isDisableFewField }
                      onClick={() => { showSelectProductComponent(fieldProductEnum.cardTypeProducts); }}
                  ><i className="fa fa-external-link-alt mr-1"></i>
                      Tìm kiếm và chọn dịch vụ
                  </Button>
                </div>
              </div>
              <div className="row mt-2"> {/* Loại trừ các dịch vụ */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <Popover
                      content={
                        <span>
                          Lựa chọn các dịch vụ KHÔNG được hưởng giảm giá.<br/>
                          Chỉ được chọn khi "Dịch vụ đi kèm" là "Tất cả".<br/>
                          Vì khi "Dịch vụ đi kèm" là "Lựa chọn", thì các dịch vụ còn lại là không được hưởng "Giảm giá";
                        </span>
                      }
                      trigger="click"
                    >
                      <Button type="link" className="p-0" title="Click để xem hướng dẫn"><i className="far fa-question-circle"></i></Button>
                    </Popover>
                    <label>Loại trừ các dịch vụ</label>
                  </div>
                  <div style={{ maxHeight: 250, overflow: "auto", padding: "2px 0" }} hidden={!isAllProductPreferential}>
                    <MitaDisplayListElement
                      content={() => geneListStringToTag(cardTypeProductExcludes, fieldProductEnum.cardTypeProductExcludes) }
                      maxLength={5} // mặc định 3
                      showMoreText={ showMoreText }
                      showLessText={ showLessText }
                      // displayMode="popup"
                      // elementDisplay="block"
                    />
                  </div>
                  <Button className="mt-1"
                      hidden={detailScreenMode==detailScreenModeEnum.detail}
                      disabled={ !isAllProductPreferential || isDisableFewField }
                      onClick={() => { showSelectProductComponent(fieldProductEnum.cardTypeProductExcludes); }}
                  ><i className="fa fa-external-link-alt mr-1"></i>
                      Tìm kiếm và chọn dịch vụ
                  </Button>
                </div>
              </div>
              <div className="row mt-2"> {/* Dịch vụ tặng kèm */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <Popover
                      content={
                        <span>
                          Lựa chọn các dịch vụ được tặng.
                        </span>
                      }
                      trigger="click"
                    >
                      <Button type="link" className="p-0" title="Click để xem hướng dẫn"><i className="far fa-question-circle"></i></Button>
                    </Popover>
                    <label>Dịch vụ tặng kèm</label>
                  </div>
                  <div style={{ maxHeight: 250, overflow: "auto", padding: "2px 0" }}>
                    <MitaDisplayListElement
                      content={() => geneListStringToTag(cardTypeProductFrees, fieldProductEnum.cardTypeProductFrees) }
                      hidden={isAllProductPreferential}
                      maxLength={5} // mặc định 3
                      showMoreText={ showMoreText }
                      showLessText={ showLessText }
                      // displayMode="popup"
                      // elementDisplay="block"
                    />
                  </div>
                  <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
                    {validateDuplicateProductVsFree ? "Dịch vụ tặng kèm và Dịch vụ đi kèm không nên trùng nhau" : ""}
                    {validateDuplicateExcludeVsFree ? "Dịch vụ tặng kèm và Loại trừ dịch vụ không nên trùng nhau" : ""}
                  </div>
                  <Button className="mt-1"
                      disabled={ isDisableFewField }
                      hidden={detailScreenMode==detailScreenModeEnum.detail}
                      onClick={() => { showSelectProductComponent(fieldProductEnum.cardTypeProductFrees); }}
                  ><i className="fa fa-external-link-alt mr-1"></i>
                      Tìm kiếm và chọn dịch vụ
                  </Button>
                </div>
              </div>
              <div className="row mt-2"> {/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Ghi chú</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={remarkOther}
                    onChange={(e) => setRemarkOther(e.target.value)}
                  ></TextArea >
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
      <Modal maskClosable={false}
          width={1400}
          height="calc(100% - 10px)"
          visible={visibleSelectProduct}
          onCancel={() => setVisibleSelectProduct(false) }
          destroyOnClose={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <SelectProduct
            isDialog={ true } 
            // selectedListServices={selectedListServices}
            selectedListServices={[
              ...(selectedListServices ?? []),
              // ...[
              //   {id: 867, ten: 'Khám cấp cứu', ma: 'KCC01', allowDelete: false},
              //   {id: 1516, ten: 'MIỄN DỊCH TEST NHANH', ma: '2-1516', allowDelete: false},
              // ]
            ]}
            onCancel={() => {setVisibleSelectProduct(false)}}
            onConfirm={(arrayResult) => { onConfirmSelectProductHandle(arrayResult); }}
            />
      </Modal>
    </React.Fragment>
  );
})