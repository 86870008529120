import { Button, DatePicker, Form, Pagination, Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService';
import Detail from './chitiet'
import { FORMAT_DATE } from '../../../configure/systemConfig';

const { RangePicker } = DatePicker;
export default function XemHoSo() {
    const [ngay, setngay] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [mabs, setMabs] = useState('');
    const [patient, setPatient] = useState({});
    const [visiable, setVisiable] = useState(false);
    useEffect(() => {
        DanhSachHoSo();
    }, [ngay, page, size]);
    function DanhSachHoSo() {
        Actions.setLoading(true);
        PatientService.DanhSachHoSoTheoNgay(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'), key, page, size, mabs).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    return (
        <React.Fragment>
            <div style={{ padding: "5px 20px" }}>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Danh sách hồ sơ bệnh nhân</div>
                <div className="d-flex justify-content-center my-1">
                    <div style={{ width: 250 }} className="mr-2">
                        <Form.Item>
                            <RangePicker allowClear={false} format="DD-MM-YYYY" value={ngay}
                                locale={viVN}
                                onChange={(value => setngay(value))}
                            />
                        </Form.Item>
                    </div>
                    <div className="d-flex">
                        <Form.Item>
                            <Button className="btn btn-success"
                                onClick={() => { DanhSachHoSo() }}>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />
                                Tìm
                            </Button>
                        </Form.Item>
                    </div>
                </div>
                <div className="table-responsive" style={{ backgroundColor: '#fff' }}>
                    <table className="table table-striped table-inverse table-hover mb-0">
                        <thead className="thead-inverse">
                            <tr>
                                <th>Ngày</th>
                                {/* <th>Số khám</th> */}
                                <th>Mã BN</th>
                                <th>Họ tên</th>
                                <th>Ngày sinh</th>
                                <th>Giới tính</th>
                                <th>Điện thoại</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.map((row, index) =>
                                <tr key={index} style={{ cursor: 'pointer' }}
                                    onClick={() => { setPatient(row); setVisiable(true) }}
                                >
                                    <td>{moment(row.applyDate).format(FORMAT_DATE)}</td>
                                    {/* <td>{row.enterNumber}</td> */}
                                    <td>{row.patientID}</td>
                                    <td>{row.patientName}</td>
                                    <td>{moment(row.birthDay).format(FORMAT_DATE)}</td>
                                    <td>{row.gender === 0 ? 'Nam' : 'Nữ'}</td>
                                    <td>{row.phone}</td>
                                    <td>{row.email}</td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                    total={totalRecord}
                    showTotal={total => `Tổng: ${total}`}
                    size={size}
                    current={page}
                    onChange={(page, size) => {
                        setPage(page);
                        setSize(size)
                    }}
                    onShowSizeChange={(page, size) => {
                        setPage(1);
                        setSize(size)
                    }
                    }
                    showSizeChanger={true}
                />
            </div>
            {visiable &&
                <Modal
                    visible={visiable}
                    onCancel={() => setVisiable(false)}
                    width={1000}
                >
                    <Detail patient={patient} onClose={() => setVisiable(false)} />
                </Modal>
            }
        </React.Fragment>
    )

}
