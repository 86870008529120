import { Button, Checkbox, DatePicker, Form, Input, Modal, notification, Select, Table, TimePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as NhapkhoaService from '../../../services/nhapkhoaService';
import * as NhapVienService from '../../../services/nhapVienService';
import EnterInfo from '../components/enterInfo';
import * as SystemConfig from "../../../configure/systemConfig";

const { Option } = Select;
const { RangePicker } = DatePicker;
const initialState = {
    cardNumber: '',
    cardCode: '',
    cardAddress: '',
    route: false,
    relative: false,
    relativeName: '',
    relativeAddress: '',
    relativePhone: '',
    receiveFrom: '',
    switchFrom: '',
    switchFromName: '',
    doctorReceive: '',
    timesInto: 1,
    nearPoor: false,
    nearPoorCode: '',
    cdKKB: '',
    cdKKBName: '',
    cdDepartment: '',
    otherDiseases: [],
    room: '',
    saveCode: '',
}
class NhapVien extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            enterCode: '',
            enterNumber: '',
            managerId: '',
            detail: {},
            dateInHospital: moment(),
            timeInHospital: moment().format('HH:mm'),
            department: '',
            subject: '',
            cicd10: '',
            lstCICD10: [],
            lstdepartment: [],
            nhapXuatKhoa: false,
            patient: '',
            lstPatient: '',
            totalPatient: 0,
            clinical: '',
            lstClinical: [],
            lstReceiveFrom: [],
            lstDoctorReceive: [],
            lstSwitchFrom: [],
            lstCdFrom: [],
            lstDSNhapVien: [],
            ngay: [moment(), moment()],
            checkDept: false,
            visible: false,
            lstOtherDiseases: [],
        };
    }
    componentDidUpdate() {
    }
    loadClinical() {
        NhapkhoaService.DanhSachBenhAn().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstClinical: result.data,
                    clinical: result.data[0].value
                });
            }
            else {
                this.setState({
                    clinical: '',
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh án lỗi' })
        })
    }
    componentDidMount() {
        this.loadNhanTu();
        this.loadKhoaCanNhap();
        this.loadDoctor();
        this.loadClinical();
        this.DanhSachNhapVien();
    }
    ChiTietNhapVien(mabn, maql, mavv) {
        NhapVienService.ThongTinBenhNhap(mabn, maql, mavv).then(result => {
            if (result.isSuccess) {
                this.loadThongTinVaoVien(result.item)
            }
        }).catch(err => {
        })
    }
    DanhSachNhapVien() {
        Actions.setLoading(true);
        NhapVienService.DanhSachNhapVien(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                let data = [];
                if (this.state.checkDept === false) {
                    data = result.data.filter(x => x.enterDept === 0 && x.leftDept === 0);
                } else {
                    data = result.data.filter(x => x.enterDept !== 0 || x.leftDept !== 0);
                }
                this.setState({
                    lstDSNhapVien: data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    loadThongTinVaoVien(patient) {
        let lstCD = patient.lstOtherDiseases.map(x => ({ 'label': x.children, 'value': x.value }))
        this.setState({
            saveCode: patient.saveCode,
            receiveFrom: patient.receiveFrom,
            otherDiseases: patient.lstOtherDiseases,
            lstOtherDiseases: lstCD,
            doctorReceive: patient.doctorReceive,
        })
        this.enterinfo.setState({
            lstCdFrom: [{ 'label': patient.cdFromName, 'value': patient.cdFrom }],
            lstRecommend: [{ 'label': patient.switchHospitalName, 'value': patient.switchHospital }],
            lstcardAddress: [{ 'value': patient.cardCode, 'label': patient.cardName }],
            comeFrom: patient.comeFrom,
            subject: patient.subjectCode !== '' ? Number(patient.subjectCode) : patient.subjectCode,
            recommend: patient.comeFrom ? patient.comeFrom : this.enterinfo.state.recommend,
            cdFrom: patient.comeFrom === '1' ? patient.cdFrom : '',
            cdFromName: patient.comeFrom === '1' ? patient.cdFromName : '',
            route: patient.route ? patient.route : false,
            cardNumber: patient.cardNumber ? patient.cardNumber : '',
            cardCode: patient.cardCode ? patient.cardCode : '',
            cardAddress: patient.cardCode ? patient.cardCode : this.enterinfo.state.cardAddress,
            cardAddressLabel: patient.cardName ? patient.cardName : '',
            cardLabel: patient.cardNumber ? true : false,
            dateExpired: [moment(patient.cardFrom).year() !== 1 ? moment(patient.cardFrom) : '', moment(patient.cardTo).year() !== 1 ? moment(patient.cardTo) : ''],

        })
        this.form.setFieldsValue({
            receiveFrom: patient.receiveFrom,
            comeFrom: patient.comeFrom,
            cdFrom: patient.cdFrom,
            recommend: patient.switchHospital,
            doctorReceive: patient.doctorReceive,
            nearPoor: patient.nearPoor,
            nearPoorCode: patient.nearPoorCode,
            saveCode: patient.saveCode,
            subject: patient.subjectCode !== '' ? Number(patient.subjectCode) : patient.subjectCode,
            dateExpired: [moment(patient.cardFrom).year() !== 1 ? moment(patient.cardFrom) : '', moment(patient.cardTo).year() !== 1 ? moment(patient.cardTo) : ''],
            route: patient.route ? patient.route : false,
            cardNumber: patient.cardNumber ? patient.cardNumber : '',
            cardCode: patient.cardCode,
            cardAddress: patient.cardCode,
            cardAddressLabel: patient.cardName ? patient.cardName : '',
            cardLabel: patient.cardNumber ? true : false,
        })

    }
    thongtinBaohiem(data) {
        let lstcardAddress = this.enterinfo.state.lstcardAddress;
        if (lstcardAddress.filter(e => e.value === data.cardAddress).length === 0) {
            lstcardAddress.push({ label: data.cardAddressLabel, value: data.cardAddress })
        }
        this.enterinfo.setState({
            route: data.route ? data.route : false,
            cardNumber: data.cardNumber ? data.cardNumber : '',
            cardCode: data.cardCode ? data.cardCode : '',
            cardAddress: data.cardCode ? data.cardCode : '',
            cardAddressLabel: data.cardName ? data.cardName : '',
            cardLabel: data.cardNumber ? true : false,
            lstcardAddress: lstcardAddress,
            dateExpired: [moment(data.cardFrom).year() !== 1 ? moment(data.cardFrom) : '', moment(data.cardTo).year() !== 1 ? moment(data.cardTo) : '']
        })
    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu khoa cần nhập bị lỗi' })
        })
    }
    loadDiagnostic(value, title) {
        if (title === 'kèm theo') {
            CommonService.GetListDiagnostic(value).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    this.setState({ lstOtherDiseases: result.data });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            })
        }
        else {
            CommonService.GetListDiagnostic(value).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    this.setState({ lstCdFrom: result.data });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            })
        }
    }
    loadNhanTu() {
        CommonService.GetListNhanTu().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstReceiveFrom: result.data,
                    receiveFrom: result.data[0].value
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    loadDoctor() {
        CommonService.GetListDoctor(1, 200).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDoctorReceive: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    resetFields() {
        this.setState(initialState);
        this.enterinfo.resetState();
        this.resetForm();
    }
    resetForm() {
        this.form.setFieldsValue({
            receiveFrom: null,
            timesInto: 1,
            department: null,
            cdKKB: null,
            doctorReceive: null,
        })
        this.form.setFieldsValue(this.enterinfo.resetForm())
    }
    async submit() {
        Actions.setLoading(true);
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            let dataEnterInfo = this.enterinfo.state;
            const data = {
                'code': this.state.detail.patientID,
                'managerId': this.state.detail.managementID,
                'recommend': dataEnterInfo.recommend,
                'recommendName': dataEnterInfo.recommendName,
                'receiveFrom': this.state.receiveFrom,
                'subjectCode': dataEnterInfo.subject.toString(),
                'cardNumber': dataEnterInfo.cardNumber,
                'cardFrom': dataEnterInfo.dateExpired.length > 1 ? (dataEnterInfo.dateExpired[0] !== '' && dataEnterInfo.dateExpired[0] !== null) ? dataEnterInfo.dateExpired[0].format('YYYY-MM-DD HH:mm') : '0001-01-01' : '0001-01-01',
                'cardTo': dataEnterInfo.dateExpired.length > 1 ? ( dataEnterInfo.dateExpired[1] !== '' && dataEnterInfo.dateExpired[1] !== null) ? dataEnterInfo.dateExpired[1].format('YYYY-MM-DD HH:mm') : '0001-01-01' : '0001-01-01',
                'cardCode': dataEnterInfo.route.toString(),
                'comeFrom': dataEnterInfo.comeFrom,
                'department': this.state.department,
                'cardName': dataEnterInfo.cardCode,
                'route': dataEnterInfo.route,
                'routeCode': dataEnterInfo.cardCode,
                'routeName': dataEnterInfo.cardAddress,
                'cdDepartment': this.state.cdDepartment,
                'lstOtherDiseases': this.state.otherDiseases,
                'cdFrom': dataEnterInfo.cdFrom,
                'cdFromName': dataEnterInfo.cdFromName,
                'room': this.state.room,
                'diagnostic': this.state.cdKKB,
                'diagnosticName': this.state.cdKKBName,
                'doctorReceive': this.state.doctorReceive,
                'nearPoor': this.state.nearPoor,
                'nearPoorCode': this.state.nearPoorCode,
                'enterCode': this.state.enterCode,
                'enterNumber': this.state.enterNumber,
                'timesInto': this.state.timesInto.toString(),
                'clinical': this.state.clinical,
                'saveCode': this.state.saveCode,
                'timeInHospital': this.state.timeInHospital,
                'dateInHospital': this.state.dateInHospital.format('YYYY-MM-DD HH:mm'),
            }
            NhapVienService.LuuNhapVien(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công" });
                    this.DanhSachNhapVien();
                    this.resetFields();
                    this.setState({ visible: false });
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
        } else {
            Actions.setLoading(false);
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    setData(row) {
        let lstCdFrom = this.state.lstCdFrom;
        if (lstCdFrom.filter(e => e.value === row.diagnostic).length === 0) {
            lstCdFrom.push({ label: row.diagnosticName, value: row.diagnostic })
        }
        this.setState({
            visible: true,
            lstCdFrom: lstCdFrom,
            dateInHospital: moment(row.applyDate),
            department: row.department,
            detail: row,
            cdKKB: row.diagnostic,
            cdKKBName: row.diagnosticName,
            timesInto: row.timesInto ? row.timesInto : 1,
            enterCode: row.enterCode,
            enterNumber: row.enterNumber,
            clinical: row.clinical,
            managerId: row.managementID,
            subject: Number(row.subjectCode),
        });
        this.form.setFieldsValue({
            department: row.department,
            cdKKB: row.diagnostic,
            subject: Number(row.subjectCode),
        })
    }

    renderForm() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        Tên BN: <strong>{this.state.detail.patientName}</strong>
                    </div>
                    <div className="col-md-6 mb-2">
                        Mã BN: <strong>{this.state.detail.patientID}</strong>
                    </div>
                    <div className="col-md-6 mb-2">
                        <div style={{ flex: 1, display: "none" }}>
                            <Form.Item label="Bệnh án:">
                                <Select value={this.state.clinical} onChange={(value) => { this.setState({ clinical: value }) }}>
                                    {this.state.lstClinical.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <label>Ngày vào viện</label>
                        <Form.Item>
                            <div className="row no-gutters">
                                <div className="col-6">
                                    <DatePicker locale={viVN} value={this.state.dateInHospital}
                                        allowClear={false}
                                        className="w-100"
                                        format={"DD-MM-YYYY"} placeholder="DD-MM-YYYY" onChange={(date) => { this.setState({ dateInHospital: date }) }} />
                                </div>
                                <div className="col-6">
                                    <TimePicker
                                        locale={viVN}
                                        allowClear={false}
                                        className="w-100"
                                        value={this.state.dateInHospital}
                                        format={'HH:mm'}
                                        onChange={(date) => { this.setState({ dateInHospital: date }) }}
                                    />
                                </div>
                            </div>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Số lưu trữ</label>
                        <Form.Item name="saveCode" rules={[{ required: true, message: 'Vui lòng nhập số lưu trữ!' }]}>
                            <Input value={this.state.saveCode} onChange={(e) => { this.setState({ saveCode: e.target.value }) }} />
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Nhận từ</label>
                        <Form.Item name="receiveFrom" rules={[{ required: true, message: 'Vui lòng chọn nhận từ!' }]}>
                            <Select value={this.state.receiveFrom} onChange={(value) => { this.setState({ receiveFrom: value }); this.form.setFieldsValue({ receiveFrom: value }) }}>
                                {this.state.lstReceiveFrom.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Số vào viện</label>
                        <Form.Item >
                            <Input value={this.state.enterNumber} onChange={(e) => { this.setState({ enterNumber: e.target.value }) }} />
                        </Form.Item>
                    </div>
                </div>
                <EnterInfo
                    ref={((c) => this.enterinfo = c)}
                    thongtinBaohiem={this.thongtinBaohiem.bind(this)}
                    patientCode={this.administrative !== undefined ? this.administrative.state.code : ''}
                    ngay={this.state.dateInHospital.format(SystemConfig.FORMAT_DATE)}
                    madoituong={this.state.subject}
                    setForm={(data) => this.form.setFieldsValue(data)}
                />
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <label>Nhập khoa</label>
                        <Form.Item name="department" rules={[{ required: true, message: 'Vui lòng chọn khoa!' }]}>
                            <Select value={this.state.department}
                                onChange={(value) => { this.setState({ department: value }); this.form.setFieldsValue({ department: value }) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstdepartment.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>BS nhận bệnh</label>
                        <Form.Item name="doctorReceive">
                            <Select
                                value={this.state.doctorReceive}
                                onChange={(value) => { this.setState({ doctorReceive: value }); this.form.setFieldsValue({ doctorReceive: value }) }}
                                showSearch

                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstDoctorReceive.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>Cận nghèo</label>
                        <Form.Item>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <Checkbox checked={this.state.nearPoor} onChange={(e) => { this.setState({ nearPoor: e.target.checked }) }}></Checkbox>
                                    </div>
                                </div>
                                <Input disabled={!this.state.nearPoor} value={this.state.nearPoorCode}
                                    style={{ width: 'calc(100% - 50px)' }}
                                    placeholder="Nhập mã cận nghèo"
                                    onChange={(e) => {
                                        this.setState({ nearPoorCode: e.target.value });
                                        this.form.setFieldsValue({ nearPoorCode: e.target.value })
                                    }} />
                            </div>
                        </Form.Item>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label>CĐ KKB, Cấp cứu</label>
                        <Form.Item name="cdKKB" rules={[{ required: true, message: 'Vui lòng chọn chẩn đoán bệnh !' }]}>
                            <Autocomplete
                                width="100%"
                                ref={((c) => this.autocomplete = c)}
                                dataSource={this.state.lstCdFrom}
                                handleSearch={this.loadDiagnostic.bind(this)}
                                getItem={(key, value) => { this.setState({ cdKKB: key, cdKKBName: value }); this.form.setFieldsValue({ cdKKB: key }) }}
                                value={this.state.cdKKB}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-md-12 mb-2">
                        <label>Bệnh kèm theo</label>
                        <Form.Item>
                            <AutocompleteMulti
                                ref={((c) => this.autocomplete = c)}
                                dataSource={this.state.lstOtherDiseases}
                                handleSearch={this.loadDiagnostic.bind(this)}
                                getItem={(key, value) => { this.setState({ otherDiseases: key }) }}
                                value={this.state.otherDiseases}
                                title='kèm theo'
                            />
                        </Form.Item>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    render() {
        const columns = [
            {
                title: '',
                render: (row) => <div>
                    <Button className="btn btn-color" disabled={this.state.checkDept} onClick={() => {
                        this.setData(row); this.ChiTietNhapVien(row.patientID, row.managementID, row.enterCode)
                    }}><i className="fa fa-edit"></i></Button>
                </div>
            },
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
            },
            {
                title: 'Họ tên',
                dataIndex: 'patientName',
                key: 'patientName',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Mã vào viện',
                dataIndex: 'enterCode',
                key: 'enterCode'
            },
            {
                title: 'Khoa',
                dataIndex: 'departmentName',
                key: 'departmentName'
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'birthDay',
                key: 'birthDay',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Giới tính',
                dataIndex: 'genderLabel',
                key: 'genderLabel'
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'diagnosticName',
                key: 'diagnosticName'
            },
            {
                title: 'Trạng thái',
                dataIndex: 'statusEnter',
                key: 'statusEnter'
            },
            {
                title: 'Ngày',
                dataIndex: 'applyDate',
                key: 'applyDate',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },

        ];
        return (
            <React.Fragment>
                <div className="row justify-content-center align-items-center">
                    <div className="col-auto my-1">
                        <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                            locale={viVN} onChange={(value => this.setState({ ngay: value }, () => { this.DanhSachNhapVien() }))}
                        />
                    </div>
                    <div className="col-auto my-1">
                        <Checkbox onChange={(e) => { this.setState({ checkDept: e.target.checked }, () => this.DanhSachNhapVien()) }}>Đã nhập khoa</Checkbox>
                    </div>
                    <div className="col-auto my-1">
                        <button type="primary" className="btn btn-color" onClick={() => { this.DanhSachNhapVien() }}><i style={{ marginRight: 10 }} className="fa fa-search" />Tìm</button>
                    </div>
                </div>
                <Table dataSource={this.state.lstDSNhapVien} scroll={{ x: 'max-content', y: 0 }} columns={columns} rowKey={(row, index) => index} />
                <Form ref={(c) => this.form = c} >
                    {this.state.visible &&
                        <Modal maskClosable={false}
                            visible={this.state.visible}
                            onCancel={(e) => { (e.currentTarget.tabIndex === -1) ? e.preventDefault() : this.setState({ visible: false }) }}
                            width={1000}
                        >
                            {this.renderForm()}
                            <div className="d-flex justify-content-center">
                                <Button className="mr-2 btn btn-color" key="2" onClick={() => { this.submit() }}> <i style={{ marginRight: 10 }} className="fa fa-save" />Lưu</Button>
                                <Button className="mr-2 btn btn-outline-secondary" key="1" onClick={() => { this.resetFields() }}> <i style={{ marginRight: 10 }} className="fa fa-eraser" />Nhập mới</Button>
                                <Button className="mr-2 btn btn-danger" key="3" onClick={() => { this.setState({ visible: false }) }}>Đóng</Button>
                            </div>
                        </Modal>
                    }
                </Form>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        patient: state.global.patient,
    })
}
export default connect(
    mapStateToProps,
)(NhapVien);
