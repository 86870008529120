import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Common';

export function GetNhomDoiTuong(pageNumber =1, pageSize = 200)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/nhomdoituong?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetPhongKham(pageNumber =1, pageSize = 100)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/phongkham?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetDSKCB(key='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnoicapbhyt?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetHospital(value)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsbv?key=${value}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListCICD10(value){
    return new Promise((resolve, reject) =>{
        return ApiCaller.httpGet(`${prefix}/loaibenh?input=${value}`).then(response =>{
            return resolve(response)
        }).catch(err =>{
            return reject(err)
        })
    });
}