import { Input, List, Button, Dropdown, Menu, Drawer } from 'antd';
import React, { useState, useEffect, useRef, useCallback, Fragment, forwardRef } from 'react';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as MedicineService from '../../../services/medicineService';
import CreateMedicineSample from "../../../screens/medicine/mitaMedicine/medicineSample";
import * as Actions from '../../../libs/actions';

export default forwardRef((props, ref) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const _notify = useRef();
    const [key, setKey] = useState("");
    const [listPrescriptionSample, setListPrescriptionSample] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [maxResultCount,] = useState(10);
    const [skipCount, setSkipCount] = useState(0);
    const [visibleCreateSample, setVisibleCreateSample] = useState(false);
    const [selectedMedicineList, setSelectedMedicineList] = useState([]);
    const [maGoiThuoc, setMaGoiThuoc] = useState();

    useEffect(() => {
        handleSearch();
    }, [skipCount]);

    const handleSearch = (keyword = key, reset1stpage = false) => {
        Actions.setLoading(true);
        let skip = skipCount;
        if (reset1stpage)
            skip = 0
        setSkipCount(skip);
        let dataFilter = {
            skipCount: skip,
            maxResultCount,
            keyword,
        }
        MedicineService.getListPrescriptionSample(dataFilter).then(result => {
            if (result.isSuccess) {
                setTotalRecords(result.data.totalCount);
                if (skip == 0)
                    setListPrescriptionSample(result.data.items);
                else 
                    setListPrescriptionSample([...listPrescriptionSample, ...result.data.items]);
            }
            else {
                _notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(() => {
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        });
    }

    const onSelectPrescriptionSample = (e, record) => {
        const data = {
            danhSachThuoc: record.danhSachThuoc,
            maDon: record.maDonThuoc,
            tenDichVu: record.tenDichVu,
            isPrivate: record.isPrivate,
            tieuDe: record.tenGoiThuoc,
        }
        setMaGoiThuoc(record.maGoiThuoc);
        setSelectedMedicineList(data);
        setVisibleCreateSample(true);
    }

    const rowOptions = (record) => {
        let items = [
            {
                label:
                    <a href="#" onClick={(e) => {
                    }}><i className='fa fa-eye mr-2' />Xem chi tiết</a>,
                key: 'viewdetail_action',
            },
            {
                label:
                    <a href="#" onClick={(e) => {
                    }}><i className="fas fa-pen mr-2"></i>Cập nhật</a>,
                key: 'update_action',
            },
        ];
        return (
            <Menu
                items={items}
            />
        )
    }

    const handleCancel = () => {
        const data = {
            danhSachThuoc: [],
            maDon: null,
            tenDichVu: null,
            isPrivate: null,
            tieuDe: null,
        }
        setSelectedMedicineList(data);
        setMaGoiThuoc();
        setVisibleCreateSample(false);
        handleSearch();
    }

    return (
        <Fragment>
            <div className='list-page containerChilderWrapper'>
                <div>
                    <HeaderModal title="Quản lý gói thuốc" />
                    <Notify ref={_notify} />
                    <Input
                        style={{ width: "50%" }}
                        placeholder='Nhập tên gói thuốc'
                        value={key}
                        onChange={(e) => {
                            setKey(e.target.value);
                        }}
                        onPressEnter={(e) => {
                            handleSearch(e.target.value, true);
                        }} />
                    <button className='btn btn-color ml-2' onClick={() => handleSearch(key, true)}><i className='fas fa-search mr-1' />Tìm</button>
                    {/* Tạm khóa vì chức năng tạo mới theo yêu cầu ban đầu phải có mã đơn thuốc và mã dịch vụ, nếu tạo mới hoàn toàn thì 2 thông tin này null
                    <button className='btn btn-color ml-2'><i className='fas fa-plus mr-1' />Tạo mới</button>
                    */}
                </div>
                <div className="list-page-body">
                    <div className="list-page-table mt-2">
                        <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                            bordered={false}
                            dataSource={listPrescriptionSample}
                            locale="Không có dữ liệu"
                            renderItem={(record) => {
                                return (
                                    <List.Item
                                        onClick={(e) => { onSelectPrescriptionSample(e, record) }}
                                        key={record.maGoiThuoc}
                                        className="p-1 border-2 border-bottom py-2 ant-list-item-hover"
                                    >
                                        <div className='row w-100'>
                                            <div className='col-5 d-flex flex-column'>
                                                <div>
                                                    <label className='mita-title'>Tên gói thuốc:</label>
                                                    <label className='mita-value ml-1'>{record.tenGoiThuoc}</label>
                                                </div>
                                                <div style={{ width: "40% " }}>
                                                    <label className='mita-title'>Mã đơn thuốc:</label>
                                                    <label className='mita-value ml-1'>{record.maDonThuoc}</label>
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <div>
                                                    <label className='mita-title'>Dịch vụ:</label>
                                                    <label className='mita-value ml-1'>{record.tenDichVu}</label>
                                                </div>
                                            </div>
                                            <div className='col-2 d-flex justify-content-end'>
                                                <Dropdown overlay={rowOptions(record)} trigger={['click']} onClick={(e) => { e.stopPropagation() }}>
                                                    <a className="ant-dropdown-link"><i className='fas fa-ellipsis-v'></i></a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }}
                        >
                            <List.Item key="noData">
                                <div className="w-100 d-flex justify-content-center">
                                    <label hidden={listPrescriptionSample.length > 0}>Không có dữ liệu</label>
                                </div>
                            </List.Item>
                            <List.Item key="showMore">
                                <div className="w-100 d-flex justify-content-center">
                                    <Button
                                        hidden={totalRecords <= listPrescriptionSample.length}
                                        onClick={() => {
                                            setSkipCount(skipCount + maxResultCount);
                                        }}
                                    >Hiển thị thêm {totalRecords - listPrescriptionSample.length}</Button>
                                </div>
                            </List.Item>
                        </List>
                    </div>
                </div>
            </div>
            <Drawer
                className='custom-ant-drawer'
                closable={false}
                width={1000}
                placement="right"
                onClose={() => handleCancel()}
                visible={visibleCreateSample}
                destroyOnClose={true}
                maskClosable={false}
            >
                <CreateMedicineSample
                    data={selectedMedicineList}
                    maGoiThuoc={maGoiThuoc}
                    onClose={() => handleCancel()}
                />
            </Drawer>
        </Fragment >
    );
});