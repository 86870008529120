import { Badge, Popover, List, Tag } from 'antd';
import React, { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import { BUTTON } from './constants';
import * as NotificationMessageService from "../../services/notificationMessage";
import { store } from '../../stores';
import { connect } from 'react-redux';
import * as ScreenComponentsModule from '../../commons/screenComponents';
import { isnotnull } from '../../libs/util';
import NotificationMessageModal from './notificationMessageModal';
import { FORMAT_TIME_DATE } from '../../configure/systemConfig';
import moment from 'moment';

function NotificationMessage(props) {
    var ScreenComponents = ScreenComponentsModule.screenComponents;
    const showMoreText = '...Xem thêm';
    const showLessText = ' Rút gọn';
    const [content, setContent] = useState([]);
    const [newNotification, setNewNotification] = useState(false);
    const [listRemove, setListRemove] = useState([]);
    const _notificationMessageModal = useRef();

    useEffect(() => {
        GetListNotificationMessage();
    }, [])

    useEffect(() => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData !== undefined && listNotiData !== null && listNotiData !== "") {
            let listNewNotiData = listNotiData.filter(x => x.read == false);
            setContent(listNotiData);
            if (listNewNotiData.length > 0) {
                setNewNotification(true);
            } else {
                setNewNotification(false);
            }
        } else {
            setContent([]);
            setNewNotification(false);
        }
    }, [sessionStorage.getItem("notificationData"), props.notificationCount]);

    const createReadStatus = (key) => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData !== undefined && listNotiData !== null && listNotiData !== "") {
            let found = listNotiData.find(x => x.key == key);
            if (found) found.read = true;
            sessionStorage.setItem("notificationData", JSON.stringify(listNotiData));
        } else {
            setContent([]);
            setNewNotification(false);
        }
    }

    const DeleteNotificationMessage = (index) => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData !== undefined && listNotiData !== null && listNotiData !== "") {
            let found = listNotiData.find(x => x.key == index);
            if (found) found.read = true;
            let listDelete = [...listRemove, found];
            setListRemove(listDelete);
            let listNewNotiData = listNotiData.filter(x => x.key != index);
            sessionStorage.setItem("notificationData", JSON.stringify(listNewNotiData));
            setContent(listNewNotiData);
        } else {
            setContent([]);
            setNewNotification(false);
        }
    }

    const GetListNotificationMessage = () => {
        NotificationMessageService.GetListNotificationMessage().then(res => {
            if (res.isSuccess) {
                sessionStorage.setItem("notificationData", JSON.stringify(res.data));
            }
        }).catch(err => {

        }).finally(() => { })
    }

    const UpdateStatusNotificationMessage = () => {
        let currentNotiData = sessionStorage.getItem("notificationData");
        let listNotiData = JSON.parse(currentNotiData);
        if (listNotiData != undefined) {
            const data = { listData: listNotiData, listRemove };
            NotificationMessageService.UpdateStatusNotificationMessage(data).then(res => {
                if (res.isSuccess) {
                }
            }).catch(err => {
            }).finally(() => {
                setListRemove([]);
            })
        }
    }

    const onClickMessage = (item) => {
        createReadStatus(item.key);
        if (item.type == "regular") {
            _notificationMessageModal && _notificationMessageModal.current && _notificationMessageModal.current.Show(item.title, item.notification);
        } else {
            let listOpts = [];
            props.menuData && props.menuData.length > 0 && props.menuData.map((x, index) => {
                if (x.nodes && x.nodes.length > 0) {
                    x.nodes.map((n, nInd) => {
                        if (n.nodes && n.nodes.length > 0) {
                            n.nodes.map((nc, ncInd) => {
                                listOpts.push(nc);
                            })
                        } else listOpts.push(n);
                    })
                } else listOpts.push(x);
            });
            let curTab = listOpts.find(x => x.code == item.screen);
            let tabs = [...props.tabs];
            if (isnotnull(curTab)) {
                let node = {
                    key: curTab.menuKey,
                    label: curTab.label,
                    content: (curTab.content && curTab.content.length > 0) ? ScreenComponents[curTab.content + 'Content'] : undefined,
                    code: curTab.code,
                    icon: curTab.icon,
                };
                if (tabs.find(x => x.code == item.screen) == undefined) tabs.push(node);
                store.dispatch({ type: 'SET_TAB', tabs: tabs, activeTab: curTab.menuKey });
            }
        }
    }

    const clearAllMessage = () => {
        if (isnotnull(content)) {
            content.forEach((noti) => {
                DeleteNotificationMessage(noti.key);
            });
        }
    }

    return (
        <Fragment>
            <Popover
                onOpenChange={(visible) => {
                    if (!visible) UpdateStatusNotificationMessage();
                }}
                title={<div className='d-flex justify-content-between'>Thông báo ({(content ?? []).length})
                    {/* {content.length > 0 && <Button type='link' onClick={() => clearAllMessage()}>Xóa tất cả</Button>} */}
                </div>}
                trigger="click"
                placement='bottom'
                content={
                    <div style={{ width: 350, maxHeight: "82vh" }}>
                        <List
                            locale={{ emptyText: "Không có thông báo" }}
                            itemLayout="horizontal"
                            dataSource={content}
                            style={{ overflow: "hidden" }}
                            renderItem={(item, index) => (
                                <List.Item onClick={(e) => { onClickMessage(item) }} className={`rowCustom p-2 animate__animated animate__backInRight`} style={{ animationDelay: `${(index / 15)}s` }}>
                                    {item.icon?.value && <i className={item.icon?.value} style={{ ...item.icon?.style, fontSize: 30, paddingRight: 10 }} />}
                                    <List.Item.Meta key={index}
                                        title={<label>{item.title}{!item.read ? <Tag className="ml-1" bordered={false} color="red">Mới</Tag> : ""}</label>}
                                        description={
                                            <Fragment>
                                                <div style={{ fontSize: 11, color: "#cdcdcd" }}>{moment(item.createdAt).format(FORMAT_TIME_DATE)}</div>
                                                <MitaDisplayLongText
                                                    content={item.type == "regular" ? "Bấm để xem chi tiết" : item.notification}
                                                    maxLength={200}
                                                    showMoreText={showMoreText}
                                                    showLessText={showLessText}
                                                />
                                            </Fragment>
                                        }
                                    />
                                    {item.key && <button className={`${BUTTON.OUTLINE_DANGER} ml-1 rounded`} onClick={(e) => { DeleteNotificationMessage(item.key); e.stopPropagation(); }}><i className='fas fa-times' /></button>}
                                </List.Item>
                            )}
                        />
                    </div>
                }>
                <div
                    style={{
                        display: 'flex',
                        color: 'white',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: "center",
                        width: "40px",
                        height: "45px",
                    }}
                >
                    <Badge count={content.length} size='small' dot={newNotification}><i style={{ fontSize: 20 }} className={`${newNotification ? "shake" : ""} fas fa-bell text-white`} /></Badge>
                </div>
            </Popover>
            <NotificationMessageModal ref={_notificationMessageModal} />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    tabs: state.global.tabs,
    activeTab: state.global.activeTab,
    menuData: state.global.menuData,
    notificationCount: state.global.notificationCount
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(NotificationMessage);