import React, { Fragment, useState, useEffect } from 'react';

function QMSContainer(props) {
    const [room, setRoom] = useState([]);
    const [patientCurrent, setPatientCurrent] = useState([]);
    const [patientsWaiting, setPatientsWaiting] = useState([]);

    useEffect(() => {
        setRoom(props.roomInfo);
        const patientList = props.roomInfo.patients;
        if (patientList && patientList.length > 0) {
            let inprogressPatient = patientList.filter(p => p.inProgress == true);
            let waitingList = patientList.filter(p => p.inProgress != true);
            if (inprogressPatient != undefined) {
                setPatientCurrent(inprogressPatient);
            }
            if (waitingList != undefined) {
                setPatientsWaiting(waitingList);
            }
        }
        if (patientList.length == 0) {
            setPatientCurrent([]);
            setPatientsWaiting([]);
        }
    }, [props.roomInfo]);

    const patientRender = (inprogress = false, patients) => {
        patients.map((patient, index) => {
            return (
                <div id={index} className={`${inprogress ? "QMSPatientCurrent" : `QMSPatientWaiting ${index % 2 ? "text-secondary" : "text-warning"}`}`}>
                    <span>{patient.patientNumber}</span>
                    <span className="QMSPatientInfo">
                        <span className="QMSPatientName">{patient.patientName}</span>
                        <span>Mã bệnh nhân: {patient.ptientID} {patient.age} tuổi</span>
                    </span>
                    <span>{patient.predict}</span>
                </div>
            )
        })
    }

    return (
        <Fragment>
            <div id={`QMS-${room.roomName}`} className="QMSContainer">
                <div className="QMSHeaderContainer">
                    <h5 className="QMSRoomName">{room.roomName}</h5>
                    <div className="QMSAdditionalInfo">
                        <span>{room.deparmentName}</span>
                        <span>{room.siteName}</span>
                    </div>
                </div>
                <div className="QMSHr" />
                <div className="QMSPatient">
                    <div id={`QMS-patient-current`}>
                        <div className="QMSHeaderCurrent">
                            <span>STT</span>
                            <span>Đang khám</span>
                            <span>Thời gian</span>
                        </div>
                        <div className="AutoScrollY">
                            {patientCurrent &&
                                patientCurrent.map((patient, index) => {
                                    return (
                                        <div id={index} className={`${"QMSPatientCurrent"}`}>
                                            <span>{patient.patientNumber}</span>
                                            <span className="QMSPatientInfo">
                                                <span className="QMSPatientName">{patient.patientName}</span>
                                                <span>Mã bệnh nhân: {patient.patientID} {patient.age} tuổi</span>
                                            </span>
                                            <span>{patient.predict}</span>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div id={`QMS-patient-waiting`}>
                        <div className="QMSHeaderWaiting">
                            <span>STT</span>
                            <span>Danh sách chờ</span>
                            <span>Thời gian</span>
                        </div>
                        <div className="AutoScrollY">
                            {patientsWaiting &&
                                patientsWaiting.map((patient, index) => {
                                    return (
                                        <div id={index} className={`QMSPatientWaiting ${index % 2 ? "Odd" : "Even"}`}>
                                            <span>{patient.patientNumber}</span>
                                            <span className="QMSPatientInfo">
                                                <span className="QMSPatientName">{patient.patientName}</span>
                                                <span>Mã bệnh nhân: {patient.patientID} {patient.age} tuổi</span>
                                            </span>
                                            <span>{patient.predict}</span>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default QMSContainer;