import { Table, Drawer, Select } from 'antd';
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import * as Actions from '../../libs/actions';
import * as TestService from '../../services/lisService';
import moment from 'moment';
import * as Configure from '../../configure/urlConfig';
import * as signalR from '@aspnet/signalr';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as SystemConfig from "../../configure/systemConfig";
import { connect } from 'react-redux';

const { Option } = Select;
let taskDelay = null;

const Result =  forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const [listResults, setListResults] = useState([]);
    const [visibleDraw, setVisibleDraw] = useState(false);
    const [sendby, setSendby] = useState("atCenter");
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [listHTTKQ, setListHTTKQ] = useState([]);
    const columns = [
        {
            title: 'Họ và tên',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 150,
            align: "left",
        },
        {
            title: 'Năm sinh',
            dataIndex: 'dob',
            key: 'dob',
            width: 80,
            align: "center",
            render: (ngaySinh, row) => {
                return (
                    row.onlyYear ? <span>{moment(ngaySinh).year()}</span> : moment(ngaySinh).format(SystemConfig.FORMAT_DATE)
                )
            }
        },
        {
            title: 'Dự kiến trả',
            dataIndex: 'timeToLive',
            key: 'timeToLive',
            width: 80,
            align: "center",
            render: (TTL) => {
                return (
                    moment(TTL).year() > 1 && moment(TTL).format("HH:mm")
                )
            }
        },
    ]

    useEffect(() => {
        DanhSachTraKetQua();
    }, [chinhanh])

    
    useEffect(() => {
        setInterval(() => {
            forceUpdate()
        }, 1000)
    }, [])

    const notifyUpdate = () => {
        const isLoading = false;
        DanhSachTraKetQua(sendby, isLoading);
    }

    const displayTime = () => {
        return (
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div style={{ fontSize: 100 }}>{moment().format("HH:mm")}<span style={{ fontSize: 20 }}>{moment().format(":ss")}</span></div>
                <h3 className=''>{moment().format("dddd, [ngày] DD [tháng] MM [năm] yyyy")}</h3>
            </div>
        )
    }
    useEffect(() => {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item);
                result.item && GetListSite();
            }
        });
        loadHTTKQ();
    }, [])
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }

    const DanhSachTraKetQua = (by = sendby, isLoading = true) => {
        Actions.setLoading(isLoading);
        TestService.DanhSachTraKetQua(by, chinhanh).then(result => {
            if (result.isSuccess) {
                setListResults(result.data)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        }).finally(() => Actions.setLoading(false))
    }

    const loadHTTKQ = (key = "", page = 1, size = 10) => {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                setListHTTKQ(res.data);
            }
        })
    }

    return (
        <React.Fragment>
            <i class="fas fa-cog" style={{ position: "absolute", top: 5, right: 10 }} onClick={() => setVisibleDraw(true)}></i>
            {listResults && listResults.length > 0 ?
                <div className='notification'>
                    <div className="header mt-2 d-flex"><span>Xin mời nhận kết quả</span><span className='ml-auto text-warning'>{moment().format("HH:mm:ss")}</span></div>
                    <Table columns={columns} dataSource={listResults}
                        rowClassName={(record) => {
                            if (moment(record.timeToLive).year() > 1 && moment(record.timeToLive) <= moment())
                                return "blink"
                        }}
                        rowKey={(row, index) => `result-${index}`}
                        pagination={false}>
                    </Table>
                </div>
                :
                <div className='notification'>
                    <div className='header'>{displayTime()}</div>
                </div>}
            <Drawer title="Thiết lập" placement="right" onClose={() => setVisibleDraw(false)} visible={visibleDraw}>
                <div className="col-lg-12 mt-1">
                    <label>Hình thức trả kết quả</label>
                    <Select value={sendby} placeholder="Chọn hình thức trả kết quả" className='w-100'
                        onChange={(value) => {
                            setSendby(value);
                            DanhSachTraKetQua(value);
                        }}
                        showSearch
                        onSearch={(e) => {
                            if (taskDelay) clearTimeout(taskDelay);
                            taskDelay = setTimeout(() => {
                                loadHTTKQ(e);
                            }, 400)
                        }}
                        filterOption={(input, option) =>{
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                    option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                        }}>
                        <Option key={0} value="all">Tất cả</Option>
                        {/* <Option key={'sms'} value="sms">Sms</Option>
                        <Option key={1} value="zalo">Zalo</Option>
                        <Option key={2} value="email">Email</Option>
                        <Option key={3} value="atCenter">Tại trung tâm</Option>
                        <Option key={4} value="atClinic">Tại phòng khám</Option> */}
                        {
                            listHTTKQ && listHTTKQ.map((x, index) => {
                                return (
                                    <Option key={x.keyValue} value={x.keyValue}>{x.keyName}</Option>
                                )
                            })
                        }
                    </Select>
                </div>
                {isMultisite && <div className="col-lg-12 mt-1">
                    <label>Chi nhánh</label>
                    <Select
                        className='w-100'
                        showSearch
                        allowClear={false}
                        value={chinhanh}
                        onChange={(e) => {
                            setChinhanh(e)
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option key={-1} value={''}>Chi nhánh</Option>
                        {listSite.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.ma}</Option>
                            )
                        })}
                    </Select>
                </div>}
            </Drawer>
        </React.Fragment>
    )
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Result);