import * as ApiCaller from '../libs/httpRequests';
const prefix = 'LogPayment';

export function GetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportLogPayment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportLogPayment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};