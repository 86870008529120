import { Button, Modal, Pagination, Popconfirm, Input, notification, Table, Tooltip, Switch, Select, Checkbox, Drawer } from 'antd';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import Update from './update';
import * as CoporationServices from "./CorporationServices";
import * as CoporationConfigServices from "../../services/CorporationServices";
import { BUTTON, FEATURE, USER_ROLE } from '../../configureHIS/common/constants';
import { FeatureDeactive } from '../../configure/featureConfig';
import HeaderModal from '../../configureHIS/common/headerModal';
import * as NhanVienService from '../../services/employeeService';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import UpdateMulti from './updateMulti';
import { isnotnull } from '../../libs/util';

let task = null;

function Corporation() {
    const [dataSource, setDataSource] = useState([]);
    const [detail, setDetail] = useState({});
    const [modalCreate, setModalCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [kwd, setKwd] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [hide, setHide] = useState(false);
    const [status, setStatus] = useState('');
    const { Option } = Select;
    const [saleKey, setSaleKey] = useState();
    const [listSale, setListSale] = useState([]);
    const [bangGiaKey, setBangGiaKey] = useState();
    const [listBangGia, setListBangGia] = useState([]);
    const [daiDienKey, setDaiDienKey] = useState();
    const [listDaiDien,] = useState([
        { 'value': 0, 'label': 'Thể nhân' },
        { 'value': 1, 'label': 'Pháp nhân' },
    ]);
    const [visibleUpdateInfoMulti, setVisibleUpdateInfoMulti] = useState(false);
    const [updateType, setUpdateType] = useState("");
    const [listCopIds, setListCopIds] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [listParent, setListParent] = useState([]);
    const [parentId, setParentId] = useState();
    const [listChild, setListChild] = useState([]);
    const [childId, setChildId] = useState();

    const [listUpdateMulti, ] = useState([
        {
            field: "sale",
            title: "Cập nhật sale hàng loạt"
        },
        {
            field: "banggia",
            title: "Cập nhật bảng giá hàng loạt"
        },
        {
            field: "nguoigioithieu",
            title: "Cập nhật người giới thiệu hàng loạt"
        },
        // {
        //     field: "daidien",
        //     title: "Cập nhật đại diện hàng loạt"
        // },
        {
            field: "discount",
            title: "Cập nhật tỷ lệ chiết khấu hàng loạt"
        },
    ])

    const columns = [
        {
            title: <Checkbox indeterminate={indeterminate} checked={checkAll} onChange={(e) => onChangeCheckAll(e.target.checked)}></Checkbox>,
            key: "checkbox",
            width: 80,
            align: "center",
            render: (row) => {
                return (
                    <Checkbox checked={listCopIds.find(x => x == row.ma) != undefined} onChange={(e) => onSelectCop(e.target.checked, row.ma)}></Checkbox>
                )
            }
        },
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            width: 90
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: 220
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'diachi',
            key: 'diachi',
            width: 250,
            render: (value) => {
                return (
                    <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: `${value}` }}>
                    </div>
                )
            }
        },
        {
            title: 'Người đại diện',
            dataIndex: 'daidien',
            key: 'daidien',
            width: 200
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 160
        },
        {
            title: 'Tài khoản ngân hàng',
            dataIndex: 'taikhoan',
            key: 'taikhoan',
            width: 160
        },
        // {
        //     title: 'Tài khoản xem KQ',
        //     dataIndex: 'username',
        //     key: 'username',
        //     width: 160
        // },
        // {
        //     title: 'Mật khẩu xem KQ',
        //     dataIndex: 'password',
        //     key: 'password',
        //     width: 160
        // },
        {
            title: 'Sale',
            dataIndex: 'saleName',
            key: 'sale',
            width: 160
        },
        {
            title: 'Người giới thiệu',
            dataIndex: 'tennguoigioithieu',
            key: 'nguoigioithieu',
            width: 160
        },
        ...FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 ? [{
            title: 'Bảng giá',
            dataIndex: 'banggia',
            key: 'banggia',
            width: 160,
            render: (value) => {
                return (
                    <span>{value === 'default' ? "Mặc định" : value}</span>
                )
            }
        }] : [],
        {
            title: 'Hiển thị',
            align: 'center',
            width: 100,
            render: (row) => {
                return (
                    <Switch loading={hide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={!row.hienthi}
                        onChange={(e) => { updateHide(row.ma, e); }} />
                )
            }
        },
        {
            title: 'Thao tác',
            width: 150,
            render: (row) => {
                return (
                    <div className='d-flex'>
                        <Tooltip placement="top" title="Sửa">
                            <Button className='btn btn-warning'
                                onClick={() => {
                                    setEdit(true);
                                    setDetail(row);
                                    setModalCreate(true);
                                }}
                            ><i className='fa fa-edit'></i></Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa">
                            <Popconfirm
                                title="Xác nhận xóa?"
                                onConfirm={(e) => {
                                    deleteCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='="btn btn-danger ml-2'><i className='fa fa-trash'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip placement="top" title="Đặt lại mật khẩu">
                            <Popconfirm
                                title="Xác nhận đặt lại mật khẩu?"
                                onConfirm={(e) => {
                                    ResetPWCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='btn btn-color ml-2'><i className='fa fa-sync-alt'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        getList();
        resetSelected();
    }, [currentPage, pageSize]);

    useEffect(() => {
        loadNhanVienSale("");
        getListBangGia();
        getListParent();
    }, [])
    
    useEffect(() => {
        getListChild();
    }, [parentId])

    const getList = (page = currentPage, size = pageSize) => {
        Actions.setLoading(true);
        CoporationServices.GetAllCoporation(kwd, status, page, size, false, saleKey, bangGiaKey, daiDienKey, parentId, childId).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
            .finally(() => Actions.setLoading(false))
    }

    const ResetPWCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.ResetPWCoporation(ma).then(result => {
            if (result.isSuccess) {
                Actions.openMessageDialog("Thông báo", "Thao tác thành công. Mật khẩu mới là: " + result.returnObject.password);
            } else {
                result.err.msgString ?
                    notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))
    }

    const deleteCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.DeleteCoporation(ma).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                getList();
            } else {
                result.err.msgString ?
                    Actions.openMessageDialog('Thông báo', result.err.msgString)
                    : Actions.openMessageDialog("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại sau");
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))
    }

    const updateHide = (ma, check) => {
        CoporationServices.HideCoporation(ma, !check).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                getList();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    const loadNhanVienSale = (key) => {
        let filterModel = {
            skipCount: 0,
            maxResultCount: 20,
            keyword: key,
            role: USER_ROLE.SALE,
        }
        NhanVienService.GetListEmployee(filterModel).then(result => {
            if (result.isSuccess) {
                setListSale(result.data.items);
            }
        }).catch(err => {
        }).finally(() => { })
    }

    const getListBangGia = () => {
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setListBangGia(result.data)
            }
        }).catch(err => {
        }).finally(() => { })
    }

    const onSearch = () => {
        currentPage !== 1 ? setCurrentPage(1) : getList(1);
    }

    const onClickUpdateInfoMulti = (buttonName) => {
        if (!isnotnull(listCopIds)) {
            notification.warning({ message: "Vui lòng tick chọn nguồn khách hàng cần cập nhật." });
            return;
        }
        setVisibleUpdateInfoMulti(true);
        setUpdateType(buttonName);
    }

    const onSelectCop = (check, ma) => {
        let newListCopId = [...listCopIds];
        if (check) newListCopId.push(ma);
        else newListCopId = newListCopId.filter(x => x != ma);
        setListCopIds(newListCopId);
        forceUpdate();
    }

    const onChangeCheckAll = (check) => {
        let newListCopId = [];
        if (check) newListCopId = dataSource.map(x => x.ma);
        setListCopIds(newListCopId);
    }

    useEffect(() => {
        if (listCopIds.length > 0) {
            if (listCopIds.length == dataSource.length) {
                setCheckAll(true);
                setIndeterminate(false);
            }
            else {
                setCheckAll(false);
                setIndeterminate(true);
            }
        } else unSelectAll();
        forceUpdate();
    }, [listCopIds])

    const unSelectAll = () => {
        setCheckAll(false);
        setIndeterminate(false);
    }

    const resetSelected = () => {
        setListCopIds([]);
    }

    const getListParent = (key = "") => {
        const data = {
            keyword: key,
            skipCount: 0,
            maxResultCount: 10,
        }
        CoporationConfigServices.GetListParent(data).then(res => {
            if (res.isSuccess)
                setListParent(res.data.items);
        }).catch(err => {
        }).finally()
    }

    const getListChild = (key = "") => {
        const data = {
            keyword: key,
            parentId: parentId,
            skipCount: 0,
            maxResultCount: 10,
        }
        CoporationConfigServices.GetListChildGroup(data).then(res => {
            if (res.isSuccess) {
                setListChild(res.data.items);
            }
        }).catch(err => {
        }).finally()
    }

    return (
        <Fragment>
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <HeaderModal title="Quản lý nguồn khách hàng" />
                    <div className="row">
                        <div className='col-md-3 mt-2'>
                            <label className='mita-title'>Nhân viên sale</label>
                            <Select className="w-100"
                                placeholder="Chọn nhân viên sale"
                                value={saleKey}
                                allowClear
                                showSearch
                                onSearch={(key) => {
                                    if (task) clearTimeout(task);
                                    task = setTimeout(() => {
                                        loadNhanVienSale(key);
                                    }, 400);
                                }}
                                filterOption={false}
                                onChange={e => {
                                    setSaleKey(e)
                                }}
                            >
                                {
                                    listSale && listSale.map((sale, index) => {
                                        return (
                                            <Option key={`sale-${index}`} value={sale.id}>{`${sale.code ? sale.code + " - " : ""}${sale.hoTen}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <label className='mita-title'>Bảng giá</label>
                            <Select className="w-100"
                                placeholder="Chọn bảng giá"
                                value={bangGiaKey}
                                allowClear
                                showSearch
                                onChange={e => {
                                    setBangGiaKey(e)
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    listBangGia && listBangGia.map((bg, index) => {
                                        return (
                                            <Option key={`bangGia-${index}`} value={bg.value}>{bg.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <label className='mita-title'>Đại diện</label>
                            <Select className="w-100"
                                placeholder="Chọn đại diện"
                                value={daiDienKey}
                                allowClear
                                showSearch
                                onChange={e => {
                                    setDaiDienKey(e)
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    listDaiDien && listDaiDien.map((dd, index) => {
                                        return (
                                            <Option key={`daiDien-${index}`} value={dd.value}>{dd.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <label className='mita-title'>Trạng thái hiển thị</label>
                            <Select className="w-100"
                                value={status}
                                onChange={e => {
                                    setStatus(e)
                                }}
                            >
                                <Option key={0} value=''>Tất cả</Option>
                                <Option key={1} value='False'>Hiển thị</Option>
                                <Option key={2} value='True'>Đã ẩn</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-2">
                            <label className='mita-title'>Nguồn khách hàng tổng</label>
                            <Select placeholder='Chọn nguồn khách hàng tổng'
                                value={parentId} className='w-100'
                                onChange={(e) => setParentId(e)}
                                showSearch allowClear filterOption={false}
                                onSearch={(e) => {
                                    if (task) clearTimeout(task);
                                    task = setTimeout(() => {
                                        getListParent(e);
                                    }, 400)
                                }}
                            >
                                {listParent && listParent.map((x, index) => {
                                    return (
                                        <Select.Option key={index} value={x.uid}>{x.parentName}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-md-3 mt-2">
                            <label className='mita-title'>Nhóm nguồn con</label>
                            <Select placeholder='Chọn nhóm nguồn con'
                                value={childId} className='w-100'
                                onChange={(e) => setChildId(e)}
                                showSearch allowClear filterOption={false}
                                onSearch={(e) => {
                                    if (task) clearTimeout(task);
                                    task = setTimeout(() => {
                                        getListChild(e);
                                    }, 400)
                                }}
                            >
                                {listChild && listChild.map((x, index) => {
                                    return (
                                        <Select.Option key={index} value={x.uid}>{x.childName}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-md-6 mt-2">
                            <label className='mita-title'>Từ khóa tìm kiếm</label>
                            <Input
                                className="w-100"
                                value={kwd} placeholder="Nhập tên hoặc mã hoặc số điện thoại sau đó bấm Enter để tìm kiếm"
                                onChange={(e) => setKwd(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onSearch();
                                    }
                                }}
                                suffix={<span onClick={() => { onSearch() }}><i className='fas fa-search' /></span>}>
                            </Input>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 mt-2 d-flex justify-content-between">
                            <div>
                                <button className={`${BUTTON.SUCCESS}`}
                                    onClick={() => {
                                        setEdit(false);
                                        setModalCreate(true);
                                    }}>
                                    <i className="fa fa-plus mr-1" />Tạo mới
                                </button>
                                {
                                    listUpdateMulti && listUpdateMulti.map((me, index) => {
                                        return (
                                            <span key={index} className='ml-2' style={{ borderLeft: "solid 1px" }}>
                                                <a href='#'
                                                    onClick={() => { onClickUpdateInfoMulti(me.field) }}>
                                                    <i className='fas fa-pen mr-1 ml-1'></i>{me.title}</a>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                            <button className={`${BUTTON.THEME}`} onClick={() => onSearch()}>Tìm kiếm</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px', height: 'calc(100% - 278px)' }} className='mt-2'>
                        <Table dataSource={dataSource} scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns}
                            rowKey={(e, index) => `tbl-copr-${e.ma}`}
                            rowClassName={"rowCustom"}
                        />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setPageSize(size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            {modalCreate &&
                <Modal
                    visible={modalCreate}
                    onCancel={() => {
                        setEdit(false);
                        setModalCreate(false);
                        setDetail({});
                    }}
                    width={1000}
                >
                    <Update edit={edit}
                        detail={detail}
                        onSuccess={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }}
                        onClose={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }} />
                </Modal>
            }
            <Drawer open={visibleUpdateInfoMulti}
                className='custom-ant-drawer'
                maskClosable={true}
                width={700}
                placement="right"
                closable={false}
                destroyOnClose={true}
                onClose={() => { setVisibleUpdateInfoMulti(false) }}>
                <UpdateMulti
                    type={updateType} copIds={listCopIds} listCops={dataSource}
                    onClose={() => setVisibleUpdateInfoMulti(false)} typeName={listUpdateMulti.find(x => x.field == updateType)?.title}
                    onSuccess={() => { getList(); resetSelected(); setVisibleUpdateInfoMulti(false); }}
                />
            </Drawer>
        </Fragment>
    );
}

export default Corporation;