import { Input, InputNumber, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import AutocompleteTable from '../../../../components/autocompletetable';
import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as InHospitalService from '../../../../services/inPatientService';
import * as MedicineService from '../../../../services/medicineService';
import * as PrintService from '../../../../services/printService';

const initialState = {
    mathuoc: '',
    idCapThuoc: '',
    songay: 1,
    soluong: 1,
    solan: 1,
    sovien: 1,
    cachdung: '',
}
export default class CapThuocNoiTru extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDetailNew: [],
            lstKho: [],
            lstMedic: [],
            lstNguon: [],
            lstTmpSearch: [],
            lstChiDinh: [],
            lstCachDung: [],
            tmpSearch: '',
            maNguon: '',
            maKho: this.props.makho,
            tenKho: this.props.tenkho,
            lstdepartment: [],
            listType: [this.props.tenkho],
            idbenhtrinh: this.props.idbenhtrinh ? this.props.idbenhtrinh : '',
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.makho !== this.props.makho) {
            this.setState({ maKho: nextProps.makho, tenKho: nextProps.tenkho }, () => {
                this.changeKho();
            });
        }
        if (nextProps.code !== this.props.code) {
            this.setState({ lstDetailNew: [] })
        }
        if (nextProps !== this.props) {
            this.setState({
                code: typeof (nextProps.code) !== undefined ? nextProps.code : '',
                name: typeof (nextProps.name) !== undefined ? nextProps.name : '',
                enterCode: typeof (nextProps.enterCode) !== undefined ? nextProps.enterCode : '',
                department: typeof (nextProps.department) !== undefined ? nextProps.department : '',
                cardNumber: typeof (nextProps.cardNumber) !== undefined ? nextProps.cardNumber : '',
                subjectName: typeof (nextProps.subjectName) !== undefined ? nextProps.subjectName : '',
                subjectCode: typeof (nextProps.subjectCode) !== undefined ? nextProps.subjectCode : '',
                managerId: typeof (nextProps.managerId) !== undefined ? nextProps.managerId : '',
                diagnostic: typeof (nextProps.machuandoan) !== undefined ? nextProps.machuandoan : '',
                diagnosticName: typeof (nextProps.tenchuandoan) !== undefined ? nextProps.tenchuandoan : '',
                doctorReceive: typeof (nextProps.mabs) !== undefined ? nextProps.mabs : '',
                departmentName: typeof (nextProps.department) !== undefined ? nextProps.departmentName : '',
                idbenhtrinh: this.props.idbenhtrinh,
            })
        }
        return true;
    }

    componentDidMount() {
        this.loadKho();
        this.loadNguon();
        this.DanhSachThuocNoiTruTheoBenhTrinh();
    }
    changeKho() {
        let { lstDetailNew } = this.state;
        lstDetailNew = lstDetailNew.map(x => ({ ...x, makho: this.state.maKho, tenkho: this.state.tenKho }))
        this.setState({ lstDetailNew: lstDetailNew, listType: [this.state.tenKho] }, () => {
            this.SearchMedicine();
        });
    }
    loadKho() {
        MedicineService.GetListKho().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    //maKho: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    loadNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNguon: result.data,
                    maNguon: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }

    DanhSachCachDung(txtCachdung) {
        let data = [];
        MedicineService.DanhSachCachDung(txtCachdung).then(result => {
            if (result.isSuccess) {
                data = result.data;
                if (result.data.findIndex(x => x.value === txtCachdung) < 0) {
                    data.push({ 'value': txtCachdung, 'label': txtCachdung })
                }
                this.setState({
                    lstCachDung: data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải cách dùng bị lỗi' })
        })
    }

    xoathuoc(row) {

        let lstDetailNew = this.state.lstDetailNew;
        let lst = this.state.lstDetailNew.filter(x => x.mabd !== row.mabd);
        lstDetailNew = lst;
        this.setState({ lstDetailNew })
    }

    AddNewMedicine(item) {
        let { lstDetailNew } = this.state
        item.id = Number(item.value);

        item.makho = Number(item.makho);
        item.manguon = Number(item.manguon);
        let olditems = lstDetailNew.filter(record => record.ma === item.ma);
        if (olditems.length > 0) {
            return
        } else {
            item.tenthuoc = item.ten;
            item.dang = item.donvi;
            item.isNew = true
            item.key = item.ma
            item.countNumber = lstDetailNew.length + 1;
            item.idCapThuoc = this.state.idCapThuoc;
            item.nhom = 0;
            item.ngay = moment().format('YYYY/MM/DD');
            item.manguon = '11';
            item.makho = this.state.maKho ? this.state.maKho.toString() : 502;
            item.tenkho = this.state.tenKho;
            item.mabd = item.id.toString();
            item.soluong = 1;
            item.idBenhTrinh = this.props.idbenhtrinh ? this.props.idbenhtrinh : '';
            lstDetailNew.push(item);
        }
        // let listType = lstDetailNew.map(x => x.tenkho);
        // let ListTypeDistinct = listType.filter((x, index) => (x!=="" && !listType.includes(x, index + 1)));
        if (this.state.listType.length === 0) {
            this.setState({ listType: [this.state.tenKho] })
        }
        this.setState({ lstDetailNew: lstDetailNew })

    }

    SearchMedicine(key) {
        let noitru = this.props.noitru === true;
        return MedicineService.DanhSachThuocBanle(2, key, 11, this.state.maKho, noitru)
    }

    onSelect(item) {
        if (item.slton === 0) {
            notification.warning({ message: `Thuốc ${item.ten} không còn trong kho !!!` })
        } else {
            this.AddNewMedicine(item)
        }
    }

    DanhSachThuocNoiTruTheoBenhTrinh() {
        if (this.props.idbenhtrinh === undefined || this.props.idbenhtrinh === '')
            return;
        InHospitalService.DanhSachThuocNoiTruTheoBenhTrinh(this.props.idbenhtrinh).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                data.forEach(element => {
                    element.tenthuoc = element.ten
                    element.key = element.ma
                });
                let listType = result.data.map(x => x.tenkho);
                let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                this.setState({
                    lstDetailNew: data,
                    idCapThuoc: data.length > 0 ? data[0].idCapThuoc : '',
                    listType: ListTypeDistinct,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cấp thuốc của bệnh nhân lỗi' })
        })
    }

    DanhSachCapThuocBenhNhan() {
        if (this.state.code === undefined || this.state.code === null || this.state.code === '') {
            return;
        }
        let ngay = moment();
        if (this.props.dateIn !== undefined) {
            ngay = moment(this.props.dateIn);
        }
        MedicineService.DanhSachCapThuocBenhNhan(0, this.state.code, this.state.managerId, ngay.format('YYYY/MM/DD'), true).then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.soluong > 0);
                //let data=result.data;
                data.forEach(element => {
                    element.tenthuoc = element.ten
                    element.key = element.ma
                });
                this.setState({
                    lstDetailNew: data,
                    idCapThuoc: data.length > 0 ? data[0].idCapThuoc : ''
                });
                setTimeout(() => {
                    if (this.newTable)
                        this.newTable.setDataSource(data);
                }, 200)

            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cấp thuốc của bệnh nhân lỗi' })
        })
    }

    PrintConclusion() {
        let ngay = moment();
        if (this.props.dateIn !== undefined) {
            ngay = moment(this.props.dateIn);
        }
        PrintService.PrintConclusion(0, this.state.code, this.state.managerId, ngay.format('YYYY/MM/DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    resetTable() {
        this.setState({ lstDetailNew: [], listType: [] })
    }

    getData() {
        const data = {
            "listCapThuoc": this.state.lstDetailNew,
        }
        return data;
    }

    editAmount(row, soluong) {
        let { lstDetailNew } = this.state
        let index = lstDetailNew.findIndex(record => record.mabd === row.mabd);
        lstDetailNew[index].soluong = soluong;
        this.setState({ lstDetailNew })
    }
    editWays(row, cachdung) {
        let { lstDetailNew } = this.state
        let index = lstDetailNew.findIndex(record => record.mabd === row.mabd);
        lstDetailNew[index].cachdung = cachdung;
        this.setState({ lstDetailNew })
    }
    render() {
        let columnsSearh = [
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
                width: 200,
            },
            {
                title: 'Tên hoạt chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
                width: 350,
            },
            {
                title: 'Nước sản xuất',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
                width: 100,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                width: 80,
                format: 'number'
            },
            {
                title: 'Đơn vị',
                dataIndex: 'donvi',
                key: 'donvi',
                width: 80,

            },
            {
                title: 'Đường dùng',
                dataIndex: 'duongdung',
                key: 'duongdung',
                width: 100,
            },
            {
                title: 'Tồn kho',
                dataIndex: 'slton',
                key: 'slton',
                width: 80,
                format: 'number'
            },
            {
                title: 'Dạng thuốc',
                dataIndex: 'dang',
                key: 'dang',
                width: 100,
            },
        ];
        if (this.props.noitru === true) {
            columnsSearh.push(
                {
                    title: 'SL khả dụng',
                    dataIndex: 'sldangcap',
                    key: 'sldangcap',
                    width: 120,
                    format: 'number'
                }
            )
        }
        let dataSourceNew = this.state.lstDetailNew.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.ma
            return o;
        })
        let pos = 0;
        return (
            <React.Fragment>
                {this.props.visibleSave === false ? '' :
                    <React.Fragment>

                        <div style={{ justifyContent: 'space-between', marginTop: '8px', marginBottom: '8px' }}>
                            <div style={{ marginTop: 4, width: '100%' }}>
                                <AutocompleteTable
                                    placeholder={"Tìm theo tên hay hoạt chất"}
                                    width={'100%'}
                                    tablewidth={800}
                                    onSearch={this.SearchMedicine.bind(this)}
                                    onSelect={this.onSelect.bind(this)}
                                    value={this.state.thuoc} headers={columnsSearh}
                                    keyValue={'ma'}
                                    ref={(c) => { this.Autocomplete = c }}
                                >
                                </AutocompleteTable>
                            </div>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-inverse table-hover">
                                {this.props.showHeader === true &&
                                    <thead>
                                        <tr>
                                            <th className="font-weight-bold" style={{ minWidth: '50px' }}>STT</th>
                                            <th className="font-weight-bold" style={{ minWidth: '150px' }}>Tên thuốc</th>
                                            <th className="font-weight-bold" style={{ minWidth: '150px' }}>Hoạt chất</th>
                                            <th className="font-weight-bold" style={{ minWidth: '100px' }}>Số lượng</th>
                                            <th className="font-weight-bold" style={{ minWidth: '150px' }}>Cách dùng</th>
                                            <th className="font-weight-bold" style={{ minWidth: '100px' }}>ĐVT</th>
                                            <th className="font-weight-bold" style={{ minWidth: '100px' }}>Đường dùng</th>
                                            <th className="font-weight-bold" style={{ minWidth: '50px' }}></th>
                                        </tr>
                                    </thead>
                                }
                                {this.state.listType.map((type, i) => {
                                    if (i > 0) return;
                                    pos++;
                                    return (
                                        <tbody key={'type'}>
                                            <tr key={'type' + i}>
                                                <th colSpan="8" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{type}</th>
                                            </tr>
                                            {dataSourceNew.map((row, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{pos++}</td>
                                                        <td>{row.tenthuoc}</td>
                                                        <td>{row.tenhc}</td>
                                                        <td>
                                                            <InputNumber value={row.soluong} style={{ width: '80px' }} min={1} maxLength={4} max={9999}
                                                                onChange={(e) => { this.editAmount(row, e) }} />
                                                        </td>
                                                        <td><Input value={row.cachdung} style={{ width: '150px' }}
                                                            onChange={(e) => { this.editWays(row, e.target.value) }} />
                                                        </td>
                                                        <td>{row.dang}</td>
                                                        <td>{row.duongdung}</td>
                                                        <td>
                                                            <div onClick={() => { this.xoathuoc(row) }} style={{ cursor: 'pointer' }}>
                                                                <span style={{ width: '100%', height: '100%', marginTop: 3, color: 'red' }}> <i className="fa fa-trash"></i>  </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    )
                                })}
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
