import moment from 'moment';
import React, { PureComponent } from 'react';


export default class Administrative extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let { item } = this.props;
        return (
            <React.Fragment>
                {item &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-4">
                                <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'><div style={{ width: 70 }}>Mã BN:</div>
                                    <b>{item.patientID}</b>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                            <div style={{ width: 70 }}>Loại:</div><b>{this.props.loai === 1 ? 'Thuốc theo đơn' : 'Thuốc bán lẻ'}</b>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                            <div style={{ width: 70 }}>Trạng thái:</div><b>{this.props.thanhtoan === true ? 'Đã thanh toán' : 'Chưa thanh toán'}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                    <div style={{ width: 70 }}>Họ tên:</div><b> {item.patientName}</b>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-4">
                                        <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                            <div style={{ width: 70 }}>Giới tính:</div><b>{item.gender === 0 ? "Nam" : "Nữ"}</b>
                                        </div>
                                    </div>
                                    {moment().diff(moment(item.birthDay), 'months') > 36 ?
                                        <div className="col-4">
                                            <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                                <div style={{ width: 70 }}>Tuổi:</div><b>{moment().year() - moment(item.birthDay).year()}</b>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-4">
                                            <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                                <div style={{ width: 80 }}>Tháng tuổi:</div><b>{
                                                    moment().diff(moment(item.birthDay), 'months')
                                                }</b>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'><div style={{ width: 70 }}>Điện thoại:</div><b> {item.phone}</b></div>
                            </div>
                            <div className="col-md-8">
                                <div style={{ display: 'flex' }} className='txtLabelAdmin w-100'>
                                    <div style={{ width: 70 }}>Địa chỉ:</div><b>{item.address}</b>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
