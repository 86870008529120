import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import moment from 'moment';
import { DatePicker, Input, Modal, Tooltip, Select, Form, Button, Table, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../configure/systemConfig';
import * as AppointmentService from '../../services/appointmentService';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import HeaderModal from '../../configureHIS/common/headerModal';
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import AutocompleteTable from '../../components/autocompletetable';
import { connect } from 'react-redux';
import * as ConfigService from '../../services/configService';
import * as ChiDinhService from '../../services/chiDinhService';
import ModalCLS from '../../components/MDCLS';
import { isNullOrEmpty } from '../../libs/util';

const { TextArea } = Input;

const ScheduleDetail = forwardRef((props, ref) => {
    const [idLichHen, setIdLichHen] = useState(props.scheduleId ?? null);
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [selectedPatient, setSelectedPatient] = useState({});
    const [inEditmode, setInEditmode] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [listChiDinh, setListChiDinh] = useState([]);
    const [dataSourceChiDinh, setDataSourceChiDinh] = useState([]);
    const [bangGia, setBangGia] = useState();
    const [siteCreate, setSiteCreate] = useState([]);
    const [listSite, setListSite] = useState([]);
    const [keyword,] = useState("");
    const [visibleCLS, setVisibleCLS] = useState(false);
    const [total, setTotal] = useState(0);

    const _nguonKhachHang = useRef();
    const _modalCLS = useRef();
    const _listServicesRef = useRef();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        GetListSite();
        getAppoimentDetailByID();
    }, []);
    
    useEffect(() => {
        let dataSource = listChiDinh.map(function (e, index) {
            let o = Object.assign({}, e);
            // o.key = o.id
            o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString()+'-') : "") + o.id.toString();
            o.children = o.listChild;
            return o;
        })
        setDataSourceChiDinh(dataSource);
        console.log('useEffect listChiDinh');
        console.log(listChiDinh);
        forceUpdate();
    }, [listChiDinh]);

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else setListSite([]);
        }).catch(() => { })
    }

    const getAppoimentDetailByID = record => {
        AppointmentService.GetAppoimentDetailByID(idLichHen, props.siteId).then(result => {
            if (result.isSuccess) {
                let data = result.item.lstChiDinh;
                setSelectedDate(result.item.ngayhen);
                setSelectedPatient(result.item);
                setSelectedAppointment(record);
                setListChiDinh(data);
                setBangGia(data.find(x => x.banggia !== "default") ? data.find(x => x.banggia !== "default").banggia : "default");
                setSiteCreate(result.item.chinhanh);
                forceUpdate();
            } else {
                Actions.openMessageDialog("Thông báo" + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    };

    const onCancel = () => {
        props.onCancel();
        setInEditmode(false);
    }

    const checkBangGia = (info) => {
        let banggia = bangGia;
        let itemFound = listChiDinh.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        let banggianguonkhachhang = bangGia;
        if (info.banggia !== null) {
            banggianguonkhachhang = info.banggia
        }
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && banggianguonkhachhang !== banggia) {
            if (listChiDinh.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${banggianguonkhachhang === "default" ? "Mặc định" : banggianguonkhachhang}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                cancelExam();
                banggia = banggianguonkhachhang;
            }
            setBangGia(banggia);
        } else setBangGia(banggianguonkhachhang);
    }

    const cancelExam = () => {
        setListChiDinh([]);
    }

    const searchIndication = (key) => {
        return ChiDinhService.searchSerivce(0, 0, bangGia, key, 1, 20);
    }

    const onSelectIndication = (item) => {
        let lstChiDinh = listChiDinh;
        var existed = lstChiDinh.find(x => x.id === item.id);
        if (existed === undefined) {
            item.banggia = "default";
            item.soluong = 1;
            item.idChiDinh = "0";
            let total = 0;
            lstChiDinh.push(item)
            lstChiDinh.map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
            setListChiDinh(lstChiDinh);
        }
    }

    const columnsSearh = [
        {
            title: 'Mã Dịch vụ',
            dataIndex: 'mavp',
            key: 'mavp',
            width: 70
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
            width: 300,
        },
        {
            title: 'Đơn giá',
            dataIndex: 'dongia',
            key: 'dongia',
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
            width: 70,
            format: 'number',
        },
    ];

    const regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '8%',
            // render: (text, record, index) => (Number(index) + 1),
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title: 'Đơn giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            // render: (value) => (<span>{value ? value.toLocaleString('en-US') : '0'}</span>),
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        {
            title: 'Số lượng',
            render: (row) => (
                <span>{1}</span>
            ),
            align: 'center',
            width: '15%'
        },
        {
            title: 'Thành tiền',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 100,
            // render: (value, row) => (<Tooltip title={row.dongia && row.dongia.length > 0 && "Thuộc chương trình tặng dịch vụ"}><span>{(value).toLocaleString('en-US')}</span></Tooltip>),
            render: (value, row) => { return (
                row.isChild ? "" : 
                (<Tooltip title={row.dongia && row.dongia.length > 0 && "Thuộc chương trình tặng dịch vụ"}><span>{(value).toLocaleString('en-US')}</span></Tooltip>)
            )}
        },
        {
            title: '',
            render: (text, record, index) => {
                return (
                    <div>
                        {inEditmode === false ? <button disabled={inEditmode === false} className={`btn btn-danger`} onClick={() => {
                            xoaChiDinh(record, index)
                        }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </button> :
                            <Tooltip title={record.voucher && record.voucher.length > 0 && "Thuộc chương trình tặng dịch vụ"}>
                                <button className={`btn btn-danger`} onClick={() => {
                                    xoaChiDinh(record, index)
                                }}>
                                    <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            },
            align: 'center',
            width: '10%'
        }
    ];

    const xoaChiDinh = (record, index) => {
        let lstChiDinh = listChiDinh;
        lstChiDinh.splice(index, 1);
        setListChiDinh(lstChiDinh);
    }

    const getListChiDinh = (data) => {
        setListChiDinh(data);
    }

    const resetStateNguonKhachHang = () => {
        _nguonKhachHang && _nguonKhachHang.current.resetState();
    }

    
    const updateAppointment = () => {
        const data = {
            ngayhen: selectedDate,
            lstChiDinh: listChiDinh,
            mabn: selectedAppointment.mabn,
            maql: selectedPatient.maql.toString(),
            site : siteCreate.toString(),
        }
        AppointmentService.updateAppointment(selectedAppointment.id, data, this.props.siteId).then((result) => {
            if (result.isSuccess) {
                _nguonKhachHang && _nguonKhachHang.updateInfo();
                notification.success({ message: "Cập nhật thàng công" })
            } else {
                Actions.openMessageDialog("Thông báo", result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    return (
        <Fragment>
            <HeaderModal title="Chi tiết lịch hẹn" onClose={() => { onCancel(); }} />
            {selectedPatient &&
                <Fragment>
                    <div className="row">
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:</span><b>{selectedPatient.tenbn}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:</span><b>{selectedPatient.mabn}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:</span><b>{selectedPatient.onlyyear ? "Không ghi nhận" : moment(selectedPatient.ngaysinh).format("DD-MM")}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:</span><b>{moment(selectedPatient.ngaysinh).format("YYYY")}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:</span><b>{selectedPatient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND:</span> <b>{selectedPatient.cmnd}</b></div>
                        <div className="col-12 d-flex mb-2"><span style={{ width: '100px' }}>Địa chỉ:</span><b>{selectedPatient.diachi}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:</span><b>{selectedPatient.dienthoai}</b></div>
                        <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Thời gian:</span>
                            <DatePicker locale={viVN} style={{ width: '200px' }}
                                allowClear={false} showTime format={SystemConfig.FORMAT_DATE_TIME}
                                onChange={(e) => { setSelectedDate(e) }} value={moment(selectedDate)}
                                disabledDate={(current) => (current && current.valueOf() < Date.now())}
                                disabled={!inEditmode} placeholder="Ngày-tháng-năm giờ:phút" />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <Nguonkhachhang
                            disable={!inEditmode}
                            ref={_nguonKhachHang}
                            managermentId={selectedPatient.maql}
                            updateInfoBangGia={(info) => { checkBangGia(info) }}
                            banggiaSite={bangGia} />
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                            <Select
                                placeholder="Chọn chi nhánh"
                                disabled={!inEditmode}
                                className="w-100"
                                value={siteCreate}
                                onChange={(value => setSiteCreate(value))}>
                                {listSite && listSite.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.ten}</option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 d-flex my-2"><span style={{ width: '100px' }}>Ghi chú:</span><b>{selectedPatient.ghichu}</b></div>
                        {!isNullOrEmpty(selectedPatient.ghichuHotline) && <div className="col-12 d-flex mb-2">
                            <label>Ghi chú hotline:</label>
                            <TextArea rows={3} value={selectedPatient.ghichuHotline} readOnly={true}></TextArea>
                        </div>}
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", marginTop: 10 }}>
                        <Tooltip placement="top" title={`Bảng giá: ${bangGia === 'default' ? "Mặc định" : bangGia}`}>
                            <Form.Item>
                                <AutocompleteTable
                                    ref={_listServicesRef}
                                    width="300px"
                                    disabled={!inEditmode}
                                    placeholder="Nhập tên dịch vụ để tìm kiếm"
                                    tablewidth={500}
                                    listHeight={"50vh"}
                                    onSearch={(value) => searchIndication(value)}
                                    onSelect={(item) => onSelectIndication(item)}
                                    value={keyword} headers={columnsSearh}
                                    keyValue={'mavp'}>
                                </AutocompleteTable>
                            </Form.Item>
                        </Tooltip>
                        <Form.Item>
                            <Tooltip title="Mở danh sách dịch vụ">
                                <Button style={{ marginLeft: 10, borderRadius: 8 }}
                                    disabled={!inEditmode}
                                    onClick={() => {
                                        setVisibleCLS(true);
                                        _modalCLS && _modalCLS.current && _modalCLS.current.UpdateSelectedFromProps(listChiDinh);
                                    }}><i className="fas fa-list" /></Button>
                            </Tooltip>
                        </Form.Item>
                    </div>

                    <div className="mt-2" style={{ height: 'calc(100% - 500px)' }} >
                        <Table dataSource={dataSourceChiDinh}
                            rowKey={(e) => (e.key ?? e.idChiDinh)}
                            columns={regiterServiceColumn}
                            pagination={false}
                            scroll={{ y: 0 }}
                        />
                    </div>
                </Fragment>}
            <div className='custom-hr'></div>
            <div className='w-100 d-flex justify-content-center align-items-center mt-2'>
                {/* {!inEditmode ?
                    <button className="btn btn-color" onClick={() => setInEditmode(true)}>
                        <i className="fa fa-edit mr-1"></i>Cập nhật
                    </button>
                    :
                    <button className="btn btn-color" onClick={() => updateAppointment()}>
                        <i className="fa fa-save mr-1"></i>Lưu
                    </button>
                } */}
                <button className="btn btn-danger ml-2" onClick={() => {
                    onCancel();
                    resetStateNguonKhachHang();
                }}><i className="fas fa-sign-out-alt mr-1" />Đóng
                </button>
            </div>
            {visibleCLS &&
                <Modal maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={visibleCLS}
                    width={1500}
                >
                    <ModalCLS
                        hidePhongKham={true}
                        maskClosable={false}
                        ref={_modalCLS}
                        editAfterRegister={true}
                        isnew={true}
                        patientID={selectedPatient.mabn}
                        getListChiDinh={(data) => getListChiDinh(data)}
                        onClose={(lstChiDinh) => {
                            lstChiDinh = lstChiDinh.map(x => ({ ...x }))
                            if (lstChiDinh === null) lstChiDinh = [];
                            let total = 0;
                            lstChiDinh.concat(lstChiDinh).map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
                            setVisibleCLS(false);
                            setListChiDinh(lstChiDinh);
                            setTotal(total);
                        }}
                        banggia={bangGia}
                        onReplaceBangGia={(tenBangGia) => {
                            if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                setBangGia(tenBangGia)
                                _listServicesRef && _listServicesRef.current.handleSearch((value) => searchIndication(value));
                            }
                        }}
                        disableSelectBangGia={true}
                    />
                </Modal>
            }
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        siteId: state.global.siteid,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ScheduleDetail);