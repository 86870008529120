import { Button, Modal, Select, Popconfirm, notification, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import * as Actions from '../../../../libs/actions';
import * as ConfigService from '../../../../services/configService';
import Update from './update';
const { Option } = Select;

export default forwardRef((props, ref) => {
    const [dataSource, setDataSource] = useState([]);
    const [detail, setDetail] = useState({});
    const [modalCreate, setModalCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [dataSite, setDataSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    useImperativeHandle(ref, () => ({
        GetListSite: () => {
            GetListSite();
        }
    }));

    useEffect(() => {
        if (chinhanh !== '')
            GetListHub();
    }, [chinhanh])
    useEffect(() => {
        GetListSite();
    }, [])
    const GetListSite = () => {
        Actions.setLoading(true);
        ConfigService.GetListSite(true).then(result => {
            if (result.isSuccess) {
                setDataSite(result.data);
                if (result.data.length > 0) {
                    setChinhanh(result.data[0].id.toString())
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const GetListHub = () => {
        Actions.setLoading(true);
        ConfigService.GetListHub(chinhanh).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const DeleteHub = (id) => {
        Actions.setLoading(true);
        ConfigService.DeleteHub(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                GetListHub();
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const columns = [
        {
            title: 'Tên',
            dataIndex: 'hubname',
            key: 'hubname',
            width: 250
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: 300,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 200
        },
        {
            title: 'Action',
            width: 150,
            render: (row) => {
                return (
                    <div className='="d-flex'>
                        <Tooltip placement="top" title="Sửa">
                            <Button className='btn btn-warning'
                                onClick={() => {
                                    setEdit(true);
                                    setDetail(row);
                                    setModalCreate(true);
                                }}
                            ><i className='fa fa-edit'></i></Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa">
                            <Popconfirm
                                title="Xác nhận xóa?"
                                onConfirm={(e) => {
                                    DeleteHub(row.id)
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">

                                <Button className='="btn btn-danger ml-2'><i className='fa fa-trash'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                )
            }
        }
    ];
    return (
        <React.Fragment>
            <div style={{ padding: '10px', height: '100%' }}>
                <div className="row justify-content-center align-content-between">
                    <div className="col-auto mb-2">
                        <Select
                            showSearch
                            value={chinhanh}
                            style={{ width: '300px' }}
                            onChange={(e) => { setChinhanh(e) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {dataSite.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id.toString()}>{row.ma}-{row.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-auto mb-2 ml-auto">
                        <button type="button" className="btn btn-success ml-2"
                            onClick={() => {
                                setEdit(false);
                                setModalCreate(true);
                            }}>
                            <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới
                        </button>
                    </div>
                </div>
                <div style={{ marginBottom: '10px', height: 'calc(100% - 75px)' }}>
                    <Table dataSource={dataSource} scroll={{ y: 0 }}
                        pagination={{
                            showTotal: total => `Tổng: ${total}`,
                            position: ["bottomCenter"],
                            style: {
                                marginBottom: 5
                            },
                            showSizeChanger: true
                        }}
                        columns={columns} rowKey={(e, index) => index}
                    />
                </div>
            </div>
            {modalCreate &&
                <Modal
                    visible={modalCreate}
                    onCancel={() => {
                        setEdit(false);
                        setModalCreate(false);
                        setDetail({});
                    }}
                    width={1000}
                >
                    <Update edit={edit}
                        detail={detail}
                        chinhanh={chinhanh}
                        onSuccess={() => {
                            GetListHub();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }}
                        onClose={() => {
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }} />
                </Modal>
            }
        </React.Fragment>
    );
})
