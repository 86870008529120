import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Lis';
export function DanhSachBenhNhan(dateFrom, dateTo, key = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbenhnhan?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachBenhNhanDichVu(dateFrom, dateTo, chinhanh, type, key = '', page = 1, size = 10, sendby, coporator) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachBenhNhanDichVu?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&key=${key}&page=${page}&size=${size}&sendby=${sendby}&chinhanh=${chinhanh}&copr=${coporator}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuThuatBenhNhanIncludeInfo(dateFrom, dateTo, type, key = '', page = 1, size = 10, trangthai = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuThuatBenhNhanIncludeInfo?dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&key=${key}&page=${page}&size=${size}&trangthai=${trangthai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinKetQua(maql, sid, chinhanh, noitru = false, nhomvp) {
    if (sid === undefined)
        sid = '';
    if (chinhanh === undefined)
        chinhanh = '';
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetQua?maql=${maql}&sid=${sid}&noitru=${noitru}&chinhanh=${chinhanh}&nhomvp=${nhomvp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongTinKetQuaTheoBenhTrinh(idbenhtrinh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongTinKetQuaTheoBenhTrinh?idbenhtrinh=${idbenhtrinh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ResendDataToLis(maql, ngay, sid,chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ResendDataToLis?maql=${maql}&ngay=${ngay}&sid=${sid}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListErrorTests(dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getListErrorTests?from=${dateFrom}&to=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachBenhNhanGuiMau(dateFrom, dateTo, chinhanh, key = '', page = 1, size = 10, sendby, coporator) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachBenhNhanGuiMau?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&sendby=${sendby}&chinhanh=${chinhanh}&copr=${coporator}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TraKetQuaGuiMau(maql, sid,chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/TraKetQuaGuiMau?maql=${maql}&sid=${sid}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachTraKetQua(sendby, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachTraKetQua?loc=${sendby}&site=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TraKetQuaChoBenhNhan(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/TraKetQua`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListLISFile(maql, siteId=null) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListLISFile?maql=${maql}${siteId ? '&siteId='+siteId:''}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DownLoadLisFile(uuid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownLoadLisFile?uuid=${uuid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UploadFileResult(data,sid,siteid,title,ghiDeKetQua = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostWithFile(`${prefix}/UploadFileResult?sid=${sid}&siteid=${siteid}&title=${title}&ghide=${ghiDeKetQua}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SentDataToLis(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SendDataToLis`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
