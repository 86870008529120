import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Select, DatePicker, Button, Tabs } from 'antd';
import HeaderModal from "../../../../../configureHIS/common/headerModal";
import moment from "moment";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../../configure/systemConfig';
import * as CoporationServices from "../../../../../services/CorporationServices";
import * as VPService from '../../../../../configureHIS/update_vp/services/VPService';
import SiteFilter from '../../../../../configureHIS/common/siteFilter';
import DailyReportByPatient from "./DailyReportByPatient";
import DailyReportByService from "./DailyReportByService";

const { Option } = Select;
const { RangePicker } = DatePicker;
const viewTypeEnum = {
    PATIENT : 'PATIENT',
    SERVICE : 'SERVICE'
}
export default (props) => {
    const notiRef = useRef(null);
    const reportByPatient = useRef();
    const reportByService = useRef();
    const [siteIds, setSiteIds] = useState([]);
    const [paymentTypeIds, setPaymentTypeIds] = useState(null);
    const [coporationIds, setCoporationIds] = useState(null);
    const [date, setDate] = useState([moment(moment().format('YYYY-MM-DD 00:00')), moment()]);

    const [listPaymentTypes, setListPaymentTypes] = useState([]);
    const [listCoporations, setListCoporations] = useState([]);
    const [viewType, setViewType] = useState(viewTypeEnum.PATIENT);

    useEffect(() => {
        GetListCoporations();
        GetListPaymentType();
    }, [])

    const GetListCoporations = () => {
        CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
        if (result.isSuccess) {
            setListCoporations(result.data);
        }
        }).catch(err => {
        })
    }

    const GetListPaymentType = () => {
        VPService.GetListPaymentType().then(result => {
            if (result.isSuccess) {
                setListPaymentTypes(result.data);
            }
        }).catch(err => {
        })
    }

    const onClickStatisticHandle = () => {
        loadPatientOrService()
    }

    const clearFilter = () => {
        setCoporationIds(null);
        setPaymentTypeIds(null);
        setDate([new moment(), new moment()]);
    }

    const loadPatientOrService = () => {
        if (viewType == viewTypeEnum.PATIENT){
            reportByPatient.current && reportByPatient.current.loadData()
        } else {
            reportByService.current && reportByService.current.loadData()
        }
    }

    return (
        <Fragment>
            <div className="list-page containerChilderWrapper">
                <HeaderModal title='Tổng hợp ngày'/>
                <div className="list-page-body overflow-auto">{/* body */}
                    <div className="list-page-search">{/* search/filter */}
                        <div className="row">{/* Chi nhánh */}
                            <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
                        </div>
                        <div className="row mt-2">{/* Hình thức thanh toán | Nguồn khách hàng | Ngày lọc */}
                            <div className='col-md-4 d-flex flex-column'>{/* Hình thức thanh toán */}
                                <label>Hình thức thanh toán</label>
                                <Select
                                    placeholder="Chọn chọn hình thức thanh toán"
                                    value={paymentTypeIds}
                                    onChange={(e) => {setPaymentTypeIds(e)}}
                                    allowClear
                                    onClear={(e) => setPaymentTypeIds(null)}
                                >
                                    {(listPaymentTypes ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className='col-md-4 d-flex flex-column'>{/* Nguồn khách hàng */}
                                <label>Nguồn khách hàng</label>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn nguồn khách hàng"
                                    value={coporationIds}
                                    onChange={(e) => {setCoporationIds(e)}}
                                    onClear={(e) => setCoporationIds(null)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {(listCoporations ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className='col-md-4 d-flex flex-column'>{/* Nguồn khách hàng */}
                                <label>Khoảng thời gian</label>
                                <RangePicker 
                                    allowClear={false}
                                    locale={viVN}
                                    showNow={false}
                                    showTime={{ format: 'HH:mm' }}
                                    className="w-100"
                                    value={date}
                                    format = { SystemConfig.FORMAT_DATE_TIME }
                                    onChange={(e) => { setDate(e) }}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">{/* button Thống kê */}
                            <div className='col-md-12 d-flex justify-content-end'>
                                <Button className='btn btn-color ml-2' 
                                    onClick={() => {
                                        onClickStatisticHandle();
                                    }}
                                >
                                    <i className='far fa-chart-bar mr-2'></i> Thống kê
                                </Button>
                                <Button className='btn btn-secondary ml-2' 
                                    onClick={() => {
                                        clearFilter();
                                    }}
                                >
                                    <i className='fa fa-eraser mr-2'></i> Xóa dữ liệu nhập
                                </Button>
                            </div>
                        </div>
                        <div className="row">{/* Dashboard & [Chart | List] */}
                            <div key="summary" className='col-md-12'>
                                <Tabs defaultActiveKey={viewTypeEnum.PATIENT} onChange={(e) => setViewType(e)}>
                                    <Tabs.TabPane tab="THEO BỆNH NHÂN" key={viewTypeEnum.PATIENT}>
                                        <DailyReportByPatient
                                            ref={reportByPatient}
                                            siteIds={siteIds}
                                            coporationIds={coporationIds}
                                            paymentTypeIds={paymentTypeIds}
                                            dateFrom={date[0]}
                                            dateTo={date[1]}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="CHI TIẾT DỊCH VỤ" key={viewTypeEnum.SERVICE}>
                                        <DailyReportByService 
                                            ref={reportByService}
                                            siteIds={siteIds}
                                            coporationIds={coporationIds}
                                            paymentTypeIds={paymentTypeIds}
                                            dateFrom={date[0]}
                                            dateTo={date[1]}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
