import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Switch, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import MutilMedia from '../../components/MitaComponents/UploadFile/mitaUploadFile';
import Notify, { NotifyStatus } from '../../components/notify';
import * as PrintTemplateService from '../../services/printTemplateService';
//import SunEditor from 'suneditor-react';
//import 'suneditor/dist/css/suneditor.min.css';
//import suneditorViLang from '../../libs/suneditor-vi-lang';

const { TextArea } = Input;
const { Dragger } = Upload;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const uploadRef = useRef();
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.create);
  const [headerText, setHeaderText] = useState(''); 
  //result model
  const [id, setId] = useState('');
  const [printTemplateName, setPrintTemplateName] = useState('');
  const [printTemplateDescription, setPrintTemplateDescription] = useState('');
  const [componentId, setComponentId] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [printTemplateContent, setPrintTemplateContent] = useState('');
  const [file, setFile] = useState();
  //#endregion 
  const [errorFileString, setErrorFileString] = useState('');
  //#region khai báo biến cục bộ
  const featureName = "printtemplate";
  const componentKey = "printtemplate";
  const refId = "temp";
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  //#region useEffect
  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
      if (props.detailScreenMode == detailScreenModeEnum.create)
        setHeaderText('Tạo mới - Template in');
      else if (props.detailScreenMode == detailScreenModeEnum.update)
        setHeaderText('Cập nhật - ' + props.record.printTemplateName);
  }, [props.detailScreenMode]);

  useEffect(() => {
    if (props.detailScreenMode == detailScreenModeEnum.update) getById(props.record.id);
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setPrintTemplateName(data.printTemplateName);
    setPrintTemplateDescription(data.printTemplateDescription);
    setComponentId(data.componentId);
    setPrintTemplateContent(data.printTemplateContent);
    setIsDefault(data.isDefault);
  }

  const getById = (id) => {
    if (props.detailScreenMode === detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    PrintTemplateService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const formData = new FormData();

  const setModel = () => {
    if (file && file.blob) {
        formData.append('printTemplateContentFile', file.blob);
    } else if (file && file.originFileObj) {
        formData.append('printTemplateContentFile', file.originFileObj);
    }
    formData.append('printTemplateName', printTemplateName);
    formData.append('printTemplateDescription', printTemplateDescription);
    formData.append('componentId', componentId);
    formData.append('printTemplateContent', printTemplateContent);
    formData.append('isDefault', isDefault);
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    PrintTemplateService.create(formData).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    PrintTemplateService.update(props.record.id, formData).then(result => {
      if (result.isSuccess) {
        getById(props.record.id);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const validate = () => {
    let isValid = true;
    if (!file && detailScreenMode == detailScreenModeEnum.create) {
      setErrorFileString("Bắt buộc phải chọn file");
      isValid = false;
    }
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && props.record) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }
  //#endregion 
  
  //#region file
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };
  const handleFileChange = (e) => {
    for(var i = 0; i < e.fileList.length  - 1; i++) {
      uploadRef.current.fileList.shift();
    }
    setFile(uploadRef.current.fileList[0]);
    if (uploadRef.current.fileList[0]) setErrorFileString('');
  }

  const handleFileRemove = (e) => {
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mã loại thẻ / Hoạt động*/}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên template</label>
                  </div>
                  <Input
                    value={printTemplateName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setPrintTemplateName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên template", printTemplateName, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>ComponentId</label>
                  </div>
                  <Input
                    value={componentId}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setComponentId(e.target.value)}
                  ></Input>
                  {validator.current.message("ComponentId", componentId, "required")}
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <div>
                    <label>Mặc định</label>
                  </div>
                  <div>
                    <Switch 
                      checked={isDefault} 
                      onChange={(checked, e) => { setIsDefault(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Mô tả */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Mô tả</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={printTemplateDescription ?? ""}
                    onChange={(e) => setPrintTemplateDescription(e.target.value)}
                  ></TextArea>
                </div>
              </div>
              
              <div className="row mt-2" style={{ lineHeight: "20px" }}>
                <div className="col-12">
                  <label>File template html</label>
                  <Dragger
                    ref={uploadRef}
                    customRequest={dummyRequest}
                    // listType="picture-card"
                    // multiple={true}
                    // fileList={files}
                    // onPreview={handlePreview.bind(this)}
                    onChange={handleFileChange.bind(this)}
                    // onChange={(e) => { handleChange(e); }}
                    onRemove={handleFileRemove.bind(this)}
                    accept=".html"
                    itemRender={(originNode, file, fileList) => {
                      return <div className="handle-move-upload">
                        {originNode}
                      </div>
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <i className="fas fa-cloud-upload-alt text-color" style={{ fontSize:"2.5rem" }}></i>
                    </p>
                    <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây</p>
                  </Dragger>
                  <label className='text-danger'>{errorFileString}</label>
                </div>
              </div>
              {/* <div className="row mt-2" style={{ }}> SunEditor nó tự convert lại theo style của nó , nên chưa xài được đâu
                <div className="col-12">
                  <SunEditor
                      lang={suneditorViLang}
                      setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                      onChange={(e) => {
                          setPrintTemplateContent(e)
                      }}
                      showToolbar={true}
                      setContents={printTemplateContent}
                      setOptions={{
                          height: 350,
                          buttonList: [
                              ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                              ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                              ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list'],
                              ['link', 'image', 'codeView']
                          ]
                      }} 
                    />
                </div>
              </div> */}
              <div className="row mt-2" style={{ }}>
                <div className="col-12">
                  <div className='w-100 p-2 border border-1' dangerouslySetInnerHTML={{ __html: `${printTemplateContent !== null ? printTemplateContent : ''}` }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
              <button 
                className='btn btn-secondary mr-2' 
                  onClick={props.onDownloadClick}
              >
                <i className='fas fa-download mr-2'></i>
                Tải về
              </button>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})