import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Spin } from 'antd';
import * as Action from '../../../libs/actions';
import { FORMAT_TIME_DATE } from '../../../configure/systemConfig';
import * as InvoiceService from '../../../services/invoiceService';
import SummaryAmount from './summaryamount';
import { isNullOrEmpty } from '../../../libs/util';

/** Component này dùng để hiển thị thành tiền, thanh toán của hóa đơn/bill thanh toán
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\invoice\trahoadon\paid.js
    - src\screens\invoice\payment\components\ListPayment.js
 ** Các props nhận vào:
* @param {object} props.billNumber: số bill. Này là chim mồi. Nếu props.data không có thì call api dựa vào billNumber này. Có thể là số bill thanh toán hoặc hoàn trả
* @param {object} props.data: dữ liệu. lấy theo response data của api "api/Invoice/GetBillDetail?billNumber=..."
    props.data = {
        * @param {Array} paymentTypeName: array, //Hình thức thanh toán.
        * @param {Date} paidDate: date, //Ngày thanh toán / hoàn trả.
        * @param {string} cashier: text, //Thu ngân.
        * @param {string} remark: text, //Ghi chú.
        * @param {string} voucher: text, //voucher code nếu có áp dụng CTKM
        * @param {string} managementId: mã quản lý.
        * @param {string} patientId: mã bệnh nhân.
        * @param {string} billNumber: số bill. Có thể là số bill thanh toán hoặc hoàn trả
        * @param {boolean} showBtnPrint: hiện / ẩn button in
        * @param {boolean} showBtnRefund: hiện / ẩn button in
        
        * @param {number} total: number, //Tổng tiền: tổng tiền các dịch vụ (sau khi đã giảm dịch vụ).
        * @param {number} discount: number, //Miễn giảm: giảm giá trên bill (giảm tay trên bill).
        * @param {number} loyalty: number, //CTKM / Thẻ: giảm giá trên bill theo CTKM / thẻ.
        * @param {number} paid: number, //Thành tiền = total - discount - loyalty.
        * @param {number} refund: number, //Hoàn trả: số tiền phải hoàn trả nếu là bill hoàn trả.
        * @param {number} revenue: number, //Thanh toán: số tiền phải thanh toán nếu là bill thanh toán.

        listDetail: array //list dịch vụ | thuốc & vật tư
        {
            code: text, // Mã dịch vụ | thuốc & vật tư
            name: text, // Tên dịch vụ | thuốc & vật tư
            price: text, // đơn giá gốc
            total: text, // Thành tiền = Price (* Quantity nếu có) - Discount - Loyalty
            discount: number, // Giảm giá dịch vụ
            loyalty: text, // Giảm CTKM / Thẻ
            isRefund: bool, // Bị hoàn trả
        }
    }
* @param {Function} props.btnPrintClick: //click button in
* @param {Function} props.btnRefundClick: //click button hoàn trả
 */
//tương ứng enum ReceiptType
const ReceiptType = {
    THANHTOAN: 0,
    TAMUNG: 2,
    HOANTRA: 3,
    VIENPHI_NOITRU: 4,
    HOANTRA_TAMUNG: 5
}
const SmallShowSummaryAmount = forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //theo props
    const [billNumber, setBillNumber] = useState(props.billNumber);
    const [billNo, setBillNo] = useState(props.billNo);
    const [data, setData] = useState(props.data);
    const [isBillRefund, setIsBillRefund] = useState(props.data?.billType == ReceiptType.HOANTRA);
    const [showBtnPrint, setShowBtnPrint] = useState(props.showBtnPrint ?? true);
    const [showBtnRefund, setShowBtnRefund] = useState(props.showBtnRefund ?? true);

    useEffect(() => {
        setIsBillRefund(data?.billType == ReceiptType.HOANTRA);
    }, [data?.billType])

    useEffect(() => {
        //Nếu không có truyền data vào thì gọi api để lấy.
        if (props.data === null || props.data === undefined) {
            getBillDetail();
        }
        else { 
            setBillNumber(props.data.billNumber); 
            setBillNo(props.data.billNo); 
            setData(props.data)
        }
    }, [props.data])

    function getBillDetail() {
        // Action.setLoading(true);
        InvoiceService.getBillDetail(billNumber).then(res => {
            if (res.isSuccess) {
                setData(res.data);
                setBillNo(res.data.billNo);
            }
        }).catch(err => {

        }).finally(() => {
            // Action.setLoading(false);
        })
    }

    return (
        <Fragment>
            {data ?
            <div style={{ width: 400 }} onClick={e => { e.stopPropagation(); e.preventDefault(); }}>
                <div className='row'>
                    <div className='col-6'>
                        <label className='mita-title'>Số phiếu</label>&nbsp;
                        <label className='mita-value'>{billNo ? billNo : billNumber}</label>
                    </div>
                    <div className='col-6'>
                        <label className='mita-title'>Hình thức</label>&nbsp;
                        <label className='mita-value'>{(data.paymentTypeName ?? ["Chưa có thông tin"]).join(', ')}</label>
                    </div>
                    <div className='col-6'>
                        <label className='mita-title'>{isBillRefund ? "Ngày giờ hoàn trả" : "Ngày giờ thanh toán"}</label>&nbsp;
                        <label className='mita-value'>{data.paidDate && moment(data.paidDate).format(FORMAT_TIME_DATE)}</label>
                    </div>
                    <div className='col-6'>
                        <label className='mita-title'>Thu ngân</label>&nbsp;
                        <label className='mita-value'>{data.cashier}</label>
                    </div>
                    <div className='col-12'>
                        <label className='mita-title'>Ghi chú</label><br />
                        <label className='mita-value' style={{whiteSpace: "pre-line"}}>{data.remark}</label>
                    </div>
                    {
                        !isNullOrEmpty(data.note?.reasonCome ?? "") &&
                        <div className='col-12'>
                            <label className='mita-title'>Lý do khám</label><br />
                            <label className='mita-value' style={{ whiteSpace: "pre-line" }}>{data.note?.reasonCome}</label>
                        </div>
                    }
                    {
                        data.note?.physicalExaminationNote != null && data.note?.physicalExaminationNote != undefined &&
                        <div className='col-12'>
                            <label className='mita-title'>Ghi chú đăng ký</label><br />
                            <label className='mita-value' style={{ whiteSpace: "pre-line" }}>{data.note?.physicalExaminationNote}</label>
                        </div>
                    }
                </div>
                <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-11'>
                        <SummaryAmount
                            isEdit={false}
                            data={data}
                        />
                    </div>
                </div>
                <div className='custom-hr'></div>
                <div className='d-flex flex-row justify-content-between'>
                    <div>
                    {showBtnPrint && 
                        <a href='#' onClick={(e) => { 
                            e.stopPropagation(); 
                            props.btnPrintClick();
                        }}
                        ><i className='fas fa-print mr-1' />In phiếu</a>
                    }
                    </div>
                    {!isBillRefund && showBtnRefund &&
                        <Button className="pl-0"
                            type="link"
                            onClick={(e) => {
                                props.btnRefundClick && props.btnRefundClick(e);
                            }}
                        ><i className="fas fa-undo mr-1" />Hoàn trả</Button>
                        // <div>
                        //     <Popover
                        //         placement="left"
                        //         onVisibleChange={(isOpen) => {
                        //             selectAllBillSer(receiptInfo.receiptNumber, isOpen);
                        //             setVisiblePopupSec(isOpen);
                        //         }}
                        //         destroyTooltipOnHide
                        //         visible={visiblePopupSec}
                        //         content={
                        //             refundPopoverContent()
                        //         }
                        //         trigger="click"
                        //     ><a href='#' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVisiblePopupSec(true); selectAllBillSer(billNumber); }}><i className='fas fa-undo mr-1'></i>Hoàn trả</a>
                        //     </Popover>
                        // </div>
                    }
                </div>
            </div>
            :
            <Spin tip="Đang load" size="small" /> 
            }
        </Fragment>
    )
});

const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps)(SmallShowSummaryAmount)