import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Input, InputNumber, Radio, Select, Tag, Tooltip, notification } from 'antd';
import Notify, { NotifyStatus } from '../../components/notify';
import HeaderModal from '../../configureHIS/common/headerModal';
import { CheckBank, isnotnull, isNullOrEmpty } from '../../libs/util';
import NoContent from "../../components/loading/noContent";
import { FeatureDeactive } from '../../configure/featureConfig';
import { FEATURE } from '../../configureHIS/common/constants';
import * as CorporationServices from './CorporationServices';
import * as PatientService from '../../services/patientService';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import * as NhanVienSerivce from '../../services/employeeService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Actions from '../../libs/actions';

const { TextArea } = Input;
const { Option } = Select;

const UpdateCopMultiInfo = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const [type, setType] = useState(props.type ?? "");
    const [copIds, setCopIds] = useState(props.copIds ?? []);
    const [listCops, setListCops] = useState(props.listCops ?? []);
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');
    const [daidien, setDaidien] = useState('');
    const [taikhoan, setTaikhoan] = useState('');
    const [username, setUsername] = useState('');
    const [lstNhanVienSale, setlstNhanVienSale] = useState([]);
    const [sale, setNhanVienSale] = useState(0)
    const [lstNguoigioithieu, setlstNguoigioithieu] = useState([]);
    const [nguoigioithieu, setNguoigioithieu] = useState(0);
    const [lstBanggia, setlstBanggia] = useState([]);
    const [lstLoaiTT, setlstLoaiTT] = useState([]);
    const [banggia, setBanggia] = useState('default');
    const [paymenttype, setPaymenttype] = useState(0);
    const [email, setEmail] = useState('');
    const [legalType, setLegalType] = useState(null);
    const [discountType, setDiscountType] = useState();
    const [discount, setDiscount] = useState();
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const listLegalType = [
        { 'value': 0, 'label': 'Thể nhân' },
        { 'value': 1, 'label': 'Pháp nhân' },
    ]

    useEffect(() => {
        setCopIds(props.copIds);
    }, [props.copIds]);

    useEffect(() => {
        setListCops(props.listCops);
    }, [props.listCops]);

    useEffect(() => {
        getlstNhanVienSale();
        getlstNguoiGioiThieu();
        getListBangGia();
        getPaymentMethod();
    }, [])

    function getlstNhanVienSale() {
        NhanVienSerivce.DanhSachNhanVien('', 1, 100, 26).then(result => {
            if (result.isSuccess) {
                setlstNhanVienSale(result.data)
            }
        }).catch(err => {
        })
    }

    function getlstNguoiGioiThieu() {
        Actions.setLoading(true);
        PatientService.GetListNguoiGioiThieu('', true, 1, 100).then(results => {
            if (results.isSuccess) {
                setlstNguoigioithieu(results.data);
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    function getListBangGia() {
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setlstBanggia(result.data)
            }
        }).catch(err => {
        })
    }

    function getPaymentMethod() {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                setlstLoaiTT(res)
            }
        }).catch(err => {
        })
    }

    const submit = () => {
        Actions.setLoading(true);
        let data = []
        if (type == "dienthoai") { data.push({ "Key": type, "DataType": "text", "Value": dienthoai?.toString() }) };
        if (type == "email") { data.push({ "Key": type, "DataType": "text", "Value": email?.toString() }) };
        if (type == "username") { data.push({ "Key": type, "DataType": "text", "Value": username?.toString() }) };
        if (type == "taikhoan") { data.push({ "Key": type, "DataType": "text", "Value": taikhoan?.toString() }) };
        if (type == "diachi") { data.push({ "Key": type, "DataType": "text", "Value": diachi?.toString() }) };
        if (type == "sale") { data.push({ "Key": type, "DataType": "number", "Value": sale?.toString() }) };
        if (type == "nguoigioithieu") { data.push({ "Key": type, "DataType": "number", "Value": nguoigioithieu?.toString() }) };
        if (type == "paymenttype") { data.push({ "Key": type, "DataType": "number", "Value": paymenttype?.toString() }) };
        if (type == "banggia") { data.push({ "Key": type, "DataType": "text", "Value": banggia?.toString() }) };
        if (type == "legalType") { data.push({ "Key": type, "DataType": "number", "Value": legalType?.toString() }) };
        if (type == "daidien") { data.push({ "Key": type, "DataType": "text", "Value": daidien?.toString() }) };
        if (type == "discount") {
            if (discountType === "percent" && discount > 100) {
                notifyUser("Tỷ lệ chiết khấu không được vượt quá 100%.");
                return;
            }
            
            if (discount != null && !discountType) {
                notifyUser("Hãy chọn hình thức chiết khấu.");
                return;
            }
            
            if (discountType && discount == null) {
                notifyUser("Hãy nhập tỷ lệ chiết khấu.");
                return;
            }
        
            data.push({ Key: "discountType", DataType: "text", Value: discountType?.toString() });
            data.push({ Key: "discount", DataType: "number", Value: discount?.toString() });
        };
        const dataToSend = {
            copIds,
            Field: data
        };
        CorporationServices.UpdateCoporationMulti(dataToSend).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Cập nhật thành công" });
                props.onSuccess && props.onSuccess();
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))
    }
    

    const onClearDiscountType = () => {
        setDiscountType();
        setDiscount();
        validator && validator.current && validator.current.purgeFields();
    }
    
    const notifyUser = (message) => {
        _notify && _notify.current && _notify.current.Show(message, NotifyStatus.Warning);
        Actions.setLoading(false);
    };

    return (
        !isnotnull(copIds) ?
            <NoContent title="Chưa chọn dữ liệu" />
            :
            <div className="list-page">
                <div className="list-page-body" style={{ overflowY: "auto" }}>
                    <HeaderModal title={props.typeName ?? `Cập nhật ${type == "banggia" ? "bảng giá" : type == "nguoigioithieu" ? "người giới thiệu" : type} hàng loạt`} onClose={() => { props.onClose && props.onClose() }} />
                    <Notify ref={_notify} />
                    <div className="row">
                        {type == "dienthoai" && <div className="col-md-12 mt-2">
                            <label>Điện thoại</label>
                            <Input value={dienthoai} className="w-100"
                                placeholder="Nhập điện thoại"
                                onKeyDown={(e) => {
                                    if (e.key === " " || isNaN(e.key) === true)
                                        e.preventDefault();
                                }}
                                onChange={(e) => {
                                    setDienthoai(e.target.value);
                                }} />
                        </div>}
                        {type == "email" && <div className="col-md-12 mt-2">
                            <label>Email</label>
                            <Input value={email}
                                type="email"
                                className="w-100"
                                placeholder="Nhập email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }} />
                        </div>}
                        {type == "daidien" && <div className="col-md-12 mt-2">
                            <label>Người đại diện</label>
                            <Input value={daidien} className="w-100" maxLength={25}
                                placeholder="Nhập tên người đại diện"
                                onChange={(e) => {
                                    setDaidien(e.target.value);
                                }} />
                        </div>}
                        {type == "username" && <div className="col-md-12 mt-2">
                            <label>Tài khoản xem kết quả</label>
                            <Input
                                className='w-100' maxLength={20}
                                placeholder='Nhập tài khoản xem kết quả'
                                onChange={e => {
                                    setUsername(e.target.value)
                                }}
                                value={username}
                            ></Input>
                        </div>}
                        {type == "taikhoan" && <div className="col-md-12 mt-2">
                            <label>Tài khoản ngân hàng</label>&nbsp;
                            {!isNullOrEmpty(taikhoan) && /\d{8,18}/g.test(taikhoan) ?
                                <Tooltip title="Đây là thông tin tự động nhận diện, chỉ hiển thị để xem, hệ thống không lưu trữ. Nếu thông tin hiển thị sai khác, vui lòng bỏ qua hoặc thông báo đến bộ phận phát triển phần mềm"><Tag color='blue'>{CheckBank(taikhoan)}</Tag></Tooltip> : ""}
                            <Input
                                className='w-100'
                                placeholder='Nhập số tài khoản ngân hàng'
                                onChange={e => {
                                    setTaikhoan(e.target.value)
                                }}
                                value={taikhoan}
                            ></Input>
                        </div>}
                        {type == "diachi" && <div className="col-md-12 mt-2">
                            <label>Địa chỉ</label>
                            <TextArea rows={1}
                                onChange={e => {
                                    setDiachi(e.target.value)
                                }}
                                value={diachi}
                            ></TextArea>
                        </div>}
                        {type == "sale" && <div className="col-md-12 mt-2">
                            <label>Sale</label>
                            <Select
                                className="w-100"
                                showSearch
                                allowClear={true}
                                value={sale !== 0 ? sale : null}
                                onChange={(value) => { setNhanVienSale(value) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstNhanVienSale.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.hoten}</Option>
                                    )
                                })}
                            </Select>
                        </div>}
                        {type == "nguoigioithieu" && <div className="col-md-12 mt-2">
                            <label>Người giới thiệu</label>
                            <Select
                                className="w-100"
                                showSearch
                                allowClear={true}
                                value={nguoigioithieu && nguoigioithieu != 0 ? nguoigioithieu.toString() : null}
                                onChange={(e) => { setNguoigioithieu(e !== undefined ? parseInt(e) : e) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstNguoigioithieu.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.hoten}</Option>
                                    )
                                })}
                            </Select>
                        </div>}
                        {type == "paymenttype" && <div className="col-md-12 mt-2">
                            <label>Hình thức thanh toán mặc định</label>
                            <Select className="w-100"
                                value={paymenttype !== 0 ? paymenttype : null}
                                onChange={(e) => { setPaymenttype(e) }}
                                allowClear
                                onClear={() => setPaymenttype(0)}>
                                {lstLoaiTT && lstLoaiTT.map((hinhthuc, index) =>
                                    <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                                )}
                            </Select>
                        </div>}
                        {type == "banggia" && FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                            <div className="col-md-12 mt-2">
                                <label className='required-field'>Bảng giá</label>
                                <Select
                                    label="Bang gia"
                                    className="w-100"
                                    showSearch
                                    value={banggia}
                                    onChange={(value) => { setBanggia(value) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstBanggia.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {validator.current.message("Bảng giá", banggia, "selection")}
                            </div>}
                        {type == "legalType" && <div className="col-md-12 mt-2">
                            <label>Đại diện</label>
                            <Select
                                placeholder="Chọn đại diện"
                                className="w-100"
                                value={legalType}
                                onChange={(e) => { setLegalType(e) }}
                                allowClear
                                onClear={() => setLegalType(null)}
                            >
                                {listLegalType.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </div>}
                        {type == "discount" && FeatureDeactive().indexOf(FEATURE.CHIET_KHAU_NGUON_KHACH_HANG) == -1 &&
                            <div className="col-md-12 mt-2">
                                <label className='d-flex flex-row align-items-center'>
                                    <span>Tỷ lệ chiết khấu</span>
                                    <span className='ml-2'>
                                        <Radio.Group value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                                            <Radio disabled value={null} style={{ display: "none", visibility: "hidden" }}>Không mang giá trị</Radio>
                                            <Radio value="percent">Phần trăm</Radio>
                                            <Radio value="amount">Số tiền</Radio>
                                        </Radio.Group>
                                    </span>
                                    <a className='ml-auto text-color' onClick={() => { onClearDiscountType() }} >Xóa bỏ</a>
                                </label>
                                {discount != null
                                    ? validator.current.message("Hình thức chiết khấu", discountType, "selection") : ""}
                                <InputNumber className='w-100'
                                    placeholder='Nhập tỷ lệ chiết khấu'
                                    value={discount} allowClear
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} max={discountType == "percent" ? 100 : 9000000000}
                                    // prefix={discountType == "percent" ? "%" : discountType == "percent" ? <u>đ</u> : ""}
                                    onChange={(e) => setDiscount(e)}
                                />
                                {discountType == "percent"
                                    ? validator.current.message("Phần trăm chiết khấu", discount, "required|numeric|min:0,num|max:100,num")
                                    : discountType == "amount" ? validator.current.message("Số tiền chiết khấu", discount, "required|numeric|min:0,num|max:9000000000,num") : ""}
                            </div>}
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2'>
                            <span className='font-bold'>Đã chọn cập nhật cho:</span>
                            <ol type='1'>
                                {
                                    (listCops?.filter(x => copIds.includes(x.ma)) ?? []).map((cop, index) => {
                                        return <li key={index}>{`${cop.ma} - ${cop.ten}`}</li>
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
                <div className='list-page-footer'>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-color mr-1"
                            onClick={() => {
                                submit()
                            }}>
                            <i className="fas fa-save" style={{ marginRight: 5 }} />Lưu</button>
                        <button
                            className="btn btn-danger ml-1"
                            onClick={() => {
                                props.onClose && props.onClose()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </div>
            </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(UpdateCopMultiInfo)