import { DatePicker, Select, Tabs } from 'antd';
import React, { useRef, useState } from 'react';
import Site from './chinhanh';
import Hub from './hub';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs
export default () => {
    const [activeKey, setActiveKey] = useState('1');
    const refHub = useRef();
    return (
        <React.Fragment>
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <div style={{ height: '100%' }}>
                        <Tabs className='tab-header-services' centered={true} onChange={e => {
                            setActiveKey(e);
                        }}>
                            <TabPane tab={"Quản lý site"} key="1">
                                <div style={{ backgroundColor: '#fff', height: '100%' }}>
                                    <Site onSuccess={() => {
                                        if (refHub && refHub.current)
                                            refHub.current.GetListSite()
                                    }} />
                                </div>
                            </TabPane>
                            <TabPane tab={"Quản lý HUB"} key="2">
                                <div style={{ backgroundColor: '#fff', height: '100%' }}>
                                    <Hub ref={refHub} />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
