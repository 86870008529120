import React, { Component } from 'react';
import { Form, Input, Table, Switch, Popconfirm, Modal, notification, Pagination, Select } from 'antd';
import * as cfgService from '../../../services/configService';
import CreateGiuong from '../components/createNewGiuong';
import SimpleReactValidator from 'simple-react-validator';
import * as PhongKhamService from '../../departmentConfig/services/PhongKhamService';
import * as Actions from '../../../libs/actions';

let task = null;
const initialState = {
    visible: false,
    visibleCreate: false,
    totalRecords: 0
}
const { Option } = Select;

export default class DanhSachGiuong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            currentPage: 1,
            pageSize: 10,
            key: "",
            lstLoaiPhong: [],
            lstdepartment: [],
            lstPhong: [],
            lstGiuong: [],
            makp: "0",
            makp_update: '0',
            idloaiphong: 0,
            idloaiphong_update: 0,
            idphong: 0,
            idphong_update: 0,
            idgiuong: 0,
            magiuong: '',
            tengiuong: '',
            bhyt: 0,
            giath: 0,
            giabh: 0,
            giacs: 0,
            giadv: 0,
            giann: 0,
            loadingHide: 0,
            disableselectlp: true,
            disableselectp: false
        };
        this.validator = new SimpleReactValidator({
            messages: {
                numeric: "Cần phải nhập :attribute là số!",
                required: "Cần phải nhập :attribute!",
                max: "Nhập :attribute tối đa :max kí tự!",
                min: "Nhập giá tối thiểu bằng :min!"
            },
            element: message => <div style={{ color: "red" }}>{message}</div>
        });
    }
    componentDidMount() {
        this.loadLoaiPhongByKhoa();
        this.LoadDepartment();
        this.loadPhongByLoaiPhong();
        this.loadGiuong();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task);
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        })
    }
    onReload = () => {
        this.loadGiuong();
    }
    LoadDepartment() {
        PhongKhamService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    loadLoaiPhongByKhoa(makp = this.state.makp) {
        cfgService.getSelectBoxLoaiPhongByKhoa(makp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiPhong: result.data
                });
            }
        }).catch(err => {
        })
    }
    loadPhongByLoaiPhong(makp = this.state.makp, idlp = this.state.idloaiphong) {
        cfgService.getSelectBoxPhongByLoaiPhong(makp, idlp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data
                });
            }
        }).catch(() => {
            notification.warning({ messages: "Xảy ra lỗi khi tải dữ liệu loại phòng" })
        })
    }
    loadGiuong = (page = this.state.currentPage, size = this.state.pageSize) => {
        var { key, makp, idloaiphong, idphong } = this.state;
        cfgService.getGiuong(key, makp, idloaiphong, idphong, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstGiuong: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page
                });
            }
        }).catch(() => {
            notification.warning({ message: "Tải dữ liệu giường nội trú bị lỗi" });
        })
    }
    updateHide(id, check) {
        this.setState({ loadingHide: 0 })
        let status = 0;
        if (check === false) {
            status = 1;
        }
        cfgService.updateHideGiuong(id, status).then(result => {
            let data = this.state.lstGiuong;
            let item = data.find(x => x.idGiuong === id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.khongDung === 0 ? 1 : 0;
                    item.khongDung = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.khongDung;
                    item.khongDung = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstGiuong: data,
                loadingHide: 0,
            })
        }).catch(err => {
            let data = this.state.lstGiuong;
            let item = data.find(x => x.idGiuong === id);
            if (item !== undefined) {
                const newcheck = item.khongDung;
                item.khongDung = newcheck;
            }
            this.setState({ loadingHide: 0, lstGiuong: data })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    updateGiuong() {
        var { idgiuong, idloaiphong_update, idphong_update, magiuong, tengiuong, bhyt, giath, giabh, giacs, giadv, giann } = this.state;
        if (this.validator.allValid()) {
            const data = {
                idGiuong: idgiuong,
                idLoaiPhong: idloaiphong_update,
                idPhong: idphong_update,
                maGiuong: magiuong,
                tenGiuong: tengiuong,
                BHYT: Number(bhyt),
                giaTH: Number(giath),
                giaBH: Number(giabh),
                giaCS: Number(giacs),
                giaDV: Number(giadv),
                giaNN: Number(giann)
            }
            cfgService.updateGiuong(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công!' });
                    this.onReload();
                    this.setState({
                        visible: false,
                        disableselectlp: true,
                        disableselectp: true
                    });
                    this.validator.hideMessages();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(() => {
                notification.warning({ messages: "Cập nhật thông tin bị lỗi" });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteGiuong(idgiuong) {
        cfgService.deleteGiuong(idgiuong).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                let list = this.state.lstGiuong;
                this.setState({ lstGiuong: list });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(() => {
            notification.warning({ message: "Đã xảy ra lỗi khi xóa giường nội trú" });
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        })
    }
    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + (Number(index) + 1))
            },
            {
                title: 'Khoa',
                dataIndex: 'tenKP',
                key: 'tenKP',
                align: 'left'
            },
            {
                title: 'Loại phòng',
                dataIndex: 'tenLoaiPhong',
                key: 'tenLoaiPhong',
                align: 'left'
            },
            {
                title: 'Phòng',
                dataIndex: 'tenPhong',
                key: 'tenPhong',
                align: 'left'
            },
            {
                title: 'Mã giường',
                dataIndex: 'maGiuong',
                key: 'maGiuong',
                align: 'left'
            },
            {
                title: 'Tên giường',
                dataIndex: 'tenGiuong',
                key: 'tenGiuong',
                align: 'left'
            },
            {
                title: 'Giá TH',
                dataIndex: 'giaTH',
                key: 'giaTH',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Giá BH',
                dataIndex: 'giaBH',
                key: 'giaBH',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Giá CS',
                dataIndex: 'giaCS',
                key: 'giaCS',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Giá DV',
                dataIndex: 'giaDV',
                key: 'giaDV',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Giá NN',
                dataIndex: 'giaNN',
                key: 'giaNN',
                align: 'left',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                }
            },
            {
                title: 'Hiển thị',
                align: 'center',
                width: 100,
                render: (row) => {
                    return (
                        <Switch
                            loading={this.state.loadingHide}
                            checkedChildren="Hiển thị"
                            unCheckedChildren="Ẩn"
                            checked={row.khongDung === 0}
                            onChange={(e) => { this.updateHide(row.idGiuong, e); }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="btn btn-warning"
                                onClick={() => {
                                    this.setState({
                                        makp_update: row.maKP,
                                        idloaiphong_update: row.idLoaiPhong,
                                        idphong_update: row.idPhong,
                                        magiuong: row.maGiuong,
                                        tengiuong: row.tenGiuong,
                                        idgiuong: row.idGiuong,
                                        bhyt: row.bhyt,
                                        giath: row.giaTH,
                                        giabh: row.giaBH,
                                        giacs: row.giaCS,
                                        giadv: row.giaDV,
                                        giann: row.giaNN,
                                        visible: true
                                    });
                                    task = setTimeout(() => { this.loadLoaiPhongByKhoa(row.maKP) }, 100);
                                    task = setTimeout(() => { this.loadPhongByLoaiPhong(row.maKP, row.idLoaiPhong) }, 100)
                                }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteGiuong(row.idGiuong) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        let dataSource = this.state.lstGiuong.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: '30px 10px' }}>
                        <div
                            className="row no-gutters"
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <div className="col-md-2">
                                <Form.Item>
                                    <Select
                                        defaultValue='0'
                                        onChange={(e) => {
                                            this.setState({ makp: e, idloaiphong: 0, idphong: 0 })
                                            task = setTimeout(() => { this.loadGiuong(1, 10) }, 100)
                                            task = setTimeout(() => { this.loadLoaiPhongByKhoa() }, 100)
                                        }}
                                    >
                                        <Option key={-2} value='0'>Tất cả khoa</Option>
                                        {this.state.lstdepartment.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <Select
                                        defaultValue={0}
                                        value={this.state.idloaiphong}
                                        onChange={(e) => {
                                            this.setState({ idloaiphong: e, idphong: 0 })
                                            task = setTimeout(() => { this.loadGiuong(1, 10) }, 100)
                                            task = setTimeout(() => { this.loadPhongByLoaiPhong() }, 100)
                                        }}
                                    >
                                        <Option key={-1} value={0}>Tất cả loại phòng</Option>
                                        {this.state.lstLoaiPhong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <Select
                                        defaultValue={0}
                                        value={this.state.idphong}
                                        onChange={(e) => {
                                            this.setState({ idphong: e })
                                            task = setTimeout(() => { this.loadGiuong(1, 10) }, 100)
                                        }}
                                    >
                                        <Option key={-1} value={0}>Tất cả phòng</Option>
                                        {this.state.lstPhong.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.tenPhong}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <Input
                                        placeholder="Nhập tên giường để tìm kiếm"
                                        value={this.state.key}
                                        onChange={(e) => this.setState({
                                            key: e.target.value
                                        })}
                                        onKeyDown={() => {
                                            task = setTimeout(() => {
                                                this.loadGiuong();
                                            }, 300);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={this.showModalCreate}>
                                        <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                                        Tạo mới
                                    </button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                             scroll={{ y: 0 }}
                                dataSource={dataSource}
                                columns={columns}
                                rowkey={(index) => index}
                                pagination={false}
                                scroll={{ x: 'max-content', y: 0 }}
                            />
                        </div>
                        <Modal
                            maskClosable={false}
                            width={1100}
                            visible={this.state.visibleCreate}
                            cancelText="Đóng"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <CreateGiuong
                                onCancel={this.handleCancel}
                                onReload={this.onReload}
                            />
                        </Modal>
                        <Modal maskClosable={true}
                            width={1100}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <Form ref={(c) => this.form = c}>
                                <div style={{ margin: 10 }}>
                                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                        <h3>Cập nhật giường nội trú</h3>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                                        <div className="col-md-4">
                                            <Form.Item label="Khoa:">
                                                <Select
                                                    name="makp_update"
                                                    placeholder="Chọn khoa"
                                                    value={this.state.makp_update}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            makp_update: e, idloaiphong_update: null, disableselectp: true,
                                                            idphong_update: null
                                                        });
                                                        this.form.setFieldsValue({
                                                            idphong_update: ''
                                                        })
                                                        task = setTimeout(() => { this.loadLoaiPhongByKhoa(this.state.makp_update) }, 100)
                                                    }}
                                                >
                                                    {this.state.lstdepartment.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.makp}>{row.tenkp}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Khoa", this.state.makp_update, "required")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Loại phòng:">
                                                <Select
                                                    name="idloaiphong_update"
                                                    value={this.state.idloaiphong_update}
                                                    placeholder="Loại phòng"
                                                    onChange={(e) => {
                                                        this.setState({ idloaiphong_update: e, idphong_update: null, disableselectp: false });
                                                        task = setTimeout(() => { this.loadPhongByLoaiPhong(this.state.makp_update, this.state.idloaiphong_update) }, 100)
                                                    }}
                                                >
                                                    {this.state.lstLoaiPhong.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.id}>{row.tenLoaiPhong}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Loại phòng", this.state.idloaiphong_update, "required")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Phòng:">
                                                <Select
                                                    name="idphong_update"
                                                    value={this.state.idphong_update}
                                                    placeholder="Chọn phòng"
                                                    disabled={this.state.disableselectp}
                                                    onChange={(e) => {
                                                        this.setState({ idphong_update: e })
                                                    }}
                                                >
                                                    {this.state.lstPhong.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.id}>{row.tenPhong}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Phòng", this.state.idphong_update, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                                        <div className="col-md-4">
                                            <Form.Item label="Mã giường:">
                                                <Input
                                                    name="magiuong"
                                                    placeholder="Nhập mã giường"
                                                    value={this.state.magiuong}
                                                    onChange={(e) => this.setState({
                                                        magiuong: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Mã giường", this.state.magiuong, "required|max:20")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Item label="Tên giường:">
                                                <Input
                                                    name="tengiuong"
                                                    placeholder="Nhập tên giường"
                                                    value={this.state.tengiuong}
                                                    onChange={(e) => this.setState({
                                                        tengiuong: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Tên giường", this.state.tengiuong, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                                        <div className="col-md-4">
                                            <Form.Item label="Giá BHYT">
                                                <Input
                                                    name="bhyt"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={5}
                                                    value={this.state.bhyt}
                                                    onChange={(e) => this.setState({
                                                        bhyt: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá bhyt", this.state.bhyt, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Giá TH">
                                                <Input
                                                    name="giath"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={10}
                                                    value={this.state.giath}
                                                    onChange={(e) => this.setState({
                                                        giath: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá th", this.state.giath, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Giá BH">
                                                <Input
                                                    name="giabh"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={10}
                                                    value={this.state.giabh}
                                                    onChange={(e) => this.setState({
                                                        giabh: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá bh", this.state.giabh, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                                        <div className="col-md-4">
                                            <Form.Item label="Giá CS">
                                                <Input
                                                    name="giacs"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={10}
                                                    value={this.state.giacs}
                                                    onChange={(e) => this.setState({
                                                        giacs: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá cs", this.state.giacs, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Giá DV">
                                                <Input
                                                    name="giadv"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={10}
                                                    value={this.state.giadv}
                                                    onChange={(e) => this.setState({
                                                        giadv: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá dv", this.state.giadv, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Item label="Giá NN">
                                                <Input
                                                    name="giann"
                                                    suffix="đồng"
                                                    minLength={1}
                                                    maxLength={10}
                                                    value={this.state.giann}
                                                    onChange={(e) => this.setState({
                                                        giann: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Giá nn", this.state.giann, "required|numeric|min:0,num")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                                        <button className="btn btn-success" onClick={() => this.updateGiuong()}>
                                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                            onClick={() => {
                                                this.setState({ visible: false, disableselectlp: false, disableselectp: false });
                                                this.validator.hideMessages();
                                            }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Modal>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadGiuong(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadGiuong(page, size)
                            }
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}