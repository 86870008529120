
import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Package';

export function getListPackage (pagenumber, pagesize, userid, name){
    if(pagenumber === undefined) pagenumber = 0;
    if(pagesize === undefined) pagesize = 10; 
    var querystring = `pagenumber=${pagenumber}&pagesize=${pagesize} `;
    if(userid) querystring +=`&userid=${userid}`
    if(name && name.trim()!=='') querystring +=`&name=${name}`
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}?${querystring}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
}
export function getListService(Name,group='',type='')  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/Services?Name=${Name}&group=${group}&type=${type}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function deletePackage(packageId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/${packageId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getPackageData(packageId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${packageId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function updatePackage(packageId,data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/${packageId}` , data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CreatePackage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getPackage(PatientID)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPackage?PatientID=${PatientID}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function getPackageItems(patientID, packageId, isNew=false, patientPackageId=0)  {
    if(patientID === undefined) patientID="noname";    
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getPackageItems?patientID=${patientID}&packageId=${packageId}&isNew=${isNew}&patientPackageId=${patientPackageId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListServicePackage(filter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListServicePackage`, filter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListServicePackageForSelected(filter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListServicePackageForSelected`, filter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListServicePackageAutocomplete(filter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListServicePackageAutocomplete`, filter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CreateServicePackage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateServicePackage`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function UpdateServicePackage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateServicePackage`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ServicePackageDetail(packageId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ServicePackageDetail?packageid=${packageId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function ListServicePackageDetail(packageId,banggia)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListServicePackageDetail?packageid=${packageId}&banggia=${banggia}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DeleteServicePackage(packageId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteServicePackage?packageid=${packageId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
