//thanh pho
import CatalogsDisctrict from '../configureHIS/catalogsdisctrict/';
// import CauHinhGiaKhoa from '../configureHIS/cauhinhgiakhoa/cauhinhgiakhoa';
// import DanhSachDaKham from '../configureHIS/dakham/danhsachdakham';
//khoa phong 
import CauHinhKhoaPhong from '../configureHIS/departmentConfig/';
import QuanLyNhanVien from '../configureHIS/HR/quanlynhanvien';
import NguoiGioiThieu from '../configureHIS/nguoigioithieu/index';
import DanhSachUpdateVP from '../configureHIS/update_vp/screens/danhsachvp';
import DanhSachGiaLayMau from '../configureHIS/update_vp/screens/gialaymau';
import DanhSachNhomVP from '../configureHIS/update_vp/screens/danhsachnhomvp';
import DanhSachLoaiVP from '../configureHIS/update_vp/screens/danhsachloaivp';
import DanhSachLoaiXN from '../configureHIS/update_vp/screens/typeXN';
import PhieuXuatChuyenKho from '../configureHIS/xuatchuyenkho/screens/index';
import VatTuDichVu from '../screens/config/equipment';
import VatTuDichVuKhamBenh from '../screens/config/equipment/patient';
//import QuanLyCDHA from '../screens/config/pasc/editfield';
import ThemCDHA from '../screens/config/pasc/add';
import Storage from '../screens/config/storage';
//import DongY from '../screens/DongY/index';
import KhamBenh from '../screens/exam/examination';
import XemHoSo from '../screens/exam/hoso';
import Invoice from '../screens/invoice';
import PayMent from '../screens/invoice/payment';
import ThanhToanNoiTru from '../screens/invoice/payment/noitru';
import ThanhToanTamUng from '../screens/invoice/payment/tamung';
import DanhSachHDNoiTru from '../screens/invoice/payment/noitru/listInvoice';
import HoanTraHoaDon from '../screens/invoice/trahoadon';
import Lichhen from '../screens/lichhen';
import ResultTest from '../screens/lis/result';
import QuanLyUser from '../screens/managementUser';
import CapNhatThuoc from '../screens/medicine/components/capnhatthuoc';
import CapNhatThuocVatTu from '../screens/medicine/components/capnhatvattu'
import XacNhanLayMau from '../screens/xacnhanmau/index'
import DanhSachHoaDonDo from '../screens/vatinvoice/index'

//Kho thuốc
import CachDungThuoc from '../screens/medicine/cachdungthuoc';
import Material from '../screens/warehouse/config/material';
import MaterialType from '../screens/warehouse/config/materialType';

//Kho vật tư

import ThanhToanThuocBanLe from '../screens/medicine/thanhtoanthuocbanle';
import ThuocBanLe from '../screens/medicine/thuocbanle';
import PascResult from '../screens/pasc/result';
import VideoList from '../screens/pasc/videolist';
import QuanLyBN from '../screens/patient/index';
import QuanLyBNMoi from '../screens/patient/quanlybenhnhanmoi';
import SendResult from '../screens/patient/result';
import QuanLyXuatKho from '../screens/quanlyxuatnhapkho/quanlyxuatkho';
import QuanLyXuatKhoChiTiet from '../screens/quanlyxuatnhapkho/quanlyxuatkhochitiet';
import Register from '../screens/recept/register';
import TheoDoiBanThuoc from '../screens/theodoibanthuoc';
import TaoPhongLuu from '../screens/recept/storage/create';
import XuatPhongLuu from '../screens/recept/storage/export';
import CongNo from '../screens/statistic/invoice/congno';
import ThongKeDaHoanTra from '../screens/statistic/invoice/doanhso/danhsachhoantra';
import ThongKeDoanhSo from '../screens/statistic/invoice/doanhso/doanhso';
import ThongKeDoanhSoBS from '../screens/statistic/invoice/doanhso/doanhsobs';
import ThongKeNguoiGioiThieu from '../screens/statistic/invoice/doanhso/nguoigioithieu';
import ThongKeNguoiGioiThieuNew from '../screens/statistic/invoice/doanhso/nguoigioithieunew';
import ThongKeNguoiTiepDon from '../screens/statistic/invoice/doanhso/tieptan';
import Theobenhnhan from '../screens/statistic/invoice/doanhso/theobenhnhan';
import Thongkekenhkhachhang from '../screens/statistic/marketing';
import ThongKeDeNghiKetLuan from '../screens/statistic/ketluan';
import ThongKeVoucher from '../screens/statistic/voucher';
import Thongkenguonkhachhang from '../screens/statistic/invoice/doanhso/theonguonkhachhang/theoluotkham';
import ThongKeNoiTru from '../screens/statistic/invoice/doanhso/noitru';
import ThongKeSoLuongThuoc from '../screens/statistic/medicine/amount';
import ThongKeThuoc from '../screens/statistic/invoice/medic';
import ThongKeDichVu from '../screens/statistic/invoice/services';
import LisStatistic from '../screens/statistic/lis/';
import ThongDanhSachKhamTrongNgay from '../screens/statistic/operation/examstatistic/luotkham';
import PascStatistic from '../screens/statistic/pasc/';
import PatientStatistic from '../screens/statistic/patient/';
import ThuthuatStatistic from '../screens/statistic/thuthuat/';
import ThongKeNhapKho from '../screens/statistic/warehouse/nhapkho/nhapkho';
import ThongKeNhapKhoQuayThuoc from '../screens/statistic/warehouse/nhapkho/nhapkhoquaythuoc';
import ThongKeXuatKho from '../screens/statistic/warehouse/xuatkho/xuatkho';
import ResultSurgery from '../screens/surgery/result';
import QuanLyKhoTong from '../screens/warehouse/khotong';
import PhieuXuatChuyenKhoThuoc from '../screens/warehouse/xuatnhapkho/chuyenkho/xuatchuyenkhothuoc';

//Kho vật tư
import QuanLyHSX from '../screens/warehouse/xuatnhapkho/hangsanxuat/index';
import QuanLyNCC from '../screens/warehouse/xuatnhapkho/nhacungcap'
import NhapKho from '../screens/warehouse/xuatnhapkho/nhapkho/danhsachphieunhapkho';
import XuatKho from '../screens/warehouse/xuatnhapkho/xuatkho/danhsachphieuxuat';
import HoanTra from '../screens/warehouse/xuatnhapkho/hoantra';
import QuanLyPhieuThuoc from '../screens/invoice/prescriptionMerge/index';
import QuanLyGoiKham from '../screens/config/package/index';
import QuanLyGoiThuoc from '../screens/config/goithuoc';
import QuanLySite from '../screens/config/site';
import XuatKhoa from '../screens/recept/xuatkhoa';
import XuatVien from '../screens/recept/xuatvien';
import NhapKhoa from '../screens/recept/nhapkhoa';
import NhapVien from '../screens/recept/nhapvien';
import LichPhauThuThuat from '../configureHIS/lichphauthuthuat/screens/lichphauthuthuat';
import KhamNoiTru from '../screens/recept/inhospital/index';
import XacNhanPhieuLanhThuoc from '../screens/recept/inhospital/quanlythuoc/xacnhanphieu/index';
import SuatAn from '../screens/recept/SuatAn';
import QuanLyTuTruc from '../configureHIS/tutruc/screen/quanlytutruc';
import DuTruThuoc from '../screens/exam/estimates/medicine';
import PhieuXuatTuTruc from '../configureHIS/tutruc/screen/quanlyphieuxuattutruc';
import PhieuVayTuTruc from '../configureHIS/tutruc/screen/quanlyphieuvaytutruc';
import TamUng from '../screens/invoice/tamung';
// import STTKB from '../../src/configureHIS/common/stttable';

//Nội trú
import DanhSachSuatAn from '../configureHIS/noitru/screens/suatan';
import CauHinhPhongNoiTru from '../configureHIS/noitru/screens/cauhinhphongnoitru';

//Bảo hiểm y tế
//Cấu hình danh mục mã kỹ thuật - dịch vụ tương đương
import CauHinhDVKT from '../configureHIS/danhmucdichvukythuattuongduong/maDichVuKyThuatTuongDuong';
//Danh mục mã khoa theo quy chế bệnh viện của Bộ Y tế
import CauHinhMaKhoa from '../configureHIS/bhyt/danhmucmakhoa';
import CreateXML from '../configureHIS/bhyt/createXML/patient';
import DiCom from '../screens/pasc/dicom'
//Danh mục vật tư cố định
import DMTaiSanCoDinh from '../configureHIS/taisancodinh';

import ThongKeTieuHao from '../screens/statistic/warehouse/tieuhao';

import Roles from '../screens/roles';
import LichDangKy from '../screens/lichdangky'
import LichhenLayMau from '../screens/lichhenlaymau';
import Hotline from '../screens/hotline';
import HotlineManage from '../screens/hotline/manager';
import TuVan from '../screens/tuvan';
import DieuPhoi from '../screens/dieuphoi';
import NhomCTV from '../screens/nhomctv';
import Loyalty from '../screens/loyalty';
//loyalty
import Promotionnew from '../screens/promotionnew';
import CauHinhNhomThanhVien from '../screens/loyalty/configure';
import Templatevoucher from '../screens/promotion/templatevoucher';
import Promotion from '../screens/promotion';
import CardType from '../screens/cards/cardtype';
import CardAccount from '../screens/cards/cardaccount';
import Card from '../screens/cards/card';

import Feature from '../screens/features';
import Menu from '../screens/super-admin/menu';
import CompanyExam from '../screens/companyexams/index';

import Corporation from '../screens/corporation';
import Survey from '../screens/survey';
import ThongKeDauSinhTon from '../screens/statistic/dausinhton';
import ThongKeLayMauTaiNha from '../screens/statistic/laymautainha';
import ThongKeLichHen from '../screens/statistic/appointment';
import EmailConfig from '../screens/config/email';
import Marketing from '../screens/config/marketing';
import DanhSachLoaiThanhToan from '../configureHIS/update_vp/screens/danhsachloaithanhtoan';
import PhieuThanhToan from "../configureHIS/PhieuThanhToan";
import ThongKeTiepNhanMau from "../screens/statistic/laymau";
import ThongKeChuyenKho from '../screens/statistic/warehouse/xuatkho/chuyenkho';
import ExpiredMedicine from '../screens/statistic/expiredMedicine'
import ThongKeCongNo from '../screens/statistic/congno';
import ThongKeDoanhThuTrungBinh from '../screens/statistic/invoice/doanhthutrungbinh';
import ThongKeChiTieu from '../screens/statistic/invoice/chitieu';
import ThongKeSinhNhat from '../screens/statistic/birthday/danhsachsinhnhat';
import Config from '../configureHIS/systemConfig/index';
import ThongKeApDungVoucher from '../screens/statistic/promotion';
import DanhMucTuVietTat from "../configureHIS/shortHand";
import LichSuThanhToan from "../screens/statistic/paymenthistory/LichSuThanhToan";
import ThongKeSoLuongBenhNhan from "../screens/statistic/patient/soluongbenhnhan";
import ThanhToanHangLoat from "../screens/invoice/paymentmulti/DanhSachPhieuThanhToan";
import ThongKeOngMauXetNghiem from "../screens/statistic/ThongKeOngMauXetNghiem";
import ConfigQMS from '../screens/qms/configQMS';

export const subCDHA = {
    key: 'cdha',
    label: 'Chẩn đoán hình ảnh',
    code: 'ddc',
    icon: 'fas fa-images',
    nodes: [
        {
            key: 'them-cdha',
            label: 'Thêm dữ liệu CDHA',
            content: ThemCDHA,
            code: 'ddc07',
        },
        {
            key: 'video',
            label: 'Danh sách video',
            content: VideoList,
            code: 'ddc08',
        },
        {
            key: 'dmviettat',
            label: 'Danh mục viết tắt',
            content: DanhMucTuVietTat,
            code: 'hhe02',
        },
    ]
}

export const listData = [
    {
        key: 'Đăng ký khám bệnh',
        label: 'Đăng ký',
        content: Register,
        icon: 'fas fa-user-edit',
        code: 'dkkb',
    },
    {
        key: 'thanhtoanvienphi',
        label: 'Thanh toán',
        code: 'ttvpngtr',
        icon: 'fas fa-comments-dollar',
        content: PayMent,
    },
    {
        key: 'thanhtoanhangloat',
        label: 'Thanh toán hàng loạt',
        code: 'tthl',
        icon: 'fas fa-hand-holding-usd',
        content: ThanhToanHangLoat,

    },
    // {
    //     key: 'danh-sach-benh-nhan',
    //     label: 'Danh sách khám',
    //     content: QuanLyBN,
    //     code: 'kk01',
    //     icon: 'fas fa-user-injured',

    // },
    {
        key: 'companyexam',
        label: 'Khám sức khỏe công ty',
        content: CompanyExam,
        code: 'companyexam',
        icon: 'fas fa-building',

    },
    {
        key: 'danh-sach-benh-nhan-moi',
        label: 'Danh sách khách hàng',
        content: QuanLyBNMoi,
        code: 'kk02',
        icon: 'fas fa-user-injured',

    },
    {
        key: 'xac-nhan-lay-mau',
        label: 'Xác nhận lấy mẫu',
        content: XacNhanLayMau,
        code: 'xnlm',
        icon: 'fas fa-clipboard-check',

    },
    {
        key: 'ketquaxetnghiem',
        label: 'Kết quả xét nghiệm',
        content: ResultTest,
        code: 'dda01',
        icon: 'fas fas fa-vial',
    },
    {
        key: 'Loyalty',
        label: 'Loyalty',
        code: 'lyt',
        icon: 'fas fa-crown',
        nodes: [
            // {
            //     key: 'cau-hinh-nhom-thanh-vien',
            //     label: 'Nhóm thành viên',
            //     content: CauHinhNhomThanhVien,
            //     code: 'lyt-01',
            //     icon: 'fas fa-users-cog',
            // },
            // {
            //     key: 'member',
            //     label: 'Thành viên',
            //     content: Loyalty,
            //     code: 'lyt-02',
            //     icon: 'fas fa-user-friends',
            // },
            {
                key: 'promotionnew',
                label: 'CTKM',
                content: Promotionnew,
                code: 'lyt-05',
                icon: "fab fa-adversal",
            },
            {
                key: 'TemplateVoucher',
                label: 'Mẫu In Voucher',
                content: Templatevoucher,
                code: 'lyt-04',
                icon: "fas fa-ticket-alt",
            },
            {
                key: 'cards',
                label: 'Thẻ trả trước',
                code: 'cards',
                icon: 'fas fa-credit-card',
                nodes: [
                    {
                        key: 'card-type',
                        label: 'Danh mục loại thẻ',
                        content: CardType,
                        code: 'cardType',
                        icon: '',
                    },
                    {
                        key: 'card-account',
                        label: 'Tài khoản',
                        content: CardAccount,
                        code: 'cardAccount',
                        icon: '',
                    },
                    {
                        key: 'card',
                        label: 'Phát hành thẻ',
                        content: Card,
                        code: 'card',
                        icon: '',
                    },
                ]
            },
        ]
    },
    {
        key: 'Lấy máu tại nhà',
        label: 'Lấy máu tại nhà',
        code: 'lmtn',
        icon: 'fas fa-truck',
        nodes: [
            {
                key: 'Lịch lấy mẫu máu',
                label: 'Lịch lấy máu tại nhà',
                content: LichhenLayMau,
                code: 'lmtn01',
                icon: 'fas fa-vial',
            },
            {
                key: 'Lịch đăng ký',
                label: 'Lịch đăng ký CTV',
                content: LichDangKy,
                code: 'lmtn02',
                icon: 'fas fa-calendar',
            },
            {
                key: 'dieuphoi',
                label: 'Điều phối CTV',
                content: DieuPhoi,
                code: 'lmtn03',
                icon: 'fas fa-link',
            },
        ]
    },


    {
        key: 'thuoc',
        label: 'Thuốc',
        code: 'thuoc',
        icon: 'fas fa-capsules',
        nodes: [
            {
                key: 'phieu-thuoc',
                label: 'Phiếu thuốc',
                content: QuanLyPhieuThuoc,
                code: 'hl',
                icon: 'fas fa-clipboard-list',
            },
            {
                key: 'Cách dùng',
                label: 'Cách dùng',
                content: CachDungThuoc,
                code: 'tv',
                icon: 'fas fa-question-circle',
            },
        ]
    },
    {
        key: 'CSKH',
        label: 'CSKH',
        code: 'cskh',
        icon: 'fas fa-hand-holding-medical',
        nodes: [
            {
                key: 'hotline',
                label: 'Hotline',
                content: Hotline,
                code: 'cskh-hl',
                icon: 'fas fa-headset',
            },
            {
                key: 'tuvan',
                label: 'Tư vấn',
                content: TuVan,
                code: 'cskh-tv',
                icon: 'fas fa-hand-holding-medical',
            },
            {
                key: 'qlcuocgoi',
                label: 'Quản lý cuộc gọi',
                content: HotlineManage,
                code: 'cskh-qlcg',
                icon: 'fas fa-phone',
            },
            {
                key: 'Lịch hẹn',
                label: 'Lịch hẹn',
                content: Lichhen,
                code: 'cskh-ll',
                icon: 'fa fa-calendar',
            },
            {
                key: 'Thống kê sinh nhật',
                label: 'Thống kê sinh nhật',
                content: ThongKeSinhNhat,
                code: 'cskh-sn',
                icon: 'fa fa-birthday-cake',
            },
        ]
    },

    {
        key: 'Kho',
        label: 'Kho',
        code: 'gg',
        icon: "fas fa-warehouse",
        nodes: [
            {
                key: 'quanlyxuatnhap',
                label: 'Xuất nhập',
                code: 'gga',
                nodes: [
                    {
                        key: 'Nhap-kho',
                        label: 'Nhập kho',
                        content: NhapKho,
                        props: 1,
                        code: 'gga01',
                    },
                    {
                        key: 'Xuat-kho',
                        label: 'Xuất kho',
                        props: 1,
                        content: XuatKho,
                        code: 'gga02',
                    },
                    {
                        key: 'Phieu-xuat-chuyen-kho-duoc',
                        label: 'Chuyển kho',
                        props: 1,
                        content: PhieuXuatChuyenKhoThuoc,
                        code: 'gga03',

                    },
                    {
                        key: 'Hoan-tra',
                        label: 'Hoàn trả',
                        content: HoanTra,
                        props: 1,
                        code: 'gga04',
                    },
                ]
            },
            {
                key: 'quanlycauhinh',
                label: 'Cấu hình',
                code: 'ggb',
                nodes: [
                    {
                        key: 'Quan-ly-danh-muc',
                        label: 'Danh mục',
                        content: Material,
                        code: 'ggb01',
                    },
                    {
                        key: 'quan-ly-loai',
                        label: 'Danh mục loại ',
                        content: MaterialType,
                        code: 'ggb02',
                    },
                    {
                        key: 'Quản lý nhà cung cấp',
                        label: 'Quản lý nhà cung cấp',
                        content: QuanLyNCC,
                        code: 'ggb03',
                    },
                    {
                        key: 'Quản lý hãng sản xuất',
                        label: 'Quản lý hãng sản xuất',
                        content: QuanLyHSX,
                        code: 'ggb06',
                    },
                    {
                        key: 'Danh sách kho',
                        label: 'Danh sách kho',
                        content: Storage,
                        code: 'ggb04',
                    },
                ]
            },
            {
                key: 'Quan-ly-ton-kho',
                label: 'Tồn kho',
                content: QuanLyKhoTong,
                code: 'ggc',
            },
        ]
    },
    {
        key: 'Danh-muc-tai-san-co-dinh',
        label: 'Tài sản cố định',
        content: DMTaiSanCoDinh,
        code: 'taisancd',
        icon: 'fa fa-desktop',
    },
    {
        key: 'Báo cáo',
        label: 'Báo cáo',
        icon: 'fas fa-chart-area',
        code: 'jj',
        nodes: [
            {
                key: 'kham benh',
                label: 'Bệnh nhân',
                code: 'jja',
                nodes: [

                    // {
                    //     key: 'luot-kham',
                    //     label: 'Lượt khám',
                    //     content: ThongDanhSachKhamTrongNgay,
                    //     code: 'jja01',
                    // },
                    // {
                    //     key: 'thong-ke-doanh-so-bs',
                    //     label: 'Lượt khám theo bác sĩ',
                    //     content: ThongKeDoanhSoBS,
                    //     code: 'jja02',
                    // },
                    {
                        key: 'thong-ke-nguoi-gioi-thieu',
                        label: 'Người giới thiệu',
                        content: ThongKeNguoiGioiThieuNew,
                        code: 'jja03',
                    },
                    {
                        key: 'patientstatitic',
                        label: 'Số lượng bệnh nhân',
                        content: PatientStatistic,
                        code: 'jja04',
                    },
                    {
                        key: 'thong-ke-so-luong-benh-nhan',
                        label: 'Thống kê số lượng bệnh nhân',
                        content: ThongKeSoLuongBenhNhan,
                        code: 'jja012',
                    },
                    {
                        key: 'thong-ke-nguoi-tiep-don',
                        label: 'Người tiếp đón',
                        content: ThongKeNguoiTiepDon,
                        code: 'jja05',
                    },
                    {
                        key: 'thong-ke-nguon-khach-hang',
                        label: 'Nguồn khách hàng',
                        content: Thongkenguonkhachhang,
                        code: 'jja06',
                    },
                    {
                        key: 'thong-ke-kenh-khach-hang',
                        label: 'Kênh khách hàng',
                        content: Thongkekenhkhachhang,
                        code: 'jja07',
                    },
                    {
                        key: 'thong-ke-de-nghi-ket-luan',
                        label: 'Đề nghị - Kết luận',
                        content: ThongKeDeNghiKetLuan,
                        code: 'jja08',
                    },
                    // {
                    //     key: 'thong-ke-voucher',
                    //     label: 'Thống kê voucher',
                    //     content: ThongKeVoucher,
                    //     code: 'jja10',
                    // },
                    {
                        key: 'Thong-ke-ap-dung-ma-voucher',
                        label: 'Thống kê áp dụng mã CTKM',
                        content: ThongKeApDungVoucher,
                        code: 'jja11',
                        icon: 'fas fa-ticket-alt',
                    },
                ]
            },
            {
                key: 'thongkengoaitru',
                label: 'Doanh thu',
                code: 'jjb',
                nodes: [
                    {
                        key: 'tong hop ngay',
                        label: 'Tổng hợp ngày',
                        code: 'jjb01',
                        content: Theobenhnhan,
                    },
                    {
                        key: 'phieuthanhtoan',
                        label: 'Phiếu thanh toán',
                        code: 'jjb02',
                        content: PhieuThanhToan,
                    },
                    {
                        key: 'thong-ke-doanh-so',
                        label: 'Doanh số',
                        content: ThongKeDoanhSo,
                        code: 'jjb03',
                    },
                    // {
                    //     key: 'cong-no',
                    //     label: 'Công nợ',
                    //     content: CongNo,
                    //     code: 'jjb0203',
                    // },
                    {
                        key: 'hoan-tra',
                        label: 'Hoàn trả',
                        content: ThongKeDaHoanTra,
                        code: 'jjb04',
                    },
                    {
                        key: 'thong-ke-cong-no',
                        label: 'Thống kê công nợ',
                        content: ThongKeCongNo,
                        code: 'jjb05',
                    },
                    {
                        key: 'thong-ke-doanh-thu-trung-binh',
                        label: 'Thống kê doanh thu trung bình',
                        content: ThongKeDoanhThuTrungBinh,
                        code: 'jjb06',
                    },
                    {
                        key: 'thong-ke-chi-tieu',
                        label: 'Thống kê chi tiêu',
                        content: ThongKeChiTieu,
                        code: 'jjb07',
                    },
                ],
            },
            {
                key: 'baocaocanlamsan',
                label: 'Cận lâm sàng',
                code: 'jjd',
                nodes: [
                    // {
                    //     key: 'thong-ke-xet-nghiem',
                    //     label: 'Thống kê xét nghiệm',
                    //     content: LisStatistic,
                    //     code: 'jje01',
                    // },
                    {
                        key: 'chuandoanhinhanh',
                        label: 'Chuẩn đoán hình ảnh',
                        content: PascStatistic,
                        code: 'jjd01',
                    },
                    // {
                    //     key: 'thuthuat',
                    //     label: 'Thống kê Thủ thuật',
                    //     content: ThuthuatStatistic,
                    //     code: 'jjc03',
                    // },
                ]
            },
            {
                key: 'theo-doi-ban-thuoc',
                label: 'Thuốc khác đơn giá',
                content: TheoDoiBanThuoc,
                code: 'jjo',
            },
            {
                key: 'baocaokho',
                label: 'Kho',
                code: 'jjc',
                nodes: [
                    {
                        key: 'thong-ke-nhap-kho',
                        label: 'Thống kê nhập kho',
                        content: ThongKeNhapKho,
                        code: 'jjc01',
                    },
                    {
                        key: 'thong-ke-xuat-kho',
                        label: 'Thống kê xuất kho',
                        content: ThongKeXuatKho,
                        code: 'jjc02',
                    },
                    {
                        key: 'thong-ke-chuyen-kho',
                        label: 'Thống kê chuyển kho',
                        content: ThongKeChuyenKho,
                        code: 'jjc04',
                    },
                    {
                        key: 'thong-ke-vattu-tieuhao',
                        label: 'Vật tư tiêu hao',
                        content: ThongKeTieuHao,
                        code: 'jjc03',
                    },
                    {
                        key: 'thong-ke-thuoc-het-han',
                        label: 'Thuốc-vật tư hết hạn',
                        content: ExpiredMedicine,
                        code: 'jjc05',
                    },
                ]
            },
            {
                key: 'slthuoc',
                label: 'Số lượng thuốc',
                content: ThongKeSoLuongThuoc,
                code: 'slthuoc',
            },
            {
                key: 'thong-ke-dich-vu',
                label: 'Thống kê dịch vụ',
                content: ThongKeDichVu,
                code: 'ee06',
            },
            {
                key: 'danh-sach-hoa-don-do',
                label: 'Danh sách hóa đơn đỏ',
                content: DanhSachHoaDonDo,
                code: 'dshdd',
                icon: 'fas fa-percent',
            },
            {
                key: 'dst',
                label: 'Thống kê chỉ số sinh tồn',
                content: ThongKeDauSinhTon,
                code: 'tkdst',
                icon: 'fas fa-heartbeat',
            },
            {
                key: 'tklaymau',
                label: 'Thống kê lịch hẹn lấy mẫu',
                content: ThongKeLayMauTaiNha,
                code: 'jjlaymau',
                icon: 'far fa-calendar-plus',
            },
            {
                key: 'tklichhen',
                label: 'Thống kê lịch hẹn',
                content: ThongKeLichHen,
                code: 'jjlichhen',
                icon: 'far fa-calendar',
            },
            {
                key: 'tktiepnhanmau',
                label: 'Thống kê tiếp nhận mẫu',
                content: ThongKeTiepNhanMau,
                code: 'jjtepnhanmau',
                icon: 'fas fa-vials',
            },
            {
                key: 'lichsuthanhtoan',
                label: 'Lịch sử thanh toán',
                content: LichSuThanhToan,
                code: 'jjthanhtoan',
            },
            {
                key: 'thongkeongmauxetnghiem',
                label: 'Thống kê ống mẫu xét nghiệm',
                content: ThongKeOngMauXetNghiem,
                code: 'jjthongkeongmau',
            },
        ]
    },

    {
        key: 'Tiện ích',
        label: 'Tiện ích',
        code: 'hh',
        icon: 'fas fa-cogs',
        nodes: [
            {
                key: 'quan-ly-nguoidung',
                label: 'Quản lý người dùng',
                code: 'hha',
                nodes: [
                    {
                        key: 'quan-ly-tai-khoan',
                        label: 'Quản lý tài khoản',
                        content: QuanLyUser,
                        code: 'hha01',
                    },
                    {
                        key: 'quan-ly-nhan-vien',
                        label: 'Quản lý nhân viên',
                        content: QuanLyNhanVien,
                        code: 'hha02',
                    },
                    {
                        key: 'quan-ly-nguoi-gioi-thieu',
                        label: 'Quản lý người giới thiệu',
                        content: NguoiGioiThieu,
                        code: 'hha03',
                    },
                    {
                        key: 'phan-nhom-ctv',
                        label: 'Phân nhóm CTV',
                        content: NhomCTV,
                        code: 'hha04',
                    },
                ]
            },
            {
                key: 'cau-hinh-he-thong',
                label: 'Cấu hình hệ thống',
                code: 'hhb',
                nodes: [
                    {
                        "key": "danh-muc-phan-he",
                        "label": "Cấu hình phân hệ",
                        "code": "feature",
                        "content": Feature,
                        "icon": null,
                    },
                    {
                        "key": "danh-muc-menu",
                        "label": "Cấu hình menu",
                        "code": "menu",
                        "content": Menu,
                        "icon": null,
                    },
                    {
                        key: 'danh-muc-quan-huyen',
                        label: 'Cấu hình thành phố',
                        content: CatalogsDisctrict,
                        code: 'hhb01',
                    },
                    {
                        key: 'cau-hinh-khoa-phong',
                        label: 'Cấu hình khoa phòng',
                        content: CauHinhKhoaPhong,
                        code: 'hhb02',
                    },
                    {
                        // icon: 'fas fa-cog',
                        key: 'quan-ly-controller',
                        label: 'Quản lý controller',
                        content: Roles,
                        code: 'controller',
                    },
                    {
                        key: 'quan-ly-site',
                        label: 'Quản lý site',
                        content: QuanLySite,
                        code: 'hhb03',
                    },
                    {
                        key: 'quan-ly-don-vi',
                        label: 'Quản lý nguồn khách hàng',
                        content: Corporation,
                        code: 'hhb04',
                    },
                    {
                        key: 'cau-hinh-email',
                        label: 'Cấu hình Email',
                        content: EmailConfig,
                        code: 'hhb05',
                    },
                    {
                        key: 'cau-hinh-marketing',
                        label: 'Cấu hình kênh khách hàng',
                        content: Marketing,
                        code: 'hhb06',
                    },
                    {
                        key: 'cauhinh',
                        label: 'Cấu hình',
                        content: Config,
                        code: 'hhb07',
                    },
                ]
            },

            {
                key: 'cau-hinh-vienphi',
                label: 'Cấu hình viện phí',
                code: 'hhc',
                nodes: [

                    {
                        key: 'danh-muc-gia-vien-phi',
                        label: 'Danh mục giá viện phí',
                        content: DanhSachUpdateVP,
                        code: 'hhc03',
                    },
                    {
                        key: 'Danh Mục Loại Viện Phí',
                        label: 'Danh mục loại viện phí',
                        content: DanhSachLoaiVP,
                        code: 'hhc06',
                    },

                    {
                        key: 'Danh Mục Nhóm Viện Phí',
                        label: 'Danh mục nhóm viện phí',
                        content: DanhSachNhomVP,
                        code: 'hhc07',
                    },


                    {
                        key: 'danh-muc-gia-loai-vn',
                        label: 'Danh mục loại xét nghiệm',
                        content: DanhSachLoaiXN,
                        code: 'hhc04',
                    },
                    // {
                    //     key: 'quan-ly-goi-kham',
                    //     label: 'Quản lý gói khám',
                    //     content: QuanLyGoiKham,
                    //     code: 'hhc05',
                    // },


                    {
                        key: 'quan-ly-goi-thuoc',
                        label: 'Quản lý gói thuốc',
                        content: QuanLyGoiThuoc,
                        code: 'hhc08',
                    },
                    // {
                    //     key: 'cau-hinh-cach-dung-thuoc',
                    //     label: 'Cách dùng thuốc',
                    //     content: CachDungThuoc,
                    //     code: 'hhc09',
                    // },
                    {
                        key: 'Danh Mục Loại Thanh Toán',
                        label: 'Danh mục loại thanh toán',
                        content: DanhSachLoaiThanhToan,
                        code: 'hhc10',
                    },
                ]
            },
            // {
            //     key: 'cauhinhvattutieuhao',
            //     label: 'Cấu hình vật tư tiêu hao',
            //     content: VatTuDichVu,
            //     code: 'hhd04',
            // },
            {
                key: 'cauhinhvattuluotkham',
                label: 'Cấu hình vật tư khám',
                content: VatTuDichVuKhamBenh,
                code: 'hhd05',
            },
            {
                key: 'cauhinhdanhsachhangdoi',
                label: 'Cấu hình danh sách hàng đợi',
                content: ConfigQMS,
                code: 'hhd06',
            },

        ]
    },
    {
        key: 'Survey',
        label: 'Danh sách khảo sát',
        code: 'svy',
        content: Survey,
        icon: 'fa fa-chart-pie',
    },
]
