import { DatePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import * as NhapkhoaService from '../../../../services/nhapkhoaService';
import BenhTrinh from './benhtrinh';
import SoChamSoc from './sochamsoc';
import GiuongBenh from './giuongbenh';
import XetNghiem from './xetnghiem';
import CDHA from './CDHA';
import VLTL from './VLTL';
import ThuocNoiTru from '../capthuoc';
const { RangePicker } = DatePicker;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyExam: 1,
            lstPatient: [],
            ngay: [moment(), moment()],
            department: '',
            lstdepartment: [],
            key: '',
            detail: {},
            benhtrinh: {},
            heightLstBtn: 0,
            active: this.props.active === true,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.active !== this.props.active) {
            this.setState({ active: nextProps.active })
        }
        return true;
    }

    componentDidMount() {
        this.loadKhoaCanNhap();
        this.getHeightListButton();
        if (this.state.active === true) {
            window.addEventListener('resize', (size, e) => {
                this.getHeightListButton();
            })
        }
    }
    getHeightListButton() {
        try {
            if (this.state.active === true) {
                let listBtn = document.getElementById('listBtnNT');
                if (listBtn) {
                    let heightListBtn = Number(window.getComputedStyle(listBtn).height.replace('px', ''));
                    this.setState({ heightLstBtn: heightListBtn + 5 });
                }
            }
        } catch (error) {
            console.log(error);
        }

    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.value !== '00');
                this.setState({
                    lstdepartment: data
                });
                if (data.length > 0) {
                    this.setState({ department: data[0].value }, () => { this.DanhSachNhapKhoa() })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu khoa bị lỗi")
        })
    }

    DanhSachNhapKhoa() {
        InHospitalService.DanhSachNoiTru(this.state.department, this.state.key, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPatient: result.data,
                });
                if (this.state.detail.enterCode) {
                    let lstItem = this.state.lstPatient.filter(x => x.enterCode === this.state.detail.enterCode)
                    let item = {};
                    if (lstItem && lstItem.length > 0) {
                        item = lstItem[0];
                    }
                    this.setState({ detail: item })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bệnh nhân nội trú bị lỗi")
        })
    }

    render() {
        const lstButtonBenhAn = [
            {
                key: 1,
                label: 'Bệnh trình'
            },
            {
                key: 9,
                label: 'Giường bệnh'
            },
            {
                key: 2,
                label: 'Sổ chăm sóc'
            },
            {
                key: 3,
                label: 'Xét nghiệm'
            },
            {
                key: 4,
                label: 'CĐHA'
            },
            {
                key: 5,
                label: 'TDCN'
            },
            {
                key: 6,
                label: 'VLTL'
            },
            {
                key: 8,
                label: 'PT-TT'
            },
            {
                key: 7,
                label: 'Thuốc'
            }
        ]
        return (
            <React.Fragment>
                <div className="row no-gutters" style={{ padding: '10px', paddingBottom: '0', height: '100%' }}>
                    <div className="col-md-3 mb-2" style={{ padding: "0px 5px", height: '100%', overflowY: 'auto' }}>
                        <div style={{ backgroundColor: '#fff', padding: '10px', height: '100%', }}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                        locale={viVN} onChange={(value => this.setState({ ngay: value }, () => {
                                            this.DanhSachNhapKhoa();
                                        }))}
                                    />
                                </div>
                                <div className="col-12 mb-2">
                                    <select className="form-control" placeholder="Chọn khoa"
                                        value={this.state.department}
                                        onChange={(value) => { this.setState({ department: value.target.value }, () => { this.DanhSachNhapKhoa() }) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstdepartment.map((row, index) => {
                                            return (
                                                <option key={index} value={row.value} style={{ textTransform: 'uppercase' }}>{row.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-color" type="button" onClick={() => { this.DanhSachNhapKhoa() }}><i className="fa fa-search" /></button>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Nhập mã, tên bệnh nhân" onChange={(e) => this.setState({ key: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.DanhSachNhapKhoa();
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse table-hover">
                                            <tbody>
                                                {this.state.lstPatient.map((row, index) => (
                                                    <tr key={index} style={{ cursor: 'pointer' }}
                                                        onClick={() => { this.setState({ detail: row, benhtrinh: {} }) }}
                                                        className={`${this.state.detail.enterCode === row.enterCode ? 'bg-select-table' : ''}`}>
                                                        <td>{row.enterCode}</td>
                                                        <td>{row.name}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 mb-2" style={{ padding: "0px 5px", height: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div id="listBtnNT" style={{ display: 'flex' }} className="text-uppercase">
                                {lstButtonBenhAn.map((row, index) => (
                                    <button key={index}
                                        style={{
                                            border: (this.state.keyExam === row.key) ? '1px solid #1890ff' : '1px solid rgb(232,232,232)',
                                            color: (this.state.keyExam === row.key) ? '#1890ff' : 'black'
                                            , padding: "10px 5px", textAlign: 'center', cursor: 'pointer', fontSize: '13px'
                                            , width: '100%', marginBottom: 5, flex: 1, borderRadius: 0, backgroundColor: '#fff'
                                        }}
                                        onClick={() => { this.setState({ keyExam: row.key }) }}>
                                        {row.label}
                                    </button>
                                ))}
                            </div>

                            {this.state.keyExam === 1 &&
                                <BenhTrinh
                                    ref={(c) => this.benhtrinh = c}
                                    patientDetail={this.state.detail}
                                    DanhSachNhapKhoa={this.DanhSachNhapKhoa.bind(this)}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 2 &&
                                <SoChamSoc
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 9 &&
                                <GiuongBenh
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                    reloadGiuong={() => {
                                        this.DanhSachNhapKhoa();
                                    }}
                                />
                            }
                            {this.state.keyExam === 3 &&
                                <XetNghiem
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 4 &&
                                <CDHA
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 6 &&
                                <VLTL
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 8 &&
                                <VLTL
                                    thuthuat={true}
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                            {this.state.keyExam === 7 &&
                                <ThuocNoiTru
                                    patientDetail={this.state.detail}
                                    heightListBtn={this.state.heightLstBtn}
                                />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
