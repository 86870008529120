import { Pagination, Button, InputNumber, DatePicker, Form, notification, Table, Modal, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as TuTrucService from '../../../services/tutrucservice';
import * as MedicineService from '../../../services/medicineService';
import TaoCoSoTuTruc from '../components/taocosotutruc';
import { EyeOutlined } from '@ant-design/icons';
import viVN from 'antd/es/date-picker/locale/vi_VN';
const { Option } = Select;
const initialState = {
    makp: '',
    makpCreate: '',
    mabd: 0,
    soluong: 0,
    ngay: moment(),
    makpUpdate: 0,
    totalRecords: 0,
    visibleUpdate: false,
    visibleCreate: false
}
export default class QuanLyTuTruc extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstDuocKP: [],
            lstCoSoTuTruc: [],
            lstMaterial: [],
            lstAddThuoc: [],
            visibleModalEdit: false,
            hiddenUpdate: false,
            hiddenLuu: true,
            disabledUpdate: true,
            currentPage: 1,
        };
    }

    componentWillMount() {
        this.loadDuocKP();
    }
    loadDuocKP() {
        TuTrucService.DanhSachDuocKP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDuocKP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dược khám phòng bị lỗi!' })
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    loadMaterial(key) {

        MedicineService.GetListMaterial(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstMaterial: result.data,
                });
            }
        }).catch(err => {
        })
    }

    loadCoSoTuTruc(page = 1, size = 10) {
        if (this.state.ngay === null || this.state.ngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        if (this.state.makp.length === 0 || this.state.makp === null) {
            notification.warning({ message: 'Vui lòng chọn khoa!' });
            return;
        }
        TuTrucService.DanhSachCSTT(this.state.makp, this.state.ngay.format('YYYY-MM-DD'), page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCoSoTuTruc: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cơ số tủ trực bị lỗi!' })
        })
    }

    async loadUpdate() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            if (this.validator.allValid()) {
                const data = {
                    'mabd': Number(this.state.mabd),
                    'makp': Number(this.state.makpUpdate),
                    'soluong': Number(this.state.soluong),
                }
                TuTrucService.CapNhatCoSoTuTruc(data).then(result => {
                    if (result.isSuccess) {
                        this.setState({
                            hiddenUpdate: false,
                            hiddenLuu: true
                        });
                        notification.success({ message: 'Cập nhật thành công!' })
                        this.loadCoSoTuTruc();
                    }
                }).catch(err => {
                    notification.warning({ message: 'Cập nhật bị lỗi, vui lòng thử lại!' })
                })
            }
        } else {
            this.validator.showMessages();
        }
    }
    handleOk = e => {
        this.setState({
            visibleUpdate: false,
            visibleCreate: false,
            visibleModalEdit: false,
            disabledUpdate: true
        });
    };

    handleCancel = e => {
        this.setState({
            visibleUpdate: false,
            visibleCreate: false,
            visibleModalEdit: false,
            hiddenUpdate: false,
            hiddenLuu: true,
            disabledUpdate: true
        });
    };

    render() {
        const columns = [
            {
                title: 'Mã thuốc',
                dataIndex: 'ma',
                key: 'ma',
            },
            {
                title: 'Tên thuốc',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Tên hợp chất',
                dataIndex: 'tenhc',
                key: 'tenhc',
            },
            {
                title: 'Tên hãng',
                dataIndex: 'tenhang',
                key: 'tenhang',
            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'tennuoc',
            },
            {
                title: 'Dạng',
                dataIndex: 'dang',
                key: 'dang',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <Button
                            type="primary"
                            className="btn btn-outline"
                            title="Cập nhật cơ số tủ trực!"
                            onClick={() => {
                                this.setState({ soluong: row.soluong, mabd: row.mabd, makpUpdate: row.makp, visibleModalEdit: true });
                                this.form.setFieldsValue({
                                    soluong: row.soluong
                                })
                            }}>
                            <EyeOutlined />
                            Xem chi tiết
                        </Button>
                    )
                }
            }
        ];
        let dataSource = this.state.lstCoSoTuTruc.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <div style={{ display: 'flex', marginTop: 10 }}>
                            <Form.Item label="Khoa:">
                                <Select value={this.state.makp} style={{ width: 300 }}
                                    showSearch
                                    width={300}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => { this.setState({ makp: e }) }}>
                                    {this.state.lstDuocKP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Tháng:">
                                <DatePicker locale={viVN} format="MM-YYYY" picker="month" allowClear={false}
                                    disabledDate={this.disabledDate}
                                    placeholder="MM-YYYY"
                                    defaultValue={moment()} value={this.state.ngay}
                                    onChange={(value => this.setState({ ngay: value }))}
                                />
                            </Form.Item>
                            <Form.Item>
                            <button className="btn btn-success"
                                onClick={() => this.loadCoSoTuTruc()}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                           </Form.Item>
                           <Form.Item>
                            <button className="btn btn-success" onClick={() => this.setState({ visibleCreate: true })}> <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="form-group" style={{ flex: 1 }} >
                        <Modal maskClosable={false}
                            width={1500}
                            visible={this.state.visibleCreate}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                           
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}>
                            <TaoCoSoTuTruc />
                        </Modal>

                        <Modal maskClosable={false}
                            width={750}
                            visible={this.state.visibleModalEdit}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                           
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ disabled: false }}>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <Form.Item label="Số lượng:" style={{ flex: 1 }} name="soluong" rules={[{ required: true, message: 'Số lượng không được để trống!' }]}>
                                    <InputNumber disabled={this.state.disabledUpdate}
                                        onChange={(e) => this.setState({ soluong: e })} />
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-warning"
                                    hidden={this.state.hiddenUpdate}
                                    onClick={() => this.setState({
                                        hiddenUpdate: true, hiddenLuu: false,
                                        disabledUpdate: false
                                    })}>Cập nhật</button>
                                <button className="btn btn-success" hidden={this.state.hiddenLuu} onClick={() => this.loadUpdate()}
                                > <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                            </div>
                        </Modal>


                        <Table dataSource={dataSource}
                         scroll={{ y: 0 }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.loadCoSoTuTruc(page, size) }}
                            onShowSizeChange={(page, size) => this.loadCoSoTuTruc(1, size)}
                            showSizeChanger={true}
                        />
                    </div>

                </Form>
            </React.Fragment>
        )
    }
}
