import { DatePicker, Form, notification, Table, Input, Pagination,Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as InvoiceService from '../../../../services/invoiceService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import * as StatisticService from '../../../../services/statisticService';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;
const { Option } = Select;
class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            key: '',
            tonghoantra: 0,
            expandableRow: [],
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.LoadThongKeDoanhSo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.LoadThongKeDoanhSo()
        }
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeDoanhSo(page = 1, size = 10) {
        Actions.setLoading(true);
        InvoiceService.DanhSachDaHoanTraTheoNgay(this.state.key, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh).then(result => {
            let tongtien = 0;
            result.data.map(x => tongtien += x.tonghoantra);
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                    tonghoantra: tongtien,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportThongKeDoanhSo() {
        // Actions.setLoading(true);
        // StatisticService.ExportDoanhSo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.loai, this.state.tenloai)
        //     .then((response) => {
        //         response.blob().then((blob) => {
        //             let url = window.URL.createObjectURL(blob);
        //             this._hiddenLink.href = url;
        //             this._hiddenLink.download = "ThongKeDoanhSo";
        //             this._hiddenLink.click();
        //         })
        //         Actions.setLoading(false);
        //     }).catch(err => {
        //         Actions.setLoading(false);
        //     })
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            lst.push(index);
            this.setState({ expandableRow: lst })
        }
        else {
            lst = lst.filter(x => x !== index);
            this.setState({ expandableRow: lst })
        }
    }
    customExpandIcon(props) {
        if (props.expanded) {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-minus fa-1x" /></button>
        }
        else {
            return <button className="btn btn-color btn-sm" onClick={e => {
                props.onExpand(props.record, e);
            }}><i className="fas fa-plus fa-1x" /></button>
        }
    }
    ExportHoanTra() {
        Actions.setLoading(true);
        StatisticService.ExportHoanTra(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeHoanTra";
                    this._hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columnChild = [
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Tên',
                dataIndex: 'tenvp',
                key: 'tenvp',

            },
            {
                title: 'Loại',
                dataIndex: 'loaikham',
                key: 'loaikham',
                render: (value) => {
                    return <span>{value === '1' ? 'Chỉ định' : (value === '2' ? 'Gói khám' : 'Thuốc')}</span>;
                },
            },
            {
                title: 'Ngày đóng',
                dataIndex: 'ngaycd',
                key: 'ngaycd',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>;
                },
            },
            {
                title: 'Ngày trả',
                dataIndex: 'ngayht',
                key: 'ngayht',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>;
                },
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
            },
            {
                title: 'Số tiền',
                dataIndex: 'sotien',
                key: 'sotien',
                align: 'right',
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                width: 100,
            },
            {
                title: 'Họ tên',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width: 300,
            },
            // {
            //     title: 'Chi tiết',
            //     dataIndex: 'lstDaHoanTra',
            //     key: 'lstDaHoanTra',
            //     render: (row) => {

            //         return (
            //             <Collapse className="remove-space-statistic">
            //                 <Panel header="Xem chi tiết">
            //                     <Table
            //                         style={{ margin: '0 !important' }}
            //                         pagination={false}
            //                         dataSource={row} columns={columnChild} rowKey={(row, index) => index} />
            //                 </Panel>
            //             </Collapse>
            //         );
            //     },
            // },
            {
                title: 'Tổng tiền',
                dataIndex: 'tonghoantra',
                key: 'tonghoantra',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            }
        ]
        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê hoàn trả</div>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                    {/* {this.state.isMultisite === true &&
                            <Form.Item>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                                </Form.Item>
                        } */}
                        <Form.Item>
                            <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                onChange={(value => this.setState({ ngay: value }, () => this.LoadThongKeDoanhSo()))}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 20 }}>
                            <Input onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.LoadThongKeDoanhSo();
                                    }
                                }} placeholder="Nhập tên hoặc mã bệnh nhân" />
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => { this.LoadThongKeDoanhSo() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                        </Form.Item>
                        <Form.Item>
                            <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => { this.ExportHoanTra() }}><i style={{ marginRight: 5 }} className="fas fa-download" />Xuất Excel</button>
                        </Form.Item>
                        <div style={{ marginLeft: '5px' }}>Tổng tiền: <strong>{this.state.tonghoantra.toLocaleString('en-US')}</strong></div>
                    </div>
                    <div style={{ height: 'calc(100% - 140px)' }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            pagination={{
                                position: ['bottomCenter'],
                                showSizeChanger: true,
                                style: { textAlignLast: "center", marginTop: 30 },
                                total: this.state.totalRecords,
                                showTotal: (total) => (`Tổng: ${total}`)
                            }}
                            expandable={{
                                expandedRowRender: (record) => (
                                    <div className='expandBorder' style={{ marginTop: "0px", marginBottom: '-7px', marginLeft: 10 }}>
                                        <Table
                                            onRow={(row, rowIndex) => {
                                                return {
                                                    onClick: e => { },
                                                };
                                            }}
                                            style={{ marginLeft: '7px' }}
                                            columns={columnChild} dataSource={record.lstDaHoanTra} pagination={false} rowKey={(row, index) => index} />
                                    </div>
                                ), rowExpandable: record => (record.lstDaHoanTra !== undefined && record.lstDaHoanTra.length > 0),
                            }}
                            rowClassName={(row, index) => {
                                const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                return xx
                            }}
                            expandIcon={(props => this.customExpandIcon(props))}
                            expandRowByClick={true}
                            // expandedRowKeys={this.state.expandableRow}
                            onRow={(record, index) => {
                                return {
                                    onClick: e => {
                                        this.setExpandable(index);
                                    }
                                };
                            }}
                            dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                    </div>
                    {/* <Pagination

                        position={['bottomCenter']}
                        showSizeChanger={true}
                        style={{ textAlignLast: "center", marginTop: 15 }}
                        total={this.state.totalRecords}
                        showTotal={(total) => (`Tổng: ${total}`)}
                    /> */}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(index)
