import { Button, DatePicker, Input, notification, Pagination, Popconfirm, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as InvoiceService from '../../../../services/invoiceService';
import * as PrintService from '../../../../services/printService';
import PatientInFo from '../noitru/patientInfo';
import * as SystemConfig from '../../../../configure/systemConfig';
const { RangePicker } = DatePicker;
function ThanhToanTamUng() {
    const [patient, setPatient] = useState([]);
    const [hinhthucthanhtoan, setHinhthucthanhtoan] = useState(1);
    const [sotien, setSotien] = useState(0);
    const [listTamUng, setListTamUng] = useState([]);
    const [lydo, setLydo] = useState('');
    const [tongtien, setTongtien] = useState(0);
    const [listTheoNgay, setListTheoNgay] = useState([]);
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [key, setKey] = useState('');
    const [currentSize, setCurrentSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const patientRef = useRef();
    useEffect(() => {
        if (currentPage === 0)
            return;
        LoadDanhSachThuTrongNgay();
    }, [currentPage, currentSize])
    useEffect(() => {
        if (patient.patientID === undefined)
            return;
        LoadDanhSachDaThu()
    }, [patient]);
    useEffect(() => {
        LoadDanhSachThuTrongNgay();
    }, []);
    const LoadDanhSachDaThu = () => {
        Actions.setLoading(true);
        InvoiceService.DanhSachDaTamUng(patient.patientID, patient.enterCode, patient.managementID).then(result => {
            if (result.isSuccess) {
                let tongtien = 0;
                result.data.map(x => tongtien += Number(x.sotien))
                setListTamUng(result.data);
                setTongtien(tongtien);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const DeleteTamUng = (id) => {
        Actions.setLoading(true);
        InvoiceService.DeleteTamUng(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" });
                LoadDanhSachDaThu();
                LoadDanhSachThuTrongNgay();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const LoadDanhSachThuTrongNgay = () => {
        Actions.setLoading(true);
        InvoiceService.DanhSachThuTrongNgay(ngay[0].format("YYYY/MM/DD"), ngay[1].format("YYYY/MM/DD"), key, currentPage, currentSize).then(result => {
            if (result.isSuccess) {
                setListTheoNgay(result.data);
                setTotalRecords(result.totalRecords);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const LuuTamUng = () => {
        Actions.setLoading(true);
        const data = {
            'ngayvv': moment(patient.dateIn, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm'),
            'mabn': patient.patientID,
            'maql': patient.managementID,
            'sotien': sotien,
            'mavaovien': patient.enterCode,
            'lydo': lydo,
            'paymenttype': hinhthucthanhtoan,
        }
        InvoiceService.LuuThuTamUng(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                LoadDanhSachDaThu();
                setLydo('');
                setSotien(0);
                setHinhthucthanhtoan(1);
                LoadDanhSachThuTrongNgay();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    const printImprestFund = (id) => {
        PrintService.PrintImprestFund(id).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    return (
        <div style={{ padding: '10px 15px' }}>
            <div style={{ background: '#fff', padding: '10px' }}>
                <PatientInFo ref={patientRef}
                    GetData={(data) => { setPatient(data) }}
                    Reload={() => {
                        LoadDanhSachDaThu();
                        LoadDanhSachThuTrongNgay();
                    }} />
                {patient.patientID &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="h4 font-weight-bold text-center">Thông tin tạm ứng</div>
                            <div className="row">
                                <div className="col-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Số tiền</span>
                                        </div>
                                        <input type="number" maxLength={9} min={0} value={sotien}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 9)
                                                    setSotien(Number(e.target.value));
                                            }} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Hình thức</span>
                                        </div>
                                        <select className="form-control" value={hinhthucthanhtoan} onChange={(e) => setHinhthucthanhtoan(e.target.value)}>
                                            <option key={1} value={1}>Tiền mặt</option>
                                            <option key={2} value={2}>Chuyển khoản</option>
                                            <option key={3} value={3}>Quẹt thẻ</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Lý do</span>
                                        </div>
                                        <input type="text" value={lydo}
                                            onChange={(e) => setLydo(e.target.value)}
                                            className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 text center">
                                <div className="d-flex" style={{ placeContent: 'center' }}>
                                    <Popconfirm title="Xác nhận lưu tạm ứng?"
                                        onConfirm={() => { LuuTamUng() }} okText="Đồng ý" cancelText="Hủy">
                                        <Tooltip placement="top" title="Lưu tạm ứng">
                                            <button className="btn btn-color" > <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                                        </Tooltip>
                                    </Popconfirm>
                                    <button className="btn btn-outline-secondary ml-2" onClick={() => {
                                        setPatient({});
                                        patientRef.current.reset();
                                    }} >Hủy</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="h4 font-weight-bold text-center">Danh sách đã tạm ứng ({tongtien.toLocaleString('en-US')})</div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Ngày</th>
                                            <th>Số HĐ</th>
                                            <th>Số tiền</th>
                                            <th>Thu ngân</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listTamUng.map((row, index) =>
                                            <tr key={index}>
                                                <td>{row.ngaythu}</td>
                                                <td>{row.sobienlai}</td>
                                                <td>{row.sotien.toLocaleString('en-US')}</td>
                                                <td>{row.nhanvienthu}</td>
                                                <td className="d-flex">
                                                    <button className="btn btn-outline-secondary mr-2" onClick={() => { printImprestFund(row.idtamung) }} title="In">
                                                        <i className="fa fa-print"></i>
                                                    </button>
                                                    <Popconfirm title="Xác nhận xóa tạm ứng?"
                                                        onConfirm={() => { DeleteTamUng(row.idtamung) }} okText="Đồng ý" cancelText="Hủy">
                                                        <button className="btn btn-danger mr-2" onClick={() => { }} title="In">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </Popconfirm>
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                <div className="h4 text-center font-weight-bold">Danh sách đóng tạm ứng</div>
                <div className="row" style={{ placeContent: 'center' }}>
                    <div className="col-auto py-1" style={{ width: '250px' }}>
                        <RangePicker format="DD-MM-YYYY" value={ngay} allowClear={false}
                            locale={viVN} onChange={(value => setNgay(value))}
                        />
                    </div>
                    <div className="col-auto py-1">
                        <Input placeholder="Nhập số hóa đơn, tên, mã bệnh nhân"
                            type="text" style={{ width: '250px' }} value={key} onChange={(e) => { setKey(e.target.value) }} />
                        <Button className="btn btn-color"
                            onClick={() => {
                                setCurrentPage(0);
                                setTimeout(() => {
                                    setCurrentPage(1);
                                }, 100);
                            }}
                        ><i className="fa fa-search"></i></Button>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Ngày</th>
                                <th>Mã BN</th>
                                <th style={{ minWidth: '150px' }}>Tên BN</th>
                                <th style={{ minWidth: '100px' }}>Số HĐ</th>
                                <th style={{ minWidth: '100px' }}>Số tiền</th>
                                <th style={{ minWidth: '150px' }}>Thu ngân</th>
                                <th style={{ width: '110px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listTheoNgay.map((row, index) =>
                                <tr key={index}>
                                    <td>{moment(row.ngaythu).format(SystemConfig.FORMAT_DATE)}</td>
                                    <td>{row.mabn}</td>
                                    <td>{row.tenbn}</td>
                                    <td>{row.sobienlai}</td>
                                    <td>{row.sotien.toLocaleString('en-US')}</td>
                                    <td>{row.nhanvienthu}</td>
                                    <td className="d-flex"  style={{ width: '110px' }}>
                                        <button className="btn btn-outline-secondary mr-2" onClick={() => { printImprestFund(row.idtamung) }} title="In">
                                            <i className="fa fa-print"></i>
                                        </button>
                                        <Popconfirm title="Xác nhận xóa tạm ứng?"
                                            onConfirm={() => { DeleteTamUng(row.idtamung) }} okText="Đồng ý" cancelText="Hủy">
                                            <button className="btn btn-danger mr-2" onClick={() => { }} title="In">
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </Popconfirm>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        size={currentSize}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setCurrentSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setCurrentSize(size);
                        }
                        }
                        showSizeChanger={true}
                    />
                </div>

            </div>
        </div>
    )
}

export default ThanhToanTamUng;