import { Button, DatePicker, Modal, notification, Popconfirm, Popover } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import CDHA from '../../../../components/history/cdha';
import * as Actions from '../../../../libs/actions';
import TestResult from '../../../lis/component/result';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as CommonService from '../../../../services/commonService';
import * as InHospitalService from '../../../../services/inPatientService';
import * as MedicineService from '../../../../services/medicineService';
import * as NhapkhoaService from '../../../../services/nhapkhoaService';
import * as PatientService from '../../../../services/patientService';
import * as PrintService from '../../../../services/printService';
import CapThuocNoiTru from '../capthuoc/capthuoc';
import CLSNoiTru from './CLSNoiTru';
const { RangePicker } = DatePicker;
export default class BenhTrinh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            department: '',
            lstdepartment: [],
            key: '',
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            benhtrinh: {},
            HistoryDrawer: false,
            ngayCD: null,
            lstDate: [],
            dienbien: '',
            chamsoc: '',
            lstBenhTrinh: [],
            benhtrinhDetail: [],
            historyInfo: {},
            listType: [],
            collapse: true,
            ngayCreate: moment(),
            visibleEditCLS: false,
            lstDoctor: [],
            lstChiDinhBenhNhan: [],
            lstPTTT: [],
            lstDongY: [],
            lstKho: [],
            makho: '',
            tenkho: '',
            lstThuocDaCap: [],
            listTypeThuoc: [],
            ngayIn: [moment(), moment()],
            lstVLTL: [],
            heightListBtn: 47,
            mabs: '',
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail }, () => {
                this.GetListDateBenhTrinh();
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }
    componentDidMount() {
        this.GetListDateBenhTrinh();
        this.loadDoctor();
        this.loadKho();
    }
    PrintBenhTrinh() {
        if (this.state.detail.enterCode === undefined) {
            notification.warning({ message: 'Chưa chọn bệnh trình!' });
            return;
        }
        this.setState({ visibleDate: false })
        PrintService.PrintBenhTrinh(this.state.detail.enterCode, this.state.ngayIn[0].format('YYYY/MM/DD'), this.state.ngayIn[1].format('YYYY/MM/DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    DanhSachThuocNoiTruTheoBenhTrinh() {
        InHospitalService.DanhSachThuocNoiTruTheoBenhTrinh(this.state.benhtrinh.id).then(result => {
            if (result.isSuccess) {
                let listType = result.data.map(x => x.tenkho);
                let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                this.setState({ lstThuocDaCap: result.data, listTypeThuoc: ListTypeDistinct })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu cấp thuốc của bệnh nhân lỗi' })
        })
    }
    ThongTinPTTTbyIdBenhTrinh(thuthuat) {
        InHospitalService.ThongTinPTTTbyIdBenhTrinh(this.state.benhtrinh.id, thuthuat).then(result => {
            if (result.isSuccess) {
                if (thuthuat === true) {
                    this.setState({ lstPTTT: result.data });
                } else {
                    this.setState({ lstDongY: result.data });
                }
            }
            else {
                if (thuthuat === true) {
                    this.setState({ lstPTTT: [] });
                } else {
                    this.setState({ lstDongY: [] });
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ PTTT' })
        })
    }
    loadKho() {
        MedicineService.GetListKho(0).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value,
                    tenkho: result.data[0].label,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' })
        })
    }
    loadDoctor() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({
                        mabs: result.data[0].value,
                    });
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    XoaChiDinh(id, ngay) {
        if (id !== 0) {
            ChiDinhService.XoaChiDinh(id, ngay).then(result => {
                if (result.isSuccess) {
                    this.DanhSachChiDinhBenhNhan();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            });
        }
    }
    DanhSachChiDinhBenhNhan() {
        ChiDinhService.DanhSachChiDinhBenhNhan(this.state.detail.code, this.state.detail.managerId,
            this.state.detail.department, this.state.ngayCreate.format('YYYY/MM/DD'), false, 0, 0, true, this.state.benhtrinh.id).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    data = data.filter(x => x.idBenhTrinh === this.state.benhtrinh.id)
                    let listType = data.map(x => x.cdtypename);
                    let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    this.setState({
                        lstChiDinhBenhNhan: data,
                        listType: ListTypeDistinct,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu chỉ định của bệnh nhân lỗi' })
            })
    }
    UpdateBenhTrinh(create = false, showMesaage = true) {
        const dataCapthuoc = this.capthuoc.getData();
        const patient = {
            'code': this.state.detail.code,
            'cardNumber': this.state.detail.cardNumber,
            'department': this.state.detail.department,
            'subjectCode': this.state.detail.subjectCode,
            'managerId': this.state.detail.managerId,
            'doctorReceive': this.state.mabs,
            'diagnostic': this.state.detail.diagnostic === undefined ? '' : this.state.detail.diagnostic,
            'diagnosticName': this.state.detail.diagnosticName === undefined ? '' : this.state.detail.diagnosticName,
            'enterCode': this.state.detail.enterCode === undefined ? '' : this.state.detail.enterCode,
        }
        const data = {
            id: this.state.benhtrinh.id ? this.state.benhtrinh.id : '',
            mavaovien: this.state.detail.enterCode,
            maql: this.state.detail.managerId,
            mabn: this.state.detail.code,
            dienbien: create === true ? this.state.dienbiencreate : this.state.dienbien,
            chamsoc: create === true ? this.state.chamsoccreate : this.state.chamsoc,
            ngay: create === true ? this.state.ngayCreate : this.state.benhtrinh.ngay,
            mabs: create === true ? this.state.mabs : this.state.benhtrinh.mabs,
            nhiptim: create === true ? this.state.nhiptimcreate : this.state.nhiptim,
            huyetap: create === true ? this.state.huyetapcreate : this.state.huyetap,
            nhietdo: create === true ? this.state.nhietdocreate : this.state.nhietdo,
            patient: patient,
            listCapThuoc: dataCapthuoc.listCapThuoc,
        }

        if (data.ngay === undefined) {
            notification.warning({ message: 'Chưa chọn bệnh trình!' });
            return;
        }
        InHospitalService.UpdateBenhTrinh(data).then(result => {
            if (result.isSuccess) {
                if (showMesaage === true) {
                    notification.success({ message: 'Lưu thành công' });
                }
                this.setState({
                    benhtrinh: { id: result.id }
                }, () => {
                    this.GetListDateBenhTrinh(true);
                    if (this.capthuoc)
                        this.capthuoc.DanhSachThuocNoiTruTheoBenhTrinh();
                });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bệnh nhân nội trú bị lỗi")
        })
    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.value !== '00');
                this.setState({
                    lstdepartment: data
                });
                if (data.length > 0) {
                    this.setState({ department: data[0].value }, () => {
                        if (this.props.DanhSachNhapKhoa)
                            this.props.DanhSachNhapKhoa();
                    })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu khoa bị lỗi")
        })
    }


    reset() {
        this.setState({
            thamkham: true,
            dienbiencreate: '',
            chamsoccreate: '',
            nhietdocreate: '',
            nhiptimcreate: '',
            huyetapcreate: '',
            benhtrinh: {},
            listType: [],
            lstChiDinhBenhNhan: [],
        })
    }
    GetBenhTrinh() {
        InHospitalService.GetBenhTrinh(this.state.benhtrinh.id).then(result => {
            if (result.isSuccess) {
                this.setState({
                    benhtrinhDetail: result.item,
                    chamsoc: result.item.chamsoc,
                    dienbien: result.item.dienbien,
                    nhietdo: result.item.nhietdo,
                    nhiptim: result.item.nhiptim,
                    huyetap: result.item.huyetap,
                    mabs: result.item.mabs,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
        if (this.state.thamkham !== true) {
            PatientService.getHistoryDetails(this.state.detail.managerId, moment(this.state.benhtrinh.ngay).format('YYYY-MM-DD'), this.state.detail.code, '', true).then((result) => {
                if (result.isSuccess === true) {
                    this.setState({ historyInfo: result.item })
                    let ListTypeDistinct = [];
                    if (result.item.utraSoundResult && result.item.utraSoundResult.isHasHistory === true) {
                        let listType = result.item.utraSoundResult.result
                            .filter(x => x.idbenhtrinh === this.state.benhtrinh.id)
                            .map(x => x.tenloaidichvu);
                        ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    }
                    else {
                        ListTypeDistinct = [];
                    }
                    this.setState({ listType: ListTypeDistinct });
                }
            });
            this.ThongTinPTTTbyIdBenhTrinh(true);
            this.ThongTinPTTTbyIdBenhTrinh(false);
        }
        else {
            this.DanhSachChiDinhBenhNhan();
        }

    }

    GetListDateBenhTrinh(close = true) {
        let ngay = this.state.ngayCD;
        if (ngay == null) {
            ngay = moment("0001-01-01");
        }
        if (this.state.detail.code === undefined) {
            this.reset();
            this.setState({ benhtrinh: {}, lstDate: [] })
            return;
        }
        InHospitalService.GetListDateBenhTrinh(this.state.detail.code, this.state.detail.enterCode, ngay.format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDate: result.data,
                });
                if (result.data.length > 0) {
                    if (this.testResult)
                        this.testResult.ThongTinKetQua();
                    if (this.state.lstDate.length > 0) {
                        this.setState({ benhtrinh: this.state.lstDate[0] }, () => { this.GetBenhTrinh() })
                    }
                }
                else {
                    this.setState({
                        thamkham: true,
                        dienbiencreate: '',
                        chamsoccreate: '',
                        nhietdocreate: '',
                        nhiptimcreate: '',
                        huyetapcreate: '',
                        benhtrinh: {},
                        listType: [],
                        lstChiDinhBenhNhan: [],
                    })
                    if (this.capthuoc)
                        this.capthuoc.setState({ lstDetailNew: [], listType: [] })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bệnh nhân nội trú bị lỗi")
        })
    }

    CopyBenhTrinh() {
        if (this.state.benhtrinh.id === undefined || this.state.benhtrinh.id === '') {
            notification.error({ message: 'Chưa chọn bệnh trình!' });
            return;
        }
        InHospitalService.CopyBenhTrinh(this.state.benhtrinh.id, moment(this.state.benhtrinhngay).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.GetListDateBenhTrinh();
                notification.success({ message: 'Sao chép thành công' })
                this.setState({
                    benhtrinh: { id: result.id }
                });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "err")
        })
    }
    render() {
        let historyInfo = this.state.historyInfo;
        let pos = 0;
        return (
            <React.Fragment>
                <div className="row" style={{ height: `calc(100% - ${this.state.heightListBtn}px)` }}>
                    <div className="col-md-3 col-lg-2 h-100" style={{ paddingRight: 0 }}>
                        <div style={{ backgroundColor: '#fff', overflowY: 'auto', padding: '10px', height: '100%' }}>
                            <button onClick={() => {
                                if (this.state.detail.code) {
                                    this.setState({
                                        thamkham: true,
                                        dienbiencreate: '',
                                        chamsoccreate: '',
                                        nhietdocreate: '',
                                        nhiptimcreate: '',
                                        huyetapcreate: '',
                                        benhtrinh: {},
                                        listType: [],
                                        ngayCreate: moment(),
                                        mabs: this.state.lstDoctor > 0 ? this.state.lstDoctor[0].value : '',
                                        lstChiDinhBenhNhan: [],
                                    })
                                    if (this.capthuoc)
                                        this.capthuoc.setState({ lstDetailNew: [], listType: [] })
                                }
                                else {
                                    notification.error({ message: 'Chưa chọn bệnh nhân!' })
                                }
                            }} className="btn btn-color w-100 mb-2">Thăm khám</button>
                            <DatePicker locale={viVN} placeholder="dd/MM/yyyy" value={this.state.ngayCD}
                                onChange={(e) => {
                                    this.setState({ ngayCD: e }, () => {
                                        this.GetListDateBenhTrinh();
                                    })
                                }} className="w-100" format={'DD/MM/YYYY'}
                            />
                            <div className="table-responsive">
                                <table className="table table-striped table-inverse table-hover">
                                    <tbody>
                                        {this.state.lstDate.map((row, index) => (
                                            <tr onClick={() => {
                                                if (this.testResult)
                                                    this.testResult.ThongTinKetQua();
                                                this.setState({ benhtrinh: row, thamkham: false }, () => { this.GetBenhTrinh(); this.DanhSachThuocNoiTruTheoBenhTrinh(); })
                                            }} style={{ cursor: 'pointer' }}
                                                className={`${this.state.benhtrinh.id === row.id ? 'bg-select-table' : ''}`}>
                                                <td>{moment(row.ngay).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-lg-10 h-100" style={{ overflowY: 'auto' }}>
                        {this.state.thamkham === true ?
                            <div style={{ backgroundColor: '#fff' }}>
                                <div style={{ padding: '10px' }}>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="d-flex">
                                                <div><strong>{this.state.detail.name} </strong></div>
                                                <div style={{ marginLeft: '15px' }}>{this.state.detail.code}</div>
                                            </div>
                                            <div className="d-flex">
                                                <div>{moment().year() - moment(this.state.detail.dob).year()} tuổi</div>
                                                <div style={{ marginLeft: '50px' }}>Số ngày điều trị:
                                                    {Math.round((moment().diff(moment(this.state.detail.dateInHospital), 'hours')) / 24) + (((moment().diff(moment(this.state.detail.dateInHospital), 'hours')) / 24) < 0.5 ? 0.5 : 0)}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Ngày:</label>
                                                    <DatePicker locale={viVN} allowClear={false} placeholder="dd/MM/yyyy"
                                                        value={this.state.ngayCreate}
                                                        onChange={(e) => { this.setState({ ngayCreate: e }) }}
                                                        className="w-100" format={'DD/MM/YYYY'} />

                                                </div>
                                                <div className="col-md-6">
                                                    <label>Bác sĩ điều trị:</label>
                                                    <select className="form-control"
                                                        value={this.state.mabs}
                                                        onChange={(value) => { this.setState({ mabs: value.target.value }) }}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.state.lstDoctor.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.value}>{row.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row equal" style={{ backgroundColor: '#F2F5FA' }}>
                                    <div className="col-md-6" style={{ padding: '10px' }}>
                                        <div style={{ padding: '10px', backgroundColor: '#D9D9D9', fontWeight: 'bold' }}>
                                            DIỄN BIẾN BỆNH
                                        </div>
                                        <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                            <textarea value={this.state.dienbiencreate} className="form-control" rows="3"
                                                onChange={(e) => { this.setState({ dienbiencreate: e.target.value }) }}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ padding: '10px' }}>
                                        <div style={{ padding: '10px', backgroundColor: '#D9D9D9', fontWeight: 'bold' }}>
                                            CHẾ ĐỘ CHĂM SÓC
                                        </div>
                                        <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                            <textarea value={this.state.chamsoccreate} className="form-control" rows="3"
                                                onChange={(e) => { this.setState({ chamsoccreate: e.target.value }) }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row equal" style={{ backgroundColor: '#F2F5FA' }}>
                                    <div className="col-md-6" style={{ padding: '10px' }}>
                                        <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                CHỈ ĐỊNH
                                                <button
                                                    disabled={!this.state.detail.code}
                                                    onClick={() => {
                                                        if (!this.state.benhtrinh.id)
                                                            this.UpdateBenhTrinh(true, false);
                                                        this.setState({ visibleEditCLS: true })
                                                    }}
                                                    className="btn btn-outline-secondary float-right">Thêm chỉ định</button>
                                            </div>
                                            <div className="table-responsive" style={{ height: '300px', border: '1px solid rgb(232,232,232)' }}>
                                                <table className="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '40px' }}>TT</th>
                                                            <th style={{ minWidth: '80px' }}>Mã</th>
                                                            <th style={{ minWidth: '150px' }}>Tên</th>
                                                            <th style={{ minWidth: '80px' }}>Đơn giá</th>
                                                            <th style={{ minWidth: '70px' }}>SL</th>
                                                            <th style={{ minWidth: '80px' }} className="text-right">Giá tiền</th>
                                                            {/* <th style={{ minWidth: '150px' }}>Phòng khám</th> */}
                                                            <th style={{ minWidth: '50px' }}></th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.listType.map((type, i) => {
                                                        return (
                                                            <tbody>
                                                                <tr key={'type' + i}>
                                                                    <th colSpan="7" style={{ textTransform: 'uppercase' }}>{type}</th>
                                                                </tr>
                                                                {this.state.lstChiDinhBenhNhan.filter(x => x.cdtypename === type).map((row, index) => {
                                                                    pos++;
                                                                    return (
                                                                        <tr key={index} className="click" >
                                                                            <td>{pos}</td>
                                                                            <td>{row.code}</td>
                                                                            <td>{row.ten}</td>
                                                                            <td className="text-right">{row.dongia.toLocaleString('en-US')}</td>
                                                                            <td>{row.soluong}</td>
                                                                            <td className="text-right">{(row.dongia * row.soluong).toLocaleString('en-US')}</td>
                                                                            {/* <td></td> */}
                                                                            <td>
                                                                                <Button onClick={() => { this.XoaChiDinh(row.ma, row.ngay) }}
                                                                                    disabled={row.paid === true} type="primary" danger><i className="fa fa-trash"></i></Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        )
                                                    })}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ padding: '10px' }}>
                                        <div style={{ backgroundColor: '#fff', padding: '10px', height: '355px', overflow: 'auto' }}>
                                            <div style={{ fontWeight: 'bold', marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                THUỐC
                                                <div className="float-right">
                                                    <select
                                                        disabled={!this.state.detail.code}
                                                        className="form-control"
                                                        style={{ width: '200px' }}
                                                        showSearch
                                                        value={this.state.makho}
                                                        onChange={(e) => { this.setState({ makho: e.target.value, tenkho: e.target.options[e.target.selectedIndex].text }) }}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.state.lstKho.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.value}>{row.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <CapThuocNoiTru
                                                ref={(c) => this.capthuoc = c}
                                                makho={this.state.makho}
                                                tenkho={this.state.tenkho}
                                                scrollY='255px'
                                                idbenhtrinh={this.state.benhtrinh.id}
                                                managerId={this.state.detail.managerId}
                                                cardNumber={this.state.detail.cardNumber}
                                                subjectCode={this.state.detail.subjectCode}
                                                gender={this.state.detail.gender}
                                                subjectName={this.state.detail.subjectName}
                                                department={this.state.detail.departmentID}
                                                departmentName={this.state.detail.departmentName}
                                                name={this.state.detail.name}
                                                code={this.state.detail.code}
                                                enterCode={this.state.detail.enterCode}
                                                dateIn={this.state.ngayCreate}
                                                noitru={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-50" style={{ display: 'flex', padding: '10px 20px' }}>
                                        <div style={{ flex: 1 }}>
                                            <label>Nhiệt độ</label>
                                            <input value={this.state.nhietdocreate} onChange={(e) => { this.setState({ nhietdocreate: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <label>Nhịp tim</label>
                                            <input value={this.state.nhiptimcreate} onChange={(e) => { this.setState({ nhiptimcreate: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <label>Huyết áp</label>
                                            <input value={this.state.huyetapcreate} onChange={(e) => { this.setState({ huyetapcreate: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#F2F5FA', textAlign: 'center', padding: '10px' }}>
                                    <button disabled={!this.state.detail.code} className="btn btn-color mr-2"
                                        onClick={(e) => {
                                            this.UpdateBenhTrinh(true)
                                        }}><i className="fa fa-save"></i> Lưu</button>
                                    <button onClick={(e) => {
                                        if (this.testResult)
                                            this.testResult.ThongTinKetQua();
                                        if (this.state.lstDate.length > 0) {
                                            this.setState({ benhtrinh: this.state.lstDate[0], thamkham: false }, () => { this.GetBenhTrinh() })
                                        }
                                    }}
                                        className="btn btn-outline-secondary">Hủy</button>
                                </div>
                            </div>
                            :
                            <div style={{ backgroundColor: '#fff', height: '100%' }}>
                                <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                    <div className="row">
                                        <div className="col-6">
                                            Ngày: {moment(this.state.benhtrinh.ngay).format('DD/MM/YYYY')}
                                        </div>
                                        <div className="col-6">Bác sĩ điều trị: {this.state.benhtrinhDetail.tenbs ? this.state.benhtrinhDetail.tenbs : ''}</div>
                                    </div>
                                    <div>
                                        <div className="h5 font-weight-bold">DIỄN BIẾN BỆNH</div>
                                        <textarea value={this.state.dienbien} className="form-control" rows="3"
                                            onChange={(e) => this.setState({ dienbien: e.target.value })}
                                        ></textarea>
                                    </div>
                                    <div>
                                        <div className="h5 font-weight-bold">CHẾ ĐỘ CHĂM SÓC</div>
                                        <textarea value={this.state.chamsoc} className="form-control" rows="3"
                                            onChange={(e) => this.setState({ chamsoc: e.target.value })}
                                        ></textarea>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1 }}>
                                            <label>Nhiệt độ</label>
                                            <input value={this.state.nhietdo} onChange={(e) => { this.setState({ nhietdo: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <label>Nhịp tim</label>
                                            <input value={this.state.nhiptim} onChange={(e) => { this.setState({ nhiptim: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <label>Huyết áp</label>
                                            <input value={this.state.huyetap} onChange={(e) => { this.setState({ huyetap: e.target.value }) }} type="text" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#F2F5FA', textAlign: 'center', padding: '10px' }}>
                                    <button className="btn btn-success mr-2"
                                        onClick={(e) => {
                                            if (this.state.benhtrinh.id && this.state.benhtrinh.id !== '') {
                                                this.setState({
                                                    thamkham: true,
                                                    ngayCreate: moment(this.state.benhtrinh.ngay),
                                                    dienbiencreate: this.state.dienbien,
                                                    chamsoccreate: this.state.chamsoc,
                                                    nhietdocreate: this.state.nhietdo,
                                                    nhiptimcreate: this.state.nhiptim,
                                                    huyetapcreate: this.state.huyetap,
                                                }, () => {
                                                    this.DanhSachChiDinhBenhNhan();
                                                });
                                            }
                                            else {
                                                notification.error({ message: 'Chưa chọn bệnh trình!' })
                                            }
                                        }}>Cập nhật</button>
                                    <Popover id=""
                                        content={
                                            <div style={{ display: 'flex' }}>
                                                <RangePicker format="DD-MM-YYYY" value={this.state.ngayIn} allowClear={false}
                                                    locale={viVN} onChange={(value => this.setState({ ngayIn: value }))}
                                                />
                                                <div>
                                                    <button style={{ height: '30px' }} className="btn btn-secondary ml-2" onClick={() => { this.PrintBenhTrinh() }}><i className="fa fa-print"></i></button>
                                                </div>

                                            </div>
                                        }
                                        title="Chọn ngày"
                                        trigger="click"
                                        visible={this.state.visibleDate}
                                        onVisibleChange={(e) => { this.setState({ visibleDate: e, openDate: e }) }}
                                        placement="bottomRight"

                                    >
                                        <button className="btn btn-outline-secondary mr-2"
                                            onClick={() => { this.setState({ visibleDate: true }) }}
                                        ><i className="fa fa-print"></i> In</button>
                                    </Popover>
                                    <Popconfirm
                                        title="Xác nhận copy bệnh trình?"
                                        onConfirm={() => { this.CopyBenhTrinh() }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <button className="btn btn-outline-secondary">
                                            <i className="fa fa-copy" /> Sao chép
                                        </button>
                                    </Popconfirm>
                                </div>
                                <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <div className="h5 font-weight-bold">DỊCH VỤ</div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input checked={this.state.collapse} type="checkbox" className="form-check-input" onChange={(e) => this.setState({ collapse: e.target.checked })} />
                                                    Thu gọn kết quả
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="h6 font-weight-bold">Xét nghiệm</div>
                                        {this.state.benhtrinh.id &&
                                            <TestResult
                                                ref={(c) => this.testResult = c}
                                                history={true}
                                                noitru={true}
                                                idbenhtrinh={this.state.benhtrinh.id}
                                                maql={this.state.detail.managerId}
                                                ngay={moment(this.state.benhtrinh.ngay).format('YYYY-MM-DD')} />
                                        }
                                    </div>
                                    {this.state.listType.map((type, i) => {
                                        return (
                                            <div className="mb-2" key={i}>
                                                <div className="h6 font-weight-bold">{type}</div>
                                                {historyInfo.utraSoundResult && historyInfo.utraSoundResult.isHasHistory === true &&
                                                    historyInfo.utraSoundResult.result.filter(x => x.tenloaidichvu === type && x.idbenhtrinh === this.state.benhtrinh.id).map((record, index) => {
                                                        return (
                                                            <div key={`child` + { index }}>
                                                                <div data-toggle="collapse" data-target={`#historyExamTable${index}`} style={{ cursor: 'pointer' }} id="btn-history">
                                                                    <div className="d-flex align-items-center" style={{ padding: '5px 10px' }}>
                                                                        {record.tenloai}
                                                                    </div>
                                                                </div>
                                                                <div id={`historyExamTable${index}`} className={`collapse ${this.state.collapse === false ? 'show' : ''}`}>
                                                                    <CDHA key={record.idchidinh} item={record}></CDHA>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        )
                                    })}
                                    <div className="mb-2">
                                        <div className="h6 font-weight-bold">Vật lý trị liệu</div>
                                        {this.state.lstDongY.map((row, index) => (
                                            <div key={`childDongY` + { index }}>
                                                <div data-toggle="collapse" data-target={`#dongy${index}`} style={{ cursor: 'pointer' }} id="btn-history">
                                                    <div className="d-flex align-items-center" style={{ padding: '5px 10px' }}>
                                                        {row.tenvp}
                                                    </div>
                                                </div>
                                                <div id={`dongy${index}`} className={`collapse ${this.state.collapse === false ? 'show' : ''}`}>
                                                    <div>BÁc sĩ thực hiện: {row.tenbs}</div>
                                                    <div>Ghi chú: {row.ketluan}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-2">
                                        <div className="h6 font-weight-bold">Thủ thuật</div>
                                        {this.state.lstPTTT.map((row, index) => (
                                            <div key={`childDongY` + { index }}>
                                                <div data-toggle="collapse" data-target={`#dongy${index}`} style={{ cursor: 'pointer' }} id="btn-history">
                                                    <div className="d-flex align-items-center" style={{ padding: '5px 10px' }}>
                                                        {row.tenvp}
                                                    </div>
                                                </div>
                                                <div id={`dongy${index}`} className={`collapse ${this.state.collapse === false ? 'show' : ''}`}>
                                                    <div>BÁc sĩ thực hiện: {row.tenbs}</div>
                                                    <div>Ghi chú: {row.ketluan}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ height: '10px', backgroundColor: '#F2F5FA' }}></div>
                                <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                                    <div className="mb-2">
                                        <div className="h5 font-weight-bold">THUỐC</div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-inverse table-hover">
                                            {this.state.listTypeThuoc.map((type, i) => {
                                                return (
                                                    <tbody key={'type'}>
                                                        <tr key={'type' + i}>
                                                            <th colSpan="5" style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '13px' }}>{type}</th>
                                                        </tr>
                                                        {this.state.lstThuocDaCap.filter(x => x.tenkho === type).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{row.ten}</td>
                                                                    <td>{row.soluong}
                                                                        {/* <InputNumber value={row.soluong} style={{ width: '80px' }} defaultValue={1} min={1} maxLength={4}
                                                                                            onChange={(e) => { this.editAmount(row, e) }} /> */}
                                                                    </td>
                                                                    <td>{row.cachdung}
                                                                        {/* <Input value={row.cachdung} style={{ width: '80px' }}
                                                                                        onChange={(e) => { this.editWays(row, e.target.value) }} /> */}
                                                                    </td>
                                                                    <td>{row.dang}</td>
                                                                    <td>
                                                                        {/* <div onClick={() => { this.xoathuoc(row) }} style={{ cursor: 'pointer' }}>
                                                                                            <span style={{ width: '100%', height: '100%', marginTop: 3, color: 'red' }}> <i className="fa fa-trash"></i>  </span>
                                                                                        </div> */}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                )
                                            })}
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>

                {this.state.visibleEditCLS && <Modal
                    width={1400}
                    visible={this.state.visibleEditCLS}
                >
                    <CLSNoiTru
                        ref={(c) => this.modalCLS = c}
                        patient={this.state.detail}
                        noitru={true}
                        ngay={this.state.ngayCreate}
                        idbenhtrinh={this.state.benhtrinh.id}
                        showType={false}
                        hideType={false}
                        onClose={() => {
                            this.DanhSachChiDinhBenhNhan();
                            this.setState({ visibleEditCLS: false })
                        }}
                    />
                </Modal>}
            </React.Fragment>
        )
    }
}
