import React, { useState , useRef, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import { PlusOutlined, LeftOutlined, RightOutlined, DeleteFilled } from '@ant-design/icons';
import { Upload, Modal, notification } from 'antd';
import * as Actions from '../../../libs/actions';
import * as UploadFileService from '../../../services/UploadFileService';
import MitaImagePreviewCrop from "./Component/mitaImagePreviewCrop";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default forwardRef((props, ref) => {
    const _upload = useRef();
    // Mã tính năng (xn, cdha, patient)
    const [featureName, setFeatureName] = useState(props.featureName ? props.featureName : '')
    // Mã component sử dụng (chẩn đoán hình ảnh, xét nghiệm)
    const [componentKey, setComponentKey] = useState('')
    const [overwrite, setOverwrite] = useState(true);
    // File types that can be accepted
    const [listExtentionAccept, setListExtentionAccept] = useState(props.extention ? props.extention : '');
    // Id tham chiếu
    const [refId, setRefId] = useState('')
    const [fileList, setFileList] = useState([]);
    const [fileListRemove, setFileListRemove] = useState([]);
    // Modal preview
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        onSubmitUpload: () => {
            UploadFile(fileList);
        },
        GetListFile: () => {
            GetListFile();
        }
    }));

    useEffect(() => {
        setComponentKey(props.componentKey);
        setRefId(props.refId);
    }, [props])

    useEffect(() => {
        if(props.fileList && props.fileList.length > 0)
            props.fileList.map((x) => { setFileList(c => [...c, x]); })
    }, [props.fileList])

    useEffect(() => {
        if(componentKey !== '' && refId !== '')
            GetListFile();
    }, [componentKey, refId])

    const GetListFile = () => {
        Actions.setLoading(true);
        let filterModel = {ComponentKey: componentKey, RefId: refId }
        UploadFileService.GetListAsync(filterModel).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                setData(res.data);
                forceUpdate();
            } else {
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const UploadFile = (listFile) => {
        if (listFile == null || listFile==undefined || listFile.length == 0){
            props.onUploadComplete && props.onUploadComplete();
            return;
        } 
        Actions.setLoading(true);
        let formData = new FormData();
        formData.append('featureName', featureName);
        formData.append('componentKey', componentKey);
        formData.append('overwrite', overwrite);
        formData.append('refId', refId);
        formData.append('extentionAccept',listExtentionAccept)
        listFile.forEach(element => {
            if(element.blob){
                formData.append('files', element.blob);
            } else {
                formData.append('files', element.originFileObj);
            }
            
        });
        fileListRemove.forEach(element => {
            formData.append('fileIdsRemove', element);
        });
        UploadFileService.CreateUploadFileAsync(formData).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                GetListFile();
                props.onUploadComplete && props.onUploadComplete();
            } else {
                Actions.setLoading(false);
                notification.warning({ message: res.error.messageText });
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const setData = (data) => {
        if (data && data.length > 0) {
            setFileList(data.map(x=> { 
                return {
                    uid: x.id,
                    name: x.fileName,
                    status: 'success',
                    url: x.fileUrl,
                }
            }));
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    }

    const handleRemove = (fileList) => {
        setFileListRemove(c => [...c , fileList.uid]);
    }

    const handleCancel = () => setPreviewOpen(false);

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const uploadButton = (
        <div >
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
        <Upload
            customRequest={dummyRequest}
            ref={_upload}
            listType="picture-card"
            fileList={fileList}
            onPreview={(file) => handlePreview(file)}
            onChange={(filelist) => handleChange(filelist)}
            onRemove={(file) => {handleRemove(file)}}
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*, .mp4"
            itemRender={(originNode, file, fileList) => {
                return (
                    <div className="handle-move-upload"> 
                        {/* Tạm thời ẩn do chưa có thời gian làm */}
                        {/* <div className="handle-move-upload-left" title="Prev" onClick={() => { MovePrev(fileList, GetIndex(file.uid, fileList)); }}>
                            <div style={{ marginTop: '30px' }}>
                                <LeftOutlined />
                            </div>
                        </div> */}
                        {originNode}
                        {/* <div className="handle-move-upload-right" title="Next" onClick={() => { MoveNext(fileList, GetIndex(file.uid, fileList)); }}>
                            <div style={{ marginTop: '30px' }}>
                                <RightOutlined />
                            </div>
                        </div> */}
                    </div>
                )
            }}
        >
            {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <Modal 
            width={'60%'}
            visible={previewOpen}>
            <MitaImagePreviewCrop 
            image={previewImage} 
            onCropComplete = {(temp) => { setFileList(c => [...c, temp]) }}
            onClose = {() => {setPreviewOpen(false)}} />
        </Modal>
        </>
    )
});
