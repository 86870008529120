import moment from 'moment';
import { notification } from 'antd';
import React, { Component } from 'react';
import ModalCLS from '../../../../components/MDCLS';
import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import { connect } from 'react-redux';


class Inpatients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultType: 0,
            resultGroup: this.props.type ? this.props.type : 2,
            listGroups: [],
            visibleCLSExam: true,
            patient: this.props.patient ? this.props.patient : {},
            idbenhtrinh: this.props.idbenhtrinh ? this.props.idbenhtrinh : '',
            listTypes: [],
            hideType: true,
            chinhanh:'',
        };
    }
    componentDidMount() {
        
        this.setState({ patient: this.props.patient  , chinhanh:this.props.chinhanh})
        if (this.props.showType === true) {
            this.loadTypes();
        }
        else {
            this.getAllTypeServices();
        }
    }
    getAllTypeServices() {
        ChiDinhService.getAllTypeService().then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    listGroups: respone.data,
                });
            }
        })
    }
    loadTypes() {
        let type = this.props.type;
        ChiDinhService.getGroups(type).then((respone) => {
            if (respone.isSuccess) {
                if (respone.data.length > 0) {
                    this.setState({
                        listTypes: respone.data,
                        selectedType: respone.data[0].value,
                        resultType: respone.data[0].value,
                        selectedSub: respone.data[0].value,
                    });
                }
            }
        })
    }

    deleteRow(row) {
        console.log(row)
        ChiDinhService.XoaChiDinh(row.packageId, row.dateIn).then(result => {
            if (result.isSuccess) {
                //this.ThongTinCDHATheoBenhTrinh();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patient.enterCode !== this.props.patient.enterCode) {
            let oldGroup = this.state.resultGroup;
            let oldType = this.state.resultType;
            this.setState({ resultGroup: -10, resultType: -10 }, () => {
                this.setState({ patient: nextProps.patient, resultGroup: oldGroup, resultType: oldType })
            })
        }
        if (nextProps.idbenhtrinh !== this.props.idbenhtrinh) {
            this.setState({ idbenhtrinh: nextProps.idbenhtrinh });
        }
        if (nextProps.hideType !== this.props.hideType) {
            this.setState({ hideType: nextProps.hideType });
        }
        return true;
    }

    SaveData(data) {
        let lstChiDinh = data.map(x => ({
            dongia: x.dongia,
            dvt: x.dvt,
            bhyt: x.bhyt,
            giaBH: x.giaBH,
            chenhlech: x.chenhlech,
            field_gia: x.field_gia,
            mavp: x.mavp,
            giaTH: null,
            giavattu: 0,
            idKhoa: x.idKhoa,
            idtrongoi: x.idtrongoi,
            loai: x.loai,
            madoituong: "1",
            makp: x.makp,
            ngay: moment(this.props.ngay).format('YYYY/MM/DD HH:mm'),
            soluong: x.soluong,
            ma: x.ma,
            idbenhtrinh: this.state.idbenhtrinh,
            ten: x.name,
            banggia: x.banggia
        }))
        Actions.setLoading(true);
        const patient = {
            'code': this.state.patient.code,
            'cardNumber': this.state.patient.cardNumber,
            'department': this.state.patient.department,
            'subjectCode': "1",
            'managerId': this.state.patient.managerId,
            'enterNumber': this.state.patient.enterNumber,
            'enterCode': this.state.patient.enterCode,
        }
        const dataSubmit = {
            "listChiDinh": lstChiDinh,
            "patient": patient,
            "noitru": true,
        }
        if (dataSubmit.noitru === true && patient.managerId === undefined) {
            notification.error({ message: "Chưa chọn bệnh nhân!" });
            return;
        }
        ChiDinhService.LuuChiDinh(dataSubmit).then(result => {
            if (result.isSuccess) {
                if (this.props.onClose)
                    this.props.onClose();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    render() {
        return (
            <React.Fragment>
                {/* {this.props.showType !== true &&
                    <div style={{ display: 'flex' }} className="text-uppercase">
                        {this.state.listGroups.filter(x => x.value !== 8 && x.value !== 4).map((item, index) => {
                            return (
                                <button className="btn size11"
                                    key={"group" + index}
                                    style={{
                                        border: (item.value == this.state.resultGroup && this.state.resultType == item.type) ? '1px solid #1890ff' : '1px solid rgb(232,232,232)',
                                        color: (item.value == this.state.resultGroup && this.state.resultType == item.type) ? '#1890ff' : 'black'
                                        , padding: "10px 5px", textAlign: 'center', cursor: 'pointer', fontSize: '13px'
                                        , width: '100%', marginBottom: 5, flex: 1, borderRadius: 0, backgroundColor: '#fff'
                                    }}
                                    onClick={() => {
                                        this.setState({ resultType: item.type, resultGroup: item.value, visibleCLSExam: true }, () => {
                                        })
                                    }}>
                                    {item.label}
                                </button>

                            )
                        })
                        }
                    </div>
                } */}
                <div style={{ padding: '10px' }}>
                    {/* {this.props.showType === true &&
                        <div className="text-uppercase" style={{ width: '200px', paddingRight: '10px', marginLeft: '10px', marginTop: '5px' }}>
                            {this.state.listTypes.map((item, index) => {
                                return (
                                    <button className="btn size11"
                                        key={"group" + index}
                                        style={{
                                            border: (item.value == this.state.selectedSub) ? '1px solid #1890ff' : '1px solid rgb(232,232,232)',
                                            color: (item.value == this.state.selectedSub) ? '#1890ff' : 'black'
                                            , padding: "5px", textAlign: 'center', cursor: 'pointer', fontSize: '12px'
                                            , width: '100%', marginBottom: 5, borderRadius: 0, backgroundColor: '#fff'
                                        }}
                                        onClick={() => {
                                            this.setState({ resultType: item.value, selectedSub: item.value, visibleCLSExam: true }, () => {
                                            })
                                        }}>
                                        {item.label}
                                    </button>

                                )
                            })
                            }
                        </div>
                    } */}
                    {this.state.visibleCLSExam === true &&
                        <ModalCLS maskClosable={false}
                            chinhanh={this.state.chinhanh}
                            ref={((c) => this.modalCLS = c)}
                            patientID={this.state.patient.code}
                            managementID={this.state.patient.managerId}
                            deleteFunc={(row) => { this.deleteRow(row) }}
                            noitru={true}//nội trú
                            showType={this.props.showType}
                            hideType={this.state.hideType}
                            type={this.state.resultGroup}
                            idbenhtrinh={this.state.idbenhtrinh}
                            dateIn={this.props.ngay}
                            onClose={(data) => {
                                this.setState({ visibleCLSExam: false });
                                this.SaveData(data);
                                this.props.onClose();
                            }}
                            onCloseInpatient={() => { this.setState({ visibleCLSExam: false }); this.props.onClose(); }}
                        />
                    }
                </div>
            </React.Fragment>
        )
    }
}



const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};



export default connect(mapStateToProps)(Inpatients)