import React, { Fragment, useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from "../../../configure/systemConfig";
import * as StatisticService from '../../../services/statisticService';

export default forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    useImperativeHandle(ref, () => ({
        getList: () => {
            getChartData();
        }
    }));
    useEffect(() => {
        getChartData();
    }, []);
    const getChartData = () => {
        Actions.setLoading(true);
        let dateFrom = props.dateRange && props.dateRange[0];
        let dateTo = props.dateRange && props.dateRange[1];
        let site = props.site;
        let coporator = props.coporator ? props.coporator : "";
        let type = props.listTypes ? props.listTypes.map(x => (x.value)) : "";
        StatisticService.GetChartThongKeOngMau(dateFrom.format(SystemConfig.FORMAT_YYYYMMDD), dateTo.format(SystemConfig.FORMAT_YYYYMMDD), coporator, type, site).then(result => {
            if (result.isSuccess) {
                let data = result.data;
                setData(data);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bị lỗi");
        })
    }
    const config = {
        data,
        xField: 'typeName',
        yField: "totalVials",
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
            },
        },
        meta: {
            totalVials: {
                alias: 'Tổng cộng',
            },
        },
    };
    return (
        <Fragment>
            <div style={{ height: "100%" }} >
                <div className='text-center'>Ống mẫu xét nghiệm</div>
                <Column {...config} />
            </div>
        </Fragment>
    );
})