import { Tabs } from 'antd';
import React, { Component } from 'react';
import CityConfig from './cityConfig/index';
import DiststrictConfig from './districtConfig/index';
import WardConfig from './wardConfig/index';
const { TabPane } = Tabs;

export default class danhmuc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '1',
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='containerChilderWrapper' >


                <Tabs activeKey={this.state.activeKey} onChange={(e) => this.setState({ activeKey: e })}>
                    <TabPane tab={"Thành phố"} key="1" >
                        {this.state.activeKey === '1' &&
                            <CityConfig></CityConfig>
                        }
                    </TabPane>
                    <TabPane tab={"Quận/Huyện"} key="2">
                        {this.state.activeKey === '2' &&
                            <DiststrictConfig></DiststrictConfig>
                        }
                    </TabPane>
                    <TabPane tab={"Phường/Xã"} key="3">
                        {this.state.activeKey === '3' &&
                            <WardConfig></WardConfig>
                        }
                    </TabPane>
                </Tabs>


            </div>
        )
    }
}
