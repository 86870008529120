import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Button, Input, Table, notification, Pagination, DatePicker, Checkbox, Popconfirm, Tag, Modal, Steps, Tooltip, Select, Alert } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import Administrative from '../recept/components/administrative';
import * as RegisMediService from '../../services/regisMediService';
import * as Actions from '../../libs/actions';
import * as HotlineServices from "./hotlineservices";
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as WorldfoneService from '../../services/worldfoneService';
import LoginWF from './loginWF';
import { connect } from 'react-redux';
import './style.css';
import { FORMAT_DATE } from '../../configure/systemConfig';
import { IsActive_FeatureLH, FeatureDeactive } from '../../configure/featureConfig';
import { FEATURE } from "../../configureHIS/common/constants";
import { isNullOrEmpty } from '../../libs/util';
import CreateUpdateLichHenKham from './createUpdateLichHenKham';
import CreateUpdateLichHenLayMau from './createUpdateLichHenLayMau';

const { RangePicker } = DatePicker;
const Hotline = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));
    const { TextArea } = Input;
    const [status, setStatus] = useState("inprogress");
    const [mabn, setMabn] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [detail, setDetail] = useState("");
    const [key, setKey] = useState("");
    const [date, setDate] = useState([moment(), moment()]);
    const [listPatients, setListPatients] = useState([]);
    const [uncompleted, setUncompleted] = useState(false);
    const [tenbn, setTenbn] = useState('');
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [caseId, setCaseId] = useState(0);
    const [updateStatus, setUpdateStatus] = useState("");
    const [caseInfo, setCaseInfo] = useState([]);
    const [idLichHen, setIdLichHen] = useState(0);
    const [priority, setPriority] = useState(false);
    const [showMore, setShowMore] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const [isLoginWF, setIsLoginWF] = useState(false);
    const [titleWF, setTitleWF] = useState('');

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Ngày tiếp nhận',
            dataIndex: 'ngay',
            key: 'ngay',
            align: 'right',
            width: 120,
            render: (date) => moment(date, 'DD/MM/YYYYY HH:mm:ss').format('HH:mm, DD/MM/YYYY')
        },
        {
            title: 'Họ và tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 240,
            align: 'center'
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            align: 'right',
            width: 90,
            render: (date, row) => row.onlyyear ? moment(date, 'YYYY/MM/DD').format('YYYY') : moment(date, 'YYYY/MM/DD').format(FORMAT_DATE)
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            align: 'center',
            width: 120,
        },
        {
            title: 'Ghi chú',
            key: 'ghichu',
            align: 'left',
            width: 300,
            render: (row) => {
                const note = row.ghichu;
                var value = note.indexOf("\n") === 0 ? note.substring(1, note.length) : note;
                const maxLength = 100;
                return (
                    <div>
                        <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? value : value.substring(0, maxLength)}</span>
                        {note.length > maxLength && <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>}
                    </div>
                )
            }
        },
        {
            title: 'Trạng thái',
            key: 'hoanthanh',
            align: 'center',
            width: 150,
            render: (status) => {
                return (
                    status.huy ?
                        <Tag>Đã hủy</Tag>
                        :
                        status.hoanthanh === 1 ?
                            <Tag color="green">Xử lý xong</Tag>
                            :
                            <Tag color={(status.idlichhen && Number(status.idlichhen) > 0) || (status.idhenkham && Number(status.idhenkham) > 0) ? "blue" : status.datuvan ? "blue" : "red"}>{(status.idlichhen && Number(status.idlichhen) > 0) || (status.idhenkham && Number(status.idhenkham) > 0) ? "Đã đặt lịch" : status.datuvan ? "Đã tư vấn" : "Chờ xử lý"}</Tag>
                )
            }
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            render: (row) => {
                return (
                    !row.huy && <div style={{ lineHeight: 3, textAlign: "right" }}>
                        {row.hoanthanh !== 1 && <button onClick={() => updateArrangerment(row.id, "tuvan", 0, row.chinhanh)} disabled={row.tuvan === 1 || row.hoanthanh === 1} className="btn btn-color">
                            {<Tooltip title={row.tuvan === 1 ? "Đã chuyển đến tư vấn viên" : "Chuyển tư vấn"}><i className='fas fa-headset' /></Tooltip>}
                        </button>}
                        {row.hoanthanh !== 1 && IsActive_FeatureLH() &&
                            <button
                                onClick={() => {
                                    setVisibleCreate(true);
                                    setCaseId(row.id);
                                    setUpdateStatus("lenlich");
                                    setCaseInfo(row);
                                }}
                                disabled={row.hoanthanh === 1}
                                className="btn btn-color"
                                style={{ marginLeft: 10 }}>
                                {row.idlichhen !== "" ? <Tooltip title="Xem lịch lấy mẫu"><i className='far fa-calendar-check'></i> - <i className="fas fa-motorcycle" /></Tooltip>
                                    : <Tooltip title="Đặt lịch lấy mẫu"><i className='far fa-calendar-plus' /> - <i className="fas fa-motorcycle" /></Tooltip>}
                            </button>}
                        {row.hoanthanh !== 1 && IsActive_FeatureLH() &&
                            <button
                                onClick={() => {
                                    setVisibleCreate(true);
                                    setUpdateStatus("lichhenkham");
                                    setCaseInfo({ ...row, action: "lichhenkham" });
                                }}
                                disabled={row.hoanthanh === 1}
                                className="btn btn-color"
                                style={{ marginLeft: 10 }}>
                                {row.idhenkham > 0 ? <Tooltip title="Xem lịch khám"><i className='fas fa-calendar-check'></i> - <i className="fas fa-walking" /></Tooltip>
                                    : <Tooltip title="Đặt lịch khám"><i className='far fa-calendar-plus' /> - <i className="fas fa-walking" /></Tooltip>}
                            </button>}
                        {row.hoanthanh !== 1 && <Popconfirm disabled={row.hoanthanh === 1} title="Bạn có chắc case đã hoàn tất?" onConfirm={() => updateArrangerment(row.id, "completed", 0, row.chinhanh)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-color" style={{ marginLeft: 10 }}><Tooltip title="Hoàn thành"><i className='fas fa-check' /></Tooltip></button>
                        </Popconfirm>}
                        <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => {
                            setCaseInfo(row);
                            MakeCall(row);
                        }}><i className="fas fa-phone" /></button>
                        <Popconfirm title="Tiếp nhận sẽ bị hủy bỏ?" onConfirm={() => deleteTiepNhan(row.id, row.idlichhen, row.idhenkham, row.datuvan, row.chinhanh)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    const _patientInfo = useRef();
    useEffect(() => {
        getPatientsList();
    }, [uncompleted, chinhanh]);
    useEffect(() => {
        setChinhanh(props.siteid)
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
            // if (result.item == true) {
            GetListSite();
            // }
        });
    }, []);

    const notifyUpdate = () => {
        const isLoading = false;
        getPatientsList(currentPage, pageSize, uncompleted, isLoading);
    }

    useEffect(() => {
        setChinhanh(props.siteid);
    }, [props.siteid]);

    const getPatientsList = (page = currentPage, size = pageSize, status = uncompleted, noti = true) => {
        Actions.setLoading(noti);
        HotlineServices.GetListPatients(date[0].format("DD/MM/YYYY"), date[1].format("DD/MM/YYYY"), key, page, size, status, chinhanh).then(res => {
            if (res.isSuccess) {
                setListPatients(res.data);
                setTotalRecords(res.totalRecords);
                setCurrentPage(page);
                setPageSize(size);
            }
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    const createNewRequest = (action) => {
        Actions.setLoading(true);
        _patientInfo.current.getForm().validateFields().then(values => {
            if (values.errorFields === undefined && _patientInfo.current.validator.allValid()) {
                let dataAdministrative = _patientInfo.current.getState();
                // setMabn(dataAdministrative.code);
                const data = {
                    'barCode': dataAdministrative.code ? dataAdministrative.code : '',
                    'code': dataAdministrative.code ? dataAdministrative.code : '',
                    'name': dataAdministrative.name.toUpperCase(),
                    'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    'age': dataAdministrative.age,
                    'gender': dataAdministrative.gender,
                    'people': "25",
                    'cmnd': dataAdministrative.cmnd,
                    'phone': dataAdministrative.phone,
                    'email': dataAdministrative.email,
                    'address': dataAdministrative.address,
                    'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                    'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                    'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                    'jobAddress': dataAdministrative.jobAddress,
                    'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                    'country': dataAdministrative.nation,
                    'dateInHospital': moment().format('YYYY/MM/DD'),
                    'timeInHospital': moment().format('HH:mm'),
                    'subjectCode': "2",
                    'coporator': dataAdministrative.coporator,
                    'sendby': dataAdministrative.sendby,
                    'onlyyear': dataAdministrative.onlyyear,
                    'year': dataAdministrative.year,
                }
                RegisMediService.CreatePatient(data).then(result => {
                    if (result.isSuccess) {
                        setMabn(result.patientID);
                        const info = {
                            mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                            ghichu: detail,
                            type: action === "lichhenkham" ? "new" : action,
                            uutien: priority
                        }
                        HotlineServices.addArrangerment(info).then(res => {
                            if (res.isSuccess) {
                                if (action !== "lenlich" && action != "lichhenkham") notification.success({ message: "Thêm thành công" })
                                var caseID = Number(res.returnObject.id); // ID hotline
                                setCaseId(caseID)
                                if (action === "lenlich" && caseID !== undefined && caseID !== 0) {
                                    setCaseInfo({
                                        dienthoai: data.phone,
                                        email: data.email,
                                        gioitinh: data.gender === 0 ? "Nam" : "Nữ",
                                        hoten: data.name,
                                        lenlich: 0,
                                        mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                                        ngayhen: new Date,
                                        ngaysinh: data.dob,
                                        sonha: data.address,
                                        sendby: data.sendby,
                                        idhotline: caseID,
                                        ghichu: detail !== "" ? res.enterNumber + detail : "",
                                    });
                                    setVisibleCreate(true);
                                } else if (action === "lichhenkham" && caseID !== undefined && caseID !== 0) {
                                    setCaseInfo({
                                        dienthoai: data.phone,
                                        email: data.email,
                                        gioitinh: data.gender === 0 ? "Nam" : "Nữ",
                                        hoten: data.name,
                                        lenlich: 0,
                                        mabn: dataAdministrative.code ? dataAdministrative.code : result.patientID,
                                        ngayhen: new Date,
                                        ngaysinh: data.dob,
                                        sonha: data.address,
                                        sendby: data.sendby,
                                        action,
                                        dataAdministrative,
                                        idhotline: caseID,
                                        ghichu: detail !== "" ? res.enterNumber + detail : "",
                                    });
                                    setVisibleCreate(true);
                                }
                                setTenbn("");
                            } else {
                                notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
                            }
                        }).catch(() => { })
                    }
                    else {
                        notification.error({ message: "Không thể thêm thông tin bệnh nhân, vui lòng thử lại sau" })
                        Actions.openMessageDialog("Lỗi", result.err.msgString);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.setLoading(false);
                })
                setStatus("inprogress");
                resetState();
            }
            else {
                notification.error({ message: 'Vui lòng kiểm tra lại dữ liệu!' });
                _patientInfo.current.validator.showMessages();
                Actions.setLoading(false);
            }
        }).catch((ex) => {
            Actions.setLoading(false);
        })
        setPriority(false);
        setDetail("");
    }
    const resetState = () => {
        _patientInfo && _patientInfo.current && _patientInfo.current.resetState();
        setDetail("");
        setTenbn("");
    }
    const updateArrangerment = (id = caseId, status = updateStatus, idhen = idLichHen, chinhanh, destSite) => {
        HotlineServices.updateArrangerment(id, status, idhen, chinhanh, destSite).then(res => {
            if (res.isSuccess) {
                getPatientsList(1);
            }
        }).catch((err) => { console.log(err); })
    }
    const deleteTiepNhan = (id, idlichhen, idhenkham, datuvan, chinhanh) => {
        if (datuvan) {
            notification.error({ message: 'Bộ phận tư vấn đã tiếp nhận nên không thể hủy' });
        } else {
            HotlineServices.deleteTiepNhan(id, idlichhen, idhenkham, chinhanh).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Đã hủy bỏ tiếp nhận" });
                    getPatientsList();
                } else {
                    if (res.err.msgString) notification.error({ message: res.err.msgString });
                    else notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
                }
            }).catch(() => { })
        }
    }
    const MakeCall = (row) => {
        if (row.dienthoai === '' || row.dienthoai.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true)
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienthoai, row.hoten, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF("Vui lòng đăng nhập Worldfone")
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true, 'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    return (
        <React.Fragment>
            <div className='containerChilderWrapper' >
                <div className="row summary-container">
                    <div className="col-md-6" onClick={() => setStatus("inprogress")}>
                        <div className={status !== "inprogress" ? "summary-item inactive" : "summary-item backgroundColorThemes"}>
                            <h4>Đã tiếp nhận</h4>
                            <i className="far fa-check-square"></i>
                        </div>
                    </div>
                    <div className="col-md-6" onClick={() => setStatus("new")}>
                        <div className={status !== "new" ? "summary-item inactive" : "summary-item backgroundColorThemes"}>
                            <h4>Tiếp nhận mới</h4>
                            <i className="fas fa-plus" />
                        </div>
                    </div>
                </div>
                {status === "new" ?
                    <div className="row content-container mt-2">
                        <div className="col-md-8">
                            <h4>ĐANG TIẾP: <span className='text-warning font-bold'>{tenbn}</span></h4>
                        </div>
                        <div className="col-md-4">
                            <div className="mt-2" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <button className="btn btn-color" onClick={() => { resetState() }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Nhập mới</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-2" >
                                <Administrative
                                    isnotRegister={true}
                                    ref={_patientInfo}
                                    newPatient={true}
                                    getPatientName={(patientName) => {
                                        setTenbn(patientName);
                                    }}
                                    onReplaceBangGia={(tenBangGia) => {
                                        if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                            this.setState({
                                                banggia: tenBangGia,
                                            });
                                            this.listServicesRef.handleSearch(this.searchIndication());
                                        }
                                    }}
                                    onReplaceCoporation={() => { }}
                                />
                            </div>
                            <div className='row mt-2'>
                                <div className="col-md-12">
                                    <label className="d-flex mt-2"><span>Ghi chú</span>&nbsp;<span className="ml-auto"><Checkbox checked={priority} onChange={() => setPriority(!priority)}>Ưu tiên</Checkbox></span></label>
                                    <TextArea placeholder="Nhập thông tin chi tiết của tiếp nhận" rows={5} onChange={(e) => setDetail(e.target.value)} value={detail}></TextArea>
                                </div>
                            </div>
                            <div className="mt-2" style={{ display: "flex", justifyContent: "center" }}>
                                <button className="btn btn-color" onClick={() => { createNewRequest("new") }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Thêm</button>
                                <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("tuvan") }}> <i style={{ marginRight: 5 }} className="fas fa-info" />Thêm và chuyển tư vấn</button>
                                {IsActive_FeatureLH() && FeatureDeactive().indexOf(FEATURE.XETNGHIEM) == -1 &&
                                    <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("lenlich") }}> <i style={{ marginRight: 5 }} className="fas fa-calendar-week" />Thêm và đặt lịch hẹn lấy mẫu</button>
                                }
                                {IsActive_FeatureLH() &&
                                    <button className="btn btn-color" style={{ marginLeft: 10 }} onClick={() => { createNewRequest("lichhenkham") }}> <i style={{ marginRight: 5 }} className="far fa-clock" />Thêm và đặt lịch hẹn</button>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <div className='mt-3' style={{ width: "300px" }}>
                                <RangePicker allowClear={false} format={[FORMAT_DATE, FORMAT_DATE]} locale={viVN} value={[moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")]} onChange={(e) => setDate(e)} />
                            </div>
                            <div style={{ width: "400px" }} className="ml-2 mt-3">
                                <Input value={key} onChange={(e) => setKey(e.target.value)}
                                    placeholder="Nhập tên bệnh nhân để tìm kiếm"
                                    onPressEnter={() => getPatientsList(1)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") getPatientsList(1);
                                    }}
                                // suffix={<Checkbox checked={uncompleted} onChange={(e) => setUncompleted(e.target.checked)}>Chưa xử lý</Checkbox>}
                                />
                            </div>
                            <Button className="btn btn-color mt-3 ml-2" onClick={() => { getPatientsList(1) }}> <i className="fas fa-search" /></Button>
                        </div>
                        <div style={{ height: 'calc(100% - 155px)' }} className="mt-3">
                            <Table
                                style={{ width: "100%" }}
                                scroll={{ x: 'max-content', y: 0 }}
                                dataSource={listPatients}
                                columns={columns}
                                pagination={false}
                                rowClassName={(row) => {
                                    if (row.huy)
                                        return "row-disabled"
                                }}
                            />
                            <Pagination
                                style={{ textAlignLast: 'center', marginTop: 10 }}
                                total={totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                current={currentPage}
                                onChange={(page, size) => {
                                    getPatientsList(page, size);
                                }}
                                onShowSizeChange={() => { }}
                                showSizeChanger={true}
                            />
                        </div>
                    </>
                }
            </div>
            {visibleCreate &&
                <RenderCreateModal show={visibleCreate} setShow={setVisibleCreate} caseInfo={caseInfo}
                    reload={() => { resetState(); setVisibleCreate(false); getPatientsList(1); }}
                    chinhanh={chinhanh} caseId={caseId} />
            }
            <Modal
                visible={isLoginWF}
                onCancel={() => { setIsLoginWF(false) }}
            > <LoginWF title={titleWF}
                onClose={() => setIsLoginWF(false)}
                /></Modal>
        </React.Fragment>
    );
})

function RenderCreateModal({ show, setShow, caseInfo, reload, chinhanh, caseId }) {
    const [curCase, setCurCase] = useState(null);
    const [isNewCase, setIsNewCase] = useState(true);
    const [lichHenKham, setLichHenKham] = useState(caseInfo.action === "lichhenkham");
    const [isNewCaseAppointment, setIsNewCaseAppointment] = useState(true);
    const [site, setSite] = useState(
        caseInfo.action === "lichhenkham"
            ? !isNullOrEmpty(caseInfo.chiNhanhHenKham) ? Number(caseInfo.chiNhanhHenKham) : ""
            : !isNullOrEmpty(caseInfo.chiNhanhLayMau) ? Number(caseInfo.chiNhanhLayMau) : "");
    const notifyRef = useRef();
    useEffect(() => {
        if (JSON.stringify(curCase) !== JSON.stringify(caseInfo)) {
            setCurCase(caseInfo);
        };
        caseInfo.idlichhen !== undefined && caseInfo.idlichhen !== "" ? setIsNewCase(false) : setIsNewCase(true);
        caseInfo.idhenkham && caseInfo.idhenkham > 0 ? setIsNewCaseAppointment(false) : setIsNewCaseAppointment(true);
        if (caseInfo.action === "lichhenkham") {
            setLichHenKham(true);
            setSite(!isNullOrEmpty(caseInfo.chiNhanhHenKham) ? Number(caseInfo.chiNhanhHenKham) : "");
        } else {
            setLichHenKham(false);
            setSite(!isNullOrEmpty(caseInfo.chiNhanhLayMau) ? Number(caseInfo.chiNhanhLayMau) : "");
        }
    }, [caseInfo]);
    return (
        curCase &&
        <React.Fragment>
            <Modal visible={show}
                onCancel={() => setShow(false)}
                width={1000}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                destroyOnClose>
                {
                    lichHenKham
                        ? <CreateUpdateLichHenKham
                            isCreate={isNewCaseAppointment}
                            onClose={() => { setShow(false) }}
                            site={site} // Chi nhánh select tạo lịch
                            activeSite={chinhanh} // Chi nhánh người dùng đang login
                            caseInfo={curCase}
                            caseId={caseId}
                            onReload={() => reload()}
                            patientInfo={curCase}
                        />
                        : <CreateUpdateLichHenLayMau
                            isCreate={isNewCase}
                            onClose={() => { setShow(false) }}
                            managementId={curCase.maql}
                            site={site} // Chi nhánh select tạo lịch
                            caseInfo={curCase}
                            caseId={caseId}
                            onReload={() => reload()}
                            patientInfo={curCase}
                            activeSite={chinhanh}
                        />
                }
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Hotline);