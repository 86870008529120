import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import ReactDOM from "react-dom";
import { Input, DatePicker, Button, Select, notification, Switch, Steps, Popconfirm, Drawer } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import { createCardModeEnum } from "../createCardModeEnum";
import Notify, { NotifyStatus } from '../../../components/notify';
import * as CardAccountService from '../../../services/CardAccountService';
import * as CardTypeService from '../../../services/CardTypeService';
import CardTypeDetail from '../cardtype/detail';
import CardDetail from '../card/detail';

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [isDisableFewField, setIsDisableFewField] = useState(false); //disable các field không được sửa khi đã được sử dụng
  const [visibleCardTypeDetail, setVisibleCardTypeDetail] = useState(false);
  const [visibleCardDetail, setVisibleCardDetail] = useState(false);
  const [openConfirmClose, setOpenConfirmClose] = useState(false);
  const [firstRender, setFirstRender] = useState(true); //
  //validate
  //model create
  const [cardAccountId, setCardAccountId] = useState();
  const [cardTypeId, setCardTypeId] = useState();
  const [cardAccountCode, setCardAccountCode] = useState('');
  const [contractNo, setContractNo] = useState('');
  const [accountName, setAccountName] = useState('');
  const [identifierNo, setIdentifierNo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [dateActivated, setDateActivated] = useState();
  const [dateExpired, setDateExpired] = useState();
  const [remark, setRemark] = useState('');
  //extent model
  const [cardTypeName, setCardTypeName] = useState('');
  const [listCardType, setListCardType] = useState([]);
  const [cardTypeSelected, setCardTypeSelected] = useState();
  const [cardAccountModel, setCardAccountModel] = useState();
  //#endregion 
  //#region khai báo biến cục bộ
  const model = {
    cardTypeId: "",
    cardAccountCode: "",
    contractNo: "",
    accountName: "",
    identifierNo: "",
    phoneNumber: "",
    email: "",
    address: "",
    isActive: true,
    dateActivated: moment(),
    dateExpired: moment(),
    remark: "",
    id: ''
  };
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    setCardTypeId(props.cardTypeId);
  }, [props.cardTypeId])

  useEffect(() => {
    setCardTypeSelected(props.cardTypeSelected);
    setHeaderTextExtent(props.cardTypeSelected?.cardTypeName ?? '');
  }, [props.cardTypeSelected])

  useEffect(() => {
    getById(cardAccountId);
  }, [cardAccountId]);

  useEffect(() => {
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - Tài khoản / ' + cardAccountCode);
  }, [cardAccountCode]);

  useEffect(() => {
    setCardAccountId(props.cardAccountId);
  }, [props.cardAccountId]);

  useEffect(() => {
    if (firstRender || props.detailScreenMode == detailScreenModeEnum.detail) return;
    if (cardTypeSelected)
      setDateExpiredExtent();
    else setCardTypeSelected(listCardType.filter(x=>x.id == cardTypeId)[0]);
  }, [dateActivated]);

  useEffect(() => {
    if (firstRender || props.detailScreenMode == detailScreenModeEnum.detail) return;
    setDateExpiredExtent();
  }, [cardTypeSelected]);

  useEffect(() => {
    switch (props.detailScreenMode) {
      case detailScreenModeEnum.detail:
        break;
      case detailScreenModeEnum.create:
        getListCardType();
        setCardAccountId('');
        break;
      case detailScreenModeEnum.update:
        getListCardType();
        break;
      default:
        //Nếu open screen detail này mà không truyền props.detailScreenMode là gì thì cho out
        props.onCancel();
        break;
    }
    setDetailScreenMode(props.detailScreenMode);
  }, [props.detailScreenMode]);

  useEffect(() => {
    if (firstRender) setFirstRender(false);
  }, []);
  //#endregion 
  const setHeaderTextExtent = (cardTypeName = cardTypeName) => {
    if (props.detailScreenMode == detailScreenModeEnum.create) {
      setHeaderText('Đăng ký Tài khoản - '+cardTypeName);
    }
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - Tài khoản / ' + cardAccountCode);
    else if (props.detailScreenMode == detailScreenModeEnum.update) {
      setHeaderText('Cập nhật - Tài khoản / ' + cardAccountCode);
    }
  }
  const setDateExpiredExtent = () => {
    if (dateActivated && cardTypeSelected) {
      let dExpired = moment(dateActivated).add(cardTypeSelected.expiredTime, cardTypeSelected.expiredTimeType)
      setDateExpired(dExpired);
    }
  }
  //#region search & filter
  const getListCardType = () => {
    // Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: 0,
      maxResultCount: 1000,
      // sorting: 'cardTypeName',
      //riêng theo module
      keyword: '',
      isActives: [true],
      getInclude: false
    }
    CardTypeService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setListCardType(result.data.items);
        // if (result.data.items?.map(x => x.id).indexOf(props.cardTypeId) == -1) setCardTypeId('');
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        // Actions.setLoading(false);
        forceUpdate();
    })
  }

  const setData = (data) => {
    setCardTypeId(data.cardTypeId);
    setCardAccountCode(data.cardAccountCode);
    setContractNo(data.contractNo);
    setAccountName(data.accountName);
    setIdentifierNo(data.identifierNo,);
    setPhoneNumber(data.phoneNumber);
    setEmail(data.email);
    setAddress(data.address);
    setIsActive(data.isActive);
    setDateActivated(moment(data.dateActivated));
    setDateExpired(moment(data.dateExpired));
    setRemark(data.remark);
    //extent
    setCardTypeName(data.cardTypeName);
    if (detailScreenMode == detailScreenModeEnum.update && data.hasUsed) setIsDisableFewField(true);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    CardAccountService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const setModel = () => {
    model.cardTypeId = cardTypeId;
    model.cardAccountCode = cardAccountCode;
    model.contractNo = contractNo;
    model.accountName = accountName;
    model.identifierNo = identifierNo;
    model.phoneNumber = phoneNumber;
    model.email = email;
    model.address = address;
    model.isActive = isActive;
    model.dateActivated = dateActivated;
    model.dateExpired = dateExpired;
    model.remark = remark;
    model.id = cardAccountId;
  }

  const saveCreate = (isNextStep = true) => {
    Actions.setLoading(true);
    CardAccountService.create(model).then(result => {
      if (result.isSuccess) {
        if (isNextStep) {
          setCardAccountModel(result.data);
          setCardAccountId(result.data.id);
          setVisibleCardDetail(true);
          notiRef && notiRef.current.Close();
        }
        else {
          props.onCancel(true);
          notification.success({ message: "Thành công" });
        }
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    CardAccountService.update(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  const validate = () => {
    let isValid = true;
    return isValid;
  }

  const saveButtonHandle = (isNextStep = true) => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (
        (detailScreenMode == detailScreenModeEnum.update && cardAccountId) ||
        (detailScreenMode == detailScreenModeEnum.create && cardAccountId) // này là trường hợp click "Chuyển bước" -> là đã tạo CardAccount -> bên CardDetail click "Quay lại" -> rồi click lưu
      ) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate(isNextStep);
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const nextStepClickHandle = () => {
    if (cardAccountId) {
      setVisibleCardDetail(true);
      notiRef && notiRef.current.Close();
    }
    else {
      saveButtonHandle();
    }
  }

  const onCancelCardDetailHandle = (isReload = false) => {
    if (isReload) {
      setVisibleCardDetail(false);
      props.onCancel(true);
      notification.success({ message: "Thành công" });
    }
    else {
      //Nghĩa là bên tạo card click "Quay lại": đóng cái screen tạo thẻ
      setVisibleCardDetail(false);
    }
  }

  const deleteNewCardAccount = () => {
    CardAccountService.deleteById(cardAccountId).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
    })
  }

  const onCancelCardAccountDetailHandle = (newOpen) => {
    if (!newOpen) {
      setOpenConfirmClose(newOpen);
      return;
    }
    // này là trường hợp click "Chuyển bước" -> là đã tạo CardAccount -> bên CardDetail click "Quay lại" -> bây giờ click "Đóng": thì sẽ hỏi xem có xóa AcrdAccount đã tạo hay không
    if (detailScreenMode === detailScreenModeEnum.create && cardAccountId) {
      setOpenConfirmClose(newOpen);
      return;
    }
    props.onCancel();
    setOpenConfirmClose(false);
  }

  //set cái này để khi mở thêm 1 drawer khác thì cái drawer trước đó không bị đẩy ra
  const setStyleTransformDrawerContentWrapper = () => {
    let t = document.getElementsByClassName('ant-drawer-content-wrapper');
    for (let i = 0; i< t?.length; i++) {
      t[i].style.transform = '';
    }
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page'>
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row mt-2" hidden={detailScreenMode != detailScreenModeEnum.create}>{/* step */}
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <Steps current={0} progressDot labelPlacement="vertical">
                  <Step title="Thông tin tài khoản" description="" />
                  <Step title="Thông tin thẻ" description="" />
                </Steps>
              </div>
            </div>
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Loại thẻ*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Loại thẻ</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Select
                      style={{ width:"calc(100% - 80px)" }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      hidden={detailScreenMode === detailScreenModeEnum.detail || (detailScreenMode === detailScreenModeEnum.update && isDisableFewField)}
                      disabled={detailScreenMode === detailScreenModeEnum.detail}
                      value={ cardTypeId }
                      onChange={(value, option) => {  
                        setCardTypeId(value); 
                        setHeaderTextExtent(option.children); 
                        setCardTypeSelected(listCardType.filter(x => x.id === value)[0]);
                      }}
                      // filterOption={(input, option) => { }}
                    >
                      {(listCardType ?? []).map((record, index) => {
                          return (
                              <Option key={record.id} value={record.id}
                                disabled={(detailScreenMode === detailScreenModeEnum.update && isDisableFewField) && record.id != cardTypeId}
                              >{record.cardTypeName}</Option>
                          )
                      })}
                    </Select>
                    <Input
                      hidden={detailScreenMode === detailScreenModeEnum.create || (detailScreenMode === detailScreenModeEnum.update && !isDisableFewField)}
                      readOnly={detailScreenMode==detailScreenModeEnum.detail}
                      disabled={detailScreenMode === detailScreenModeEnum.update && isDisableFewField}
                      value={cardTypeName}
                    ></Input>
                    <Button type="link" className="p-0 ml-2"
                      onClick={() => {
                        if (!cardTypeId) {
                          notification.warning({message:"Vui lòng chọn Loại thẻ"})
                          return;
                        }
                        setVisibleCardTypeDetail(true)
                      }}
                    >
                      Xem chi tiết
                    </Button>
                  </div>
                  {validator.current.message("Loại thẻ", cardTypeId, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Số tài khoản / Hoạt động */}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Số tài khoản</label>
                  </div>
                  <Input
                    value={cardAccountCode}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={detailScreenMode === detailScreenModeEnum.update && isDisableFewField}
                    onChange={(e) => setCardAccountCode(e.target.value)}
                  ></Input>
                  {validator.current.message("Số tài khoản", cardAccountCode, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Hoạt động</label>
                  <div>
                    <Switch 
                      // disabled={detailScreenMode==detailScreenModeEnum.detail || hasAccount}
                      checked={isActive} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsActive(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Số hợp đồng */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Số hợp đồng</label>
                  </div>
                  <Input
                    value={contractNo}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={detailScreenMode === detailScreenModeEnum.update && isDisableFewField}
                    onChange={(e) => setContractNo(e.target.value)}
                  ></Input>
                  {validator.current.message("Số hợp đồng", contractNo, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Hiệu lực */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Hiệu lực</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <DatePicker //allowClear={false}
                      hidden={detailScreenMode === detailScreenModeEnum.detail || (detailScreenMode === detailScreenModeEnum.update && isDisableFewField)}
                      disabled={detailScreenMode === detailScreenModeEnum.detail || (detailScreenMode === detailScreenModeEnum.update && isDisableFewField)}
                      locale={viVN}
                      showNow={false}
                      className={detailScreenMode === detailScreenModeEnum.detail ? 'd-none' : 'w-100 mr-2'}
                      placeholder={"Bắt đầu"}
                      value={dateActivated}
                      format = { SystemConfig.FORMAT_DATE }
                      onChange={(e) => { 
                        setDateActivated(e ? moment(e) : null);
                      } }
                    />
                    <Input 
                      hidden={!(detailScreenMode === detailScreenModeEnum.detail)}
                      className='w-100 ant-picker'
                      value={dateActivated ? moment(dateActivated).format(SystemConfig.FORMAT_DATE) : ''}
                      readOnly={true}
                    ></Input>
                    <Input
                      className='w-100 ml-2 ant-picker'
                      placeholder="Kết thúc"
                      value={dateExpired ? moment(dateExpired).format(SystemConfig.FORMAT_DATE) : ''}
                      readOnly={true}
                    ></Input>
                  </div>
                  {validator.current.message("Hiệu lực", dateActivated, "required")}
                </div>
                {/* <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label></label>
                  </div>
                </div> */}
              </div>
              <div className="row mt-2"> {/* Tên chủ thẻ */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên chủ thẻ</label>
                  </div>
                  <Input
                    value={accountName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={detailScreenMode==detailScreenModeEnum.update && isDisableFewField}
                    onChange={(e) => setAccountName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên chủ thẻ", accountName, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* CCCD/CMND/Passport*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>CCCD/CMND/Passport</label>
                  </div>
                  <Input
                    value={identifierNo}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={detailScreenMode==detailScreenModeEnum.update && isDisableFewField}
                    onChange={(e) => setIdentifierNo(e.target.value)}
                  ></Input>
                  {validator.current.message("CCCD/CMND/Passport", identifierNo, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Điện thoại liên hệ / Email */}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label>Điện thoại liên hệ</label>
                    <label className='required-field' hidden={detailScreenMode==detailScreenModeEnum.detail}>*</label>
                  </div>
                  <Input
                    value={phoneNumber}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    // disabled={isDisableFewField}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  ></Input>
                  {validator.current.message("Điện thoại liên hệ", phoneNumber, "required|phonenumber")}
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Email</label>
                  </div>
                  <Input
                    value={email}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    // disabled={isDisableFewField}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                  {validator.current.message("Email", email, "required|email")}
                </div>
              </div>
              <div className="row mt-2"> {/* Địa chỉ liên hệ */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Địa chỉ liên hệ</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  ></TextArea >
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
              <Button type="link" className="p-0" title="Đăng ký thẻ"
                hidden={detailScreenMode != detailScreenModeEnum.create}
                onClick={() => { nextStepClickHandle(); }}
              >
                Chuyển bước
                <i className="fa fa-chevron-right ml-2"></i>
              </Button>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={() => {saveButtonHandle(false);}}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <Popconfirm
                  title="Tài khoản đã được tạo. Bạn có muốn xóa trước khi đóng không?"
                  open={openConfirmClose}
                  onVisibleChange={(newOpen) => onCancelCardAccountDetailHandle(newOpen)}
                  onConfirm={() => deleteNewCardAccount() }
                  onCancel={() => props.onCancel()}
                  okText="Xóa"
                  cancelText="Không"
                >
                  <button
                    className='btn btn-danger'
                    // onClick={() => props.onCancel()}
                  >
                    <i className='fa fa-sign-out-alt mr-2'></i>
                      Đóng
                  </button>
                </Popconfirm>
            </div>
          </div>
      </div>
      {/* <Modal maskClosable={false}
          width={800}
          height="calc(100% - 10px)"
          visible={visibleCardTypeDetail}
          onCancel={() => setVisibleCardTypeDetail(false) }
          destroyOnClose={true}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <CardTypeDetail 
            isDialog={ true } 
            cardTypeId={cardTypeId}
            detailScreenMode = {detailScreenModeEnum.detail}
            onCancel={() => {setVisibleCardTypeDetail(false)}}/>
      </Modal> */}
      {/* <Modal maskClosable={false}
          width={800}
          height="calc(100% - 10px)"
          visible={visibleCardDetail}
          // onCancel={() => setVisibleCardDetail(false) }
          // destroyOnClose={true} // Bên tạo card click "Quat về" thì nên giữ lại state
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <CardDetail 
            isDialog={ true } 
            cardAccountId={cardAccountId}
            detailScreenMode = {detailScreenModeEnum.create}
            createMode = {createCardModeEnum.createAccount}
            cardAccountSelected = {cardAccountModel}
            onCancel={(reload = false) => { onCancelCardDetailHandle(reload); }}/>
      </Modal> */}
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={700}
        placement="right"
        // onClose={() => handleCancel()}
        visible={visibleCardTypeDetail}
        destroyOnClose={true}
        afterVisibleChange={(visible) => {
          setStyleTransformDrawerContentWrapper();
        }}
      >
        <CardTypeDetail 
          isDialog={ true } 
          cardTypeId={cardTypeId}
          detailScreenMode = {detailScreenModeEnum.detail}
          onCancel={() => {setVisibleCardTypeDetail(false)}}
        />
      </Drawer>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        mask={false}
        closable={false}
        width={700}
        placement="right"
        // onClose={() => handleCancel()}
        visible={visibleCardDetail}
        // destroyOnClose={true}
        afterVisibleChange={(visible) => {
          setStyleTransformDrawerContentWrapper();
        }}
      >
        <CardDetail 
          isDialog={ true } 
          cardAccountId={cardAccountId}
          detailScreenMode = {detailScreenModeEnum.create}
          createMode = {createCardModeEnum.createAccount}
          cardAccountSelected = {cardAccountModel}
          onCancel={(reload = false) => { onCancelCardDetailHandle(reload); }}
        />
      </Drawer>
    </React.Fragment>
  );
})