import React, { useRef, Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect } from "react";
import { List, Checkbox, Tag, Input, Radio, Button, Select, Popover, Popconfirm, Drawer, Tabs, Tooltip, Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { LOYALTY_TYPE, FEATURE, COMBO, PAYMENTTYPE } from "../../../../configureHIS/common/constants";
import HeaderModal from "../../../../configureHIS/common/headerModal";
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import {FeatureDeactive} from '../../../../configure/featureConfig';
import { registerSource as registerSourceEnum } from "../../../../commons/enums/registerSource";
import * as Actions from '../../../../libs/actions';
import { isObjectEmpty, isNullOrEmpty, getPageSetting } from '../../../../libs/util';
import handlebarHelper from "../../../../libs/handlebarHelper";
import * as InvoiceService from '../../../../services/invoiceService';
import * as PrintService from '../../../../services/printService';
import * as PatientService from '../../../../services/patientService';
import * as LisService from '../../../../services/lisService';
import * as AppointmentService from '../../../../services/appointmentService';
import AppliedVoucher from '../appliedVoucher';
import CustomerVATInfo from '../../../vatinvoice/customer';
import DiscountPayment from "./DiscountPayment";
import OTPConfirm from '../OTPConfirm';
import Handlebars from 'handlebars';
import SeparatePayment from "./SeparatePayment";
import NoContent from "../../../../components/loading/noContent";

const { confirm } = Modal;
export default forwardRef((props, ref) => {
    // Constant
    const UNPAY = 1;

    const optionsLoyalty = [];
    if (FeatureDeactive().indexOf(FEATURE.DEBITCARD) == -1) // phân hệ Thẻ trả trước
        optionsLoyalty.push({ label: 'Thẻ trả trước', value: LOYALTY_TYPE.CARD });
    if (FeatureDeactive().indexOf(FEATURE.CTKM) == -1) // phân hệ CTKM
        optionsLoyalty.push({ label: 'CTKM', value: LOYALTY_TYPE.VOUCHER });
    // Ref
    const firstRender = useRef(true);
    // Datasource
    const [listGroupResult, setListGroupResult] = useState([]);
    const [listServiceResult, setListServiceResult] = useState([]);
    const [listSelectedServices, setListSelectedServices] = useState([]);
    const [listMedicineResult, setListMedicineResult] = useState([]);
    const [listPaymentType, setListPaymentType] = useState([]);
    // Data
    const [patient, setPatient] = useState(props.patient);
    const [managementId, setManagementId] = useState('');
    const [registerSource, setRegisterSource] = useState();
    const [billType, setBillType] = useState(props.billType)
    const [selectedPaymentType, setSelectedPaymentType] = useState(1);
    const [selectedService, setSelectedService] = useState({});
    const [billRemark, setBillRemark] = useState('');
    const [lyDoKham, setLyDoKham] = useState('');
    const [lyDoKhamInit, setLyDoKhamInit] = useState('');
    const [ghiChuKhamSucKhoeInit, setGhiChuKhamSucKhoeInit] = useState('');
    const [ghiChuKhamSucKhoe, setGhiChuKhamSucKhoe] = useState('');
    const [totalBillService, setTotalBillService] = useState(0);
    const [totalBillMedicine, setTotalBillMedicine] = useState(0);
    const [isPercent, setIsPercent] = useState(false);
    const [discountValueBill, setDiscountValueBill] = useState(0);
    const [discountPercentBill, setDiscountPercentBill] = useState(0);
    const [discountValueService, setDiscountValueService] = useState(0);
    const [selectedPromotion, setSelectedPromotion] = useState({})
    const [isOTPConfirm, setIsOTPConfirm] = useState(false);
    const [vatInvoice, setVatInvoice] = useState(false);
    const [isPayConfirm, setIsPayConfirm] = useState(false);
    const [disableSelectPayment, setDisableSelectPayment] = useState(false);
    const [promotionRegister, setPromotionRegister] = useState({});
    // Filter
    const [loyaltyType, setLoyaltyType] = useState(null);
    //
    const [visiblePromotion, setVisiblePromotion] = useState(false);
    const [visibleVATInvoice, setVisibleVATInvoice] = useState(false);
    const [visibleDiscount, setVisibleDiscount] = useState(false);
    const [visibleOTP, setVisibleOTP] = useState(false);
    const [visibleSeparatePayment, setVisibleSeparatePayment] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Separate Payment
    const INITIAL_PAYMENT = {
        ma : selectedPaymentType,
        amount : 0,
    }
    const [listSelectedPaymentType, setListSelectedPaymentType] = useState([INITIAL_PAYMENT]);

    useImperativeHandle(ref, () => ({
        reload: (mid, pid) => {
            ResetState();
            // GetPatientInfo(pid);
            setManagementId(mid);
            GetPaymentDetail(mid);
        },
        resetState: () => {
            ResetState();
        }
    }));

    useEffect(() => {
        setPatient(props.patient);
    }, [props.patient]) 

    useEffect(() => {
        setBillType(props.billType);
    }, [props.billType]) 

    useEffect(() => {
        if (!firstRender.current && !isNullOrEmpty(managementId)) {
            GetPaymentDetail(managementId);
        }
    }, [billType])

    useEffect(() => {
        if (!firstRender.current && !isObjectEmpty(promotionRegister)) {
            submitVoucher(promotionRegister);
        }
    }, [promotionRegister])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            GetListPaymentType();
        }
    }, []);

    const GetPatientInfo = (pid) => {
        PatientService.GetPatientDetail(pid).then(result => {
            if (result.isSuccess) {
                setPatient(result.data);
            } else {
                props.onError(result);
            }
        }).catch(err => {
            props.onError(err);
        }).finally(() => {
            forceUpdate();
        })
    }

    const GetPaymentDetail = (maql) => {
        Actions.setLoading(true);
        let filter = {
            'ManagementId' : maql,
            'BillType' : billType,
            'ProcessStatus' : UNPAY,
            'PatientId' : patient.maBN,
        };
        InvoiceService.GetPaymentDetail(filter).then(result => {
            if (result.isSuccess) {
                // Register
                setRegisterSource(result.data.registerSource);
                // Service
                setListGroupResult(result.data.listServiceGroupDetail);
                setListServiceResult(result.data.listServiceDetail);
                setTotalBillService(result.data.totalBillService);
                setDiscountValueService(result.data.totalDiscountService);
                // Medicine
                setListMedicineResult(result.data.listMedicineBill);
                setTotalBillMedicine(result.data.totalBillMedicine);
                // Promotion
                if(result.data.promotionInfo !== null){
                    setPromotionRegister(result.data.promotionInfo);
                }
                // Payment Type
                if(result.data.paymentType !== null && result.data.paymentType > 0 
                    && (result.data.listServiceDetail.length !== 0 || result.data.listMedicineBill.length !== 0)) {
                    onSelectedPaymentType(result.data.paymentType);
                } else {
                    onSelectedPaymentType(PAYMENTTYPE.CASH);
                }
                // Các ghi chú
                setLyDoKham(result.data.note.reasonCome);
                setLyDoKhamInit(result.data.note.reasonCome);
                setGhiChuKhamSucKhoe(result.data.note.physicalExaminationNote);
                setGhiChuKhamSucKhoeInit(result.data.note.physicalExaminationNote);
                // Check confirm by Collaborator
                GetVATPayByMaql(maql);
            } else {
                props.onError(result)
            }
        }).catch(err => {
            props.onError(err)
        }).finally(() => {
            forceUpdate();
            Actions.setLoading(false);
        })
    }

    const GetListPaymentType = () => {
        VPService.GetListPaymentType().then(result => {
            if (result.isSuccess) {
                setListPaymentType(result.data);
            } else {
                props.onError(result)
            }
        }).catch(err => {
            props.onError(err)
        })
    }

    const openAppliedVoucher = () => {
        convertListSelectedService(); 
        setVisiblePromotion(true);
    }

    const validateVoucher = (promotion) => {
        if(promotion.loyaltyType === LOYALTY_TYPE.SERVICE || promotion.loyaltyType === LOYALTY_TYPE.CARD){
            let listService = listServiceResult;
            for (var i = 0; i < listService.length; i++) {
                if ( (promotion.isAllProductPreferential && !promotion.excludeProductIds.includes(listService[i].serviceId))
                    || (promotion.discountProductIds && promotion.discountProductIds.includes(listService[i].serviceId))
                    || (promotion.freeProductIds && promotion.freeProductIds.includes(listService[i].serviceId))) 
                {
                    if(listService[i].discountValue > 0){
                        let err = {
                            error : { messageText : 'Dịch vụ thuộc CTKM đã được giảm giá. Vui lòng hủy giảm giá rồi thử lại.' }
                        }
                        props.onError(err);
                        return false;
                    }
                }
            }
        }
        if(isSeparate()) // Kiểm tra đã phân bổ HTTT chưa
            return false;
        return true;
    }

    const submitVoucher = (promotion) => {
        Actions.setLoading(true);
        // Kiểm tra xem dịch vụ thuộc giảm giá đã được giảm giá tay chưa
        let check = validateVoucher(promotion);
        if(check){
            //Nếu CTKM giảm giá theo voucher => giảm giá toàn bill
            if(promotion.loyaltyType === LOYALTY_TYPE.VOUCHER){
                let totalBill = totalBillService - discountValueService;
                if(promotion.isPercent){
                    let discountValue = Number(totalBill * (promotion.discountValue / 100));
                    setDiscountValueBill(discountValue);
                    setDiscountPercentBill(Number(promotion.discountValue));
                } else {
                    if(totalBill !== 0){
                        if(promotion.discountValue > totalBill){
                            setDiscountPercentBill(100);
                            setDiscountValueBill(Number(totalBill));
                        } else {
                            let discountPercent = (promotion.discountValue / totalBill) * 100
                            setDiscountPercentBill(discountPercent);
                            setDiscountValueBill(Number(promotion.discountValue));
                        }
                    } else {
                        setDiscountPercentBill(0);
                        setDiscountValueBill(0);
                    }
                }
                setIsPercent(promotion.isPercent);
                setBillRemark(promotion.voucherCode);
            } else if (promotion.loyaltyType === LOYALTY_TYPE.SERVICE){
                /*  
                    Nếu CTKM có giá trị giảm cao hơn tổng giá của dịch vụ => Giảm 100%.
                    Nếu CTKM có giá trị giảm thấp hơn tổng giá của dịch vụ => Chia dịch vụ theo tỷ lệ phần trăm => Giảm theo tỉ lệ.
                    Khóa những dịch vụ yêu cầu và dịch vụ khuyến mãi.
                */
                const listService = listServiceResult;
                let listDiscountService = promotion.discountProductIds;
                let totalPriceOfDiscountService = 0; // Tổng giá của các dịch vụ giảm giá.
                let totalDiscountService = 0; // Tổng giá giảm giá của các dịch vụ.
                listService.map(x => {
                    if(promotion.discountProductIds.includes(x.serviceId)){
                        totalPriceOfDiscountService += x.price
                    }
                })
                listService.forEach(c => {
                    if(promotion.criteriaProductIds.includes(c.serviceId)){
                        c.voucherId = promotion.id.toString();
                        c.voucherCode = promotion.voucherCode;
                        c.discountRemark = promotion.voucherCode;
                        c.disableCheck = true;
                    }
                    if(listDiscountService.includes(c.serviceId)){
                        c.voucherId = promotion.id.toString();
                        c.voucherCode = promotion.voucherCode;
                        c.isPercent = promotion.isPercent;
                        c.discountRemark = promotion.voucherCode;
                        c.disableCheck = true;
                        if(promotion.isPercent){
                            c.discountPercent = promotion.discountValue;
                            c.discountValuePromotion = Number((c.price * (promotion.discountValue / 100)));
                        } else {
                            if(promotion.discountValue >= totalPriceOfDiscountService){
                                
                                c.discountValuePromotion = c.price;
                            } else {
                                c.discountValuePromotion = Number((promotion.discountValue * (c.price / totalPriceOfDiscountService))) ;
                            }
                        }
                        totalDiscountService += c.discountValuePromotion;
                    }
                })
                setTotalBillService(totalBillService - totalDiscountService);
                // Set lại giảm giá toàn bill
                onChangeDiscountBill(totalBillService - totalDiscountService);
                setListServiceResult(listService);
            } else if (promotion.loyaltyType === LOYALTY_TYPE.CARD){
                const listService = listServiceResult;
                let totalDiscountService = 0; //
                // Do là thẻ trả trước chỉ cho phép giảm giá theo %
                listService.forEach(c => {
                    //  Nếu giảm giá tất cả các dịch vụ và không phải là dịch vụ ngoài trừ -> Giảm hết 
                    if(promotion.isAllProductPreferential && !promotion.excludeProductIds.includes(c.serviceId)
                        && (promotion.freeProductIds && !promotion.freeProductIds.includes(c.serviceId))){
                        c.cardId = promotion.id.toString();
                        c.isPercent = promotion.isPercent;
                        c.disableCheck = true;
                        if(promotion.isPercent){
                            c.discountPercent = promotion.discountValue;
                            c.discountValueCard = Number((c.price * (promotion.discountValue / 100)).toFixed()) ;
                        }
                        totalDiscountService += c.discountValueCard;
                    } else if(promotion.discountProductIds && promotion.discountProductIds.includes(c.serviceId)){
                        c.cardId = promotion.id.toString();
                        c.isPercent = promotion.isPercent;
                        c.disableCheck = true;
                        if(promotion.isPercent){
                            c.discountPercent = promotion.discountValue;
                            c.discountValueCard = Number((c.price * (promotion.discountValue / 100)).toFixed());
                        }
                        totalDiscountService += c.discountValueCard;
                    }
                    if(promotion.freeProductIds && promotion.freeProductIds.includes(c.serviceId)){
                        c.cardId = promotion.id.toString();
                        c.isPercent = promotion.isPercent;
                        c.disableCheck = true;
                        if(promotion.isPercent){
                            c.discountPercent = 100;
                            c.discountValueCard = c.price;
                        }
                        totalDiscountService += c.discountValueCard;
                    }
                })
                setTotalBillService(totalBillService - totalDiscountService);
                // Set lại giảm giá toàn bill
                onChangeDiscountBill(totalBillService - totalDiscountService);
                setListServiceResult(listService);
                // setSelectedPaymentType(PAYMENTTYPE.CARD);
                onSelectedPaymentType(PAYMENTTYPE.CARD);//2023.07.20: task #4182
                setDisableSelectPayment(true);
            }
            setSelectedPromotion(promotion);
            props.onCloseError();
        }
        Actions.setLoading(false);
    }

    const onClearPromotion = () => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            if(!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType !== LOYALTY_TYPE.CARD){
                if(selectedPromotion.loyaltyType === LOYALTY_TYPE.VOUCHER){
                    setDiscountPercentBill(0);
                    setDiscountValueBill(0);
                    setIsPercent(false);
                } else if(selectedPromotion.loyaltyType === LOYALTY_TYPE.SERVICE) {
                    let listService = listServiceResult;
                    let voucherId = selectedPromotion.id.toString();
                    let totalDiscountService = 0; // Tổng giá giảm giá của các dịch vụ.
                    listService.map(c => {
                        if(c.voucherId === voucherId){
                            totalDiscountService += c.discountValuePromotion;
                            c.voucherId = null;
                            c.voucherCode = null;
                            c.isPercent = false;
                            c.disableCheck = false;
                            c.discountValuePromotion = 0;
                            c.discountRemark = "";
                        }
                    })
                    setListServiceResult(listService);
                    setTotalBillService(totalBillService + totalDiscountService);
                    onChangeDiscountBill(totalBillService + totalDiscountService);
                }
                setSelectedPromotion({});
            }
            forceUpdate();
        }
    }

    const onClearCard = () => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            if(!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType === LOYALTY_TYPE.CARD){
                let listService = listServiceResult;
                let voucherId = selectedPromotion.id.toString();
                let totalDiscountService = 0; // Tổng giá giảm giá của các dịch vụ.
                listService.map(c => {
                    if(c.cardId === voucherId){
                        totalDiscountService += c.discountValueCard;
                        c.voucherId = null;
                        c.isPercent = false;
                        c.disableCheck = false;
                        c.discountValueCard = 0;
                    }
                })
                setListServiceResult(listService);
                setTotalBillService(totalBillService + totalDiscountService);
                onChangeDiscountBill(totalBillService + totalBillMedicine + totalDiscountService);
                setSelectedPromotion({});
                setIsOTPConfirm(false);
                // setSelectedPaymentType(PAYMENTTYPE.CASH);
                onSelectedPaymentType(PAYMENTTYPE.CASH);//2023.07.20: task #4182
                setDisableSelectPayment(false);
            }
            forceUpdate();
        }
    }

    const onDiscountBill = (value, isMedicine) => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            if(isObjectEmpty(selectedService)){
                setDiscountValueBill(value.discountValue);
                setDiscountPercentBill(value.discountPercent);
                setIsPercent(value.isPercent);
                setBillRemark(value.remark);
            } else {
                let discountValueAfter = Number(discountValueService + value.discountValue);
                if(isMedicine){
                    let listMedicineBill = listMedicineResult;
                    listMedicineBill.map(x => {
                        if(x.billId === selectedService.billId){
                            x.isPercent = value.isPercent;
                            x.discountPercent = value.discountPercent;
                            x.discountValue = value.discountValue;
                            x.discountRemark = value.remark;
                        }
                    })
                    setListMedicineResult(listMedicineBill)
                } else {
                    let listService = listServiceResult;
                    listService.map(c => {
                        if(c.idChiDinh === selectedService.idChiDinh){
                            c.isPercent = value.isPercent;
                            c.discountPercent = value.discountPercent;
                            c.discountValue = value.discountValue;
                            c.discountRemark = value.remark;
                        }
                    })
                    setListServiceResult(listService);
                }
                setDiscountValueService(Number(discountValueService + value.discountValue));
                setSelectedService({});
                // Cập nhật lại giảm giá bill nếu thay đổi giảm giá dịch vụ 
                if(isPercent === true){
                    let valueDiscount = discountValueBill
                    valueDiscount = Number(totalBillService + totalBillMedicine - discountValueAfter) * (discountPercentBill / 100)
                    setDiscountValueBill(valueDiscount)
                }
            }
            setVisibleDiscount(false);
            forceUpdate();
        }
    }

    const onCloseDiscount = () => {
        setVisibleDiscount(false);
        setSelectedService({});
    }

    const clearDiscountService = (record, isMedicine) => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            let discountValueAfter = 0;
            if(isMedicine){
                let medicineBill = listMedicineResult;
                medicineBill.map(c => {
                    if(c.billId === record.billId){
                        setDiscountValueService(Number(discountValueService - c.discountValue));
                        discountValueAfter = Number(discountValueService - c.discountValue);
                        c.isPercent = false;
                        c.discountPercent = 0;
                        c.discountValue = 0;
                        c.discountRemark = '';
                    }
                })
                setListMedicineResult(medicineBill);
            } else {
                let listService = listServiceResult;
                listService.map(c => {
                    if(c.idChiDinh === record.idChiDinh){
                        setDiscountValueService(Number(discountValueService - c.discountValue));
                        discountValueAfter = Number(discountValueService - c.discountValue);
                        c.isPercent = false;
                        c.discountPercent = 0;
                        c.discountValue = 0;
                        c.discountRemark = '';
                    }
                })
                setListServiceResult(listService);
            }
            // Thay đổi giá trị giảm giá toàn bill
            onChangeDiscountBill((totalBillService + totalBillMedicine) - discountValueAfter)
            forceUpdate();
        }
    }

    const clearDiscountBill = () => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            setDiscountValueBill(0); 
            setDiscountPercentBill(0);
            setIsPercent(false);
        }
    }

    const onChecked = (checked, record, isMedicine) => {
        // Kiểm tra xem đã phân bổ HTTT chưa
        if(!isSeparate()){
            let amountBillMedicine = totalBillMedicine;
            let amountBillService = totalBillService;
            if(isMedicine){
                let medicineBill = listMedicineResult;
                medicineBill.map(x => {
                    if(x.billId === record.billId){
                        x.checked = checked
                        if(checked){
                            amountBillMedicine += x.totalBill
                        } else {
                            amountBillMedicine -= x.totalBill
                        }
                    }
                })
                setListMedicineResult(medicineBill);
            } else {
                let listService = listServiceResult;
                listService.map(c => {
                    if(c.idChiDinh === record.idChiDinh){
                        c.checked = checked
                        if(checked){
                            amountBillService += c.price;
                            setDiscountValueService(discountValueService + c.discountValue);
                        } else {
                            amountBillService -= c.price;
                            setDiscountValueService(discountValueService - c.discountValue);
                        }
                    }
                })
                setListServiceResult(listService);
            }
            onChangeDiscountBill(amountBillService + amountBillMedicine);
            setTotalBillService(amountBillService);
            setTotalBillMedicine(amountBillMedicine);
            forceUpdate();
        }
    }

    const onChangeDiscountBill = (totalBill) => {
        if(isPercent === true){
            let discountValue = discountValueBill;
            discountValue = (totalBill) * (discountPercentBill / 100);
            setDiscountValueBill(discountValue)
        }
    }

    const convertListSelectedService = () => {
        let listSelected = []
        let listService = listServiceResult;
        listService.map(c => {
            if(c.checked === true){
                listSelected.push({...c, mavp : c.serviceId});
            }
        })
        setListSelectedServices(listSelected);
        return listSelected;
    }

    const ResetState = () => {
        setPatient({});
        setManagementId('');
        setSelectedPaymentType(1);
        setSelectedService({});
        setListServiceResult([]);
        setListMedicineResult([]);
        setBillRemark('');
        setLyDoKham('');
        setLyDoKhamInit('');
        setGhiChuKhamSucKhoe('');
        setGhiChuKhamSucKhoeInit('');
        setTotalBillService(0);
        setTotalBillMedicine(0);
        setIsPercent(false);
        setDiscountValueBill(0);
        setDiscountPercentBill(0);
        setDiscountValueService(0);
        setSelectedPromotion({});
        setPromotionRegister({});
        setIsOTPConfirm(false);
        setVatInvoice(false);
        setDisableSelectPayment(false);
        setListSelectedPaymentType([INITIAL_PAYMENT])
    }

    const SavePayment = () => {
        Actions.setLoading(true);
        let listPaymentMedthod = [];
        if(listSelectedPaymentType && listSelectedPaymentType.length === 1){
            listPaymentMedthod = listSelectedPaymentType.map(x => {
                return {
                    ma : x.ma,
                    tongtien : Number((totalBillService + totalBillMedicine) - discountValueBill  - discountValueService) 
                }
            })
        } else {
            listPaymentMedthod = listSelectedPaymentType
        }
        let listService = convertListSelectedService();
        let listMedicine = listMedicineResult.filter(x => x.checked === true);
        let data = {
            PatientId : patient.maBN,
            ManagementId : managementId,
            VoucherId : (!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType !== LOYALTY_TYPE.CARD) ? selectedPromotion.id.toString() : '',
            CardId : (!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType === LOYALTY_TYPE.CARD) ? selectedPromotion.id.toString() : '',
            OTPConfirm : isOTPConfirm,
            TotalBill : (totalBillService + totalBillMedicine - discountValueService),
            DiscountBillValue : Number(discountValueBill),
            DiscountBillPercent : Number(discountPercentBill),
            IsPercent : isPercent,
            BillRemark : billRemark,
            Note: {
                ReasonCome: lyDoKham == lyDoKhamInit ? null : isNullOrEmpty(lyDoKham) ? null : lyDoKham,
                PhysicalExaminationNote: ghiChuKhamSucKhoe == ghiChuKhamSucKhoeInit ? null : isNullOrEmpty(ghiChuKhamSucKhoe) ? null : ghiChuKhamSucKhoe,
            },
            VATInvoice : vatInvoice,
            PaymentType : selectedPaymentType,
            ListPaymentMethod : listPaymentMedthod,
            ListServiceDetail : listService,
            ListMedicineBill : listMedicine
        }

        InvoiceService.SavePayment(data).then(result => {
            if (result.isSuccess) {
                if (registerSource == registerSourceEnum.COMPANY_EXAM) {
                    if (window.location.hostname.indexOf('localhost') > -1
                        || window.location.hostname.indexOf('192.168.10.59') > -1
                        || window.location.hostname.indexOf('192.168.10.45') > -1
                        || window.location.hostname.indexOf('inventory.mitalabvn.com') > -1
                    ) showConfirm();
                    else SentDataToLis(managementId);
                }
                if (getPageSetting("invoicePreview")) PrintListPatientPayment(result.data);
                ResetState();
                props.onSuccessPayment();
            } else {
                props.onError(result)
            }
        }).catch(err => {
            props.onError(err)
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const PrintListPatientPayment = (data) => {
        let banle = false
        PrintService.PrintPayment(data.billNumber, moment(data.paymentDate).format('YYYY-MM-DD'), data.managementId, banle).then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                return;
            } else {
                const template = Handlebars.compile(result.item.template);
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        })
    }

    const onSelectedPaymentType = (value) => {
        if(value === PAYMENTTYPE.CARD ){
            setLoyaltyType(LOYALTY_TYPE.CARD);
            openAppliedVoucher();
        }
        const rowsInput = [...listSelectedPaymentType];
        rowsInput[0].ma = value;
        setListSelectedPaymentType(rowsInput);
        setSelectedPaymentType(value);
    }

    const onSubmitSeperatePayment = (data) => {
        if(data && data !== null && data.length > 0){
            let listSelected = data.map(x => {
                return ({
                    ma : x.ma,
                    ten : listPaymentType.find(c => c.ma === x.ma)?.ten,
                    tongtien : x.amount
                })
            })
            setListSelectedPaymentType(listSelected)
        } else {
            setListSelectedPaymentType([INITIAL_PAYMENT])
        }
        setVisibleSeparatePayment(false);
        forceUpdate();
    }

    const isSeparate = () => {
        if(listSelectedPaymentType && listSelectedPaymentType.length > 1){
            let err = {
                    error : { messageText : 'Hóa đơn đã được phân bổ hình thức thanh toán. Vui lòng hủy phân bổ và thử lại.' }
                }
                props.onError(err);
                return true;
        }
        return false;
    }

    const SentDataToLis = (managementId) => {
        let data = { managementIds : managementId }
        LisService.SentDataToLis(data).then(result => {
            if (!result.isSuccess) {
                Actions.openMessageDialog("Lỗi", result.error.messageText)
            }
        }).catch(err => {
            Actions.openMessageDialog(err.error.messageText)
        })
    }

    const showConfirm = () => {
        confirm({
          title: 'Đã thanh toán thành công',
          icon: <ExclamationCircleFilled />,
          content: <>
                Đây là đăng ký được tạo từ "Khám sức khỏe công ty". Sau khi thanh toán sẽ tự động đẩy dữ liệu xét nghiệm xuống LIS.<br/>
                Tuy nhiên dường như bạn đang trong môi trường test, nên hãy lưu ý hostdms của site để tránh đẩy dữ liệu test xuống PRD của LIS.<br/><br/>
                <b>Bạn vẫn muốn đẩy dữ liệu xuống LIS?</b>
            </>,
          onOk() {
            SentDataToLis(managementId);
          },
          onCancel() {
          },
        });
      };

    const GetVATPayByMaql = (maql) => {
        AppointmentService.GetVATPayconfirmByMaql(maql, false).then(result => {
            if (result.isSuccess) {
                setVatInvoice(result.returnObject.vatinvoice);
                setIsPayConfirm(result.returnObject.payconfirm);
            }
        }).catch(err => {
        })
    }

    const renderService = (data) => {
        if (!data || data.length == 0) return "";
        let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
        return (
            data.map(record => {
                return (
                    <List.Item
                        key={`${'service' + record.idChiDinh}`}
                        className={className}>
                        <div className="d-flex flex-row justify-content-between w-100">
                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: 16 }}>
                                <Checkbox className="mr-2"
                                    checked={record.checked ?? false}
                                    onChange={(e) => { onChecked(e.target.checked, record, false) }}
                                    disabled={record.disableCheck}
                                    onClick={(e) => { e.stopPropagation(); }}
                                ></Checkbox>
                            </div>
                            <div className="d-flex flex-column justify-content-start h-100" style={{ width: '40%' }}>
                                <div>
                                    <label className="mita-value mr-2">{record.serviceName}</label>
                                    {record.groupId === COMBO && 
                                        <Popover trigger="hover" content = {() => renderComboService(record)}>
                                            <span><i className="fas fa-info-circle"></i></span>
                                        </Popover>
                                    }
                                </div>
                            </div>
                            <div className="d-flex justify-content-start h-100" style={{ width: 'calc(16% - 28px)' }}>
                                <span className="mita-value">{record.status}</span>
                            </div>
                            <div className="d-flex flex-row align-items-end justify-content-end h-100" style={{ width: '40%' }}>
                                <span className="mr-2">{record.price.toLocaleString('en-US')}</span>
                                { isNullOrEmpty(record.voucherCode) && isNullOrEmpty(record.cardId) &&
                                    <div className="mr-2">
                                    <Popover 
                                        content={
                                            <div style={{ width: 400 }}>
                                                <DiscountPayment 
                                                data={selectedService}
                                                onSubmit={(data) => onDiscountBill(data, false)} 
                                                onClose={() => { onCloseDiscount()} }/>
                                            </div>
                                        } 
                                        visible={visibleDiscount && selectedService.idChiDinh === record.idChiDinh && record.discountValue === 0}
                                        trigger="click">
                                            <Tag color="volcano" 
                                            onClick={() => {setSelectedService(record); setVisibleDiscount(true);}}>
                                                Miễn giảm: {record.discountValue > 0 ? Number(record.discountValue).toLocaleString('en-US') : '---'}
                                            </Tag>
                                        </Popover>
                                        {record.discountValue > 0 && <span><i className="fas fa-eraser anchor-color" onClick={() => { clearDiscountService(record, false) }}/></span>}
                                    </div>
                                }
                                { record.discountValuePromotion > 0 && 
                                    <div className="mr-2"><Tag color="green">CTKM: {record.discountValuePromotion.toLocaleString('en-US')}</Tag></div>
                                }
                                { record.discountValueCard > 0 && 
                                    <div className="mr-2"><Tag color="green">Thẻ: {record.discountValueCard.toLocaleString('en-US')}</Tag></div>
                                }
                            </div>
                        </div>
                    </List.Item>
            )})
        )
    }

    const renderComboService = (data) => {
        return (
            <div className='list-page' style={{ minWidth: "80vh" }}>
                <div className="list-page-header"> {/* header */}
                    <h5>Danh sách dịch vụ con của [{data.serviceName}]</h5>
                </div>
                <div className="list-page-body">{/* body */}
                    <div className="list-page-table">
                        <List className="none-border-bottom none-border-bottom-item"
                            bordered={false}
                            dataSource={data.listComboServiceDetail}
                            header={
                                <List.Item key={`header`} className='p-1'>
                                    <div className={`d-flex justify-content-between w-100 pl-2`}>{/* 1 item */}
                                        <div className="" style={{width: 'calc(50%)'}}>{/* column 1: tên - mã */}
                                            <span className={`mita-title`} style={{whiteSpace: "pre-line"}}>Tên dịch vụ</span>
                                        </div>
                                        <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                            <span className='mita-title' style={{whiteSpace: "pre-line"}}>Loại</span>
                                        </div>
                                        <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                            <span className='mita-title' style={{whiteSpace: "pre-line"}}>Trạng thái</span>
                                        </div>
                                    </div>
                                </List.Item>
                            }
                            renderItem={(item) => {
                                let className = "p-1 border-2 border-top";
                                return (
                                    <List.Item key={`${item.idChiDinh}`}
                                        className={className}
                                    >
                                        <div className={`d-flex justify-content-between w-100 pl-2`} key={`pr-${item.idChiDinh}`}>{/* 1 item */}
                                            <div className="" style={{width: 'calc(50%)'}}>{/* column 1: tên - mã */}
                                                <span className={`mita-value`} style={{whiteSpace: "pre-line"}}>{item.serviceName}</span>
                                            </div>
                                            <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                                <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.groupName}</span>
                                            </div>
                                            <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                                <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.status}</span>
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }}
                        >
                        </List>
                    </div>
                </div>
            </div>
        )
    }

    const onChangeGhiChu = (type) => {
        if (isNullOrEmpty(type)) return;
        if (type == "PhysicalExaminationNote") {
            if (ghiChuKhamSucKhoe == null)
                setGhiChuKhamSucKhoe(ghiChuKhamSucKhoeInit);
            if (ghiChuKhamSucKhoe == ghiChuKhamSucKhoeInit)
                setGhiChuKhamSucKhoe("");
        }
        if (type == "ReasonCome") {
            if (lyDoKham == null)
                setLyDoKham(lyDoKhamInit);
            if (lyDoKham == lyDoKhamInit)
                setLyDoKham("");
        }
    }

    return (
        <Fragment>
            <div className="list-page-body">
                <div className="h-100 overflow-auto">
                    {listServiceResult && listServiceResult.length === 0 && listMedicineResult && listMedicineResult.length === 0 &&
                        <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu"/>
                    }
                    {listServiceResult && listServiceResult.length > 0 && 
                    <>
                        <div> {/* header */}
                            <h5><i className="fas fa-briefcase-medical mx-2"></i>DỊCH VỤ ({listServiceResult.filter(x => x.checked).length})</h5>
                        </div>
                        <div className="list-page-table" style={{ height: 'fit-content' }}>
                            <List className="none-border-bottom none-border-bottom-item"
                                bordered={false}
                                dataSource={listGroupResult}
                                renderItem={(record) => {
                                    let className = "d-flex justify-content-start w-100 p-1 border-2 border-bottom py-2";
                                    return (
                                        <>
                                            <List.Item
                                                onClick={(e) => { }}
                                                key={`${'group' + record.groupId}`}
                                                className={className}
                                                style={{backgroundColor:"#D8D8D8"}}>
                                                <div className="mita-title">{record.groupName}</div>
                                            </List.Item>
                                            {renderService(listServiceResult.filter(x => x.groupId === record.groupId))}
                                        </> 
                                    )
                                }}
                            />
                        </div>
                    </>
                    }
                    {listMedicineResult && listMedicineResult.length > 0 && 
                    <>
                        <div className="mt-2"> {/* header */}
                            <h5><i className="fas fa-capsules mx-2"></i>THUỐC & VẬT TƯ ({listMedicineResult.filter(x => x.checked).reduce((count, item) => count + item.listMedicineDetail.length, 0)})</h5>
                        </div>
                        <div className="list-page-table" style={{ height: 'fit-content' }}>
                            <List className="none-border-bottom none-border-bottom-item"
                                bordered={false}
                                dataSource={listMedicineResult}
                                renderItem={(record) => {
                                    let className = "d-flex justify-content-start w-100 p-1 border-2 border-bottom py-2";
                                    return (
                                        <>
                                            <List.Item
                                                key={`${'medicine' + record.billId}`}
                                                className={className}>
                                                <div className="d-flex flex-column w-100">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width: 16 }}>
                                                            <Checkbox className="mr-2"
                                                                checked={record.checked ?? false}
                                                                onChange={(e) => { onChecked(e.target.checked, record, true) }}
                                                                disabled={record.disableCheck}
                                                                onClick={(e) => { e.stopPropagation(); }}
                                                            ></Checkbox>
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-start h-100" style={{ width:'calc(60% - 28px)' }}>
                                                            <div>
                                                                <label className="mita-title mr-2">Phiếu xuất: {record.receiptNumber}</label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row align-items-end justify-content-end h-100" style={{ width: '40%' }}>
                                                            <span className="mr-2">{record.totalBill.toLocaleString('en-US')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between ml-4">
                                                        {record.listMedicineDetail && record.listMedicineDetail.map(c => {
                                                            return (
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <div className="d-flex justify-content-start h-100" style={{ width: 16 }}></div>
                                                                <div className="d-flex justify-content-start h-100" style={{ width:'calc(55% - 28px)' }}>
                                                                    <span className="mita-value">{c.medicineName} {c.content}</span>
                                                                </div>
                                                                <div className="d-flex flex-row align-items-end justify-content-end h-100" style={{ width: '30%' }}>
                                                                    <span className="mr-2">{c.quantity} x {c.price.toLocaleString('en-US')}</span>
                                                                </div>
                                                                <div className="d-flex flex-row align-items-end justify-content-end h-100" style={{ width: '10%' }}>
                                                                    <span className="mr-3">{Number(c.quantity * c.price).toLocaleString('en-US')}</span>
                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </List.Item>
                                        </> 
                                    )
                                }}
                            />
                        </div>
                    </>
                    }
                </div>
                {((listServiceResult && listServiceResult.length > 0) || (listMedicineResult && listMedicineResult.length > 0)) && 
                    <div className="list-page-footer" style={{borderTopStyle : 'dashed'}}>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="d-flex flex-column overflow-hidden" style={{width : '60%'}}>{/* panel-left */}
                                {FeatureDeactive().indexOf(FEATURE.CTKM) == -1 &&
                                    <div className="w-100 d-flex flex-row justify-content-between align-items-baseline">
                                        <div className="d-flex flex-row" style={{flex : 2}}>
                                            <Radio.Group
                                                className="mr-2"
                                                disabled={!isObjectEmpty(selectedPromotion)}
                                                options={optionsLoyalty}
                                                onChange={(e) => {setLoyaltyType(e.target.value); openAppliedVoucher();}}
                                                value={loyaltyType}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                            { !isObjectEmpty(selectedPromotion) &&
                                                <Tooltip placement="top" title="Chương trình khuyến mãi không áp dụng cho thuốc/vật tư.">
                                                    <div className="align-self-center ml-1"><i className="fas fa-info-circle"></i></div>
                                                </Tooltip>
                                            }
                                        </div>
                                        {selectedPromotion && selectedPromotion.loyaltyType === LOYALTY_TYPE.CARD &&
                                            <Popover
                                                content={
                                                    <div style={{ width: 300, padding: 10 }}>
                                                        <OTPConfirm 
                                                            cardId={selectedPromotion.id} 
                                                            onSubmit={() => { setIsOTPConfirm(true) }} 
                                                            onClose={() => { setVisibleOTP(false) }}
                                                            />
                                                    </div>}
                                                trigger="click"
                                                placement="top"
                                                visible={visibleOTP}
                                                onVisibleChange={(e) => {setVisibleOTP(e)}}
                                            >
                                                <a href='#' onClick={() => { setVisibleOTP(true) }}><u>Gửi OTP</u></a>
                                            </Popover>
                                        }
                                    </div>
                                }
                                { !isObjectEmpty(selectedPromotion) 
                                    && (selectedPromotion.loyaltyType === LOYALTY_TYPE.VOUCHER || selectedPromotion.loyaltyType === LOYALTY_TYPE.SERVICE) &&
                                <>
                                    <div className="w-100 d-flex flex-row justify-content-between mt-1">
                                        <span>Áp dụng mã voucher <b>{selectedPromotion.voucherCode}</b> CTKM <b>{selectedPromotion.promotionName}</b> </span>
                                        {/* <a href="#"><u>Xem chi tiết</u></a> */}
                                    </div>
                                    <div className="w-100 d-flex flex-row justify-content-between mt-1">
                                        <span>Mức giảm: {selectedPromotion.isPercent ? selectedPromotion.discountValue + '%' : 'đ ' + selectedPromotion.discountValue.toLocaleString('en-US')} </span>
                                        <Button className="btn-danger" onClick={() => onClearPromotion()}>Không áp dụng</Button>
                                    </div>
                                </>
                                }
                                { !isObjectEmpty(selectedPromotion) 
                                    && selectedPromotion.loyaltyType === LOYALTY_TYPE.CARD &&
                                <>
                                    <div className="w-100 d-flex flex-row justify-content-between mt-1">
                                        <span>Áp dụng số thẻ <b>{selectedPromotion.voucherCode}</b> của <b>{selectedPromotion.accountName}</b> </span>
                                        {/* <a href="#"><u>Xem chi tiết</u></a> */}
                                    </div>
                                    <div className="w-100 d-flex flex-row justify-content-between mt-1">
                                        <span>Mức giảm: {selectedPromotion.discountValue + '%'} </span>
                                        <Button className="btn-danger" onClick={() => onClearCard()}>Không áp dụng</Button>
                                    </div>
                                </>
                                }
                                <Tabs defaultActiveKey="1" >
                                    <Tabs.TabPane tab="Ghi chú" key="BillRemark">
                                        <Input.TextArea 
                                            className="w-100"
                                            rows={3} 
                                            value={billRemark} 
                                            onChange={(e) => setBillRemark(e.target.value)}
                                        />
                                    </Tabs.TabPane>
                                    {
                                        lyDoKham != null &&
                                        <Tabs.TabPane tab="Lý do khám" key="ReasonCome">
                                            <Tooltip title="Ghi chú của bạn sẽ được thêm vào cùng với ghi chú đã có" placement="top">
                                                <Input.TextArea
                                                    className="w-100"
                                                    rows={3} placeholder={lyDoKhamInit}
                                                    onFocus={() => onChangeGhiChu("ReasonCome")}
                                                    value={lyDoKham}
                                                    onChange={(e) => setLyDoKham(e.target.value)}
                                                />
                                            </Tooltip>
                                        </Tabs.TabPane>
                                    }
                                    {
                                        ghiChuKhamSucKhoe != null &&
                                        <Tabs.TabPane tab="Ghi chú đăng ký" key="PhysicalExaminationNote">
                                            <Tooltip title="Ghi chú của bạn sẽ được thêm vào cùng với ghi chú đã có" placement="top">
                                                <Input.TextArea
                                                    className="w-100"
                                                    rows={3} placeholder={ghiChuKhamSucKhoeInit}
                                                    onFocus={() => onChangeGhiChu("PhysicalExaminationNote")}
                                                    value={ghiChuKhamSucKhoe}
                                                    onChange={(e) => setGhiChuKhamSucKhoe(e.target.value)}
                                                />
                                            </Tooltip>
                                        </Tabs.TabPane>
                                    }
                                    <Tabs.TabPane tab="Ghi nhận xuất hóa đơn đỏ" key="VATInvoice">
                                        <Checkbox className="mr-2"
                                            checked={vatInvoice}
                                            onChange={(e) => { 
                                                setVatInvoice(e.target.checked) 
                                                if(e.target.checked){
                                                    setVisibleVATInvoice(true)
                                                }
                                            }}
                                        >Ghi nhận xuất hóa đơn đỏ</Checkbox>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="CTV xác nhận thanh toán" key="CollaboratorConfirm">
                                        <Checkbox
                                            checked={isPayConfirm}
                                            disabled={true}
                                            >CTV xác nhận thanh toán</Checkbox>
                                        </Tabs.TabPane>
                                </Tabs>
                                {/* <span className="mr-2">Ghi chú</span>
                                <Checkbox className="mr-2"
                                    checked={vatInvoice}
                                    onChange={(e) => { 
                                        setVatInvoice(e.target.checked) 
                                        if(e.target.checked){
                                            setVisibleVATInvoice(true)
                                        }
                                    }}
                                >Ghi nhận xuất hóa đơn đỏ</Checkbox>
                                <Checkbox
                                    disabled={true}
                                    onChange={() => { }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                >CTV xác nhận thanh toán</Checkbox> */}
                            </div>
                            <div className="h-100 d-flex flex-row overflow-hidden" style={{width : '38%'}}>{/* panel-right */}
                                <div className="d-flex flex-column w-100">
                                    <Tag className="w-100 d-flex flex-row justify-content-between align-items-center p-1">
                                        <span>THÀNH TIỀN</span>
                                        <span><h4>đ {Number((totalBillService + totalBillMedicine) - discountValueService).toLocaleString('en-US')}</h4></span>
                                    </Tag>
                                    { (isObjectEmpty(selectedPromotion) || (!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType !== LOYALTY_TYPE.VOUCHER )) &&
                                        <Popover 
                                        content={
                                            <div style={{ width: 400 }}>
                                                <DiscountPayment 
                                                    data={{ price : ((totalBillService + totalBillMedicine) - discountValueService) }}
                                                    onSubmit={(data) => onDiscountBill(data)} 
                                                    onClose={() => onCloseDiscount() }/>
                                            </div>
                                        } 
                                        visible={visibleDiscount && isObjectEmpty(selectedService) && discountValueBill === 0}
                                        trigger="click">
                                            <Tag className="w-100 d-flex flex-row justify-content-between align-items-center mt-1 p-1" 
                                                color="volcano" 
                                                onClick={() => {setVisibleDiscount(true);}}>
                                                <span>MIỄN GIẢM</span>
                                                { (isObjectEmpty(selectedPromotion) || (!isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType !== LOYALTY_TYPE.VOUCHER ))  
                                                    && discountValueBill > 0 && 
                                                    <i className="fas fa-eraser anchor-color ml-2" onClick={(e) => { clearDiscountBill(); e.stopPropagation()}}></i>
                                                }
                                                <span><h4>{isPercent === true && `(${discountPercentBill} %) `} đ {Number(discountValueBill).toLocaleString('en-US')}</h4></span>
                                            </Tag>
                                        </Popover>
                                    }
                                    { !isObjectEmpty(selectedPromotion) && selectedPromotion.loyaltyType === LOYALTY_TYPE.VOUCHER &&
                                        <Tag className="w-100 d-flex flex-row justify-content-between align-items-center mt-1 p-1" color="green">
                                            <span>CTKM</span>
                                            <span><h4>{isPercent === true && `(${discountPercentBill} %) `} đ {Number(discountValueBill).toLocaleString('en-US')}</h4></span>
                                        </Tag>
                                    }
                                    <Tag className="w-100 d-flex flex-row justify-content-between align-items-center mt-1 p-1" color="blue">
                                    {(listSelectedPaymentType && listSelectedPaymentType.length > 1) ? 
                                        <span className="mita-value">
                                            {listSelectedPaymentType.reduce((accumulator, item) => {
                                                accumulator.push(item.ten)
                                                return accumulator;
                                            } , []).join(', ')}
                                        </span>
                                    :
                                        <Select
                                            className="w-50"
                                            placeholder="Chọn trạng thái"
                                            disabled={disableSelectPayment}
                                            value={selectedPaymentType}
                                            onChange={(value) => { onSelectedPaymentType(value); }}
                                        >
                                            {listPaymentType.map(x => (
                                                <Select.Option key={x.ma} value={x.ma}>{x.ten}</Select.Option>
                                            ))}
                                        </Select>
                                    }
                                    { selectedPaymentType !== PAYMENTTYPE.CONGNO && selectedPaymentType !== PAYMENTTYPE.CARD &&
                                        <Popover
                                            content={
                                                <div style={{ width: 400, padding: 10 }}>
                                                    <SeparatePayment
                                                        isPayment = {true}
                                                        isChangePayment={false}
                                                        data = {{
                                                            listSelected : listSelectedPaymentType,
                                                            totalBill : Number((totalBillService + totalBillMedicine) - discountValueBill  - discountValueService)
                                                        }}
                                                        onSubmit = {(data) => { onSubmitSeperatePayment(data)}}
                                                        onClose={() => setVisibleSeparatePayment(false)}
                                                        showCancel={true} />
                                                </div>}
                                            trigger="click"
                                            placement="top"
                                            visible={visibleSeparatePayment}
                                            onVisibleChange={(e) => setVisibleSeparatePayment(e)}
                                        >
                                            <Tooltip title="Phân bổ hình thức thanh toán">
                                                <div className="iconButton anchor-color" onClick={() => setVisibleSeparatePayment(true)}><i className="fas fa-sitemap"></i></div>
                                            </Tooltip>
                                        </Popover>
                                    }
                                        <span><h4>đ {Number((totalBillService + totalBillMedicine) - discountValueBill  - discountValueService).toLocaleString('en-US')}</h4></span>
                                    </Tag>
                                    <Popconfirm
                                        title="Xác nhận thanh toán"
                                        onConfirm={() => {SavePayment()}}
                                        okText="Đồng ý"
                                        cancelText="Hủy"
                                        disabled={managementId === ''}>
                                        <Button className="btn-color mt-2">THANH TOÁN</Button>
                                    </Popconfirm>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Drawer 
                placement='right'
                key={'vat_invoice'}
                closable={false}
                visible={visibleVATInvoice}
                width={'40%'}
                className='custom-ant-drawer'
                maskClosable={false}
                destroyOnClose={true}
            >
                <HeaderModal title="Thông tin phiếu xuất hóa đơn đỏ" onClose={() => setVisibleVATInvoice(false)} />
                <CustomerVATInfo disableChange={false} info={{ name: patient.hoTen, maql: managementId }}
                    onClose={() => setVisibleVATInvoice(false)} />
            </Drawer>

            <Drawer 
                placement='right'
                key={'AppliedVoucher'}
                closable={false}
                visible={visiblePromotion}
                width={'40%'}
                className='custom-ant-drawer'
                maskClosable={false}
                destroyOnClose={true}
            >
                <AppliedVoucher
                    loyaltyType = {loyaltyType} //Type = 0 là voucher, Type = 1 là dịch vụ
                    isView = {false}
                    patient={{...patient, mabn : patient.maBN}} 
                    listSelectedServices={listSelectedServices}
                    selectedPromotion={selectedPromotion}
                    onCancel={() => { setVisiblePromotion(false); setLoyaltyType(null) }}
                    onSubmit={(promotion) => submitVoucher(promotion)} 
                    />
            </Drawer>
        </Fragment>
    )
});