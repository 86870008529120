import React, { Component } from 'react';
import { Tabs } from 'antd';
import BenhAn from './benhan/index';
import PhieuTraThuoc from './quanlythuoc/phieutrathuoc/index';
import PhieuLanhThuoc from './quanlythuoc/phieulanhthuoc/index';
const { TabPane } = Tabs
class exam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '2',
        };
    }
    render() {
        return (
            <React.Fragment>
                <Tabs className='tab-header-services tab-header-exam' centered={true} defaultActiveKey={'2'}
                    activeKey={this.state.activeKey}
                    onChange={(e) => { this.setState({ activeKey: e }) }}
                >
                    {/* <TabPane tab={"Duyệt nhập khoa"} key={'1'}>
                    </TabPane> */}
                    <TabPane tab={"Bệnh án"} key={'2'} >
                        <BenhAn
                            active={this.state.activeKey === '2'}
                        />
                    </TabPane>
                    <TabPane tab={"Phiếu lãnh thuốc"} key={'3'}>
                        <PhieuLanhThuoc
                            active={this.state.activeKey === '3'}
                        />
                    </TabPane>
                    <TabPane tab={"Phiếu trả thuốc"} key={'4'}>
                        <PhieuTraThuoc
                            active={this.state.activeKey === '4'}
                        />
                    </TabPane>
                </Tabs>
            </React.Fragment>
        );
    }
}

export default exam;