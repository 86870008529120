import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useImperativeHandle, useCallback, forwardRef } from 'react';
import { Button, Table,  Modal, Pagination, notification, Input, Select, Tooltip, Popconfirm } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import * as TemplateVoucherService from '../../promotion/templatevoucher/templateVoucherServices';
import * as PromotionService from '../../../services/PromotionServices';
import * as PromotionVoucherService from '../../../services/PromotionVoucherServices';
import Notify, { NotifyStatus } from '../../../components/notify';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const notiRef = useRef(null);
// function VoucherList(props) {
  //#region khai báo state
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //state filterModel
  const [promotionId, setPromotionId] = useState(props.promotionId);
  const [voucherId, setVoucherId] = useState(props.voucherId);
  const [patients, setPatients] = useState([]);
  const [billIds, setBillIds] = useState([]);
  const [usedDateFrom, setUsedDateFrom] = useState(null);
  const [usedDateTo, setUsedDateTo] = useState(null);
  const [keyword, setKeyword] = useState('');
  //list model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  
  //#region useEffect
  useEffect(() => {
    search();
  }, []);
  
  useEffect(() => {
    search();
  }, [pageIndex, sorting])

  //#endregion 
  const reset = () => {
    notiRef && notiRef.current.Close();
    validator && validator.current.hideMessages();
    forceUpdate();
  }

  //#region search & filter & call service
  const searchToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }
  const search = () => {
    let filterModel = {
      promotionId: promotionId,
      voucherId: voucherId,
      patients: patients,
      billIds: billIds,
      usedDateFrom: usedDateFrom,
      usedDateTo: usedDateTo,
      keyword: keyword,

      maxResultCount: pageSize, 
      skipCount: (pageIndex-1)*pageSize, 
      sorting: sorting
    }

    if (!filterModel.promotionId || filterModel.promotionId == '') return;
    //get list voucher
    Actions.setLoading(true);
    PromotionVoucherService.getListHistory(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
      }
      else {
        notification.error({ message: result.error.messageText });
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notification.error({ message: err.error.messageText });
      else notification.error({ message: err });
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 40,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      {
          title: 'Mã voucher',
          dataIndex: 'voucherCode',
          key: 'voucherCode',
          align: 'left',
          width: 150,
      },
      {
          title: 'Mã khách hàng',
          dataIndex: 'patientCode',
          key: 'patientCode',
          align: 'left',
          width: 130,
          sorter: () => {},
          // render: (value, row) => {
          //     return <span>{(row.patientCode ?? '') + ' - ' + (row.patientName ?? '')}</span>
          // }
      },
      {
          title: 'Tên khách hàng',
          dataIndex: 'patientName',
          key: 'patientName',
          align: 'left',
          sorter: () => {},
          // width: 250,
          // render: (value, row) => {
          //     return <span>{(row.patientCode ?? '') + ' - ' + (row.patientName ?? '')}</span>
          // }
      },
      {
          title: 'Ngày sử dụng',
          dataIndex: 'usedDate',
          key: 'usedDate',
          align: 'left',
          width: 130,
          sorter: () => {},
          render: (value, row) => {
              return <span>{moment(row.usedDate).format(SystemConfig.FORMAT_DATE_TIME)}</span>
          }
      },
      // {
      //     title: 'Giá trị',
      //     dataIndex: 'usedDate',
      //     key: 'usedDate',
      //     align: 'left',
      //     // width: 250,
      //     render: (value, row) => {
      //         return <span>{moment(row.usedDate).format(SystemConfig.FORMAT_DATE_TIME)}</span>
      //     }
      // },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //event click close button
  const closeButtonHandle = () => {
    props.onCancel();
  }
  //#endregion 

  return (
    <React.Fragment>
    <div className='h-100 d-flex flex-column justify-content-between' >
        <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>Lịch sử sử dụng mã khuyến mại</h3>
            <h3 hidden={ !props.isDialog }
                onClick={() => { props.onCancel() }}
                style={{ cursor: 'pointer' }}
            ><i className='far fa-times-circle' /></h3>
        </div>
        <div className='overflow-auto max-h-100 h-100 px-3 d-flex flex-column justify-content-start'>{/* body */}
            <div className='d-flex flex-column justify-content-start'> {/* Info of Promotion */}
                <Notify ref={notiRef} />
                <div className="row">
                    <div className="col-md-12 mt-2">{/* Tên chương trỉnh */}
                      <Table 
                        dataSource={listResult} 
                        columns={columns}
                        locale={{ emptyText: 'Không có dữ liệu' }} 
                        scroll={{ x: 'max-content', y: 0 }}
                        rowKey={(e) => e.id}
                        // rowSelection={rowSelection}
                        pagination={false}
                        onChange={onTableChange}
                      />
                      <Pagination
                          current={pageIndex}
                          size={pageSize}
                          style={{ textAlignLast: "center", marginTop: "10px" }}
                          total={totalRecord}
                          showTotal={total => `Tổng: ${total}`}
                          onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                          onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                          showSizeChanger={true}
                      />
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-between mt-1">{/* footer */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button
                  className='btn btn-danger'
                  onClick={() => { closeButtonHandle(); }}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
                </button>
            </div>
        </div>
    </div>
    </React.Fragment>
  );
})
