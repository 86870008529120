import { Button, Input, Table, Statistic, Select, InputNumber, Modal, notification, Form } from 'antd';
import React, { Component } from 'react';
import * as Actions from '../../libs/actions';
import * as ChiDinhService from '../../services/chiDinhService';
import * as PackgeService from '../../services/packageService';
const { Option } = Select;
export default class modalPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            soluong: 1,
            lstLoaiChiDinh: [],
            loaiChiDinh: '',
            lstChiDinh: [],
            chiDinh: '',
            department: '',
            cardNumber: '',
            lstRooms: [],
            departmentName: '',
            subjectName: '',
            selectedType: 3,
            selectedServiceID: '',
            listServices: [],
            listSelectedServices: [],
            searchName: '',
            tongtienNew: 0,
            listPatientPackage: [],
            packageid: 0,
            key: '',
            listPackages: [],
            listPackagesItem: [],
            lstDept: [],
            yourPackageID: 0,
            selectItemPackageID: 0,
        }

    }
    DanhSachPKBenh() {
        ChiDinhService.DanhSachPKBenh().then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    lstRooms: respone.data,
                });
            }

        })
    }
    getPackage() {
        Actions.setLoading(true);
        PackgeService.getListPackage(1, 99999, '', this.state.key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    listPackages: result.data,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Error', err)
        })
    }


    reset() {
        this.setState({
            soluong: 1,
            lstLoaiChiDinh: [],
            loaiChiDinh: '',
            lstChiDinh: [],
            chiDinh: '',
            department: '',
            cardNumber: '',

            departmentName: '',
            subjectName: '',

            selectedType: 0,

            selectedServiceID: '',
            listServices: [],
            listSelectedServices: [],
            searchName: '',
            tongtienNew: 0,
            listPatientPackage: [],


            //listPackages: [],
            listPackagesItem: [],

            yourPackageID: 0,
            selectItemPackageID: 0,
        });
    }
    getListChiDinh() {
        return this.state.lstChiDinhBenhNhan;
    }

    handleCancel = e => {
        if (this.props.setVisibleCLS)
            this.props.setVisibleCLS(false);
    };


    componentDidMount() {
        this.getPackage();
        this.getPackagePatient(this.props.patientID);
        this.DanhSachPKBenh();

    }

    shouldComponentUpdate(nextProps) {
        // if (nextProps.patientID !== this.props.patientID)
        //    this.getPackagePatient(nextProps.patientID);
        return true;
    }
    getpackage() {
        this.getPackagePatient(this.props.patientID);
    }

    DanhSachPhongKhamTheoLoai() {
        ChiDinhService.DanhSachPhongKhamTheoLoai().then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    lstDept: respone.data,
                });
            }
        })
    }

    getPackagePatient(patientID) {
        this.getPackage();
        if (patientID) {
            PackgeService.getPackage(patientID).then((respone) => {
                if (respone.isSuccess) {
                    this.setState({
                        listPatientPackage: respone.data,
                    });
                }
            }).catch((err) => {
            })
        }
    }

    XoaChiDinh(record) {
        if (record.type === 3) {
            if (record.packagepatientid === "") {
                let lst = this.state.listSelectedServices.filter(x => !(x.idvp === record.idvp && x.packageid === record.packageid && x.packagepatientid === ""))
                this.setState({
                    listSelectedServices: lst.map(d => ({ ...d }))
                })
            } else {
                let lst = this.state.listSelectedServices.filter(x => !(x.idvp === record.idvp && x.packagepatientid === record.packagepatientid))
                this.setState({
                    listSelectedServices: lst.map(d => ({ ...d }))
                })
            }
        }
        else {
            let lst = this.state.listSelectedServices.filter(x => !(x.packageid === record.packageid && (x.packagepatientid === "" || x.type === 1)));
            let listPatientPackage = this.state.listPatientPackage.filter(x => !(x.id === 0 && x.packageid.toString() === record.packageid.toString()));
            let tongtienNew = 0;
            for (let item of listPatientPackage) {
                if (item.type === 1)
                    tongtienNew += item.price;
            }
            this.setState({
                tongtienNew,
                listSelectedServices: lst.map(d => ({ ...d })),
                listPatientPackage: listPatientPackage.map(d => ({ ...d }))
            });
            if ((this.state.yourPackageID === 0 && this.state.packageid.toString() === record.packageid.toString()) || listPatientPackage.length === 0) {
                this.setState({ listPackagesItem: [] });
            }
        }
    }

    AddService() {
        let { listServices, listSelectedServices, selectedServiceID } = this.state
        let item = listServices.filter(record => record.id === selectedServiceID)[0];
        item.mathuoc = item.value
        let olditems = listSelectedServices.filter(record => record.id === selectedServiceID);
        if (olditems.length > 0) {
            Actions.openMessageDialog("Error", "Dịch vụ đã được chọn");
            return;
        } else {
            item.type = 3;
            listSelectedServices.push(item);
        }
        for (let item of listSelectedServices) {
            item.isNew = false;
            if (item.id === selectedServiceID) item.isNew = true;
        }
        this.setState({ listSelectedServices, selectedServiceID: '' })
    }

    addExamServiceWithoutRoom() {
        let { listPackagesItem, selectItemPackageID, selectedServiceID, lstRooms, selectedRoom, listSelectedServices } = this.state
        let olditems = listSelectedServices.filter(record => record.id === selectItemPackageID);
        if (olditems.length > 0) {
            Actions.openMessageDialog("Error", "Dịch vụ đã được chọn");
            return;
        }
        let item = listPackagesItem.filter(record => record.id === selectItemPackageID)[0];
        let phongkham = lstRooms.filter(record => record.value === selectedRoom)[0];
        item.discount = 0;
        item.dongia = item.price;
        item.soluong = 1;
        item.type = 3;
        item.mavp = item.idvp.toString();
        if (phongkham) {
            item.makp = phongkham.departmentID
            item.maphongkham = phongkham.value
            item.phongkham = phongkham.label
        }
        switch (item.groupId) {
            case 1:
                item.phongkham = "Phòng phẫu thuật thủ thuật";
                break;
            case 2:
                item.phongkham = "Phòng xét nghiệm";
                break;
            case 3:
                switch (item.typeId) {
                    case 4:
                        item.phongkham = "Phòng Thăm dò chức năng";
                        break;
                    case 1:
                        item.phongkham = "Phòng siêu âm";
                        break;
                    case 3:
                        item.phongkham = "Phòng X-QUANG";
                        break;
                    case 2:
                    case 32:
                        item.phongkham = "Phòng nội soi";
                        break;
                    case 17:
                        item.phongkham = "Phòng nội sản";
                        break;
                    default:
                }
                break;
            case 4:
                item.phongkham = "Phòng cấp cứu";
                break;
            case 5:
                item.phongkham = "Phòng đông y";
                break;
            default:
        }
        listSelectedServices.push(item);
        let tongtienNew = 0;
        for (let item of listSelectedServices) {
            item.isNew = false;
            if (item.id === selectedServiceID) item.isNew = true;
            if (item.price)
                tongtienNew += item.soluong * item.price * ((100 - item.discount) / 100);
        }
        this.setState({ tongtienNew, listSelectedServices, selectedServiceID: '', chooseRoomFLag: false, selectedRoom: null })
    }
    AddPackage(data) {
        let { listPackages, listSelectedServices, yourPackageID, listPatientPackage } = this.state
        let item = listPackages.filter(record => record.id.toString() === data.id.toString() && record.packageid.toString() === data.packageid.toString())[0];
        item.mathuoc = item.value
        let olditems = listSelectedServices.filter(record => record.id.toString() === data.id.toString() && record.packageid.toString() === data.packageid.toString());
        if (olditems.length > 0) {
            Actions.openMessageDialog("Error", "Dịch vụ đã được chọn");
            return;
        } else {
            item.type = 1;
            item.soluong = 1;
            item.discount = 0;
            item.dongia = item.price;
            item.makp = '';
            item.maphongkham = '';
            item.phongkham = '';
            item.isNew = true;
            item.id = 0;
            item.packageid = item.packageid.toString();
            listSelectedServices.push(item);
            listPatientPackage.push(item);
            // this.setState({ yourPackageID: item.id });
            this.getListPackageItems(item.packageid, true, 0);
        }
        let tongtienNew = 0;
        for (let item of listPatientPackage) {
            item.isNew = false;
            if (item.id === data.id)
                item.isNew = true;
            if (item.price)
                tongtienNew += item.price;
        }
        this.setState({ tongtienNew, listSelectedServices }, () => {
            this.getListPackageItems(item.packageid, item.isNew === true, item.id);
        })
    }

    AddItemPackage() {
        let { listPackagesItem, listSelectedServices, selectItemPackageID, lstRooms } = this.state
        let item = {}
        let olditems = listSelectedServices.filter(record => (record.id === selectItemPackageID && record.type === 3));
        if (olditems.length > 0) {
            Actions.openMessageDialog("Error", "Dịch vụ đã được chọn");
            return;
        } else {
            for (let i = 0; i < listPackagesItem.length; i++) {
                if (listPackagesItem[i].id === selectItemPackageID) {
                    item = listPackagesItem[i];
                    if (item.times <= item.current_times) {
                        Actions.openMessageDialog("Error", `Dịch vụ '${item.name}' đã hết lượt khám!`);
                        return;
                    }
                    //listPackagesItem[i].current_times++;
                    break;
                }
            }
            item.packagepatientid = this.state.yourPackageID === 0 ? '' : this.state.yourPackageID.toString();
            item.dongia = 0;//item.newPrice;
            item.discount = 0;
            item.soluong = 1;
            item.price = 0;//item.newPrice;
            item.typeName = "Dịch vụ gói khám";
            item.type = 3;
            if (item.groupId === 8) {
                let temp = lstRooms.filter(record => record.mavp === item.idvp.toString());
                if (temp.length > 0) {

                    item.makp = temp[0].departmentID
                    // item.phongkham = temp[0].label
                    // item.maphongkham = temp[0].roomCode
                } else {
                    item.makp = '002'
                    // item.phongkham = 'Phòng khám nội'
                    // item.maphongkham = '002'
                    // this.setState({ chooseRoomFLag: true })
                    // return;
                }
            }
            switch (item.groupId) {
                case 1:
                    item.phongkham = "Phòng phẫu thuật thủ thuật";
                    break;
                case 2:
                    item.phongkham = "Phòng xét nghiệm";
                    break;
                case 3:
                    switch (item.typeId) {
                        case 4:
                            item.phongkham = "Phòng Thăm dò chức năng";
                            break;
                        case 1:
                            item.phongkham = "Phòng siêu âm";
                            break;
                        case 3:
                            item.phongkham = "Phòng X-QUANG";
                            break;
                        case 2:
                        case 32:
                            item.phongkham = "Phòng nội soi";
                            break;
                        case 17:
                            item.phongkham = "Phòng nội sản";
                            break;
                        default:
                    }
                    break;
                case 4:
                    item.phongkham = "Phòng cấp cứu";
                    break;
                case 5:
                    item.phongkham = "Phòng đông y";
                    break;
                default:
            }
            listSelectedServices.push(item);
        }

        this.setState({ listSelectedServices, selectItemPackageID: 0, listPackagesItem: listPackagesItem.map(d => ({ ...d })) })
    }

    AddAllItemPackage() {
        let { listPackagesItem, listSelectedServices, lstRooms } = this.state
        const itemsAdd = listPackagesItem.filter(({ id }) => !listSelectedServices.some(x => x.id === id))
        if (itemsAdd && itemsAdd.length > 0) {
            for (let i = 0; i < itemsAdd.length; i++) {
                let item = {}
                for (let j = 0; j < listPackagesItem.length; j++) {
                    if (listPackagesItem[j].id === itemsAdd[i].id) {
                        item = listPackagesItem[j];
                        if (item.times <= item.current_times) {
                            Actions.openMessageDialog("Error", `Dịch vụ '${item.name}' đã hết lượt khám!`);
                            return;
                        }
                        //listPackagesItem[j].current_times++;
                        item.packagepatientid = this.state.yourPackageID === 0 ? '' : this.state.yourPackageID.toString();
                        item.dongia = 0;//item.newPrice;
                        item.discount = 0;
                        item.soluong = 1;
                        item.price = 0;//item.newPrice;
                        item.typeName = "dịch vụ gói khám";
                        item.type = 3;
                        if (item.groupId === 8) {
                            let temp = lstRooms.filter(record => record.mavp === item.idvp.toString());
                            if (temp.length > 0) {
                                item.makp = temp[0].departmentID
                                //item.phongkham = temp[0].label
                                //item.maphongkham = temp[0].roomCode
                            } else {
                                item.makp = '002'
                                //item.phongkham = 'Phòng khám nội'
                                //item.maphongkham = '002'
                            }
                        }
                        listSelectedServices.push(item);
                        break;
                    }
                }
            }
            this.setState({ listSelectedServices, selectItemPackageID: 0, listPackagesItem: listPackagesItem.map(d => ({ ...d })) })
        }
    }

    getListPackageItems(packageID, boolIsNew = false, patientPackageId = 0) {
        PackgeService.getPackageItems(this.props.patientID, packageID, boolIsNew, patientPackageId).then((respone) => {
            if (respone.isSuccess) {
                for (let item of respone.data) {
                    item.packageid = packageID.toString();
                }
                this.setState({
                    listPackagesItem: respone.data
                }, () => {
                    //this.AddAllItemPackage(); 
                });
            }
        })
    }


    editAmount(row, soluong) {

        let { listSelectedServices } = this.state
        let index = listSelectedServices.findIndex(record => record.id === row.id);
        listSelectedServices[index].soluong = soluong;
        let tongtienNew = 0;

        for (let item of listSelectedServices) {
            if (item.price)
                tongtienNew += item.soluong * item.dongia * ((100 - item.discount) / 100);
        }
        this.setState({ listSelectedServices, tongtienNew })
    }
    render() {
        let { listServices, listSelectedServices, listPatientPackage, listPackages, listPackagesItem, yourPackageID } = this.state;

        const packageItemColumn = [
            // {
            //     title: 'TT',
            //     dataIndex: 'index',
            //     key: 'index',
            //     render: (text, record, index) => Number(index) + 1,
            // },
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Giá',
                dataIndex: 'newPrice',
                key: 'newPrice',
                align: 'right',
                render: (value) => (<span>{value.toLocaleString('en-US')}</span>)
            },
            {
                title: 'Lần khám',
                dataIndex: 'current_times',
                key: 'current_times',
            },
            {
                title: 'Tổng số lần',
                dataIndex: 'times',
                key: 'times',
            },

        ];

        const columns2 = [
            // {
            //     title: 'TT',
            //     dataIndex: 'index',
            //     key: 'index',
            //     render: (text, record, index) => Number(index) + 1,
            // },
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Số ngày',
                dataIndex: 'expiry_time',
                key: 'expiry_time',
            },
            {
                title: 'giá tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => (<span>{value?value.toLocaleString('en-US'):''}</span>)
            }
        ];

        const columns = [
            {
                title: 'Tên Dịch vụ',
                dataIndex: 'name',
                key: 'name',
                width:240
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => (<span>{value.toLocaleString('en-US')}</span>)
            },
            {
                title: 'SL',
                render: (row) => (<InputNumber disabled={true} style={{ width: '60px' }} value={row.soluong} defaultValue={1} min={1} maxLength={2} onChange={(e) => { this.editAmount(row, e) }} />)
            },
            {
                title: 'Thành tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value, row) => (<span>{(value * row.soluong).toLocaleString('en-US')}</span>)
            },
            // {
            //     title: 'Phòng khám',
            //     dataIndex: 'phongkham',
            //     key: 'phongkham',
            // },
            {
                title: '',
                align: 'center',
                render: (row, index) => {
                    return (
                        <Button type="primary" danger onClick={() => { this.XoaChiDinh(row) }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i>  </span>
                        </Button>
                    )
                }
            }
        ];

        let dataSource = listServices.map(function (e, index) {
            let o = Object.assign({}, e);

            o.key = o.id
            return o;
        })

        let dataSourceNew = listSelectedServices.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })


        let packages = listPackages.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })


        let packagesItem = listPackagesItem.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = o.id
            return o;
        })
        let { selectedType } = this.state;
        return (
            <React.Fragment>
                <div style={{ height: '100%', display: 'flex', marginBottom: 20, marginTop: 20 }}>
                    <div style={{ width: '55%', marginRight: 20 , height:'100%'}} >
                        <div className="warrperStyleGroup" style={{ height: '48%', marginBottom: 20 }}>
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>GÓI KHÁM ĐÃ CHỌN</h4>
                            </div>
                            <div style={{ flex: 1, display: 'flex', height:'100%' }}>
                                {listPatientPackage && listPatientPackage.length > 0 &&
                                    <div style={{ fontSize: 12, width: 120, marginRight: 10 }}>
                                        {listPatientPackage.map((record, index) => {
                                            return (
                                                <div key={index} style={{
                                                    padding: "10px 5px", border: "1px solid rgb(232,232,232)", cursor: 'pointer'
                                                    , backgroundColor: (record.packageid === this.state.packageid && record.id === yourPackageID) ? '#293555' : 'white',
                                                    color: (record.packageid === this.state.packageid && record.id === yourPackageID) ? 'white' : 'black'
                                                }}
                                                    onClick={() => {
                                                        this.setState({ yourPackageID: record.id, packageid: record.packageid });
                                                        this.getListPackageItems(record.packageid, record.isNew === true, record.id);
                                                    }}> {record.name}</div>
                                            )
                                        })
                                        }
                                    </div>
                                }
                                <div style={{ flex: 1, width: 'calc(100% - 130px)', height:'100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type="primary" style={{ alignSelf: 'flex-end', height: 28 }}
                                            onClick={() => {
                                                this.AddAllItemPackage();
                                            }} disabled={this.state.yourPackageID === 0 && this.state.packageid === 0}><i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm Tất cả</Button>
                                        <Button type="primary" style={{ height: 28, display: 'none' }}
                                            onClick={() => {
                                                this.AddItemPackage();
                                            }} disabled={this.state.selectItemPackageID === 0}>  <i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm</Button>
                                    </div>
                                    <div style={{height:'calc(100% - 30px)'}}>
                                        <Table
                                            scroll={{ y: 0 }}
                                            dataSource={packagesItem} rowKey={(e, index) => index}
                                            columns={packageItemColumn}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: e => { this.setState({ selectItemPackageID: record.id }, () => this.AddItemPackage()) }
                                                };
                                            }}
                                            pagination={false}
                                            rowClassName={(record, rowIndex) => {
                                                return `rowCustomSelect ${(this.state.selectItemPackageID === record.id) ? 'rowCustomSelectActive' : ''}`
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="warrperStyleGroup" style={{ height: '50%' }}>
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>GÓI KHÁM</h4>
                            </div>

                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button type="primary" style={{ alignSelf: 'flex-end', height: 28, display: 'none' }}
                                    onClick={() => {
                                        this.AddPackage();
                                    }} disabled={this.state.yourPackageID === 0}><i className="fa fa-plus" />Thêm</Button>
                                <div style={{ width: '200px', display: 'none' }}>
                                    <Input placeholder='Nhập tên gói khám'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                this.getPackage();
                                            }
                                        }}
                                    />
                                </div>
                                <Button style={{ display: 'none' }} type='primary' onClick={() => this.getPackage()} ><i className='fa fa-search'></i> Tìm</Button>
                            </div>
                            <div className="table-responsive" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                <Table
                                    scroll={{ y: 0 }}
                                    dataSource={packages} rowKey={(e, index) => index} columns={columns2} pagination={false}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: e => {
                                                this.setState({ yourPackageID: 0, packageid: record.packageid }, () => {
                                                    this.AddPackage(record);
                                                })
                                            }
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        return `rowCustomSelect`
                                    }} />
                            </div>
                        </div>
                    </div>

                    <div className="warrperStyleGroup" style={{ width: '44%' }}>
                        <div className="grouplabel">
                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>DỊCH VỤ ĐÃ CHỌN</h4>
                        </div>
                        <div className="grouplabelRight">
                            <div style={{ flex: 1, textAlign: 'right', marginRight: '40px' }}>Tổng Tiền :<b>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</b></div>
                        </div>
                        <Table
                            scroll={{ y: 0 }}
                            dataSource={dataSourceNew} rowKey={(e, index) => index} columns={columns} pagination={false}

                            rowClassName={(record) => {

                                return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                            }}
                        />
                    </div>
                </div>
                <div className="text-center">
                    <button className="btn btn-color" style={{ marginRight: 5 }}
                        onClick={() => { this.props.onClose(this.state.listSelectedServices) }}>
                        Xác nhận</button>
                    <button className="btn btn-danger" style={{ marginLeft: 5 }}
                        onClick={() => { this.setState({ listSelectedServices: [] }, () => { this.props.onClose([]) }) }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng</button>
                </div>
                <Modal maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={this.state.chooseRoomFLag}
                    width={700} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 50 }}>
                        <Form.Item label="Phòng khám" required={true}>
                            <Select value={this.state.selectedRoom} style={{ width: '200px' }} onChange={(e) => {
                                this.setState({ selectedRoom: e })
                            }}>
                                {this.state.lstRooms.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>

                            <button className="btn btn-success"
                                onClick={() => {
                                    if (this.state.selectedRoom === null) {
                                        notification.warning({ message: "Vui lòng chọn phòng khám !" })
                                        return
                                    }
                                    this.addExamServiceWithoutRoom();

                                }}
                            > <i style={{ marginRight: 5 }}

                                className="fa fa-plus" />Lưu</button>

                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() =>
                                    this.setState({ chooseRoomFLag: false })}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
