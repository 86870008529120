import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../components/notify';
import { connect } from 'react-redux';
import HeaderModal from "../../../configureHIS/common/headerModal";
import PatientInfo from "../../../screens/patient/component/patientInfo";
import VitalSignInfo from "../../../screens/patient/component/vitalSign";
import History from '../../../components/history/indexNew';
import MutilMedia from '../../../components/MitaComponents/UploadFile/mitaUploadFile';
import { Collapse, Drawer, Input, Select, Modal } from 'antd';
import { HISTORYFEATURE, HISTORYFEATURETYPE } from '../../../configureHIS/common/constants';
import SlideShow from './Component/slideShow';
import Speaker from '../../../components/speaker';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../../libs/suneditor-vi-lang';
import moment from 'moment';
import * as PacsService from '../../../services/pascService';
import * as CommonService from '../../../services/commonService';
import Schedule from '../../lichhen/schedule';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import { IsActive_FeatureLH } from '../../../configure/featureConfig';
import CameraPACSViewer from './Component/cameraPACSViewer';

const { Panel } = Collapse;
const { Option } = Select;

const PACSEdit = forwardRef((props, ref) => {
    const _uploadRef = useRef();
    const [data, setData] = useState(props.data);
    const [visibleSlideShow, setVisibleSlideShow] = useState(false);
    const [chanDoan, setChanDoan] = useState();
    const [trangThai, setTrangThai] = useState();
    const [moTa, setMoTa] = useState();
    const [ketLuan, setKetLuan] = useState();
    const [deNghi, setDeNghi] = useState();
    const [ngayGioHen, setNgayGioHen] = useState();
    const [tenNguoiTaoLichHen, setTenNguoiTaoLichHen] = useState("");
    const [ngayTaoLichHen, setNgayTaoLichHen] = useState();
    const [danhSachICD, setDanhSachICD] = useState([]);
    const [ICD, setICD] = useState([]);
    const [listFormName, setListFormName] = useState([]);
    const [formName, setFormName] = useState(null);
    const [PACSType, setPACSType] = useState('');
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [visibleCameraViewer, setVisibleCameraViewer] = useState(false);

    const historyRef = useRef();
    const _notiRef = useRef();
    const _scheduleRef = useRef();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setData(props.data);
        if (props.data.appointmentId) {
            getAppoimentDetail(props.data.appointmentId);
            getListFormPACS(props.data.serviceCode);
            forceUpdate();
        }
    }, [props.data]);

    const onOpenSlideShow = (e) => {
        e.stopPropagation();
        setVisibleSlideShow(true);
    }

    const onCloseSlideShow = () => {
        setVisibleSlideShow(false);
    }

    const getAppoimentDetail = (id) => {
        Actions.setLoading(true);
        PacsService.getPACSAppoimentDetail(id).then(result => {
            if (result.isSuccess) {
                const data = result.data;
                setChanDoan(data.diagnostic);
                setTrangThai(data.isDone ? "Đã thực hiện" : "Chưa thực hiện");
                data.description && setMoTa(data.description);
                setKetLuan(data.result);
                setDeNghi(data.advice);
                setNgayGioHen(data.appointmentSchedule && moment(data.appointmentSchedule).format(SystemConfig.FORMAT_DATE_TIME));
                setICD(data.icd);
                setDanhSachICD(data.icd);
                setTenNguoiTaoLichHen(data.apScheCreator);
                setNgayTaoLichHen(data.apScheDateCreate);
                setPACSType(data.pacsType)
                !data.isDone && getListFormPACS(props.data.serviceCode, data.isDone)
                _notiRef && _notiRef.current.Close();
            }
            else {
                resetState();
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const resetState = () => {
        setChanDoan();
        setTrangThai();
        setMoTa();
        setKetLuan();
        setDeNghi();
        setNgayGioHen();
        setICD();
        setTenNguoiTaoLichHen();
        setNgayTaoLichHen();
    }

    const GetListDiagnostic = (key) => {
        let skip = 0;
        let maxCount = 10;
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxCount,
            keyword: key,
        }
        CommonService.GetListICD10(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                data = data.map(x => ({ ...x, label: `${x.value} - ${x.label}` }))
                setDanhSachICD(data);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", 'Lỗi tải danh chẩn đoán ICD');
        })
    }

    const savePACS = () => {
        Actions.setLoading(true);
        let ngayHen = null;
        let idLichHen = null;
        if (_scheduleRef && _scheduleRef.current) {
            const dataHen = _scheduleRef.current.getData();
            ngayHen = dataHen.ngayGioHen;
            idLichHen = dataHen.idLichHen
        }
        let data = {
            appointmentId: props.data.appointmentId,
            managermentId: props.data.managermentId,
            serviceId: props.data.serviceId,
            description: moTa,
            result: ketLuan,
            advice: deNghi,
            appointmentSchedule: ngayHen,
            appointmentScheduleId: idLichHen,
            registedDate: props.data.registedDate,
            patientCode: props.data.patientCode,
            pacsId: null,
            iCD: ICD,
        }
        _notiRef && _notiRef.current.Close();
        scrollToTop();
        PacsService.savePACSAppoiment(data).then(result => {
            if (result.isSuccess) {
                _notiRef.current.Show("Lưu thành công", NotifyStatus.Success);
                setTimeout(() => {
                    props.afterSaving();
                }, 800);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const getListFormPACS = (idVienPhi, isDone = true) => {
        PacsService.getPACSTemplate(idVienPhi).then(result => {
            if (result.isSuccess) {
                setListFormName(result.data);
                // Nếu có Form mô tả -> Chọn cái đầu tiên
                if (result.data && result.data.length > 0 && !isDone) {
                    setFormName(result.data[0].id);
                    getFormPACSDetail(result.data[0].id);
                }
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        })
    }

    const getFormPACSDetail = (formId) => {
        Actions.setLoading(true);
        PacsService.getPACSTemplateDetail(formId).then(result => {
            if (result.isSuccess) {
                setMoTa(result.data ? result.data.description : null);
                setKetLuan(result.data ? result.data.ketLuan : null);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const setSelectedICD = (item) => {
        let lst = [];
        item.map(x => {
            lst.push({ value: x.key, label: x.children })
        })
        setICD(lst);
    }

    const onSelectedDevice = (device) => {
        setSelectedDevice(device);
        setVisibleCameraViewer(true);
    }

    const onClosePACSViewer = (data) => {
        if (data) {
            setMoTa(data.mota);
            setDeNghi(data.denghi);
            setKetLuan(data.ketluan);
        }
        _uploadRef.current && _uploadRef.current.GetListFile();
        setSelectedDevice([]);
        setVisibleCameraViewer(false);
    }

    const scrollToTop = () => {
        var containerToScroll = document.getElementById('container');
        containerToScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={`${data.serviceName} - ${data.patientName}`} onClose={() => { props.onCancel() }} />
                <div className="list-page-body">
                    <div className="w-100 h-100 d-flex justify-content-between" style={{ overflowY: "auto" }}>
                        <div style={{ width: '60%', overflowY: "auto" }}>
                            <Collapse defaultActiveKey={['pif', 'multimedia']} expandIconPosition="right">
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={props.data.patientCode} />
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>} key="vts">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <VitalSignInfo maql={props.data.managermentId} col={12}></VitalSignInfo>
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title d-flex justify-content-between align-items-center w-100'>
                                    <span>Thư viện hình ảnh & video</span>
                                    <span onClick={(e) => { e.stopPropagation(); onOpenSlideShow(e); }}><i className="far fa-play-circle"></i></span>
                                </label>} key="multimedia">
                                    <div className="row my-2" style={{ lineHeight: "20px" }}>
                                        <MutilMedia col={12}
                                            ref={_uploadRef}
                                            featureName="cdha"
                                            componentKey="CDHA-Multimedia"
                                            refId={props.data.appointmentId.toString()}
                                            isEdit={true}
                                            layout="SmallIcons"
                                            extention=".tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png, .mp4"
                                            camera={true}
                                            showPreview={true}
                                            onSelectedDevice={(device) => { onSelectedDevice(device) }}
                                        >
                                        </MutilMedia>
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="htr">
                                    <div className="w-100 my-2" style={{ lineHeight: "20px" }}>
                                        <History
                                            ref={historyRef}
                                            iShowResult={true}
                                            patientID={props.data.patientCode}
                                            feature={[HISTORYFEATURE.CDHA]}
                                            type={[PACSType.toString()]}
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className="h-100 w-100 position-relative" style={{ overflowY: "auto" }} id="container">
                            <div className='h-100 mx-2 border position-relative'>
                                <Collapse defaultActiveKey={['xctcdha']} expandIconPosition="right">
                                    <Panel
                                        key="xctcdha"
                                        header={
                                            <div className='d-flex justify-content-between align-items-center w-100'>
                                                <label className='mita-header-title'>Thông tin {data.serviceName}</label>
                                                <span onClick={(e) => e.stopPropagation()}><Speaker sentence={`Mời khách hàng ${data.patientName} vào phòng ${props.roomName && props.roomName.toLowerCase().replace("phòng ", "")}`}
                                                    notShowButton={true}></Speaker></span>
                                            </div>
                                        }>
                                        <Notify ref={_notiRef} />
                                        <div className='row'>
                                            <div className='col-6 mt-2'>
                                                <label className='font-weight-bold'>Chẩn đoán ban đầu</label>
                                                {/* <Input.TextArea
                                                    autoSize={{
                                                        minRows: 1,
                                                        maxRows: 4,
                                                    }}
                                                    readOnly
                                                    value={chanDoan}>
                                                </Input.TextArea> */}
                                                <div className='w-100'>
                                                    <AutocompleteMulti
                                                        className="w-100"
                                                        width={'100%'}
                                                        dataSource={danhSachICD}
                                                        handleSearch={GetListDiagnostic}
                                                        getItem={(item) => { setSelectedICD(item); }}
                                                        value={ICD}
                                                        title="Chẩn đoán ban đầu" />
                                                </div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label className='font-weight-bold'>Trạng thái</label>
                                                <div>{trangThai}</div>
                                            </div>
                                            <div className='col-6 mt-2'>
                                                <label className='font-weight-bold'>Biểu mẫu</label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder="Chọn biểu mẫu"
                                                    className='w-100'
                                                    value={formName}
                                                    onChange={(e) => { getFormPACSDetail(e); setFormName(e) }}
                                                >
                                                    {listFormName.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.id}>{row.ten}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Mô tả</label>
                                                <SunEditor
                                                    lang={suneditorViLang}
                                                    onBlur={(e, value) => {
                                                        setMoTa(value);
                                                    }}
                                                    onChange={(e) => { setMoTa(e); }}
                                                    setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                                                    setContents={moTa}
                                                    setOptions={{
                                                        height: 300,
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'lineHeight', 'horizontalRule', 'list', 'table', 'link'],
                                                        ]
                                                    }}
                                                    height='100%'
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Kết luận</label>
                                                {/* <Input.TextArea
                                                    autoSize={{
                                                        minRows: 1,
                                                    }}
                                                    value={ketLuan}
                                                    placeholder="Nhập kết luận"
                                                    onChange={(e) => setKetLuan(e.target.value)}
                                                ></Input.TextArea> */}
                                                <SunEditor
                                                    lang={suneditorViLang}
                                                    onBlur={(e, value) => {
                                                        setKetLuan(value);
                                                    }}
                                                    onChange={(e) => setKetLuan(e)}
                                                    setDefaultStyle="font-size: 13px; paragraphStyles: normal; font-family: Arial;"
                                                    setContents={ketLuan}
                                                    setOptions={{
                                                        height: 300,
                                                        buttonList: [
                                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                            ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                                            ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'lineHeight', 'horizontalRule', 'list', 'table', 'link'],
                                                        ]
                                                    }}
                                                    height='100%'
                                                />
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Đề nghị</label>
                                                <Input.TextArea
                                                    autoSize={{
                                                        minRows: 1,
                                                    }}
                                                    value={deNghi}
                                                    placeholder="Nhập đề nghị"
                                                    onChange={(e) => setDeNghi(e.target.value)}
                                                ></Input.TextArea>
                                            </div>
                                            {IsActive_FeatureLH() &&
                                                <div className='col-12 mt-2'>
                                                    <Schedule
                                                        ref={_scheduleRef}
                                                        data={{
                                                            managermentId: data.managermentId,
                                                            serviceId: data.serviceId,
                                                            patientCode: data.patientCode,
                                                        }} />
                                                </div>}
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 mt-2'>
                                                <label className='font-weight-bold'>Đính kèm tập tin</label>
                                                <MutilMedia col={12}
                                                    featureName="cdha"
                                                    componentKey="CDHA-Attachment"
                                                    refId={props.data.appointmentId.toString()}
                                                    isEdit={true}
                                                    layout="Details"
                                                    extention=".pdf, .doc, .docx, .xls, .xlsx"
                                                ></MutilMedia>
                                            </div>
                                        </div>
                                        <div className="row mt-2 my-2">
                                            <div className='col-12 d-flex justify-content-end'>
                                                <button className="btn btn-color" onClick={() => { savePACS(); }}><i className="fas fa-save mr-1" />Lưu</button>
                                                <button className="btn btn-danger ml-2" onClick={() => props.onCancel()}><i className="fas fa-times-circle mr-1" />Đóng</button>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"50%"}
                placement="right"
                visible={visibleSlideShow}
                destroyOnClose={true}
                onCancel={() => { onCloseSlideShow() }}>
                <SlideShow
                    componentKey="CDHA-Multimedia"
                    refId={props.data.appointmentId.toString()}
                    onCancel={onCloseSlideShow}
                ></SlideShow>
            </Drawer>
            <Modal
                className=""
                visible={visibleCameraViewer}
                width="90%"
                destroyOnClose={true}
                mask={true}
                maskClosable={false}
            >
                <CameraPACSViewer
                    data={{ 'mota': moTa, 'ketluan': ketLuan, 'denghi': deNghi }}
                    device={selectedDevice}
                    refId={props.data.appointmentId.toString()}
                    onClose={(data) => onClosePACSViewer(data)}
                />
            </Modal>
        </Fragment>
    );
})

export default PACSEdit;