
import * as React from 'react';
import { AutoComplete, Button, Input, InputNumber } from 'antd';
import { connect } from 'react-redux';
import * as Actions from '../../libs/actions';
import moment from 'moment'
import './main.css'
import { isNullOrEmpty } from '../../libs/util';
import { FLIPPED_ALIAS_KEYS } from '@babel/types';
import { BUTTON } from '../../configureHIS/common/constants';

let taskDelay = null;

class AutocompleteTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [],

      columns: [],
      searchInput:'',
      searchList: [],
      currentPage: 1,
    }
  }

  componentDidMount() {

    this.setState({ columns: this.props.headers })
  }

  renderGroupName = (text) => {
    return (

      <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
        {text}
      </div>
    )
  }

  focus() {
    this.search.focus()
  }
  renderTittle = () => {
    return (
      <div
        style={{ display: 'flex' }}>
        {this.state.columns.map((col, index) => {
          return (
            <span key={"data" + col.dataIndex + index} style={{
              width: col.width, overflow: 'hidden',
              textOverflow: 'ellipsis', marginRight: 5, paddingLeft: 10, textAlign: col.align,
            }}>{col.title}</span>
          )
        })
        }
      </div>

    )
  };
  getFormat(item, col) {
    if (col.format == "number")
      return item[col.dataIndex].toLocaleString('en-US')
    if (col.format == "datetime" && item[col.dataIndex]) {
      return moment(item[col.dataIndex]).format('DD-MM-YYYY');
    }
    if (col.format == "gender" && item[col.dataIndex]) {
      return Number(item[col.dataIndex]) === 0 ? 'NAM' : 'NỮ';
    }
    // return item[col.dataIndex]
    if (col.render) {
      return col.render(item[col.dataIndex], item)
    }
    return item[col.dataIndex]
  }
  renderItem = (item) => {
    return {
      value: item[this.props.keyValue],
      label: (
        <div
          style={{ display: 'flex' }}>
          {this.state.columns.map((col, index) => {
            return (
              <span key={"data" + col.dataIndex + index} style={{
                width: col.width, overflow: 'hidden',
                textOverflow: 'ellipsis', marginRight: 5, textAlign: col.align,
              }}>{
                  this.getFormat(item, col)
                } </span>
            )
          })
          }
        </div>
      ),
    };
  };

  groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  handleSearch = (value, page = 1) => {
    let options = [];
    let option = {
      label: this.renderTittle(),
      options: [],
    }
    if (!this.props.enableSearchEmpty && isNullOrEmpty(value)) {
      this.setState({ options })
      return;
    }
    
    if (taskDelay) clearTimeout(taskDelay);
    taskDelay = setTimeout(() => {
      this.props.onSearch(value, page).then((result) => {
        if (result.isSuccess) {
          for (let item of result.data) {
            option.options.push(this.renderItem(item))
          }
          options.push(option)
        } else {
          options = []
        }
        this.props.pagination && options.push({
          label:
          <div className='d-flex justify-content-end align-items-center' disabled={true} >
            <span>Tổng số: {result.totalRecords} -</span>
            {this.state.currentPage > 1 && <Button className={`ml-2 btn-sm ${BUTTON.THEME}`}
              disabled={this.state.currentPage <= 1}
              onClick={(e) => {
                  this.onChangePagination(-1);
                  e.stopPropagation();
                }}>&lsaquo;</Button>}
              <span className='ml-2'>Trang {this.state.currentPage} / {Math.ceil(result.totalRecords / 10)}</span>
              {this.state.currentPage < Math.ceil(result.totalRecords / 10) && <Button className={`ml-2 btn-sm ${BUTTON.THEME}`}
                disabled={this.state.currentPage >= Math.ceil(result.totalRecords / 10)}
                onClick={(e) => {
                  this.onChangePagination(1);
                  e.stopPropagation();
                }}>&rsaquo;</Button>}
            </div>
        });
        this.setState({ options, searchList: result.data })
      }).catch((ex) => {
        this.setState({ options })
      })
    }, 400);
  }

  onChangePagination(addPage) {
    let { currentPage } = this.state;
    let newPage = currentPage + addPage;
    if (newPage <= 0) newPage = 1;
    this.setState({ currentPage: newPage });
    this.handleSearch(this.state.searchInput, newPage);
  }

  onSelect = (value) => {
    let item = this.state.searchList.filter(record => record[this.props.keyValue] === value)[0];
    this.setState({
      // searchInput: item.patientName
      searchInput: '',
      options: [], 
      searchList: []
    });
    this.props.onSelect(item);
  }

  setValue = (value) => {
    this.setState({
      searchInput: value
    });
  }

  componentDidUpdate() {
    if (this.props.resetBarCode === true) {
      Actions.ResetBarCode(false);
      this.setState({ searchInput: '' })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.value !== nextProps.value) {
      this.setState({ searchInput: nextProps.value })
    }
    return true;
  }
  onBlurPatientName = (value) => {
    this.props.onBlurPatientName(value);
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'relative', width: this.props.width }}>
          <AutoComplete
            value={this.state.searchInput ? (this.props.keepTransfrom ? this.state.searchInput : this.state.searchInput.toUpperCase()) : ''}
            onChange={e => { this.setState({ searchInput: e, currentPage: 1 }); if (this.props.onChange) { this.props.onChange(e) } }}
            onSelect={this.onSelect}
            onSearch={this.handleSearch}
            onDropdownVisibleChange={(open) => {
              if (!open) {
                this.setState({ searchInput: this.props.value });
                if (isNullOrEmpty(this.props.value))
                  this.setState({ options: [] });
              }
              if (this.props.onDropdownVisibleChange) { this.props.onDropdownVisibleChange(open) } 
            }}
            // onFocus={() => {this.handleSearch(this.searchInput)}}
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={this.props.tablewidth}
            style={{ width: this.props.width }}
            options={this.state.options}
            listHeight={this.props.listHeight ? this.props.listHeight : 500}
            maxLength={40}
            disabled={this.props.disabled ? true : false}
            onBlur={this.onBlurPatientName}
          >
            <Input style={{}} ref={(c) => { this.search = c }} placeholder={this.props.placeholder}/>
          </AutoComplete>
          {this.props.qrcode === true &&
            <div style={{
              position: 'absolute', right: '0px', bottom: '4px', cursor: 'pointer', border: '1px solid rgb(191,191,191)',
              padding: '2px 9px', borderRadius: 8
            }}
              onClick={() => {
                if (this.props.showQRCode) { this.props.showQRCode() }
              }}
            ><i className="fa fa-qrcode"></i></div>
          }
          {this.props.showRemove === true &&
            <div style={{
              position: 'absolute', right: `${this.props.qrcode === true ? '32' : '6'}px`, bottom: '4px', cursor: 'pointer', border: 'none',
              padding: '2px 9px', borderRadius: 8
            }}
              onClick={() => {
                if (this.props.CleanText) { this.props.CleanText() }
              }}
            ><i className="fas fa-times"></i></div>
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    resetBarCode: state.global.resetBarCode,
  })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(AutocompleteTable);