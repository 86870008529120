import moment from "moment";
import { connect } from 'react-redux';
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Table, Pagination, Select, DatePicker,Checkbox,Input, notification, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Action from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as ReportServices from '../../../services/ReportService';
import SiteFilter from "../../../configureHIS/common/siteFilter";
import Notify, { NotifyStatus } from '../../../components/notify';
import { isNullOrEmpty } from "../../../libs/util";
import ThongKeNguoiLayMauChiTiet from './detail';

const { Option } = Select;
const ThongKeNguoiLayMau = forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const now = new Date();
  //#endregion
  const detailRef = useRef(null);
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  // const [dateFrom, setDateFrom] = useState(moment('2024-02-01').set({"hour": 0, "minute": 0}));
  const [dateFrom, setDateFrom] = useState(moment(new moment()).set({"hour": 0, "minute": 0}));
  const [dateTo, setDateTo] = useState(new moment());
  const [keyword, setKeyword] = useState('');
  //model result
  // const [totalAmount, setTotalAmount] = useState(0);
  // const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //other
  const [selectedNguoiLayMau_UserName, setSelectedNguoiLayMau_UserName] = useState([]);
  //#endregion
  //#region useEffect
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getDataChiTiet();
  }, [selectedNguoiLayMau_UserName]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex, sorting, pageSize]);
  //#endregion 
  
  //#region get data
  const getData = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      keyword: keyword,
      
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getThongKeNguoiLayMauList(filterModel).then(result => {
      if (result.isSuccess) {
        // setTotalRecord(result.data.totalCount);
        setListResult(result.data);
        notiRef && notiRef.current.Close();
        setSelectedNguoiLayMau_UserName([]);
        // getDataChiTiet();
        forceUpdate();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
      Action.setLoading(false);
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
      Action.setLoading(false);
    }).finally(() => {
    })
  }
  //#endregion 

  const onExportExcelClinkHandle = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      keyword: keyword,
      sorting: sorting,
    }
    ReportServices.getThongKeNguoiLayMauChiTietExport(filterModel).then(result => {
      let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_ThongKeNguoiLayMau`; 
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = fileName;
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        Action.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Action.setLoading(false);
      forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }
  
  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };

  const getDataChiTiet = () => {
    if (detailRef && detailRef.current) detailRef.current.getData();
  }
  // , Tên nhân viên, tên bệnh nhân, Tên dịch vụ, Tên nhóm - loại, Thanh toán, Số phiếu, Nguồn khách hàng
  // const columnsChiTiet = [
  //   {
  //       title: 'STT',
  //       dataIndex: 'stt',
  //       key: 'stt',
  //       align: 'center',
  //       width: 55,
  //       render: (text, record, index) => {
  //         return <span>{ record.isChild ? '' : (pageIndex-1)*pageSize + (index + 1) }</span>;
  //       }
  //   },
  //   {
  //     title: <>
  //       <span>Người lấy mẫu</span>
  //       <Tooltip title="Thông tin người lấy mẫu chỉ có từ các dữ liệu sau khi update version 2.4.4. Vì trước đó chưa ghi nhận thông tin người lấy mẫu."><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>
  //     </>,
  //     dataIndex: 'nguoiLayMau_HoTen',
  //     key: 'nguoiLayMau_HoTen',
  //     align: 'left',
  //     width: 170,
  //     render: (value, record, index) => {
  //       return (
  //           <span>{isNullOrEmpty(value) ? record.nguoiLayMau_UserName : value}</span>
  //       )
  //     }
  //   },
  //   {
  //       title: 'Ngày lấy mẫu',
  //       dataIndex: 'ngayGioLayMau',
  //       key: 'ngayGioLayMau',
  //       align: 'left',
  //       width: 115,
  //       render: (value, record, index) => {
  //         return (
  //             <span>{moment(value).format(SystemConfig.FORMAT_DATE_TIME)}</span>
  //         )
  //       }
  //   },
  //   {
  //     title: 'SID',
  //     dataIndex: 'sid',
  //     key: 'sid',
  //     align: 'left',
  //     width: 140,
  //     // sorter: () => {},
  //   },
  //   {
  //     title: 'Mã khách hàng',
  //     dataIndex: 'maBN',
  //     key: 'maBN',
  //     align: 'left',
  //     width: 140,
  //     // sorter: () => {},
  //   },
  //   {
  //       title: 'Tên khách hàng',
  //       dataIndex: 'hoTen',
  //       key: 'hoTen',
  //       align: 'left',
  //       // width: 120,
  //       // sorter: () => {},
  //       // render: (text, record, index) => {
  //       //   return <span>{ `${text}${(record.isChildInCombo ? " (combo)" : "")}` }</span>;
  //       // }
  //   },
  //   {
  //       title: 'Lấy mẫu tại nhà',
  //       dataIndex: 'layMauTaiNha',
  //       key: 'layMauTaiNha',
  //       align: 'center',
  //       width: 120,
  //       render: (value, record, index) => {
  //         return <span>{value && <i className='fas fa-check'></i>}</span>;
  //       }
  //   },
  //   {
  //       title: 'Chi nhánh',
  //       dataIndex: 'siteName',
  //       key: 'siteName',
  //       align: 'left',
  //       width: 300,
  //   },
  // ];

  const columns = [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 45,
        render: (text, record, index) => {
          return <span>{ record.isChild ? '' : (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    {
      title: <>
        <span>Người lấy mẫu</span>
        <Tooltip title="Thông tin người lấy mẫu chỉ có từ các dữ liệu sau khi update version 2.4.4. Vì trước đó chưa ghi nhận thông tin người lấy mẫu."><i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip>
      </>,
      dataIndex: 'nguoiLayMau_HoTen',
      key: 'nguoiLayMau_HoTen',
      align: 'left',
      // width: 170,
      render: (value, record, index) => {
        return (
            <span>{isNullOrEmpty(value) ? record.nguoiLayMau_UserName : value}</span>
        )
      }
    },
    {
        title: 'SL mẫu lấy',
        dataIndex: 'soLuongLayMau',
        key: 'soLuongLayMau',
        align: 'center',
        width: 90,
        render: (value, record, index) => {
          return (
            <span>{value}</span>
          )
        }
    },
  ];

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Thống kê người lấy mẫu</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Ngày lấy mẫu | Từ khóa */}
                <div className='col-md-6 d-flex flex-column'>{/* Ngày lấy mẫu */}
                  <div>
                    <label>Ngày lấy mẫu</label>
                  </div>
                  <div className="d-flex">
                    <DatePicker 
                      allowClear={false}
                      locale={viVN}
                      showNow={false}
                      className="w-100"
                      placeholder={"Từ ngày"}
                      showTime={{ format: 'HH:mm' }}
                      value={dateFrom}
                      format = { SystemConfig.FORMAT_DATE_TIME }
                      onChange={(e) => { 
                        setDateFrom(e ? moment(e) : null);
                      }}
                    />
                    <div className="m-auto">&#160;-&#160;</div>
                    <DatePicker 
                      allowClear={false}
                      locale={viVN}
                      showNow={false}
                      className="w-100"
                      placeholder={"Đến ngày"}
                      showTime={{ format: 'HH:mm' }}
                      value={dateTo}
                      format = { SystemConfig.FORMAT_DATE_TIME }
                      onChange={(e) => { 
                        setDateTo(e ? moment(e) : null);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column">{/* Từ khóa */}
                  <div><label>Từ khóa</label></div>
                    <Input
                      placeholder="Tìm theo tên người lấy mẫu, SID, mã khách hàng, tên khách hàng"
                      prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                      value={keyword} 
                      onChange={(e) => { setKeyword(e.target.value); }}
                      onKeyUp={(e) => { enterToSearch(e); }}
                    ></Input>
                  </div>
              </div>
              <div className="row mt-2">{/* button */}
                <div className='col-md-12 d-flex justify-content-end align-items-center'>{/* Tổng tiền | button thống kê | xuất excel */}
                  <button 
                    className='btn btn-color ml-2' 
                    onClick={() => {
                      resetToFirstPage();
                    }}
                  >
                    <i className='far fa-chart-bar mr-2'></i>
                    Thống kê
                  </button>
                  <button className="btn btn-color ml-2" type="button" 
                      onClick={() => { onExportExcelClinkHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                  </button>
                </div>
              </div>
            </div>
            <div className="list-page-table mt-2">
            <div className="h-100 w-100 overflow-hidden d-flex justify-content-between pt-1">
              <div className="h-100 border-right border-dark d-flex flex-column" style={{ width:300 }}>
                <div>
                  <h4>Thống kê người lấy mẫu</h4>
                </div>
                <div className="h-100 overflow-auto">
                  <Table style={{ height: "calc(100%)", width: "100%" }}
                    className="table-min-h-0"
                    dataSource={listResult} columns={columns}
                    locale={{ emptyText: 'Không có dữ liệu' }} 
                    scroll={{ x: 0, y: 0 }}
                    rowKey={(e) => e.nguoiLayMau_UserName??''}
                    // rowSelection={rowSelection}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: e => {
                          if (selectedNguoiLayMau_UserName.indexOf(record.nguoiLayMau_UserName) > -1)
                            setSelectedNguoiLayMau_UserName([]);
                          else setSelectedNguoiLayMau_UserName([record.nguoiLayMau_UserName]);
                        }, // click row
                        // onDoubleClick: event => {}, // double click row
                        // onContextMenu: event => {}, // right button click row
                        // onMouseEnter: event => {}, // mouse enter row
                        // onMouseLeave: event => {}, // mouse leave row
                      };
                    }}
                    rowClassName={(record, rowIndex) => {
                      return `rowCustom rowCustomSelect ${(selectedNguoiLayMau_UserName.indexOf(record.nguoiLayMau_UserName) > -1) ? 'rowCustomSelectActive' : ''}`
                    }}
                    pagination={false}
                    onChange={onTableChange} 
                  />
                </div>
              </div>
              <div className="h-100 w-100 d-flex flex-column overflow-hidden">
                <ThongKeNguoiLayMauChiTiet ref={detailRef}
                  siteIds={siteIds}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  keyword={keyword}
                  selectedNguoiLayMau_UserName={selectedNguoiLayMau_UserName}
                  sorting={sorting}
                ></ThongKeNguoiLayMauChiTiet>
              </div>
            </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
});
const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps)(ThongKeNguoiLayMau)