import { store } from '../stores';
import * as WorldfoneService from '../services/worldfoneService';
import * as Configure from '../configure/urlConfig';
import { notification } from 'antd';
import * as Actions from '../libs/actions';

function CleanData() {
    localStorage.setItem('token', '');
    localStorage.setItem('name', '');
    localStorage.setItem('groupid', '');
    localStorage.setItem('title', '');
    localStorage.setItem('userID', '');
    localStorage.setItem('tokenWF', '');
    localStorage.setItem('userWF', '');
    localStorage.setItem('id', '');
    store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '' } });
}

export function openMessageDialog(errorHeader, errContent) {
    store.dispatch({ type: 'MODAL_SET_LOADING_MODAL', status: false });
    store.dispatch({ type: 'MODAL_OPEN_ERROR_MODAL', errHeader: errorHeader, errContent: errContent });
}

export function logout() {
    let userWF = localStorage.getItem('userWF');
    // let tokenWF = localStorage.getItem('tokenWF');
    let tokenWF = localStorage.getItem('tokenWF');
    // WorldfoneService.login(userWF, tokenWF).then(xxx => {
    WorldfoneService.logout(tokenWF).then(response => {
        // CleanData();
    }).catch(err => {
        console.log(err);
    })
    // }).catch(err => {
    CleanData();
}

export function openPrintDialog(func, params) {

    store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', func: func, params: params });

}


export function closePrintDialog() {
    store.dispatch({ type: 'MODAL_CLOSE_PRINT_MODAL' });
}

export function setLoading(state) {
    store.dispatch({ type: 'MODAL_SET_LOADING_MODAL', status: state });
}
export function clearTabs() {
    store.dispatch({ type: 'TAB_CLEAR' });
}
// export function DoPrint(promiseFunction) {
//     promiseFunction.then(repsone => {
//         repsone.blob().then((blob) => {
//             let url = window.URL.createObjectURL(blob);
//             store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', file: url });
//         })
//     }).catch(ex => {
//         store.dispatch({ type: 'MODAL_OPEN_ERROR_MODAL', errHeader: "error", errContent: ex.toString()});
//     })

// }

export function DoPrint(file) {
    store.dispatch({ type: 'MODAL_OPEN_PRINT_MODAL', file: file });
}

export function setDataBarCode(data) {
    store.dispatch({ type: 'SET_DATA_BARCODE', data: data });
}

export function ResetBarCode(data) {
    store.dispatch({ type: 'RESET_BARCODE', data: data });
}

export function ResetListPhieuLinhThuoc(data) {
    store.dispatch({ type: 'RESET_LIST_PHIEULINHTHUOC', data: data });
}

export function ReloadPhieuThuoc(data) {
    store.dispatch({ type: 'RELOAD_PHIEUTHUOC', data: data });
}

export function ResetListPhieuTraThuoc(data) {
    store.dispatch({ type: 'RESET_LIST_PHIEUTRATHUOC', data: data });
}

export function EditPhieuTraThuoc(data) {
    // { isEdit: true, idphieutra: idphieutra
    store.dispatch({ type: 'EDIT_PHIEUTRATHUOC', data: data });
}

export function OpenCallWorldfone(isopen, status, data) {
    store.dispatch({ type: 'MODAL_OPEN_CALL_WORLDFONE', WFopen: isopen, WFstatus: status, WFdata: data });
}

export function setConfigure(data) {
    store.dispatch({ type: 'SET_CONFIGURE', data: data });
}

export function setAvailableSite(data) {
    store.dispatch({ type: 'SET_AVAILABLE_SITE', data: data });
}

export function setMenuData(data) {
    store.dispatch({ type: 'SET_MENU_DATA', data: data });
}

export function setPermission(data) {
    store.dispatch({ type: 'SET_PERMISSION', data: data });
}

export function setUserScreenAuthorization(data) {
    store.dispatch({ type: 'SET_USER_SCREEN_AUTHORIZATION', data: data });
}

export function setNotificationCount(data) {
    store.dispatch({ type: 'SET_NOTIFICATION_COUNT', data: data });
}

export function setScreenSaver(state) {
    store.dispatch({ type: 'MODAL_SET_SCREEN_SAVER', status: state });
}