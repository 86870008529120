import { InboxOutlined } from '@ant-design/icons';
import { Input, InputNumber, notification, Radio, Upload } from 'antd';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Barcode from 'react-barcode';
import BarCodeMove from './barCodeMove';
import './style.css';
import * as VoucherServices from "./templateVoucherServices";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import { helpers } from 'chart.js';
const { Dragger } = Upload;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
const EditForm = (props) => {
    const [width, setWidth] = useState(410);
    const [height, setHeight] = useState(210);
    var fileListfrontSide = []
    var fileListbackSide = []
    const [frontSide, setFrontSide] = useState('');
    const [backSide, setBackSide] = useState('');


    const [barCodeSide, setBarCodeSide] = useState('front');
    const [barcodeWidth, setBarcodeWidth] = useState(width / 2);
    const [barCodeHeight, setBarCodeHeight] = useState(height / 2);

    const [x, setX] = useState(2);
    const [y, setY] = useState(2);

    const [allowMove, setAllowMove] = useState(false);
    const frontBarCode = useRef(null);
    const backBarCode = useRef(null);
    const [templateName, setTemplateName] = useState("");
    const [templateType, setTemplateType] = useState(2);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [idTemplate, setIdTemplate] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    useEffect(() => {
        if (props.infoUpdate && props.infoUpdate !== null && props.infoUpdate.length > 0) {
            let info = props.infoUpdate[0];
            setIdTemplate(info.id);
            setTemplateName(info.templateName);
            setTemplateType(info.templateType);
            setBackSide(info.barcodeFront ? "front" : "back");
            setWidth(Number(info.templateWidth));
            setHeight(Number(info.templateHeight));
            setFrontSide(info.frontSide);
            setBackSide(info.backSide);
            setX(info.barCodeX);
            setY(info.barCodeY);
            setBarcodeWidth(Number(info.barcodeWidth));
            setBarCodeHeight(Number(info.barCodeHeight));
            setIsUpdate(true);
        }
    }, [props.infoUpdate])

    const resetState = () => {
        setIdTemplate(0);
        setTemplateName("");
        setTemplateType(2);
        setBackSide("front");
        setWidth(800);
        setHeight(300);
        setFrontSide("");
        setBackSide("");
        setX(2);
        setY(2);
        setBarcodeWidth(width / 2);
        setBarCodeHeight(height / 2);
        setIsUpdate(false);
    }

    const configFrontSide = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        onChange(info) {
            getBase64(info.fileList[0].originFileObj).then(result => {
                setFrontSide(result);
            }).catch(err => {
                setFrontSide('');
                console.log(err);
            })
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const ChangeX = (x) => {
        if ("front" == barCodeSide) {
            if (frontBarCode && frontBarCode.current) {
                frontBarCode.current.setLeft(x);
            }
        } else {
            if (backBarCode && backBarCode.current) {
                backBarCode.current.setLeft(x);
            }
        }
        setX(x)
    }

    const ChangeY = (y) => {
        if ("front" == barCodeSide) {
            if (frontBarCode && frontBarCode.current) {
                frontBarCode.current.setTop(y);
            }
        } else {
            if (backBarCode && backBarCode.current) {
                backBarCode.current.setTop(y);
            }
        }
        setY(y)
    }

    const ChangeBarcodeWith = (width) => {
        if (width < 140) width = 140
        if ("front" == barCodeSide) {
            if (frontBarCode && frontBarCode.current) {
                frontBarCode.current.setWidth(width);
            }
        } else {
            if (backBarCode && backBarCode.current) {
                backBarCode.current.setWidth(width);
            }
        }
        setBarcodeWidth(y)
    }
    const ChangeBarcodeHeight = (height) => {
        if (height < 140) height = 140
        if ("front" == barCodeSide) {
            if (frontBarCode && frontBarCode.current) {
                frontBarCode.current.setHeight(height);
            }
        } else {
            if (backBarCode && backBarCode.current) {
                backBarCode.current.setHeight(height);
            }
        }
        setBarCodeHeight(height)
    }



    const configBackSide = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        onChange(info) {
            getBase64(info.fileList[0].originFileObj).then(result => {
                setBackSide(result);
            }).catch(err => {
                setBackSide('');
                console.log(err);
            })
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    var getFrontType = () => {
        var style = {
            border: '1px solid rgb(131,131,131)', width: width, height: height, position: 'relative', overflow: "hidden"
        };
        if (frontSide != '') {
            style.backgroundImage = `url(${frontSide})`
            style.backgroundSize = '100% 100%'
        }
        return style
    }

    var getBackType = () => {
        var style = {
            border: '1px solid rgb(131,131,131)', width: width, height: height, marginTop: 50, position: 'relative', overflow: "hidden"
        };
        if (backSide != '') {
            style.backgroundImage = `url(${backSide})`
            style.backgroundSize = '100% 100%'
        }
        return style
    }

    var getStyleBarCode = () => {
        var style = {
            position: 'absolute', top: `${y}px`, left: `${x}px`, width: barcodeWidth, height: barCodeHeight,
        };
        return style;
    }

    var onChangeBarCodeSide = (e) => {
        setBarCodeSide(e.target.value);
        setAllowMove(false)
    };

    const createVoucherTemplate = () => {
        if (validator.current.allValid()) {
            const data = {
                templateName: templateName,
                templateType: templateType,
                barcodeFront: barCodeSide === "front" ? true : false,
                templateWidth: width,
                templateHeight: height,
                frontSide: frontSide,
                backSide: backSide,
                barCodeX: x,
                barCodeY: y,
                barcodeWidth: barcodeWidth,
                barCodeHeight: barCodeHeight,
                id: isUpdate ? idTemplate : 0,
            }
            !isUpdate ? VoucherServices.create(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Đã tạo thành công" });
                    resetState();
                    if (props.onCancel())
                        props.onCancel();
                } else {
                    if (res.err.msgString) notification.warning({ message: res.err.msgString });
                    else notification.warning({ message: 'Đã xảy ra lỗi, vui lòng thử lại sau' });
                }
            }).catch(() => { })
            : VoucherServices.update(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" });
                    resetState();
                    if (props.onCancel())
                        props.onCancel();
                } else {
                    if (res.err.msgString) notification.warning({ message: res.err.msgString });
                    else notification.warning({ message: 'Đã xảy ra lỗi, vui lòng thử lại sau' });
                }
            }).catch(() => { })
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{
                flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', paddingTop: 10
            }}>
                <div className="warrperStyleGroup" style={{
                    display: 'flex',
                    justifyContent: 'center', alignItems: 'center', height: '48%'
                }} >
                    {templateType === 2 && <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold' }}>Mặt trước</h4>
                    </div>}
                    <div style={getFrontType()}
                        onMouseUp={(e) => {
                            if (frontBarCode && frontBarCode.current)
                                frontBarCode.current.clearState();
                            setAllowMove(false)
                        }}
                        onMouseMove={(e) => {
                            if (frontBarCode && frontBarCode.current && allowMove)
                                frontBarCode.current.handleMouseMove(e);
                        }}
                        onMouseLeave={(e) => {

                        }}
                    >
                        {barCodeSide == 'front' &&

                            <BarCodeMove
                                onChange={(options) => {
                                    setBarcodeWidth(options.width)
                                    setBarCodeHeight(options.height)
                                    setX(options.left)
                                    setY(options.top)
                                }}
                                onActiveMove={(c) => {
                                    if (c === null) {
                                        if (frontBarCode && frontBarCode.current)
                                            frontBarCode.current.clearState();
                                    }
                                    setAllowMove(true)
                                }}
                                onClick={() => {
                                }}
                                ref={frontBarCode} top={y} left={x} width={barcodeWidth} height={barCodeHeight}>
                            </BarCodeMove>

                        }
                    </div>
                </div>
                {templateType === 2 && <div className="warrperStyleGroup" style={{
                    display: 'flex',
                    justifyContent: 'center', alignItems: 'center', height: '48%'
                }}>
                    <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold' }}>Mặt Sau</h4>
                    </div>
                    <div style={getBackType()}

                        onMouseUp={(e) => {
                            if (backBarCode && backBarCode.current)
                                backBarCode.current.clearState();
                            setAllowMove(false)
                        }}
                        onMouseMove={(e) => {
                            if (backBarCode && backBarCode.current && allowMove)
                                backBarCode.current.handleMouseMove(e);
                        }}
                        onMouseLeave={(e) => {
                        }}>
                        {barCodeSide == 'back' &&

                            <BarCodeMove
                                onChange={(options) => {
                                    setBarcodeWidth(options.width)
                                    setBarCodeHeight(options.height)
                                    setX(options.left)
                                    setY(options.top)
                                }}
                                onActiveMove={(c) => {
                                    if (c === null) {
                                        if (backBarCode && backBarCode.current)
                                            backBarCode.current.clearState();
                                    }
                                    setAllowMove(true)
                                }}
                                onClick={() => {
                                }}
                                ref={backBarCode} top={y} left={x} width={barcodeWidth} height={barCodeHeight}>
                            </BarCodeMove>

                        }
                    </div>
                </div>}
            </div>
            <div style={{ width: 350, border: '1px solid rgb(131,131,131)', margin: 10, borderRadius: 20, padding: 20, overflow: 'auto' }}>
                <Radio.Group onChange={(e) => { setAllowMove(false); setTemplateType(e.target.value); if (e.target.value === 1) { setBarCodeSide("front"); setBackSide("") }}} value={templateType}>
                    <Radio value={1}>1 mặt</Radio>
                    <Radio value={2}>2 mặt</Radio>
                </Radio.Group>
                <div className='row'>
                    <div className='col-md-8'>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <div style={{ width: 80 }}>Chiều dài:</div>
                            <InputNumber style={{ width: 140 }} value={height} placeholder="" onChange={(e) => { setHeight(e) }} /></div>
                        <div style={{ display: 'flex', alignItems: "center", marginTop: 10 }}>
                            <div style={{ width: 80 }}>Chiều rộng:</div>
                            <InputNumber style={{ width: 140 }} value={width} placeholder="" onChange={(e) => { setWidth(e) }} /></div>
                    </div>
                    {/* <div className='col-md-4 d-flex align-items-center'>
                        <span style={{ height: "67%", width: 10, borderTop: "solid 1px", borderRight: "solid 1px", borderBottom: "solid 1px" }}></span>
                        <i style={{ transform: "translateX(-12px)", backgroundColor: "#fff", padding: 5 }} className="fas fa-link"></i>
                    </div> */}
                </div>
                <div style={{ display: 'flex', marginTop: 10 }} >
                    <div style={{ width: 80 }}>{templateType === 2 ? "Mặt Trước:" : "Ảnh nền:"}</div>
                    <div className={`${frontSide !== '' ? 'hasphoto' : ''} photocontainer`} >
                        {frontSide !== '' &&
                            <div style={{ width: 220 }}>
                                <img style={{ width: 220 }} src={frontSide} />
                            </div>
                        }
                        <Dragger {...configFrontSide} beforeUpload={() => false} fileList={fileListfrontSide} itemRender={null} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                        </Dragger>
                    </div>
                </div>
                {templateType === 2 && <div style={{ display: 'flex', marginTop: 10 }} >
                    <div style={{ width: 80 }}> Mặt Sau: </div>
                    <div className={`${backSide !== '' ? 'hasphoto' : ''} photocontainer`} >
                        {backSide !== '' &&
                            <div style={{ width: 220 }}>
                                <img style={{ width: 220 }} src={backSide} />
                            </div>
                        }
                        <Dragger {...configBackSide} beforeUpload={() => false} fileList={fileListbackSide} itemRender={null} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                        </Dragger>
                    </div>
                </div>}
                <div className="warrperStyleGroup" style={{
                    display: 'flex', marginTop: 10,
                    justifyContent: 'center', alignItems: 'center'
                }} >
                    <div className="grouplabel">
                        <h4 style={{ fontWeight: 'bold' }}>BarCode</h4>
                    </div>
                    <div style={{ width: '100%' }}>
                        {templateType === 2 && <Radio.Group defaultValue="front" buttonStyle="solid">
                            <Radio.Button style={{ marginRight: 20 }} onChange={onChangeBarCodeSide} value="front">Mặt Trước</Radio.Button>
                            <Radio.Button onChange={onChangeBarCodeSide} value="back">Mặt Sau</Radio.Button>
                        </Radio.Group>}
                        <div style={{ marginTop: 10 }} >x: <InputNumber value={x} min={0} onChange={ChangeX}></InputNumber></div>
                        <div style={{ marginTop: 10 }}>y: <InputNumber value={y} min={0} onChange={ChangeY}></InputNumber></div>
                        <div style={{ display: 'flex', marginTop: 10 }}> <div style={{ width: 80 }}>Chiều dài:
                        </div> <InputNumber style={{ width: 140 }} value={barCodeHeight} placeholder=""
                            onChange={ChangeBarcodeHeight} /></div>
                        <div style={{ display: 'flex', marginTop: 10 }}> <div style={{ width: 80 }}>Chiều rộng:
                        </div><InputNumber style={{ width: 140 }} value={barcodeWidth} placeholder=""
                            onChange={ChangeBarcodeWith} /></div>
                    </div>
                </div>
                <div className='mt-2 d-flex flex-column justify-content-center'>
                    <label>Tên mẫu in</label>
                    <Input value={templateName} onChange={e => setTemplateName(e.target.value)} placeholder='Nhập tên mẫu in'></Input>
                    {validator.current.message("Tên mẫu in", templateName, "required")}
                </div>
                <div className='mt-2 d-flex justify-content-center'>
                    <button className='btn btn-color' onClick={() => createVoucherTemplate()}><i className='fas fa-save' style={{ marginRight: 5 }} />{isUpdate ? "Cập nhật" : "Lưu"}</button>
                    <button className='btn btn-danger' style={{ marginLeft: 10 }} onClick={() => { resetState(); props.onCancel() }}><i className='fas fa-sign-out-alt' style={{ marginRight: 5 }} />Đóng</button>
                </div>

            </div>
        </div>
    )
}

export default EditForm 