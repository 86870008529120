import moment from "moment";
import React, { Fragment, useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Table, Pagination, Select, Spin } from 'antd';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import * as Actions from '../../../../libs/actions';

const { Option } = Select;
const now = new Date();
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      resetToFirstPage();
    },
  }));
  //#region khai báo state
  const notiRef = useRef(null);
  const firstRender = useRef(true);
  const _hiddenLink = useRef();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex]);

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])

  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    Actions.setLoading(true);
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      paymentTypeIds: props.paymentTypeIds,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
    }
    ReportServices.getAverageRevenueList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
      Actions.setLoading(false);
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }
  
  const onExportExcelClinkHandle = () => {
    Actions.setLoading(true);
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      paymentTypeIds: props.paymentTypeIds,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
    }
    ReportServices.getAverageRevenueListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = "ThongKeTrungBinhDon_"+moment(new Date()).format('YYMMDDhhmmss');
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
      Actions.setLoading(false);
    })
  }

  const columns = [
    {
        title: '#',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 30,
        render: (text, record, index) => {
            return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
    {
      title: 'Ngày lập phiếu',
      dataIndex: 'ngay',
      key: 'ngay',
      align: 'left',
      width: 120,
      render: (value, record) => {
        return (
          <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>
        )
      }
  },
  { 
      title: 'Mã khách hàng',
      dataIndex: 'maBN',
      key: 'maBN',
      align: 'left',
      width: 115,
      // render: (value, record) => {
      // }
    },
    {
        title: 'Khách hàng',
        dataIndex: 'hoTen',
        key: 'hoTen',
        align: 'left',
        width: 200,
    },
    {
        title: 'Nguồn khách hàng',
        dataIndex: 'coporationName',
        key: 'coporationName',
        align: 'left',
        width: 200,
    },
    {
        title: 'Đơn khách hàng',
        dataIndex: 'isNew',
        key: 'isNew',
        align: 'left',
        width: 140,
        render: (value, record) => {
            return (
              <span>{ value ? "Khách hàng mới" : "Khách hàng tái khám" }</span>
            )      
        }
    },
    {
        title: 'Ngày lập phiếu lần đầu',
        dataIndex: 'ngayDauTien',
        key: 'ngayDauTien',
        align: 'left',
        width: 170,
        render: (value, record) => {
          return (
            <span>{moment(value).format(SystemConfig.FORMAT_DATE)}</span>
          )
        }
    },
    {
        title: 'Giới tính',
        dataIndex: 'phai',
        key: 'phai',
        align: 'left',
        width: 80,
        render: (value, record) => {
            return (
              <span>{value == 0 ? "Nam": "Nữ" }</span>
            )
        }
    },
    {
        title: 'Năm sinh',
        dataIndex: 'namSinh',
        key: 'namSinh',
        align: 'left',
        width: 80,
    },
    {
            title: 'SL phiếu',
            children:[
                {
                    title: 'TT',
                    dataIndex: 'countOrder',
                    key: 'countOrder',
                    align: 'center',
                    width: 80
                },
                {
                    title: 'HT',
                    dataIndex: 'countRefund',
                    key: 'countRefund',
                    align: 'center',
                    width: 80
                }
            ]
            
        },
    {
        title: 'Tổng tiền',
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        align: 'right',
        width: 100,
        render: (value, record) => {
            return (
              <span>{ Number(value).toLocaleString('en-US') }</span>
            )
        }
    },
    {
        title: 'Tổng giảm',
        dataIndex: 'discountOrder',
        key: 'discountOrder',
        align: 'right',
        width: 100,
        render: (value, record) => {
            return (
              <span>{ Number(record.discountOrder + record.discountDetail).toLocaleString('en-US') }</span>
            )
        }
    },
    {
        title: 'Hoàn trả',
        dataIndex: 'totalRefund',
        key: 'totalRefund',
        align: 'right',
        width: 100,
        render: (value, record) => {
            return (
              <span>{ Number(value).toLocaleString('en-US') }</span>
            )
        }
    },
    {
        title: 'Thực thu',
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        align: 'right',
        width: 100,
        render: (value, record) => {
            return (
              <span>{ Number(record.totalRevenue - (record.discountOrder + record.discountDetail) - record.totalRefund).toLocaleString('en-US') }</span>
            )
        }
    },
    {
        title: 'Trung bình đơn',
        dataIndex: 'discountOrder',
        key: 'discountOrder',
        align: 'right',
        width: 150,
        render: (value, record) => {
            return (
              <span>{ Number((record.totalRevenue - (record.discountOrder + record.discountDetail) - record.totalRefund) / record.countOrder ).toLocaleString('en-US') }</span>
            )
        }
    },
  ];
  //#endregion
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className="list-page" >
            <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
            </div>
            <div className="list-page-body">{/* body */}
              <div className="row">{/* notification */}
                <div className="col-md-12"><Notify ref={notiRef} /></div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-end p-0">
                  <button className="btn btn-color" type="button" 
                    onClick={() => { onExportExcelClinkHandle(); }}
                  ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                </div>
              </div>
              <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                  className="table-min-h-0 table-header-1row"
                  dataSource={listResult} 
                  columns={columns}
                  locale={{ emptyText: 'Không có dữ liệu' }} 
                  scroll={{ x: 1200, y: 0 }}
                  rowKey={(e) => e.ngay.toString() + e.maBN + e.coporationName}
                  rowClassName={(record, rowIndex) => {
                  }}
                  pagination={false}
                  // onChange={onTableChange} 
                />
                <Pagination
                  current={pageIndex}
                  size={pageSize}
                  style={{ textAlignLast: "center", marginTop: "10px" }}
                  total={totalRecord}
                  showTotal={total => `Tổng: ${total}`}
                  onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                  onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                  showSizeChanger={true}
                />
              </div>
            </div>
        </div>
      </Spin>
    </React.Fragment>
  );
})