import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Select, Switch, Tooltip, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as ThirdPartyConfigService from '../../../services/thirdPartyConfigService';

const { Option } = Select;
const { TextArea } = Input;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [selectedListServices, setSelectedListServices] = useState();
  //validate
  //result model
  const [id, setId] = useState(props.id);
  const [description, setDescription] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [zaloApiKey, setZaloApiKey] = useState();
  const [zaloSecretKey, setZaloSecretKey] = useState();
  const [zaloOAID, setZaloOAID] = useState();
  const [zaloApiUrl, setZaloApiUrl] = useState();
  //#endregion 
  //gửi test
  const [zaloTo, setZaloTo] = useState();
  const [zaloListTemplate, setZaloListTemplate] = useState([]);
  const [zaloTemplateId, setZaloTemplateId] = useState();
  const [zaloTemplateContent, setZaloTemplateContent] = useState();
  const [zaloTemplateDetails, setZaloTemplateDetails] = useState([]);
  const [zaloTemplateSelected, setZaloTemplateSelected] = useState();
  //#region khai báo biến cục bộ
  //2 thằng này dùng suốt không thay đổi, nên chỉ cần khai báo là biến
  const showMoreText = "...Xem thêm";
  const showLessText = "\nRút gọn";
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    getById(props.id);
  }, [props.id]);
  
  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
      if (props.detailScreenMode == detailScreenModeEnum.create)
        setHeaderText('Tạo mới');
  }, [props.detailScreenMode]);

  useEffect(() => {
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      getListTemplateZalo();
  }, []);
  //#endregion 
  const setData = (data) => {
    setDescription(data.description);
    setIsDefault(data.isDefault);

    setZaloApiKey(data.config.zaloApiKey);
    setZaloSecretKey(data.config.zaloSecretKey);
    setZaloOAID(data.config.zaloOAID);
    setZaloApiUrl(data.config.zaloApiUrl);
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - ' + data.config.zaloOAID);
    else if (props.detailScreenMode == detailScreenModeEnum.update)
      setHeaderText('Cập nhật - ' + data.config.zaloOAID);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    ThirdPartyConfigService.zaloGetById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
    // forceUpdate();
  }

  const model = {
    id : null,
    description: '',
    isDefault : '',
    config: {
      zaloApiKey : '',
      zaloSecretKey : '',
      zaloOAID : '',
      zaloApiUrl : '',
    }
  };

  const setModel = () => {
    model.id = id;
    model.description = description;
    model.isDefault = isDefault;
    model.config={
      zaloApiKey : zaloApiKey,
      zaloSecretKey : zaloSecretKey,
      zaloOAID : zaloOAID,
      zaloApiUrl : zaloApiUrl,
    };
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.zaloCreate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    ThirdPartyConfigService.zaloUpdate(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const validate = () => {
    let isValid = true;
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && id) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }
  //#region khu vực gửi test
  const getListTemplateZalo = () => {
    ThirdPartyConfigService.zaloGetListTemplate().then(result => {
      if (result.isSuccess) {
        setZaloListTemplate(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const changeZaloTemplate = (templateId = zaloTemplateId) => {
    let tempSelected = zaloListTemplate.find(x=>x.tempId == templateId);
    setZaloTemplateId(templateId);
    setZaloTemplateSelected(tempSelected);
    if (tempSelected) {
      // setZaloTemplateContent(tempSelected.tempContent);
      setZaloTemplateDetails(tempSelected.templateDetails);
    }
    forceUpdate();
  }

  const changeZaloTemplateDetails = (record, newValue) => {
    let tempDetails = zaloTemplateDetails;
    let detail = tempDetails.find(x => x.id == record.id);
    detail.value = newValue;
    setZaloTemplateDetails(tempDetails);
  }

  const validateSendZaloTest = () => {
    if (!zaloTo || zaloTo.length == 0) {
      notification.error({ message: "Phải nhập Số điện thoại nhận" });
      return false;
    }
    if (!zaloTemplateId || zaloTemplateId.length == 0) {
      notification.error({ message: "Phải chọn mẫu tin nhắn" });
      return false;
    }
    if (zaloTemplateDetails.filter(x => !x.value || x.value.length == 0).length > 0){
      notification.error({ message: "Phải nhập đủ biến vào" });
      return false;
    }
    return true;
  }
  const sendZaloTest = () => {
    if (!validateSendZaloTest()) {
      return;
    }
    Actions.setLoading(true);
    let zaloTempSelected = zaloTemplateSelected;
    zaloTempSelected.templateDetails = zaloTemplateDetails;
    let data = {id: id, zaloTo: zaloTo, zaloTemplate: zaloTempSelected};
    ThirdPartyConfigService.sendZaloTest(data).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  //#endregion
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mô tả / Mặc định*/}
                <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mô tả</label>
                  </div>
                  <TextArea
                    value={description}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextArea>
                  {validator.current.message("Mô tả", description, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label className="mita-title">Mặc định</label>
                  <div>
                    <Tooltip title={'Chỉ 1 tài khoản được bật mặc định. Nếu bạn bật mặc định cho tài khoản này thì các tài khoản khác sẽ bị tắt.'}>
                      <Switch 
                        checked={isDefault} 
                        onChange={(checked, e) => { 
                          if (detailScreenMode == detailScreenModeEnum.detail) {
                            props.onIsDefaultClick(id);
                          }
                          setIsDefault(checked); 
                        }} 
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Api key */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Api key</label>
                  </div>
                  <Input
                    value={zaloApiKey}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setZaloApiKey(e.target.value)}
                  ></Input>
                  {validator.current.message("Api key", zaloApiKey, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Secret key*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className="mita-title">Secret key</label>
                    <label className='required-field' hidden={detailScreenMode==detailScreenModeEnum.detail}>*</label>
                  </div>
                  <Input
                    value={zaloSecretKey}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setZaloSecretKey(e.target.value)}
                  ></Input>
                  {validator.current.message("Secret key", zaloSecretKey, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Secret key*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>OA ID</label>
                  </div>
                  <Input
                    value={zaloOAID}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setZaloOAID(e.target.value)}
                  ></Input>
                  {validator.current.message("oa id", zaloOAID, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* url*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`mita-title ${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Api url</label>
                  </div>
                  <Input
                    value={zaloApiUrl}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setZaloApiUrl(e.target.value)}
                  ></Input>
                  {validator.current.message("Api url", zaloApiUrl, "required")}
                </div>
              </div>
              <div hidden={detailScreenMode!=detailScreenModeEnum.detail}>
                <div className="row mt-2 border border-top"> {/* */}
                  <div className="col-md-12">
                    <label className="mita-title">Gửi zalo test</label>
                  </div>
                </div>
                <div className="row mt-2"> {/* zaloTo | zaloContent */}
                  <div className="col-md-5 d-flex flex-column">
                    <div>
                      <label className="mita-title">Số điện thoại nhận</label>
                    </div>
                    <Input
                      value={zaloTo}
                      readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                      onChange={(e) => setZaloTo(e.target.value)}
                    ></Input>
                  </div>
                  <div className="col-md-5 d-flex flex-column">
                    <div>
                      <label className="mita-title">Mẫu tin nhắn
                        <Tooltip title="Tin nhắn zalo phải chọn theo mẫu đã đăng ký với nhà cung cấp dịch vụ. Chọn mẫu xong nhập giá trị cho biến để gửi tin nhắn zalo.">
                          <i className="far fa-question-circle" style={{ marginLeft: 5 }} />
                        </Tooltip>
                      </label>
                    </div>
                    <Select
                      allowClear
                      // showSearch
                      filterOption={(input, option) => {
                        return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                      }}
                      className="w-100"
                      placeholder="Chọn mẫu tin nhắn"
                      // maxTagCount= 'responsive'
                      value={ zaloTemplateId }
                      onChange={(value, record) => {changeZaloTemplate(value);}}
                    >
                      {(zaloListTemplate ?? []).map((record, index) => {
                        return (
                          <Option key={record.tempId} value={record.tempId}>{record.tempName}</Option>
                        )
                      })}
                    </Select>
                    {/* <Input
                      value={zaloContent}
                      readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                      onChange={(e) => setZaloContent(e.target.value)}
                    ></Input> */}
                  </div>
                  <div className="col-md-2 d-flex flex-column justify-content-end">
                    <button 
                      className='btn btn-color mr-2' 
                      onClick={sendZaloTest}
                    >
                      <i className='fas fa-paper-plane  mr-2'></i>
                      Gửi
                    </button>
                  </div>
                </div>
                <div className="row"> {/* zaloTo | zaloContent */}
                  <div className="col-md-12 d-flex flex-column">
                    <div className="w-100 mt-2 p-align-left" style={{whiteSpace: "pre-line"}}
                      dangerouslySetInnerHTML={{ __html: zaloTemplateSelected?.tempContent }}
                    >
                      {/* {zaloTemplateContent} */}
                    </div>
                    {(zaloTemplateDetails ?? []).map((record, index) => {
                      return (
                        <div className="row mt-2" key={record.id}>
                          <div className="col-md-3">
                            <label>{record.param}</label>
                          </div>
                          <div className="col-md-6">
                            <Input
                              value={record.value}
                              readOnly={detailScreenMode!=detailScreenModeEnum.detail}
                              onChange={(e) => {changeZaloTemplateDetails(record, e.target.value); }}
                              onPressEnter={sendZaloTest}
                            ></Input> 
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.update || detailScreenMode == detailScreenModeEnum.create}
                  onClick={props.onUpdateClick}
                >
                  <i className='fa fa-pencil mr-2'></i>
                  Chỉnh sửa
                </button>
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})