import { Button, Form, Input, Modal, notification, Popconfirm, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as PatientService from '../../../services/patientService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';

export default class dausinhton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            mach: '',
            chieucao: '',
            cannang: '',
            nhietdo: '',
            mabn: '',
            lstDST: [],
            huyetap: '',
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    static getDerivedStateFromProps(props, state) {
        if (props.visible !== undefined) {
            return {
                visible: props.visible,
                mabn: props.mabn,

                change: true,
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            if (this.state.visible) {
                this.DanhSachDauSinhTon();
            }
        }
    }
    componentDidMount() {
        this.DanhSachDauSinhTon();
    }
    DanhSachDauSinhTon() {
        PatientService.DanhSachDauSinhTon(this.state.mabn).then(result => {
            if (result.isSuccess) {
                this.setState({ lstDST: result.data });
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    ThemDauSinhTon() {
        Actions.setLoading(true);
        if (this.validator.allValid()) {
            const data = {
                'mach': this.state.mach.toString(),
                'nhietdo': this.state.nhietdo.toString(),
                'huyetap': this.state.huyetap.toString(),
                'mabn': this.state.mabn.toString(),
                'chieucao': this.state.chieucao.toString(),
                'cannang': this.state.cannang.toString(),
            }
            PatientService.ThemDauSinhTon(data).then(result => {
                Actions.setLoading(false);
                if (result.isSuccess) {
                    notification.success({ message: 'Thêm thành công' });
                    this.DanhSachDauSinhTon();
                    this.resetState();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
            }).catch(err => {
                Actions.setLoading(false);
                Actions.openMessageDialog("Lỗi", err);
            })
        } else {
            Actions.setLoading(false);
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    XoaDauSinhTon(mabn, ngay) {
        const data = {
            'mabn': mabn,
            'ngay': ngay,
        }
        PatientService.XoaDauSinhTon(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                this.DanhSachDauSinhTon();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    resetState() {
        this.validator.hideMessageFor("Mạch");
        this.validator.hideMessageFor("Huyết áp");
        this.validator.hideMessageFor("Nhiệt độ");
        this.validator.hideMessageFor("Chiều cao");
        this.validator.hideMessageFor("Cân nặng");
        this.validator.hideMessages();
        this.setState({
            mach: null,
            nhietdo: null,
            huyetap: null,
            cannang: null,
            chieucao: null
        });
    }

    render() {
        const columns = [
            {
                title: 'Nhiệt độ',
                dataIndex: 'nhietdo',
                key: 'nhietdo',
            },
            {
                title: 'Mạch',
                dataIndex: 'mach',
                key: 'mach',
            },
            {
                title: 'Huyết áp',
                dataIndex: 'huyetap',
                key: 'huyetap',
            },
            {
                title: 'Chiều cao',
                dataIndex: 'chieucao',
                key: 'chieucao',
            },
            {
                title: 'Cân nặng',
                dataIndex: 'cannang',
                key: 'cannang',
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm:ss')}</span>;
                }
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <Popconfirm title="Bạn có chắc muốn xóa dấu sinh tồn này?"
                            onConfirm={() => this.XoaDauSinhTon(this.state.mabn, row.ngay)}
                            okText="Đồng ý"
                            cancelText="Hủy">
                            <Button type="primary" danger
                                className="btn btn-outline-danger" title="Xóa dấu sinh tồn">
                                <i className="fa fa-trash"></i>
                            </Button>
                        </Popconfirm>
                    )
                }
            },
        ]
        return (
            <React.Fragment>
                <Modal maskClosable={false}
                    width={1000}
                    visible={this.state.visible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ display: 'flex', marginTop: "30px", marginBottom: "8px" }}>
                        <Form.Item style={{ flex: 1 }}
                            label="Mạch:">
                            <Input
                                className="w-100"
                                value={this.state.mach}
                                min={40}
                                max={250}
                                maxLength={3}
                                suffix='lần/phút'
                                onChange={(e) => {
                                    this.setState({ mach: e.target.value });
                                    this.validator.showMessageFor("Mạch");
                                }}
                            />
                            {this.validator.message("Mạch", this.state.mach, "required|numeric|min:40,num|max:250,num")}
                        </Form.Item>
                        <Form.Item style={{ flex: 1 }}
                            label="Nhiệt độ:">
                            <Input
                                value={this.state.nhietdo}
                                min={20}
                                max={50}
                                maxLength={5}
                                suffix={'\u2103'}
                                onChange={(e) => {
                                    this.setState({ nhietdo: e.target.value });
                                    this.validator.showMessageFor("Nhiệt độ");
                                }}
                            />
                            {this.validator.message("Nhiệt độ", this.state.nhietdo, "required|numeric|max:50,num|min:20,num")}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginBottom: "8px" }}>
                        <Form.Item
                            style={{ flex: 1 }}
                            label="Huyết áp:">
                            <Input
                                value={this.state.huyetap}
                                maxLength={7}
                                min={50}
                                max={200}
                                suffix='mmHg'
                                onChange={(e) => {
                                    this.setState({ huyetap: e.target.value });
                                    this.validator.showMessageFor("Huyết áp");
                                }}
                            />
                            {this.validator.message("Huyết áp", this.state.huyetap, "required|numeric|min:50,num|max:200,num")}
                        </Form.Item>
                        <Form.Item style={{ flex: 1 }}
                            label="Cân nặng:">
                            <Input
                                value={this.state.cannang}
                                min={0}
                                max={500}
                                maxLength={4}
                                suffix='kg'
                                onChange={(e) => {
                                    this.setState({ cannang: e.target.value });
                                    this.validator.showMessageFor("Cân nặng");
                                }}
                            />
                            {this.validator.message("Cân nặng", this.state.cannang, "required|numeric|min:0|max:500,num")}
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', marginBottom: "8px" }}>
                        <Form.Item style={{ flex: 1 }}
                            label="Chiều cao:">
                            <Input
                                value={this.state.chieucao}
                                min={20}
                                max={250}
                                maxLength={5}
                                suffix='cm'
                                onChange={(e) => {
                                    this.setState({ chieucao: e.target.value });
                                    this.validator.showMessageFor("Chiều cao");
                                }}
                            />
                            {this.validator.message("Chiều cao", this.state.chieucao, "required|numeric|min:20,num|max:250,num")}
                        </Form.Item>
                    </div>
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <button className="btn btn-success" onClick={() => { this.ThemDauSinhTon() }}> <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                    </div>
                    <Table pagination={false} dataSource={this.state.lstDST} columns={columns} rowKey={(row, index) => index} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                        <button
                            className="btn btn-danger"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                                this.props.closeModal();
                                this.resetState()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
