import { Pagination, Button, Input, DatePicker, Form, notification, Table, Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../libs/actions';
import SimpleReactValidator from 'simple-react-validator';
import * as WarehouseService from '../../../services/WarehouseTransferService';
import TaoPhieuChuyenKho from '../../xuatchuyenkho/components/taophieuchuyenkho';
import * as PrintService from '../../../services/printService';
const { RangePicker } = DatePicker;
const initialState = {
    khoxuatID: '',
    khonhapID: '',
    key: '',
    ngayrange: [moment(), moment()],
    tungay: moment(),
    denngay: moment(),
    totalRecords: 0,
}
export default class PhieuXuatChuyenKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKhoXuat: [],
            lstKhoNhap: [],
            lstChuyenKho: [],
            lstDetail: [],
            visibleCreate: false,
            visibleDetail: false
        };
    }

    componentWillMount() {
        this.DanhSachChuyenKho();
    }

    ThongTinPhieu(id, ngay) {
        WarehouseService.DetailChuyenKho(id, moment(ngay).format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.data,
                    visibleDetail: true
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thông tin phiếu chuyển kho bị lỗi!' })
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    DanhSachChuyenKho(page = 1, size = 10) {
        WarehouseService.ListChuyenKho(this.state.key, moment(this.state.tungay).format('YYYY-MM-DD'),
            moment(this.state.denngay).format('YYYY-MM-DD'), page, size).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstChuyenKho: result.data,
                        totalRecords: result.totalRecords,
                        currentPage: page,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu danh sách chuyển kho bị lỗi!' })
            })
    }

    handleCancel = e => {
        this.setState({
            visibleCreate: false,
            visibleDetail: false
        });
        this.DanhSachChuyenKho();
    };
    PrintPhieu(id, ngay) {
        PrintService.PrintPhieuChuyenKho(id, moment(ngay).format('YYYY-MM-DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    render() {
        let { khoxuatID, khonhapID, ngay, id } = this.state;
        const columns = [
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'center',
            },
            {
                title: 'Kho xuất',
                dataIndex: 'tenkhoxuat',
                key: 'tenkhoxuat',
                align: 'left',
            },
            {
                title: 'Kho nhập',
                dataIndex: 'tenkhonhap',
                key: 'tenkhonhap',
                align: 'left',
            },
            {
                title: 'Lý do',
                dataIndex: 'lydo',
                key: 'lydo',
                align: 'left',
            },
            {
                title: 'Loại',
                dataIndex: 'loai',
                key: 'loai',
                align: 'left',
            },

            {
                title: 'Action',
                align: 'center',
                render: (record) => {
                    return (
                        <div>
                            <button className="btn btn-success"
                                title="Thông tin phiếu chuyển kho!"
                                onClick={() => { this.setState({ id: record.id, ngay: record.ngay }); this.ThongTinPhieu(record.id, moment(record.ngay)) }}>
                               <i className="fa fa-eye" style={{ paddingRight: 5 }}></i>
                            Xem
                        </button>
                            <button className="btn btn-primary" onClick={() => { this.PrintPhieu(record.id, moment(record.ngay)) }}
                                style={{ marginLeft: 10 }}><i className="fa fa-print" style={{ paddingRight: 5 }}></i>In phiếu</button>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstChuyenKho.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'mabd',
                key: 'mabd',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
                align: 'left',
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'center',
            },
            {
                title: 'SL xuất',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'giaban',
                key: 'giaban',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        let dataSource1 = this.state.lstDetail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <div style={{ backgroundColor: "rgb(250,250,250)", height: '100%' }}>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>PHIẾU XUẤT CHUYỂN KHO</div>
                <Form ref={(c) => this.form = c} >
                    <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                        <div className="col-auto mb-2">
                            <Form.Item>
                                <RangePicker format="DD-MM-YYYY" value={this.state.ngayrange} allowClear={false}
                                    locale={viVN} onChange={(value => this.setState({ ngayrange: value, tungay: value[0], denngay: value[1] }, () => { this.DanhSachChuyenKho() }))}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-auto mb-2">
                            <Form.Item>
                                <Input placeholder="Nhập loại,số phiếu,tên kho...."
                                    style={{ width: '250px' }}
                                    value={this.state.key}
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.DanhSachChuyenKho();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-auto mb-2">
                            <Form.Item>
                                <button className="btn btn-success" 
                                    onClick={() => this.DanhSachChuyenKho()}><i style={{ marginRight: 5 }} className="fa fa-search" /> Tìm</button>
                            </Form.Item>
                        </div>

                        <div className="col-auto mb-2">
                            <Form.Item>
                                <button className="btn btn-success" onClick={() => this.setState({ visibleCreate: true })}> <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="form-group" style={{ flex: 1 }} >
                        <Modal maskClosable={false}
                            width={1700}
                            visible={this.state.visibleCreate}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}

                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <TaoPhieuChuyenKho onCancel={this.handleCancel}
                                khoxuat={khoxuatID} khonhap={khonhapID} done={1}
                                idPhieu={id} ngay={ngay} />
                        </Modal>
                        <Table dataSource={dataSource}
                        
                            pagination={false}
                            scroll={{ y: 0 }}
                            columns={columns} rowKey={(e, index) => index} />
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.DanhSachChuyenKho(page, size) }}
                            onShowSizeChange={(page, size) => this.DanhSachChuyenKho(1, size)}
                            showSizeChanger={true}
                        />
                        <Modal maskClosable={false}
                            width={800}
                            visible={this.state.visibleDetail}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}

                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                                <Form.Item label="ID phiếu:">
                                    <Input
                                        disabled
                                        value={this.state.id} />
                                </Form.Item>
                            </div>
                            <Table dataSource={dataSource1}
                                pagination={false}
                                scroll={{ y: 0 }}
                                columns={columns1} rowKey={(e, index) => index} />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                                <Button type="primary" style={{ marginLeft: 5 }} danger
                                    onClick={() => {
                                        this.handleCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng

                        </Button>
                            </div>
                        </Modal>
                    </div>
                </Form>

            </div>
        )
    }
}
