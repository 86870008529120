import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Configure';


export function RegisterAccount(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/createAccount`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DetailRegister(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/searchdetail`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function SavePatient(data)  {

    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`RegisterMedical/luubenhnhan`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
