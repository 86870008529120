import { Button, Checkbox, DatePicker, Drawer, Form, notification, Select, Table, TimePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import History from '../../../components/history';
import * as Actions from '../../../libs/actions';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as PTTTServices from '../../../services/phauthuthuatService';
import * as CommonService from '../../../services/commonService';
import * as PhauThuThuatService from '../../../services/phauthuthuatService';
import CreateXuatKho from './exportVT';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { Option } = Select;
const init = {
    tenpt: '',
    mapt: '',
    lstTenpt: [],
    ngaykt: moment(),
    giokt: moment(),
    detail: {},
    ppvocam: '',
    saupt: '',
    truocpt: '',
    ghichu: '',
    gayme: '',
    phumo: '',
    idpttt: '',
    nhom: 0,
    loai: '',
    mabs: '',
    isModal: false,
    HistoryDrawer: false,
    selectedPatient: {},
    isEdit: false,
    danhSachICD: [],
    ICD: [],
}
export default class thuthuat extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...init,
            chiDinh: '',
            soluong: 1,
            lstLoaiChiDinh: [],
            loaiChiDinh: '',
            lstChiDinh: [],
            lstChiDinhBenhNhan: [],
            lstMeTe: [],
            lstMabs: [],
            lstLoai: [],
            lstNhom: [],
            code: typeof (this.props.code) !== undefined ? this.props.code : '',
            name: typeof (this.props.name) !== undefined ? this.props.name : '',
            department: typeof (this.props.department) !== undefined ? this.props.department : '',
            lstdepartment: [],
            cardNumber: typeof (this.props.cardNumber) !== undefined ? this.props.cardNumber : '',
            enterNumber: typeof (this.props.enterNumber) !== undefined ? this.props.enterNumber : '',
            departmentName: typeof (this.props.departmentName) !== undefined ? this.props.departmentName : '',
            subjectName: typeof (this.props.subjectName) !== undefined ? this.props.subjectName : '',
            subjectCode: typeof (this.props.subjectCode) !== undefined ? this.props.subjectCode : '',
            managerId: typeof (this.props.managerId) !== undefined ? this.props.managerId : '',
            diagnostic: typeof (this.props.machuandoan) !== undefined ? this.props.machuandoan : '',
            diagnosticName: typeof (this.props.tenchuandoan) !== undefined ? this.props.tenchuandoan : '',
            doctorReceive: typeof (this.props.mabs) !== undefined ? this.props.mabs : '',
            dateIn: typeof (this.props.dateIn) !== undefined ? this.props.dateIn : moment().format("YYYY/MM/DD HH:mm"),
            detail: {},
        };
    }
    componentDidMount() {
        this.setState({
            code: typeof (this.props.code) !== undefined ? this.props.code : '',
            name: typeof (this.props.name) !== undefined ? this.props.name : '',
            department: typeof (this.props.department) !== undefined ? this.props.department : '',
            lstdepartment: [],
            cardNumber: typeof (this.props.cardNumber) !== undefined ? this.props.cardNumber : '',
            enterNumber: typeof (this.props.enterNumber) !== undefined ? this.props.enterNumber : '',
            departmentName: typeof (this.props.departmentName) !== undefined ? this.props.departmentName : '',
            subjectName: typeof (this.props.subjectName) !== undefined ? this.props.subjectName : '',
            subjectCode: typeof (this.props.subjectCode) !== undefined ? this.props.subjectCode : '',
            managerId: typeof (this.props.managerId) !== undefined ? this.props.managerId : '',
            diagnostic: typeof (this.props.machuandoan) !== undefined ? this.props.machuandoan : '',
            diagnosticName: typeof (this.props.tenchuandoan) !== undefined ? this.props.tenchuandoan : '',
            doctorReceive: typeof (this.props.mabs) !== undefined ? this.props.mabs : '',
            dateIn: typeof (this.props.dateIn) !== undefined ? this.props.dateIn : moment().format("YYYY/MM/DD HH:mm"),
            ageForYear: typeof (this.props.detail.year) != undefined ? moment().year() - Number(this.props.detail.year) : 0,
            ageForMonth: typeof (this.props.detail.birthYear) != undefined ? Math.floor(moment().diff(moment(this.props.detail.birthYear), 'months', true)) : 0
        }, () => {
            this.DanhSachChiDinhBenhNhan();
            this.loadDoctor();
            this.loadDanhSachNhom();
            this.loadMeTe();
        })
    }
    resetModal() {
        this.setState(init);
        this.form.setFieldsValue({
            mapt: null,
            nhom: 0,
            loai: null,
            mabs: null,
            gayme: null,
            phumo: null,
            ghichu: null,
            ppvocam: null,
        })
    }
    loadThongTinThuThuatBenhNhan(idchidinh) {
        if (this.props.thuthuat === true) {
            PTTTServices.ThongTinThuThuatBenhNhan(this.state.code, this.state.managerId, this.state.detail.mavp, this.state.detail.ngay, idchidinh).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        idpttt: result.item.idpttt,
                        nhom: result.item.nhompt,
                        loai: result.item.loaipt,
                        mapt: result.item.mapt,
                        ghichu: result.item.ketluan,
                        ppvocam: result.item.phuongphap,
                        saupt: result.item.tencdsau,
                        truocpt: result.item.tencdtruoc,
                        ngaykt: moment(result.item.ngaykt),
                        giokt: moment(result.item.ngaykt),
                        mabs: result.item.mabs,
                        phumo: result.item.phumo,
                        gayme: result.item.gayme,
                    });
                    this.form.setFieldsValue({
                        nhom: Number(result.item.nhompt),
                        loai: result.item.loaipt,
                        mapt: result.item.mapt,
                        ghichu: result.item.ketluan,
                        ppvocam: result.item.phuongphap,
                        //saupt:result.item.tencdsau,
                        //truocpt:result.item.tencdtruoc,
                        ngaykt: moment(result.item.ngaykt),
                        giokt: moment(result.item.ngaykt),
                        mabs: result.item.mabs,
                        phumo: result.item.phumo,
                        gayme: result.item.gayme,
                    });
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu nhóm bị lỗi' })
            })
        } else {
            ChiDinhService.ThongTinDongYBenhNhan(this.state.code, this.state.managerId, this.state.detail.mavp, this.state.detail.ngay, idchidinh).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        idpttt: result.item.idpttt,
                        ghichu: result.item.ketluan,
                        ngaykt: moment(result.item.ngaykt),
                        giokt: moment(result.item.ngaykt),
                        mabs: result.item.mabs,
                    });
                    this.form.setFieldsValue({
                        ghichu: result.item.ketluan,
                        ngaykt: moment(result.item.ngaykt),
                        giokt: moment(result.item.ngaykt),
                        mabs: result.item.mabs,
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tải dữ liệu nhóm bị lỗi' })
            })
        }
        this.getICDs(this.state.managerId, this.state.detail.mavp);
    }
    editAmount(row, soluong) {
        let { lstChiDinhBenhNhan } = this.state
        let index = lstChiDinhBenhNhan.findIndex(record => record.id === row.id);
        lstChiDinhBenhNhan[index].soluong = soluong;
        let tongtienNew = 0;

        for (let item of lstChiDinhBenhNhan) {
            if (item.dongia)
                tongtienNew += item.soluong * item.dongia * ((100 - item.discount) / 100);
        }
        this.setState({ lstChiDinhBenhNhan, tongtienNew })
    }
    loadDanhSachNhom() {
        ChiDinhService.DanhSachLoaiChiDinh('', 1).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhom: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({
                        nhom: result.data[0].value,
                    });
                    this.form.setFieldsValue({ nhom: result.data[0].value })
                    this.loadDanhSachLoaiPhauThat(result.data[0].value);
                }
            }
        }).catch(err => {
            //notification.warning({ message: 'Tải dữ liệu nhóm bị lỗi' })
        })
    }
    loadMeTe() {
        PhauThuThuatService.DanhSachMeTe().then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstMeTe: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu vô cảm bị lỗi!' });
        })
    }

    loadDanhSachLoaiPhauThat(key) {
        PhauThuThuatService.DanhSachLoaiPhauThat(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoai: result.data,
                });
                if (this.state.loai === '') {
                    if (result.data.length > 0) {
                        this.setState({
                            loai: result.data[0].value,
                        });
                        this.form.setFieldsValue({ loai: result.data[0].value })
                        this.loadDanhSachTenPhauThat(result.data[0].value);
                    }
                } else {
                    this.loadDanhSachTenPhauThat(this.state.loai);
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu loại bị lỗi' })
        })
    }
    loadDanhSachTenPhauThat(key) {
        PhauThuThuatService.DanhSachTenPhauThat(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTenpt: result.data,
                });
                if (this.state.mapt === '') {
                    if (result.data.length > 0) {
                        this.setState({
                            mapt: result.data[0].value,
                            tenpt: result.data[0].label,
                        });
                        this.form.setFieldsValue({ mapt: result.data[0].value })
                    }
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu ten bị lỗi' })
        })
    }
    loadDoctor() {
        CommonService.GetListDoctorTT(1, 200).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstMabs: result.data,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }
    DanhSachLoaiChiDinh(key) {
        ChiDinhService.DanhSachLoaiChiDinh(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiChiDinh: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu loại chỉ định lỗi' })
        })
    }

    DanhSachChiDinh(key) {
        ChiDinhService.DanhSachChiDinh(key, this.state.loaiChiDinh, this.state.subjectCode, this.state.cardNumber, this.state.department).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstChiDinh: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu chỉ định lỗi' })
        })
    }
    DanhSachChiDinhBenhNhan() {
        if (this.props.thuthuat === true) {
            ChiDinhService.DanhSachThuThuatBenhNhan(this.state.code, this.state.managerId, this.state.dateIn, this.props.detail.vPid).then(result => {
                if (result.isSuccess) {
                    if (result.data.length > 0) {
                        let row = result.data.find(x => x.idChiDinh === this.props.detail.idChiDinh)
                        if (row.idpttt === '' || row.idpttt === null)
                            this.setState({ isEdit: true });
                        this.setState({ isModal: true, detail: row }, () => { this.loadThongTinThuThuatBenhNhan(this.props.detail.idChiDinh) })
                    }
                    this.setState({
                        lstChiDinhBenhNhan: result.data,
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog('Lỗi', err);
            })
        } else {
            ChiDinhService.DanhSachDongYBenhNhan(this.state.code, this.state.managerId, this.state.dateIn, this.props.detail.vPid).then(result => {
                if (result.isSuccess) {
                    if (result.data.length > 0) {
                        let row = result.data.find(x => x.ma === this.props.detail.idChiDinh)
                        if (row.idpttt === '' || row.idpttt === null)
                            this.setState({ isEdit: true });
                        this.setState({ isModal: true, detail: row }, () => { this.loadThongTinThuThuatBenhNhan(this.props.detail.idChiDinh) })
                    }
                    this.setState({
                        lstChiDinhBenhNhan: result.data,
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog('Lỗi', err);
            })
        }
    }

    async LuuThuThuat() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            Actions.setLoading(true);
            const data = {
                'ngay': moment(this.state.detail.ngay, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD HH:mm'),
                'idChiDinh': this.state.detail.idChiDinh,
                'idpttt': this.state.idpttt,
                'ngaykt': this.state.ngaykt.format('YYYY/MM/DD') + ' ' + this.state.giokt.format('HH:mm'),
                'mavaovien': this.state.enterNumber,
                'mabn': this.state.code,
                'tencdtruoc': this.state.truocpt,
                'tencdsau': this.state.saupt,
                'mabs': this.state.mabs,
                'phumo': this.state.phumo,
                'gayme': this.state.gayme,
                'ketluan': this.state.ghichu,
                'mapt': this.state.mapt,
                'tenpt': this.state.tenpt,
                'maql': this.state.managerId,
                'phuongphap': this.state.ppvocam,
                'makp': this.state.department,
                'mavp': Number(this.state.detail.mavp),
            }
            ChiDinhService.LuuThuThuat(data).then(result => {
                if (result.isSuccess) {
                    this.CreateXuatKho.SaveDon(result.id);
                    this.saveICDs(this.state.managerId, this.state.detail.mavp);
                    this.resetModal();
                    this.closeModal();
                    notification.success({ message: "Lưu kết quả thành công" });
                    this.props.onReload();
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString)
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err)
            });
        }
    }

    closeModal() {
        if (this.props.closeModal) {
            this.props.closeModal()
        }
    }

    getListDiagnostic(key) {
        let skip = 0;
        let maxCount = 10;
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxCount,
            keyword: key,
        }
        CommonService.GetListICD10(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                data = data.map(x => ({ ...x, label: `${x.value} - ${x.label}` }))
                this.setState({
                    danhSachICD: data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", 'Lỗi tải danh chẩn đoán ICD');
        })
    }

    setSelectedICD(item) {
        let lst = [];
        item.map(x => {
            lst.push({ value: x.key, label: x.children })
        })
        this.setState({
            ICD: lst,
        });
    }

    getICDs(managerId, mavp) {
        ChiDinhService.getICDs(managerId, mavp).then((result) => {
            if (result.isSuccess) {
                let data = [];
                data = result.data.map(x => ({ value: x.value, label: x.label }));
                this.setState({
                    ICD: data,
                    danhSachICD: data,
                });
            }
        }).catch((error) => {
            Actions.openMessageDialog("Lỗi", error.err.msgString)
        }).finally(() => {
            this.forceUpdate();
        })
    }

    saveICDs(managerId, mavp) {
        const data = {
            managermentID: managerId,
            serviceID: Number(mavp),
            list: this.state.ICD,
        }
        ChiDinhService.saveICDs(data).then((result) => {
            if (!result.isSuccess && result.err.msgString) {
                Actions.openMessageDialog("Lỗi lưu chẩn đoán", result.err.msgString);
            }
        }).catch((error) => {
            Actions.openMessageDialog("Lỗi", error.err.msgString)
        })
    }
    
    onClose() {
        this.setState({ isModal: false });
        this.closeModal();
        this.props.onReload();
    }

    render() {
        const columns = [

            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Trạng thái',
                render: (record) => {
                    return (
                        <span>{record.idpttt === null || record.idpttt === '' ? "Mới" : "Hoàn thành"}</span>
                    )
                },
            },
        ];
        return (
            <React.Fragment>
                {this.state.lstChiDinhBenhNhan.length === 0 ?
                    <div>
                        {/* <strong>Bệnh nhân này không có thủ thuật nào</strong> */}
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" style={{ marginLeft: 10 }} danger
                                onClick={() => {
                                    this.setState({ isModal: false });
                                    this.closeModal();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </Button>
                        </div>
                    </div>
                    :
                    <div>
                        <Form ref={(c) => this.form = c}>
                            {this.state.isModal ?
                                <div>
                                    <HeaderModal title={`Kết quả ${this.props.thuthuat === true ? 'thủ thuật' : 'vật lý trị liệu'}`}
                                        onClose={() => {
                                            this.onClose();
                                        }} />
                                    <div className="row">
                                        <div className="col-sm-12 col-md-5 col-lg-6">
                                            <div className="row">
                                                <div className="col-md-6 mb-2">
                                                    <div><strong>Thông tin bệnh nhân</strong></div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Họ và tên</div>
                                                        <div>{this.props.detail.patientName}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>{this.state.ageForMonth < 12 ? "Tháng tuổi" : "Tuổi"}</div>
                                                        <div>
                                                            {this.state.ageForMonth < 12 ? `${this.state.ageForMonth} tháng` : this.state.ageForYear}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Giới tính</div>
                                                        <div>{this.props.detail.gender}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Mã bệnh nhân</div>
                                                        <div>{this.props.detail.patientID}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Địa chỉ</div>
                                                        <div>{this.props.detail.address}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-2">
                                                    <div><strong>Thông tin khám</strong></div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>BS chỉ định</div>
                                                        <div>{this.state.detail.tenbs}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Chỉ định</div>
                                                        <div>{this.state.detail.ten}</div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px', flexDirection: "column", }}>
                                                        <label>Chẩn đoán ban đầu</label>
                                                        <div className='w-100'>
                                                            <AutocompleteMulti
                                                                disabled={this.props.disabledSave === false || this.state.isEdit === false}
                                                                className="w-100"
                                                                width={'100%'}
                                                                dataSource={this.state.danhSachICD}
                                                                handleSearch={(e) => this.getListDiagnostic(e)}
                                                                getItem={(item) => { this.setSelectedICD(item); }}
                                                                value={this.state.ICD}
                                                                title="Chẩn đoán ban đầu" />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                                                        <div style={{ width: '120px' }}>Ngày chỉ định</div>
                                                        <div>{moment(this.state.detail.ngay, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm')}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div><strong>Thông tin thực hiện</strong></div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-2">
                                                            <Form.Item label="Người thực hiện:" style={{ flex: 1 }}
                                                            // rules={[{ required: true, message: 'Vui lòng chọn bác sĩ !' }]}
                                                            >
                                                                <Select
                                                                    onChange={(value) => { this.setState({ mabs: value }) }}
                                                                    value={this.state.mabs}
                                                                    showSearch
                                                                    disabled={this.props.disabledSave === false || this.state.isEdit === false}
                                                                    allowClear={true}
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {this.state.lstMabs.map((row, index) => {
                                                                        return (
                                                                            <Option key={index} value={row.value}>{row.label}</Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <Form.Item label="Ngày thực hiện:" style={{ flex: 1 }}>
                                                                <DatePicker locale={viVN} className="ant-w-50" disabled={this.props.disabledSave === false || this.state.isEdit === false} allowClear={false} value={this.state.ngaykt} format={"DD-MM-YYYY"} placeholder="DD-MM-YYYY" onChange={(date) => { this.setState({ ngaykt: date }) }} />
                                                                <TimePicker locale={viVN} className="ant-w-50" disabled={this.props.disabledSave === false || this.state.isEdit === false} allowClear={false} value={this.state.giokt} format={'HH:mm'} onChange={(date) => { this.setState({ giokt: date }) }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col-12 mb-2 d-none">
                                                            <div><Checkbox>Một người thực hiện</Checkbox></div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Form.Item label="Kết luận/Ghi chú:" style={{ width: '100%' }} rules={[{ required: true, message: 'Vui lòng nhập kết luận/ghi chú !' }]}>
                                                                <TextArea disabled={this.props.disabledSave === false || this.state.isEdit === false} value={this.state.ghichu} onChange={(e) => this.setState({ ghichu: e.target.value })} rows={5}></TextArea>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <strong>Lịch sử khám bệnh: </strong>
                                                    <Button onClick={() => { this.setState({ HistoryDrawer: true }) }} style={{ marginLeft: '5px' }}>Xem</Button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-7 col-lg-6">
                                            <div style={{ flex: 1, padding: 5 }}>
                                                <CreateXuatKho ref={((c) => this.CreateXuatKho = c)}
                                                    thuthuat={this.props.thuthuat}
                                                    maql={this.state.managerId}
                                                    detail={this.state.detail} mabn={this.state.code} mavp={this.state.detail.mavp} />
                                            </div>
                                        </div>
                                    </div>
                                    <Drawer
                                        title="Lịch sử khám bệnh"
                                        placement="right"
                                        width={400}
                                        closable={true}
                                        onClose={() => this.setState({ HistoryDrawer: false })}
                                        visible={this.state.HistoryDrawer}
                                    >
                                        {this.state.code &&
                                            <History iShowResult={true} patientID={this.state.code}></History>
                                        }
                                    </Drawer>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5, alignItems: 'center' }}>
                                        {this.state.isEdit === true ?
                                            <button onClick={() => this.LuuThuThuat()}
                                                className="btn btn-color" style={this.props.disabledSave === false ? { display: "none" } : {}} key="submit" >
                                                <i className="fa fa-save" style={{ marginRight: 5 }} /> Lưu</button>
                                            :
                                            <button onClick={() => this.setState({ isEdit: true })}
                                                className="btn btn-color">
                                                <i className="fa fa-edit" style={{ marginRight: 5 }} />  Cập nhật</button>
                                        }
                                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                            onClick={() => {
                                                this.onClose();
                                            }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                            /> Đóng
                                        </button>
                                    </div>
                                </div> : ''}
                        </Form>
                        <Table style={{ display: 'none' }} dataSource={this.state.lstChiDinhBenhNhan} rowKey={(e, index) => index} columns={columns} pagination={false}
                            onRow={(row, rowIndex) => {
                                return {
                                    onClick: e => {
                                        if (row.idpttt === '' || row.idpttt === null)
                                            this.setState({ isEdit: true });
                                        this.setState({ isModal: true, detail: row }, () => { this.loadThongTinThuThuatBenhNhan() })
                                    },
                                };
                            }}
                            rowClassName={(record, rowIndex) => {
                                return "rowCustom"
                            }}
                        />
                        {/* <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Button type="primary" style={{ marginLeft: 10 }} danger
                                onClick={() => {
                                    this.setState({ isModal: false });
                                    this.closeModal();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng

                            </Button>
                        </div> */}
                    </div>
                }
            </React.Fragment>
        )
    }
}
