import { Button, Form, Input, Pagination, DatePicker, Table, notification, Tooltip, Modal, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../libs/actions';
import * as SurveyService from './SurveyService';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import PhieuKhaoSat from "../recept/register/survey";
import * as SystemConfig from "../../configure/systemConfig";
const { RangePicker } = DatePicker;
export default function Survey() {
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [surveyData, setSurveyData] = useState([]);
    const [date, setDate] = useState([moment(), moment()]);
    const [vsbDetail, setVsbDetail] = useState(false);
    const [visibleSurvey, setVisibleSurvey] = useState(false);

    useEffect(() => {
        GetListSurvey()
    }, [currentPage, pageSize]);

    const GetListSurvey = (page = currentPage, size = pageSize) => {
        Actions.setLoading(true);
        SurveyService.GetListSurveys(key, page, size, date[0].format("YYYY/MM/DD"), date[1].format("YYYY/MM/DD")).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords)
            }
            else {
                if (result.err.msgString) notification.warning({ message: result.err.msgString })
                else notification.warning({ message: "Đã xảy ra lỗi truy xuất dữ liệu, vui lòng thử lại sau" });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const getDetail = (id) => {
        if (id !== undefined && id > 0) {
            Actions.setLoading(true);
            SurveyService.GetSurveyDetail(id).then(result => {
                if (result.isSuccess) {
                    setSurveyData(result.data[0]);
                }
                else {
                    Actions.setLoading(false);
                }
            }).catch(err => {
                Actions.setLoading(false);
                console.log(err);
            });
            setVsbDetail(true);
        }
        else {
            notification.warning({ message: "Không nhận được thông tin khảo sát" })
        }
    }

    const deleteSurvey = (id) => {
        if (id !== undefined && id > 0) {
            SurveyService.DeleteSurvey(id).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Đã xóa thành công" });
                    GetListSurvey();
                }
                else {
                    notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
                }
            }).catch(err => {
            });
        }
        else {
            notification.warning({ message: "Không nhận được thông tin khảo sát" })
        }
    }
    const columns = [
        {
            title: 'Mã khách hàng',
            dataIndex: 'mabn',
            key: 'mabn',
            width: 150,
            align: "center",
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'tenbn',
            key: 'tenbn',
            width: 400
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'ngay',
            key: 'ngay',
            width: 150,
            align: "center",
            render: (ngay) => (
                <span>{moment(ngay).format(SystemConfig.FORMAT_TIME_DATE)}</span>
            )
        },
        {
            title: 'Thao tác',
            align: 'center',
            render: (row) => (
                <div>
                    <Tooltip title="Xem chi tiết">
                        <button className='btn btn-success' onClick={() => { getDetail(row.id) }}
                        ><i className='fas fa-eye'></i></button>
                    </Tooltip>
                    <Tooltip title="Xóa">
                        <Popconfirm
                            title="Bạn có chắc muốn xóa?"
                            onConfirm={() => { deleteSurvey(row.id) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}><i className="fa fa-trash" /></button>
                        </Popconfirm>
                    </Tooltip>
                </div>
            ),
        }
    ];
    return (
        <div className='containerChilderWrapper'>
            <div className='h4 text-center mb-2 font-bold text-uppercase'>Danh sách khảo sát khách hàng</div>
            <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-auto">
                    <Form.Item>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={date}
                            style={{ width: '200px' }}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(e) => { setDate(e) }}
                        />
                    </Form.Item>
                </div>
                <div className="col-md-4">
                    <Form.Item>
                        <Input
                            value={key}
                            placeholder="Nhập tên hoặc mã bệnh nhân và bấm Enter để tìm kiếm"
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    GetListSurvey(1);
                                }
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="col-auto">
                    <Form.Item>
                        <Button className="btn btn-color" onClick={() => {
                            GetListSurvey(1);
                        }
                        }>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                        </Button>
                    </Form.Item>
                </div>
                <div className='col-auto'>
                    <Form.Item>
                        <Button className="btn btn-color"
                            onClick={() => {
                                setVisibleSurvey(true);
                            }} >
                            <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo khảo sát
                        </Button>
                    </Form.Item>
                    <Modal
                        maskClosable={false}
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                        visible={visibleSurvey}
                        width={1000}
                    ><PhieuKhaoSat detailPatient={{}} onCancel={(visible) => setVisibleSurvey(visible)} />
                    </Modal>
                </div>
            </div>
            <div style={{ height: 'calc(100% - 110px)' }} className="mt-2">
                <Table dataSource={dataSource}
                    scroll={{ y: 0, x: 'max-content' }}
                    pagination={false}
                    columns={columns} rowKey={(e, index) => index}
                />
            </div>
            <Pagination
                current={currentPage}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageSize(size) }}
                showSizeChanger={true}
            />
            <Modal
                width={1000}
                maskClosable={true}
                visible={vsbDetail}
                onCancel={() => setVsbDetail(false)}>
                <div className='d-flex justify-content-end w-100 mb-2'>
                    <Tooltip title="Đóng">
                        <button className='btn btn-danger' onClick={() => { setVsbDetail(false); setSurveyData([]) }}>
                            <i className="fas fa-times"></i></button>
                    </Tooltip>
                </div>
                <div className="row content-container mr-auto ml-auto">
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Họ tên:</span><strong className="ml-2">{surveyData.tenbn}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Giới tính:</span><strong className="ml-2">{surveyData.gioitinh}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}> {surveyData.chinamsinh ? "Năm sinh" : "Ngày sinh"}:</span>
                        <strong className="ml-2">
                            {surveyData.chinamsinh ?
                                moment(surveyData.ngaysinh).format("YYYY")
                                : surveyData.ngaysinh && moment(surveyData.ngaysinh).format(SystemConfig.FORMAT_DATE)}
                        </strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Email:</span><strong className="ml-2">{surveyData.email}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Điện thoại:</span><strong className="ml-2">{surveyData.dienthoai}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>CMND/CCCD:</span><strong className="ml-2">{surveyData.cmnd}</strong>
                    </div>
                    <div className="col-md-12 my-1 d-flex">
                        <span style={{ width: '100px' }}>Địa chỉ:</span><strong className="ml-2">{surveyData.diachi}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Chiều cao:</span><strong className="ml-2">{surveyData.chieucao && surveyData.chieucao > 0 ? surveyData.chieucao : "..."} (cm)</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '100px' }}>Cân nặng:</span><strong className="ml-2">{surveyData.cannang && surveyData.cannang > 0 ? surveyData.cannang : "..."} (kg)</strong>
                    </div>
                    <div className="col-md-12 my-1 d-flex">
                        <span style={{ width: '150px' }}>Tiền sử bệnh:</span><strong className="ml-2">{surveyData.tiensu}</strong>
                    </div>
                    <div className="col-md-12 my-1 d-flex">
                        <span style={{ width: '150px' }}>Bệnh lý đang quan tâm:</span><strong className="ml-2">{surveyData.benhly}</strong>
                    </div>
                    <div className="col-md-6 my-1 d-flex">
                        <span style={{ width: '150px' }}>Tình trạng hôn nhân:</span><strong className="ml-2">{surveyData.honnhan ? surveyData.honnhan : <i>Không chia sẻ</i>}</strong>
                    </div>
                </div>
                <div className="ant-modal-header text-uppercase mt-2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3>Chi tiết khảo sát</h3>
                </div>
                <div className="table-responsive content-container mr-auto ml-auto" style={{ backgroundColor: '#fff' }}>
                    <table className="table table-striped table-inverse table-hover table-bordered">
                        <thead className='btn-color'>
                            <td style={{ width: 80 }}>STT</td>
                            <td>Câu hỏi</td>
                            <td>Câu trả lời</td>
                        </thead>
                        <tbody>
                            {
                                surveyData.listQuestions && surveyData.listQuestions.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.question}</td>
                                            <td><strong>{item.answer}</strong></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Modal>
        </div>
    )

}