import * as ApiCaller from '../../../libs/httpRequests';
const prefix='Instruction';

export function getMedicinesList(key, page, size, flag) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetMedicinesList?key=${key}&page=${page}&size=${size}&flag=${flag}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createUpdateInstruction(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/CreateUpdateInstruction`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportMedicinesList(flag) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportMedicinesList/${flag}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function importMedicinesList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportMedicinesInstruction`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};