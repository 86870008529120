import React, { Component } from 'react';
import { Form, Input, Table, Modal, Pagination, Menu, Select, Dropdown, Popconfirm, notification, Radio, Upload, Tag } from 'antd';
import CreateDanhMuc from './createDanhMuc';
import * as cfgService from '../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';

const initialState = {
    currentPage: 1,
    pageSize: 10,
    visible: false,
    visibleCreate: false,
    visibleImport: false,
    totalRecords: 0
}
let task = null;
const { Option } = Select;
const { Dragger } = Upload;

export default class MaDichVuKyThuatTuongDuong extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
            key: "",
            loadingHide: 0,
            lstdanhmuc: [],
            madichvu: "",
            tendichvu: "",
            iddichvu: 0,
            tendichvubhyt: "",
            matuongduong: "",
            malienthong: "",
            lstdanhmucmadvkt: [],
            nhom: null,
            value: null,
            radiocheck: 0,
            fileList: [],
            loaded: 0,
            uploading: false,
            updateRowCount: 0,
            insertRowCount: 0,
            showImportResult: false
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.loadDanhMuc();
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    }
    onReload = () => {
        this.validator.hideMessages();
        this.loadDanhMuc();
        this.setState({
            nhom: null
        });
    }
    loadDanhMuc = (page = this.state.currentPage, size = this.state.pageSize) => {
        cfgService.getDanhMucMaDichVuKyThuatTuongDuong(this.state.key, this.state.radiocheck, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdanhmuc: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(() => {
            notification.warning({ message: "Đã xảy ra lỗi khi tải dữ liệu danh mục mã dịch vụ kỹ thuật tương đương" })
        })
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        })
    }
    updateDanhMuc = () => {
        if (this.validator.allValid()) {
            var { id, madichvu, tendichvu, iddichvu, tendichvubhyt, matuongduong, malienthong, nhom } = this.state;
            const data = {
                id: id,
                madichvu: madichvu,
                tendichvu: tendichvu,
                iddichvu: iddichvu,
                ten: tendichvubhyt,
                matuongduong: matuongduong,
                malienthong: malienthong,
                nhom: nhom
            }
            cfgService.updateDanhMuc(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Cập nhật thành công" });
                    this.onReload();
                    this.setState({
                        madichvu: null,
                        tendichvu: null,
                        iddichvu: null,
                        tendichvubhyt: null,
                        matuongduong: null,
                        malienthong: null,
                        visible: false
                    });
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteDanhMuc = (id) => {
        cfgService.deleteDanhMuc(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadDanhMuc();
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(() => {
            notification.warning({ message: 'Đã xảy ra lỗi khi xóa!' });
        })
    }
    ExportLoaiDichVu = () => {
        Actions.setLoading(true);
        cfgService.ExportLoaiDichVu(this.state.radiocheck).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "DanhMucMaDichVuKyThuatTuongDuong";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    loadSelectBox() {
        var { nhom } = this.state;
        cfgService.getSelectBoxDichVuByNhom(nhom).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdanhmucmadvkt: result.data
                });
            }
        }).catch(() => {
        })
    }
    TemplateLoaiDichVu = () => {
        Actions.setLoading(true);
        cfgService.TemplateLoaiDichVu().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Template_DanhMucMaDichVuKyThuatTuongDuong";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        this.setState({
            uploading: true
        });
        cfgService.importDanhSach(formData).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Hoàn tất import dữ liệu" });
                this.setState({
                    uploading: false,
                    fileList: [],
                    updateRowCount: res.updateRowCount,
                    insertRowCount: res.insertRowCount,
                    showImportResult: true
                });
                this.loadDanhMuc();
            }
            else {
                notification.warning({ message: res.err.msgString });
                this.setState({
                    uploading: false
                });
            }
        }).catch(err => {
            this.setState({
                uploading: false
            });
        })
    }
    render() {
        let { fileList } = this.state;
        const props = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        };
        let dataSource = this.state.lstdanhmuc.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1)
            },
            {
                title: 'Tên theo danh mục giá Thông tư 39/2018/TT-BYT',
                dataIndex: 'ten',
                key: 'ten'
            },
            {
                title: 'Mã tương đương',
                dataIndex: 'matuongduong',
                key: 'matuongduong'
            },
            {
                title: 'Mã giá liên thông BHYT',
                dataIndex: 'malienthong',
                key: 'malienthong'
            },
            // {
            //     title: 'Hiển thị',
            //     align: 'center',
            //     render: (row) => {
            //         return (
            //             <Switch
            //                 loading={this.state.loadingHide}
            //                 checkedChildren="Hiển thị"
            //                 unCheckedChildren="Ẩn"
            //                 checked={row.hienthi === 0}
            //                 onChange={(e) => { this.updateHide(row.id, e); }}
            //             />
            //         )
            //     }
            // },
            {
                title: 'Mã dịch vụ',
                dataIndex: 'madichvu',
                key: 'madichvu'
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tendichvu',
                key: 'tendichvu'
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type="button" className="btn btn-warning"
                                onClick={() => {
                                    this.setState({
                                        id: row.id,
                                        madichvu: row.madichvu,
                                        tendichvubhyt: row.ten,
                                        tendichvu: row.tendichvu,
                                        matuongduong: row.matuongduong,
                                        malienthong: row.malienthong,
                                        value: row.madichvu ? `[${row.madichvu}] - ${row.tendichvu}` : null,
                                        nhom: null,
                                        visible: true
                                    });
                                }}>
                                <i className="fa fa-edit" style={{ marginRight: 5 }} /> Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteDanhMuc(row.id) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: "15px" }}>
                                    <i className="fa fa-trash-alt" style={{ marginRight: 5 }} />Xóa
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ]
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.TemplateLoaiDichVu()}>Tải file excel mẫu</button>
                </Menu.Item>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ visibleImport: true })}>Import dữ liệu</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={500}
                    visible={this.state.visibleImport}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div >
                            <Dragger
                                {...props}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}
                                onChange={info => {
                                    this.setState({
                                        loaded: info.file.percent,
                                    })
                                    if (info.file.status === 'uploading') {
                                        notification.info({ message: `Đã upload: ${info.file.percent} %` })
                                    }
                                    if (info.file.status === 'done') {
                                        notification.success({ message: "Đã hoàn tất upload" });
                                        this.handleUpload();
                                    } else if (info.file.status === 'error') {
                                        notification.error({ message: `Không thể upload file: ${info.file.name}` });
                                    }
                                }}>
                                <div style={{ position: 'relative', width: 400 }}>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                            <p style={{ textTransform: "uppercase", textAlign: "center", display: this.state.showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                            <p style={{ display: this.state.updateRowCount > 0 ? 'flex' : 'none' }}>Cập nhật:{'\u00A0'}<Tag color="red">{`${this.state.updateRowCount}`}</Tag> hàng dữ liệu.</p>
                            <p style={{ display: this.state.insertRowCount > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="red">{`${this.state.insertRowCount}`}</Tag> hàng dữ liệu.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.handleUpload()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        visibleImport: false,
                                        fileList: [],
                                        updateRowCount: 0,
                                        insertRowCount: 0,
                                        showImportResult: false
                                    });
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        );
        return (
            <React.Fragment>
                <Form>
                    <div style={{ padding: '30px 10px' }}>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <div className='h4 text-center mt-2 font-bold text-uppercase'>Cấu hình danh mục mã dịch vụ kỹ thuật tương đương</div>
                        <div
                            className="row no-gutters"
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                            <div className="row no-gutters" style={{ width: "90vw" }}>
                                <div className="col-md-12">
                                    <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                                        <Form.Item>
                                            <Input
                                                placeholder="Nhập tên hoặc mã dịch vụ, kỹ thuật để tìm kiếm"
                                                value={this.state.key}
                                                onChange={(e) => this.setState({
                                                    key: e.target.value
                                                })}
                                                style={{
                                                    width: 400
                                                }}
                                                onKeyDown={() => {
                                                    task = setTimeout(() => {
                                                        this.loadDanhMuc();
                                                    }, 900);
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Radio.Group
                                                value={this.state.radiocheck}
                                                onChange={(e) => {
                                                    this.setState({
                                                        radiocheck: e.target.value
                                                    });
                                                    task = setTimeout(() => {
                                                        this.loadDanhMuc();
                                                    }, 500);
                                                }}
                                            >
                                                <Radio value={0}>Tất cả</Radio>
                                                <Radio value={1}>Chưa mapping</Radio>
                                                <Radio value={2}>Đã mapping</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item>
                                            <button className="btn btn-success"
                                                onClick={this.showModalCreate}>
                                                <i className="fa fa-plus" style={{ marginRight: '10px' }} />
                                                Tạo mới
                                            </button>
                                        </Form.Item>
                                        <Dropdown
                                            overlay={menu} trigger={['click']}
                                            onClick={e => e.preventDefault()}>
                                            <Form.Item>
                                                <button className="btn btn-primary">
                                                    <i className="fa fa-upload" style={{ marginRight: '10px' }} />
                                                    {this.state.uploading ? "Đang tải lên" : "Nhập danh sách"}
                                                </button>
                                            </Form.Item>
                                        </Dropdown>
                                        <Form.Item>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.ExportLoaiDichVu()}
                                            >
                                                <i className="fas fa-download" style={{ marginRight: '10px' }} />
                                                Xuất danh sách
                                            </button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                rowkey={(index) => index}
                                pagination={false}
                                scroll={{ y: 0 }}
                            />
                        </div>
                        <Modal
                            maskClosable={true}
                            width={900}
                            visible={this.state.visibleCreate}
                            cancelText="Đóng"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}
                        >
                            <CreateDanhMuc
                                onCancel={this.handleCancel}
                                onReload={this.onReload}
                            />
                        </Modal>
                        <Modal maskClosable={true}
                            width={900}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <Form ref={(c) => this.form = c}>
                                <div style={{ margin: 10 }}>
                                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                        <h3>Cập nhật mapping mã dịch vụ kỹ thuật tương đương</h3>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: "10px" }} >
                                        <div className="col-md-6">
                                            <Form.Item label="Mã tương đương:">
                                                <Input
                                                    name="matuongduong"
                                                    value={this.state.matuongduong}
                                                    placeholder="Nhập mã tương đương"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            matuongduong: e.target.value
                                                        })
                                                    }}
                                                />
                                                {this.validator.message("Mã tương đương", this.state.matuongduong, "required")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Item label="Mã liên thông:">
                                                <Input
                                                    name="malienthong"
                                                    placeholder="Nhập mã liên thông giá BHYT"
                                                    value={this.state.malienthong}
                                                    onChange={(e) => this.setState({
                                                        malienthong: e.target.value
                                                    })}
                                                />
                                                {this.validator.message("Mã liên thông", this.state.malienthong, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10 }} >
                                        <div className="col-md-12">
                                            <Form.Item label="Tên dịch vụ BHYT:">
                                                <Input
                                                    name="tendichvubhyt"
                                                    value={this.state.tendichvubhyt}
                                                    placeholder="Nhập tên theo danh mục giá Thông tư 39/2018/TT-BYT"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            tendichvubhyt: e.target.value
                                                        })
                                                    }}
                                                />
                                                {this.validator.message("Tên dịch vụ bảo hiểm y tế", this.state.tendichvubhyt, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "20px" }}>
                                        <hr className="btn-color" style={{ width: "100%" }} />
                                        <p style={{ textTransform: 'uppercase', position: 'absolute', transform: "translateY(40%)", backgroundColor: "#fff", width: "50%" }}>Chọn dịch vụ kỹ thuật tương đương</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Item label="Chọn nhóm:">
                                                <Select
                                                    name="nhom"
                                                    placeholder="Chọn nhóm"
                                                    value={this.state.nhom}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            nhom: Number(e),
                                                            tendichvu: null,
                                                            value: null
                                                        });
                                                        task = setTimeout(() => this.loadSelectBox(), 500);
                                                    }}
                                                >
                                                    <Option value={1}>Nhóm viện phí</Option>
                                                    <Option value={2}>Nhóm vật tư</Option>
                                                    <Option value={3}>Nhóm thuốc</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-8">
                                            <Form.Item>
                                                <Select
                                                    disabled={this.state.nhom == null ? true : false}
                                                    showSearch
                                                    name="tendichvu"
                                                    placeholder="Nhập tên hoặc mã dịch vụ kỹ thuật cần tìm"
                                                    value={this.state.value}
                                                    optionFilterProp="children"
                                                    onChange={(e, data) => {
                                                        this.setState({
                                                            value: e,
                                                            tendichvu: data.children[3],
                                                            iddichvu: Number(data.value),
                                                            madichvu: data.children[1]
                                                        });
                                                    }}
                                                >
                                                    {this.state.lstdanhmucmadvkt.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.id}>[{row.ma}] - {row.ten}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.validator.message("Tên dịch vụ", this.state.tendichvu, "required")}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-success" onClick={() => this.updateDanhMuc()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }}></i>Cập nhật</button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.validator.hideMessages();
                                        this.setState({
                                            visible: false,
                                            nhom: null
                                        });
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadDanhMuc(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadDanhMuc(page, size)
                            }
                            showSizeChanger={true}
                        />
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}