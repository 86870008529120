import React, { Fragment, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Input, notification, AutoComplete, Empty } from 'antd';
import * as PatientService from '../../services/patientService';
import { capitalizeEveryFirstLeter } from '../../commons/utils';
import moment from 'moment';
import { FORMAT_DATE } from '../../configure/systemConfig';
import { isnotnull } from '../../libs/util';

let timeoutToSearch = null;

const PatientSearch = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        getKeyword: () => {
            return sendKeyWord();
        },
        clearData: () => {
            resetState();
        }
    }));

    const [key, setKey] = useState();
    const [listPatient, setListPatient] = useState([]);
    const [totalPatient, setTotalPatient] = useState(0);
    const [disabled, setDisabled] = useState(props.disable ?? false);

    const sendKeyWord = () => {
        return key;
    }

    useEffect(() => {
        if (props.maBN) {
            getPatientInfo(props.maBN);
        }
    }, [props.maBN]);

    useEffect(() => {
        setDisabled(props.disable);
    }, [props.disable]);

    const getListPatient = (keyword = key) => {
        let sortingAndPagingModel = { pageIndex: 1, pageSize: 20, sorting: "" };
        let filterModel = { Keyword: keyword, CoporationId: '' }
        PatientService.GetListPatientAsync(sortingAndPagingModel, filterModel).then(result => {
            if (result.isSuccess) {
                generateListOption(result.data);
            } else notification.error({ message: result.error.messageText });
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    const generateListOption = (data) => {
        let options = [];
        let patients = data.items;
        if (patients.length == 0) {
            options.push({
                label: (
                    <Empty description="Không có dữ liệu"></Empty>
                )
            })
        } else {
            for (let patient of patients) {
                let item = {
                    value: patient.maBN,
                    rawData: patient,
                    label: (
                        <div key={patient.maBN} className='row w-100 border-bottom'>
                            <div className='col-md-4 d-flex flex-column'>
                                <label className='mita-value text-uppercase'>{patient.hoTen}</label>
                                <label className='mita-value'>{patient.maBN}</label>
                            </div>
                            <div className='col-md-4 d-flex flex-column'>
                                <label className='mita-value text-uppercase'>{patient.phai === 0 ? "Nam" : "Nữ"}</label>
                                <label className='mita-value'><i className='fas fa-phone-alt mr-1' />{isnotnull(patient.dienThoai) ? patient.dienThoai : "--"}</label>
                            </div>
                            <div className='col-md-4 d-flex flex-column'>
                                <label className='mita-value text-uppercase'>{patient.onlyYear ? patient.namSinh : moment(patient.ngaySinh).format(FORMAT_DATE)}</label>
                                <label className='mita-value'><i className='fas fa-id-card mr-1' />{isnotnull(patient.cmnd) ? patient.cmnd : "--"}</label>
                            </div>
                        </div>
                    ),
                };
                options.push(item);
            };
        }
        setListPatient(options);
        setTotalPatient(data.totalCount ?? 0);
    }

    const handleSelectPatient = (patientCode) => {
        patientCode && getPatientInfo(patientCode);
    }

    const getPatientInfo = (patientCode) => {
        PatientService.GetPatientDetail(patientCode).then(result => {
            if (result.isSuccess) {
                setKey(result.data.hoTen);
                props.fillPatientInfo && props.fillPatientInfo(result.data);
            } else notification.error({ message: result.error.messageText });
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    const resetState = () => {
        setKey();
        setListPatient([]);
        setTotalPatient(0);
    }

    return (
        <Fragment>
            <label className='mita-title required-field'>Tên bệnh nhân</label>
            <AutoComplete
                options={listPatient ?? []}
                onSelect={(pid) => handleSelectPatient(pid)}
                onSearch={(key) => {
                    setKey(capitalizeEveryFirstLeter(key));
                    if (timeoutToSearch) {
                        clearTimeout(timeoutToSearch);
                        timeoutToSearch = null;
                    }
                    timeoutToSearch = setTimeout(() => {
                        getListPatient(key);
                    }, 450)

                }}
                dropdownMatchSelectWidth={700}
                listHeight={500}
                className="w-100 h-100"
                value={key}
                onBlur={() => {
                    props.getPatientName();
                }}
                disabled={disabled}>
                <Input placeholder='Nhập mã, tên, điện thoại, CCCD để tìm kiếm' disabled={disabled}></Input>
            </AutoComplete>
        </Fragment>
    );
})

export default PatientSearch;