import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Modal, notification, Table, Tag, Tooltip, Upload, Switch } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as TestService from '../../../services/lisService';
import * as ExamResultService from '../../../services/ExamResultServices';
import * as SystemConfig from '../../../configure/systemConfig';
import SendResult from './send-result';
const { Dragger } = Upload;
const { Panel } = Collapse;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maql: '',
            ngay: moment(),
            expandableRow: [],
            sidxn: '',
            lstKetQua: [],
            idbenhtrinh: this.props.idbenhtrinh ? this.props.idbenhtrinh : '',
            namsinh: this.props.namsinh ? this.props.namsinh : 0,
            done: this.props.done,
            listFile: [],
            fileUpload: [],
            title: '',
            fileUrlBlob: '',
            visible: false,
            detail: {},
            nhomvp: this.props.nhomvp ? this.props.nhomvp : 2,
            visibleSendResult: false,
            //Những thứ bắt buộc resultInfo phải có {patientID: '', sid:'', siteid:''}
            resultInfo: this.props.resultInfo,
            listUrlWebFileIds: [],
            ghiDeKetQua: false,
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.sidxn !== this.props.sidxn) {
            this.setState({ ngay: nextProps.ngay, maql: nextProps.maql, sidxn: nextProps.sidxn });
        }
        if (nextProps.idbenhtrinh !== this.props.idbenhtrinh) {
            this.setState({ idbenhtrinh: nextProps.idbenhtrinh }, () => { this.ThongTinKetQua() });
        }
        if (nextProps.nhomvp !== this.props.nhomvp) {
            this.setState({ nhomvp: nextProps.nhomvp }, () => { this.ThongTinKetQua() });
        }
        if (nextProps.resultInfo !== this.props.resultInfo) {
            this.setState({ resultInfo: nextProps.resultInfo }, () => { this.GetListLISFile(nextProps.resultInfo.maQL) });
        }
        return true;
    }
    static getDerivedStateFromProps(props, state) {
        return {
            maql: props.maql,
            ngay: props.ngay,
            sidxn: props.sidxn,
        }
    }
    componentDidMount() {
        this.ThongTinKetQua();
        this.GetListLISFile(this.props.maql)
    }
    CapNhatKetQua() {
        Actions.setLoading(true);
        CommonService.GetDataFromLis(this.state.maql, this.state.sidxn, this.props.chinhanh).then(result => {
            if (result.isSuccess) {
                this.ThongTinKetQua(false);
            }
            else {
                // Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    ThongTinKetQua(update = true) {
        Actions.setLoading(true);
        let noitru = this.props.noitru ? this.props.noitru : false;
        if (noitru !== true && this.state.sidxn !== '') {
            let chinhanh = '';
            if (this.props.chinhanh)
                chinhanh = this.props.chinhanh;
            TestService.ThongTinKetQua(this.state.maql, this.state.sidxn, chinhanh, noitru, this.state.nhomvp).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    if (noitru)
                        data = data.filter(x => x.idbenhtrinh === this.state.idbenhtrinh);
                    this.setState({ lstKetQua: data });
                    // Chưa nhận được kết quả từ LIS, danh sách xét nghiệm không rỗng thì tự động gọi LIS lấy kết quả
                    if (this.state.done !== "1" && update && data.length > 0) {
                        this.CapNhatKetQua()
                    }
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        } else {
            TestService.ThongTinKetQuaTheoBenhTrinh(this.state.idbenhtrinh).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    if (noitru)
                        data = data.filter(x => x.idbenhtrinh === this.state.idbenhtrinh);
                    this.setState({ lstKetQua: data });
                }
                else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
                Actions.setLoading(false);
            })
        }
    }
    checkColor(data) {
        if (isNaN(data.ketQua) || isNaN(data.mucBinhThuongMin) || isNaN(data.mucBinhThuongMax) || data.ketQua === "" || data.mucBinhThuongMin === "" || data.mucBinhThuongMax === "") {
            return 'normal';
        }
        else {
            if (Number(data.ketQua) < Number(data.mucBinhThuongMin)) {
                return 'bold';
            } else if (Number(data.ketQua) > Number(data.mucBinhThuongMax)) {
                return 'bold';
            }
        }
        return 'normal';
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            lst.push(index);
            this.setState({ expandableRow: lst })
        }
        else {
            lst = lst.filter(x => x !== index);
            this.setState({ expandableRow: lst })
        }
    }
    normalRangeFilter(range = "") {
        const { namsinh } = this.state;
        var normalRange = "";
        if (range.length > 0) {
            if (range.includes("tuổi") && namsinh !== 0) {
                const age = Number(moment().year()) - Number(namsinh);
                var arrayRange = range.split("\r\n");
                var arrayResult = [];
                var lowEnd = 0;
                var highEnd = 0;
                arrayRange.forEach(element => {
                    var ageRange = element.split("tuổi:")[0];
                    var valueRange = element.split("tuổi:")[1];
                    lowEnd = ageRange.split("-")[0];
                    highEnd = ageRange.split("-")[1];
                    var ageRangeArray = [];
                    for (var i = lowEnd; i <= highEnd; i++) {
                        ageRangeArray.push(Number(i));
                    }
                    arrayResult.push({ "age": ageRangeArray, "value": valueRange });
                });
                arrayResult.forEach(element => {
                    if (element.age.includes(age)) {
                        normalRange = `${element.value} (${lowEnd}-${highEnd} tuổi)`;
                    }
                });
                return normalRange;
            }
            return range;
        }
    }
    GetListLISFile(sid) {
        TestService.GetListLISFile(this.props.maql).then(result => {
            if (result.isSuccess) {
                let res = result.data;
                // if (res.length > 0)
                //     res[0].checked = true;
                this.setState({ 
                    listFile: res,
                    listUrlWebFileIds: res.filter(x => x.urlWebPatient?.length > 0).map(x => x.uuid)
                 })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }
    ViewResultPdf(data) {
        Actions.setLoading(true);
        TestService.DownLoadLisFile(data.uuid) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this.setState({ fileUrlBlob: url, visible: true });
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    SubmitUploadFile() {
        if (this.state.title === '' || this.state.title.trim() === '') {
            notification.warning({ message: "Vui lòng nhập tiêu đề!" });
            return;
        }
        Actions.setLoading(true);
        let formdata = new FormData();
        this.state.fileUpload.forEach(element => {
            formdata.append('listFile', element.originFileObj);
        });
        TestService.UploadFileResult(formdata, this.props.sidxn, this.props.chinhanh, this.state.title, this.state.ghiDeKetQua).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Upload file thành công!" });
                Actions.setLoading(false);
                this.GetListLISFile(this.props.maql);
                this.setState({
                    fileUpload: [],
                    title: ''
                })
            } else {
                notification.warning({ message: res.err.msgString });
            }
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    DownloadFile() {
        TestService.DownLoadLisFile(this.state.detail.uuid).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "KetQuaXetNgiem.pdf";
                this._hiddenLink.click();
            })
        }).catch(err => {
        })
    }

    customExpandIcon(props) {
        if (props.record.listSubTestResult && props.record.listSubTestResult.length > 0) {
            if (props.expanded) {
                return <button className="btn btn-color btn-sm" onClick={e => {
                    props.onExpand(props.record, e);
                }}><i className="fas fa-minus fa-1x" /></button>
            }
            else {
                return <button className="btn btn-color btn-sm" onClick={e => {
                    props.onExpand(props.record, e);
                }}><i className="fas fa-plus fa-1x" /></button>
            }
        }
    }

    sendFileToWebPatient(e, row) {
        Actions.setLoading(true);
        if (e) {
            ExamResultService.sendFileToWebPatient(row).then(result => {
                if (result.isSuccess) {
                    row.urlWebPatient = result.data;
                    this.setState({ 
                        listUrlWebFileIds: [...this.state.listUrlWebFileIds, row.uuid]
                     });
                    notification.success({ message: "Thành công" });
                }
                else {
                    notification.error({ message: "Lỗi " + result.error.messageText});
                }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    notification.error({ message: "Lỗi " + err.error.messageText});
                else 
                    notification.error({ message: "Lỗi " + err});
            }).finally(() => {
                Actions.setLoading(false);
            });
        }
        else {
            ExamResultService.recallFileToWebPatient(row.uuid).then(result => {
                if (result.isSuccess) {
                    row.urlWebPatient = '';
                    this.setState({ 
                        listUrlWebFileIds: this.state.listUrlWebFileIds.filter(x => x != row.uuid)
                     });
                    notification.success({ message: "Thành công" });
                }
                else {
                    notification.error({ message: "Lỗi " + result.error.messageText});
                }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    notification.error({ message: "Lỗi " + err.error.messageText});
                else 
                    notification.error({ message: "Lỗi " + err});
            }).finally(() => {
                Actions.setLoading(false);
            });
        }
    }

    deleteResultFile(uuid) {
        Actions.setLoading(true);
        ExamResultService.DeleteExamResultFile(uuid).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Xóa file thành công"})
                this.GetListLISFile(this.props.maql);
                Actions.setLoading(false);
            } else {
                notification.error({ message: "Không thể xóa file, vui lòng thử lại sau"});
                Actions.setLoading(false);
            }
        }).catch(() => {
            Actions.setLoading(false);
        })
    }

    render() {
        const columns = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'maDV',
                key: 'maDV',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Kết quả',
                dataIndex: 'ketQua',
                key: 'ketQua',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            { title: 'Bình thường', dataIndex: 'normalRange', width: 100, key: 'normalRange' },
            { title: 'Đơn vị', dataIndex: 'unit', width: 100, key: 'unit' },
            { title: 'Bất thường', dataIndex: 'batThuong', width: 100, key: 'batThuong' },
            { title: 'Mức nguy hiểm', dataIndex: 'normalRangeWarning', width: 120, key: 'normalRangeWarning' }
        ];
        const columnsMore = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'maDV',
                key: 'maDV',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Kết quả',
                dataIndex: 'ketQua',
                key: 'ketQua',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            { title: 'Bình thường', dataIndex: 'normalRange', width: 100, key: 'normalRange' },
            { title: 'Đơn vị', dataIndex: 'unit', width: 100, key: 'unit' },
            { title: 'Bất thường', dataIndex: 'batThuong', width: 100, key: 'batThuong' },
            { title: 'Mức nguy hiểm', dataIndex: 'normalRangeWarning', width: 120, key: 'normalRangeWarning' },
            {
                title: "Thời gian dự kiến có kết quả",
                dataIndex: "expectedtime",
                key: "expectedtime",
                render: (date) => {
                    return (
                        (this.state.done !== "1" && moment(date).year() !== 1 && date) ?
                            <Tag color={moment(date) < moment() ? "red" : "blue"}>{moment(date).format(SystemConfig.FORMAT_TIME_DATE)}</Tag>
                            :
                            <span><Tooltip title="Truy cập Tiện ích > Cấu hình viện phí > Danh mục loại xét nghiệm">Vui lòng kiểm tra cấu hình thời gian<i className="far fa-question-circle" style={{ marginLeft: 5 }} /></Tooltip></span>
                    )
                }
            }
        ];
        const columnsChild = [
            {
                title: 'Mã dịch vụ',
                dataIndex: 'maDV',
                key: 'maDV',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'tenDichVu',
                key: 'tenDichVu',
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Kết quả',
                dataIndex: 'ketQua',
                key: 'ketQua',
                width: 100,
                render: (value, row) => (<div style={{ fontWeight: `${this.checkColor(row)}` }}>{value}</div>)
            },
            {
                title: 'Bình thường',
                dataIndex: 'normalRange',
                width: 100,
                key: 'normalRange',
                render: (value) => this.normalRangeFilter(value)
            },
            { title: 'Đơn vị', dataIndex: 'unit', width: 100, key: 'unit' },
            { title: 'Bất thường', dataIndex: 'batThuong', width: 100, key: 'batThuong' },
            { title: 'Mức nguy hiểm', dataIndex: 'normalRangeWarning', width: 120, key: 'normalRangeWarning' },
        ];
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                {this.props.patientName &&
                    <div className="h4 text-center font-bold text-uppercase">Kết quả xét nghiệm của {this.props.patientName}</div>
                }
                {this.state.lstKetQua.length === 0 ?
                    <div>
                        <p>Bệnh nhân {this.props.patientName} không có xét nghiệm nào</p>
                        {this.props.history !== true && <div style={{ textAlign: "center", marginTop: "10px" }}>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        ghiDeKetQua: false,
                                    })
                                    if (this.props.onCancel)
                                        this.props.onCancel()
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng
                            </button>
                        </div>}
                    </div>
                    :
                    <div style={{ marginTop: "5px" }} className="row">
                        <div
                            className='col-md-9 my-1'
                        >
                            <div style={{ minHeight: 200, maxHeight: "67vh", overflowY: 'auto' }}>
                                <Table
                                    className="components-table-demo-nested"
                                    columns={this.state.done === "1" || this.state.done === undefined ? columns : columnsMore}
                                    style={{ fontSize: '14px' }}
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <div className='expandBorder' style={{ marginTop: "-7px", marginBottom: '-7px', marginLeft: 35, fontStyle: 'italic' }}>
                                                <Table
                                                    onRow={(row, rowIndex) => {
                                                        return {
                                                            onClick: e => { },
                                                        };
                                                    }}
                                                    style={{ marginLeft: '7px' }} showHeader={false} columns={columnsChild} dataSource={record.listSubTestResult} pagination={false} rowKey={(row, index) => index} />
                                            </div>
                                        ), rowExpandable: record => record.listSubTestResult && record.listSubTestResult.length > 0,
                                    }}
                                    dataSource={this.state.lstKetQua}
                                    rowKey={(row, index) => index}
                                    pagination={false}
                                    rowClassName={(row, index) => {
                                        const xx = index % 2 === 0 ? 'rowCustomSelectNewEven' : 'rowCustomSelectNewOdd'
                                        return xx
                                    }}
                                    expandRowByClick={true}
                                    // expandedRowKeys={this.state.expandableRow}
                                    expandIcon={(props => this.customExpandIcon(props))}
                                    onRow={(record, index) => {
                                        return {
                                            onClick: e => {
                                                this.setExpandable(index);
                                            }
                                        };
                                    }}
                                    scroll={{ y: "80vh" }}
                                />
                            </div>
                            {this.props.history !== true && <div style={{ textAlign: "center", marginTop: "10px" }}>
                                <button className="btn btn-success" onClick={() => this.CapNhatKetQua()}>Cập nhật</button>

                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({
                                            ghiDeKetQua: false,
                                        })
                                        if (this.props.onCancel)
                                            this.props.onCancel()
                                        if (this.state.done !== "1" && this.state.lstKetQua.length > 0) {
                                            this.props.onUpdate();
                                        }
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng
                                </button>
                            </div>}
                        </div>
                        <div className='col-md-3 my-1'>
                            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                                <table className="table table-striped table-inverse table-bordered"

                                >
                                    <thead>
                                        <tr>
                                            {/* <th></th> */}
                                            <th>Xem</th>
                                            <th>Ngày</th>
                                            <th>Tiêu đề kết quả</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.listFile.map((row, index) => <tr key={index}>
                                            {/* <td><Checkbox
                                                checked={row.checked || false}
                                                onChange={e => {
                                                    row.checked = e.target.checked
                                                    // this.setState({ listFile: this.state.listFile })
                                                }}
                                            ></Checkbox></td> */}
                                            <td>
                                                <div className='text-center' style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        this.setState({ detail: row })
                                                        this.ViewResultPdf(row)
                                                    }}
                                                >
                                                    <i className='fas fa-file-pdf'></i>
                                                </div>
                                            </td>
                                            <td>{moment(row.datecreate).format(SystemConfig.FORMAT_DATE_TIME)}</td>
                                            <td>{row.title}</td>
                                            <td>
                                                <Tooltip title={() => {
                                                    return(
                                                        <div className='d-flex flex-column'>
                                                            <span><b>On: </b>Hiện file bên web tra cứu</span>
                                                            <span><b>Off: </b>Ẩn file bên web tra cứu</span>
                                                        </div>
                                                    )
                                                }}
                                                >
                                                    <Switch 
                                                        checked={ this.state.listUrlWebFileIds.indexOf(row.uuid) > -1 }
                                                        // checked={ row.urlWebPatient?.length > 0 }
                                                        // defaultChecked={row.urlWebPatient?.length > 0} 
                                                        onChange={(e) => {this.sendFileToWebPatient(e, row)}} 
                                                    />
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Button className="btn btn-danger"
                                                    onClick={() => this.deleteResultFile(row.uuid)}><i className='fas fa-trash'/></Button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            {this.props.showUpload === true &&
                                <div>
                                    <Collapse defaultActiveKey={['1']}
                                        expandIconPosition={'end'}
                                    >
                                        <Panel header="Upload kết quả xét nghiệm" key="1">
                                            <Dragger
                                                name='file'
                                                multiple={false}
                                                accept="application/pdf"
                                                maxCount={1}
                                                onChange={(info) => {
                                                    this.setState({ fileUpload: info.fileList })
                                                }}
                                                onDrop={(e) => {
                                                    console.log('Dropped files', e.dataTransfer.files);
                                                }}
                                                beforeUpload={() => false} fileList={this.state.fileUpload} >
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây.</p>
                                            </Dragger>
                                            <div className='d-flex justify-content-end'>
                                                <Tooltip title={`${this.state.ghiDeKetQua ? "Ghi đè" : "Không ghi đè"} file nếu trùng tên file`}>
                                                    <Checkbox className='w-100' checked={this.state.ghiDeKetQua} onChange={() => this.setState({ ghiDeKetQua: !this.state.ghiDeKetQua })}>Cho phép ghi đè</Checkbox>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Input.Group compact>
                                                    <Input style={{ width: 'calc(100% - 50px)' }}
                                                        placeholder="Nhập tiêu đề kết quả"
                                                        value={this.state.title}
                                                        onChange={(e) => {
                                                            this.setState({ title: e.target.value })
                                                        }}
                                                    />
                                                    <Button className='btn btn-color'
                                                        onClick={() => {
                                                            this.SubmitUploadFile();
                                                        }}
                                                    >Lưu</Button>
                                                </Input.Group>
                                            </div>
                                            
                                        </Panel>
                                    </Collapse>

                                </div>
                            }
                            <div>
                                <div className='mt-2'>
                                    <Button className='btn btn-color w-100'
                                        onClick={() => {
                                            this.setState({ visibleSendResult: true, })
                                        }}
                                    ><i className='fa fa-paper-plane mr-2'></i>Gửi kết quả</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.visible &&
                    <Modal maskClosable={true}
                        width={1400}
                        visible={this.state.visible}
                        onCancel={() => this.setState({ visible: false })}
                        footer={null}>
                        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                            <div className="list-button-preview">
                                <Button className="mr-2" onClick={() => {
                                    this.DownloadFile()
                                }}><i className="fa fa-download"></i></Button>
                                <Button onClick={() => this.setState({ visible: false })}>Đóng</Button>
                            </div>
                            <embed src={`${this.state.fileUrlBlob}#toolbar=0`}
                                style={{ height: '100%', width: '100%' }}
                            />
                        </div>
                    </Modal>
                }
                <Modal maskClosable={false}
                    destroyOnClose={true}
                    width={1400}
                    style={{minWidth: '600px'}}
                    className="w-25"
                    visible={this.state.visibleSendResult}
                    onCancel={() => this.setState({visibleSendResult: false})}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <SendResult 
                        isDialog={ true } 
                        // resultInfo = { this.state.resultInfo }
                        resultInfo = {{
                            patientID: this.state.resultInfo.patientID,
                            maQL: this.state.resultInfo.managementID,
                            sid: this.state.resultInfo.sid,
                            siteid: this.state.resultInfo.siteid,
                            coporator : this.state.resultInfo.nguonkhachhang
                        }}
                        listFile = { this.state.listFile.concat() }
                        onCancel={(isReloadLisFile = false) => {
                            this.setState({visibleSendResult: false});
                            if (isReloadLisFile) 
                                setTimeout(() => {
                                    this.GetListLISFile(this.props.maql);
                                },1000);
                        }}/>
                </Modal>
            </React.Fragment>
        )
    }
}
