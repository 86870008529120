import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Button, Switch, notification, Modal, Popover } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import SimpleReactValidator from 'simple-react-validator';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as FeatureService from '../../services/FeatureService';

const { Dragger } = Upload;
const { TextArea } = Input;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  // const [isDisableFewField, setIsDisableFewField] = useState(false); //disable các field không được sửa
  const [validateImage, setValidateImage] = useState(false);
  //validate
  //model create
  const [featureId, setFeatureId] = useState(); 
  const [code, setCode] = useState(); 
  const [name, setName] = useState(); 
  const [description, setDescription] = useState(); 
  const [isActive, setIsActive] = useState(); 
  const [isTest, setIsTest] = useState(); 
  const [files, setFiles] = useState([]); 
  const [removeFileIds, setRemoveFileIds] = useState([]); 
  //upload
  const [previewImage, setPreviewImage] = useState(); 
  const [previewVisible, setPreviewVisible] = useState(false); 
  const [previewTitle, setPreviewTitle] = useState(); 
  //#endregion 
  //#region khai báo biến cục bộ
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
  };
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    switch (props.detailScreenMode) {
      case detailScreenModeEnum.detail:
        getById(props.featureId);
        break;
      case detailScreenModeEnum.create:
        setHeaderText('Tạo mới');
        break;
      case detailScreenModeEnum.update:
        getById(props.featureId);
        break;
      default:
        //Nếu open screen detail này mà không truyền props.detailScreenMode là gì thì cho out
        props.onCancel();
        break;
    }
    setDetailScreenMode(props.detailScreenMode);
  }, [props.detailScreenMode]);

  useEffect(() => {
    setFeatureId(props.featureId);
  }, [props.featureId]);

  useEffect(() => {
  }, []);
  //#endregion 
  //#region search & filter
  const setData = (data) => {
    setCode(data.code);
    setName(data.name);
    setIsActive(data.isActive);
    setIsTest(data.isTest);
    setDescription(data.description);
    if (data.files && data.files.length > 0)
      setFiles(data.files.map(x=> { return {
          uid: x.id,
          name: x.fileName,
          status: 'success',
          url: x.hostFileUrl,
          featureId: x.featureId,
        }
      }));
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - ' + data.code);
    else if (props.detailScreenMode == detailScreenModeEnum.update)
      setHeaderText('Cập nhật - ' + data.code);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    FeatureService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const model = {
    code: "",
    name: "",
    description: "",
    isTest: true,
  };

  const setModel = () => {
    model.code = code;
    model.name = name;
    model.description = description;
    model.isTest = isTest;
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    FeatureService.create(model).then(result => {
      if (result.isSuccess) {
        saveImages(result.data.id);
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    FeatureService.update(featureId, model).then(result => {
      if (result.isSuccess) {
        saveImages(featureId);
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  const saveImages = (featureId) => {
    let formData = new FormData();
    formData.append('featureId', featureId);
    for (let fileRemoveId of removeFileIds) 
      formData.append('removeFileIds', fileRemoveId);
    for (let file of files) {
      if (file.blob) {
          formData.append('files', file.blob);
      } else {
          formData.append('files', file.originFileObj);
      }
    }
    FeatureService.saveImages(formData).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        // notification.success({ message: "Thành công" });
      }
      else {
        notification.error({ message: "Upload hình ảnh minh họa không thành công." });
        // notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      notification.error({ message: "Upload hình ảnh minh họa không thành công." });
      // if (err.error && err.error.messageText)
      //   notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      // else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      // Actions.setLoading(false);
    })
  }

  const validate = () => {
    let isValid = true;
    let newFileCount = files?.length ?? 0;
    if (newFileCount > 20) {
      isValid=false;
      setValidateImage(true);
    }
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && featureId) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  //#region upload
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }

  const handleChange = (e) => {
    setFiles(e.fileList);
  }

  const handleRemove = (e) => {
    if (e.featureId)
      setRemoveFileIds([...removeFileIds, e.uid]);
  }

  // const GetIndex = (uid, list) => {
  //     if (!list)
  //         return;
  //     return list.findIndex(x => x.uid === uid);
  // }

  // const Swap = (list, positionA, positionB) => {
  //     if (!list)
  //         return;
  //     let tmp = { ...list[positionA] };
  //     list[positionA] = { ...list[positionB] };
  //     list[positionB] = tmp;
  //     return list;
  // }
  
  // const MoveNext = (list, position) => {
  //   if (!list)
  //       return;
  //   if (position === list.length - 1)
  //       return;
  //   let data = Swap(list, position, position + 1);
  //   setFiles(data);
  //   return data;

  // }
  // const MovePrev = (list, position) => {
  //     if (!list)
  //         return;
  //     if (position === 0)
  //         return;
  //     let data = Swap(list, position, position - 1);
  //     setFiles(data);
  //     return data;
  // }
  //#endregion 

  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row mt-2"> {/* Mã phân hệ - tính năng*/}
                <div className="col-md-10 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mã phân hệ - tính năng</label>
                    <Popover
                      content={
                        <span>
                          Không nên sửa trường này. Vì dùng để code cứng.<br/>
                        </span>
                      }
                      trigger="click"
                    >
                      <Button type="link" shape="circle" style={{ height: 'min-content', paddingLeft: '0.5rem', minWidth: 0 }}
                      ><i className="far fa-question-circle"></i></Button>
                      {/*Hồi trước dùng trường Id (Guid) để code cứng, nhưng thấy như vậy không hay.
                      Vì phải tạo Id trước rồi váo database lấy giá trị Id đi code cứng và phải đồng bộ Id của môi trường test và production => hơi lu bu.
                      Nên là khi phát triển và test thì cứ dùng cái code (Mã phân hệ - tính năng), khi đưa lên production thì chỉ cần tạo và copy code paste vào là được*/
                      }
                    </Popover>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Input
                      placeholder="Mã này phục vụ mục đích active / deactive"
                      readOnly={detailScreenMode==detailScreenModeEnum.detail}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    ></Input>
                    <label className={isActive ? "text-blue" : "text-red"} 
                      style={{ minWidth:100, paddingLeft: '0.5rem' }}
                      hidden={detailScreenMode==detailScreenModeEnum.create}
                    >{isActive ? "Đang áp dụng" : "Chưa áp dụng"}</label>
                  </div>
                  {validator.current.message("Mã phân hệ - tính năng", code, "required")}
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <div>
                    <label>IsTest</label>
                    <Popover
                      content={
                        <span>
                          Thông tin này để phân biệt dữ liệu test hay là dữ liệu đang chạy thực tế.<br/>
                          Vì id của phân hệ được dùng để code cứng 1 số tính năng.<br/>
                          Khi test, nên hạn chế đụng đến field IsTest này và cả các dữ liệu có IsTest = false.
                        </span>
                      }
                      trigger="click"
                    >
                      <Button type="link" shape="circle" style={{ height: 'min-content', paddingLeft: '0.5rem', minWidth: 0 }}
                      ><i className="far fa-question-circle"></i></Button>
                    </Popover>
                  </div>
                  <div>
                    <Switch 
                      checked={isTest} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsTest(checked); }} 
                    />
                    </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Tên phân hệ - tính năng*/}
              <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên phân hệ - tính năng</label>
                  </div>
                  <Input
                    value={name}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên phân hệ - tính năng", name, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Diễn giải</label>
                  </div>
                  <TextArea 
                    rows={5}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></TextArea >
                  {validator.current.message("Diễn giải", description, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label>Hình ảnh minh họa</label>
                  </div>
                  <div style={{fontSize: 14, color: 'rgb(255, 77, 79)'}}>
                    {validateImage ? "Không được up quá 20 hình ảnh" : ""}
                  </div>
                  <Dragger 
                    customRequest={dummyRequest}
                    listType="picture-card"
                    multiple={true}
                    fileList={files}
                    onPreview={handlePreview.bind(this)}
                    onChange={handleChange.bind(this)}
                    // onChange={(e) => { handleChange(e); }}
                    onRemove={handleRemove.bind(this)}
                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                    itemRender={(originNode, file, fileList) => {
                      return <div className="handle-move-upload">
                        {originNode}
                      </div>
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <i className="fas fa-cloud-upload-alt text-color" style={{ fontSize:"3rem" }}></i>
                    </p>
                    <p className="ant-upload-text">Chọn hoặc kéo thả file vào đây</p>
                  </Dragger>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode === detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} className="p-0">
        <img alt="example" style={{ width: '100%', height: '100%', objectFit:'contain' }} src={previewImage} />
      </Modal>
    </React.Fragment>
  );
})