import { Tabs } from 'antd';
import React, { Component } from 'react';

import DepartmentConfig from './department';
import RoomConfig from './room'
import DepartmentExam from './departmentExam';
const { TabPane } = Tabs;

export default class chidinh extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {

    }




    render() {


        return (
            <React.Fragment>
                <div className='containerChilderWrapper' >
                    <Tabs>
                        <TabPane tab={"Khoa"} key="1" >
                            <DepartmentConfig></DepartmentConfig>
                        </TabPane>
                        <TabPane tab={"Phòng"} key="2">
                            <RoomConfig></RoomConfig>
                        </TabPane>
                        <TabPane tab={"Dịch vụ khám mặc định"} key="3">
                            <DepartmentExam></DepartmentExam>
                        </TabPane>
                    </Tabs>
                </div>

            </React.Fragment>
        )
    }
}
