import React, { Fragment, useRef, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { Select, Input, Popover, DatePicker, Button, List, Tag, Modal, Menu, Dropdown, Drawer, Spin, Tooltip } from "antd";
import moment from "moment";
import Notify, { NotifyStatus } from "../../../../components/notify";
import { FORMAT_DATE } from "../../../../configure/systemConfig";
import { groupBy, isNullOrEmpty, renumber } from "../../../../libs/util";
import Handlebars from 'handlebars';
import handlebarHelper from "../../../../libs/handlebarHelper";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import * as InvoiceService from '../../../../services/invoiceService';
import * as Actions from '../../../../libs/actions';
import * as PrintService from '../../../../services/printService';
import PrintSIDComp from "../../paymentmulti/InSIDBarcode";
import Report from '../report';
import Preview from '../../trahoadon/preview';
import SmallShowSummaryAmount from '../../components/smallshowsummaryamount';
import ActionRefund from '../../components/actionrefund';
import { connect } from 'react-redux';
import { BUTTON } from "../../../../configureHIS/common/constants";

const {Option} = Select;
const ListPayment = forwardRef((props, ref) => {
    const _notiRef = useRef();
    const firstRender = useRef(true);
    const refundPopupRef = useRef([]);
    // Datasource
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([])
    const [selectedData, setSelectedData] = useState([]);
    const [reportDetail, setReportDetail] = useState({});
    // Filter
    const [dateFrom, setDateFrom] = useState(moment());
    const [dateTo, setDateTo] = useState(moment());
    const [billType, setBillType] = useState(props.payMedicineAtCashDesk ? 1 : 0);
    const [processStatus, setProcessStatus] = useState(1);
    const [keyword, setKeyword] = useState(''); 
    const [skipCount, setSkipCount] = useState(0);
    const [maxResultCount, setMaxResultCount] = useState(10); 
    // Modal
    const [visibleDate, setVisibleDate] = useState(false);
    const [visibleReport, setVisibleReport] = useState(false);
    const [visiblePrintSID, setVisiblePrintSID] = useState(false);
    const [visiblePreview, setvisiblePreview] = useState(false);
    const [listMethod, setListMethod] = useState([]);
    const [listBill, setListBill] = useState([]);
    const [billSelected, setBillSelected] = useState();
    const [selectedBillData, setSelectedBillData] = useState({});
    const [refundPopupShow, setRefundPopupShow] = useState([]);
    const [loadingRefund, setLoadingRefund] = useState(false);
    //
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        reloadListPayment: () => {
            setSelectedData([]);
            resetToFirstPage();
        }
    }));

    useEffect(() => {
        if (firstRender.current) return;
        GetList();
    }, [skipCount])

    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
        let data = {
            'billType' : billType,
            'processStatus' : processStatus
        }
        props.onChangeBillType(data)
    }, [dateFrom, dateTo, billType, processStatus, props.siteid])

    useEffect(() => {
        let t = [];
        listBill.forEach(x => {
            t[x.billNumber] = false;
        });
        setRefundPopupShow(t);
    }, [listBill])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            GetList();
            getListPaymentType();
        }
    }, []);

    const validate = () => {
        let listErr = [];
        if (dateFrom > dateTo) listErr.push('"Ngày đăng ký từ" phải nhỏ hơn "ngày đăng ký đến"');
        return listErr;
    }

    const GetList = (skip = skipCount, maxResult = maxResultCount) => {

        let listErr = validate();
        if (listErr.length > 0) {
            let err = listErr.join('\n');
            _notiRef.current.Show(err, NotifyStatus.Error);
            return;
        }
        Actions.setLoading(true);
        let filter = {
            dateFrom : dateFrom,
            dateTo : dateTo,
            keyword : keyword,
            processStatus : processStatus,
            billType : billType,
            skipCount : skip,
            maxResultCount : maxResult
        }
        InvoiceService.GetListPayment(filter).then((result) => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                if (skip === 0) {
                    setListResult(result.data.items);
                    getBillList(result.data.items.map(x => x.managementId), true);
                }
                else {
                    setListResult([...listResult, ...result.data.items]);
                    getBillList(result.data.items.map(x => x.managementId));
                }
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
        if (skipCount != 0) setSkipCount(0);
        else GetList();
    }

    const onSelected = (data) => {
        setSelectedData(data);
        props.onSelected(data);
    }

    const inBangKe = (data) => {
        Actions.setLoading(true);
        PrintService.PrintManifest(data.patientCode, data.managementId, "").then(result => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    let detailService = result.data.detail.listServices;
                    if (detailService) {
                        let detailGroupBy = groupBy(detailService, "groupName");
                        let detailArray = Object.keys(detailGroupBy).map((key) => ({ key, value: detailGroupBy[key] }));
                        let resultGroupArrRenumber = renumber(detailArray);
                        result.data.detail.listServices = resultGroupArrRenumber;   
                    }
                    Handlebars.registerHelper(handlebarHelper);
                    Actions.DoPrint(template(result.data));
                }
            else Actions.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).catch(() => {
            Actions.openMessageDialog("Lỗi", "Không thể in, vui lòng thử lại sau");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const rowOptions = (record) => {
        return (
            <Menu
                items={[
                    {  
                        label:
                            <a href="#" className='stopRowClick' onClick={() => {
                                onSelected(record)
                                handleOpenPrintSID()
                            }}><i className='fas fa-print mr-2' />In SID</a>,
                        key: 'printsid',
                    },
                    (record.listPaidBill && record.listPaidBill.length > 0) && {
                        label:
                            <a href="#" className='stopRowClick' onClick={(e) => {
                                inBangKe(record)
                            }}><i className='fa fa-print mr-2' />In bảng kê</a>,
                        key: 'printmanifest',
                    },
                ]}
            />
        )
    }

    const handleOpenPrintSID = () => {
        if (selectedData !== undefined ) {
            setVisiblePrintSID(true);
            _notiRef.current && _notiRef.current.Close();
        }
        else _notiRef.current.Show("Chưa chọn bệnh nhân", NotifyStatus.Warning);
    }

    const renderPopoverRefundOrPaid = (receiptInfo) => {
        //load lên danh sách KH là nó nhảy vào đây luôn. nên chưa làm được chỗ hover này. để sau.....
        let isRefunded = receiptInfo.isRefunded;
        let bill = listBill.find(x => x.managementId == receiptInfo.managementId && x.billNumber == receiptInfo.billNumber);
        return (
            (bill == null || bill == undefined) ?
            <Spin tip="Đang load" size="small"/> :
            <SmallShowSummaryAmount
                data={{ ...bill, billNo: receiptInfo.billNo }}
                // data = {{
                //             paymentTypeName: bill?.paymentTypeName, //Hình thức thanh toán.
                //             paidDate: bill?.paidDate, //Ngày thanh toán / hoàn trả.
                //             cashier: bill?.cashier, //Thu ngân.
                //             remark: bill?.remark, //Ghi chú.
                            
                //             total: bill?.total, //Thành tiền: tổng tiền các dịch vụ (sau khi đã giảm dịch vụ).
                //             discount: bill?.discount, //Miễn giảm: giảm giá trên bill (giảm tay trên bill).
                //             loyalty: bill?.loyalty, //CTKM / Thẻ: giảm giá trên bill theo CTKM / thẻ.
                //             paid: bill?.paid, //Thành tiền = total - discount - loyalty.
                //             refund: bill?.refund, //Hoàn trả: số tiền phải hoàn trả nếu là bill hoàn trả.
                //             revenue: bill?.revenue, //Thanh toán: số tiền phải thanh toán nếu là bill thanh toán.
                //         }}
                // managementId={receiptInfo.managementId}
                // patientId={receiptInfo.patientCode}
                // billNumber={receiptInfo.billNumber}
                // isRefunded={isRefunded}
                // voucher={voucher}
                // showBtnPrint={false}
                // showBtnRefund={false}
                btnPrintClick={() => {
                    isRefunded ? printRefund(bill.billNumber, bill.managementId) : printReceipt(bill.billNumber, bill.paidDate, bill.managementId)
                }}
                btnRefundClick={(e) => {
                    e.preventDefault(); e.stopPropagation(); 
                    refundPopupRef.current[bill.billNumber].click()
                    // setVisiblePopupSec(true); 
                    // setVisiblePopup(true);
                    // selectAllBillSer(receiptInfo.receiptNumber);
                }}
            />
        )
    }

    const getBillList = (listMaQl = [], reset = false) => {
        InvoiceService.getBillListByListMaQl(listMaQl).then(res => {
            if (res.isSuccess) {
                setListBill(reset ? res.data : [...listBill,...res.data]);
                forceUpdate();
            }
        }).catch(err => {

        }).finally(() => {
        })
    }

    const printReceipt = (soHoaDon, ngayChiDinh, maQuanLy) => {
        Actions.setLoading(true);
        let banLe = false;
        PrintService.PrintPayment(soHoaDon, moment(ngayChiDinh).format('YYYY-MM-DD'), maQuanLy, banLe).then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                return;
            } else {
                const template = Handlebars.compile(result.item.template);
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const printRefund = (refundNumber, managementId) => {
        Actions.setLoading(true);
        PrintService.PrintRefund(refundNumber).then((result) => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Actions.DoPrint(template(result.data));
                }
            else Actions.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    
    const setShowHideRefundPopover = (billNumber, isShow) => {
        let l = refundPopupShow;
        l[billNumber] = isShow;
        setRefundPopupShow(l);
        forceUpdate();
    }

    const refundPopoverContent = () => {
        if (loadingRefund) return <Spin tip="Đang load" size="small"/>
        if (billSelected == null || billSelected == undefined) return "Không có bill";
        let listDetail = billSelected.listDetail.filter(x => !x.isRefund);
        // if (listDetail == undefined || listDetail.length == 0) return ;
        return (
            (listDetail == undefined || listDetail.length == 0)  ? 
            <div><i className="fas fa-exclamation-triangle text-warning mr-1"></i>Không còn dịch vụ để hoàn trả</div> :
            <ActionRefund
                managementId={billSelected.managementId}
                patientId={billSelected.patientId}
                listDetail={listDetail}
                listMaster={[billSelected]}
                listMethod={listMethod}
                onReload={() => {GetList(0, listResult.length); 
                    setShowHideRefundPopover(billSelected.billNumber, false);
                }}
            ></ActionRefund>
        )
    }
    const getListPaymentType = () => {
        VPService.GetListPaymentType().then((res) => {
            setListMethod(res.data);
        }).catch((err) => { })
    }

    function getBillDetail(billNumber) {
        setLoadingRefund(true);
        // Actions.setLoading(true);
        InvoiceService.getBillDetail(billNumber).then(res => {
            if (res.isSuccess) {
                setBillSelected(res.data);
                // notiRef && notiRef.current && notiRef.current.Close();
                forceUpdate();
            }
            else {
                // notiRef && notiRef.current && notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {

        }).finally(() => {
            setLoadingRefund(false);
            // Actions.setLoading(false);
        })
    }

    const onShowPreview = (record, billId, billNo) => {
        let data = {...record, billNumber: billId, billNo}
        setSelectedBillData(data);
        setvisiblePreview(true)
    }

    return (
        <Fragment>
            <div className="list-page-body">{/* body */}
                <Notify ref={_notiRef} />
                <div className="list-page-search">{/* search/filter */}
                    <div className="w-100 d-flex flex-row justify-content-between">
                        <Select
                            style={{width : '45%'}}
                            key="billType"
                            placeholder="Chọn trạng thái"
                            value={billType}
                            onChange={(value) => { setBillType(value); }}
                        >
                            {!props.payMedicineAtCashDesk && <Option key='BILL_ALL' value={0}>Dịch vụ, thuốc & vật tư</Option>}
                            <Option key='BILL_SERVICE' value={1}>Dịch vụ</Option>
                            <Option key='BILL_MEDICINE' value={2}>Thuốc & vật tư</Option>
                        </Select>
                        <Select
                            style={{width : '40%'}}
                            key="processStatus" 
                            placeholder="Chọn trạng thái"
                            value={processStatus}
                            onChange={(value) => { setProcessStatus(value); }}
                        >
                            <Option key='UNPAY' value={1}>Chưa thanh toán</Option>
                            <Option key='PAID' value={2}>Đã thanh toán</Option>
                            <Option key='REFUND' value={3}>Hoàn trả</Option>
                            <Option key='ALL' value={0}>Tất cả hóa đơn</Option>
                        </Select>
                        <Popover
                            content={
                                <div className="col-md-12 d-flex flex-column">
                                    <div className="d-flex">
                                        <DatePicker
                                            allowClear={false}
                                            locale={viVN}
                                            showNow={false}
                                            className="w-100"
                                            placeholder="Từ ngày"
                                            value={dateFrom}
                                            format={SystemConfig.FORMAT_DATE}
                                            onChange={(e) => {
                                                setDateFrom(e ? moment(e) : null);
                                            }}
                                            
                                        />
                                        <div className="m-auto">&nbsp;~&nbsp;</div>
                                        <DatePicker
                                            allowClear={false}
                                            locale={viVN}
                                            showNow={false}
                                            className="w-100"
                                            placeholder="Đến ngày"
                                            value={dateTo}
                                            format={SystemConfig.FORMAT_DATE}
                                            onChange={(e) => {
                                                setDateTo(e ? moment(e) : null);
                                            }}
                                            disabledDate={(date) => date < moment(dateFrom)}
                                        />
                                    </div>
                                </div>
                            }
                            title="Chọn ngày"
                            trigger="click"
                            visible={visibleDate}
                            onVisibleChange={(e) => { setVisibleDate(e) }}
                            placement="bottomRight"
                        >
                            <Button className={BUTTON.OUTLINE_SECONDARY} onClick={() => { setVisibleDate(true) }}><i className="fa fa-calendar-alt"></i></Button>
                        </Popover>
                    </div>
                    <div className="w-100 d-flex flex-row mt-2">
                        <Input
                            className="w-100"
                            prefix={<i className="fas fa-search anchor-color mr-1" onClick={() => resetToFirstPage()}></i>}
                            placeholder="Nhập họ tên bệnh nhân hoặc mã PID"
                            value={keyword}
                            onChange={(e) => {
                                setKeyword(e.target.value);
                            }}
                            onPressEnter={(e) => {
                                resetToFirstPage();
                                getBillList();
                            }}
                        />
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                        bordered={false}
                        dataSource={listResult}
                        renderItem={(record, index) => {
                            let className = "p-1 border-2 border-bottom py-2 ant-list-item-hover";
                            if ( selectedData.managementId === record.managementId ){ className = className + " backgroundColorThemesAlpha"};
                            return (
                                <List.Item
                                    onClick={(e) => { onSelected(record) }}
                                    key={`${record.managementId}`}
                                    className={className}>
                                    <div className="d-flex flex-column w-100">
                                        <div className="d-flex">
                                            <div style={{ width: "5%" }}>
                                                <label className="mita-value">{index + 1}</label>
                                            </div>
                                            <div style={{ width: "5%" }}>
                                                {record.atHome && <Tooltip title="Mẫu tại nhà" placement="left"><i style={{ color: "#c11c7b", fontSize: 16 }} className="fas fa-truck"></i></Tooltip>}
                                                {record.companyExam && <Tooltip title="Khám sức khỏe công ty" placement="left"><i style={{ color: "#00a2ae", fontSize: 16 }} className="fas fa-building"></i></Tooltip>}
                                            </div>
                                            <div style={{ width: "45%" }}>
                                                <label className="mita-value">{record.patientName}</label>
                                            </div>
                                            <div style={{ width: "15%" }}>
                                                <label className="mita-value">{record.gender}</label>
                                            </div>
                                            <div style={{ width: "25%" }}>
                                                <label className="mita-value">{record.onlyYear ? record.yob : moment(record.dob).format(FORMAT_DATE)}</label>
                                            </div>
                                            <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                                                <Dropdown overlay={rowOptions(record)} trigger={['hover']}>
                                                    <a style={{ textAlign: "end", }} className="ant-dropdown-link"><i className='fas fa-ellipsis-v'></i></a>
                                                </Dropdown>
                                            </div>
                                            
                                        </div>
                                        <div className="d-flex justify-content-end h-100">
                                            <div className="">
                                                {listBill.filter(x => x.managementId == record.managementId).map(x => {
                                                    if (x.billType==0) record.listPaidBill.push(x);
                                                    return(
                                                        <>
                                                        <Popover placement='bottomLeft' trigger={'click'}
                                                            content={refundPopoverContent()}
                                                            visible={refundPopupShow[x.billNumber]}
                                                            onVisibleChange={(e) => {setShowHideRefundPopover(x.billNumber, e);}}
                                                        >
                                                            <Popover placement='bottomLeft'
                                                                content={renderPopoverRefundOrPaid({...record, isRefunded: x.billType==3, billNumber: x.billNumber, billNo: x.billNo})}
                                                            >
                                                                <Tag className='mt-1' color={x.billType==3 ? "#b13431" : "#576cdf" }
                                                                    onClick={(e) => { onShowPreview(record, x.billNumber, x.billNo); e.preventDefault(); e.stopPropagation(); }}>
                                                                    {x.billNo ?? x.billNumber}
                                                                </Tag>
                                                                <a href="#" ref={e => refundPopupRef.current[x.billNumber] = e} 
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); e.stopPropagation();
                                                                        getBillDetail(x.billNumber);
                                                                        setShowHideRefundPopover(x.billNumber, true);
                                                                    }}
                                                                ></a>
                                                            </Popover>
                                                        </Popover>
                                                        </>
                                                    )
                                                })}
                                                {/* {record.listPaidBill.map(x => (
                                                    <>
                                                    <Popover placement='bottomLeft' trigger={'click'}
                                                        content={refundPopoverContent()}
                                                        visible={refundPopupShow[x.key]}
                                                        onVisibleChange={(e) => {setShowHideRefundPopover(x.key, e);}}
                                                    >
                                                        <Popover placement='bottomLeft'
                                                            content={renderPopoverRefundOrPaid({...record, isRefunded: false, billNumber: x.key, billNo: x.value})}
                                                        >
                                                            <Tag className='mt-1' color="#576cdf" 
                                                                onClick={(e) => { onShowPreview(record, x.key, x.value); e.preventDefault(); e.stopPropagation(); }}>
                                                                {x.value ?? x.key}
                                                            </Tag>
                                                            <a href="#" ref={e => refundPopupRef.current[x.key] = e} 
                                                                onClick={(e) => {
                                                                    e.preventDefault(); e.stopPropagation();
                                                                    getBillDetail(x.key);
                                                                    setShowHideRefundPopover(x.key, true);
                                                                }}
                                                            ></a>
                                                        </Popover>
                                                    </Popover>
                                                    </>
                                                ))}
                                                {record.listRefundBill.map(c => (
                                                    <>
                                                    <Popover placement='bottomLeft'
                                                        content={renderPopoverRefundOrPaid({...record, isRefunded: true, billNumber: c.key, billNo: c.value})}
                                                    >
                                                        <Tag className='ml-2' color="#b13431"
                                                            onClick={(e) => { onShowPreview(record, c.key, c.value); e.preventDefault(); e.stopPropagation(); }}>
                                                            {c.value ?? c.key}
                                                        </Tag>
                                                    </Popover>
                                                    </>
                                                ))} */}
                                            </div>
                                        </div>
                                    </div>
                                </List.Item>
                            )
                        }}
                    >
                        <List.Item key="showMore">
                            <div className="w-100 d-flex justify-content-center">
                                <button
                                    className='btn btn-secondary'
                                    hidden={totalRecord <= listResult.length}
                                    onClick={() => {
                                        setSkipCount(skipCount + maxResultCount);
                                    }}
                                >Hiển thị thêm {totalRecord - listResult.length}</button>
                            </div>
                        </List.Item>
                    </List>
                </div>
                {/* footer (nếu có) */}
                <div className="list-page-footer d-flex flex-column justify-content-between" style={{borderTopStyle : 'dashed'}}>
                    <h5><i className="fas fa-chart-bar mt-2"></i> Thống kê</h5>
                    <div className="d-flex flex-row">
                        <Button 
                            style={{whiteSpace: "normal",height:'auto'}}
                            className="w-100 mr-1" 
                            size="large"
                            onClick={() => {
                                setVisibleReport(true);
                                setReportDetail({
                                    title: 'Báo cáo dịch vụ',
                                    filter: '1',
                                })
                            }}>
                            <i className="fas fa-briefcase-medical fa-2x"></i><br/>
                            Dịch vụ
                        </Button>
                        <Button 
                            style={{whiteSpace: "normal",height:'auto'}}
                            className="w-100 mr-1" 
                            size="large"
                            onClick={() => {
                                setVisibleReport(true);
                                setReportDetail({
                                    title: 'Báo cáo thuốc, vật tư',
                                    filter: '2',
                                })
                            }}>
                            <i className="fas fa-capsules fa-2x"></i><br/>
                            Thuốc, vật tư
                        </Button>
                        <Button 
                            style={{whiteSpace: "normal",height:'auto'}}
                            className="w-100 mr-1" 
                            size="large"
                            onClick={() => {
                                setVisibleReport(true);
                                setReportDetail({
                                    title: 'Báo cáo tổng hợp',
                                    filter: '',
                                })
                            }}>
                            <i className="fas fa-dollar-sign fa-2x"></i><br/>
                            Tổng hợp
                        </Button>
                    </div>
                </div>
            </div>

            <Drawer
                className='custom-ant-drawer'
                width={'94%'}
                closable={false}
                placement="right"
                maskClosable={false}
                destroyOnClose={true}
                visible={visibleReport}>
                <Report onCancel={() => setVisibleReport(false)}
                    chinhanh={props.siteid}
                    data={reportDetail}
                />
            </Drawer>
            <Drawer
                closable={false}
                width={700}
                placement="right"
                visible={visiblePrintSID}>
                <PrintSIDComp 
                    billSelected={selectedData.sid}
                    onSubmit={() => setVisiblePrintSID(false)}
                    onCancel={() => setVisiblePrintSID(false)} />
            </Drawer>
            { visiblePreview &&
                <Drawer
                    className='custom-ant-drawer'
                    closable={false}
                    width={1400}
                    placement="right"
                    onClose={() => setvisiblePreview(false)}
                    visible={visiblePreview}
                    destroyOnClose={true}
                    maskClosable={true}
                >
                    <Preview
                        patientCode={selectedBillData.patientCode}
                        patientName={selectedBillData.patientName}
                        managermentId={selectedBillData.managementId}
                        receiptNumber={selectedBillData.billNumber}
                        billNo={selectedBillData.billNo}
                        onClose={() => setvisiblePreview(false)}
                        showBtnPrint={true}
                        showBtnRefund={true}
                        enableEditPayment={true}
                        onReload = {() => resetToFirstPage()}
                    />
                </Drawer>
            }
        </Fragment>
    )
});

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        siteName: state.global.siteName,
        payMedicineAtCashDesk: state.global.sysConfig.payMedicineAtCashDesk ? state.global.sysConfig.payMedicineAtCashDesk : false,
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(ListPayment);
