import { FORMAT_YYYYMMDD } from '../configure/systemConfig';
import * as Actions from '../libs/actions';
import * as ApiCaller from '../libs/httpRequests';
import moment from 'moment';
const prefix = 'Promotion';

//#region new version
export function getList(
    sortingAndPagingModel = {pageIndex: 1, pageSize: 10, sorting: ''}, 
    filterModel = {promotionName: '', remark: '', promotionTypes: [], 
        beginningDateFrom: null,
        beginningDateTo: null,
        endingDateFrom: null,
        endingDateTo: null,
    }
) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}&sorting=${sortingAndPagingModel.sorting}`;
    let filter = `&promotionName=${filterModel.promotionName}`+
        `&remark=${filterModel.remark}`+
        `&beginningDateFrom=${filterModel.beginningDateFrom}`+
        `&beginningDateTo=${filterModel.beginningDateTo}`+
        `&endingDateFrom=${filterModel.endingDateFrom}`+
        `&endingDateTo=${filterModel.endingDateTo}`;
    filterModel.promotionTypes.forEach(element => {
        filter += `&promotionTypes=${element}`
    });
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/list${sortingAndPaging + filter}`)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function getListPromotionType() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/list-promotiontypes`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/`+id).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function edit(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function delete_promotion(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/` + id).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function clone(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/clone-promotion/`+id).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printVouchersNew(promotionId, mau, voucherIds) {
    var url = `Print/print-vouchers?promotionId=${promotionId}&mau=${mau}`;
    voucherIds.forEach(element => {
        url += `&voucherIds=${element}`;
    });
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(url).then(respone => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function exportListPromotions(
    sortingAndPagingModel = {pageIndex: 1, pageSize: 10, sorting: ''}, 
    filterModel = {promotionName: '', remark: '', promotionTypes: [], 
        beginningDateFrom: null,
        beginningDateTo: null,
        endingDateFrom: null,
        endingDateTo: null,
    }
) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}&sorting=${sortingAndPagingModel.sorting}`;
    let filter = `&promotionName=${filterModel.promotionName}`+
        `&remark=${filterModel.remark}`+
        `&beginningDateFrom=${filterModel.beginningDateFrom}`+
        `&beginningDateTo=${filterModel.beginningDateTo}`+
        `&endingDateFrom=${filterModel.endingDateFrom}`+
        `&endingDateTo=${filterModel.endingDateTo}`;
    filterModel.promotionTypes.forEach(element => {
        filter += `&promotionTypes=${element}`
    });
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/export-list${sortingAndPaging + filter}`)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion
export function createPromotion(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreatePromotion`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getPromotionsList(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetPromotionsList?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListVouchers(groupid, key, page, size, used) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListVouchers?groupid=${groupid}&key=${key}&page=${page}&size=${size}&used=${used}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updatePromotion(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdatePromotion`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deletePromotion(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeletePromotion/${id}`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function clonePromotion(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ClonePromotion/${id}`, ).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getVouchers(code, mabn, date, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetVouchers?code=${code}&mabn=${mabn}&date=${date}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printVouchers(id, mau, code) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Print/PrintVouchers?id=${id}&mau=${mau}&code=${code}`).then(respone => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};

export function getPromotions(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Loyalty/GetPromotions?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListPatients(promotionid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPatients/${promotionid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetServiceVoucher(voucher, mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetServiceVoucher?voucher=${voucher}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListServiceVoucher(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListServiceVoucher/${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListVouchers(groupid, key, used) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListVouchers?groupid=${groupid}&key=${key}&used=${used}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListPatients(promotionid) {
    return new Promise((resolve, reject) => {
        // return ApiCaller.httpGet(`${prefix}/GetListPatients/${promotionid}`).then(respone => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListPatients/${promotionid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function getListPromotions(sortingAndPagingModel = { pageIndex: 1, pageSize: 10 }, filterModel = { Keyword: '' }) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let filter = `&Keyword=${filterModel.Keyword}`;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/list${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListPromotionsByFilter(type, max, list, page, site, allSite, priority, from, to) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPromotionsStatistic?type=${type}&list=${list}&max=${max}&page=${page}&site=${site}&allSite=${allSite}&from=${from}&to=${to}&priority=${priority}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListUsedVouchersByPromotion(promotionId, sortingAndPagingModel = { pageIndex: 1, pageSize: 10 }, filterModel = { keyword: '', usedDateFrom: null, usedDateTo: null, site: "", allSite: false }) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let filter = `&keyword=${filterModel.keyword}`;
    if (filterModel.usedDateFrom !== null && filterModel.usedDateTo !== null) {
        filter += `&usedDateFrom=${moment(filterModel.usedDateFrom).format(FORMAT_YYYYMMDD)}&usedDateTo=${moment(filterModel.usedDateTo).format(FORMAT_YYYYMMDD)}`;
    }
    if (filterModel.site !== "") filter += `&site=${filterModel.site}`;
    filter += `&allsite=${filterModel.allSite}`;
    let sortingAndPaging = `&skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListUsedVouchersByPromotion?promotionId=${promotionId}${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListUsedVouchers(promotionId, sortingAndPagingModel, filterModel = { keyword: '', usedDateFrom: null, usedDateTo: null }) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let sortingAndPaging = `&skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}`;
    let filter = `&keyword=${filterModel.keyword !== undefined ? filterModel.keyword : ""}`;
    if (filterModel.usedDateFrom !== null && filterModel.usedDateFrom !== undefined && filterModel.usedDateTo !== null && filterModel.usedDateTo !== undefined) {
        filter += `&usedDateFrom=${moment(filterModel.usedDateFrom).format(FORMAT_YYYYMMDD)}&usedDateTo=${moment(filterModel.usedDateTo).format(FORMAT_YYYYMMDD)}`;
    }
    if (filterModel.site !== "") filter += `&site=${filterModel.site}`;
    filter += `&allSite=${filterModel.allSite}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListUsedVouchers?promotionId=${promotionId}${filter}${sortingAndPaging}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getVoucherUsedSummarize(type, max, list, page, site, allSite, priority, from, to) {
    if (from === undefined || from === null) from = ""; 
    if (to === undefined || to === null) to = ""; 
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetVoucherUsedSummarize?type=${type}&list=${list}&allSite=${allSite}&max=${max}&page=${page}&site=${site}&from=${from}&to=${to}&priority=${priority}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListVoucherHistory(type, max, list, page, site, allSite, priority, from, to, currentPage, currentSize, sort) {
    if (from === undefined || from === null) from = ""; 
    if (to === undefined || to === null) to = ""; 
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetVoucherHistoryStatistic?type=${type}&list=${list}&max=${max}&page=${page}&site=${site}&allSite=${allSite}&from=${from}&to=${to}&currentPage=${currentPage}&currentSize=${currentSize}&sort=${sort}&priority=${priority}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListVoucherHistory(type, max, list, page, site, allSite, priority, from, to) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListVoucherHistory?type=${type}&listpromo=${list}&max=${max}&page=${page}&site=${site}&allSite=${allSite}&from=${from}&to=${to}&priority=${priority}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};