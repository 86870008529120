import { Form, Input, Table, InputNumber, AutoComplete } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';

var refs = {};
const EditableContext = React.createContext();
const { TextArea } = Input;

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const validator = new SimpleReactValidator(ValidationCustom);
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    checkDate,
    handleSave,
    type,
    isrequire,
    keyFunction,
    maxLength,
    min,
    max,
    startDay,
    handleSearch,
    selectOptions,
    ...restProps
}) => {
    const [editing,] = useState(false);
    const form = useContext(EditableContext);

    useEffect(() => {

    }, [editing]);


    const save = async e => {
        try {
            const values = await form.validateFields();

            handleSave({ ...record, ...values });
        } catch (errInfo) {

        }
    };

    let childNode = children;
    if (record) refs[record.countNumber] = {};



    const CheckType = type => {
        switch (type) {
            case 'number':
                return (
                    <InputNumber
                        onKeyDown={(e) => {
                            if (e.key === 'e') {
                                e.preventDefault();
                            }
                            if (e.key === 'Tab' || e.key === 'Enter') {
                                if (keyFunction)
                                    keyFunction(e, record.countNumber, dataIndex)
                            }
                            if (e.key === 'Enter') {
                                save();
                            }
                        }}
                        maxLength={maxLength !== undefined ? maxLength : 10}
                        min={min !== undefined ? min : -9999999999}
                        max={max !== undefined ? typeof(max) === 'string' ? record[`${max}`] : max : 9999999999}
                        ref={(c) => record && (refs[record.countNumber][dataIndex] = c)}
                        value={record[dataIndex]} onBlur={save}
                        onChange={(e) => {
                            save();
                            if (keyFunction)
                                keyFunction(e, record.countNumber, dataIndex)
                        }}
                    />
                )

            case 'date':
                return (
                    <Input
                        style={{ width: "max-content" }}
                        type="date"
                        maxLength={10}
                        max='2100-01-01'
                        onKeyDown={(e) => {
                            if (e.key === 'Tab' || e.key === 'Enter') {
                                if (keyFunction)
                                    keyFunction(e, record.countNumber, dataIndex)

                            }
                            if (e.key === 'Enter') {
                                save();
                            }
                        }}
                        ref={(c) => record && (refs[record.countNumber][dataIndex] = c)}
                        value={record[dataIndex]}
                        onBlur={(e) => { save() }}
                        onChange={(e) => {
                            save();
                            if (keyFunction)
                                keyFunction(e, record.countNumber, dataIndex)
                        }}
                    />
                )
            case "textAsNumber": return (
                <Input
                    onKeyDown={(e) => {
                        if ((e.target.value.includes("/") && e.key == "/") || (e.target.value.includes(".") && e.key == ".")) {
                            e.preventDefault();
                            return false;
                        }
                        var k = e.keyCode;
                        if ((k < 48 || k > 57) && (k < 96 || k > 105) && k != 8 && k != 190 && k != 191 && k != 110 && k != 111 && k != 9 && k != 13) {
                            e.preventDefault();
                            return false;
                        }
                        if (e.key === 'Tab' || e.key === 'Enter') {
                            if (keyFunction)
                                keyFunction(e, record.countNumber, dataIndex)
                        }
                        if (e.key === 'Enter') {
                            save();
                        }
                    }}
                    ref={(c) => record && (refs[record.countNumber][dataIndex] = c)} value={record[dataIndex]}
                    onBlur={save}
                    onChange={(e) => {
                        save();
                        if (keyFunction)
                            keyFunction(e, record.countNumber, dataIndex)
                    }}
                />
            )
            case "textArea":
                return (
                    <AutoComplete
                        options={selectOptions}
                        onSelect={save}
                        onSearch={(e) => handleSearch(record.mabd, e)}
                        dropdownMatchSelectWidth={700}
                        listHeight={500}
                        className="txtare-autocom"
                    >
                        <TextArea
                            autoSize
                            onKeyDown={(e) => {
                                if (e.key === 'Tab' || e.key === 'Enter') {
                                    if (keyFunction)
                                        keyFunction(e, record.countNumber, dataIndex)
                                    e.key === 'Enter' && save();
                                }
                            }}
                            ref={(c) => record && (refs[record.countNumber][dataIndex] = c)} value={record[dataIndex]}
                            onBlur={save}
                            onChange={(e) => {
                                save();
                                if (keyFunction)
                                    keyFunction(e, record.countNumber, dataIndex)
                            }}
                        />
                    </AutoComplete>
            ) 
            default:
                return (
                    <Input
                        onKeyDown={(e) => {
                            if (e.key === 'Tab' || e.key === 'Enter') {
                                if (keyFunction)
                                    keyFunction(e, record.countNumber, dataIndex)

                            }
                            if (e.key === 'Enter') {
                                save();
                            }
                        }}
                        ref={(c) => record && (refs[record.countNumber][dataIndex] = c)} value={record[dataIndex]}
                        onBlur={save}
                        onChange={(e) => {
                            save();
                            if (keyFunction)
                                keyFunction(e, record.countNumber, dataIndex)
                        }}
                    />
                )
        }
    }


    if (editable) {
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });

        childNode = (

            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={isrequire === true && [
                    {
                        required: true,
                        message: `${title} cần phải nhập!`,
                    },
                ]}
            >
                {CheckType(type)}
            </Form.Item>
        )
    }

    return <td {...restProps}>{childNode}</td>;
};

export default class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.validator = validator;
    }

    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    handleSave = row => {

        const newData = [...this.state.dataSource];
        let index = -1;
        if (this.props.keyFind2 !== undefined) {
            index = newData.findIndex(item => row[this.props.keyFind] === item[this.props.keyFind] && row[this.props.keyFind2] === item[this.props.keyFind2]);
            if (index === -1)
                index = newData.findIndex(item => row[this.props.keyFind] === item[this.props.keyFind]);
        }
        else if (this.props.keyFind !== undefined) {
            index = newData.findIndex(item => row[this.props.keyFind] === item[this.props.keyFind]);
        }
        else {
            index = newData.findIndex(item => row.key === item.key);
        }
        const item = newData[index];

        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        if (this.props.onchange) this.props.onchange(newData)
        this.setState({ dataSource: newData });
    };

    componentDidMount() {
        this.setState({ dataSource: this.props.dataSource });
    }

    getDataSource() {
        return this.state.dataSource;
    }

    focusNewRow(row, col) {
        refs[row][col].focus();
    }

    setDataSource(dataSource) {
        this.setState({ dataSource: dataSource.map(d => ({ ...d })) })
    }

    render() {
        const { dataSource } = this.state;
        if (dataSource === undefined || this.props.columns === undefined) return (<div></div>)
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };

        let dataSourceNew = dataSource.map(function (e, index) {
            let o = Object.assign({}, e);
            return o;
        })

        const columns = this.props.columns.map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    type: col.type,
                    isrequire: col.isrequire,
                    keyFunction: this.props.keyFunction,
                    maxLength: col.maxLength,
                    min: col.min,
                    max: col.max,
                    checkDate: col.checkDate,
                    startDay: col.startDay,
                    handleSearch: this.props.handleSearch && this.props.handleSearch,
                    selectOptions: this.props.selectOptions ? this.props.selectOptions : [],
                }),
            };
        });
        return (
            <div style={{ height: `${this.props.height ? this.props.height : 'unset'}` }}>
                <Table
                    scroll={{ y: this.props.scrollY ? this.props.scrollY : '65vh', x: 'max-content' }}
                    components={components}
                    rowClassName={(record, rowIndex) => {
                        if (this.props.rowClassName)
                            return `  ${this.props.rowClassName(record, rowIndex)} editable-row`
                    }}

                    dataSource={dataSourceNew}
                    columns={columns}
                    pagination={false}
                    rowKey={(row, index) => index}
                />
            </div>
        );
    }
}