import React, { Fragment, forwardRef, useImperativeHandle, useState } from 'react';
import "./notificationMessageStyle.scss";

export default forwardRef((props, ref) => {
    const [title, setTitle] = useState();
    const [content, setContent] = useState();

    useImperativeHandle(ref, () => ({
        Show: (title, content) => {
            show(title, content);
        },
        Close: () => {
            close();
        }
    }));

    const show = (title, content) => {
        setTitle(title);
        setContent(content);
        window.notificationMessageModal.showModal();
    }

    const close = () => {
        window.notificationMessageModal.close();
    }

    const keepVisible = (e) => {
        e.stopPropagation();
    }

    return (
        <Fragment>
            <dialog id="notificationMessageModal" onClick={() => close()}>
                <div onClick={(e) => keepVisible(e)}>
                    <h3>{title}</h3>
                    <div style={{
                        lineHeight: "30px"
                    }} dangerouslySetInnerHTML={{ __html: `${content}` }}></div>
                    <button onClick={() => close()} aria-label="close" class="x">❌</button>
                </div>
            </dialog>
        </Fragment>
    );
})