import { Button, Checkbox, Collapse, Modal, notification, Pagination, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
// import { listData } from '../../commons/menuData';
import * as Actions from '../../libs/actions';
import * as ConfigService from '../../services/configService';
import * as RoleService from '../../services/roleService';
import AddConfig from './AddConfig';
import { connect } from 'react-redux';
const { Panel } = Collapse;

function ConfigMonitor(props) {

    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [dataSource, setDataSource] = useState([]);
    const [selectMonitor, setSelectMonitor] = useState('');
    const [monitorName, setMonitorName] = useState('');
    const [visable, setVisable] = useState(false);
    const [listMenu, setListMenu] = useState(JSON.parse(JSON.stringify(props.menuData)))
    useEffect(() => {
        renderSmallMenu()
    }, [])
    const RenderMonitor = () => {
        return <div style={{ marginLeft: '-20px' }}>
            {listMenu.map((row, index) => {
                return checkNode(row);
            })}
        </div>;
    }

    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        GetListControllerByMonitor();
    }, [currentPage, size]);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [selectMonitor]);

    const renderSmallMenu = () => {
        ConfigService.GetListSubMenu(true).then(result => {
            if (result.isSuccess) {
                const smallMenu = result.data;
                const newListData = JSON.parse(JSON.stringify(props.menuData))//listMenu.map(x => ({ ...x }));
                for (let index = 0; index < newListData.length; index++) {
                    const row = newListData[index];
                    let data = smallMenu.filter(x => x.parent === row.code);
                    if (data.length > 0) {
                        if (row.nodes && row.nodes.length > 0) {
                            // row.nodes.concat(data);
                            row.nodes.forEach(e => {
                                const i = smallMenu.findIndex(x => x.code === e.code)
                                if (i >= 0)
                                    e.label = smallMenu[i].label
                            });
                            data.forEach(element => {
                                if (row.nodes.filter(x => x.code === element.code).length <= 0) {
                                    row.nodes.push(element)
                                }
                            });
                        }
                        else {
                            row.nodes = data;
                        }
                    }
                    if (row.nodes && row.nodes.length > 0) {
                        for (let indexChild = 0; indexChild < row.nodes.length; indexChild++) {
                            const child = row.nodes[indexChild];
                            data = smallMenu.filter(x => x.parent === child.code);
                            if (data.length > 0) {
                                if (child.nodes && child.nodes.length > 0) {
                                    // child.nodes.concat(data);
                                    child.nodes.forEach(e => {
                                        const i = smallMenu.findIndex(x => x.code === e.code)
                                        if (i >= 0)
                                            e.label = smallMenu[i].label
                                    });
                                    data.forEach(element => {
                                        if (child.nodes.filter(x => x.code === element.code).length <= 0) {
                                            child.nodes.push(element)
                                        }
                                    });
                                }
                                else {
                                    child.nodes = data;
                                }
                            }
                            if (child.nodes && child.nodes.length > 0) {
                                for (let indexSub = 0; indexSub < child.nodes.length; indexSub++) {
                                    const sub = child.nodes[indexSub];
                                    data = smallMenu.filter(x => x.parent === sub.code);
                                    if (data.length > 0) {
                                        if (sub.nodes && sub.nodes.length > 0) {
                                            // sub.nodes.concat(data);
                                            sub.nodes.forEach(e => {
                                                const i = smallMenu.findIndex(x => x.code === e.code)
                                                if (i >= 0)
                                                    e.label = smallMenu[i].label
                                            });
                                            data.forEach(element => {
                                                if (sub.nodes.filter(x => x.code === element.code).length <= 0) {
                                                    sub.nodes.push(element)
                                                }
                                            });
                                        }
                                        else {
                                            sub.nodes = data;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                setListMenu(JSON.parse(JSON.stringify(newListData)))
            }
        })
    }

    const checkNode = (node) => {
        return <div className="config-monitor" style={{ marginLeft: '15px' }}>
            <div style={{ paddingLeft: '16px' }}>
                {(node.nodes === undefined || node.nodes.length === 0) &&
                    // <Checkbox checked={selectMonitor === node.code} style={{ marginRight: '10px' }}
                    //     onChange={() => {
                    //         setSelectMonitor(node.code);
                    //         setMonitorName(node.label)
                    //     }}
                    // >
                    //     {node.label}
                    // </Checkbox>
                    <div className='d-flex'>
                        <div style={{ width: 24 }}></div>
                        <div onClick={() => {
                            setSelectMonitor(node.code);
                            setMonitorName(node.label)
                        }}
                        >
                            {selectMonitor === node.code ?
                                <mark>{node.label}</mark>
                                :
                                node.label
                            }

                        </div>
                    </div>
                }

            </div>
            {node.nodes !== undefined && node.nodes.length > 0 &&
                <Collapse ghost className="m-0 p-0">
                    <Panel className="m-0 p-0"
                        header={<>
                            {/* <Checkbox checked={selectMonitor === node.code} style={{ marginRight: '10px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                onChange={() => {
                                    setSelectMonitor(node.code);
                                    setMonitorName(node.label)
                                }}
                            >
                            </Checkbox>
                            {node.label} */}
                            <div onClick={() => {
                                setSelectMonitor(node.code);
                                setMonitorName(node.label)
                            }}
                            >
                                {selectMonitor === node.code ?
                                    <mark>{node.label}</mark>
                                    :
                                    node.label
                                }

                            </div>
                        </>}>
                        {
                            node.nodes.map((data, index) => {
                                return checkNode(data)
                            })}
                    </Panel>
                </Collapse>
            }
        </div>
    }

    const GetListControllerByMonitor = () => {
        if (selectMonitor === '')
            return;
        Actions.setLoading(true);
        RoleService.GetListControllerByMonitor(selectMonitor, key, currentPage, size, false).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data)
                setTotalRecords(result.totalRecords)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const InsertControllerMonitor = (list) => {
        const data = {
            listController: list.map(x => x.id.toString()),
            idmonitor: selectMonitor,
        }
        Actions.setLoading(true);
        RoleService.InsertControllerMonitor(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhập thành công' });
                GetListControllerByMonitor();
                setVisable(false);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const DeleteControllerMonitor = (idcontroller) => {
        Actions.setLoading(true);
        RoleService.DeleteControllerMonitor(idcontroller, selectMonitor).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' });
                GetListControllerByMonitor();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    const columns = [
        {
            title: 'Controller',
            dataIndex: 'controller',
            key: 'Controller',
            width: 120
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'Action',
            width: 180
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'Method',
            width: 80,
        },
        {
            title: 'Dùng chung',
            align: 'center',
            width: 50,
            render: (row) => {
                return (
                    <Popconfirm
                        title="Bạn có chắc muốn xóa?"
                        onConfirm={(e) => { DeleteControllerMonitor(row.id) }}
                        okText="Đồng ý" cancelText="Hủy">
                        <div className="text-danger" style={{ cursor: 'pointer' }} >
                            <i className="fa fa-trash"></i>
                        </div>
                    </Popconfirm>
                )
            }
        }
    ];
    return (
        <>
            <div style={{ height: '100%', display: 'flex' }}>
                <div className="warrperStyleGroup" style={{ width: '50%', height: '100%', marginRight: 20 }}>
                    <div className="grouplabel" style={{ position: 'relative' }}>
                        <h4 style={{ fontWeight: 'bold' }}>Thông tin khám bệnh</h4>
                        <div style={{ position: 'absolute', right: 0 }}><Button
                            onClick={() => {
                                setListMenu(JSON.parse(JSON.stringify(props.menuData)))
                                renderSmallMenu()
                            }}
                        ><i className='fa fa-sync-alt'></i></Button></div>
                    </div>
                    <div style={{ height: 'calc(100% - 15px)', overflow: 'auto' }}>
                        {RenderMonitor()}
                    </div>
                </div>
                <div className="warrperStyleGroup" style={{ width: '50%', height: '100%', marginRight: 20 }}>
                    <div className="grouplabel">
                        <div style={{ fontWeight: 'bold' }} className="h4 text-center">Controller đang dùng {monitorName === '' ? '' : `(${monitorName})`}</div>
                    </div>

                    <div className="text-right">
                        <Button className="btn btn-color"
                            onClick={() => {
                                if (selectMonitor === '')
                                    notification.warning({ message: 'Chưa chọn màn hình!' })
                                else
                                    setVisable(true);
                            }}
                        >Thêm controller</Button>
                    </div>
                    <div style={{ height: 'calc(100% - 60px)' }}>
                        <Table dataSource={dataSource}
                            scroll={{ y: '65vh', x: 'max-content' }}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={currentPage}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setCurrentPage(page) }}
                        onShowSizeChange={(page, size) => { setSize(size) }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <Modal
                visible={visable}
                width={1400}
                onCancel={() => setVisable(false)}
            >
                <AddConfig onClose={() => setVisable(false)}
                    GetData={(data) => { InsertControllerMonitor(data); }}
                    currentData={dataSource}
                    monitorName={monitorName}
                />
            </Modal>
        </>
    )

}

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ConfigMonitor)
