import React, { useEffect, useRef, useState } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Button, notification, Pagination, Table, Tooltip } from 'antd';
import { FORMAT_DATE_TIME } from '../../../configure/systemConfig';
import moment from 'moment';
import * as SuperAdminService from "../../../services/superadminService";
import Notify, { NotifyStatus } from '../../../components/notify';
import { setLoading } from '../../../libs/actions';
import NoContent from '../../../components/loading/noContent';
import { BUTTON } from '../../../configureHIS/common/constants';

function SQLExecuteHistory(props) {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const _notify = useRef();

    useEffect(() => {
        getQueryHistory();
    }, [])

    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createAt',
            key: 'createAt',
            align: 'left',
            width: 100,
            render: (val) => moment(val).format(FORMAT_DATE_TIME)
        },
        {
            title: 'Lệnh',
            dataIndex: 'requestData',
            key: 'requestData',
            align: 'left',
            width: 200,
            render: (val) => <code>{val?.query ?? ""}</code>
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            width: 80,
            render: (val, record) => <Tooltip title="Chạy lại"><i style={{ cursor: "pointer" }} className='fas fa-play text-success' onClick={() => onReuseQuery(record.requestData?.query)} /></Tooltip>
        },
    ]

    const getQueryHistory = (p = page, s = size) => {
        let filterModel = {
            skipCount: (p - 1) * s,
            maxResultCount: s,
        }
        setLoading(true);
        SuperAdminService.QueryHistory(filterModel).then(result => {
            if (result.isSuccess) {
                setTotalRecords(result.data.totalCount);
                setData(result.data.items);
            } else {
                showErrorMessage(result.error.messageText);
            }
        }).catch(err => {
            showErrorMessage(err.error.messageText);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onCloseHistory = () => {
        if (props.onClose) {
            props.onClose();
        } else {
            notification.error({ message: "Không tìm thấy hàm thực thi." });
        }
    }

    const onReuseQuery = (query) => {
        if (props.reuseQuery)
            props.reuseQuery(query);
        else notification.error({ message: "Không tìm thấy hàm thực thi." })
    }

    const showErrorMessage = (err) => {
        _notify && _notify.current && _notify.current.Show(err.messageText, NotifyStatus.Error);
    }

    return (
        <div className="list-page">
            <HeaderModal title="Lịch sử thực thi" onClose={() => { onCloseHistory() }} />
            < Notify ref={_notify} />
            <div className="list-page-body">
                <Table
                    className="table-min-h-0 w-100"
                    dataSource={data}
                    columns={columns}
                    locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                    scroll={{ x: 0, y: 0 }}
                    rowKey={(e) => e.id}
                    pagination={false}
                />
                <Pagination
                    current={page}
                    size={size} defaultPageSize={20}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { setPage(page); setSize(size); getQueryHistory(page, size); }}
                    onShowSizeChange={(page, size) => { setPage(1); setSize(size); getQueryHistory(1, size); }}
                    showSizeChanger={true}
                    locale={{ items_per_page: "kết quả / trang" }}
                />
            </div>
            <div className='list-page-footer d-flex justify-content-end'>
                <Button className={`${BUTTON.DANGER}`} onClick={() => { onCloseHistory() }}><i className='fas fa-sign-out-alt mr-1' />Đóng</Button>
            </div>
        </div>
    );
}

export default SQLExecuteHistory;