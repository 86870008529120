import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Support';

export function SendSupportRequirement(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SendSupportRequirement`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};