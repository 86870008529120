import { Alert, Form, Input, Tooltip, notification } from 'antd';
import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as Actions from '../../libs/actions';


import * as ConfigService from '../../services/configService';
import HeaderModal from '../../configureHIS/common/headerModal';

let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

export default class ChangePassword extends React.PureComponent {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator(ValidationCustom);

		this.state = {
			isShow: false,
			txtNewPass: '',
			txtNewPassConfirm: '',
			color: '',
			bgr: '',
			status: ''
		}
	}
	onConfirm() {
		this.props.onConfirm();
	}

	changePass = () => {
		if (this.validator.allValid()) {
			if (this.state.txtNewPass !== this.state.txtNewPassConfirm) {
				Actions.openMessageDialog("lỗi", "Xác nhận lại mật khẩu không khớp");
			} else {
				ConfigService.changePass( {newpass : this.state.txtNewPass ,  userid : this.props.selectedEditUserID}).then((objRespone) => {
					if (objRespone.isSuccess) {
						this.props.onOk();
						this.setState({
							txtNewPass: '',
							txtNewPassConfirm: ''
						});
						notification.success({ message: "Cập nhật thành công" });
					} else {
						Actions.openMessageDialog("Lỗi " + objRespone.err.msgCode, objRespone.err.msgString.toString());
					}
				}).catch(err => {
				})
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	handleNewPassInput = (event) => {
		let value = event.target.value;
		this.setState({ txtNewPass: value });
		this.strengthChecker(value);
	}


	handleNewPassConfirmInput = (event) => {
		let value = event.target.value;
		this.setState({ txtNewPassConfirm: value });
	}
	strengthChecker(pass) {
		if (strongPassword.test(pass)) {
			this.setState({
				color: "green",
				bgr: "rgba(0,128,0,0.2)",
				status: "Mật khẩu mạnh"
			});
		} else if (mediumPassword.test(pass)) {
			this.setState({
				color: "green",
				bgr: "rgba(255,255,0,0.2)",
				status: "Mật khẩu mức trung bình"
			});
		} else {
			this.setState({
				color: 'red',
				bgr: "rgba(255,0,0,0.2)",
				status: "Mật khẩu yếu"
			});
		}
	}
	render() {
		const { isShow } = this.state;
		return (
			<React.Fragment>
				<HeaderModal title="Cập nhật mật khẩu" onClose={() => this.props.onCancel()} />
				<Alert type='warning' message="Sau khi cập nhật mật khẩu, tài khoản sẽ đăng xuất trên tất cả thiết bị." />
				<div className='row mt-2' style={{ display: 'flex', justifyContent: "center" }}>
					<div key="newpass" className="col-md-12">
						<Form.Item className='ant-not-form-item'>
							<label>Mật khẩu mới</label>
							<div style={{ display: "flex" }}>
								<Tooltip title={this.state.txtNewPass.length > 0 && this.state.status} color={this.state.color} placement="bottomRight">
									<Input
										type={`${this.state.isShow === true ? 'text' : 'password'}`} className="form-control" value={this.state.txtNewPass}
										style={{ background: this.state.txtNewPass.length > 0 && this.state.bgr }}
										onChange={
											this.handleNewPassInput.bind(this)
										} />
								</Tooltip>
								<i style={{
									color: isShow === true ? 'orange' : 'black', padding: '8px 10px', marginLeft: 5, cursor: 'pointer',
									border: isShow === true ? '1px solid orange' : '1px solid #ccc', borderRadius: 5
								}} className="fa fa-eye" onClick={() => { this.setState({ isShow: !this.state.isShow }) }} />
							</div>
							{this.validator.message("Mật khẩu mới", this.state.txtNewPass, 'required')}
						</Form.Item>
					</div>
					<div key={"newpassConfirm"} className="mt-2 col-md-12">
						<Form.Item className='ant-not-form-item'>
							<label>Xác nhận mật khẩu</label>
							<div style={{ display: 'flex' }}>
								<Input
									type={`${this.state.isShow === true ? 'text' : 'password'}`}
									className="form-control"
									value={this.state.txtNewPassConfirm}
									onChange={
										this.handleNewPassConfirmInput.bind(this)
									} />
								{this.state.txtNewPassConfirm.length > 0 && <i style={{ position: "absolute", right: "10px", top: "35px" }}>{this.state.txtNewPass !== this.state.txtNewPassConfirm ? <i style={{ color: "#f00" }} className="fas fa-times" /> : <i style={{ color: "#0f0" }} className="fas fa-check" />}</i>}
							</div>
							{this.validator.message("Xác nhận mật khẩu", this.state.txtNewPassConfirm, 'required')}
						</Form.Item>

					</div>
				</div>
				<div className='custom-hr'></div>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<button className="btn btn-color" onClick={() => {
						this.changePass()
					}}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>{' '}
					<button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => { this.props.onCancel() }}>
						<i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
				</div>
			</React.Fragment>
		);
	}
}
