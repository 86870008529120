import { Table } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import moment from 'moment';
import { FORMAT_DATE } from '../../../configure/systemConfig';

export default class index extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstDetail: [],
            lstColums: [],
            detail: this.props.detail
        };
    }
    render() {
        //let tongtien = this.props.detail.reduce((tmp, cur) => tmp + cur.thanhtien, 0);
        const columns = [
            {
                title: 'Ngày chỉ định',
                dataIndex: 'ngaychidinh',
                key: 'ngaychidinh',
                render: (value) => {
                    return <span>{moment(value.ngaycd).format(FORMAT_DATE)}</span>;
                },
            },   
            {
                title: 'Tên viện phí',
                dataIndex: 'tenvp',
                key: 'tenvp',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Đơn giá',
            //     dataIndex: 'dongia',
            //     key: 'dongia',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                //title: 'Giá KM',
                title: 'Đơn giá',
                dataIndex: 'giakm',
                key: 'giakm',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Tiền giảm',
            //     render: (row) => {
            //         return <span>{(row.dongia*row.soluong-row.thanhtien).toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                title: 'Thành tiền',
                dataIndex: 'thanhtien',
                key: 'thanhtien',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            
        ];
        let dataSource = this.props.detail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Table dataSource={dataSource} columns={columns}  pagination={false} footer={
                    null
                //     () => (
                //     <div style={{textAlign:"right"}}>Tổng: <strong>{tongtien.toLocaleString("en-US")}</strong> VNĐ</div>
                // )
                }/>
            </React.Fragment>
        )
    }
}