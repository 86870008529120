import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Medicine';
export function GetListKho(type)  {
    return new Promise((resolve, reject) => {
        let url = `${prefix}/listKho`;
        if(type !== undefined) url+=`?type=all`
        return ApiCaller.httpGet(url).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThemKho(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/addKho`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function TonKhoChiTiet(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/tonkhochitiet`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function SearchTonKhoThang(dateFrom, dateTo, makho, loai, key, page=1, size=10, notZero) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/tonkhothang?dateFrom=${dateFrom}&dateTo=${dateTo}&makho=${makho}&loai=${loai}&key=${key}&page=${page}&size=${size}&notzero=${notZero}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function SearchTonKhoChiTiet(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/searchtonkhochitiet`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuocHetHan(ngay,makho,key,page=1,size=10, songayhethan = 60)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/expiredmedicine?ngay=${ngay}&makho=${makho}&key=${key}&page=${page}&size=${size}&expdays=${songayhethan}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListWareHouse(value, page = 1, size = 10)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`ExtendWarehouse?key=${value}&page=${page}&size=${size} `).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function addWareHouse(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`ExtendWarehouse`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function updateWareHouse( id ,data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`ExtendWarehouse/${id}`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function deleteWareHouse(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`ExtendWarehouse/${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListDepartment(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`PhongKham/DanhSachPhongKham?key=${key}&page=0&size=10000`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateDateStorage(mabd,losx,makho,ngayhh,ngaysx)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateDateStorage?mabd=${mabd}&losx=${losx}&makho=${makho}&ngayhh=${ngayhh}&ngaysx=${ngaysx}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LstNhapKho(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LstNhapKho?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LstHoanTra(key, dateFrom, dateTo, page=1, size=10)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LstHoanTra?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function detailHoanTra(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/detailHoanTra?id=${id}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function Hoantra(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/HoanTra`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function editHoantra(idphieu,data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/editHoanTra/${idphieu}`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function deleteHoantra(idphieu,data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/deleteHoanTra/${idphieu}`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ConfigSoNgayHetHan()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ConfigSoNgayHetHan`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetMedicineMinRequirement(warehouse, keyword, currentPage, pageSize)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetMedicineMinRequirement?warehouse=${warehouse}&page=${currentPage}&size=${pageSize}&keyword=${keyword}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};