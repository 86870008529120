import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import {  Button, List, Menu, Select, Modal, Checkbox, Input } from 'antd';
import * as Actions from '../../libs/actions';
import * as SystemConfig from '../../configure/systemConfig';
import * as CompanyExamService from '../../services/CompanyExamService';
import Notify, { NotifyStatus } from '../../components/notify';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import PrintSID from "../invoice/paymentmulti/InSIDBarcode";
import { groupBy, isNullOrEmpty, isnotnull, renumber, uppercaseFirstLetter } from "../../libs/util";
import Handlebars from "handlebars";
import { default as num2words } from 'vn-num2words';
import handlebarHelper from "../../libs/handlebarHelper";
import MitaChoosePrintTemplate from "../../components/MitaComponents/mitaChoosePrintTemplate";

const { Option } = Select;
export default forwardRef((props, ref) => {
  //niếm cục bộ
  //ref
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(10);
  const [sortingField, setSortingField] = useState('');
  const [sortingType, setSortingType] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  const [companyExamId, setCompanyExamId] = useState(props.companyExamId);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  const [sortFields, setSortFields] = useState([]);
  const [sortTypes, setSortTypes] = useState([]);

  const [checkPrintAll, setCheckPrintAll] = useState(false);
  const [indeterminateCheckPrintAll, setIndeterminateCheckPrintAll] = useState(false);
  const [visiblePrint, setVisiblePrint] = useState(false);
  const [listSidSelected, setListSidSelected] = useState([]);
  const [listMaqlSelected, setListMaqlSelected] = useState([]);
  const listPageSize = [10,20,50,100];
  const [templateId, setTemplateId] = useState();
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    setCheckPrintAll(listSidSelected.length == listResult.length);
  }, [listSidSelected])

  useEffect(() => {
    setIndeterminateCheckPrintAll(listSidSelected.length > 0 && listSidSelected.length < listResult.length);
  }, [listSidSelected, listResult])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [maxResultCount, sortingField, sortingType])

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [skipCount])

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [props.companyExamId]);

  useEffect(() => {
    firstRender.current = false;
    resetToFirstPage();
  }, []);
  //#endregion  

  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      sorting: ((sortingField ?? '').length < 1 ? 'stt' : sortingField) + ((sortingType ?? '').length < 1 ? ' asc' : sortingType),
      //riêng theo module
      keyword: keyword,
      companyExamId: companyExamId,
    }
    CompanyExamService.tiepDonList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setSortFields(result.data.sortFields);
        setSortTypes(result.data.sortTypes);
        if (skipCount == 0)
          setListResult(result.data.items);
        else {
          setListResult([...listResult, ...result.data.items]);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //các options cho row
  const rowOptions = (record) => {
    return (
      <Menu
        items={[
        //   {
        //     label: 
        //       <a href="#" onClick={() => {
        //         // loadCardCreate(record);
        //       }}><i className="fas fa-barcode mr-2" /> Tạo & In SID</a>,
        //     key: '0',
        //   },
        ]}
      />
    )
  }
  //#region print sid
  const onChangeCheckPrintAll = (e) => {
    setCheckPrintAll(e.target.checked);
    if (e.target.checked) {
      setListMaqlSelected(listResult.map(x=>x.maQL));
      setListSidSelected(listResult.map(x=>x.sid));
    }
    else { setListMaqlSelected([]); setListSidSelected([]); }
  }
  //#endregion 
  const PrintIndication = () => {
    if (!isnotnull(listSidSelected)) {
      Actions.openMessageDialog("Thông báo", "Vui lòng chọn dữ liệu cần in");
      return;
    }
    const data = {
      id: companyExamId,
      listSID: listSidSelected,
      listMaQL: listMaqlSelected,
      templateId
    };
    Actions.setLoading(true);
    CompanyExamService.PrintTestIndication(data).then((result) => {
      if (isNullOrEmpty(result.data)) {
        Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ xét nghiệm");
        return;
      } else {
        let fullPageTemplate = "";
        let data = result.data;
        if (data && data.length > 0) {
          data.forEach(fData => {
            if (isNullOrEmpty(fData.template)) {
              Actions.openMessageDialog("Không có thông tin để in", "Không có mẫu in");
              return;
            }
            let templateMaster = "";
            var finalData = fData;
            const listTest = finalData.data?.listTest ?? []; // Danh sách chỉ định xét nghiệm (Chưa phân theo orderId)
            const listOrderId = finalData?.listOrderId ?? []; // Danh sách orderId
            if (listTest.listIndication && listTest.listIndication.length > 0) {
              var listTestGroupByOrderId = groupBy(listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo loại
              Object.keys(listTestGroupByOrderId).forEach(orderId => {
                let listTestBelongToGroup = listTestGroupByOrderId[orderId];
                const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
                const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
                const resultGroupArrRenumber = renumber(resultGroupArr);
                let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                let dataInfo = {
                  listIndication: resultGroupArrRenumber,
                  sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
                  title: `${listTest.title?.toUpperCase() ?? "PHIẾU CHỈ ĐỊNH XÉT NGHIỆM"}`,
                  amount: totalMoney,
                  amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                  amountOfTest: listTest.amountOfTest ?? 0,
                };
                let keyName = `test${orderId}`;
                var tempData = { ...listTest, [`${keyName}`]: dataInfo };
                finalData.data = tempData;
                templateMaster += finalData.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
              });
              const template = Handlebars.compile(finalData.template.replace("#listMasterData", templateMaster));
              Handlebars.registerHelper(handlebarHelper);
              fullPageTemplate += template(finalData);
            }
          })
        }
        if (fullPageTemplate != "") Actions.DoPrint(fullPageTemplate);
        else Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ xét nghiệm");
      }
    }).finally(() => Actions.setLoading(false));
  }
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h5>Danh sách khách hàng đăng ký dịch vụ</h5>
              <h2 hidden={!props.isDialog || props.hiddenClose}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h2>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12 pl-1"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                <div className="col-md-12 pl-1 d-flex flex-column">
                  <Input
                    placeholder="Nhập mã, tên khách hàng, SID"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
              </div>
            <div className="mt-2">
              <MitaChoosePrintTemplate
                title="Mẫu in phiếu chỉ định"
                componentId={'PrintIndication'}
                displayMode={'select'}
                changeSelectedId={(id) => { setTemplateId(id); }}
              />
            </div>
              <div className="row mt-2">
                  <div className='col-md-12 pl-1 d-flex justify-content-between'>
                    <div>
                        <Checkbox checked={checkPrintAll}
                          indeterminate={indeterminateCheckPrintAll}
                          onChange={(e) => {
                            onChangeCheckPrintAll(e);
                          }}
                        ></Checkbox>
                        <label className="mx-1"><b>|</b></label>
                        <Button className="pl-0"
                            type="link"
                            onClick={() => {
                              PrintIndication();
                            }}
                        ><i className="fa fa-print mr-1" />In chỉ định xét nghiệm</Button>
                        <Button className="pl-0"
                            type="link"
                            onClick={() => {
                              setVisiblePrint(true);
                            }}
                        ><i className="fa fa-print mr-1" />In SID</Button>
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <label>Sắp xếp theo</label>
                        <Select className="ml-1" style={{ width:'140px' }}
                            allowClear
                            placeholder=""
                            maxTagCount= 'responsive'
                            value={ sortFields.find(x => x.selected)?.key }
                            onChange={(value) => { setSortingField(value ?? ''); }}
                        >
                            {(sortFields ?? []).map((record, index) => {
                              // if (record.selected) setSortingField(record.key);
                              return (
                                <Option key={record.key} value={record.key}>{record.text}</Option>
                              )
                            })}
                        </Select>
                        <Select className="mx-1" style={{ width:'100px' }}
                            allowClear
                            placeholder=""
                            maxTagCount= 'responsive'
                            value={ sortTypes.find(x => x.selected)?.key }
                            onChange={(value) => { setSortingType(value ?? ''); }}
                        >
                            {( sortTypes ?? []).map((record, index) => {
                              // if (record.selected) setSortingField(record.key);
                              return (
                                <Option key={record.key} value={record.key}>{record.text}</Option>
                              )
                            })}
                        </Select>
                        <label>Hiển thị</label>
                        <Select className="ml-1" 
                            allowClear
                            placeholder=""
                            maxTagCount= 'responsive'
                            value={ maxResultCount }
                            onChange={(value) => { setMaxResultCount(value); }}
                        >
                        {listPageSize.map((record, index) => {
                            return (
                                <Option key={record} value={record}>{record}</Option>
                            )
                        })}
                        </Select>
                    </div>
                  </div>
              </div>
            </div>
            <div className="list-page-table mt-2">{/* list */}
              <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                bordered={false}
                dataSource={listResult}
                renderItem={(record) => {
                  let className = "p-1 border-2 border-bottom py-2";
                  return (
                    <List.Item key={record.id}
                      className={className}
                    >
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between">{/* dòng 1 */}
                          <div className="">
                            <Checkbox className="mr-2"
                                checked={listMaqlSelected.indexOf(record.maQL) > -1}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setListMaqlSelected([...listMaqlSelected, record.maQL]);
                                    setListSidSelected([...listSidSelected, record.sid]);
                                  }
                                  else {
                                    setListMaqlSelected(listMaqlSelected.filter(x => x != record.maQL));
                                    setListSidSelected(listSidSelected.filter(x => x != record.sid));
                                  }
                                }}
                            ></Checkbox>
                            <b>{record.hoTen} - {record.maBN}</b>
                          </div>
                          <div>
                            <label className="mr-2">Giới tính: <b>{record.phai == 0 ? "Nam" : "Nữ"}</b></label>
                            {/* <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                              <a className="ant-dropdown-link d-inline-block text-center" style={{ width: 13 }}><i className='fas fa-ellipsis-v'></i></a>
                            </Dropdown> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">{/* dòng 2 */}
                          <div className="">
                            <div className="d-inline-block mr-2" style={{width:16}}></div>
                            <label>Ngày sinh: <b>{record.onlyYear == true ? record.namSinh : moment(record.ngaySinh).format(SystemConfig.FORMAT_DATE)}</b></label>
                          </div>
                          <div>
                            <label className="mr-2">SID: <b>{record.sid}</b></label>
                            {/* <a className="d-inline-block" style={{ width: 13 }}><i className='fa fa-print'></i></a> */}
                          </div>
                        </div>
                      </div>
                    </List.Item>
                )}}
              >
                <List.Item key="showMore">
                  <div className="w-100 d-flex justify-content-center">
                    <Button className="pl-0"
                      type="link"
                      hidden={totalRecord <= listResult.length}
                      onClick={() => {
                        setSkipCount(skipCount + maxResultCount);
                      }}
                    >... Xem thêm ...</Button>
                  </div>
                </List.Item>
              </List>
            </div>
          </div>
      </div>
      <Modal
        width={800}
        visible={visiblePrint}
        maskClosable={true}
        onClose={() => setVisiblePrint(false)}>
        <PrintSID billSelected={listSidSelected.join(',')} onSubmit={() => { setVisiblePrint(false) }} onCancel={() => { setVisiblePrint(false) }} />
      </Modal>
    </React.Fragment>
  );
})