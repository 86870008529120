import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { Button, Spin, Table, Input, InputNumber, Radio, Tag, Popconfirm, Popover, Tooltip } from 'antd';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as InvoiceService from '../../../services/invoiceService';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as Actions from '../../../libs/actions';
import { isNullOrEmpty, isArrayEmpty } from '../../../libs/util';
import SeparatePayment from '../payment/components/SeparatePayment';
import { connect } from 'react-redux';
import { USER_AUTH } from '../../../configureHIS/common/constants';

const optionsDiscount = [
    { label : '%', value : 0},
    { label : 'đ', value : 1},
];
const DISCOUNTTYPE = {
    PERCENT : 0,
    VALUE : 1
}
const ModifyInvoice = (props) => {
    const notiRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [billInfo, setBillInfo] = useState({})
    const [listResult, setListResult] = useState([]);
    const [totalBill, setTotalBill] = useState(0);
    const [discountValueService, setDiscountValueService] = useState(0);
    const [discountType, setDiscountType] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);      
    const [discountRemark, setDiscountRemark] = useState('')
    const [listSelectedPaymentType, setListSelectedPaymentType] = useState([]);
    const [visibleSeparatePayment, setVisibleSeparatePayment] = useState(false);
    const [disableDiscountBill, setDisableDiscountBill] = useState(false);

    useEffect(() => {
        getBillDetail()
    },[props.receiptNumber])

    const getBillDetail = () => {
        Actions.setLoading(true);
        InvoiceService.getBillDetail(props.receiptNumber).then(res => {
            if (res.isSuccess) {
                // Danh sách dịch vụ
                let listService = res.data.listDetail.map(x => ({...x, discountType : 1}))
                setListResult(listService);
                // Thông tin hóa đơn
                setBillInfo(res.data);
                setTotalBill(res.data.total);
                setListSelectedPaymentType(res.data.paymentTypes);
                setDiscountValue(res.data.discount);
                setDiscountPercent(res.data.discountPercent ?? 0);
                let isPercent = res.data.isPercent == true ? DISCOUNTTYPE.PERCENT : DISCOUNTTYPE.VALUE;
                setDiscountType(isPercent);
                setDiscountRemark(res.data.remark);
                // Notification
                if(res.data.listDetail.filter(x => x.isRefund).length > 0){
                    setDisableDiscountBill(true);
                    notiRef && notiRef.current && notiRef.current.Show("Phiếu đã có dịch vụ hoàn trả. Không thể điều chỉnh miễn giảm hóa đơn.", NotifyStatus.Warning);
                } else {
                    notiRef && notiRef.current && notiRef.current.Close();
                }
                forceUpdate();
            }
            else {
                notiRef && notiRef.current && notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {

        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const onSubmit = () => {
        if(onValidate()){
            let listPaymentMethod = [];
            if(listSelectedPaymentType && listSelectedPaymentType.length === 1){
                listPaymentMethod = listSelectedPaymentType.map(x => {
                    return {
                        ma : x.ma,
                        tongtien : Number(totalBill - discountValueService - discountValue)
                    }
                })
            } else {
                listPaymentMethod = listSelectedPaymentType
            }
            let data = {
                'BillId' : billInfo.billId,
                'BillNumber' : billInfo.billNumber,
                'Remark' : discountRemark,
                'Total' : Number(totalBill - discountValueService),
                'Discount' : Number(discountValue),
                'DiscountPercent' : Number(discountPercent),
                'IsPercent' : discountType === DISCOUNTTYPE.PERCENT,
                'ListDetail' : listResult,
                'PaymentTypes' : listPaymentMethod
            } 

            InvoiceService.ModifyDiscountInvoice(data).then(result => {
                if (result.isSuccess) {
                    notiRef && notiRef.current && notiRef.current.Show("Cập nhật thành công", NotifyStatus.Success);
                    setTimeout(() => { props.onSubmit() }, 500)
                } else {
                    notiRef && notiRef.current && notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                }
            }).catch(err => {
                if (err && err.error && err.error.messageText)
                    notiRef.current && notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
                else notiRef.current && notiRef.current.Show(err, NotifyStatus.Warning);
            }).finally(() => {
                Actions.setLoading(false);
                forceUpdate();
            })
        }
    }

    const onValidate = () => {
        let listService = listResult;
        for (var i = 0; i < listService.length; i++) {
            if(listService[i].discount > 0 && isNullOrEmpty(listService[i].discountRemark)){
                notiRef && notiRef.current && notiRef.current.Show(`Vui lòng nhập lý do miễn giảm cho dịch vụ ${listService[i].name}.`, NotifyStatus.Warning);
                return false;
            }
        }
        if(discountPercent > 0 && isNullOrEmpty(discountRemark)){
            notiRef && notiRef.current && notiRef.current.Show(`Vui lòng nhập lý do miễn giảm cho hóa đơn.`, NotifyStatus.Warning);
            return false;
        }
        if(discountValue > totalBill){
            notiRef && notiRef.current && notiRef.current.Show(`Số tiền miễn giảm không được lớn hơn tổng tiền.`, NotifyStatus.Warning);
            return false;
        }
        if(listSelectedPaymentType && listSelectedPaymentType.length > 1) {
            let amount = 0;
            listSelectedPaymentType.forEach(x => amount += x.tongTien)
            if(amount !== (totalBill - discountValue)){
                notiRef && notiRef.current && notiRef.current.Show(`Số tiền phân bổ không bằng tổng tiền. Vui lòng phân bổ lại.`, NotifyStatus.Warning);
                return false;
            }
        }
        return true;
    }

    const onChangeDiscountTypeService = (record, value) => {
        let listService = listResult;
        listService.map(c => {
            if(c.idChiDinh === record.idChiDinh){
                c.discountType = value;
                c.discount = 0;
                c.discountPercent = 0;
            }
        })
        setListResult(listService);
        forceUpdate();
    } 

    const onDiscountService = (type, record, value) => {
        let listService = listResult;
        let discountServiceValue = 0;
        let totalBill = 0;
        
        listService.map(c => {
            if(c.idChiDinh === record.idChiDinh){
                if(type === DISCOUNTTYPE.PERCENT){
                    discountServiceValue = record.price * (value / 100);
                    c.discountPercent = value;
                } else {
                    discountServiceValue = value;
                }
                c.discount = discountServiceValue;
                c.total = c.price - discountServiceValue;
            }
            totalBill += (c.price - c.discount)
        })

        if( discountType === DISCOUNTTYPE.PERCENT ){
            let value = Number(totalBill * (discountPercent / 100))
            setDiscountValue(value);
        } else {
            let value = Number((discountValue / totalBill) * 100)
            setDiscountPercent(value)
        }
        setTotalBill(Number(totalBill));
        setListResult(listService);
        forceUpdate();
    }

    const onChangeDiscountRemark = (record, value) => {
        let listService = listResult;
        listService.map(c => {
            if(c.idChiDinh === record.idChiDinh){
                c.discountRemark = value
            }
        })
        setListResult(listService);
        forceUpdate();
    }

    const onSubmitSeperatePayment = (data) => {
        if(!isArrayEmpty(data)){
            let listSelected = data.map(x => {
                return ({
                    ma : x.ma,
                    tongTien : x.amount
                })
            });
            setListSelectedPaymentType(listSelected)
        }
        setVisibleSeparatePayment(false);
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: '5%',
            render: (text, record, index) => {
                return <span>{ index + 1 }</span>;
            }
        },
        { 
            title: 'Dịch vụ',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '20%',
            render : (value, record) => {
                return (
                    <span>{value}</span>
                )
            }
        },
        {
            title: 'Đơn giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            width: '10%',
            render : (value, record) => {
                return (
                    <span>{Number(value).toLocaleString('en-US')}</span>
                )
            }
        },
        {
            title: 'Số tiền giảm',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            width: '30%',
            render : (value, record) => {
                return (
                    <Fragment>
                        <Radio.Group
                            className="mr-2"
                            options={optionsDiscount}
                            onChange={(e) => { onChangeDiscountTypeService(record, e.target.value);}}
                            value={record.discountType}
                            optionType="button"
                            buttonStyle="solid"
                            disabled={record.isRefund}
                        />
                        <InputNumber
                            className='w-25 mr-2'
                            value={record.discountPercent}
                            onChange={(value) => {
                                onDiscountService(DISCOUNTTYPE.PERCENT, record, value)
                            }}
                            min={0}
                            max={100}
                            placeholder='% giảm'
                            disabled={record.discountType === DISCOUNTTYPE.VALUE || record.isRefund}
                        ></InputNumber>
                        <InputNumber
                            className='w-25'
                            value={value}
                            onChange={(value) => {
                                onDiscountService(DISCOUNTTYPE.VALUE, record, value)
                            }}
                            min={0}
                            max={record.price}
                            step={1000}
                            maxLength={12}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            disabled={record.discountType === DISCOUNTTYPE.PERCENT || record.isRefund}
                            placeholder='số tiền'
                        ></InputNumber>
                    </Fragment>
                )
            }
        },
        {
            title: 'Lý do giảm',
            dataIndex: 'discountRemark',
            key: 'discountRemark',
            align: 'left',
            width: '25%',
            render : (value, record) => {
                return (
                    <Input className='w-100' 
                        value={value} 
                        placeholder='Lý do miễn giảm' 
                        onChange={(e) => onChangeDiscountRemark(record, e.target.value)} 
                        disabled={record.isRefund}
                    />
                )
            }
        },
        {
            title: 'Thành tiền',
            align: 'right',
            width: '10%',
            render : (value, record) => {
                return (
                    <span>{Number(record.price - record.discount).toLocaleString('en-US')}</span>
                )
            }
        },
    ]

    return (
        <Fragment>
            <div className="list-page">
                <div className="list-page-body overflow-auto">{/* body */}
                    <HeaderModal title='Điều chỉnh miễn giảm' onClose={() => props.onClose()}/>
                    <Notify ref={notiRef} />
                    <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                        <Table style={{ height: "calc(100% - 20px)", width: "100%" }}
                            className="table-min-h-0 table-header-1row"
                            dataSource={listResult} 
                            columns={columns}
                            locale={{ emptyText: 'Không có dữ liệu' }} 
                            scroll={{ x: 'max-content', y: 0 }}
                            rowKey={(e) => e.idVienPhi + e.code}
                            rowClassName={(record, rowIndex) => {
                            }}
                            pagination={false}
                        />
                    </div>
                    <div className='row'>
                        <div className="col-12 h-100 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column mr-2" style={{width : '50%'}}>
                                <div className="w-100 flex-column mt-1">
                                    <div className="d-flex justify-content-between">
                                        <Radio.Group
                                            className="mr-2"
                                            options={optionsDiscount}
                                            onChange={(e) => { setDiscountType(e.target.value) }}
                                            value={discountType}
                                            optionType="button"
                                            buttonStyle="solid"
                                            disabled={disableDiscountBill}
                                        />
                                        {discountType === DISCOUNTTYPE.PERCENT && 
                                            <InputNumber
                                                className='w-25 mr-2' 
                                                value={discountPercent}
                                                onChange={(e) => {
                                                    setDiscountPercent(e);
                                                    let value = Number(totalBill * (e / 100)).toFixed(0)
                                                    setDiscountValue(value);
                                                }}
                                                min={0}
                                                max={100}
                                                placeholder='% giảm'
                                                disabled={disableDiscountBill}
                                            ></InputNumber>
                                        }
                                        <InputNumber
                                            className='w-50'
                                            value={discountValue}
                                            onChange={(e) => {
                                                setDiscountValue(e)
                                                let value = Number((e / totalBill) * 100)
                                                setDiscountPercent(value)
                                            }}
                                            min={0}
                                            max={totalBill}
                                            step={1000}
                                            maxLength={12}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            disabled={discountType === 0 || disableDiscountBill}
                                            placeholder='số tiền'
                                        ></InputNumber>
                                    </div>
                                </div>
                                <div className="w-100 d-flex flex-column justify-content-between mt-1">
                                    <span className={(discountValue > 0) && "required-field"}>Lý do giảm</span>
                                    <Input.TextArea 
                                        className="w-100"
                                        rows={2} 
                                        value={discountRemark} 
                                        onChange={(e) => setDiscountRemark(e.target.value)}
                                        disabled={disableDiscountBill}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column" style={{width : '30%'}}>
                                <Tag className="w-100 d-flex flex-row justify-content-between align-items-center p-1">
                                    <span>THÀNH TIỀN</span>
                                    <span><h4>đ {Number(totalBill - discountValueService).toLocaleString('en-US')}</h4></span>
                                </Tag>
                                <Tag color="volcano"  className="w-100 d-flex flex-row justify-content-between align-items-center p-1 mt-1">
                                    <span>MIỄN GIẢM</span>
                                    <span><h4>đ {Number(discountValue).toLocaleString('en-US')}</h4></span>
                                </Tag>
                                <Tag color="blue" className="w-100 d-flex flex-row justify-content-between align-items-center p-1 mt-1">
                                    <span>THANH TOÁN</span>
                                    {listSelectedPaymentType && listSelectedPaymentType.length > 1 &&
                                        <Popover
                                            content={
                                                <div style={{ width: 400, padding: 10 }}>
                                                <SeparatePayment
                                                    data = {{
                                                        billNumber : billInfo.billNumber,
                                                        totalBill : Number(totalBill - discountValue)
                                                    }}
                                                    onSubmit = {(value) => { onSubmitSeperatePayment(value)}}
                                                    onClose={() => setVisibleSeparatePayment(false)}
                                                    isPayment = {true}
                                                    isChangePayment = {false}
                                                    showCancel={false} />
                                                </div>}
                                            trigger="click"
                                            placement="top"
                                            visible={visibleSeparatePayment}
                                            onVisibleChange={(e) => setVisibleSeparatePayment(e)}
                                        >
                                            <Tooltip title="Phân bổ hình thức thanh toán">
                                                <div className="anchor-color" onClick={() => setVisibleSeparatePayment(true)}><i className="fas fa-sitemap"></i></div>
                                            </Tooltip>
                                        </Popover>
                                    }
                                    <span><h4>đ {Number(totalBill - discountValueService - discountValue).toLocaleString('en-US')}</h4></span>
                                </Tag>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 d-flex justify-content-center'>
                            {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.DISCOUND] &&
                                <Popconfirm
                                    title="Xác nhận cập nhật"
                                    onConfirm={() => { onSubmit() }}
                                    okText="Đồng ý"
                                    cancelText="Hủy">
                                    <Button className="btn btn-color mr-2"><i className="fas fa-save mr-1" /> Cập nhật</Button>
                                </Popconfirm>
                            }
                            <Button className='btn btn-danger' onClick={() => props.onClose()}>
                                <i className="fas fa-sign-out-alt mr-1"/> Đóng
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};


const mapStateToProps = (state) => ({
    userScreenAuthorization: state.global.userScreenAuthorization,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ModifyInvoice);
