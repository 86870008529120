import * as ApiCaller from '../../../libs/httpRequests';
const prefix = 'PhongKham';

export function GetDanhSachPKham(key,page,size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachphongkham?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatPhongKham(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatephongkham`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoMoiPK(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taophongkham`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};



export function DanhSachLoaiPhongKham() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLoaiPhongKham`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachKhoa() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachKhoaKham`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};




export function getListDepartment(key, page,size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/departments?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function createNewDepartent(department) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/departments`, department).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateDepartent(department) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/departments`, department).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteDepartment(makp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/departments/${makp}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function deleteRoom(idPhong) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/rooms/${idPhong}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
