import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Feature';

//#region dành cho client (user thường)
export function cGetList() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/cgetlist`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion

export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(id, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/update/${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function toggleActive(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/toggle-active/${data.id}`, data.isActive).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function saveImages(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/saveImages`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};