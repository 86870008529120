import { Button, Collapse, Form, Modal, notification, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as CommonService from '../../../services/commonService';
import * as PTTTServices from '../../../services/phauthuthuatService';
const { Panel } = Collapse;
const init = {
    tenpt: '',
    mapt: '',
    lstTenpt: [],
    ngaykt: moment(),
    giokt: moment(),
    detail: {},
    ppvocam: '',
    saupt: '',
    truocpt: '',
    ghichu: '',
    gayme: '',
    phumo: '',
    idpttt: '',
    nhom: 0,
    loai: '',
    mabs: '',
    isModal: false,
    HistoryDrawer: false,
    selectedPatient: {},
}
export default class thuthuat extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...init,
            chiDinh: '',
            soluong: 1,
            lstLoaiChiDinh: [],
            loaiChiDinh: '',
            lstChiDinh: [],
            lstChiDinhBenhNhan: [],
            lstMeTe: [],
            lstMabs: [],
            lstLoai: [],
            lstNhom: [],
            code: typeof (this.props.code) !== undefined ? this.props.code : '',
            name: typeof (this.props.name) !== undefined ? this.props.name : '',
            department: typeof (this.props.department) !== undefined ? this.props.department : '',
            lstdepartment: [],
            cardNumber: typeof (this.props.cardNumber) !== undefined ? this.props.cardNumber : '',
            enterNumber: typeof (this.props.enterNumber) !== undefined ? this.props.enterNumber : '',
            departmentName: typeof (this.props.departmentName) !== undefined ? this.props.departmentName : '',
            subjectName: typeof (this.props.subjectName) !== undefined ? this.props.subjectName : '',
            subjectCode: typeof (this.props.subjectCode) !== undefined ? this.props.subjectCode : '',
            managerId: typeof (this.props.managerId) !== undefined ? this.props.managerId : '',
            diagnostic: typeof (this.props.machuandoan) !== undefined ? this.props.machuandoan : '',
            diagnosticName: typeof (this.props.tenchuandoan) !== undefined ? this.props.tenchuandoan : '',
            doctorReceive: typeof (this.props.mabs) !== undefined ? this.props.mabs : '',
            dateIn: typeof (this.props.dateIn) !== undefined ? this.props.dateIn : moment().format("YYYY/MM/DD HH:mm"),
            detail: {},
            lstResult: [],
        };
    }
    componentDidMount() {
        this.setState({
            code: typeof (this.props.code) !== undefined ? this.props.code : '',
            name: typeof (this.props.name) !== undefined ? this.props.name : '',
            department: typeof (this.props.department) !== undefined ? this.props.department : '',
            lstdepartment: [],
            cardNumber: typeof (this.props.cardNumber) !== undefined ? this.props.cardNumber : '',
            enterNumber: typeof (this.props.enterNumber) !== undefined ? this.props.enterNumber : '',
            departmentName: typeof (this.props.departmentName) !== undefined ? this.props.departmentName : '',
            subjectName: typeof (this.props.subjectName) !== undefined ? this.props.subjectName : '',
            subjectCode: typeof (this.props.subjectCode) !== undefined ? this.props.subjectCode : '',
            managerId: typeof (this.props.managerId) !== undefined ? this.props.managerId : '',
            diagnostic: typeof (this.props.machuandoan) !== undefined ? this.props.machuandoan : '',
            diagnosticName: typeof (this.props.tenchuandoan) !== undefined ? this.props.tenchuandoan : '',
            doctorReceive: typeof (this.props.mabs) !== undefined ? this.props.mabs : '',
            dateIn: typeof (this.props.dateIn) !== undefined ? this.props.dateIn : moment().format("YYYY/MM/DD HH:mm"),
        }, () => {
            this.DanhSachChiDinhBenhNhan();
        })
    }
    resetModal() {
        this.setState(init);
    } GetHistoryDetails
    loadThongTinThuThuatBenhNhan() {
        PTTTServices.ThongTinThuThuatBenhNhan(this.state.code, this.state.managerId, this.state.detail.mavp, this.state.detail.ngay).then(result => {
            if (result.isSuccess) {
                this.setState({
                    ketluan: result.item.ketluan,
                    ngaykt: result.item.ngaykt,
                    tenbs: result.item.tenbs,
                    idpttt: result.item.idpttt,
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm bị lỗi' })
        })
    }
    loadDoctor() {
        CommonService.GetListDoctorTT(1, 200).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstMabs: result.data,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }

    DanhSachChiDinh(key) {
        ChiDinhService.DanhSachChiDinh(key, this.state.loaiChiDinh, this.state.subjectCode, this.state.cardNumber, this.state.department).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstChiDinh: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu chỉ định lỗi' })
        })
    }
    DanhSachChiDinhBenhNhan() {
        ChiDinhService.DanhSachThuThuatBenhNhan(this.state.code, this.state.managerId, this.state.dateIn).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstChiDinhBenhNhan: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu phẫu thuật của bệnh nhân bị lỗi')
        })
    }
    render() {
        const columns = [

            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
            },
            {
                title: 'Dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
            },
            {
                title: 'Trạng thái',
                render: (record) => {
                    return (
                        <span>{record.idpttt === null || record.idpttt === '' ? "Mới" : "Hoàn thành"}</span>
                    )
                },
            },
        ];
        return (
            <React.Fragment>
                {this.state.lstChiDinhBenhNhan.length === 0 ?
                    <div>
                        <p>Bệnh nhân này không có thủ thuật nào</p>
                    </div>
                    :
                    <div>
                        {this.props.history !== true ?
                            <div style={{ padding: '10px' }} >
                                <Form ref={(c) => this.form = c}>
                                    {this.state.isModal ?
                                        <Modal maskClosable={false}
                                            visible={this.state.isModal}
                                            centered
                                            onCancel={() => this.resetModal()}
                                            cancelButtonProps={{ style: { display: 'none' } }}
                                            okButtonProps={{ style: { display: 'none' } }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <span>Chỉ định:{'\u00A0'}</span>
                                                <strong>{this.state.detail.ten}</strong>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <span>Người thực hiện:{'\u00A0'}</span>
                                                <strong>{this.state.tenbs}</strong>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <span>Ngày thực hiện:{'\u00A0'}</span>
                                                <strong>{this.state.ngaykt !== null ? moment(this.state.ngaykt).format('DD/MM/YYYY HH:mm') : ''}</strong>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <span>Kết luận/Ghi chú:{'\u00A0'}</span>
                                                <strong>{this.state.ketluan}</strong>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <Button type='primary' danger onClick={(e) => this.setState({ isModal: false })} >Đóng</Button>
                                            </div>
                                        </Modal>
                                        : ''}
                                </Form>
                                <Table dataSource={this.state.lstChiDinhBenhNhan} rowKey={(e, index) => index} columns={columns} pagination={false}
                                    onRow={(row, rowIndex) => {
                                        return {
                                            onClick: e => {
                                                this.setState({ isModal: true, detail: row }, () => { this.loadThongTinThuThuatBenhNhan() })
                                            },
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        return "rowCustom"
                                    }}
                                />
                            </div>
                            :
                            <div style={{ padding: '10px' }} >
                                <Collapse style={{ width: '100%' }} defaultActiveKey={['0']}>
                                    {this.state.lstChiDinhBenhNhan.map((x, index) => {
                                        return (<Panel className="site-collapse-custom-collapse" header={x.ten} key={index}>
                                            <div>
                                                <div style={{ display: "flex" }}>
                                                    <span>Chỉ định:{'\u00A0'}</span>
                                                    <strong>{x.ten}</strong>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <span>Người thực hiện:{'\u00A0'}</span>
                                                    <strong>{x.bsthuchien}</strong>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <span>Ngày thực hiện:{'\u00A0'}</span>
                                                    <strong>{x.ngaythuchien !== null && x.ngaythuchien !== '' ? moment(x.ngaythuchien).format('DD/MM/YYYY HH:mm') : ''}</strong>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <span>Kết luận/Ghi chú:{'\u00A0'}</span>
                                                    <strong>{x.noidungPT}</strong>
                                                </div>
                                            </div>
                                        </Panel>
                                        )
                                    })}
                                </Collapse>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}
