import { Button, Drawer, Form, Modal, notification, Popconfirm, Select, Table, Tabs } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import ModalCLS from '../../../components/MDCLS';
import * as Actions from '../../../libs/actions';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as CommonService from '../../../services/commonService';
import * as EnterinfoServices from '../../../services/enterInfoService';
import * as MediService from '../../../services/MedicalExam';
import * as StorageService from '../../../services/storageService';
import * as PTTTServices from '../../../services/phauthuthuatService';
import CapThuoc from '../inhospital/capthuoc/capthuoc';
import { connect } from 'react-redux';

const { TabPane } = Tabs;
const { Option } = Select;




class Strorage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            dataSource: [],
            key: '',
            visible: false,
            ttlucrv: '',
            lstXuTri: [],
            lstLoaibv: [],
            detail: {},
            visibleCLS: false,
        };
    }
    componentWillMount() {
        this.DanhSachBenhNhanPhongLuu();
        this.loadXuTri();
        this.loadTuyen();
    }

    loadTuyen() {
        MediService.ListTuyen().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstLoaibv: result.data,
                    loaibv: result.data[0].value,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu loại bệnh viện bị lỗi!' })
        })
    }
    loadKCB(key) {
        EnterinfoServices.GetDSKCB(key).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstHospital: result.data,
                    hospital: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dân tộc bị lỗi' })
        })
    }
    loadXuTri() {
        CommonService.ListXuTri().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                const lst = result.data.filter(x => x.value === 6 || x.value === 2);
                this.setState({
                    lstXuTri: lst,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu xử trí bị lỗi' })
        })
    }
    DanhSachBenhNhanPhongLuu() {
        PTTTServices.ThongTinThuThuatBenhNhanPhongLuu(this.state.key).then(result => {
            if (result.isSuccess) {
                this.setState({ dataSource: result.data })
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {

        })
    }
    submit() {
        const data = {
            'xutri': this.state.ttlucrv.toString(),
            'mabn': this.state.detail.mabn,
            'maql': this.state.detail.maql,
        }
        StorageService.XuatPhongLuu(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                this.setState({ visible: false });
                this.DanhSachBenhNhanPhongLuu();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {

        })
    }

    AddRow(lstData) {

        if (lstData === [])
            return;
        const lstChiDinhBenhNhan = lstData.map(x => ({
            ...x,
            ma: x.ma !== undefined ? x.ma : "0",
            madoituong: "2",
            mavp: x.id.toString(),
            ngay: this.state.detail.dateIn,
            soluong: x.soluong,
            isNew: true,
            type: 0,
            packageId: x.packageId !== undefined ? x.packageId : "0"
        }));
        if (lstChiDinhBenhNhan.length === 0) {
            return;
        }

        Actions.setLoading(true);
        const patient = {
            'code': this.state.detail.patientID,
            'department': this.state.detail.departmentID,
            'subjectCode': '2',
            'managerId': this.state.detail.managementID,
            'enterCode': this.state.detail.enterCode,
        }
        const data = {
            "listChiDinh": lstChiDinhBenhNhan,
            "patient": patient,
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                if (this.props.changePage) {
                    this.props.changePage('', 1, 10, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                }
                this.modalCLS.reset()
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Tên',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width: 250
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'diachi',
                key: 'diachi',
                width: 250,
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'chuandoan',
                key: 'chuandoan',
                width: 300,
            },
            {
                title: 'Bác sĩ nhận',
                dataIndex: 'tenbs',
                key: 'tenbs',
                width: 200,
            },
            // {
            //     title: 'Mã QL',
            //     dataIndex: 'maql',
            //     key: 'maql',
            // },
            {
                width: 180,
                render: (row) => {
                    return (
                        <div>
                            <button style={{ marginRight: '5px' }} className="btn btn-danger" onClick={(e) => { this.setState({ visible: true, detail: row }) }}><i className="fa fa-sign-out-alt"></i> Xuất</button>
                            <button className="btn btn-success" onClick={(e) => { this.setState({ visibleCLS: true, detail: row }) }}><i className="fas sign-out-alt">Chỉ định</i></button>
                        </div>
                    );
                },
            }
        ]
        return (
            <React.Fragment>
                <div style={{ padding: '0px 20px' }}>
                    <div className='ccCenter'>
                        <h4>Danh sách bệnh nhân trong phòng lưu</h4>
                    </div>
                    <Table scroll={{ x: 'max-content', y: 0 }} dataSource={this.state.dataSource} columns={columns} rowKey={(e, index) => index} />
                </div>
                <Modal maskClosable={false}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    footer={[
                        <Popconfirm key="submit"
                            title="Bạn có chắc muốn xuất phòng lưu?"
                            onConfirm={() => { this.submit() }}
                            okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-success"><i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu</button>
                        </Popconfirm>
                    ]}
                >
                    <div style={{ marginTop: '30px' }}>
                        <div style={{ display: 'flex', marginBottom: '8px' }}>
                            <div style={{ flex: 1 }}>Mã BN: <strong>{this.state.detail.mabn}</strong></div>
                            <div style={{ flex: 1 }}>Mã BN: <strong>{this.state.detail.tenbn}</strong></div>
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                            <Form.Item label="Xử trí">
                                <Select
                                    allowClear={false}
                                    value={this.state.ttlucrv}
                                    onChange={(value) => { this.setState({ ttlucrv: value }) }}
                                >
                                    {this.state.lstXuTri.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className={this.state.ttlucrv === 6 ? 'xutri-open' : 'xutri-close'} style={{ marginBottom: '8px' }}>
                            <Form.Item label="Loại" >
                                <Select
                                    onChange={(value) => { this.setState({ loaibv: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstLoaibv.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className={this.state.ttlucrv === 6 ? 'xutri-open' : 'xutri-close'} style={{ marginBottom: '8px' }}>
                            <Form.Item label="Chuyển đến">
                                <Autocomplete
                                    disabled={this.state.ttlucrv !== 6}
                                    width='100%'
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstHospital}
                                    handleSearch={this.loadKCB.bind(this)}
                                    getItem={(key, value) => { this.setState({ hospitalID: key, hostpitalName: value }) }}
                                    value={this.state.hospitalID}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Popconfirm key="submit"
                                title="Bạn có chắc muốn xuất phòng lưu?"
                                onConfirm={() => { this.submit() }}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button type="primary">Lưu</Button>
                            </Popconfirm>
                            <Button style={{ marginLeft: 5 }} onClick={(e) => this.setState({ visible: false })} danger>Đóng</Button>
                        </div>
                    </div>
                </Modal>
                <Drawer
                    height={"95%"}
                    width={"90%"}
                    visible={this.state.visibleCLS}
                    placement="right"
                    closable={false}
                    onClose={() => { this.setState({ visibleCLS: false }) }}
                >
                    {this.state.visibleCLS && <div style={{ flex: 2, padding: "0px 10px" }} >
                        <div style={{ position: 'absolute', right: '15px', zIndex: 999 }}><Button onClick={() => this.setState({ visibleCLS: false })}>Đóng</Button></div>
                        <Tabs>

                            <TabPane tab={"Đơn thuốc"} key="1">
                                <CapThuoc
                                    department={this.state.detail.makp}
                                    subjectCode={this.state.detail.madoituong}
                                    code={this.state.detail.mabn}
                                    enterCode={this.state.detail.mavaovien}
                                    managerId={this.state.detail.maql}
                                />
                            </TabPane>
                            <TabPane tab={"CLS"} key="2">
                                {/* TODO */}
                                <div style={{ padding: '20px 20px 20px 0px' }}>
                                    <ModalCLS maskClosable={false}
                                        ref={((c) => this.modalCLS = c)}
                                        patientID={this.state.detail.mabn}
                                        managementID={this.state.detail.mavaovien}
                                        deleteFunc={(row) => { this.deleteRow(row) }}
                                        listSelectedServices={this.state.detail.listSubPatient}
                                        editAfterRegister={true}
                                        onClose={(data) => this.AddRow(data)}

                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                    }
                </Drawer>
            </React.Fragment>
        )
    }
}



const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};



export default connect(mapStateToProps)(Strorage)