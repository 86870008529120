import { DatePicker, Input, Pagination, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';
import * as MedicineService from '../../../services/medicineService';
import * as KhoService from '../../../services/KhoService';
import * as ConfigService from '../../../services/configService';
import { connect } from 'react-redux';

const { Option } = Select;
const { RangePicker } = DatePicker;
function Amount(props) {
    const _hiddenLink = useRef();
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [dataSource, setDataSource] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [key, setKey] = useState('');
    const [currentSize, setCurrentSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [makho, setMakho] = useState('');
    const [loai, setLoai] = useState('');
    const [listKho, setListKho] = useState([]);
    const [listLoai, setListLoai] = useState([]);
    const [chinhanh, setChinhanh] = useState(props.siteid);
    const [listSite, setListSite] = useState([]);
    useEffect(() => {
        if (currentPage === 0)
            return;
        LoadDataSource();
    }, [currentPage, currentSize, chinhanh])
    useEffect(() => {
        // GetListSite();
        LoadKho();
        LoadLoai();
        LoadDataSource();
    }, [])
    useEffect(() => {
        setChinhanh(props.siteid);
    }, [props.siteid])
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    const LoadDataSource = () => {
        Actions.setLoading(true);
        StatisticService.ThongKeSoLuongThuoc(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'),chinhanh, makho, loai, key, false, currentPage, currentSize).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecords(result.totalRecords);
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err);
        })
    }
    const Export = () => {
        Actions.setLoading(true);
        StatisticService.ExportThongKeSoLuongThuoc(ngay[0].format('YYYY-MM-DD'), ngay[1].format('YYYY-MM-DD'),chinhanh, makho, loai, key)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeSoLuongThuoc";
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    const LoadLoai = () => {
        MedicineService.DanhSachLoaiDMBD(true).then(result => {
            if (result.isSuccess) {
                setListLoai(result.data);
            }
            else {
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        })
    }
    const LoadKho = () => {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                setListKho(result.data);
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err);
        })
    }
    return (
        <>
            <div className='containerChilderWrapper'>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê số lượng thuốc</div>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
                <div className="row" style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                    {/* <div className="col-auto py-1">
                        <Select
                            style={{ width: '200px' }}
                            showSearch
                            allowClear={false}
                            value={chinhanh}
                            onChange={(e) => {
                                setChinhanh(e);
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {listSite.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id}>{row.ma}</Option>
                                )
                            })}
                        </Select>
                    </div> */}
                    <div className="col-auto py-1" style={{ width: '250px' }}>
                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                            allowClear={false} format="DD-MM-YYYY" value={ngay}
                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                            onChange={(value => setNgay(value))}
                        />
                    </div>
                    <div className="col-auto py-1" style={{ width: '250px' }}>
                        <Select className="w-100"
                            showSearch
                            value={makho}
                            onChange={(e) => {
                                setMakho(e);
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={-1} value={''}>Tất cả kho</Option>
                            {listKho.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-auto py-1" style={{ width: '250px' }}>
                        <Select className="w-100"
                            showSearch
                            value={loai}
                            onChange={(e) => setLoai(e)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option key={-1} value=''>Tất cả loại</Option>
                            {listLoai.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-auto py-1">
                        <Input className="form-control" placeholder="Nhập mã, tên thuốc"
                            type="text" style={{ width: '250px' }} value={key} onChange={(e) => { setKey(e.target.value) }} />
                    </div>
                    <div className="col-auto py-1 d-flex">
                        <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                        <button className="btn btn-color" onClick={() => {
                            Export()
                        }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 150px)', overflow: 'auto' }}>
                    <table className="w-100 table" style={{ background: '#fff' }}>
                        <thead>
                            <tr>
                                <th style={{ minWidth: '80px' }}>Mã</th>
                                <th style={{ minWidth: '150px' }}>Tên</th>
                                <th style={{ minWidth: '150px' }}>Hợp chất</th>
                                <th style={{ minWidth: '80px' }}>Đơn vị</th>
                                <th style={{ minWidth: '100px' }}>Nước</th>
                                <th style={{ minWidth: '100px' }}>Loại</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>Bán lẻ</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>Theo toa</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>VT Tiêu hao</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>VT Tính phí</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>Nội trú</th>
                                <th className="text-right" style={{ minWidth: '100px' }}>Tổng</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.map((row, index) =>
                                <tr key={index}>
                                    <td>{row.ma}</td>
                                    <td>{row.tenthuoc}</td>
                                    <td>{row.tenhc}</td>
                                    <td>{row.donvi}</td>
                                    <td>{row.tennuoc}</td>
                                    <td>{row.tenloai}</td>
                                    <td className="text-right">{row.slbanle.toLocaleString('en-US')}</td>
                                    <td className="text-right">{row.sltheodon.toLocaleString('en-US')}</td>
                                    <td className="text-right">{row.sltieuhao.toLocaleString('en-US')}</td>
                                    <td className="text-right">{row.sltinhphi.toLocaleString('en-US')}</td>
                                    <td className="text-right">{row.slnoitru.toLocaleString('en-US')}</td>
                                    <td className="text-right">{row.tongsoluong.toLocaleString('en-US')}</td>
                                </tr>)}
                        </tbody>
                    </table>

                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10 }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    current={currentPage}
                    size={currentSize}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setCurrentSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setCurrentPage(1);
                        setCurrentSize(size);
                    }
                    }
                    showSizeChanger={true}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Amount);