import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Input, AutoComplete, InputNumber, Popconfirm } from "antd";
import { convertToNumber, isNullOrEmpty, preConvertToNumber } from '../../../libs/util';
import * as MedicineService from '../../../services/medicineService';
import * as Actions from '../../../libs/actions';
import { NotifyStatus } from '../../../components/notify';

const { TextArea } = Input;
let timeoutToSearch;

function MedicineList(props) {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Xem hay chỉnh sửa
    const [isEdit, setIsEdit] = useState(props.isEdit ?? false);
    const [listThuoc, setListThuoc] = useState([]);
    const [listCachDung, setListCachDung] = useState([]);
    // Cho phép xóa thuốc?
    const [canDelete, setCanDelete] = useState(props.canDelete ?? true);
    const [selectedIdThuoc, setSelectedIdThuoc] = useState();
    const [maDon, setMaDon] = useState(props.maDon);
    const [maDichVu, setMaDichVu] = useState(props.maDichVu);

    useEffect(() => {
        props.data && setListThuoc(props.data);
        props.maDon && setMaDon(props.maDon);
        props.maDichVu && setMaDichVu(props.maDichVu);
        props.isEdit && setIsEdit(props.isEdit);
        props.canDelete && setCanDelete(props.canDelete);
    }, [props])

    const handleSearchCachDung = (idThuoc, key) => {
        if (timeoutToSearch) {
            clearTimeout(timeoutToSearch);
            timeoutToSearch = null;
        }
        timeoutToSearch = setTimeout(() => {
            MedicineService.getListCachDungThuoc(idThuoc, key).then(result => {
                if (result.isSuccess) {
                    setListCachDung(result.data);
                }
                else { }
            }).catch(err => {
            }).finally(() => {
                forceUpdate();
            });
        }, 700);
    }

    const checkValidValue = (e, index, key) => {
        if ((e.target.value.includes("/") && e.key == "/") || (e.target.value.includes(".") && e.key == ".") || e.key === "e") {
            e.preventDefault();
            return false;
        }
        if ((key == 'ngayDung' || key == 'soLuong') && (e.target.value.includes(".") || e.key == ".")) {
            e.preventDefault();
            return false;
        }
        var k = e.keyCode;
        if ((k < 48 || k > 57) && (k < 96 || k > 105) && k != 8 && k != 190 && k != 191 && k != 110 && k != 111 && k != 9 && k != 13 && k != 46) {
            e.preventDefault();
            return false;
        }
    }

    const luuThongTinThuoc = (e, index, key) => {
        let data = listThuoc;
        switch (key) {
            case "sang":
                data[index].sang = e.target.value;
                break;
            case "trua":
                data[index].trua = e.target.value;
                break;
            case "chieu":
                data[index].chieu = e.target.value;
                break;
            case "toi":
                data[index].toi = e.target.value;
                break;
            case "ngayDung":
                data[index].ngayDung = Number(e);
                break;
            case "soLuong":
                data[index].soLuong = Number(e);
                break;
            case "cachDung":
                data[index].cachDung = e.target?.value ?? e;
                setListCachDung([]);
                break;
            default: break;
        }
        for (let thuoc of data) {
            let slSang, slTrua, slChieu, slToi = 0;
            slSang = convertToNumber(thuoc.sang);
            thuoc.sang = preConvertToNumber(thuoc.sang).toString();
            slTrua = convertToNumber(thuoc.trua);
            thuoc.trua = preConvertToNumber(thuoc.trua).toString();
            slChieu = convertToNumber(thuoc.chieu);
            thuoc.chieu = preConvertToNumber(thuoc.chieu).toString();
            slToi = convertToNumber(thuoc.toi);
            thuoc.toi = preConvertToNumber(thuoc.toi).toString();
            if (isNullOrEmpty(thuoc.donViSuDung) || (!isNullOrEmpty(thuoc.donViSuDung)) && thuoc.donViTinh?.toLowerCase() == thuoc.donViSuDung?.toLowerCase())
                if (!isNullOrEmpty(thuoc.ngayDung) && (slSang > 0 || slTrua > 0 || slChieu > 0 || slToi > 0)) {
                    thuoc.soLuong = Math.ceil((slSang + slTrua + slChieu + slToi) * thuoc.ngayDung)
                }
        }
        setListThuoc(data);
        forceUpdate();
    }

    const xoaThongTinThuoc = (thuoc, index) => {
        if (maDon && (thuoc.isNew == false || thuoc.isNew === undefined)) {
            MedicineService.deleteMedicine(maDon, thuoc.idThuoc, maDichVu).then(result => {
                if (result.isSuccess) {
                    props.handleGetNewData && props.handleGetNewData();
                    props.updateNotify && props.updateNotify("Xóa thành công", NotifyStatus.Success);
                }
                else {
                    Actions.openMessageDialog("Thông báo", result.error.messageText);
                }
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err.error.messageText);
            }).finally(() => {
                forceUpdate();
            });
        } else {
            let data = listThuoc;
            if (index > -1) {
                data.splice(index, 1);
            }
            setListThuoc(data);
            forceUpdate();
        }
    }

    return (
        <Fragment>
            {
                listThuoc && listThuoc.map((thuoc, index) => {
                    return (
                        <div className='border-2 border-bottom py-2 ant-list-item-hover'>
                            <div className='d-flex flex-row justify-content-between'>
                                <div style={{ width: 20, textAlign: "center", }}>
                                    {index + 1}
                                </div>
                                <div style={{ width: "calc(100% - 40px)" }} className="d-flex justify-content-between">
                                    <div className='d-flex flex-row' style={{ width: "70%" }}>
                                        <label class="mita-title" style={{ width: "25%" }}>{!isNullOrEmpty(thuoc.hoatChat) ? thuoc.hoatChat : "--"}</label>
                                        <label class="mita-value"><i>Tên thuốc: {thuoc.tenThuoc} {thuoc.hamLuong ?? ""}</i></label>
                                    </div>
                                    <div className='d-flex flex-row justify-content-end' style={{ width: "30%" }}>
                                        <label class="mita-value">{thuoc.soLuong} x {thuoc.donGia.toLocaleString("en-US")} / {thuoc.donViTinh ?? "--"}</label>
                                        <label class="mita-value ml-4 mr-4"><u>đ</u>&nbsp;{(thuoc.soLuong * thuoc.donGia).toLocaleString("en-US")}</label>
                                    </div>
                                </div>
                                <div style={{ width: 20, textAlign: "center" }}>
                                    {canDelete &&
                                        <Popconfirm
                                            title="Có chắc muốn xóa?"
                                            placement='left'
                                            onConfirm={() => { xoaThongTinThuoc(thuoc, index); }}
                                            okText="Đồng ý" cancelText="Hủy">
                                            <i className='fas fa-trash-alt text-danger' />
                                        </Popconfirm>}
                                </div>
                            </div>
                            <div className='row' style={{ padding: "0 20px" }}>
                                <div className='col-2 mt-2'>
                                    <label>Sáng:&nbsp;
                                        {
                                            !isEdit ? (thuoc.sang && Number(thuoc.sang) > 0 ? <span>{`${thuoc.sang} ${thuoc.donViSuDung ?? ""}`}</span> : "--")
                                                :
                                                <Fragment>
                                                    <Input
                                                        value={thuoc.sang}
                                                        style={{ width: thuoc.donViSuDung ? "40%" : "50%" }}
                                                        onKeyDown={(e) => {
                                                            checkValidValue(e, index, "sang");
                                                        }}
                                                        onChange={(e) => {
                                                            luuThongTinThuoc(e, index, "sang");
                                                        }}
                                                    />{thuoc.donViSuDung && <span>&nbsp;{thuoc.donViSuDung}</span>}
                                                </Fragment>

                                        }
                                    </label>
                                </div>
                                <div className='col-2 mt-2'>
                                    <label>Trưa:&nbsp;
                                        {
                                            !isEdit ? (thuoc.trua && Number(thuoc.trua) > 0 ? <span>{`${thuoc.trua} ${thuoc.donViSuDung ?? ""}`}</span> : "--")
                                                :
                                                <Fragment>
                                                    <Input
                                                        value={thuoc.trua}
                                                        style={{ width: thuoc.donViSuDung ? "40%" : "50%" }}
                                                        onKeyDown={(e) => {
                                                            checkValidValue(e, index, "trua");
                                                        }}
                                                        onChange={(e) => {
                                                            luuThongTinThuoc(e, index, "trua");
                                                        }}
                                                    />{thuoc.donViSuDung && <span>&nbsp;{thuoc.donViSuDung}</span>}
                                                </Fragment>
                                        }
                                    </label>
                                </div>
                                <div className='col-2 mt-2'>
                                    <label>Chiều:&nbsp;
                                        {
                                            !isEdit ? (thuoc.chieu && Number(thuoc.chieu) > 0 ? <span>{`${thuoc.chieu} ${thuoc.donViSuDung ?? ""}`}</span> : "--")
                                                :
                                                <Fragment>
                                                    <Input
                                                        value={thuoc.chieu}
                                                        style={{ width: thuoc.donViSuDung ? "40%" : "50%" }}
                                                        onKeyDown={(e) => {
                                                            checkValidValue(e, index, "chieu");
                                                        }}
                                                        onChange={(e) => {
                                                            luuThongTinThuoc(e, index, "chieu");
                                                        }}
                                                    />{thuoc.donViSuDung && <span>&nbsp;{thuoc.donViSuDung}</span>}
                                                </Fragment>
                                        }
                                    </label>
                                </div>
                                <div className='col-2 mt-2'>
                                    <label>Tối:&nbsp;
                                        {
                                            !isEdit ? (thuoc.toi && Number(thuoc.toi) > 0 ? <span>{`${thuoc.toi} ${thuoc.donViSuDung ?? ""}`}</span> : "--")
                                                :
                                                <Fragment>
                                                    <Input
                                                        value={thuoc.toi}
                                                        style={{ width: thuoc.donViSuDung ? "40%" : "50%" }}
                                                        onKeyDown={(e) => {
                                                            checkValidValue(e, index, "toi");
                                                        }}
                                                        onChange={(e) => {
                                                            luuThongTinThuoc(e, index, "toi");
                                                        }}
                                                    />{thuoc.donViSuDung && <span>&nbsp;{thuoc.donViSuDung}</span>}
                                                </Fragment>
                                        }
                                    </label>
                                </div>
                                <div className='col-2 mt-2'>
                                    <label>Ngày dùng:&nbsp;
                                        {
                                            !isEdit ? thuoc.ngayDung
                                                :
                                                <InputNumber
                                                    onKeyDown={(e) => {
                                                        checkValidValue(e, index, "ngayDung");
                                                    }}
                                                    value={thuoc.ngayDung}
                                                    style={{ width: "40%" }}
                                                    min={1}
                                                    onChange={(e) => {
                                                        luuThongTinThuoc(e, index, "ngayDung");
                                                    }}
                                                />
                                        }
                                    </label>
                                </div>
                                <div className='col-2 mt-2'>
                                    <label>Số lượng:&nbsp;
                                        {
                                            !isEdit ? thuoc.soLuong
                                                :
                                                <InputNumber
                                                    onKeyDown={(e) => {
                                                        checkValidValue(e, index, "soLuong");
                                                    }}
                                                    value={thuoc.soLuong}
                                                    style={{ width: "50%" }}
                                                    min={1}
                                                    max={thuoc.soLuongTon}
                                                    onChange={(e) => {
                                                        luuThongTinThuoc(e, index, "soLuong");
                                                    }}
                                                />
                                        }
                                    </label>
                                </div>
                            </div>
                            <div className='row' style={{ padding: "0 20px" }}>
                                <div className='col-12 d-flex flex-row align-items-center mt-2'>
                                    <label style={{ width: isEdit ? "80px" : "fit-content" }}>Cách dùng:</label>&nbsp;
                                    {!isEdit
                                        ? <label>{thuoc.cachDung}</label>
                                        :
                                        <AutoComplete
                                            options={thuoc.idThuoc === selectedIdThuoc ? listCachDung : []}
                                            onSelect={(e) => { luuThongTinThuoc(e, index, "cachDung"); }}
                                            onSearch={(key) => handleSearchCachDung(thuoc.idThuoc, key)}
                                            dropdownMatchSelectWidth={700}
                                            listHeight={500}
                                            className="w-100 h-100"
                                            value={thuoc.cachDung}
                                        >
                                            <TextArea
                                                key={thuoc.idThuoc}
                                                autoSize={{ minRows: 1, maxRows: 3 }}
                                                onChange={(e) => {
                                                    luuThongTinThuoc(e, index, "cachDung");
                                                }}
                                                onFocus={() => { setListCachDung([]); setSelectedIdThuoc(thuoc.idThuoc) }}
                                            />
                                        </AutoComplete>
                                    }
                                </div>
                            </div>
                        </div>

                    )
                })
            }
        </Fragment>
    );
}

export default MedicineList;