import React, {  useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef, Fragment } from 'react';
import { Table, Pagination, Spin, List, Popover, Button } from 'antd';
import Notify, { NotifyStatus } from '../../../../../components/notify';
import * as ReportServices from '../../../../../services/ReportService';
import * as Actions from '../../../../../libs/actions';
import * as SystemConfig from "../../../../../configure/systemConfig";
import * as PrintService from '../../../../../services/printService';
import { isNullOrEmpty } from "../../../../../libs/util";
import { FORMAT_DATE_TIME, FORMAT_PREFIX_EXCEL } from '../../../../../configure/systemConfig';
import Handlebars from 'handlebars';
import handlebarHelper from "../../../../../libs/handlebarHelper";
import moment from "moment";

export default forwardRef((props, ref) => {
    //#region khai báo state
    const _hiddenLink = useRef();
    const notiRef = useRef(null);
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //state paging và sorting
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    //
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    useImperativeHandle(ref, () => ({
        loadData: () => {
            resetToFirstPage();
        },
    }));

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
            }
            getData();
    }, [pageIndex]);

    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [pageSize])

    const getData = () => {
        Actions.setLoading(true);
        setLoading(true);
        let dateFrom=moment().format('YYYY-MM-DD HH:mm');
        if (!isNullOrEmpty(props.dateFrom)) dateFrom=moment(props.dateFrom).format('YYYY-MM-DD HH:mm');
        let dateTo=moment().format('YYYY-MM-DD HH:mm');
        if (!isNullOrEmpty(props.dateTo)) dateTo=moment(props.dateTo).format('YYYY-MM-DD HH:mm');
        let filterModel = {
            siteIds: props.siteIds ?? [],
            coporationIds: props.coporationIds,

            paymentTypeIds: props.paymentTypeIds,
            dateFrom: dateFrom ?? '',
            dateTo: dateTo ?? '',
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll: false
        }

        ReportServices.RevenueReportByService(filterModel).then(result => {
            if (result.isSuccess) {
                let totalRevenue = result.data.extendObject.totalRevenue;
                let expandedRowKeys = result.data.items.map(x => `${x.soBienLai.toString()}${(x.vienPhiId ?? 0).toString()}`);
                setListResult(result.data.items);
                setTotalRecord(result.data.totalCount);
                setTotalRevenue(totalRevenue)
                setExpandedRowKeys(expandedRowKeys)
                notiRef && notiRef.current.Close();
            }
            else {
                notiRef.current && notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current && notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else notiRef.current && notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
        if (pageIndex != 1) setPageIndex(1);
        else getData();
    }

    const onExportExcelClinkHandle = () => {
        Actions.setLoading(true);
        let filterModel = {
            siteIds: props.siteIds ?? [],
            coporationIds: props.coporationIds,
            paymentTypeIds : props.paymentTypeIds,
            dateFrom: props.dateFrom ?? '',
            dateTo: props.dateTo ?? '',
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll : true
        }
        ReportServices.ExportReportRevenueByService(filterModel).then(result => {
        result.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            _hiddenLink.current.href = url;
            let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_BCDoanhThuTongHop_DichVu`; 
            _hiddenLink.current.download = fileName;
            _hiddenLink.current.click();
        })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const onPrintClinkHandle = (data) => {
        Actions.setLoading(true);
        let paymentType = props.paymentTypeIds ?? 0;
        PrintService.PrintThongKeDichVuBenhNhanFromReport(moment(props.dateFrom).format('YYYY-MM-DD HH:mm'), moment(props.dateTo).format('YYYY-MM-DD HH:mm'), paymentType.toString(), "", props.siteIds, "")
            .then((result) => {
                if (result.isSuccess) Actions.DoPrint(result.item)
                else notiRef.current && notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }).catch(err => {
                if (err.error && err.error.messageText)
                    notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
                else notiRef.current.Show(err, NotifyStatus.Error);
            }).finally(() => {
                Actions.setLoading(false);
                forceUpdate();
            })
    }

    const renderContent = (value, colSpan) => {
        let obj = {
            children: value,
            props: {colSpan: colSpan},
        };
        return obj;
    }

    const columns = [
        {
            title: 'STT',
            width: 40,
            align: 'center',
            render: (text, record, index) => {
                if (record.isMaster) {
                    let t=<strong style={{ fontSize: '15px', float: 'left', marginLeft: '5px', color: record.isRefundBill ? 'red':'' }}>
                    {( pageIndex - 1 )* pageSize + ( index + 1 ) }. Biên lai: {record.billNo} - {record.patientName} - {moment(record.billDate).format(FORMAT_DATE_TIME)}
                    </strong>;
                    return renderContent(t,9);
                }
                else {
                    return renderContent(<span>{(index+1)}</span>,1);
                }
            },
        },
        {
            title: 'Sản phẩm/Dịch vụ',
            dataIndex: 'vienPhiTen',
            key: 'vienPhiTen',
            width: 150,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent(text,1));
            },
        },
        {
            title: 'Nhóm dịch vụ',
            width: 100,
            dataIndex: 'vienPhiNhom',
            key: 'vienPhiNhom',
        },
        {
            title: 'Loại dịch vụ',
            width: 100,
            dataIndex: 'vienPhiLoai',
            key: 'vienPhiLoai',
        },
        {
            title: 'SL',
            dataIndex: 'soLuong',
            key: 'soLuong',
            align: 'center',
            width: 80,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent(text,1));
            },
        },
        {
            title: 'Đơn giá',
            dataIndex: 'donGiaGoc',
            key: 'donGiaGoc',
            align: 'right',
            width: 100,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent(Number(text).toLocaleString('en-US'),1));
            },
        },
        {
            title: 'Thành tiền',
            dataIndex: 'thanhTien',
            key: 'thanhTien',
            align: 'right',
            width: 100,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent(Number(text).toLocaleString('en-US'),1));
            },
        },
        {
            title: 'Giảm trừ khác',
            dataIndex: 'giamGia',
            key: 'giamGia',
            align: 'right',
            width: 100,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent((Number(text)+(Number(record.thanhToan)-Number(record.thucThu))).toLocaleString('en-US'),1));
            },
        },
        {
            title: 'Thực thu',
            dataIndex: 'thucThu',
            key: 'thucThu',
            align: 'right',
            width: 100,
            render: (text, record, index) => {
                return (record.isMaster ? 
                    renderContent('',0) : 
                    renderContent((record.isRefundBill ? '-':'')+Number(text).toLocaleString('en-US'),1)
                    );
            },
        },
        {
            title: 'Người lập',
            dataIndex: 'cashierName',
            key: 'cashierName',
            width: 150,
            render: (text, record, index) => {
                return (record.isMaster ? renderContent('',0) : renderContent(text,1));
            },
        },
        {
            title: 'Hình thức',
            dataIndex: 'paymentTypeText',
            key: 'paymentTypeText',
            width: 150,
            render: (text, record, index) => {
                if (record.isMaster) return  renderContent('',0)
                else {
                    let t=text;
                    if (record.ngayThuCongNo != null && record.ngayThuCongNo != undefined) {
                        t = t.replace('Công nợ', `Công nợ (Đã thu)`);
                    }
                    return renderContent(t,1);
                }
            },
        },
        {
            title: 'Đơn vị gửi mẫu',
            width: 100,
            dataIndex: 'donViGui_Ten',
            key: 'donViGui_Ten',
        },
    ]

    const onTableRowExpand = (expanded, record) => {
        let key = `${record.soBienLai.toString()}${(record.vienPhiId ?? 0).toString()}`;
        let expandedRowKey = expandedRowKeys;
        if (expanded) {expandedRowKey.push(key);}
        else { expandedRowKey = expandedRowKey.filter(x=> x != key); }
        setExpandedRowKeys(expandedRowKeys)
    }

    return (
        <Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} >download</a>
            <Spin spinning={loading} className='spin-color'>
                <div className="list-page" >
                    <div className="list-page-body">{/* body */}
                        <Notify ref={notiRef} />
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-end p-0">
                                <h4>Tổng tiền: <b>{totalRevenue.toLocaleString('en-US')}</b></h4>
                                <div>
                                    <Button className="btn btn-secondary ml-2" 
                                        type="button"
                                        onClick={() => { onPrintClinkHandle() }}>
                                        <i style={{ marginRight: 5 }} className="fas fa-print" />In
                                    </Button>
                                    <Button className="btn btn-color ml-2" 
                                        type="button" 
                                        onClick={() => { onExportExcelClinkHandle() }}>
                                        <i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="list-page-table mt-2">{/* Dashboard & [Chart | List] */}
                            <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                                sticky
                                className="table-min-h-0 table-header-1row"
                                dataSource={listResult} 
                                columns={columns}
                                locale={{ emptyText: 'Không có dữ liệu' }} 
                                scroll={{ x: 1200, y: 0 }}
                                rowKey={(row, index) => `${row.soBienLai.toString()}${(row.vienPhiId ?? 0).toString()}`}
                                rowClassName={(record, rowIndex) => {
                                }}
                                pagination={false}
                                expandable = {{
                                    expandedRowKeys : expandedRowKeys,
                                    onExpand : (expanded, record) => onTableRowExpand(expanded, record),
                                    expandIconColumnIndex : 5 ,
                                    expandRowByClick : true,
                                    showExpandColumn : true
                                }}
                            />
                            <Pagination
                                current={pageIndex}
                                size={pageSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={totalRecord}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </Fragment>
    )
});
