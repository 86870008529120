import { DownOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Upload } from 'antd';
import dwv from 'dwv';
import PropTypes from 'prop-types';
import React from 'react';
import * as Actions from '../../../libs/actions';
import './DwvComponent.css';

dwv.gui.getElement = dwv.gui.base.getElement;
dwv.gui.prompt = function (message, def) {
    return prompt(message, def);
}

dwv.image.decoderScripts = {
    "jpeg2000": `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`,
    "jpeg-lossless": `${process.env.PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`,
    "jpeg-baseline": `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`,
    "rle": `${process.env.PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`
};

class DwvComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showInfo: true,
            versions: {
                dwv: dwv.getVersion(),
                react: React.version
            },
            tools: {
                Scroll: {},
                ZoomAndPan: {},
                WindowLevel: {},
                Draw: {
                    options: ['Ruler'],
                    type: 'factory',
                    events: ['drawcreate', 'drawchange', 'drawmove', 'drawdelete']
                },
                Livewire: {}, Filter: {}, Floodfill: {},
            },
            toolNames: [],
            selectedTool: 'Select Tool',
            loadProgress: 0,
            dataLoaded: false,
            dwvApp: null,
            metaData: [],
            showDicomTags: false,
            toolMenuAnchorEl: null,
            dropboxDivId: 'dropBox',
            dropboxClassName: 'dropBox',
            borderClassName: 'dropBoxBorder',
            hoverClassName: 'hover',
            infoImage: {},
        };
    }
    GetValueFromMetaDate(metadata, name) {
        let tmp = metadata.find(x => x.name === name);
        if (tmp) {
            return tmp.value;
        }
        return "";
    }
    SetDataInFo() {
        let metadata = this.state.metaData;
        const data = {
            PatientID: this.GetValueFromMetaDate(metadata, 'PatientID'),
            PatientName: this.GetValueFromMetaDate(metadata, 'PatientName'),
            PatientSex: this.GetValueFromMetaDate(metadata, 'PatientSex'),
            PatientBirthDate: this.GetValueFromMetaDate(metadata, 'PatientBirthDate'),

            InstitutionName: this.GetValueFromMetaDate(metadata, 'InstitutionName'),
            StudyDate: this.GetValueFromMetaDate(metadata, 'StudyDate'),
            StudyTime: this.GetValueFromMetaDate(metadata, 'StudyTime'),
            Modality: this.GetValueFromMetaDate(metadata, 'Modality'),

            PatientPosition: this.GetValueFromMetaDate(metadata, 'PatientPosition'),
            Rows: this.GetValueFromMetaDate(metadata, 'Rows'),
            Columns: this.GetValueFromMetaDate(metadata, 'Columns'),
            NumberOfFrames: this.GetValueFromMetaDate(metadata, 'NumberOfFrames'),
            state: JSON.parse(this.state.dwvApp.getState()),
        }
        this.setState({ infoImage: data })
    }
    DownloadState() {
        let data = this.state.dwvApp.getState();
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'thongtin.json'
        a.click();
        a.remove();
    }
    render() {
        const { toolNames, dataLoaded } = this.state;
        const toolsMenuItems = <Menu>{
            toolNames.map((tool) =>
                <Menu.Item onClick={this.handleMenuItemClick.bind(this, tool)} key={tool} value={tool}>{tool}</Menu.Item>
            )}
        </Menu>;
        return (
            <div id="dwv">
                <div className="button-row">
                    {dataLoaded &&
                        <React.Fragment>
                            <Button onClick={() => {
                                this.state.dwvApp.getLayerController()
                                    .getActiveViewLayer().getViewController().play()
                            }}>Play/Stop</Button>
                            <Button onClick={() => {
                                this.state.dwvApp.getLayerController()
                                    .getActiveViewLayer().getViewController().incrementFrameNb()
                            }}>+</Button>
                            <Button onClick={() => {
                                this.state.dwvApp.getLayerController()
                                    .getActiveViewLayer().getViewController().decrementFrameNb()
                            }}>-</Button>
                        </React.Fragment>
                    }
                    <Button disabled={!dataLoaded} onClick={() => {
                        this.setState({ showInfo: !this.state.showInfo })
                    }}>Thông tin</Button>
                    <Dropdown overlay={toolsMenuItems}>
                        <Button>
                            {this.state.selectedTool} <DownOutlined />
                        </Button>
                    </Dropdown>
                    <Button variant="contained" color="primary"
                        disabled={!dataLoaded}
                        onClick={this.onReset}
                    >Reset</Button>
                    <Button variant="contained" color="primary"
                        onClick={this.handleTagsDialogOpen}
                        disabled={!dataLoaded}
                        size="medium">Tags</Button>
                    <Button color="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => { this.DownloadState() }}
                        disabled={!dataLoaded}
                        size="medium">Tải thông tin</Button>
                    <Upload accept=".DCM,.JSON"
                        itemRender={() => <div className="d-none"></div>}
                        // beforeUpload={() => false}
                        beforeUpload={file => {
                            if (file.name.substring(file.name.length - 4).toLocaleUpperCase() !== ".DCM" && file.name.substring(file.name.length - 5).toLocaleUpperCase() !== ".JSON") {
                                message.error(`File ${file.name} không hợp lệ`);
                            }
                            return false;
                        }}
                        onChange={info => {
                            if (info.file.name.substring(info.file.name.length - 4).toLocaleUpperCase() === ".DCM" || info.file.name.substring(info.file.name.length - 5).toLocaleUpperCase() === ".JSON") {
                                this.SelectFile(info.fileList.filter(x => x.uid === info.file.uid).map(x => x.originFileObj));
                            }
                        }}
                    >
                        <Button icon={<UploadOutlined />}>Chọn file</Button>
                    </Upload>
                    {this.state.showDicomTags &&
                        <Modal
                            visible={this.state.showDicomTags}
                            onCancel={this.handleTagsDialogClose}
                            width={1400}
                        >
                            <div variant="h6" color="inherit" className="h4">
                                DICOM Tags
                            </div>
                            <div className="">
                                <Button onClick={this.handleTagsDialogClose}>Đóng</Button>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-inverse table-responsive">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>NAME</th>
                                            <th>VALUE</th>
                                            <th>GROUP</th>
                                            <th>ELEMENT</th>
                                            <th>VR</th>
                                            <th>VL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.metaData.map((row, index) =>
                                            <tr key={index}>
                                                <td>{JSON.stringify(row.name)}</td>
                                                <td>{JSON.stringify(row.value)}</td>
                                                <td>{JSON.stringify(row.group)}</td>
                                                <td>{JSON.stringify(row.element)}</td>
                                                <td>{JSON.stringify(row.vr)}</td>
                                                <td>{JSON.stringify(row.vl)}</td>
                                            </tr>
                                        )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </Modal>
                    }
                </div>
                <div id="dropBox"></div>
                <div className="layerContainer">
                    {dataLoaded && this.state.showInfo &&
                        <div className="infoLayer">
                            <div className="infotl info">
                                <ul>
                                    <li>{this.state.infoImage.PatientID}</li>
                                    <li>{this.state.infoImage.PatientName} {`[${this.state.infoImage.PatientSex}]`}</li>
                                    <li>{this.state.infoImage.PatientBirthDate}</li>
                                </ul>
                            </div>
                            <div className="infotc infoc"></div>
                            <div className="infotr info">
                                <ul>
                                    <li>{this.state.infoImage.InstitutionName}</li>
                                    <li>{this.state.infoImage.StudyDate} {this.state.infoImage.StudyTime}</li>
                                    <li>{this.state.infoImage.Modality}</li>
                                </ul>
                            </div>
                            <div className="infocl infoc"></div>
                            <div className="infocr infoc"></div>
                            <div className="infobl info">
                                <ul>
                                    <li>{this.state.infoImage.PatientPosition}</li>
                                    <li>RES: {this.state.infoImage.Rows}x{this.state.infoImage.Columns}</li>
                                </ul>
                            </div>
                            <div className="infobc infoc"></div>
                            <div className="infobr info">
                                {this.state.infoImage.state &&
                                    <ul>
                                        <li>WC: {this.state.infoImage.state['window-center']}</li>
                                        <li>WW: {this.state.infoImage.state['window-width']}</li>
                                        <li>Frame: {this.state.infoImage.NumberOfFrames}</li>
                                    </ul>}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    componentDidMount() {
        dwv.gui.getElement = dwv.gui.base.getElement;
        // create app
        var app = new dwv.App();
        // initialise app
        app.init({
            "containerDivId": "dwv",
            "tools": this.state.tools,
            "fitToWindow": true,
            "filters": ["Threshold", "Sharpen", "Sobel"],
            "shapes": ["Arrow", "Ruler", "Protractor", "Rectangle", "Roi", "Ellipse", "FreeHand"],
            isMobile: false,
            "gui": ["tool", "load", "help", "undo", "version", "tags", "drawList"],
        });
        // load events
        let nLoadItem = null;
        let nReceivedError = null;
        let nReceivedAbort = null;
        app.addEventListener('loadstart', (/*event*/) => {
            // reset flags
            nLoadItem = 0;
            nReceivedError = 0;
            nReceivedAbort = 0;
            // hide drop box
            this.showDropbox(app, false);
        });
        app.addEventListener("loadprogress", (event) => {
            Actions.setLoading(true);
            this.setState({ loadProgress: event.loaded });
        });
        app.addEventListener("load", (/*event*/) => {
            // set dicom tags
            this.setState({ metaData: dwv.utils.objectToArray(app.getMetaData()) });
            // available tools
            let names = [];
            for (const key in this.state.tools) {
                if ((key === 'Scroll' && app.canScroll()) ||
                    (key === 'WindowLevel' && app.canWindowLevel()) ||
                    (key !== 'Scroll' && key !== 'WindowLevel')) {
                    names.push(key);
                }
            }
            this.setState({ toolNames: names });
            this.onChangeTool(names[0]);
            // set the selected tool
            let selectedTool = 'Scroll';
            if (app.isMonoSliceData() && app.getImage().getNumberOfFrames() === 1) {
                selectedTool = 'ZoomAndPan';
            }
            this.onChangeTool(selectedTool);
            // set data loaded flag
            this.setState({ dataLoaded: true }, () => {
                this.SetDataInFo();
            });
            Actions.setLoading(false);
        });
        app.addEventListener('loadend', (/*event*/) => {
            if (nReceivedError) {
                this.setState({ loadProgress: 0 });
                alert('Received errors during load. Check log for details.');
                Actions.setLoading(false);
                // show drop box if nothing has been loaded
                if (!nLoadItem) {
                    this.showDropbox(app, true);
                }
            }
            if (nReceivedAbort) {
                this.setState({ loadProgress: 0 });
                alert('Load was aborted.');
                this.showDropbox(app, true);
            }
        });
        app.addEventListener('loaditem', (/*event*/) => {
            ++nLoadItem;
        });
        app.addEventListener('error', (event) => {
            console.error(event.error);
            ++nReceivedError;
        });
        app.addEventListener('abort', (/*event*/) => {
            ++nReceivedAbort;
        });

        // handle key events
        app.addEventListener('keydown', (event) => {
            app.defaultOnKeydown(event);
        });
        // handle window resize
        window.addEventListener('resize', app.onResize);

        // store
        this.setState({ dwvApp: app });

        // setup drop box
        this.setupDropbox(app);

        // possible load from location
        dwv.utils.loadFromUri(window.location.href, app);

    }

    /**
     * Handle a change tool event.
     * @param tool The new tool name.
     */
    onChangeTool = (tool) => {
        if (this.state.dwvApp) {
            this.setState({ selectedTool: tool });
            this.state.dwvApp.setTool(tool);
            if (tool === 'Draw') {
                this.onChangeShape(this.state.tools.Draw.options[0]);
            }
        }
    }

    /**
     * Handle a change draw shape event.
     * @param shape The new shape name.
     */
    onChangeShape = (shape) => {
        if (this.state.dwvApp) {
            this.state.dwvApp.setDrawShape(shape);
        }
    }

    /**
     * Handle a reset event.
     */
    onReset = tool => {
        if (this.state.dwvApp) {
            this.state.dwvApp.resetDisplay();
        }
    }

    /**
     * Open the DICOM tags dialog.
     */
    handleTagsDialogOpen = () => {
        this.setState({ showDicomTags: true });
    }

    /**
     * Close the DICOM tags dialog.
     */
    handleTagsDialogClose = () => {
        this.setState({ showDicomTags: false });
    };

    /**
     * Menu button click.
     */
    handleMenuButtonClick = event => {
        this.setState({ toolMenuAnchorEl: event.currentTarget });
    };

    /**
     * Menu cloase.
     */
    handleMenuClose = event => {
        this.setState({ toolMenuAnchorEl: null });
    };

    /**
     * Menu item click.
     */
    handleMenuItemClick = tool => {
        this.setState({ toolMenuAnchorEl: null });
        this.onChangeTool(tool);
    };

    // drag and drop [begin] -----------------------------------------------------

    /**
     * Setup the data load drop box: add event listeners and set initial size.
     */
    setupDropbox = (app) => {
        this.showDropbox(app, true);
    }

    /**
     * Default drag event handling.
     * @param event The event to handle.
     */
    defaultHandleDragEvent = (event) => {
        // prevent default handling
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Handle a drag over.
     * @param event The event to handle.
     */
    onBoxDragOver = (event) => {
        this.defaultHandleDragEvent(event);
        // update box border
        const box = document.getElementById(this.state.dropboxDivId);
        if (box && box.className.indexOf(this.state.hoverClassName) === -1) {
            box.className += ' ' + this.state.hoverClassName;
        }
    }

    /**
     * Handle a drag leave.
     * @param event The event to handle.
     */
    onBoxDragLeave = (event) => {
        this.defaultHandleDragEvent(event);
        // update box class
        const box = document.getElementById(this.state.dropboxDivId);
        if (box && box.className.indexOf(this.state.hoverClassName) !== -1) {
            box.className = box.className.replace(' ' + this.state.hoverClassName, '');
        }
    }

    /**
     * Handle a drop event.
     * @param event The event to handle.
     */
    onDrop = (event) => {
        this.defaultHandleDragEvent(event);
        // load files
        this.state.dwvApp.loadFiles(event.dataTransfer.files);
    }

    SelectFile = (files) => {
        this.state.dwvApp.loadFiles(files);
    }

    /**
     * Show/hide the data load drop box.
     * @param show True to show the drop box.
     */
    showDropbox = (app, show) => {
        const box = document.getElementById(this.state.dropboxDivId);
        const isBoxShown = box && box.offsetHeight !== 0;
        const layerDiv = this.state.dwvApp?.getElement('layerContainer');

        if (box) {
            if (show && !isBoxShown) {
                // reset css class
                box.className = this.state.dropboxClassName + ' ' + this.state.borderClassName;
                // check content
                if (box.innerHTML === '') {
                    const p = document.createElement('p');
                    p.appendChild(document.createTextNode('Kéo và thả file vào đây hoặc chọn file.'));
                    box.appendChild(p);
                }
                // show box
                box.setAttribute('style', 'visible:true;width:50%;height:75%');
                // stop layer listening
                if (layerDiv) {
                    layerDiv.removeEventListener('dragover', this.defaultHandleDragEvent);
                    layerDiv.removeEventListener('dragleave', this.defaultHandleDragEvent);
                    layerDiv.removeEventListener('drop', this.onDrop);
                }
                // listen to box events
                box.addEventListener('dragover', this.onBoxDragOver);
                box.addEventListener('dragleave', this.onBoxDragLeave);
                box.addEventListener('drop', this.onDrop);
            } else {
                // remove border css class
                box.className = this.state.dropboxClassName;
                // remove content
                box.innerHTML = '';
                // hide box
                box.setAttribute('style', 'visible:false;width:0;height:0');
                // stop box listening
                box.removeEventListener('dragover', this.onBoxDragOver);
                box.removeEventListener('dragleave', this.onBoxDragLeave);
                box.removeEventListener('drop', this.onDrop);
                // listen to layer events
                if (layerDiv) {
                    layerDiv.addEventListener('dragover', this.defaultHandleDragEvent);
                    layerDiv.addEventListener('dragleave', this.defaultHandleDragEvent);
                    layerDiv.addEventListener('drop', this.onDrop);
                }
            }
        }
    }

    // drag and drop [end] -------------------------------------------------------

} // DwvComponent

DwvComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(DwvComponent);

export default DwvComponent;