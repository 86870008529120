import React, { Component } from 'react';
import { Modal, Tooltip, Select, Tag, Button, } from 'antd';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import TaoLichLayMau from "../lichhenlaymau/create";
import '../lichhen/lichhen.css';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from "../../services/commonService";
import * as DistributeServices from '../dieuphoi/distributeservices';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import { connect } from 'react-redux';
import HeaderModal from '../../configureHIS/common/headerModal';
import { BUTTON } from '../../configureHIS/common/constants';
import ChiTietLichLayMauTaiNha from "./update";
import { capitalizeFirstLetter } from '../../libs/util';

moment.locale('vi_vn');
const localizer = momentLocalizer(moment);
const { Option } = Select;
var GroupCTV = true;
export class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            btnKey: 'month',
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-4 mb-2">
                    <div className="btn-group mr-2" role="group">
                        <button className={`btn btn-appointment ${this.state.btnKey === 'PREV' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'PREV' }); this.navigate('PREV') }}><i className="fa fa-angle-left"></i></button>
                        <button className={`btn btn-appointment ${this.state.btnKey === 'NEXT' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'NEXT' }); this.navigate('NEXT') }}><i className="fa fa-angle-right"></i></button>
                    </div>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'TODAY' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'TODAY' }); this.navigate('TODAY') }}>Hôm nay</button>
                </div>
                <div className="col-md-4 mb-2 text-center align-self-center">
                    <div className="h4 text-uppercase">{this.props.label}</div>
                </div>
                <div className="col-md-4 mb-2 text-right">
                    <button className={`btn btn-appointment ${this.state.btnKey === 'month' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'month' }); this.view('month', null) }}>Tháng</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'week' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'week' }); this.view('week', null) }}>Tuần</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'day' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'day' }); this.view('day', null) }}>Ngày</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'agenda' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} onClick={() => { this.setState({ btnKey: 'agenda' }); this.view('agenda', null) }}>Lịch</button>
                </div>
            </div>
        );
    }
}
class LichLayMau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstEvent: [],
            lstCTV: [],
            visibleCreate: false,
            inEditmode: false,
            selectedAppointment: {},
            group: [],
            key: 0,
            gionhanmau: moment(),
            dateRange: [moment().startOf('month'), moment().endOf('month')],
            getType: 1,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        }
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        UsersService.CheckKyThuatVien().then(res => {
            if (res.isSuccess) {
                GroupCTV = true;
                this.setState({ chinhanh: this.props.siteid }, () => {
                    this.loadDanhSachLichHen();
                })
            } else {
                GroupCTV = false;
                this.setState({ chinhanh: this.props.siteid }, () => {
                    this.loadDanhSachLichHen();
                    this.getListCollaborators();
                })
            }
            UsersService.CheckPremissionMultisite().then(result => {
                if (result.isSuccess) {
                    this.setState({
                        isMultisite: result.item
                    });
                    if (result.item == true) {
                        this.GetListSite();
                    }
                }
            });
        }).catch(() => { })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.loadDanhSachLichHen();
            this.getListCollaborators();
        }
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    notifyUpdate() {
        const isLoading = false;
        const status = -1;
        this.loadDanhSachLichHen(this.state.key, status, isLoading);
    }
    onSelectEvent = (value) => {
        this.setState({
            selectedAppointment: value,
            visibleDetail: true,
        })
    }
    eventStyleGetter = (e) => {
        var backgroundColor = 'white'
        var color = 'black'
        var diff = moment().diff(e.ngayhen, 'minutes')
        if (e.status === 0) {
            backgroundColor = '#D9822D'
            color = 'white'
        }
        else if (e.status === 2) {
            backgroundColor = '#dc3545'
            color = 'white'
        }
        else {
            if (e.hoantat === 1) {
                backgroundColor = 'rgb(40,167,69)'
                color = 'white'
            } else {
                if (e.dalaymau === 1) {
                    backgroundColor = 'rgba(25,134,218,.8)'
                    color = 'white'
                } else {
                    if (diff > 0) {
                        backgroundColor = 'rgba(32,32,32,0.7)'
                        color = 'white'
                    } else {
                        if (diff > -60) {
                            backgroundColor = 'rgba(240,240,67,0.8)'
                            color = 'black'
                        } else {
                            backgroundColor = 'rgba(242,242,242,0.8)'
                            color = 'black'
                        }
                    }
                }
            }
        }
        var style = {
            fontSize: 12,
            fontFamily: 'Averta',
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    }

    getListCollaborators = (getType = this.state.getType, group = this.state.nhomCTV, chinhanh = this.state.chinhanh) => {
        var getAll = getType === 1 ? true : false;
        if (!getAll) {
            var date = moment(this.state.ngayhen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, true, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                } else {
                    this.setState({
                        lstCTV: [],
                    });
                }
            }).catch(() => { })
        }
        else {
            CommonService.GetListCollaborators(1, 10, false, group, chinhanh).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        lstCTV: res.data,
                    });
                }
            }).catch(() => { })
        }
    }
    loadDanhSachLichHen(key = this.state.key, status = -1, isLoading = true) {
        Actions.setLoading(isLoading);
        AppointmentService.DanhSachHenLayMau(key, status, this.state.dateRange[0].format('YYYY/MM/DD'), this.state.dateRange[1].format('YYYY/MM/DD'), this.state.chinhanh).then(result => {
            let lstEvent = []
            if (result.isSuccess) {
                for (let item of result.data) {
                    var event = {
                        id: item.id,
                        tenbn: item.tenbn,
                        mabn: item.mabn,
                        ngayhen: item.ngayhen,
                        title: item.tenbn + "-" + item.mabn,
                        start: moment(item.ngayhen).toDate(),
                        end: moment(item.ngayhen).add(1, 'hours').toDate(),
                        dalaymau: moment(item.gionhanmau).year() === 1 ? 0 : 1,
                        hoantat: item.dalaymau,
                        status: item.status,
                        chinhanh: item.chinhanh,
                    }
                    lstEvent.push(event)
                }
                this.setState({
                    lstEvent,
                    group: result.groupUser
                })
                if (this.state.selectedAppointment.id && this.state.visibleDetail) {
                    let event = lstEvent.find(x => x.id == this.state.selectedAppointment.id);
                    this.setState({
                        selectedAppointment: event
                    });
                    this.onSelectEvent(event);
                }
            } else {
                Actions.openMessageDialog("Thông báo", result.err.msgString);
                this.setState({
                    lstEvent: [],
                })
            }
        }).finally(() => Actions.setLoading(false))
    }
    handleCancel = () => {
        this.setState({
            visibleCreate: false,
            visibleDetail: false,
        });
        this.loadDanhSachLichHen(this.state.key);
    }
    onReloadData = () => {
        this.setState({
            visibleDetail: false,
        }, () => this.loadDanhSachLichHen());
    }
    render() {
        return (
            <div style={{ height: '100%' }}>
                <div className={'containerChilderWrapper'}>
                    <HeaderModal title="Lịch hẹn lấy mẫu tại nhà" />
                    {!GroupCTV &&
                        <div className="row no-gutters" style={{ display: "flex" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: '100px' }}>Cộng tác viên&nbsp;</span>
                                <Select
                                    style={{ width: '200px' }}
                                    value={this.state.key}
                                    placeholder="Lọc theo cộng tác viên"
                                    allowClear={false}
                                    onChange={(e) => {
                                        this.setState({ key: e });
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option key={-1} value={0}>Tất cả</Option>
                                    {this.state.lstCTV.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            {/* <div>
                                {this.state.isMultisite === true &&
                                    <Select
                                        style={{ width: '200px' }}
                                        showSearch
                                        allowClear={false}
                                        value={this.state.chinhanh}
                                        onChange={(e) => {
                                            this.setState({ chinhanh: e },()=>{
                                                this.loadDanhSachLichHen();
                                                this.getListCollaborators();
                                            })
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={''}>Chi nhánh</Option>
                                        {this.state.listSite.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.id}>{row.ma}</Option>
                                            )
                                        })}
                                    </Select>
                                }
                            </div> */}
                            <Tooltip title="Tải lại trang">
                                <Button className="btn btn-secondary ml-2"
                                    onClick={() => this.loadDanhSachLichHen()}>
                                    <i className="fa fa-sync" /></Button>
                            </Tooltip>
                        </div>}
                    <div className="row no-gutters" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ backgroundColor: "#fff", padding: 5, borderRadius: 5, lineHeight: 2 }}>
                            <span>Mã màu:&nbsp;</span>
                            <Tag color="rgba(242,242,242,0.8)"><span style={{ color: "#333" }}>Đang chờ</span></Tag>
                            <Tag color="rgba(32,32,32,0.7)">Quá giờ</Tag>
                            <Tag color="rgba(240,240,67,0.8)"><span style={{ color: "#333" }}>1 giờ đổ lại</span></Tag>
                            <Tag color="rgba(25,134,218,.8)">Đã lấy mẫu</Tag>
                            <Tag color="rgb(40,167,69)">Đã bàn giao mẫu</Tag>
                            <Tag color="#D9822D" onClick={() => this.loadDanhSachLichHen(this.state.key, 0)} style={{ cursor: "pointer" }}>Đang chờ xác nhận</Tag>
                            <Tag color="#dc3545" onClick={() => this.loadDanhSachLichHen(this.state.key, 2)} style={{ cursor: "pointer" }}>Từ chối</Tag>
                        </div>
                        {!GroupCTV && <div>
                            <Tooltip title="Tạo lịch hẹn">
                                <button className={BUTTON.THEME}
                                    onClick={() => this.setState({ visibleCreate: true })}>
                                    <i className="fas fa-calendar-plus mr-1" />Tạo mới
                                </button>
                            </Tooltip>
                        </div>}
                    </div>
                    <div className="mt-2" style={{ height: "calc(100% - 140px)", backgroundColor: '#fff' }}>
                        <div style={{ height: "100%" }}>
                            <Calendar
                                selectable
                                localizer={localizer}
                                components={
                                    {
                                        toolbar: CalendarToolbar,
                                        week: {
                                            header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd, DD'))
                                        },
                                        month: {
                                            header: ({ date, localizer }) => capitalizeFirstLetter(localizer.format(date, 'dddd'))
                                        },
                                        event: (e) => {
                                            return (
                                                <div onClick={() => this.onSelectEvent(e.event)}>
                                                    <div>{e.event?.title}</div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                                messages={{
                                    noEventsInRange: "Chưa có dữ liệu",
                                    date: "Ngày",
                                    time: "Giờ",
                                    event: "Thông tin",
                                    showMore: (count) => <span><i className="fa fa-eye" style={{ marginRight: 5 }} />Xem thêm {count} lịch hẹn</span>
                                }}
                                onSelectEvent={this.onSelectEvent}
                                events={this.state.lstEvent}
                                startAccessor="start"
                                endAccessor="end"
                                eventPropGetter={(this.eventStyleGetter)}
                                onRangeChange={(value) => {
                                    if (value instanceof Array) {
                                        this.setState({ dateRange: [moment(value[0]), moment(value[value.length - 1])] }, () => {
                                            this.loadDanhSachLichHen(this.state.key)
                                        })
                                    }
                                    else {
                                        this.setState({ dateRange: [moment(value.start), moment(value.end)] }, () => {
                                            this.loadDanhSachLichHen(this.state.key)
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {this.state.visibleCreate &&
                        <Modal
                            maskClosable={false}
                            width={1600}
                            open={this.state.visibleCreate}
                            onCancel={this.handleCancel}
                            okButtonProps={{ disabled: true, hidden: true }}
                            cancelButtonProps={{ style: { display: 'none' } }}>
                            <TaoLichLayMau onCancel={this.handleCancel} chinhanh={this.state.chinhanh} />
                        </Modal>
                    }
                    <Modal
                        maskClosable={false}
                        width={1000}
                        visible={this.state.visibleDetail}
                        onCancel={() => this.setState({ visibleDetail: false })}
                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        destroyOnClose={true}>
                        <ChiTietLichLayMauTaiNha
                            onCancel={this.handleCancel}
                            reload={this.onReloadData}
                            inEditMode={this.state.inEditmode}
                            selectedAppointment={this.state.selectedAppointment}
                            groupCTV={GroupCTV} />
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps)(LichLayMau)
