import * as ApiCaller from '../libs/httpRequests';
const prefix = 'UploadFile';

export function GetListAsync(filter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListAsync/${filter.ComponentKey}`, filter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function CreateUploadFileAsync(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/CreateUploadFileAsync`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function DeleteFileAsync(fileId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteFileAsync/${fileId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DownloadFileAsync(fileId)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownloadFileAsync/${fileId}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DownloadAppOrder()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownloadAppOrder`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
