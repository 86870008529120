import { Button, Form, Input, notification, Pagination, Popover, Switch, Table, Popconfirm } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import * as PatientService from '../../services/patientService';
import * as CommonService from '../../services/commonService';

const initialState = {
    key: '',
    mabn: '',
    loadingZalo: false,
    txtZalo: '',
    totalRecords: 0,
}
export default class ResultBenhNhan extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstBN: [],
            visibleZalo: false,
            currentPage: 1,
            pageSize: 10,
        }

    }
    componentWillMount() {
        this.loadDanhSach();
    }
    loadDanhSach(page = this.state.currentPage, size = this.state.pageSize) {
        PatientService.GetListBN(this.state.key, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
                if (result.data.length > 0) {
                    this.setState({ totalRecords: result.data[0].totalRecords })
                }
                this.setState({ currentPage: page })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách bệnh nhân bị lỗi' });
        })
    }
    updateCheckZalo(mabn, check) {
        this.setState({ loadingZalo: true })
        PatientService.updateCheckZalo(mabn, check).then(result => {
            let data = this.state.lstBN;
            let item = data.find(x => x.patientID === mabn);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.zalo;
                    item.zalo = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.zalo;
                    item.zalo = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstBN: data,
                loadingZalo: false,
            })
        }).catch(err => {
            let data = this.state.lstBN;
            let item = data.find(x => x.patientID === mabn);
            if (item !== undefined) {
                const newcheck = item.zalo;
                item.zalo = newcheck;
            }
            this.setState({ loadingZalo: false, lstBN: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    updateUserZalo(mabn) {
        PatientService.updateUserZalo(mabn, this.state.txtZalo).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                this.loadDanhSach(1, this.state.pageSize);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    submitResult(mabn, maql) {
        CommonService.SendResultForUser(mabn, maql).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Gửi thành công!' })
                this.loadDanhSach(1, this.state.pageSize);
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã BN',
                dataIndex: 'patientID',
                key: 'patientID',
            },
            {
                title: 'Họ tên',
                dataIndex: 'patientName',
                key: 'patientName',
                render: (value) => {
                    return <b>{value}</b>;
                },
            },
            {
                title: 'Giới tính',
                dataIndex: 'gender',
                key: 'gender',
                render: (value) => {
                    return <span>{value === 0 ? "Nam" : "Nữ"}</span>;
                },
            },
            {
                title: 'Số CMND',
                dataIndex: 'cardId',
                key: 'cardId',
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'Nhận zalo',
                render: (row) => {
                    return (
                        <div>
                            <Switch loading={this.state.loadingZalo} checkedChildren="Có" unCheckedChildren="Không" checked={row.zalo}
                                onChange={(e) => { this.updateCheckZalo(row.patientID, e) }} />
                            <Popover
                                content={
                                    row.patientID === this.state.mabn ?
                                        <div style={{ display: "flex" }}>
                                            <Input style={{ marginRight: 5 }}
                                                onChange={e => this.setState({ txtZalo: e.target.value })} placeholder="Nhập số điện thoại Zalo" />
                                            <button className="btn btn-success" onClick={() => { this.updateUserZalo(row.patientID) }}>Xác nhận</button>
                                        </div>
                                        :
                                        ''
                                }
                                title={`Cập nhật thông tin Zalo bệnh nhân: ${row.patientID}`}
                                trigger="click"
                                visible={this.state.visibleZalo === true && row.patientID === this.state.mabn}
                                onVisibleChange={() => { this.state.visibleZalo === true && row.patientID === this.state.mabn && this.setState({ visibleZalo: false }) }}
                            >
                                <button
                                    style={{ marginLeft: "5px", borderRadius: "5px" }}
                                    className="btn btn-warning"
                                    title="Cập nhật thông tin Zalo"
                                    onClick={() => { this.setState({ mabn: row.patientID, visibleZalo: true }) }} >
                                    Cập nhật
                                </button>
                            </Popover>

                        </div>
                    )
                }
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: '',
                render: (row) => {
                    return (
                        <Popconfirm title="Bạn có chắc muốn gửi kết quả?"
                            onConfirm={() => { this.submitResult(row.patientID, ' ') }} okText="Đồng ý" cancelText="Hủy">
                            <Button className="btn btn-outline" title="Gửi kết quả" >Gửi kết quả</Button>
                        </Popconfirm>

                    )
                }
            }
        ];
        return (
            <div style={{ padding: 20 }}>
                <Form ref={(c) => this.form = c}>
                    <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="col-md-4 mt-2">
                            <Form.Item label="Tìm bệnh nhân:">
                                <Input
                                    value={this.state.key}
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    placeholder="Nhập mã hoặc tên bệnh nhân..."
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.loadDanhSach(1, this.state.pageSize);
                                        }
                                    }}
                                />
                            </Form.Item>
                            </div>
                            <div className="col-md-3 mt-2" style={{alignSelf:'end'}}>
                            <Form.Item>
                                <button className="btn btn-success"
                                    onClick={() => this.loadDanhSach(1, this.state.pageSize)}><i className="fa fa-search" style={{ marginRight: 5 }} />Tìm</button>
                            </Form.Item>
                            </div>
                    </div>
                    <div className="table-responsive" style={{ paddingTop: 10 }}>
                        <Table pagination={false} scroll={{ x: 'max-content', y: 0 }}
                            dataSource={this.state.lstBN} columns={columns} rowKey={(e, index) => index} />
                    </div>
                    <Pagination
                        current={this.state.currentPage}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSach(page, size) }}
                        onShowSizeChange={(page, size) => { this.setState({currentPage: 1, pageSize: size }); this.loadDanhSach(1, size)}}
                        showSizeChanger={true}
                    />
                </Form>
            </div>
        )
    }
}