import * as ApiCaller from '../libs/httpRequests';


const prefix = 'RegisterMedical';

export function SaveKhaiBaoYTe(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveKhaiBaoYTe`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
