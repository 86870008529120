import { Button, DatePicker, Input, Pagination, Popconfirm,Modal } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import Detail from './detail'
import * as SystemConfig from '../../../../configure/systemConfig';
const { RangePicker } = DatePicker;
function ListPaid(props) {
    const [listHoaDon, setListHoaDon] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [key, setKey] = useState('');
    const [currentSize, setCurrentSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [visiableDetail, setVisiableDetail] = useState(false);
    const [ngay, setNgay] = useState([moment(), moment()]);
    const [detail, setDetail] = useState({});
    useEffect(() => {
        if (currentPage === 0)
            return;
        GetListThanhToanNoiTruByDate();
    }, [currentPage, currentSize])
    useEffect(() => {
        GetListThanhToanNoiTruByDate();
    }, []);
    const GetListThanhToanNoiTruByDate = () => {
        Actions.setLoading(true);
        InHospitalService.GetListThanhToanNoiTruByDate(ngay[0].format("YYYY/MM/DD"), ngay[1].format("YYYY/MM/DD"), key, currentPage, currentSize).then(result => {
            if (result.isSuccess) {
                setTotalRecords(result.totalRecords);
                setListHoaDon(result.data)
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    const DeleteThanhToanNoiTru = (id) => {
        Actions.setLoading(true);
        InHospitalService.DeleteThanhToanNoiTru(id).then(result => {
            if (result.isSuccess) {
                GetListThanhToanNoiTruByDate();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    return (
        <React.Fragment>
            <div className="row" style={{ placeContent: 'center' }}>
                <div className="col-auto py-1" style={{ width: '250px' }}>
                    <RangePicker format="DD-MM-YYYY" value={ngay} allowClear={false}
                        locale={viVN} onChange={(value => setNgay(value))}
                    />
                </div>
                <div className="col-auto py-1">
                    <Input placeholder="Nhập số hóa đơn, tên, mã bệnh nhân"
                        type="text" style={{ width: '250px' }} value={key} onChange={(e) => { setKey(e.target.value) }} />
                    <Button className="btn btn-color"
                        onClick={() => {
                            setCurrentPage(0);
                            setTimeout(() => {
                                setCurrentPage(1);
                            }, 100);
                        }}
                    ><i className="fa fa-search"></i></Button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 py-1">
                    <div className="table-responsive table-inpatient">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: '120px' }}>Ngày</th>
                                    <th style={{ minWidth: '80px' }}>Số HĐ</th>
                                    <th style={{ minWidth: '80px' }}>Mã BN</th>
                                    <th style={{ minWidth: '150px' }}>Tên BN</th>
                                    <th style={{ minWidth: '100px' }}>Số tiền</th>
                                    <th style={{ minWidth: '100px' }}>Miễn giảm</th>
                                    <th style={{ minWidth: '100px' }}>Lý do</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listHoaDon.map((row, index) =>
                                    <tr style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setVisiableDetail(true);
                                            setDetail(row);
                                        }}>
                                        <td style={{ minWidth: '120px' }}>{moment(row.ngay).format(SystemConfig.FORMAT_DATE_TIME)}</td>
                                        <td>{row.sohd}</td>
                                        <td>{row.mabn}</td>
                                        <td>{row.tenbn}</td>
                                        <td className="text-right">{row.tongtien.toLocaleString('en-US')}</td>
                                        <td className="text-right">{(row.miengiam).toLocaleString('en-US')}</td>
                                        <td>{row.lydomiengiam}</td>
                                        <td onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}>
                                            <Popconfirm
                                                title="Xác nhận xóa hóa đơn?"
                                                onConfirm={() => {
                                                    DeleteThanhToanNoiTru(row.id);
                                                }}
                                                okText="Đồng ý" cancelText="Hủy">
                                                <button className="btn btn-danger"
                                                    onClick={() => { }}
                                                ><i className="fa fa-trash"></i></button>
                                            </Popconfirm>
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        size={currentSize}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setCurrentSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setCurrentSize(size);
                        }
                        }
                        showSizeChanger={true}
                    />
                </div>
            </div>
            {visiableDetail &&
                <Modal
                    visible={visiableDetail}
                    width={1400}
                    onCancel={() => setVisiableDetail(false)}>
                    <Detail patient={detail} onClose={() => setVisiableDetail(false)} />
                </Modal>
            }
        </React.Fragment>
    )
}

export default ListPaid;
