import React, { Fragment, useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { Button } from 'antd';
import { BUTTON } from '../../../configureHIS/common/constants';
import { COLOR } from '../../../configureHIS/common/TagVertical';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as PatientStaticService from '../../../services/patientStaticService';
import * as SystemConfig from "../../../configure/systemConfig";

const MAX_X_FIELD = 12;

export default forwardRef((props, ref) => {
    const _chartRef = useRef();
    const [baseData, setBaseData] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    useImperativeHandle(ref, () => ({
        getChartData: () => {
            setPage(1);
            getChartData();
        },
        downloadImage: () => {
            downloadImage();
        }
    }));
    useEffect(() => {
        getChartData();
    }, []);
    useEffect(() => {
        setUpData(baseData);
    }, [page]);
    const getChartData = () => {
        Actions.setLoading(true);
        let dateFrom = props.datePickerType === "day" ? props.dateRange[0] : getDate("from");
        let dateTo = props.datePickerType === "day" ? props.dateRange[1] : getDate("to");
        let site = props.site;
        let getAll = true
        PatientStaticService.GetPatientStatistic(dateFrom, dateTo, 1, 1, site, "", getAll).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                setBaseData(data);
                setUpData(data);
                updatePropsSummarize(result.data.returnObject);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bị lỗi");
        })
    }
    const getDate = (type) => {
        let datePickerType = props.datePickerType;
        let dateSelected = props.date;
        if (type === "from") {
            return moment(dateSelected[0]).startOf(datePickerType);
        } else if (type === "to") {
            return moment(dateSelected[1]).endOf(datePickerType);
        }
    }
    const updatePropsSummarize = (data) => {
        let dataSummarize = [];
        dataSummarize.push({
            title: "Khách hàng mới",
            color: COLOR.Primary,
            value: data.totalNew,
        });
        dataSummarize.push({
            title: "Khách hàng tái khám",
            color: COLOR.Success,
            value: data.totalOld,
        });
        dataSummarize.push({
            title: "Tổng cộng",
            value: data.totalNew + data.totalOld,
        });
        props.updateDataSummarize && props.updateDataSummarize(dataSummarize);
    }
    const setUpData = (data) => {
        let datePickerType = props.datePickerType;
        let dateFormat = getDateFormat();
        data = data.sort(function (a, b) { return moment(a.dateIn) - moment(b.dateIn) });
        data = data.map(x => ({
            date: datePickerType === "quarter" ? moment(x.dateIn).startOf("quarter").format(`[Quý] Q-YYYY`) : moment(x.dateIn).format(dateFormat),
            count: 1,
            status: x.isNew ? "Khách hàng mới" : "Khách hàng tái khám"
        }));
        let listDataProced = [...data.reduce((r, o) => {
            const key = o.date + '-' + o.status;
            const item = r.get(key) || Object.assign({}, o, {
                count: 0,
            });
            item.count += o.count;
            return r.set(key, item);
        }, new Map).values()];
        listDataProced.forEach(element => {
            let foundNewInDate = listDataProced.find(x => x.date + '-' + x.status === element.date + '-' + "Khách hàng mới");
            let foundOldInDate = listDataProced.find(x => x.date + '-' + x.status === element.date + '-' + "Khách hàng tái khám");
            if (foundNewInDate === undefined) {
                listDataProced.push({
                    date: element.date,
                    count: 0,
                    status: "Khách hàng mới",
                })
            }
            if (foundOldInDate === undefined) {
                listDataProced.push({
                    date: element.date,
                    count: 0,
                    status: "Khách hàng tái khám",
                })
            }
        });
        listDataProced = listDataProced.sort(function (a, b) { return moment(a.date, getDateFormat()) - moment(b.date, getDateFormat()) });
        let listDateUniq = [...new Set(listDataProced.map(x => x.date))];
        setTotalRecords(listDateUniq.length);
        sliceToShow(listDataProced);
    };
    const sliceToShow = (data) => {
        function limit(c) {
            return this.filter((x, i) => {
                if (i <= (c - 1)) { return true }
            })
        }
        Array.prototype.limit = limit;
        function skip(c) {
            return this.filter((x, i) => {
                if (i > (c - 1)) { return true }
            })
        }
        Array.prototype.skip = skip;
        data = data.skip(MAX_X_FIELD * 2 * (page - 1)).limit(MAX_X_FIELD * 2);
        setData(data);
    }
    const getDateFormat = () => {
        let datePickerType = props.datePickerType;
        if (datePickerType === "day") return SystemConfig.FORMAT_DATE;
        if (datePickerType === "month") return "MM-YYYY";
        if (datePickerType === "year") return "YYYY";
        if (datePickerType === "quarter") return "[Q]Q-YYYY";
    }
    const downloadImage = () => {
        let dateFrom = props.datePickerType === "day" ? props.dateRange[0] : getDate("from");
        let dateTo = props.datePickerType === "day" ? props.dateRange[1] : getDate("to");
        let dateFormat = getDateFormat();
        let name = moment().format("[Patient_Statistic_Chart_]YYMMDD_HHmmss");
        name += "_From_";
        name += moment(dateFrom).format(dateFormat);
        name += "_To_";
        name += moment(dateTo).format(dateFormat);
        _chartRef.current.downloadImage(name);
    };
    const config = {
        data,
        xField: 'date',
        yField: 'count',
        seriesField: 'status',
        yAxis: {
            label: {
                formatter: (value) => `${value}`,
            },
        },
        legend: {
            position: 'bottom',
        },
        point: {
            size: 5,
        },
        color: ({ status }) => {
            if (status === "Khách hàng mới") {
                return COLOR.Primary;
            }
            return COLOR.Success;
        },
        label: ({ count }) => {
            return count;
        },
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 3000,
        //     },
        // },
    };
    return (
        <Fragment>
            <div style={{ height: "100%" }} >
                <Line {...config}
                    onReady={(plot) => {
                        _chartRef.current = plot;
                    }} />
            </div>
            <div className="text-center mt-2">
                <Button id="pcrt-btn-prev" className={`${BUTTON.THEME} btn-circle`}
                    disabled={page === 1 || totalRecords === MAX_X_FIELD}
                    onClick={() => setPage(page - 1)}>&#8249;</Button>
                {/* <label className='ml-2'>{page}</label> */}
                <Button id="pcrt-btn-next" className={`${BUTTON.THEME} ml-5 btn-circle`}
                    disabled={page >= (totalRecords / MAX_X_FIELD) || totalRecords === MAX_X_FIELD}
                    onClick={() => setPage(page + 1)}>&#8250;</Button>
            </div>
        </Fragment>
    );
})