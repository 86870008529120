import * as ApiCaller from '../libs/httpRequests';
const prefix = 'SendMessage';

export function GetListSendMessageType() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSendMessageType`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListSendMessageTemplateType() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListSendMessageTemplateType`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListSendMessageTemplate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListMessageTemplate`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateSendMessageTemplate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateSendMessageTemplate`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateSendMessageTemplate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateSendMessageTemplate`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};