import { DatePicker, Form, Modal, notification, Popconfirm, Select, Table, Tooltip, Button, Input } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import * as ChiDinhService from '../../services/chiDinhService';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as PatientService from '../../services/patientService';
import * as Actions from '../../libs/actions';
import TaoLichHen from './taolichhen';
import AutocompleteTable from '../../components/autocompletetable';
import SelectProductAutocomplete from '../../components/MDCLS/selectProductAutocomplete';
import ModalCLS from '../../components/MDCLS';
import SelectConsultant from '../../components/MDCLS/selectConsultant';
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import HeaderModal from '../../configureHIS/common/headerModal';
import './lichhen.css';
import { isNullOrEmpty } from '../../libs/util';
import ConsultantWarning from '../recept/register/consultantWarning';

moment.locale('vi_vn');
const localizer = momentLocalizer(moment);
const { Option } = Select;
const { TextArea } = Input;
class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            func: null,
            mabs: '',
            btnKey: 'month',
            banggia: 'default'
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-4 mb-2">
                    <div className="btn-group mr-2" role="group">
                        <button className={`btn btn-appointment ${this.state.btnKey === 'PREV' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'PREV' }); this.navigate('PREV') }}><i className="fa fa-angle-left"></i></button>
                        <button className={`btn btn-appointment ${this.state.btnKey === 'NEXT' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'NEXT' }); this.navigate('NEXT') }}><i className="fa fa-angle-right"></i></button>
                    </div>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'TODAY' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'TODAY' }); this.navigate('TODAY') }}>Hôm nay</button>
                </div>
                <div className="col-md-4 mb-2 text-center align-self-center">
                    <div className="h4 text-uppercase">{this.props.label}</div>
                </div>
                <div className="col-md-4 mb-2 text-right">
                    <button className={`btn btn-appointment ${this.state.btnKey === 'month' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'month' }); this.view('month', null) }}>Tháng</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'week' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'week' }); this.view('week', null) }}>Tuần</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'day' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'day' }); this.view('day', null) }}>Ngày</button>
                    <button className={`btn btn-appointment ${this.state.btnKey === 'agenda' ? 'btn-appointment-active' : 'btn-appointment-noactive'}`} type="button" onClick={() => { this.setState({ btnKey: 'agenda' }); this.view('agenda', null) }}>Lịch</button>
                </div>
            </div>
        );
    }
}

class LichHen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: moment(),
            selectedValue: moment(),
            listTest: [],
            lstHen: [],
            visible: false,
            lstEvent: [],
            lstDoctor: [],
            mabs: 0,
            groupID: 0,
            lstCreate: [],
            create: '',
            editAppointmentModalFlag: false,
            inEditmode: false,
            selectedAppointment: {},
            selectedPatient: {},
            selectedDate: {},
            lstPhong: [],
            maphongkham: '',
            mabskham: [],
            lstChiDinh: [],
            visibleCLS: false,
            banggia: 'default',
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            site: 1,
        };

    }
    notifyUpdate = () => {
        const isLoading = false;
        this.loadDanhSachLichHen(0, "", isLoading);
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetBangGiaDefaultSite();
        this.GetListSite();
        this.loadDanhSachLichHen();
        this.DanhSachBacSi();
        this.ListUserCreate();
        this.DanhSachPhongKham();
        let group = localStorage.getItem('groupid');
        this.setState({
            groupID: Number(group)
        })
    }

    GetBangGiaDefaultSite() {
        ConfigService.BangGiaDefaultSite(this.props.siteid).then((respone) => {
            if (respone.isSuccess) {
                let banggia = respone.item ? respone.item : 'default';
                this.setState({
                    banggia: banggia,
                });
            }
        }).catch(err => { })
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    getListData(value) {
        let listData;
        let { lstHen } = this.state;

        if (lstHen && lstHen.length > 0) {

            listData = lstHen.filter(x => (moment(x.ngayhen).date() === value.date()) && (moment(x.ngayhen).month() === value.month()));
        }


        return listData || [];
    }
    searchIndication(key) {
        return ChiDinhService.searchSerivce(0, 0, this.state.banggia, key, 1, 20);
    }

    onSelectIndication(item) {
        // this.modalCLS.AddService(item);

        const { lstChiDinh } = this.state;
        var existed = lstChiDinh.find(x => x.id === item.id);
        if (existed === undefined) {
            item.banggia = "default";
            item.soluong = 1;
            item.idChiDinh = "0";
            let total = 0;
            lstChiDinh.push(item)
            lstChiDinh.map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
            this.setState({
                lstChiDinh: lstChiDinh,

            })
        }
    }


    DanhSachPhongKham() {
        CommonService.GetDepartment().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi!' })
        })
    }
    loadDanhSachLichHen(e = 0, create = "", isLoading = true) {
        Actions.setLoading(isLoading);
        AppointmentService.DanhSachHenKham(e, this.props.siteid, create).then(result => {
            let lstEvent = []
            if (result.isSuccess) {
                for (let item of result.data) {
                    var event = {
                        id: item.id,
                        tenbn: item.tenbn,
                        mabn: item.mabn,
                        ngayhen: item.ngayhen,
                        title: item.tenbn + "-" + item.mabn,
                        start: moment(item.ngayhen).toDate(),
                        end: moment(item.ngayhen).add(1, 'hours').toDate(),
                        arrived: item.daden,
                        maql: item.managementID,
                    }
                    lstEvent.push(event)
                }
                this.setState({
                    lstHen: result.data, lstEvent, inEditmode: false,
                    editAppointmentModalFlag: false, selectedAppointment: {}, selectedPatient: {}
                })

            }
        }).finally(() => Actions.setLoading(false))
    }
    DanhSachBacSi() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    ListUserCreate() {
        AppointmentService.ListUserCreate().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstCreate: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };


    handleCancel = e => {
        this.setState({
            visible: false,
        });
        this.loadDanhSachLichHen(this.state.mabs, this.state.create);
    };

    disabledDate(current) {
        // let customDate = moment().add(1, 'days').format('yyyy-MM-dd').toString();
        // return current && current > moment(customDate, "yyyy-MM-dd'");
        return current && current.valueOf() < Date.now();
    }

    onSelectEvent = record => {
        this.setState({
            arrived: record.arrived,
        })
        Actions.setLoading(true);
        AppointmentService.GetAppoimentDetailByID(record.id, this.props.siteid).then(result => {
            if (result.isSuccess) {
                let data = result.item.lstChiDinh;
                for (let item of data) {
                    item.listChild = item.listChildCd;
                }
                this.setState({
                    selectedDate: result.item.ngayhen,
                    selectedPatient: result.item,
                    selectedAppointment: record,
                    lstChiDinh: data,
                    editAppointmentModalFlag: true,
                    maphongkham: result.item.makp,
                    mabskham: (result.item.mabs || result.item.mabs !== "0") ? result.item.mabs : '',
                    banggia: data.find(x => x.banggia !== "default") ? data.find(x => x.banggia !== "default").banggia : "default",
                    site: result.item.chinhanh
                })
                if (this.state.lstDoctor.findIndex(x => x.value === result.item.mabs) < 0) {
                    let lstBS = this.state.lstDoctor;
                    lstBS.unshift({ value: result.item.mabs, label: `${result.item.mabs}-${result.item.tenbs}` })
                    this.setState({ lstDoctor: lstBS.map(x => ({ ...x })) })
                }

            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        }).finally(() => Actions.setLoading(false))

    };
    updateAppointment() {
        let dataNguonKhachHang = this.nguonKhachHang && this.nguonKhachHang.getState();
        if (this.props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
            Actions.openMessageDialog("Thông báo", "Bắt buộc chọn nguồn khách hàng");
            return;
        }
		let isNullConsultant = this.state.lstChiDinh.some(x => isNullOrEmpty(x.consultantId));
		if (this.props.consultantRequired && isNullConsultant) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        Actions.setLoading(true);
        let { selectedAppointment, selectedDate } = this.state;
        let lstSelect = this.state.lstChiDinh;
        let listChiDinh = [];
        for (let item of lstSelect) {
            item.ma = "0";
            item.madoituong = "2";
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment().format("YYYY-MM-DD HH:mm")
            if (listChiDinh.filter(x=>x.id == item.id).length > 0) {
                notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                return;
            }
            listChiDinh.push(item);
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = xChild;
                    itemChild.giaaTH = xChild.dongia.toString();
                    itemChild.dongia = xChild.dongia;
                    itemChild.soluong = xChild.soluong;
                    itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                    itemChild.vienPhiIdOfCombo = item.id;
                    
                    if (listChiDinh.filter(x=>x.id == itemChild.id).length > 0) {
                        notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                        return;
                    }
                    
                    listChiDinh.push(itemChild);
                });
        }
        const data = {
            ngayhen: selectedDate,
            makp: this.state.maphongkham,
            mabs: this.state.mabskham,
            // lstChiDinh: this.state.lstChiDinh,
            lstChiDinh: listChiDinh,
            mabn: selectedAppointment.mabn,
            maql: this.state.selectedPatient.maql.toString(),
            site: this.props.siteid.toString(), // Chi nhánh hẹn ban đầu (chi nhánh hẹn khám đã chọn thực tế lưu trong DB)
            otCopor: dataNguonKhachHang.coporator,
        }
        const siteDuocDatLich = this.state.site; // Chi nhánh được đặt lịch hẹn
        AppointmentService.updateAppointment(selectedAppointment.id, data, siteDuocDatLich).then((result) => {
            if (result.isSuccess) {
                this.nguonKhachHang && this.nguonKhachHang.updateInfo();
                notification.success({ message: "Cập nhật thàng công" })
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog(result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        }).finally(() => Actions.setLoading(false))
    }
    deleteAppointment() {
        let { selectedAppointment } = this.state;
        Actions.setLoading(true);
        AppointmentService.deleteAppointment(selectedAppointment.id, this.props.siteid).then((result) => {
            if (result.isSuccess) {
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        }).finally(() => Actions.setLoading(false))

    }

    dateCellRender(value) {
        const listData = this.getListData(value);
        return (
            <React.Fragment>
                {listData.map(item => (
                    <div>
                        <span>BN:{item.tenbn} lúc {moment(item.ngayhen).format('HH:mm')}</span>
                    </div>

                ))}
            </React.Fragment>
        );
    }
    getColumns() {
        return [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                render: (text, record, index) => (Number(index) + 1),
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
            },
            {
                title: 'Dịch vụ',
                dataIndex: 'tenchidinh',
                key: 'tenchidinh',
            },
            {
                title: 'Giờ',
                dataIndex: 'ngayhen',
                key: 'ngayhen',
                render: (value) => {
                    return <span>Lúc <strong>{moment(value).format('HH:mm')}</strong></span>;
                }
            },
            {
                title: 'Bác sĩ',
                dataIndex: 'tenbs',
                key: 'tenbs',
            },
            {
                title: 'Phòng khám',
                dataIndex: 'tenphongkham',
                key: 'tenphongkham',
            },
            {
                title: 'Người tạo',
                dataIndex: 'tennguoitao',
                key: 'tennguoitao',
            },
        ];
    }

    eventStyleGetter(e, start, end, isSelected) {
        var backgroundColor = 'white'
        var color = 'black'
        var diff = moment().diff(e.ngayhen, 'minutes')
        if (e.arrived === 1) {
            backgroundColor = 'rgb(40,167,69)'
            color = 'white'
        } else {
            if (diff > 0) {
                backgroundColor = 'rgba(32,32,32,0.7)'
                color = 'white'
            } else {
                if (diff > -60) {
                    backgroundColor = 'rgba(240,240,67,0.8)'
                    color = 'black'
                } else {
                    backgroundColor = 'rgba(242,242,242,0.8)'
                    color = 'black'
                }
            }
        }

        var style = {
            fontSize: 12,
            fontFamily: 'Averta',
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            color: color
        };
        return {
            style: style
        };
    }
    ConfirmArrived() {
        const data = {
            id: this.state.selectedAppointment.id,
            ngayhen: this.state.selectedDate,
            makp: this.state.maphongkham,
            mabs: this.state.mabskham,
        }
        Actions.setLoading(true);
        AppointmentService.ConfirmArrived(data, this.props.siteid).then((result) => {
            if (result.isSuccess) {
                this.loadDanhSachLichHen(this.state.mabs, this.state.create);
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString.toString());
            }
        }).catch(err => {
            Actions.openMessageDialog("Error ", err);
        }).finally(() => Actions.setLoading(false))
    }

    XoaChiDinh(record, index) {
        let { lstChiDinh } = this.state;
        lstChiDinh.splice(index, 1);
        this.setState(lstChiDinh);
    }


    getListChiDinh(data) {
        this.setState({ lstChiDinh: data });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.siteid !== this.props.siteid) {
            this.loadDanhSachLichHen();
            this.DanhSachPhongKham();
        }
    }

    checkBangGia = (info) => {
        let banggia = this.state.banggia;
        let itemFound = this.state.lstChiDinh.find(x => x.banggia !== "" && x.banggia !== null && x.banggia !== undefined);
        let banggianguonkhachhang = this.state.banggia;
        if (info.banggia !== null) {
            banggianguonkhachhang = info.banggia
        }
        if (itemFound !== undefined) banggia = itemFound.banggia;
        if (itemFound !== undefined && banggianguonkhachhang !== banggia) {
            if (this.state.lstChiDinh.length > 0) {
                Actions.openMessageDialog("Thông báo",
                    `Việc thay đổi nguồn khách hàng (Bảng giá <b>${banggianguonkhachhang === "default" ? "Mặc định" : banggianguonkhachhang}</b>) đã xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                this.cancelExam();
                banggia = banggianguonkhachhang;
            }
            this.setState({ banggia });
        } else this.setState({ banggia: banggianguonkhachhang });
    }

    cancelExam() {
        this.setState({
            lstChiDinh: []
        })
    }

    resetStateNguonKhachHang = () => {
        this.nguonKhachHang && this.nguonKhachHang.resetState();
    }

	//#region SelectProductAutocomplete
	GetAllService() { //lấy all chỉ định (ra cùng cấp)
		let list = [];
		(this.state.lstChiDinh ?? []).forEach(item => {
			list.push(item);
			if (item.isCombo) {
				item.listChild.forEach(xChild => {
					xChild.vienPhiTenOfCombo = item.ten;
                    xChild.key=`${item.id}-${xChild.id}`;
					list.push(xChild);
				});
			}
		});
		return list;
	}
    onSelectIndications(products = []) {
		let listSelectedServices = [...this.state.lstChiDinh];
        let listAll = this.GetAllService();
		let total = 0;
		let isUpdateList = true;
        products.filter(x => x.isInBangGia).forEach(item => {
			// var existed = listSelectedServices.find((x) => x.id === item.id);
			var existed = listAll.find((x) => x.id === item.id);
			if (existed === undefined) {
				let itemConvert = this.convertToModelSaveNew(item);
				// if (itemConvert.groupId === 8) {
				// 	itemConvert = this.GetRoomDefaultForService(itemConvert);
				// }
				if (item.isCombo) {
					itemConvert.listChild = [];
					item.listChild.forEach(xChild => {
						// newVienPhiIds.push(xChild.id);
						let itemChild = this.convertToModelSaveNew(xChild);
						var existedChild = listAll.find((x) => x.id === itemChild.id);
						if (existedChild !== undefined) {
							if (existedChild.isChild)
								notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại trong combo [<b>]${existedChild.vienPhiTenOfCombo}]` });
							else notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại` });
							isUpdateList = false;
							return;
						}
						itemChild.vienPhiIdOfCombo = item.id;
						itemChild.isChild = true;
                        itemChild.key=`${item.id}-${itemChild.id}`;
						// if (itemChild.groupId === 8) {
						// 	itemChild = this.GetRoomDefaultForService(itemChild);
						// }
						itemConvert.listChild.push(itemChild);
					});
				}
				listSelectedServices.push(itemConvert);
			}
			else {
				if (existed.isChild)
					notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại trong combo [${existed.vienPhiTenOfCombo}]` });
				else notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại` });
				isUpdateList = false;
				return;
			}
        })
		listSelectedServices.map(
			(x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
		);

		if (isUpdateList) {
			this.setState({
				lstChiDinh: listSelectedServices,
				total: total,
			});
		}
    }
    convertProductToSelectComponent(arrProduct) {
        return arrProduct?.map(x => {
            let tenKhoaPhong = ((x.nhomId == 8 && (x.tenkp || x.tenPhongKham)) ? `${x.tenkp } - ${x.tenPhongKham }` : '');
            let listChild = [];
            if (x.listChild && x.listChild.length > 0) {
                listChild = this.convertProductToSelectComponent(x.listChild);
            }
            return {
                id: x.vienPhiId,
                ma: x.vienPhiMa,
                ten: x.vienPhiTen,
                allowDelete: x.allowDelete,
                idChiDinh: x.idChiDinh,
                uiStatus: 'selected',
                uiDeleted: false,
                gia_Th: x.donGia,
                tenKhoaPhong: tenKhoaPhong,
                statusText: x.status_Text,
                paid: x.paid,
                listChild: listChild,
            }
        })
    }
    convertToModelSaveNew(x) {
        return {
			banggia: this.state.banggia,
			soluong: 1,
			idChiDinh: "0",
			madoituong: "2",
            giaaTH: x.gia_Th.toString(),
            dongia: x.gia_Th,
            ngay: moment().format('YYYY/MM/DD HH:mm'),
			maql: this.state.managementID,
			mabn: this.state.code,
			id: x.id,
			mavp: x.ma,
			ten: x.ten,
			groupId: x.nhomId,
			listChild: x.listChild,
			typeId: x.loaiId,
			typeName: x.loaiTen,
			isCombo: x.isCombo,
			dongiakm: null,
			discount: x.tyLeKhuyenMai,
        };
    }
	//#endregion

    onUpdateConsultant = (newData) => {
        let listSelectedServices = this.state.lstChiDinh;
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });
        this.setState({dataOrder: listSelectedServices })
    }

    render() {
        let { selectedAppointment, inEditmode, selectedPatient, selectedDate, lstChiDinh } = this.state;
        const regiterServiceColumn = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: '8%',
                render: (text, record, index) => {
                    return (record.isChild ? "" : (Number(index) + 1));
                },
            },
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
                width: '10%'
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: '40%'
            },
            {
                title: 
                    <div>
                        <span className={this.props.consultantRequired && "required-field"}>NV tư vấn</span>
                        {inEditmode === true &&
                        <Tooltip title='Cập nhật nhân viên tư vấn'>
                            <Button type='link' style={{ color: 'white' }}
                                className='pl-2'
                                onClick={() => { this.setState({ modalSelectConsultant: true }) }}
                            >
                                <i className='fa fa-pen' />
                            </Button>
                        </Tooltip>
                        }
                    </div>,
                dataIndex: 'consultantName',
                key: 'consultantName',
                width: 130,
                render: (text, record, index) => {
                    return record.consultantName
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 120,
                render: (value, row) => {
                    return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
                },
            },
            {
                title: 'Số lượng',
                render: (row) => (
                    row.isChild ? "" : <span>{1}</span>
                ),
                align: 'center',
                width: '15%'
            },
            {
                title: 'Thành tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 100,
                render: (value, row) => {
                    return (
                        row.isChild ? "" : 
                        (<Tooltip title={row.dongia && row.dongia.length > 0 && "Thuộc chương trình tặng dịch vụ"}><span>{(value * ((100 - row.discount) / 100)).toLocaleString('en-US')}</span></Tooltip>)
                    )}
            },
            {
                title: '',
                align: 'center',
                width: '10%',
                render: (text, record, index) => { return (
                    record.isChild ? "" : (
                        <div>
                            {inEditmode === false ? <button disabled={inEditmode === false} className={`btn btn-danger`} onClick={() => {
                                this.XoaChiDinh(record, index)
                            }} >
                                <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                            </button> :
                                <Tooltip title={record.voucher && record.voucher.length > 0 && "Thuộc chương trình tặng dịch vụ"}>
                                    <button className={`btn btn-danger`} onClick={() => {
                                        this.XoaChiDinh(record, index)
                                    }} >
                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                    </button>
                                </Tooltip>
                            }
                        </div>
                    )
                )}
            }
        ];
        const columnsSearh = [
            {
                title: 'Mã Dịch vụ',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 70
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: 300,
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
                width: 70,
                format: 'number',
            },
        ]
        let dataSource = lstChiDinh.map(function (e, index) {
            let o = Object.assign({}, e);
            // o.key = index;
            o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString()+'-') : "") + o.id.toString();
            o.children = o.listChild;
            return o;
        })

        return (
            <>
                <div style={{ height: '100%' }}>
                    <div className='containerChilderWrapper'>
                        <HeaderModal title="Lịch hẹn" />
                        <div style={{ padding: 10, flex: 1, marginBottom: 10, backgroundColor: '#fff' }}>
                            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item label="Xem lịch:" style={{ display: 'none' }}>
                                    <Select style={{ width: 350, marginLeft: 35 }}
                                        hidden={(this.state.groupID === 1 || this.state.groupID === 2) ? false : true}
                                        placeholder="Chọn bác sĩ"
                                        defaultValue="0"
                                        onChange={(e) => { this.setState({ mabs: e }); this.loadDanhSachLichHen(e, this.state.create) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    ><Option key="" value="0">-----Chọn tất cả-----</Option>
                                        {this.state.lstDoctor.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <div className="col-auto">
                                    <Select
                                        style={{ width: '300px' }}
                                        onChange={(e) => { this.setState({ create: e }); this.loadDanhSachLichHen(this.state.mabs, e) }}
                                        showSearch
                                        defaultValue=""
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    ><Option key="" value="">-----Chọn người tạo-----</Option>
                                        {this.state.lstCreate.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-auto">
                                    <Button type="primary" className="btn btn-color"
                                        onClick={() => this.showModal()}>
                                        <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo lịch hẹn</Button>
                                </div>
                                <div className="col-auto">
                                    <Tooltip title="Tải lại trang">
                                        <Button className="btn btn-secondary"
                                            onClick={() => this.loadDanhSachLichHen(this.state.mabs, this.state.create)}>
                                            <i className="fa fa-sync" /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 130px)', backgroundColor: '#fff', }}>
                            <div style={{ height: '100%' }}>
                                <Calendar
                                    selectable
                                    localizer={localizer}
                                    components={
                                        {
                                            toolbar: CalendarToolbar,
                                            event: (e) => {
                                                return (
                                                    <div onClick={() => this.onSelectEvent(e.event)}>
                                                        <div>{e.event?.title}</div>
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                    onSelectEvent={this.onSelectEvent}
                                    events={this.state.lstEvent}
                                    startAccessor="start"
                                    endAccessor="end"
                                    eventPropGetter={(this.eventStyleGetter)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.visible && <Modal maskClosable={false}
                    width={1600}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    okButtonProps={{ disabled: true, hidden: true }}
                    cancelButtonProps={{ style: { display: 'none' } }}>
                    <TaoLichHen
                        onCancel={this.handleCancel}
                        layMau={false}
                        chinhanh={this.props.siteid}
                    />
                </Modal>}
                {this.state.editAppointmentModalFlag &&
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.editAppointmentModalFlag}
                        onCancel={() => this.setState({ editAppointmentModalFlag: false, inEditmode: false }, () =>
                            this.resetStateNguonKhachHang()
                        )}
                        okButtonProps={{ disabled: true, hidden: true }}
                        cancelButtonProps={{ style: { display: 'none' } }}>
                        <div>
                            <HeaderModal
                                title="Chi tiết lịch hẹn"
                                onClose={() => {
                                    this.setState({
                                        editAppointmentModalFlag: false,
                                        selectedAppointment: {},
                                        selectedPatient: {},
                                        inEditmode: false
                                    })
                                }} />
                            <div className="row">
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Tên bệnh nhân:</span><b>{selectedAppointment.tenbn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Mã bệnh nhân:</span><b>{selectedAppointment.mabn}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Ngày sinh:</span><b>{selectedPatient.onlyyear ? "Không ghi nhận" : moment(selectedPatient.ngaysinh).format("DD-MM")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Năm sinh:</span><b>{moment(selectedPatient.ngaysinh).format("YYYY")}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Giới tính:</span><b>{selectedPatient.gioitinh === 0 ? "Nam" : "Nữ"}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>CMND:</span> <b>{selectedPatient.cmnd}</b></div>
                                <div className="col-12 d-flex mb-2"><span style={{ width: '100px' }}>Địa chỉ:</span><b>{selectedPatient.diachi}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Điện thoại:</span><b>{selectedPatient.dienthoai}</b></div>
                                <div className="col-md-6 d-flex mb-2"><span style={{ width: '100px' }}>Thời gian:</span>
                                    <DatePicker locale={viVN} style={{ width: '200px' }} allowClear={false} showTime format={"DD-MM-YYYY HH:mm"} onChange={(e) => { this.setState({ selectedDate: e }) }} value={moment(selectedDate)}
                                        disabledDate={this.disabledDate} disabled={inEditmode === false} placeholder="dd-MM-YYYY" />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <Nguonkhachhang ref={(c) => this.nguonKhachHang = c} managermentId={selectedPatient.maql} updateInfoBangGia={(info) => { this.checkBangGia(info) }} banggiaSite={this.state.banggia} />
                            </div>
                            <div className='row'>
                                <div className="col-12">
                                    <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                                    <Select
                                        placeholder="Chọn chi nhánh"
                                        disabled={inEditmode === false}
                                        style={{ width: '100%' }}
                                        value={this.state.site}
                                        onChange={(value => this.setState({ site: value }))}
                                    >
                                        {this.state.listSite && this.state.listSite.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.ten}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                {selectedPatient.ghichu && <div className="col-12 d-flex my-2"><span style={{ width: '100px' }}>Ghi chú:</span><b>{selectedPatient.ghichu}</b></div>}
                                {!isNullOrEmpty(selectedPatient.ghichuHotline) && <div className="col-12 d-flex">
                                    <label>Ghi chú hotline:</label>
                                    <TextArea rows={3} value={selectedPatient.ghichuHotline} readOnly={true}></TextArea>
                                </div>}
                            </div>
                            <div className='row mt-2'>
                            <div className="col-12 d-flex" style={{ display: 'flex', alignItems: "center", marginTop: 10 }}>
                                <div style={{ width: "40%"  }}>
                                    <Tooltip placement="top" title={`Bảng giá: ${this.state.banggia === 'default' ? "Mặc định" : this.state.banggia}`}>
                                        <Form.Item label="">
                                            {/* <AutocompleteTable
                                                ref={(c) => (this.listServicesRef = c)}
                                                width="300px"
                                                disabled={inEditmode === false}
                                                placeholder="Nhập tên dịch vụ để tìm kiếm"
                                                tablewidth={500}
                                                listHeight={"50vh"}
                                                onSearch={(value) => this.searchIndication(value)}
                                                onSelect={(item) => this.onSelectIndication(item)}
                                                value={this.state.serKey} headers={columnsSearh}
                                                keyValue={'id'}
                                            >
                                            </AutocompleteTable> */}
                                            <SelectProductAutocomplete 
                                                // ref={_selectProductRef}
                                                ref={(c) => (this.selectProductAutocompleteRef = c)}
                                                disabled={inEditmode === false}
                                                showBangGia={true}
                                                bangGia={this.state.banggia}
                                                selectedListServices={[
                                                ...(this.state.lstChiDinh ?? []),
                                                ]}
                                                width='100%'
                                                dropdownMatchSelectWidth={700}
                                                onSelect={(items) => {
                                                    this.onSelectIndications(items);
                                                }}
                                                value={this.state.serKey}
                                                // headers={columnsSearhProducts}
                                                keyValue={'id'}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </div>
                                <Form.Item label="">
                                    <Tooltip title="Mở danh sách dịch vụ">
                                        <Button style={{ marginLeft: 10, borderRadius: 8 }}
                                            disabled={inEditmode === false}
                                            onClick={() => {
                                                this.setState({
                                                    visibleCLS: true
                                                }, () => {
                                                    this.modalCLS.UpdateSelectedFromProps(this.state.lstChiDinh)
                                                })
                                            }}> <i className={"fas fa-list"} /></Button>
                                    </Tooltip>
                                </Form.Item>
                            </div>
                            </div>

                            <div className="mt-2" style={{ height: 'calc(100% - 500px)' }} >
                                <Table dataSource={dataSource}
                                    rowKey={(e) => e.id}
                                    columns={regiterServiceColumn}
                                    pagination={false}
                                    scroll={{ y: 0 }}
                                />
                            </div>
                            <div className='custom-hr'></div>
                            <ConsultantWarning visible={this.state.lstChiDinh.length > 0 && this.state.lstChiDinh.some(x => isNullOrEmpty(x.consultantId))} />
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                {(this.state.arrived !== 1 && !inEditmode) &&
                                    <Popconfirm
                                        title="Xác nhận bệnh nhân đến khám!"
                                        onConfirm={() => { this.ConfirmArrived() }}
                                        okText="Đồng ý" cancelText="Hủy"
                                        disabled={inEditmode}>
                                        <button className="btn btn-color" style={{ marginRight: 10 }}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Xác nhận đến</button>
                                    </Popconfirm>
                                }
                                {this.state.arrived !== 1 && <div>{inEditmode === false ?

                                    <button className="btn btn-color" onClick={() => this.setState({ inEditmode: true })}><i style={{ marginRight: 5 }} className="fa fa-edit"></i>Cập nhật</button>
                                    :
                                    <button className="btn btn-color" onClick={() => this.updateAppointment()}><i style={{ marginRight: 5 }} className="fa fa-save"></i>Lưu</button>
                                }
                                </div>}
                                {this.state.arrived !== 1 && <Popconfirm
                                    title="Bạn có chắc muốn xóa lịch hẹn này không ??"
                                    onConfirm={() => { this.deleteAppointment() }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    >
                                        <i style={{ marginRight: 5 }} className="fa fa-trash" />Xóa</button>
                                </Popconfirm>
                                }
                                <button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => this.setState({
                                    editAppointmentModalFlag: false, selectedAppointment: {}, selectedPatient: {}, inEditmode: false
                                }, () => this.resetStateNguonKhachHang())}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>

                            </div>
                        </div>
                    </Modal>
                }
                {this.state.visibleCLS &&
                    <Modal maskClosable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        visible={this.state.visibleCLS}
                        width={1500}
                    >
                        <ModalCLS
                            hidePhongKham={true}
                            maskClosable={false}
                            ref={(c) => this.modalCLS = c}
                            editAfterRegister={true}
                            isnew={true}
                            patientID={this.state.patientID}
                            getListChiDinh={this.getListChiDinh.bind(this)}
                            onClose={(lstChiDinh) => {
                                lstChiDinh = lstChiDinh.map(x => ({ ...x }))
                                if (lstChiDinh === null) lstChiDinh = [];
                                let total = 0;
                                this.state.lstChiDinh.concat(lstChiDinh).map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
                                this.setState({ visibleCLS: false, lstChiDinh, total: total })
                            }}
                            banggia={this.state.banggia}
                            onReplaceBangGia={(tenBangGia) => {
                                if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                    this.setState({
                                        banggia: tenBangGia,
                                    });
                                    // this.listServicesRef.handleSearch(this.searchIndication());
                                }
                            }}
                            disableSelectBangGia={!this.props.allowToChangeServicePriceList}
                        />
                    </Modal>
                }
                <Modal
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={this.state.modalSelectConsultant}
                    destroyOnClose={true}
                    width={1000}>
                    <SelectConsultant
						isDialog={true}
                        dataSource={this.state.lstChiDinh.map(x => ({ ...x }))}
                        onClose={() => { this.setState({ modalSelectConsultant: false }) }}
                        onSubmit={(values) => {
                            // this.setState({ listSelectedServices: values })
                            this.onUpdateConsultant(values);
                            this.setState({ modalSelectConsultant: false })
                        }}
                    />
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
		coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
		allowToChangeServicePriceList: state.global.sysConfig.allowToChangeServicePriceList ? state.global.sysConfig.allowToChangeServicePriceList : false,
        consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LichHen);