import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Input, Button, List, notification, Popconfirm, Modal, Select, Drawer, Popover } from 'antd';
import * as Actions from '../../libs/actions';
import MitaDisplayLongText from '../../components/MitaComponents/mitaDisplayLongText';
import * as FeatureService from '../../services/FeatureService';
import Notify, { NotifyStatus } from '../../components/notify';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import FeatureDetail from './detail';
import ViewImages from './viewImages';

const { Option } = Select;
export default forwardRef((props, ref) => {
  //niếm cục bộ
  const showMoreText = '...xem thêm';
  const showLessText = ' rút gọn';
  //ref
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [firstRender, setFirstRender] = useState(true); 
  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(200);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [keyword, setKeyword] = useState('');
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //row options
  const [visibleDetail, setVisibleDetail] = useState(false); // set visible modal create/update/detail
  const [visibleViewImages, setVisibleViewImages] = useState(false); // set visible modal create/update/detail
  const [selectedId, setSelectedId] = useState(); // set visible modal create/update/detail
  //table
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender) return;
    search();
  }, [skipCount, sorting])

  useEffect(() => {
    setFirstRender(false);
    search();
  }, []);
  //#endregion 
  //#region search & filter
  const search = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      isActives: []//khi nào có nhu cầu filter theo cái này thì thêm vào
    }
    FeatureService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        if (skipCount == 0)
          setListResult(result.data.items);
        else {
          setListResult([...listResult, ...result.data.items]);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (skipCount != 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  //#endregion 
  //#region table info & event
  const loadDetail = (row, detailScrMode) => {
    if (row) setSelectedId(row.id);
    setDetailScreenMode(detailScrMode);
    setVisibleDetail(true);
  }

  const onDeleteClickHandle = (id) => {
    Actions.setLoading(true);
    FeatureService.deleteById(id).then(result => {
      if (result.isSuccess) {
        resetToFirstPage();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  };

  const onToggleActiveClickHandle = (id, isActive) => {
    Actions.setLoading(true);
    let data = {
      'id': id,
      'isActive': isActive,
    }
    FeatureService.toggleActive(data).then(result => {
      if (result.isSuccess) {
        let tList = listResult;
        let index = tList.findIndex(x => x.id === id);
        tList[index].isActive = isActive;
        setListResult(tList);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  };
  
  //#region modal create & update
  const handleCancel = (reload = false) => {
    setVisibleDetail(false);
    if (reload) resetToFirstPage();
  };
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Danh sách phân hệ - tính năng</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">
                  <div className='col-md-6 d-flex justify-content-start align-items-start'>
                      <Button className="pl-0"
                        type="link"
                        hidden={props.isDialog}
                        onClick={() => {
                          loadDetail(null, detailScreenModeEnum.create);
                        }}
                      ><i className="fa fa-plus-circle mr-1" />Tạo mới</Button>
                  </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex">
                  <Input
                    placeholder="Nhập mã, tên để tìm kiếm"
                    prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                    value={keyword} 
                    onChange={(e) => { setKeyword(e.target.value); }}
                    onKeyUp={(e) => { enterToSearch(e); }}
                  ></Input>
                </div>
              </div>
            </div>
                <div className="list-page-table mt-2">{/* list */}
                  <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                    bordered={false}
                    dataSource={listResult}
                    renderItem={(record) => {
                      let className = "p-1 border-2 border-bottom py-3";
                      return (
                        <List.Item key={record.id}
                          className={className}
                        >
                          <div className="d-flex flex-column w-100">
                            <div className="d-flex justify-content-between">
                              <div className="">
                                <b>{record.name}</b>
                              </div>
                              <div>
                                <Button type="link" shape="circle" style={{ height: 'min-content', paddingLeft: '0.5rem', minWidth: 0 }}
                                  onClick={() => { loadDetail(record, detailScreenModeEnum.update); }}
                                ><i className="fas fa-pencil-alt"></i></Button>
                                <Popconfirm
                                  title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{record.promotionName}</b>?</span>)}}
                                  onConfirm={() => onDeleteClickHandle(record.id)}
                                  onCancel={() => {}}
                                  okText="Đồng ý"
                                  cancelText="Hủy"
                                >
                                  <Button hidden={!record.isTest} type="link" shape="circle" style={{ height: 'min-content', paddingLeft: '0.5rem', minWidth: 0 }}
                                    onClick={() => {}}
                                  ><i className="fa fa-trash-alt"></i></Button>
                                </Popconfirm>
                                <Popover
                                  content={
                                    <span>
                                      Đây là dữ liệu được chạy thực tế.<br/>
                                      Không được xóa và hạn chế sửa (khi test) những dữ liệu này.
                                    </span>
                                  }
                                  trigger="click"
                                >
                                  <Button hidden={record.isTest} type="link" shape="circle" style={{ height: 'min-content', paddingLeft: '0.5rem', minWidth: 0 }}
                                  ><i className="far fa-question-circle"></i></Button>
                                </Popover>
                                <label className={record.isActive ? "text-blue" : "text-red"} style={{ paddingLeft: '0.5rem' }}>{record.isActive ? "Đang áp dụng" : "Chưa áp dụng"}</label>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <MitaDisplayLongText
                                content={record.description}
                                maxLength={256} // mặc định 64
                                showMoreText={showMoreText}
                                showLessText={showLessText}
                                // displayMode="popup" //mặc định inner
                              />
                            </div>
                            <div className="d-flex justify-content-end">
                              <button
                                className="btn btn-light"
                                style={{ border: '1px solid rgb(217, 217, 217)' }}
                                onClick={() => { setSelectedId(record.id); setVisibleViewImages(true); }}
                              >
                                Xem ảnh minh họa
                              </button>
                              <Popconfirm
                                title={() => {return (<span>Bạn chắc chắn muốn thực hiện thao tác này?</span>)}}
                                onConfirm={() => { onToggleActiveClickHandle(record.id, !record.isActive); }}
                                onCancel={() => {}}
                                okText="Đồng ý"
                                cancelText="Hủy"
                              >
                                <button
                                  className={record.isActive ? "btn btn-danger" : "btn btn-color"}
                                  style={{ marginLeft: '0.5rem' }}
                                >
                                  {record.isActive ? "Không áp dụng" : "Áp dụng"}
                                </button>
                              </Popconfirm>
                            </div>
                          </div>
                        </List.Item>
                    )}}
                  >
                    <List.Item key="showMore">
                      <div className="w-100 d-flex justify-content-center">
                        <Button className="pl-0"
                          type="link"
                          hidden={totalRecord <= listResult.length}
                          onClick={() => {
                            setSkipCount(skipCount + maxResultCount);
                          }}
                        >... Xem thêm ...</Button>
                      </div>
                    </List.Item>
                  </List>
                </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
      <Drawer className='custom-ant-drawer'
        maskClosable={false}
        closable={false}
        width={700}
        placement="right"
        // onClose={() => handleCancel()}
        visible={visibleDetail}
        destroyOnClose={true}
      >
        <FeatureDetail ref={detailRef}
          isDialog={ true } 
          featureId={selectedId}
          detailScreenMode = {detailScreenMode}
          onCancel={(reload = false) => {handleCancel(reload)}}
        />
      </Drawer>
      <Modal 
        maskClosable={true}
        width={700}
        visible={visibleViewImages}
        onCancel={() => setVisibleViewImages(false)}
        destroyOnClose={true}
      >
      <ViewImages
        isDialog={ true } 
        featureId={selectedId}
        // detailScreenMode = {detailScreenMode}
        onCancel={() => {setVisibleViewImages(false)}}
      />
      </Modal>
    </React.Fragment>
  );
})