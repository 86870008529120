import { Button, Form, Modal, notification, Select, Table, Tooltip, Popover, List } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../libs/actions';
import * as ChiDinhService from '../../services/chiDinhService';
import * as CommonService from '../../services/commonService';
import * as PackgeService from '../../services/packageService';
import * as ConfigService from '../../services/configService';
import * as PhongKhamService from '../../services/PhongKhamService';
import ModalPackage from '../../components/MDCLS/modalPackage';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import SelectRoom from './selectRoom';
import SelectRoomMulti from './selectRoomMulti';
import SelectConsultant from './selectConsultant';
import { COMBO } from '../../configureHIS/common/constants';
import { NotifyUpdateServiceModified } from '../../screens/recept/register/notifyCheck';
import { connect } from 'react-redux';
import { isNullOrEmpty } from '../../libs/util';
import ConsultantWarning from '../../screens/recept/register/consultantWarning';
const { Option } = Select;

//todo left side  khác right side 
var task = null;
let timeout = null;
class modalCLS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            soluong: 1,
            loaiChiDinh: '',
            lstChiDinh: [],
            chiDinh: '',
            department: '',
            cardNumber: '',
            departmentName: '',
            subjectName: '',
            selectedType: 0,
            selectedServiceID: '',
            listServices: [],
            listSelectedServices: [],
            searchName: '',
            tongtienNew: 0,
            selectedItem: {},
            selectedGroup: 3,
            listTypes: [],
            listGroups: [],
            lstRooms: [],
            lstChiDinhBenhNhan: [],
            selectedRoom: null,
            checkIcon: null,
            selectedRowKeys: [],
            RowRecord: [],
            hideType: false,
            lstBangGia: [],
            banggia: "default",
            bangGiaDefaultSite: "default",
            disableSelectBangGia: this.props.disableSelectBangGia ? this.props.disableSelectBangGia : false,
            currentPage: 1,
            pageSize: 80,
            selecttemp : -1,
            selectedDetail: {},
            expandedRowSelectedKeys: [],
        }

    }

    notifyUpdate(data) {
        if (data.action == "UpdateQuotation") {
			NotifyUpdateServiceModified(data, this.state.banggia, this.state.listSelectedServices);
		}
	}

    reset() {
        this.setState({
            soluong: 1,
            loaiChiDinh: '',
            lstChiDinh: [],
            chiDinh: '',
            department: '',
            cardNumber: '',
            departmentName: '',
            subjectName: '',
            selectedGroup: 0,
            selectedServiceID: '',
            listServices: [],
            listSelectedServices: [],
            searchName: '',
            tongtienNew: 0,
            selectedRowKeys: [],
            listTypes: [],
            listGroups: [],
            chooseRoomFLag: false,
            expandedRowSelectedKeys: []
        }, this.getAllTypeServices());
    }
    getListChiDinh() {
        return this.state.listSelectedServices;
    }

    UpdateSelectedFromProps(data) {
        this.setState({ listSelectedServices: data }, () => {
            let newdata = this.state.listServices.map(x => ({
                ...x,
                selected: data.filter(o => o.id.toString() === x.id.toString()).length > 0
            }))
            this.setState({
                listServices: newdata,
            });
        })
        let totalPrice = 0;
        for (let item of data) {
            totalPrice += (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia) * item.soluong;
        }
        this.setState({ tongtienNew: totalPrice })
    }

    handleCancel = e => {
        if (this.props.setVisibleCLS)
            this.props.setVisibleCLS(false);
    };

    componentDidMount() {
        this.DanhSachPKBenh();
        this.GetListBangGia();
        ConfigService.BangGiaDefaultSite(this.props.chinhanh).then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    banggia: this.props.banggia !== undefined && this.props.banggia !== "" ? this.props.banggia : respone.item ? respone.item : 'default',
                    bangGiaDefaultSite: respone.item ? respone.item : 'default',
                }, () => {
                    this.getAllTypeServices(true);
                });
            }
            else
                this.getAllTypeServices(true);
        }).catch(err => {
            this.getAllTypeServices(true);
        });
    }

    componentWillUnmount() {
        if (task)
            clearInterval(task)
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.hideType !== this.props.hideType) {
            this.setState({ hideType: nextProps.hideType });
        }
        if (nextProps.banggia !== this.props.banggia) {
            this.setState({ banggia: nextProps.banggia }, () => this.search());
            // setTimeout(() => {
            //     this.search()
            // },100)
        }
        // if (nextProps.banggia !== this.state.banggia) {
        //     this.setState({ banggia: nextProps.banggia }, () => this.search());
        //     // setTimeout(() => {
        //     //     this.search()
        //     // },100)
        // }
        return true;
    }

    ChangeBangGia(banggia){
        this.setState({banggia : banggia}, () => this.search())
    }

    GetServicesHaveBeenSelected(reloadServices = false) {
        if (this.props.noitru === true) {
            let dateIn = this.props.dateIn ? moment(this.props.dateIn).format("YYYY/MM/DD HH:mm") : moment().format("YYYY/MM/DD HH:mm");
            ChiDinhService.DanhSachChiDinhBenhNhan(this.props.patientID, this.props.managementID,
                '', dateIn, false, 0, 0, true, this.props.idbenhtrinh).then(result => {
                    if (result.isSuccess) {
                        let data = result.data;
                        let listSelectedServices = [];
                        let { listServices } = this.state;
                        let tongtienNew = 0;
                        for (let item of data) {
                            let child = {};
                            child.ngay = item.ngay
                            child.maql = item.maql;
                            child.mabn = item.mabn;
                            child.status = item.paid === false ? 'Chưa đóng tiền' : 'Đã đóng tiền';
                            child.type = item.type ? item.type : "2";//2:chỉ định, 1: gói khám
                            child.id = item.id;
                            child.mavp = item.mavp;
                            child.ten = item.ten;
                            child.idChiDinh = item.idChiDinh;
                            if (child.isCombo) {
                                child.listChild = child.listChildCd; // phải set lại như này vì BE đang dùng ChiDinh (cho api này) và ThongTinDichVu (cho api searchService) mà ChiDinh kết thừa ThongTinDichVu, mà ThongTinDichVu có listChild rồi
                            }
                            listSelectedServices.push(child);
                            tongtienNew += (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia) * item.soluong;
                            let itemServices = listServices.filter(x => x.id.toString() === child.id.toString());
                            if (itemServices.length > 0) {
                                let itemService = itemServices[0];
                                itemService.selected = true;
                            }
                        }
                        if (data.length > 0 && data.find(x => x.banggia !== '')) {
                            this.setState({
                                banggia: data.find(x => x.banggia !== null || x.banggia !== undefined).banggia,
                                disableSelectBangGia: true
                            })
                            task = setTimeout(() => {
                                this.search();
                            }, 100);
                        } else {
                            this.setState({
                                banggia: this.props.banggia !== undefined && this.props.banggia !== "" ? this.props.banggia : this.state.bangGiaDefaultSite ? this.state.bangGiaDefaultSite : "default",
                                disableSelectBangGia: this.props.disableSelectBangGia ? this.props.disableSelectBangGia : false,
                            })
                        }
                        this.setState({ listSelectedServices, tongtienNew, listServices }, () => {
                            if (reloadServices === true) {
                                this.search();
                            }
                        })
                    }
                }).catch(err => {
                    notification.warning({ message: 'Tải dữ liệu chỉ định của bệnh nhân lỗi' })
                })
        }
        else {
            if (this.props.managementID === "" || this.props.managementID === null || this.props.managementID === undefined)
                return;
            let dateIn = this.props.dateIn ? moment(this.props.dateIn).format("YYYY/MM/DD HH:mm") : moment().format("YYYY/MM/DD HH:mm");
            CommonService.GetServicesHaveBeenSelected(this.props.managementID, dateIn).then((respone) => {

                if (respone.isSuccess) {
                    let data = respone.data;
                    let listSelectedServices = [];
                    let tongtienNew = 0;

                    let { listServices } = this.state;
                    for (let item of data) {
                        if (item.isCombo) {
                            item.listChild = item.listChildCd; // phải set lại như này vì BE đang dùng ChiDinh (cho api này) và ThongTinDichVu (cho api searchService) mà ChiDinh kết thừa ThongTinDichVu, mà ThongTinDichVu có listChild rồi
                        }
                        listSelectedServices.push(item)
                        tongtienNew += item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia);
                        let itemServices = listServices.filter(x => x.id.toString() === item.id.toString());
                        if (itemServices.length > 0) {
                            let itemService = itemServices[0];
                            itemService.selected = true;
                        }
                    }
                    if (data.length > 0 && data.find(x => x.banggia !== '')) {
                        this.setState({
                            banggia: data.find(x => x.banggia !== null || x.banggia !== undefined).banggia,
                            disableSelectBangGia: true,
                            listSelectedServices, tongtienNew, listServices
                        }, () => {
                            this.search();
                        })
                    } else {
                        this.setState({
                            banggia: this.props.banggia !== undefined && this.props.banggia !== "" ? this.props.banggia : this.state.bangGiaDefaultSite ? this.state.bangGiaDefaultSite : "default",
                            disableSelectBangGia: this.props.disableSelectBangGia ? this.props.disableSelectBangGia : false,
                            listSelectedServices, tongtienNew, listServices
                        }, () => {
                            if (reloadServices === true) {
                                this.search();
                            }
                        })
                    }
                }
            })
        }
    }

    getAllTypeServices(first = false) {
        ChiDinhService.getAllTypeService().then((respone) => {
            if (respone.isSuccess) {
                if (this.props.editAfterRegister !== true)
                    respone.data = respone.data.filter(function (obj) {
                        return obj.value.toString() !== '8';
                    });
                let listGrs = respone.data;
                if (this.props.showCombo == false) listGrs = respone.data.filter(x => x.value != COMBO);
                if (this.props.noitru === true) {
                    this.setState({
                        listGroups: listGrs,
                        selectedGroup: this.props.type,
                        selectedType: 0,
                    }, () => { this.search(first) });
                } else {
                    this.setState({
                        listGroups: listGrs,
                        selectedGroup: listGrs.length > 0 ? listGrs[0].value : this.state.selectedGroup,
                        selectedType: listGrs.length > 0 ? listGrs[0].type : this.state.selectedType,
                    }, () => { this.search(first) });
                }
            }
        })
    }

    loadTypes(type) {
        ChiDinhService.getGroups(type).then((respone) => {
            if (respone.isSuccess) {
                if (respone.data.length > 0) {
                    this.setState({
                        listTypes: respone.data,
                        selectedType: respone.data[0].value,
                    });
                    setTimeout(() => {
                        ChiDinhService.searchSerivce(this.state.selectedGroup, respone.data[0].value, '').then(result => {
                            if (result.isSuccess) {
                                let data = result.data;
                                this.setState({
                                    listServices: data,
                                    selectedServiceID: '',
                                });
                            }
                            Actions.setLoading(false);
                        }).catch(err => {
                            Actions.setLoading(false);
                            Actions.openMessageDialog('Error 1', err)
                        })
                    }, 100)
                }
            }
        })
    }

    XoaChiDinh(record, index) {
        if( (record.done && record.done === 1) || (record.status_kham && record.status_kham !== "") ){
            notification.warning({ message: "Dịch vụ đã thực hiện. Không thể xóa !" })
            return
        }
        let { listServices } = this.state
        let item = listServices.filter(x => x.id.toString() === record.id.toString())[0];
        if (item) {
            if (item.selected === true) {
                item.selected = false;
            } else {
                item.selected = true;
            }
        }
        let listIdToRemove = [record.id];
        if (record.isParent) {
            listIdToRemove = listIdToRemove.concat(this.state.listSelectedServices.filter(x => x.isParent === false).map(x => (x.id)));
        }

        let expandedRowSelectedKeys = this.state.expandedRowSelectedKeys;
        let key = record.key ?? (record.isChild ? (record.vienPhiIdOfCombo.toString()+'-') : "") + record.id.toString();
        expandedRowSelectedKeys = expandedRowSelectedKeys.filter(x=>x != key);

        let lst = this.state.listSelectedServices.filter(x => !listIdToRemove.includes(x.id));
        let tongtienNew = 0;
        for (let item of lst) {
            if (item.dongia)
                tongtienNew += (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia) * item.soluong;
        }
        this.setState({
            tongtienNew,
            listSelectedServices: lst.map(d => ({ ...d })),
            expandedRowSelectedKeys: expandedRowSelectedKeys
        })
        if (record.idChiDinh !== '0' && record.idChiDinh !== undefined && this.props.deleteFunc !== undefined) {
            this.props.deleteFunc(record);
        }
        if (listIdToRemove.length > 1)
            Actions.openMessageDialog("Thông báo", "Đã hủy bỏ các dịch vụ liên quan trong chương trình loyalty");
    }

    editAmount(row, soluong) {
        let { listSelectedServices } = this.state
        let index = listSelectedServices.findIndex(record => record.id === row.id);
        listSelectedServices[index].soluong = soluong;
        let tongtienNew = 0;
        for (let item of listSelectedServices) {
            if (item.dongia)
                tongtienNew += item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia);
        }
        this.setState({ listSelectedServices, tongtienNew })
    }

    editPhongKham(row, dataPK) {
        let { listSelectedServices } = this.state
        let index = listSelectedServices.findIndex(record => record.id === row.id);
        listSelectedServices[index].makp = dataPK.makhoa;
        listSelectedServices[index].phongkham = dataPK.maphong;
        listSelectedServices[index].tenphong = dataPK.tenphong;
        this.setState({ listSelectedServices, modalSelectRoom: false })
    }

    addExamServiceWithoutRoom() {
        let { listServices, listSelectedServices, selectedServiceID, lstRooms, selectedRoom } = this.state
        let item = listServices.filter(record => record.id === selectedServiceID)[0];
        let phongkham = lstRooms.filter(record => record.value === selectedRoom)[0];
        item.ma = "0";
        item.soluong = 1;
        item.type = 2;
        if (phongkham) {
            item.makp = phongkham.departmentID
            item.maphongkham = phongkham.value
            item.phongkham = phongkham.label
        }
        if (item.selected === true) {
            item.selected = false;
        } else {
            item.selected = true;
        }
        listSelectedServices.push(item);
        let tongtienNew = 0;
        for (let item of listSelectedServices) {
            item.isNew = false;
            if (item.id === selectedServiceID) item.isNew = true;
            if (item.dongia)
                tongtienNew += item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia) * ((100 - item.discount) / 100);
        }
        this.setState({ tongtienNew, listSelectedServices, selectedServiceID: '', chooseRoomFLag: false, selectedRoom: null })
    }

	GetAllService() { //lấy all chỉ định (ra cùng cấp)
		let list = [];
		(this.state.listSelectedServices ?? []).forEach(item => {
			list.push(item);
			if (item.isCombo) {
                if (item.listChild && item.listChild.length > 0)
                    item.listChild.forEach(xChild => {
                        xChild.vienPhiTenOfCombo = item.ten;
                        list.push(xChild);
                    });
			}
		});
		return list;
	}

	GetRoomDefaultForService(item) {
		PhongKhamService.GetRoomDefault(item.makp, item.id).then((respone) => {
			if (respone.isSuccess) {
				item.phongkham = respone.item.makp || ""; // này là mã phòng
				item.maphong = respone.item.makp || ""; // này là mã phòng
				item.tenphong = respone.item.tenkp || "";
				item.makp = respone.item.makhoa || "";// này là mã khoa
				this.forceUpdate();
			}
		});
		return item;
	}

    AddService() {
        if (this.state.selectedItem.isChild) return;
        // listServices là list bên trái
        // listSelectedServices là list được chọn, nằm bên phải
        // let { listServices, listSelectedServices, selectedServiceID } = this.state;
        let { selectedServiceID } = this.state;
        let listServices = [...this.state.listServices];
        let listSelectedServices = [...this.state.listSelectedServices];
        
        let olditems = listSelectedServices.filter(record => record.id.toString() === selectedServiceID.toString());
        if (olditems.length > 0) {
            this.XoaChiDinh(olditems[0])
            return;
        }
        let itemService = listServices.filter(record => record.id.toString() === selectedServiceID.toString())[0];
        let listAll = this.GetAllService();
        let isUpdateList = true;
        let tongtienNew = 0;
        var existed = listAll.find((x) => x.id === itemService.id);
        if (existed === undefined) {
            let item = {...itemService};
            item.mavp = item.id.toString();
            item.soluong = 1;
            item.ma = "0";
            item.type = 2;
            item.banggia = this.state.banggia;
            if (item.groupId === 8) {
                this.GetRoomDefaultForService(item);
            }
            if (item.isCombo) {
                let selectedIds = [];
                for (let x of listSelectedServices) {
                    selectedIds.push(x.id);
                    (x.listChild ?? []).forEach(xChild => {
                        selectedIds.push(xChild.id);
                    });
                }
                item.listChild = [];
                itemService.listChild.forEach(xChild => {
                    // newVienPhiIds.push(xChild.id);
                    let itemChild = {...xChild};
                    if (selectedIds.indexOf(itemChild.id) > -1) {
                        isUpdateList = false;
                        notification.warning({ message: `Chỉ định [${itemChild.ten}] đã tồn tại.` });
                        return;
                    }
                    
                    itemChild.vienPhiIdOfCombo = item.id;
                    itemChild.isChild = true;
                    itemChild.mavp = itemChild.id.toString();
                    itemChild.soluong = 1;
                    itemChild.ma = "0";
                    itemChild.type = 2;
                    itemChild.banggia = this.state.banggia;
                    if (itemChild.groupId === 8) {
                        this.GetRoomDefaultForService(itemChild);
                    }
                    item.listChild.push(itemChild);
                });
            }
            // item.children = item.listChild;
            listSelectedServices.push(item);
            for (let item of listSelectedServices) {
                item.isNew = false;
                if (item.id === selectedServiceID) item.isNew = true;
                if (item.dongia)
                    tongtienNew += (item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia) * ((100 - item.discount) / 100));
            }
        }
        else {
            if (existed.isChild)
                notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại trong combo [${existed.vienPhiTenOfCombo}]` });
            else notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại` });
            isUpdateList = false;
            return;
        }
		if (isUpdateList) {
            // sao khi chọn thì set cái item bên phải là selected để có dấu tick
            if (itemService.selected === true) {
                itemService.selected = false;
            } else {
                itemService.selected = true;
            }
            this.setState({ tongtienNew, listSelectedServices: listSelectedServices, selectedServiceID: '', searchName: '' });
            this.forceUpdate();
        }
    }
    // AddService() {
    //     if (this.state.selectedItem.isChild) return;
    //     let { listServices, listSelectedServices, selectedServiceID } = this.state
    //     let item = listServices.filter(record => record.id.toString() === selectedServiceID.toString())[0];
    //     let olditems = listSelectedServices.filter(record => record.id.toString() === selectedServiceID.toString());
    //     if (olditems.length > 0) {
    //         this.XoaChiDinh(olditems[0])
    //         return;
    //     }
    //     // item.mavp = item.id.toString();
    //     PhongKhamService.GetRoomDefault(item.makp, item.id).then((respone) => {
    //         if (respone.isSuccess) {
    //             if (item.isCombo) {
    //                 let selectedIds = [];
    //                 for (let x of listSelectedServices) {
    //                     selectedIds.push(x.id);
    //                     (x.listChild ?? []).forEach(xChild => {
    //                         selectedIds.push(xChild.id);
    //                     });
    //                 }
    //                 for (let itemChild of item.listChild) {
    //                     if (selectedIds.indexOf(itemChild.id) > -1) {
    //                         notification.warning({ message: `Chỉ định [${itemChild.ten}] đã tồn tại.` });
    //                         return;
    //                     }
    //                     let res = this.getRoomDefault(itemChild.makp, itemChild.id);
    //                     if (res) {
    //                         itemChild.phongkham = res.makp || "";// này là mã phòng
    //                         itemChild.tenphong = res.tenkp || "";
    //                         itemChild.maphong = res.makp || "";// này là mã phòng
    //                         itemChild.makp = respone.item.makhoa || "";// này là mã khoa
    //                     }
    //                 }
    //             }
    //             item.soluong = 1;
    //             item.ma = "0";
    //             item.phongkham = respone.item.makp || "";
    //             item.tenphong = respone.item.tenkp || "";
    //             item.maphong = respone.item.makp || "";
    //             if (item.selected === true) {
    //                 item.selected = false;
    //             } else {
    //                 item.selected = true;
    //             }
    //             item.type = 2;
    //             item.banggia = this.state.banggia;
    //             // item.children = item.listChild;
    //             listSelectedServices.push(item);
    //             let tongtienNew = 0;
    //             for (let item of listSelectedServices) {
    //                 item.isNew = false;
    //                 if (item.id === selectedServiceID) item.isNew = true;
    //                 if (item.dongia)
    //                     tongtienNew += item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia);
    //             }
    //             this.setState({ tongtienNew, listSelectedServices, selectedServiceID: '', searchName: '' })
    //         }
    //         else {
    //             notification.error({ message: "Có lỗi xảy ra, vui lòng thử lại" })
    //         }
    //     }).catch(err => {
    //         notification.error({ message: err })
    //     })
    // }

    AddServiceOutSide(recordNew) {
        let { listServices, listSelectedServices } = this.state
        let item = listServices.filter(record => record.id.toString() === recordNew.id.toString())[0];
        let olditems = listSelectedServices.filter(record => record.id.toString() === recordNew.id.toString());
        if (olditems.length > 0) {
            this.XoaChiDinh(olditems[0])
            return;
        }
        item.soluong = 1;
        item.ma = "0";
        switch (item.groupId) {
            case 1:
                item.phongkham = "Phòng phẫu thuật thủ thuật";
                break;
            case 2:
                item.phongkham = "Phòng xét nghiệm";
                break;
            case 3:
                switch (item.typeId) {
                    case 4:
                        item.phongkham = "Phòng Thăm dò chức năng";
                        break;
                    case 1:
                        item.phongkham = "Phòng siêu âm";
                        break;
                    case 3:
                        item.phongkham = "Phòng X-QUANG";
                        break;
                    case 2:
                    case 32:
                        item.phongkham = "Phòng nội soi";
                        break;
                    case 17:
                        item.phongkham = "Phòng nội sản";
                        break;
                    default:
                }
                break;
            case 4:
                item.phongkham = "Phòng cấp cứu";
                break;
            case 5:
                item.phongkham = "Phòng đông y";
                break;
            default:
        }
        if (item.selected === true) {
            item.selected = false;
        } else {
            item.selected = true;
        }
        item.type = 2;
        item.banggia = this.state.banggia;
        listSelectedServices.push(item);
        let tongtienNew = 0;
        for (let item of listSelectedServices) {
            item.isNew = false;
            if (item.id === recordNew.id) item.isNew = true;
            if (item.dongia)
                tongtienNew += item.soluong * (item.dongiakm !== null && item.dongiakm !== undefined && item.dongiakm < item.dongia ? item.dongiakm : item.dongia);
        }
        this.setState({ tongtienNew, listSelectedServices, selectedServiceID: '' })
    }

    getRoomDefault(makp, id) {
        PhongKhamService.GetRoomDefault(makp, id).then((respone) => {
            if (respone.isSuccess) {
                return respone.item;
                // item.phongkham = respone.item.makp || "";
                // item.tenphong = respone.item.tenkp || "";
                // item.maphong = respone.item.makp || "";
                // if (item.selected === true) {
                //     item.selected = false;
                // } else {
                //     item.selected = true;
                // }
                // item.type = 2;
                // item.banggia = this.state.banggia;
            }
            else {
                return null;
                notification.error({ message: "Có lỗi xảy ra, vui lòng thử lại" })
            }
        }).catch(err => {
            return null;
            notification.error({ message: err })
        })
    }

    search(first = false) {
        let olddata = this.state.listSelectedServices;
        Actions.setLoading(true);
        ChiDinhService.searchSerivce(this.state.selectedGroup, this.state.selectedType,
            this.state.banggia, this.state.searchName, this.state.currentPage, this.state.pageSize).then(result => {
                if (result.isSuccess) {
                    let data = result.data.map(x => ({
                        ...x,
                        selected: olddata.filter(o => o.id.toString() === x.id.toString()).length > 0
                    }))
                    this.setState({
                        listServices: data,
                        selectedServiceID: '',
                    }, () => {
                        if (first === true) {
                            this.GetServicesHaveBeenSelected();
                        }
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog('Lỗi', err)
            }).finally(() => Actions.setLoading(false));
    }

    getListPackageItems(packageID, boolIsNew) {
        PackgeService.getPackageItems(this.props.patientID, packageID).then((respone) => {
            if (respone.isSuccess) {
                for (let item of respone.data) {
                    item.packageid = packageID;
                }
                this.setState({
                    listPackagesItem: respone.data
                });
            }
        })
    }

    DanhSachPKBenh() {
        ChiDinhService.DanhSachPKBenh().then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    lstRooms: respone.data,
                });
            }
        })
    }

    handleKeySearchDown(event) {
        let tableScroll = document.getElementById("tableScroll").firstChild.firstChild.nextElementSibling;
        let tableElement = tableScroll.firstChild.lastChild;
        let tableFirstElement = tableElement.firstChild;
        let tableNextElement = tableFirstElement.nextElementSibling;
        if (event.keyCode === 9) {        
            this._saveBtn.focus(); 
            event.preventDefault();
        }
        if (event.keyCode === 13 && this.state.listServices.length > 0) {
            this.setState({ selectedServiceID: this.state.listServices[this.state.selecttemp].id, selectedItem: this.state.listServices[this.state.selecttemp] },
                () => {
                    this.AddService();
                })
            event.preventDefault();
        }
        if (event.keyCode === 40) {         //down
            tableScroll.scrollTop === 0 ? tableScroll.scrollTop = tableFirstElement.offsetHeight : tableScroll.scrollTop += tableNextElement.offsetHeight;
            if(this.state.selecttemp < this.state.listServices.length - 1)
                this.setState({selecttemp : this.state.selecttemp + 1})
        }
        if (event.keyCode === 38) {         //up
            tableScroll.scrollTop -= tableNextElement.offsetHeight;
            if(this.state.selecttemp > -1)
            this.setState({selecttemp : this.state.selecttemp -1})  
        }
    }

    keyDownTable(event) {
        event.preventDefault();
    }

    focusSearchControl(){
        if(this.searchControl){
            setTimeout(()=>{
                this.searchControl.focus();
            },500)
        }
    }

    GetListBangGia = (key = "") => {
        VPService.GetListBangGia(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBangGia: result.data
                });
            }
        }).catch(err => {
        }).finally(() => {
            this.forceUpdate();
        })
    }

    onSearchBangGia = (key) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => {
            this.GetListBangGia(key);
        }, 300);
    }

    onUpdateConsultant = (newData) => {
        let listSelectedServices = this.state.listSelectedServices;
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });
        this.setState({listSelectedServices: listSelectedServices })
    }
    
    onPressSave = () => {
        let isNullConsultant = this.state.listSelectedServices.some(x => isNullOrEmpty(x.consultantId));
		if (this.props.consultantRequired && isNullConsultant) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
			return;
		}
        this.props.onClose(this.state.listSelectedServices, 'save');
        this.props.onReplaceBangGia && this.props.onReplaceBangGia(this.state.banggia);
    }

    render() {
        let { listServices, listSelectedServices, listGroups, selectedGroup, selectedType, lstBangGia } = this.state;
        const columns2 = [
            {
                title: '',
                align: 'center',
                width: 40,
                render: (row) => (
                    row.selected === true ? <i className="fa fa-check"></i> : ''
                )
            },
            {
                title: 'Mã',
                dataIndex: 'mavp',
                key: 'mavp',
                align: 'left',
                width: 50,
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 170,
                render: (value, row) => {
                    if (row.isCombo) {return (<>
                        <Popover trigger="hover"
                            content = {() => renderListChild(row)}
                        >
                            <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>
                        </Popover>
                        <span>{value}</span>
                    </>)
                    }
                    return (<span>{value}</span>)
                }
            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value) => (<span>{value.toLocaleString('en-US')}</span>),
            },
            {
                title: 'Loại',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'left',
                width: 150,
            },
        ];

        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                render: (text, record, index) => {
                    return (record.isChild ? "" : (Number(index) + 1));
                },
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'ten',
                key: 'ten',
                width: '40%',
            },
            ...(this.props.hideConsultant === true ? [] : [
                {
                    title: 
                        <div>
                            <span className={this.props.consultantRequired && "required-field"}>NV tư vấn</span>
                            <Tooltip title='Cập nhật nhân viên tư vấn'>
                                <Button type='link' style={{ color: 'white' }}
                                    className='pl-2'
                                    onClick={() => { this.setState({ modalSelectConsultant: true }) }}
                                >
                                    <i className='fa fa-pen' />
                                </Button>
                            </Tooltip>
                        </div>,
                    dataIndex: 'consultantName',
                    key: 'consultantName',
                    width: 130,
                    render: (text, record, index) => {
                        return record.consultantName
                    },
                },
            ]),
            ...(this.props.hidePhongKham === true ? [] : [
                {
                    title: 'Phòng',
                    width: 120,
                    render: (row) => (<>
                        {row.groupId === 8 &&
                            <span><i style={{ marginRight: 5 }} className='fa fa-sync'
                                onClick={() => {
                                    this.setState({ modalSelectRoom: true, selectedDetail: row })
                                }}
                            ></i> {row.tenphong} </span>
                        }
                    </>),
                }
            ]),
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 80,
                render: (value, row) => {
                    return (row.isChild ? "" : (
                        row.dongiakm !== null && row.dongiakm !== undefined && row.dongiakm < row.dongiagoc ?
                            <span style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "end",
                            }}><s>{row.dongiagoc.toLocaleString("en-US")}</s><b>&rarr;{row.dongiakm.toLocaleString("en-US")}</b></span>
                            : row.dongia.toLocaleString("en-US")
                        )
                )},
            },
            {
                title: <Tooltip title="Số lượng">SL</Tooltip>,
                align: 'center',
                width: 60,
                render: (value, row) => {
                    return (row.isChild ? "" : <span>{row.soluong}</span>)

                    // row.status === 'Đã đóng tiền' ? <span>{row.soluong}</span> :
                    //     <InputNumber style={{ width: '60px' }} value={row.soluong} min={1} max={99} maxLength={2} onChange={(e) => {
                    //         if (e === null) {
                    //             this.editAmount(row, 1)
                    //         } else
                    //             this.editAmount(row, e)
                    //     }} />
                },
            },
            {
                title: 'Thành tiền',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 90,
                render: (value, row) => {
                    return (
                        row.isChild ? "" : 
                        <Tooltip title={row.voucher && row.voucher.length > 0 && "Thuộc chương trình loyalty"}>
                            <span>{((row.dongiakm !== null && row.dongiakm !== undefined && row.dongiakm < row.dongia ? row.dongiakm : value) * row.soluong * ((100 - row.discount) / 100)).toLocaleString('en-US')}</span>
                        </Tooltip>
                )},
            },
            {
                title: '',
                align: 'center',
                width: '28px',
                render: (text, record, index) => {
                    return (record.isChild ? "" : 
                    (
                        <button disabled={record.status === 'Đã đóng tiền' || record.done === 1} className="btn btn-danger" 
                            style={{padding: '5px 5px 3px'}}
                            onClick={() => { this.XoaChiDinh(record, index) }} >
                            <Tooltip title={record.voucher && record.voucher.length > 0 && "Thuộc chương trình loyalty"}>
                                <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                            </Tooltip>
                        </button>
                    )
                )},
            }
        ];

        let dataSource = listServices.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = (o.isChild ? o.vienPhiIdOfCombo.toString()+'-' : "") + o.id.toString();
            // o.children = o.listChild;
            return o;
        })

        let dataSourceNew = listSelectedServices.map(function (e, index) {
            let o = Object.assign({}, e);
            // o.key = o.id;
            o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString()+'-') : "") + o.id.toString();
            o.children = o.listChild;
            return o;
        })

        function renderListChild(row) {
            return (
            <div className='list-page' style={{ minWidth: "80vh" }}>
                <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom:0 }}> {/* header */}
                    <h5>Danh sách dịch vụ con của [{row.ten}]</h5>
                    {/* <h3 hidden={!props.isDialog}
                        onClick={() => { props.onCancel() }}
                    ><i className='far fa-times-circle' /></h3> */}
                </div>
                <div className="list-page-body">{/* body */}
                    <div className="list-page-table">
                        <List className="none-border-bottom none-border-bottom-item"
                            bordered={false}
                            dataSource={row.listChild ?? []}
                            header={
                                <List.Item key={`header`}className='p-1'>
                                    <div className={`d-flex justify-content-between w-100 pl-2`}>{/* 1 item */}
                                        <div className="" style={{width: 'calc(70%)'}}>{/* column 1: tên - mã */}
                                            <span className={`mita-title`} style={{whiteSpace: "pre-line"}}>Tên dịch vụ</span>
                                        </div>
                                        <div className='' style={{width: '30%'}}>{/* column 2: nhóm/loại */}
                                            <span className='mita-title' style={{whiteSpace: "pre-line"}}>Loại</span>
                                        </div>
                                    </div>
                                </List.Item>
                            }
                            renderItem={(item) => {
                                let className = "p-1 border-2 border-top";
                                return (
                                    <List.Item key={`${row.id}-${item.id}`}
                                        className={className}
                                        // style={{backgroundColor:"#D8D8D8"}}
                                    >
                                        <div className={`d-flex justify-content-between w-100 pl-2`} key={`pr-${item.id}`}>{/* 1 item */}
                                            <div className="" style={{width: 'calc(70%)'}}>{/* column 1: tên - mã */}
                                                <span className={`mita-value`} style={{whiteSpace: "pre-line"}}>{item.ten} - {item.mavp}</span>
                                            </div>
                                            <div className='' style={{width: '30%'}}>{/* column 2: nhóm/loại */}
                                                <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.typeName}</span>
                                            </div>
                                        </div>
                                    </List.Item>
                                )
                            }}
                        >
                        </List>
                    </div>
                </div>
            </div>
            )
        }

        return (
            <React.Fragment>
                <div style={{ padding: '0px', height: 'calc(100% - 35px)' }}>
                    {this.state.hideType !== true &&
                        <div style={{ display: this.props.showType === true ? 'none' : 'flex', overflowX: 'auto' }}>
                            <div className="table-responsive" style={{ display: 'contents' }}>
                                {this.props.showGoiKham === true &&
                                    <Button
                                        style={{
                                            border: 'none', flex: 1,
                                            borderBottom: (-1 === selectedGroup) ? '4px solid lightgray' : '1px solid rgb(232,232,232)'
                                            , backgroundColor: 'rgb(250, 250, 250)',
                                            color: (-1 === selectedGroup) ? 'firebrick' : 'black'
                                            , textAlign: 'center', cursor: 'pointer', fontSize: '13px',
                                            fontWeight: (-1 === selectedGroup) ? 'bold' : 'normal'
                                            , width: '-webkit-fill-available', display: 'block', minHeight: '45px', textTransform: 'uppercase',

                                        }}
                                        onClick={() => {
                                            this.setState({
                                                selectedGroup: -1
                                            }, () => {
                                                if (this.state.patientID !== null && this.modalPackage)
                                                    this.modalPackage.getPackagePatient(this.props.patientID);
                                            })
                                        }}>
                                        Gói khám
                                    </Button>
                                }
                                {listGroups.map((item, index) => {
                                    return (
                                        <Button
                                            key={"group" + index}
                                            style={{
                                                border: 'none', flex: 1,
                                                borderBottom: (item.value === selectedGroup && selectedType === item.type) ? '4px solid lightgray' : '1px solid rgb(232,232,232)'
                                                , backgroundColor: 'rgb(250, 250, 250)',
                                                color: (item.value === selectedGroup && selectedType === item.type) ? 'firebrick' : 'black'
                                                , textAlign: 'center', cursor: 'pointer', fontSize: '13px',
                                                fontWeight: (item.value === selectedGroup && selectedType === item.type) ? 'bold' : 'normal'
                                                , width: '-webkit-fill-available', display: 'block', minHeight: '45px', textTransform: 'uppercase',

                                            }}
                                            id={item.label}
                                            onClick={() => {
                                                if (selectedGroup === 3 && selectedType !== item.type) {
                                                    this.setState({ selectedGroup: item.value, selectedType: item.type }, () => this.search());
                                                }
                                                else {
                                                    if (selectedGroup !== item.value) {
                                                        this.setState({ selectedGroup: item.value, selectedType: item.type }, () => this.search());
                                                    }
                                                }

                                            }}>
                                            {item.label}
                                        </Button>
                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    {this.state.selectedGroup !== -1 ?
                        <React.Fragment>
                            <div style={{
                                width: '100%', marginTop: 25, height: 'calc(100% - 85px)',
                                width: '100%', display: 'flex', justifyContent: 'space-between'
                            }}>
                                <div style={{ width: '49%', height: '100%' }}>
                                    <div className="warrperStyleGroup10p" style={{ height: '100%' }} >
                                        <div className="grouplabel">
                                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Bảng giá</h4>
                                        </div>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        style={{ width: 200, }}
                                                        value={this.state.banggia}
                                                        disabled={
                                                            (this.state.disableSelectBangGia || (!this.state.disableSelectBangGia && this.state.listSelectedServices.length > 0))
                                                            || this.state.listSelectedServices.length > 0}
                                                        onChange={(e) => {
                                                            this.setState({ banggia: e }, () => this.search());
                                                        }}
                                                        showSearch
                                                        onSearch={(e) => { this.onSearchBangGia(e) }}>
                                                        {lstBangGia && lstBangGia.map((row, index) => {
                                                            return (
                                                                <Option key={index} value={row.value}>{row.label}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="form-group has-search col-md-6">
                                                    <span className="fa fa-search form-control-feedback"></span>
                                                    <input  ref={(c)=> this.searchControl = c} type="text" className="form-control" value={this.state.searchName}
                                                        onKeyDown={this.handleKeySearchDown.bind(this)}
                                                        onChange={(e) => {
                                                            this.setState({ searchName: e.target.value, selecttemp: -1});
                                                            if (task) clearTimeout(task);
                                                            task = setTimeout(() => {
                                                                this.search();
                                                            }, 500)
                                                        }} style={{ marginRight: 0, borderRadius: '15px' }} placeholder="Tìm kiếm"></input>
                                                </div>
                                            </div>
                                            <div style={{ height: 'calc(100% - 39px)' }} >
                                                <Table dataSource={dataSource}
                                                    id="tableScroll"
                                                    onKeyDown={this.keyDownTable.bind(this)}
                                                    rowKey={(e) => e.key}
                                                    columns={columns2}
                                                    pagination={false}
                                                    scroll={{ y: 0 }}
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: e => {
                                                                this.setState({ selectedServiceID: record.id, selectedItem: record },
                                                                    () => {
                                                                        this.AddService();
                                                                    })
                                                            }
                                                        };
                                                    }}

                                    

                                                    rowClassName={(record , index) => {
                                                        return `rowCustomSelect ${(this.state.selecttemp === index) ? 'rowCustomSelectActive' : ''}`
                                                        
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '49.5%', height: '100%' }}>
                                    <div className="warrperStyleGroup10p" style={{ height: '100%' }}>
                                        <div className="grouplabel">
                                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>DỊCH VỤ ĐÃ CHỌN</h4>
                                        </div>
                                        <div className="grouplabelRight">
                                            <div style={{ flex: 1, textAlign: 'right', marginRight: '40px' }}>Tổng Tiền: <b>{this.state.tongtienNew.toLocaleString('en-US') + ' VNĐ'}</b></div>
                                        </div>
                                        <div style={{ height: '100%' }} >
                                            <Table dataSource={dataSourceNew}
                                                onExpandedRowsChange={(expandedRows) => {
                                                    this.setState({expandedRowSelectedKeys: expandedRows});
                                                }}
                                                expandedRowKeys={this.state.expandedRowSelectedKeys}
                                                onExpand={(expanded, record) => {
                                                }}
                                                // rowKey={(e, index) => index} 
                                                rowKey={(e) => e.key}
                                                columns={columns} 
                                                scroll={{ y: 0 }}
                                                pagination={false}
                                                rowClassName={(record) => {
                                                    return `${record && record.voucher && record.voucher.length > 0 ? 'text-danger' : (record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`

                                                }}


                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div style={{ clear: 'left' }}></div>
                            <ConsultantWarning visible={this.state.listSelectedServices.length > 0 && this.state.listSelectedServices.some(x => isNullOrEmpty(x.consultantId))} />
                            <div className="form-group" style={{
                                display: 'flex', justifyContent: 'center',
                                alignItems: 'center', height: 40, marginTop: 15
                            }}>

                                {this.props.noitru !== true ?
                                    <React.Fragment>
                                        {this.props.editAfterRegister === true && this.props.editAfterRegister !== undefined ?
                                            <div>
                                                <button className="btn btn-color" id="btn-save-mdcls" ref={(c) => {this._saveBtn = c }}
                                                    onClick={() => { this.onPressSave() }}
                                                > <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                                                {this.props.isnew !== true &&
                                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                                        onClick={() => { this.props.onClose([], 'close');
                                                            this.props.onReplaceBangGia && this.props.onReplaceBangGia(this.state.banggia);
                                                        }}>
                                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng
                                                    </button>
                                                }
                                            </div>
                                            :
                                            <button className="btn btn-danger"
                                                onClick={() => { this.props.onClose(this.state.listSelectedServices);
                                                    this.props.onReplaceBangGia && this.props.onReplaceBangGia(this.state.banggia);
                                                }}>
                                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng
                                            </button>
                                        }
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div>
                                            <button className="btn btn-success"
                                                onClick={() => {
                                                    if (!this.props.patientID) {
                                                        notification.warning({ message: "Chưa chọn bệnh nhân!" })
                                                    } else {
                                                        this.props.onClose(this.state.listSelectedServices);
                                                    }
                                                }}
                                            > <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                                onClick={() => this.props.onCloseInpatient([])}>
                                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />  Xong
                                            </button>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                        :
                        <div style={{ height: '90%', paddingTop: '10px' }}>
                            <ModalPackage
                                maskClosable={false}
                                ref={(c) => this.modalPackage = c}
                                patientID={this.props.patientID}
                                getListChiDinh={this.getListChiDinh.bind(this)}
                                onClose={(listSelectedServices) => {
                                    if (listSelectedServices === null) {
                                        listSelectedServices = [];
                                    }
                                    else {
                                        listSelectedServices = listSelectedServices.map(x => ({
                                            ...x,
                                            packageId: x.packageid,
                                            id: x.type === 3 ? x.idvp : x.id
                                        }));
                                    }
                                    this.setState({ listSelectedServices }, () => {
                                        this.props.onClose(listSelectedServices);
                                    })
                                }}
                            />
                        </div>
                    }
                </div>
                <Modal
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={this.state.chooseRoomFLag}
                    width={700}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 50 }}>
                        <Form.Item label="Phòng khám" required={true}>
                            <Select value={this.state.selectedRoom} style={{ width: '200px' }} onChange={(e) => {
                                this.setState({ selectedRoom: e })
                            }}>
                                {this.state.lstRooms.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                            <button className="btn btn-success"
                                onClick={() => {
                                    if (this.state.selectedRoom === null) {
                                        notification.warning({ message: "Vui lòng chọn phòng khám !" })
                                        return
                                    }
                                    this.addExamServiceWithoutRoom();
                                }}
                            > <i style={{ marginRight: 5 }}
                                className="fa fa-plus" />Thêm</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() =>
                                    this.setState({ chooseRoomFLag: false })}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />  Xong
                            </button>
                        </div>
                    </div>
                </Modal>
                {/* {this.state.modalSelectRoom &&
                    <Modal
                        maskClosable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        visible={this.state.modalSelectRoom}
                        width={1000}>
                        <SelectRoom
                            item={this.state.selectedDetail}
                            onClose={() => { this.setState({ modalSelectRoom: false }) }}
                            onSubmit={(value) => {
                                this.editPhongKham(this.state.selectedDetail, value)
                            }}
                        />
                    </Modal>
                } */}
                {this.state.modalSelectRoom &&
                    <Modal
                        maskClosable={false}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        visible={this.state.modalSelectRoom}
                        width={1000}>
                        <SelectRoomMulti
                            dataSource={this.state.listSelectedServices.map(x => ({ ...x }))}
                            onClose={() => { this.setState({ modalSelectRoom: false }) }}
                            onSubmit={(values) => {
                                this.setState({ listSelectedServices: values })
                                this.setState({ modalSelectRoom: false })
                            }}
                        />
                    </Modal>
                }
                <Modal
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    visible={this.state.modalSelectConsultant}
                    destroyOnClose={true}
                    width={1000}>
                    <SelectConsultant
						isDialog={true}
                        dataSource={this.state.listSelectedServices.map(x => ({ ...x }))}
                        onClose={() => { this.setState({ modalSelectConsultant: false }) }}
                        onSubmit={(values) => {
                            // this.setState({ listSelectedServices: values })
                            this.onUpdateConsultant(values);
                            this.setState({ modalSelectConsultant: false })
                        }}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
	return ({
		consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
	})
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(modalCLS)



