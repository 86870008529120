export default {
    code: 'vi',
    toolbar: {
        default: 'Mặc định',
        save: 'Lưu',
        font: 'Phông chữ',
        formats: 'Định dạng',
        fontSize: 'Kích thước',
        bold: 'In đậm',
        underline: 'Gạch chân',
        italic: 'In nghiêng',
        strike: 'Gạch giữa',
        subscript: 'Chỉ số dưới',
        superscript: 'Chỉ số trên',
        removeFormat: 'Xóa định dạng',
        fontColor: 'Màu chữ',
        hiliteColor: 'Màu highlight',
        indent: 'Indent',
        outdent: 'Outdent',
        align: 'Căn lề',
        alignLeft: 'Căn lề trái',
        alignRight: 'Căn lề phải',
        alignCenter: 'Căn lề giữa',
        alignJustify: 'Căn đều',
        list: 'Danh sách',
        orderList: 'Danh sách có thứ tự',
        unorderList: 'Danh sách không thứ tự',
        horizontalRule: 'Đường kẻ ngang',
        hr_solid: 'Đường kẻ liền ngang',
        hr_dotted: 'Đường kẻ chấm ngang',
        hr_dashed: 'Đường kẻ nét đứt ngang',
        table: 'Bảng',
        link: 'Liên kết',
        math: 'Hàm tính toán',
        image: 'Ảnh',
        video: 'Video',
        audio: 'Âm thanh',
        fullScreen: 'Toàn màn hình',
        showBlocks: 'Show blocks',
        codeView: 'Code',
        undo: 'Hoàn tác',
        redo: 'Thao tác vừa rồi',
        preview: 'Xem trước',
        print: 'In',
        tag_p: 'Đoạn văn bản',
        tag_div: 'Thẻ div',
        tag_h: 'Tiêu đề',
        tag_blockquote: 'Quote',
        tag_pre: 'Code',
        template: 'Template',
        lineHeight: 'Độ dãn dòng',
        paragraphStyle: 'Định dạng đoạn văn bản',
        textStyle: 'Định dạng chữ',
        imageGallery: 'Thư viện ảnh',
        mention: 'Mention'
    },
    dialogBox: {
        linkBox: {
            title: 'Tiêu đề đường dẫn',
            url: 'Địa chỉ đường dẫn',
            text: 'Nội dung hiển thị',
            newWindowCheck: 'Mở ở cửa sổ mới',
            downloadLinkCheck: 'Đường dẫn tải về',
            bookmark: 'Dấu trang'
        },
        mathBox: {
            title: 'Hàm tính oán',
            inputLabel: 'Ký hiệu toán học',
            fontSizeLabel: 'Cỡ chữ',
            previewLabel: 'Xem trước'
        },
        imageBox: {
            title: 'Thêm hình ảnh',
            file: 'Chọn file',
            url: 'Đường liên kết',
            altText: 'Từ thay thế'
        },
        videoBox: {
            title: 'Thêm video',
            file: 'Chọn file',
            url: 'Thêm từ đường dãn, YouTube/Vimeo'
        },
        audioBox: {
            title: 'Thêm âm thanh',
            file: 'Chọn file',
            url: 'Thêm âm thanh từ đường dẫn'
        },
        browser: {
            tags: 'Thẻ',
            search: 'Tìm kiếm',
        },
        caption: 'Thêm mô tả',
        close: 'Đóng',
        submitButton: 'Lưu',
        revertButton: 'Hoàn tác',
        proportion: 'Giữ nguyên tỷ lệ',
        basic: 'Cơ bản',
        left: 'Trái',
        right: 'Phải',
        center: 'Giữa',
        width: 'Độ rộng',
        height: 'Chiều cao',
        size: 'Kích thước',
        ratio: 'Tỉ lệ'
    },
    controller: {
        edit: 'Chỉnh sửa',
        unlink: 'Bỏ liên kết',
        remove: 'Loại bỏ',
        insertRowAbove: 'Thêm dòng phía trên',
        insertRowBelow: 'Thêm dòng bên dưới',
        deleteRow: 'Xóa dòng',
        insertColumnBefore: 'Thêm cột bên trái',
        insertColumnAfter: 'Thêm cột bên phải',
        deleteColumn: 'Xóa cột',
        fixedColumnWidth: 'Cố định độ rộng cột',
        resize100: 'Chỉnh về mức 100%',
        resize75: 'Chỉnh về mức 75%',
        resize50: 'Chỉnh về mức 50%',
        resize25: 'Chỉnh về mức 25%',
        autoSize: 'Tự động chỉnh kích thước',
        mirrorHorizontal: 'Lật theo chiều ngang',
        mirrorVertical: 'Lật theo chiều dọc',
        rotateLeft: 'Xoay trái',
        rotateRight: 'Xoay phải',
        maxSize: 'Kích thước tối đa',
        minSize: 'Kích thước tối thiểu',
        tableHeader: 'Tiêu đề bảng',
        mergeCells: 'Gom cột/hàng',
        splitCells: 'Chia cột/hàng',
        HorizontalSplit: 'Horizontal split',
        VerticalSplit: 'Vertical split'
    },
    menu: {
        spaced: 'Spaced',
        bordered: 'viền',
        neon: 'Neon',
        translucent: 'Translucent',
        shadow: 'Đổ bóng',
        code: 'Code'
    }
}