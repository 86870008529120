import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Switch, Button, Select, notification, Steps } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import { createCardModeEnum } from "../createCardModeEnum";
import Notify, { NotifyStatus } from '../../../components/notify';
import * as CardAccountService from '../../../services/CardAccountService';
import * as CardService from '../../../services/CardService';

/**
 Ngoài 3 cái props này:
 * props.detailScreenMode: [detail | create | update] để biết screen dành để làm gì
 * props.onCancel(true): hàm đóng dialog trả ra component cha
 * props.isDialog: hiện screen dạn dialog hay page thường
 Thì thêm các props này:
 * props.createMode: createCardModeEnum để biết gọi screen create này từ quy trình tạo account hay từ list page
 * props.cardAccountSelected: truyền từ screen tạo tài khoản vào 
 */
const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  const [isDisableFewField, setIsDisableFewField] = useState(false); //disable các field không được sửa khi đã có tài khoản
  //validate
  //model create
  const [cardId, setCardId] = useState();
  const [cardAccountId, setCardAccountId] = useState();
  const [cardCode, setCardCode] = useState();
  const [isActive, setIsActive] = useState(true);
  const [remark, setRemark] = useState('');
  //extent model
  const [cardAccountCode, setCardAccountCode] = useState('');
  const [accountName, setAccountName] = useState('');
  const [cardTypeName, setCardTypeName] = useState('');
  const [listCardAccount, setListCardAccount] = useState([]);
  const [cardAccountSelected, setCardAccountSelected] = useState();
  //#endregion 
  //#region khai báo biến cục bộ
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    switch (props.detailScreenMode) {
      case detailScreenModeEnum.detail:
        break;
      case detailScreenModeEnum.create:
        if (props.createMode === createCardModeEnum.createAccount) {
          setListCardAccount([props.cardAccountSelected]);
          setCardAccountSelected(props.cardAccountSelected);
          setCardAccountId(props.cardAccountId ?? props.cardAccountSelected?.id);
        }
        else {
          getListCardAccount();
        }
        break;
      case detailScreenModeEnum.update:
        getListCardAccount();
        setCardAccountId(props.cardAccountId);
        break;
      default:
        //Nếu open screen detail này mà không truyền props.detailScreenMode là gì thì cho out
        props.onCancel();
        break;
    }
    setDetailScreenMode(props.detailScreenMode);
    setHeaderTextExtent(props.cardTypeName ?? props.cardAccountSelected?.cardTypeName);
  }, [props.detailScreenMode]);

  // useEffect(() => {
  //   setCardAccountId(props.cardAccountId);
  // }, [props.cardAccountId]);
  
  useEffect(() => {
    getById(cardId);
  }, [cardId]);
  
  useEffect(() => {
    setHeaderTextExtent(cardTypeName);
  }, [cardCode]);
  
  useEffect(() => {
    setHeaderTextExtent(cardAccountSelected?.cardTypeName);
  }, [cardAccountSelected]);

  useEffect(() => {
    setCardId(props.cardId);
  }, [props.cardId]);

  useEffect(() => {
    if (props.cardAccountId) setCardAccountId(props.cardAccountId);
    if (props.cardAccountSelected) setCardAccountSelected(props.cardAccountSelected);
  }, []);
  //#endregion 
  const setHeaderTextExtent = (cardTypeName) => {
    if (props.detailScreenMode == detailScreenModeEnum.create) {
      setHeaderText('Đăng ký - '+(cardTypeName??''));
    }
    if (props.detailScreenMode == detailScreenModeEnum.detail)
      setHeaderText('Chi tiết - Thẻ / ' + cardCode);
    else if (props.detailScreenMode == detailScreenModeEnum.update) {
      setHeaderText('Cập nhật - Thẻ / ' + cardCode);
    }
  }
  //#region search & filter
  const getListCardAccount = () => {
    Actions.setLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: 0,
      maxResultCount: 1000,
      sorting: 'accountName',
      //riêng theo module
      keyword: '',
      isActives: [true]
    }
    CardAccountService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setListCardAccount(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }

  const setData = (data) => {
    setCardCode(data.cardCode);
    setIsActive(data.isActive);
    setRemark(data.remark);
    //extent
    setCardAccountId(data.cardAccountId);
    setCardAccountCode(data.cardAccountCode);
    setAccountName(data.accountName);
    setCardTypeName(data.cardTypeName);
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    CardService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }

  const model = {
    cardCode: "",
    cardAccountId: "",
    isActive: true,
    remark: "",
    id: ''
  };

  const setModel = () => {
    model.cardAccountId = cardAccountId;
    model.isActive = isActive;
    model.remark = remark;
    model.cardCode = cardCode;
    model.id = cardId;
  }

  const saveCreate = () => {
    Actions.setLoading(true);
    CardService.create(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        if (props.createMode === createCardModeEnum.listCard)
          notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    CardService.update(model).then(result => {
      if (result.isSuccess) {
        props.onCancel(true);
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
        Actions.setLoading(true);
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  const validate = () => {
    let isValid = true;
    return isValid;
  }

  const saveButtonHandle = () => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && cardAccountId) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                //   if (this.state.disableSelectStore || this.state.idPhieu != null) {
                //   this.reset()
                //   }
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row mt-2" hidden={props.createMode != createCardModeEnum.createAccount}>{/* step */}
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <Steps current={1} progressDot labelPlacement="vertical">
                  <Step title="Thông tin tài khoản" description="" />
                  <Step title="Thông tin thẻ" description="" />
                </Steps>
              </div>
            </div>
            <div className="row mt-2">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row mt-2"> {/* Chủ tài khoản*/}
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Chủ tài khoản</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Select
                      className='w-100'
                      hidden={detailScreenMode === detailScreenModeEnum.detail}
                      // disabled={detailScreenMode != detailScreenModeEnum.create || props.createMode == createCardModeEnum.createAccount}
                      value={ cardAccountId }
                      onChange={(value, option) => {  
                        setCardAccountId(value); 
                        // setHeaderTextExtent(option.children); 
                        setCardAccountSelected(listCardAccount.filter(x => x.id === value)[0]);
                      }}
                      filterOption={(input, option) => { }}
                    >
                      {(listCardAccount ?? []).map((record, index) => {
                          return (
                              <Option key={record?.id} value={record?.id}>{record?.accountName} - {record?.cardAccountCode}</Option>
                          )
                      })}
                    </Select>
                    <Input
                      hidden={detailScreenMode !== detailScreenModeEnum.detail}
                      readOnly={detailScreenMode==detailScreenModeEnum.detail}
                      value={accountName + " - " +cardAccountCode}
                    ></Input>
                  </div>
                  {validator.current.message("Chủ tài khoản", cardAccountId, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Mã thẻ | Hoạt động*/}
              <div className="col-md-8 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mã thẻ</label>
                  </div>
                  <Input
                    value={cardCode}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    disabled={isDisableFewField}
                    onChange={(e) => setCardCode(e.target.value)}
                  ></Input>
                  {validator.current.message("Mã thẻ", cardCode, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>Hoạt động</label>
                  <div>
                    <Switch 
                      // disabled={detailScreenMode==detailScreenModeEnum.detail || hasAccount}
                      checked={isActive} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsActive(checked); }} 
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Ghi chú */}
                <div className="col-md-12 d-flex flex-column">
                  <label>Ghi chú</label>
                  <TextArea 
                    rows={4}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  ></TextArea >
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
              <Button type="link" className="p-0" title="" hidden={props.createMode != createCardModeEnum.createAccount}
                onClick={() => props.onCancel(false)}
              >
                <i className="fa fa-chevron-left mr-2"></i>
                Quay lại
              </Button>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  hidden={detailScreenMode == detailScreenModeEnum.detail}
                  onClick={saveButtonHandle}
                >
                  <i className='fa fa-save mr-2'></i>
                  Lưu
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})