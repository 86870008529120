import { Form, Input, InputNumber, notification, Popconfirm, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as WarehouseService from '../../../services/WarehouseTransferService';
import * as XuatKhoService from '../../../services/xuatkhoService';
const { Option } = Select;
const initialState = {
    id: '',
    key: '',
    lydo: '',
    khoxuatID: '',
    khonhapID: '',
    ngay: moment(),
    soluong: 0,
    ma: '',
    ten: '',
    lonhap: '',
    soluongton: '',
    ngaynhap: null,
    rowNumber: []
}
export default class PhieuXuatChuyenKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKhoXuat: [],
            lstKhoNhap: [],
            lstThuoc: [],
            lstLoSX: [],
            lstAddThuoc: [],
            disabledAdd: false,
            disabledKhoXuat: false,
        };
    }

    componentWillMount() {
        this.loadKhoXuat();
    }
    loadKhoXuat() {
        WarehouseService.ListKhoXuat().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstKhoXuat: result.data,
                    lstKhoNhap: [],
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho xuất bị lỗi!' })
        })
    }
    loadKhoNhap(makho) {
        WarehouseService.ListKhoNhap(makho).then(result => {

            if (result.isSuccess) {
                this.setState({
                    lstKhoNhap: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho nhập bị lỗi!' })
        })
    }
    loadThuocTrongKho(value) {
        XuatKhoService.GetThuocTrongKho(value).then(result => {

            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        lstThuoc: result.data,
                        id: result.data[0].id
                    });
                    this.form.setFieldsValue({
                        id: result.data[0].id,
                        lstThuoc: result.data
                    })
                }
                else {
                    this.setState({
                        lstThuoc: [],
                    });
                }
            }
        }).catch(err => {
        })

    }
    loadLoSX(mabd) {
        XuatKhoService.GetSLTonKhoTheoID(this.state.khoxuatID, mabd).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoSX: result.data,
                });
            }
            else {
                this.setState({
                    lstLoSX: []
                });
            }
        }).catch(err => {
        })
    }

    AddThuoc(row, index) {
        let lst = this.state.lstAddThuoc;
        const current = lst.find(x => x.ma === row.ma);
        const current2 = lst.find(x => x.losx === row.losx);
        if (this.state.rowNumber[index] <= 0) {
            notification.warning({ message: 'Số lượng xuất phải lớn hơn 0!' });
            return;
        }

        if (!Number.isInteger(this.state.rowNumber[index])) {
            notification.warning({ message: 'Số lượng phải là chữ số!' });
            return;
        }

        if (current !== undefined && current2 !== undefined) {
            notification.warning({ message: 'Thuốc theo lô đã tồn tại trong danh sách!' });
            return;
        }
        if (this.state.rowNumber[index] > row.soluongton) {
            notification.warning({ message: 'Số lượng xuất phải nhỏ hơn hoặc bằng số lượng tồn!' });
            return;
        }
        lst.unshift({
            soluongton: row.soluongton,
            makho: row.makho,
            manguon: row.manguon,
            idPhieu: row.idPhieu,
            id: row.mabd,
            ma: row.ma,
            ten: row.ten,
            losx: row.losx,
            ngaynhap: moment(row.ngaynhap).format('YYYY-MM-DD'),
            soluong: this.state.rowNumber[index]
        })
        this.setState({
            soluong: 0,
            disabledKhoXuat: true,
            lstAddThuoc: lst.map(d => ({ ...d }))
        });
    }

    async SaveDon() {
        if (this.state.lydo === undefined || this.state.lydo === null) {
            notification.warning({ message: 'Yêu cầu nhập lý do chuyển kho!' });
            return;
        }
        if (this.state.lstAddThuoc.length === 0) {
            notification.warning({ message: 'Không có thuốc nào trong danh sách!' });
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            if (this.validator.allValid()) {
                const createTransfer = {
                    'ngay': moment().format('YYYY-MM-DD'),
                    'lydo': this.state.lydo,
                    'khoxuatID': Number(this.state.khoxuatID),
                    'khonhapID': Number(this.state.khonhapID),
                }
                const data = {
                    "createTransfer": createTransfer,
                    'lstAddThuoc': this.state.lstAddThuoc,
                }
                if (this.state.lstAddThuoc.length === 0) {
                    notification.warning({ message: 'Không có vật tư nào trong danh sách!' });
                    return;
                }
                WarehouseService.TaoPhieuChuyenKho(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        this.reset();
                        this.loadKhoXuat();
                    }
                    else {
                        notification.warning({ message: result.err.msgString })
                    }
                }).catch(err => {
                })
            }
        }
        else {
            this.validator.showMessages();
        }
    }

    reset() {
        this.setState({
            khonhapID: null,
            khoxuatID: null,
            id: null,
            lstAddThuoc: [],
            lstLoSX: [],
            soluong: 0,
            soluongton: 0,
            lydo: null,
            disabledAdd: false,
            disabledKhoXuat: false
        })
        this.form.setFieldsValue({
            khonhapID: null,
            khoxuatID: null,
            id: null,
            lydo: null,
            soluong: 0,
        })
        this.loadKhoXuat();
    }
    XoaThuoc(record) {
        if (record.id !== 0) {
            let lst = this.state.lstAddThuoc.filter(x => x.ma !== record.ma);
            if (lst.length === 0) {
                this.reset();
            }
            this.setState({ lstAddThuoc: lst.map(d => ({ ...d })) })
        }
    }
    refreshInfo() {
        this.setState({
            ...initialState,
            lstKhoXuat: [],
            lstKhoNhap: [],
            lstThuoc: [],
            lstLoSX: [],
            lstAddThuoc: [],
            disabledAdd: false,
            disabledKhoXuat: false
        });
        this.form.setFieldsValue({
            id: '',
            khonhapID: null,
            khoxuatID: null
        })
        this.loadKhoXuat();
    }
    render() {
        let { rowNumber } = this.state;
        const columns = [
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 100
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 300
            },
            // {
            //     title: 'Hàm lượng',
            //     dataIndex: 'hamluong',
            //     key: 'hamluong',
            //     align: 'left',
            //     width: 100
            // },
            // {
            //     title: 'Hợp chất',
            //     dataIndex: 'tenhc',
            //     key: 'tenhc',
            //     align: 'left',
            //     width: 100
            // },
            {
                title: 'Lô nhập',
                dataIndex: 'losx',
                key: 'losx',
                align: 'left',
                width: 90
            },
            // {
            //     title: 'Ngày nhập',
            //     dataIndex: 'ngaynhap',
            //     key: 'ngaynhap',
            //     render: (value) => {
            //         return <span>{moment(value).format('DD/MM/YYYY')}</span>;
            //     }
            // },
            // {
            //     title: 'Nhập',
            //     dataIndex: 'slnhap',
            //     key: 'slnhap',
            //     align: 'right',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            //     width: 80
            // },
            // {
            //     title: 'SL xuất',
            //     dataIndex: 'slxuat',
            //     key: 'slxuat',
            //     align: 'right',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            //     width:100
            // },
            {
                title: 'Tồn kho',
                dataIndex: 'soluongton',
                key: 'soluongton',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
                width: 80
            },
            {
                title: 'Số lượng xuất',
                width: 120,
                align: 'right',
                render: (text, row, index) => {
                    return (row.soluongton > 0 &&
                        <div style={{ display: "flex", justifyContent:'flex-end' }}>
                            <InputNumber placeholder="Nhập số lượng" min={0} maxLength={8} value={rowNumber[index] !== undefined ? rowNumber[index] : 0}
                                onChange={(e) => {
                                    rowNumber[index] = e;
                                    this.setState({ rowNumber })
                                }
                                }
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.AddThuoc(row, index);
                                    }
                                }} />
                        </div>
                    )
                },
            },
            {
                title: 'Action',
                align: 'center',
                width: 80,
                render: (text, row, index) => {
                    return (row.soluongton > 0 &&
                        <span>
                            <button className="btn btn-success" style={{ marginRight: '5px' }}
                                onClick={() => {
                                    this.setState({
                                        ma: row.ma, ten: row.ten, lonhap: row.losx,
                                        soluongton: row.soluongton, ngaynhap: row.ngaynhap
                                    });
                                    this.AddThuoc(row, index)
                                }}>
                                Chọn
                            </button>
                        </span>
                    )
                },
            }
        ]
        const columns1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
                width:40
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width:80
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width:200
            },
            {
                title: 'Lô',
                dataIndex: 'losx',
                key: 'losx',
                align: 'left',
                width:80
            },
            // {
            //     title: <span style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>MÃ</span>,
            //     dataIndex: 'soluongton',
            //     key: 'soluongton',
            //     render: (value) => {
            //         return <span>{value.toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'right',
                width:80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Action',
                align: 'left',
                width:60,
                render: (row) => {
                    return (
                        <span>
                            <button className="btn btn-danger btn-sm" style={{ marginRight: '5px' }}>
                                <Popconfirm
                                    title="Bạn có chắc muốn xóa chỉ định này?"
                                    onConfirm={() => { this.XoaThuoc(row) }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <i className="fa fa-trash-alt" />
                                </Popconfirm>
                            </button>
                        </span>
                    )
                },
            }
        ]
        let dataSource = this.state.lstLoSX.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} >
                    <div className="row no-gutters">
                        <div className="col-md-7" style={{ borderRight: "1px solid #000"}}>
                            <div className="row no-gutters" style={{display:'flex'}}>
                                <div className="col-md-6 mt2" style={{ flex: 1 }} >
                                <label>Kho xuất:</label>
                                    <Form.Item name="khoxuatID" style={{display:'flex', flex: 1 }}
                                        rules={[{ required: true, message: 'Vui lòng chọn kho xuất!' }]}>
                                        <Select disabled={this.state.disabledKhoXuat}
                                            width={'100%'}
                                            value={this.state.khoxuatID}
                                            onChange={(value) => {
                                                this.setState({ khoxuatID: value, rowNumber: [], lstLoSX: [] });
                                                this.loadKhoNhap(value); this.loadThuocTrongKho(value);
                                                this.form.setFieldsValue({
                                                    khonhapID: null,
                                                    lstKhoNhap: [],
                                                    id: null,
                                                    lstThuoc: null
                                                })
                                            }}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstKhoXuat.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6 mt2" style={{ flex: 1 }} >
                                <label>Kho nhập:</label>
                                    <Form.Item style={{display:'flex', flex: 1 }} name="khonhapID"
                                        rules={[{ required: true, message: 'Vui lòng chọn kho nhập!' }]}>
                                        <Select width={'100%'}
                                            value={this.state.khonhapID} disabled={this.state.disabledAdd}
                                            onChange={(value) => { this.setState({ khonhapID: value }) }}>
                                            {this.state.lstKhoNhap.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                             <div className="row no-gutters" style={{display:'flex'}}>
                                <div className="col-md-6 mt2" style={{ flex: 1 }} >
                                    <label>Thuốc:</label>
                                    <Form.Item name="id" style={{ flex: 1 }}
                                        rules={[{ required: true, message: 'Yêu cầu chọn thuốc!' }]}>
                                        <Select width={'100%'}
                                            showSearch
                                            onChange={(value) => {
                                                this.setState({ rowNumber: [] })
                                                this.loadLoSX(value)
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.lstThuoc.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.value}>{row.label}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6 mt2" style={{ flex: 1 }} >
                                <label>Lý do xuất:</label>
                                    <Form.Item style={{ flex: 1 }} name="lydo"
                                        rules={[{ required: true, message: 'Yêu cầu nhập lý do tạo phiếu!' }]}>
                                        <Input value={this.state.lydo} disabled={this.state.disabledAdd}
                                            onChange={(e) => this.setState({ lydo: e.target.value })}
                                            placeholder="Nhập lý do tạo phiếu chuyển kho" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="table-responsive" style={{ marginTop: 20 }}>
                                <Table dataSource={dataSource}  scroll={{ y: 0 }} pagination={false}
                                    columns={columns} rowKey={(e, index) => index} />
                            </div>
                        </div>
                        <div className="col-md-5" style={{ padding: "5px" }}>
                            <Form.Item>
                                <button className="btn btn-success" onClick={() => this.refreshInfo()}>
                                    <i className="fa fa-eraser" style={{ marginRight: 5 }} />Làm mới</button>
                            </Form.Item>
                            <div className="table-responsive" style={{ display: 'flex', flex: 1}}>
                                <Table pagination={false} dataSource={this.state.lstAddThuoc}  scroll={{ y: 0 }} 
                                    columns={columns1} rowKey={(e, index) => index} />
                            </div>
                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn btn-success">
                                    <Popconfirm
                                        title="Bạn có chắc muốn xuất đơn này?"
                                        onConfirm={() => { this.SaveDon() }}
                                        okText="Đồng ý" cancelText="Hủy">
                                        <in className="fa fa-save" style={{ marginRight: 5 }} /> Lưu
                                </Popconfirm>
                                </button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.props.onCancel()
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                    /> Đóng

                              </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
