import * as ApiCaller from '../libs/httpRequests';
const prefix = 'ThirdPartyConfig';
const prefixEmail = 'emailconfig-';
const prefixSms = 'smsconfig-';
const prefixZalo = 'zaloconfig-';

//#region email
export function emailGetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function emailGetById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function emailCreate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function emailUpdate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function emailDeleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function sendEmailTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixEmail}sendEmailTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion

//#region sms
export function smsGetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function smsGetById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function smsCreate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function smsUpdate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function smsDeleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function sendSmsTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixSms}sendSmsTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion

//#region zalo
export function zaloGetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function zaloGetById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}get/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function zaloCreate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function zaloUpdate(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function zaloDeleteById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}delete/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function sendZaloTest(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${prefixZalo}sendZaloTest`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function zaloGetListTemplate() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`SendMessage/getlist-zalomessagetemplate`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion

export function onOffDefaultById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/onOffDefault/${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};