import React, { Fragment, useRef, useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import { Input, Select, DatePicker, InputNumber, Tooltip, Popconfirm } from 'antd'
import moment from 'moment';
import Notify, { NotifyStatus } from '../../../../components/notify';
import AutocompleteMulti from '../../../../components/autocompleteMulti';
import SunEditor from 'suneditor-react';
import suneditorViLang from '../../../../libs/suneditor-vi-lang';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../../libs/actions';
import * as CommonService from '../../../../services/commonService';
import * as MedicalExamService from '../../../../services/MedicalExam';
import { IsActive_FeatureLH } from '../../../../configure/featureConfig';
import Schedule from '../../../lichhen/schedule';

const { TextArea } = Input;
const { Option } = Select;
export default forwardRef((props, ref) => {
    const _notiRef = useRef();
    const _icd = useRef();
    const _scheduleRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Datasource 
    const [listICDs, setListICDs] = useState([]);
    const [listTemplate, setListTemplate] = useState([]);
    const [examinationId, setExaminationId] = useState('')
    const [dataSource, setDataSource] = useState({});
    const [listTreatmentDirection, setListTreatmentDirection] = useState([])
    // Biến cục bộ
    const [lyDo, setLyDo] = useState('');
    const [moTa, setMoTa] = useState('');
    const [ketLuan, setKetLuan] = useState('');
    const [denghi, setDenghi] = useState('');
    const [ICDs, setICDs] = useState([]);
    const [henkham, setHenkham] = useState();
    const [treatmentDirection, setTreatmentDirection] = useState(null);
    const [appointmentScheduleId, setAppointmentScheduleId] = useState();

    useEffect(() => {
        setExaminationId(props.examinationId);
    }, [props])
    
    useEffect(() => {
        if(examinationId && examinationId !== '') GetDetail();
        GetListTreatmentDirection();
    }, [examinationId])

    useImperativeHandle(ref, () => ({
        onSaveExam: (isDone) => {
            SaveExamination(isDone);
        }
    }));
    
    const GetDetail = () => {
        Actions.setLoading(true);
        MedicalExamService.GetExaminationDetail(examinationId).then((result) => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setLyDo(result.data?.lyDo);
                setMoTa(result.data?.moTa);
                setTreatmentDirection(result.data?.huongXuTri);
                setDenghi(result.data?.deNghi);
                setKetLuan(result.data?.ketLuan);
                setHenkham(result.data?.appointmentDate);
                setICDs(result.data?.icDs);
                setListICDs(result.data?.icDs);
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const SaveExamination = (isDone) => {
        Actions.setLoading(true);
        let ngayHen = null;
        let idLichHen = null;
        if (_scheduleRef && _scheduleRef.current) {
            const dataHen = _scheduleRef.current.getData();
            ngayHen = dataHen.ngayGioHen;
            idLichHen = dataHen.idLichHen
        }
        let data = {
            'ExaminationId' : dataSource.examinationId,
            'isDone' : isDone,
            'LyDo' : lyDo,
            'MoTa' : moTa,
            'KetLuan' : ketLuan,
            'LoiDan' : denghi,
            'XuTri' : treatmentDirection,
            'ICDs' : ICDs,
            'HenKham' : ngayHen,
            'AppointmentScheduleId' : idLichHen
        }
        MedicalExamService.SaveExamination(data).then((result) => {
            if (result.isSuccess) {
                if(!isDone){
                    GetDetail();
                    _scheduleRef.current && _scheduleRef.current.reload();
                    _notiRef.current.Show("Lưu thành công.", NotifyStatus.Success);
                } else {
                    props.isDone();
                }
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const GetListTreatmentDirection = () => {
        MedicalExamService.GetListTreatmentDirection().then((result) => {
            if (result.isSuccess) {
                setListTreatmentDirection(result.data);
                _notiRef && _notiRef.current.Close();
            }
            else {
                _notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else _notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const SetListChiDinhICD = (item) => {
        let lst = [];
        item.map(x => {
            lst.push({ value: x.key, label: x.children })
        })
        setICDs(lst);
    }

    const GetListDiagnostic = (key) => {
        let skip = 0;
        let maxCount = 10;
        let filterModel = {
            skipCount: skip,
            maxResultCount: maxCount,
            keyword: key,
        }
        CommonService.GetListICD10(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items;
                data = data.map(x => ({ ...x, label: `${x.value} - ${x.label}` }))
                setListICDs(data);
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", 'Lỗi tải danh chẩn đoán ICD');
        })
    }

    const renderStatus = (record) => {
        switch(record.examPatientStatus) {
            case '?':
                return (
                    <label className="mita-value">Đang khám</label>
                );
            case 'x':
                return (
                    <label className="mita-value">Đã khám</label>
                );
            default:
                return (
                    <label className="mita-value">Chưa khám</label>
                );
        }
    }

    return (
        <Fragment>
            <div className="w-100 h-100 overflow-auto" style={{overflowY:"auto"}}>
                <div className="row">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Tên dịch vụ </label>
                        <label className="mita-value">{dataSource.serviceName}</label>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Trạng thái </label>
                        <label className="mita-value">{renderStatus(dataSource)}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Lý do khám</label>
                        <Input value={lyDo} onChange={(e) => setLyDo(e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Chẩn đoán ICD</label>
                        <div className='w-100'>
                            <AutocompleteMulti
                                className="w-100"
                                width={'100%'}
                                ref={_icd}
                                dataSource={listICDs}
                                handleSearch={GetListDiagnostic}
                                getItem={(item) => { SetListChiDinhICD(item); }}
                                value={ICDs}
                                title="bệnh kèm theo" />
                        </div>
                    </div>
                </div>
                {/* 010323 - Huynguyen : Ẩn do ko có sẵn */}
                {/* <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Biểu mẫu</label>
                        <Select
                            placeholder="Chọn biểu mẫu"
                            className='w-100'
                            value = {template}
                            onChange={(e) => { setTemplate(e) }}
                        >
                            {listTemplate.map((row, index) => {
                                return (
                                    <Option key={index} value={row.id}>{row.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div> */}
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Mô tả</label>
                        <SunEditor
                            lang={suneditorViLang}
                            onBlur={(e, value) => {
                                setMoTa(value);
                            }}
                            onChange={(e) => setMoTa(e)}
                            setDefaultStyle="font-size: 13px;paragraphStyles : div;font-family : Arial;"
                            setContents={moTa}
                            setOptions={{
                                height: 300,
                                buttonList: [
                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                    ['bold', 'underline', 'italic', 'fontColor', 'subscript', 'superscript', 'removeFormat'],
                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                ]
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Kết luận</label>
                        <TextArea rows={2} value={ketLuan} onChange={(e) => setKetLuan(e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Lời dặn - Đề nghị</label>
                        <TextArea rows={2} value={denghi} onChange={(e) => setDenghi(e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label className="mita-title">Hướng xử trí</label>
                        <Select
                            placeholder="Chọn hướng xử trí"
                            className='w-100'
                            value = {treatmentDirection}
                            onChange={(e) => { setTreatmentDirection(e);}}
                            allowClear={true}
                            onClear={() => setTreatmentDirection(null)}
                        >
                            {listTreatmentDirection.map((row, index) => {
                                return (
                                    <Option key={row.ma} value={row.ma}>{row.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row mt-2">
                    {IsActive_FeatureLH() &&
                        <div className='col-12'>
                            <Schedule
                                ref={_scheduleRef}
                                data={{
                                    managermentId: dataSource.managermentId,
                                    serviceId: dataSource.serviceCode,
                                    patientCode: dataSource.patientCode,
                                }} />
                        </div>}
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <Notify ref={_notiRef} />
                    </div>
                </div>
                {/*2023/04/06 : ẩn do task #3288*/}
                {/* <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-end">
                        <Popconfirm
                            title="Xác nhận khám xong?"
                            onConfirm={() => {SaveExamination(true)}}
                            okText="Đồng ý"
                            cancelText="Đóng"
                        >
                            <button className="btn btn-success mr-2"><i className="fas fa-check mr-2" /> Khám xong</button>
                        </Popconfirm>
                        <button className="btn btn-color mr-2" onClick={() => { SaveExamination(false) }}><i className="fas fa-save" /> Lưu</button>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
});
