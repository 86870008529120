
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

/*
listFiles: là array của file
file = {
    uid: 'uid',      // unique identifier, negative is recommend, to prevent interference with internal generated id
    name: 'fileName', //filename
    url: 'url', // url đến hình ảnh
}
*/
export default forwardRef((props, ref) => {
    const carouselRef  = useRef();
    // const imgRef  = useRef();
    const [listFiles, setListFiles] = useState([]); 
    const [currentUid, setCurrentUid] = useState(); 

    useEffect(() => {
        changeProps();
    }, [props]);


    const changeProps = () => {
        let tListFiles = [];
        if (props.listFiles &&  props.listFiles .length > 0) {
            props.listFiles.map(x => {
                if (!x.uid || !x.uid.length) x.uid = (new Date * Math.random()).toString(36).substring(0,10);
                tListFiles.push(x);
            });
            setCurrentUid(props.listFiles[0].uid);
        }
        setListFiles(tListFiles);
    }

    const btnNextClickHandle = () => {
        carouselRef.current.next();
    }

    const btnPrevClickHandle = () => {
        carouselRef.current.prev();
    }

    return (
        <>
            <div hidden={listFiles && listFiles.length > 0} style={{ textAlign: "center", color:"rgba(0, 0, 0, 0.25)" }}>Không có hình ảnh</div>
            <div className="h-100 w-100 position-relative" hidden={!listFiles || listFiles.length==0}>
              <div className="image-move image-move-left" title="Prev" onClick={() => { btnPrevClickHandle(); }}>
                <LeftOutlined className="m-auto"/>
              </div>
              {
                listFiles.map((x) => {
                  return (
                    <img src={x.url} key={x.uid} hidden={x.uid != currentUid} className="w-100 h-100" style={{ objectFit: "contain" }}></img>
                  )
                })
              }
              <div className="image-move image-move-right" title="Next" onClick={() => { btnNextClickHandle(); }}>
                <RightOutlined className="m-auto"/>
              </div>
            </div>
            <Carousel ref={carouselRef}
              className="w-100"
              afterChange={(current) => {
                setCurrentUid(listFiles[current].uid);
              }}
            >
            {
              listFiles.map(x=> {
                return <div className="w-100" key={x.uid} />
              })
            }
            </Carousel>
        </>
    );
})