import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, notification, Select, Space, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import * as Actions from '../../../libs/actions';
import * as NhapkhoaService from '../../../services/nhapkhoaService';
import * as XuatKhoaService from '../../../services/xuatKhoaService';
import EditModalXuatKhoa from './editModal';


const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            lstdepartment: [],
            department: '',
            result: '',
            lstResult: [],
            status: '',
            visible: false,
            detail: {},
        };
    }
    componentDidMount() {
        this.loadKhoaCanNhap();
    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                let data = result.data.filter(x => x.value !== '00');
                this.setState({
                    lstdepartment: data
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu khoa cần nhập bị lỗi' })
        })
    }
    loadDanhSachBenhNhanXuatKhoa() {
        if (this.state.department === '') {
            notification.warning({ message: 'Chưa chọn khoa!' })
            return;
        }
        XuatKhoaService.DanhSachXuatKhoa(this.state.department, '').then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPatient: result.data,
                    totalPatient: result.data.length,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh nhân cần xuất khoa bị lỗi' })
        })
    }

    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Tìm kiếm ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Tìm kiếm
                </Button>
                    <Button type="primary" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Hủy lọc
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    async submit() {
        Actions.setLoading(true);
        const data = {
            'code': this.state.detail.code,
            'name': this.state.detail.name,
            'department': this.state.department,
            'lstOtherDiseases': this.editmodal.state.otherDiseases,
            'bed': this.state.detail.bed,
            'diagnostic': this.editmodal.state.diagnostic,
            'diagnosticName': this.editmodal.state.diagnosticName,
            'doctorReceive': this.editmodal.state.doctorReceive,
            'managerId': this.state.detail.managerId,
            'result': this.editmodal.state.result.toString(),
            'status': this.editmodal.state.status.toString(),
            'enterCode': this.state.detail.enterCode,
            'enterNumber': this.state.detail.enterNumber,
            'subjectCode': this.state.detail.subjectCode,
            'saveCode': this.editmodal.state.saveCode,
            'dateInDepartment': moment(this.state.detail.dateInDepartment).format('YYYY-MM-DD HH:mm'),
            'dateOutDepartment': moment().format('YYYY-MM-DD HH:mm'),
            'dateInHospital': moment(this.state.detail.dateInHospital).format('YYYY-MM-DD HH:mm'),
        }
        XuatKhoaService.LuuXuatKhoa(data).then(result => {
            if (result.isSuccess) {
                this.loadDanhSachBenhNhanXuatKhoa();
                this.setState({ visible: false })
                notification.success({ message: "Lưu thành công" });
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    setData(row) {
        this.setState({ visible: true, detail: row });
    }
    render() {
        const dataSource = this.state.lstPatient

        const columns = [
            {
                title: '',
                render: (row) => <div>
                    <Button className="btn btn-color mr-1" onClick={() => {
                        this.setData(row);
                    }}><i className="fa fa-edit"></i></Button>
                    {/* <Button className="btn btn-color" onClick={() => {
                        this.setData(row); this.setState({ visibleRoom: true })
                    }}><i className="fa fa-edit"></i></Button> */}
                </div>
            },
            {
                title: 'Mã BN',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code', 'mã bệnh nhân'),
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name', 'tên'),
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'dob',
                key: 'dob',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY')}</span>);
                }
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
            },
            {
                title: 'Ngày vào viện',
                dataIndex: 'dateInHospital',
                key: 'dateInHospital',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>);
                }
            },
            {
                title: 'Ngày vào khoa',
                dataIndex: 'dateInDepartment',
                key: 'dateInDepartment',
                render: (value) => {
                    return (<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>);
                }
            },
            {
                title: 'Chẩn đoán',
                dataIndex: 'diagnosticName',
                key: 'diagnosticName',
            },
            {
                title: 'Chẩn đoán nơi chuyển',
                dataIndex: 'cdFromName',
                key: 'cdFromName',
            },
            {
                title: 'Bác sĩ nhận',
                dataIndex: 'doctorReceiveName',
                key: 'doctorReceiveName',
            },
            {
                title: 'Giường',
                dataIndex: 'bedName',
                key: 'bedName',
            },
            {
                title: 'Phòng',
                dataIndex: 'roomName',
                key: 'roomName',
            },
            {
                title: 'Loại phòng',
                dataIndex: 'roomTypeName',
                key: 'roomTypeName',
            },
            {
                title: 'Nhận từ',
                dataIndex: 'receiveFromName',
                key: 'receiveFromName',
            },
            {
                title: 'Nơi giới thiệu',
                dataIndex: 'comeFromName',
                key: 'comeFromName',
            },
            {
                title: 'Khoa chuyển đến',
                dataIndex: 'switchFromName',
                key: 'switchFromName',
            },
            {
                title: 'Bệnh viện chuyển đến',
                dataIndex: 'switchHospitalName',
                key: 'switchHospitalName',
            },
        ];
        return (
            <React.Fragment>
                <div className="font-weight-bold" style={{ textAlign: 'center', marginBottom: '10px' }}>DANH SÁCH CHỜ XUẤT KHOA</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <Form.Item label="Chọn khoa cần xuất:">
                            <Select value={this.state.department} onChange={(value) => { this.setState({ department: value }, () => { this.loadDanhSachBenhNhanXuatKhoa() }) }}
                                showSearch
                                style={{ width: '200px' }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstdepartment.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value} style={{ textTransform: 'uppercase' }}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button className="btn btn-color" onClick={() => { this.loadDanhSachBenhNhanXuatKhoa() }}><i className="fas fa-sync" title="refesh"></i></Button>
                        </Form.Item>
                    </div>
                </div>
                <Table dataSource={dataSource} columns={columns} rowKey={(e, index) => index}></Table>
                {this.state.visible && <Modal maskClosable={false}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    width={1000}
                >
                    <EditModalXuatKhoa ref={(c) => this.editmodal = c} detail={this.state.detail} makp={this.state.department} />
                    <div className="d-flex justify-content-center">
                        <Button key="2" className="btn btn-color mr-2" onClick={() => { this.submit() }}> <i style={{ marginRight: 10 }} className="fa fa-save" />Lưu</Button>
                        <Button key="3" onClick={() => { this.setState({ visible: false }) }}>Đóng</Button>
                    </div>
                </Modal>
                }
                {/* {this.state.visible && <Modal maskClosable={false}
                    visible={this.state.visible}
                    onCancel={() => this.setState({ visible: false })}
                    width={1000}
                >
                    <Room ref={(c) => this.editmodal = c} detail={this.state.detail} makp={this.state.department} />
                    <div className="d-flex justify-content-center">
                        <Button key="2" className="btn btn-color mr-2" onClick={() => {  }}> <i style={{ marginRight: 10 }} className="fa fa-save" />Lưu</Button>
                        <Button key="3" onClick={() => { this.setState({ visible: false }) }}>Đóng</Button>
                    </div>
                </Modal>
                } */}
            </React.Fragment>
        )
    }
}
