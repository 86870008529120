import { Form, Input, notification } from 'antd';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as MedicineService from '../../../../services/medicineService';
import { ValidationCustom } from '../../../../commons/validationCustom';
const initialState = {
    tenhang: '',
    ghichu:'',
}
let TOL = null;
export default class createhangsx extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
        };
    }
    componentWillUnmount() {
        if (TOL)
            clearTimeout(TOL)
    }
    async createHSX() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                ten: this.state.tenhang,
                ghichu: this.state.ghichu,
                action: "insert"
            }

            MedicineService.TaoHangSanXuat(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' });
                    this.resetState();
                    if(this.props.onSubmit){
                        this.props.onSubmit(result.returnObject);
                        this.props.onCancel();
                    }
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    resetState() {
        this.form.setFieldsValue({
            tenhang: '',
            ghichu: ''
        });
        this.setState({
            tenhang: '',
            ghichu:''
        })
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="modal-header" style={{ display: 'flex', justifyContent: 'left' }}>
                        <h3>Thêm hãng sản xuất</h3>
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                            <Form.Item style={{ flex: 1 }} name="tenhang" className='input-not-formitem'>
                            <label>Tên hãng</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                                <Input
                                    value={this.state.tenhang}
                                    onChange={(e) => this.setState({ tenhang: e.target.value })}
                                />
                                {this.validator.message("Tên hãng", this.state.tenhang, 'required')}
                            </Form.Item>
                        </div>
                        <div className="row no-gutters mt-2">
                            <div className="col-md-12">
                                <Form.Item className='input-not-formitem'>
                                    <label>Ghi chú</label>
                                    <Input
                                        value={this.state.ghichu}
                                        onChange={(e) => this.setState({ ghichu: e.target.value })} />
                                </Form.Item>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success" style={{ margin: "-4px 0px 0px 0px" }}
                                onClick={() => this.createHSX()}>Tạo</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10, marginTop:-3 }}
                                onClick={() => {
                                    TOL = setTimeout(() => {
                                        this.form.resetFields();
                                    }, 50);
                                    this.props.onCancel();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                /> Đóng

                              </button>
                        </div> */}
                        <div className='custom-hr'></div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                            <button className="btn btn-success"
                                onClick={() => { this.createHSX() }}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }} onClick={() => { this.resetState(); this.props.onCancel() }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
