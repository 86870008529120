import React from 'react';
import "./main.css";

const MinHeight = 200;
const MinWidth = 200;
export default class MidItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            top: 10,
            left: 10,
            width: 500,
            height: 400,
            lastMouseX: 0,
            lastMouseY: 0,
            isResizeHorizon: false,
            isResizeVerical: false,
            isResizeBoth: false,
            moveState: false,
            fullMode: false,
        }
    }
    handleRotate = (rotateAngle) => {
        this.setState({
            rotateAngle
        })
    }


    componentDidMount() {
        let { top, left } = this.props
        this.setState({ top, left })
    }

    handleMouseMove(e) {
        if (this.state.isResizeHorizon) {
            this.resizeHorizon(e, this.state.horizonType);
            return;
        }

        if (this.state.isResizeVerical) {
            this.resizeVertical(e, this.state.verticalType);
            return;
        }

        if (this.state.isResizeBoth) {
            this.resizeBoth(e, this.state.resizeBothType);
            return;
        }

        let { top, left, lastMouseX, lastMouseY } = this.state;
        var difx = e.screenX - lastMouseX;
        var dify = e.screenY - lastMouseY;
        top += dify;
        left += difx;
        lastMouseX = e.screenX;
        lastMouseY = e.screenY;
        if (top > 0)
            this.setState({ top, left, lastMouseX, lastMouseY });
    }

    resizeHorizon(e, type) {
        if (this.state.isResizeHorizon) {
            var { lastMouseX, left, width } = this.state;
            if (type === 1) {
                var right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                if (width >= MinWidth)
                    this.setState({ left, width, lastMouseX });
            } else {
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                if (width >= MinWidth)
                    this.setState({ width, lastMouseX });
            }
        }
    }

    resizeVertical(e, type) {
        if (this.state.isResizeVerical) {
            let { lastMouseY, top, height } = this.state;
            if (type === 1) {
                let bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY
                if (height >= MinHeight) {
                    this.setState({ top, height, lastMouseY });
                }
            } else {
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                if (height >= MinHeight) {
                    this.setState({ height, lastMouseY });
                }
            }
        }
    }

    resizeBoth(e, type) {
        let { lastMouseX, left, width, lastMouseY, top, height } = this.state;
        switch (type) {
            case 1:
                let right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                let bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY

                break;
            case 2:
                bottom = top + height;
                top += e.screenY - lastMouseY;
                height = bottom - top;
                lastMouseY = e.screenY
                this.setState({ top, height, lastMouseY });
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                break;
            case 3:
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                width += e.screenX - lastMouseX;
                lastMouseX = e.screenX
                break;
            case 4:
                right = left + width;
                left += e.screenX - lastMouseX;
                width = right - left;
                lastMouseX = e.screenX
                height += e.screenY - lastMouseY;
                lastMouseY = e.screenY
                break;
            default:
                break;
        }
        if (width >= MinWidth && height >= MinHeight)
            this.setState({ top, height, lastMouseY, left, width, lastMouseX });
    }

    clearState() {
        this.setState({
            isResizeHorizon: false,
            isResizeVerical: false,
            isResizeBoth: false,
            moveState: false
        })
    }
    render() {

        return (

            <div key={this.props.key}
                cursor={'e-resize'}
                onClick={() => { this.props.onClick() }}
                style={{
                    padding: "5px 5px 5px 5px !important",
                    position: "absolute", zIndex: this.props.index, display: 'flex', flexDirection: 'column',
                    top: this.state.fullMode === true ? 0 : this.state.top,
                    left: this.state.fullMode === true ? 0 : this.state.left,
                    borderRadius: 3,

                    width: this.state.fullMode === true ? '100%' : this.state.width,
                    height: this.state.fullMode === true ? '100%' : this.state.height,
                    //  userSelect: 'none',
                    backgroundColor: 'white'
                }}>
                <div style={{
                    padding: 10, width: '100%', height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden'
                }}>
                    {/* header */}
                    <div
                        // onMouseMove={this.handleMouseMove.bind(this)}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ lastMouseX: e.screenX, lastMouseY: e.screenY, moveState: true })
                            this.props.onActiveMove(this);
                        }}


                        //     onMouseLeave={(e) => {  moveState  = false }}
                        className="noselect"
                        style={{
                            height: 40, width: '100%', borderBottom: '1px solid rgb(233,233,233)', backgroundColor: "rgb(130,210,150)", cursor: "move", display: 'flex',
                            justifyContent: 'center', alignItems: 'center', padding: 6, borderRadius: 2, position: 'relative'
                        }}>
                        <div style={{
                            position: "absolute", right: 7, top: 0, bottom: 0, backgroundColor: '', width: 60, display: 'flex',
                            justifyContent: "center", alignItems: "center"
                        }}>
                            {this.state.fullMode === false ?
                                <div
                                    onClick={() => { this.setState({ fullMode: true }) }}
                                    style={{
                                        cursor: 'pointer', padding: "0px 8px 0px 8px", marginRight: 5, backgroundColor: 'rgba(233,233,233,0.6)', fontSize: 12, border: '1px solid rgb(233,233,233)'
                                        , boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)'
                                    }}> <i className="fa fa-window-maximize" aria-hidden="true"></i>
                                </div>
                                :
                                <div
                                    onClick={() => { this.setState({ fullMode: false }) }}
                                    style={{
                                        cursor: 'pointer', padding: "0px 8px 0px 8px", marginRight: 5, backgroundColor: 'rgba(233,233,233,0.6)', fontSize: 12, border: '1px solid rgb(233,233,233)'
                                        , boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)'
                                    }}> <i className="fa fa-window-restore" aria-hidden="true"></i>
                                </div>
                            }
                            <div
                                onClick={() => { this.props.onClose() }}
                                style={{
                                    cursor: 'pointer', padding: "0px 8px 0px 8px", backgroundColor: 'rgba(233,233,233,0.6)', fontSize: 12, border: '1px solid rgb(233,233,233)', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.15)'
                                }}> <i className="fa fa-window-close" aria-hidden="true"></i> </div>
                        </div>
                        {this.props.title}
                    </div>
                    <div style={{ backgroundColor: "white", width: "100%", flex: 1, padding: 10, overflow: 'hidden' }}>
                        {this.props.children}
                    </div>



                    <div
                        style={{ position: "absolute", height: 4, left: 5, right: 5, top: 0, borderTop: '2px solid rgb(233,233,233)', cursor: 'ns-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeVerical: true, verticalType: 1, lastMouseY: e.screenY });
                            this.props.onActiveMove(this);
                            e.preventDefault();
                        }}
                    >

                    </div>
                    <div style={{ position: "absolute", height: 4, left: 5, right: 5, bottom: 0, borderBottom: '2px solid rgb(233,233,233)', cursor: 'ns-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeVerical: true, verticalType: 2, lastMouseY: e.screenY });
                            this.props.onActiveMove(this);

                        }}
                    ></div>
                    <div style={{ position: "absolute", width: 4, left: 0, top: 5, bottom: 5, borderLeft: '2px solid rgb(233,233,233)', cursor: 'ew-resize' }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeHorizon: true, horizonType: 1, lastMouseX: e.screenX });
                            this.props.onActiveMove(this);

                        }}

                    ></div>
                    <div style={{ position: "absolute", width: 4, right: 0, top: 5, bottom: 5, borderRight: '2px solid rgb(233,233,233)', cursor: 'ew-resize' }}

                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeHorizon: true, horizonType: 2, lastMouseX: e.screenX });
                            this.props.onActiveMove(this);

                        }}

                    ></div>

                    <div style={{
                        position: "absolute", width: 8, height: 8, left: 0, top: 0, borderLeft: '2px solid rgb(233,233,233)',
                        borderTop: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nwse-resize'

                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 1 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>


                    <div style={{
                        position: "absolute", width: 8, height: 8, right: 0, top: 0, borderRight: '2px solid rgb(233,233,233)',
                        borderTop: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nesw-resize'
                    }}

                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 2 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>

                    <div style={{
                        position: "absolute", width: 8, height: 8, bottom: 0, right: 0, borderRight: '2px solid rgb(233,233,233)',
                        borderBottom: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nwse-resize'
                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 3 });
                            this.props.onActiveMove(this);

                        }}
                    ></div>



                    <div style={{
                        position: "absolute", width: 8, height: 8, bottom: 0, left: 0, borderLeft: '2px solid rgb(233,233,233)',
                        borderBottom: '2px solid rgb(233,233,233)', borderRadius: 2
                        , cursor: 'nesw-resize'
                    }}
                        onMouseDown={(e) => {
                            if (this.state.fullMode === true) return;
                            this.props.onClick();
                            this.setState({ isResizeBoth: true, horizonType: 1, lastMouseX: e.screenX, lastMouseY: e.screenY, resizeBothType: 4 });
                            this.props.onActiveMove(this);

                        }}

                    ></div>
                </div>


            </div>

        );
    }
}
