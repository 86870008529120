import { Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../libs/actions';
import * as PatientService from '../../services/patientService';
import DetailsHistory from './historydetails';
import HeaderModal from '../../configureHIS/common/headerModal'

export default class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTran: null,
            flagDetailsModal: false,
            patientHistoryInfo: {}
        }
    };


    componentWillMount() {
        this.getListHistory();
    }
    getListHistory() {
        PatientService.getListHistory(this.props.patientID)
            .then((respone) => {
                if (respone.isSuccess === true) {
                    var patientHistoryInfo = respone.item;
                    if (patientHistoryInfo.trans !== undefined && patientHistoryInfo.trans.length > 0) {
                        patientHistoryInfo.trans = patientHistoryInfo.trans.sort((a, b) => {
                            if (moment(b.time).isBefore(moment(a.time)))
                                return -1;
                            return 1;
                        });
                    }
                    this.setState({ patientHistoryInfo })
                } else {
                    Actions.openMessageDialog("Error " + respone.err.msgCode, respone.err.msgString.toString());
                }
            }).catch((ex) => {
                Actions.openMessageDialog("lỗi", ex);
            })
    }

    removeComma(str) {
        if (str) return str.replace(/,/g, '')
        return str
    }

    formatDate(str) {
        if (str) return moment(str).format('DD-MM-YYYY');
        return str
    }

    render() {
        let { patientHistoryInfo } = this.state;
        return (
            <React.Fragment>
                <div style={{ padding: 5 }}>
                    {patientHistoryInfo.trans !== undefined && patientHistoryInfo.trans.length > 0 ?
                        <div>
                            {patientHistoryInfo.trans.map((tran, index) => {
                                return (
                                    <div className="border-register" key={index} style={{ padding: 0 }}>
                                        <div data-toggle="collapse" data-target={`#historyExamTable${index}`} className="h4 font-weight-bold mb-0" id="btn-history">
                                            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px', fontSize: '13px' }}>
                                                <div style={{ width: '50%', flex: 1 }}>
                                                    {this.formatDate(tran.time)} {tran.icd}
                                                </div>
                                                <div style={{ width: '50%', cursor: 'pointer', fontSize: '13px' }} onClick={(e) => {
                                                    this.setState({ selectedTran: tran, flagDetailsModal: true })
                                                    e = e || window.event;
                                                    e.preventDefault();
                                                }}>
                                                    <div><i className="fa fa-eye" style={{ marginRight: 5 }}></i>Xem chi tiết</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id={`historyExamTable${index}`} class="collapse">
                                            <table className="table table-striped table-hover table-bordered w-100">
                                                {/* <thead>
                                                    <th>Ngày</th>
                                                    <th>Loại khám</th>
                                                    <th>Lý do</th>
                                                    <th>Bác sĩ</th>
                                                </thead> */}
                                                <tbody key={'1-' + index}>
                                                    {/* <tr key={'1-' + index}>
                                                        <td>{moment(tran.time).format('DD-MM-YYYY')}</td>
                                                        <td>{tran.ten}</td>
                                                        <td>{tran.lydo}</td>
                                                        <td>{tran.tenbs}</td>
                                                    </tr> */}
                                                    {tran.subtrans.length > 0 && tran.subtrans.map((child, indexChild) => (
                                                        // indexChild > 0 &&
                                                        <tr key={'2-' + indexChild}>
                                                            <td colSpan="4">{child.title}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        // <Timeline>
                        //     {patientHistoryInfo.trans.map((tran, index) => {
                        //         return (
                        //             <Timeline.Item key={index}>
                        //                 <Collapse accordion>
                        //                     <Panel header={`${this.formatDate(tran.time)}`}>
                        //                         <div style={{ display: 'flex' }}>
                        //                             <div style={{ flex: 3 }}>
                        //                                 {tran.subtrans && tran.subtrans.map((subtran, index) => {
                        //                                     return (
                        //                                         <div key={index}>
                        //                                             <p style={{ textAlign: 'start' }}>{subtran.title}</p>
                        //                                         </div>
                        //                                     )
                        //                                 })}
                        //                             </div>
                        //                             {this.props.iShowResult === true &&
                        //                                 <div>
                        //                                     <Button onClick={() => {
                        //                                         this.setState({ selectedTran: tran, flagDetailsModal: true })
                        //                                     }}><i className="fa fa-eye" style={{marginRight: 10}}></i>Chi tiết</Button>
                        //                                 </div>
                        //                             }
                        //                         </div>
                        //                     </Panel>
                        //                 </Collapse>
                        //             </Timeline.Item>
                        //         )
                        //     })}
                        // </Timeline>
                        :
                        <div>
                            Chưa có lịch sử khám
                        </div>
                    }
                </div>
                {this.state.flagDetailsModal === true &&
                    <Modal maskClosable={true} visible={this.state.flagDetailsModal}
                        onCancel={() => {
                            this.setState({
                                flagDetailsModal: false,
                                selectedTran: null
                            })
                        }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        footer={null}
                        okButtonProps={{ style: { display: 'none' } }}
                        onOk={() => {
                            this.setState({
                                flagDetailsModal: false,
                                selectedTran: null
                            })
                        }}
                        width={'90%'}
                        placement="top"
                        style={{ overflow: "hidden" }}
                    >
                        <React.Fragment>
                            <HeaderModal title="Lịch sử khám" onClose={() => {
                                this.setState({
                                    flagDetailsModal: false,
                                    selectedTran: null
                                })
                            }} />
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ flex: 1 }}>
                                    {this.state.selectedTran != null &&
                                        <DetailsHistory mabn={this.props.patientID} selectedTran={this.state.selectedTran}></DetailsHistory>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}
