import { Form, Table, Input, Pagination, Modal, Popconfirm, Radio, Select, notification, Upload, Tag } from 'antd';
import React, { Component } from 'react';
import * as ConfigService from '../../services/configService';
import SimpleReactValidator from 'simple-react-validator';
import * as KhoaPhongServices from '../../configureHIS/departmentConfig/services/PhongKhamService';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';

const initialState = {
    currentPage: 1,
    pageSize: 10,
    totalRecords: 0,
    visible: false,
    visibleCreate: false,
    makhoaInit: '',
    makpInit: '',
    visibleImport: false
}
let task = null;
const { Option } = Select;
const { Dragger } = Upload;

export default class CauHinhMaKhoa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            key: "",
            tenkhoa: "",
            makhoa: "",
            lstKhoaBHYT: [],
            radiocheck: 0,
            lstdepartment: [],
            makp: null,
            idkhoa: 0,
            tenkhoaUpdate: "",
            makhoaUpdate: "",
            tenkpUpdate: "",
            fileList: [],
            updateRowCount: 0,
            insertRowCount: 0,
            showImportResult: false
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    };
    componentDidMount() {
        this.loadDanhSachKhoaBHYT();
        this.loadDepartment();
    }
    componentWillUnmount() {
        if (task) {
            clearTimeout(task);
        }
    }
    loadDanhSachKhoaBHYT(page = this.state.currentPage, size = this.state.pageSize) {
        ConfigService.getDanhSachkhoaBHYT(this.state.key, this.state.radiocheck, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKhoaBHYT: result.data,
                    totalRecords: result.totalRecords
                });
            }
        }).catch(err => {
        })
    }
    loadDepartment() {
        KhoaPhongServices.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }
    createKhoaBHYT() {
        let { makhoa, tenkhoa, makp } = this.state;
        if (this.validator.fieldValid('Tên khoa') && this.validator.fieldValid('Mã khoa') && this.validator.fieldValid('Khoa khám bệnh')) {
            const data = {
                makhoa: makhoa,
                tenkhoa: tenkhoa,
                makp: makp
            }
            ConfigService.createKhoaBHYT(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.loadDanhSachKhoaBHYT();
                    this.resetState();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            }).catch(() => {
            })
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    updateKhoaBHYT() {
        let { makhoaUpdate, tenkhoaUpdate, makpUpdate, idkhoa, makhoaInit, makpInit } = this.state;
        if (this.validator.fieldValid('Tên khoa cập nhật') && this.validator.fieldValid('Mã khoa cập nhật') && this.validator.fieldValid('Khoa khám bệnh cập nhật')) {
            const data = {
                id: idkhoa,
                makhoa: makhoaUpdate,
                tenkhoa: tenkhoaUpdate,
                makp: makpUpdate,
                makhoaInit: makhoaInit,
                makpInit: makpInit
            }
            ConfigService.updateKhoaBHYT(data).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: 'Cập nhật thành công' });
                    this.loadDanhSachKhoaBHYT();
                    this.validator.hideMessages();
                    this.setState({
                        visible: false
                    });
                }
                else {
                    notification.warning({ message: res.err.msgString });
                }
            }).catch(() => {
            });
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteKhoaBHYT(id) {
        ConfigService.deleteKhoaBHYT(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.onReload();
                this.handleCancel();
            }
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(() => {
        })
    }
    exportDanhSachKhoaBHYT() {
        Actions.setLoading(true);
        ConfigService.exportDanhSachKhoaBHYT(this.state.radiocheck).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "DanhSachMappingMaKhoaQuyChuanBYT";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    templateKhoaBHYT = () => {
        Actions.setLoading(true);
        ConfigService.templateKhoaBHYT().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "DanhMucMaKhoaQuyChuanBYT";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    handleUpload() {
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        ConfigService.importKhoaBHYT(formData).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Hoàn tất import dữ liệu" });
                this.setState({
                    fileList: [],
                    updateRowCount: res.updateRowCount,
                    insertRowCount: res.insertRowCount,
                    showImportResult: true
                });
                this.loadDanhSachKhoaBHYT();
            }
            else {
                notification.warning({ message: res.err.msgString });
            }
        }).catch(err => {
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            visibleCreate: false
        });
    }
    onReload = () => {
        this.loadDanhSachKhoaBHYT();
    }
    showModalCreate = () => {
        this.setState({
            visibleCreate: true
        });
    }
    resetState = () => {
        this.setState({
            makhoa: '',
            tenkhoa: '',
            tenkp: '',
            makp: null
        });
        this.validator.hideMessages();
    }
    render() {
        let { fileList } = this.state;
        const props = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        };
        let dataSource = this.state.lstKhoaBHYT.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columns = [
            {
                title: 'STT',
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            {
                title: 'Mã khoa BHYT',
                width: 150,
                dataIndex: 'makhoa',
                key: 'makhoa',
                align: 'center',
            },
            {
                title: 'Tên khoa BHYT',
                dataIndex: 'tenkhoa',
                key: 'tenkhoa',
                align: 'left',
            },
            {
                title: 'Mã khoa',
                dataIndex: 'makp',
                key: 'makp',
                align: 'center',
            },
            {
                title: 'Tên khoa',
                dataIndex: 'tenkp',
                key: 'tenkp',
                align: 'left',
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật khoa"
                                onClick={() => {
                                    this.setState({
                                        idkhoa: row.id,
                                        tenkhoaUpdate: row.tenkhoa,
                                        makhoaUpdate: row.makhoa,
                                        makpUpdate: row.makp,
                                        tenkpUpdate: row.tenkp,
                                        makhoaInit: row.makhoa,
                                        makpInit: row.makp,
                                        visible: true
                                    });
                                }}>
                                <i style={{ marginRight: 10 }} className="fa fa-edit"></i>Cập nhật
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.deleteKhoaBHYT(row.makhoa) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-danger" style={{ marginLeft: "15px" }}
                                ><i className="fa fa-eraser" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div style={{ padding: "5px 20px" }}>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >Download</a>
                        <div className='h4 text-center mt-2 mb-3 font-bold text-uppercase'>Cấu hình danh mục mã khoa</div>
                        <div className="row no-gutters col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item>
                                <Input
                                    style={{ width: 300 }}
                                    placeholder="Tìm kiếm với mã khoa hoặc tên khoa"
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={
                                        () => {
                                            task = setTimeout(() => { this.loadDanhSachKhoaBHYT() }, 300);
                                        }
                                    } />
                            </Form.Item>
                            <Form.Item>
                                <Radio.Group
                                    value={this.state.radiocheck}
                                    onChange={(e) => {
                                        this.setState({
                                            radiocheck: e.target.value
                                        });
                                        task = setTimeout(() => {
                                            this.loadDanhSachKhoaBHYT();
                                        }, 300);
                                    }}
                                >
                                    <Radio value={0}>Tất cả</Radio>
                                    <Radio value={1}>Chưa mapping</Radio>
                                    <Radio value={2}>Đã mapping</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <button
                                    className="btn btn-success"
                                    onClick={this.showModalCreate} >
                                    <i style={{ marginRight: 10 }} className="fa fa-plus" />Tạo mới
                                </button>
                                <button
                                    className="btn btn-primary"
                                    style={{ marginLeft: 10 }}
                                    onClick={() =>
                                        this.setState({
                                            visibleImport: true
                                        })
                                    }>
                                    <i className="fa fa-upload" style={{ marginRight: '10px' }} />Nhập danh sách
                                </button>
                                <button
                                    className="btn btn-primary" style={{ marginLeft: 10 }}
                                    onClick={() => { this.exportDanhSachKhoaBHYT() }} >
                                    <i style={{ marginRight: 10 }} className="fas fa-download" />Xuất excel
                                </button>
                            </Form.Item>
                        </div>
                        <div className="table-responsive" style={{ paddingTop: 20 }}>
                            <Table
                                dataSource={dataSource}
                                scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} />
                        </div>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            current={this.state.currentPage}
                            onChange={(page, size) => {
                                this.setState({
                                    currentPage: page,
                                    pageSize: size
                                });
                                this.loadDanhSachKhoaBHYT(page, size);
                            }}
                            onShowSizeChange={(page, size) =>
                                this.loadDanhSachKhoaBHYT(page, size)
                            }
                            showSizeChanger={true}
                        />
                        {/* Cập nhật */}
                        <Modal maskClosable={false}
                            width={800}
                            style={{ padding: 10 }}
                            visible={this.state.visible}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Cập nhật mapping mã khoa</h3>
                            </div>
                            <div className="row no-gutters" style={{ display: 'flex', marginBottom: 10, justifyContent: 'center' }}>
                                <div className="col-md-6">
                                    <Form.Item label="Mã khoa:">
                                        <Input
                                            name="makhoa"
                                            maxLength={3}
                                            placeholder="Mã khoa theo quy chế của Bộ Y Tế"
                                            value={this.state.makhoaUpdate}
                                            onChange={(e) => {
                                                this.setState({ makhoaUpdate: e.target.value })
                                            }} />
                                        {this.validator.message('Mã khoa cập nhật', this.state.makhoaUpdate, 'required|max:3')}
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item label="Tên khoa:">
                                        <Input
                                            placeholder="Tên khoa theo quy chế của Bộ Y Tế"
                                            value={this.state.tenkhoaUpdate}
                                            onChange={(e) => {
                                                this.setState({ tenkhoaUpdate: e.target.value })
                                            }} />
                                        {this.validator.message('Tên khoa cập nhật', this.state.tenkhoaUpdate, 'required')}
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "10px" }}>
                                <hr className="btn-color" style={{ width: "100%" }} />
                                <p style={{ textTransform: 'uppercase', position: 'absolute', transform: "translateY(40%)", backgroundColor: "#fff", width: "15%" }}>Mapping</p>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <Form.Item label="Chọn khoa:">
                                        <Select
                                            showSearch
                                            defaultValue={this.state.makpUpdate == null ? "" : "[" + this.state.makpUpdate + "] - " + this.state.tenkpUpdate}
                                            value={this.state.makpUpdate}
                                            optionFilterProp="children"
                                            onChange={(e, data) => {
                                                this.setState({
                                                    makpUpdate: e,
                                                    selectedKhoa: data.value
                                                });
                                            }}
                                        >
                                            {this.state.lstdepartment.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.makp}>[{row.makp}] - {row.tenkp}</Option>
                                                );
                                            })}
                                        </Select>
                                        {this.validator.message('Khoa khám bệnh cập nhật', this.state.makpUpdate, "required")}
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button
                                    className="btn btn-success"
                                    onClick={() => this.updateKhoaBHYT()}>
                                    <i className="fa fa-save" style={{ marginRight: 5 }} />Cập nhật
                                </button>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({ visible: false });
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        {/* Tạo mới */}
                        <Modal maskClosable={true}
                            width={800}
                            style={{ padding: 10 }}
                            visible={this.state.visibleCreate}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                                <h3>Thêm mapping mã khoa</h3>
                            </div>
                            <div className="row no-gutters" style={{ display: "flex", justifyContent: "center" }}>
                                <div className="col-md-6">
                                    <Form.Item label="Mã khoa:">
                                        <Input
                                            name="makhoa"
                                            maxLength={3}
                                            placeholder="Mã khoa theo quy chế của Bộ Y Tế"
                                            value={this.state.makhoa}
                                            onChange={(e) => {
                                                this.setState({ makhoa: e.target.value })
                                            }} />
                                        {this.validator.message('Mã khoa', this.state.makhoa, 'required|max:3')}
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item label="Tên khoa:">
                                        <Input
                                            name="tenkhoa"
                                            placeholder="Tên khoa theo quy chế của Bộ Y Tế"
                                            value={this.state.tenkhoa}
                                            onChange={(e) => {
                                                this.setState({ tenkhoa: e.target.value })
                                            }} />
                                        {this.validator.message('Tên khoa', this.state.tenkhoa, 'required')}
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row no-gutters" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "10px" }}>
                                <hr className="btn-color" style={{ width: "100%" }} />
                                <p style={{ textTransform: 'uppercase', position: 'absolute', transform: "translateY(40%)", backgroundColor: "#fff", width: "15%" }}>Mapping</p>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <Form.Item label="Chọn khoa:">
                                        <Select
                                            showSearch
                                            placeholder="Nhập mã hoặc tên khoa để tìm kiếm"
                                            value={this.state.makp}
                                            optionFilterProp="children"
                                            onChange={(e, data) => {
                                                this.setState({
                                                    makp: e,
                                                    selectedKhoa: data.value
                                                });
                                            }}
                                        >
                                            {this.state.lstdepartment.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.makp}>[{row.makp}] - {row.tenkp}</Option>
                                                );
                                            })}
                                        </Select>
                                        {this.validator.message("Khoa khám bệnh", this.state.makp, "required")}
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20px" }}>
                                <button
                                    className="btn btn-success"
                                    onClick={() => this.createKhoaBHYT()}>
                                    <i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm
                                </button>
                                <button
                                    className="btn btn-success"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.resetState();
                                    }}>
                                    <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới
                                </button>
                                <button
                                    className="btn btn-danger"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({ visibleCreate: false });
                                        this.resetState();
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </Modal>
                        {/* Import */}
                        <Modal
                            maskClosable={true}
                            width={500}
                            visible={this.state.visibleImport}
                            onCancel={this.handleCancel}
                            cancelText='Đóng'
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div className="alert alert-danger">
                                    <i className="fas fa-exclamation-triangle" />{'\u00A0'}Thao tác này có thể làm mất dữ liệu mapping đã thực hiện!
                                </div>
                                <div>
                                    <Dragger
                                        {...props}
                                        accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        maxCount={1}
                                        style={{ width: '100%' }}>
                                        <div style={{ position: 'relative', width: 400 }}>
                                            <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                            <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                            <p className="ant-upload-hint">
                                                Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                            </p>
                                        </div>
                                    </Dragger>
                                </div>
                                <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                                    <p style={{ textTransform: "uppercase", textAlign: "center", display: this.state.showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                                    <p style={{ display: this.state.updateRowCount > 0 ? 'flex' : 'none' }}>Cập nhật:{'\u00A0'}<Tag color="red">{`${this.state.updateRowCount}`}</Tag> hàng dữ liệu.</p>
                                    <p style={{ display: this.state.insertRowCount > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="red">{`${this.state.insertRowCount}`}</Tag> hàng dữ liệu.</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                                    <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.handleUpload()}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import</button>
                                    <button className="btn btn-primary" style={{ marginLeft: 10 }} onClick={() => this.templateKhoaBHYT()}>Tải file mẫu</button>
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                        onClick={() => {
                                            this.setState({
                                                visibleImport: false,
                                                fileList: [],
                                                updateRowCount: 0,
                                                insertRowCount: 0,
                                                showImportResult: false
                                            });
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}