import * as ApiCaller from '../libs/httpRequests'

const prefix = 'Patient';

export function GetListByExamRoom(filter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListByExamRoom`, filter).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetExaminationDetail(examId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetExaminationDetail/${examId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListTreatmentDirection() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListTreatmentDirection`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListRegisteredPatient(clinicID, roomCode, patientTxtSearch, isExamed, dateFrom, dateTo) {
    var condition = "";
    if (patientTxtSearch && patientTxtSearch !== '') {
        condition += `&patientTxtSearch=${patientTxtSearch}`
    }
    if (isExamed != null) {
        condition += `&isExamed=${isExamed}`
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListRegisteredPatient?roomCode=${roomCode}&clinicID=${clinicID + condition}&dateFrom=${dateFrom}&dateTo=${dateTo}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function searchPatient(key, pageNumber = 1, pageSize = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsbnsearch?key=${key}&pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err) 
        })
    });
}
export function GetAllExamedPatientDone(tungay, denngay, key, clinicID, page, size) {
    var condition = "";
    if (key && key !== '') {
        condition += `&key=${key}`
    }
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetAllExamedPatientDone?tungay=${tungay}&denngay=${denngay}&clinicID=${clinicID + condition}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListBN(key = '', coporatorFilter = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbenhnhan?key=${key}&coporation=${coporatorFilter}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function SearchBenhNhan(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/timkiembenhnhan?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function Update(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatebn`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateCheckZalo(mabn, check) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateCheckZalo?mabn=${mabn}&check=${check}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function updateUserZalo(mabn, phone) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateUserZalo?mabn=${mabn}&phone=${phone}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachQuanHe(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachQuanHe?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function XoaQuanHe(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XoaQuanHe`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThemQuanHe(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThemQuanHe`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachDauSinhTon(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachDauSinhTon?mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function XoaDauSinhTon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XoaDauSinhTon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThemDauSinhTon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThemDauSinhTon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getListHistory(PatientID) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetHistory?PatientID=${PatientID}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function getHistoryDetails(maql, ngay, mabn, type = "", noitru = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetHistoryDetails?maql=${maql}&ngay=${ngay}&mabn=${mabn}&type=${type}&noitru=${noitru}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function DanhSachTaiKhoanBenhNhan(key = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachTaiKhoanBenhNhan?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CreateMobileAccount(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateMobileAccount`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function cancelExam(maql, clrCopporator = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/cancelExam/${maql}&clrCopporator=${clrCopporator}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};
//TODO CHECK server not check
export function changePK(maql, makp) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/changePK`, { maql: maql, makp: makp }).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};
export function UploadFile(data, patientid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostWithFile(`${prefix}/uploadfile/${patientid}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListFilePatient(patientid) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListFilePatient?patientid=${patientid}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DownloadFilePatient(path) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownloadFilePatient?filePath=${path}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DeleteFilePatientById(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DeleteFilePatientById?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateNguoiGioiThieu(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateNguoiGioiThieu`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListNguoiGioiThieu(key = '', getAll=true, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListNguoiGioiThieu?key=${key}&getall=${getAll}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function UpdateHienTiNguoiGioiThieu(id, isdeleted) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHienTiNguoiGioiThieu?id=${id}&isdeleted=${isdeleted}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function XoaNguoiGioiThieu(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/XoaNguoiGioiThieu?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachHoSoTheoNgay(dateFrom, dateTo, key = '', page = 1, size = 10, mabs = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachHoSoTheoNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&mabs=${mabs}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListPatientExam(dateFrom, dateTo, key = '', page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListPatientExam?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachKetQuaCDHA(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachKetQuaCDHA?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DownloadKetQuaKhamBenh(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/DownloadKetQuaKhamBenh?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function mauImportDanhSachBenhNhan(coporationid,banggia) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/mauimportdanhsachbenhnhan?coporationid=${coporationid}&banggia=${banggia}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function importDanhSachBenhNhan(data, hinhthucthanhtoan) {
    return new Promise((resolve,reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/importdanhsachbenhnhan?paymenttype=${hinhthucthanhtoan}`, data).then( respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetRegisteredPatientByMaql(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetRegisteredPatientByMaql?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function GetDauSinhTon(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDauSinhTon?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function getNguoiGioiThieuTheoMaQuanLy(mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetNguoiGioiThieuTheoMaQuanLy?maql=${maql}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function GetKenhKhachHang(page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetKenhKhachHang?pageNumber=${page}&pageSize=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function getListLoaiKham(loaiKham, maql, phongkham) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListLoaiKham?maql=${maql}&loaiKham=${loaiKham}&phong=${phongkham}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
}
export function GetListPatientAsync(
        sortingAndPagingModel = {pageIndex: 1, pageSize: 10, sorting: ''}, 
        filterModel = {Keyword: '', CoporationId : ''}
    ) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}&sorting=${sortingAndPagingModel.sorting}`;
    let filter = `&Keyword=${filterModel.Keyword}&CoporationId=${filterModel.CoporationId}`;
    // let filter = `&promotionName=${filterModel.promotionName}`+
    //     `&remark=${filterModel.remark}`+
    //     `&beginingDateFrom=${filterModel.beginingDateFrom}`+
    //     `&beginingDateTo=${filterModel.beginingDateTo}`+
    //     `&endingDateFrom=${filterModel.endingDateFrom}`+
    //     `&endingDateTo=${filterModel.endingDateTo}`;
    // filterModel.promotionTypes.forEach(element => {
    //     filter += `&promotionTypes=${element}`
    // });
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetPatientDetail(mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetReferentList(filterModel)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetReferentList`, filterModel).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
export function GetVitalSigns(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetVitalSigns?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetPatientHistory(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetPatientHistory`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function InsertUpdateVitalSigns(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/InsertUpdateVitalSigns`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function saveOrUpdatePatientAndRegister(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/Register`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getRelationshipInfo(maBN) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetRelationship?pid=${maBN}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};