export const colors = [
    '#e9724d',
    '#d6d727',
    '#92cad1',
    '#79ccb3',
    '#868686',
    '#003f5c',
    '#58508d',
    '#bc5090',
    '#ff6361',
    '#ffa600'
]