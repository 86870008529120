import * as React from 'react';

const styleGlassmorphism = {
    width: "100vw",
    height: "100vh",
    backdropFilter: "blur(1px) saturate(149%)",
    backgroundColor: "rgba(255, 255, 255, 0.51)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

export default function LOADING() {
    return (
        <div style={styleGlassmorphism}>
            <svg className="react_widget__process-loader-img" width="505" height="75" viewBox="0 0 505 75" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path id="line-dark_3975860333" opacity="0.8"
                    d="M1 43.2452L63.1764 38.0207C82.2135 38.0207 82.3882 48.4697 94.9632 48.4697C110.682 48.4697 123.257 51.6044 144.565 38.0207C165.872 24.437 187.042 10.544 204.994 13.1172C232.939 17.1227 262.281 68.3228 292.67 73.1989C327.529 78.7923 331.094 31.0547 374.408 35.9309C417.722 40.8071 407.941 47.0765 431.694 45.335C455.447 43.5935 464.528 41.852 475.008 41.1554C483.391 40.5981 497.596 40.9232 504 41.1554"
                    stroke="url(#paint0_linear_3975860333)"></path>
                <path id="line-yellow_3975860333"
                    d="M504 40.7062C474.658 55.6831 436.234 33.7187 411.084 41.852C398.16 46.0316 351.702 74.2439 333.189 72.1541C314.676 70.0643 290.574 52.301 262.979 25.4819C240.903 4.02664 223.507 0.288226 217.569 1.10093C211.282 1.21703 196.261 3.53903 171.111 23.0438C149.594 39.2049 140.023 44.2901 129.544 47.7731C113.035 53.2604 68.9396 71.0083 52.6969 50.4586C36.4542 29.9089 2.04761 42.5486 2.04761 42.5486"
                    stroke="url(#paint1_linear_3975860333)"></path>
                <path id="line-gray_3975860333"
                    d="M1.34924 42.8969H77.1486C85.3572 42.8969 94.1653 39.4139 99.5041 39.4139C113.476 39.4139 119.831 47.552 126.226 52.5484C143.167 65.7838 156.441 30.9538 179.146 30.9538C201.851 30.9538 204.121 58.048 218.967 59.267C236.432 60.7012 257.39 24.9595 271.712 24.9595C295.988 24.9595 305.594 56.4806 325.156 55.0874C344.717 53.6942 360.086 31.0547 387.681 32.7962C415.276 34.5377 434.488 40.8071 449.858 40.4588C462.153 40.1802 490.726 41.4028 504 42.0994"
                    stroke="url(#paint2_linear_3975860333)"></path>
                <defs>
                    <linearGradient id="paint0_linear_3975860333" x1="1" y1="43.0001" x2="504" y2="43.0001"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"></stop>
                        <stop offset="0.0840632" stopColor="#01c0c8"></stop>
                        <stop offset="0.895833" stopColor="#01c0c8"></stop>
                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint1_linear_3975860333" x1="504" y1="36.6291" x2="2.04761" y2="40.8209"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"></stop>
                        <stop offset="0.111735" stopColor="#01c0c8"></stop>
                        <stop offset="0.897073" stopColor="#01c0c8"></stop>
                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                    </linearGradient>
                    <linearGradient id="paint2_linear_3975860333" x1="1" y1="42" x2="504" y2="42"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"></stop>
                        <stop offset="0.0677083" stopColor="#03a9f3"></stop>
                        <stop offset="0.947917" stopColor="#03a9f3"></stop>
                        <stop offset="1" stopColor="white" stopOpacity="0"></stop>
                    </linearGradient>
                </defs>
                <animate href="#line-dark_3975860333" attributeName="d" dur="2s" calcMode="spline"
                    values=" M1 43.2452L63.1764 38.0207C82.2135 38.0207 82.3882 48.4697 94.9632 48.4697C110.682 48.4697 123.257 51.6044 144.565 38.0207C165.872 24.437 187.042 10.544 204.994 13.1172C232.939 17.1227 262.281 68.3228 292.67 73.1989C327.529 78.7923 331.094 31.0547 374.408 35.9309C417.722 40.8071 407.941 47.0765 431.694 45.335C455.447 43.5935 464.528 41.852 475.008 41.1554C483.391 40.5981 497.596 40.9232 504 41.1554; M1 42.2451L63.1764 44.9999C82.2135 44.9999 82.3882 33.9999 94.9632 33.9999C110.682 33.9999 123.257 57.0836 144.565 43.4999C165.872 29.9162 187.042 60.9267 204.994 63.4999C232.939 67.5053 262.281 2.62376 292.67 7.49993C327.529 13.0932 331.094 40.1237 374.408 44.9999C417.722 49.8761 407.941 28.2415 431.694 26.5C455.447 24.7585 464.528 48.1966 475.008 47.5C483.391 46.9427 497.596 39.9231 504 40.1553; M1 43.2452L63.1764 38.0207C82.2135 38.0207 82.3882 48.4697 94.9632 48.4697C110.682 48.4697 123.257 51.6044 144.565 38.0207C165.872 24.437 187.042 10.544 204.994 13.1172C232.939 17.1227 262.281 68.3228 292.67 73.1989C327.529 78.7923 331.094 31.0547 374.408 35.9309C417.722 40.8071 407.941 47.0765 431.694 45.335C455.447 43.5935 464.528 41.852 475.008 41.1554C483.391 40.5981 497.596 40.9232 504 41.1554; "
                    keyTimes="0; 0.5; 1" keySplines="0.5 0 0.5 1; 0.5 0 0.5 1;" repeatCount="indefinite"></animate>
                <animate href="#line-yellow_3975860333" attributeName="d" dur="2s" calcMode="spline"
                    values=" M504 40.7062C474.658 55.6831 436.234 33.7187 411.084 41.852C398.16 46.0316 351.702 74.2439 333.189 72.1541C314.676 70.0643 290.574 52.301 262.979 25.4819C240.903 4.02664 223.507 0.288226 217.569 1.10093C211.282 1.21703 196.261 3.53903 171.111 23.0438C149.594 39.2049 140.023 44.2901 129.544 47.7731C113.035 53.2604 68.9396 71.0083 52.6969 50.4586C36.4542 29.9089 2.04761 42.5486 2.04761 42.5486; M504 39.7061C474.658 54.683 436.803 36.734 411 31C397.5 28 348.879 25.5 333.189 36C317.5 46.5 280.021 53 268.5 54C256.979 55 231 58.5 217.569 54C204.138 49.5 191 40.5 171.111 45.4999C151.223 50.4999 144.588 32 129.544 23.5C114.5 15 72.1969 14.9999 52.6969 34.4999C33.1969 53.9999 2.04761 41.5486 2.04761 41.5486; M504 40.7062C474.658 55.6831 436.234 33.7187 411.084 41.852C398.16 46.0316 351.702 74.2439 333.189 72.1541C314.676 70.0643 290.574 52.301 262.979 25.4819C240.903 4.02664 223.507 0.288226 217.569 1.10093C211.282 1.21703 196.261 3.53903 171.111 23.0438C149.594 39.2049 140.023 44.2901 129.544 47.7731C113.035 53.2604 68.9396 71.0083 52.6969 50.4586C36.4542 29.9089 2.04761 42.5486 2.04761 42.5486; "
                    keyTimes="0; 0.5; 1" keySplines="0.5 0 0.5 1; 0.5 0 0.5 1;" repeatCount="indefinite"></animate>
                <animate href="#line-gray_3975860333" attributeName="d" dur="2s" calcMode="spline"
                    values=" M1.34924 42.8969C1.34924 42.8969 68.5 44 77.1486 42.8969C85.7971 41.7939 94.1653 39.4139 99.5041 39.4139C113.476 39.4139 119.831 47.552 126.226 52.5484C143.167 65.7838 156.441 30.9538 179.146 30.9538C201.851 30.9538 204.121 58.048 218.967 59.267C236.432 60.7012 257.39 24.9595 271.712 24.9595C295.988 24.9595 305.594 56.4806 325.156 55.0874C344.717 53.6942 360.086 31.0547 387.681 32.7962C415.276 34.5377 434.488 40.8071 449.858 40.4588C462.153 40.1802 490.726 41.4028 504 42.0994; M1.34924 41.8969C1.34924 41.8969 59 31 75.5 31C92 31 89.5 39.5 99.5 41.8969C113.087 45.1537 116 33 127 28C138 23 156.795 50.5 179.5 50.5C202.205 50.5 204.155 25.2809 219 26.5C236.465 27.9342 263.678 58.4999 278 58.4999C302.277 58.4999 305.594 24.8931 325.156 23.4999C344.717 22.1067 360.086 42.2585 387.681 44C415.276 45.7415 434.488 26.3483 449.858 26C462.153 25.7214 490.726 40.4028 504 41.0994; M1.34924 42.8969C1.34924 42.8969 68.5 44 77.1486 42.8969C85.7971 41.7939 94.1653 39.4139 99.5041 39.4139C113.476 39.4139 119.831 47.552 126.226 52.5484C143.167 65.7838 156.441 30.9538 179.146 30.9538C201.851 30.9538 204.121 58.048 218.967 59.267C236.432 60.7012 257.39 24.9595 271.712 24.9595C295.988 24.9595 305.594 56.4806 325.156 55.0874C344.717 53.6942 360.086 31.0547 387.681 32.7962C415.276 34.5377 434.488 40.8071 449.858 40.4588C462.153 40.1802 490.726 41.4028 504 42.0994; "
                    keyTimes="0; 0.5; 1" keySplines="0.5 0 0.5 1; 1 0 0.5 0.5;" repeatCount="indefinite"></animate>
            </svg>
        </div>
    )
}