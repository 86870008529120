import { Form, Button, Input, Modal, notification, Pagination, Select, Switch, Table, Menu, Dropdown, Tag, Tooltip, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import HeaderModal from '../../../../configureHIS/common/headerModal';
import * as Actions from '../../../../libs/actions';
import * as MedicineService from '../../../../services/medicineService';
import * as StatisticService from '../../../../services/statisticService';
import CreateComponent from './createComponent';
import UpdateComponent from './updateComponent';

const { Option, OptGroup } = Select;
const { Dragger } = Upload;
var _hiddenLink = {}
export default function Material() {

    const [lstThuoc, setLstThuoc] = useState([]);
    const [detail, setDetail] = useState(null);
    const [lstHangSX, setLstHangSX] = useState([]);
    const [lstNuocSX, setLstNuocSX] = useState([]);
    const [lstDVT, setLstDVT] = useState([]);
    const [lstLoai, setLstLoai] = useState([]);
    const [lstDangThuoc, setLstDangThuoc] = useState([]);
    const [lstNhom, setLstNhom] = useState([]);
    const [key, setKey] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [size, setSize] = useState(10);
    const [manhom, setManhom] = useState(0);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleImport, setVisibleImport] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [showImportResult, setShowImportResult] = useState(false);
    const [insertRow, setInsertRow] = useState(0);
    const [rejectRow, setRejectRow] = useState(0);
    const [rejectList, setRejectList] = useState('');
    const [updateRow, setUpdateRow] = useState(0);
    const [visibleImportInfo, setVisibleImportInfo] = useState(false);
    const [loaiDmbd, setLoaiDmbd] = useState(0);
    const _hiddenLink2 = useRef();
    const createComponent = useRef();

    useEffect(() => {
        LoadNhom();
        loadDanhSachThuoc();
        loadLoaiDMBD();
        loadNuocSX();
        loadHSX('');
        loadDangThuoc();
    }, []);


    useEffect(() => {
        if (currentPage === 0) {
            return;
        }
        loadDanhSachThuoc()
    }, [loaiDmbd, manhom, currentPage, size]);

    const LoadNhom = () => {
        MedicineService.DanhSachNhomThuoc().then(result => {
            if (result.isSuccess) {
                let lstNhom = [];
                lstNhom.push({ value: 0, label: 'Tất cả' })
                lstNhom = lstNhom.concat(result.data);
                setLstNhom(lstNhom)
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm thuốc bị lỗi' });
        })
    }

    const loadDanhSachThuoc = () => {
        Actions.setLoading(true);
        MedicineService.DanhSachLoaiThuoc(key, loaiDmbd, currentPage,  size, false).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                setLstThuoc(result.data)
                if (result.data.length > 0) {
                    setTotalRecords(result.data[0].totalRecords)
                }
            }
            else {
                setLstThuoc([])
                setTotalRecords(0)
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách thuốc bị lỗi' });
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    const loadHSX = (e, page = 1, size = 10) => {
        MedicineService.HangSanXuat(e, page, size).then(result => {
            if (result.isSuccess) {
                setLstHangSX(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu hãng sản xuất bị lỗi' })
        })
    }
    const loadNuocSX = () => {
        MedicineService.NuocSanXuatThuoc().then(result => {
            if (result.isSuccess) {
                setLstNuocSX(result.data);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nước sản xuất bị lỗi' })
        })
    }
    const loadDangThuoc = () => {
        MedicineService.DangThuoc().then(result => {
            if (result.isSuccess) {
                setLstDangThuoc(result.data)
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dạng thuốc bị lỗi' })
        })
    }
    const loadDonViTinh = (key) => {
        MedicineService.DonViTinh(key).then(result => {
            if (result.isSuccess) {
                setLstDVT(result.data)
            }
        }).catch(err => {
        })
    }
    const loadLoaiDMBD = () => {
        MedicineService.DanhSachLoaiDMBD().then(result => {
            if (result.isSuccess) {
                setLstLoai(result.data)
            }
            else {
            }
        }).catch(err => {
        })
    }

    const showModalCreate = () => {
        setVisibleCreate(true)
        // this.setState({
        //     visibleCreate: true,
        // });
    };

    const handleCancel = e => {
        setVisibleCreate(false)
        if (e === true) {
            loadDanhSachThuoc();
        }
    };

    const setStateValues = (row) => {
        setDetail(row);
        setTimeout(() => {
            setVisibleUpdate(true);
        },100)
    }
    
    const updateHide = (mabd, check) => {
        //this.setState({ loadingHide: true })
        let status = '0';
        if (check === false) {
            status = '1';
        }
        MedicineService.updateHide(mabd, status).then(result => {
            let data = lstThuoc;
            let item = data.find(x => x.id === mabd);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienthi === '1' ? '0' : '1';
                    item.hienthi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            setLstThuoc(data.map(x => ({ ...x })));
            // this.setState({
            //     lstThuoc: data,
            //     loadingHide: false,
            // })
        }).catch(err => {
            console.log(err);
            let data = lstThuoc;
            let item = data.find(x => x.id === mabd);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            setLstThuoc(data.map(x => ({ ...x })));
            //this.setState({ loadingHide: false, lstThuoc: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    const ExportDanhMuc = () => {
        Actions.setLoading(true);
        StatisticService.ExportDanhMucThuoc(1) // 1 LOẠI THUỐC, 2 LOẠI VẬT TƯ, 0 TẤT CẢ
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.href = url;
                    _hiddenLink.download = "ThongKeDanhMucThuoc-VatTu";
                    _hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    const getTemplate = () => {
        Actions.setLoading(true);
        StatisticService.GetTemplate(loaiDmbd).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink2.current.href = url;
                _hiddenLink2.current.download = "Template-Danh-Muc-Thuoc";
                _hiddenLink2.current.click();
            })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const getTemplateExcel = () => {
        Actions.setLoading(true);
        StatisticService.GetTemplateExcel(loaiDmbd).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink2.current.href = url;
                _hiddenLink2.current.download = "Template-Danh-Muc-Thuoc";
                _hiddenLink2.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const dataSource = [...lstThuoc];
    const MedicineColumns = [
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            align: 'left',
            width: 70
        },
        {
            title: 'Tên',
            dataIndex: 'tenbd',
            key: 'tenbd',
            align: 'left',
            width: 150,
            render: (value) => {
                return <span style={{ maxWidth: 150 }}>{value}</span>;
            }
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'tenhc',
            key: 'tenhc',
            align: 'left',
            width: 150,

        },
        {
            title: 'Quy cách',
            dataIndex: 'quycach',
            key: 'quycach',
            align: 'left',
            width: 80
        },
        {
            title: 'Hãng SX',
            dataIndex: 'tenhang',
            key: 'tenhang',
            align: 'left',
            width: 120,
        },
        {
            title: 'Nước',
            dataIndex: 'tennuocsx',
            key: 'tennuocsx',
            align: 'left',
            width: 120,
        },
        {
            title: 'Loại',
            dataIndex: 'tenloai',
            key: 'tenloai',
            align: 'left',
            width: 150,
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'center',
            width: 80
        },
        {
            title: 'Dạng',
            dataIndex: 'dang',
            key: 'dang',
            align: 'center',
            width: 80
        },
        {
            title: 'Giá nhập',
            dataIndex: 'gianhap',
            key: 'gianhap',
            align: 'right',
            width: 80,
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            }
        },
        {
            title: 'Giá bán',
            dataIndex: 'giaban',
            key: 'giaban',
            align: 'right',
            width: 80,
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            }
        },
        // {
        //     title: 'Giá BHYT',
        //     dataIndex: 'gia_bh',
        //     key: 'gia_bh',
        //     align: 'right',
        //     width: 80,
        //     render: (value) => {
        //         return <span>{value.toLocaleString('en-US')}</span>;
        //     }
        // },
        // {
        //     title: 'Hiển thị',
        //     align: 'center',
        //     width: 80,
        //     render: (row) => {
        //         return (
        //             <Switch checkedChildren="Hiện" unCheckedChildren="Ẩn" checked={row.hienthi === '0'}
        //                 onChange={(e) => {
        //                     updateHide(row.id, e)
        //                 }} />
        //         )
        //     }
        // },
        {
            title: 'Bảo quản',
            dataIndex: 'cachbaoquan',
            key: 'cachbaoquan',
            align: 'center',
            width: 100
        },
        {
            title: 'Tồn tối thiểu',
            dataIndex: 'sltoithieu',
            key: 'sltoithieu',
            align: 'right',
            width: 100
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 150,
            render: (row) => {
                return (
                    <div>
                        <Button
                            className="btn btn-color"
                            title="Cập nhật thuốc!"
                            onClick={() => {
                                setStateValues(row);
                            }} >
                            <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                        </Button>
                    </div>
                )
            }
        }
    ];

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <button className="btn" onClick={() => getTemplate()}>Tải file excel mẫu</button>
            </Menu.Item>
            <Menu.Item key="1">
                <button className="btn" onClick={() => setVisibleImport(true)}>Import danh mục</button>
            </Menu.Item>
        </Menu>
    )

    const menuExport = (
        <Menu>
            <Menu.Item key="0">
                <button className="btn" onClick={() => ExportDanhMuc()}>Xuất file excel thống kê</button>
            </Menu.Item>
            <Menu.Item key="1">
                <button className="btn" onClick={() => getTemplateExcel()}>Tải file excel import danh mục</button>
            </Menu.Item>
        </Menu>
    )

    const configFileUpload = {
        showUploadList: {
            showDownloadIcon: false,
        },
        onRemove(file) {
            setFileList([])
        },
        onChange(info) {
            setFileList(info.fileList)
        },
        fileList
    };

    const resetImportResult = () => {
        setInsertRow(0);
        setRejectRow(0);
        setRejectList('');
        setUpdateRow(0);
    }

    const importMedicinesList = () => {
        const formData = new FormData();
        formData.append('file', fileList[0].originFileObj);
        MedicineService.importMedicinesList(formData).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Import thành công" })
                setFileList([]);
                setInsertRow(res.returnObject.insertRow);
                setRejectRow(res.returnObject.rejectRow);
                setRejectList(res.returnObject.rejectList);
                setUpdateRow(res.returnObject.updateRow);
            }
            else {
                res.err.msgString ?
                    notification.warning({ message: res.err.msgString })
                    :
                    notification.warning({ message: "Lỗi import, vui lòng kiểm tra lại dữ liệu" });
                setRejectRow(res.returnObject.rejectRow);
                setRejectList(res.returnObject.rejectList);
                setFileList([])
            }
        }).catch(() => {
            resetImportResult();
            setFileList([])
        })
    }

    return (
        <>
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { _hiddenLink = c }} >download</a>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink2} ></a>
                <HeaderModal title="Danh mục thuốc - vật tư" />
                <div className="row no-gutters" style={{ justifyContent: 'center' }}>
                    <div className="col-auto">
                        <Form.Item style={{ width: '300px' }} >
                            <Select defaultValue={0}
                                onChange={(e) => {
                                    setLoaiDmbd(e)
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={0}>Tất cả loại</Option>
                                <OptGroup label="Nhóm thuốc">
                                    {lstLoai.filter(x => x.nhom === 1).map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </OptGroup>
                                <OptGroup label="Nhóm vật tư">
                                    {lstLoai.filter(x => x.nhom === 2).map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-auto">
                        <Form.Item>
                            <Input
                                value={key}
                                placeholder="Nhập mã hoặc tên"
                                onChange={(e) => setKey(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setCurrentPage(0);
                                        setTimeout(() => {
                                            setCurrentPage(1);
                                        }, 100);
                                    }
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="col-auto">
                        <Form.Item>
                            <Button className="btn btn-color" onClick={() => {
                                setCurrentPage(0);
                                setTimeout(() => {
                                    setCurrentPage(1);
                                }, 100);
                            }
                            }>
                                <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm
                            </Button>
                        </Form.Item>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <Form.Item>
                            <Button className="btn btn-success"
                                onClick={showModalCreate}
                            > <i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</Button>
                        </Form.Item>
                    </div>
                    <div className='col-md-6 mb-2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Form.Item>
                            {/* <Button className="btn btn-color" style={{ marginLeft: 10 }}
                                    onClick={() => { ExportDanhMuc() }}>
                                    <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button> */}
                            <Dropdown
                                overlay={menuExport} trigger={['click']}
                                onClick={e => e.preventDefault()}>
                                <Button className="btn btn-color"
                                    style={{ marginLeft: 10 }}>
                                    <i className="fa fa-upload" style={{ marginRight: '10px' }} />
                                    Xuất excel
                                </Button>
                            </Dropdown>
                            <Dropdown
                                overlay={menu} trigger={['click']}
                                onClick={e => e.preventDefault()}>
                                <Button className="btn btn-color"
                                    style={{ marginLeft: 10 }}>
                                    <i className="fa fa-upload" style={{ marginRight: '10px' }} />
                                    Import
                                </Button>
                            </Dropdown>
                        </Form.Item>
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 180px)' }}>
                    <Table dataSource={dataSource}
                        scroll={{ y: '65vh', x: 'max-content' }}
                        pagination={false}
                        columns={MedicineColumns} rowKey={(e, index) => index}
                        rowClassName={(record) => {
                            if (record.hienthi == '1') return "text-color"
                        }} />
                </div>
                <Pagination
                    current={currentPage}
                    size={size}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { setCurrentPage(page) }}
                    onShowSizeChange={(page, size) => { setSize(size) }}
                    showSizeChanger={true}
                />
            </div>
            <Modal maskClosable={false}
                width={600}
                visible={visibleUpdate}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                    <UpdateComponent detail={detail} onClose={() => { setVisibleUpdate(false); loadDanhSachThuoc() }} />
            </Modal>
            <Modal maskClosable={false}
                width={600}
                visible={visibleCreate}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                destroyOnClose
            >
                <CreateComponent onCancel={handleCancel} type={2} />
            </Modal>
            <Modal visible={visibleImport}
                width={600}
                maskClosable={false}
                onCancel={() => { setVisibleImport(false); resetImportResult() }}
                cancelText='Đóng'
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ width: "100%", maxHeight: 200 }}>
                        <Dragger
                            {...configFileUpload}
                            beforeUpload={() => false}
                            accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            maxCount={1}
                            style={{ width: '100%' }}>
                            <div>
                                <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                <p className="ant-upload-hint">
                                    Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                </p>
                            </div>
                        </Dragger>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                            <p style={{ textTransform: "uppercase", textAlign: "center", display: showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                            <p style={{ display: insertRow > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="green">{`${insertRow}`}</Tag> dữ liệu.</p>
                            <p style={{ display: updateRow > 0 ? 'flex' : 'none' }}>Cập nhật:{'\u00A0'}<Tag color="blue">{`${updateRow}`}</Tag> dữ liệu.</p>
                            <p style={{ display: rejectRow > 0 ? 'flex' : 'none' }}>Lỗi:{'\u00A0'}<Tag color="red">{`${rejectRow}`}</Tag> dữ liệu.</p>
                            <p style={{ display: rejectList ? 'flex' : 'none' }}>Danh sách lỗi:{'\u00A0'}{`${rejectList}`}</p>
                            <div style={{ display: rejectRow > 0 ? 'unset' : 'none', background: "aliceblue", border: "solid 1px #bbb", borderRadius: 15, padding: 9, cursor: "pointer" }}>
                                <Tooltip title={!visibleImportInfo ? "Tìm hiểu nguyên nhân và khắc phục" : "Đóng"}>
                                    <i className={!visibleImportInfo ? "far fa-question-circle" : "far fa-times-circle"}
                                        style={{ fontSize: 18 }}
                                        onClick={() => setVisibleImportInfo(!visibleImportInfo)}></i>
                                </Tooltip><br />
                                <div style={{
                                    visibility: visibleImportInfo ? "visible" : "hidden", display: visibleImportInfo ? "unset" : "none"
                                }}>
                                    <ol style={{ textAlign: "justify" }}>
                                        {rejectRow > 0 &&
                                            <span>
                                                <li>Kiểm tra các thông tin đã được nhập đầy đủ hay chưa.</li>
                                                <li>Các thông tin bắt buộc:
                                                    <ul>
                                                        <li>Nhóm</li>
                                                        <li>Loại</li>
                                                        <li>Tên</li>
                                                        <li>Đơn vị tính</li>
                                                        <li>Nước sản xuất</li>
                                                        <li>Hãng sản xuất</li>
                                                    </ul>
                                                </li>
                                                <li>Phải chọn <b>DẠNG THUỐC</b> nếu chọn <b>NHÓM THUỐC</b>.</li>
                                                <li>Kiểm tra thuốc có tồn tại hay chưa. Đảm bảo thuốc khác thông tin:
                                                    <ul>
                                                        <li>Nhóm</li>
                                                        <li>Loại</li>
                                                        <li>Tên</li>
                                                        <li>Dạng</li>
                                                        <li>Hàm lượng</li>
                                                        <li>Nước sản xuất</li>
                                                        <li>Hãng sản xuất</li>
                                                    </ul>
                                                </li>
                                            </span>}
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <button disabled={fileList.length > 0 ? false : true} className="btn btn-success" onClick={() => importMedicinesList()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    setVisibleImport(false);
                                    setShowImportResult(false);
                                    resetImportResult();
                                    loadDanhSachThuoc();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    )
}