import { DatePicker, Form, Input, notification, Pagination, Table,Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';

const { RangePicker } = DatePicker;
const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(), moment()],
            dataSource: [],
            loai: '',
            tenloai: 'Tất cả',
            txtmabn: '',
            total: 0,
            currentPage: 1,
            currentSize: 10,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        this.GetListSite();
        this.LoadThongKeCongNo();
    }
    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }
    LoadThongKeCongNo() {
        Actions.setLoading(true);
        StatisticService.DanhSachCongNoBenhNhanTheoNgay(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh,
            this.state.txtmabn, this.state.currentPage, this.state.currentSize).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        dataSource: result.data,
                        total: Number(result.totalRecords),
                    });
                }
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
                notification.warning({ message: 'Tải dữ liệu bị lỗi' })
            })
    }
    ExportThongKeCongNo() {
        Actions.setLoading(true);
        StatisticService.ExportCongNo(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh, this.state.txtmabn)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeCongNo";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columns = [
            {
                title: 'Mã',
                dataIndex: 'mabn',
                key: 'mabn',
                width:140,
            },
            {
                title: 'Tên',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width:190,
            },
            {
                title: 'Điện thoại',
                dataIndex: 'dienthoai',
                key: 'dienthoai',
                width:120,
            },
            {
                title: 'Giới tính',
                dataIndex: 'gioitinh',
                key: 'gioitinh',
                width:110,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width:110,
            },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
            },
            {
                title: 'CMND',
                dataIndex: 'cmnd',
                key: 'cmnd',
                width:130,
                render: (text) => {
                    return <span>{text === '0' ? '' : text}</span>;
                },
            },
            {
                title: 'Viện phí',
                dataIndex: 'vienphi',
                key: 'vienphi',
                render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Thu trực tiếp',
                dataIndex: 'thutructiep',
                key: 'thutructiep',
                render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Tạm ứng',
            //     dataIndex: 'tamung',
            //     key: 'tamung',
            //     render: (text) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // },
            {
                title: 'Hoàn trả',
                dataIndex: 'hoantra',
                key: 'hoantra',
                width:100,
                render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            },
            // {
            //     title: 'Khách nợ',
            //     dataIndex: 'tienno',
            //     key: 'tienno',
            //     render: (text) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // },
            // {
            //     title: 'Khách dư',
            //     dataIndex: 'tiendu',
            //     key: 'tiendu',
            //     render: (text) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // }
        ]
        return (
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }}>download</a>
                <div className='h4 text-center mt-2 font-bold text-uppercase'>Thống kê công nợ</div>
                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 15 }}>
                    <div style={{ display: 'flex', marginTop: 10 }}>
                    {this.state.isMultisite === true &&
                            <Form.Item>
                                <Select
                                    style={{ width: '200px' }}
                                    showSearch
                                    allowClear={false}
                                    value={this.state.chinhanh}
                                    onChange={(e) => {
                                        this.setState({ chinhanh: e })
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={-1} value={''}>Chi nhánh</Option>
                                    {this.state.listSite.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.ma}</Option>
                                        )
                                    })}
                                </Select>
                                </Form.Item>
                        }
                        <Form.Item>
                            <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                locale={viVN} onChange={(value => this.setState({ ngay: value }, () =>
                                    this.setState({ currentPage: 1 }, () => {
                                        this.LoadThongKeCongNo()
                                    })
                                ))}
                            />
                        </Form.Item>
                        <Form.Item label="Mã bệnh nhân:">
                            <Input onChange={(e) => this.setState({ txtmabn: e.target.value })} placeholder="Nhập mã bệnh nhân"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({ currentPage: 1 }, () => {
                                            this.LoadThongKeCongNo()
                                        })
                                    }
                                }} />
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <button className="btn btn-color" style={{ margin: "0px 5px", marginTop: "5px" }} onClick={() => {
                            this.setState({ currentPage: 1 }, () => {
                                this.LoadThongKeCongNo()
                            })
                        }}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                    </Form.Item>
                    <Form.Item>
                        <button className="btn btn-color" style={{ margin: "0px 5px", marginTop: "5px" }} onClick={() => { this.ExportThongKeCongNo() }}>
                            <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                    </Form.Item>
                </div>
                <div style={{ height: 'calc(100% - 145px)' }}>
                    <Table
                        scroll={{ x: 'max-content' , y : 0}}
                        pagination={false}
                        dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                </div>

                <Pagination
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={this.state.total}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => {
                        this.setState({ currentPage: page, currentSize: size }, () => {
                            this.LoadThongKeCongNo(page, size)
                        })
                    }}
                    onShowSizeChange={(page, size) => this.setState({ currentPage: page, currentSize: size }, () => {
                        this.LoadThongKeCongNo(page, size)
                    })}
                    showSizeChanger={true}
                    size={this.state.currentSize}
                    current={this.state.currentPage}
                />
            </div>
        )
    }
}
