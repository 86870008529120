import * as ApiCaller from '../libs/httpRequests';

const prefix = 'users';
export function changePass( newPassword)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`Auth/changepass`,newPassword ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function getPermission() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`Users/me`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function getGroupUser(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getGroup/${id}` ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getFeature(groupid)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getFeature/${groupid}` ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateUserGroup(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateUserGroup`,data ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateFeatures(id,data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateFeatures/${id}`,data ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteGroup(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteGroup/${id}` ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateGroup(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateGroup`,data ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CreateGroup(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateGroup`,data ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateHienThiUser(userId,hienthi)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHienThiUser?userId=${userId}&hienthi=${hienthi}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DeleteUserOfGroup(user, group)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteUserOfGroup/${group}/${user}` ).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateGroupUserMultisite(groupid,hienthi)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateGroupUserMultisite?id=${groupid}&hienthi=${hienthi}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CheckPremissionMultisite()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CheckPremissionMultisite`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CheckKyThuatVien()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CheckKyThuatVien`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ClearToken(userID)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ClearToken/${userID}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};