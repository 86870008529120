import { Button, Select } from 'antd';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { isnotnull } from '../libs/util';
import * as CoporationServices from "../services/CorporationServices";

let task = null;

const CoporationReport = forwardRef((props, ref) => {
    const [coporationIds, setCoporationIds] = useState(props.coporationId ?? []);
    const [listCoporations, setListCoporations] = useState([]);
    const [modeMultiple, ] = useState(props.modeMultiple ?? false);
    const [allowClear, ] = useState(props.allowClear ?? false);
    const [requiredField, ] = useState(props.requiredField ?? false);
    const [hide, ] = useState(props.getHide);

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        },
        getListCoporationId: () => {
            return coporationIds;
        },
        getListCoporation: () => {
            return getListCoporation();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setCoporationIds([]);
        getListCoporations();
    }, [props.salesmanIds]);

    useEffect(() => {
        setCoporationIds(props.coporationId);
    }, [props.coporationId])

    const onChangeSelectedCoporation = (groupName) => {
        let listId = listCoporations.filter(x => `${x.coporationParentName} - ${x.coporationChildName}` == groupName).map(x => x.ma);
        var leftOver = (listId ?? []).filter(x => !(coporationIds ?? []).includes(x));
        let newCopIds = [...(coporationIds ?? [])];
        if (isnotnull(leftOver)) {
            newCopIds.push(...leftOver);
        } else {
            newCopIds = newCopIds.filter(x => !listId.includes(x));
        }
        setCoporationIds(newCopIds);
    }

    const delaySearchCoporation = (key) => {
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            getListCoporations(key);
        }, 400);
    }

    const getListCoporations = (key = "") => {
        CoporationServices.GetAllCoporation(key, typeof(hide) == "boolean" ? hide : '', 1, 10, true, props.salesmanIds ?? "").then(result => {
            if (result.isSuccess) {
                setListCoporations(result.data);
            }
        }).catch(err => {
        }).finally(() => {
        })
    }

    const getListCoporation = () => {
        const result = (listCoporations ?? []).filter(x => (coporationIds ?? []).includes(x.ma)).map(x => ({ ma: x.ma, ten: x.ten }));
        return result ?? [];
    }

    return (
        <Fragment>
            <label className={requiredField ? "required-field" : ""}>Nguồn khách hàng</label>
            <Select
                dropdownMatchSelectWidth={750}
                disabled={props.disabled ?? false}
                mode={modeMultiple ? "multiple" : ""}
                allowClear={allowClear}
                showSearch
                className="w-100"
                placeholder="Chọn nguồn khách hàng"
                maxTagCount='responsive'
                value={coporationIds}
                optionLabelProp="ten" //"ten" này là cáo prop  ten={record.ten} trong thẻ <Option>
                filterOption={false}
                onSearch={(e) => delaySearchCoporation(e)}
                onChange={(value) => { 
                    setCoporationIds(value);
                    props.onUpdateCoporation && props.onUpdateCoporation(value);
                }}
            >
                {[...new Set((listCoporations ?? []).map(x => (`${x.coporationParentName} - ${x.coporationChildName}`)))].map((cp, pIndex) => {
                    return (
                        <Select.OptGroup key={pIndex} label={<div>{`Nguồn tổng: ${cp.includes("null") ? "Chưa có thông tin" : cp.split(" - ")[0]} - Nguồn con: ${cp.includes("null") ? "Chưa có thông tin" : cp.split(" - ")[1]}`} {modeMultiple && <Button onClick={() => onChangeSelectedCoporation(cp)} type="link" style={{ cursor: "pointer" }}>Chọn/bỏ chọn tất cả</Button>}</div>}>
                            {/* <Select.Option key={-1} value={-1} header={true} disabled style={{ color: 'black', fontWeight: 'bold', cursor: "default" }}>
                                <div className="d-flex justify-content-between">
                                    <span>Tên</span>
                                    <span>Mã</span>
                                </div>
                            </Select.Option> */}
                            {(listCoporations ?? []).filter(x => `${x.coporationParentName} - ${x.coporationChildName}` == cp).map((record, index) => {
                                return (
                                    <Select.Option key={record.ma} value={record.ma} ma={record.ma} ten={record.ten}>
                                        <div className="d-flex justify-content-between">
                                            <span>{record.ten}</span>
                                            <span>{record.ma}</span>
                                        </div>
                                    </Select.Option>
                                )
                            })}
                        </Select.OptGroup>
                    )
                })
                }
            </Select>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CoporationReport)