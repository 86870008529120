import React, { Component } from 'react';
import { DatePicker, Form, Popconfirm, Pagination, InputNumber, Select, notification, Button, Table, Modal, Input } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as KhoService from '../../services/KhoService';
import * as MedicineService from '../../services/medicineService';
import * as Actions from '../../libs/actions';
import * as PrintService from '../../services/printService';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as StatisticService from '../../services/statisticService';

const { RangePicker } = DatePicker;
const { Option } = Select;
const initialState = {
    makho: '',
    tungay: moment(),
    denngay: moment(),
    visible: false,
    totalRecords: 0,
    totalRecords1: 0,
    key: '',
    sotoa: 0,
}
export default class ThuocBanLe extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstKho: [],
            lstBanle: [],
            lstDetail: [],
            currentPage: 1,
            trangthai: '',
            detail: {},
            isPrint: false,
            ngayRange: [moment(), moment()],
            done: 'Đã thanh toán',
        };
    }
    componentWillMount() {
        this.loadKho();
        this.loadDanhSachBanLe();
    }
    notifyUpdate = () => {
        this.loadDanhSachBanLe();
    }
    loadKho() {
        KhoService.GetListKho().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                    makho: result.data[0].value
                });
            }
            this.form.setFieldsValue({
                makho: result.data[0].value
            })
        }).catch(err => {
        })
    }

    loadDanhSachBanLe(page = 1, size = 10) {
        if (this.state.tungay === null || this.state.tungay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian từ ngày!' });
            return;
        }
        if (this.state.denngay === null || this.state.denngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian đến ngày!' });
            return;
        }
        MedicineService.DanhSachToaBanLe(this.state.key, Number(this.state.sotoa), this.state.tungay.format('YYYY-MM-DD'), this.state.denngay.format('YYYY-MM-DD'), page, size, this.state.trangthai).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBanle: result.data,
                    totalRecords: result.totalRecords,
                    currentPage: page,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách bán lẻ bị lỗi' });
        })
    }
    thongtinHD(ngay, id, page = 1, size = 10) {
        MedicineService.ThongTinHoaDon(ngay, id, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDetail: result.data,
                    totalRecords1: result.totalRecords
                });
            }
            else {
                this.setState({
                    lstNhap: []
                })
            }
        }).catch(err => {
        })
    }
    ThanhToanHoaDon(id, ngay) {
        Actions.setLoading(true);
        MedicineService.ThanhToanHoaDonBanLe(id, ngay).then(result => {
            if (result.isSuccess) {
                this.setState({ isPrint: true })
                notification.success({ message: 'Thao tác thành công!' })
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
            Actions.setLoading(false);
        })
    }
    showModalDetail = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visibleDetail: false
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleDetail: false,
            isPrint: false,
        });
        this.loadDanhSachBanLe();
    };

    PrintConclusion(mabn, id, sotoa, ngay) {
        PrintService.PrintConclusionBanLe(mabn, id, Number(sotoa), ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    PrintUseRetail(id, ngay) {
        PrintService.PrintUseRetail(id, ngay).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    DanhSachThongKeThuocBanLeTheoNgay() {
        Actions.setLoading(true);
        StatisticService.DanhSachThongKeThuocBanLeTheoNgay(this.state.ngayRange[0].format('YYYY-MM-DD'), this.state.ngayRange[1].format('YYYY-MM-DD'), this.state.trangthai)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDanhSachThuocBanLe";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    XoaMotThuocBanLe(row) {
        Actions.setLoading(true);
        MedicineService.XoaMotThuocBanLe(row.id, this.state.detail.ngaylapphieu, row.mathuoc).then(result => {
            if (result.isSuccess) {
                this.setState({ isPrint: true })
                notification.success({ message: 'Thao tác thành công!' })
                this.thongtinHD(this.state.detail.ngaylapphieu, this.state.detail.id, 1, 10);
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
            Actions.setLoading(false);
        })
    }
    XoaPhieuBanLeDaThanhToan(id, ngay) {
        Actions.setLoading(true);
        MedicineService.XoaPhieuBanLeDaThanhToan(id, ngay).then(result => {
            if (result.isSuccess) {
                this.setState({ isPrint: true })
                notification.success({ message: 'Thao tác thành công!' })
                this.loadDanhSachBanLe();
                this.handleCancel();
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
            Actions.setLoading(false);
        })
    }
    render() {
        const columns = [
            {
                title: 'Ngày bán',
                dataIndex: 'ngaylapphieu',
                key: 'ngaylapphieu',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số toa',
                dataIndex: 'sotoa',
                key: 'sotoa',
                align: 'center',

            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                align: 'center',

            },
            {
                title: 'Họ tên',
                dataIndex: 'hoten',
                key: 'hoten',
                align: 'left',

            },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                align: 'center',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'trangthai',
                key: 'trangthai',
                width: 100,
            }
        ];
        let dataSource = this.state.lstBanle.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columnsDetail1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',

            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'datennuocng',
                align: 'left',

            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
                align: 'left',

            },
            {
                title: 'SL xuất',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
        ];
        const columnsDetailDelete = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',

            },
            {
                title: 'Nước',
                dataIndex: 'tennuoc',
                key: 'datennuocng',
                align: 'left',

            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
                align: 'left',

            },
            {
                title: 'SL xuất',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá bán',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giá thực thu',
                dataIndex: 'giathucthu',
                key: 'giathucthu',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Xóa',
                align: 'center',
                render: (row) => {
                    return <Popconfirm
                        title="Xác nhận xóa thuốc này?"
                        onConfirm={() => { this.XoaMotThuocBanLe(row); }}
                        okText="Đồng ý" cancelText="Hủy">
                        <Button danger><i className="fa fa-trash"></i></Button>
                    </Popconfirm>
                },
            }
        ];
        let dataSource2 = this.state.lstDetail.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        let tongtien = 0;
        dataSource2.map(x => tongtien += x.soluong * x.giathucthu)
        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <Form ref={(c) => this.form = c} >
                    <div style={{ padding: "0px 20px" }}>
                        <div className="form-group" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px' }}>
                            <Form.Item style={{ marginRight: 10 }}>
                                <RangePicker allowClear={false} format="DD-MM-YYYY" value={this.state.ngayRange}
                                    locale={viVN} onChange={(value => this.setState({ ngayRange: value, tungay: value[0], denngay: value[1] }, () => this.loadDanhSachBanLe()))}
                                />
                            </Form.Item>
                            <Form.Item style={{ display: 'none' }} name="makho" rules={[{ required: true, message: 'Yêu cầu chọn kho!' }]}>
                                Kho: <Select
                                    value={this.state.makho}
                                    showSearch style={{ width: "130px" }}
                                    onChange={(value) => { this.setState({ makho: value }) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstKho.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ marginRight: 10 }}>
                                <Select
                                    style={{ width: 150 }}
                                    showSearch
                                    defaultValue='-----'
                                    value={this.state.trangthai}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(key) => { this.setState({ trangthai: key }, () => this.loadDanhSachBanLe()) }}>
                                    <Option key={0} value=''>Trạng thái</Option>
                                    <Option key={1} value='1'>Mới</Option>
                                    <Option key={2} value='3'>Đã thanh toán</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ marginRight: 10 }}>Số toa:
                                    <InputNumber placeholder="Nhập số toa"
                                    style={{ width: "150px" }}
                                    min={1}
                                    maxLength={5}
                                    onChange={(e) => this.setState({ sotoa: e })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.loadDanhSachBanLe();
                                        }
                                    }} />
                            </Form.Item>

                            <Form.Item style={{ marginRight: 10 }}>
                                <Input placeholder="Nhập mã hoặc tên bệnh nhân..."
                                    onChange={(e) => this.setState({ key: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.loadDanhSachBanLe();
                                        }
                                    }} />
                            </Form.Item>

                            <button className="btn btn-success" style={{ margin: '-5px 15px 0px 5px' }}
                                onClick={() => this.loadDanhSachBanLe()}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            <Form.Item>
                                <button className="btn btn-primary" onClick={() => { this.DanhSachThongKeThuocBanLeTheoNgay() }}>
                                <i className="fas fa-download" style={{ marginRight:10 }} ></i>Xuất Excel</button>
                            </Form.Item>
                        </div>
                        <Table dataSource={dataSource}
                            pagination={false}
                            columns={columns} rowKey={(e, index) => index}
                            onRow={(row, rowIndex) => {
                                return {
                                    onClick: e => { this.setState({ id: row.id, visibleDetail: true, detail: row, done: row.trangthai }); this.thongtinHD(row.ngaylapphieu, row.id) },
                                };
                            }}
                            rowClassName={(record, rowIndex) => {
                                return "rowCustom"
                            }}
                        />
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.loadDanhSachBanLe(page, size) }}
                            onShowSizeChange={(page, size) => this.loadDanhSachBanLe(1, size)}
                            showSizeChanger={true}
                        />

                    </div>
                </Form>
                <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.visibleDetail}
                    onCancel={this.handleCancel}

                    okButtonProps={{ disabled: true, hidden: true }}
                    cancelButtonProps={{ disabled: false }}
                    footer={[
                        (this.state.trangthai === '3' || this.state.isPrint === true) ?
                            <button className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { this.PrintConclusion(this.state.detail.mabn, this.state.detail.id, this.state.detail.sotoa, (this.state.detail.ngaylapphieu)) }}><i className="fa fa-print"></i></button>
                            :
                            <Popconfirm
                                title="Xác nhận thanh toán đơn bán lẻ?"
                                onConfirm={() => { this.ThanhToanHoaDon(this.state.detail.id, moment(this.state.detail.ngaylapphieu).format('YYYY-MM-DD HH:mm')) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-primary" style={{ marginLeft: "5px" }}>Xác nhận thanh toán</button>
                            </Popconfirm>,
                        <button className="btn btn-danger" style={{ marginLeft: "5px" }} onClick={this.handleCancel}>Đóng</button>,
                    ]}
                >
                    <div style={{ display: 'flex', marginTop: 10 }}>
                        <Form.Item label="ID phiếu:">
                            <Input
                                disabled
                                value={this.state.id} />
                        </Form.Item>
                    </div>
                    <Table dataSource={dataSource2} style={{ display: 'none' }}
                        pagination={false}
                        columns={columnsDetail1} rowKey={(e, index) => index} />
                    <div style={{ marginTop: '30px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>Tên: <strong>{this.state.detail.hoten}</strong></div>
                            <div style={{ flex: 1 }}>Mã BN: <strong>{this.state.detail.mabn}</strong></div>
                        </div>
                        <div>Năm sinh: <strong>{moment(this.state.detail.ngaysinh).year()}</strong></div>
                        <div style={{ display: 'none', marginTop: '15px' }}>
                            <div style={{ flex: 1 }}>
                                <Form.Item label="Hình thức thanh toán:">
                                    <Select style={{ width: '100%', flex: 2 }} defaultValue={1} onChange={(value) => this.setState({ hinhthucthanhtoan: value })}>
                                        <Option key={1} value={1}>Tiền mặt</Option>
                                        <Option key={2} value={2}>Chuyển khoản</Option>
                                        <Option key={3} value={3}>Quẹt thẻ</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Form.Item label="Mức giảm giá (%):">
                                    <InputNumber min={0} max={100} value={this.state.mucgiamgia} onChange={(e) => this.setState({ mucgiamgia: e })} />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                            <Table dataSource={dataSource2} columns={this.state.detail.trangthai === 'Đã thanh toán' ? columnsDetailDelete : columnsDetail1} pagination={false}
                                rowKey={(e, index) => index}
                            />
                            <div style={{ display: 'flex', marginBottom: '8px', justifyContent: 'flex-end', paddingRight: '10px' }}>
                                <div style={{ textAlign: 'right', paddingRight: '10px' }}>Tổng tiền: </div>
                                <div style={{}}><strong>{tongtien.toLocaleString('en-US')}</strong></div>
                            </div>
                            <div style={{ display: 'none', marginBottom: '8px' }}>
                                <div style={{ flex: 2, textAlign: 'right', paddingRight: '10px' }}>Tiền được giảm: </div>
                                <div style={{ flex: 1 }}><strong>{Number((tongtien * (this.state.mucgiamgia / 100)).toFixed(0)).toLocaleString('en-US')}</strong></div>
                            </div>
                            <div style={{ display: 'none', marginBottom: '8px' }}>
                                <div style={{ flex: 2, textAlign: 'right', paddingRight: '10px' }}>Số tiền thanh toán: </div>
                                <div style={{ flex: 1 }}><strong>{Number((tongtien * ((100 - this.state.mucgiamgia) / 100)).toFixed(0)).toLocaleString('en-US')}</strong></div>
                            </div>
                        </div>
                    </div>
                    {/* {this.props.done !== 0 ? <div><strong style={{ color: 'red' }}>{(this.props.done === 1 || this.state.isPrint === true) ? 'Đã thanh toán' : 'Chưa Thanh Toán'}</strong></div>
                        :
                        <div><strong style={{ color: 'red' }}>Chưa xác nhận</strong></div>
                    } */}
                    <div className="form-group" style={{
                        marginTop: 15, display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {/* {(this.props.done !== 0 || this.state.isPrint === true) &&
                            <div>
                                <Button
                                    onClick={() => this.PrintMedicine(this.props.id, this.props.ngay)} style={{ marginRight: 5 }}>
                                    In toa thuốc
                                    </Button>
                                <Button
                                    onClick={() => this.PrintUse(this.props.id, this.props.ngay)} style={{ marginRight: 5 }}>
                                    In cách dùng</Button>
                            </div>
                        } */}
                    </div>
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                        {this.state.trangthai === '3' || this.state.done === 'Đã thanh toán' || this.state.isPrint === true ?
                            <div>
                                <button className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { this.PrintConclusion(this.state.detail.mabn, this.state.detail.id, this.state.detail.sotoa, (this.state.detail.ngaylapphieu)) }}>In toa thuốc</button>
                                <button className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { this.PrintUseRetail(this.state.detail.id, (this.state.detail.ngaylapphieu)) }}>In cách dùng</button>
                                <Popconfirm style={{ marginLeft: "5px" }}
                                    title="Xác nhận xóa thuốc này?"
                                    onConfirm={() => { this.XoaPhieuBanLeDaThanhToan(this.state.detail.id, this.state.detail.ngaylapphieu) }}
                                    okText="Đồng ý" cancelText="Hủy">
                                    <button className="btn btn-danger">Xóa phiếu</button>
                                </Popconfirm>
                            </div>
                            :
                            <Popconfirm
                                title="Xác nhận thanh toán đơn bán lẻ?"
                                onConfirm={() => { this.ThanhToanHoaDon(this.state.detail.id, moment(this.state.detail.ngaylapphieu).format('YYYY-MM-DD HH:mm')) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button className="btn btn-primary" style={{ marginLeft: "5px" }}>Xác nhận thanh toán</button>
                            </Popconfirm>

                        }
                        <button className="btn btn-danger" danger style={{ marginLeft: "5px" }} onClick={this.handleCancel}>Đóng</button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}


