import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Input, Select, Checkbox, Collapse, Popconfirm, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../../components/notify';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import IndicationExam from './registerproduct-indication';
import * as ConfigService from "../../../services/configService";
import * as CorporationServices from '../../../services/CorporationServices';
import * as PatientService from "../../../services/patientService";
import * as NhanVienSerivce from '../../../services/employeeService';
import { connect } from 'react-redux';
/**Hướng dẫn sử dụng component
 ** Màn hình đăng ký dịch vụ
 ** Các component đang sử dụng:
    - src\screens\register\index.js
 ** Các props nhận vào:
 * @param {string} props.maBN: [require] mã bệnh nhân. Bắt buộc có, đặc biệt là màn hình đăng ký / tạo mới tiếp đón 
 * @param {string} props.maQL: [require] mã quản lý. nếu maQL có giá trị thì gọi chỉnh sửa, nếu không thì gọi tạo mới (tạm thời bắt buộc)
 * @param {string} props.siteId: [require] mã chi nhánh (theo mã quản lý)
*/
/**Note:
Get thông tin:
* List nguồn khách hàng: 
    ** api: /api/Coporation/GetListCoporation?key=&page=1&size=10&all=false
    ** table: medibv.coporation
* List người giới thiệu: 
    ** api: api/Patient/GetListNguoiGioiThieu?key=a&getall=false&page=1&size=20
    ** table: medibv.nguoigioithieu
* List sale: 
    ** api: /api/Employee/danhsachnhanvien?key=&page=1&size=100&role=26
    ** table: medibv.nhanvien
* List kênh khách hàng: 
    ** api: pi/config/GetKenhKhachHang?pageNumber=1&pageSize=100&getAll=false
    ** table: 
* List hình thức trả kết quả: 
    ** api: 
    ** table: enum HIS.Enums.SendMessageType
Lưu thông tin
* Nguồn khách hàng: 
    ** table: medibv.nguonkhachhang. column: idcopor
* Người giới thiệu: 
    ** table: medibv.gioithieu. column: mangt
* Sale: 
    ** table: medibv.nguonkhachhang. column: idsale
* Kênh khách hàng: 
    ** table: medibv1.tiepdon. column: marketing
* Hình thức trả kết quả: 
    ** table: medibv1.tiepdon. column: sendby
 */

// const listSendResultTypes = [ // lấy theo SendMessageType trong HIS.Resource/Enum
//   // Sms,
//   // Zalo,
//   // Email
//   {value: 0, text: 'Sms'},
//   {value: 1, text: 'Zalo'},
//   {value: 2, text: 'Email'},
// ]
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;
const RegisterProduct = React.forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const hiddenLink = useRef();
    const indicationRef = useRef();
    const btnChangeCorporationRef = useRef();
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    //state
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
    //model
    const [maQL, setMaQL] = useState(props.maQL);
    const [maBN, setMaBN] = useState(props.maBN);
    const [siteId, setSiteId] = useState(props.siteId);
    const [corporationId, setCorporationId] = useState();
    const [corporationName, setCorporationName] = useState();
    const [tempCorporation, setTempCorporation] = useState();
    const [nguoiGioiThieuId, setNguoiGioiThieuId] = useState();
    const [nguoiGioiThieuName, setNguoiGioiThieuName] = useState();
    const [marketings, setMarketings] = useState([]);//có s ở sau vì số nhiều (plural0)
    const [sendResultTypes, setSendResultTypes] = useState();
    const [reasonCome, setReasonCome] = useState();
    // bangGiaChiDinh này là cho trường hợp update. Bảng giá đã lưu từ trước. Nhưng user nó chơi ác nó change bảng giá của nguồn KH/site.
    // Khi update tiếp đón -> load nguồn KH hoặc site thì rõ ràng bangGia theo nguồn KH nó khác bangGiaChiDinh.
    // Lúc này vẫn giữ nguyên bangGiaChiDinh. Nếu có change nguồn KH thì xóa list dịch vụ. (hỏi lại nghiệp vụ cho chắc).
    const [bangGiaChiDinh, setBangGiaChiDinh] = useState();
    const [bangGia, setBangGia] = useState('default'); // banggia này thay đởi theo nguồn khách hàng -> site
    const [bangGiaText, setBangGiaText] = useState(''); // này là text hiển thị bảng giá theo nguồn KH hoặc site thôi, không ảnh hưởng đến quy trình/dữ liệu
    const [saleId, setSaleId] = useState();
    const [saleName, setSaleName] = useState();
    //get dữ liệu
    const [listCorporation, setListCorporation] = useState([]);
    const [listNguoiGioiThieu, setListNguoiGioiThieu] = useState([]);
    const [listMarketing, setListMarketing] = useState([]);
    const [listSendResultType, setListSendResultType] = useState([]);
    //#region useEffect
    useEffect(() => {
        loadCorporation();
        loadMarketing();
        loadHTTKQ();
    }, []);
    //#endregion 
    
	// function loadCorporation(key = "", page = 1, size = 1000) {
    //     CorporationServices.GetList(key, page, size).then(res => {
    //         if (res.isSuccess) {
    //             setListCorporation(res.data);
    //         }
    //         else {
    //             notification.warning({ message: res.err.msgString });
    //         }
    //     }).catch(err => {

    //     })
    // }

	function loadCorporation(data) {
        return CorporationServices.GetList(data, 1, 1000);
        // CorporationServices.GetList(data, 1, 1000).then(res => {
        //     if (res.isSuccess) {
        //         setListCorporation(res.data);
        //     }
        //     else {
        //         notification.warning({ message: res.err.msgString });
        //     }
        // }).catch(err => {

        // })
    }

    function selectCorporation(data) {
        setTempCorporation(data);
        // btnChangeCorporationRef.current.click();
        if ((indicationRef.current.getListService() ?? []).length > 0) {
            btnChangeCorporationRef.current.click();
        }
        else confirmChangeCorporation(data);
    }

    function confirmChangeCorporation(data) {
        setCorporationId(data?.ma);
        setCorporationName(data?.ten);
        changeBangGia(data);
    }

    // Lấy bảng giá. Ưu tiên theo: Nguồn KH -> Site -> Default
    function changeBangGia(corporation){
        if (corporation && corporation.banggia) {
            setBangGia(corporation.banggia);
            setBangGiaText(`Theo nguồn khách hàng: ${corporation.ten}`);
        }
        else {
            ConfigService.BangGiaDefaultSite(props.siteid).then((respone) => {
                if (respone.isSuccess && respone.item != '' && respone.item != null) {
                    setBangGia(respone.item);
                    setBangGiaText(`Theo chi nhánh: ${props.siteName}`);
                }
                else {
                    setBangGia('default');
                    setBangGiaText(``);
                }
            }).catch(err => {
                // this.getAllTypeServices(true);
            });
        }
    }
    
	function loadNguoiGioiThieu(data) {
        // if (this.props.onType) {
        //     this.props.onType(data);
        // }
        // this.setState({ key: data });
        return PatientService.GetListNguoiGioiThieu(data, false, 1, 20);
    }
    
    function selectNguoiGioiThieu(data) {
        setNguoiGioiThieuId(data.id);
        setNguoiGioiThieuName(data.hoten);
    }

	function loadSale(data) {
        // if (this.props.onType) {
        //     this.props.onType(data);
        // }
        // this.setState({ key: data });
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100,'26');
    }

    function selectSale(data) {
        setSaleId(data.id);
        setSaleName(data.hoten);
    }

    function loadMarketing(){
        ConfigService.GetKenhKhachHang(1,100,false).then(result => {
            if(result.isSuccess){
                setListMarketing(result.data);
            } 
            else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {

        })
    }
    
    function addMarkerting(e) {
        let lst = [...marketings];
        if(e.target.checked){
            lst.push(e.target.value.toString());
        } else { 
            lst = lst.filter((item) => item !== e.target.value.toString())
        }
        setMarketings(lst);
    }
    
    function loadHTTKQ (key = "", page = 1, size = 1000) {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                setListSendResultType(res.data);
            }
        })
    }
  
    const columnNguoiGioiThieu = [
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 100,
        },
        // {
        //     title: 'Giới tính',
        //     dataIndex: 'gioitinh',
        //     key: 'gioitinh',
        //     width: 80,
        // },
    ];

    const columnCorporation = [
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: 250,
        },
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 100,
        },
        {
            title: 'Bảng giá',
            dataIndex: 'banggia',
            key: 'banggia',
            width: 200,
            render: (value, row) => {
                return (value === 'default' ? 'Mặc định' : value);
            }
        },
    ];

    const columnSale = [
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
        },
    ];

    return (
        <React.Fragment>
        <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
        <div className='list-page' >
            <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between', border: 'none' }}> {/* header */}
                <h3></h3>
                <h3 hidden={!props.isDialog}
                    onClick={() => {
                    props.onCancel();
                    }}
                ><i className='far fa-times-circle' /></h3>
            </div>
            <div className="list-page-body overflow-auto">{/* body */}
                <div className="row">{/* notification */}
                <div className="col-md-12"><Notify ref={notiRef} /></div>
                </div>
                <Collapse defaultActiveKey={['register', 'service']} expandIconPosition="end">
                    <Panel header={<label className='mita-header-title'>Thông tin đăng ký</label>} key="register">
                        <div className="my-2">{/* Thông tin tiếp đón (trừ thông khách hàng) */}
                            <div className="row">{/* Nguồn khách hàng | Người giới thiệu */}
                                <div className="col-md-4 d-flex flex-column">{/* Nguồn khách hàng */}
                                    <label className="mita-title">Nguồn khách hàng
                                        <Popconfirm
                                            title={() => {return (
                                                <span>Việc thay đổi <b>Nguồn khách hàng</b> sẽ làm thay đổi <b>Bảng giá</b> nên sẽ xóa hết các dịch vụ đang chọn.<br/>Bạn chắc chắn muốn đổi?</span>
                                            )}}
                                            onConfirm={() => { console.log('confirm'); confirmChangeCorporation(tempCorporation); }}
                                            onCancel={() => { console.log(corporationName); }}
                                            okText="Đồng ý"
                                            cancelText="Hủy"
                                        >
                                            <span ref={btnChangeCorporationRef}></span>
                                        </Popconfirm>
                                    </label>
                                    {/* <Select value={corporationId} placeholder="Chọn nguồn khách hàng"
                                        onChange={(e) => {}}
                                        showSearch
                                        allowClear
                                        onSearch={(value) =>{}}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {listCorporation.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                                    </Select> */}
                                    <AutocompletetableNoGroup
                                        // ref={(c) => this.AutocompletePatient = c}
                                        placeholder={"Nhập thông tin nguồn khách hàng"}
                                        width='100%'
                                        tablewidth={630}
                                        listHeight={350}
                                        onSearch={loadCorporation.bind(this)}
                                        onSelect={(item) => {selectCorporation(item);}}
                                        // onDropdownVisibleChange={(open) => { 
                                        //  }}
                                        headers={columnCorporation}
                                        keyValue={'ma'}
                                        value={corporationName}
                                        showRemove={corporationName !== '' && corporationName !== undefined && corporationName !== null} 
                                        CleanText={(e) => {
                                            selectCorporation(null);
                                            // setCorporationId('');
                                            // setCorporationName('');
                                        }}
                                        // disabled={this.state.disableCopor || isnotEdit}
                                    />
                                </div>
                                <div className="col-md-4 d-flex flex-column">{/* Người giới thiệu */}
                                    <label className="mita-title">Người giới thiệu</label>
                                    <AutocompletetableNoGroup
                                        // ref={(c) => this.AutocompletePatient = c}
                                        placeholder={"Nhập mã, tên người giới thiệu"}
                                        width='100%'
                                        tablewidth={500}
                                        onSearch={loadNguoiGioiThieu.bind(this)}
                                        onSelect={(item) => {selectNguoiGioiThieu(item);}}
                                        headers={columnNguoiGioiThieu}
                                        keyValue={'id'}
                                        value={nguoiGioiThieuName}
                                        showRemove={nguoiGioiThieuName !== '' && nguoiGioiThieuName !== undefined && nguoiGioiThieuName !== null}
                                        CleanText={() => {
                                            setNguoiGioiThieuId('');
                                            setNguoiGioiThieuName('');
                                        }}
                                        // disabled={this.state.disableCopor || isnotEdit}
                                    />
                                </div>
                                <div className="col-md-4 d-flex flex-column">{/* Sale */}
                                    <label className="mita-title mr-2">Sale</label>
                                    <AutocompletetableNoGroup
                                        // ref={(c) => this.AutocompletePatient = c}
                                        placeholder={"Nhập thông tin nhân sale"}
                                        width='100%'
                                        tablewidth={500}
                                        onSearch={loadSale.bind(this)}
                                        onSelect={(item) => {selectSale(item);}}
                                        headers={columnSale}
                                        keyValue={'id'}
                                        value={saleName}
                                        showRemove={saleName !== '' && saleName !== undefined && saleName !== null}
                                        CleanText={() => {
                                            setSaleId('');
                                            setSaleName('');
                                        }}
                                        // disabled={this.state.disableCopor || isnotEdit}
                                    />
                                </div>
                            </div>
                            <div className="row">{/* Kênh KH | Hình thức trả kết quả */}
                                <div className="col-md-8 d-flex flex-column">{/* Kênh khách hàng */}
                                    <label className="mita-title">Kênh khách hàng</label>
                                    <div>
                                        {listMarketing && listMarketing.map((item, index) => {
                                            return (
                                                <Checkbox key={item.id} 
                                                    onChange={(e) => addMarkerting(e)} 
                                                    value={item.id} 
                                                    checked={marketings.includes(item.id.toString())}
                                                >
                                                    {item.ten}
                                                </Checkbox>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex flex-column">{/* Hình thức trả kết quả */}
                                    <label className="mita-title">Hình thức trả kết quả</label>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        // showSearch
                                        // filterOption={(input, option) => {
                                        //     return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }}
                                        className="w-100"
                                        placeholder="Chọn hình thức"
                                        maxTagCount= 'responsive'
                                        value={ sendResultTypes }
                                        onChange={(value) => {setSendResultTypes(value);}}
                                    >
                                        {(listSendResultType ?? []).map((record, index) => {
                                        return (
                                            <Option key={record.id} value={record.keyValue}>{record.keyName}</Option>
                                        )
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="row">{/* Lý do */}
                                <div className="col-md-12 d-flex flex-column">{/* Lý do khám */}
                                    <label className="mita-title">Lý do khám</label>
                                    <TextArea 
                                        rows={3}
                                        // readOnly={detailScreenMode==detailScreenModeEnum.detail}
                                        value={reasonCome}
                                        onChange={(e) => setReasonCome(e.target.value)}
                                    ></TextArea >
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel header={<label className='mita-header-title'>Đăng ký dịch vụ</label>} key="service">
                        <div className="my-2">{/* Thông tin đăng ký dịch vụ */}
                            {/* <div className="row">
                                <div className="col-md-12 mt-2">
                                    <h2>Đăng ký dịch vụ</h2>
                                </div>
                            </div> */}
                            <IndicationExam ref={indicationRef}
                                parentComponent='DANGKY'
                                data={{ 
                                    managermentId: maQL,
                                    siteId: siteId,
                                    patientName: null,
                                    patientCode: maBN,
                                    coporationName: null,
                                    priceListName: bangGia,
                                    priceListNameText: bangGiaText,
                                }} 
                            // roomName={props.roomName} examRoom={props.examRoom} clinicRoom={props.clinicRoom}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
            {/*<div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
            > footer (nếu có) */}
                {/* <div>
                </div>
                <div className='d-flex justify-content-end' >
                    <Button type="link" className="p-0"
                    onClick={() => {  }}
                    >
                    Hành động
                    <i className="fa fa-chevron-right ml-2"></i>
                    </Button>
                    <button
                        className='btn btn-danger ml-3'
                        onClick={() => props.onCancel()}
                    >
                        <i className='fa fa-sign-out-alt mr-2'></i>
                        Đóng
                    </button>
                </div> */}
            {/* </div> */}
        </div>
        </React.Fragment>
    );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
    //   siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RegisterProduct);