import React, { useState, useEffect, useRef, forwardRef, useCallback, useImperativeHandle } from 'react';
import { Input, Table, notification, Pagination, DatePicker, Checkbox, Modal, Tag, Button, Popconfirm, Tooltip, Select, Alert } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../libs/actions';
import * as ConsultServies from "./consultservices";
import * as HotlineServices from "../hotline/hotlineservices";
import * as ChiDinhService from "../../services/chiDinhService";
import * as AppointmentService from "../../services/appointmentService";
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as WorldfoneService from '../../services/worldfoneService';
import ModalCLS from '../../components/MDCLS';
import SelectConsultant from '../../components/MDCLS/selectConsultant';
import AddOrder from '../lichhenlaymau/addOrder';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import LoginWF from '../hotline/loginWF';
import { connect } from 'react-redux';
import * as SystemConfig from "../../configure/systemConfig";
import { IsActive_FeatureLH } from '../../configure/featureConfig';

import "./style.css";
import HeaderModal from '../../configureHIS/common/headerModal';
import PatientInfo from '../../configureHIS/common/patientinfo';
import { ALLOW_COMBO } from '../../configureHIS/common/constants';
import { isNullOrEmpty } from '../../libs/util';
import { Header } from 'antd/lib/layout/layout';
import { xor } from 'lodash';
import Nguonkhachhang from '../recept/register/nguonkhachhang';
import ConsultantWarning from '../recept/register/consultantWarning';
const { Option } = Select;
const { RangePicker } = DatePicker;

const TuVan = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const { TextArea } = Input;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [key, setKey] = useState("");
    const [uncompleted, setUncompleted] = useState(false);
    const [date, setDate] = useState([moment(), moment()]);
    const [ListConsults, setListConsults] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [info, setInfo] = useState({});
    const [detail, setDetail] = useState("");
    const [initDetail, setInitDetail] = useState("");
    const [ngayhen, setNgayhen] = useState(new Date());
    const [diffAddr, setDiffAddr] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [editable, setEditable] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const [visibleOrder, setVisibleOrder] = useState(false);
    const OrderRef = useRef();
    const [showMore, setShowMore] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const [isLoginWF, setIsLoginWF] = useState(false);
    const [titleWF, setTitleWF] = useState('');
    const [username,] = useState(localStorage.getItem("userID"));
    const [modalSelectConsultant, setModalSelectConsultant] = useState(false);
    const _nguonKhachHang = useRef();
    const [bangGia, setBangGia] = useState("");
    const [bangGiaTheoSite, setBangGiaTheoSite] = useState("");
    const [bangGiaTheoNguonKH, setBangGiaTheoNguonKH] = useState("");

    useEffect(() => {
        GetListConsults();
    }, [uncompleted, chinhanh]);

    useEffect(() => {
        setChinhanh(props.chinhanh)
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
            if (result.item == true) {
                GetListSite();
            }
        });
    }, []);
    
    const notifyUpdate = () => {
        const isLoading = false;
        GetListConsults(currentPage, pageSize, uncompleted, isLoading);
    }

    useEffect(() => {
        setChinhanh(props.siteid);
        let s = listSite.find(x => x.id == props.siteid);
        if (s) setBangGiaTheoSite(s.banggia);
    }, [props.siteid])
    
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    
    const GetListConsults = (page = currentPage, size = pageSize, status = uncompleted, isLoading = true) => {
        Actions.setLoading(isLoading);
        ConsultServies.GetListConsults(date[0].format("DD/MM/YYYY"), date[1].format("DD/MM/YYYY"), key, page, size, status, chinhanh).then(res => {
            if (res.isSuccess) {
                setListConsults(res.data);
                setTotalRecords(res.totalRecords);
                setCurrentPage(page);
                setPageSize(size);
                setDate(date);
            }
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }
    const updateTiepNhan = () => {
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
		let isNullConsultant = dataOrder.some(x => isNullOrEmpty(x.consultantId));
        if (editable) {
            if (props.coporationRequired && isNullOrEmpty(dataNguonKhachHang.coporator)) {
                Actions.openMessageDialog("Thông báo", "Bắt buộc chọn nguồn khách hàng");
                return;
            }
            if (props.consultantRequired && isNullConsultant) {
                Actions.openMessageDialog("Thông báo", "Vui lòng chọn nhân viên tư vấn");
                return;
            }
        }
        const data = {
            ghichu: initDetail,
            type: "tuvan",
        }
        HotlineServices.UpdateDetailArrangerment(info.id, data, info.chinhanh).then(res => {
            if (res.isSuccess) {
                !editable && notification.success({ message: "Lưu tư vấn thành công" });
                GetListConsults(1);
                if (UpdateLichHen())
                    onClose();
            } else {
                notification.warning({ message: "Xảy ra lỗi khi cập nhật, hãy thử lại sau" });
            }
        }).catch(() => { })
    }

    const UpdateLichHen = () => {
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        if (editable) {
            const scheduleInfo = {
                mabn: info.mabn,
                ngayhen: ngayhen,
                idlichhen: info.idlichhen,
                newAddress: newAddress,
                site: info.chinhanh.toString(),
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                lstChiDinh: dataOrder,
            }
            if (info.idlichhen > 0) {
                HotlineServices.updateLichHen(info.idlichhen, scheduleInfo, info.chinhanhlaymau).then(res => {
                    if (!res.isSuccess) {
                        if (res.err.msgString)
                            notification.warning({ message: res.err.msgString })
                        else notification.warning({ message: "Không thể cập nhật lịch hẹn, vui lòng thử lại sau" });
                        return false;
                    } else {
                        HotlineServices.updateArrangerment(info.id, "lenlich", info.idlichhen, info.chinhanh, info.chinhanhlaymau);
                    }
                }).catch(() => { })
            } else {
                HotlineServices.saveLichHen(scheduleInfo).then(res => {
                    if (!res.isSuccess) {
                        notification.warning({ message: res.err.msgString ?? "Không thể lưu lịch hẹn, vui lòng thử lại sau" });
                        return false;
                    } else {
                        HotlineServices.updateArrangerment(info.id, "lenlich", res.returnObject.id, info.chinhanh, res.returnObject.chinhanh);
                        UpdateChidinh(res.returnObject.id, res.returnObject.chinhanh, dataNguonKhachHang);
                    }
                }).catch(() => { })
            }
        }
        return true;
    }

    const UpdateChidinh = (orderid, chinhanh, dataNguonKhachHang) => {
        let lstSelect = dataOrder;
        let listChiDinh = [];
        if (!lstSelect || lstSelect.length === 0)
            return;
        for (let item of lstSelect) {
            item.ma = "0";
            item.madoituong = "2";
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment().format("YYYY-MM-DD HH:mm")
            if (listChiDinh.filter(x=>x.id == item.id).length > 0) {
                notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                return;
            }
            listChiDinh.push(item);
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = xChild;
                    itemChild.giaaTH = xChild.dongia.toString();
                    itemChild.dongia = xChild.dongia;
                    itemChild.soluong = xChild.soluong;
                    itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                    itemChild.vienPhiIdOfCombo = item.id;
                    
                    if (listChiDinh.filter(x=>x.id == itemChild.id).length > 0) {
                        notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                        return;
                    }
                    
                    listChiDinh.push(itemChild);
                });
        }
        const data = {
            orderid: orderid,
            listChiDinh: listChiDinh,
            patient: {
                code: info.mabn,
                subjectCode: '2',
            },
            idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu chỉ định thành công!' });
                GetListConsults(1);
            } else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const GetListOrderById = (id, chinhanh) => {
        /*
            Khi đặt lịch xong bấm cập nhật xong mới chạy vô lấy danh sách đã đặt
            Dịch vụ chỉ có khi tư vấn xong (Sau khi có id lịch hẹn).
        */
        if (id > 0) {
            AppointmentService.GetListOrderById(id, chinhanh).then(result => {
                if (result.isSuccess) {
                    let data = result.data;
                    for (let item of data) {
                        item.listChild = item.listChildCd;
                    }
                    setDataOrder(data)
                } else {
                    Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
                }
            }).catch(err => {
                Actions.openMessageDialog("Error ", err);
            })
        } else {
            setDataOrder([])
        }
    }

    const XoaChiDinh = (row) => {
        Actions.setLoading(true);
        let data = dataOrder;
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                data = data.filter(x => x.id !== row.id)
                setDataOrder(data.map(x => ({ ...x })));
                notification.success({ message: "Xóa thành công" })
                if (Number(row.type) === 1) {
                    // if (modalCLS)
                    //     modalCLS.current.GetServicesHaveBeenSelected(true);
                    // if (reLoad) {
                    //     reLoad()
                    // }
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
                // if (modalCLS) {
                //     modalCLS.current.GetServicesHaveBeenSelected(true);
                // }
                // if (reLoad) {
                //     reLoad()
                // }
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => Actions.setLoading(false))
    }

    const onClose = () => {
        setVisibleModal(false);
        setInfo([]);
        setEditable(false);
        setDetail("");
        setInitDetail("");
        setNgayhen(moment());
        forceUpdate();
    }

    const deleteTiepNhan = (id, idlichhen, idhenkham, chinhanh, fromTuVan = true) => {
        HotlineServices.deleteTiepNhan(id, idlichhen, idhenkham ?? "", chinhanh, fromTuVan).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã hủy bỏ tiếp nhận" });
            } else {
                if (res.err.msgString) notification.error({ message: res.err.msgString });
                else notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
            }
        }).catch(() => { })
    }

    const MakeCall = (row) => {
        if (row.dienthoai === '' || row.dienthoai.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true)
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienthoai, row.hoten, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF("Vui lòng đăng nhập Worldfone")
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true, 'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    const onChangeDetail = () => {
        if (detail !== undefined && detail !== null && detail !== "" && detail != " ") {
            let data = initDetail ? `${initDetail}\n[${username}]: ${detail}` : `[${username}]: ${detail}`;
            setInitDetail(data);
            setDetail("");
        }
    }

    const onUpdateConsultant = (newData) => {
        let listSelectedServices = dataOrder;
        newData.forEach(record => {
            let index = listSelectedServices.findIndex(x => x.id === record.id);
            listSelectedServices[index].consultantId = record.consultantId;
            listSelectedServices[index].consultantName = record.consultantName;
        });
        
        let model = listSelectedServices.map(x => {
            return { siteId: Number(props.siteid), NhanVienId: x.consultantId, idChiDinh: x.idChiDinh }
        });
        if (info.idlichhen > 0) {
            Actions.setLoading(true);
            ChiDinhService.updateConsultantIndication(model).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Lưu thành công." });
                    setDataOrder(listSelectedServices);
                    setModalSelectConsultant(false);
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
                Actions.setLoading(false);
            }).catch(err => {
                if (err.stack) {
                    notification.error({ message: "Lỗi javascript sau khi lưu. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
                    return;
                }
                notification.error({ message: "Lưu không thành công. Vui lòng làm mới lại dữ liệu và thử thao tác lại." });
                Actions.setLoading(false);
            })
        } else {
            setDataOrder([...dataOrder])
            setModalSelectConsultant(false);
        }
    }

    const onOpenModalRecieve = (data) => {
        setInfo(data);
        setInitDetail(data.ghichu);
        GetListOrderById(data.idlichhen, data.chinhanhlaymau)
        data.ngayhen && moment(data.ngayhen).year() !== 1 ? setNgayhen(moment(data.ngayhen).toDate()) : new Date();
        setDiffAddr(data.diachihen === "" ? true : false);
        setNewAddress(data.diachihen);
        setVisibleModal(true);
        forceUpdate();
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        // {
        //     title: 'Ngày tiếp nhận',
        //     dataIndex: 'ngay',
        //     key: 'ngay',
        //     align: 'right',
        //     render: (date) => moment(date, 'DD/MM/YYYYY HH:mm:ss').format('HH:mm, DD/MM/YYYY')
        // },
        {
            title: 'Họ và tên',
            dataIndex: 'hoten',
            key: 'hoten',
            align: 'left',
            width: 240,
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            align: 'left',
            width: 90,
            render: (date, row) => row.onlyyear ? moment(date, "DD/MM/YYYY HH:mm:ss").format("YYYY") : moment(date, 'DD/MM/YYYY HH:mm:ss').format(SystemConfig.FORMAT_DATE)
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            align: 'center',
            width: 110,
            render: (phone) => {
                return (
                    phone &&
                    <div className="phonenumber border border-success rounded text-success"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(phone);
                            notification.info({ message: "Đã sao chép vào bộ nhớ tạm" })
                        }}>{phone}
                    </div>
                )
            }
        },
        {
            title: 'Ghi chú',
            key: 'ghichu',
            align: 'left',
            width: 300,
            render: (row) => {
                const note = row.ghichu;
                var value = note.indexOf("\n") === 0 ? note.substring(1, note.length) : note;
                const maxLength = 100;
                return (
                    <div>
                        <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? value : value.substring(0, maxLength)}</span>
                        {note.length > maxLength && <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>}
                    </div>
                )
            }
        },
        // {
        //     title: 'Mức ưu tiên',
        //     dataIndex: 'urgent',
        //     key: 'urgent',
        //     align: 'center',
        // },
        {
            title: 'Trạng thái',
            key: 'status',
            align: 'center',
            width: 180,
            render: (row) => {
                return (
                    <span>{row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1 ?
                        <Tag color="green">{row.lenlich === 1 ? "Đã tư vấn và đặt lịch" : "Đã tư vấn"}</Tag> : <Tag color="red">Chờ</Tag>}
                    </span>
                )
            }
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            render: (row) => {
                return (
                    <div style={{ lineHeight: 3 }}>
                        {/* <button className="btn btn-outline-success"
                            disabled={row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1}
                            onClick={() => { setVisibleModal(true); setInfo(row); setDetail(row.ghichu) }}><i className="fas fa-check"
                                style={{ marginRight: 5 }} />{row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1 ? "Đã tư vấn" : "Tiếp nhận"}
                        </button> */}
                        <Button 
                            className="btn btn-color mr-1"
                            onClick={() => { onOpenModalRecieve(row) }}>
                            {(row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1) ?
                                <span><i className="fas fa-edit" style={{ marginRight: 5 }} />Cập nhật</span>
                                : 
                                <span><i className="fas fa-check" style={{ marginRight: 5 }} />Tiếp nhận</span>
                            }
                        </Button>
                        <Button className="btn btn-success mr-1"
                        onClick={() => {
                            setInfo(row);
                            MakeCall(row);
                        }}><i className="fas fa-phone" /></Button>
                        <Popconfirm title="Tiếp nhận sẽ bị hủy bỏ?" onConfirm={() => deleteTiepNhan(row.id, row.idlichhen, row.idhenkham, row.chinhanh, true)} okText="Đồng ý" cancelText="Hủy">
                            <Button className="btn btn-danger"><i className="fas fa-trash" /></Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];

    const regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            // render: (text, record, index) => (Number(index) + 1),
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title: 
                <div>
                    <span className={props.consultantRequired && "required-field"}>NV tư vấn</span>
                    {editable === true &&
                    <Tooltip title='Cập nhật nhân viên tư vấn'>
                        <Button type='link' style={{ color: 'white' }}
                            className='pl-2'
                            onClick={() => { setModalSelectConsultant(true) }}
                        >
                            <i className='fa fa-pen' />
                        </Button>
                    </Tooltip>
                    }
                </div>,
            dataIndex: 'consultantName',
            key: 'consultantName',
            width: 130,
            render: (text, record, index) => {
                return record.consultantName
            },
        },
        {
            title: 'Giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            // render: (value) => (<span>{value ? value.toLocaleString('en-US') : '0'}</span>),
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{(value * ((100 - row.discount) / 100)).toLocaleString('en-US')}</span>))
            },
        },
        {
            title: '',
            align: 'center',
            width: '10%',
            render: (text, row, index) => { return (
                row.isChild ? "" : !editable ? "" : (
                (Number(info.idlichhen) > 0 ?
                    <Popconfirm
                        title="Thao tác xóa sẽ thực hiện xóa dữ liệu ngay lập tức. Bạn chắc chắn muốn xóa?"
                        onConfirm={() => { XoaChiDinh(row, index) }}
                        okText="Đồng ý" cancelText="Hủy" 
                        disabled={!editable}
                    >
                        <div style={{ cursor: 'pointer' }} className="text-danger" disabled={row.status === 'Đã đóng tiền'}
                        >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </div>
                    </Popconfirm> :
                    <div style={{ cursor: 'pointer' }}
                        className="text-danger"
                        disabled={row.status === 'Đã đóng tiền'}
                        onClick={() => {
                            let data = dataOrder;
                            data = data.filter(x => x.id !== row.id)
                            setDataOrder(data.map(x => ({ ...x })));
                        }} 
                    >
                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                    </div>
                )
                )
            )}
        }
    ];

    let dataSource = dataOrder.map(function (e, index) {
        let o = Object.assign({}, e);
        // o.key = index;
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString()+'-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    })

    const checkBangGia = (infor) => {
        if (info.idlichhen <= 0 || isNullOrEmpty(info.idlichhen)) {
            if (infor.banggia != bangGia) {
                setDataOrder([]);
                if (OrderRef && OrderRef.current) {
                    OrderRef.current.UpdateSelectedFromProps([]);
                }
            }
            setBangGia(infor.banggia);
        }
    }

    return (
        <React.Fragment>
            <div className='containerChilderWrapper h-100'>
                <HeaderModal title="Tư vấn khách hàng" />
                <div className="col-md-12 d-flex justify-content-center align-items-center">
                    <Input 
                        className='mr-2' 
                        value={key} 
                        onChange={(e) => setKey(e.target.value)}
                        placeholder="Nhập tên bệnh nhân hoặc số điện thoại để tìm kiếm"
                        onPressEnter={() => GetListConsults(1)}
                        suffix={
                            <Checkbox 
                                className='mr-2' 
                                checked={uncompleted} 
                                onChange={(e) => { setUncompleted(e.target.checked); }}>
                                Chưa xử lý
                            </Checkbox>
                        } />
                    <RangePicker 
                        className='mr-2' 
                        format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]} 
                        locale={viVN} 
                        value={[moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")]} 
                        onChange={(e) => setDate(e)} />
                    <Button className="btn btn-color" onClick={() => { GetListConsults(1) }}> <i className="fas fa-search mr-1" />Tìm kiếm</Button>
                </div>
                <div className='mt-2' style={{height: 'calc(100% - 150px)'}}>
                    <Table
                        scroll={{ x: 'max-content', y: 0 }}
                        dataSource={ListConsults}
                        columns={columns}
                        pagination={false}
                        rowClassName={(row, rowIndex) => {
                            // if (moment(row.thoigiantuvan).year() === 1 && moment().diff(moment(row.ngay), 'minutes') > 30)
                            if (moment(row.thoigiantuvan).year() === 1 && row.priority)
                                return "row-alert"
                        }}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10 }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    current={currentPage}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                        GetListConsults(page, size);
                    }}
                    onShowSizeChange={() => { }}
                    showSizeChanger={true}
                />
            </div>

            {visibleModal &&
                <Modal
                    visible={visibleModal} 
                    open={visibleModal} 
                    maskClosable={false}
                    onCancel={() => setVisibleModal(false)}
                    width={1000}
                    destroyOnClose
                >
                    <HeaderModal title={`Tiếp nhận tư vấn: ${info.hoten}`} onClose={() => { onClose() }} />
                    <PatientInfo
                        patientInfo={
                            [
                                {
                                    label: "Mã khách hàng",
                                    value: info.mabn,
                                    column: 6,
                                },
                                {
                                    label: "Họ và tên",
                                    value: info.hoten,
                                    column: 6,
                                }, 
                                {
                                    label: info.onlyyear ? "Năm sinh" : "Ngày sinh",
                                    value: info.onlyyear ? moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format("YYYY") : moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format(SystemConfig.FORMAT_DATE),
                                    column: 6,
                                },
                                {
                                    label: "Giới tính",
                                    value: info.gioitinh,
                                    column: 6,
                                }, 
                                {
                                    label: "Điện thoại",
                                    value: info.dienthoai,
                                    column: 6,
                                }, 
                                {
                                    label: "Email",
                                    value: info.email,
                                    column: 6,
                                },
                                {
                                    label: "Địa chỉ",
                                    value: info.sonha,
                                    column: 12,
                                },
                            ]
                        }    
                    />
                    <div>
                        <label>Ghi chú</label>
                        <TextArea rows={5} onChange={(e) => setDetail(e.target.value)} value={initDetail} readOnly={true}/>
                    </div>
                    <div className="d-flex mt-2" >
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }}
                            value={detail}
                            onChange={(e) => setDetail(e.target.value)} 
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && e.ctrlKey) {
                                    onChangeDetail();
                                }
                            }}
                            placeholder="Nhập ghi chú và bấm thêm hoặc bấm Ctrl+Enter" />
                        <button style={{ width: 90 }} className={`btn ${!isNullOrEmpty(detail) ? "btn-warning-sparkle" : "btn-color"} ml-2`} onClick={() => { onChangeDetail() }}><i className="fa fa-plus mr-1"></i>Thêm</button>
                    </div>
                    {IsActive_FeatureLH() && 
                    <div className="mt-2">
                        <div className="d-flex mt-2">
                            <Checkbox checked={editable} onChange={() => setEditable(!editable)}>{info.idlichhen > 0 ? "Cập nhật lịch hẹn lấy mẫu tại nhà" : "Tạo lịch hẹn lấy mẫu tại nhà"}</Checkbox>
                        </div>
                        <Alert type='warning' className='mt-2'
                            message={
                                <div className='row'>
                                    <div className="col-md-12"><i>* Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i></div>
                                    <div className="col-md-12"><i className='text-danger'>* Việc thay đổi nguồn khách hàng có thể hủy toàn bộ chỉ định ngay lập tức mà <b>không thể hoàn tác</b></i></div>
                                    <div className='col-md-12'><i>* Cần xác nhận <b>nguồn khách hàng</b>. Không cho phép điều chỉnh thông tin này khi hoàn tất thao tác</i></div>
                                </div>
                            }
                        ></Alert>
                        <div className='row no-gutters mt-2 ml-0'>
                            <Nguonkhachhang ref={_nguonKhachHang}
                                managermentId={info.maql}
                                chiNhanhDatLich={info.chinhanhlaymau}
                                updateInfoBangGia={(info) => {
                                    let bgia = info.banggia;
                                    if (bgia == null || bgia == '' || bgia == undefined) {
                                        bgia = bangGiaTheoSite
                                        if (bgia == null || bgia == '' || bgia == undefined)
                                            bgia = 'default';
                                    }
                                    setBangGiaTheoNguonKH(info.banggia);
                                    checkBangGia({ banggia: bgia });
                                }}
                                disable={info.idlichhen > 0 && dataSource.length > 0}
                            />
                        </div>
                        <div>
                            <label>{info.idlichhen > 0 ? "Ngày hẹn đã tạo" : "Ngày hẹn"}</label>
                            <DateTimePicker
                                className="w-100"
                                disabled={!editable}
                                locale="vi"
                                calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                                clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                                format={'dd-MM-yyyy HH:mm'}
                                isClockOpen={false}
                                isCalendarOpen={false}
                                onChange={setNgayhen}
                                minDate={moment(ngayhen).year() > 1 && moment(ngayhen) < moment() ? moment(ngayhen).toDate() : moment().toDate()}
                                value={ngayhen ? moment(ngayhen).toDate() : new Date()}
                            />
                        </div>
                        <div className="mt-2">
                            <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                            <Input
                                placeholder={diffAddr ? "" : "Nhập địa chỉ lấy mẫu"}
                                value={diffAddr ? "" : newAddress}
                                disabled={diffAddr || !editable}
                                prefix={<button disabled={!editable} className={diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-success btn-sm mr-2"}
                                    onClick={(e) => { e.preventDefault(); setDiffAddr(!diffAddr); setNewAddress(""); }}>{diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                                onChange={(e) => setNewAddress(e.target.value)}
                            />
                        </div>
                        <div className="mb-2">
                            <div className="border-register" style={{ background: '#fff' }}>
                                <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                    <div className="h4 font-weight-bold">
                                        Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) => tmp + Number(cur.dongia * ((100 - cur.discount) / 100)), 0).toLocaleString('en-US')})
                                    </div>
                                    <Button className="btn btn-secondary ml-auto" 
                                        disabled={!editable} 
                                        onClick={e => { 
                                            e.stopPropagation(); 
                                            setVisibleOrder(true); 
                                            OrderRef && OrderRef.current && OrderRef.current.UpdateSelectedFromProps(dataOrder);
                                        }}>
                                        Thêm/Sửa chỉ định
                                    </Button>
                                </div>
                                <div class="collapse" id="chidinh">
                                    <div className="table-responsive">
                                        <Table dataSource={dataSource}
                                            rowKey={(e) => e.id}
                                            columns={regiterServiceColumn}
                                            pagination={false}
                                            scroll={{ y: 0 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <ConsultantWarning visible={dataOrder.length > 0 && dataOrder.some(x => isNullOrEmpty(x.consultantId))} />
                    <div className="d-flex justify-content-center w-100 mt-4">
                        <button className="btn btn-color" onClick={() => {updateTiepNhan()}}><i className="mr-2 fa fa-save" />Lưu</button>
                        <button className="ml-2 btn btn-danger" onClick={() => onClose()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                    </div>
                    <Modal
                        width={1400}
                        maskClosable={false}
                        visible={visibleOrder}
                        onCancel={() => setVisibleOrder(false)}
                        // destroyOnClose={true}
                    >
                    {(Number(info.idlichhen) > 0) ?
                        <AddOrder //cập nhật thì vô đây
                            ref={OrderRef}
                            chinhanh={info.chinhanhlaymau}
                            selectedPatient={{
                                mabn: info.mabn,
                                maql: info.maql,
                                ngayhen: info.ngayhen,
                                id: info.idlichhen?.toString()
                            }}
                            reLoad={() => { 
                                GetListOrderById(info.idlichhen, info.chinhanhlaymau) ;
                            }}
                            onClose={() => {
                                setVisibleOrder(false);
                            }}
                            banggia={bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia}
                            disableSelectBangGia={!props.allowToChangeServicePriceList} />
                        :
                        <ModalCLS //tiếp nhận thì vô đây (info.idlichhen = -1)
                            showGoiKham={false}
                            showCombo={ALLOW_COMBO}
                            chinhanh={props.siteid}
                            ref={OrderRef}
                            onClose={(data, state) => {
                                setVisibleOrder(false);
                                if (state == 'save')
                                    setDataOrder(data);
                            }}
                            editAfterRegister={true}
                            banggia={bangGiaTheoNguonKH && bangGiaTheoNguonKH != "default" ? bangGiaTheoNguonKH : bangGiaTheoSite ? bangGiaTheoSite : bangGia}
                            disableSelectBangGia={!props.allowToChangeServicePriceList}
                        />
                    }
                    </Modal>
                </Modal>
            }

            <Modal
                visible={isLoginWF}
                onCancel={() => { setIsLoginWF(false) }}> 
                <LoginWF title={titleWF} onClose={() => setIsLoginWF(false)} />
            </Modal>

            <Modal
                maskClosable={false}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                visible={modalSelectConsultant}
                open={modalSelectConsultant}
                destroyOnClose={true}
                width={1000}>
                <SelectConsultant
                    isDialog={true}
                    dataSource={dataOrder.map(x => ({ ...x }))}
                    onClose={() => { setModalSelectConsultant(false) }}
                    onSubmit={(values) => {
                        onUpdateConsultant(values);
                    }}
                />
            </Modal>
        </React.Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
		coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
		allowToChangeServicePriceList: state.global.sysConfig.allowToChangeServicePriceList ? state.global.sysConfig.allowToChangeServicePriceList : false,
        consultantRequired: state.global.sysConfig.consultantRequired ? state.global.sysConfig.consultantRequired : false,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true})(TuVan)
