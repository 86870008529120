import { Input, Tooltip, notification, Select, Checkbox } from 'antd';
import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import * as KhoService from '../../../../services/KhoService';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import { DanhSachNhanVien } from '../../../../services/employeeService';
import * as XuatKhoService from '../../../../services/xuatkhoService';
import EditableTable from '../../../../commons/editableTable';
import { ValidationCustom } from '../../../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as PrintService from '../../../../services/printService';
import * as Actions from '../../../../libs/actions';
import * as SystemConfig from '../../../../configure/systemConfig';
import Notify, { NotifyStatus } from '../../../../components/notify';

const { Option } = Select;
const { TextArea } = Input;

export default forwardRef((props, ref) => {
    const [isUpdate, setIsUpdate] = useState(props.isUpdate || false);
    const [nguoiChuyen, setNguoiChuyen] = useState(localStorage.getItem("name"));
    const [ngayChuyen, setNgayChuyen] = useState(new Date());
    const [listKho, setListKho] = useState([]);
    const [khoXuat, setKhoXuat] = useState(null);
    const [khoNhap, setKhoNhap] = useState(null);
    const [listXuat, setListXuat] = useState([]);
    const [IDNguoiNhan, setIDNguoiNhan] = useState(null);
    const [tenNguoiNhan, setTenNguoiNhan] = useState(null);
    const [lyDoChuyen, setLyDoChuyen] = useState(null);
    const [IDPhieuXuat, setIDPhieuXuat] = useState(null);
    const [soPhieu, setSoPhieu] = useState(null);
    const [keySearch, setKeySearch] = useState("");

    const ThongTinNguoiNhan = useRef();
    const tableListXuat = useRef();
    const searchThuocVatTu = useRef();
    const notiRef = useRef(null);
    const notiRefSecond = useRef(null);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isPreview, setIsPreview] = useState(props.isPreview || false);

    const columnsNguoiNhan = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 200,
        },
        {
            title: 'Bộ phận',
            dataIndex: 'vitri',
            key: 'vitri',
            width: 180,
        },
    ]

    const columnsTableListXuat = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: 60,
            render: (text, record, index) => (Number(index) + 1),
        },
        ...(isPreview ? [] : [{
            title: '',
            align: 'center',
            width: 60,
            render: (item) => {
                return (
                    <Checkbox checked={item.selected} onChange={() => handleSelectItem(item)} />
                )
            }
        }]),
        {
            title: 'Mã thuốc - vật tư',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
            width: 110
        },
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 180
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'center',
            width: 80
        },
        {
            title: 'Quy cách',
            dataIndex: 'quycach',
            key: 'quycach',
            align: 'left',
            width: 100,
        },
        {
            title: 'Số lượng xuất',
            dataIndex: 'soluong',
            key: 'soluong',
            align: !isPreview ? 'right' : "center",
            editable: !isPreview ? true : false,
            type: 'number',
            width: 100,
            insideWidth: 80,
            maxLength: 10,
            min: 1,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            width: 80,
            render: (value) => {
                return <span>{(value !== "" && value != null && value !== undefined && moment(value).year() != 1) ? moment(value).format(SystemConfig.FORMAT_DATE) : ""}</span>;
            }
        },
        {
            title: 'Lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 90,
        },
        {
            title: 'Bảo quản',
            dataIndex: 'baoquan',
            key: 'baoquan',
            align: 'left',
            width: 100,
        },
    ]

    const columnsThuocVatTu = [
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 300
        },
        {
            title: 'Mã thuốc',
            dataIndex: 'ma',
            key: 'ma',
            align: 'left',
            width: 100
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'left',
            width: 80
        },
        {
            title: 'Nước sản xuất',
            dataIndex: 'nuocsanxuat',
            key: 'nuocsanxuat',
            align: 'left',
            width: 120
        },
        {
            title: 'Mã lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 120,
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            align: 'right',
            width: 100,
            render: (value) => value ? moment(value, "DD-MM-YYYY").format(SystemConfig.FORMAT_DATE) : ""
        },
        {
            title: 'Tồn kho',
            dataIndex: 'soluongton',
            key: 'soluongton',
            align: 'right',
            width: 90,
        },
    ]

    const loadlistKho = () => {
        KhoService.GetListKho("all").then(result => {
            if (result.isSuccess) {
                setListKho(result.data);
                !isPreview && result.data[0] && setKhoXuat(result.data[0].value);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }

    const loadNguoiNhan = (key) => {
        if (key === null || key === undefined) key = "";
        return DanhSachNhanVien(key, 1, 10, '');
    }

    const selectNguoiNhan = (item) => {
        if (item !== undefined) {
            setIDNguoiNhan(item.id);
            setTenNguoiNhan(item.hoten);
        }
    }

    const loadTonKho = (searchText = "") => {
        notiRefSecond && notiRefSecond.current.Close();
        if (searchText === null || searchText === undefined) searchText = "";
        return XuatKhoService.GetSLTonKhoByName(khoXuat, searchText);
    }

    const handleSelectItem = (item) => {
        if (item) {
            let data = listXuat;
            let foundItemIndex = data.findIndex(x => x.id === item.id && x.losx === item.losx);
            if (foundItemIndex !== -1) {
                data[foundItemIndex].selected = !data[foundItemIndex].selected;
                handleChangeListXuat(data);
            }
        }
    }

    const handleChangeListXuat = (data) => {
        let newData = data.map(function (e, index) {
            let o = Object.assign({}, e);
            return o;
        })
        if (newData) {
            tableListXuat && tableListXuat.current.setDataSource(newData);
            setListXuat(newData);
        }
    }

    const addItem = (item) => {
        notiRefSecond && notiRefSecond.current.Close();
        if (item) {
            let data = listXuat;
            let foundItem = listXuat.find(x => x.id === item.mabd && x.losx === item.losx);
            if (foundItem !== undefined) {
                notiRefSecond.current.Show("Thuốc - vật tư đã tồn tại.", NotifyStatus.Warning)
            } else {
                setKeySearch(item.ten);
                searchThuocVatTu.current && searchThuocVatTu.current.setValue("");
                notiRefSecond && notiRefSecond.current.Close();
                data.unshift({
                    soluong: item.soluongton,
                    selected: false,
                    soluongton: item.soluongton,
                    makho: item.makho,
                    manguon: item.manguon,
                    idPhieu: item.idPhieu,
                    idtheodoi: item.idtheodoi,
                    id: item.mabd,
                    ma: item.ma,
                    donvi: item.donvi,
                    ten: item.ten,
                    losx: item.losx,
                    ngaynhap: moment(item.ngaynhap).format('YYYY/MM/DD'),
                    nghh: item.nghh ? moment(item.nghh, SystemConfig.FORMAT_DATE).format('YYYY/MM/DD') : null,
                    key: item.mabd + item.losx,
                    quycach: item.quycach,
                    baoquan: item.baoquan,
                    hopchat: item.hopchat,
                })
                handleChangeListXuat(data);
            }
        } else {
            notiRefSecond.current.Show("Không thể thêm thuốc - vật tư", NotifyStatus.Error)
        }
    }

    const saveOrUpdatePhieuChuyen = () => {
        if (validator.current.allValid()) {
            notiRef && notiRef.current.Close();
            if (listXuat.length === 0) {
                notiRefSecond.current.Show("Không có thuốc - vật tư trong danh sách cần xuất.", NotifyStatus.Error)
                return;
            }
            let invalidDate = false;
            let errList = "";
            listXuat.sort(function (a, b) { return moment(a).diff(moment(b)) }).forEach(item => {
                if (moment(item.ngaynhap) > moment(ngayChuyen)) {
                    if (errList != "") errList += ",\n";
                    errList += `Ngày chuyển kho phải sau ngày ${moment(item.ngaynhap).format(SystemConfig.FORMAT_DATE)} (là ngày nhập kho của thuốc - vật tư: ${item.ten})`;
                    invalidDate = true;
                }
            })
            if (invalidDate) {
                notiRefSecond.current.Show(errList, NotifyStatus.Warning)
                return;
            }
            Actions.setLoading(true);
            const hoaDonXuat = {
                'makho': khoXuat,
                'lydo': lyDoChuyen,
                'ngaynhap': moment(ngayChuyen).format("YYYY-MM-DD"),
                'makhonhap': khoNhap,
                'tennguoinhan': tenNguoiNhan,
                'idnguoinhan': IDNguoiNhan ? IDNguoiNhan : 0,
                'sophieu': soPhieu,
            }
            for (let record of listXuat) {
                if (record.nghh === "") record.nghh = null
            }
            const data = {
                hoaDonXuat,
                lstAddThuoc: listXuat,
            }
            if (!isUpdate) {
                XuatKhoService.ChuyenKho(data).then(result => {
                    if (result.isSuccess) {
                        setIDPhieuXuat(result.id);
                        setSoPhieu(result.returnObject.sophieu);
                        // setIsUpdate(true);
                        notiRefSecond.current.Show("Lưu thành công.", NotifyStatus.Success);
                    }
                    else {
                        notiRefSecond.current.Show(result.err.msgString, NotifyStatus.Error);
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    notiRefSecond.current.Show("Lưu dữ liệu thất bại.", NotifyStatus.Error);
                    Actions.setLoading(false);
                })
            }
            // else {
            //     XuatKhoService.updateChuyenKho(IDPhieuXuat, data).then(result => {
            //         if (result.isSuccess) {
            //             setIDPhieuXuat(result.id);
            //             notiRefSecond.current.Show("Cập nhật thành công.", NotifyStatus.Success);
            //             searchThuocVatTu.current && searchThuocVatTu.current.handleSearch([]);
            //         }
            //         else {
            //             notiRefSecond.current.Show(result.err.msgString, NotifyStatus.Error);
            //             Actions.openMessageDialog("Lỗi", result.err.msgString);
            //         }
            //         Actions.setLoading(false);
            //     }).catch(err => {
            //         notiRefSecond.current.Show("Cập nhật dữ liệu thất bại", NotifyStatus.Error);;
            //         Actions.setLoading(false);
            //     })
            // }
        } else {
            validator.current.showMessages();
            notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
            forceUpdate();
        }
    }

    const deleteThuocVatTuXuat = () => {
        let dataDelete = listXuat.filter(x => x.selected === true && x.selected !== undefined);
        if (dataDelete.length === 0 || dataDelete === undefined) {
            notiRefSecond.current.Show("Chưa chọn thuốc - vật tư cần xóa.", NotifyStatus.Warning);
            return;
        }
        if (dataDelete) {
            handleChangeListXuat(listXuat.filter(x => x.selected === false || x.selected == undefined));
            notiRefSecond.current.Show(`Đã xóa ${dataDelete.length} thuốc - vật tư`, NotifyStatus.Success);
        }
    }

    const printPhieuChuyen = () => {
        if (IDPhieuXuat) {
            PrintService.PrintPhieuChuyenKho(IDPhieuXuat, moment(ngayChuyen).format("YYYY-MM-DD")).then((result) => {
                Actions.DoPrint(result.item)
            })
        } else {
            notiRefSecond.current.Show("Không có thông tin để in.", NotifyStatus.Error);
        }
    }

    const setData = (data) => {
        // setIsUpdate(true);
        setLyDoChuyen(data.hoadonxuat.lydo);
        setIDPhieuXuat(data.idPhieu);
        setSoPhieu(data.hoadonxuat.sophieu);
        setKhoXuat(data.hoadonxuat.makho);
        setKhoNhap(data.hoadonxuat.makhonhap);
        setNguoiChuyen(data.hoadonxuat.nguoinhap);
        setIDNguoiNhan(data.hoadonxuat.idnguoinhan);
        setTenNguoiNhan(data.hoadonxuat.tennguoinhan);
        handleChangeListXuat(data.lstAddThuoc);
    }

    useEffect(() => {
        loadlistKho();
    }, []);

    useImperativeHandle(ref, () => ({
        setData: (data) => {
            setData(data);
        },
        setCreateState: () => {
            resetState();
        },
        setUpdateState: (data) => {
            setUpdateState(data);
        },
    }));

    const resetState = () => {
        setIsUpdate(false);
        setListXuat([]);
        setLyDoChuyen(null);
        setIDPhieuXuat(null);
        setSoPhieu(null);
        setKhoXuat(listKho && listKho.length > 0 ? listKho[0].value : null);
        setKhoNhap(null);
        setNguoiChuyen(localStorage.getItem("name"));
        setIDNguoiNhan(null);
        setTenNguoiNhan(null);
        setNgayChuyen(new Date());
        tableListXuat && tableListXuat.current.setDataSource([]);
        searchThuocVatTu.current && searchThuocVatTu.current.setValue("");
        validator.current.purgeFields();
        validator.current.hideMessages();
        notiRef && notiRef.current.Close();
        notiRefSecond && notiRefSecond.current.Close();
    }

    const onclose = () => {
        props.onCancel();
        setNgayChuyen(new Date())
        setIsUpdate(false);
        setKeySearch("");
        setIsPreview(false);
        notiRef && notiRef.current.Close();
        notiRefSecond && notiRefSecond.current.Close();
    }

    useEffect(() => {
        if (!isPreview) {
            handleChangeListXuat([]);
            handleChangeListXuat([]);
            setKhoNhap(null);
            setKeySearch("");
            searchThuocVatTu.current && searchThuocVatTu.current.handleSearch([]);
        }
    }, [khoXuat, isPreview])

    const setUpdateState = (data) => {
        setIsPreview(true);
        if (data) {
            const dataInfo = data.hoadonxuat;
            const dataXuat = data.lstAddThuoc;
            setSoPhieu(dataInfo.sophieu);
            setIDPhieuXuat(dataInfo.id);
            setKhoXuat(Number(dataInfo.makho));
            setKhoNhap(Number(dataInfo.makhonhap));
            setNguoiChuyen(dataInfo.nguoinhap);
            setTenNguoiNhan(dataInfo.tennguoinhan);
            setLyDoChuyen(dataInfo.lydo);
            setNgayChuyen(moment(dataInfo.ngaynhap).toDate());
            handleChangeListXuat(dataXuat);
        }
    }

    return (
        <Fragment>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>{isPreview ? "Chi tiết" : !isUpdate ? "Tạo mới" : "Cập nhật"} - Phiếu chuyển kho</h3>
                <h3 onClick={() => props.onCancel()}><i className='far fa-times-circle' /></h3>
            </div>
            <Notify ref={notiRef} />
            <div className='row'>
                <div className='col-md-4 mt-2'>
                    <label>Mã phiếu xuất</label>
                    <Input disabled={isPreview} placeholder='Tạo tự động từ hệ thống' readOnly style={{ cursor: 'not-allowed' }} value={soPhieu}></Input>
                </div>
                <div className='col-md-4 mt-2'>
                    <label>Người tạo</label>
                    <Input disabled={isPreview} placeholder='Người tạo' value={nguoiChuyen} readOnly style={{ cursor: 'not-allowed' }}></Input>
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Ngày chuyển kho</label>
                    <DateTimePicker
                        readOnly={isUpdate || isPreview} disabled={isUpdate || isPreview} style={{ cursor: isUpdate ? 'not-allowed' : "pointer" }}
                        className="w-100"
                        locale='vi'
                        format={'dd-MM-yyyy'}
                        isClockOpen={false}
                        isCalendarOpen={false}
                        calendarIcon={<Tooltip title="Mở lịch"><i className='far fa-calendar-alt customclearicon' /></Tooltip>}
                        clearIcon={null}
                        onChange={(e) => setNgayChuyen(moment(e).toDate())}
                        maxDate={moment().toDate()}
                        value={ngayChuyen ? ngayChuyen : new Date()} />
                    {validator.current.message("Ngày chuyển kho", ngayChuyen, "required")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Kho xuất</label>
                    <Select
                        className='w-100'
                        value={khoXuat}
                        onChange={(e) => {
                            setKhoXuat(e);
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        readOnly={isUpdate || isPreview} disabled={isUpdate || isPreview} style={{ cursor: isUpdate ? 'not-allowed' : "pointer" }}
                    >
                        {listKho && listKho.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                    {validator.current.message("Kho xuất", khoXuat, "selection")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Kho nhập</label>
                    <Select
                        className='w-100'
                        value={khoNhap}
                        onChange={(e) => {
                            setKhoNhap(e);
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        readOnly={isUpdate || isPreview} disabled={isUpdate || isPreview} style={{ cursor: isUpdate ? 'not-allowed' : "pointer" }}
                    >
                        {listKho && listKho.filter(x => Number(x.value) !== Number(khoXuat)).map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                    {validator.current.message("Kho nhập", khoNhap, "selection")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Người nhận</label>
                    <AutocompletetableNoGroup
                        readOnly={isUpdate || isPreview} disabled={isUpdate || isPreview} style={{ cursor: isUpdate ? 'not-allowed' : "pointer" }}
                        ref={ThongTinNguoiNhan}
                        placeholder={"Nhập tên người nhận"}
                        width='100%'
                        tablewidth={500}
                        onSearch={(e) => loadNguoiNhan(e)}
                        onSelect={(e) => selectNguoiNhan(e)}
                        headers={columnsNguoiNhan}
                        keyValue={'id'}
                        value={tenNguoiNhan}
                        onChange={(e) => { setTenNguoiNhan(e) }}
                        showRemove={IDNguoiNhan !== '' && IDNguoiNhan !== undefined && IDNguoiNhan !== null && !isUpdate && !isPreview}
                        CleanText={() => {
                            setIDNguoiNhan(null);
                            setTenNguoiNhan(null);
                        }}
                    />
                    {validator.current.message("Tên người nhận", tenNguoiNhan, "selection")}
                </div>
                <div className='col-md-12 mt-2'>
                    <label className='required-field'>Lý do chuyển</label>
                    <TextArea disabled={isPreview} size={2} value={lyDoChuyen} onChange={(e) => setLyDoChuyen(e.target.value)} placeholder="Nhập lý do chuyển" />
                    {validator.current.message("Lý do chuyển", lyDoChuyen, "required")}
                </div>
            </div>
            <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                <h5>Chi tiết phiếu chuyển kho</h5>
            </div>
            <Notify ref={notiRefSecond} />
            <div className='row'>
                {!isPreview && <React.Fragment>
                    <div className='col-md-12 mt-2'>
                        <AutocompletetableNoGroup
                            disabled={isPreview}
                            ref={searchThuocVatTu}
                            placeholder="Tìm kiếm theo tên, mã thuốc - vật tư, hoạt chất, lô"
                            width='100%'
                            onSearch={(e) => loadTonKho(e)}
                            onSelect={(e) => addItem(e)}
                            headers={columnsThuocVatTu}
                            keyValue={'idtheodoi'}
                            title="----"
                            value={keySearch}
                            listHeight={400}
                            keepTransfrom={true}
                        />
                    </div>
                    <div className='col-md-12 mb-2 mt-2'>
                        <button
                            disabled={isPreview}
                            className="btn btn-danger"
                            onClick={() => { deleteThuocVatTuXuat() }}>
                            <i className="far fa-trash-alt mr-2" />Xóa hạng mục
                        </button>
                    </div>
                </React.Fragment>}
                <div className="col-md-12">
                    <EditableTable
                        ref={tableListXuat}
                        height={"300px"}
                        scrollY={"45vh"}
                        onchange={(data) => { handleChangeListXuat(data) }}
                        dataSource={listXuat}
                        columns={columnsTableListXuat}
                        pagination={false}
                        rowClassName={(record) => {
                            return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                        }}
                        keyFind={'ma'}
                        keyFind2={'losx'}
                    ></EditableTable>
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className='row'>
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="btn btn-color" onClick={() => { printPhieuChuyen() }}>
                        <i className="fa fa-print mr-2" />In
                    </button>
                    <div>
                        {!isPreview && <button className="btn btn-color" onClick={() => { saveOrUpdatePhieuChuyen() }} disabled={isPreview}>
                            <i className="fa fa-save mr-2" />{!isUpdate ? "Lưu" : "Cập nhật"}
                        </button>}
                        <button className="btn btn-danger ml-2" onClick={() => { onclose() }}>
                            <i className="fas fa-sign-out-alt mr-2" />Đóng
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});