import { DatePicker, Form, Input, InputNumber, notification, Select, Checkbox, Tooltip, Tag, Alert } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as AdministrativeService from '../../../services/administrativeService';
import * as CommonService from '../../../services/commonService';
import * as PatientService from '../../../services/patientService';
import * as ConfigService from '../../../services/configService';
import Barcode from './barcode';
import './administrative.css';
import SimpleReactValidator from 'simple-react-validator';
import * as EnterInfoService from '../../../services/enterInfoService';
import { ValidationCustom } from '../../../commons/validationCustom';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import { GetList } from '../../corporation/CorporationServices';
import * as SystemConfig from '../../../configure/systemConfig';
import * as NhanVienSerivce from '../../../services/employeeService';
import { connect } from 'react-redux';
import { addressDuplicate, isNullOrEmpty, isnotnull } from '../../../libs/util';
import * as Actions from '../../../libs/actions';
import MitaDisplayLongText from '../../../components/MitaComponents/mitaDisplayLongText';

let taskDelay= null;

const { Option } = Select;
var DEFAULT_CITY = null;
var DEFAULT_DISTRICT = null;
var DEFAULT_WARD = null;
const regEx = new RegExp('(^[a-zA-Z])');
class Administrative extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstNation: [],
            lstPeople: [],
            lstJob: [],
            lstCity: [],
            lstDistrict: [],
            lstWard: [],
            managerId: '',
            address: '',
            reason: '',
            mach: '', nhietdo: '', huyetap: '', cannang: '', chieucao: '',
            lstdepartment: [],
            department: [],
            newAddress: true,
            phone: '',
            cmnd: '',
            email: '',
            idngt: '',
            tenngt: '',
            idsale:'',
            saleName: '',
            coporator: null,
            listCoporators: [],
            sendby: [],
            disableCopor : false,
            lstngt : [],
            lstMarketing : [],
            marketing : [],
            defaultAddress: props.defaultAddress,
            addressFull: "",
            addressWarning: false,
            isDuplicate: false,
            duplicateInfo: "",
            initPatientSelected: {},
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }

    componentDidMount() {
        if (this.state.defaultAddress) {
            const listDefaultAddr = this.state.defaultAddress;
            let foundCity = listDefaultAddr.find(x => x.key === 'city');
            let foundDistrict = listDefaultAddr.find(x => x.key === 'district');
            let foundWard = listDefaultAddr.find(x => x.key === 'ward');
            if (this.props.administrativeRequired && foundCity !== undefined && foundDistrict !== undefined && foundWard !== undefined) {
                DEFAULT_CITY = Number(foundCity.value);
                DEFAULT_DISTRICT = Number(foundDistrict.value);
                DEFAULT_WARD = Number(foundWard.value);
            }
        }
        if (this.props.onLoaded) {
            this.props.onLoaded(this);
        }
        // this.loadPeople();
        this.loadCity();
        // this.LoadDepartment();
        this.form.setFieldsValue({ 'dob': moment(), age: 0 });
        this.defaultAddress();
        //this.resetState();
        // this.loadMarketing();
        this.loadHTTKQ();
    }

    defaultAddress() {
        setTimeout(() => this.loadDistrict(DEFAULT_CITY ? DEFAULT_CITY.toString() : null, 1, 100), 150);
        setTimeout(() => this.loadWard(DEFAULT_DISTRICT ? DEFAULT_DISTRICT.toString() : null, 1, 100), 300);
    }

    setMabn(mabn) {
        //this.form.setFieldsValue({mabn:mabn});

        this.setState({ code: mabn })
    }

    LoadDepartment() {
        CommonService.DanhSachKhoaKham().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstdepartment: result.data
                });
            }
        })
    }

    resetState() {
        this.form.resetFields();
        this.form.setFieldsValue({
            city: DEFAULT_CITY ?  DEFAULT_CITY : null,
            ward: DEFAULT_WARD ? DEFAULT_WARD : null,
            district: DEFAULT_DISTRICT ? DEFAULT_DISTRICT : null,
            gender: null,
            people: null,
            job: null,
            nation: "VN",
            name: '',
            cmnd: null,
            cardId: '',
            jobAddress: '',
            validAdministrative: false,
            village: '',
            mabn: '',
            dob: moment(),
            age: 0,
            phone: null,
            email: null,
            address: '',
            department: null,
            agecalbymonth: null,
            coporator: null,
            sendby: [],
            marketing : [],
        });
        this.setState({
            code: '',
            relative: '',
            relativeName: '',
            relativeAddress: '',
            relativePhone: '',
            reason: '',
            newAddress: true,
            mach: '', nhietdo: '', huyetap: '', cannang: '', chieucao: '',
            phone: '',
            cmnd: '',
            email: '',
            idngt: '',
            tenngt: '',
            idkhaibao: '',
            coporator: null,
            sendby: [],
            onlyyear: false,
            saleName: '',
            disableCopor: false,
            marketing : [],
            address:'',
            addressFull: "",
            addressWarning: false,
            isDuplicate: false,
            duplicateInfo: "",
            initPatientSelected: {}
        })
        this.defaultAddress();
    }

    handleLoadPatientBarCode(patientInfo) {
        if (this.props.onSeached) {
            if (patientInfo.patientID)
                this.props.onSeached(patientInfo.patientID);
        }
        if (this.props.sendData)
            if (patientInfo.patientID)
                this.props.sendData(patientInfo);
        if (!patientInfo.patientID || patientInfo.patientID === '') {
            if (this.props.SetCodeNull)
                this.props.SetCodeNull()
        }
        if (this.props.getPatientName) {
            if (patientInfo.patientName)
                this.props.getPatientName(patientInfo.patientName);
        }
        if(patientInfo.coporator !== '' && patientInfo.coporator !== null){
            // this.setState({
            //     disableCopor : true
            // });
            this.form.setFieldsValue({
                coporator : null
            });
        } else {
            this.setState({
                disableCopor : false
            })
        }
        if(patientInfo.marketing !== null && patientInfo.marketing !== ''){
            var lst = patientInfo.marketing.split(';');
            this.setState({
                marketing : lst
            })
        }
        let months = new moment().diff(patientInfo.birthDay !== '' ? moment(patientInfo.birthDay) : moment(), 'months');
        this.setState({ code: patientInfo.patientID, managerId: patientInfo.managementID })
        this.setState({
            cmnd: patientInfo.cardId,
            phone: patientInfo.phone,
            email: patientInfo.email,
            address: patientInfo.address,
            addressFull: "",
            newAddress: false,
            agecalbymonth: months >= 24 ? null : months,
            idngt: patientInfo.idngt,
            tenngt: patientInfo.nguoigioithieu,
            idkhaibao: patientInfo.idkhaibao ? patientInfo.idkhaibao : '',
            coporator: patientInfo.coporator && patientInfo.coporator !== "" ? patientInfo.coporator : null,
            onlyyear: patientInfo.onlyyear,
            idsale : patientInfo.idsale ? patientInfo.idsale : '',
            saleName : patientInfo.saleName,
            addressWarning: patientInfo.city && patientInfo.address == patientInfo.addressFull,
            initPatientSelected: patientInfo
        })
        this.form.setFieldsValue({
            phone: patientInfo.phone,
            email: patientInfo.email,
            managementID: patientInfo.managementID,
            name: patientInfo.patientName,
            city: patientInfo.city,
            agecalbymonth: months >= 24 ? null : months,
            dob: patientInfo.birthDay !== '' ? moment(patientInfo.birthDay) : moment(),
            people: 25,
            village: '',
            gender: patientInfo.gender,
            cmnd: patientInfo.cardId,
            age: months >= 24 ? (patientInfo.birthDay !== '' ? moment().year() - moment(patientInfo.birthDay).year() : 0) : 0,
            job: patientInfo.job,
            jobAddress: patientInfo.jobPlace,
            mabn: patientInfo.patientID,
            code: patientInfo.patientID,
            nation: 'VN',
            address: patientInfo.address,
            coporator: patientInfo.coporator && patientInfo.coporator !== "" ? patientInfo.coporator : null,
        });

        this.loadDistrict(patientInfo.city, 1, 100, () => {
            this.form.setFieldsValue({
                district: patientInfo.district
            });
        });
        setTimeout(() => {
            this.loadWard(patientInfo.district, 1, 100, () => {
                this.form.setFieldsValue({
                    ward: patientInfo.ward,
                });
            });
        }, 100);
    }
    loadPeople(pageNumber, pageSize) {
        AdministrativeService.GetAllPeople(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstPeople: result.data,

                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu dân tộc bị lỗi' })
        })
    }
    loadJob(pageNumber, pageSize) {
        AdministrativeService.GetAllJob(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstJob: result.data,

                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nghề nghiệp bị lỗi' })
        })
    }

    loadCity(pageNumber, pageSize) {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.form.setFieldsValue({
                    ward: null,
                    district: null,
                    city: DEFAULT_CITY ? DEFAULT_CITY : null
                });
                this.setState({
                    lstCity: result.data,
                    lstDistrict: [],
                    lstWard: [],
                }, () => this.getAddressFull());
            }
        }).catch(err => {
            console.log(err);
            //notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }

    loadDistrict(id, pageNumber, pageSize, callback) {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {

            if (result.isSuccess && result.data.length > 0) {
                this.form.setFieldsValue({
                    district: DEFAULT_CITY && id === DEFAULT_CITY.toString() ? DEFAULT_DISTRICT : null,
                    ward: null,
                });
                this.setState({
                    lstDistrict: result.data,
                    lstWard: [],

                }, () => {
                    if (callback) { callback() }
                    this.getAddressFull();
                });
            }
            else {
                this.form.setFieldsValue({
                    district: null,
                    ward: null,
                });
                this.setState({
                    lstDistrict: [],
                    lstWard: [],

                }, () => this.getAddressFull());
            }
        }).catch(err => {
            console.log(err);
            //notification.warning({ message: 'Tải dữ quận huyện thị bị lỗi' })
        })
    }

    loadWard(id, pageNumber, pageSize, callback) {
        AdministrativeService.GetWardByDistrictId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.form.setFieldsValue({
                    ward: DEFAULT_DISTRICT && id === DEFAULT_DISTRICT.toString() ? DEFAULT_WARD : null,
                });
                this.setState({
                    lstWard: result.data,
                }, () => {
                    if (callback) { callback() }
                    this.getAddressFull();
                });
            }
            else {
                this.form.setFieldsValue({
                    ward: null,
                });
                this.setState({
                    lstWard: [],
                }, () => this.getAddressFull());
            }
        }).catch(err => {
            console.log(err);
            //notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }

    loadMarketing(){
        ConfigService.GetKenhKhachHang(1,100,false).then(result => {
            if(result.isSuccess){
                this.setState({
                    lstMarketing : result.data
                })
            }else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {

        })
    }

    onChangeName(value) {
        this.setState({ name: value.toUpperCase() })
    }

    getState() {
        let obj = this.form.getFieldsValue();
        obj.mach = this.state.mach;
        obj.nhietdo = this.state.nhietdo;
        obj.huyetap = this.state.huyetap;
        obj.cannang = this.state.cannang;
        obj.chieucao = this.state.chieucao;
        obj.address = this.state.address;
        obj.addressFull = this.state.addressFull;
        obj.relative = this.state.relative;
        obj.relativeName = this.state.relativeName;
        obj.relativeAddress = this.state.relativeAddress;
        obj.relativePhone = this.state.relativePhone;
        obj.phone = this.state.phone;
        obj.cmnd = this.state.cmnd;
        obj.email = this.state.email;
        obj.code = this.state.code;
        obj.idngt = this.state.idngt;
        obj.idsale = this.state.idsale;
        obj.idkhaibao = this.state.idkhaibao;
        obj.coporator = this.state.coporator;
        obj.sendby = this.state.sendby;
        obj.onlyyear = this.state.onlyyear;
        obj.banggia = this.state.banggia;
        obj.marketing = this.state.marketing;
        obj.mabn = this.state.code;
        return obj;
    }

    disabledDate(current) {
        let customDate = moment().add(1, 'days').format('YYYY-MM-DD').toString();
        return current && current > moment(customDate, 'YYYY-MM-DD');
    }

    getForm() {
        return this.form
    }

    onChangeDob = date => {
        if (date.isValid() && this.form !== null) {
            let months = new moment().diff(date, 'months')

            if (months >= 24) {
                this.form.setFieldsValue({
                    age: new moment().year() - date.year(),
                    agecalbymonth: ''
                })
            } else {
                this.form.setFieldsValue({
                    agecalbymonth: months,
                    age: new moment().diff(date, 'years')
                })
            }
        }
    }
    onchangeAge = e => {
        if (e === null)
            e = 0;
        if (this.form !== null && e !== null)
            this.form.setFieldsValue({
                dob: moment(new moment().subtract(e, 'years').format("YYYY-01-01")),
                agecalbymonth: '',
                age: e,
            })

    }
    onchangeAgeMonth = e => {
        if (this.form !== null && e !== null) {
            let dob = new moment().subtract(e, 'months').format("YYYY-MM-01");
            this.form.setFieldsValue({
                dob: moment(dob),
                age: e === 24 ? new moment().diff(moment(dob), 'years') : 0,
                agecalbymonth: e === 24 ? 0 : e,
            })
            if (e !== null && e !== undefined && this.state.onlyyear) {
                this.setState({ onlyyear: false })
            }
        }

    }
    TypePatient(data) {
        this.form.setFieldsValue({ 'name': data.toUpperCase() })
    }
    loadKCB(key) {
        EnterInfoService.GetDSKCB(key).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstcardAddress: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi đăng ký bị lỗi' })
        })
    }
    loadNguoiGioiThieu(data) {
        if (this.props.onType) {
            this.props.onType(data);
        }
        this.setState({ key: data });
        return PatientService.GetListNguoiGioiThieu(data, false, 1, 20);
    }
    SelectNguoiGioiThieu(data) {
        this.setState({ idngt: data.id, tenngt: data.hoten });
    }
    handleEnter(event, nextIndex) {
        if (event.keyCode === 13) {
            try {
                const form = event.target.form;
                const index = Array.prototype.indexOf.call(form, event.target);
                // form.elements[index + 1].focus(); //Focus vào item kế
                form.elements[nextIndex].focus(); //Focus vào item đã chọn
                event.preventDefault();
            } catch (error) {

            }
        }
    }
    checkPassport = (value) => {
        return regEx.test(value);
    }

    addMarkerting = (e) => {
        let lst = this.state.marketing;
        if(e.target.checked){
            lst.push(e.target.value.toString());
        } else { 
            lst = lst.filter((item) => item !== e.target.value.toString())
        }
        this.setState({ marketing : lst });
    }
    
    loadSale(data) {
        if (this.props.onType) {
            this.props.onType(data);
        }
        this.setState({ key: data });
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100, 'sale');
    }

    SelectSale(data) {
        this.setState({ idsale: data.id, saleName: data.hoten });
    }

    onChangeCheckYearOnly() {
        let obj = this.form.getFieldsValue();
        let ageByMonth = obj.agecalbymonth;
        if (ageByMonth != undefined && ageByMonth != null && Number(ageByMonth) > 0) {
            this.setState({ onlyyear: false });
            notification.warning({ message: "Không thể thay đổi khi tính tuổi theo tháng" })
        }
        else this.setState({ onlyyear: !this.state.onlyyear })
    }

    loadHTTKQ(key = "", page = 1, size = 10) {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listHTTKQ: res.data
                }, () => this.forceUpdate())
            }
        })
    }

    getAddressFull(addr = this.state.address) {
        let obj = this.form.getFieldsValue();
        let address = addr;
        if (!isNullOrEmpty(obj.ward) && this.state.lstWard) {
            for (let ward of this.state.lstWard) {
                if (obj.ward?.toString().slice(-2) !== "00" && ward.label.toLowerCase() != "không xác định" && obj.ward?.toString() === ward.value.toString()) {
                    address += (isNullOrEmpty(address) ? "" : ', ') + ward.label;
                    break;
                }
            }
        }
        if (!isNullOrEmpty(obj.district) && this.state.lstDistrict)
            for (let dis of this.state.lstDistrict) {
                if (obj.district?.toString().slice(-2) !== "00" && dis.label.toLowerCase() != "không xác định" && obj.district?.toString() === dis.value.toString()) {
                    address += (isNullOrEmpty(address) ? "" : ', ') + dis.label;
                    break;
                }
            }
        if (!isNullOrEmpty(obj.city) && this.state.lstCity)
            for (let city of this.state.lstCity) {
                if (obj.city?.toString() === city.value.toString() && city.label.toLowerCase() != "không xác định") {
                    address += (isNullOrEmpty(address) ? "" : ', ') + city.label;
                    break;
                }
            }
        this.setState({ addressFull: address });
    }

    joinCity(cityId) {
        let { address } = this.state;
        for (let city of this.state.lstCity) {
            if (cityId?.toString() === city.value.toString() && city.label.toLowerCase() != "không xác định") {
                address += (isNullOrEmpty(address) ? "" : ', ') + city.label;
                break;
            }
        }
        this.setState({ addressFull: address });
    }

    joinDistrict(disId) {
        let obj = this.form.getFieldsValue();
        let { address } = this.state;
        if (this.state.lstDistrict)
            for (let dis of this.state.lstDistrict) {
                if (disId?.toString().slice(-2) !== "00" && dis.label.toLowerCase() != "không xác định" && disId?.toString() === dis.value.toString()) {
                    address += (isNullOrEmpty(address) ? "" : ', ') + dis.label;
                    break;
                }
            }
        if (this.state.lstCity)
            for (let city of this.state.lstCity) {
                if (obj.city?.toString() === city.value.toString() && city.label.toLowerCase() != "không xác định") {
                    address += (isNullOrEmpty(address) ? "" : ', ') + city.label;
                    break;
                }
            }
        this.setState({ addressFull: address });
    }

    joinWard(wardId) {
        let obj = this.form.getFieldsValue();
        let { address } = this.state;
        for (let ward of this.state.lstWard) {
            if (wardId?.toString().slice(-2) !== "00" && ward.label.toLowerCase() != "không xác định" && wardId?.toString() === ward.value.toString()) {
                address += (isNullOrEmpty(address) ? "" : ', ') + ward.label;
                break;
            }
        }
        if (this.state.lstDistrict)
            for (let dis of this.state.lstDistrict) {
                if (obj.district?.toString().slice(-2) !== "00" && dis.label.toLowerCase() != "không xác định" && obj.district?.toString() === dis.value.toString()) {
                    address += (isNullOrEmpty(address) ? "" : ', ') + dis.label;
                    break;
                }
            }
        if (this.state.lstCity)
            for (let city of this.state.lstCity) {
                if (obj.city?.toString() === city.value.toString() && city.label.toLowerCase() != "không xác định") {
                    address += (isNullOrEmpty(address) ? "" : ', ') + city.label;
                    break;
                }
            }
        this.setState({ addressFull: address });
    }

    checkAddressDuplicate() {
        if (addressDuplicate(this.state.addressFull))
            return <span className='ml-1'><Tag color='red'>Có vẻ chưa đúng</Tag></span>
        else return "";
    }

    CheckDuplicatePatient() {
        let isDuplicate = false;
        let duplicateInfo = "";
        let obj = this.form.getFieldsValue();
        const data = {
            "HoTen": obj.name,
            "NgaySinh": moment(obj.dob).format('YYYY-MM-DD'),
            "Phai": obj.gender,
            "DienThoai": this.state.phone,
            "CMND": this.state.cmnd,
            "OnlyYear": this.state.onlyyear
        }
        AdministrativeService.CheckDuplicatePatient(data).then(result => {
            if (result.isSuccess) {
                isDuplicate = !isnotnull(result.data);
                duplicateInfo = result.data;
            } else {
                isDuplicate = false;
                duplicateInfo = "Đã xảy ra lỗi khi kiểm tra dữ liệu bệnh nhân/khách hàng";
            }
        }).catch(err => {
            duplicateInfo = "Chưa thể kiểm tra dữ liệu bệnh nhân trùng lặp";
        }).finally(() => {
            this.setState({
                isDuplicate,
                duplicateInfo
            })
        })
    }

    OnBlurFieldPatientCheck() {
        const hasModified = this.form.getFieldValue("name") != this.state.initPatientSelected.patientName
        || this.form.getFieldValue("gender") != this.state.initPatientSelected.gender
        || this.form.getFieldValue("dob").format("YYYY-MM-DD") != moment(this.state.initPatientSelected.birthDay).format("YYYY-MM-DD")
        || this.state.phone != this.state.initPatientSelected.phone
        || this.state.cmnd != this.state.initPatientSelected.cardId;
        if (hasModified) this.CheckDuplicatePatient();
    }

    render() {
        let { disabled } = this.props;
        let newPatient = this.props.newPatient;
        const columnNguoiGioiThieu = [
            {
                title: 'Mã',
                dataIndex: 'id',
                key: 'id',
                width: 80,
            },
            {
                title: 'Tên',
                dataIndex: 'hoten',
                key: 'hoten',
                width: 250,
            },
            {
                title: 'Điện thoại',
                dataIndex: 'dienthoai',
                key: 'dienthoai',
                width: 100,
            },
            {
                title: 'Giới tính',
                dataIndex: 'gioitinh',
                key: 'gioitinh',
                width: 80,
            },
        ];
        return (
            <React.Fragment>
                <div>
                    {!isNullOrEmpty(this.state.duplicateInfo) &&
                        <Alert type="info"
                            message={
                                <div className='text-warning'>
                                    <MitaDisplayLongText content={this.state.duplicateInfo}
                                        maxLength={26}
                                        showMoreText={"...Xem thêm"}
                                        showLessText={" Rút gọn"}>
                                    </MitaDisplayLongText>
                                </div>}
                        />}
                </div>
                <div style={{ position: 'relative' }}>
                    <Form ref={(c) => this.form = c} >
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12 mt-1">
                                <label className='required-field'>Họ và tên</label>
                                <Form.Item className={`${disabled === true ? 'input-not-formitem-disable' : 'input-not-formitem'}`}
                                    style={{ pointerEvents: `${disabled === true ? 'none' : 'unset'}`, display: this.props.isUpdate ? 'none' : '' }}
                                    name="name" rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}
                                    onKeyDown={(e) => this.handleEnter(e, 2)}>
                                    <Barcode
                                        qrcode={false}
                                        ref={(c) => this.Barcode = c}
                                        onType={this.TypePatient.bind(this)}
                                        onBlurPatientName={this.OnBlurFieldPatientCheck.bind(this)}
                                        disabled={disabled} onSuccessLoad={this.handleLoadPatientBarCode.bind(this)}
                                        pagination={true}></Barcode>
                                </Form.Item>
                                <Form.Item
                                    style={{ display: this.props.isUpdate ? '' : 'none' }}
                                    name="name" rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}>
                                    <Input className='w-100' disabled={disabled} style={{ textTransform: 'uppercase' }} placeholder="Nhập họ và tên" />
                                </Form.Item>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-12 mt-1">
                                {newPatient !== true &&
                                    <Form.Item label="Mã BN:" style={{ flex: 1, display: 'none' }}>
                                        <Input disabled={true} value={this.state.code ? this.state.code : ''} />
                                    </Form.Item>
                                }
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <label className='required-field'>Giới tính</label>
                                        <Form.Item name="gender" rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}>
                                            <Select disabled={disabled} className='w-100' onKeyDown={(e) => this.handleEnter(e, 3)}
                                                onBlur={() => this.OnBlurFieldPatientCheck()}>
                                                <Option value={0}>Nam</Option>
                                                <Option value={1}>Nữ</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <Tooltip className='mr-2' title="Chỉ lưu năm sinh">
                                            <Checkbox disabled={disabled} checked={this.state.onlyyear} onChange={() => this.onChangeCheckYearOnly()}></Checkbox>
                                        </Tooltip>
                                        <label className='required-field dob-short'><span>{this.state.onlyyear ? "Năm sinh" : "Ngày sinh"}</span></label>
                                        <Form.Item name="dob" placeholder="dd/mm/yyyy" onKeyDown={(e) => this.handleEnter(e, 6)}
                                            min="1990-01-01" max="2030-12-31" rules={[{ required: true, message: 'Vui lòng nhập ngày sinh !' }]} >
                                            <DatePicker disabled={disabled} className='w-100' format={this.state.onlyyear ? "YYYY" : SystemConfig.FORMAT_DATE} allowClear={false} onChange={(e) => { this.onChangeDob(e) }}
                                                locale={viVN} picker={this.state.onlyyear ? "year" : "date"}
                                                disabledDate={(current) => {
                                                    return current && current >= moment().endOf('day');
                                                }}
                                                onBlur={() => this.OnBlurFieldPatientCheck()}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-12 mt-1">
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <label>Tuổi</label>
                                        <Form.Item name="age" >
                                            <InputNumber disabled={disabled} placeholder="Tuổi" min={0} className='w-100' max={150} onChange={(e) => {
                                                this.onchangeAge(e)
                                            }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-6">
                                        <label>Tháng tuổi</label>
                                        <Form.Item
                                            name="agecalbymonth"  >
                                            <InputNumber disabled={disabled} className='w-100' placeholder="Tháng" min={0} max={24} onChange={(e) => {
                                                this.onchangeAgeMonth(e)
                                            }} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-1">
                                <label className={this.props.administrativeRequired ? "required-field" : ""}>Tỉnh/TP</label>
                                <Form.Item name="city" rules={[{ required: this.props.administrativeRequired, message: 'Vui lòng chọn Tỉnh/TP!' }]}>
                                    <Select disabled={disabled}
                                        onChange={(e) => {
                                            this.loadDistrict(e);
                                            this.joinCity(e);
                                        }}
                                        onKeyDown={(e) => this.handleEnter(e, 7)}
                                        showSearch
                                        allowClear={!this.props.administrativeRequired}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstCity.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-lg-4 mt-1">
                                <label className={this.props.administrativeRequired ? "required-field" : ""}>Quận/Huyện</label>
                                <Form.Item name="district" rules={[{ required: this.props.administrativeRequired, message: 'Vui lòng chọn Quận/Huyện!' }]}>
                                    <Select disabled={disabled}
                                        showSearch
                                        onChange={(e) => {
                                            this.loadWard(e, 1, 100);
                                            this.joinDistrict(e);
                                        }}
                                        onKeyDown={(e) => this.handleEnter(e, 8)}
                                        allowClear={!this.props.administrativeRequired}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstDistrict.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-lg-4 mt-1">
                                <label className={this.props.administrativeRequired ? "required-field" : ""}>Phường/Xã</label>
                                <Form.Item name="ward" rules={[{ required: this.props.administrativeRequired, message: 'Vui lòng chọn Phường/Xã!' }]}>
                                    <Select disabled={disabled} ref={(c) => { this.phuongxa = c }}
                                        onChange={(e) => {
                                            this.joinWard(e);
                                        }}
                                        onKeyDown={(e) => this.handleEnter(e, 9)}
                                        showSearch
                                        allowClear={!this.props.administrativeRequired}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstWard.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            {this.props.showDepartment === true &&
                                <div className="col-lg-4 mt-1">
                                    {this.props.showDepartment === true &&
                                        <Form.Item label="Khoa khám" style={{ flex: 1, display: 'none' }} name="department"
                                            rules={[{ message: 'Vui lòng chọn khoa khám!' }]}
                                        >
                                            <Select disabled={disabled}>
                                                {this.state.lstdepartment.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.value}>{row.label}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    }
                                </div>
                            }
                            <div className={this.props.nguoigioithieu === true ? `col-lg-8 col-md-12 mt-2` : `col-md-12 mt-2`}>
                                {
                                    this.state.addressWarning
                                    ? <label>Địa chỉ <i className='font-weight-bold text-warning'>*Kiểm tra lại thông tin địa chỉ, nếu đúng, hãy bỏ qua thông báo này</i> <Tooltip title="Số nhà, tên đường, thôn, ấp..."><i className='far fa-question-circle' /></Tooltip></label>
                                    : <label>Địa chỉ <span style={{ color: "#b3b3b3"}}>(số nhà, tên đường, thôn, ấp...)</span></label>
                                }
                                <Form.Item name="address" >
                                    <Input className='w-100' onChange={(e) => this.setState({ address: e.target.value }, () => this.getAddressFull(e.target.value))} disabled={disabled} placeholder="Nhập địa chỉ (số nhà, tên đường, thôn, ấp...)"
                                        onKeyDown={(e) => this.handleEnter(e, 10)} />
                                </Form.Item>
                                <label className='mt-2'>
                                    <b>Địa chỉ đầy đủ:</b>
                                    <span className='mita-value'>{this.state.addressFull}</span>
                                    {this.checkAddressDuplicate()}
                                </label>
                            </div>
                            <div className="col-lg-4 mt-2">
                                <label>Điện thoại</label>
                                <Form.Item name="phone">
                                    <Input
                                        disabled={disabled}
                                        min={0}
                                        maxLength={11}
                                        value={this.state.phone}
                                        placeholder="Nhập số điện thoại"
                                        onKeyPress={(e) => {
                                            if (e.key === " " || isNaN(e.key) === true)
                                                e.preventDefault();
                                        }}
                                        onKeyDown={(e) => this.handleEnter(e, 12)}
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value })
                                            this.validator.showMessages();
                                        }}
                                        onBlur={() => this.OnBlurFieldPatientCheck()}/>
                                    {this.validator.message("Số điện thoại", this.state.phone, 'phonenumber|maxLen:11,num')}
                                </Form.Item>
                            </div>
                            <div className="col-lg-4 mt-1">
                                <label>{this.checkPassport(this.state.cmnd) ? "Hộ chiếu" : "Căn cước công dân"}</label>
                                <Form.Item name='cardId'>
                                    <Input className="w-100" value={this.state.cmnd} disabled={disabled} min={0} maxLength={20} placeholder="Nhập căn cước công dân"
                                        // onKeyPress={(e) => {
                                        //     if (e.key === " " || isNaN(e.key) === true)
                                        //         e.preventDefault();
                                        // }}
                                        onKeyDown={(e) => this.handleEnter(e, 13)}
                                        onChange={(e) => {
                                            this.setState({ cmnd: e.target.value })
                                            this.validator.showMessages()
                                        }}
                                        onBlur={() => this.OnBlurFieldPatientCheck()} />
                                    {this.state.cmnd && this.checkPassport(this.state.cmnd) ?
                                        this.validator.message("Hộ chiếu", this.state.cmnd, "passport|maxLen:11,num")
                                        : this.validator.message("CMND/CCCD", this.state.cmnd, "cmnd|maxLen:20,num")}
                                </Form.Item>
                            </div>
                            <div className="col-lg-4 mt-1">
                                <label>Email</label>
                                <Form.Item name="email">
                                    <Input value={this.state.email} disabled={disabled} type="email" placeholder="Nhập email"
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                            this.validator.showMessages()
                                        }} />
                                    {this.validator.message("Địa chỉ email", this.state.email, "email")}
                                </Form.Item>
                            </div>
                            {!this.props.isnotRegister && <div className="col-lg-4 mt-1">
                                <label>Hình thức trả kết quả<Tooltip title="Chỉ áp dụng cho lần đăng ký này">&nbsp;<i className='far fa-question-circle' /></Tooltip></label>
                                <Form.Item name="sendby">
                                    <Select value={this.state.sendby} disabled={disabled} placeholder="Chọn hình thức trả kết quả"
                                        onChange={(value) => {
                                            this.setState({ sendby: value })
                                        }}
                                        showSearch
                                        onSearch={(e) => {
                                            if (taskDelay) clearTimeout(taskDelay);
                                            taskDelay = setTimeout(() => {
                                                this.loadHTTKQ(e);
                                            }, 400);
                                        }}
                                        mode='multiple'
                                        filterOption={(input, option) =>{
                                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                                    option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                        }}>
                                        {
                                            this.state.listHTTKQ && this.state.listHTTKQ.map((x, index) => {
                                                return (
                                                    <Option key={x.id} value={x.keyValue}>{x.keyName}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>}
                            {/* <div className="col-lg-4 mt-1">
                                <label>Nguồn khách hàng<Tooltip title="Thuộc về thông tin khách hàng"><i className='far fa-question-circle ml-1'></i></Tooltip></label>
                                <Form.Item name="coporator">
                                    <Select value={this.state.coporator} disabled={disabled} placeholder="Chọn nguồn khách hàng"
                                        onChange={(e, item) => {
                                            if(e !== '' && e !== null && e !== undefined){
                                                let data = this.state.listCoporators.find(x => x.ma === e);
                                                let banggia = data.banggia;
                                                this.setState({ 
                                                    coporator: e, 
                                                    banggia : banggia, 
                                                    idsale : data.idsale,
                                                    tenngt: data.tennguoigioithieu ? data.tennguoigioithieu : '',
                                                    idngt: data.nguoigioithieu,
                                                    saleName : data.saleName ? data.saleName : '', 
                                                    disableCopor: true});
                                                this.props.onReplaceBangGia(banggia);
                                                this.props.onReplaceCoporation(e, item);
                                            } else {
                                                this.setState({
                                                    tenngt : '',
                                                    saleName : '',
                                                    disableCopor : false
                                                })
                                                this.props.onReplaceCoporation(e);
                                            }
                                        }}
                                        showSearch
                                        allowClear
                                        onSearch={(value) => this.loadCoporation(value)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {this.state.listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                            {this.props.nguoigioithieu === true &&
                                <div className="col-4 mt-1">
                                    <label>Người giới thiệu</label>
                                    <Form.Item onKeyDown={(e) => this.handleEnter(e, 11)}
                                        className={`${disabled === true ? 'input-not-formitem-disable' : 'input-not-formitem'}`}
                                    >
                                        <AutocompletetableNoGroup
                                            ref={(c) => this.AutocompletePatient = c}
                                            placeholder={"Nhập thông tin người giới thiệu"}
                                            width='100%'
                                            tablewidth={500}
                                            onSearch={this.loadNguoiGioiThieu.bind(this)}
                                            onSelect={this.SelectNguoiGioiThieu.bind(this)}
                                            headers={columnNguoiGioiThieu}
                                            keyValue={'id'}
                                            value={this.state.tenngt}
                                            showRemove={this.state.tenngt !== '' && this.state.tenngt !== undefined && this.state.tenngt !== null && !this.state.disableCopor}
                                            CleanText={() => {
                                                this.setState({ idngt: '', tenngt: '' });
                                            }}
                                            disabled={this.state.disableCopor}
                                        />
                                    </Form.Item>
                                </div>
                            }
                            {this.props.nguoigioithieu === true &&
                                <div className="col-4 mt-1">
                                    <label>Sale</label>
                                    <Form.Item onKeyDown={(e) => this.handleEnter(e, 11)}
                                        className={`${disabled === true ? 'input-not-formitem-disable' : 'input-not-formitem'}`}
                                    >
                                        <AutocompletetableNoGroup
                                            ref={(c) => this.AutocompletePatient = c}
                                            placeholder={"Nhập thông tin sale"}
                                            width='100%'
                                            tablewidth={500}
                                            onSearch={this.loadSale.bind(this)}
                                            onSelect={this.SelectSale.bind(this)}
                                            headers={columnNguoiGioiThieu}
                                            keyValue={'id'}
                                            value={this.state.saleName}
                                            showRemove={this.state.saleName !== '' && this.state.saleName !== undefined && this.state.saleName !== null && !this.state.disableCopor}
                                            CleanText={() => {
                                                this.setState({ idsale: '', saleName: '' });
                                            }}
                                            disabled={this.state.disableCopor}
                                        />
                                    </Form.Item>
                                </div>
                            } */}
                            {/* <div className="col-lg-12 mt-1">
                                <label>Kênh khách hàng</label>
                                <Form.Item name="marketing" >
                                {this.state.lstMarketing && this.state.lstMarketing.map((item, index)=> {
                                    return(
                                        <Checkbox key={index} onChange={(e) => this.addMarkerting(e)} value={item.id} checked={this.state.marketing.includes(item.id.toString())}>{item.ten}</Checkbox>
                                    )})
                                }
                                </Form.Item>
                            </div> */}
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    defaultAddress: state.global.defaultAddress,
    administrativeRequired: state.global.sysConfig.administrativeRequired ? state.global.sysConfig.administrativeRequired : false,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(Administrative);