import React, { Fragment, useEffect, useCallback, useState, forwardRef, useRef } from 'react';
import { Carousel } from 'antd';
import * as Actions from '../../../../libs/actions';
import * as UploadFileService from '../../../../services/UploadFileService';
import HeaderModal from '../../../../configureHIS/common/headerModal';
import MitaImageVideoSlider from '../../../../components/MitaComponents/mitaImageVideoSlider'
import Notify, { NotifyStatus } from '../../../../components/notify';

const SlideShow = forwardRef((props, ref) => {
    const _notiRef = useRef(null);
    const [files, setFiles] = useState([]);
    // Mã component sử dụng (chẩn đoán hình ảnh, xét nghiệm)
    const [componentKey, setComponentKey] = useState('');
    // Id tham chiếu
    const [refId, setRefId] = useState('');
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        setComponentKey(props.componentKey);
        setRefId(props.refId);
    }, [props]) 

    useEffect(() => {
        if(componentKey !== '')
            GetListFile();
    }, [componentKey, refId])

    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const GetListFile = () => {
        Actions.setLoading(true);
        let filterModel = {ComponentKey: componentKey, RefId: refId }
        UploadFileService.GetListAsync(filterModel).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                setData(res.data);
                _notiRef && _notiRef.current.Close();
                forceUpdate();
            } else {
                Actions.setLoading(false);
                _notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch((err) => {
            Actions.setLoading(false);
            if (err.error && err.error.messageText)
                _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            else _notiRef.current.Show(err, NotifyStatus.Warning);
        })
    }

    const setData = (data) => {
        // setHeaderText("Ảnh minh họa " + data.name);
        if (data && data.length > 0) {
            setFiles(data.map(x=> { 
                return {
                    original: x.fileUrl,
                    thumbnail: x.fileUrl,
                    embedUrl : x.fileUrl,
                    originalClass: 'featured-slide',
                    thumbnailClass: 'featured-thumb',
                    description: '',        
                    renderItem : '',
                    extension : x.extensionName
                }
            }));
        }
    }

    return (
        <Fragment>
        <div className='list-page'>
            <HeaderModal title="Trình diễn hình ảnh & videos" onClose={() => { props.onCancel() }} />
            <div className="list-page-body">{/* body */}
                <div className="row">{/* notification */}
                    <div className="col-md-12"><Notify ref={_notiRef} /></div>
                </div>
                <div className="row">
                    <MitaImageVideoSlider listFiles={files}/>
                </div>
            </div>
        </div>
        </Fragment>
    );
})

export default SlideShow;