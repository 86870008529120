import { Button, DatePicker, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from '../../../configureHIS/common/headerModal';
import * as AuthServices from '../../../services/authServices';
import * as SystemConfig from "../../../configure/systemConfig";
import { BUTTON } from "../../../configureHIS/common/constants";
import TagVertical, { COLOR } from '../../../configureHIS/common/TagVertical';
import ListPatient from "./list";
import LineChart from "./linechart";
import { connect } from 'react-redux';
import SiteFilter from '../../../configureHIS/common/siteFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;

function ThongKeSoLuongBenhNhan(props) {
    const notiRef = useRef(null);
    const _chartRef = useRef();
    const _listRef = useRef();
    const [isChart, setIsChart] = useState(true);
    const [site, setSite] = useState([props.siteid]);
    const [availableSites, setAvailableSites] = useState([]);
    const [listDatePickerTypes,] = useState(
        [
            {
                key: "Năm",
                value: "year",
                format: "YYYY",
            },
            {
                key: "Quý",
                value: "quarter",
                format: "[Quý] Q-YYYY",
            },
            {
                key: "Tháng",
                value: "month",
                format: "MM-YYYY",
            },
            {
                key: "Ngày",
                value: "day",
                format: SystemConfig.FORMAT_DATE,
            }
        ]
    );
    const [datePickerType, setDatePickerType] = useState("day");
    const [datePickerFormat, setDatePickerFormat] = useState(SystemConfig.FORMAT_DATE);
    const [selectedDate, setSelectedDate] = useState([moment(), moment()]);
    const [selectedDateRange, setSelectedDateRange] = useState([moment().startOf("week"), moment()]);
    const [dataSummarize, setDataSummarize] = useState([
        {
            title: "Khách hàng mới",
            color: COLOR.Primary,
            value: 0,
        },
        {
            title: "Khách hàng tái khám",
            color: COLOR.Success,
            value: 0,
        },
        {
            title: "Tổng cộng",
            color: COLOR.Secondary,
            value: 0,
        },
    ]);

    const getAvaiabeSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data)
            }
        })
    };

    useEffect(() => {
        getAvaiabeSites();
    }, []);

    const getData = () => {
        if (isChart) {
            _chartRef && _chartRef.current && _chartRef.current.getChartData();
        } else {
            _listRef && _listRef.current && _listRef.current.getList();
        }
    }

    const exportPatientStatistic = () => {
        _listRef && _listRef.current && _listRef.current.exportPatientStatistic();
    }
    
    const downloadImage = () => {
        _chartRef && _chartRef.current && _chartRef.current.downloadImage();
    };

    return (
        <React.Fragment>
            <div className="containerChilderWrapper" id="thongkesoluongbenhnhan">
                <HeaderModal title="Thống kê số lượng bệnh nhân" />
                <Notify className="mt-2" ref={notiRef} />
                <div className="row">
                    <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                    <div className="col-12 mt-2">
                        <label>Lựa chọn</label>
                    </div>
                    <div className="col-auto">
                        <Select
                            id="psta-slt-datetype"
                            style={{ width: 200 }}
                            value={datePickerType}
                            onChange={(value, item) => {
                                setDatePickerType(value);
                                setDatePickerFormat(item.dataFormat ? item.dataFormat : "");
                            }}>
                            {listDatePickerTypes.map((type) => {
                                return <Option key={type.value} value={type.value} dataFormat={type.format}>{type.key}</Option>
                            })}
                        </Select>
                    </div>
                    <div className="col-auto" style={{ width: 400 }}>
                        {datePickerType !== "day" ?
                            <RangePicker
                                id="psta-dpk-datepicker"
                                className="w-100"
                                locale={viVN}
                                allowClear={false}
                                onChange={(value) => { setSelectedDate(value) }}
                                value={selectedDate}
                                format={[datePickerFormat, datePickerFormat]}
                                picker={datePickerType} />
                            : <RangePicker
                                id="psta-rpk-rangepicker"
                                className="w-100"
                                locale={viVN}
                                allowClear={false}
                                onChange={(value) => { setSelectedDateRange(value) }}
                                value={selectedDateRange}
                                format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]} />}
                    </div>
                    <div className="col-auto">
                        <Button id="psta-btn-search" className={BUTTON.THEME} onClick={() => { getData() }}><i className="fas fa-filter" /></Button>
                    </div>
                </div>
                <TagVertical data={dataSummarize} />
                <div className="row">
                    <div className="ml-auto d-flex flex-direction-row align-items-center">
                        <span>Hiển thị</span>
                        <div className="col-auto">
                            <Select
                                id="psta-slt-dpltpe"
                                style={{ width: 200 }}
                                value={isChart}
                                onChange={(value) => {
                                    setIsChart(value);
                                }}>
                                <Option key={1} value={true}><i className="fas fa-chart-bar mr-1" />Biểu đồ</Option>
                                <Option key={2} value={false}><i className="fas fa-list mr-1" />Danh sách</Option>
                            </Select>
                            {!isChart ? <Button className={`${BUTTON.THEME} ml-2`} onClick={() => { exportPatientStatistic() }}><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</Button>
                                : <Button className={`${BUTTON.THEME} ml-2`} onClick={downloadImage} style={{ marginRight: 24 }}><i style={{ marginRight: 5 }} className="far fa-file-image" />Tải ảnh biểu đồ</Button>}
                        </div>
                    </div>
                </div>
                <div id={`psta-dpl-${isChart ? "chart" : "list"}`} className="mt-2" style={{ height: isChart ? 'calc(100% - 356px)' : '60%' }}>
                    {isChart ?
                        <LineChart
                            ref={_chartRef}
                            date={selectedDate}
                            dateRange={selectedDateRange}
                            datePickerType={datePickerType}
                            site={site}
                            updateDataSummarize={(data) => setDataSummarize(data)} />
                        :
                        <ListPatient
                            ref={_listRef}
                            date={selectedDate}
                            dateRange={selectedDateRange}
                            datePickerType={datePickerType}
                            site={site}
                            updateDataSummarize={(data) => setDataSummarize(data)} />
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeSoLuongBenhNhan)