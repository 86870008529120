import { Input, notification } from "antd";
import React ,{ Fragment, useRef, useState } from "react";
import Notify,{ NotifyStatus } from "../../../components/notify";
import HeaderModal from "../../../configureHIS/common/headerModal";
import * as CardService from '../../../services/CardService';

export default (props) => {
    const _notiRef = useRef()
    const [cardId, setCardId] = useState(props.cardId)        
    const [OTPCode, setOTPCode] = useState("");
    const [resent, setResent] = useState(false);

    const onClose = () => {
        _notiRef.current && _notiRef.current.Close();
        props.onClose();
    }

    const SendOTP = () => {
        CardService.SendOTPBySmsAsync(cardId).then(result => {
        if(result.isSuccess){
            if(result.data == ""){
                _notiRef.current.Show("Gửi OTP thành công.",NotifyStatus.Success)
                setResent(true);
            } else {
                _notiRef.current.Show(result.data, NotifyStatus.Warning)
            }
        }
        }).catch(err => {
            _notiRef.current.Show(err ,NotifyStatus.Warning)
        })
    }

    const checkValidOTP = () => {
        if(OTPCode === ""){
            _notiRef.current.Show("Vui lòng nhập mã OTP.",NotifyStatus.Warning);
            return;
        }
        CardService.CheckValidOTP(OTPCode, cardId).then(result => {
        if(result.isSuccess){
            if(result.data){
                notification.success({message : "Xác nhận mã thành công."})
                onClose();
                props.onSubmit();
            } else {
                _notiRef.current.Show("OTP không hợp lệ.",NotifyStatus.Warning)
            }
        }
        }).catch(err => {
            _notiRef.current.Show(err ,NotifyStatus.Warning)
        })
    }
    return (
        <Fragment>
            <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='h3'>Xác nhận OTP</div>
                <h3 onClick={() =>{ onClose() }}>
                <i className='far fa-times-circle' /></h3>
            </div>
            <div className="row">
                <div className="col-12">
                    <Notify ref={_notiRef}/>
                </div>
                <div className="col-12">
                    <Input value={OTPCode} onChange={(e) => setOTPCode(e.target.value)}/>
                </div>
            </div>
            <div className="row py-2">
                <div className="col-12 ">
                    <div className="d-flex justify-content-between">
                    <button className="btn btn-secondary mr-1" disabled={resent} onClick={() => {SendOTP()}}>Gửi mã</button>
                        <div>
                            <button className="btn btn-color mr-1" onClick={() => {checkValidOTP()}}>Xác nhận</button>
                            <button className="btn btn-danger" onClick={() => {onClose()}}>Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
