import { DatePicker, notification, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import * as PrintService from '../../../../services/printService';
import * as ChiDinhService from '../../../../services/chiDinhService';
import CLSNoiTru from './CLSNoiTru';
import PatientInfo from './patientinfo';
import * as SystemConfig from '../../../../configure/systemConfig';
const { RangePicker } = DatePicker;

class VLTL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            lstVLTL: [],
            listType: [],
            ListTypeDistinct: [],
            ngaySearch: [moment(this.props.patientDetail.dateInHospital), moment()],
            keySearch: '',
            heightListBtn: 47,
        };
    }
    componentDidMount() {
        this.ThongTinDongYBenhNhanTheoBenhTrinh();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail, ngaySearch: [moment(nextProps.patientDetail.dateInHospital), moment()] }, () => {
                this.ThongTinDongYBenhNhanTheoBenhTrinh();
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }

    ThongTinDongYBenhNhanTheoBenhTrinh() {
        if (this.state.detail.enterCode === undefined)
            return;
        InHospitalService.ThongTinDongYBenhNhanTheoBenhTrinh(this.state.detail.enterCode, this.state.detail.code,
            this.state.ngaySearch[0].format('YYYY/MM/DD'),
            this.state.ngaySearch[1].format('YYYY/MM/DD'), this.props.thuthuat === true, this.state.keySearch, this.state.isOld).then(result => {
                if (result.isSuccess) {
                    let listType = result.data.map(x => moment(x.ngay).format('DD/MM/YYYY'));
                    let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    this.setState({
                        lstVLTL: result.data,
                        listType: ListTypeDistinct,
                        lstBlob: [],
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", "Tải dữ liệu chẩn đoán hình ảnh bệnh nhân nội trú bị lỗi")
            })
    }

    PrintResultTest(data, all = true) {
        PrintService.PrintPASCBenhTrinh(this.state.detail.code, this.state.detail.enterCode, this.state.detail.managerId,
            moment(data.ngay).format('YYYY/MM/DD'), this.state.ngaySearch[0].format('YYYY/MM/DD'), this.state.ngaySearch[1].format('YYYY/MM/DD'),
            all, this.state.keySearch, this.state.isOld).then((result) => {
                Actions.DoPrint(result.item)
            })
    }

    XoaChiDinh(id, ngay) {
        if (id !== 0) {
            ChiDinhService.XoaChiDinh(id, ngay).then(result => {
                if (result.isSuccess) {
                    this.ThongTinDongYBenhNhanTheoBenhTrinh();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="row align-items-center" style={{ padding: '10px', paddingTop: 0 }}>
                            <div className="w-100 col-12 row no-gutters" style={{ backgroundColor: '#fff', paddingTop: 10 }}>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '115px' }} className="btn btn-color fontSize12" onClick={() => { this.setState({ visibleEditCLS: true }) }}
                                        data-toggle="collapse" data-target="#themchidinhxetnghiem"><i className="fa fa-plus"></i> Thêm chỉ định</button>
                                </div>
                                {/* <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '105px' }} className="btn btn-outline-secondary fontSize12"
                                        onClick={() => { this.PrintResultTest({ ngaySearch: moment() }) }}><i className="fa fa-print"></i> In tất cả KQ</button>
                                </div> */}
                                <div className="col-auto mb-2 mr-2" style={{ width: '225px' }}>
                                    <RangePicker className="fontSize12" format="DD-MM-YYYY" value={this.state.ngaySearch} allowClear={false}
                                        locale={viVN} onChange={(value => this.setState({ ngaySearch: value }, () => { this.ThongTinDongYBenhNhanTheoBenhTrinh() }))}
                                    />
                                </div>
                                <div className="col-auto mb-2 mr-2 align-self-center">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" value={this.state.isOld}
                                                onChange={(e) => { this.setState({ isOld: e.target.checked }, () => { this.ThongTinDongYBenhNhanTheoBenhTrinh() }) }} />Hiện bệnh án cũ
                                        </label>
                                    </div>

                                </div>
                                <div className="col-auto mb-2">
                                    <div className="input-group fontSize12">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-color" type="button" onClick={() => { this.ThongTinDongYBenhNhanTheoBenhTrinh(); }}><i className="fa fa-search" /></button>
                                        </div>
                                        <input value={this.state.keySearch} type="text" className="form-control" placeholder="Nhập tên dịch vụ"
                                            onChange={(e) => this.setState({ keySearch: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.ThongTinDongYBenhNhanTheoBenhTrinh();
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.visibleEditCLS &&
                        <div className="pt-2 collapse" id='themchidinhxetnghiem' style={{ marginTop: '10px', backgroundColor: "#fff" }}>
                            <CLSNoiTru
                                ref={(c) => this.modalCLS = c}
                                patient={this.state.detail}
                                noitru={true}
                                ngay={moment()}
                                showType={true}
                                type={this.props.thuthuat === true ? 1 : 5}
                                idbenhtrinh={this.state.idbenhtrinh}
                                onClose={() => {
                                    this.ThongTinDongYBenhNhanTheoBenhTrinh();
                                    this.setState({ visibleEditCLS: false })
                                }}
                            />
                        </div>
                    }
                    {this.state.listType.map((ngay, index) => {
                        let tmp = this.state.lstVLTL.filter(x => moment(x.ngay).format('DD/MM/YYYY') === ngay)[0];
                        let dataTMP = this.state.lstVLTL.filter(x => moment(x.ngay).format('DD/MM/YYYY') === ngay);
                        return (
                            <div className="w-100">
                                <div className="w-100" style={{ height: '1px', backgroundColor: '#F2F5FA' }}></div>
                                <div className="p-md-0">
                                    <div style={{ padding: '10px', backgroundColor: '#fff' }}>
                                        <div className="w-100" key={index}>
                                            <div data-toggle="collapse" data-target={`#BTXN${index}`} className="mb-0" style={{ cursor: 'pointer' }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <div style={{ marginRight: '30px' }}>{moment(tmp.ngay).format('DD/MM/YYYY')}</div>
                                                        <div>BS điều trị: {tmp.bscd}</div>
                                                    </div>
                                                    {/* <div className="ml-auto" style={{ cursor: 'pointer', fontSize: '13px' }} onClick={(e) => { e.stopPropagation(); this.PrintResultTest(tmp, false) }}>
                                                        <i className="fa fa-print" style={{ marginRight: '5px' }}></i>In kết quả
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div id={`BTXN${index}`} className={`collapse ${index === 0 ? 'show' : ''}`}>
                                                {dataTMP.map((row, indexChild) => (
                                                    <React.Fragment>
                                                        <div className="row mb-2">
                                                            <div className="col-md-4 font-weight-bold mb-2 d-flex">{indexChild + 1}. {row.tenvp}
                                                                <div className="ml-2">
                                                                    <Tooltip align="top" title="Xóa">
                                                                        <div className="text-danger"
                                                                            onClick={() => { this.XoaChiDinh(row.idChiDinh, moment(row.ngay).format("YYYY/MM/DD")) }}
                                                                            style={{ cursor: "pointer" }}><i className="fa fa-trash"></i>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                            {row.idpttt !== "" ?
                                                                <React.Fragment>
                                                                    <div className="col-md-4 mb-2">Ngày thực hiện: {moment(row.ngaykt).format(SystemConfig.FORMAT_DATE_TIME)}</div>
                                                                    <div className="col-md-4 mb-2">Người thực hiện: {row.tenbs}</div>
                                                                    <div className="col-12 mb-2">
                                                                        Ghi chú:  {row.ketluan}
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <div className="col-md-8 mb-2">Chưa thực hiện</div>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </React.Fragment>
        );
    }
}

export default VLTL;