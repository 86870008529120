import React, { useRef, Fragment, useState, useEffect,useCallback } from "react";
import { List, Drawer, Button, Tooltip, Checkbox, Popconfirm, Select, notification } from 'antd';
import moment from "moment";
import * as Actions from '../../../../libs/actions';
import Notify, { NotifyStatus } from '../../../../components/notify';
import SelectProduct from '../../../../components/MDCLS/selectProduct';
import SelectProductAutocomplete from '../../../../components/MDCLS/selectProductAutocomplete';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as PackageService from "../../../../services/packageService";
import * as PrintService from '../../../../services/printService';
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import { groupBy, isNullOrEmpty, renumber, uppercaseFirstLetter } from "../../../../libs/util";
import Handlebars from "handlebars";
import {default as num2words} from 'vn-num2words';
import handlebarHelper from "../../../../libs/handlebarHelper";

/** Hướng dẫn sử dụng component
 ** Màn hình đăng ký chỉ định
 ** Các component đang sử dụng:
    - src\screens\exam\examination\detail-components\clinical-exam.js
    - src\screens\register\register-products\registerproduct.js
 ** Các props nhận vào:
 * @param {string} props.applyLoyalty: boolean. 
 * @param {string} props.data: [require] object. 
 * properties của props.data
 * {
     patientCode: [require] string. Mã bệnh nhân
     priceListName: [require] string. Tên bảng giá
     managermentId: string. Mã quản lý
     registedDate: datetime. Ngày đăng ký.
     examinationId: string. id chỉ định, từ màn hình trước. Dành cho module "Khám bệnh".
     serviceName: string. Tên dịch vụ từ màn hình trước. Dành cho module "Khám bệnh".
     serviceCode: number. id viện phí, từ màn hình trước. Dành cho module "Khám bệnh".
 * }
*/
export default (props) => {
    const hiddenLink = useRef();
    const notiRef = useRef();
    const selectProductRef = useRef();
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [listResult, setListResult] = useState([]); // list chidinh để view
    const [listResultLoai, setListResultLoai] = useState([]); // list loại

    const [listResultRoot, setListResultRoot] = useState([]); // list chidinh full. Cái listResult trên là lọc lại để hiển thị.
    const [listResultOfCurrentUser, setListResultOfCurrentUser] = useState([]); // list chidinh của current user và tạo trong dịch vụ khám bệnh hiện tại.

    const [totalAmount, setTotalAmount] = useState(0);//tổng tiền chưa thanh toán
    const [totalAmountPaid, setTotalAmountPaid] = useState(0); //tổng tiền đã thanh toán
    const [totalRecord, setTotalRecord] = useState(0);
    const [selectedListServices, setSelectedListServices] = useState([]);
    const [data, setData] = useState(props.data);
    const [checkPrintAll, setCheckPrintAll] = useState(false);
    const [indeterminateCheckPrintAll, setIndeterminateCheckPrintAll] = useState(false);
    const [countResultOfCurrentUser, setCountResultOfCurrentUser] = useState(0);
    const [checkViewIndicationAll, setCheckViewIndicationAll] = useState(false);
    const [idChiDinhs, setIdChiDinhs] = useState([]); // idChiDinhs này dùng cho mục đích tick chọn để in chỉ định. Không dùng cho mục đích khác nhé.
    const [visibleSelectProduct, setVisibleSelectProduct] = useState(false);
    const [keywordProducts, setKeywordProducts] = useState('');
    const [currentUserId, setCurrentUserId] = useState(localStorage.getItem('id'));
    
    useEffect(() => {
        setData(props.data);
        search();
    },[props.data])

    useEffect(() => {
        setIndeterminateCheckPrintAll(idChiDinhs.length > 0 && idChiDinhs.length < countResultOfCurrentUser);
        if (!firstRender.current) setCheckPrintAll(idChiDinhs && idChiDinhs.length > 0 && idChiDinhs.length == countResultOfCurrentUser);
    }, [idChiDinhs, countResultOfCurrentUser]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, []);

    const search = (chkViewIndicationAll = checkViewIndicationAll) => {
        Actions.setLoading(true);
        let filterModel = {
          //common: paging & sorting
          skipCount: 0,
          maxResultCount: 0,
        //   sorting: sorting,
          //riêng theo module
          keyword: '',
          maQL: data.managermentId,
          orderId: '',
          sid: ''
        }
        ChiDinhService.getList(filterModel).then(result => {
          if (result.isSuccess) {
            setTotalRecord(result.data.totalCount);
            setListResultRoot(result.data.items);
            let listResultOfCurrentUser = result.data.items.filter(x => x.userCreate == currentUserId && x.parentId == data.examinationId);
            setListResultOfCurrentUser(listResultOfCurrentUser);
            let count = listResultOfCurrentUser.reduce((accumulator, currentItem)  => accumulator + (currentItem.listChild ?? []).length, listResultOfCurrentUser.length);
            setCountResultOfCurrentUser(count);
            setIndicationView(chkViewIndicationAll, result.data.items);
            setSelectedListServices(convertProductToSelectComponent(result.data.items));
            notiRef.current && notiRef.current.Close();
          }
          else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
          }
        }).catch(err => {
          if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
          else notiRef.current.Show(err, NotifyStatus.Warning);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }
    
    function xoaChiDinhByVienPhiId(vienPhiId) {
        let row = listResult.find(x=>x.vienPhiId == vienPhiId);
        Actions.setLoading(true);
        return new Promise((resolve, reject) => {
            ChiDinhService.XoaChiDinh(row.idChiDinh, row.chiNhanh).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: "Xóa thành công" })
                    search();
                    return resolve(true);
                } else {
                    if (result.err)
                        return reject(result.err.msgString);
                    else if (result.error) {
                        return reject(result.error.messageText);
                    }
                    else return reject("Lỗi server. Liên hệ IT check Exception")
                }
            }).catch(err => {
                return reject("Lỗi server. Liên hệ IT check Exception");
            }).finally(() => {Actions.setLoading(false);})
        });


        // if (row) return xoaChiDinh(row);
        // return false;
    }

    function xoaChiDinh(row) {
        Actions.setLoading(true);
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chiNhanh).then(result => {
            Actions.setLoading(false);
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                search();
                return true;
                // this.GetListOrderById(this.state.appointmentid, this.state.selectedPatient.chinhanh)
            } else {
                notiRef.current.Show(result.err.msgString, NotifyStatus.Warning);
                return false;
            }
        }).catch(err => {
            notiRef.current.Show(err, NotifyStatus.Warning);
            Actions.setLoading(false);
            return false;
        })
    }

    const convertProductToSelectComponent = (arrProduct) => {
        return arrProduct?.map(x => {
            let tenKhoaPhong = ((x.nhomId == 8 && (x.tenkp || x.tenPhongKham)) ? `${x.tenkp } - ${x.tenPhongKham }` : '');
            // let tenKhoaPhong = `${x.tenkp } - ${x.tenPhongKham }`;
            let listChild = [];
            if (x.listChild && x.listChild.length > 0) {
                listChild = convertProductToSelectComponent(x.listChild);
            }
            return {
                id: x.vienPhiId,
                ma: x.vienPhiMa,
                ten: x.vienPhiTen,
                allowDelete: x.allowDelete,
                idChiDinh: x.idChiDinh,
                uiStatus: 'selected',
                uiDeleted: false,
                gia_Th: x.donGiaGoc,
                donGia: x.donGia,
                tenKhoaPhong: tenKhoaPhong,
                statusText: x.status_Text,
                paid: x.paid,
                listChild: listChild,
            }
        })
    }
    const showSelectProductComponent = () => {
        setVisibleSelectProduct(true); 
        setSelectedListServices(convertProductToSelectComponent(listResultRoot));
    }
    const onConfirmSelectProductHandle = (arrayResult = []) => {
        let newChiDinh = arrayResult.filter(x=>x.uiStatus == 'new');
        let listChiDinh = [];
        let newVienPhiIds = [];
        newChiDinh.forEach(x => {
            newVienPhiIds.push(x.id);
            listChiDinh.push(convertToModelSaveNew(x));
            if (x.isCombo)
                x.listChild.forEach(xChild => {
                    newVienPhiIds.push(xChild.id);
                    let itemChild = convertToModelSaveNew(xChild);
                    itemChild.vienPhiIdOfCombo = x.id;
                    listChiDinh.push(itemChild);
                });
        });
        return saveNewManyProduct(listChiDinh);
    }

    //áp dụng cho component SelectProductAutocomplete
    const onSelectIndications = (products = []) => {
        let listChiDinh = [];
        products.filter(x => x.isInBangGia).forEach(item => {
            listChiDinh.push(convertToModelSaveNew(item));
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = convertToModelSaveNew(xChild);
                    itemChild.vienPhiIdOfCombo = item.id;
                    listChiDinh.push(itemChild);
                });
        })
        saveNewManyProduct(listChiDinh);
    }
    
    const saveNewManyProduct = (listChiDinh = []) => {
        if (!listChiDinh || listChiDinh.length ===0) return;
        let model = {
            maQL: data.managermentId,
            listIndicationExams: listChiDinh,
        }
        ChiDinhService.createMany(model).then(result => {
            if (result.isSuccess) {
                setVisibleSelectProduct(false);
                if (listChiDinh.length > 1)
                    notification.success({ message: "Lưu thành công" });
                else notification.success({ message: `Lưu thành công` });
                search(checkViewIndicationAll);
                if (result.data)
                    setIdChiDinhs([...idChiDinhs,...result.data]);
                notiRef.current && notiRef.current.Close();
            }
            else {
                // notification.error({ message: result.error.messageText });
                notification.error({ message: "Lưu không thành công" });
                notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                selectProductRef.current && selectProductRef.current.showNotiRef(result.error.messageText);
            }
            Actions.setLoading(false);
        }).catch(err => {
            if (err.stack) {
                notification.error({ message: "Lỗi javascript sau khi lưu" });
                return;
            }
            notification.error({ message: "Lưu không thành công" });
            notiRef.current.Show(err, NotifyStatus.Warning);
            selectProductRef.current && selectProductRef.current.showNotiRef(err);
            Actions.setLoading(false);
        })
    }
    
    const onChangeCheckPrintAll = (e) => {
        setCheckPrintAll(e.target.checked);
        if (e.target.checked) {
            let idCDs = listResultOfCurrentUser.map(x => x.idChiDinh);
            listResultOfCurrentUser.forEach(x=>{
                idCDs = [...idCDs,  ...(x.listChild ?? []).map(c => c.idChiDinh)];
            })
            setIdChiDinhs(idCDs);
        }
        else { setIdChiDinhs([]); }
    }

    function setIndicationView(isViewAll = false, listFull = []) {
        if (listFull && listFull.length > 0) {
            let lsit = isViewAll ? listFull : listFull.filter(x => x.userCreate == currentUserId && x.parentId == data.examinationId);

            let listLoai=[];
            let amount = 0;
            let amountPaid = 0;
            lsit.forEach(x => {
                if (x.paid == 1) amountPaid += x.donGia;
                else amount += x.donGia;
                let itemLoai = listLoai.find(c=>c.loaiId == x.loaiId);
                if (!itemLoai) {
                    itemLoai={ loaiId: x.loaiId, loaiTen: x.loaiTen, listChiDinh: [] };
                    listLoai.push(itemLoai);
                }
            });
            setTotalAmount(amount);
            setTotalAmountPaid(amountPaid);
            setListResultLoai(listLoai);
            setListResult(lsit);
        }
    }

    function onChangeCheckViewIndicationAll(e) {
        setCheckViewIndicationAll(e.target.checked);
        search(e.target.checked);
    }

    //áp dụng cho component SelectProductAutocomplete và SelectProduct. Nhưng cho api lưu chỉ định mới
    const convertToModelSaveNew = (x) => {
        return {
            bangGia: data.priceListName,
            donGia: x.donGia,
            donGiaGoc: x.gia_Th, // chỗ này lưu ý ở Đăng ký. donGiaGoc là giá dịch vụ, donGia là sau khi trừ khuyến mại nhé.
            maKp: x.maKp, // hiện tại chưa có, sau này có thể cho chọn kho phòng khi tạo chỉ định
            maBs: currentUserId,
            PhongKham: x.phongKham, // hiện tại chưa có, sau này có thể cho chọn kho phòng khi tạo chỉ định
            parentId: data.examinationId,
            vienPhiId: x.id,
            vienPhiMa: x.ma,
            vienPhiTen: x.ten,
            nhomId: x.nhomId,
            ngay: moment().format('YYYY/MM/DD HH:mm'),
        };
    }

    const PrintIsPaid = () => {
        if (idChiDinhs.length > 0)
            PrintService.printIndication(data.patientCode, data.managermentId, moment(data.registedDate).format('YYYY-MM-DD'), idChiDinhs.join(","), data.serviceCode)
                .then((result) => {
                    if (isNullOrEmpty(result.item.template)) {
                        Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                        return;
                    } else {
                        let templateMaster = "";
                        var finalData = result.item.data;
                        const listPacs = finalData.listPacs; // Danh sách chỉ định CĐHA (Chưa phân loại)
                        const listSurgery = finalData.listSurgery; // Danh sách chỉ định thủ thuật (Chưa phân loại)
                        const listTest = finalData.listTest; // Danh sách chỉ định thủ thuật (Chưa phân theo orderId)
                        const listOrderId = result.item.listOrderId; // Danh sách orderId
                        if (listPacs.listIndication) {
                            var listPacsGroup = groupBy(listPacs.listIndication, "tenloai"); // Danh sách chỉ định CĐHA đã phân theo loại
                            Object.keys(listPacsGroup).forEach((groupName, index) => {
                                let listPacsBelongToGroup = listPacsGroup[groupName];
                                let totalMoney = listPacsBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: listPacsBelongToGroup,
                                    title: `${listPacs.title?.toUpperCase()} ${groupName.toUpperCase()}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `pacs${index}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplateSecond.replaceAll("data.listService", `data.${keyName}`); // Thêm template chỉ định CĐHA theo loại
                            });
                        }
                        if (listSurgery.listIndication) {
                            var listSurgeryGroup = groupBy(listSurgery.listIndication, "tenloai"); // Danh sách chỉ định thủ thuật đã phân theo loại
                            Object.keys(listSurgeryGroup).forEach((groupName, index) => {
                                let listSurgeryBelongToGroup = listSurgeryGroup[groupName];
                                let totalMoney = listSurgeryBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: listSurgeryBelongToGroup,
                                    title: `${listSurgery.title?.toUpperCase()} ${groupName.toUpperCase()}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `surgery${index}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplateSecond.replaceAll("data.listService", `data.${keyName}`); // Thêm template chỉ định thủ thuật theo loại
                            });
                        }
                        if (listTest.listIndication) {
                            var listTestGroupByOrderId = groupBy(finalData.listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo loại
                            Object.keys(listTestGroupByOrderId).forEach(orderId => {
                                let listTestBelongToGroup = listTestGroupByOrderId[orderId];
                                const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
                                const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
                                const resultGroupArrRenumber = renumber(resultGroupArr);
                                let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
                                let dataInfo = {
                                    listIndication: resultGroupArrRenumber,
                                    sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
                                    title: `${finalData.listTest.title?.toUpperCase() ?? "PHIẾU CHỈ ĐỊNH XÉT NGHIỆM"}`,
                                    amount: totalMoney,
                                    amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
                                };
                                let keyName = `test${orderId}`;
                                var tempData = {...finalData, [`${keyName}`]: dataInfo};
                                finalData = tempData;
                                templateMaster += result.item.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
                            });
                        }
                        const template = Handlebars.compile(result.item.template.replace("#listMasterData", templateMaster));
                        result.item.data = finalData;
                        Handlebars.registerHelper(handlebarHelper);
                        Actions.DoPrint(template(result.item));
                    }
                })
        else notification.warning({ message: "Vui lòng chọn chỉ định để in" })
    }

    const renderDv = (listDv) => {
      if (!listDv || listDv.length == 0) return "";
      let className = "p-1 border-2 border-bottom py-2 d-flex flex-column ant-list-item-hover";
      return (
        listDv.map((dvCha, index) => {
            let tenKhoaPhong = ((dvCha.nhomId == 8 && (dvCha.tenkp || dvCha.tenPhongKham)) ? `Khoa - phòng: ${dvCha.tenkp } - ${dvCha.tenPhongKham }` : '');
            let enableCheckPrint = (dvCha.userCreate == currentUserId && dvCha.parentId == data.examinationId);
            return (
                <List.Item key={"dvCha-"+dvCha.idChiDinh.toString()}
                    className={className}
                >
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between w-100">
                            <div className="d-flex" style={{width: 'calc(70% - 106px)'}}>
                                <Tooltip title={enableCheckPrint ? '' : `Chỉ định này không phải do bạn thêm trong dịch vụ [${data.serviceName}]. Nên bạn không thể chọn để in.`}>
                                    <Checkbox className="mr-2"
                                        disabled={!enableCheckPrint}
                                        checked={idChiDinhs.indexOf(dvCha.idChiDinh) > -1}
                                        onChange={(e) => {
                                            let idChilds = (dvCha.listChild ?? []).map(x=>x.idChiDinh);
                                            if (e.target.checked) setIdChiDinhs([...idChiDinhs, dvCha.idChiDinh, ...idChilds]);
                                            else setIdChiDinhs(idChiDinhs.filter(x => x != dvCha.idChiDinh && idChilds.indexOf(x)<0));
                                        }}
                                        // onClick={(e) => {e.stopPropagation();}}
                                    ></Checkbox>
                                </Tooltip>
                                <label className="mita-title h-100">
                                    <Tooltip title={`Người đăng ký: ${dvCha.userCreateName}`}>
                                        <label><i className={`fa fa-user-md mr-1 ${(dvCha.userCreate == currentUserId && dvCha.parentId == data.examinationId) ? "text-color" : ""}`} /></label>
                                    </Tooltip>
                                    {dvCha.vienPhiTen} - {dvCha.vienPhiMa}
                                </label>
                            </div>
                            <div style={{width: '30%'}}>
                                <label className="mita-value">{dvCha.status_Text}</label>
                            </div>
                            <div className="text-right" style={{width: 106}}>
                                {dvCha.paid == 1 ?
                                    // <Tooltip title="Đã thanh toán">
                                    //     <label className="mita-value"><i class="fas fa-dollar-sign"></i></label>
                                    // </Tooltip>
                                <label className="mita-value">Đã thanh toán</label>
                                :
                                <label className="mita-value">{dvCha.donGia.toLocaleString('en-US')}</label>
                                }
                                <Popconfirm
                                    title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{dvCha.vienPhiTen}</b>?</span>)}}
                                    onConfirm={() => xoaChiDinh(dvCha)}
                                    onCancel={() => {}}
                                    okText="Đồng ý"
                                    cancelText="Hủy"
                                >
                                    <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0, width: 16 }}
                                        hidden={!(dvCha.allowDelete ?? true)}
                                        // onClick={() => {xoaChiDinh(dvCha);}}
                                    ><i className="fa fa-trash-alt text-danger"></i></Button>
                                </Popconfirm>
                            </div>
                        </div>
                        <div className="d-flex justify-content-stretch">
                            {(tenKhoaPhong && tenKhoaPhong.length > 0) &&
                            <div className="" style={{width: '55%', paddingLeft:20}}>
                                <label className="mita-value">{tenKhoaPhong}</label>
                            </div>
                            }
                        </div>
                        {(dvCha.listChild && dvCha.listChild.length > 0) &&
                        <div className="d-flex flex-column justify-content-stretch">
                            {dvCha.listChild.map((dvConCombo, index) => {
                                let tenKhoaPhongChild = ((dvConCombo.nhomId == 8 && (dvConCombo.tenkp || dvConCombo.tenPhongKham)) ? `Khoa - phòng: ${dvConCombo.tenkp } - ${dvConCombo.tenPhongKham }` : '');
                                return (<>
                                    <div className="w-100 d-flex justify-content-between">
                                        <div className="d-flex" style={{width: 'calc(70% - 106px)'}}>
                                            <label className="mita-value h-auto">{dvConCombo.vienPhiTen} - {dvConCombo.vienPhiMa}</label>
                                        </div>
                                        <div style={{width: '30%'}}>
                                            <label className="mita-value">{dvConCombo.status_Text}</label>
                                        </div>
                                        <div className="text-right" style={{width: 106}}>
                                        </div>
                                    </div>
                                    {(tenKhoaPhongChild && tenKhoaPhongChild.length > 0) &&
                                    <div className="pl-3" style={{width: '55%'}}>
                                        <label className="mita-value h-auto">{tenKhoaPhongChild}</label>
                                    </div>
                                    }
                                </>
                                )
                            })}
                        </div>
                        }
                    </div>
                </List.Item>
            )
        })
      )
    }

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
            <div className="w-100 h-100 d-flex flex-column">
                <div className="row">{/* notification */}
                    <div className="col-md-12"><Notify ref={notiRef} /></div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-flex flex-column">
                        {/* <label className="mita-title">Chọn nhanh dịch vụ / gói dịch vụ</label> */}
                        <div className="d-flex justify-content-between">
                            <div className="w-100">
                                <SelectProductAutocomplete 
                                    showBangGia={true}
                                    bangGia={data.priceListName}
                                    selectedListServices={[
                                    ...(selectedListServices ?? []),
                                    ]}
                                    width='100%'
                                    tablewidth={500}
                                    listHeight={"50vh"}
                                    onSelect={(items) => onSelectIndications(items)}
                                    value={keywordProducts}
                                    // headers={columnsSearhProducts}
                                    keyValue={'id'}
                                />
                            </div>
                            <Button className="pl-0 ml-1" type="link" 
                                onClick={() => {showSelectProductComponent();}}
                            ><i className='fa fa-file-alt mr-2'/>Chỉ định</Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            <div className="pl-1">
                                <Checkbox  className="mr-2"
                                    checked={checkPrintAll}
                                    indeterminate={indeterminateCheckPrintAll}
                                    onChange={(e) => {
                                        onChangeCheckPrintAll(e);
                                    }}
                                ></Checkbox>
                                <Button className="pl-0" type="link" 
                                    onClick={() => {PrintIsPaid();}}
                                ><i className='fa fa-print mr-2'/>In chỉ định</Button>
                            </div>
                            <div className="pl-1">
                                <Checkbox  className="mr-2 m-auto" style={{verticalAlign: 'middle'}}
                                    checked={checkViewIndicationAll}
                                    // indeterminate={indeterminateCheckPrintAll}
                                    onChange={(e) => {
                                        onChangeCheckViewIndicationAll(e);
                                    }}
                                ><span className="pl-0" >Tất cả chỉ định</span></Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-page-table overflow-auto">{/* list */}
                    <List className="none-border-bottom none-border-bottom-item"
                        bordered={false}
                        dataSource={listResultLoai}
                        renderItem={(record) => {
                            let className = "p-1 border-2 border-top";
                            return (
                                <>
                                    <List.Item key={'loai-'+record.loaiId.toString()}
                                        className={className}
                                        style={{backgroundColor:"#D8D8D8"}}
                                    >
                                        <div key={"loaidv"} className="d-flex justify-content-between w-100">{/* 1 item */}
                                        {record.loaiTen}
                                        </div>
                                    </List.Item>
                                    {renderDv(listResult.filter(x => x.loaiId == record.loaiId))}
                                </>
                            )
                        }}
                    >
                        <List.Item key={"total-amount"} className='p-1'>
                            <div className="d-flex flex-column w-100">
                                <div className="d-flex justify-content-end">
                                    <div className=""><label className="mita-value">Chưa thanh toán</label></div>
                                    <div className="text-right" style={{ width: '15%' }}>
                                        <label className="mita-title"><u>đ</u> {totalAmount.toLocaleString('en-US')}</label>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    </List>
                </div>
                {/* footer (nếu có) */}
                {/* <div className="d-flex justify-content-between mt-2">
                    <div className='d-flex justify-content-end' >
                        <button 
                            className='btn btn-color mr-2' 
                            // onClick={print}
                        >
                            <i className='fas fa-print mr-2'></i>
                            In
                        </button>
                        <button
                            className='btn btn-danger'
                            // onClick={() => props.onCancel()}
                        >
                            <i className='fa fa-sign-out-alt mr-2'></i>
                            Đóng
                        </button>
                    </div>
                </div> */}
            </div>
            <Drawer className='custom-ant-drawer'
                maskClosable={false}
                mask={true}
                closable={false}
                width={"80%"}
                placement="right"
                visible={visibleSelectProduct}
                destroyOnClose={true}
            >
                <SelectProduct ref={selectProductRef}
                    isDialog={ true } 
                    // selectedListServices={selectedListServices}
                    selectedListServices={[
                    ...(selectedListServices ?? []),
                    // ...[
                    //   {id: 867, ten: 'Khám cấp cứu', ma: 'KCC01', allowDelete: false},
                    //   {id: 1516, ten: 'MIỄN DỊCH TEST NHANH', ma: '2-1516', allowDelete: false},
                    // ]
                    ]}
                    onCancel={() => {setVisibleSelectProduct(false)}}
                    onConfirm={(arrayResult) => { onConfirmSelectProductHandle(arrayResult); }}
                    onDeleteItem={(record) => { // record này là theo model product của component selectProduct.
                        return xoaChiDinhByVienPhiId(record.id);
                    }}
                    btnConfirmText="Lưu"
                    bangGia={data.priceListName}
                    showBangGia={true}
                    disableBangGia={true}
                />
            </Drawer>
        </Fragment>
    )
};
