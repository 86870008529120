import * as ApiCaller from '../../libs/httpRequests';
const prefix='Collaborator';

export function GetListCollaborators(key, page, size, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListCollaborators?key=${key}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListCollaboratorsOfGroup(groupname, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListCollaboratorsOfGroup?groupname=${groupname}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListGroup(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListGroup?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateListCollaboratorsOfGroup(groupname, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateCollaboratorsOfGroup?groupname=${groupname}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteGroup(groupname) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/DeleteGroup?groupname=${groupname}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};