import { Alert } from 'antd';
import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

const ConsultantWarning = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const [visible, setVisible] = useState(props.visible ?? false);

    const notifyUpdate = () => {

    }

    useEffect(() => {
        setVisible(props.visible ?? false);
    }, [props.visible]);

    return (
        visible && <Alert className="my-2" type="warning" message="Có dịch vụ chưa chọn nhân viên tư vấn. Bạn có muốn chọn nhân viên tư vấn?" />
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ConsultantWarning)