import { DatePicker, notification, Table, Pagination, Select, Tabs,Tooltip } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import * as Actions from '../../../libs/actions';
import * as PrintService from '../../../services/printService';
import * as StatisticService from '../../../services/statisticService';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import * as ReportService from '../../../services/ReportService';
import ReportByBill from './report/reportbybill';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { PAYMENTTYPE } from '../../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import { FORMAT_DATE_TIME, FORMAT_PREFIX_EXCEL } from '../../../configure/systemConfig';
import { isNullOrEmpty } from '../../../libs/util';

const { RangePicker } = DatePicker;
const { Option } = Select;
const ProductType = {
    Service: 1,
    Medicine: 2
}
export default class baocaotonghop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(moment().format('YYYY-MM-DD') + " 00:00"), moment()],
            dataSource: [],
            tongthucthu: 0,
            tongdoanhthu: 0,
            tongbn: 0,
            hinhthucthanhtoan: 0,
            tienmat: 0,
            chuyenkhoan: 0,
            quetthe: 0,
            congno: 0,
            tongBHYT: 0,
            tongtienBD: 0,
            tonggiamtru: 0,
            tongGiamDichVu: 0,
            tongGiamBill: 0,
            refundCount: 0,
            refundAmount: 0,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
            seletedItem: 0,
            tongtien: 0,
            listTT: [],
            lstLoaiTT: [],
            loadingTable: false,
            expandedRowKeys:[],
            pageIndex:1,
            pageSize:10
        };
    }

    componentDidMount() {
        this.setState({ chinhanh: this.props.chinhanh })
        this.GetListSite();
        this.getPaymentMethod();
        this.LoadThongKeDoanhSo();
        this.LoadGeneral();
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pageIndex !== this.state.pageIndex || prevState.pageSize !== this.state.pageSize) {
            this.LoadThongKeDoanhSo();
        }
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }

    LoadGeneral() {
        let filterModel = {
            dateFrom: this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
            dateTo: this.state.ngay[1].format('YYYY-MM-DD HH:mm'),
            siteIds : [Number(this.props.chinhanh)],
            billType : this.props.data.filter === '' ? 0 : Number(this.props.data.filter),
        }
        ReportService.GetRevenueByPaymentType(filterModel).then(result => {
            if (result.isSuccess) {
                let tongthucthu = 0;
                result.data.map(x => { tongthucthu += x.tongTien });
                let listpayment = result.data.filter(x => x.tongTien !== 0);
                this.setState({
                    tongdoanhthu: tongthucthu,
                    listTT: listpayment
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
        // StatisticService.GetGeneralRevenueByService(this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
        //     this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.props.data.filter).then(result => {
        //         if (result.isSuccess) {
        //             let tongthucthu = 0;
        //             result.item.lstRevenues.map(x => { tongthucthu += x.totalMoney });
        //             this.setState({
        //                 tongdoanhthu: tongthucthu,
        //                 listTT: result.item.lstRevenues
        //             })
        //         }
        //     }).catch(err => {
        //         Actions.setLoading(false);
        //         notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        //     })
    }

    LoadThongKeDoanhSo(paymentType = this.state.hinhthucthanhtoan.toString(), loading = true) {
        Actions.setLoading(loading);
        this.setState({loadingTable: true});
        const fromPayment = true;
        paymentType = Number(paymentType ?? 0);
        if (paymentType == 0) paymentType = null;
        let filterModel = {
            //common: paging & sorting
            skipCount:(this.state.pageIndex - 1) * this.state.pageSize,
            maxResultCount: this.state.pageSize,
            // sorting: sorting,
            //riêng theo module
            dateFrom: this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
            dateTo: this.state.ngay[1].format('YYYY-MM-DD HH:mm'),
            paymentTypeId: paymentType,
            siteIds : [Number(this.props.chinhanh)],
            isGetBillRefund: true,
            // fromPayment: fromPayment
            billType : this.props.data.filter === '' ? 0 : Number(this.props.data.filter),
        }
        ReportService.ThongKeTheoDichVuBenhNhanImprove(filterModel).then(result => {
            if (result.isSuccess) {
                let tongbn = result.data.totalCount;
                let tongtien = Math.round(result.data.extendObject.totalAmount,2);
                let tongGiamDichVu = Math.round(result.data.extendObject.discountServiceValue,2);
                let tongGiamBill = Math.round(result.data.extendObject.discountBillValue,2);
                let tonggiamtru = Math.round(tongGiamDichVu + tongGiamBill,2);
                let refundAmount = Math.round(result.data.extendObject.refundAmount,2);
                let refundCount = result.data.extendObject.refundCount;
                if (result.data.items.find(x => x.paymentTypeText?.includes(", ")) != undefined)
                    this.notifyRef.Show("Có sự tồn tại nhiều hình thức thanh toán", NotifyStatus.Warning);
                else this.notifyRef.Close();
                let expandedRowKeys = result.data.items.map(x=>`${x.soBienLai.toString()}${(x.vienPhiId ?? 0).toString()}`);
                {/* RefundCount = refundCount,
                TotalAmount = totalAmount, 
                DiscountValue = discountValue,
                RefundAmount = refundAmount, */}
                this.setState({
                    dataSource: result.data.items,
                    expandedRowKeys: expandedRowKeys,
                    tongbn: tongbn,
                    tonggiamtru, tongGiamDichVu, tongGiamBill,
                    tongtien,
                    tongtienBD: tongtien,
                    refundAmount,
                    refundCount
                });
            }
            else {
                this.notifyRef.Show(result.error.messageText , NotifyStatus.Warning);
                // notification.warning({ message: result.error.messageText });
            }
            this.setState({loadingTable: false});
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    PrintTongHopNgay() {
        Actions.setLoading(true);
        PrintService.PrintThongkeDichVuBenhNhan(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), 
        this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.hinhthucthanhtoan.toString(), "", 
        this.state.chinhanh.toString(), this.props.data.filter)
        .then((result) => {
            Actions.DoPrint(result.item)
            Actions.setLoading(false);
        })
    }

    onExportExcelClinkHandle() {
        Actions.setLoading(true);
        let filterModel = {
            siteIds : [Number(this.state.chinhanh)],
            coporationIds : [],
            paymentTypeIds : this.state.hinhthucthanhtoan,
            dateFrom : this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
            dateTo : this.state.ngay[1].format('YYYY-MM-DD HH:mm'),
            billType : this.props.data.filter === '' ? 0 : Number(this.props.data.filter),
            isGetAll : true
        }
        ReportService.ExportDailyReportRevenue(filterModel).then(result => {
            result.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "ThongKeDoanhThu_"+moment(new Date()).format('YYMMDDhhmmss');
                this._hiddenLink.click();
            })
        }).catch(err => {
        if (err.error && err.error.messageText)
            notification.warning({ message: err.error.messageText })
        }).finally(() => {
        Actions.setLoading(false);
        })
    }

    ExportThongKeDoanhSo(paymentType = this.state.hinhthucthanhtoan.toString()) {
        paymentType = Number(paymentType ?? 0);
        if (paymentType == 0) paymentType = null;
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount:(this.state.pageIndex - 1) * this.state.pageSize,
            maxResultCount: this.state.pageSize,
            // sorting: sorting,
            //riêng theo module
            dateFrom: this.state.ngay[0].format('YYYY-MM-DD HH:mm'),
            dateTo: this.state.ngay[1].format('YYYY-MM-DD HH:mm'),
            paymentTypeId: paymentType,
            siteIds : [Number(this.state.chinhanh)],
            // fromPayment: fromPayment
        }
        ReportService.ExportThongKeTheoDichVuBenhNhanImprove(filterModel).then(response => {
            let res = response.clone();
            res.json().then((t) => {
                if (!t.isSuccess) {
                    // notification.error({ message: t.error.messageText });
                    this.notifyRef.Show(`${t.error.messageText}. ${t.error.extensionData}.`, NotifyStatus.Error);
                }
        }).catch(err => {
            response.blob().then((blob) => {
                let ProductPaymentType = this.props.data.filter == "" ? "TongHop" : this.props.data.filter == ProductType.Service ? "DichVu" : "ThuocVatTu"
                let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BCDoanhThu${ProductPaymentType}_ChiTiet`; 
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = fileName;
                this._hiddenLink.click();
                this.notifyRef && this.notifyRef.Close();
            })
        })
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            if (err.error && err.error.messageText)
                this.notifyRef.Show(err.error.messageText, NotifyStatus.Error);
            else this.notifyRef.Show(err, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }
    GetAllInvoices = (data) => {
        if (data.billNo)
            return data.billNo;
        else if (data.sobienlai)
            return data.sobienlai;
        else return "";
    }
    getPaymentOwnList = (record) => {
        let tongtien = (record.tongtien).toLocaleString('en-US');
        let result = "";
        if (record.listpayment && record.listpayment.length > 0) {
            record.listpayment.map((item, index) => {
                if (Number(item.total) > 0) {
                    if (result !== "") result += ", ";
                    result += `${item.name}: ${item.total.toLocaleString('en-US')}đ`;
                }
            })
        }
        if (result !== "") result += " - ";
        result += `Tổng: ${tongtien}đ`;
        return result;
    }

    getPaymentMethod() {
        const getAll = true;
        VPService.GetDanhSachLoaiThanhToan(getAll).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiTT: result.data,
                })
            }
        }).catch(err => { })
    }

    renderContent(value, colSpan) {
        let obj = {
          children: value,
          props: {colSpan: colSpan},
        };
        return obj;
    }

    render() {
        const columns = [
            {
                title: 'STT',
                width: 40,
                align: 'center',
                render: (text, record, index) => {
                    if (record.isMaster) {
                        // return this.renderContent('',1);
                        let t=<strong style={{ fontSize: '15px', float: 'left', marginLeft: '5px', color: record.isBillRefund ? 'red':'' }}>
                        {(this.state.pageIndex-1)*this.state.pageSize +(index+1)}. Biên lai: {record.billNo} - {record.patientName} - {moment(record.billDate).format(FORMAT_DATE_TIME)}
                        </strong>;
                        return this.renderContent(t,12);
                    }
                    else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{index+1}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Sản phẩm/Dịch vụ',
                dataIndex: 'vienPhiTen',
                key: 'vienPhiTen',
                width: 150,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{text}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'SL',
                dataIndex: 'soLuong',
                key: 'soLuong',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{text}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Đơn giá',
                dataIndex: 'donGiaGoc',
                key: 'donGiaGoc',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{Number(text).toLocaleString('en-US')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Thành tiền',
                dataIndex: 'thanhTien',
                key: 'thanhTien',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{Number(text).toLocaleString('en-US')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Giảm trừ khác',
                dataIndex: 'giamGia',
                key: 'giamGia',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{(Number(text)+(Number(record.thanhToan)-Number(record.thucThu))).toLocaleString('en-US')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Hoàn trả',
                dataIndex: 'refundAmount',
                key: 'refundAmount',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{Number(!isNullOrEmpty(record.refundId) ? text : 0).toLocaleString('en-US')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Thực thu',
                dataIndex: 'thucThu',
                key: 'thucThu',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{Number(!isNullOrEmpty(record.refundId) ? 0 : text).toLocaleString('en-US')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Người lập',
                dataIndex: 'cashierName',
                key: 'cashierName',
                width: 150,
                render: (text, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{text}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            {
                title: 'Hình thức',
                dataIndex: 'paymentTypeText',
                key: 'paymentTypeText',
                width: 150,
                render: (text, record, index) => {
                    if (record.isMaster) return this.renderContent('',0)
                    else {
                        let t=text;
                        if (record.ngayThuCongNo != null && record.ngayThuCongNo != undefined) {
                            t = t.replace('Công nợ', `Công nợ (Đã thu)`);
                        }
                        return this.renderContent(t,1);
                    }
                },
            },
            {
                title: 'Biên lai hoàn trả',
                dataIndex: 'refundBillNo',
                key: 'refundBillNo',
                align: 'center',
                width: 120,
                render: (value, record, index) => {
                    return (record.isMaster ? this.renderContent('',0) : this.renderContent(<span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{value}</span>,1));
                },
            },
            {
                title: 'Ngày hoàn trả',
                dataIndex: 'refundDate',
                key: 'refundDate',
                align: 'left',
                width: 150,
                render: (value, record, index) => {
                    if(record.isMaster){
                        return this.renderContent('',0)
                    } else {
                        let t = <span style={{color : !isNullOrEmpty(record.refundId) ? 'red':''}}>{(!isNullOrEmpty(value) ? moment(value).format(FORMAT_DATE_TIME) : '')}</span>
                        return (this.renderContent(t,1));
                    }
                },
            },
            // {
            //     title: 'Đơn vị gửi mẫu',
            //     width: 100,
            //     dataIndex: 'donViGui_Ten',
            //     key: 'donViGui_Ten',
            //     render: (value, record, index) => {
            //         return record.isMaster ? '' : value
            //     },
            // },
        ]

        return (
            <React.Fragment>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <div className='list-page'>
                    <HeaderModal title={this.props.data.title} 
                        style={{margin:'0'}}
                        onClose={() => { this.props.onCancel && this.props.onCancel() }} />
                    <div style={{ background: 'aliceblue', borderRadius: 8 }}>
                        <div className="row mt-2">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                        showTime={{ format: 'HH:mm' }}
                                        allowClear={false} format="DD-MM-YYYY HH:mm" value={this.state.ngay}
                                        placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
                                        onChange={(value => this.setState({ ngay: value }, () => {
                                            this.LoadGeneral()
                                            this.LoadThongKeDoanhSo()
                                        }))}
                                        getPopupContainer={(e) => e.parentNode}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2" style={{ overflow: 'auto', height: 108, }}>
                            <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className='container' 
                                    onClick={() => { 
                                        this.setState({ hinhthucthanhtoan: 0 }, () => this.LoadThongKeDoanhSo()); 
                                    }}
                                >
                                    <div className={`r4_counter row mb-0 mt-2 ${this.state.hinhthucthanhtoan === 0 && "btn-color"}`}>
                                        <div className="col-3" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                            <i className="fas fa-dollar-sign fa-3x" style={{ color: 'cadetblue' }}></i>
                                        </div>
                                        <div className="col-8" style={{ alignSelf: 'center' }}>
                                            <div><h3>{this.state.tongdoanhthu.toLocaleString('en-US')}</h3></div>
                                            <span style={{ textAlign: 'left' }}>Tổng doanh thu</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.listTT && this.state.listTT.map((loaiTT, index) => {
                                    return (
                                        <div className="col-lg-3 col-sm-6 col-xs-12">
                                            <div className="container" onClick={() => { this.setState({ hinhthucthanhtoan: loaiTT.maHinhThuc }, () => this.LoadThongKeDoanhSo()) }}>
                                                <div className={`r4_counter row mb-0 mt-2 ${this.state.hinhthucthanhtoan === loaiTT.maHinhThuc && "btn-color"}`}>
                                                    <div className="col-3" style={{ alignSelf: 'center', textAlign: 'center' }}>
                                                        <i className="fas fa-money-bill-alt fa-3x" style={{ color: 'cadetblue' }}></i>
                                                    </div>
                                                    <div className="col-8" style={{ alignSelf: 'center' }}>
                                                        <div><h3>{loaiTT.tongTien.toLocaleString('en-US')}</h3></div>
                                                        <span style={{ textAlign: 'left' }}>{loaiTT.tenHinhThuc}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="mt-2">
                        <Notify ref={(c) => this.notifyRef = c} />
                    </div>
                    <div className="list-page-body">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Chi tiết doanh thu" key="1">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div>
                                        <Tooltip title={<span>{(this.state.tongbn-this.state.refundCount)} biên lai thanh toán.<br/>{this.state.refundCount} biên lai hoàn trả.</span>}>
                                        <label>Tổng biên lai: <strong>{this.state.tongbn.toLocaleString('en-US')}</strong>
                                            {this.state.refundCount > 0 && <Fragment>&nbsp;({(this.state.tongbn-this.state.refundCount).toLocaleString('en-US')} + <label className='text-danger'>{this.state.refundCount.toLocaleString('en-US')}</label>)</Fragment>}
                                        </label>
                                        </Tooltip>
                                        <label className='ml-4'>Tổng tiền: <strong>{this.state.tongtienBD.toLocaleString('en-US')}</strong></label>
                                        {/* <label className='ml-2'>BHYT trả: <strong>{this.state.tongBHYT.toLocaleString('en-US')}</strong></label> */}
                                        <Tooltip title={<span>{(this.state.tongGiamDichVu.toLocaleString('en-US'))} Giảm dịch vụ.<br/>{this.state.tongGiamBill.toLocaleString('en-US')} Giảm bill.</span>}>
                                        <label className='ml-4'>Miễn giảm: <strong>{this.state.tonggiamtru.toLocaleString('en-US')}</strong></label>
                                        </Tooltip>
                                        <label className='ml-4'>Hoàn trả: <strong>{this.state.refundAmount.toLocaleString('en-US')}</strong></label>
                                        {/* <label className='ml-2'>Tổng thanh toán: <strong>{this.state.tongtien.toLocaleString('en-US')}</strong></label>  */}
                                    </div>
                                    <div>
                                        <button className='btn btn-color' onClick={() => { this.ExportThongKeDoanhSo() }}><i className="fas fa-download mr-1"></i>Xuất excel</button>
                                        <button className='btn btn-outline-secondary ml-2' onClick={() => { this.PrintTongHopNgay() }}><i className="fa fa-print mr-1"></i>In</button>
                                    </div>
                                </div>
                                {/* <div className='d-flex flex-column justify-conten-center' style={{alignItems: 'center', height: 'calc(100% - 78px)' }}>
                                    <Table */}
                                <div className="list-page-table">
                                    <Table style={{ height: "calc(100% - 65px)", width: "100%" }}
                                        pagination={false}
                                        scroll={{ x: 'max-content', y: 0 }}
                                        expandedRowKeys={this.state.expandedRowKeys}
                                        onExpand={(expanded, record) => {
                                            let key = `${record.soBienLai.toString()}${(record.vienPhiId ?? 0).toString()}`;
                                            let expandedRowKeys = this.state.expandedRowKeys;
                                            if (expanded) {expandedRowKeys.push(key);}
                                            else { expandedRowKeys = expandedRowKeys.filter(x=> x != key); }
                                            this.setState({expandedRowKeys: expandedRowKeys});
                                        }}
                                        expandIconColumnIndex={5}
                                        expandRowByClick={true}
                                        loading={this.state.loadingTable}
                                        dataSource={this.state.dataSource} columns={columns} 
                                        rowKey={(row, index) => `${row.soBienLai.toString()}${(row.vienPhiId ?? 0).toString()}`}
                                    />
                                    <Pagination
                                      current={this.state.pageIndex}
                                      size={this.state.pageSize}
                                      style={{ textAlignLast: "center", marginTop: "2px"  }}
                                      total={this.state.tongbn}
                                    //   showTotal={total => `Tổng: ${total}`}
                                      onChange={(page, size) => { this.setState({pageIndex: page, pageSize: size}); }}
                                      onShowSizeChange={(page, size) => { this.setState({pageIndex: page, pageSize: size}); }}
                                      showSizeChanger={true}
                                    />
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Hình thức thanh toán" key="2">
                                <ReportByBill ref={(c) => this.ReportByBill = c} Ngay={this.state.ngay} paymentType={this.state.hinhthucthanhtoan} filter={this.props.data.filter.toString()} />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                    <div className="list-page-footer text-center">
                        <button className="btn btn-danger"
                            onClick={() => { this.props.onCancel() }}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
