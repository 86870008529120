import React, { useState , useRef, useEffect, useCallback } from "react";
import { notification, Upload } from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined, DeleteFilled } from '@ant-design/icons';
import UploadFilePACS from '../../../../components/MitaComponents/UploadFile/mitaUploadFilePacs';
import * as Actions from '../../../../libs/actions';
import * as Configure from '../../../../configure/urlConfig';
import * as UploadFileService from '../../../../services/UploadFileService';
import moment from 'moment';
import Webcam from "react-webcam";
import ButtonInputCamera from "./mitaCameraButton";
import '../css/details.css';

var task = null;
export default (props) => {
    const _uploadRef = useRef();
    const _webcamRef = useRef();
    const _btnInputCamera = useRef();
    const _mediaRecorderRef = useRef(null);
    // Mã tính năng (xn, cdha, patient)
    const [featureName, setFeatureName] = useState("cdha")
    // Mã component sử dụng (chẩn đoán hình ảnh, xét nghiệm)
    const [componentKey, setComponentKey] = useState("CDHA-Multimedia")
    // Id tham chiếu
    const [refId, setRefId] = useState('')
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [capturing, setCapturing] = useState(false);  
    const [recordedChunks, setRecordedChunks] = useState([]);
    //Datasource
    const [fileList, setFileList] = useState([]);
    // Timer
    const [TimerCount, setTimerCount] = useState("00:00");

    useEffect(() => {
        setComponentKey(props.componentKey);
        setRefId(props.refId);
        setSelectedDevice(props.device);
    },[props])

    useEffect(() => {
        if(recordedChunks.length > 0){
            Finish();
        }
    },[recordedChunks])

    const takePicture = () => {
        if (_webcamRef.current) {
            var png = _webcamRef.current.getScreenshot();
            var byteString = window.atob(png.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var the_file = new Blob([ab], { type: 'image/jpeg' });
            // let urlTemp = window.URL.createObjectURL(the_file);
            let uid = moment().format("YYYYMMDDHHMMss") + moment().milliseconds().toString();
            let name = "snapshot" + uid + ".jpeg";
            let temp = {
                uid: uid,
                name: name,
                status: 'done',
                url: png,
                blob: new File([the_file], name)
            }
            setFileList([temp]);
        }
    }

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        _mediaRecorderRef.current = new MediaRecorder(_webcamRef.current.stream, {
            mimeType: "video/webm;codecs=h264"
        });
        _mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
        );
        _mediaRecorderRef.current.start();
        returnTimer(moment());
    }, [_webcamRef, _mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
        setCapturing(false);
        _mediaRecorderRef.current.stop();
        task && clearInterval(task);
    }, [_mediaRecorderRef, _webcamRef]);

    const returnTimer = (currentTime) => {
        task = setInterval(() => {
            setTimerCount( moment(moment().diff(currentTime)).format("mm:ss") );
        }, 1000);
        return () => {
            clearInterval(task);
        };
    }

    const Finish = React.useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            let url = `${Configure.BASE_URL}/images/DefaultThumbnailForVideo.jpg`
            url = url.replace("/api/","");
            let uid = moment().format("YYYYMMDDHHMMss") + moment().millisecond().toString();
            let name = "video" + uid + ".webm";
            // Lấy default thumbnail do chưa lấy thumbnail từ video đc
            let temp = {
                uid: uid,
                name: name,
                status: 'done',
                // response: 'Server Error 500', // custom error message to show
                url: url,
                blob: new File([blob], name)
            }
            
            setFileList(c => [...c, temp])
            setRecordedChunks([]);
        }
        
    }, [recordedChunks]);

    const onClose = () => {
        _uploadRef.current && _uploadRef.current.onSubmitUpload();
    }

    const onUploadComplete = useCallback(() => {
        props.onClose();
    })

    return (
        <div className=''>
            <div className="row">
                <div className="col-12 upload-edit-position">
                    <UploadFilePACS
                        ref={_uploadRef}
                        featureName={featureName}
                        componentKey={componentKey}
                        refId={refId}
                        camera={false}
                        isEdit={false}
                        fileList = {fileList}
                        onUploadComplete = {onUploadComplete}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    {selectedDevice != null &&
                        <div>
                            <div className='containerTimer'>
                                <i className="fas fa-video recordAnimation" style={{ marginRight: 5 }}/><span>{TimerCount}</span>
                            </div>
                            <Webcam
                                screenshotQuality={1}
                                forceScreenshotSourceSize
                                screenshotFormat="image/jpeg"
                                ref={_webcamRef}
                                style={{ width: '99%', }}
                                audio={false} 
                                videoConstraints={{
                                    height: 1080,
                                    width: 1920,
                                    deviceId: selectedDevice.deviceId 
                                }}/>
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-2">
                <ButtonInputCamera
                    ref={_btnInputCamera}
                    onSelected = {(device) => { setSelectedDevice(device);}}
                    takePhoto = {true}
                    onCapturePhoto = {() => { takePicture() }}
                    handleStart = {handleStartCaptureClick}
                    handleStop = {handleStopCaptureClick}
                    onClose = {() => { onClose() }}
                />
            </div>
        </div>
    )
};
