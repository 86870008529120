import { Button, Input, InputNumber, notification, Radio, Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useState, useCallback, useRef, useTransition } from 'react'
import * as Actions from '../../libs/actions';
import * as CorporationServices from './CorporationServices';
import * as PatientService from '../../services/patientService';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import * as NhanVienSerivce from '../../services/employeeService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import { DeleteThanhToanNoiTru } from '../../services/inPatientService';
import { FEATURE } from '../../configureHIS/common/constants';
import { FeatureDeactive } from '../../configure/featureConfig';
import { CheckBank, isnotnull, isNullOrEmpty } from '../../libs/util';
import * as CoporationServices from "../../services/CorporationServices";
import HeaderModal from '../../configureHIS/common/headerModal';

const { TextArea } = Input;
const { Option } = Select;
let task = null;

export default (props) => {
    const ListLegalType = [
        {'value' : 0, 'label' : 'Thể nhân'},
        {'value' : 1, 'label' : 'Pháp nhân'},
    ]
    const [edit, setEdit] = useState(false);
    const [detail, setDetail] = useState({});
    const [ten, setTen] = useState('');
    const [dienthoai, setDienthoai] = useState('');
    const [diachi, setDiachi] = useState('');
    const [daidien, setDaidien] = useState('');
    const [taikhoan, setTaikhoan] = useState('');
    const [username, setUsername] = useState('');
    const [ma, setMa] = useState('');
    const [lstNhanVienSale, setlstNhanVienSale] = useState([]);
    const [sale, setNhanVienSale] = useState(0)
    const [lstNguoigioithieu, setlstNguoigioithieu] = useState([]);
    const [nguoigioithieu, setNguoigioithieu] = useState(0);
    const [lstBanggia, setlstBanggia] = useState([]);
    const [lstLoaiTT, setlstLoaiTT] = useState([]);
    const [banggia, setBanggia] = useState('default');
    const [paymenttype, setPaymenttype] = useState(0);
    const [email, setEmail] = useState('');
    const [legalType, setLegalType] = useState(null);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [listCoporationParent, setListCoporationParent] = useState([]);
    const [coporationParentId, setCoporationParentId] = useState();
    const [listCoporationChild, setListCoporationChild] = useState([]);
    const [coporationChildId, setCoporationChildId] = useState();
    const [discountType, setDiscountType] = useState();
    const [discount, setDiscount] = useState();

    useEffect(() => {
        setEdit(props.edit)
    }, [props.edit])
    useEffect(() => {
        setDetail(props.detail)
    }, [props.detail])  
    useEffect(() => {
        if (detail.ma) {
            setDiachi(detail.diachi);
            setTen(detail.ten);
            setDienthoai(detail.dienthoai);
            setTaikhoan(detail.taikhoan);
            setMa(detail.ma);
            setDaidien(detail.daidien);
            setUsername(detail.username);
            setNhanVienSale(detail.sale);
            setNguoigioithieu(detail.nguoigioithieu);
            setBanggia(detail.banggia);
            setPaymenttype(detail.paymenttype);
            setEmail(detail.email);
            setLegalType(detail.legalType);
            setCoporationParentId(detail.coporationParentId);
            setCoporationChildId(detail.coporationChildId);
            setDiscountType(detail.discountType);
            setDiscount(detail.discount);
        }
    }, [detail])

    useEffect(() => {
        GetlstNhanVienSale();
        GetlstNguoiGioiThieu();
        GetListBangGia();
        getPaymentMethod();
        onSearchCoporationParent();
    },[])

    useEffect(() => {
        onSearchCoporationChild();
    }, [coporationParentId])

    function GetlstNhanVienSale(){
        NhanVienSerivce.DanhSachNhanVien('', 1, 100,26).then(result => {
            if (result.isSuccess) {
                setlstNhanVienSale(result.data)
            }
        }).catch(err => {
        })
    }

    function GetlstNguoiGioiThieu(){
        Actions.setLoading(true);
        PatientService.GetListNguoiGioiThieu('', true, 1, 100).then(results => {
            if (results.isSuccess) {
                setlstNguoigioithieu(results.data);
            } else {
                Actions.openMessageDialog("Lỗi", results.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err);
            Actions.setLoading(false);
        })
    }

    function GetListBangGia(){
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setlstBanggia(result.data)
            }
        }).catch(err => {
        })
    }

    function getPaymentMethod() {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                setlstLoaiTT(res)
            }
        }).catch(err => {
        })
    }
    
    const Submit = () => {
        if (validator.current.allValid()) {
            if (discountType == "percent" && discount > 100) {
                notification.warning({ message: "Tỷ lệ chiết khấu không được vượt quá 100%"});
                return;
            }
            if (discount != null && (discountType == null || discountType == "")) {
                notification.warning({ message: "Hãy chọn hình thức chiết khấu."});
                return;
            }
            let data = {
                diachi, ten, dienthoai, taikhoan, ma, daidien, username,
                sale: sale,
                nguoigioithieu: nguoigioithieu,
                banggia, paymenttype,
                email : email,
                legalType,
                coporationParentId,
                coporationChildId,
                discountType,
                discount
            }
            if (!edit) {
                Actions.setLoading(true);
                CorporationServices.CreateCoporation(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Thêm thành công" })
                        if (props.onSuccess)
                            props.onSuccess()
                    } else {
                        result.err.msgString ?
                            notification.warning({ message: result.err.msgString })
                            : notification.warning({ message: result.error?.messageText ?? "Đã xảy ra lỗi, vui lòng thử lại sau" })
                    }
                }).catch(err => {
                    Actions.openMessageDialog('Lỗi', err.error?.messageText ?? err)
                }).finally(() => Actions.setLoading(false));
            }
            else {
                data.id = detail.id;
                Actions.setLoading(true);
                CorporationServices.UpdateCoporation(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: "Cập nhật thành công" })
                        if (props.onSuccess)
                            props.onSuccess()
                    } else {
                        Actions.openMessageDialog('Lỗi', result.error?.messageText ?? result.err.msgString);
                    }
                }).catch(err => {
                    Actions.openMessageDialog('Lỗi', err.error?.messageText ?? err)
                }).finally(() => Actions.setLoading(false));
            }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }
    
    const onSearchCoporationParent = (key) => {
        const data = {
            keyword: key,
            skipCount: 0,
            maxResultCount: 20,
        }
        CoporationServices.GetListParent(data).then(res => {
            if (res.isSuccess) {
                let result = res.data.items;
                if (result.find(x => x.uid == props.detail.coporationParentId) == undefined) {
                    if (props.detail.coporationParentId) {
                        result.push({
                            parentName: props.detail.coporationParentName,
                            uid: props.detail.coporationParentId,
                        })
                    }
                }
                setListCoporationParent(result);
            }
        }).catch(err => {
        }).finally()
    }
    
    const onSearchCoporationChild = () => {
        const data = {
            parentId: coporationParentId,
            skipCount: 0,
            maxResultCount: 20,
        }
        CoporationServices.GetListChildGroup(data).then(res => {
            if (res.isSuccess) {
                let result = res.data.items;
                if (result.find(x => x.uid == props.detail.coporationChildId) == undefined) {
                    if (props.detail.coporationParentId) {
                        result.push({
                            childName: props.detail.coporationChildName,
                            uid: props.detail.coporationChildId,
                        })
                    }
                }
                setListCoporationChild(result);
            }
        }).catch(err => {
        }).finally()
    }

    const onClearDiscountType = () => {
        setDiscountType();
        setDiscount();
        validator && validator.current && validator.current.purgeFields();
    }

    return (
        <React.Fragment>
            <HeaderModal title={edit ? detail.ten : 'Thêm nguồn khách hàng'} onClose={() => { props.onClose() }} />
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label className='required-field'>Mã</label>
                    <Input disabled={edit} value={ma.toUpperCase()} className="w-100"
                        placeholder="Nhập mã nguồn khách hàng" maxLength={8}
                        onChange={(e) => {
                            setMa(e.target.value.toUpperCase());
                        }} />
                    {validator.current.message("Mã nguồn khách hàng", ma, "required")}
                </div>
                <div className="col-md-6 mb-2">
                    <label className='required-field'>Tên</label>
                    <Input value={ten} className="w-100"
                        placeholder="Nhập tên nguồn khách hàng"
                        onChange={(e) => {
                            setTen(e.target.value);
                        }} />
                    {validator.current.message("Tên nguồn khách hàng", ten, "required")}
                </div>
                <div className="col-md-6 mb-2">
                    <label>Điện thoại</label>
                    <Input value={dienthoai} className="w-100"
                        placeholder="Nhập điện thoại"
                        onKeyPress={(e) => {
                            if (e.key === " " || isNaN(e.key) === true)
                                e.preventDefault();
                        }}
                        onChange={(e) => {
                            setDienthoai(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Email</label>
                    <Input value={email} 
                        type="email"
                        className="w-100"
                        placeholder="Nhập email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Người đại diện</label>
                    <Input value={daidien} className="w-100" maxLength={25}
                        placeholder="Nhập tên người đại diện"
                        onChange={(e) => {
                            setDaidien(e.target.value);
                        }} />
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tài khoản xem kết quả</label>
                    <Input
                        className='w-100' maxLength={20}
                        placeholder='Nhập tài khoản xem kết quả'
                        onChange={e => {
                            setUsername(e.target.value)
                        }}
                        value={username}
                    ></Input>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Tài khoản ngân hàng</label>&nbsp;
                    {!isNullOrEmpty(taikhoan) && /\d{8,18}/g.test(taikhoan) ? 
                        <Tooltip title="Đây là thông tin tự động nhận diện, chỉ hiển thị để xem, hệ thống không lưu trữ. Nếu thông tin hiển thị sai khác, vui lòng bỏ qua hoặc thông báo đến bộ phận phát triển phần mềm"><Tag color='blue'>{CheckBank(taikhoan)}</Tag></Tooltip> : ""}
                    <Input
                        className='w-100'
                        placeholder='Nhập số tài khoản ngân hàng'
                        onChange={e => {
                            setTaikhoan(e.target.value)
                        }}
                        value={taikhoan}
                    ></Input>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Địa chỉ</label>
                    <TextArea rows={1}
                        onChange={e => {
                            setDiachi(e.target.value)
                        }}
                        value={diachi}
                    ></TextArea>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Sale</label>
                    <Select
                        className="w-100"
                        showSearch
                        allowClear={true}
                        value={sale !== 0 ? sale : null}
                        onChange={(value) => { setNhanVienSale(value)  }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {lstNhanVienSale.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.hoten}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Người giới thiệu</label>
                    <Select 
                        className="w-100"
                        showSearch
                        allowClear={true}
                        value={nguoigioithieu && nguoigioithieu != 0 ? nguoigioithieu.toString() : null}
                        onChange={(e) => {setNguoigioithieu(e !== undefined ? parseInt(e) : e)}}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {lstNguoigioithieu.map((row, index) => {
                            return (
                                <Option key={index} value={row.id}>{row.hoten}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Hình thức thanh toán mặc định</label>
                    <Select className="w-100" 
                    value={paymenttype !== 0 ? paymenttype : null} 
                    onChange={(e) => { setPaymenttype(e) }}
                    allowClear
                    onClear={() => setPaymenttype(0)}>
                        {lstLoaiTT && lstLoaiTT.map((hinhthuc, index) =>
                            <option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</option>
                        )}
                    </Select>
                </div>
                {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                    <div className="col-md-6 mb-2">
                        <label className='required-field'>Bảng giá</label>
                        <Select
                            label="Bang gia"
                            className="w-100"
                            showSearch
                            value={banggia}
                            onChange={(value) => { setBanggia(value) }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {/* <Option key={-1} value='default'>Mặc định</Option> */}
                            {lstBanggia.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                        {validator.current.message("Bảng giá", banggia, "selection")}
                    </div>}
                <div className="col-md-6 mb-2">
                    <label>Đại diện</label>
                    <Select 
                        placeholder="Chọn đại diện"
                        className="w-100"
                        value={legalType}
                        onChange={(e) => { setLegalType(e) }}
                        allowClear
                        onClear={() => setLegalType(null)}
                    >
                        {ListLegalType.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="col-md-6 mb-2">
                    <label>Nguồn khách hàng tổng</label>
                    <Select className="w-100"
                        value={coporationParentId}
                        onChange={(e) => { setCoporationParentId(e) }}
                        allowClear showSearch filterOption={false}
                        onClear={() => {
                            setCoporationParentId();
                            validator && validator.current && validator.current.purgeFields();
                        }}
                        onSearch={(e) => {
                            if (task) clearTimeout(task);
                            task = setTimeout(() => {
                                onSearchCoporationParent(e);
                            }, 400)
                        }}>
                        {listCoporationParent && listCoporationParent.map((x, index) =>
                            <option key={index} value={x.uid}>{x.parentName}</option>
                        )}
                    </Select>
                </div>
                {isnotnull(coporationParentId) && <div className='col-md-6 mb-2'>
                    <label className={isnotnull(coporationParentId) ? "required-field" : ""}>Nhóm nguồn con</label>
                    <Select placeholder='Chọn nhóm nguồn con'
                        value={coporationChildId} className='w-100'
                        onChange={(e) => setCoporationChildId(e)}
                        showSearch allowClear filterOption={false}
                        onSearch={(e) => {
                            if (task) clearTimeout(task);
                            task = setTimeout(() => {
                                onSearchCoporationChild(e);
                            }, 400)
                        }}
                    >
                        {listCoporationChild && listCoporationChild.map((x, index) => {
                            return (
                                <option key={index} value={x.uid}>{x.childName}</option>
                            )
                        })}
                    </Select>
                    {isnotnull(coporationParentId) ? validator.current.message("Nhóm nguồn con", coporationChildId, "selection") : ""}
                </div>}
                {FeatureDeactive().indexOf(FEATURE.CHIET_KHAU_NGUON_KHACH_HANG) == -1 &&
                    <div className='col-md-6 mb-2'>
                        <label className='d-flex flex-row'>
                            <span>Tỷ lệ chiết khấu</span>
                            <span className='ml-2'>
                                <Radio.Group value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                                    <Radio disabled value={null} style={{ display: "none", visibility: "hidden" }}>Không mang giá trị</Radio>
                                    <Radio value="percent">Phần trăm</Radio>
                                    <Radio value="amount">Số tiền</Radio>
                                </Radio.Group>
                            </span>
                            <a className='ml-auto text-color' onClick={() => { onClearDiscountType() }} >Xóa bỏ</a>
                        </label>
                        {discount != null
                            ? validator.current.message("Hình thức chiết khấu", discountType, "selection") : ""}
                        <InputNumber className='w-100'
                            placeholder='Nhập tỷ lệ chiết khấu'
                            value={discount} allowClear
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            min={0} max={discountType == "percent" ? 100 : 9000000000}
                            // prefix={discountType == "percent" ? "%" : discountType == "percent" ? <u>đ</u> : ""}
                            onChange={(e) => setDiscount(e)}
                        />
                        {discountType == "percent"
                            ? validator.current.message("Phần trăm chiết khấu", discount, "required|numeric|min:0,num|max:100,num")
                            : discountType == "amount" ? validator.current.message("Số tiền chiết khấu", discount, "required|numeric|min:0,num|max:9000000000,num") : ""}
                    </div>
                }
            </div>
            <div className="d-flex align-self-center">
                <button className="btn btn-color mr-1"
                    onClick={() => {
                        Submit()
                    }}>
                    <i className="fas fa-save" style={{ marginRight: 5 }} />Lưu</button>
                <button
                    className="btn btn-danger ml-1"
                    onClick={() => {
                        if (props.onClose)
                            props.onClose()
                    }}>
                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
            </div>
        </React.Fragment>
    )
}
