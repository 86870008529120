import { Input, Radio } from 'antd';
import React, { Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import Notify, { NotifyStatus } from '../../../components/notify';
import HeaderModal from '../../../configureHIS/common/headerModal';
import ActionControl from '../../../libs/actionControl';
import MedicineList from './medicineList';
import * as MedicineService from '../../../services/medicineService';
import * as Actions from '../../../libs/actions';
import { isNullOrEmpty } from '../../../libs/util';
import MedicineSearch from './medicineSearch';

const regex = "(^[a-zA-Z0-9]{1,100}$)";
const ANONYMOUS = false;
const PRIVATE = true;

export default forwardRef((props, ref) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useImperativeHandle(ref, () => ({
    }));

    const [maDon, setMaDon] = useState();
    const [maDichVu, setMaDichVu] = useState();
    const [tenDichVu, setTenDichVu] = useState();
    const [isPrivate, setIsPrivate] = useState(false);
    const [danhSachThuoc, setDanhSachThuoc] = useState([]);
    const [tieuDe, setTieuDe] = useState("");
    const [inEditMode, setInEditMode] = useState(false);
    const [isDonNgoai, setIsDonNgoai] = useState();

    const notifyRef = useRef();

    const handleCancel = () => {
        props.onClose && props.onClose();
    }

    useEffect(() => {
        let info = props.data;
        setMaDon(info.maDon);
        setTenDichVu(props.tenDichVu ?? info.tenDichVu);
        setMaDichVu(props.maDichVu ?? info.maDichVu);
        setDanhSachThuoc(info.danhSachThuoc ?? []);
        setIsPrivate(info.isPrivate ?? false);
        setTieuDe(info.tieuDe ?? "");
        if (isNullOrEmpty(props.maGoiThuoc)) getThongTinDonThuoc();
        else setInEditMode(true);
        setIsDonNgoai(props.isDonNgoai ?? false);
    }, [props])

    const getThongTinDonThuoc = () => {
        let idDonThuoc = maDon ? maDon : props.data.maDon ?? "";
        MedicineService.getThongTinDonThuoc(idDonThuoc).then(result => {
            if (result.isSuccess) {
                notifyRef && notifyRef.current.Close();
                let danhSachThuoc = result.data.danhSachThuoc;
                if (danhSachThuoc && danhSachThuoc.length > 0) {
                    danhSachThuoc = danhSachThuoc.map(x => ({ ...x, sang: x.sang.toString(), trua: x.trua.toString(), chieu: x.chieu.toString(), toi: x.toi.toString() }))
                }
                setDanhSachThuoc(danhSachThuoc);
            }
            else {
                notifyRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            notifyRef.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            forceUpdate();
        })
    }

    const handleSave = () => {
        if (tieuDe === null || tieuDe === undefined || tieuDe === "" || tieuDe.trim() === "") {
            notifyRef && notifyRef.current.Show("Tiêu đề bắt buộc nhập", NotifyStatus.Warning);
            return;
        }
        if (danhSachThuoc.length == 0) {
            notifyRef && notifyRef.current.Show("Danh sách thuốc rỗng", NotifyStatus.Warning);
            return;
        }
        notifyRef && notifyRef.current && notifyRef.current.Close();
        Actions.setLoading(true);
        const data = {
            maDon: maDon,
            maDichVu: maDichVu,
            tieuDe: tieuDe,
            isPrivate: isPrivate,
            danhSachThuoc: JSON.stringify(danhSachThuoc),
        }
        MedicineService.savePrescriptionSample(data).then(result => {
            if (result.isSuccess) {
                notifyRef.current.Show("Tạo đơn thuốc mẫu thành công", NotifyStatus.Success);
                handleCancel();
            } else {
                notifyRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            notifyRef.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const handleSelectMedicine = (record) => {
        let isExisted = false;
        let newMedicineList = danhSachThuoc;
        let tenThuoc = "";
        if (record.isGoiThuoc && record.listThuoc && record.listThuoc.length > 0) {
            record.listThuoc.every(subThuoc => {
                isExisted = newMedicineList.filter(x => x.idThuoc === subThuoc.idThuoc).length > 0;
                tenThuoc = subThuoc.tenThuoc;
                if (isExisted) return;
            });
        }
        else if (newMedicineList.length > 0) isExisted = newMedicineList.filter(x => x.idThuoc === record.idThuoc).length > 0;
        if (isExisted) {
            notifyRef && notifyRef.current && notifyRef.current.Show(`Thuốc "${record.isGoiThuoc ? tenThuoc : record.tenThuoc}" đã tồn tại trong danh sách`, NotifyStatus.Warning);
        } else {
            notifyRef && notifyRef.current && notifyRef.current.Close();
            if (record.isGoiThuoc) {
                record.listThuoc.forEach(subThuoc => {
                    if (subThuoc.soLuong === undefined || subThuoc.soLuong === null || subThuoc.soLuong === 0)
                        subThuoc.soLuong = 1;
                    newMedicineList.push(subThuoc);
                });
            } else newMedicineList.push(record);
            setDanhSachThuoc(newMedicineList);
            forceUpdate();
        }
    }

    const handleUpdate = () => {
        if (tieuDe === null || tieuDe === undefined || tieuDe === "" || tieuDe.trim() === "") {
            notifyRef && notifyRef.current.Show("Tiêu đề bắt buộc nhập", NotifyStatus.Warning);
            return;
        }
        if (danhSachThuoc.length == 0) {
            notifyRef && notifyRef.current.Show("Danh sách thuốc rỗng", NotifyStatus.Warning);
            return;
        }
        notifyRef && notifyRef.current && notifyRef.current.Close();
        Actions.setLoading(true);
        const data = {
            id: props.maGoiThuoc,
            maDon: maDon.toString(),
            tieuDe: tieuDe,
            isPrivate: isPrivate,
            danhSachThuoc: JSON.stringify(danhSachThuoc),
        }
        MedicineService.updatePrescriptionSample(data).then(result => {
            if (result.isSuccess) {
                notifyRef.current.Show("Cập nhật đơn thuốc mẫu thành công", NotifyStatus.Success);
                handleCancel();
            } else {
                notifyRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            notifyRef.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    return (
        <Fragment>
            <div className='list-page'>
                <HeaderModal title={inEditMode ? "Đơn thuốc mẫu" : "Đặt làm đơn thuốc mẫu"} onClose={() => { props.onClose() }} />
                <Notify ref={notifyRef} />
                <div className="list-page-body">
                    <div className='row'>
                        <div className='col-md-6 mt-2 d-flex flex-column'>
                            <label className='mita-title required-field'>Mã đơn thuốc</label>
                            <label className='mita-value'>{maDon}</label>
                        </div>
                        <div className='col-md-6 mt-2 d-flex flex-column'>
                            <label className='mita-title required-field'>Tên dịch vụ</label>
                            <label className='mita-value'>{tenDichVu}</label>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label className='mita-title required-field'>Tiêu đề</label>
                            <Input
                                maxLength={200}
                                placeholder='Nhập tiêu đề'
                                value={tieuDe}
                                onKeyDown={(e) => {
                                }}
                                onChange={(e) => {
                                    setTieuDe(e.target.value)
                                }}
                            ></Input>
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label className="mita-title required-field">Chia sẻ</label>
                            <div>
                                <Radio.Group
                                    options={[
                                        { label: "Dùng chung", value: ANONYMOUS },
                                        { label: "Riêng tư", value: PRIVATE },
                                    ]}
                                    onChange={(e) => {
                                        setIsPrivate(e.target.value);
                                    }}
                                    value={isPrivate}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </div>
                        </div>
                        {inEditMode && <div className='col-12 mt-2'>
                            <label className='mita-title'>Chi tiết thuốc</label>
                            <MedicineSearch
                                handleSelect={(record) => { handleSelectMedicine(record) }} isDonNgoai={isDonNgoai} />
                        </div>}
                        <div className='col-md-12 mt-2'>
                            <label className='mita-title'>Danh sách thuốc</label>
                            <MedicineList data={danhSachThuoc} canDelete={inEditMode} isEdit={inEditMode} />
                        </div>
                    </div>
                </div>
                <div className="list-page-footer d-flex justify-content-between mt-2">
                    <div className='w-100'>
                        <ActionControl
                            activeSave={!inEditMode}
                            activeUpdate={inEditMode}
                            handleClose={() => handleCancel()}
                            handleSave={() => { handleSave() }}
                            handleUpdate={() => { handleUpdate() }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
})