import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'Loyalty';

export function getListLoyalMembers(groupid, key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListLoyalMembers?groupid=${groupid}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function getListLoyalGroups(key, page, size, getAll = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListLoyalGroups?key=${key}&page=${page}&size=${size}&getAll=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createLoyalGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateLoyalGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateLoyalGroup(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateLoyalGroup`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteLoyalGroup(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteLoyalGroup?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function exportListLoyalMembers(groupid, key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportListLoyalMembers?groupid=${groupid}&key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};