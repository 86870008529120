import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, useImperativeHandle, useCallback, forwardRef } from 'react';
import { Button, Table,  Modal, Pagination, notification, Input, Select, Tooltip, Popconfirm } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import VoucherCreate from "./voucher-create"
import * as TemplateVoucherService from '../../promotion/templatevoucher/templateVoucherServices';
import * as PromotionService from '../../../services/PromotionServices';
import * as PromotionVoucherService from '../../../services/PromotionVoucherServices';
import Notify, { NotifyStatus } from '../../../components/notify';
import VoucherHistoryList from "../voucherHistory/voucherhistory-list";

const { Option } = Select;
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    reset: () => {
      reset();
    },
    loadData: () => {
      if (pageIndex == 1) search();
      else setPageIndex(1);
    },
    openCreateVoucher: () => {
      if (props.promotionId) setVisibleCreate(true);
    },
  }));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
// function VoucherList(props) {
  //#region khai báo state
  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //state filterModel
  const [promotionId, setPromotionId] = useState(props.promotionId);
  const [keyword, setKeyword] = useState('');
  //list model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listVoucher, setListVoucher] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //voucherIdViewHistory
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleHistoryList, setVisibleHistoryList] = useState(false);
  const [voucherIdViewHistory, setVoucherIdViewHistory] = useState();
  const [promotionType, setPromotionType] = useState(props.promotionType);
  const [printTemplate, setPrintTemplate] = useState(null);
  const [listPrintTemplate, setListPrintTemplate] = useState([]);
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  
  //#region useEffect
  useEffect(() => {
    onSearchMauIn("", 1);
  }, []);

  useEffect(() => {
    search();
  }, [pageIndex, pageSize, sorting])

  useEffect(() => {
    searchToFirstPage();
    setSelectedRowKeys([]);
  }, [promotionId, keyword])

  useEffect(() => {
    props.setListVoucher(listVoucher);
  }, [listVoucher])

  useEffect(() => {
    setPromotionId(props.promotionId);
    setListVoucher([]);
  }, [props.promotionId]);

  useEffect(() => {
    setPromotionType(props.promotionType);
  }, [props.promotionType]);

  useEffect(() => {
      setDetailScreenMode(props.detailScreenMode);
  }, [props.detailScreenMode]);

  //#endregion 
  const reset = () => {
    //state paging và sorting
    setPageIndex(1);
    setPageSize(10);
    setSorting('');
    //state filterModel
    setPromotionId(props.promotionId);
    setKeyword('');
    //list model
    setTotalRecord(0);
    setListVoucher([]);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    //
    setVisibleCreate(false);
    setPrintTemplate();
    notiRef && notiRef.current.Close();
    validator && validator.current.hideMessages();
    forceUpdate();
  }

  //#region search & filter & call service
  const searchToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else search();
  }
  const search = () => {
    let filterModel = {
      promotionIds: [promotionId],
      keyword: keyword,
      voucherStates: [],

      sorting: sorting,
      skipCount: (pageIndex-1)*pageSize,
      maxResultCount: pageSize
    }
    if (!promotionId || promotionId == '') return;
    // let sortingAndPagingModel = {pageIndex: pageIndex, pageSize: pageSize, sorting: sorting};
    //get list voucher
    PromotionVoucherService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        setListVoucher(result.data.items);
      }
      else {
        notification.error({ message: result.error.messageText });
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notification.error({ message: err.error.messageText });
      else notification.error({ message: err });
    })
  }
  
  const deleteVouchers = (listRecord) => {
    let listUsed = listRecord.filter(x => x.usedNumber > 0);
    if (listUsed && listUsed.length > 0) 
      notiRef.current.Show("Các mã sau đã được sử dụng nên không thể xóa: "+listUsed.map(x => {return x.voucherCode}).join(', '), NotifyStatus.Warning);
    Actions.setLoading(true);
    PromotionVoucherService.deleteByIds(listRecord.map(x => {return x.id})).then(result => {
      if (result.isSuccess) {
        searchToFirstPage();
        setSelectedRowKeys(selectedRowKeys.filter(x => listRecord.map((c) => c.id).indexOf(x.id) > -1));
        notification.success({message: "Thành công"});
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
      notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);;
    }).finally(err => {
      Actions.setLoading(false);
    });
  }
  
  const onSearchMauIn = (key = '', page = 1, type = 0, size = 10) => {
    TemplateVoucherService.getList(key, type, page, size).then(res => {
        if (res.isSuccess) {
            setListPrintTemplate(res.data);
        }
    }).catch(() => { })
  }

  const printVouchers = (id = promotionId, voucherIds = []) => {
    if (!printTemplate) {
      notification.warning({ message: "Cần chọn mẫu in trước" });
      return;
    }
    PromotionService.printVouchersNew(id, printTemplate, voucherIds).then((result) => {
      if (result.isSuccess) {
        Actions.DoPrint(result.data)
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        notification.error({ message: result.error.messageText });
        forceUpdate();
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
      notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);;
    })
  }
  //#endregion
  const onCloseDialogCreateHandle = (isChangeData) => {
    if (isChangeData) {
      //reload table
      search();
    }
  }
  const onExportClickHandle = () => {
    if (!promotionId || promotionId == '') return;
    Actions.setLoading(true);
    let filterModel = {
      promotionIds: [promotionId],
      keyword: keyword,
      voucherStates: [],

      sorting: sorting,
      skipCount: (pageIndex-1)*pageSize,
      maxResultCount: pageSize
    }
    PromotionVoucherService.exportList(filterModel)
      .then(result => {
        result.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          _hiddenLink.current.href = url;
          _hiddenLink.current.download = "DanhSachvoucher";
          _hiddenLink.current.click();
        })
      }).catch(err => {
        if (err.error && err.error.messageText)
          notiRef.current.show(err.error.messageText, NotifyStatus.Warning);
        else notiRef.current.show(err, NotifyStatus.Warning);
      }).finally(() => {
          Actions.setLoading(false);
      })
  }
  //#region table info & event

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    onChange: (newSelectedRowKeys, selectionRows) => {
      // setSelectedRowKeys(selectedRowKeys);
      // setSelectedRows(selectedRows);
      // let yourArray = [...selectedRowKeys,...newSelectedRowKeys];
      // yourArray = Array.from(new Set(yourArray.map((item) => item)));
      // setSelectedRowKeys(yourArray);
    },
    onSelect: (record, selected, selectionRows, nativeEvent) => {
      let newSelecteds = selectedRowKeys.filter(x => x != record.id);
      let newSelectedRows = selectedRows.filter(x => x.id != record.id);
      if (selected) {
        newSelecteds.push(record.id);
        newSelectedRows.push(record);
      }
      setSelectedRowKeys(newSelecteds);
      setSelectedRows(newSelectedRows);
      return true;
    },
    onSelectAll: (selected, selectionRows, changeRows) => {
      let selectedRowIds = changeRows.map(c => c.id);
      let newSelecteds = selectedRowKeys.filter(x => selectedRowIds.indexOf(x) < 0);
      let newSelectedRows = selectedRows.filter(x => selectedRowIds.indexOf(x.id) < 0);
      if (selected) {
        newSelecteds = [...newSelecteds,...changeRows.map(x => x.id)];
        newSelecteds = Array.from(new Set(newSelecteds.map((item) => item)));
        newSelectedRows = [...newSelectedRows,...changeRows];
        newSelectedRows = Array.from(new Set(newSelectedRows.map((item) => item)));
      }
      setSelectedRowKeys(newSelecteds);
      setSelectedRows(newSelectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 40,
          render: (text, record, index) => {
              return <span>{ (pageIndex-1)*pageSize + (index + 1) }</span>;
          }
      },
      Table.SELECTION_COLUMN,
      {
          title: 'Mã voucher',
          dataIndex: 'voucherCode',
          key: 'voucherCode',
          align: 'left',
          width: 150,
          sorter: () => {},
      },
      {
          title: 'Khách hàng',
          dataIndex: 'patientCode',
          key: 'patientCode',
          align: 'left',
          // width: 250,
          render: (value, row) => {
              return <span>{(row.patientCode ?? '') + ' - ' + (row.patientName ?? '')}</span>
          }
      },
      ...(promotionType == 0 ? [] :
        [
          {
            title: 'Đăng ký dịch vụ', // promotionType == 0 là voucher.
            dataIndex: 'criteriaProductIds',
            key: 'criteriaProductIds',
            align: 'left',
            width: 250,
            render: (value, record) => {
                return <span>{record.criteriaProducts ? record.criteriaProducts.map(x => x.ten).join(', ') : ''}</span>
            }
          },
          {
              title: 'Khuyến mại dịch vụ', // promotionType == 0 là voucher
              dataIndex: 'discountProductIds',
              key: 'discountProductIds',
              align: 'left',
              width: 250,
              render: (value, record) => {
                return <span>{record.discountProducts ? record.discountProducts.map(x => x.ten).join(', ') : ''}</span>
              }
          },
        ]
      ),
      {
          title: 'Số lượng',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center',
          width: 100,
          render: (value) => {
              return (
                  <span>{value ? value : 'Không giới hạn'}</span>
              )          
          }
      },
      {
          title: 'Trạng thái',
          dataIndex: 'voucherStatus',
          key: 'voucherStatus',
          align: 'right',
          width: 92,
          render: (text, record, index) => {
              return <span>{ record.voucherStatusText }</span>;
          }
      },
      {
          title: 'Thao tác',
          dataIndex: 'options',
          key: 'id',
          align: 'center',
          width: 90,
          render: (value, record) => {
            return (
              <div>
                <Tooltip title={ "Xem danh sách sử dụng mã" }>
                  <Button type="link" shape="circle" style={{ minWidth: 24 }}
                    onClick={() => {  
                      setVoucherIdViewHistory(record.id);
                      setVisibleHistoryList(true);
                    }}
                  ><i className="fa fa-eye"></i></Button>
                </Tooltip>
                <Tooltip title={printTemplate === null && "Chọn mẫu in trước"}>
                  <Button type="link" shape="circle" style={{ minWidth: 24 }}
                    onClick={() => { printVouchers(promotionId, [record.id]) }}
                  ><i className="fa fa-print"></i></Button>
                </Tooltip>
                <Popconfirm
                  title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{record.voucherCode}</b>?</span>)}}
                  onConfirm={() => {deleteVouchers([record]);}}
                  onCancel={() => { }}
                  okText="Đồng ý"
                  cancelText="Hủy"
                ><Button type="link" shape="circle" style={{ minWidth: 24 }}><i className="fa fa-trash"></i></Button>
                </Popconfirm>
              </div>
            )
          }
      },
  ];

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };
  //#endregion 

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className="row">
        <div className='col-md-12'>
          <Notify ref={notiRef} />
        </div>
      </div>
      <div className="row">
          <div className='col-md-6 d-flex justify-content-start align-items-start'>
              <Button className="pl-0"
                hidden={detailScreenMode == detailScreenModeEnum.detail}
                type="link"
                  onClick={() => {
                    props.onCreateVoucherButtonClickHandle(true); 
                    if (props.promotionId) setVisibleCreate(true);
                  }}
              ><i style={{ marginRight: 5 }} className="fa fa-plus-circle" />Tạo mã</Button>
          </div>
      </div>    
      <div className="row"> {/** option for list voucher */}
        <div className="col-12"> {/** search by code or patient */}
          <Input value={keyword} 
            onChange={(e) => {setKeyword(e.target.value)}}
            placeholder='Nhập mã voucher, tên khách hàng'
            prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}>
          </Input>
        </div>
        <div className="col-12 mt-2 d-flex justify-content-between"> {/** chọn mẫu in, in, xóa, export excel */}
          <div className="d-flex justify-content-start">{/** chọn mẫu in, in, xóa */}
            <Select showSearch
              style={{ width: 250, marginRight: 5 }} 
              placeholder="Chọn mẫu in" 
              value={printTemplate} 
              onChange={(e) => setPrintTemplate(e)}  
              onSearch={(e) => onSearchMauIn(e, 1)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {(listPrintTemplate ?? []).map((mauin, index) => {
                  return (
                      <Select.Option key={index} value={mauin.id}>{mauin.templateName}</Select.Option>
                  )
              })}
            </Select>
            <Tooltip title={printTemplate === null && "Chọn mẫu in trước"}>
              <button className="btn btn-color ml-2"
                onClick={() => { printVouchers(promotionId, selectedRows.map(x => x.id)) }}
              ><i className="fas fa-print" style={{ marginRight: 5 }} />In mã</button>
            </Tooltip>
            <Popconfirm
              title={`Bạn chắc chắn muốn xóa các voucher được chọn?`}
              onConfirm={() => {deleteVouchers(selectedRows);}}
              onCancel={() => { }}
              okText="Đồng ý"
              cancelText="Hủy"
            >
              <button className="btn btn-color ml-2" type="button" 
              ><i style={{ marginRight: 5 }} className="fas fa-trash" />Xóa hạng mục</button>
            </Popconfirm>
          </div>
          <div>{/** export excel */}
            <button className="btn btn-color" type="button" onClick={() => {onExportClickHandle();}}
            ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
          </div>
        </div>
      </div>
      <div className="row">{/** */}
        <div className="col-12 mt-2"> 
          <Table 
            dataSource={listVoucher} 
            columns={columns}
            locale={{ emptyText: 'Không có dữ liệu' }} 
            scroll={{ x: 'max-content', y: 0 }}
            rowKey={(e) => e.id}
            rowSelection={rowSelection}
            // rowClassName={(record, rowIndex) => {
            //     return "rowCustom"
            // }}
            pagination={false}
            onChange={onTableChange} 
          />
          <Pagination
              current={pageIndex}
              size={pageSize}
              style={{ textAlignLast: "center", marginTop: "10px" }}
              total={totalRecord}
              showTotal={total => `Tổng: ${total}`}
              onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
              onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
              showSizeChanger={true}
          />
        </div>
      </div>

      <Modal maskClosable={false}
          width={1400}
          style={{minWidth: '600px'}}
          className="w-25"
          destroyOnClose={true}
          visible={visibleCreate}
          // destroyOnClose={true}
          // onCancel={handleCancel}
          // cancelButtonProps={{ style: { display: 'none' } }}
          // okButtonProps={{ style: { display: 'none' } }}
      >
        <VoucherCreate isDialog={ true } 
          // listPromotionType = { listPromotionType }
          promotionId={props.promotionId}
          promotionType={promotionType}
          onCancel={(isChangeData) => {onCloseDialogCreateHandle(isChangeData); setVisibleCreate(false); }}
        />
      </Modal>
      <Modal maskClosable={false}
        width={800}
        style={{minWidth: '600px'}}
        destroyOnClose={true}
        visible={visibleHistoryList}
      >
        <VoucherHistoryList isDialog={ true } 
          promotionId={props.promotionId}
          voucherId={voucherIdViewHistory}
          onCancel={() => { setVisibleHistoryList(false); }}
        />
      </Modal>
    </React.Fragment>
  );
})

// export default VoucherList;
