import { notification, Input, InputNumber, Tooltip } from 'antd';
import React, { Fragment, useState, useEffect, useCallback, forwardRef, useImperativeHandle, useRef } from "react";
import * as PatientService from '../../../services/patientService';
import Notify, { NotifyStatus } from '../../../components/notify';

export default forwardRef((props, ref) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    //biến cục bộ
    const [maql, setMaql] = useState('')
    const [mach, setMach] = useState('')
    const [nhietdo, setNhietdo] = useState('')
    const [chieucao, setChieucao] = useState('')
    const [cannang, setCannang] = useState('')
    const [huyetap, setHuyetap] = useState('')
    const [nhiptho, setNhiptho] = useState('')
    const [detail, setDetail] = useState(null);
    const [isEdit,] = useState(props.isEdit ?? false);
    const [patientCode, setPatientCode] = useState();

    useImperativeHandle(ref, () => ({
        Reload: () => {
            GetVitalSign();
        },
        getInfo: () => {
            const data = {
                chieuCao: chieucao ? chieucao.toString() : '',
                canNang: cannang ? cannang.toString() : '',
                mach: mach ? mach.toString() : '',
                nhietDo: nhietdo ? nhietdo.toString() : '',
                huyetAp: huyetap,
                nhipTho: nhiptho ? nhiptho.toString() : '',
                maQL: maql,
                maBN: patientCode,
            }
            return data;
        },
        clearData: () => {
            resetState();
        },
        hideNotify: () => {
            notify && notify.current && notify.current.Close();
        },
        handleSave: () => {
            insertUpdateVitalSigns();
        }
    }));

    useEffect(() => {
        setMaql(props.maql);
        props.maBN && setPatientCode(props.maBN);
    }, [props.maql])

    useEffect(() => {
        if (maql !== '')
            GetVitalSign();
    }, [maql])

    const notify = useRef()

    const GetVitalSign = () => {
        PatientService.GetVitalSigns(maql).then(result => {
            if (result.isSuccess) {
                setDetail(result.data);
                setMach(result.data?.mach);
                setNhietdo(result.data?.nhietDo);
                setChieucao(result.data?.chieuCao);
                setCannang(result.data?.canNang);
                setNhiptho(result.data?.nhipTho);
                setHuyetap(result.data?.huyetAp);
                forceUpdate()
            } else {
                notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        })
    }

    const BMIWarning = (canNang, chieuCao, gender) => {
        let BMIValue = Number((canNang / (Math.pow(chieuCao / 100, 2))).toFixed(2));
        let color = "green";
        let detail = "Bình thường";
        if (BMIValue < 18.5) {
            color = "blue"; // "underWeight";
            detail = "Thiếu cân";
        }
        else if (18.5 <= BMIValue && BMIValue <= 24.9) {
            color = "green"; //"normal";
            detail = "Bình thường";
        }
        else if (25 <= BMIValue && BMIValue <= 29.9) {
            color = "lime"; // "overWeight";
            detail = "Thừa cân";
        }
        else if (30 <= BMIValue && BMIValue <= 34.9) {
            color = "orange"; // "obese";
            detail = "Béo phì";
        }
        else if (BMIValue > 35) {
            color = "red"; // "extremlyObese";
            detail = "Béo phì mức cao"
        }
        return <Tooltip title={detail} placement='right'><span style={{ color: "#fff", backgroundColor: color, padding: 5, borderRadius: 8, }}>{BMIValue}</span></Tooltip>
    }

    const insertUpdateVitalSigns = () => {
        if (mach === undefined
            && nhietdo === undefined
            && huyetap === undefined
            && cannang === undefined
            && chieucao === undefined
            && nhiptho === undefined) {
            return;
        }
        let data = {
            'chieuCao': chieucao ? chieucao.toString() : '',
            'canNang': cannang ? cannang.toString() : '',
            'mach': mach ? mach.toString() : '',
            'nhietDo': nhietdo ? nhietdo.toString() : '',
            'huyetAp': huyetap,
            'nhipTho': nhiptho ? nhiptho.toString() : '',
            'maQL': maql,
            'maBN': patientCode
        }
        PatientService.InsertUpdateVitalSigns(data).then(result => {
            if (result.isSuccess) {
                notify && notify.current.Show("Thao tác thành công.", NotifyStatus.Success);
            } else {
                notify && notify.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            notify && notify.current.Show(err.error.messageText, NotifyStatus.Error);
        })
    }

    const resetState = () => {
        setDetail([]);
        setMach();
        setNhietdo();
        setChieucao();
        setCannang();
        setNhiptho();
        setHuyetap();
        forceUpdate();
    }

    const heightRef = useRef();
    const weightRef = useRef();
    const pressureRef = useRef();
    const pulseRef = useRef();
    const tempratureRef = useRef();

    const handleRefocus = (focusOn) => {
        switch (focusOn) {
            case 1:
                heightRef.current.focus();
                heightRef.current.select();
                break;
            case 2:
                weightRef.current.focus();
                weightRef.current.select();
                break;
            case 3:
                pressureRef.current.focus();
                pressureRef.current.select();
                break;
            case 4:
                pulseRef.current.focus();
                pulseRef.current.select();
                break;
            case 5:
                tempratureRef.current.focus();
                tempratureRef.current.select();
                break;
            default:
                heightRef.current.focus();
                heightRef.current.select();
        }
    }

    return (
        <Fragment>
            {
                isEdit ?
                    <Fragment>
                        <Notify ref={notify} />
                        <div className="row mt-2">
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">Chiều cao</label>
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Bấm Enter để chuyển sang ô nhập tiếp theo" placement='bottom' trigger="focus">
                                        <InputNumber style={{ width: "calc(100% - 65px)" }} value={chieucao}
                                            min={0} maxLength={5}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            ref={heightRef}
                                            onPressEnter={() => handleRefocus(2)}
                                            onChange={(e) => setChieucao(e)}></InputNumber>
                                    </Tooltip>
                                    <span style={{ width: "65px" }} className='ml-1'>cm</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">Cân Nặng</label>
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Bấm Enter để chuyển sang ô nhập tiếp theo" placement='bottom' trigger="focus">
                                        <InputNumber style={{ width: "calc(100% - 65px)" }} value={cannang}
                                            min={0} maxLength={5}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            ref={weightRef}
                                            onPressEnter={() => handleRefocus(3)}
                                            onChange={(e) => setCannang(e)}></InputNumber>
                                    </Tooltip>
                                    <span style={{ width: "65px" }} className='ml-1'>kg</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">BMI</label>
                                <div className="mita-value">{cannang && chieucao && BMIWarning(cannang, chieucao)}</div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">Huyết áp</label>
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Bấm Enter để chuyển sang ô nhập tiếp theo" placement='bottom' trigger="focus">
                                        <Input style={{ width: "calc(100% - 65px)" }} value={huyetap}
                                            maxLength={8}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            ref={pressureRef}
                                            onPressEnter={() => handleRefocus(4)}
                                            onChange={(e) => {
                                                setHuyetap(e.target.value.toString().replace(/[a-zA-Z~!@#$%^&*()_+*.{}|;:,?'"\-\\=]/g, ""))
                                            }}
                                        ></Input>
                                    </Tooltip>
                                    <span style={{ width: "65px" }} className='ml-1'>mmHg</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">Mạch</label>
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Bấm Enter để chuyển sang ô nhập tiếp theo" placement='bottom' trigger="focus">
                                        <InputNumber style={{ width: "calc(100% - 65px)" }} value={mach}
                                            min={40} maxLength={5} max={250}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            ref={pulseRef}
                                            onPressEnter={() => handleRefocus(5)}
                                            onChange={(e) => setMach(e)}></InputNumber>
                                    </Tooltip>
                                    <span style={{ width: "65px" }} className='ml-1'>lần / phút</span>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 d-flex flex-column">
                                <label className="mita-title">Nhiệt độ</label>
                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                    <Tooltip title="Bấm Enter để chuyển sang ô nhập đầu tiên" placement='bottom' trigger="focus">
                                        <InputNumber style={{ width: "calc(100% - 65px)" }} value={nhietdo}
                                            min={30} maxLength={5} max={42}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            ref={tempratureRef}
                                            onPressEnter={() => handleRefocus(1)}
                                            onChange={(e) => setNhietdo(e)}></InputNumber>
                                    </Tooltip>
                                    <span style={{ width: "65px" }} className='ml-1'>độ</span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    detail ?
                        <div className={`col-${props.col ?? 12}`}>
                            <div className="row">
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">Chiều cao</label>
                                    <label className="mita-value">{chieucao} cm</label>
                                </div>
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">Cân Nặng</label>
                                    <label className="mita-value">{cannang} kg</label>
                                </div>
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">BMI</label>
                                    <label className="mita-value">{cannang && chieucao && Number(cannang / (Math.pow(chieucao / 100, 2))).toFixed(2)}</label>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">Huyết áp</label>
                                    <label className="mita-value">{huyetap} mmHg</label>
                                </div>
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">Mạch</label>
                                    <label className="mita-value">{mach} lần/phút</label>
                                </div>
                                <div className="col-4 d-flex flex-column">
                                    <label className="mita-title">Nhiệt độ</label>
                                    <label className="mita-value">{nhietdo}℃</label>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='col-12 d-flex justify-content-center'>
                            <p className='text-body'>Không có thông tin</p>
                        </div>
            }
        </Fragment>
    )
});
