import React, { Fragment, useRef, useState, useEffect } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import Notify, { NotifyStatus } from '../../../components/notify';
import { Button, DatePicker, Select } from 'antd';
import * as SystemConfig from "../../../configure/systemConfig";
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import { connect } from 'react-redux';
import * as CorporationServices from '../../../services/CorporationServices';
import * as AuthServices from '../../../services/authServices';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { BUTTON, NHOMXETNGHIEM } from '../../../configureHIS/common/constants';
import ColumnChart from "./chart";
import ListData from "./listData";
import SiteFilter from '../../../configureHIS/common/siteFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;

function ThongKeOngMauXetNghiem(props) {
    const notiRef = useRef();
    const chartRef = useRef();
    const listRef = useRef();
    const [site, setSite] = useState([props.siteid]);
    const [availableSites, setAvailableSites] = useState([]);
    const [listCoporators, setListCoporators] = useState([]);
    const [coporator, setCoporator] = useState(null);
    const [dateRange, setDateRange] = useState([moment().startOf("week"), moment()]);
    const [testType, setTestType] = useState([]);
    const [listTestType, setListTestType] = useState([]);
    const [isChart, setIsChart] = useState(true);
    useEffect(() => {
        getCoporation();
        getAvaiableSites();
        getListTestType();
    }, []);
    const getCoporation = (key = "") => {
        CorporationServices.GetList(key, 1, 40).then(res => {
            if (res.isSuccess) {
                setListCoporators(res.data);
            }
        })
    }
    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };
    const getListTestType = () => {
        VPService.GetLoaiVPByNhom(NHOMXETNGHIEM).then(result => {
            if (result.isSuccess) {
                setListTestType(result.data);
            }
        }).catch(err => {
        })
    }
    const loadDataComponent = () => {
        if (isChart) {
            chartRef && chartRef.current && chartRef.current.getList();
        } else {
            listRef && listRef.current && listRef.current.getList();
        }
    }
    const generateListType = () => {
        let listTypes = [];
        testType.forEach(type => {
            let found = listTestType.find(x => Number(x.value) === Number(type));
            if (found !== undefined)
                listTypes.push({ value: type, label: found.label })
        });
        return listTypes;
    }
    const exportStatistic = () => {
        listRef && listRef.current && listRef.current.export();
    }
    return (
        <Fragment>
            <div className="containerChilderWrapper" id="thongkeongmauxetnghiem">
                <HeaderModal title="Thống kê ống mẫu xét nghiệm" />
                <Notify className="mt-2" ref={notiRef} />
                <div className="row">
                    <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                    <div className="col-4 mt-2">
                        <label>Nguồn khách hàng</label>
                        <Select
                            id="vsta-slt-copor"
                            value={coporator}
                            placeholder="Chọn nguồn khách hàng"
                            className="w-100"
                            onChange={(e) => {
                                setCoporator(e)
                            }}
                            showSearch
                            allowClear
                            onSearch={(value) => getCoporation(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {listCoporators && listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                        </Select>
                    </div>
                    <div className="col-4 mt-2">
                        <label>Ngày xác nhận</label>
                        <RangePicker
                            id="vsta-rpk-gdte"
                            className="w-100"
                            locale={viVN}
                            placeholder="Chọn ngày xác nhận"
                            format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]}
                            value={dateRange}
                            onChange={(date) => {
                                setDateRange(date);
                            }} />
                    </div>
                    <div className="col-4 mt-2">
                        <label>Loại xét nghiệm</label>
                        <Select
                            id="vsta-slt-ttpy"
                            className="w-100"
                            mode="multiple"
                            allowClear
                            placeholder="Chọn loại xét nghiệm"
                            value={testType}
                            maxTagCount={testType && testType.length >= 3 && 3}
                            maxTagTextLength={testType && testType.length >= 3 && 10}
                            onChange={(type) => {
                                setTestType(type);
                            }}>
                            {listTestType && listTestType.map((type, index) => <Option key={index} value={type.value}>{type.label}</Option>)}
                        </Select>
                    </div>
                </div>
                <div className="row">
                    <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                        <span>Hiển thị</span>
                        <div className="col-auto">
                            <Select
                                id="vsta-slt-dpltpe"
                                style={{ width: 200 }}
                                value={isChart}
                                onChange={(value) => {
                                    setIsChart(value);
                                }}>
                                <Option key={1} value={true}><i className="fas fa-chart-bar mr-1" />Biểu đồ</Option>
                                <Option key={2} value={false}><i className="fas fa-list mr-1" />Danh sách</Option>
                            </Select>
                            {!isChart && <Button className={`${BUTTON.THEME} ml-2`} onClick={() => { exportStatistic(); }}><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</Button>}
                            <Button className={`${BUTTON.THEME} ml-2`} onClick={() => { loadDataComponent() }}><i className="fas fa-chart-bar mr-1" />Thống kê</Button>
                        </div>
                    </div>
                </div>
                <div id={`vsta-dpl-${isChart ? "chart" : "list"}`} className="mt-2" style={{ height: isChart ? 'calc(100% - 300px)' : '63%' }}>
                    {isChart ?
                        <ColumnChart
                            ref={chartRef}
                            dateRange={dateRange}
                            listTypes={testType.length > 0 ? generateListType() : listTestType}
                            coporator={coporator}
                            site={site} />
                        :
                        <ListData
                            ref={listRef}
                            dateRange={dateRange}
                            listTypes={testType.length > 0 ? generateListType() : listTestType}
                            coporator={coporator}
                            site={site} />
                    }
                </div>
            </div>
        </Fragment>
    );
}
const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeOngMauXetNghiem);