import * as Configure from '../configure/urlConfig';
import * as Action from './actions';
import {store} from '../stores'
import { EncodeURI, isnotnull } from './util';

export function httpGet(path, useToken = true) {

    return callApi(path, 'GET', null, useToken, false);
}

export function httpGetDownload(path, filetype, useToken = true) {
    return Download(path);
}

export function httpPostDownload(path, body, filetype, useToken = true) {
    return Download(path, 'POST', body);
}

export function httpPost(path, body, useToken = true) {
    return callApi(path, 'POST', body, useToken, false);
}

export function httpPut(path, body, useToken = true) {
    return callApi(path, 'PUT', body, useToken, false);
}

export function httpDelete(path, useToken = true) {
    return callApi(path, 'DELETE', null, useToken, false);
}
export function httpPostFormData(path, body, useToken = true, noloading = false) {
    return callApi(path, 'POST', body, useToken, false, true, noloading);
}
export function httpPostWithFile(path, body, useToken = true) {
    return callApi(path, 'POST', body, useToken, false, true, false, true);
}
export function httpPutFormData(path, body, useToken = true) {
    return callApi(path, 'PUT', body, useToken, false, true);
}

function callApi(path, method, body = null, useToken = true, https = false, isFormData = false, noloading = false, file = false) {
    let url = Configure.BASE_URL + path;

    const requestHeaders = new Headers();

    requestHeaders.set('Content-Type', 'application/json');
    let site = '';
    if(store.getState() && store.getState().global ){
        site = store.getState().global.siteid
    }
    requestHeaders.set('RequestSite', site);


    if (useToken) {
        let token = '';
        if (useToken) {
            token = localStorage.getItem('token') || '';
        }
        requestHeaders.set('Authorization', 'bearer ' + token);
    }

    let requestInfo;

    switch (method) {
        case 'POST':
        case 'PUT':
            if (noloading === false) {
            }
            if (isFormData === false) {
                requestInfo = { method: method, headers: requestHeaders, body: JSON.stringify(body) };
            } else {
                requestHeaders.delete('Content-Type');
                requestInfo = { method: method, headers: requestHeaders, body: body };
            }
            url = EncodeURI(url);
            break;
        case 'DELETE':
        case 'GET':
            requestInfo = { method: method, headers: requestHeaders };
            url = EncodeURI(url);
            break;
        default:
            break;
    }

    return new Promise((resolve, reject) => {
        fetch(url, requestInfo)
            .then(response => {
                if (response.status !== 999) {
                    if (response.status !== 200) {
                        if (response.status === 401) {
                            //   Action.openMessageDialog("Lỗi phân quyền", "Vui lòng đăng nhập account đúng phân quyền")
                            localStorage.setItem('token', '');
                            localStorage.setItem('name', '');
                            Action.logout();
                            return reject("Vui lòng đăng nhập account đúng phân quyền")
                        }
                        else if (response.status === 403) {
                            Action.openMessageDialog("Lỗi phân quyền", "Tài khoản của bạn không thể thực hiện thao tác này!")
                            return;
                        }
                        return response.json().then((obj) => {
                            return reject(getError(obj));
                        })
                    }
                    return resolve(response.json());
                }
                else {
                    // Action.openMessageDialog("Thông báo", `<div style="flex:1"><p style="text-align: center;color: #000;">Thời gian dùng thử phần mềm của quý khách đã hết. </p>
                    // <p style="text-align: center;color: #000;">Vui lòng liên hệ bộ phận bán hàng để được hỗ trợ!</p>
                    // <p style="text-align: center;color: #000;">Hotline: <tel>0945 777 111</tel></p></div>`)
                    Action.openMessageDialog("Thông báo",
                    `<div style="flex:1"><h4 style="text-align: center;color: #000;">Thời gian sử dụng phần mềm của quý khách đã kết thúc.</h4>
                    <h4 style="text-align: center;color: #000;">Vui lòng liên hệ bộ phận bán hàng để được hỗ trợ</h4>
                    ${Configure.HIS_HOTLINE ?? ""}</div>`)
                    return;
                }
            })
            .catch(ex => {
                if (ex.toString() === `TypeError: Failed to fetch`) {
                    Action.openMessageDialog("Lỗi kết nối mạng", "Hiện tại không thể kết nối đến server vui lòng liên hệ IT")
                    return;
                }
                return reject(ex);
            })
    });
}
function getError(obj) {
    if (obj.hasOwnProperty('isSuccess') && obj.hasOwnProperty('data') && obj.hasOwnProperty('error'))
        return obj;
    let ret = ""
    for (let record in obj.errors) {
        ret += obj.errors[record] + '@@@@@'
    }

    return ret
}


function Download(path, method='GET', body='') {
    let url = Configure.BASE_URL + path;
    const requestHeaders = new Headers();
    let site = '';
    if(store.getState() && store.getState().global ){
        site = store.getState().global.siteid
    }
    requestHeaders.set('RequestSite', site);
    // requestHeaders.set('Content-Type', "text/plain");
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Content-Disposition', 'attachment');
    let token = '';
    token = localStorage.getItem('token') || '';
    requestHeaders.set('Authorization', 'bearer ' + token);
    let requestInfo;
    if (method == 'POST')
        requestInfo = { method: method, headers: requestHeaders, body: JSON.stringify(body) };
    else requestInfo = { method: method, headers: requestHeaders };
    url = EncodeURI(url);

    return new Promise((resolve, reject) => {
        fetch(url, requestInfo)
            .then(response => {
                if (response.status !== 999) {
                    if (response.status !== 200) {
                        if (response.status === 401) {
                            Action.openMessageDialog("Lỗi phân quyền", "Vui lòng đăng nhập tài đúng phân quyền")
                            localStorage.setItem('token', '');
                            localStorage.setItem('name', '');
                            Action.logout();
                            return reject("Vui lòng đăng nhập tài đúng phân quyền")
                        } else {
                            return response.json().then((obj) => {
                                return reject(getError(obj));
                            })
                        }
                    }
                    return resolve(response);
                }
                else {
                    // Action.openMessageDialog("Thông báo", `<div style="flex:1"><p style="text-align: center;color: #000;">Thời gian dùng thử phần mềm của quý khách đã hết. </p>
                    // <p style="text-align: center;color: #000;">Vui lòng liên hệ bộ phận bán hàng để được hỗ trợ!</p>
                    // <p style="text-align: center;color: #000;">Hotline: <tel>0945 777 111</tel></p></div>`)
                    Action.openMessageDialog("Thông báo",
                    `<div style="flex:1"><h4 style="text-align: center;color: #000;">Thời gian sử dụng phần mềm của quý khách đã kết thúc.</h4>
                    <h4 style="text-align: center;color: #000;">Vui lòng liên hệ bộ phận bán hàng để được hỗ trợ</h4>
                    ${Configure.HIS_HOTLINE ?? ""}</div>`)
                    return;
                }
            })
            .catch(ex => {
                if (ex.toString() === `TypeError: Failed to fetch`) {
                    Action.openMessageDialog("Lỗi kết nối mạng", "Hiện tại không thể kết nối đến server vui lòng liên hệ IT")
                    return;
                }
                return reject(ex);
            })
    });
}
