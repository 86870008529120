import { DatePicker, Form, notification, Table, Tabs, Pagination, Select, Button, Alert } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as PrintService from '../../../../services/printService';
import TheoDichVuBN from './theodichvuBN';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import * as SystemConfig from "../../../../configure/systemConfig";
import * as CorporationService from '../../../corporation/CorporationServices';
import * as AuthServices from '../../../../services/authServices';
import { connect } from 'react-redux';
import SiteFilter from '../../../../configureHIS/common/siteFilter';
import { PAYMENTTYPE } from '../../../../configureHIS/common/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const TabPane = Tabs.TabPane;

class theobenhnhan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ngay: [moment(moment().format('YYYY-MM-DD 00:00')), moment()],
            dataSource: [],
            tongthucthu: 0,
            listSite: [],
            chinhanh: this.props.availableSites,
            isMultisite: false,
            paymentType: 0,
            lstLoaiTT: [],
            listCoporators: [],
            coporator: "",
            site: this.props.siteid,
            curTab: 1,
            hasMultiPaymentType: false,
        };
    }

    componentDidMount() {
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                this.setState({
                    isMultisite: result.item
                });
            }
        });
        //this.GetListSite();
        this.getPaymentMethod();
        this.LoadThongKeDoanhSo();
        this.loadCoporation();
        this.getAvaiabeSites();
        this.getTongDoanhThu();
    }

    getAvaiabeSites = () => {
        AuthServices.getAvailableSites().then((result) => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            }
        })
    }

    LoadThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ThongKeDoanhSoTheoBN(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.paymentType, this.state.coporator, this.state.chinhanh).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                });
                let multiplePaymentType = result.data.find(x => x.paymenttype?.includes(", ")) != undefined;
                this.setState({ hasMultiPaymentType: multiplePaymentType }, () => this.forceUpdate());
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }

    getTongDoanhThu() {
        StatisticService.GetTongDoanhThu(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.coporator, this.state.chinhanh, this.state.paymentType)
        .then(result => {
            if (result.isSuccess) {
                this.setState({
                    tongthucthu: result.item,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu tổng doanh thu bị lỗi' })
        })
    }

    ExportThongKeDoanhSo() {
        Actions.setLoading(true);
        StatisticService.ExportDoanhSoTheoBN(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.paymentType, this.state.coporator, this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDoanhSoTongHopTheoBenhNhan";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    PrintTongHopNgay() {
        PrintService.PrintTongHopNgay(this.state.ngay[0].format('YYYY-MM-DD HH:mm'), this.state.ngay[1].format('YYYY-MM-DD HH:mm'), this.state.paymentType, this.state.coporator, this.state.chinhanh).then((result) => {
            Actions.DoPrint(result.item)
        })
    }
    ExportMISA() {
        Actions.setLoading(true);
        StatisticService.ExportMISA(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'), this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeMISA";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    getPaymentMethod() {
        const getAll = true;
        VPService.GetDanhSachLoaiThanhToan(getAll).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiTT: result.data,
                })
            }
        }).catch(err => {
        })
    }

    loadCoporation(key = "", page = 1, size = 10) {
        CorporationService.GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporators: res.data
                })
            }
        })
    }

    LoadThongKeDoanhSoByTab() {
        if (this.state.curTab == 2 && this.PatientReport) this.PatientReport.LoadThongKeDoanhSo();
        else {
            this.getTongDoanhThu();
            this.LoadThongKeDoanhSo();
        }
    }

    render() {
        const columns = [
            {
                width: 50,
                title: '#',
                align: "center",
                render: (text, record, index) => (this.state.dataSource.indexOf(record) + 1),
            },
            {
                title: 'Mã khách hàng',
                dataIndex: 'mabn',
                key: 'mabn',
                align: "center",
                width: 110,
            },
            {
                title: 'Tên khách hàng',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width: 250,
            },
            {
                title: 'Nguồn khách hàng',
                dataIndex: 'coporator',
                key: 'coporator',
                width: 170,
                render: (copr) => {
                    return <label style={{ wordBreak: "break-word" }}>{copr}</label>
                }
            },
            {
                title: 'Năm sinh',
                dataIndex: 'namsinh',
                key: 'namsinh',
                width: 80,
                align: "center",
            },
            {
                title: 'Giới tính',
                dataIndex: 'gioitinh',
                key: 'gioitinh',
                width: 80,
                align: "center",
            },
            {
                title: 'Ngày lập phiếu',
                dataIndex: 'ngay',
                key: 'ngay',
                width: 110,
                align: "center",
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>;
                },
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'tongtiendau',
                key: 'tongtiendau',
                align: 'right',
                width: 100,
                render: (text, record, index) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Giảm khác',
                width: 100,
                align: 'right',
                render: (text, record, index) => {
                    return <span>{(record.tongtiendau - record.tongtien - record.tongtienbhyt).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Hoàn trả phí',
                dataIndex: 'totalRefund',
                key: 'totalRefund',
                width: 100,
                align: 'right',
                render: (refund) => {
                    return <span>{refund && refund.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Thực thu',
                key: 'thucthu',
                width: 100,
                align: 'right',
                render: (text, record, index) => {
                    return <span>{(record.tongtien + record.tongtienbhyt - record.totalRefund).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Hình thức thanh toán',
                dataIndex: 'paymenttype',
                key: 'paymenttype',
                align: 'center',
                width: 150,
                render: (type, record) => {
                    let foundHinhThucCongNo = this.state.lstLoaiTT && this.state.lstLoaiTT.find(x => Number(x.ma) === Number(PAYMENTTYPE.DEBT));
                    let tenHinhThucCongNo = foundHinhThucCongNo ? foundHinhThucCongNo.ten : "Công nợ";
                    if (type && type.includes(tenHinhThucCongNo) && record.congnodathu !== null && record.congnodathu !== "") {
                        return {
                            children: <span>
                                {type.replace(tenHinhThucCongNo, `${tenHinhThucCongNo} (Đã thu)`)}
                            </span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    } else {
                        return {
                            children: <span>
                                {type}
                            </span>,
                            props: {
                                colSpan: 1,
                            }
                        }
                    }
                },
            },
            // {
            //     title: 'Thực thu',
            //     dataIndex: 'tongtien',
            //     key: 'tongtien',
            //     align: 'right',
            //     width: 110,
            //     render: (text, record, index) => {
            //         return <span>{text.toLocaleString('en-US')}</span>;
            //     },
            // },
        ]

        // const DatetimePicker = (
        //     <>
        //     <div style={{ height: '100%', overflow: 'auto' }}>
        //         <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
        //             <Form.Item>
        //                 <Select
        //                 showSearch
        //                 style={{width: '150px'}}
        //                 value = {this.state.chinhanh}
        //                 onChange={(value => this.setState( {site : value}))}
        //             >
        //                 {this.state.listSite && this.state.listSite.map((item, index) => {
        //                     return (
        //                         <option key={index} value={item.id}>{item.ma}</option>
        //                     )
        //                 })}
        //             </Select>
        //             </Form.Item>
        //             <Form.Item>
        //                 <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
        //                     showTime={{ format: 'HH:mm'}}
        //                     allowClear={false} format="DD-MM-YYYY HH:mm " value={this.state.ngay}
        //                     placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
        //                     getPopupContainer={trigger => trigger.parentNode}
        //                     onChange={(value => this.setState({ ngay: value }))}

        //                 />
        //             </Form.Item>
        //             <Form.Item>
        //                 <button className="btn btn-color" onClick={() => { 
        //                     this.LoadThongKeDoanhSo()
        //                     if(this.PatientReport != null){
        //                         this.PatientReport.LoadThongKeDoanhSo()
        //                     }
        //                 }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
        //             </Form.Item>
        //         </div>
        //     </div>
        //     </>
        // )

        return (
            <>
                <div className='containerChilderWrapper'>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    <div className='pb-1'>
                        <div className="row no-gutters mb-2">
                            <SiteFilter updateSeletedSite={(listSite) => { this.setState({ chinhanh: listSite }) }} />
                        </div>
                        <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item>
                                <Select
                                    style={{ width: '220px' }}
                                    value={this.state.paymentType}
                                    onChange={(value => this.setState({ paymentType: value }))}
                                >
                                    <Option key="all" value={0}>Tất cả hình thức thanh toán</Option>
                                    {this.state.lstLoaiTT && this.state.lstLoaiTT.map((item, index) => {
                                        return (
                                            <option key={index} value={item.ma}>{item.ten}</option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Select
                                    showSearch
                                    style={{ width: '220px' }}
                                    value={this.state.coporator}
                                    onChange={(value => this.setState({ coporator: value }))}
                                    onSearch={(value) => this.loadCoporation(value)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key="all" value={""}>Tất cả nguồn khách hàng</Option>
                                    {this.state.listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                    showTime={{ format: 'HH:mm' }}
                                    allowClear={false} format={SystemConfig.FORMAT_DATE_TIME} value={this.state.ngay}
                                    placeholder={['DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm']}
                                    placement="bottomRight"
                                    onChange={(value => this.setState({ ngay: value }))}

                                />
                            </Form.Item>
                            <Form.Item>
                                <button className="btn btn-color" onClick={() => {
                                    this.LoadThongKeDoanhSoByTab();
                                }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                            </Form.Item>
                        </div>
                    </div>
                    <Tabs animated={true}
                        style={{ height: "86%" }}
                        onChange={(tab) => { this.setState({ curTab: tab })}}>
                        <Tabs.TabPane tab={"Theo bệnh nhân"} key="1">
                            <div style={{ height: '100%', overflow: 'auto' }}>
                                <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
                                    {/* {this.state.isMultisite === true &&
                                        <div><Form.Item>
                                            <Select
                                                style={{ width: '200px' }}
                                                showSearch
                                                allowClear={false}
                                                value={this.state.chinhanh}
                                                onChange={(e) => {
                                                    this.setState({ chinhanh: e })
                                                }}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option key={-1} value={''}>Chi nhánh</Option>
                                                {this.state.listSite.map((row, index) => {
                                                    return (
                                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                                    )
                                                })}
                                            </Select></Form.Item>
                                        </div>
                                    } */}
                                    <Form.Item>
                                        <button className="btn btn-outline-secondary" onClick={() => { this.PrintTongHopNgay() }}><i style={{ marginRight: 5 }} className="fa fa-print" />In</button>
                                    </Form.Item>
                                    <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportThongKeDoanhSo() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                    </Form.Item>
                                    {/* <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportMISA() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất dữ liệu MISA</button>
                                    </Form.Item> */}
                                    <div style={{ marginLeft: '5px' }}>Tổng tiền: <strong>{this.state.tongthucthu.toLocaleString('en-US')}</strong></div>
                                </div>
                                {this.state.hasMultiPaymentType && <div className='w-100 mb-1'>
                                    <Alert message="Có sự tồn tại nhiều hình thức thanh toán" type='warning' />
                                </div>}
                                <div style={{ height: 'calc(100% - 125px)' }}>
                                    <Table
                                        scroll={{ x: 'max-content', y: 0 }}
                                        pagination={{
                                            position: ['bottomCenter'],
                                            showSizeChanger: true,
                                            style: { textAlignLast: "center", marginTop: 30 },
                                            total: this.state.totalRecords,
                                            showTotal: (total) => (`Tổng: ${total}`)
                                        }}
                                        rowClassName={(record) => {
                                            if (record.refunded)
                                                return "bg-danger text-white";
                                        }}
                                        dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                                </div>

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Chi tiết dịch vụ"} key="2">
                            <TheoDichVuBN ref={(c) => { this.PatientReport = c }} ngay={this.state.ngay} paymenttype={this.state.paymentType} hinhthuc={this.state.lstLoaiTT} coporator={this.state.coporator} chinhanh={this.state.chinhanh} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        availableSites: state.global.availableSites,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(theobenhnhan);
