import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { Pagination, Table, Select, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../../configureHIS/common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import * as SendMessageService from '../../../services/ConfigMessageTemplateService';
import { setLoading } from '../../../libs/actions';
import ViewCreateUpdate from './viewCreateUpdate';

let task = null;

const ConfigMessageTemplate = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const _notify = useRef();
    const [keyword, setKeyword] = useState();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [sendMessageType, setSendMessageType] = useState();
    const [listSendMessageType, setListSendMessageType] = useState([]);
    const [sendMessageTemplateType, setSendMessageTemplateType] = useState();
    const [listSendMessageTemplateType, setListSendMessageTemplateType] = useState([]);
    const [selected, setSelected] = useState([]);
    const [mode, setMode] = useState("create");
    const [visibleModal, setVisibleModal] = useState(false);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Template ID',
            dataIndex: 'tempId',
            key: 'tempId',
            align: 'left',
            width: 100,
        },
        {
            title: 'Tên mẫu',
            dataIndex: 'tempName',
            key: 'tempName',
            align: 'left',
            width: 130,
        },
        {
            title: 'Loại tin nhắn',
            dataIndex: 'sendMessageType',
            key: 'sendMessageType',
            align: 'left',
            width: 120,
            render: (val, record) => {
                return <div>{listSendMessageType.find(x => x.Key == val)?.Value}</div>
            }
        },
        {
            title: 'Mẫu dành cho',
            dataIndex: 'sendMessageTemplateType',
            key: 'sendMessageTemplateType',
            align: 'left',
            width: 130,
            render: (val, record) => {
                return <div>{listSendMessageTemplateType.find(x => x.Key == val)?.Value}</div>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'tempContent',
            key: 'tempContent',
            align: 'left',
            width: 250,
            render: (content, record) => {
                return (
                    <div dangerouslySetInnerHTML={{ __html: `${content}` }}></div>
                )
            }
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (content, record) => {
                return (
                    <div>
                        <button className={`${BUTTON.THEME}`} onClick={(e) => { e.stopPropagation(); HandleView(record); }}><i className='fas fa-eye' /></button>
                        <button className={`${BUTTON.WARNING} ml-1`} onClick={(e) => { e.stopPropagation(); HandleUpdate(record); }}><i className='fas fa-pen' /></button>
                    </div>
                )
            }
        },
    ];

    useEffect(() => {
        GetMessageType();
        GetTemplateMessageType();
    }, []);

    useEffect(() => {
        HandleSearch();
    }, [page, size]);

    const GetMessageType = () => {
        SendMessageService.GetListSendMessageType().then(res => {
            if (res.isSuccess) {
                setListSendMessageType(res.data);
            }
        }).catch(err => {

        }).finally();
    }

    const GetTemplateMessageType = () => {
        SendMessageService.GetListSendMessageTemplateType().then(res => {
            if (res.isSuccess) {
                setListSendMessageTemplateType(res.data);
            }
        }).catch(err => {

        }).finally();
    }

    const EnterToSearch = (key) => {
        if (task) clearTimeout(task);
        task = setTimeout(() => {
            HandleBeforeSearch(key);
        }, 500)
    }

    const HandleSearch = (key = keyword) => {
        _notify && _notify.current && _notify.current.Close();
        let data = {
            keyword,
            sendMessageType,
            sendMessageTemplateType,
            skipCount: (page - 1) * size,
            maxResultCount: size,
        };
        setLoading(true);
        SendMessageService.GetListSendMessageTemplate(data).then(res => {
            if (res.isSuccess) {
                setListData(res.data.items);
                setTotalRecords(res.data.totalCount);
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText ?? "Đã xảy ra lỗi danh sách template", NotifyStatus.Error);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.messageText ?? "Đã xảy ra lỗi danh sách template", NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const HandleCreate = () => {
        setMode("create");
        setVisibleModal(true);
    }

    const HandleUpdate = (record) => {
        setSelected(record);
        setMode("update");
        setVisibleModal(true);
    }

    const HandleView = (content) => {
        setSelected(content);
        setMode("view");
        setVisibleModal(true);
    }

    const onCloseModal = () => {
        setSelected([]);
        setVisibleModal(false);
        setMode("create");
        HandleBeforeSearch();
    }

    const HandleBeforeSearch = (key = keyword) => {
        if (page != 1) setPage(1);
        else HandleSearch(key);
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Cấu hình gửi tin nhắn" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className="row mt-2">
                        <div className='col-md-3 d-flex flex-column'>
                            <div><label>Loại tin nhắn</label></div>
                            <Select
                                allowClear
                                showSearch
                                className="w-100"
                                placeholder="Chọn loại tin nhắn"
                                value={sendMessageType}
                                filterOption={(input, option) => {
                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }}
                                onChange={(value) => { setSendMessageType(value); }}
                            >
                                {(listSendMessageType ?? []).map((record) => {
                                    return (
                                        <Select.Option key={record.Key} value={record.Key} label={record.Value}>{record.Value}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className='col-md-3 d-flex flex-column'>
                            <div><label>Mẫu dành cho</label></div>
                            <Select
                                allowClear
                                showSearch
                                className="w-100"
                                placeholder="Chọn mẫu dành cho"
                                value={sendMessageTemplateType}
                                filterOption={(input, option) => {
                                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }}
                                onChange={(value) => { setSendMessageTemplateType(value); }}
                            >
                                {(listSendMessageTemplateType ?? []).map((record) => {
                                    return (
                                        <Select.Option key={record.Key} value={record.Key} label={record.Value}>{record.Value}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-md-6 d-flex flex-column">
                            <div><label>Từ khóa</label></div>
                            <Input
                                placeholder="Tìm theo template ID, tên mẫu, nội dung"
                                prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                                value={keyword}
                                onChange={(e) => { setKeyword(e.target.value); }}
                                onKeyUp={(e) => { EnterToSearch(e); }}
                            ></Input>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-12 d-flex justify-content-between'>
                            <button className={`${BUTTON.THEME}`} onClick={() => { HandleCreate(); }}>
                                <i className='fas fa-plus mr-1' />Tạo mới
                            </button>
                            <button className={`${BUTTON.THEME} ml-2`} onClick={() => { HandleBeforeSearch() }}>
                                <i className='fas fa-search mr-1' />Tìm kiếm
                            </button>
                        </div>
                    </div>
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        rowClassName={"rowCustom"}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    HandleView(record);
                                }
                            }
                        }}
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.id}
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        size={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size) }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size) }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
            <Modal
                maskClosable={false}
                open={visibleModal}
                destroyOnClose
                width={1000}
            >
                <ViewCreateUpdate
                    mode={mode} data={selected}
                    onClose={() => onCloseModal()}
                />
            </Modal>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ConfigMessageTemplate)