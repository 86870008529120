import React, { Fragment, useEffect, useState, forwardRef, useRef, useCallback } from "react";
import moment from 'moment';
import {
    Input, Modal, notification, Pagination, Select, Table, Tag, Menu, Upload, Tooltip, Checkbox, Dropdown
} from 'antd';
import * as PatientService from '../../services/patientService';
import { GetListAsync, GetByIdAsync } from '../corporation/CorporationServices';
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import Notify, { NotifyStatus } from '../../components/notify';
import LoginWF from '../hotline/loginWF';
import * as WorldfoneService from '../../services/worldfoneService';
import * as Actions from '../../libs/actions';
import Administrative from '../recept/components/administrative';
import * as RegisMediService from '../../services/regisMediService';
import * as CommonService from '../../services/commonService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import DetailHoSo from '../exam/hoso/chitietNew'
import * as SystemConfig from '../../configure/systemConfig';

const { Option } = Select;
const { Dragger } = Upload;
const validator = new SimpleReactValidator(ValidationCustom);
const LIST_PATIENT_MODE = {
        NORMAL: 'Normal', 
        READ: 'Read'
    }
export default forwardRef((props, ref) =>{
    const notiRef = useRef(null);
    const [indeterminateOfChkAllForPopup, setIndeterminateOfChkAllForPopup] = useState(false);
    const [checkedOfChkAllForPopup, setCheckedOfChkAllForPopup] = useState(false);
    const [mode , setMode] = useState(props.mode ?? LIST_PATIENT_MODE.NORMAL);
    const [isDlg , setIsDlg] = useState(props.isDlg);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    const [mabn , setMabn] = useState('');
    const [hoten, setHoten] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [cmnd, setCmnd] = useState('');
    const [ngaysinh, setNgaysinh] = useState(moment());
    const [ward, setWard] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [loadingZalo, setLoadingZalo] = useState(0);
    const [txtZalo, setTxtZalo] = useState('');
    const [diachi, setDiachi] = useState('');
    const [coporator, setCoporator] = useState(null);
    const [banggia, setBangGia] = useState('default');
    const [patient, setPatient] = useState({});

    const [onlyYear, setOnlyYear] = useState(false);
    const [visibleLienHe, setVisibleLienHe] = useState(false);
    const [visibleDST, setVisibleDST] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visiableHoSo, setVisiableHoSo] = useState(false);
    const [visibleZalo, setVisibleZalo] = useState(false);
    const [historyDrawer, setHistoryDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showImportResult, setShowImportResult] = useState(false);
    const [visibleImportInfo, setVisibleImportInfo] = useState(false);
    const [vsbSelectBangGia, setVsbSelectBangGia] = useState(false);
    const [visibleImport, setVisibleImport] = useState(false);
    const [isLayMau, setIsLayMau] = useState(false);
    const [isLoginWF, setIsLoginWF] = useState(false);
    
    const [key, setKey] = useState('');
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [sorting, setSorting] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    // const [coporatorFilter, setCoporatorFilter] = useState(null);

    const [selectedId, setSelectedId] = useState('');
    const [idLichhen, setIdLichhen] = useState('');
    const [insertRow, setInsertRow] = useState(0);
    const [errorRow, setErrorRow] = useState(0);
    const [errorList, setErrorList] = useState('');
    const [rejectRow, setRejectRow] = useState(0);
    const [rejectList, setRejectList] = useState('');
    const [updateRow, setUpdateRow] = useState(0);
    const [updateList, setUpdateList] = useState('');
    const [successInfo, setSuccessInfo] = useState('');
    const [msgCode, setMsgCode] = useState('');
    const [titleWF, setTitleWF] = useState('');
    const [mactv, setMactv] = useState('');
    const [hinhthucthanhtoan, setHinhthucthanhtoan] = useState(1);
    
    const [listCoporatorsFilter, setListCoporatorsFilter] = useState([]);
    const [lstBN, setLstBN] = useState([]);
    const [lstCity, setLstCity] = useState([]);
    const [lstDistrict, setLstDistrict] = useState([]);
    const [lstWard, setLstWard] = useState([]);
    const [lstDoctor, setLstDoctor] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [listBangGia, setListBangGia] = useState([]);
    const [lstCTV, setLstCTV] = useState([]);
    const [lstMarketing, setLstMarketing] = useState([]);
    const [listPatientForPopup, setListPatientForPopup] = useState([]);

    const _administrative = useRef();
    const _hiddenLink = useRef();

    useEffect(() => {
        if (props.listPatientsSelected) {
            setListPatientForPopup(props.listPatientsSelected);
        }
        // loadDanhSach();
    }, [])

    useEffect(() => {
        loadDanhSach();
        // loadCoporationFilter();
    }, [page, size, sorting])

    const loadDanhSach = () => {
        Actions.setLoading(true);
        let sortingAndPagingModel = {pageIndex: page, pageSize: size, sorting: sorting};
        let filterModel = {Keyword: key, CoporationId: '' }
        PatientService.GetListPatientAsync(sortingAndPagingModel, filterModel).then(result => {
            if (result.isSuccess) {
                setLstBN(result.data.items);
                setTotalRecords(result.data.totalCount);
            } else {
            notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        }).finally(() => Actions.setLoading(false))
    }

    const loadCoporationFilter = (Keyword = '') => {
        let sortingAndPagingModel = {pageIndex: 1, pageSize: 10, sorting:''};
        let filterModel = {Keyword: Keyword}
        GetListAsync(sortingAndPagingModel, filterModel).then(result => {
            if (result.isSuccess) {
                setListCoporatorsFilter(result.data.items);
            }
        })
    }

    const getCoporationDetail = (coporationId) => {
        if(coporationId !== null && coporationId !== undefined ){
            GetByIdAsync(coporationId).then(result => {
            if (result.isSuccess) {
                if(result.data.bangGia && result.data.bangGia !== ""){
                    setBangGia(result.data.bangGia);
                } else {
                    setBangGia('default');
                } 
            }
        })
        }
    }

    const MakeCall = (row) => {
        if (row.dienThoai === null || row.dienThoai === '' || row.dienThoai.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true);
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienThoai, row.hoTen, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF('Vui lòng đăng nhập Worldfone');
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true,'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    async function CreatePatient() {
        let data = _administrative.current.getState();
        data.city = data.city ? data.city.toString() : '';
        data.district = data.district ? data.district.toString() : '';
        data.ward = data.ward ? data.ward.toString() : '';
        data.subjectCode = '2';
        data.people = '25';
        let numberError = 0;
        await _administrative.current.getForm().validateFields().then(values => {
            if (values.errorFields)
                numberError += values.errorFields.length

        }).catch((ex) => {
            if (ex.errorFields)
                numberError += ex.errorFields.length
        })
        if (_administrative.current.validator.allValid()) {
            if (numberError === 0) {
                Actions.setLoading(true);
                RegisMediService.CreatePatient(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        onCancel();
                        loadDanhSach();
                    }
                    else {
                        Actions.openMessageDialog("Lỗi", result.err.msgString)
                    }
                    Actions.setLoading(false);
                }).catch(err => {
                    Actions.openMessageDialog("Lỗi", err);
                    Actions.setLoading(false);
                })
            }
        }
    }

    const GetPatientById = (row) => {
        let data = {
            patientName : row.hoTen,
            patientID : row.maBN
        }

        // _administrative.current && _administrative.current.loadPatientDetail(data);
        
        // setTimeout(() => {
        //     _administrative.current && _administrative.current.loadPatientDetail(data);
        // }, 200);
        // setIsEdit(true);
        // setVisible(true);
        CommonService.GetPatientDetail(row.maBN).then(result => {
            if (result.isSuccess) {
                setTimeout(() => {
                    _administrative.current && _administrative.current.handleLoadPatientBarCode(result.item);
                }, 200);
                
                setIsEdit(true);
                setVisible(true);
            } else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: err })
        })
    }

    async function UpdatePatient() {
        let obj = _administrative.current.getState();
        const data = {
            patientID: obj.code,
            PatientName: obj.name,
            birthDay: obj.dob,
            gender: obj.gender,
            phone: obj.phone,
            email: obj.email,
            cardId: obj.cmnd,
            ward: obj.ward,
            district: obj.district,
            city: obj.city,
            patientName: obj.name,
            address: obj.address,
            coporator: obj.coporator,
            onlyyear: obj.onlyyear,
            marketing : obj.marketing.join(';'),
        }
        PatientService.Update(data).then(result => {
            if (result.isSuccess) {
                onCancel();
                loadDanhSach();
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    
    const resetPassword = (row) => {
        CommonService.resetMatKhau(row.maBN).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Đã reset mật khẩu cho " + row.hoTen })
            }
            else {
                notification.error({ message: "Thao tác lỗi" })
            }
        })
    }

    const GetListBangGia = () => {
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                setListBangGia(result.data);
            }
        }).catch(err => {
        })
    }

    const getImportTemplate = () => {
        Actions.setLoading(true);
        PatientService.mauImportDanhSachBenhNhan(coporator ,banggia).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                _hiddenLink.current.href = url;
                _hiddenLink.current.download = "Mau-Import-Danh-Sach-Benh-Nhan";
                _hiddenLink.current.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }

    const handleUpload = () => {
        // if (this.state.hinhthucthanhtoan !== null && this.state.hinhthucthanhtoan !== undefined) {
            Actions.setLoading(true);
            const formData = new FormData();
            formData.append('file', fileList[0]);
            setUploading(true)
            PatientService.importDanhSachBenhNhan(formData, hinhthucthanhtoan).then(res => {
                if (res.isSuccess) {
                    setUploading(false);
                    setFileList([]);
                    setInsertRow(res.returnObject.insertRow);
                    setErrorList(res.returnObject.errorList);
                    setErrorRow(res.returnObject.errorRow);
                    setRejectList(res.returnObject.rejectList);
                    setRejectRow(res.returnObject.rejectRow);
                    setUpdateList(res.returnObject.updateList);
                    setUpdateRow(res.returnObject.updateRow);
                    setSuccessInfo(res.returnObject.successInfo);
                    setShowImportResult(true);
                    setHinhthucthanhtoan(1);
                    loadDanhSach();
                    Actions.setLoading(false);
                }
                else {
                    notification.warning({ message: res.err.msgString });
                    setUploading(false);
                    setShowImportResult(true);
                    setVisibleImportInfo(true);
                    setErrorList(res.returnObject.errorList);
                    setErrorRow(res.returnObject.errorRow);
                    setRejectList(res.returnObject.rejectList);
                    setRejectRow(res.returnObject.rejectRow);
                    setUpdateList(res.returnObject.updateList);
                    setUpdateRow(res.returnObject.updateRow);
                    setMsgCode(res.err.msgCode);
                    setFileList([]);
                    setSuccessInfo(res.returnObject.successInfo);
                    Actions.setLoading(false);
                }
            }).catch(err => {
                setUploading(false)
                Actions.setLoading(false);
            })
        // } else {
        //     notification.warning({ message: "Chưa chọn hình thức thanh toán" })
        // }
    }

    const isSelectedPatientEqualListPatient = () => {
        let listPatientId = lstBN.map(x => x.maBN);
        let listSelectedPatientId = listPatientForPopup.map(x => x.maBN);
        listSelectedPatientId = listSelectedPatientId.filter(x => listPatientId.indexOf(x) > -1);
        return Math.abs(listSelectedPatientId.length - listPatientId.length);
    }

    const setStatusOfChkAllForPopup = () => {
        let t = isSelectedPatientEqualListPatient();
        switch (t) {
            case 0:
                setIndeterminateOfChkAllForPopup(false);
                setCheckedOfChkAllForPopup(true);
                break;
            case lstBN.length:
                setIndeterminateOfChkAllForPopup(false);
                setCheckedOfChkAllForPopup(false);
                break;
            default:
                setIndeterminateOfChkAllForPopup(true);
                break;
        }
    }
    
    const updateListpatientForPopup = (text, record, index) => {
        let indexOfList = listPatientForPopup.map(x => x.maBN).indexOf(record.maBN);
        if (indexOfList >= 0)
            listPatientForPopup.splice(indexOfList, 1);
        else
            listPatientForPopup.push(record);
        setStatusOfChkAllForPopup();
        forceUpdate();
    }

    const checkAll = (e) => {
        let t = isSelectedPatientEqualListPatient();
        //trường hợp đang checkAll (t=0) -> uncheckAll
        if (t == 0) {
            lstBN.forEach(item => {
                let indexOfList = listPatientForPopup.map(x => x.maBN).indexOf(item.maBN);
                listPatientForPopup.splice(indexOfList, 1);
            });
            setCheckedOfChkAllForPopup(false);
        }
        else {
            lstBN.forEach(item => {
                let indexOfList = listPatientForPopup.map(x => x.maBN).indexOf(item.maBN);
                if (indexOfList < 0)
                    listPatientForPopup.push(item);
            });
            setCheckedOfChkAllForPopup(true);
        }
        setIndeterminateOfChkAllForPopup(false);
        forceUpdate();
    }

    const onCloseListPatientPoptupHandle = () => {
        setIsDlg(props.isDlg);
        setMode(props.mode ?? LIST_PATIENT_MODE.NORMAL);
        setIndeterminateOfChkAllForPopup(false);
        setCheckedOfChkAllForPopup(false);
        setListPatientForPopup([]);
    }

    const resetState = () => {
        // setCoporatorFilter(null);
        setKey('');
    }

    const onCancel = () => {
        setVisible(false);
        setIsEdit(false);
        _administrative.current.resetState();
        setVsbSelectBangGia(false); 
        setBangGia('default');
    }

    const onCancelVsb = () => {
        setIsEdit(false);
        setVsbSelectBangGia(false); 
        setBangGia('default');
    }

    const rowOptions = (row) => {
        return (
            <Menu
                items={[
                // {
                //     label: 
                //     <a href="#" onClick={() => {
                //         MakeCall(row);
                //     }}><i className='fas fa-phone mr-1'/> Gọi điện</a>,
                //     key: '0',
                // },
                {
                    label: 
                    <a href="#" onClick={() => { 
                        setMabn(row.maBN);
                        setVisiableHoSo(true);
                    }}><i className='fas fa-file-medical mr-2'/> Xem hồ sơ </a>,
                    key: '1',
                },
                {
                    label: 
                    <a href="#" onClick={() => {
                        GetPatientById(row);
                    }}><i className='fas fa-cogs mr-1'/> Chỉnh sửa</a>,
                    key: '2',
                },
                // {
                //     label: 
                //     <a href="#" onClick={() => {
                //         resetPassword(row);
                //     }}><i className='fas fa-key mr-2'/> Cài lại mật khẩu</a>,
                //     key: '3',
                // },
                ]}
            />
        )
    }

    let columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
            width: 30,
        },
        {
            title: () => { return <Checkbox onChange={(e) => checkAll(e)} id='chkAllForPopup' 
                    indeterminate={indeterminateOfChkAllForPopup}
                    checked={checkedOfChkAllForPopup}
                ></Checkbox>
            },
            // dataIndex: 'dataIndexPatientID',
            key: 'keyPatientID',
            isHideColumn: !isDlg,
            width: isDlg ? 54 : 0,
            width: 54,
            align: 'center',
            render: (text, record, index) => {
                if (isDlg) {
                // if (true) {
                    return <Checkbox checked={listPatientForPopup.map(x => x.maBN).indexOf(record.maBN) > -1}
                        onChange={() => updateListpatientForPopup(text, record, index)}></Checkbox>;
                }
                return;
            },
        },
        {
            title: 'Mã BN',
            dataIndex: 'maBN',
            key: 'maBN',
            width: 110,
            render: (value, record) =>{
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        setPatient(record);
                        setMabn(record.maBN);
                        setVisiableHoSo(true);
                    }}>{value}</a>
            }
        },
        {
            title: 'Thao tác',
            align: 'center',
            width: 76,
            isHideColumn: mode !== LIST_PATIENT_MODE.NORMAL,
            render: (row) => {
                if (mode !== LIST_PATIENT_MODE.NORMAL) return;
                return (
                    <Dropdown overlay={rowOptions(row)} trigger={['click']}>
                        <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                    </Dropdown>
                )          
            }
        },
        {
            title: 'Họ tên',
            dataIndex: 'hoTen',
            key: 'hoTen',
            render: (value) => {
                return <b>{value}</b>;
            },
        },
        {
            title: 'Giới tính',
            dataIndex: 'phai',
            key: 'phai',
            align: 'center',
            width: 76,
            render: (value) => {
                return <span>{value === 0 ? "Nam" : "Nữ"}</span>;
            },
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaySinh',
            key: 'ngaySinh',
            align: 'center',
            width: 120,
            render: (value, record) => {
                return <span>{onlyYear ? record.namSinh : moment(record.ngaySinh).format(SystemConfig.FORMAT_DATE)}</span>;
            },
        },
        {
            title: 'CMND / CCCD / Passport',
            dataIndex: 'cmnd',
            key: 'cmnd',
            width: 180
        },
        {
            title: 'SĐT',
            dataIndex: 'dienThoai',
            key: 'dienThoai',
            width: 180
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left'
        }
    ];
    columns = columns.filter(x => !(x.isHideColumn ?? false));

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <button className="btn" onClick={() => {setVsbSelectBangGia(true); GetListBangGia()}}><i className="fa fa-table mr-1"/>Tải mẫu</button>
            </Menu.Item>
            <Menu.Item key="1">
                <button className="btn" onClick={() => {setVisibleImport(true)}}><i className="fa fa-upload mr-1"/>Nhập dữ liệu</button>
            </Menu.Item>
        </Menu>
    );

    const config = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                setFileList([]);
            },
            beforeUpload: file => {
                setFileList([file]);
                setSuccessInfo("");
                return false;
            },
            fileList
        };

    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
            <div className={isDlg ? "list-page" :"containerChilderWrapper list-page"} >
                <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
                    <h3>Danh sách khách hàng</h3>
                    <h3 hidden={!isDlg} onClick={() => {props.onCancel()}}><i className='far fa-times-circle' /></h3>
                </div>
                <div className="list-page-body">{/* body */}
                    <div className="row">{/* notification */}
                        <div className="col-md-12"><Notify ref={notiRef} /></div>
                    </div>
                    <div className="list-page-search">{/* search/filter */}
                        <div className="row">
                            <div className="col-md-12 col-sm-6">
                                <label>Từ khóa</label>
                                <Input
                                    placeholder="Nhập mã, tên, CCCD, điện thoại bệnh nhân"
                                    value={key}
                                    onChange={(e) => {setKey(e.target.value)}}
                                    onPressEnter={() => {setPage(1); loadDanhSach()}}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6 d-flex justify-content-start">
                                <button hidden={!isDlg} className="btn btn-secondary" onClick={() => {
                                    props.onCloseListPatientPoptup(listPatientForPopup); 
                                    onCloseListPatientPoptupHandle(); 
                                }}><i className="fa fa-check-square mr-1"></i>Chọn khách hàng</button>
                            </div>
                        </div>
                    </div>
                    <div className="list-page-table">
                        <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                            className="table-min-h-0"
                            locale={{ emptyText: 'Không có dữ liệu' }} 
                            scroll={{ y: 0 }}
                            pagination={false} 
                            dataSource={lstBN}
                            columns={columns} 
                            rowKey={(record) => record.maBN}
                        />
                        <Pagination
                            // position={['bottomCenter']}
                            current={page}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { setPage(page); setSize(size) }}
                            onShowSizeChange={(page, size) => {setPage(page); setSize(size)}}
                            showSizeChanger={true}
                        />
                    </div>
                </div>
                {/* footer (nếu có)*/}
                {/* <div className="list-page-footer d-flex justify-content-between mt-2 d-none">
                </div> */}
            </div>
            <Modal
                visible={isLoginWF}
                onCancel={() => {  setIsLoginWF(false) }}> 
                <LoginWF title={titleWF} onClose={() => setIsLoginWF(false)} />
            </Modal>
            <Modal
                visible={visible}
                onCancel={() => {onCancel()}}
                width={1000}>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>{isEdit ? 'Cập nhật' : "Thêm"} thông tin khách hàng</h3>
                    <h3 onClick={() => {onCancel()}}><i className='far fa-times-circle' /></h3>
                </div>
                <Administrative ref={_administrative}
                    newPatient={true}
                    nguoigioithieu={true}
                    isnotRegister={true}
                    isUpdate={true}
                />
                <div className='custom-hr'></div>
                <div className='row'>
                    <div className="col-md-12 d-flex justify-content-end" >
                        <div>
                            <button className="btn btn-color" onClick={() => { {isEdit ? UpdatePatient() : CreatePatient()} }} >
                                <i className="fa fa-save mr-2" />{isEdit ? 'Cập nhật' : "Lưu"}
                            </button>
                            <button className="btn btn-danger ml-2" onClick={() => {onCancel()}}>
                                <i className="fas fa-sign-out-alt mr-2" />Đóng
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                width={600}
                maskClosable={true}
                visible={vsbSelectBangGia}
                onCancel={() => {onCancelVsb()}}>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Mẫu import danh sách khách hàng</h3>
                    <h3 onClick={() => {onCancelVsb()}}><i className='far fa-times-circle' /></h3>
                </div>
                <div className="row mb-2">
                    <div className="col-md-8 col-sm-6">
                        <label>Nguồn khách hàng</label>
                        <Select
                            style={{ width: "100%" }}
                            value={coporator} placeholder="Chọn nguồn khách hàng"
                            onSelect={(e) => {setCoporator(e);getCoporationDetail(e)}}
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onClear={(e) => {setCoporator(null);setBangGia('default')}}
                            onSearch={(value) => {loadCoporationFilter(value)}}>
                            {listCoporatorsFilter && listCoporatorsFilter.map((value, index) => {
                                return (
                                    <Option key={index} value={value.ma}>{value.ten}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <label>Bảng giá</label>
                        <Select value={banggia}
                            style={{ width: "100%" }}
                            onChange={e => setBangGia(e)}>
                            {/* <Option key={-1} value="default">Mặc định</Option> */}
                            {listBangGia.map((row, index) => {
                                return (
                                    <Option key={index} value={row.value}>{row.label}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12 mt-2 d-flex justify-content-center" >
                        <div>
                            <button className="btn btn-color" onClick={() => { getImportTemplate() }} >
                                <i className="fa fa-download mr-2" />Tải về
                            </button>
                            <button className="btn btn-danger ml-2" onClick={() => {onCancelVsb()}}>
                                <i className="fas fa-sign-out-alt mr-2" />Đóng
                            </button>
                        </div>
                    </div>
                </div>
                {/* <button className="btn btn-color mt-4" onClick={() => getImportTemplate()}>
                    Tải về mẫu import với danh mục bảng giá: <b>{banggia === "default" ? "Mặc định" : banggia}</b></button> */}
            </Modal>
            <Modal
                maskClosable={true}
                width={600}
                visible={visibleImport}
                onCancel={{}}
                cancelText='Đóng'
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}>
                <div className='text-danger text-center'><i>Theo yêu cầu, chức năng tự động thanh toán sẽ ngừng hoạt động kể từ ngày 17/05/2022.<br/>Sau khi hoàn tất import anh/chị vui lòng thanh toán bằng tay!</i></div>
                <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {/* <div className="form-group w-100">
                        <label>Hình thức thanh toán</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label><Tooltip title="Áp dụng hình thức thanh toán bên dưới cho tất cả bệnh nhân trong danh sách nếu có đăng ký dịch vụ">&nbsp;<i className='far fa-question-circle' /></Tooltip>
                        <Select className='w-100 text-center'
                            placeholder="Chọn hình thức thanh toán"
                            onChange={(e) => this.setState({ hinhthucthanhtoan: e })}
                            value={this.state.hinhthucthanhtoan}>
                            <Option key={1} value={1}>Tiền mặt</Option>
                            <Option key={2} value={2}>Chuyển khoản</Option>
                            <Option key={3} value={3}>Quẹt thẻ</Option>
                            <Option key={4} value={4}>Công nợ</Option>
                        </Select>
                    </div> */}
                    <div style={{ width: "100%" }}>
                        <Dragger
                            {...config}
                            accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            maxCount={1}
                            style={{ width: '100%' }}>
                            <div>
                                <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                <p className="ant-upload-hint">
                                    Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                </p>
                            </div>
                        </Dragger>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                        <p style={{ textTransform: "uppercase", textAlign: "center", display: showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                        <p style={{ display: insertRow > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="green">{`${insertRow}`}</Tag> bệnh nhân.</p>
                        <p style={{ display: rejectRow > 0 ? 'flex' : 'none' }}>
                            <Tooltip title="Thông tin bắt buộc nhập: Tên, ngày sinh, giới tính, số điện thoại">Thiếu (sai) thông tin:</Tooltip>{'\u00A0'}<Tag color="red">{`${rejectRow}`}</Tag> bệnh nhân.</p>
                        <p style={{ display: rejectRow > 0 ? 'initial' : 'none', color: "red" }}>Bao gồm:{'\u00A0'}<br />{`${rejectList}`}</p>
                        <p style={{ display: errorRow > 0 ? 'flex' : 'none' }}>
                            Trùng mã xét nghiệm (SID):{'\u00A0'}<Tag color="red">{`${errorRow}`}</Tag> bệnh nhân.</p>
                        <p style={{ display: errorRow > 0 ? 'initial' : 'none', color: "red" }}>Bao gồm:{'\u00A0'}<br />{`${ errorList}`}</p>
                        <p style={{ display: errorRow === 0 && errorList ? 'initial' : 'none', color: "red" }}>{`${ errorList}`}</p>
                        <p style={{ display: updateRow > 0 ? 'flex' : 'none' }}>
                            <Tooltip title="Bệnh nhân trùng tên, giới tính và số điện thoại">Cập nhật thông tin:</Tooltip>{'\u00A0'}<Tag color="orange">{`${ updateRow}`}</Tag> bệnh nhân.</p>
                        <p style={{ display:  updateRow > 0 ? 'initial' : 'none', color: "#e77a1c" }}>Bao gồm:{'\u00A0'}<br />{`${ updateList}`}</p>
                        <p style={{ display:  successInfo ? 'initial' : 'none', color: "red" }}>{successInfo}</p>
                        <div style={{ display: (rejectRow > 0 || errorList) ? 'unset' : 'none', background: "aliceblue", border: "solid 1px #bbb", borderRadius: 15, padding: 9, cursor: "pointer" }}>
                            <Tooltip title={!visibleImportInfo ? "Tìm hiểu nguyên nhân và khắc phục" : "Đóng"}>
                                <i className={!visibleImportInfo ? "far fa-question-circle" : "far fa-times-circle"}
                                    style={{ fontSize: 18 }}
                                    onClick={() => setVisibleImportInfo(!visibleImportInfo)}></i>
                            </Tooltip><br />
                            <div style={{
                                visibility: visibleImportInfo ? "visible" : "hidden", display: visibleImportInfo ? "unset" : "none"
                            }}>
                                <ol style={{ textAlign: "justify" }}>
                                    {rejectRow > 0 &&
                                        <span>
                                            <li>Kiểm tra các thông tin bắt buộc (tên, ngày sinh, giới tính, số điện thoại, tỉnh-quận-huyện) đã được nhập hay chưa.</li>
                                            <li>Cần đảm bảo chọn đúng quận/huyện, phường/xã của tỉnh/thành phố tương ứng. Đảm bảo chọn đơn vị hành chính trong danh sách xổ ra từ file excel</li>
                                            <li>Các thông tin cá nhân (điện thoại, CMND/CCCD, email) cần được nhập đúng định dạng.</li>
                                        </span>}
                                </ol>
                                <ol style={{ textAlign: "justify" }}>
                                    {msgCode &&
                                        <span>
                                            <h5 className='text-uppercase font-bold text-center'>Các thông tin cần kiểm tra lại</h5>
                                            {msgCode.includes("P001") && <li><b>Thông tin tỉnh/thành, quận/huyện, phường/xã phải chọn từ file excel. Không copy từ nguồn khác.</b></li>}
                                            {msgCode.includes("P002") && <li><b>Số điện thoại phải theo chuẩn số điện thoại Việt Nam (10 số nếu là điện thoại di động). Chỉ có thể dùng dấu (.) hoặc (-) để ngăn cách nhóm 3-4 số.</b></li>}
                                            {msgCode.includes("P003") && <li><b>Số CMND/CCCD phải đúng chuẩn.</b></li>}
                                            {msgCode.includes("P004") && <li><b>Email phải đúng chuẩn.</b></li>}
                                            {msgCode.includes("P005") && <li><b>Giới tính không hợp lệ.</b></li>}
                                            {msgCode.includes("P006") && <li><b>Ngày sinh không hợp lệ.</b></li>}
                                        </span>}
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                        <button disabled={fileList ? false : true} className="btn btn-success" onClick={() => handleUpload()}>
                            <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                setVisibleImport(false);
                                setFileList([]);
                                setRejectList('');
                                setInsertRow(0);
                                setRejectRow(0);
                                setUpdateList('');
                                setUpdateRow(0);
                                setShowImportResult(false);
                                setUploading(false);
                                setSuccessInfo('');
                                setErrorList('');
                                setErrorRow(0);
                                //validator.current.hideMessages();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={visiableHoSo}
                onCancel={() => { setVisiableHoSo(false)}}
                width={1000}>
                <DetailHoSo patientId={mabn} onClose={() => setVisiableHoSo(false)} />
            </Modal>
        </Fragment>
    );
});
