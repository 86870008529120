import React, { Fragment, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { BUTTON } from '../../../configureHIS/common/constants';
import * as WarehouseService from '../../../services/KhoService';
import { Button, Input, Select, Table, Pagination } from 'antd';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';

const { Option } = Select;

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({

    }));

    const _hiddenLink = useRef();
    const notify = useRef(null);
    const [keyword, setKeyword] = useState("");
    const [warehouse, setWarehouse] = useState("all");
    const [listWarehouse, setListWarehouse] = useState([]);
    const [listMeds, setListMeds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const columns = [
        {
            title: '#',
            key: 'index',
            align: 'center',
            width: 80,
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
        },
        {
            title: 'Mã thuốc - vật tư',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 200,

        },
        {
            title: 'Đơn vị tính',
            dataIndex: 'unit',
            key: 'unit',
            align: 'left',
            width: 100,
        },
        {
            title: 'Kho',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            align: 'left',
            width: 100,
        },
        {
            title: 'Tồn kho tối thiểu',
            dataIndex: 'minQuantity',
            key: 'minQuantity',
            align: 'right',
            width: 80,
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Tồn kho',
            dataIndex: 'stockQuantity',
            key: 'stockQuantity',
            align: 'right',
            width: 80,
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
        },
    ]

    useEffect(() => {
        loadWarehouse();
        getList();
    }, []);

    useEffect(() => {
        getList();
    }, [warehouse]);

    const loadWarehouse = () => {
        WarehouseService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                setListWarehouse(result.data);
            }
            else {
                setListWarehouse([]);
            }
        }).catch(() => { })
    }

    const getList = (page = currentPage, size = pageSize) => {
        Actions.setLoading(true);
        WarehouseService.GetMedicineMinRequirement(warehouse, keyword, page, size).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                notify && notify.current.Close();
                setListMeds(res.data);
                setTotalRecords(res.totalRecords)
            } else {
                Actions.setLoading(false);
                setListMeds([]);
                notify.current.Show("Không thể tải danh sách", NotifyStatus.Error);
            }
        }).catch(() => { 
            Actions.setLoading(false);
        })
    }

    const exportExcel = () => {
        Actions.setLoading(true);
        StatisticService.ExportMedicineMinRequirement(warehouse, keyword)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "ThongKeThuoc-VatTuSoLuongToiThieu";
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            })
            .finally(() => {
                Actions.setLoading(false);
            })
    }

    const onCancel = () => {
        setCurrentPage(1);
        setPageSize(10);
        props.onCancel && props.onCancel();
    }

    return (
        <Fragment>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink}></a>
            <HeaderModal title="Số lượng thuốc - vật tư chạm mức tồn tối thiểu" onClose={onCancel}/>
            <Notify ref={notify} />
            <div className='row d-flex align-items-center'>
                <div className='col-lg-3 mt-2'>
                    <Select
                        className='w-100'
                        defaultValue="all"
                        onChange={(value) => {
                            setCurrentPage(1);
                            setWarehouse(value);
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option key="allwarehouse" value="all">Tất cả kho</Option>
                        {listWarehouse && listWarehouse.map((row, index) => {
                            return (
                                <Option key={index} value={row.value.toString()}>{row.label}</Option>
                            )
                        })}
                    </Select>
                </div>
                <div className='col-lg-4 mt-2'>
                    <Input
                        placeholder='Nhập mã hoặc tên thuốc'
                        value={keyword}
                        onChange={(e) => {
                            setKeyword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                getList(1);
                            }
                        }}
                    />
                </div>
                <div className='col-auto mt-2'>
                    <Button
                        className={`${BUTTON.THEME} ml-2`}
                        onClick={() => { getList(1); }}>
                        <i className="fa fa-search mr-1" />Tìm
                    </Button>
                    <Button
                        className={`${BUTTON.PRIMARY} ml-2`}
                        onClick={() => exportExcel()}>
                        <i className="fa fa-file-excel mr-1" />Xuất Excel
                    </Button>
                </div>
            </div>
            <div className='mt-2' style={{ width: '100%', height: 'calc(100% - 190px)' }}>
                <Table dataSource={listMeds}
                    scroll={{ x: 'max-content', y: 0 }}
                    pagination={false}
                    columns={columns}
                    rowKey={(e, index) => index} />
            </div>
            <Pagination
                className='mt-2'
                style={{ textAlignLast: "center" }}
                current={currentPage}
                total={totalRecords}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setCurrentPage(page); setPageSize(size); getList(page, size); }}
                onShowSizeChange={(page, size) => { setCurrentPage(1); setPageSize(size); getList(1, size); }}
                showSizeChanger={true}
            />
            <div className='custom-hr' />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button className={`${BUTTON.DANGER}`}
                    onClick={() => { onCancel() }}>
                    <i className="fas fa-sign-out-alt mr-1" />Đóng</Button>
            </div>
        </Fragment>
    );
});