import * as ApiCaller from '../libs/httpRequests';

const prefix = 'xuatvien';

export function ThongTinVaoVien(mabn)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinvaovien?mabn=${mabn}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuXuatVien(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuxuatvien`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachXuatVien()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachXuatVien`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachLanVaoVien(mabn)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachLanVaoVien?mabn=${mabn}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};