import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Input, Tooltip, Select, Modal, Pagination, Table, Tag, notification, Popconfirm, DatePicker, InputNumber, Checkbox, Form, Button } from 'antd';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as LoyalServices from '../../screens/loyalty/LoyalServices';
import * as PromotionalCampagin from './PromotionServices';
import * as Actions from '../../libs/actions';
import * as TemplateVoucherService from './templatevoucher/templateVoucherServices';
import Barcode from '../../screens/recept/components/barcode';
import AutocompleteTable from '../../components/autocompletetable';
import * as ChiDinhService from '../../services/chiDinhService';
import DanhSachBenhNhan from '../patient/quanlybenhnhan';
import {
    SyncOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';
import * as SystemConfig from '../../configure/systemConfig';
import UnSupport from '../unknowPage/unSupport';

const { Option } = Select;
const {TextArea} = Input;
//tìm cách đưa vào 1 file khác thành kiểu enum
const LIST_PATIENT_MODE = {
    NORMAL: 'Normal', 
    READ: 'Read'
}
function Promotion() {
    const _hiddenLink = useRef();
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [kwd, setKwd] = useState('');
    const [vsbCreate, setVsbCreate] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [listPromotions, setListPromotions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [promotionName, setPromotionName] = useState("");
    const [note, setNote] = useState(null);
    const [discountType, setDiscountType] = useState("%");
    const [discount, setDiscount] = useState(0);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [listMemberType, setListMemberType] = useState(2);
    const [listGroup, setListGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [numberOfReach, setNumberOfReach] = useState(1);

    const [listLoyalMembers, setListLoyalMembers] = useState([]);
    const [kwdMember, setKwdMember] = useState('');
    const [totalMembers, setTotalMembers] = useState(0);
    const [currentPageMember, setCurrentPageMember] = useState(1);
    const [pageSizeMember, setPageSizeMember] = useState(10);

    const [vcKey, setVcKey] = useState("");
    const [curVCPage, setCurVCPage] = useState(1);
    const [curVCSize, setCurVCSize] = useState(10);
    const [listVouchers, setListVouchers] = useState([]);
    const [totalVouchers, setTotalVouchers] = useState(0);

    const [vsbListVoucher, setVsbListVoucher] = useState(false);
    const [used, setUsed] = useState(false);
    const [expired, setExpired] = useState(false);
    const [initPromotionName, setInitPromotionName] = useState("");
    const [vsbPrint, setVsbPrint] = useState(false);

    const [mauIn, setMauIn] = useState(null);
    const [keyMauIn, setKeyMauIn] = useState("");
    const [listMauIn, setListMauIn] = useState([]);
    const [listPatients, setListPatients] = useState([]);
    const [listPatientsNew, setListPatientsNew] = useState([]);
    const [includedService, setIncludedService] = useState(false);
    const [serKey, setSerKey] = useState("");
    const [serviceID, setServiceID] = useState(0);
    const [serviceName, setServiceName] = useState("");

    const [sameVoucher, setSameVoucher] = useState(false);
    const [customVoucher, setCustomVoucher] = useState("");

    const [banggia, setBanggia] = useState("default");
    const [isShowPopupPatient, setIsShowPopupPatient] = useState(false);

    const _barcode = useRef();

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 80
        },
        {
            title: 'Tên chương trình',
            dataIndex: 'promotionname',
            key: 'promotionname',
            align: 'left',
            width: 300,
        },
        {
            title: 'Tỉ lệ giảm giá',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            width: 150,
            render: (discount) => {
                return (
                    discount.includes("$") ? <span>{Number(discount.replace("$", "")).toLocaleString("en-US")} đồng</span>
                        : <span>{discount}</span>
                )
            }
        },
        {
            title: 'Ngày hiệu lực',
            // dataIndex: 'beginning',
            key: 'beginning',
            align: 'center',
            width: 150,
            render: (row) => {
                return (
                    moment(row.ending) >= moment() ?
                        <Tag icon={moment(row.beginning) >= moment() ? <ClockCircleOutlined /> : <SyncOutlined spin />}
                            color={moment(row.beginning) >= moment() ? "default" : "processing"}>
                            {moment(row.beginning).format("HH:mm:ss, DD-MM-YYYY")}
                        </Tag>
                        :
                        <Tag color="default">
                            {moment(row.beginning).format("HH:mm:ss, DD-MM-YYYY")}
                        </Tag>
                )
            }
        },
        {
            title: 'Ngày hết hạn',
            key: 'ending',
            align: 'center',
            width: 150,
            render: (row) => {
                return (
                    moment(row.beginning) <= moment() ?
                        <Tag icon={moment(row.ending) < moment() ? <MinusCircleOutlined /> : <SyncOutlined spin />}
                            color={moment(row.ending) < moment() ? "error" : "processing"}>
                            {moment(row.ending).format("HH:mm:ss, DD-MM-YYYY")}
                        </Tag>
                        :
                        <Tag color="default" icon={<ClockCircleOutlined />}>
                            {moment(row.ending).format("HH:mm:ss, DD-MM-YYYY")}
                        </Tag>
                )
            }
        },
        {
            title: 'Số phiếu đã tạo',
            dataIndex: 'totalvouchers',
            key: 'totalvouchers',
            align: 'center',
            width: 150,
        },
        {
            title: 'Đã dùng',
            key: 'totalused',
            align: 'center',
            width: 90,
            render: (row) => {
                return <Tag color={Number(row.totalused) > 0 ? "success" : "default"} onClick={() => Number(row.totalused) > 0 && row.customVoucher === "false" && getListVouchers(row.id, true)}>{row.totalused}</Tag>
            }
        },
        {
            title: 'Dùng chung',
            dataIndex: 'groupid',
            key: 'groupid',
            align: 'center',
            width: 100,
            render: (value) => <i className={value === 0 && "far fa-check-circle text-success"} />
        },
        {
            title: 'Tặng dịch vụ',
            key: 'serviceid',
            align: 'center',
            width: 120,
            render: (row) => <Tooltip title={row.servicename}><i className={row.serviceid > 0 && "far fa-check-circle text-success"} /></Tooltip>
        },
        {
            key: "action",
            align: 'center',
            render: (row, record) => {
                return (
                    <div>
                        <Tooltip title="Xem danh sách mã khuyến mại">
                            <button className='btn btn-color'
                                onClick={() => {
                                    getListVouchers(row.id, false);
                                    onSearchMauIn("", 1);
                                    moment(row.ending) < moment() ? setExpired(true) : setExpired(false);
                                }}>
                                <i className='fas fa-list' />
                            </button>
                        </Tooltip>
                        <Tooltip title="Cập nhật thông tin CTKM">
                            <button className='btn btn-color' style={{ marginLeft: 10 }} onClick={() => { setIsUpdate(true); setVsbCreate(true); setInfoBeforeUpdate(row) }}><i className='fas fa-pen' /></button>
                        </Tooltip>
                        <Tooltip title={(record.groupid === 0 ||record.customVoucher === 'true') ? "CTKM này không có danh sách khách hàng. Không thể sao chép!" : "Sao chép"}>
                            <Popconfirm width={200} disabled={(record.groupid === 0 ||record.customVoucher === 'true')} title="Bạn có chắc chắn muốn sao chép CTKM này không?" okText="Đồng ý" cancelText="Từ chối" onConfirm={() => clonePromotion(row.id)}>
                                <button className='btn btn-color' style={{ marginLeft: 10, cursor: `${(record.groupid === 0 ||record.customVoucher === 'true') && "not-allowed"}` }} ><i className='fas fa-clone' /></button>
                            </Popconfirm>
                        </Tooltip>
                        <Popconfirm width={200} title="Nói chung, hệ thống sẽ không biết chính xác bạn đã phân phối mã voucher hay chưa. Vẫn muốn hủy bỏ CTKM?" okText="Đồng ý" cancelText="Từ chối" onConfirm={() => deletePromotion(row.id)}>
                            <button className='btn btn-danger' style={{ marginLeft: 10 }}><i className='fas fa-times' /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    const columnMember = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPageMember - 1) * pageSizeMember + Number(index) + 1),
            align: 'center',
            width: 70
        },
        {
            title: 'Mã BN',
            dataIndex: 'patientID',
            key: 'patientID',
            width: 100,
        },
        {
            title: 'Họ tên',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 200,
            render: (value) => {
                return <b>{value}</b>;
            },
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 80,
            render: (value) => {
                return <span>{value === 0 ? "Nam" : "Nữ"}</span>;
            },
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'dob',
            key: 'dob',
            width: 100,
            render: (dob) => {
                return <span>{moment(dob).format(SystemConfig.FORMAT_DATE)}</span>;
            },
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            width: 250,
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 120
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'left',
            width: 160
        },
        // {
        //     title: 'Tổng chi tiêu',
        //     dataIndex: 'total',
        //     key: 'total',
        //     align: 'right',
        //     width: 100,
        //     render: (total) => total.toLocaleString('en-US'),
        // },
    ]

    const columnVoucher = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((curVCPage - 1) * curVCSize + Number(index) + 1),
            align: 'center',
            width: 70
        },
        {
            title: 'Mã voucher',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Người sử dụng',
            dataIndex: 'customer',
            key: 'customer',
            width: 170,
        },
        {
            title: 'Thời điểm sử dụng',
            dataIndex: 'usedat',
            key: 'usedat',
            width: 150,
            render: (date) => moment(date).year() === 1 ? "Chưa có thông tin, hoặc chưa dùng" : moment(date).format(SystemConfig.FORMAT_TIME_DATE)
        },
        {
            title: 'Số hóa đơn',
            dataIndex: 'sohoadon',
            key: 'sohoadon',
            align: "center",
            width: 100,
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            width: 120,
            render: (row) => {
                return (
                    !row.used && <Tooltip title={mauIn === null && "Chọn mẫu in trước"}>
                        {!expired && <button className='btn btn-warning' onClick={() => mauIn && printVouchers(row.promotionid, row.code)}>
                            <i className='fas fa-print' />
                        </button>}
                    </Tooltip>
                )
            }
        }
    ]

    const getPromotionsList = (page = currentPage, size = pageSize) => {
        PromotionalCampagin.getPromotionsList(kwd, page, size).then(res => {
            if (res.isSuccess) {
                setListPromotions(res.data);
                setCurrentPage(page);
                setPageSize(size);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }

    const getPromotionsDetail = (id) => {
        PromotionalCampagin.getPromotionsList(id, 1, 10).then(res => {
            if (res.isSuccess) {
                let data = res.data[0];
                setInfoBeforeUpdate(data);
            } else {
                notification.error({ message: "Tải dữ liệu bị lỗi. Vui lòng thử lại!" });
            }
        }).catch(() => { })
    }

    const createPromotion = () => {
        if (validator.current.allValid()) {
            Actions.setLoading(true);
            if (listMemberType === 3 && listPatients.length === 0) {
                notification.warning({ message: "Danh sách khách hàng trống!" });
                Actions.setLoading(false);
            } else {
                if (includedService && serviceName === "") {
                    notification.warning({ message: "Chưa chọn dịch vụ" });
                    Actions.setLoading(false);
                } else {
                    if (moment(endDate) > moment(startDate)) {
                        const data = {
                            promotionname: promotionName,
                            discount: discount.toString() + discountType,
                            beginning: startDate,
                            ending: endDate,
                            note : note,
                            totalvouchers: numberOfReach,
                            groupid: listMemberType === 1 ? Number(selectedGroup) : listMemberType === 2 ? 0 : -1,
                            listPatients: listMemberType === 3 ? listPatients : [],
                            serviceID: serviceID,
                            customVoucher,
                        }
                        PromotionalCampagin.createPromotion(data).then(res => {
                            if (res.isSuccess) {
                                Actions.setLoading(false);
                                notification.success({ message: "Đã tạo thành công" });
                                resetState();
                                getPromotionsList();
                            } else {
                                Actions.setLoading(false);
                                res.err.msgString ? notification.warning({ message: res.err.msgString })
                                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
                            }
                        }).catch(() => { })
                    } else {
                        Actions.setLoading(false);
                        notification.warning({ message: "Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu" });
                    }
                }
            }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }

    const setInfoBeforeUpdate = (info) => {
        setSelectedId(info.id);
        setPromotionName(info.promotionname);
        setInitPromotionName(info.promotionname);
        const dType = info.discount.substr(-1);
        const dValue = info.discount.substr(0, info.discount.length - 1);
        setDiscountType(dType);
        setDiscount(Number(dValue));
        setSameVoucher(info.customVoucher === "true");
        setNote(info.note)
        const gValue = info.groupid;
        if (gValue > 0) {
            setListMemberType(1);
            setSelectedGroup(gValue.toString());
        } else if (gValue === -1) {
            setListMemberType(3);
            setNumberOfReach(info.totalvouchers);
            PromotionalCampagin.getListPatients(info.id).then(res => {
                if (res.isSuccess) {
                    setListPatients(res.data);
                } else {
                    notification.warning({ message: "Không thể tải danh sách khách hàng, vui lòng tải lại trang" });
                }
            }).catch(() => { })
        } else {
            setListMemberType(2);
            setNumberOfReach(info.totalvouchers);
        }
        setStartDate(moment(info.beginning));
        setEndDate(moment(info.ending));
        if (info.servicename) {
            setIncludedService(true);
            setServiceID(info.serviceid);
            setServiceName(info.servicename);
        }
    }

    const updatePromotion = () => {
        if (validator.current.allValid()) {
            if (moment(endDate) > moment(startDate)) {
                Actions.setLoading(true);
                const data = {
                    _promotionname: promotionName !== initPromotionName ? "" : initPromotionName,
                    promotionname: promotionName,
                    discount: discount.toString() + discountType,
                    beginning: startDate,
                    ending: endDate,
                    id: Number(selectedId),
                    listPatients: listPatientsNew,
                    serviceID: serviceID,
                    note:note,
                }
                PromotionalCampagin.updatePromotion(data).then(res => {
                    if (res.isSuccess) {
                        Actions.setLoading(false);
                        notification.success({ message: "Cập nhật thành công" });
                        resetState();
                        getPromotionsList();
                    } else {
                        res.err.msgString ? notification.warning({ message: res.err.msgString })
                            : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
                    }
                }).catch(() => { })
            } else {
                notification.warning({ message: "Vui lòng chọn ngày kết thúc lớn hơn ngày bắt đầu" });
            }
        } else {
            forceUpdate();
            validator.current.showMessages();
        }
    }

    const deletePromotion = (id) => {
        Actions.setLoading(true);
        PromotionalCampagin.deletePromotion(id).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                notification.success({ message: "Xóa thành công" });
                setSelectedId(null);
                getPromotionsList(1);
            } else {
                res.err.msgString ? notification.warning({ message: res.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
        }).catch(() => { })
    }

    const clonePromotion = (id) => {
        Actions.setLoading(true);
        PromotionalCampagin.clonePromotion(id).then(res => {
            if (res.isSuccess) {
                Actions.setLoading(false);
                notification.success({ message: "Sao chép thành công" });
                getPromotionsList();
                setSelectedId(null);
                getPromotionsDetail(Number(res.returnObject));
                getPromotionsList(1);
                setIsUpdate(true);
                setVsbCreate(true);
            } else {
                Actions.setLoading(false);
                res.err.msgString ? notification.warning({ message: res.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
        }).catch(() => { 
            Actions.setLoading(false);
        })
    }

    const getListLoyalGroups = (getAll = true) => {
        LoyalServices.getListLoyalGroups("", 1, 10, getAll).then(res => {
            if (res.isSuccess) {
                setListGroup(res.data);
            }
        }).catch(() => { })
    }

    const getListLoyalMembers = (page = currentPageMember, size = pageSizeMember) => {
        LoyalServices.getListLoyalMembers(selectedGroup && Number(selectedGroup), kwdMember, page, size).then(res => {
            if (res.isSuccess) {
                setListLoyalMembers(res.data);
                setCurrentPageMember(page);
                setPageSizeMember(size);
                setTotalMembers(res.totalRecords);
            }
        }).catch(() => { })
    }

    const getListVouchers = (id = selectedId, used = false, page = curVCPage, size = curVCSize) => {
        PromotionalCampagin.getListVouchers(id, vcKey, page, size, used).then(res => {
            if (res.isSuccess) {
                setListVouchers(res.data);
                setVsbListVoucher(true);
                setUsed(used);
                setCurVCPage(page);
                setCurVCSize(size);
                setTotalVouchers(res.totalRecords);
            }
        })
    }

    const resetState = () => {
        validator.current.hideMessages();
        setVsbCreate(false);
        setIsUpdate(false);
        setNumberOfReach(1);
        setTotalMembers(0);
        setSelectedId(null);
        setSelectedGroup(null);
        setListLoyalMembers([]);
        setPromotionName("");
        setDiscountType("%");
        setDiscount(0);
        setStartDate(moment());
        setEndDate(moment());
        setListMemberType(2);
        setListPatients([]);
        setListPatientsNew([]);
        setIncludedService(false);
        setServiceID(0);
        setServiceName("");
        setNote(null);
        setIsShowPopupPatient(false);
    }

    const printVouchers = (id = selectedId, code = "") => {
        PromotionalCampagin.printVouchers(id, mauIn, code).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    const onSearchMauIn = (key = keyMauIn, page = 1, type = 0, size = 10) => {
        TemplateVoucherService.getList(key, type, page, size).then(res => {
            if (res.isSuccess) {
                setListMauIn(res.data);
            }
        }).catch(() => { })
    }

    const patientColumns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => listPatients.indexOf(record) + 1,
            align: 'center',
            width: 80
        },
        {
            title: 'Mã',
            dataIndex: 'patientID',
            key: 'patientID',
            align: 'center',
            width: 100
        },
        {
            title: 'Họ tên',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'left',
            width: 240
        },
        {
            title: 'Ngày sinh',
            key: 'dob',
            align: 'center',
            width: 110,
            render: (info) => info.onlyyear ? moment(info.dob).format("YYYY") : moment(info.dob).format(SystemConfig.FORMAT_DATE),
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            align: 'center',
            width: 110,
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
            width: 110,
            render: (sex) => Number(sex) >= 0 ? sex === 0 ? "Nam" : "Nữ" : "Không xác định",
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            width: 60,
            render: (row) => {
                return (
                    row.isNew && <button className='btn btn-danger' onClick={() => deleteFromSeletedList(row.patientID)}><i className='fas fa-trash' /></button>
                )
            }
        },
    ]

    const handleLoadPatientBarCode = (patientInfo) => {
        let data = {
            phoneNumber: patientInfo.phone,
            patientName: patientInfo.patientName,
            dob: patientInfo.birthDay !== '' ? moment(patientInfo.birthDay) : moment(),
            gender: patientInfo.gender,
            patientID: patientInfo.patientID,
            onlyyear: patientInfo.onlyyear,
            isNew: true,
        }
        if (listPatients.find(p => p.patientID === patientInfo.patientID)) {
            notification.warning({ message: "Đã có trong danh sách" });
        } else {
            setListPatients([...listPatients, data]);
            setListPatientsNew([...listPatientsNew, data]);
            setNumberOfReach(listPatients.length + 1);
        }
    }
    const deleteFromSeletedList = (id) => {
        if (listPatients.find(p => p.patientID === id)) {
            setListPatients(listPatients.filter(p => p.patientID !== id));
            setNumberOfReach(listPatients.length - 1);
            if (listPatientsNew.find(p => p.patientID === id)) {
                setListPatientsNew(listPatientsNew.filter(p => p.patientID !== id));
            }
        } else {
            notification.warning({ message: "Không tìm thấy thông tin" });
        }
    }

    const columnsServices = [
        {
            title: 'Mã Dịch vụ',
            dataIndex: 'mavp',
            key: 'mavp',
            width: 70
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'ten',
            key: 'ten',
            width: 300
        },
        {
            title: 'Đơn giá',
            dataIndex: 'dongia',
            key: 'dongia',
            render: (value) => {
                return <span>{value.toLocaleString('en-US')}</span>;
            },
            width: 70,
            format: 'number',
        },
    ]


    const searchIndication = (key = serKey) => {
        return ChiDinhService.searchSerivce(0, 0, banggia, key, 1, 20);
    }




    const onSelectIndication = (item) => {
        setServiceID(item.id);
        setServiceName(item.ten);
    }

    useEffect(() => {
        getPromotionsList();
    }, [])

    useEffect(() => {
        vsbCreate && getListLoyalGroups();
    }, [vsbCreate])

    useEffect(() => {
        listMemberType === 1 && selectedGroup && getListLoyalMembers();
    }, [selectedGroup])

    useEffect(() => {
        listMemberType === 1 ? setNumberOfReach(0) : setListLoyalMembers([]);
    }, [listMemberType])

    function ExportListLoyalMembers() {
        Actions.setLoading(true);
        LoyalServices.exportListLoyalMembers(selectedGroup && Number(selectedGroup), kwdMember)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let promo = listPromotions.filter(x => x.id == selectedGroup && Number(selectedGroup));
                    if (promo && promo.length) promo = promo[0];
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "KhachHangTiepCanCTKM" + (promo ? ("-" + promo.promotionname.replaceAll('.','')) : "");
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    function ExportListVouchers(id = selectedId, used = false) {
        Actions.setLoading(true);
        PromotionalCampagin.exportListVouchers(id, vcKey, used)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let promo = listPromotions.filter(x => x.id == id);
                    if (promo && promo.length) promo = promo[0];
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "DanhSachVoucher" + (promo ? ("-" + promo.promotionname.replaceAll('.','')) : "");
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }

    function ExportListPatients(id = selectedId) {
        Actions.setLoading(true);
        PromotionalCampagin.exportListPatients(id)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let promo = listPromotions.filter(x => x.id == id);
                    if (promo && promo.length) promo = promo[0];
                    _hiddenLink.current.href = url;
                    _hiddenLink.current.download = "DanhSachKhachHang" + (promo ? ("-" + promo.promotionname.replaceAll('.','')) : "");
                    _hiddenLink.current.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    
    function setPatienInfo(patientInfo) {
        let dataPatient = {
            phoneNumber: patientInfo.phone,
            patientName: patientInfo.patientName,
            dob: patientInfo.birthDay !== '' ? moment(patientInfo.birthDay) : moment(),
            gender: patientInfo.gender,
            patientID: patientInfo.patientID,
            onlyyear: patientInfo.onlyyear,
            isNew: true,
        }
        return dataPatient;
    }
    function onCloseListPatientPoptupHandle(data) {
        let arrDataToAdd = [];
        data.forEach(element => {
            let t = setPatienInfo(element);
            if (listPatients.find(p => p.patientID === element.patientID)) {
                notification.warning({ message: element.patientName + " đã có trong danh sách" });
            } else {
                arrDataToAdd.push(t);
            }
        });
        setNumberOfReach(listPatients.length + arrDataToAdd.length);
        setListPatients([...listPatients, ...arrDataToAdd]);
        setListPatientsNew([...listPatientsNew, ...arrDataToAdd]);
        setIsShowPopupPatient(false);
    }

    return (
        1===1 ? <UnSupport title="Chức năng này chưa hỗ trợ ở thời điểm hiện tại" /> :
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
            <div className={'containerChilderWrapper'}>
                <h4 className="font-bold text-center text-uppercase mt-2">Chương trình khuyến mại</h4>
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-4">
                        <Input
                            className="mt-2"
                            value={kwd} placeholder="Nhập tên CTKM sau đó bấm Enter để tìm kiếm"
                            onChange={(e) => setKwd(e.target.value)}
                            onClick={() => setSelectedId(null)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    getPromotionsList(1);
                                }
                            }}>
                        </Input>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-color mt-2" onClick={() => getPromotionsList(1)}>
                            <i className="fas fa-search" />
                        </button>
                        <Tooltip title="Tạo mới chương trình khuyến mại">
                            <button className="btn btn-color ml-2 mt-2" onClick={() => { setVsbCreate(true) }}>
                                <i className="fas fa-plus" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 130px)', padding: 10 }}>
                    <Table scroll={{ x: "max-content", y: 0 }} pagination={false} dataSource={listPromotions}
                        columns={columns}
                        rowKey={row => `table-item-${row.id}`}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setSelectedId(record.id)
                                }
                            };
                        }}
                        // rowClassName={(record) => {
                        //     return `rowCustomSelect ${(selectedId === record.id) ? 'rowCustomSelectActive' : ''}`
                        // }}
                    />
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            getPromotionsList(page, size);
                            setSelectedId(null)
                        }}
                        onShowSizeChange={() => { }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            <Modal visible={vsbCreate} maskClosable={false} onCancel={() => setVsbCreate(false)} width={1200}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <h3>{`${isUpdate ? "Cập nhật" : "Thêm"} chương trình khuyến mại`}</h3>
                </div>
                <div className="row">
                    <div className="col-md-6 mt-2">
                        <label>Tên chương trình</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Input value={promotionName} onChange={(e) => setPromotionName(e.target.value)}
                            placeholder='Nhập tên chương trình khuyến mại'>
                        </Input>
                        {validator.current.message("Tên chương trình khuyến mại", promotionName, "required")}
                    </div>
                    <div className="col-md-6 mt-2">
                        <label>Tỉ lệ khuyến mại</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <Input value={discount}
                            onChange={(e) => {
                                discountType === "%" && Number(e.target.value) > 100 ?
                                    setDiscount(100)
                                    : setDiscount(Number(e.target.value));
                            }} dir='rtl'
                            style={{ borderRadius: 8, paddingLeft: 15 }} disabled={includedService} maxLength={discountType === "%" ? 3 : 8}
                            placeholder={`Nhập ${discountType === "%" ? "tỉ lệ" : "số tiền"} giảm`}
                            onKeyPress={(e) => {
                                if (e.key === " " || isNaN(e.key) === true)
                                    e.preventDefault();
                            }}
                            prefix={
                                <Tooltip title="Bấm để thay đổi" style={{ cursor: "pointer" }}>
                                    <span onClick={() => { !includedService && discountType === "%" ? setDiscountType("$") : setDiscountType("%") }}>
                                        <i className="fas fa-angle-down" style={{ marginRight: 5 }}></i>{discountType === "%" ? "Phần trăm" : "Số tiền"}
                                    </span>
                                </Tooltip>}
                            suffix={discountType === "%" ? "%" : "đồng"}>
                        </Input>
                        {validator.current.message("Tỉ lệ khuyến mại", discount, discountType === "%" ? "required|max:100,num|min:1,num" : "required|max:99999999,num|min:0,num")}
                    </div>
                    <div className="col-md-6 mt-2">
                        <label>Ngày bắt đầu</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <DatePicker value={startDate} onChange={(e) => setStartDate(e)} showTime
                            format="HH:mm:ss, DD-MM-YYYY" className="w-100" allowClear={false} locale={viVN}
                            placeholder='Chọn ngày bắt đầu'>
                        </DatePicker>
                    </div>
                    <div className="col-md-6 mt-2">
                        <label>Ngày kết thúc</label><label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>
                        <DatePicker value={endDate} onChange={(e) => setEndDate(e)} showTime showNow={false}
                            format="HH:mm:ss, DD-MM-YYYY" className="w-100" allowClear={false} locale={viVN}
                            disabledDate={(current) => current && current <= startDate}
                            placeholder='Chọn ngày ngày kết thúc'>
                        </DatePicker>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-2'>
                        <label>Ghi chú</label>
                        <TextArea rows={3}
                            placeholder="Nhập ghi chú"
                            value={note}
                            onChange={(e => { setNote(e.target.value) })}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mt-2'>
                        <label>Chọn nguồn khách hàng</label>
                        {!isUpdate && <label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>}
                        <Select disabled={isUpdate} value={listMemberType} onChange={(e) => { setListMemberType(e); validator.current.purgeFields(); setNumberOfReach(0) }} className='w-100'>
                            {/* <Select.Option key={1} value={1}>Nhóm thành viên</Select.Option> */}
                            <Select.Option key={2} value={2}>Nhập số lượng</Select.Option>
                            <Select.Option key={3} value={3}>Chọn từ danh sách</Select.Option>
                        </Select>
                    </div>
                    {listMemberType === 1 ?
                        <div className='col-md-6 mt-2'>
                            <Tooltip title="Tạo nhóm ở menu Thành viên">
                                <label>Chọn nhóm thành viên</label>
                                {!isUpdate && <label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>}
                                &nbsp;<i className='far fa-question-circle' />
                            </Tooltip>
                            <Select disabled={isUpdate} value={selectedGroup} placeholder="Chọn nhóm" onChange={(e) => setSelectedGroup(e)} className="w-100">
                                {listGroup.map((group, index) => {
                                    return <Option key={`option-group-${group.key}`} value={group.key}>{group.label}</Option>
                                })}
                            </Select>
                            {listMemberType === 1 && validator.current.message("Nhóm thành viên", selectedGroup, "selection")}
                        </div>
                        :
                        listMemberType === 2 ?
                            <div className='col-md-6 mt-2'>
                                <Tooltip title="Số lượng khách hàng tương đương số lượng voucher">
                                    <label>Nhập số lượng khách hàng</label>
                                    {!isUpdate && <label style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</label>}
                                    &nbsp;<i className='far fa-question-circle' />
                                </Tooltip>
                                <InputNumber disabled={isUpdate} value={numberOfReach} onChange={(e) => setNumberOfReach(e)} className='w-100'
                                    min={1} max={9999999}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                {listMemberType === 2 && validator.current.message("Số lượng khách hàng", numberOfReach, "required|min:1,num")}
                            </div>
                            :
                            <div className='col-md-6 mt-2'>
                                <Tooltip title="Nhập tên hoặc mã BN để tìm">
                                    <label>Danh sách khách hàng</label>&nbsp;<i className='far fa-question-circle' />
                                </Tooltip>
                                <div style={{ marginTop: 5 }}>
                                    <Button className="btn btn-color"
                                        onClick={() => { 
                                            setIsShowPopupPatient(true);
                                        }}>
                                        <i style={{ marginRight: 5 }} className="fa fa-search" />
                                        Tìm kiếm và chọn khách hàng
                                    </Button>
                                    {/* <Barcode
                                        qrcode={false}
                                        ref={_barcode}
                                        onSuccessLoad={(pInfo) => handleLoadPatientBarCode(pInfo)}>
                                    </Barcode> */}
                                </div>
                            </div>
                    }
                    {listMemberType === 2 &&
                        <div className='col-md-6 mt-2'>
                            <Tooltip title={`${includedService ? "Không thể tạo voucher dùng chung khi tặng dịch vụ" : ""}`}>
                                <label>Tạo mã voucher dùng chung {includedService && <i className='far fa-question-circle' />}</label>
                            </Tooltip>
                            <Input
                                disabled={isUpdate || !sameVoucher || includedService} placeholder={isUpdate && sameVoucher ? 'Xem mã tại Danh sách voucher' : 'Nhập mã voucher dùng chung'}
                                value={customVoucher} onChange={(e) => {
                                    const regx = /^[A-Za-z][A-Za-z0-9]*$/;
                                    if (e.target.value === '' || regx.test(e.target.value)) {
                                        setCustomVoucher(e.target.value)
                                    }
                                }}
                                prefix={
                                    <Checkbox checked={sameVoucher}
                                        disabled={isUpdate || includedService}
                                        style={{ marginRight: 10 }}
                                        onChange={() => {
                                            setSameVoucher(!sameVoucher);
                                            if (!sameVoucher) {
                                                setCustomVoucher("");
                                                validator.current.purgeFields();
                                            }
                                        }}>
                                    </Checkbox>
                                }
                            ></Input>
                            {!isUpdate && sameVoucher && validator.current.message("Mã voucher", customVoucher, "required")}
                        </div>}
                    <div className='col-md-6 mt-2'>
                        <label><Checkbox checked={includedService}
                            disabled={isUpdate}
                            onChange={() => {
                                setIncludedService(!includedService);
                                setSelectedId(0);
                                setServiceName("");
                                setDiscountType("%");
                                setDiscount(100);
                                if (includedService) {
                                    setCustomVoucher(null);
                                    setSameVoucher(false);
                                }
                            }}>Tặng dịch vụ</Checkbox></label>
                        {includedService && <Form.Item label="Chọn dịch vụ">
                            <AutocompleteTable
                                width="100%"
                                disabled={!includedService || isUpdate}
                                placeholder="Nhập tên dịch vụ để tìm kiếm"
                                tablewidth={500}
                                listHeight={"50vh"}
                                onSearch={searchIndication}
                                onSelect={(item) => onSelectIndication(item)}
                                value={serKey} headers={columnsServices}
                                keyValue={'id'}
                            >
                            </AutocompleteTable>
                        </Form.Item>}
                    </div>
                    {serviceName != "" && <div className='col-md-6 mt-2'>
                        <label>Đã chọn:</label>
                        <div><Tag color="gold">{serviceName}</Tag></div>
                    </div>}
                </div>
                {(totalMembers > 0 || numberOfReach > 0) &&
                    <div className='row mt-2 d-flex justify-content-end align-items-center'>
                        <div className='col-auto text-center'>
                            <span>Số lượng khách hàng có thể tiếp cận được trong CTKM này:&nbsp;
                                {listMemberType === 1 ?
                                    Number(totalMembers) > 0 &&
                                    <Tag color="blue">{totalMembers.toLocaleString("en-US")}</Tag>
                                    : Number(numberOfReach) > 0 && <Tag color="blue">{numberOfReach.toLocaleString("en-US")}</Tag>}
                            </span>
                        </div>
                    </div>}
                <div className='row mt-2'>
                    {listMemberType === 1 ?
                        selectedGroup && <Fragment>
                            <div className="col-md-4">
                                <Input
                                    className="mt-2"
                                    value={kwdMember} placeholder="Nhập tên hoặc mã khách hàng sau đó bấm Enter để tìm kiếm"
                                    onChange={(e) => setKwdMember(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            getListLoyalMembers(1);
                                        }
                                    }}>
                                </Input>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-color mt-2" onClick={() => getListLoyalMembers(1)}>
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                            <div className="col-auto">
                                <Button className="btn btn-color mt-2" onClick={() => ExportListLoyalMembers()}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                            </div>
                            <div className='col-md-12' style={{ height: 300, width: "100%" }}>
                                <Table scroll={{ x: "max-content", y: 0 }} pagination={false} dataSource={listLoyalMembers}
                                    columns={columnMember}
                                    rowKey={row => `table-item-${row.patientID}`}
                                />
                            </div>
                            <div className='col-md-12'>
                                <Pagination
                                    style={{ textAlignLast: 'center', marginTop: 10 }}
                                    total={totalMembers}
                                    showTotal={total => `Tổng: ${total}`}
                                    current={currentPageMember}
                                    onChange={(page, size) => {
                                        getListLoyalMembers(page, size);
                                    }}
                                    onShowSizeChange={() => { }}
                                    showSizeChanger={true}
                                />
                            </div>
                        </Fragment>
                        :
                        listMemberType === 3 &&
                        <Fragment>
                            <h3 className="w-100 text-center">Danh sách khách hàng đã chọn</h3>
                            {isUpdate &&
                            <div className="col-auto">
                                <Button className="btn btn-color mt-2" onClick={() => ExportListPatients()}> <i style={{ marginRight: 5 }} className="fas fa-download" />
                                    Xuất excel
                                </Button>
                            </div>
                            }
                            <div className='col-md-12' style={{ height: 300, width: "100%", marginBottom: 10 }}>
                                <Table scroll={{ x: "max-content", y: 0 }} columns={patientColumns}
                                    dataSource={listPatients} rowKey={row => `table-ptn-${row.patientID}`}
                                    pagination={{ showSizeChanger: true, showTotal: (total) => `Tổng: ${total}` }}>
                                </Table>
                            </div>
                        </Fragment>}
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4" style={{ zIndex: 1000 }}>
                    <button className="btn btn-success" onClick={() => { isUpdate ? updatePromotion() : createPromotion() }}>
                        <i className="fas fa-save" style={{ marginRight: 5 }} />{isUpdate ? "Cập nhật" : "Lưu"}
                    </button>
                    <button className="ml-2 btn btn-danger" onClick={() => resetState()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                </div>
            </Modal>
            <Modal visible={vsbListVoucher} maskClosable={false} onCancel={() => setVsbListVoucher(false)} width={1200}>
                <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <h3>Danh sách voucher {used && "đã sử dụng"}</h3>
                </div>
                <div className='row'>
                    <div className="col-md-4">
                        <Input
                            className="mt-2"
                            value={vcKey} placeholder="Nhập đủ mã khách hàng hoặc mã voucher sau đó bấm Enter để tìm kiếm"
                            onChange={(e) => setVcKey(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    getListVouchers(selectedId, used, 1);
                                }
                            }}>
                        </Input>
                    </div>
                    <div className="col-auto d-flex justify-content-between">
                        <button className="btn btn-color mt-2" onClick={() => getListVouchers(selectedId, used, 1)}>
                            <i className="fas fa-search" />
                        </button>
                    </div>
                    <div className="col-auto">
                        <Button className="btn btn-color mt-2" onClick={() => ExportListVouchers()}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
                    </div>
                    <div className="col-auto">
                        {!used && !expired &&
                            <div className='ml-auto mt-2'>
                                <span>Chọn mẫu in</span>&nbsp;
                                <Select style={{ width: 250, marginRight: 5 }} placeholder="Chọn mẫu in" value={mauIn} onChange={(e) => setMauIn(e)} showSearch onSearch={(e) => onSearchMauIn(e, 1)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {listMauIn.map((mauin, index) => {
                                        return (
                                            <Select.Option key={index} value={mauin.id}>{mauin.templateName}</Select.Option>
                                        )
                                    })}
                                </Select>
                                <Tooltip title={mauIn === null && "Chọn mẫu in trước"}>
                                    <button className="btn btn-color"
                                        onClick={() => { mauIn && printVouchers() }}
                                    >
                                        <i className="fas fa-print" style={{ marginRight: 5 }} />In mã
                                    </button>
                                </Tooltip>
                            </div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mt-2">
                    </div>
                    <div className='col-md-12' style={{ height: "calc(100vh - 300px)", width: "100%" }}>
                        <Table scroll={{ x: "max-content", y: 0 }} pagination={false} dataSource={listVouchers}
                            columns={columnVoucher}
                            rowKey={(index) => `table-voucher-${index}`}
                            rowClassName={(voucher) => {
                                return `${voucher.used && moment(voucher.usedat).year() !== 1 && 'rowCustomSelectActive'}`
                            }}
                        />
                    </div>
                    <div className='col-md-12'>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={totalVouchers}
                            showTotal={total => `Tổng: ${total}`}
                            current={curVCPage}
                            onChange={(page, size) => {
                                getListVouchers(selectedId, used, page, size);
                            }}
                            onShowSizeChange={() => { }}
                            showSizeChanger={true}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                    <button className="ml-2 btn btn-danger" onClick={() => { setVsbListVoucher(false); setCurVCPage(1); setCurVCSize(10); setMauIn(null) }}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                </div>
            </Modal>
            <Modal visible={isShowPopupPatient} maskClosable={false} onCancel={() => setIsShowPopupPatient(false)} width={1200}>
                <DanhSachBenhNhan 
                    isDlg={true}
                    mode={LIST_PATIENT_MODE.READ}
                    onCloseListPatientPoptup={(data) => { onCloseListPatientPoptupHandle(data) }}/>
            </Modal>
        </Fragment>
    );
}

export default Promotion;