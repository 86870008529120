import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle  } from 'react';
import { Spin } from 'antd';
import * as Actions from '../../../../libs/actions';
import * as ReportServices from '../../../../services/ReportService';

// const { Option } = Select;
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    loadData: () => {
      getData();
    },
  }));
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [loading, setLoading] = useState(false);
  //search/filter
  const [totalOrder, setTotalOrder] = useState();
  const [totalPatient, setTotalPatient] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [highestRevenue, setHighestRevenue] = useState();
  const [lowestRevenue, setLowestRevenue] = useState();
  //other
  //#endregion
  
  //#region useEffect
  useEffect(() => {
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getData = () => {
    setLoading(true);
    let filterModel = {
      siteIds: props.siteIds,
      coporationIds: props.coporationIds,
      keyword: props.keyword,
      city : props.city,
      district : props.district,
      dateFrom: props.dateFrom,
      dateTo: props.dateTo,
      minSpent: props.minSpent,
      maxSpent: props.maxSpent,
    }
    ReportServices.getSpentRevenueSumary(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalOrder(result.data.totalBill);
        setTotalPatient(result.data.totalPatient);
        setHighestRevenue(result.data.highestRevenue);
        setLowestRevenue(result.data.lowestRevenue);
        setTotalRevenue(result.data.totalRevenue);
        // notiRef && notiRef.current.Close();
      }
      else {
        // notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      // if (err.error && err.error.messageText)
      //   notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      // else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      setLoading(false);
      forceUpdate();
    })
  }

  //#endregion 
  //#region table info & event
  //#endregion 
  return (
    <React.Fragment>
      <Spin spinning={loading} className='spin-color'>
        <div className="list-page" >
          <div className="row mt-2">
            <div className="col-md-12 p-0 d-flex justify-content-between">
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2 px-4" style={{ backgroundColor:"#2b78e4", fontSize: 20, color:"white" }}>
                <div className="w-100 d-flex justify-content-between">
                  <div className="col-6 d-flex justify-content-end"><span>Tổng tiền thực thu</span></div>
                  <div className="col-6 d-flex justify-content-end"><u>đ</u>&nbsp;{Number(totalRevenue).toLocaleString('en-US')}</div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="col-6 d-flex justify-content-end"><span>Cao nhất</span></div>
                  <div className="col-6 d-flex justify-content-end"><u>đ</u>&nbsp;{Number(highestRevenue).toLocaleString('en-US')}</div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <div className="col-6 d-flex justify-content-end"><span>Thấp nhất</span></div>
                  <div className="col-6 d-flex justify-content-end"><u>đ</u>&nbsp;{Number(lowestRevenue).toLocaleString('en-US')}</div>
                </div>
              </div>
              <div style={{width: "10%" }}></div>
              <div className="w-75 rounded d-flex flex-column justify-content-center align-items-center p-2 px-4" style={{ backgroundColor:"#009E0F", fontSize: 20, color:"white" }}>
                <div className="w-100 d-flex justify-content-between">
                  <span>Tổng số phiếu</span>
                  <div>{Number(totalOrder).toLocaleString('en-US')}</div>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <span>Tổng khách hàng</span>
                  <div>{Number(totalPatient).toLocaleString('en-US')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
})