import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../common/headerModal';
import { Alert, Checkbox, Pagination, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../common/constants';
import Notify, { NotifyStatus } from '../../../components/notify';
import NoContent from "../../../components/loading/noContent";
import * as VPService from '../services/VPService';
import { isnotnull } from '../../../libs/util';
import { setLoading } from '../../../libs/actions';

const UpdateHideServiceMulti = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        GetListBangGia();
    }, []);


    const _notify = useRef();
    const [dataProps,] = useState(props.data);
    const [listBangGia, setListBangGia] = useState([]);
    const [currentBangGia,] = useState(props.currentBangGia);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [newHideValue,] = useState(Number(dataProps.hienthi));

    const GetListBangGia = () => {
        VPService.DanhSachBangGiaTheoDichVu(dataProps.ma, newHideValue).then(result => {
            if (result.isSuccess) {
                let lstBangGia = result.data.filter(x => x.value != currentBangGia);
                setListBangGia(lstBangGia);
            }
        }).catch(err => {
        })
    }

    const OnChangeSelect = (index, checked) => {
        let dataBangGia = [...listBangGia];
        dataBangGia[index].checked = checked;
        setListBangGia(dataBangGia);
        CheckIndeterminate();
    }

    const OnChangeCheckAll = (check) => {
        let dataBangGia = [...listBangGia];
        if (!isnotnull(dataBangGia)) return;
        dataBangGia.map((bg, index) => {
            dataBangGia[index].checked = check;
        });
        setListBangGia(dataBangGia);
        CheckIndeterminate();
    }

    const CheckIndeterminate = () => {
        let existUncheck = listBangGia.some(x => x.checked == undefined || x.checked == false);
        let isCheckAll = listBangGia.filter(x => x.checked == true).length == listBangGia.length;
        let unCheckAll = listBangGia.filter(x => x.checked == true).length == 0;
        if (unCheckAll) setIndeterminate(false); else setIndeterminate(existUncheck);
        setCheckAll(isCheckAll);
    }

    const OnUpdateHide = () => {
        const data = {
            listBangGia: listBangGia.filter(x => x.checked),
            hide: newHideValue,
            serviceCode: dataProps.ma, 
        };
        setLoading(true);
        VPService.UpdateHideServiceMulti(data).then(result => {
            if (result.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Cập nhật thành công.", NotifyStatus.Success);
                GetListBangGia();
                setIndeterminate(false);
                setCheckAll(false);
            } else 
                _notify && _notify.current && _notify.current.Show(result.error.messageText ?? "Cập nhật lỗi, vui lòng thử lại sau.", NotifyStatus.Warning);
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
        })
    }

    const OnCloseModal = () => {
        _notify && _notify.current && _notify.current.Close();
        props.onCancel();
    }

    return (
        <div className="list-page">
            <HeaderModal title={`${newHideValue == "0" ? "Hiển thị" : "Ẩn"} dịch vụ trên nhiều bảng giá`}
                onClose={OnCloseModal}/>
            <Alert message={`Danh sách bên dưới, chỉ bao gồm các bảng giá có dịch vụ "${dataProps.ten}" đang ở trạng thái ${dataProps.hienthi == 0 ? "ẩn" : "hiển thị"}`} type='info'></Alert>
            <div className="list-page-body">
                <div className='mt-2 mb-2'>
                    <Notify ref={_notify} />
                </div>
                <div className="warrperStyleGroup mt-3">
                    <div className="grouplabel">
                        <div style={{ fontWeight: 'bold' }}>Thông tin dịch vụ</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-6'>
                            <label className='mita-title'>Mã dịch vụ:</label>
                            <label className='mita-value'>{dataProps.ma}</label>
                        </div>
                        <div className='col-6'>
                            <label className='mita-title'>Tên dịch vụ:</label>
                            <label className='mita-value'>{dataProps.ten}</label>
                        </div>
                    </div>
                </div>
                {
                    isnotnull(listBangGia) ?
                        <Fragment>
                            <div className='row mt-2 backgroundColorThemes d-flex align-items-center' style={{ height: 35 }}>
                                <div className='col-10 mita-title d-flex align-items-center'>Tên bảng giá</div>
                                <div className='col-2 mita-title'>
                                    <Checkbox
                                        indeterminate={indeterminate}
                                        checked={checkAll}
                                        onChange={(e) => OnChangeCheckAll(e.target.checked)}
                                    ></Checkbox>
                                </div>
                            </div>
                            <div style={{ overflowY: "auto" }}>
                                {
                                    listBangGia.map((bangGia, index) => {
                                        return (
                                            <div className='row rowCustom mt-1' key={index} onClick={() => OnChangeSelect(index, !bangGia.checked)}>
                                                <div className='col-10 d-flex align-items-center'>{index + 1}. {bangGia.label}</div>
                                                <div className='col-2'>
                                                    <Checkbox checked={bangGia.checked ?? false} onChange={(e) => { OnChangeSelect(index, e.target.checked) }}></Checkbox>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Fragment>
                        : <NoContent></NoContent>
                }
            </div>
            <div className="list-page-footer mt-2">
                <div className='d-flex justify-content-end' >
                    <button
                        className={`${BUTTON.THEME} mr-2`}
                        onClick={() => {
                            OnUpdateHide();
                        }}
                    ><i className='fa fa-save mr-2'></i>Lưu
                    </button>
                    <button
                        className={`${BUTTON.DANGER}`}
                        onClick={() => { OnCloseModal(); }}
                    ><i className='fa fa-sign-out-alt mr-2'></i>Đóng
                    </button>
                </div>
            </div>
        </div >
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(UpdateHideServiceMulti)