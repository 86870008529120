import { FORMAT_DATE, FORMAT_DATE_TIME, FORMAT_DATE_FULL, FORMAT_TIME_DATE_FULL } from "../configure/systemConfig";
import { isNullOrEmpty, renumber, renumberArray } from "./util";
import moment from "moment";

function incremented(index) {
    index++;
    return index;
}

function isdefined(value) {
    return value !== undefined;
}

function isnotnull(value) {
    return value !== undefined && value !== null &&
    ((typeof value === 'object' && Object.keys(value).length !== 0) ||
    (typeof value === 'string' && value.trim().length !== 0))
}

function moneyformat(value) {
    return value?.toLocaleString("en-US");
}

function dateformat(value) {
    return value && moment(value).format(FORMAT_DATE);
}

function dateformatfull(value) {
    return value && moment(value).format(FORMAT_DATE_FULL);
}

function formatDate(format, value) {
    return value && moment(value).format(format);
}

function datetimeformat(value) {
    return value && moment(value).format(FORMAT_TIME_DATE_FULL);
}

function datetimeformatshort(value) {
    return value && moment(value).format(FORMAT_DATE_TIME);
}

function ifequal(arg1, arg2, options) {
    if (typeof(arg1) == "number")
        return arg1 == arg2 ? options.fn(this) : options.inverse(this);
    else
        return (arg1.toUpperCase() == arg2.toUpperCase()) ? options.fn(this) : options.inverse(this);
}

function ifnotequal(arg1, arg2, options) {
    if (typeof(arg1) == "number")
        return arg1 !== arg2 ? options.fn(this) : options.inverse(this);
    else
        return (arg1.toUpperCase() !== arg2.toUpperCase()) ? options.fn(this) : options.inverse(this);
}

function ifLengthEqual(list, arg2, options) {
    return (list.length == arg2) ? options.fn(this) : options.inverse(this);
}

function ifLengthLessThan(list, arg2, options) {
    return (list.length < arg2 && list.length > 0) ? options.fn(this) : options.inverse(this);
}

function ifLengthGreaterThan(list, arg2, options) {
    return (list.length > arg2) ? options.fn(this) : options.inverse(this);
}

function reOrderListAndRemoveGroup(list, childrenListKeyName, comboKeyName, comboName, groupRemove, groupKeyName) {
    if (isNullOrEmpty(list)) return [];
    let newList = [];
    list.map(x => {
        if (x[childrenListKeyName] != undefined && x[childrenListKeyName] != null && x[childrenListKeyName].length > 0)
            x[childrenListKeyName].map(y => {
                let yItem = {...y, [comboKeyName]: x[comboName]};
                newList.push(yItem);
            })
        else newList.push(x);
    });
    if (!isNullOrEmpty(groupRemove))
        newList = newList.filter(x => x[groupKeyName] !== groupRemove);
    newList = renumberArray(newList);
    return newList;
}

function ifCondition(arg1, operator, arg2, options) {
    switch (operator) {
        case '==':
            return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (arg1 === arg2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (arg1 != arg2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (arg1 !== arg2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (arg1 < arg2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (arg1 <= arg2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (arg1 > arg2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (arg1 >= arg2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (arg1 && arg2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (arg1 || arg2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
}

function math(lvalue, operator, rvalue, options){
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);
        
    return {
        "+": lvalue + rvalue,
        "-": lvalue - rvalue,
        "*": lvalue * rvalue,
        "/": lvalue / rvalue,
        "%": lvalue % rvalue
    }[operator];
}

export default {
    incremented,
    isdefined,
    isnotnull,
    moneyformat,
    dateformat,
    dateformatfull,
    datetimeformat,
    datetimeformatshort,
    ifequal,
    ifnotequal,
    ifLengthEqual,
    ifLengthLessThan,
    ifLengthGreaterThan,
    ifCondition,
    formatDate,
    reOrderListAndRemoveGroup,
    math
}