import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Medicine';
export function GetListKho(id = 502) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/listKho?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuocBanLeTrongGoiThuoc(idgoithuoc,nguon,kho, noitru=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuocBanLeTrongGoiThuoc?idgoithuoc=${idgoithuoc}&nguon=${nguon}&kho=${kho}&noitru=${noitru}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListNhaCungCap(key = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnhacungcap?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListNguon() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsnguon`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListMaterial(key = '', type) {
    if (type === undefined || type === null) type = 1; // 1: thuốc, 2: Vật tư -- Phần này đẩy xuống BE cũng không xử lý!!!
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsmaterial?key=${key}&type=${type}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetPriceByMaterialID(id = '') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/giathuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getDanhSachToa(pageNumber = 1, pageSize = 100) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dstoa?pageNumber=${pageNumber}&pageSize=${pageSize}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachToaBanLe(key, sotoa, tungay, denngay, page = 1, size = 10, trangthai = '1') {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsthuocbanle?key=${key}&sotoa=${sotoa}&tungay=${tungay}&denngay=${denngay}&page=${page}&size=${size}&trangthai=${trangthai}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SearchDonThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/searchtoa`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function SearchDonDaXacNhap(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/SearchDonDaXacNhap`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SearchPhieuThuocDaXuat(tenbn,tungay,denngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/SearchPhieuThuocDaXuat?tenbn=${tenbn}&tungay=${tungay}&denngay=${denngay}`,).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListChiTietHoaDon(ngay, idToathuoc, pageNumber = 1, pageSize = 100) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dschitiettoa?ngay=${ngay}&id=${idToathuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachChiTietToaBS(ngay, idToathuoc, maql, pageNumber = 1, pageSize = 100) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachChiTietToaBS?ngay=${ngay}&id=${idToathuoc}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetSoPhieu(ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/sophieuhoadon?ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoaDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuhoadon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuXacNhanHoaDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuXacNhanHoaDon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TaoPhieuThuocMoi(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/TaoPhieuThuocMoi`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuXacNhanHoaDonBanLe(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuXacNhanHoaDonBanle`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoaDonBanLe(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luuhoadonbanle`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function NhapKho(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/nhapkho`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function editNhapKho(idphieu, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/editNhapKho/${idphieu}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteNhapKho(idphieu, ngay, thuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete/${idphieu}?ngaynhap=${ngay}&thuoc=${thuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinHoaDonNhap(ngay, id, thuoc = true) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/detailnhapkho?ngay=${ngay}&id=${id}&thuoc=${thuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SearchNhapKho(fromdate, todate, key, makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/searchnhapkho?fromdate=${fromdate}&todate=${todate}&key=${key}&makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function SearchSanPham(fromdate, todate, key, makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/searchsanpham?fromdate=${fromdate}&todate=${todate}&key=${key}&makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachNuocSanXuat(mabd) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/nuocsx?mabd=${mabd}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function NuocSanXuatThuoc() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/nuocsxthuoc`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinHoaDon(ngaytao, id, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/lstdetailbanle?ngaytao=${ngaytao}&id=${id}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThanhToanHoaDonBanLe(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThanhToanHoaDonBanLe?id=${id}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLoaiThuoc(key, filter, page, size, vattu = false) {

    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsloaithuoc?key=${key}&filter=${filter}&page=${page}&size=${size}&vattu=${vattu}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachLoaiDMBD(vattu = false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/listloaidmbd?vattu=${vattu}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/updatethuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TaoMoiThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/createthuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachNhomThuoc() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/nhomthuoc`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachLoaiTheoNhom(nhom, key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loaithuoctheonhom?nhom=${nhom}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoMoiLoaiThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/createloaithuoc`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachKBTD(key, makho, ngay, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/khaibaotondau?key=${key}&makho=${makho}&ngay=${ngay}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListKhoKhaiBaoTonDau() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/listkhokhaibaotondau?`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListThuoc(makho) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuoc_theokho?makho=${makho}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/detailthuoc?mabd=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function AddThuocConfigure(key, nhom) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/addThuocConfigure?key=${key}&nhom=${nhom}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuKhaiBaoTonDau(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luukhaibaotondau`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachGiaVP(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsgiavienphi?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachGiaVPTheoKhoa() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/dsgiavienphitheokhoa?`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function CapNhatVienPhiKhoa(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/capnhatvienphikhoa`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function HangSanXuat(key, page, size) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/hangsanxuat?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoHangSanXuat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taohsx`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailHSX(id){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/getdetailhsx?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    })
}

export function updateHide(mabd, status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/UpdateHide?mabd=${mabd}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DonViTinh(key) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/donvitinh?key=${key}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function XoaToaBanLe(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaToaBanLe?id=${id}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaToaTheoDon(id, ngay, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/XoaToaTheoDon?id=${id}&ngay=${ngay}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaMotThuocBanLe(id, ngay, mabd) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/XoaMotThuocBanLe?id=${id}&ngay=${ngay}&mabd=${mabd}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaPhieuBanLeDaThanhToan(id, ngay) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/XoaPhieuBanLeDaThanhToan?id=${id}&ngay=${ngay}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CloneToaThuocBS(id, ngay, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CloneToaThuocBS?id=${id}&ngay=${ngay}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DangThuoc() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DangThuoc`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuVatTuPTTT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuVatTuPTTT`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function TaoDonViTinh(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/taodonvitinh`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LichSuDungVatTu(idpttt,maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LichSuDungVatTu?idpttt=${idpttt}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ThongKeVatTuTieuHao(dateFrom,dateTo,key,page,size,tinhphi) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ThongKeVatTuTieuHao?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&tinhphi=${tinhphi}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function GetListGoiThuoc(key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListGoiThuoc?key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListGoiThuocCT(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListGoiThuocCT?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteGoiThuoc(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteGoiThuoc?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteGoiThuocCT(idgoithuoc,idthuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteGoiThuocCT?idgoithuoc=${idgoithuoc}&idthuoc=${idthuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateGoiThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateGoiThuoc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateGoiThuocCT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateGoiThuocCT`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateGoiThuocCT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateGoiThuocCT`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateGoiThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateGoiThuoc`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function importMedicinesList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/ImportMedicines`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function LuuCapThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luucapthuoc`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuoc(doituong,ngay,key,nguon,kho)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuoc?doituong=${doituong}&ngayvv=${ngay}&key=${key}&nguon=${nguon}&kho=${kho}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachThuocBanle(doituong,key,nguon,kho, noitru=false)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachthuocbanle?doituong=${doituong}&&key=${key}&nguon=${nguon}&kho=${kho}&noitru=${noitru}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachCachDung(key)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachcachdung?key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};


export function DanhSachCapThuocBenhNhan(id,mabn,maql,ngay,makp,loaiKham,isBacsi=false) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachcapthuocbenhnhan?id=${id}&mabn=${mabn}&maql=${maql}&ngay=${ngay}&makp=${makp}&loaikham=${loaiKham}&isBacsi=${isBacsi}`).then(respone => {
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CapNhatLoaiThuoc(id, data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/CapNhatLoaiThuoc?id=${id}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachBaoQuan()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachBaoQuan`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetMedicineInstruction(medicineCode, keyword)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetMedicineInstruction?code=${medicineCode}&key=${keyword}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getThongTinDonThuoc(maDon) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetThongTinDonThuoc?id=${maDon}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListMedicine(dataFilter) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListMedicine`, dataFilter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListCachDungThuoc(medicineCode, keyword)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetCachDungThuoc?code=${medicineCode}&key=${keyword}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function saveDonThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveDonThuoc`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getDanhSachDonThuoc(maQuanLy, maDichVu)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDanhSachDonThuoc?managermentId=${maQuanLy}&serviceId=${maDichVu}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function deleteDonThuoc(maDonThuoc) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteDonThuoc/${maDonThuoc}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function printDonThuoc(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/PrintPrescription`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function savePrescriptionSample(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SavePrescriptionSample`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteMedicine(maDonThuoc, idThuoc, maDichVu) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteMedicine?ma=${maDonThuoc}&id=${idThuoc}&service=${maDichVu}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListPrescriptionSample(dataFilter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListPrescriptionSample`, dataFilter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function updatePrescriptionSample(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdatePrescriptionSample`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};