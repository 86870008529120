import * as ApiCaller from '../libs/httpRequests';

const prefix = 'NotificationMessage';

export function GetListNotificationMessage()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetNotificationMessage`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateStatusNotificationMessage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateStatusNotificationMessage`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListUser(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListUser`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CreateNotificationMessage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateNotificationMessage`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetSentNotificationMessage(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetSentNotificationMessage`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteSentNotificationMessage(id)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteSentNotificationMessage/${id}`,).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DeleteSentNotificationMessageFilter(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/DeleteSentNotificationMessageFilter`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getDefaultConfigInfo()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DefaultConfigInfo`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};