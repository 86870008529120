import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Report';

export function getAverageRevenueSumary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueSumary`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueListForChart(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueListForChart`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/AverageRevenueList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getAverageRevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/AverageRevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueSumary(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueSumary`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueListForChart(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueListForChart`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SpentRevenueList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getSpentRevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/SpentRevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};

//#region Thống kê doanh thu CDHA
export function getCDHARevenueListForChartLoai(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueListForChartLoai`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getCDHARevenueListForChartVienPhi(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueListForChartVienPhi`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getCDHARevenueGetList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CDHARevenueGetList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getPatientDetailServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/PatientDetailServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getCDHARevenueListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/CDHARevenueListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function SummaryRegisterServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SummaryRegisterServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DetailRegisterServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DetailRegisterServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function RegisterServiceListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/RegisterServiceListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function SummaryPerformanceServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SummaryPerformanceServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DetailPerformanceServiceList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DetailPerformanceServiceList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function PerformanceServiceListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/PerformanceServiceListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function ExportDailyReportRevenue(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportDailyReportRevenue`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion

//#region ConsultantReport (Báo cáo nhân viên tư vấn)
export function getConsultantReportList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ConsultantReportList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getConsultantReportListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ConsultantReportListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion
//#region ServiceSalesmanReport (Báo cáo nhân viên kinh doanh) (Báo cáo dịch vụ đơn vị gửi)
export function getServiceSalesmanReportList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ServiceSalesmanReportList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getServiceSalesmanReportListExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ServiceSalesmanReportListExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
export function ThongKeTheoDichVuBenhNhanImprove(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThongKeTheoDichVuBenhNhanImprove`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ExportThongKeTheoDichVuBenhNhanImprove(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportThongKeTheoDichVuBenhNhanImprove`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function RevenueReportByPatient(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReportRevenueByPatient`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportRevenueReportByPatient(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportReportRevenueByPatient`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PrintRevenueReportByPatient(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/PrintReportRevenueByPatient`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function RevenueReportByService(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReportRevenueByService`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportReportRevenueByService(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportReportRevenueByService`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetRevenueByPaymentType(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetRevenueByPaymentType`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
//#endregion

//#region Thống kê người lấy mẫu
export function getThongKeNguoiLayMauList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThongKeNguoiLayMauList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getThongKeNguoiLayMauChiTietList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThongKeNguoiLayMauChiTietList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function getThongKeNguoiLayMauChiTietExport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ThongKeNguoiLayMauChiTietExport`, data)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};
//#endregion

export function ReportCoporationDiscount(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ReportCoporationDiscount`,data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportReportCoporationDiscount(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/ExportReportCoporationDiscount`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};