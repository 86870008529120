import React, { Fragment, useEffect, useRef, useState } from 'react'
import Notify, { NotifyStatus } from '../../../components/notify';
import { Select, Drawer, Tooltip, DatePicker, Button, Checkbox } from 'antd';
import { Polar } from 'react-chartjs-2';
import PromotionDetail from "./promotionDetail";
import * as PromotionalCampagin from '../../../../src/services/PromotionServices';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { connect } from 'react-redux';
import * as AuthServices from '../../../services/authServices';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import TagVertical, { COLOR } from '../../../configureHIS/common/TagVertical';
import { BUTTON } from '../../../configureHIS/common/constants';
import * as SystemConfig from "../../../configure/systemConfig";
import VoucherHistory from './voucherHistory';
import SiteFilter from '../../../configureHIS/common/siteFilter';

const { Option } = Select;
const { RangePicker } = DatePicker;
const MAX_PROMOTION = 5;
const DEFAULT_COLORS_LIST = [
    "#4C4D8D",
    "#C86569",
    "#9C6177",
    "#86AFBA",
    "#F7C564",
    "#63506F",
    "#FFF3CD",
    "#2A5C5E",
    "#2A5C00",
    "#2A97AB",
];
var task = {};

function ThongKeApDungMaVoucher(props) {
    const notiRef = useRef(null);
    const innerRef = useRef(null);
    const listRef = useRef()
    const refDrawer = useRef();
    const [site, setSite] = useState(props.availableSites);
    const [availableSites, setAvailableSites] = useState([]);
    const [dateSelected, setDateSelected] = useState([null, null]);
    const [isChart, setIsChart] = useState(true);
    const [optionTypes] = useState([
        {
            label: "Áp dụng nhiều",
            value: 1,
            mutiSelect: false,
        },
        {
            label: "Gần đây",
            value: 2,
            mutiSelect: false,
        },
        {
            label: "Tự chọn",
            value: 3,
            mutiSelect: true,
        }
    ]);
    const [optionTypeSelected, setOptionTypeSelected] = useState(1);
    const [typeSelectedAfterClickFilter, setTypeSelectedAfterClickFilter] = useState(1);
    const [optionNameSelected, setOptionNameSelected] = useState([]);
    const [listPromotions, setListPromotions] = useState([]);
    const [listAllPromotions, setListAllPromotions] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [randomColors, setRandomColors] = useState(false);
    const [listNguaBa] = useState([
        {
            label: "Bảng màu mặc định",
            value: false,
        },
        {
            label: "Bảng màu ngẫu nhiên",
            value: true,
        },
    ]);
    const [page, setPage] = useState(1);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSummarize, setDataSummarize] = useState([
        {
            title: "Tổng tiền KM",
            color: COLOR.Primary,
            value: 0,
        },
        {
            title: "Tổng số khách hàng",
            color: COLOR.Success,
            value: 0,
        },
        {
            title: "Tổng số phiếu",
            color: COLOR.Warning,
            value: 0,
        },
    ]);
    const [allSite, setAllSite] = useState(false);
    const [priority, setPriority] = useState(1);

    const generateColor = (numberOfColor = MAX_PROMOTION) => {
        let colorArray = DEFAULT_COLORS_LIST;
        if (randomColors) {
            colorArray = [];
            for (let i = 0; i < numberOfColor; i++) {
                let o = Math.round, r = Math.random, s = 255;
                let clr = 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ', 0.6)';
                colorArray.push(clr);
            }
        }
        return colorArray;
    }

    const createChartData = (list = listPromotions) => {
        if (list && list.length > 0) {
            let _listPromotions = list;
            _listPromotions = _listPromotions.slice(0, MAX_PROMOTION); // Lấy MAX_PROMOTION items trong list (Trường hợp list có nhiều hơn MAX_PROMOTION items)
            let promotionNameList = Object.keys(_listPromotions).map((key) => _listPromotions[key].promotionName);
            let usedVouchersCountList = Object.keys(_listPromotions).map((key) => priority !== 1 ? _listPromotions[key].totalPatients : _listPromotions[key].totalPaid);
            setChartData({
                datasets: [{
                    data: usedVouchersCountList,
                    backgroundColor: generateColor(),
                }],
                labels: promotionNameList,
            });
        } else {
            setChartData([]);
        }
    };

    const loadListPromotionsByFilter = (listSelected = optionNameSelected) => {
        PromotionalCampagin.getListPromotionsByFilter(
            optionTypeSelected, MAX_PROMOTION, listSelected, page, site, allSite, priority,
            dateSelected && dateSelected[0] && dateSelected[0].format(SystemConfig.FORMAT_YYYYMMDD),
            dateSelected && dateSelected[1] && dateSelected[1].format(SystemConfig.FORMAT_YYYYMMDD)).then(res => {
                if (res.isSuccess) {
                    setListPromotions(res.data.items);
                    setTotalRecords(res.data.totalCount);
                } else {
                    setListPromotions([]);
                    setTotalRecords(0);
                }
            }).catch(() => { })
    }

    const loadListPromotions = (key = "") => {
        let sortingAndPagingModel = { pageIndex: page, pageSize: 10 };
        let filterModel = { Keyword: key };
        PromotionalCampagin.getListPromotions(sortingAndPagingModel, filterModel).then(res => {
            if (res.isSuccess) {
                setListAllPromotions(res.data.items);
            }
        }).catch(() => { })
    }

    const resetState = () => {
        setOptionNameSelected([]);
        setPage(1);
    }

    const onCloseDrawer = () => {
        setVisibleDrawer(false);
        refDrawer && refDrawer.current && refDrawer.current.reload();
    }

    useEffect(() => {
        createChartData();
    }, [randomColors, listPromotions]);

    useEffect(() => {
        if (isChart) {
            loadListPromotionsByFilter();
        } else {
            listRef && listRef.current && listRef.current.getList();
        }
        getVoucherUsedSummarize();
    }, [page])

    // const refreshCanvas = () => {
    //     const parentWithClass = document.getElementsByClassName("chartjs-size-monitor");
    //     for (var i = 0; i < parentWithClass.length; i++) {
    //         parentWithClass[i].style["position"] = 'relative';
    //     }
    //     setTimeout(function () {
    //         for (var i = 0; i < parentWithClass.length; i++) {
    //             parentWithClass[i].style["position"] = 'absolute';
    //         }
    //     }, 0);
    // }

    // useEffect(() => {
    //     const chartParent = innerRef.current;
    //     chartParent.addEventListener("mousemove", refreshCanvas);
    //     return () => {
    //         chartParent.addEventListener("mousemove", refreshCanvas);
    //     };
    // }, []);

    useEffect(() => {
        getAvaiableSites();
        getVoucherUsedSummarize();
    }, []);

    const getAvaiableSites = () => {
        AuthServices.getAvailableSites().then((res) => {
            if (res.isSuccess) {
                setAvailableSites(res.data);
            }
        })
    };

    useEffect(() => {
        loadDataComponent();
    }, [isChart])

    const loadDataComponent = () => {
        notiRef && notiRef.current && notiRef.current.Close();
        setTypeSelectedAfterClickFilter(optionTypeSelected);
        getVoucherUsedSummarize();
        if (isChart) {
            setPage(1);
            loadListPromotionsByFilter();
        } else {
            listRef && listRef.current && listRef.current.getList();
        }
    }

    const getVoucherUsedSummarize = (listSelected = optionNameSelected) => {
        PromotionalCampagin.getVoucherUsedSummarize(
            optionTypeSelected, MAX_PROMOTION, listSelected, page, site, allSite, priority,
            dateSelected && dateSelected[0] && dateSelected[0].format(SystemConfig.FORMAT_YYYYMMDD),
            dateSelected && dateSelected[1] && dateSelected[1].format(SystemConfig.FORMAT_YYYYMMDD)).then(res => {
                if (res.isSuccess) {
                    let data = res.data;
                    const colorList = [COLOR.Primary, COLOR.Success, COLOR.Warning];
                    data = data.map((x, index) => ({
                        title: x.label, color: colorList[index],
                        value: x.label.toLowerCase().includes("thực thu".toLowerCase()) || x.label.toLowerCase().includes("tiền".toLowerCase())
                            ? <span><u>đ</u>&nbsp;{x.value.toLocaleString('en-US')}</span>
                            : x.value.toLocaleString('en-US')
                    }));
                    setDataSummarize(data);
                }
            }).catch(() => { })
    }

    useEffect(() => {
        if (site.length === props.availableSites.length)
            setAllSite(true);
        else setAllSite(false)
    }, [site]);

    return (
        <Fragment>
            <div className="containerChilderWrapper" ref={innerRef}>
                <HeaderModal title="Thống kê áp dụng mã CTKM" />
                <Notify ref={notiRef} id="promotion-notify" />
                <div className="row mt-2">
                    <SiteFilter updateSeletedSite={(listSite) => { setSite(listSite) }} />
                    <div className="col-md-4 mt-2">
                        <label>Lựa chọn</label>
                        <Select
                            id="promotion-select-type"
                            className='w-100'
                            value={optionTypeSelected}
                            onChange={(e) => { setOptionTypeSelected(e); resetState() }}>
                            {
                                optionTypes && optionTypes.map((item, index) => {
                                    return (
                                        <Option key={`opt-type-${index}`} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="col-md-4 mt-2">
                        <label>Ngày áp dụng</label>
                        <RangePicker
                            id="promotion-select-date"
                            className='w-100'
                            locale={viVN}
                            value={dateSelected}
                            format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]}
                            onChange={(e) => { setDateSelected(e); }}>
                        </RangePicker>
                    </div>
                    {/* {isChart && <div className="col-md-4 mt-2">
                        <label>Bảng màu</label>
                        <div className='w-100 d-flex justify-content-between'>
                            {
                                listNguaBa && listNguaBa.map((item, index) => {
                                    return (
                                        <Tooltip title={randomColors && item.value && "Bấm thêm lần nữa để đổi màu"} placement="bottom">
                                            <button
                                                id={`promotion-btn-nguaba-${index}`}
                                                key={`opt-nguaba-${index}`}
                                                style={{ width: "49%" }}
                                                className={`${index > 0 && "ml-1"} btn btn-${randomColors === item.value ? "color" : "outline-secondary"}`}
                                                onClick={() => {
                                                    setRandomColors(item.value);
                                                    createChartData();
                                                }}
                                            >{item.label}</button>
                                        </Tooltip>
                                    )
                                })
                            }
                        </div>
                    </div>} */}
                    <div className="col-md-4 mt-2">
                        <label>Ưu tiên</label>
                        <Select
                            id="promotion-select-priority"
                            className='w-100'
                            value={priority}
                            onChange={(e) => { setPriority(e); }}>
                            <Option key={1} value={1}>{`Thực thu > Khách hàng > SL Phiếu`}</Option>
                            <Option key={2} value={2}>{`Khách hàng > Thực thu > SL Phiếu`}</Option>
                        </Select>
                    </div>
                    {optionTypes && optionTypes.find(x => x.value === optionTypeSelected).mutiSelect &&
                        <div className="col-md-12 mt-2">
                            <label>Chương trình khuyến mại</label>
                            <Select
                                id="promotion-select-name"
                                className='w-100'
                                mode="multiple"
                                maxTagCount={4}
                                maxLength={MAX_PROMOTION}
                                maxTagTextLength={25}
                                maxTagPlaceholder={(exceedList) => `và +${exceedList.length} chương trình khác`}
                                allowClear
                                placeholder={`Chọn tên chương trình (Cho phép chọn nhiều, tối đa ${MAX_PROMOTION})`}
                                disabled={optionTypes && optionTypes.find(x => x.value === optionTypeSelected).mutiSelect === false}
                                value={optionNameSelected}
                                onChange={(e) => {
                                    if (e && e.length > MAX_PROMOTION) {
                                        notiRef.current.Show(`Chỉ được phép chọn tối đa ${MAX_PROMOTION} chương trình`, NotifyStatus.Warning)
                                        return;
                                    } else {
                                        setOptionNameSelected(e);
                                        // loadListPromotionsByFilter(e);
                                    }
                                    notiRef && notiRef.current && notiRef.current.Close();
                                }}
                                onSearch={(e) => {
                                    if (task)
                                        clearTimeout(task);
                                    task = setTimeout(() => {
                                        loadListPromotions(e);
                                    }, 800);
                                }}
                                getPopupContainer={trigger => trigger.parentNode}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    listAllPromotions && listAllPromotions.map((item, index) => {
                                        return (
                                            <Option key={`opt-name-${index}`} value={item.id}>{item.promotionName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>}
                </div>
                <div className="row">
                    <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                        <div className="col-auto">
                            <span>Hiển thị</span>&nbsp;
                            <Select
                                id="promotion-select-chrtlst"
                                style={{ width: 200 }}
                                value={isChart}
                                onChange={(value) => {
                                    setIsChart(value);
                                }}>
                                <Option key={1} value={true}><i className="fas fa-chart-bar mr-1" />Biểu đồ</Option>
                                <Option key={2} value={false}><i className="fas fa-list mr-1" />Danh sách</Option>
                            </Select>
                        </div>
                    </div>
                    <div className='col-auto mt-2 ml-2'>
                        <Button className={BUTTON.THEME} onClick={() => { loadDataComponent() }}><i className="fas fa-chart-bar mr-1" />Thống kê</Button>
                    </div>
                </div>
                <TagVertical data={dataSummarize} />
                {isChart ? <Fragment>
                    <div className="row mt-2" style={{ height: "80vh" }}>
                        <div className="col-md-12">
                            <Polar
                                data={chartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    legend: {
                                        align: 'center',
                                        labels: {
                                            boxWidth: 20,
                                            boxHeight: 10
                                        }
                                    },
                                    title: {
                                        display: true,
                                        fontSize: 13,
                                        text: `Biểu đồ thống kê sử dụng voucher theo ${MAX_PROMOTION} chương trình ${optionTypes.find(x => x.value === typeSelectedAfterClickFilter).label.toLowerCase()}`
                                    },
                                    tooltips: {
                                        callbacks: {
                                            label: function (t, d) {
                                                var xLabel = d.labels[t.index];
                                                var yLabel = t.yLabel.toLocaleString("en-US");
                                                return xLabel + ': ' + yLabel;
                                            },
                                        },
                                        // backgroundColor: "rgba(255, 99, 139, 0.8)",
                                    },
                                    onClick: (event, element) => {
                                        if (element.length > 0) {
                                            let found = listPromotions[element[0]._index];
                                            if (found !== undefined) {
                                                setSelectedPromotion(found);
                                                setVisibleDrawer(true);
                                                refDrawer && refDrawer.current && refDrawer.current.loadDetail();
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {(optionTypes && !optionTypes.find(x => x.value === optionTypeSelected).mutiSelect && isChart) &&
                        <div className="text-center mt-3">
                            <Tooltip title={`${MAX_PROMOTION} chương trình trước đó`} placement="left">
                                <button id="promotion-btn-prev" className="btn btn-color btn-sm btn-circle"
                                    disabled={page === 1 || totalRecords === MAX_PROMOTION}
                                    onClick={() => setPage(page - 1)}>&#8249;</button>
                                <label className='ml-2'>{page}</label>
                            </Tooltip>
                            {page < (totalRecords / MAX_PROMOTION) && totalRecords !== MAX_PROMOTION &&
                                <Tooltip title={`${MAX_PROMOTION} chương trình tiếp theo`} placement="right">
                                    <button id="promotion-btn-next" className="btn btn-color btn-sm btn-circle ml-2"
                                        disabled={page >= (totalRecords / MAX_PROMOTION) || totalRecords === MAX_PROMOTION}
                                        onClick={() => setPage(page + 1)}>&#8250;</button>
                                </Tooltip>}
                        </div>}
                </Fragment>
                    :
                    <VoucherHistory
                        ref={listRef}
                        site={site}
                        allSite={allSite}
                        dateSelected={dateSelected}
                        type={optionTypeSelected}
                        maximumPromotion={MAX_PROMOTION}
                        listPromotion={optionNameSelected}
                        page={page}
                        priority={priority}
                    />}
            </div>
            <Drawer headerStyle={{ display: "none", visibility: "hidden" }}
                placement="right" width={"75vw"}
                onClose={() => onCloseDrawer()} visible={visibleDrawer}>
                <PromotionDetail data={selectedPromotion} site={site} allSite={allSite} dateSelected={dateSelected} onClose={() => onCloseDrawer()} ref={refDrawer} />
            </Drawer>
        </Fragment >
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        availableSites: state.global.availableSites
    })
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(ThongKeApDungMaVoucher);