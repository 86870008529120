import { Input, Select } from 'antd';
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import TextArea from 'antd/lib/input/TextArea';
import { isValidPhone } from '../../commons/utils';
import { isNullOrEmpty } from '../../libs/util';
import * as PatientService from '../../services/patientService';

const { Option } = Select;

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        checkValidInfo: () => {
            return checkValidInfo();
        },
        getInfo: () => {
            const data = {
                HoTen: hoTen,
                DiaChi: diaChi,
                DienThoai: dienThoai,
                QuanHe: quanHe,
            }
            return data;
        },
        setInfo: (info) => {
            setInfo(info);
        },
        clearData: () => {
            resetState();
        },
        hideNotify: () => {
            notiRef && notiRef.current && notiRef.current.Close();
        }
    }));
    const notiRef = useRef(null);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isUpdate,] = useState(props.isUpdate ?? false);
    const [maBN, setMaBN] = useState();
    const [hoTen, setHoTen] = useState("");
    const [dienThoai, setDienThoai] = useState("");
    const [quanHe, setQuanHe] = useState("");
    const [diaChi, setDiaChi] = useState("");
    const [danhSachQuanHe,] = useState([
        {
            key: "father",
            value: "father",
            title: "Bố",
        },
        {
            key: "mother",
            value: "mother",
            title: "Mẹ",
        },
        {
            key: "husband",
            value: "husband",
            title: "Chồng",
        },
        {
            key: "wife",
            value: "wife",
            title: "Vợ",
        },
        {
            key: "sibling",
            value: "sibling",
            title: "Anh/chị/em",
        },
        {
            key: "other",
            value: "other",
            title: "Khác",
        },
    ]);

    useEffect(() => {
        if (props.maBN) {
            setMaBN(props.maBN);
            getPatientRelaInfo(props.maBN);
        }
    }, [props.maBN]);

    const getPatientRelaInfo = (maBN) => {
        PatientService.getRelationshipInfo(maBN).then(res => {
            if (res.isSuccess) {
                setInfo(res.data);
            }
        }).catch(err => {

        })
    }

    const setInfo = (info) => {
        if (info) {
            setHoTen(info.hoTen);
            setDiaChi(info.diaChi);
            setDienThoai(info.dienThoai);
            setQuanHe(info.quanHe);
        }
    }

    const checkValidInfo = () => {
        let isError = true;
        let errMsg = "";
        if (isNullOrEmpty(hoTen) && !isNullOrEmpty(quanHe)) errMsg += "Chưa nhập tên người thân bệnh nhân.\n";
        if (!isNullOrEmpty(hoTen) && isNullOrEmpty(quanHe)) errMsg += "Chưa nhập mối quan hệ.\n";
        if (!isNullOrEmpty(dienThoai) && !isValidPhone(dienThoai)) errMsg += "Số điện thoại người thân không hợp lệ.\n";
        if (!isNullOrEmpty(errMsg)) {
            notiRef.current.Show(errMsg, NotifyStatus.Warning);
        } else isError = false;
        return isError;
    }

    const resetState = () => {
        setHoTen();
        setDiaChi();
        setDienThoai();
        setQuanHe();
        notiRef.current.Close();
    }
    
    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-12 mt-2'>
                    <Notify ref={notiRef} />
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='mita-title'>Họ và tên</label>
                    {
                        isUpdate ?
                            <Input
                                disabled={!isUpdate}
                                value={hoTen}
                                onChange={(e) => {
                                    setHoTen(e.target.value);
                                }}
                            ></Input>
                            : <label className='mita-value'>{hoTen}</label>
                    }
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='mita-title'>Điện thoại</label>
                    {
                        isUpdate ?
                            <Input
                                disabled={!isUpdate}
                                value={dienThoai}
                                onChange={(e) => {
                                    setDienThoai(e.target.value);
                                }}
                            ></Input>
                            : <label className='mita-value'>{dienThoai}</label>
                    }
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='mita-title'>Quan hệ</label>
                    <div className='w-100'>
                        {
                            isUpdate ?
                                <Select
                                    className='w-100'
                                    allowClear
                                    disabled={!isUpdate}
                                    value={quanHe}
                                    onChange={(e) => {
                                        setQuanHe(e);
                                    }}>
                                    {danhSachQuanHe && danhSachQuanHe.map((rel, index) => {
                                        return (
                                            <Option key={rel.key} value={rel.value}>{rel.title}</Option>
                                        )
                                    })}
                                </Select>
                                : <label className='mita-value'>{quanHe}</label>
                        }
                    </div>
                </div>
                <div className='col-md-12 mt-2'>
                    <label className='mita-title'>Địa chỉ</label>
                    <div className='w-100'>
                        {
                            isUpdate ?
                                <TextArea
                                    disabled={!isUpdate}
                                    value={diaChi}
                                    onChange={(e) => {
                                        setDiaChi(e.target.value);
                                    }}>
                                </TextArea>
                                : <label className='mita-value'>{diaChi}</label>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
})