import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, InputNumber, Button, Select, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../libs/actions';
import { ValidationCustom } from '../../../commons/validationCustom';
// import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import MutilMedia from '../../../components/MitaComponents/UploadFile/mitaUploadFile';
import MitaChoosePrintTemplate from '../../../components/MitaComponents/mitaChoosePrintTemplate';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as PacsService from '../../../services/pascService';
// import * as PrintTemplateService from '../../services/printTemplateService';
import Handlebars from "handlebars";
import { isNullOrEmpty } from "../../../libs/util";
import handlebarHelper from "../../../libs/handlebarHelper";

export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  // const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [data, setData] = useState();
  const [listImageIds, setListImageIds] = useState([]);
  const [printTemplateId, setPrintTemplateId] = useState();
  const [headerText, setHeaderText] = useState(`In - ${props.data.serviceName} - ${props.data.patientName}`);
  //#endregion 
  // useEffect(() => {
  //   setHeaderText(`In - ${props.data.serviceName} - ${props.data.patientName}`);
  // }, [props.data]);

  const print = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
      return;
    }
    Actions.setLoading(true);
    notiRef && notiRef.current.Close();
    let data={
      appointmentId: props.data.appointmentId,
      printTemplateId: printTemplateId,
      listUploadIds: listImageIds,
    };
    PacsService.printPacs(data).then(result => {
      if (result.isSuccess) {
        if (isNullOrEmpty(result.data.template)) {
          Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
          return;
        } else {
          const template = Handlebars.compile(result.data.template);
          Handlebars.registerHelper(handlebarHelper);
          Actions.DoPrint(template(result.data));
        }
      }
      else {
          notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
          notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
    });
  }

  const printToPdf = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
      return;
    }
    Actions.setLoading(true);
    notiRef && notiRef.current.Close();
    //     PrintService.PrintPASC(idchidinh, maql, idkt, mabn, ngay, roomId).then((result) => {
    //         Actions.DoPrint(result.item)
    //     })
    // public string AppointmentId { get; set; }
    // public Guid PrintTemplateId { get; set; }
    // public List<Guid> ListUploadIds { get; set; }
    let data={
      appointmentId: props.data.appointmentId,
      printTemplateId: printTemplateId,
      listUploadIds: listImageIds,
    };

    // generateHtmlToPdf('');

    PacsService.printPacsHtmlToPdf(data).then(result => {
      if (result.isSuccess) {
        if (isNullOrEmpty(result.data.template)) {
          Actions.openMessageDialog("Không có thông tin để xuất pdf", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
          return;
        } else {
          const template = Handlebars.compile(result.data.template);
          Handlebars.registerHelper(handlebarHelper);
          // Actions.DoPrint(template(result.data));
          generateHtmlToPdf(template(result.data));
        }
      }
      else {
          notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
          notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
    });
  }

  function generateHtmlToPdf(html) {
    let loai = '';
    // 3	X-QUANG
    // 1	SIÊU ÂM
    // 4	THĂM DÒ CHỨC NĂNG
    // 2	NỘI SOI
    // 30	Nội sôi dạ dày - trực tràng
    // 46	Điện tim
    // 40	ĐIỆN CƠ
    // đúng ra cho BE trả ra code hay gì đó theo loại. nhưng bị dí quá set cứng vậy cho nhanh.
    switch (props.data.pacsType) {
      case '1':
        loai='SA';
        break;
      case '2':
        loai='XQ';
        break;
      case '3':
        loai='XQ';
        break;
      case '4':
        loai='TDCN';
        break;
      case '40':
        loai='DC';
        break;
      case '46':
        loai='DT';
        break;
      default:
        break;
    }
    let data={
      appointmentId: props.data.appointmentId,
      html: html,
      loai: loai,
      patientCode: props.data.patientCode,
      patientName: props.data.patientName,
      yob: props.data.yob,
    };
    PacsService.printPacsToPdf(data)
      .then((response) => {
        let res = response.clone();
        res.json().then((t) => {
          if (!t.isSuccess) 
            notification.error({ message: t.error.extensionData })
        }).catch(err => {
          response.blob().then((blob) => {
            let urlTemp = window.URL.createObjectURL(blob);
            hiddenLink.current.href = urlTemp; //Image Base64 Goes here                         
            hiddenLink.current.download = `Ket qua ${loai}_${moment().format('DDMMYY')}_${props.data.patientCode}_${props.data.patientName}_${props.data.yob}.pdf`;
            hiddenLink.current.click();
          })
        })
        Actions.setLoading(false);
      }).catch(err => {
        Actions.setLoading(false);
      })
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row">
                <div className="col-md-12 d-flex flex-column">
                  <MitaChoosePrintTemplate
                    componentId={'PrintPasc'}
                    displayMode={'select'}
                    changeSelectedId={(id) => { setPrintTemplateId(id); }}
                  />
                  {validator.current.message("Template in", printTemplateId, "required")}
                </div>
              </div>
              <div className="row mt-2" style={{ lineHeight: "20px" }}>
                <MutilMedia col={12}
                  featureName="cdha"
                  componentKey="CDHA-Multimedia"
                  refId={props.data.appointmentId.toString()}
                  isEdit={false}
                  showCopy={false}
                  showSelect={true}
                  defaultSelectAll={true}
                  layout="SmallIcons"
                  fileExtentions={".tif.tiff.bmp.jpg.jpeg.gif.png"}
                  changeSelectedIds={(ids) => { setListImageIds(ids); }}
                >
                </MutilMedia>
              </div>
              <div className="row">
                <div className="col-md-6 d-flex flex-column">
                  {/* <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Tên template</label>
                  </div>
                  <Input
                    value={printTemplateName}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setPrintTemplateName(e.target.value)}
                  ></Input>
                  {validator.current.message("Tên template", printTemplateName, "required")} */}
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
                <button 
                  className='btn btn-secondary mr-2' 
                  onClick={printToPdf}
                >
                  <i className='far fa-file-pdf mr-2'></i>
                  Xuất PDF
                </button>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  onClick={print}
                >
                  <i className='fas fa-print mr-2'></i>
                  In
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})