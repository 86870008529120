import { DatePicker, Modal, Tooltip, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import * as Actions from '../../../../libs/actions';
import * as InHospitalService from '../../../../services/inPatientService';
import * as PascService from '../../../../services/pascService';
import * as PrintService from '../../../../services/printService';
import * as ChiDinhService from '../../../../services/chiDinhService';
import CLSNoiTru from './CLSNoiTru';
import PatientInfo from './patientinfo';
const { RangePicker } = DatePicker;
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

class CDHA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: this.props.patientDetail ? this.props.patientDetail : {},
            lstCDHA: [],
            listType: [],
            ListTypeDistinct: [],
            ngaySearch: [moment(this.props.patientDetail.dateInHospital), moment()],
            keySearch: '',
            isOld: false,
            lstBlob: [],
            heightListBtn: 47,
        };
    }
    componentDidMount() {
        this.ThongTinCDHATheoBenhTrinh();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.patientDetail !== this.props.patientDetail) {
            this.setState({ detail: nextProps.patientDetail, ngaySearch: [moment(nextProps.patientDetail.dateInHospital), moment()] }, () => {
                this.ThongTinCDHATheoBenhTrinh();
            })
        }
        if (nextProps.heightListBtn !== this.props.heightListBtn) {
            this.setState({ heightListBtn: nextProps.heightListBtn })
        }
        return true;
    }

    ThongTinCDHATheoBenhTrinh() {
        if (this.state.detail.enterCode === undefined)
            return;
        InHospitalService.ThongTinCDHATheoBenhTrinh(this.state.detail.enterCode, this.state.detail.code,
            this.state.ngaySearch[0].format('YYYY/MM/DD'),
            this.state.ngaySearch[1].format('YYYY/MM/DD'), this.state.keySearch, this.state.isOld).then(result => {
                if (result.isSuccess) {
                    let listType = result.data.map(x => moment(x.ngaycd).format('DD/MM/YYYY'));
                    let ListTypeDistinct = listType.filter((x, index) => !listType.includes(x, index + 1));
                    this.setState({
                        lstCDHA: result.data,
                        listType: ListTypeDistinct,
                        lstBlob: [],
                    });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", "Tải dữ liệu chẩn đoán hình ảnh bệnh nhân nội trú bị lỗi")
            })
    }

    PrintResultTest(data, all = true) {
        PrintService.PrintPASCBenhTrinh(this.state.detail.code, this.state.detail.enterCode, this.state.detail.managerId,
            moment(data.ngaycd).format('YYYY/MM/DD'), this.state.ngaySearch[0].format('YYYY/MM/DD'), this.state.ngaySearch[1].format('YYYY/MM/DD'),
            all, this.state.keySearch, this.state.isOld).then((result) => {
                Actions.DoPrint(result.item)
            })
    }

    SortImages() {
        let data = this.state.lstBlob;
        data.sort(function (a, b) {
            return Number(a.name.split('.')[0]) - (b.name.split('.')[0]);
        });
        this.setState({ lstBlob: data.map(x => ({ ...x })) })
    }


    loadimage(data, key) {
        let lstBlob = this.state.lstBlob;
        let old = lstBlob.find(x => x.key === key);
        if (old !== undefined) {
            return;
        }
        let fileList = lstBlob;
        let index = 0;
        for (let item of data) {
            PascService.loadImage(item.maql, item.idcd, item.filename).then(result => {
                result.blob().then((blob) => {
                    let urlTemp = window.URL.createObjectURL(blob);

                    let temp = {
                        key: key,
                        uid: item.maql + item.filename,
                        name: item.filename,
                        status: 'done',
                        response: 'Server Error 500', // custom error message to show
                        url: urlTemp,
                    }
                    index++;
                    fileList.push(temp);
                    this.setState({ lstBlob: fileList }, () => {
                        if (index === data.length) {
                            this.SortImages();
                        }
                    })
                })

            }).catch(err => {
                console.log(err);
            })
        }
    }

    XoaChiDinh(id, ngay) {
        if (id !== 0) {
            ChiDinhService.XoaChiDinh(id, ngay).then(result => {
                if (result.isSuccess) {
                    this.ThongTinCDHATheoBenhTrinh();
                }
                else {
                    notification.warning({ message: result.err.msgString });
                }
            });
        }
    }

    render() {
        const CustomRightArrow = ({ onClick, ...rest }) => {
            const {
                onMove,
                carouselState: { currentSlide, deviceType }
            } = rest;
            // onMove means if dragging or swiping in progress.
            return <button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => onClick()} />;
        };
        return (
            <React.Fragment>
                <div style={{ height: `calc(100% - ${this.state.heightListBtn}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                    <div style={{ backgroundColor: '#fff', padding: '10px' }}>
                        <PatientInfo patientDetail={this.state.detail} />
                    </div>
                    <div className="p-md-0 pt-2" style={{ marginTop: '10px' }}>
                        <div className="row align-items-center" style={{ padding: '10px', paddingTop: 0 }}>
                            <div className="w-100 col-12 row no-gutters" style={{ backgroundColor: '#fff', paddingTop: 10 }}>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '115px' }} className="btn btn-color fontSize12" onClick={() => { this.setState({ visibleEditCLS: true }) }}
                                        data-toggle="collapse" data-target="#themchidinhxetnghiem"><i className="fa fa-plus"></i> Thêm chỉ định</button>
                                </div>
                                <div className="col-auto mb-2 mr-2">
                                    <button style={{ width: '105px' }} className="btn btn-outline-secondary fontSize12"
                                        onClick={() => { this.PrintResultTest({ ngaySearch: moment() }) }}><i className="fa fa-print"></i> In tất cả KQ</button>
                                </div>
                                <div className="col-auto mb-2 mr-2" style={{ width: '225px' }}>
                                    <RangePicker className="fontSize12" format="DD-MM-YYYY" value={this.state.ngaySearch} allowClear={false}
                                        locale={viVN} onChange={(value => this.setState({ ngaySearch: value }, () => { this.ThongTinCDHATheoBenhTrinh() }))}
                                    />
                                </div>
                                <div className="col-auto mb-2 mr-2 align-self-center">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" value={this.state.isOld}
                                                onChange={(e) => { this.setState({ isOld: e.target.checked }, () => { this.ThongTinCDHATheoBenhTrinh() }) }} />Hiện bệnh án cũ
                                    </label>
                                    </div>

                                </div>
                                <div className="col-auto mb-2">
                                    <div className="input-group fontSize12">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-color" type="button" onClick={() => { this.ThongTinCDHATheoBenhTrinh(); }}><i className="fa fa-search" /></button>
                                        </div>
                                        <input value={this.state.keySearch} type="text" className="form-control" placeholder="Nhập tên dịch vụ"
                                            onChange={(e) => this.setState({ keySearch: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    this.ThongTinCDHATheoBenhTrinh();
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.visibleEditCLS &&
                        <div className="pt-2 collapse" id='themchidinhxetnghiem' style={{ marginTop: '10px', backgroundColor: "#fff" }}>
                            <CLSNoiTru
                                ref={(c) => this.modalCLS = c}
                                patient={this.state.detail}
                                noitru={true}
                                ngay={moment()}
                                showType={true}
                                type={3}
                                idbenhtrinh={this.state.idbenhtrinh}
                                onClose={() => {
                                    this.ThongTinCDHATheoBenhTrinh();
                                    this.setState({ visibleEditCLS: false })
                                }}
                            />
                        </div>
                    }
                    {this.state.listType.map((ngay, index) => {
                        let tmp = this.state.lstCDHA.filter(x => moment(x.ngaycd).format('DD/MM/YYYY') === ngay)[0];
                        let dataTMP = this.state.lstCDHA.filter(x => moment(x.ngaycd).format('DD/MM/YYYY') === ngay);
                        return (
                            <div className="w-100">
                                <div className="w-100" style={{ height: '1px', backgroundColor: '#F2F5FA' }}></div>
                                <div className="p-md-0">
                                    <div style={{ padding: '10px', backgroundColor: '#fff' }}>
                                        <div className="w-100" key={index}>
                                            <div data-toggle="collapse" data-target={`#BTXN${index}`} className="mb-0" style={{ cursor: 'pointer' }}>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex">
                                                        <div style={{ marginRight: '30px' }}>{moment(tmp.ngaycd).format('DD/MM/YYYY')}</div>
                                                        <div>BS điều trị: {tmp.tenbs}</div>
                                                    </div>
                                                    <div className="ml-auto" style={{ cursor: 'pointer', fontSize: '13px' }} onClick={(e) => { e.stopPropagation(); this.PrintResultTest(tmp, false) }}>
                                                        <i className="fa fa-print" style={{ marginRight: '5px' }}></i>In kết quả
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={`BTXN${index}`} className={`collapse ${index === 0 ? 'show' : ''}`}>
                                                {dataTMP.map((row, indexChild) => {
                                                    if (index === 0 && indexChild === 0) {
                                                        this.loadimage(row.images, 'images-' + ngay + indexChild);
                                                    }
                                                    return (
                                                        <div className="row" style={{ borderTop: '1px solid #d9d9d9', paddingTop: '10px', paddingBottom: '10px' }}>
                                                            <div className="col-12" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#cdhaChild${indexChild}`}
                                                                onClick={() => { this.loadimage(row.images, 'images-' + ngay + indexChild) }}
                                                            >
                                                                <div className="h6 font-weight-bold d-flex">{`${indexChild + 1}. ${row.tenkt}`}
                                                                    <div className="ml-2">
                                                                        <Tooltip align="top" title="Xóa">
                                                                            <div className="text-danger"
                                                                                onClick={() => { this.XoaChiDinh(row.idchidinh, moment(row.ngaycd).format("YYYY/MM/DD")) }}
                                                                                style={{ cursor: "pointer" }}><i className="fa fa-trash"></i>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`col-12 row collapse ${indexChild === 0 ? 'show' : ''}`} id={`cdhaChild${indexChild}`}>
                                                                <div className="col-md-6">
                                                                    <div style={{ overflow: 'auto' }} className='table-collapse-pasc table-collapse-pascDetails text-left-print-pasc' dangerouslySetInnerHTML={{ __html: `${row.mota !== null ? row.mota : ''}` }}></div>
                                                                    <div><strong>Kết luận: </strong>{row.ketluan}</div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    {row.lstUrlImage && row.lstUrlImage.length > 0 &&
                                                                        <Carousel
                                                                            responsive={responsive}
                                                                            // additionalTransfrom={0}
                                                                            arrows
                                                                            autoPlaySpeed={3000}
                                                                            centerMode={false}
                                                                            className=""
                                                                            containerClass="container-with-dots"
                                                                            dotListClass=""
                                                                            draggable
                                                                            // focusOnSelect={false}
                                                                            //infinite
                                                                            itemClass=""
                                                                            keyBoardControl
                                                                            minimumTouchDrag={80}
                                                                            renderButtonGroupOutside={false}
                                                                            renderDotsOutside={false}
                                                                            showDots={false}
                                                                            sliderClass=""
                                                                            slidesToSlide={1}
                                                                            swipeable
                                                                            customRightArrow={<CustomRightArrow />}
                                                                        >
                                                                            {this.state.lstBlob.filter(x => x.key === 'images-' + ngay + indexChild).map((img, indexImg) => (
                                                                                <div style={{ margin: '10px', cursor: 'pointer' }} onClick={() => {
                                                                                    this.setState({ showImg: true, linkUrl: img.url })
                                                                                }}>
                                                                                    <img className="d-block w-100" src={img.url} alt={`slide${indexChild}`} />
                                                                                </div>
                                                                            ))}
                                                                        </Carousel>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <Modal
                    width={1400}
                    visible={this.state.showImg}
                    onCancel={() => {
                        this.setState({ showImg: false })
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: '7px',
                            cursor: 'pointer',
                            backgroundColor: 'rgba(0,0,0,0.1)',
                            textAlign: 'center',
                            height: '20px',
                            width: '20px',
                        }}
                        onClick={() => {
                            this.setState({ showImg: false })
                        }}
                    >
                        <i className="fa fa-times"></i>
                    </div>
                    <img className="d-block w-100" src={this.state.linkUrl} alt={`slide item`} />
                </Modal>
            </React.Fragment>
        );
    }
}

export default CDHA;