import * as ApiCaller from '../libs/httpRequests';

const prefix = 'nhapkhoa';

export function DanhSachDaNhap(makhoa,key="")  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachdanhap?makhoa=${makhoa}&key=${key}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachChoNhap(makhoa)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachchonhap?makhoa=${makhoa}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachBenhAn(makp)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachkhoabenhan?makp=${makp}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachKhoa()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachkhoasenhap`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThongTinBenhNhanNhapKhoa(mabn,maql)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/thongtinbannhannhapkhoa?mabn=${mabn}&maql=${maql}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function LuuNhapKhoa(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/luunhapkhoa`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachSuatAn(loai)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachSuatAn?loai=${loai}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateDatCom(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateDatCom`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function UpdateDatComCT(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateDatComCT`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ListDatCom(ngay)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListDatCom?ngay=${ngay}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};