import moment from "moment";
import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { defaultStyles, FileIcon } from 'react-file-icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popconfirm, notification, Tooltip, Checkbox } from 'antd';
import * as SystemConfig from '../../../../configure/systemConfig';

export default (props) => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [isEdit, setIsEdit] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [showCopy, setShowCopy] = useState(true);
    const [showPreview, setShowPreview] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [selectedFileId, setSelectedFileId] = useState([]);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setFileList(props.fileList);
        setIsEdit(props.isEdit);
        setShowSelect(props.showSelect);
        setShowCopy(props.showCopy);
        setShowPreview(props.showPreview);
    },[props])

    useEffect(() => {
        defaultSelectAll();
    },[props.fileList])

    function defaultSelectAll() {
        if (props.defaultSelectAll) {
            let list = props.fileList.map(x=> {return x.id});
            setSelectedFileId(list);
            props.changeSelectedIds(list);
        }
    }

    function copyToClipboard(e) {
        setCopied(true);
        notification.success({ message: 'Sao chép thành công' });
        setCopied(false);   
    }

    const viewFile = (data) => {
        props.onViewFile && props.onViewFile(data);
    }

    const onDeleteFileClickHandle = (data) => {
        props.onDelete && props.onDelete(data);
    }

    const onChangeSelected = (e, row) => {
        let list = selectedFileId ?? [];
        if (e.target.checked) {
            list.push(row.id);
        }
        else {
            list = list.filter(x => x != row.id);
        }
        setSelectedFileId(list);
        props.changeSelectedIds(list);
        forceUpdate();
    }

    return (
        <div className="row">
            {fileList && fileList.map((row, index) => (
                <div className="col-sm-12 col-md-6 col-lg-4 py-1" key={index}>
                    <div className="upload-file-container text-center">
                        <Tooltip title={row.fileName}>
                            <div className="w-100" style={{ margin: '0 auto' }}>
                                { (row.extensionName === 'jpg' || row.extensionName === 'jpeg' || row.extensionName === 'png') ? 
                                    <img alt={row.fileName} src={row.fileUrl} width="100px" height="100px" />
                                :
                                    <FileIcon extension={row.extensionName} {...defaultStyles[`${row.extensionName}`]} />
                                }
                            </div>
                        </Tooltip>
                    </div>
                    <div className="d-flex justify-content-center align-content-center my-2">
                        {showSelect && 
                            <Checkbox className="mr-2"
                                checked={selectedFileId.indexOf(row.id) > -1}
                                onChange={(e) => { onChangeSelected(e, row); }}
                                onClick={(e) => { }}
                            ></Checkbox>
                        }
                        {showPreview && 
                            <i className="far fa-eye mr-2" onClick={() => {viewFile(row)}} style={{ cursor: 'pointer' }} />
                        }
                        {showCopy && 
                        <CopyToClipboard text={row.fileUrl}
                            onCopy={() => copyToClipboard()}>
                            <i className="far fa-clone mr-2" style={{ cursor: 'pointer' }} />
                        </CopyToClipboard>
                        }
                        {isEdit && 
                            <Popconfirm
                                title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{row.fileName}</b>?</span>)}}
                                onConfirm={() => onDeleteFileClickHandle(row)}
                                onCancel={() => {}}
                                okText="Đồng ý"
                                cancelText="Hủy"
                            >
                                <i className="fas fa-trash-alt mr-2" style={{ cursor: 'pointer' }} />
                            </Popconfirm>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
};
